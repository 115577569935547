import { styled } from '@mui/material'

export const StyledAddLinesDrawerRoot = styled('div')`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
export const StyledAddLinesDrawerDiscard = styled('div')`
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  right: ${({ theme }) => theme.spacing(1.2)};
  top: ${({ theme }) => theme.spacing(1.2)};
`
