import { DimensionId } from '@fintastic/web/util/dimensions'

export const endpoints = {
  dimensions: '/planning/api/v2/global/dimensions',
  createDimensions: '/planning/api/v2/global/dimensions/',
  createDimensionValues: (id: DimensionId) =>
    `/planning/api/v2/global/dimensions/${id}/values/`,
  tables: '/planning/api/v2/global/tables/',
  columns: '/planning/api/v2/global/columns/',
  getDimensionValues: (id: DimensionId) =>
    `/planning/api/v2/global/dimensions/${id}/values/`,
  renameDimensionValues: (id: DimensionId) =>
    `/planning/api/v2/global/dimensions/${id}/values/`,
  renameDimension: (id: DimensionId) =>
    `/planning/api/v2/global/dimensions/${id}/`,
  reorderDimensions: (id: DimensionId) =>
    `/planning/api/v2/global/dimensions/${id}/values/ordering/`,
}
