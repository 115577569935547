import { useCallback, useMemo } from 'react'
import { ChangeColumnTypeAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'
import { DataContextValue } from '../../data/data-context'

export function useChangeColumnTypeFlow({ dimensionsList }: DataContextValue) {
  const { changeType, changeName } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnTypeAction>(
    (columnId, newType, defaultDimension) => {
      if (newType === 'dimension') {
        if (!dimensionsList || !defaultDimension) {
          throw new Error('not enough arguments')
        }
        changeType({
          columnId,
          type: 'dimension',
          allDimensions: dimensionsList,
          dimensionId: defaultDimension,
        })

        const dimensionName =
          dimensionsList.find((d) => d.id === defaultDimension)?.label || ''

        if (dimensionName) {
          changeName({
            columnId,
            newName: dimensionName,
            dimensionNames: dimensionsList.map((d) => d.label),
          })
        }
        return
      }

      changeType({
        columnId,
        type: newType,
      })
    },
    [changeType, changeName, dimensionsList],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
