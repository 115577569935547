import { Maybe } from '@fintastic/shared/util/types'

const LIST_ID_PREFIX = 'Table'
const METRIC_ID_PREFIX = 'Cube'

const metricIdRegExp = new RegExp(`^${METRIC_ID_PREFIX}\\.[^.]+$`)
const listIdRegExp = new RegExp(`^${LIST_ID_PREFIX}\\.[^.]+$`)
const columnIdRegExp = new RegExp(`^${LIST_ID_PREFIX}\\.[^.]+\\.[^.]+$`)

export const idLooksLikeList = (id: string) => listIdRegExp.test(id)

export class ParsedListId {
  public readonly idWithoutPrefix: string

  constructor(idWithoutPrefix: string) {
    this.idWithoutPrefix = idWithoutPrefix
  }

  public static fromString(s: string): Maybe<ParsedListId> {
    if (!idLooksLikeList(s)) {
      return null
    }
    const [, ...otherParts] = s.split('.')
    return new ParsedListId(otherParts.join('.'))
  }

  toString(): string {
    return `${LIST_ID_PREFIX}.${this.idWithoutPrefix}`
  }
}

export const idLooksLikeMetric = (id: string) => metricIdRegExp.test(id)

export class ParsedMetricId {
  public readonly idWithoutPrefix: string

  constructor(idWithoutPrefix: string) {
    this.idWithoutPrefix = idWithoutPrefix
  }

  public static fromString(s: string): Maybe<ParsedMetricId> {
    if (!idLooksLikeMetric(s)) {
      return null
    }
    const [, ...otherParts] = s.split('.')
    return new ParsedMetricId(otherParts.join('.'))
  }

  toString(): string {
    return `${METRIC_ID_PREFIX}.${this.idWithoutPrefix}`
  }
}

export const idLooksLikeColumn = (id: string) => columnIdRegExp.test(id)

export class ParsedColumnId {
  public readonly listId: string
  public readonly columnIdWithoutPrefix: string

  constructor(listId: string, columnIdWithoutPrefix: string) {
    this.listId = listId
    this.columnIdWithoutPrefix = columnIdWithoutPrefix
  }

  public static fromString(s: string): Maybe<ParsedColumnId> {
    if (!idLooksLikeColumn(s)) {
      return null
    }
    const [, listIdWithoutPrefix, ...columnParts] = s.split('.')
    return new ParsedColumnId(
      `${LIST_ID_PREFIX}.${listIdWithoutPrefix}`,
      columnParts.join('.'),
    )
  }

  toString(): string {
    return `${this.listId}.${this.columnIdWithoutPrefix}`
  }
}
