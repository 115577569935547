import type { MutationKey, QueryKey } from 'react-query'
import compact from 'lodash/fp/compact'

export const importConfigsBaseQueryKey = (): QueryKey => [
  'collaboration',
  'threads',
]

export const importConfigsByVersionQueryKey = (versionId: string): QueryKey =>
  compact([...importConfigsBaseQueryKey(), versionId])

export const importConfigUpdateMutationKey = (version_id: string): MutationKey => [
  ...importConfigsBaseQueryKey(),
  'update',
  version_id,
]
