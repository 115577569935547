import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DeeplinkKeyFullPath } from '../types'

/**
 * Use this hook to preserve deeplink state when navigating iside of app
 */
export const useKeepPartialDeeplinkUrl = (
  nextUrl: string,
  preservedDeeplinkKeys: PartialDeeplinkKeeperConfig,
): string => {
  const [searchParams] = useSearchParams()

  const preservedSearchParams = useMemo<string>(() => {
    const nextParams = new URLSearchParams()

    preservedDeeplinkKeys.forEach((item) => {
      if (item.shape?.length) {
        item.shape.forEach((subKey) => {
          const fullKey = `${item.key}[${subKey}]`
          const v = searchParams.get(fullKey)
          if (v !== null) {
            nextParams.set(fullKey, v)
          }
        })
      } else {
        const v = searchParams.get(item.key)
        if (v !== null) {
          nextParams.set(item.key, v)
        }
      }
    })

    return nextParams.toString()
  }, [preservedDeeplinkKeys, searchParams])

  return useMemo<string>(() => {
    if (preservedSearchParams) {
      return `${nextUrl}?${preservedSearchParams}`
    }

    return nextUrl
  }, [nextUrl, preservedSearchParams])
}

export type PartialDeeplinkKeeperConfig = Array<{
  key: DeeplinkKeyFullPath
  shape?: string[]
}>
