/**
 Types for describe duck state and extend root state
 */
 import { UserData } from '@fintastic/web/data-access/iam'
import {
  ChangeType,
  ColumnMaskConfig,
  DimensionMap,
  Group,
  GroupId,
  TableAccess,
} from '../types'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'permissions' as const

// extending root state to use it in selectors
export type RootState = {
  [namespace]: State
}

// duck state
export type State = {
  loading: boolean
  saving: boolean
  selectedGroup: string
  groups: Group[]
  users: UserData[]
  columnMasking: Record<GroupId, ColumnMaskConfig[]>
  tableAccessList: Record<GroupId, TableAccess[]>
  policyHash: Record<GroupId, string>
  defaultDimensionConfig: Record<GroupId, DimensionMap>
  changes: Record<
    string,
    {
      groupId: GroupId
      changeType: ChangeType
      payload: TableAccess | any
    }
  >
}

export const initialState: State = {
  loading: false,
  saving: false,
  policyHash: {},
  selectedGroup: '',
  groups: [],
  users: [],
  columnMasking: {},
  tableAccessList: {},
  defaultDimensionConfig: {},
  changes: {},
}
