import { DependencyGraphWrapper } from '@fintastic/web/util/versions'
import { ParsedColumnId } from '@fintastic/web/util/metrics-and-lists'
import uniq from 'lodash/uniq'

export function getAffectedEntities(
  userModifiedEntities: string[],
  dependenciesGraph: DependencyGraphWrapper,
  useModifiedEntities: boolean,
) {
  let affectedEntities = dependenciesGraph.successors(userModifiedEntities)

  if (useModifiedEntities) {
    affectedEntities.push(...userModifiedEntities)
  }

  // if only columns were changed, filter out list itself to avoid unnecessary columns cache revalidation
  userModifiedEntities?.forEach((entityId) => {
    const parsedColumnId = ParsedColumnId.fromString(entityId)
    if (
      !parsedColumnId ||
      userModifiedEntities?.includes(parsedColumnId.listId)
    ) {
      return
    }
    affectedEntities = affectedEntities.filter(
      (affectedId) => affectedId !== parsedColumnId.listId,
    )
  })

  return uniq(affectedEntities)
}
