import React, { useCallback, useMemo } from 'react'
import { VersionTimeDimension } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { Dimension } from '@fintastic/shared/util/types'
import { TimeDimensionSelectorInput } from '../../../shared/TimeDimensionSelectorInput'
import { useDataContext } from '../../connection/data/data-context'
import { useActionsContext } from '../../connection/actions/actions-context'
import { compact } from 'lodash'

export const TimeDimensionSelector: React.FC = () => {
  const { list, dimensionsList } = useDataContext()
  const { readonly, actions, flow } = useActionsContext()

  const handleChangeTimeDimension = useCallback(
    (newTimeDimension: Maybe<Dimension>) => {
      actions.changeListTimeDimension(newTimeDimension?.id ?? null)
    },
    [actions],
  )

  const timeDimensions = useMemo(
    () =>
      (dimensionsList?.filter((dim) => dim.type === 'Time') ??
        []) as VersionTimeDimension[],
    [dimensionsList],
  )

  if (!list) {
    return null
  }

  const enabled =
    (flow === 'creation' || !list.unwrap()._initiallyHadBaseTimeDimension) &&
    list?.allowedToChangeBaseTimeDimension()

  return (
    <TimeDimensionSelectorInput
      metricDimensions={compact([list.baseTimeDimension()])}
      timeDimensions={timeDimensions}
      onChangeTimeDimension={handleChangeTimeDimension}
      readonly={readonly || !enabled}
    />
  )
}
