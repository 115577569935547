import {
  styled,
  Select,
  selectClasses,
  MenuItem,
  listItemTextClasses,
  listItemIconClasses,
  typographyClasses,
} from '@mui/material'

export const StyledSelect = styled(Select<string>)`
  .${selectClasses.select} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .${listItemTextClasses.root} {
      margin: 0;
    }

    .${listItemIconClasses.root} {
      width: 24px;
      min-width: 24px;
    }

    &:not(.${selectClasses.disabled}) .${listItemIconClasses.root} {
      color: ${({ theme }) => theme.palette.text.primary};
    }

    .${listItemIconClasses.root} svg {
      width: 16px;
      height: 16px;
    }
  }
`

export const StyledMenuItem = styled(MenuItem)`
  .${listItemTextClasses.root} {
    .${typographyClasses.root} {
      font-size: 14px;
    }
  }

  .${listItemIconClasses.root} {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`
