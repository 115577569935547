import {
  Version,
  VersionStateApi,
} from '../../../Editor/features/version-state/types'
import { useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

// @todo add tests
export function useVersionState(versions: Version[]): VersionStateApi {
  const [versionId, setVersionId] = useState<Maybe<string>>(
    versions[0]?.id || null,
  )

  useEffect(() => {
    setVersionId(versions[0]?.id || null)
  }, [versions])

  const version = useMemo(
    () => versions.find((v) => v.id === versionId),
    [versionId, versions],
  )

  return useMemo(
    () => ({
      version: version || null,
      selectVersion: setVersionId,
      allVersions: versions,
    }),
    [version, versions],
  )
}
