import React, { useCallback, useMemo } from 'react'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { DimensionsSettings } from '../../settings/dimensions'
import { useActionsContext } from '../../connection/actions/actions-context'
import { useDataContext } from '../../connection/data/data-context'
import { DimensionsChangeCallback } from '../../settings/dimensions/types'
import { resolveDimensionValues } from '@fintastic/web/util/dimensions'
import { Dimension } from '@fintastic/shared/util/types'
import { Box, Divider } from '@mui/material'
import { TimeDimensionSelector } from '../../settings/dimensions/time-dimension-selector/TimeDimensionSelector'

export const MetricDimensions: React.FC = () => {
  const { metric, dimensionsList } = useDataContext()
  const { readonly, actions, flow } = useActionsContext()

  const dimensionsWithValues = useMemo<Dimension[]>(() => {
    if (!metric || !dimensionsList) {
      return []
    }

    const localRangeDims = metric.getLocalRangeDimensions()

    return dimensionsList
      .filter((d) =>
        d.type === 'Range' ? metric.data().hasDimension(d.id) : true,
      )
      .map<Dimension>((d) => {
        if (d.type !== 'Range') {
          return d
        }
        return {
          ...d,
          values: resolveDimensionValues(d, localRangeDims) || {},
        }
      })
      .filter((d) => !!d.values)
  }, [dimensionsList, metric])

  const handleChangeDimensions = useCallback<DimensionsChangeCallback>(
    (newDimensions, changedDimensionId) => {
      const changedDimension = newDimensions.find(
        (d) => d.id === changedDimensionId,
      )
      if (changedDimension !== undefined) {
        actions.addDimension(changedDimension)
        return
      }
      actions.removeDimension(changedDimensionId)
    },
    [actions],
  )

  if (!metric) {
    return null
  }

  const allowTimeDimensions = metric.allowedToHaveTimeDimension()

  return (
    <ViewLayout title={'Dimensions'}>
      {allowTimeDimensions ? (
        <>
          <TimeDimensionSelector />
          <Box my={2}>
            <Divider />
          </Box>
        </>
      ) : null}
      <DimensionsSettings
        onChangeMetricDimensions={handleChangeDimensions}
        metricSource={metric.source() || 'input'}
        metricDimensions={metric.data().dimensions()}
        allDimensions={dimensionsWithValues}
        readonly={readonly}
        isNewMetric={flow === 'creation'}
      />
    </ViewLayout>
  )
}
