import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { deleteVersion } from '../../api/versions-management-api'
import {
  invalidateVersionManagementRelatedQueries,
  optimisticDeleteVersionsListCache,
} from '../../cache'

export function useDeleteListOfVersions(versionsIds: string[]) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.deleteVersions(versionsIds),
    async () =>
      Promise.all(
        versionsIds.map((id) =>
          (async () => {
            const response = await deleteVersion(id)
            return response.data
          })(),
        ),
      ),
    {
      onMutate: async () => {
        optimisticDeleteVersionsListCache(queryClient, versionsIds)
      },
      onSuccess: async () => {
        await invalidateVersionManagementRelatedQueries(
          queryClient,
          versionsIds,
        )
      },
      onError: async () => {
        await invalidateVersionManagementRelatedQueries(
          queryClient,
          versionsIds,
        )
      },
    },
  )
}
