import { LabelsContext, LabelTagListItem } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { labelsQueryKey } from './query-keys'
import { updateLabel } from '../api'

export const useUpdateLabelMutation = (context: LabelsContext) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse<LabelTagListItem>,
    AxiosError,
    LabelTagListItem,
    {
      previousLabels: LabelTagListItem[]
    }
  >(
    labelsQueryKey.updateLabel(context),
    (label) =>
      updateLabel(context, label.id, {
        title: label.title,
        color: label.color,
      }),
    {
      onMutate: async (label) => {
        await queryClient.cancelQueries(labelsQueryKey.updateLabel(context))

        const previousLabels =
          queryClient.getQueryData<LabelTagListItem[]>(
            labelsQueryKey.list(context),
          ) || []

        queryClient.setQueryData<LabelTagListItem[]>(
          labelsQueryKey.list(context),
          (prev) => [
            ...(prev || []).map((i) => {
              if (i.id !== label.id) {
                return i
              }

              return {
                ...i,
                title: label.title,
                color: label.color,
              }
            }),
          ],
        )

        return { previousLabels }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData<LabelTagListItem[]>(
          labelsQueryKey.list(context),
          ctx?.previousLabels || [],
        )

        if (err.response?.status !== 409) {
          console.error(err)
          toast.error(
            `Failed to update a label. Error: ${err?.code || 'unknown'}`,
          )
        }
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: labelsQueryKey.list(context),
        })
      },
    },
  )

  return result
}
