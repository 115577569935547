import { axios } from '@fintastic/web/data-access/service-axios'
import type {
  VersionDependencyGraph,
  VersionEntities,
} from '@fintastic/web/util/versions'

const endpoints = {
  entities: (versionId: string) =>
    `/planning/api/v2/versions/${versionId}/entities/`,
}

export function getVersionEntities(versionId: string) {
  return axios.get<VersionEntities>(endpoints.entities(versionId))
}

export function getVersionDependencyGraph(versionId: string) {
  return axios.get<VersionDependencyGraph>(
    `${endpoints.entities(versionId)}dependencies/`,
    {
      params: {
        with_weights: 'true',
        with_reports: 'true',
      },
    },
  )
}
