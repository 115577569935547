import { DeeplinkValueElement } from '../types'
import { JOIN_SYMBOL } from './const'

export const deeplinkValueElementStringify = (
  input: DeeplinkValueElement,
): string => {
  if (input === null) {
    return 'null'
  }

  if (input === true) {
    return 'true'
  }

  if (input === false) {
    return 'false'
  }

  if (isFinite(input as number)) {
    return `${input}`
  }

  return encodeURIComponent(String(input))
}

export const serialazeSimpleArray = (input: DeeplinkValueElement[]): string =>
  JOIN_SYMBOL + input.map(deeplinkValueElementStringify).join(JOIN_SYMBOL)
