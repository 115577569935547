import { QueryKey } from 'react-query'
import type { LabelsContext } from '../types'

const base = (context: LabelsContext): QueryKey => ['labels', context]

export const labelsQueryKey = {
  base,
  list: (context: LabelsContext) => [...base(context), 'list'],
  createLabel: (context: LabelsContext) => [
    ...base(context),
    'mutate',
    'create',
  ],
  deleteLabel: (context: LabelsContext) => [
    ...base(context),
    'mutate',
    'delete',
  ],
  updateLabel: (context: LabelsContext) => [
    ...base(context),
    'mutate',
    'update',
  ],
} as const
