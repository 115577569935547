import { styled, Box, BoxProps } from '@mui/material'
import { forwardRef } from 'react'

export const StyledPanelRoot = styled(
  forwardRef<
    HTMLDivElement,
    {
      fullHeight: boolean
      hideShadow: boolean
      hideBackground: boolean
      hideRoundedBorders?: boolean
    } & BoxProps
  >(
    (
      { hideShadow, hideBackground, hideRoundedBorders, fullHeight, ...rest },
      ref,
    ) => <Box {...rest} ref={ref} />,
  ),
)(({ theme, fullHeight, hideShadow, hideBackground, hideRoundedBorders }) => ({
  position: 'relative',
  overflow: 'hidden',

  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',

  width: '100%',
  height: fullHeight ? '100%' : undefined,

  boxShadow: hideShadow
    ? undefined
    : '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: hideRoundedBorders ? undefined : theme.shape.borderRadius,
  background: hideBackground ? undefined : theme.palette.common.white,
}))

export const StyledPanelToolbar = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export const StyledPanelContent = styled(
  ({
    fullHeight,
    enableContentPaddings,
    ...rest
  }: { fullHeight: boolean; enableContentPaddings: boolean } & BoxProps) => (
    <Box {...rest} />
  ),
)(({ theme, fullHeight, enableContentPaddings }) => ({
  padding: enableContentPaddings ? theme.spacing(2) : undefined,
  width: '100%',
  height: fullHeight ? '100%' : undefined,
}))

export const StyledPanelBottomBar = styled(Box)(({ theme }) => ({
  width: '100%',
}))
