import React from 'react'
import { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'
import { Switch } from '@mui/material'
import { FunctionFXIcon, FunctionAXIcon } from '@fintastic/shared/ui/icons'
import { StyledRoot } from './SourceField.styled'

export type SourceFieldProps = {
  value: MetricOrListSource
  onChange: (newValue: MetricOrListSource) => void
  disabled: boolean
  isLiveActuals: boolean
}

export const SourceField: React.FC<SourceFieldProps> = ({
  value,
  onChange,
  disabled,
  isLiveActuals,
}) => (
  <StyledRoot
    label={
      <>
        Calculated{' '}
        {!isLiveActuals ? (
          <FunctionFXIcon
            sx={{
              color: (theme) => theme.palette.action.disabled,
              ml: 0.5,
            }}
          />
        ) : (
          <FunctionAXIcon
            sx={{
              color: (theme) => theme.palette.action.disabled,
              ml: 0.5,
            }}
          />
        )}
      </>
    }
    disabled={disabled}
    control={
      <Switch
        checked={value === 'calculated'}
        onChange={(_, checked) => onChange(checked ? 'calculated' : 'input')}
      />
    }
  />
)
