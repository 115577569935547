import { MutationKey, useMutation, useQueryClient } from 'react-query'
import { createBoard, EditableBoardParams, createFolder } from '../api'
import { boardsListQueryKeyGetter } from './useBoardsListQuery'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'

export const boardCreateMutationKeyGetter = () => {
  const key: MutationKey = `board/create`
  return key
}

export function useBoardCreateMutation() {
  const queryClient = useQueryClient()

  return useMutation(
    boardCreateMutationKeyGetter(),
    async (variables: EditableBoardParams & { newFolderName?: string }) => {
      const { newFolderName, ...createBoardParams } = variables

      if (newFolderName !== undefined) {
        const folderResponse = await createFolder({ name: newFolderName })
        createBoardParams.folder_id = folderResponse.data.id
      }

      const response = await createBoard(createBoardParams)
      return response.data
    },
    {
      onSuccess: (data, variables) => {
        if (variables.newFolderName !== undefined) {
          queryClient.invalidateQueries(boardsFoldersListQueryKeyGetter(), {
            exact: true,
          })
        }
        queryClient.invalidateQueries(boardsListQueryKeyGetter(), {
          exact: true,
        })
      },
    },
  )
}
