import { styled, Box } from '@mui/material'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const StyledFiltersAndOrderingControls = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  gap: 4px ${({ theme }) => theme.spacing(1)};

  & > div {
    margin: ${({ theme }) => theme.spacing(0.25)} 0;
    max-width: 450px;
    min-width: 140px;
    flex-grow: 2;
  }
`

export const StyledDateControls = styled(Box)`
  display: flex;
  max-width: 600px;
  min-width: 240px;
`
