import { useEffect, useRef } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'

export function usePortal() {
  const portalRoot = useRef<Maybe<HTMLElement>>(null)
  useEffect(() => {
    const el = document.createElement('div')
    document.body.appendChild(el)
    portalRoot.current = el
    return () => {
      document.body.removeChild(el)
    }
  }, [])

  return portalRoot.current
}
