import React, { useCallback, useMemo } from 'react'
import { DimensionFilterInput } from '@fintastic/shared/ui/filters'
import {
  FilterByDimension,
  filterUtils,
  useFilterContext,
} from '@fintastic/web/util/filters'
import { CheckboxesListChangeHandler } from '@fintastic/shared/ui/components'
import type { Maybe } from '@fintastic/shared/util/types'

export const DimensionFilterGroup: React.FC<{
  filter: FilterByDimension
  timeDimensionValueId: Maybe<string>
}> = ({ filter, timeDimensionValueId }) => {
  const filterContext = useFilterContext()

  const handleDimensionSelected = useCallback<CheckboxesListChangeHandler>(
    (nextDimensions) => {
      const unselected = filter.options
        .filter(({ value }) => !nextDimensions.includes(value))
        .map(({ value }) => value)

      if (!unselected.length) {
        filterContext.setFilterValue(filter.id, timeDimensionValueId, undefined)
        return
      }

      filterContext.setFilterValue(filter.id, timeDimensionValueId, {
        value: unselected,
        operator: 'dimension',
      })
    },
    [filter.id, filter.options, filterContext, timeDimensionValueId],
  )

  const reversedValue = useMemo(
    () =>
      filterUtils.getFilterValueAsSelectedDimensions(
        filterContext.model,
        filter,
      ),
    [filter, filterContext.model],
  )

  return (
    <DimensionFilterInput
      onChange={handleDimensionSelected}
      options={filter.options}
      selected={reversedValue}
    />
  )
}

DimensionFilterGroup.displayName = 'DimensionFilterGroup'
