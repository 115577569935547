import { SyntaxLiteral, SyntaxLiteralType } from './types'
import { ObjectTokenRecognitionMap } from '../tokens/types'

// estimated complexity - around O(3n), where n is amount of fields in objectRecognitionMap
// in current stage objectRecognitionMap contains around 20 000 fields
export function getAllNonPunctuationLiterals(
  functionsList: string[],
  constantsList: string[],
  objectRecognitionMap: ObjectTokenRecognitionMap,
): SyntaxLiteral[] {
  const literalsByType = {
    functions: functionsList.map((l) => ({
      type: 'function',
      literal: l,
      upperCaseLiteral: l.toLocaleUpperCase(),
    })) as SyntaxLiteral[],
    constants: constantsList.map((l) => ({
      type: 'constant',
      literal: l,
      upperCaseLiteral: l.toLocaleUpperCase(),
    })) as SyntaxLiteral[],
    metrics: [] as SyntaxLiteral[],
    lists: [] as SyntaxLiteral[],
    dimensions: [] as SyntaxLiteral[],
  }

  // Object.keys + for loop is the fastest way to iterate over the object entries
  const objectsLiterals = Object.keys(objectRecognitionMap)
  for (let i = 0; i < objectsLiterals.length; i += 1) {
    const literal = objectsLiterals[i]
    const literalType = objectRecognitionMap[literal] as SyntaxLiteralType
    const literalObject = {
      literal,
      type: literalType,
      upperCaseLiteral: literal.toLocaleUpperCase(),
    }

    if (literalType === 'metric' || literalType === 'calculatedMetric') {
      literalsByType.metrics.push(literalObject)
      continue
    }

    if (
      literalType === 'list' ||
      literalType === 'listColumn' ||
      literalType === 'calculatedList' ||
      literalType === 'calculatedListColumn'
    ) {
      literalsByType.lists.push(literalObject)
      continue
    }

    if (literalType === 'dimensionValue' || literalType === 'dimension') {
      literalsByType.dimensions.push(literalObject)
    }
  }

  // using for loops because it's the fastest way to iterate over the array
  const resultOrder: Array<keyof typeof literalsByType> = [
    'functions',
    'metrics',
    'lists',
    'dimensions',
    'constants',
  ]
  const result: SyntaxLiteral[] = []
  for (let i = 0; i < resultOrder.length; i += 1) {
    const literals = literalsByType[resultOrder[i]].sort((a, b) =>
      a.literal.localeCompare(b.literal),
    )
    for (
      let literalIndex = 0;
      literalIndex < literals.length;
      literalIndex += 1
    ) {
      const literal = literals[literalIndex]
      result.push(literal)
    }
  }

  return result
}
