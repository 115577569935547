export const defaultPillValueStyle = `
  display: inline-flex;
  flex-flow: row nowrap;
  background: #f8f4f1;
  height: auto;
  line-height: 1;
  padding: 4px 6px;
  margin: 0;
  border-radius: 4px;
`
