import React, { useMemo, useRef } from 'react'
import { VersionMetric } from '../../../../utils/metrics'
import {
  FormulaEditor,
  Version as FormulaEditorVersion,
} from '@fintastic/web/feature/formulas'
import { metricVersionToFormulaVersion } from './metricVersionToFormulaVersion'
import { Maybe } from '@fintastic/shared/util/types'
import { ClientOnlyMapping } from '@fintastic/web/util/formulas'

export type MetricFormulaEditorWrapperProps = {
  versions: VersionMetric[]
  onRequestClose: () => void
  showVersionsSelector?: boolean
  formulaEditorTitle?: string
  onValidationFinished?: (
    versionId: string,
    formula: string,
    invalid?: boolean,
  ) => void
  creationOfCalculatedMetric?: boolean
  editingOfCalculatedMetric?: boolean
  isVersionPage?: boolean
  isSettingsEditingActive?: boolean
  controlledFormulaError: Maybe<string>
  clientOnlyMapping?: ClientOnlyMapping
}

export const MetricFormulaEditorWrapper: React.FC<
  MetricFormulaEditorWrapperProps
> = ({
  onRequestClose,
  versions: versionMetrics,
  showVersionsSelector = true,
  formulaEditorTitle,
  onValidationFinished,
  creationOfCalculatedMetric,
  editingOfCalculatedMetric,
  isVersionPage,
  isSettingsEditingActive,
  controlledFormulaError,
  clientOnlyMapping,
}) => {
  const singleVersionRef = useRef<FormulaEditorVersion[]>([])

  // to keep formula editor working correctly keep the reference to the version object,
  // otherwise the cursor position will be reset after every application of autocomplete
  const versions = useMemo<FormulaEditorVersion[]>(() => {
    if (versionMetrics.length === 0) {
      return []
    }

    if (isVersionPage) {
      const metric = versionMetrics[0]
      const newFormulaVersion = metricVersionToFormulaVersion(metric)

      if (isSettingsEditingActive) {
        newFormulaVersion.locked = false
      }

      if (
        !singleVersionRef.current ||
        singleVersionRef.current[0]?.id !== metric.versionId
      ) {
        singleVersionRef.current = [newFormulaVersion]
        return singleVersionRef.current
      }

      singleVersionRef.current[0].formula = newFormulaVersion.formula
      singleVersionRef.current[0].title = newFormulaVersion.title
      singleVersionRef.current[0].locked = newFormulaVersion.locked
      singleVersionRef.current[0].connectedTable =
        newFormulaVersion.connectedTable
      return singleVersionRef.current
    }

    return versionMetrics.map((version) =>
      metricVersionToFormulaVersion(version),
    )
  }, [isSettingsEditingActive, isVersionPage, versionMetrics])

  return (
    <FormulaEditor
      title={formulaEditorTitle || 'Forecast formula'}
      versions={versions}
      onRequestClose={onRequestClose}
      showVersionsSelector={showVersionsSelector}
      onValidationFinished={onValidationFinished}
      controlledEditingMode={
        creationOfCalculatedMetric || editingOfCalculatedMetric || isVersionPage
      }
      showCloseButtonInControlledMode={editingOfCalculatedMetric}
      readonly={
        isVersionPage &&
        !creationOfCalculatedMetric &&
        !editingOfCalculatedMetric
      }
      controlledFormulaError={controlledFormulaError}
      clientOnlyMapping={clientOnlyMapping}
    />
  )
}
