import { AuditLogActionType } from '../types'

type UpdateType = { name: string; supported: boolean }

// pls keep alphabetical order
export const auditLogUpdateTypes: Record<AuditLogActionType, UpdateType> = {
  add_line: { name: 'Add line', supported: true },
  add_entity: { name: 'Add entity', supported: false },
  change_entity_settings: { name: 'Change entity settings', supported: false },
  change_formula: { name: 'Change formula', supported: false },
  change_value: { name: 'Edit data', supported: true },
  delete_entity: { name: 'Delete entity', supported: false },
  delete_line: { name: 'Delete line', supported: false },
  duplicate_line: { name: 'Duplicate line', supported: false },
}

export function isUpdateType(
  valueType: unknown,
): valueType is AuditLogActionType {
  return auditLogUpdateTypes[valueType as AuditLogActionType] !== undefined
}

export function getUpdateTypeName(updateType: unknown): string {
  if (isUpdateType(updateType)) {
    return auditLogUpdateTypes[updateType].name
  }

  return String(updateType)
}

export const actionTypesSelectOptions: Array<{
  label: string
  value: AuditLogActionType
}> = Object.keys(auditLogUpdateTypes)
  .filter((key) => auditLogUpdateTypes[key as AuditLogActionType].supported)
  .map((key) => ({
    value: key as AuditLogActionType,
    label: getUpdateTypeName(key),
  }))
