import React from 'react'
import { ModalDialog } from '../ModalDialog'
import {
  SectionedModalNavigationPath,
  SectionedModalSectionDefinition,
} from './types'
import { SectionedContextWrapper } from './SectionedContextWrapper/SectionedContextWrapper'

export type SectionedModalProps = {
  open?: boolean
  title?: string
  onCancel: () => void
  width?: number | string
  initialPath?: SectionedModalNavigationPath
  routes: SectionedModalSectionDefinition[]
  allowNavigation?: boolean
  allowLeaveSection?: (
    sectionPath: SectionedModalSectionDefinition['path'],
  ) => boolean
  footer?: React.ReactNode
  bottomPanel?: React.ReactNode
}

export const SectionedModal: React.FC<SectionedModalProps> = ({
  open = true,
  onCancel,
  title,
  width = 400,
  initialPath,
  routes,
  allowNavigation = true,
  allowLeaveSection,
  footer,
  bottomPanel,
}) => (
  <ModalDialog open={open} onClose={onCancel} width={width}>
    <SectionedContextWrapper
      initialPath={initialPath}
      routes={routes}
      title={title}
      allowNavigation={allowNavigation}
      allowLeaveSection={allowLeaveSection}
      onRequestClose={onCancel}
    />
    {bottomPanel}
    {footer}
  </ModalDialog>
)
