import { LabelsContext } from '../types'

export const endpoints = {
  list: (context: LabelsContext) => `${base(context)}/labels/`,
  createLabel: (context: LabelsContext) => `${base(context)}/labels/`,
  updateLabel: (context: LabelsContext, id: number) =>
    `${base(context)}/labels/${id}/`,
  deleteLabel: (context: LabelsContext, id: number) =>
    `${base(context)}/labels/${id}/`,
}

const base = (context: LabelsContext): string =>
  context === 'collaboration' ? '/collaboration/api/v2' : '/planning/api/v2'
