import { createSvgIcon } from '@mui/material'

export const OrderingIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33975 10.2564V4.10096L4.76123 6.67948L4.16669 6.08975L7.75644 2.5L11.3462 6.08975L10.7516 6.67948L8.1731 4.10096V10.2564H7.33975ZM12.2276 17.5L8.63783 13.9103L9.2324 13.3205L11.8109 15.899V9.74358H12.6442V15.899L15.2228 13.3205L15.8173 13.9103L12.2276 17.5Z"
      fill="#6E665E"
    />
  </svg>,
  'OrderingIcon',
)
