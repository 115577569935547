import React, { useEffect, useMemo, useRef } from 'react'
import {
  StyledAppLayoutDrawerContainer,
  StyledAppLayoutLateralContainer,
  StyledAppLayoutLogoContainer,
  StyledAppLayoutMainContainer,
  StyledAppLayoutPageContainer,
  StyledAppLayoutPageContent,
  StyledAppLayoutRoot,
  StyledAppLayoutSidebarContainer,
  StyledAppLayoutTopbarPlaceholder,
} from './AppLayout.styled'
import { Sidebar } from '../Sidebar'
import { Discussions } from '../Lateral/Discussions/Discussions'
import { Logo } from '../Logo'
import { useLayoutStateIsDiscussionsOpened } from '../../ducks/layout/hooks'
import { layoutConfig } from '../../layout-config'
import { triggerWindowResizeSmoothly } from '@fintastic/shared/util/web-api'
import { LayoutSidebarConfig } from '../../types'
import {
  AppLayoutContextProvider,
  useAppLayoutContextValue,
} from '../../contexts/app-layout-context'
import { LateralPanel } from '../Lateral/LateralPanel'
import {
  AddLinesDrawer,
  addLinesPayloadType,
  AddLinesStateContext,
  useAddLinesDrawerContextValues,
} from '@fintastic/web/feature/list-add-lines'
import {
  BottomDrawer,
  DrawerContextProvider,
  DrawerContextValue,
} from '@fintastic/shared/ui/drawer-framework'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'

export type AppLayoutProps = {
  children: React.ReactNode
  sidebarConfig: LayoutSidebarConfig
  entityLevelHistory: React.ReactNode
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  sidebarConfig,
  entityLevelHistory,
}) => {
  const [isDiscussionsOpen] = useLayoutStateIsDiscussionsOpened()
  const historyApi = useHistoryLogGlobalApi()
  const isHistoryOpen =
    !!historyApi?.state.isOpen && historyApi.state.level === 'entity'
  const appLayoutContextValue = useAppLayoutContextValue(sidebarConfig)

  const drawerAddLinesContextValues = useAddLinesDrawerContextValues()

  const drawerContexts = useMemo<DrawerContextValue>(
    () =>
      // set of contexts for drawer, {[code]: {context}}
      ({
        [addLinesPayloadType]: drawerAddLinesContextValues.publicContext,
      }),
    [drawerAddLinesContextValues],
  )

  const pageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handle = (e: Event) => {
      if (!e.isTrusted) {
        return
      }
      triggerWindowResizeSmoothly(300, 10)
    }

    window.addEventListener('resize', handle)

    return () => {
      window.removeEventListener('resize', handle)
    }
  }, [])

  const contentWidth = `calc(100vw - ${
    layoutConfig.sidebar.bar.width +
    (isDiscussionsOpen || isHistoryOpen ? layoutConfig.lateralPanel.width : 0)
  }px)`

  return (
    <AppLayoutContextProvider value={appLayoutContextValue}>
      <StyledAppLayoutRoot data-testid="layout-v2-app-root">
        <DrawerContextProvider value={drawerContexts}>
          <StyledAppLayoutTopbarPlaceholder />
          <StyledAppLayoutMainContainer>
            <StyledAppLayoutLogoContainer>
              <Logo />
            </StyledAppLayoutLogoContainer>
            <StyledAppLayoutSidebarContainer>
              <Sidebar />
            </StyledAppLayoutSidebarContainer>
            <StyledAppLayoutPageContainer ref={pageRef}>
              <StyledAppLayoutPageContent
                sx={{
                  width: contentWidth,
                }}
              >
                {children}
              </StyledAppLayoutPageContent>
            </StyledAppLayoutPageContainer>
            <StyledAppLayoutLateralContainer>
              <LateralPanel isHistoryOpen={isHistoryOpen}>
                {isHistoryOpen ? entityLevelHistory : null}
                <Discussions />
              </LateralPanel>
            </StyledAppLayoutLateralContainer>
          </StyledAppLayoutMainContainer>
          <StyledAppLayoutDrawerContainer
            sx={{
              width: contentWidth,
            }}
          >
            <BottomDrawer pageRef={pageRef}>
              <AddLinesStateContext.Provider
                value={drawerAddLinesContextValues.stateContext}
              >
                <AddLinesDrawer />
              </AddLinesStateContext.Provider>
            </BottomDrawer>
          </StyledAppLayoutDrawerContainer>
        </DrawerContextProvider>
      </StyledAppLayoutRoot>
    </AppLayoutContextProvider>
  )
}
