import { useMemo } from 'react'
import type { VersionMetric } from '../../../../utils/metrics'
import { hasMetricFormula } from './hasMetricFormula'

export function useHasMetricFormula(versions: VersionMetric[]): boolean {
  return useMemo(
    () => versions.some((version) => hasMetricFormula(version)),
    [versions],
  )
}
