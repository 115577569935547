import React, { createContext, useContext, useState } from 'react'
import { useSyncDeeplinkValue } from '@fintastic/web/util/deeplink'

const stub = (): never => {
  throw new Error('MonthOverMonthContext is used out from context')
}

export const MonthOverMonthContext = createContext<MonthOverMonthContextValue>({
  available: false,
  active: false,
  setActive: stub,
  setAvailable: stub,
})

MonthOverMonthContext.displayName = 'MonthOverMonthContext'

export type MonthOverMonthContextValue = {
  available: boolean
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setAvailable: React.Dispatch<React.SetStateAction<boolean>>
}

export const useMonthOverMonthContextValue = (
  deeplinkWidgetId: string,
): MonthOverMonthContextValue => {
  const [active, setActive] = useSyncDeeplinkValue<boolean>({
    key: `w${deeplinkWidgetId}_mom`,
    defaultValue: false,
  })
  const [available, setAvailable] = useState(false)

  return {
    active: active && available,
    available,
    setActive,
    setAvailable,
  }
}

export const useMonthOverMonthContext = () => useContext(MonthOverMonthContext)
