import { createContext, useContext } from 'react'

export type FlagsContextValue = {
  readonly: boolean
}

export const FlagsContext = createContext<FlagsContextValue>({
  readonly: false,
})

export function useFlagsContext() {
  return useContext(FlagsContext)
}
