import React from 'react'
import { StyledRoot, StyledIcon } from './CardHeader.styled'
import { Typography } from '@mui/material'

export const CardHeader: React.FC<{
  icon: React.ReactNode
  title: React.ReactNode
}> = ({ icon, title }) => (
  <StyledRoot>
    <StyledIcon>{icon}</StyledIcon>
    <Typography variant="body1">
      <b>{title}</b>
    </Typography>
  </StyledRoot>
)
