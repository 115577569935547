import { Tooltip, TooltipProps } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React from 'react'
import { StyledInlineTooltip } from './InlineInfoTooltip.styled'

export type InlineTooltipProps = {
  tooltip: string
  placement?: TooltipProps['placement']
}

export const InlineInfoTooltip: React.FC<InlineTooltipProps> = ({
  tooltip,
  placement = 'top',
}) => {
  if (!tooltip) {
    return null
  }
  const offset = placement === 'top' || placement === 'bottom' ? [0, -6] : []

  return (
    <StyledInlineTooltip>
      <Tooltip
        title={tooltip}
        arrow
        disableInteractive
        placement={placement}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: offset,
                },
              },
            ],
          },
        }}
      >
        <InfoOutlinedIcon />
      </Tooltip>
    </StyledInlineTooltip>
  )
}
