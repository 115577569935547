import React, { useCallback, useState } from 'react'
import {
  ReportingGroupsView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

// @todo RESP connect
export const ReportingGroupsViewConnector: React.FC = () => {
  const { goBack, goTo } = useReportSettingsPanelNavigationContext()
  const goToReportingGroup = useCallback(
    (id: string) => {
      goTo(settingsPanelNavigationPaths.reportingGroupSettings(id))
    },
    [goTo],
  )

  const [groups, setGroups] = useState<
    Array<{
      id: string
      label: string
    }>
  >([
    {
      id: '1',
      label: 'Reporting Group 1',
    },
    {
      id: '2',
      label: 'Reporting Group 2',
    },
  ])

  return (
    <ReportingGroupsView.Editable
      goBack={goBack}
      goToReportingGroup={goToReportingGroup}
      groups={groups}
      addReportingGroup={() =>
        setGroups((groups) => [
          ...groups,
          {
            id: `${groups.length}`,
            label: `Reporting Group ${groups.length}`,
          },
        ])
      }
    />
  )
}
