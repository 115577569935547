import { RouteObject } from '@fintastic/shared/util/memory-router'
import { memoryRoutesPaths } from './memory-routes-paths'
import { Metric } from './views/Metric'
import { MetricDimensions } from './views/MetricDimensions'
import { MetricValueDataFormat } from './views/MetricValueDataFormat'
import { MetricAggregationFunction } from './views/MetricAggregationFunction'

export const memoryRoutes: RouteObject[] = [
  {
    path: memoryRoutesPaths.metric,
    element: <Metric />,
  },
  {
    path: memoryRoutesPaths.metricDimensions,
    element: <MetricDimensions />,
  },
  {
    path: memoryRoutesPaths.metricValueDataFormat,
    element: <MetricValueDataFormat />,
  },
  {
    path: memoryRoutesPaths.metricAggregationFunction,
    element: <MetricAggregationFunction />,
  },
]
