import { Box, styled, TextField } from '@mui/material'

export const StyledTextWithIcon = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
    width: 15px;
    color: #263646;
    transition: all ${({ theme }) => theme.transitions.duration.standard}ms
      ${({ theme }) => theme.transitions.easing.easeInOut};
  }
`

export const StyledTextWithIconField = styled(TextField)`
  transition: all ${({ theme }) => theme.transitions.duration.standard}ms
    ${({ theme }) => theme.transitions.easing.easeInOut};

  .Mui-disabled {
    pointer-events: none;

    svg {
      filter: grayscale(1);
      opacity: 0.3;
    }
  }
`
