import {
  CalendarDatePickerConfig,
  isResolutionHigherOrEqual,
} from '@fintastic/web/util/period-selector'
import {
  DimensionId,
  DimensionValueId,
  TimeDimensionId,
  VersionTimeDimension,
} from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { filterItemsAndValues } from './filter'
import { isNullish } from '@fintastic/shared/util/functional-programming'

type AllowedTimeDimsMap = Record<DimensionId, Record<DimensionValueId, boolean>>

export const keepOnlyAllowedTimeResolutions = (
  config: CalendarDatePickerConfig,
  baseTimeDim: Maybe<TimeDimensionId>,
): CalendarDatePickerConfig => {
  if (!baseTimeDim) {
    return config
  }

  const baseResolution = config.find(
    (i) => i.dimension_id === baseTimeDim,
  )?.resolution
  if (!baseResolution) {
    return config
  }

  return config.filter(({ resolution }) =>
    isResolutionHigherOrEqual(resolution, baseResolution),
  )
}

export const keepOnlyAllowedTimeDimensions = (
  config: CalendarDatePickerConfig,
  dims: AllowedTimeDimsMap,
): CalendarDatePickerConfig =>
  filterItemsAndValues(
    config,
    (configItem) => !isNullish(dims[configItem.dimension_id]),
    (value, configItem) =>
      Boolean(dims[configItem.dimension_id][value.dimension_value_id]),
  )

export const makeAllowedTimeDimsMap = (
  allDimensions: VersionTimeDimension[],
): AllowedTimeDimsMap =>
  allDimensions.reduce<AllowedTimeDimsMap>((map, dim) => {
    const subMap: Record<DimensionValueId, boolean> = map[dim.id] ?? {}

    for (let i = 0; i < dim.ordered_values.length; i++) {
      subMap[dim.ordered_values[i]] = true
    }

    map[dim.id] = subMap
    return map
  }, {})
