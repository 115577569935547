import { Channel, PresenceChannel } from 'pusher-js'
import { useEffect, useState } from 'react'
import { usePusher } from './pusher-context'
import { Maybe } from '@fintastic/shared/util/types'

export const usePusherChannel = <T extends Channel & PresenceChannel>(
  channelName: Maybe<string>,
): Maybe<T> => {
  const client = usePusher()
  const [channel, setChannel] = useState<Maybe<T>>(null)

  useEffect(() => {
    if (channelName === null) {
      return
    }
    const pusherChannel = client.subscribe(channelName)
    setChannel(pusherChannel as unknown as T)
    return () => {
      if (client && pusherChannel.callbacks.get(channelName)?.length === 0) {
        client.unsubscribe(channelName)
      }
    }
  }, [channelName, client])

  return channel
}
