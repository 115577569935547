import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { MetricGridRow } from '@fintastic/web/util/metrics-and-lists'
import {
  agGridCellRenderersMap,
  AgGridCustomCellRendererName,
} from '@fintastic/shared/ui/ag-grid'
import { useTotalLineLoadingContext } from './total-line-loading-context'
import { CalculatingIndicator } from './CalculatingIndicator'

export const TotalRowValueColumnCellRenderer: React.FC<
  ICellRendererParams<MetricGridRow> & {
    originalRedererComponent: AgGridCustomCellRendererName
  }
> = (params) => {
  const OriginalRenderer =
    agGridCellRenderersMap[params.originalRedererComponent]
  const isLoading = useTotalLineLoadingContext()

  if (isLoading) {
    return <CalculatingIndicator />
  }

  return <OriginalRenderer {...params} />
}
