import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { BoardDuplicateForm } from '../../forms/BoardDuplicateForm'
import { Board } from '../../../types'
import { Maybe } from '@fintastic/shared/util/types'

export type BoardDuplicateModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  boardId?: Maybe<Board['id']>
}

export const BoardDuplicateModal: React.FC<BoardDuplicateModalProps> = (
  props,
) => {
  const { isOpen, onRequestClose, boardId } = props

  return (
    <Modal
      open={isOpen}
      onRequestClose={onRequestClose}
      title="Duplicate board"
      data-testid="board-duplicate-modal-root"
    >
      {boardId && (
        <BoardDuplicateForm
          closeParentModal={onRequestClose}
          boardId={boardId}
        />
      )}
    </Modal>
  )
}
