import React, { useMemo } from 'react'
import { useListEditorApi } from '../../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../../features/active/makeApi'
import { PanelContainer } from '../shared/PanelContainer'
import { Grid } from '../shared/Grid'
import { useVersionDimensions } from '../shared/useVersionDimensions'
import { useColDefs } from '../shared/useColDefs'
import { FormulasEditor } from '../shared/FormulasEditor'
import { useLoadPaginatedListColumns } from '@fintastic/web/data-access/metrics-and-lists'
import { useRowData } from '@fintastic/web/feature/lists'

export const ExistingListEditor: React.FC = () => {
  const api = useListEditorApi() as ActiveListEditorApi
  const { isLoadingDependencies, list, versionMetadata } = api
  const versionId = versionMetadata?.uuid || ''

  const { dimensions: versionDimensions, isLoading: dimensionsAreLoading } =
    useVersionDimensions()
  const colDefs = useColDefs(versionDimensions)

  const previewColumnsQuery = useLoadPaginatedListColumns({
    versionId: versionId,
    enabled: !!versionMetadata?.uuid,
    listId: list.id,
    columnIds: list.metrics
      .filter((m) => !m.newColumn && m.metadata.display_config.is_visible)
      .map(({ id }) => id),
    page: 1,
    itemsPerPage: 50,
    periodSelection: null,
    filters: { filters: [] },
  })
  const columnsWithData = useMemo(
    () => previewColumnsQuery?.data?.result || [],
    [previewColumnsQuery?.data?.result],
  )

  const rowData = useRowData(
    columnsWithData,
    useMemo(
      () => ({
        versionId,
        rowDimension: list.row_dim,
      }),
      [list.row_dim, versionId],
    ),
  )

  const isLoading =
    isLoadingDependencies ||
    dimensionsAreLoading ||
    previewColumnsQuery.isLoading

  return (
    <PanelContainer isLoading={isLoading} formulaEditor={<FormulasEditor />}>
      {!isLoading && <Grid colDefs={colDefs} rowData={rowData} />}
    </PanelContainer>
  )
}
