import { CreateImportConfigListPayload, ImportConfigItem } from '../types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'

export function updateImportConfig(importConfigItem: ImportConfigItem) {
  return axios.put(
    endpoints.updateImportConfig(
      importConfigItem.target_version_id,
      importConfigItem.type === 'list' ? 'lists' : 'metrics',
      importConfigItem.resource_id,
    ),
    importConfigItem,
  )
}

export function createImportConfig(importConfigItem: CreateImportConfigParams) {
  return axios.post(
    endpoints.updateImportConfig(
      importConfigItem.target_version_id,
      importConfigItem.type === 'list' ? 'lists' : 'metrics',
      importConfigItem.resource_id,
    ),
    importConfigItem.type === 'list'
      ? ({
          date_column_name: importConfigItem.date_column_name,
        } as CreateImportConfigListPayload)
      : undefined,
  )
}

export type CreateImportConfigParams = Pick<
  ImportConfigItem,
  | 'type'
  | 'target_version_id'
  | 'resource_id'
  | 'date_column_name'
  | 'is_enabled'
  | 'is_archived'
>

export function deleteImportConfig(
  importConfigItem: Pick<
    ImportConfigItem,
    'target_version_id' | 'type' | 'resource_id'
  >,
) {
  return axios.delete(
    endpoints.updateImportConfig(
      importConfigItem.target_version_id,
      importConfigItem.type === 'list' ? 'lists' : 'metrics',
      importConfigItem.resource_id,
    ),
  )
}

export function getImportConfigs(versionId: string) {
  return axios.get<{
    result: ImportConfigItem[]
  }>(endpoints.importConfigs(versionId))
}
