import { ExtendedColumn, getTypeAndParams } from './utils'
import { CellData } from './data-values'
import { ExportMode } from '../types'

export type HeaderCell = {
  label: string
  type: string
  formattingParams?: unknown
  isGroupingHeader: boolean
  calculated?: boolean
  associatedLevel?: number
  colIndex?: number
  hexColor?: string
}
export type ColumnsHeaderRows = {
  data: HeaderCell[][]
  headerStackDepth: number
  groupColumnIdx: number
}

export function getColumnsHeaderRows(
  cols: ExtendedColumn[],
): ColumnsHeaderRows {
  let headerStackDepth = 0
  const groupColumnIdx = cols.findIndex(
    (col) => col._colDef.showRowGroup === true,
  )
  const data = cols
    .filter((col) => !col._colDef.showRowGroup)
    .map((col, idx): HeaderCell[] => {
      const color =
        col._userColDef?.headerComponentParams?.contentBeforeMenu?.props
          ?.currentColumnColumn?.hexColor
      const isCalculated =
        col._userColDef?.headerComponentParams?.isCalculated || false
      const label =
        (col._userColDef?.headerName || col._colDef?.headerName || '') +
        (isCalculated ? ' (calculated)' : '')
      const colHeaders: HeaderCell[] = [
        {
          label,
          ...getTypeAndParams(col),
          calculated: isCalculated,
          isGroupingHeader: false,
          hexColor: color,
          colIndex: idx < groupColumnIdx ? idx : idx + 1, // <- only one column can be a group column
        },
      ]
      // if nested records (multiline header)
      // @todo: row/col span (?)
      if (col.getOriginalParent()?.getColGroupDef()) {
        let curLevel = col.getOriginalParent()
        while (curLevel) {
          const isCalculated =
            curLevel.getColGroupDef()?.headerGroupComponentParams
              ?.isCalculated || false
          colHeaders.unshift({
            label:
              (curLevel.getColGroupDef()?.headerName || '') +
              (isCalculated ? ' (calculated)' : ''),
            calculated: isCalculated,
            type: 'generic',
            isGroupingHeader: false,
            hexColor: color,
            colIndex: idx < groupColumnIdx ? idx : idx + 1,
          })
          curLevel = curLevel.getOriginalParent()
        }
      }
      // extra consistency check
      //  Jan 2015    Feb 2015      <nothing>
      //  Actual      Actual        Actual
      if (headerStackDepth === 0) {
        headerStackDepth = colHeaders.length
      }
      if (colHeaders.length !== headerStackDepth) {
        throw new Error('Inconsistent headers structure!')
      }
      return colHeaders
    })
  return {
    data,
    groupColumnIdx,
    headerStackDepth,
  }
}

export type ExtendHeadersWithGroupHeadersParams = {
  columnHeaders: HeaderCell[][]
  groupHeaders: HeaderCell[]
  groupColumnIdx: number
  headerStackDepth: number
}

export function extendHeadersWithGroupHeaders({
  columnHeaders,
  groupHeaders,
  groupColumnIdx,
  headerStackDepth,
}: ExtendHeadersWithGroupHeadersParams): HeaderCell[][] {
  // header (multi-column)
  // [Jan 2015] [Total 2015]
  // [Actuals]  [Actuals]
  // group header (single column)
  // [Group] [SubGroup] [SubSubGroup]
  // result (group is on the first position)
  // [     ] [        ] [           ] [Jan 2015] [Total 2015]
  // [Group] [SubGroup] [SubSubGroup] [Actuals]  [Actuals]
  // result (group is on the last position)
  // [Jan 2015] [Total 2015] [     ] [        ] [           ]
  // [Actuals]  [Actuals]    [Group] [SubGroup] [SubSubGroup]
  const finalHeaders: HeaderCell[][] = []
  const colCount = Math.max(columnHeaders.length, groupColumnIdx + 1)
  const rowCount = Math.max(headerStackDepth, 1)
  for (let colIdx = 0; colIdx < colCount; colIdx++) {
    for (let rowIdx = 0; rowIdx < rowCount; rowIdx++) {
      if (!finalHeaders[rowIdx]) {
        finalHeaders[rowIdx] = []
      }
      if (colIdx === groupColumnIdx) {
        for (let i = 0; i < groupHeaders.length; i++) {
          if (rowIdx === rowCount - 1) {
            finalHeaders[rowIdx].push({
              ...groupHeaders[i],
              associatedLevel: i,
            })
          } else {
            finalHeaders[rowIdx].push({
              label: '',
              type: 'stub',
              isGroupingHeader: true,
              associatedLevel: i,
            })
          }
        }
      }
      if (colIdx < columnHeaders.length) {
        finalHeaders[rowIdx].push(columnHeaders[colIdx][rowIdx])
      }
    }
  }
  return finalHeaders
}

export type FillDatasetWithHeadersParams = {
  rawDataSet: CellData[][]
  dataTable: Array<HeaderCell[] | CellData[]>
  baseHeaders: ColumnsHeaderRows
  groupHeaders: HeaderCell[]
  mode: ExportMode
}

export function fillDatasetWithHeaders({
  rawDataSet,
  dataTable,
  baseHeaders,
  groupHeaders,
  mode,
}: FillDatasetWithHeadersParams) {
  const lastHeaderLine = dataTable[dataTable.length - 1]
  const colCount = lastHeaderLine.length
  const groupLength = groupHeaders.length

  const isCurrentMode = mode === 'current' || mode === 'current-with-comments'

  rawDataSet.forEach((rowData, rowIdx) => {
    const mappedRow: CellData[] = []

    for (let colIdx = 0; colIdx < colCount; colIdx++) {
      if (colIdx < baseHeaders.groupColumnIdx) {
        // before group, col col | col grpcol grpcol grpcol col col
        mappedRow[colIdx] = rowData[colIdx]
      }
      if (colIdx >= baseHeaders.groupColumnIdx) {
        if (colIdx < baseHeaders.groupColumnIdx + groupLength) {
          // inside group, col col col grpcol | grpcol grpcol col col
          const inGrpIdx =
            baseHeaders.groupColumnIdx +
            rowData[baseHeaders.groupColumnIdx].level
          if (colIdx === inGrpIdx) {
            mappedRow[colIdx] = rowData[baseHeaders.groupColumnIdx]
          } else {
            // stub value with the same formatting
            if (isCurrentMode) {
              mappedRow[colIdx] = {
                ...rowData[baseHeaders.groupColumnIdx],
                value: undefined,
                rawValue: undefined,
              }
            } else {
              // mapped flat titles
              const groupTitle =
                rowData[0]?.flatColumns?.[
                  colIdx - baseHeaders.groupColumnIdx
                ] ||
                rowData[baseHeaders.groupColumnIdx].value ||
                '-unknown-'
              mappedRow[colIdx] = {
                ...rowData[baseHeaders.groupColumnIdx],
                type: 'generic',
                value: groupTitle,
                rawValue: groupTitle,
              }
            }
          }
        } else {
          // outside group, col col col grpcol grpcol grpcol col | col
          mappedRow[colIdx] = rowData[colIdx - groupLength + 1]
        }
      }
    }
    dataTable.push(mappedRow)
  })
}
