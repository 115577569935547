import { Maybe } from '@fintastic/shared/util/types'
import { useMemo } from 'react'
import {
  GenericReportId,
  GenericReportNormalised,
  Report,
} from '@fintastic/web/util/generic-report'
import { QueryKey, useQueries } from 'react-query'
import { getReportAggregatesAPI } from '../api'
import { buildReportNormalised } from '../logic/buildReportNormalisedList'
import { compact } from 'lodash'
import { reportQueryKeyBase } from '@fintastic/web/util/generic-report'
import {
  PeriodSelection,
  usePeriodSelectorContext,
} from '@fintastic/web/util/period-selector'
import { AxiosError } from 'axios'

export type GenericReportsAggregatesQueriesKeyGetterParams = {
  reportOrVersionId?: Maybe<GenericReportId>
  periodSelection: PeriodSelection
  category?: string
  includeTotalsColumn?: boolean
}

export function genericReportsAggregatesQueriesKeyGetter({
  reportOrVersionId,
  category,
  periodSelection,
  includeTotalsColumn,
}: GenericReportsAggregatesQueriesKeyGetterParams): QueryKey {
  return compact([
    ...reportQueryKeyBase(reportOrVersionId || ''),
    category,
    'aggregates',
    JSON.stringify(periodSelection),
    includeTotalsColumn,
  ])
}

export type UseLoadNormalisedReportsListParams = {
  reportsOrVersionsIds: Maybe<GenericReportId[]>
  category: string
  includeTotalsColumn: boolean
}

type QueryError = AxiosError | Error

export function useLoadNormalisedReportsList({
  reportsOrVersionsIds,
  category,
  includeTotalsColumn,
}: UseLoadNormalisedReportsListParams) {
  const periodSelection = usePeriodSelectorContext()

  const queries = useQueries(
    (reportsOrVersionsIds || []).map((reportOrVersionId) => ({
      queryKey: genericReportsAggregatesQueriesKeyGetter({
        reportOrVersionId,
        periodSelection,
        category,
        includeTotalsColumn,
      }),
      queryFn: async () => {
        const response = await getReportAggregatesAPI(
          reportOrVersionId,
          periodSelection,
          category,
          includeTotalsColumn,
        )

        const report: Report = {
          reportOrVersionId,
          ...response.data,
        }

        return buildReportNormalised(report)
      },
      refetchInterval: false as const,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    })),
  )

  const loadingCount = queries.filter((query) => query.isLoading).length
  const memoKey = queries
    .map(
      (query, queryIndex) =>
        `${(reportsOrVersionsIds || [])[queryIndex]}-${JSON.stringify(
          periodSelection,
        )}-${query.dataUpdatedAt}`,
    )
    .join(';')

  const memorized = useMemo(
    () => {
      if (loadingCount !== 0) {
        return {
          isLoading: true,
          results: [] as {
            versionId: GenericReportId
            error: QueryError | undefined
            data: GenericReportNormalised | undefined
          }[],
        }
      }

      return {
        isLoading: false,
        // Use old cached queries to prevent rendering per one version.
        // Render in bulk when everything is loaded
        results: compact(
          reportsOrVersionsIds?.map((versionId, queryIndex) => {
            const query = queries[queryIndex]
            if (!query) {
              return null
            }
            return {
              versionId,
              error: query.error as QueryError | undefined,
              data: query.data,
            } as const
          }),
        ),
      } as const
    },
    // useQueries returns a new array on each render
    // that's why we need to wrap it into useMemo and provide custom memo keys
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingCount, memoKey],
  )

  return useMemo(
    () => ({
      ...memorized,
      isFetching: !!queries.find((q) => q.isFetching || q.isRefetching),
    }),
    [memorized, queries],
  )
}
