import { wrapSvgStringIcon } from './wrapSvgStringIcon'
import { IconDefinition } from './types'

const name = 'columns' as const

const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68005 2.62448C4.07528 2.62936 4.26433 2.62597 4.07124 2.67119C3.60808 2.77964 3.20019 3.06503 2.93241 3.468C2.78865 3.68433 2.68648 3.95365 2.64256 4.232C2.62862 4.32039 2.62805 4.62506 2.62805 12C2.62805 19.3777 2.62862 19.6796 2.64258 19.7677C2.74092 20.3886 3.11099 20.8984 3.66405 21.1749C3.84896 21.2674 4.02454 21.3223 4.24805 21.3575C4.3364 21.3714 4.64355 21.372 12.0001 21.372C19.3566 21.372 19.6637 21.3714 19.7521 21.3575C20.1238 21.2989 20.4314 21.1616 20.7114 20.9292C20.9471 20.7336 21.1502 20.4447 21.2597 20.1491C21.297 20.0485 21.3389 19.8851 21.3575 19.7677C21.3715 19.6796 21.3721 19.3778 21.3721 12.004C21.3721 5.73495 21.3701 4.31883 21.3616 4.26C21.3099 3.90322 21.1749 3.58785 20.9592 3.32001C20.8854 3.22833 20.7319 3.07786 20.6438 3.01081C20.3631 2.79724 20.0361 2.66715 19.6921 2.63215C19.5919 2.62196 10.7962 2.61678 6.68005 2.62448ZM4.40112 3.88126C4.27792 3.89889 4.15757 3.96097 4.06371 4.05533C3.97093 4.1486 3.91546 4.24623 3.8918 4.35787C3.88188 4.40465 3.88031 5.44186 3.88019 12.0007C3.88005 19.5456 3.88014 19.5898 3.89594 19.6527C3.92108 19.7528 3.96924 19.8397 4.04242 19.9171C4.11272 19.9913 4.18622 20.0439 4.26324 20.0751C4.38086 20.1227 4.24756 20.12 6.44372 20.12H8.45605V11.996V3.872L6.45405 3.87298C5.29823 3.87356 4.43052 3.87705 4.40112 3.88126ZM9.71205 11.996V20.12H12.0001H14.2881V11.996V3.872H12.0001H9.71205V11.996ZM15.5441 11.996V20.12L17.5581 20.1199C19.2939 20.1197 19.5798 20.1181 19.6281 20.108C19.8567 20.0603 20.0457 19.8784 20.1062 19.648L20.1241 19.58V12C20.1241 4.89721 20.1232 4.41634 20.1105 4.36193C20.0825 4.24255 20.0292 4.14867 19.9364 4.05533C19.8391 3.95757 19.7232 3.89922 19.5895 3.88068C19.5459 3.87463 18.9228 3.872 17.5355 3.872H15.5441V11.996Z"/>
</svg>`

export const columnsIcon: IconDefinition<typeof name> = {
  name,
  makeIcon: (attributes) => wrapSvgStringIcon(name, svg, attributes),
}
