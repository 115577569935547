import { useValidationHighlightContext } from './validation-highlight-context'
import { useMemo } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { getRangeHash } from './getRangeHash'
import type { TokenWithNode } from '../tokens/types'

// @todo add tests

export function useTokenValidation(
  start?: number,
  stop?: number,
): {
  error: Maybe<string>
  handleHover: Maybe<(element: Maybe<TokenWithNode>) => void>
} {
  const { ranges, enabled, setHighlightingToken } =
    useValidationHighlightContext()

  return useMemo(
    () => ({
      error: enabled ? ranges[getRangeHash(start, stop)] || null : null,
      handleHover: enabled ? setHighlightingToken : null,
    }),
    [enabled, ranges, setHighlightingToken, start, stop],
  )
}
