import { OutlinedInput, Paper, Popover, styled } from '@mui/material'

export const StyledInputRoot = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;

  span.tooltip-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`

export const StyledInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== 'inFormula',
})<{ inFormula?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 0;

  input[type='text'].MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    border-style: ${({ inFormula }) =>
      inFormula === true ? 'none !important' : 'solid'};

    color: ${({ inFormula }) => (inFormula === true ? '#2C45FE' : 'inherit')};
  }

  fieldset {
    border-style: ${({ inFormula }) =>
      inFormula === true ? 'none !important' : 'solid'};
  }

  border: ${({ inFormula }) =>
    inFormula === true ? '1px solid #2C45FE' : 'none'};

  &.Mui-error {
    color: ${({ theme }) => theme.palette.error.main};

    border: ${({ inFormula, theme }) =>
      inFormula === true ? '1px solid ' + theme.palette.error.main : 'none'};
  }
`

export const StyledErrorPopover = styled(Popover)`
  margin-top: 12px;
  z-index: 10;

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    overflow: visible;
  }
`

export const StyledErrorPopoverPaper = styled(Paper)`
  position: relative;

  span.arrow-top {
    z-index: 10;
    position: absolute;
    top: -7px;
    left: 32px;
    transform: translateX(-50%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(0, 0, 0, 0.1);

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 0;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
  }
`

export const StyledErrorActions = styled('div')`
  padding: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`
