import { Box, styled } from '@mui/material'
import { WIDGETS_ANIMATION_SPEED_MS } from '../../const'
import { layoutConfig } from '@fintastic/shared/ui/app-layout-framework'

export const StyledWidgetsAreaRoot = styled(Box)`
  &.design-mode {
    .react-grid-item:after {
      display: block;
      content: '';
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      position: absolute;
      z-index: -1;
      opacity: 0.2;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.primary.main};
    }

    .handle-se:after {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    .react-grid-item.resizing .board-design-widget-menu:after {
      opacity: 1;
    }

    .react-grid-item.resizing:after {
      opacity: 1;
    }

    & [data-testid='topbar-container'] {
      padding-right: 16px;
    }
  }
`

const SUPPOSED_BOARD_HEADER_HEIGHT = 40 + 24

export const StyledWidgetsAreaSingleRoot = styled(Box)`
  height: calc(
    100vh - ${layoutConfig.topbar.height}px - ${layoutConfig.page.paddingTop}px -
      ${layoutConfig.page.paddingBottom}px - ${SUPPOSED_BOARD_HEADER_HEIGHT}px
  );

  &.design-mode {
    .widget-wrapper {
      position: relative;
    }

    .widget-wrapper:after {
      display: block;
      content: '';
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      position: absolute;
      z-index: 0;
      pointer-events: none;
      opacity: 0.2;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.primary.main};
    }

    & [data-testid='topbar-container'] {
      padding-right: 16px;
    }
  }
`

export const StyledWidgetsAreaWrapper = styled(Box)``

export const StyledWidgetsAreaContainer = styled(Box)`
  width: 100%;

  &.disable-user-selection * {
    user-select: none;
  }

  .react-grid-item.react-grid-placeholder {
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    background: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.3;
    transition: all 100ms;
  }

  .react-grid-item {
    transition: all ${WIDGETS_ANIMATION_SPEED_MS}ms;

    &.resizing,
    &.react-draggable-dragging {
      transition: none;
    }
  }
`
