import { VersionDateRangeInput } from './VersionDateRangeInput'
import React from 'react'
import { UseVersionDateRangeInputStateResult } from './useVersionDateRangeInputState'
import { Dayjs } from 'dayjs'

export type VersionDateRangFormProps = {
  inputState: UseVersionDateRangeInputStateResult
  horizontalLayout?: boolean
  fullWidth?: boolean
  minEndDate?: Dayjs
  maxEndDate?: Dayjs
  disabled?: boolean
}

export const VersionDateRangeForm: React.FC<VersionDateRangFormProps> = ({
  inputState,
  horizontalLayout,
  fullWidth,
  minEndDate,
  maxEndDate,
  disabled,
}) => (
  <VersionDateRangeInput
    {...inputState}
    horizontalLayout={horizontalLayout}
    fullWidth={fullWidth}
    minEndDate={minEndDate}
    maxEndDate={maxEndDate}
    disabled={disabled}
  />
)
