import React from 'react'
import {
  StyledRoot,
  StyledLabel,
  StyledContent,
} from './CardValueSection.styled'
import { CardSectionLabel } from './CardSectionLabel'

export const CardValueSection: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <StyledRoot>
    <StyledLabel>
      <CardSectionLabel>Value</CardSectionLabel>
    </StyledLabel>
    <StyledContent>{children}</StyledContent>
  </StyledRoot>
)
