import React from 'react'
import {
  ReportingGroupSettingsView,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

// @todo RESP connect
export const ReportingGroupSettingsViewConnector: React.FC = () => {
  const { goBack } = useReportSettingsPanelNavigationContext()

  return (
    <ReportingGroupSettingsView.Readonly
      goBack={goBack}
      group={{
        id: '1',
        label: 'Reporting Group 1',
        aggregation: 'sum',
        weighedMetric: null,
        dataType: 'currency',
        currency: 'USD',
      }}
    />
  )
}
