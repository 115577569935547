import { useMemo } from 'react'
import { useFilterContext } from './useFilterContext'
import { filterUtils } from '../utils'

export const useFilterContextApiPayload = () => {
  const filterContext = useFilterContext()

  if (!filterContext) {
    throw new Error('useFilterContextApiPayload is dependant on FilterContext')
  }

  return useMemo(
    () => filterUtils.mapFiltersToApiPayload(filterContext.appliedModel),
    [filterContext.appliedModel],
  )
}
