import {
  useFeatureConfig,
  useIsFeatureEnabled,
} from '@fintastic/web/feature/config'
import { useCallback, useMemo } from 'react'
import {
  BaseGridColumnId,
  BaseGridContextProp,
} from '@fintastic/shared/ui/grid-framework'
import {
  getNetsuiteEntityUrl,
  getNetsuiteTransactionUrl,
  getSalesforceAccountUrl,
} from '@fintastic/shared/util/external-platforms'
import { ICellRendererParams } from 'ag-grid-community'

export function useGridContextProp(
  context?: BaseGridContextProp,
): BaseGridContextProp {
  const salesforceCustomId = useFeatureConfig('salesforce_customer_id')?.value
  const netsuiteCustomerId = useFeatureConfig('netsuite_customer_id')?.value

  const linkResolver = useCallback(
    (dataType: BaseGridColumnId, value: any, params: ICellRendererParams) => {
      switch (dataType) {
        case 'salesforce_account':
          return getSalesforceAccountUrl(salesforceCustomId, value) || ''
        case 'netsuite_account':
          return getNetsuiteEntityUrl(netsuiteCustomerId, value) || ''
        case 'netsuite_transaction_id':
          return (
            getNetsuiteTransactionUrl(
              netsuiteCustomerId,
              params.node.data?.[
                context?.linkResolverNetsuiteTransactionTypeFieldName || ''
              ] || '',
              value,
            ) || ''
          )
      }
      console.warn('Unsupported ID type in link resolver')
      return ''
    },
    [
      salesforceCustomId,
      netsuiteCustomerId,
      context?.linkResolverNetsuiteTransactionTypeFieldName,
    ],
  )

  const allowGrouping = useIsFeatureEnabled('allow_grouping_in_lists')

  return useMemo(
    () => ({
      ...context,
      linkResolver,
      allowGrouping,
    }),
    [context, allowGrouping, linkResolver],
  )
}
