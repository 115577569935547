import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete, TextField, AutocompleteProps, FilterOptionsState } from '@mui/material'
import { UserPanel } from '../UserPanel'
import { Maybe } from '@fintastic/shared/util/types'
import { WithRequired } from '@fintastic/shared/util/types'
import { Box } from '@mui/material'
import { ArrowBottomIcon } from '@fintastic/shared/ui/icons'
import {
  StyledUserSelectPopper,
  StyledUserSelectRoot,
} from './UserSelect.styled'
import { AutocompleteInputChangeReason, AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'
import { matchSorter } from 'match-sorter'

export type UserSelectOption = {
  name: string
  email: string
  avatarSrc?: string
}
export type UserSelectProps = {
  id?: string
  options: UserSelectOption[]
  value: Maybe<string>
  disabled?: boolean
  onChange: (selectedEmail: Maybe<string>) => void
  label?: string
}
export const UserSelect: React.FC<UserSelectProps> = ({
  id,
  options,
  value,
  onChange,
  disabled,
  label,
}) => {
  const [inputValue, setInputValue] = useState('')
  const selectedOption = useMemo(
    () => options.find((o) => o.email === value),
    [options, value],
  )
  useEffect(() => {
    if (value === null) {
      setInputValue('')
    }
  }, [value])
  const handleChange = useCallback<
    WithRequired<
      AutocompleteProps<UserSelectOption, undefined, undefined, undefined>,
      'onChange'
    >['onChange']
  >(
    (event, value) => {
      onChange(value?.email || null)
    },
    [onChange],
  )

  const handleChangeInput = useCallback(
    (
      event: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      setInputValue(value)
    },
    [],
  )

  const filterOptions = (
    options: UserSelectOption[],
    { inputValue }: FilterOptionsState<UserSelectOption>,
  ) => matchSorter(options, inputValue, { keys: ['name', 'email'] })

  return (
    <StyledUserSelectRoot>
      <Autocomplete<UserSelectOption, undefined, undefined, undefined>
        id={id}
        options={options}
        value={selectedOption || null}
        onChange={handleChange}
        disabled={disabled}
        size="small"
        fullWidth
        filterOptions={filterOptions}
        popupIcon={<ArrowBottomIcon fontSize="small" />}
        PopperComponent={StyledUserSelectPopper}
        inputValue={inputValue}
        onInputChange={handleChangeInput}
        getOptionLabel={useCallback(
          (option: UserSelectOption) => option.email,
          [],
        )}
        renderOption={useCallback(
          (
            optionProps: React.HTMLAttributes<HTMLLIElement>,
            option: UserSelectOption,
            state: AutocompleteRenderOptionState,
          ) => (
            <Box component="li" {...optionProps}>
              <UserPanel
                avatarSrc={option.avatarSrc}
                name={option.name}
                email={option.email}
              />
            </Box>
          ),
          [],
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            label={label}
          />
        )}
      />
    </StyledUserSelectRoot>
  )
}
