import React from 'react'
import {
  MetricWidgetForm,
  useWidgetSettingsDialogContext,
} from '@fintastic/web/feature/boards'
import {
  ChartThemeSelect,
  ChartTypeSelect,
  defaultChartTheme,
  defaultChartType,
  useChartSettingsEditContext,
} from '@fintastic/web/feature/charts'
import { StyledSubtitle, StyledTopStub } from './DialogSectionGeneral.styled'

export const DialogSectionGeneral: React.FC = () => {
  const { localSettings, extraPayload, handleSettingsChange } =
    useChartSettingsEditContext()

  const { isNew } = useWidgetSettingsDialogContext()

  return (
    <>
      {isNew && !extraPayload?.navigationAllowed ? (
        <StyledSubtitle variant={'body1'}>General</StyledSubtitle>
      ) : (
        <StyledTopStub />
      )}

      <MetricWidgetForm />

      <ChartTypeSelect
        chartType={localSettings.type || defaultChartType}
        onChangeChartSettings={handleSettingsChange}
      />

      <ChartThemeSelect
        chartTheme={localSettings.theme || defaultChartTheme}
        onChangeChartSettings={handleSettingsChange}
      />
    </>
  )
}
