import {
  ParsedColumnId,
  ParsedListId,
  ParsedMetricId,
} from '@fintastic/web/util/metrics-and-lists'
import { titleFormatter } from '@fintastic/shared/util/formatters'

// will be repleaced with labels from BE
export const resolveMetricOrListLabelFromId = (id: string): string =>
  titleFormatter(
    ParsedMetricId.fromString(id)?.idWithoutPrefix ||
      ParsedListId.fromString(id)?.idWithoutPrefix ||
      ParsedColumnId.fromString(id)?.columnIdWithoutPrefix ||
      id,
  )
