import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;

  svg {
    display: inline-flex;
    margin-left: auto;
    width: 16px;
    height: 16px;
  }
`
