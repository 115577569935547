import { NavigationApi } from '../navigation/types'
import { KeyEventInterceptor } from '../../../formula-input/types'
import { useCallback, useMemo, useRef } from 'react'
import { interceptKeyUpEvent } from './keyboard'

export function useKeyboard(enabled: boolean, navigationApi: NavigationApi) {
  // for better performance - we don't create an interceptor function every render
  // so, we use refs to keep values actual

  const enabledRef = useRef(enabled)
  enabledRef.current = enabled

  const navigationApiRef = useRef(navigationApi)
  navigationApiRef.current = navigationApi

  const keyDown: KeyEventInterceptor = useCallback(
    (event) =>
      interceptKeyUpEvent(enabledRef.current, navigationApiRef.current, event),
    [],
  )

  return useMemo(
    () => ({
      keyDown,
    }),
    [keyDown],
  )
}
