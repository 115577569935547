import React, { memo } from 'react'
import {
  StyledChartDebugPanel,
  StyledDebugButton,
} from './ChartDebugPanel.styled'
import { ChartTypeBarsIcon } from '../../../../icons/ChartTypeBarsIcon'
import { ChartDebugDisplayMode } from '../../../../../types'
import { WidgetMetricIcon } from '@fintastic/shared/ui/icons'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'

export type ChartDebugPanelProps = {
  open: boolean
  mode: ChartDebugDisplayMode
  onSetMode: (mode: ChartDebugDisplayMode) => void
}

export const ChartDebugPanel: React.FC<ChartDebugPanelProps> = memo(
  ({ mode, onSetMode, open }) => {
    if (!open) {
      return null
    }

    return (
      <StyledChartDebugPanel>
        <StyledDebugButton
          size="small"
          active={mode === 'chart'}
          onClick={() => onSetMode('chart')}
        >
          <ChartTypeBarsIcon />
        </StyledDebugButton>

        <StyledDebugButton
          size="small"
          active={mode === 'table'}
          onClick={() => onSetMode('table')}
        >
          <WidgetMetricIcon />
        </StyledDebugButton>

        <StyledDebugButton
          size="small"
          active={mode === 'debug'}
          onClick={() => onSetMode('debug')}
        >
          <DataObjectOutlinedIcon />
        </StyledDebugButton>
      </StyledChartDebugPanel>
    )
  },
)
