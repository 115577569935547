import {
  VersionCategoryDimension,
  VersionDimension,
  VersionTimeDimension,
} from '../types'
import * as selectors from './common-selectors'

export const isTimeDimension = (
  d: VersionDimension,
): d is VersionTimeDimension => selectors.type(d) === 'Time'

export const isCategoryDimension = (
  d: VersionDimension,
): d is VersionCategoryDimension => selectors.type(d) === 'Category'
