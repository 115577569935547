import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ColDef } from 'ag-grid-community'
import {
  BlankOrMaskedValue,
  stringifyBlankOrMaskedValue,
} from '@fintastic/web/util/blanks-and-masked'
import { BaseGridRow } from '../../base-grid/types'
import {
  baseGridContext,
  ToolbarRowsActionFn,
} from '@fintastic/shared/ui/grid-framework'

export type UseRowsActionButtonParams<T extends BaseGridRow> = {
  onClick: ToolbarRowsActionFn<T>
}

export function useRowsActionButton<T extends BaseGridRow>({
  onClick,
}: UseRowsActionButtonParams<T>) {
  const [rowsSelected, setRowsSelected] = useState(false)
  const gridContext = React.useContext(baseGridContext)
  const gridApiRef = React.useRef(gridContext.gridApi)
  const [ready, setReady] = useState(false)

  const destroying = useRef(false)

  useEffect(() => {
    destroying.current = false
    return () => {
      destroying.current = true
    }
  }, [])

  useEffect(() => {
    gridApiRef.current = gridContext.gridApi
    const gridApi = gridApiRef.current
    if (gridApi) {
      setReady(true)
      gridApi.addEventListener('rowSelected', () => {
        if (!destroying.current && gridContext.gridApi) {
          setRowsSelected(gridApi.getSelectedRows()?.length > 0)
        }
      })
      if (!destroying.current && gridContext.gridApi) {
        // check now, maybe some are already selected?
        setRowsSelected(gridApi.getSelectedRows()?.length > 0)
      }
    }
  }, [gridContext])

  const resetSelected = useCallback(() => {
    const gridApi = gridApiRef.current
    if (gridApi) {
      gridApi.deselectAll()
    }
  }, [])

  const handleClick = useCallback(async () => {
    const gridApi = gridApiRef.current
    if (!gridApi) {
      console.error('clicked before Grid is ready')
      return
    }

    const selectedRows = gridApi.getSelectedRows()
    if (selectedRows.length === 0) {
      throw new Error('clicked on action button, no selected rows')
    }
    const modifiedRows = selectedRows.map((row: T) => {
      const modifiedRow = { ...row }
      gridApi
        .getColumnDefs()
        ?.filter((c: ColDef) => c.type === 'numericColumn')
        .map((c: ColDef) => c.field)
        .forEach((field) => {
          // fix default value if numeric cell contains an empty string
          if (
            field !== undefined &&
            `${stringifyBlankOrMaskedValue(
              modifiedRow[field] as BlankOrMaskedValue,
            )}`.trim() === ''
          ) {
            ;(modifiedRow as Record<string, unknown>)[field] = 0
          }
        })

      return modifiedRow
    })

    try {
      const response = await onClick(modifiedRows)
      if (response) {
        resetSelected()
      }
    } catch (error) {
      console.error('error in RowsActionButton', error)
    }
  }, [resetSelected, onClick])

  return useMemo(
    () => ({
      rowsSelected,
      setRowsSelected,
      ready,
      resetSelected,
      handleClick,
    }),
    [handleClick, ready, resetSelected, rowsSelected],
  )
}
