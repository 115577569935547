export const versionsCacheKeys = {
  versionPrefix: (id: string) => ['version', id],
  versionsList: (
    showArchived?: boolean,
    showDeleted?: boolean,
    withLiveActuals?: boolean,
    versionsIds?: string[],
  ) => [
    'versionsList',
    encodeURIComponent(!!showArchived),
    encodeURIComponent(!!showDeleted),
    encodeURIComponent(!!withLiveActuals),
    versionsIds?.join(';') || '-',
  ],
  versionData: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'versionData',
  ],
  versionEntities: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'versionEntities',
  ],
  versionDependencyGraph: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'versionDependencyGraph',
  ],
  versionAccess: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'versionAccess',
  ],
  versionUserLock: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'versionUserLock',
  ],
} as const

// note: should invalidate keys taking into account versionIds, but till this
// invocation can happen only on version page - it has no sense
// https://stackoverflow.com/questions/77466721/is-it-possible-to-setquerydata-using-inclusive-query-key

export const versionsListRefreshKeys: any[] = []
// fill array with all possible keys (without version)
;[true, false].forEach((showArchived) => {
  [true, false].forEach((showDeleted) => {
    [true, false].forEach((withLiveActuals) => {
      versionsListRefreshKeys.push(
        versionsCacheKeys.versionsList(
          showArchived,
          showDeleted,
          withLiveActuals,
        ),
      )
    })
  })
})
