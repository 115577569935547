import React, { useMemo } from 'react'
import type { Version as FormulaEditorVersion } from '@fintastic/web/feature/formulas'
import type { VersionTable, VersionTableReport } from '../../types'
import { FormulaEditor } from '@fintastic/web/feature/formulas'

export type VersionListFormulaEditorProps = {
  table: Exclude<VersionTable, VersionTableReport>
  versionId: string
  versionLocked?: boolean
  onRequestClose: () => void
  formulaEditorTitle?: string
}

export const VersionListFormulaEditorWrapper: React.FC<
  VersionListFormulaEditorProps
> = ({
  table,
  versionId,
  onRequestClose,
  versionLocked,
  formulaEditorTitle,
}) => {
  const versions = useMemo<FormulaEditorVersion[]>(
    () => [
      {
        title: '', // no matter, we don't show it
        id: versionId,
        formula: table.metadata?.formula || '',
        locked: versionLocked,
        connectedTable: {
          id: table.id,
        },
      },
    ],
    [table.metadata?.formula, versionId, versionLocked, table.id],
  )

  if (
    table.metadata?.formula === undefined ||
    table.metadata?.formula === null
  ) {
    return null
  }

  return (
    <FormulaEditor
      title={formulaEditorTitle || 'Forecast formula'}
      versions={versions}
      onRequestClose={onRequestClose}
      showVersionsSelector={false}
      readonly={true}
      controlledEditingMode={true}
      showCloseButtonInControlledMode={true}
    />
  )
}
