import { axios } from '@fintastic/web/data-access/service-axios'
import { liveActualsEndpoints as endpoints } from './endpoints'
import { Version } from '@fintastic/web/util/versions'

export async function getLiveActualsStatus(id: string) {
  return axios.get<boolean>(endpoints.status(id), {
    transformResponse: (d) => {
      const version = JSON.parse(d).version as Version
      return version.is_up_to_date
    },
  })
}
