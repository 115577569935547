import { CheckboxesListOption } from '@fintastic/shared/ui/components'
import { Dimension } from '@fintastic/shared/util/types'

export function dimensionsToCheckboxesOptions(
  dimensions: Dimension[],
): CheckboxesListOption[] {
  return dimensions.map((dim) => ({
    label: dim.label,
    value: dim.id,
  }))
}
