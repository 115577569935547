import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo, useMemo } from 'react'
import { getChartThemeName } from '@fintastic/web/feature/charts'

export const DialogSectionGeneralSubtitle: React.FC = memo(() => {
  const { data, isNew } = useWidgetSettingsDialogContext()

  const title = useMemo(() => {
    if (!data.versionId) {
      return 'Please select a version'
    }

    if (!data.id) {
      return 'Please select a metric'
    }

    const chartType =
      data.chartSettings?.type === 'bars' ? 'Bar chart' : 'Line chart'

    const theme = getChartThemeName(data.chartSettings?.theme)

    const displayLabel = data.displayLabel

    return `${displayLabel || 'Metric chart'}, ${chartType}, ${theme}`
  }, [
    data.chartSettings?.theme,
    data.chartSettings?.type,
    data.displayLabel,
    data.id,
    data.versionId,
  ])

  return <span>{title}</span>
})
