import {
  MetricDisplaySettings,
  MetricDisplaySettingsFormatting,
} from '@fintastic/web/util/metrics-and-lists'
import { DeepReadonly, Maybe } from '@fintastic/shared/util/types'
import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'

export class MetricDisplaySettingsWrapper
  implements ImmutableADTWrapper<MetricDisplaySettings>
{
  _rawData: DeepReadonly<MetricDisplaySettings>

  constructor(displaySettings: MetricDisplaySettings) {
    this._rawData = displaySettings
  }

  unwrap(): MetricDisplaySettings {
    return this._rawData
  }

  /**
   * Applicable only for currency data types
   */
  currency(): Maybe<string> {
    const v = this._rawData.currency_name
    return v.length === 0 ? null : v
  }

  /**
   * Applicable only for currency data types
   */
  currencySignPosition(): Maybe<'before' | 'after'> {
    if (this.currency() === null) {
      return null
    }
    return this._rawData.currency_sign_position
  }

  /**
   * Applicable only to columns of the list
   */
  visibleAsColumn(): boolean {
    return this._rawData.is_visible
  }

  /**
   * Applicable only for numeric data types
   */
  numericDecimalPlaces(): number {
    return this._rawData.formatting.decimal_places
  }

  /**
   * Applicable only for numeric data types
   */
  numericCompactFormat(): Maybe<
    Exclude<MetricDisplaySettingsFormatting['compact_formatting'], 'no_format'>
  > {
    const v = this._rawData.formatting.compact_formatting
    return v === 'no_format' ? null : v
  }

  /**
   * Applicable only for numeric data types
   */
  numericUsesThousandsSeparator(): boolean {
    return this._rawData.formatting.thousands_separator
  }

  /**
   * Applicable only for numeric data types
   */
  numericNegativesFormat(): '-' | '()' {
    return this._rawData.formatting.negatives
  }

  prefix(): Maybe<string> {
    const v = this._rawData.formatting.prefix
    return v.length === 0 ? null : v
  }

  suffix(): Maybe<string> {
    const v = this._rawData.formatting.suffix
    return v.length === 0 ? null : v
  }
}
