/**
 * @deprecated
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { AxiosError } from 'axios'
import { Maybe } from '@fintastic/shared/util/types'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar'

export type QueryErrorToastProps = {
  error: Maybe<Error | AxiosError>
  errorMessageGetter?: (error: Maybe<Error | AxiosError>) => string
  anchorOrigin?: SnackbarOrigin
  autoHideDuration?: number | null
}

const defaultAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

/**
 * @deprecated use toastErrorHandler from '@fintastic/shared/ui/toast-framework' instead
 */
export const QueryErrorToast: React.FC<QueryErrorToastProps> = ({
  error,
  errorMessageGetter,
  anchorOrigin = defaultAnchorOrigin,
  autoHideDuration = 6000,
}) => {
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    if (error) {
      setIsShow(true)
    }
  }, [error])

  const message = useMemo(
    () =>
      errorMessageGetter ? errorMessageGetter(error) : 'Something went wrong',
    [error, errorMessageGetter],
  )

  const handleClose = useCallback(() => {
    setIsShow(false)
  }, [])

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  return (
    <Snackbar
      open={isShow}
      onClose={handleClose}
      message={message}
      action={action}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
    />
  )
}
