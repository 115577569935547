import { useMemo } from 'react'
import type { VersionsApi } from '../versions-api/useVersionsApi'
import type { ListsApi } from '../lists-api/useListsApi'
import { ListGridPropsLocalVersion } from './types'
import { filterMapNullable } from '@fintastic/shared/util/functional-programming'
import { useVersionUserLocks } from '@fintastic/web/data-access/versions'
import { SingleVersionSelectorOption } from '@fintastic/shared/ui/components'

export const useVersionsSelectorProps = (
  enabled: boolean,
  versionIds: string[],
  versionsApi: VersionsApi,
  listsApi: ListsApi,
) => {
  const versionUserLocks = useVersionUserLocks(versionIds)

  return useMemo<ListGridPropsLocalVersion>(() => {
    if (!enabled) {
      return {
        enableLocalVersionSelector: false,
      }
    }

    const options = filterMapNullable<string, SingleVersionSelectorOption>(
      versionIds,
      (versionId) => {
        if (!versionsApi.versionsMetadata[versionId]) {
          return null
        }

        const verionUserLock = versionUserLocks.find(
          (l) => l.versionId === versionId,
        )

        return {
          label: versionsApi.versionsMetadata[versionId].name || versionId,
          value: versionId,
          disabled: listsApi.versionsWhereListDoesNotLoad.includes(versionId),
          isLocked: !!versionsApi.versionsMetadata[versionId].locked_at,
          lockedForEditing: verionUserLock?.editIsBlocked,
          lockedForEditingBy: verionUserLock?.originalLock?.user,
        }
      },
    )

    return {
      enableLocalVersionSelector: true,
      localVersion: versionsApi.versionId,
      onChangeLocalVersion: versionsApi.switchToVersion,
      localVersionOptions: options,
      localVersionOverridesGlobal: versionsApi.versionId !== versionIds[0],
    }
  }, [
    enabled,
    versionIds,
    versionsApi.versionId,
    versionsApi.switchToVersion,
    versionsApi.versionsMetadata,
    versionUserLocks,
    listsApi.versionsWhereListDoesNotLoad,
  ])
}
