import React, { useCallback } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorAlert } from './ErrorAlert'
import * as Sentry from '@sentry/react'

export type ErrorBoundaryProps = {
  children: React.ReactNode
  title?: string
  message?: string
  errorComponent?: React.ReactNode
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  title,
  message,
  errorComponent,
}) => {
  const handleOnError = useCallback((error: Error) => {
    Sentry.captureException(error)
  }, [])

  return (
    <ReactErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <>
          <ErrorAlert
            tryAgainCallback={resetErrorBoundary}
            title={title}
            message={message}
          />
          {errorComponent}
        </>
      )}
      onError={handleOnError}
      children={children}
    />
  )
}
