import React from 'react'
import { Tooltip } from '@mui/material'
import { ChartSettingsIcon } from '@fintastic/web/feature/charts'
import { TopBar } from '@fintastic/shared/ui/grid-framework'

export type MetricChartTopBarProps = {
  title?: string
  leftContent: React.ReactNode
  rightContent: React.ReactNode
}

export const MetricChartTopBar: React.FC<MetricChartTopBarProps> = ({
  title,
  leftContent,
  rightContent,
}) => (
  <TopBar
    data-testid="topbar-container"
    title={title || 'Unknown Metric Chart'}
    titleIcon={
      <Tooltip title="Metric Chart">
        <span>
          <ChartSettingsIcon sx={{ fill: '#2C45FE' }} />
        </span>
      </Tooltip>
    }
    sx={{ flexGrow: 0, flexShrink: 0 }}
    leftContent={leftContent}
    rightContent={rightContent}
  />
)
