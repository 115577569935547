import { useQueryClient } from 'react-query'
import { importConfigsByVersionQueryKey } from '../utils/query-key'

import { useCallback } from 'react'

export const useImportConfigQueryInvalidate = (versionId: string) => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    queryClient.removeQueries(importConfigsByVersionQueryKey(versionId))
    await queryClient.invalidateQueries(
      importConfigsByVersionQueryKey(versionId),
    )
  }, [queryClient, versionId])
}
