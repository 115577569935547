import React from 'react'
import { UsePeriodSelectionComponentValue } from '@fintastic/web/feature/period-selector'
import { WidgetRendererProps } from '@fintastic/shared/ui/widgets-framework'
import { WidgetPeriodSelectionContainerDesignModeWrapper } from './WidgetPeriodSelectionContainerDesignModeWrapper'
import { WidgetPeriodSelectionContainerDisplayMode } from './WidgetPeriodSelectionContainerDisplayMode'

export type WidgetPeriodSelectionComponentProps = {
  periodSelection: UsePeriodSelectionComponentValue
}

export const WidgetPeriodSelectionContainer: React.FC<
  {
    Component: React.FC<WidgetPeriodSelectionComponentProps>
  } & WidgetRendererProps
> = (props) =>
  props.boardParams.isDesignMode ? (
    <WidgetPeriodSelectionContainerDesignModeWrapper {...props} />
  ) : (
    <WidgetPeriodSelectionContainerDisplayMode {...props} />
  )
