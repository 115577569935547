import React, { useCallback, useMemo, useState } from 'react'
import { StyledRoot } from './ColumnsList.styled'
import { Column } from './Column'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import { useColumnsSettingsContext } from '../columns-settings-context'
import { StyledSearch } from '../../../../../../../../../lists/src/lib/features/columns-visibility/columns-panel/ColumnsPanel/ColumnsList.styled'
import { QuickSearchDebounced } from '@fintastic/shared/ui/components'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'

const dndRestrictions = [restrictToVerticalAxis, restrictToParentElement]

export const ColumnsList = ({
  bottomContent,
  showSearch = true,
}: {
  showSearch?: boolean
  bottomContent?: React.ReactNode
}) => {
  const { columns, onReorderColumns } = useColumnsSettingsContext()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (active.id !== over?.id) {
        const columnIds = columns.map((c) => c.clientOnlyId())

        const oldIndex = columnIds.indexOf(active.id as string)
        const newIndex = columnIds.indexOf(over!.id as string)

        return onReorderColumns(arrayMove(columnIds, oldIndex, newIndex))
      }
    },
    [columns, onReorderColumns],
  )

  const sortableItems = useMemo(
    () => columns.map((c) => ({ id: c.clientOnlyId() })),
    [columns],
  )

  const [filterString, setFilterString] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [visibleCount, setVisibleCount] = useState(0)

  const filteredColumns = useMemo<ListColumnWrapper[]>(() => {
    const trimmedSearch = filterString.trim().toLocaleLowerCase()

    if (!trimmedSearch) {
      setTotalCount(() => columns.length)
      setVisibleCount(() => columns.length)
      return columns
    }

    const result = columns.filter((c) =>
      c.label().toLocaleLowerCase().includes(trimmedSearch),
    )
    setTotalCount(() => columns.length)
    setVisibleCount(() => result.length)

    return result
  }, [columns, filterString])

  return (
    <StyledRoot>
      {showSearch && (
        <StyledSearch>
          <QuickSearchDebounced
            onFilter={setFilterString}
            totalCount={totalCount}
            visibleCount={visibleCount}
          />
        </StyledSearch>
      )}
      {bottomContent}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={dndRestrictions}
      >
        <SortableContext
          items={sortableItems}
          strategy={verticalListSortingStrategy}
        >
          {filteredColumns.map((column) => (
            <Column
              key={column.clientOnlyId()}
              id={column.clientOnlyId()}
              name={column.label()}
              type={column.type()}
              visible={column.visible()}
            />
          ))}
        </SortableContext>
      </DndContext>
    </StyledRoot>
  )
}
