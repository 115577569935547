import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import {
  generateErrorMessage,
  generateSuccessMessage,
  useSelectedVersions,
} from '../utils'
import type {
  AccessLevel,
  ShareUserData,
  UserData,
} from '@fintastic/web/data-access/iam'
import Switch from '@mui/material/Switch'
import {
  UserPanel,
  UserSelect,
  UserSelectOption,
} from '@fintastic/shared/ui/components'
import { Maybe } from '@fintastic/shared/util/types'
import {
  useChangeAccessForVersionsList,
  useLoadAccessForVersionsList,
} from '@fintastic/web/data-access/versions'
import {
  isVersionArchived,
  Version,
  VersionAccess,
  VersionIdentityAccess,
} from '@fintastic/web/util/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'

export type VersionsShareFormProps = {
  selectedVersions: Version[]
  closeParentModal: () => void
  users: UserData[]
  onAfterSuccess?: () => void
}

const sortUsersByEmail = (a: UserSelectOption, b: UserSelectOption): number =>
  (a?.email || '').localeCompare(b?.email || '')

export const VersionsShareForm: React.FC<VersionsShareFormProps> = (props) => {
  const { closeParentModal, selectedVersions, users, onAfterSuccess } = props
  const { selectedVersionsIds, singleVersionSelected, versionNamesTitle } =
    useSelectedVersions(selectedVersions)
  const [selectedUser, setSelectedUser] = useState<Maybe<string>>(null)
  const [shareData, setShareData] = useState<ShareUserData[]>([])
  const [initialMixedRoles, setInitialMixedRoles] = useState<
    Record<string, boolean>
  >({})
  const [initialIsRestricted, setInitialIsRestricted] = useState<
    Record<string, boolean>
  >({})
  const [initialAccess, setInitialAccess] = useState<
    Record<string, Record<string, AccessLevel>>
  >({})
  const [shareDataChanged, setShareDataChanged] = useState(false)
  const usersToSelect = useMemo<UserData[]>(
    () =>
      users
        .filter((user) => user.is_active && !user.deactivated_at)
        .filter(
          (user) =>
            !shareData
              .map((userShare) => userShare.user.username)
              .includes(user.username),
        ),
    [shareData, users],
  )

  const usersOptions = useMemo(
    () =>
      usersToSelect
        .map<UserSelectOption>((user) => ({
          email: user.username,
          name: user.full_name || user.username,
        }))
        .sort(sortUsersByEmail),
    [usersToSelect],
  )

  const handleAddUser = useCallback(() => {
    if (selectedUser !== '' && selectedUser !== null) {
      const user = users.find(
        (user) => user.username === selectedUser,
      ) as UserData
      setShareData((prevStatus) => [
        ...prevStatus,
        {
          user,
          access_level: 'viewer',
        },
      ])
      setShareDataChanged(true)
      setSelectedUser(null)
    }
  }, [selectedUser, users])
  const [generalAccess, setGeneralAccess] = useState(false)
  const handleGeneralAccessChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGeneralAccess(event.target.checked)
    },
    [],
  )
  const versionsAccessResponse =
    useLoadAccessForVersionsList(selectedVersionsIds)
  useEffect(() => {
    // on-load init version sharing data based on current shared data
    if (versionsAccessResponse.some((access) => access.isFetching)) {
      return
    }
    const versionsAccess = versionsAccessResponse.map(
      (accessResp) => accessResp.data,
    ) as VersionAccess[]
    setGeneralAccess(versionsAccess.every((access) => !access.is_restricted))
    const newShareData = [] as ShareUserData[]
    let firstVersion = true
    versionsAccess.forEach((access) => {
      // check if all previously added users exists in this version, if no - change role to "mixed"
      newShareData.forEach((userShare) => {
        if (
          !access.accesses.find(
            (access: VersionIdentityAccess) =>
              access.identity_id === userShare.user.username,
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          userShare.access_level = ''
        }
      })
      // add users from current version access list
      access.accesses.forEach((identity) => {
        const existedShare = newShareData.find(
          (share: ShareUserData) =>
            share.user.username === identity.identity_id,
        )
        if (existedShare) {
          if (existedShare.access_level !== identity.access_level) {
            existedShare.access_level = ''
          }
        } else {
          // this user wasn't in sharing list before
          const user = users.find(
            (user: UserData) => user.username === identity.identity_id,
          )
          if (!user) {
            console.error(
              `incorrect users list. shared user ${identity.identity_id} is not found in users list`,
              identity,
            )
          } else {
            newShareData.push({
              user,
              access_level: firstVersion ? identity.access_level : '',
            })
          }
        }
      })
      firstVersion = false
    })
    setShareData(newShareData)
    setInitialMixedRoles(
      newShareData.reduce(
        (res, userShare) => ({
          ...res,
          [userShare.user.username]: userShare.access_level === '',
        }),
        {} as Record<string, boolean>,
      ),
    )
    setInitialAccess(
      versionsAccess.reduce(
        (res, versionAccess, index) => ({
          ...res,
          [selectedVersionsIds[index]]: versionAccess.accesses.reduce(
            (accesses, userAccess) => ({
              ...accesses,
              [userAccess.identity_id]: userAccess.access_level,
            }),
            {} as Record<string, AccessLevel>,
          ),
        }),
        {} as Record<string, Record<string, AccessLevel>>,
      ),
    )
    setInitialIsRestricted(
      versionsAccess.reduce(
        (res, versionAccess, index) => ({
          ...res,
          [selectedVersionsIds[index]]: versionAccess.is_restricted,
        }),
        {} as Record<string, boolean>,
      ),
    )
  }, [users, selectedVersions, versionsAccessResponse, selectedVersionsIds])
  const changeAccessMutation = useChangeAccessForVersionsList()
  const handleSubmit = useCallback(async () => {
    const changeAccessParams = selectedVersions
      .filter((version) => !isVersionArchived(version))
      .map((version) => {
        const prevAccess =
          initialAccess[version.uuid] || ({} as Record<string, AccessLevel>)
        const access = {
          is_restricted: singleVersionSelected
            ? !generalAccess
            : initialIsRestricted[version.uuid],
          accesses: shareData
            .filter((userShare) => {
              if (userShare.access_level !== '') {
                return true
              }
              // access_level === "" means mixed roles. so we should give same permissions we had initially
              // but if user didn't had eny permissions for this version - just filter it out
              return !!prevAccess[userShare.user.username]
            })
            .map((userShare) => ({
              identity_type: 'user',
              identity_id: userShare.user.username,
              access_level:
                userShare.access_level === ''
                  ? prevAccess[userShare.user.username]
                  : userShare.access_level,
            })),
        } as VersionAccess
        return { versionId: version.uuid, access }
      })
    await changeAccessMutation.mutate(changeAccessParams, {
      onSuccess: () => {
        toast.success(generateSuccessMessage(selectedVersions, 'Shared'))
        onAfterSuccess?.()
      },
      onError: () => {
        toast.error(generateErrorMessage(selectedVersions, 'Share'))
      },
    })
    if (closeParentModal) {
      closeParentModal()
    }
  }, [
    onAfterSuccess,
    selectedVersions,
    changeAccessMutation,
    closeParentModal,
    initialAccess,
    singleVersionSelected,
    generalAccess,
    initialIsRestricted,
    shareData,
  ])
  const selectChangeHandle = useCallback(
    (e: SelectChangeEvent<AccessLevel>, userShare: ShareUserData) => {
      const value = e.target.value
      setShareDataChanged(true)
      if (value === 'remove') {
        setShareData((prevState) =>
          prevState.filter(
            (stateUser) => stateUser.user.username !== userShare.user.username,
          ),
        )
      } else {
        setShareData((prevState) =>
          prevState.map((stateUser) =>
            stateUser.user.username === userShare.user.username
              ? {
                  user: userShare.user,
                  access_level: value as AccessLevel,
                }
              : stateUser,
          ),
        )
      }
    },
    [],
  )
  if (versionsAccessResponse.some((access) => access.isLoading)) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </Box>
    )
  }
  if (versionsAccessResponse.some((access) => access.isError)) {
    return <div>Some error with getting access data. Contact support</div>
  }
  const generalAccessChanged = singleVersionSelected
    ? initialIsRestricted[selectedVersions[0].uuid] !== !generalAccess
    : false

  return (
    <FormLayout
      submitButtonText="Apply"
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
      testIdPrefix="share"
      submitEnabled={generalAccessChanged || shareDataChanged}
    >
      <Typography sx={{ marginBottom: 3, whiteSpace: 'pre-line' }}>
        {singleVersionSelected
          ? `"${versionNamesTitle}"`
          : `${selectedVersions.length} versions selected`}
      </Typography>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '100%' }}>
          <UserSelect
            id="sharing-user-select"
            disabled={!usersToSelect.length}
            value={selectedUser}
            options={usersOptions}
            onChange={setSelectedUser}
            label={
              usersToSelect.length
                ? 'User to share version with'
                : `Version${
                    singleVersionSelected ? ' is' : 's are'
                  } already shared with all users`
            }
          />
        </Box>
        <Button
          variant="contained"
          disabled={!usersToSelect.length || selectedUser === null}
          onClick={handleAddUser}
          size="large"
          sx={{
            width: 40,
            minWidth: 40,
            ml: 1,
          }}
        >
          +
        </Button>
      </Box>
      {shareData.length > 0 && (
        <Stack
          spacing={3}
          maxHeight={'40vh'}
          sx={{
            mt: 3,
            mb: 3,
            overflowY: 'auto',
          }}
        >
          {shareData.map((userShare) => (
            <Stack
              key={userShare.user.username}
              direction="row"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <UserPanel
                name={userShare.user.full_name}
                email={userShare.user.username}
              />
              <FormControl variant="standard" sx={{ minWidth: 80 }}>
                {/* @todo @ipomazkin-fin add styles for selects */}
                <Select
                  value={userShare.access_level}
                  displayEmpty
                  disableUnderline
                  sx={{
                    '&.Mui-focused': {
                      background: 'transparent',
                      fontWeight: 'bold',
                    },
                    '.MuiInput-input': {
                      background: 'transparent',
                    },
                  }}
                  onChange={(e) => selectChangeHandle(e, userShare)}
                >
                  <MenuItem key="viewer" value="viewer">
                    Viewer
                  </MenuItem>
                  <MenuItem key="editor" value="editor">
                    Editor
                  </MenuItem>
                  {!singleVersionSelected &&
                    initialMixedRoles[userShare.user.username] && (
                      <MenuItem key="mixed" value="">
                        Mixed roles
                      </MenuItem>
                    )}
                  <Divider />
                  <MenuItem key="remove" value="remove">
                    Remove
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          ))}
        </Stack>
      )}
      {singleVersionSelected && (
        <>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
          <FormControlLabel
            sx={{
              mt: -1,
              mb: -1,
            }}
            control={
              <Switch
                sx={{ m: 1 }}
                checked={generalAccess}
                onChange={handleGeneralAccessChange}
              />
            }
            label="Public"
          />
          {generalAccess && (
            <Typography sx={{ mt: 1 }}>
              {singleVersionSelected
                ? ' This version will be visible to all users'
                : ' These versions will be visible to all users'}
            </Typography>
          )}
        </>
      )}
    </FormLayout>
  )
}
