export const oceanTheme = {
  color: [
    '#3fb1e3',
    '#6be6c1',
    '#626c91',
    '#a0a7e6',
    '#c1efa9',
    '#8d9c9d',
    '#8994c3',
    '#198181',
  ],
}
