import React, { useMemo, useState } from 'react'
import { Column } from '../types'
import { ColumnVisibilityToggleCallback } from './types'
import {
  StyledButtons,
  StyledColumns,
  StyledHeader,
  StyledRoot,
  StyledSearch,
  StyledTitle,
} from './ColumnsList.styled'
import { Button, Typography } from '@mui/material'
import { ColumnsListItem } from './ColumnsListItem'
import { QuickSearchDebounced } from '@fintastic/shared/ui/components'

export type ColumnsListProps = {
  columns: Column[]
  hiddenColumns: string[]
  onToggleColumnVisibility: ColumnVisibilityToggleCallback
  onShowAll: () => void
  onHideAll: () => void
  showSearch?: boolean
  onNavigateToColumn?: (columnId: string) => void
}

export const ColumnsList: React.FC<ColumnsListProps> = ({
  onHideAll,
  columns,
  hiddenColumns,
  onToggleColumnVisibility,
  onShowAll,
  showSearch = true,
  onNavigateToColumn,
}) => {
  const [filterString, setFilterString] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [visibleCount, setVisibleCount] = useState(0)

  const filteredColumns = useMemo<Column[]>(() => {
    const trimmedSearch = filterString.trim().toLocaleLowerCase()

    if (!trimmedSearch) {
      setTotalCount(() => columns.length)
      setVisibleCount(() => columns.length)
      return columns
    }

    const result = columns.filter((c) =>
      c.label.toLocaleLowerCase().includes(trimmedSearch),
    )
    setTotalCount(() => columns.length)
    setVisibleCount(() => result.length)

    return result
  }, [columns, filterString])

  return (
    <StyledRoot>
      <StyledHeader>
        <StyledTitle>
          <Typography variant="body1">Columns</Typography>
        </StyledTitle>
        <StyledButtons>
          <Button
            sx={{ ml: 1 }}
            size="small"
            color="primary"
            onClick={onHideAll}
          >
            Hide all
          </Button>
          <Button
            sx={{ ml: 1 }}
            size="small"
            color="primary"
            onClick={onShowAll}
          >
            Show all
          </Button>
        </StyledButtons>
      </StyledHeader>
      {showSearch && (
        <StyledSearch>
          <QuickSearchDebounced
            onFilter={setFilterString}
            totalCount={totalCount}
            visibleCount={visibleCount}
          />
        </StyledSearch>
      )}
      <StyledColumns>
        {filteredColumns.map((column) => (
          <ColumnsListItem
            key={column.id}
            column={column}
            visible={!hiddenColumns.includes(column.id)}
            onToggleVisibility={onToggleColumnVisibility}
            onNavigateToColumn={() => onNavigateToColumn?.(column.id)}
          />
        ))}
      </StyledColumns>
    </StyledRoot>
  )
}
