import { useMemo } from 'react'
import { useLoadVersion } from '../api/useLoadVersion'
import { isVersionEditable } from '@fintastic/web/util/versions'
import { Maybe } from '@fintastic/shared/util/types'

export function useIsVersionEditable(versionId: Maybe<string>) {
  const versionQuery = useLoadVersion(versionId)

  return useMemo(
    () => ({
      isLoading: versionQuery.isLoading,
      editable: isVersionEditable(versionQuery.data || null),
    }),
    [versionQuery.data, versionQuery.isLoading],
  )
}
