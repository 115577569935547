import { useCallback, useMemo } from 'react'
import { Board } from '../types'
import { useBoardUpdateMutation } from './useBoardUpdateMutation'

export function useBoardMoveToNewFolder() {
  const updateBoardMutation = useBoardUpdateMutation()

  const callback = useCallback(
    (boardId: Board['id'], newFolderName: string, onSuccess?: () => void) =>
      updateBoardMutation.mutate(
        {
          boardId,
          newFolderName,
        },
        {
          onSuccess,
        },
      ),
    [updateBoardMutation],
  )

  return useMemo(
    () => ({
      mutationState: updateBoardMutation,
      moveBoardToNewFolder: callback,
    }),
    [callback, updateBoardMutation],
  )
}
