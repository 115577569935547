import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import {
  ColDef,
  GetRowIdParams,
  IAggFuncParams,
  ICellRendererParams,
  ISetFilterParams,
  SideBarDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useMemo, useRef } from 'react'
import { AllowDenyToggle } from './components/AllowDenyToggle'
import {
  DimensionAccessValue,
  DimensionMap,
  DimensionValueTuple,
} from './types'
import { ShowMaskToggle } from './components/showMaskToggle'
import { cleanupDimensionName } from '@fintastic/web/util/metrics-and-lists'

const defaultGridStyles = {
  height: '100%',
  width: '100%',
}

const defaultColDef = {
  filter: true,
  sortable: true,
  resizable: true,
  flex: 1,
}

type DimensionAccessTableProps = {
  dimensions: DimensionValueTuple[]
  restrictions: DimensionMap
  onChange: (newRestrictions: DimensionMap) => void
  masking?: boolean
}

const OrphanCellRenderer = (props: { data: DimensionAccessValue }) => (
  <span>{cleanupDimensionName(props?.data?.value || 'unknown value')}</span>
)

export const DimensionAccessGrid: React.FC<DimensionAccessTableProps> = ({
  dimensions,
  restrictions,
  onChange,
  masking,
}) => {
  const handleChangeAccess = useCallback(
    (change: DimensionAccessValue) => {
      const newRestrictions = { ...restrictions }
      if (change.allowed) {
        newRestrictions[change.dimensionId] = change.value
          ? [...(newRestrictions[change.dimensionId] || []), change.id]
          : dimensions
              .filter((dim) => dim.dimensionId === change.dimensionId)
              .map((d) => d.id)
      } else {
        if (change.value) {
          newRestrictions[change.dimensionId] = (
            newRestrictions[change.dimensionId] || []
          ).filter((dimValId) => dimValId !== change.id)
        } else if (newRestrictions[change.dimensionId]) {
          delete newRestrictions[change.dimensionId]
        }
      }

      onChange(newRestrictions)
    },
    [restrictions, dimensions, onChange],
  )

  const dimensionLabels = useMemo(
    () =>
      Object.fromEntries(dimensions.map((d) => [d.dimensionId, d.dimension])),
    [dimensions],
  )

  const handleChangeAccessRef = useRef(handleChangeAccess)
  handleChangeAccessRef.current = handleChangeAccess
  const dimensionLabelsRef = useRef(dimensionLabels)
  dimensionLabelsRef.current = dimensionLabels

  const dimensionsTableColDefs = useMemo<ColDef<DimensionAccessValue>[]>(
    () => [
      {
        field: 'dimensionId',
        headerName: 'Dimension',
        rowGroup: true,
        hide: true,
        valueFormatter: (params) =>
          dimensionLabelsRef.current[params.value] ?? params.value,
        filterParams: {
          valueFormatter: (params) =>
            dimensionLabelsRef.current[params.value] ?? params.value,
        } as ISetFilterParams,
      },
      {
        field: 'value',
        headerName: 'Name',
        filter: false,
        showRowGroup: 'dimensionId',
        cellRendererSelector: (params) => {
          if (params.node?.key === null) {
            return { component: OrphanCellRenderer }
          }

          return {
            component: 'agGroupCellRenderer',
          }
        },
        valueFormatter: (params) =>
          dimensionLabelsRef.current[params.value] ?? params.value,
      },
      {
        field: 'allowed',
        headerName: 'Access',
        filter: false,
        valueFormatter: (params: ValueFormatterParams<DimensionAccessValue>) =>
          !params.value || params.value === 'false' ? 'Available' : 'Available',
        filterParams: {
          valueFormatter: (
            params: ValueFormatterParams<DimensionAccessValue>,
          ) =>
            !params.value || params.value === 'false'
              ? 'Available'
              : 'Restricted',
        },
        aggFunc: (params: IAggFuncParams) => {
          if (params.values.every((v) => v === params.values[0])) {
            return params.values[0] === true
              ? true
              : params.values[0] === false
              ? false
              : params.values[0]
          }
          return null
        },
        cellRenderer: (params: ICellRendererParams) => {
          const ToggleComponent = masking ? ShowMaskToggle : AllowDenyToggle
          return (
            <ToggleComponent
              value={params.value}
              onChange={(newValue) => {
                const change: DimensionAccessValue = {
                  ...params.data,
                  allowed: newValue === 'allow',
                  dimensionId: params.node.group
                    ? params.node.getGroupKeys()[0]
                    : params.data.dimensionId,
                }
                handleChangeAccessRef.current(change)
              }}
            />
          )
        },
        width: 180,
      },
    ],
    [masking],
  )

  const rowData = useMemo<DimensionAccessValue[]>(
    () =>
      dimensions.map((dimensionValue) => ({
        ...dimensionValue,
        allowed: (restrictions[dimensionValue.dimensionId] || []).includes(
          dimensionValue.id,
        ),
      })),
    [dimensions, restrictions],
  )

  const getRowIdCallBack = useCallback(
    (params: GetRowIdParams<DimensionAccessValue>) => params.data['id'],
    [],
  )

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      minWidth: 385,
      pinned: 'left',
      sort: 'asc',
    }),
    [],
  )

  return (
    <AgGridThemeFintasticWrapper
      sx={defaultGridStyles}
      themeOptions={useMemo(() => ({}), [])}
    >
      {({ defaultGridProps }) => (
        <AgGridReact
          {...defaultGridProps}
          columnDefs={dimensionsTableColDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          groupDisplayType="custom"
          groupRemoveSingleChildren={true}
          suppressAggFuncInHeader
          getRowId={getRowIdCallBack}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={sidebarDef}
        ></AgGridReact>
      )}
    </AgGridThemeFintasticWrapper>
  )
}

const sidebarDef: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: '',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
}
