import { styled, Box } from '@mui/material'

export const StyledNameAndType = styled(Box)(({ theme }) => ({}))

export const StyledSubsections = styled(Box)(({ theme }) => ({}))

export const StyledBreakdown = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

export const StyledFooter = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`
