import { useSubscribeToCalculationProgressEvent } from '@fintastic/web/data-access/service-pusher'
import { useCallback } from 'react'
import { ImmutableCalculationProgressEvent } from '@fintastic/web/data-access/calc'

// @todo: cleanup?
const processedUpdateModelTaskIds: string[] = []
const failedUpdateModelTaskIds: string[] = []

export function useWaitImportCalculationEvent(
  versionId: string,
  callback: (taskIds: string[], failedTaskIds: string[]) => void,
) {
  useSubscribeToCalculationProgressEvent(
    [versionId],
    useCallback(
      async (event) => {
        void handleUpdateModelEvent(event, callback)
      },
      [callback],
    ),
  )

  return useCallback(() => {
    void callback(processedUpdateModelTaskIds, failedUpdateModelTaskIds)
  }, [callback])
}

const handleUpdateModelEvent = async (
  event: ImmutableCalculationProgressEvent,
  cb: (taskIds: string[], failedTaskIds: string[]) => void,
) => {
  const shouldProceed =
    (event.successful() || event.failed()) &&
    event.triggeredByUpdateModelAction()

  if (!shouldProceed) {
    return
  }

  if (event.failed()) {
    if (!failedUpdateModelTaskIds.includes(event.taskId)) {
      failedUpdateModelTaskIds.push(event.taskId)
    }
  }

  if (!processedUpdateModelTaskIds.includes(event.taskId)) {
    processedUpdateModelTaskIds.push(event.taskId)
  }
  // Warning!
  // Event can be delivered before other async functions.
  // Callback function must take care of filtering and idempotency.
  cb(processedUpdateModelTaskIds, failedUpdateModelTaskIds)
}
