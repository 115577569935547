import React from 'react'
import {
  StyledCont,
  StyledFrom,
  StyledRoot,
  StyledTo,
} from './PeriodsPicker.styled'
import {
  SmartMultiselect,
  SmartSelectOption,
  SmartSelectInputButton,
} from '@fintastic/shared/ui/dropdowns'
import { Period } from '@fintastic/web/util/period-selector'

export type PeriodsPickerProps = {
  options: SmartSelectOption<Period>[]
  values: Period[]
  onAddPeriods: (p: Period[]) => void
  onRemovePeriods: (p: Period[]) => void
  disabled: boolean
}

export const PeriodsPicker: React.FC<PeriodsPickerProps> = ({
  onRemovePeriods,
  onAddPeriods,
  values,
  options,
  disabled,
}) => (
  <StyledRoot>
    <StyledCont>
      <StyledFrom>
        <SmartMultiselect
          options={options}
          value={values}
          onSelectOptions={onAddPeriods}
          onUnselectOptions={onRemovePeriods}
          placeholder="From"
          disabled={disabled}
        />
      </StyledFrom>
      <StyledTo>
        <SmartSelectInputButton placeholder="To" values={[]} disabled={true} />
      </StyledTo>
    </StyledCont>
  </StyledRoot>
)
