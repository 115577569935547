import { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'

export function isValueColumnEditable(
  readonly: boolean,
  versionEditable: boolean,
  source: MetricOrListSource,
): boolean {
  return (
    !readonly && versionEditable && source !== 'calculated' && source !== 'raw'
  )
}
