import React from 'react'

export const handleTryToFormSubmit = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (e.key !== 'Enter') {
    return
  }

  e.currentTarget.closest('form')?.requestSubmit?.()
}
