import { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import { getScrolledParent } from './utils'
import { throttle } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'

const EXTRA_HEIGHT_SIZE = 48
const THROTTLE_INTERVAL = 200

export const useOneWayExtraSpaceResize = (
  elementRef: MutableRefObject<Maybe<HTMLDivElement>>,
) => {
  const handleExtraSpace = useCallback(() => {
    const element = elementRef.current as HTMLDivElement
    if (!element) {
      return
    }

    let minH = parseInt(element.style.minHeight)
    if (isNaN(minH)) {
      minH = element.getBoundingClientRect().height
    }

    if (isNaN(minH) || minH <= EXTRA_HEIGHT_SIZE) {
      console.error('Incorrect height calculation')
      return
    }
    // eslint-disable-next-line no-param-reassign
    element.style.minHeight = minH + EXTRA_HEIGHT_SIZE + 'px'

    const nearestScrolledElement = getScrolledParent(element)
    if (nearestScrolledElement) {
      nearestScrolledElement.scrollTop = nearestScrolledElement.scrollHeight
    }
  }, [elementRef])

  const throttledFns = useMemo(() => {
    const throttledFn = throttle(handleExtraSpace, THROTTLE_INTERVAL)

    return {
      updateExtraHeight: throttledFn,
      cancelUpdateExtraHeight: throttledFn.cancel,
    }
  }, [handleExtraSpace])

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      e.stopPropagation()
      e.preventDefault() // prevent text selection

      const maxHeight = document.body.getBoundingClientRect().height

      if (maxHeight >= e.clientY + EXTRA_HEIGHT_SIZE / 2) {
        throttledFns.cancelUpdateExtraHeight() // stop throttling, if any
        return
      }
      throttledFns.updateExtraHeight()
    },
    [throttledFns],
  )

  const cleanupResize = useCallback(() => {
    throttledFns.cancelUpdateExtraHeight()
    document.removeEventListener('pointermove', handlePointerMove)

    const element = elementRef.current as HTMLDivElement
    if (!element) {
      return
    }
    element.style.minHeight = ''
    element.style.transition = ''
  }, [elementRef, handlePointerMove, throttledFns])

  const initResize = useCallback(() => {
    throttledFns.cancelUpdateExtraHeight()
    document.removeEventListener('pointermove', handlePointerMove)

    const element = elementRef.current as HTMLDivElement
    if (!element) {
      return
    }

    const height = element.getBoundingClientRect().height
    // @todo: store modified props/initial values?
    element.style.minHeight = height + 'px'
    element.style.transition = 'min-height .2s linear'

    document.addEventListener('pointermove', handlePointerMove)
  }, [elementRef, handlePointerMove, throttledFns])

  useEffect(
    () => () => {
      // additional cleaning to remove the remaining listeners in case of any error
      cleanupResize()
    },
    [cleanupResize],
  )

  return useMemo(
    () => ({
      ...throttledFns,
      cleanupOneWayResize: cleanupResize,
      initOneWayResize: initResize,
    }),
    [cleanupResize, initResize, throttledFns],
  )
}
