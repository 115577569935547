import React from 'react'
import { NameField } from './NameField'

export type GeneralSettingsProps = {
  name: string
}

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({ name }) => (
  <NameField value={name} readonly={true} />
)
