import { Maybe } from '@fintastic/shared/util/types'
import { useQuery } from 'react-query'
import { createVersionUserLockerQueryHandler } from './version-user-locker-query-handler'
import { versionsCacheKeys } from '../../cache'

export function useLoadVersionUserLocker(id?: Maybe<string>) {
  return useQuery(
    versionsCacheKeys.versionUserLock(id || ''),
    createVersionUserLockerQueryHandler(id),
    {
      enabled: id !== null && id !== undefined,
      refetchInterval: 60 * 1000,
    },
  )
}
