import { useLoadListInDifferentVersions } from '@fintastic/web/data-access/metrics-and-lists'
import { useMemo } from 'react'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { toMaybe } from '@fintastic/shared/util/types'
import { filterMapNullable } from '@fintastic/shared/util/functional-programming'

export type ListsApi = ReturnType<typeof useListsApi>

export const useListsApi = ({
  versionId,
  allVersions,
  listId,
  isClientOnlyList,
}: {
  versionId: string
  listId: string
  allVersions: string[]
  isClientOnlyList: boolean
}) => {
  const periodSelection = usePeriodSelectorContext()

  const listInAllVersions = useLoadListInDifferentVersions(
    allVersions,
    listId,
    periodSelection,
    !isClientOnlyList,
  )

  const listQuery = useMemo(() => {
    const versionIndex = allVersions.indexOf(versionId)
    if (versionIndex === -1) {
      return null
    }
    return toMaybe(listInAllVersions[versionIndex])
  }, [listInAllVersions, allVersions, versionId])

  const existingList = useMemo(
    () => toMaybe(listQuery?.data),
    [listQuery?.data],
  )

  const versionsWhereListDoesNotLoad = useMemo(
    () =>
      filterMapNullable<typeof listInAllVersions[number], string>(
        listInAllVersions,
        (query, index) => {
          if (!query.isError) {
            return null
          }
          return allVersions[index]
        },
      ),
    [listInAllVersions, allVersions],
  )

  const isLoading = listInAllVersions.some((q) => q.isLoading)
  const isFetching = listInAllVersions.some((q) => q.isFetching)
  const list = toMaybe(existingList?.list)
  const listOriginallyEmpty = !!existingList?.originallyEmpty
  const error = toMaybe(listQuery?.error)

  return useMemo(
    () =>
      ({
        versionsWhereListDoesNotLoad,
        list,
        listOriginallyEmpty,
        isLoading,
        isFetching,
        error,
      } as const),
    [
      error,
      isFetching,
      isLoading,
      list,
      listOriginallyEmpty,
      versionsWhereListDoesNotLoad,
    ],
  )
}
