import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnVisibilityPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnVisibility = (
  uncheckedState: State,
  {
    payload: { columnId, visible },
  }: PayloadAction<ChangeColumnVisibilityPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  list.findColumnByClientId(columnId)?.changeVisibility(visible)
}
