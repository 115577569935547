import React, { useCallback, useMemo, useState } from 'react'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import {
  FormLayout,
  useTextFieldState,
} from '@fintastic/shared/ui/form-framework'
import { useVersionDateRangeInputState } from '../../useVersionDateRangeInputState'
import { VersionDateRangeForm } from '../../VersionDateRangeForm'
import { generateErrorMessage, generateSuccessMessage } from '../../utils'
import { ErrorIcon } from '@fintastic/shared/ui/icons'
import { useModalState } from '@fintastic/shared/util/modal'
import {
  useCopyVersion,
  VersionsManagementApiErrorResponse,
} from '@fintastic/web/data-access/versions'
import { Version } from '@fintastic/web/util/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../routes'
import { AxiosError } from 'axios'
import { findNewVersionName, isNameExists } from './utils'
import dayjs from 'dayjs'

export type VersionsDuplicateFormProps = {
  versions: Version[]
  selectedVersions: Version[]
  closeParentModal: () => void
  onAfterSuccess?: () => void
}

export const VersionsDuplicateForm: React.FC<VersionsDuplicateFormProps> = ({
  closeParentModal,
  versions,
  selectedVersions,
  onAfterSuccess,
}) => {
  const sourceVersion = selectedVersions[0]
  const minEndDate = useMemo(
    () => dayjs(sourceVersion.period_end),
    [sourceVersion.period_end],
  )
  const maxEndDate = useMemo(
    () => dayjs(sourceVersion.period_start).add(20, 'years'),
    [sourceVersion.period_start],
  )
  const navigate = useNavigate()
  const copyVersionMutation = useCopyVersion()

  const versionNames = useMemo<string[]>(
    () => versions.map((version) => version.name),
    [versions],
  )

  const defaultVersionName = useMemo(
    () => findNewVersionName(sourceVersion.name, versionNames),
    [sourceVersion, versionNames],
  )

  const versionNameFieldState = useTextFieldState(defaultVersionName)
  const dateRangeState = useVersionDateRangeInputState(sourceVersion, true)
  const [duplicatePermissions, setDuplicatePermissions] = useState(false)
  const [duplicateLabels, setDuplicateLabels] = useState(true)

  const handleChangeDuplicatePermissions = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setDuplicatePermissions(event.target.checked),
    [setDuplicatePermissions],
  )

  const handleChangeDuplicateLabels = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setDuplicateLabels(event.target.checked),
    [setDuplicateLabels],
  )

  const {
    isOpen: isOperationErrorToastOpened,
    open: openOperationErrorToast,
    close: closeOperationErrorToast,
  } = useModalState()

  const handleSubmit = useCallback(async () => {
    if (!dateRangeState.startDate || !dateRangeState.endDate) {
      openOperationErrorToast()
      return
    }

    try {
      await copyVersionMutation.mutateAsync(
        {
          versions: [
            {
              newName: versionNameFieldState.value,
              originId: sourceVersion.uuid,
            },
          ],
          dateRange: {
            start: dateRangeState.startDate?.toDate(),
            end: dateRangeState.endDate?.toDate(),
          },
          duplicatePermissions,
          duplicateLabels,
        },
        {
          onSuccess: (versions: Version[]) => {
            toast.success(
              generateSuccessMessage(selectedVersions, 'Duplicated'),
            )
            const versionId = versions[versions.length - 1]?.uuid
            if (!versionId) {
              return
            }
            navigate(routes.version(versionId))
            onAfterSuccess?.()
          },
        },
      )
    } catch (error) {
      let detailsMessage: string | undefined = undefined
      const axiosError = error as AxiosError<VersionsManagementApiErrorResponse>
      if (axiosError.isAxiosError && axiosError.response?.data?.message) {
        detailsMessage = axiosError.response?.data?.message
      }
      toast.error(
        generateErrorMessage(selectedVersions, 'Duplicate', detailsMessage),
      )
    }

    if (closeParentModal) {
      closeParentModal()
    }
  }, [
    dateRangeState.startDate,
    dateRangeState.endDate,
    closeParentModal,
    openOperationErrorToast,
    copyVersionMutation,
    versionNameFieldState.value,
    sourceVersion.uuid,
    duplicatePermissions,
    duplicateLabels,
    selectedVersions,
    navigate,
    onAfterSuccess,
  ])

  return (
    <>
      {copyVersionMutation.isLoading ? (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        />
      ) : (
        <div /> // Maintain DOM-elements number and order
      )}
      <FormLayout
        submitButtonText="Duplicate"
        submitEnabled={
          !!dateRangeState.startDate &&
          !!dateRangeState.endDate &&
          !isNameExists(versionNameFieldState.value, versionNames) &&
          !copyVersionMutation.isLoading
        }
        onSubmit={handleSubmit}
        testIdPrefix="duplicate"
      >
        <TextField
          placeholder={'Origin version name would get a _copy'}
          value={versionNameFieldState.value}
          onChange={(event) =>
            versionNameFieldState.setValue(event.target.value)
          }
          required={true}
          fullWidth
          error={isNameExists(versionNameFieldState.value, versionNames)}
          helperText={
            isNameExists(versionNameFieldState.value, versionNames)
              ? 'Version with this name already exists'
              : ''
          }
          data-testid="duplicate-form-name"
          label="New name"
          disabled={copyVersionMutation.isLoading}
        />
        <Typography sx={{ marginTop: 4 }}>Date range:</Typography>
        <VersionDateRangeForm
          inputState={dateRangeState}
          horizontalLayout
          minEndDate={minEndDate}
          maxEndDate={maxEndDate}
          disabled={copyVersionMutation.isLoading}
        />
        <Divider orientation="horizontal" flexItem />
        <FormGroup sx={{ marginTop: 2, marginBottom: -2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={duplicatePermissions}
                onChange={handleChangeDuplicatePermissions}
                data-testid={'duplicate-permissions-checkbox'}
                disabled={copyVersionMutation.isLoading}
              />
            }
            label="Duplicate sharing settings"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={duplicateLabels}
                onChange={handleChangeDuplicateLabels}
                data-testid={'duplicate-labels-checkbox'}
                disabled={copyVersionMutation.isLoading}
              />
            }
            label="Duplicate labels"
          />
        </FormGroup>
        <Snackbar
          open={isOperationErrorToastOpened}
          autoHideDuration={6000}
          onClose={closeOperationErrorToast}
          message={
            <>
              <ErrorIcon fontSize="small" sx={{ mr: '11px' }} />
              Please enter start and end dates before
            </>
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </FormLayout>
    </>
  )
}
