import { MutationKey, useMutation, useQueryClient } from 'react-query'
import { updateFolder, EditableFolderParams } from '../api'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'
import type { BoardFolder } from '../types'

export const boardFolderUpdateMutationKeyGetter = (
  folderId: BoardFolder['id'],
) => {
  const key: MutationKey = `boardFolder/${folderId}/update`
  return key
}

export type UseBoardFolderUpdateMutationParams = {
  folderId: BoardFolder['id']
  refetchList?: boolean
  updateListCache?: boolean
}

export function useBoardFolderUpdateMutation(
  params: UseBoardFolderUpdateMutationParams,
) {
  const { folderId, refetchList = true, updateListCache = false } = params
  const queryClient = useQueryClient()

  return useMutation(
    boardFolderUpdateMutationKeyGetter(folderId),
    async (variables: EditableFolderParams) => {
      const response = await updateFolder(folderId, variables)
      return response.data
    },
    {
      onSuccess: (folder) => {
        if (updateListCache) {
          queryClient.setQueryData<BoardFolder[]>(
            boardsFoldersListQueryKeyGetter(),
            (foldersList) => {
              if (!foldersList) {
                return foldersList as unknown as BoardFolder[]
              }

              const folderIndex = foldersList.findIndex(
                (folderInList) => folderInList.id === folder.id,
              )
              if (folderIndex === -1) {
                return foldersList
              }

              const newFoldersList = [...foldersList]
              newFoldersList[folderIndex] = {
                ...newFoldersList[folderIndex],
                ...folder,
              }

              return newFoldersList
            },
          )
        }

        if (refetchList) {
          queryClient.invalidateQueries(boardsFoldersListQueryKeyGetter(), {
            exact: true,
          })
        }
      },
    },
  )
}
