import { ToolPanelDef } from 'ag-grid-community'
import { ListSettingsToolPanel } from './ListSettingsToolPanel'
import { resolveFintasticAgIconKey } from '@fintastic/shared/ui/ag-grid-theme-fintastic'

export const LIST_SETTINGS_TOOL_PANEL_ID = 'listSettings'

export const listSettingsToolPanelDefinition: ToolPanelDef = {
  id: LIST_SETTINGS_TOOL_PANEL_ID,
  labelDefault: '',
  toolPanel: ListSettingsToolPanel,
  labelKey: '',
  iconKey: resolveFintasticAgIconKey('settings'),
  maxWidth: 320,
  minWidth: 320,
  width: 320,
}
