import { alpha, Box, IconButton, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

const LINE_HEIGHT = 30
const VERTICAL_EXTRA_PADDING = 8

export const READONLY_CLASS_NAME = 'readonly'
export const EDITING_CLASS_NAME = 'editing'

export const StyledFormulaInputRoot = styled(Box)`
  .FormulaInput-root {
    overflow-x: hidden;
    overflow-y: auto;

    padding: ${VERTICAL_EXTRA_PADDING / 2 - 1}px ${8 - 1}px;
    border: 1px solid transparent;

    max-height: ${LINE_HEIGHT * 5 + VERTICAL_EXTRA_PADDING}px;

    white-space: initial;

    &.readonly {
      word-break: break-word;
    }

    padding-right: 32px;

    ${styledMixins.thinVScrollbar()};

    user-select: text;
    line-height: ${LINE_HEIGHT}px;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), 0 -4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition: ${({ theme }) =>
      theme.transitions.create('border', {
        duration: theme.transitions.duration.shorter,
      })};
    background: ${({ theme }) => theme.palette.common.white};

    & *::selection {
      background: ${({ theme }) =>
        alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity,
        )};
    }

    &.${EDITING_CLASS_NAME} {
      border-color: ${({ theme }) =>
        alpha(theme.palette.black.main, theme.palette.action.activatedOpacity)};
    }

    &.${EDITING_CLASS_NAME}:focus {
      outline: none;
      border-color: ${({ theme }) =>
        alpha(theme.palette.black.main, theme.palette.action.borderOpacity)};
    }
  }

  position: relative;

  .formula-copy {
    opacity: 0.4;
    position: absolute;
    top: 7px;
    right: 8px;
    height: 24px;
    width: 24px;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  &:hover .formula-copy {
    opacity: 1;
  }

  &.scrollable {
    .formula-copy {
      right: 16px;
    }
  }
}
`

export const StyledCopyButton = styled(IconButton)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border-color: transparent;

  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.palette.divider};
    background: ${({ theme }) => theme.palette.action.hover};
  }

  svg {
    width: 16px;
  }
`
