import { useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import {
  useAppInitializationSelectStatuses,
  useAppInitializationStart,
} from '@fintastic/web/feature/initialization'

export type UseRunAppInitializationParams = {
  isAuthenticated: boolean
}

export function useRunAppInitialization(params: UseRunAppInitializationParams) {
  const { isAuthenticated } = params

  const dispatch = useDispatch()
  const statuses = useAppInitializationSelectStatuses()
  const startInitialization = useAppInitializationStart()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    if (!statuses.isInitial) {
      return
    }
    startInitialization()
  }, [statuses, isAuthenticated, dispatch, startInitialization])

  return useMemo(
    () => ({
      ...statuses,
      isLoading: statuses.isLoading,
      isFinished: statuses.isFinished,
      isInitial: statuses.isInitial,
      error: statuses.error,
    }),
    [statuses],
  )
}
