import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ChartTypeLinesIcon = createSvgIcon(
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.70514 16.9564C9.31321 17.2677 8.75145 17.2413 8.39044 16.8946L2.91934 11.6402C2.57329 11.3078 2.03997 11.2681 1.64849 11.5455L1.18793 11.8718C0.689444 12.2251 0 11.8686 0 11.2577C0 11.0143 0.117615 10.786 0.31573 10.6448L1.79935 9.58693C2.19202 9.30695 2.72858 9.34712 3.0752 9.68244L8.48206 14.9131C8.84363 15.2629 9.40877 15.2896 9.80173 14.9755L13.4941 12.0241C13.6713 11.8824 13.8915 11.8053 14.1185 11.8053H17.2473C17.663 11.8053 18 12.1423 18 12.558C18 12.9737 17.663 13.3107 17.2473 13.3107H14.6432C14.4173 13.3107 14.1981 13.3871 14.0212 13.5277L9.70514 16.9564ZM9.73304 7.32973C9.33601 7.61592 8.79016 7.57269 8.44313 7.22755L4.91474 3.71843C4.56684 3.37244 4.01931 3.32996 3.62221 3.61815L1.19551 5.37932C0.697626 5.74066 0 5.38497 0 4.76978C0 4.52906 0.115072 4.30283 0.309631 4.16108L3.75395 1.65149C4.15108 1.36213 4.69959 1.40417 5.048 1.75067L8.57537 5.25878C8.92234 5.60386 9.46807 5.64715 9.86511 5.36109L16.7975 0.366419C17.2994 0.00474817 18 0.363459 18 0.982159C18 1.22609 17.8827 1.45516 17.6849 1.5978L9.73304 7.32973Z" />
  </svg>,
  'ChartTypeLinesIcon',
)
