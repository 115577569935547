import { RollUpFunction } from '@fintastic/shared/util/types'

const names: Record<RollUpFunction, string> = {
  sum: 'Sum',
  mean: 'Mean',
  last: 'Last',
  first: 'First',
  fin_last: 'Fin Last',
  fin_first: 'Fin First',
  noop: 'None',
  weighted_avg: 'Weighted Average',
  last_not_blank: 'Last Not Blank',
  first_not_blank: 'First Not Blank',
}

export function resolveRollUpName(rollUp: RollUpFunction): string {
  return names[rollUp] || rollUp
}
