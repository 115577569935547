import { Box, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;

  width: 100%;
  height: 100%;

  ${styledMixins.thinVScrollbar()};
`

export const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2, 2, 1)};

  width: 100%;
`

export const StyledTitle = styled(Box)``

export const StyledButtons = styled(Box)``

export const StyledColumns = styled(Box)``

export const StyledSearch = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1, 2, 1)};

  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart {
    padding-left: 0;
  }
`
