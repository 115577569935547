import { Box, IconButton, IconButtonProps, styled } from '@mui/material'

export const StyledChartDebugPanel = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding-left: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
`

export const StyledDebugButton = styled(
  (props: { active?: boolean } & IconButtonProps) => {
    const { active, ...buttonProps } = props
    return <IconButton {...buttonProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'active',
  },
)`
  margin-right: ${({ theme }) => theme.spacing(1)};

  border-radius: 0;
  box-sizing: border-box;
  background: none;

  svg {
    max-height: 14px;
    max-width: 14px;
  }

  &,
  &:hover,
  &:focus {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 2px solid
      ${({ active, theme }) =>
        active ? theme.palette.primary.main : 'transparent'};

    svg {
      fill: ${({ theme, active }) =>
        active ? theme.palette.primary.main : theme.palette.text.primary};
      color: ${({ theme, active }) =>
        active ? theme.palette.primary.main : theme.palette.text.primary};
    }
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
