import {
  PeriodSelection,
  PeriodsRange,
  convertPeriodSelectionToRange,
  isEmptyPeriodSelection,
} from '@fintastic/web/util/period-selector'
import { CalendarDatePickerConfig } from '@fintastic/web/util/period-selector'
import {
  getAggregationTypes,
  getPlainAggregationTypes,
  getAggregationTypesDiffList,
  isTheHighestGranularAggregation,
} from '../utils'
import { DimensionId } from '@fintastic/web/util/dimensions'

export const getHigherAggregationRange = (
  datePickerConfig: CalendarDatePickerConfig,
  nextDimensionId: DimensionId,
  periodSelection: PeriodSelection,
): PeriodsRange => {
  if (isEmptyPeriodSelection(periodSelection)) {
    return periodSelection
  }

  const aggregationTypesPlain = getPlainAggregationTypes(
    getAggregationTypes(datePickerConfig),
  )

  const range = convertPeriodSelectionToRange(periodSelection, datePickerConfig)
  if (
    isTheHighestGranularAggregation(
      aggregationTypesPlain,
      periodSelection.dimensionId,
    )
  ) {
    return range
  }

  const steps = [
    periodSelection.dimensionId,
    ...getAggregationTypesDiffList(
      aggregationTypesPlain,
      nextDimensionId,
      periodSelection.dimensionId,
    ),
  ]

  return steps.reduce<PeriodsRange>(([from, to], step) => {
    const stepIndex = datePickerConfig.findIndex((i) => i.dimension_id === step)

    const upperFrom = datePickerConfig[stepIndex].values.find(
      (i) => i.dimension_value_id === from,
    )
    const upperTo = datePickerConfig[stepIndex].values.findLast(
      (i) => i.dimension_value_id === to,
    )

    const nextFrom = datePickerConfig[stepIndex + 1]?.values.find(
      (i) => i.dimension_value_id === upperFrom?.groups?.[0],
    )
    const nextTo = datePickerConfig[stepIndex + 1]?.values.findLast(
      (i) => i.dimension_value_id === upperTo?.groups?.[0],
    )

    if (!nextFrom || !nextTo) {
      throw new Error('Could not find higher granularity value!')
    }

    return [nextFrom.dimension_value_id, nextTo.dimension_value_id] as const
  }, range)
}
