import { RouteObject } from '@fintastic/shared/util/memory-router'
import { List } from './views/List'
import { Column } from './views/Column'
import { ColumnAggregationFunction } from './views/ColumnAggregationFunction'
import { memoryRoutesPaths } from './memory-routes-paths'
import { ColumnValueDataFormat } from './views/ColumnValueDataFormat'
export const memoryRoutes: RouteObject[] = [
  {
    path: memoryRoutesPaths.list,
    element: <List />,
  },
  {
    path: memoryRoutesPaths.column(),
    element: <Column />,
  },
  {
    path: memoryRoutesPaths.columnValueDataFormat(),
    element: <ColumnValueDataFormat />,
  },
  {
    path: memoryRoutesPaths.columnAggregationFunction(),
    element: <ColumnAggregationFunction />,
  },
]
