import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .tooltip-header {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    .tooltip-header-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline;
    }

    & span {
      // sorting arrow fine tune
    }
  }
`
