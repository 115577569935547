import { Button, styled } from '@mui/material'

export const StyledSelectedCellAggregationButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};

  &:hover {
    color:  ${theme.palette.primary.main};
  }
`,
)
