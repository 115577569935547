import { useMutation, useQueryClient } from 'react-query'
import { VersionAccess } from '@fintastic/web/util/versions'
import { updateVersionAccess } from '../../api/versions-access-api'
import { invalidateVersionAccessCache } from '../../cache'

export type UseChangeVersionsAccessParams = {
  versionId: string
  access: VersionAccess
}

export const useChangeAccessForVersionsList = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (versions: UseChangeVersionsAccessParams[]) => {
      for (let i = 0; i < versions.length; i++) {
        await updateVersionAccess(versions[i].versionId, versions[i].access)
      }
    },
    {
      onSuccess: async (data, variables) => {
        await invalidateVersionAccessCache(
          queryClient,
          variables.map((v) => v.versionId),
        )
      },
    },
  )
}
