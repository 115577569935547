import { useCallback, useEffect, useMemo, useState } from 'react'

export type UseEditorModeOptions = {
  focusInput: () => void
  readonly: boolean
}

// @todo add tests
export function useEditorMode(
  defaultEditingState = false,
  { focusInput, readonly }: UseEditorModeOptions,
) {
  const [isEditing, setIsEditing] = useState(defaultEditingState)

  const enterEditingMode = useCallback(() => {
    if (readonly) {
      return
    }
    setIsEditing(true)
  }, [readonly])

  const exitEditingMode = useCallback(() => {
    setIsEditing(false)
  }, [])

  useEffect(() => {
    if (!isEditing) {
      return
    }
    focusInput()
  }, [isEditing, focusInput])

  useEffect(() => {
    if (readonly) {
      exitEditingMode()
    }
  }, [exitEditingMode, readonly])

  return useMemo(
    () =>
      ({
        isEditing,
        enterEditingMode,
        exitEditingMode,
      } as const),
    [enterEditingMode, exitEditingMode, isEditing],
  )
}
