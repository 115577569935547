import {
  FilterChangedEvent,
  GridReadyEvent,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { useCallback, useMemo, useRef } from 'react'
import { useSessionStorage } from 'usehooks-ts'
import type { Maybe } from '@fintastic/shared/util/types'
import { isEmpty } from 'lodash'

export const usePersistentFilterModel = <T>(
  key: string,
  columnKeyReplacer?: (key: string) => string,
) => {
  const [filterModelValue, setFilterModelValue] = useSessionStorage<
    Maybe<Record<string, unknown>>
  >(`ag-persistent-filters:${key}`, null)
  const filterModelValueRef = useRef<Maybe<Record<string, unknown>>>()
  filterModelValueRef.current = filterModelValue

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent<T>) => {
      if (!e.columns?.length) {
        return
      }
      const model = e.api.getFilterModel()
      filterModelValueRef.current = model
      setFilterModelValue(model)
    },
    [setFilterModelValue],
  )

  const handleRestoreFilter = useCallback(
    ({ api }: GridReadyEvent<T> | RowDataUpdatedEvent<T>) => {
      if (!isEmpty(filterModelValueRef.current)) {
        if (columnKeyReplacer) {
          filterModelValueRef.current = Object.fromEntries(
            Object.entries(filterModelValueRef.current || {}).map(([k, v]) => [
              columnKeyReplacer(k),
              v,
            ]),
          )
        }
        api.setFilterModel(filterModelValueRef.current)
      }
    },
    [columnKeyReplacer],
  )

  return useMemo(
    () =>
      ({
        onFilterChanged,
        onFirstDataRendered: handleRestoreFilter,
        onRowDataUpdated: handleRestoreFilter,
      } as const),
    [onFilterChanged, handleRestoreFilter],
  )
}
