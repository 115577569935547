import React from 'react'
import {createSvgIcon} from '@mui/material'


export const ArrowsSyncIcon = createSvgIcon(
  <>
    <path d="M4.82209 8.90846C5.41726 7.89622 6.25973 6.93579 7.25424 6.1592C8.65555 5.06494 10.2962 4.38327 11.8849 4.38297C15.0656 4.39238 17.9837 6.46216 19.0438 9.46098C19.1841 9.85762 19.6193 10.0655 20.0159 9.92527C20.4125 9.78505 20.6204 9.34983 20.4802 8.9532C19.2052 5.34677 15.7132 2.87025 11.8881 2.8595H11.886C9.87221 2.8595 7.91039 3.71388 6.31659 4.95845C5.28747 5.76208 4.38572 6.74825 3.70308 7.81905C3.63494 7.47898 3.57678 7.11189 3.5167 6.66736C3.46035 6.25046 3.0767 5.95817 2.65979 6.01453C2.24289 6.07088 1.9506 6.45453 2.00695 6.87143C2.18672 8.20138 2.36167 8.93925 2.78836 10.1575C2.90747 10.4976 3.24858 10.7077 3.60588 10.661C4.88585 10.4939 5.62352 10.3182 6.89211 9.88031C7.28979 9.74305 7.5009 9.30941 7.36364 8.91173C7.22638 8.51406 6.79273 8.30295 6.39506 8.4402C5.77308 8.65488 5.30177 8.7983 4.82209 8.90846Z"/>
    <path d="M11.865 19.617C14.8039 19.4464 17.4762 17.7396 18.9141 15.2223C18.4816 15.3612 18.0479 15.5311 17.491 15.7707C17.1046 15.937 16.6565 15.7585 16.4902 15.3721C16.3239 14.9857 16.5024 14.5376 16.8888 14.3713C18.1136 13.8442 18.8349 13.6139 20.0838 13.3546C20.2229 13.3205 20.3732 13.3251 20.5183 13.3763C20.7467 13.4569 20.9125 13.6352 20.9855 13.8477C21.4838 14.9978 21.7101 15.7184 21.9834 17.0104C22.0704 17.422 21.8073 17.8262 21.3957 17.9133C20.9841 18.0003 20.5799 17.7372 20.4929 17.3257C20.3884 16.8316 20.2936 16.4352 20.1862 16.066C18.4801 18.9842 15.363 20.9487 11.9283 21.1393C11.9143 21.1401 11.9003 21.1405 11.8862 21.1405C8.08041 21.1412 4.59447 18.6997 3.29414 15.123C3.15041 14.7276 3.3544 14.2905 3.74978 14.1468C4.14515 14.0031 4.58219 14.2071 4.72593 14.6024C5.80494 17.5704 8.70805 19.6085 11.865 19.617Z"/>
    <path d="M11.8312 7.09386C12.2519 7.09386 12.593 7.43491 12.593 7.8556V12.0223C12.593 12.443 12.2519 12.7841 11.8312 12.7841C11.4105 12.7841 11.0695 12.443 11.0695 12.0223V7.8556C11.0695 7.43491 11.4105 7.09386 11.8312 7.09386Z"/>
    <path d="M12.593 15.7657C12.593 15.345 12.2519 15.0039 11.8312 15.0039C11.4105 15.0039 11.0695 15.345 11.0695 15.7657V15.9749C11.0695 16.3955 11.4105 16.7366 11.8312 16.7366C12.2519 16.7366 12.593 16.3955 12.593 15.9749V15.7657Z"/>
  </>,
  'Sync',
)

