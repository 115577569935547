import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ViewOnIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.816 5.83678C11.3843 5.86014 11.1103 5.88761 10.7471 5.94394C9.09095 6.20081 7.30989 6.93655 5.62798 8.05861C4.50887 8.8052 3.45636 9.70173 2.55992 10.672C2.41829 10.8253 2.36664 10.8894 2.28612 11.012C2.1488 11.2211 2.05576 11.4598 2.01212 11.715C1.99528 11.8136 1.9951 12.2135 2.01187 12.3169C2.06291 12.6316 2.21258 12.9586 2.41609 13.2C2.57316 13.3864 3.08535 13.9029 3.43214 14.2248C5.07859 15.7528 6.89698 16.9107 8.64941 17.5469C11.0432 18.4161 13.2937 18.3717 15.7007 17.408C17.2503 16.7876 18.8776 15.732 20.3563 14.388C20.674 14.0992 21.1726 13.6075 21.4353 13.324C21.7009 13.0373 21.8402 12.7993 21.9277 12.4826C22.0063 12.1984 22.0125 11.8599 21.9444 11.572C21.8815 11.3064 21.7525 11.0437 21.5808 10.832C21.4538 10.6753 21.0017 10.2059 20.6879 9.90481C18.9759 8.26241 17.0279 7.01783 15.156 6.37047C14.3203 6.08146 13.527 5.91323 12.708 5.85134C12.5593 5.8401 11.9411 5.83001 11.816 5.83678ZM11.732 8.17705C11.0778 8.22325 10.4443 8.43743 9.89662 8.79761C9.45994 9.08477 9.08476 9.45996 8.7976 9.89664C7.95837 11.1728 7.95837 12.8272 8.7976 14.1034C9.08476 14.54 9.45994 14.9152 9.89662 15.2024C10.5915 15.6594 11.4279 15.8802 12.2493 15.8235C13.1915 15.7586 14.0445 15.3716 14.708 14.7081C15.018 14.3981 15.2455 14.0808 15.4394 13.688C16.0372 12.4769 15.9467 11.0285 15.2024 9.89664C14.9152 9.45996 14.54 9.08477 14.1033 8.79761C13.4044 8.33799 12.5647 8.11825 11.732 8.17705ZM11.748 10.3522C11.215 10.4342 10.7584 10.7653 10.5126 11.248C10.3911 11.4867 10.3361 11.7211 10.3361 12C10.3361 12.4618 10.5087 12.8723 10.8378 13.1931C11.1558 13.5031 11.5528 13.6639 12 13.6639C12.4618 13.6639 12.8723 13.4913 13.1931 13.1622C13.4025 12.9474 13.5494 12.6842 13.6187 12.3997C13.682 12.1399 13.6796 11.83 13.6123 11.5731C13.4922 11.1153 13.1702 10.7216 12.7395 10.5058C12.5831 10.4275 12.4264 10.3787 12.244 10.3516C12.1059 10.331 11.8839 10.3313 11.748 10.3522Z"
  />,
  'ViewOn',
)
