import { Box, Divider } from '@mui/material'
import React from 'react'

export type ModalFooterProps = {
  children?: React.ReactNode
}

// @todo ipomazkin-fin to implement
export const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => (
  <div>
    <Divider />
    <Box py={2} px={3}>
      {children}
    </Box>
  </div>
)
