import type { Maybe } from '@fintastic/shared/util/types'
import type { QueryParams } from './types'
import { Metric } from '@fintastic/web/util/metrics-and-lists'

export type MetricLike = {
  version: string
  metric: Maybe<{
    metadata: {
      value: Pick<Metric['metadata']['value'], 'weights_metric_id'>
    }
  }>
}

export function extractQueryParams(metrics: MetricLike[]): QueryParams[] {
  return metrics
    .filter((m) => !!m.metric?.metadata.value.weights_metric_id)
    .map<QueryParams>((m) => ({
      version: m.version,
      metric: m.metric?.metadata.value.weights_metric_id as string,
    }))
}
