import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  StyledAutoDropdownItem,
  StyledAutoDropdownMenuButtonRoot,
  StyledAutoDropdownMenuPopover,
  StyledAutoDropdownRoot,
  StyledAutoDropdownWrapper,
} from './AutoDropdown.styled'
import useResizeObserver from 'use-resize-observer'
import {
  ContextMenuIcon,
  useContextMenuState,
} from '@fintastic/shared/ui/context-menu-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { IconButton, PopoverOrigin, Tooltip } from '@mui/material'

const contextMenuOrigin: {
  anchorOrigin: PopoverOrigin
  transformOrigin: PopoverOrigin
} = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: -6,
    horizontal: 'right',
  },
}

type AutoDropdownProps = {
  items?: Array<ReactNode>
}

export const AutoDropdown: React.FC<AutoDropdownProps> = ({ items }) => {
  const ref = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const contextMenuButtonRef = useRef<Maybe<HTMLButtonElement>>(null)

  const contextMenuState = useContextMenuState()

  const handleOpenContextMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()

      if (contextMenuButtonRef.current === null) {
        return
      }

      contextMenuState.handleClickOnAnchor({
        target: contextMenuButtonRef.current,
      })
    },
    [contextMenuState],
  )

  const { width } = useResizeObserver({
    ref: wrapperRef,
    box: 'border-box',
  })

  const [breakAt, setBreakAt] = useState(-1)

  useLayoutEffect(() => {
    if (!ref.current || !width) {
      return
    }
    const container = ref.current
    const button = contextMenuButtonRef.current

    if (!container || !button) {
      return
    }

    const items = container.querySelectorAll('.__autodropdown_item')

    let currentWidth = 32 // dropdown menu size
    let breakPosition = -1

    items.forEach((item, i) => {
      const itemWidth = item.getBoundingClientRect().width + 6 // gap

      if (currentWidth + itemWidth > width && breakPosition === -1) {
        container.style.maxWidth = currentWidth + 'px'
        breakPosition = i - 1
        button.style.display = 'flex'
      }
      currentWidth += itemWidth
    })

    if (breakPosition === -1) {
      container.style.maxWidth = width + 'px'
      button.style.display = 'none'
    }

    contextMenuState.handleClose()
    setBreakAt(() => breakPosition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, items])

  const displayItems = useMemo(() => {
    if (!width || !items || items.length === 0) {
      return []
    }
    const result: Array<ReactNode> = []
    items.forEach((item, i) => {
      result.push(
        <StyledAutoDropdownItem
          key={'_key_' + width + '_' + i}
          className={'__autodropdown_item'}
        >
          {item}
        </StyledAutoDropdownItem>,
      )
    })
    return result
  }, [items, width])

  const menuItems = useMemo(() => {
    if (breakAt === -1 || !displayItems || displayItems.length === 0) {
      contextMenuState.handleClose()
      return []
    }

    const result = displayItems.slice(breakAt + 1, displayItems.length)
    if (result.length === 0) {
      contextMenuState.handleClose()
    }
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakAt, displayItems])

  return (
    <StyledAutoDropdownWrapper ref={wrapperRef}>
      <StyledAutoDropdownRoot ref={ref}>
        {displayItems}

        <StyledAutoDropdownMenuButtonRoot data-testid="dropdown-container">
          <Tooltip
            title={`The rest of versions (+${menuItems.length})`}
            placement="bottom"
            arrow
          >
            <IconButton
              disableRipple
              ref={contextMenuButtonRef}
              className={'design-menu'}
              size="small"
              onClick={handleOpenContextMenu}
              sx={{
                backgroundColor: contextMenuState.isOpen
                  ? 'rgba(38, 54, 70, 0.08)'
                  : '#fff',
              }}
            >
              <ContextMenuIcon />
            </IconButton>
          </Tooltip>
        </StyledAutoDropdownMenuButtonRoot>

        {menuItems.length > 0 && (
          <StyledAutoDropdownMenuPopover
            open={contextMenuState.isOpen}
            anchorEl={contextMenuState.anchorElement}
            onClose={contextMenuState.handleClose}
            {...contextMenuOrigin}
          >
            {menuItems}
          </StyledAutoDropdownMenuPopover>
        )}
      </StyledAutoDropdownRoot>
    </StyledAutoDropdownWrapper>
  )
}
