import React, { useCallback, useContext, useMemo } from 'react'
import { baseGridContext } from '@fintastic/shared/ui/grid-framework'
import {
  CollapseExpandButton as PanelFrameworkCollapseExpandButton,
  PanelToolbarIconButtonProps,
} from '@fintastic/shared/ui/panel-framework'
import { SvgIconProps } from '@mui/material'

const defaultIconProps: SvgIconProps = {
  fontSize: 'medium',
}

export type CollapseExpandButtonProps = Omit<
  PanelToolbarIconButtonProps,
  'children' | 'title' | 'onClick'
> & {
  iconProps?: SvgIconProps
  handleCollapse?: () => void
}

export const CollapseExpandButton = ({
  iconProps = defaultIconProps,
  handleCollapse,
  ...rest
}: CollapseExpandButtonProps): JSX.Element | null => {
  const gridContext = useContext(baseGridContext)
  const [collapsed, setCollapseState] = gridContext.collapseState

  const mergedIconProps = useMemo(
    () => ({
      ...defaultIconProps,
      ...iconProps,
    }),
    [iconProps],
  )

  const handleClick = useCallback(() => {
    if (setCollapseState) {
      handleCollapse?.()
      setCollapseState(!collapsed)
    }
  }, [collapsed, handleCollapse, setCollapseState])

  return (
    <PanelFrameworkCollapseExpandButton
      isCollapsed={collapsed}
      onClick={handleClick}
      data-testid="collapseExpandButton"
      iconProps={mergedIconProps}
      {...rest}
    />
  )
}
