import { styled } from '@mui/material/styles'
import { Box, Button, TextField } from '@mui/material'

export const StyledPageContainer = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledDetailGridContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#F9F7F5',
  padding: '16px',
  height: '100%',
}))

export const StyledInlineButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
}))

export const StyledVersionEditableNameField = styled(TextField)(
  ({ theme }) => ({
    'width': '80%',
    'maxWidth': '70%',
    'marginBottom': 32,
    'marginRight': 'auto',
    '.MuiInputBase-input': {
      'fontSize': `${24 / 14}rem`,
      'fontWeight': 900,
      'lineHeight': 33 / 24,
      '&.Mui-disabled': {
        textFillColor: '#263646',
      },
    },
  }),
)

export const StyledVersionTitle = styled(Box)(({ theme }) => ({
  'display': 'flex',
  'justifyContent': 'space-between',
  'flexDirection': 'row',
  'width': '100%',
  '&:hover .version-menu-button': {
    pointerEvents: 'all',
  },
}))

export const StyledVersionTablesGrid = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
}))

export const StyledVersionSourceCol = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))
