import { styled, Box, alpha } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  background: ${({ theme }) => alpha(theme.palette.common.white, 0.92)};

  ${styledMixins.alignContentCenter()};
`
