import { texts } from '@fintastic/web/data-access/versions'
import { UnsupportedGranularityAggregationError } from '@fintastic/web/util/period-selector'

export type ListGridError =
  | VersionsAreNotSelectedError
  | ListDoesNotExistError
  | NetworkError
  | RestrictedByPermissionsError
  | UnsupportedGranularityAggregationError
  | UnknownError

export class VersionsAreNotSelectedError extends Error {
  public ERROR_TEXT = texts.noVersionsSharedWithUser

  constructor() {
    super()
    this.name = 'VersionsAreNotSelectedError'
    Object.setPrototypeOf(this, VersionsAreNotSelectedError.prototype)
  }
}

export class ListDoesNotExistError extends Error {
  public ERROR_TEXT = 'The List does not exist in the selected version'

  constructor() {
    super()
    this.name = 'ListDoesNotExistError'
    Object.setPrototypeOf(this, ListDoesNotExistError.prototype)
  }
}

export class NetworkError extends Error {
  public ERROR_TEXT = 'Something went wrong during the List loading'

  constructor() {
    super()
    this.name = 'NetworkError'
    Object.setPrototypeOf(this, NetworkError.prototype)
  }
}

export class RestrictedByPermissionsError extends Error {
  public ERROR_TEXT =
    'Your permissions settings do not allow you to view the data in this List'

    constructor() {
    super()
    this.name = 'RestrictedByPermissionsError'
    Object.setPrototypeOf(this, RestrictedByPermissionsError.prototype)
  }
}

export class UnknownError extends Error {
  public originalError: Error
  public ERROR_TEXT = 'Something went wrong during the List processing'

  constructor(originalError: Error) {
    super(originalError.message)
    this.originalError = originalError
    this.name = 'UnknownError'
    Object.setPrototypeOf(this, UnknownError.prototype)
  }
}
