import { createKeys } from './utils'

export const chartXAxisAlignments = {
  sloping: 'Sloping',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
}

export type ChartXAxisAlignment = keyof typeof chartXAxisAlignments

export const charXAxisAlignmentKeys: ChartXAxisAlignment[] =
  createKeys<ChartXAxisAlignment>(chartXAxisAlignments)

export function isChartXAxisAlignment(
  value: unknown,
): value is ChartXAxisAlignment {
  return chartXAxisAlignments[value as ChartXAxisAlignment] !== undefined
}

// Format
export const chartXAxisFormats = {
  standard: 'Standard',
  compact: 'Compact',
}

export type ChartXAxisFormat = keyof typeof chartXAxisFormats

export const chartXAxisFormatKeys: ChartXAxisFormat[] =
  createKeys<ChartXAxisFormat>(chartXAxisFormats)

export function isChartXAxisFormat(value: unknown): value is ChartXAxisFormat {
  return chartXAxisFormats[value as ChartXAxisFormat] !== undefined
}

export const defaultChartXAxisAlignment: ChartXAxisAlignment = 'sloping'

export const defaultChartXAxisFormat: ChartXAxisFormat = 'standard'

export function getXAxisAlignmentName(xAxisAlignment?: ChartXAxisAlignment) {
  return chartXAxisAlignments[
    isChartXAxisAlignment(xAxisAlignment)
      ? xAxisAlignment
      : defaultChartXAxisAlignment
  ]
}

export function getXAxisFormatName(xAxisFormat?: ChartXAxisFormat) {
  return chartXAxisFormats[
    isChartXAxisFormat(xAxisFormat) ? xAxisFormat : defaultChartXAxisFormat
  ]
}
