import { ThemeComponents } from './types'
import { styleKit } from '../styleKit'

export const MuiTab: ThemeComponents['MuiTab'] = {
  styleOverrides: {
    root: {
      fontSize: styleKit.typography.defaults.fontSize,
      textTransform: 'none',
    },
  },
}
