import React, { useCallback, useMemo, useState } from 'react'
import {
  CheckboxesList,
  CheckboxesListChangeHandler,
  CheckboxesListOption,
} from '@fintastic/shared/ui/components'
import { Box, TextField } from '@mui/material'
import { FilterNoResults } from '../FilterNoResults'

export const DimensionFilterInput: React.FC<DimensionFilterInputProps> = ({
  options,
  selected,
  onChange,
}) => {
  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (e) => {
      setSearch(e.target.value)
    },
    [setSearch],
  )

  const filteredOptions = useMemo<CheckboxesListOption[]>(() => {
    const trimmedSearch = search.trim().toLocaleLowerCase()
    if (!trimmedSearch) {
      return options
    }

    return options.filter(({ label }) =>
      label.toLocaleLowerCase().includes(trimmedSearch),
    )
  }, [search, options])

  const allSelected = selected.length === options.length
  const handleSelectAll = useCallback(() => {
    setSearch('')
    onChange(options.map((i) => i.value))
  }, [onChange, options])

  return (
    <Box my={1}>
      <Box my={1}>
        <TextField
          onChange={handleSearchChange}
          placeholder="Search"
          fullWidth
          size="small"
          autoComplete="off"
          value={search}
        />
      </Box>
      {filteredOptions.length ? (
        <CheckboxesList
          options={filteredOptions}
          onChange={onChange}
          selectedOptions={selected}
          selectAll
          allSelected={allSelected}
          onSelectAll={handleSelectAll}
        />
      ) : (
        <FilterNoResults />
      )}
    </Box>
  )
}

export type DimensionFilterInputProps = {
  options: Array<{ value: string; label: string }>
  selected: string[]
  onChange: CheckboxesListChangeHandler
}

DimensionFilterInput.displayName = 'DimensionFilterInput'
