import { createContext, useContext } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricAsOption } from '@fintastic/web/util/metrics-and-lists'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { ListWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { DependencyGraphWrapper } from '@fintastic/web/util/versions'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export type DataContextValue = {
  list: Maybe<ListWrapper>
  dimensionsList: Maybe<VersionDimension[]>
  currenciesList: Maybe<Currency[]>
  metricsList: Maybe<MetricAsOption[]>
  dependencyGraph: Maybe<DependencyGraphWrapper>
  isLoading: boolean
  isLoaded: boolean
  load: () => void
}

const stub = () => {
  throw new Error('DataContext is not initialised')
}

export const DataContext = createContext<DataContextValue>({
  list: null,
  dimensionsList: [],
  currenciesList: [],
  metricsList: [],
  dependencyGraph: null,
  isLoading: false,
  isLoaded: false,
  load: stub,
})

export const useDataContext = () => useContext(DataContext)
