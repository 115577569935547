import { Box, Button, styled, Typography } from '@mui/material'

export const StyledFormLayoutRoot = styled('form')(({ theme }) => ({
  position: 'relative',

  width: '100%',
}))

export const StyledFormLayoutMain = styled(Box)(({ theme }) => ({}))

export const StyledFormLayoutLoader = styled(Box)(({ theme }) => ({}))

export const StyledFormLayoutFooter = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}))

export const StyledFormLayoutSubmitContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
}))

export const StyledFormLayoutCancelButton = styled(Button)`
  margin-right: 6px;
`

export const StyledFormLayoutSubmitButton = styled(Button)(({ theme }) => ({}))

export const StyledFormLayoutSubmitErrorMessage = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacingFromPixels(8),

    color: theme.palette.error.main,
  }),
)
