import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  StyledVerticalTwoResizableContainersRoot,
  StyledVerticalTwoResizableContainersContainer,
  StyledVerticalTwoResizableContainersWrapper,
} from './VerticalTwoResizableContainers.styled'
import { Maybe } from '@fintastic/shared/util/types'
import { Resizable, ResizeCallbackData, ResizeHandle } from 'react-resizable'
import 'react-resizable/css/styles.css'
import { renderHandleCallback } from './Handle'

const minConstraints = [0, 0] as [number, number]
const resizeHandles: Array<ResizeHandle> = ['s']

// Suppress un-used prop types warning
// eslint-disable-next-line react/forbid-foreign-prop-types
if (typeof (Resizable as any)?.propTypes !== 'undefined') {
  (Resizable as any).propTypes = undefined
}

export type VerticalTwoResizableContainersProps = {
  maxTopPanelHeightRatio?: number
  defaultTopPanelHeightRatio?: number
  minTopPanelHeightRatio?: number
  disablePaddingForHandleInTop?: boolean
  disablePaddingForHandleInBottom?: boolean
} & (
  | {
      topContainer?: React.ReactNode
      bottomContainer: React.ReactNode
    }
  | {
      topContainer: React.ReactNode
      bottomContainer?: React.ReactNode
    }
)

export const VerticalTwoResizableContainers: React.FC<
  VerticalTwoResizableContainersProps
> = (props) => {
  const {
    topContainer,
    bottomContainer,
    minTopPanelHeightRatio = 0.25,
    maxTopPanelHeightRatio = 0.75,
    defaultTopPanelHeightRatio = 0.5,
    disablePaddingForHandleInTop = false,
    disablePaddingForHandleInBottom = false,
  } = props

  const [height, setHeight] = useState<Maybe<number>>(null)
  const rootRef = useRef<Maybe<HTMLElement>>(null)
  const topContainerRef = useRef<Maybe<HTMLElement>>(null)

  useEffect(() => {
    if (bottomContainer && rootRef.current) {
      setHeight(
        rootRef.current.getBoundingClientRect().height *
          defaultTopPanelHeightRatio,
      )
    }
  }, [bottomContainer, defaultTopPanelHeightRatio])

  const handleResize = useCallback(
    (event: React.SyntheticEvent, data: ResizeCallbackData) => {
      if (height === null && topContainerRef.current) {
        setHeight(
          topContainerRef.current.getBoundingClientRect().height +
            data.size.height,
        )
        return
      }
      if (rootRef.current) {
        setHeight(
          Math.max(
            Math.min(
              data.size.height,
              rootRef.current.getBoundingClientRect().height *
                maxTopPanelHeightRatio,
            ),
            rootRef.current.getBoundingClientRect().height *
              minTopPanelHeightRatio,
          ),
        )
        return
      }
      setHeight(data.size.height)
    },
    [height, maxTopPanelHeightRatio, minTopPanelHeightRatio],
  )

  const resizeHandleCallback = useCallback(
    (handleAxis: ResizeHandle, ref: React.ForwardedRef<HTMLElement>) =>
      renderHandleCallback(handleAxis, ref, !!bottomContainer),
    [bottomContainer],
  )

  return (
    <StyledVerticalTwoResizableContainersRoot ref={rootRef}>
      <StyledVerticalTwoResizableContainersWrapper>
        {topContainer && (
          <Resizable
            axis="y"
            height={height || 0}
            width={undefined}
            onResize={handleResize}
            minConstraints={minConstraints}
            resizeHandles={resizeHandles}
            handle={resizeHandleCallback}
          >
            <StyledVerticalTwoResizableContainersContainer
              isSingle={!bottomContainer}
              ref={topContainerRef}
              style={{
                height: bottomContainer ? height || '100%' : undefined,
                minHeight: bottomContainer ? height || '100%' : undefined,
              }}
              sx={
                bottomContainer && !disablePaddingForHandleInTop
                  ? { paddingBottom: `15px` }
                  : {}
              }
            >
              {topContainer}
            </StyledVerticalTwoResizableContainersContainer>
          </Resizable>
        )}
        {bottomContainer && (
          <StyledVerticalTwoResizableContainersContainer
            isSingle={!topContainer}
            sx={
              topContainer && !disablePaddingForHandleInBottom
                ? { paddingTop: '15px' }
                : {}
            }
          >
            {bottomContainer}
          </StyledVerticalTwoResizableContainersContainer>
        )}
      </StyledVerticalTwoResizableContainersWrapper>
    </StyledVerticalTwoResizableContainersRoot>
  )
}
