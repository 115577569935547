import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

export const DiscardConfirmationModal: React.FC<{
  isOpen: boolean
  close: () => void
  onConfirm: () => void
  discarding: boolean
}> = ({ isOpen, onConfirm, discarding, close }) => (
  <ConfirmationPopup
    open={isOpen}
    onRequestClose={close}
    onConfirm={onConfirm}
    title={'Cancel Report editing?'}
    description="Unsaved settings will be lost."
  >
    <ConfirmationPopup.DeclineButton variant="contained" color="primary">
      Continue editing
    </ConfirmationPopup.DeclineButton>
    <ConfirmationPopup.ConfirmButton sx={{ ml: 1 }} disabled={discarding}>
      {discarding ? 'Cancelling...' : 'Yes, Cancel'}
    </ConfirmationPopup.ConfirmButton>
  </ConfirmationPopup>
)
