import React from 'react'
import { createSvgIcon } from '@mui/material'

const paths = [
  'M6.87189 7.53922C6.14715 7.47039 5.50805 8.02796 5.44217 8.79204L5.27745 10.7814H7.13543V12.1581H5.15886L4.86896 15.6481C4.83947 16.0082 4.74218 16.3586 4.58267 16.6793C4.42317 17 4.2046 17.2846 3.93951 17.5168C3.67442 17.749 3.36804 17.9242 3.03796 18.0323C2.70789 18.1404 2.36062 18.1793 2.01611 18.1468C1.60745 18.1106 1.21248 17.9755 0.862528 17.752C0.512575 17.5286 0.21726 17.223 0 16.8596L0.988287 15.8271C1.14641 16.3365 1.58126 16.7288 2.12811 16.777C2.85285 16.8458 3.49195 16.2883 3.55783 15.5242L3.84114 12.1581H1.86457V10.7814H3.95315L4.13104 8.66813C4.25622 7.15374 5.53441 6.03172 6.9839 6.16939C7.847 6.24511 8.57174 6.74761 9 7.45662L8.01171 8.48916C7.85359 7.97977 7.41874 7.58741 6.87189 7.53922Z',
  'M12.906 7.13796L12.892 7.19396C12.332 7.97796 11.8933 8.84129 11.576 9.78396C11.2587 10.7173 11.1 11.6646 11.1 12.626C11.1 13.5966 11.2587 14.5533 11.576 15.496C11.8933 16.4293 12.332 17.288 12.892 18.072L11.716 18.842C11.072 17.9553 10.568 16.9846 10.204 15.93C9.84933 14.8753 9.672 13.774 9.672 12.626C9.672 11.4873 9.84933 10.3906 10.204 9.33596C10.568 8.27196 11.072 7.29663 11.716 6.40996L12.906 7.13796Z',
  'M15.8306 13.046L13.5066 9.93796H15.5506L16.8946 11.954L18.3506 9.93796H20.2686L17.9726 13.046L20.6746 16.658H18.6306L16.8806 14.25L15.1166 16.658H13.1286L15.8306 13.046Z',
  'M20.9001 18.114L20.9141 18.058C21.4741 17.274 21.9128 16.4153 22.2301 15.482C22.5474 14.5393 22.7061 13.5873 22.7061 12.626C22.7061 11.6553 22.5474 10.7033 22.2301 9.76996C21.9128 8.83663 21.4741 7.97796 20.9141 7.19396L22.0901 6.40996C22.7341 7.29663 23.2334 8.26729 23.5881 9.32196C23.9521 10.3766 24.1341 11.478 24.1341 12.626C24.1341 13.7646 23.9521 14.866 23.5881 15.93C23.2334 16.9846 22.7341 17.9553 22.0901 18.842L20.9001 18.114Z',
]

export const FunctionFXIcon = createSvgIcon(
  <>
    {paths.map((path, i) => (
      <path d={path} key={i} />
    ))}
  </>,
  'FunctionFX',
)

export const functionFxIconSvgPathDef = {
  width: 24,
  height: 24,
  pathString: paths.join(''),
}
