import type {
  GenericReportDiff,
  GenericReportId,
} from '@fintastic/web/util/generic-report'
import { DiffPart } from '@fintastic/web/util/versions'

const ID_DELIMITER = '__'

export const generateFieldId = (versionId: string, period: string): string => {
  const sanitizeId = (id: string) => id.replace(ID_DELIMITER, '--')
  return ['amount', sanitizeId(versionId), period].join(ID_DELIMITER)
}

export const parseReportIdFromFieldId = (fieldId: string): GenericReportId => {
  const [, id] = fieldId.split(ID_DELIMITER)
  return id as GenericReportId
}

/**
 * Builds diff field id considering legacy historical comments object IDs
 * Please note that true field id is generated by AG Grid and usually has numeric suffix (for the same field ids)
 * @todo These fields should be always uniq and contain period, instead of generated AG Grid suffix.
 * But to implement this we'll to migrate ALL historical deeplinks and comment object keys.
 * @see `buildObjectId()`
 * @see `useCellHasComment()`
 */
export const generateDiffFieldId = (
  type: 'normal' | 'mom' | 'mom_percentage',
  diff?: GenericReportDiff,
  isSecondPercentageDiff?: boolean,
): string => {
  if (type === 'mom') {
    return 'amount_two_period_diff'
  }

  if (type === 'mom_percentage') {
    return 'amount_two_period_diff_percentage'
  }

  const isPercentage =
    isSecondPercentageDiff || diff?.[DiffPart.mode] === 'percent'

  return `amount_period_diff${isPercentage ? '_percent' : ''}`
}
