import { QueryClient } from 'react-query'
import { resetAllVersionsEntitiesCache } from '@fintastic/web/data-access/versions'
import { globalDimensionsKey } from '../api/keys'

export async function invalidateDimensionsRelated(qc: QueryClient) {
  return Promise.all([
    qc.invalidateQueries({
      queryKey: globalDimensionsKey,
    }),
    resetAllVersionsEntitiesCache(qc),
  ])
}
