import { useCallback, useMemo } from 'react'
import { ChangeValueDataFormattingAction } from '../types'
import { useMetricsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeValueDataFormattingFlow() {
  const { changeValueDataFormatting } = useMetricsActions()

  const handleAction = useCallback<ChangeValueDataFormattingAction>(
    (changes) => {
      changeValueDataFormatting(changes)
    },
    [changeValueDataFormatting],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
