import React, { useCallback } from 'react'
import {
  ExplorerButtonWithDropDown,
  StyledModelExplorerVersionSelectorMenuItem,
} from './ModelExplorerVersionSelector.styled'
import { IconButton, Popover, Tooltip } from '@mui/material'
import { ModelExplorerIcon } from '@fintastic/shared/ui/icons'
import { ArrowDropDown } from '@mui/icons-material'
import {
  BoardVersionsSelectOptions,
  VersionLockUnlockIcon,
} from '@fintastic/shared/ui/components'
import { ModelExplorerVersionSelectorUIDisabled } from './ModelExplorerVersionSelectorUIDisabled'

export type ModelExplorerVersionSelectorProps = {
  disabled?: boolean
  options: BoardVersionsSelectOptions[]
  onNavigate: (versionId: string) => void
  entityTitle?: string
}

export const ModelExplorerVersionSelectorUI: React.FC<
  ModelExplorerVersionSelectorProps
> = ({ disabled = false, entityTitle, onNavigate, options }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled || !event) {
        return
      }
      setAnchorEl(event.currentTarget)
    },
    [disabled],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl) && !disabled

  const handleClickOnMenuItem = useCallback(
    (versionId: string) => {
      handleClose()
      onNavigate(versionId)
    },
    [handleClose, onNavigate],
  )

  if (options.length === 0) {
    return (
      <ModelExplorerVersionSelectorUIDisabled
        reason={'Neither version is available'}
      />
    )
  }

  const tooltipText =
    'Open Version Explorer' + (entityTitle ? ' for ' + entityTitle : '')

  if (options.length === 1) {
    // no dropdown
    return (
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={tooltipText}
        disableInteractive={true}
        placement="top"
      >
        <span>
          <IconButton
            onClick={() => handleClickOnMenuItem(options[0].value)}
            data-testid="GoVersionExplorer"
            disabled={disabled || options[0].disabled}
          >
            <ModelExplorerIcon sx={{ width: 18 }} />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  // version with dropdown
  return (
    <>
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={tooltipText}
        disableInteractive={true}
        placement="top"
      >
        <span>
          <ExplorerButtonWithDropDown
            onClick={handleClick}
            data-testid="GoVersionExplorer"
            disabled={disabled}
          >
            <ModelExplorerIcon sx={{ width: 18 }} />

            <ArrowDropDown
              color={disabled ? 'disabled' : undefined}
              fontSize="small"
            />
          </ExplorerButtonWithDropDown>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <StyledModelExplorerVersionSelectorMenuItem
            key={option.value}
            disabled={option.disabled}
            onClick={() => handleClickOnMenuItem(option.value)}
          >
            <VersionLockUnlockIcon isLocked={option.isLocked} />
            <span>{option.label}</span>
          </StyledModelExplorerVersionSelectorMenuItem>
        ))}
      </Popover>
    </>
  )
}
