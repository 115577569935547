import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import {
  setIsDiscussionsOpened,
  setIsHistoryOpened,
  setIsSidebarOpened,
} from './index'
import { useLayoutStateIsDiscussionsOpened } from './hooks-discussions'
import { useLayoutStateIsHistoryOpened } from './hooks-history'
import { useLayoutStateIsSidebarOpened } from './hooks-sidebar'

export function useCloseLateralOnDestroy() {
  const dispatch = useDispatch()
  useEffect(
    () => () => {
      dispatch(setIsDiscussionsOpened(false))
      dispatch(setIsHistoryOpened(false))
      dispatch(setIsSidebarOpened(false))
    },
    [dispatch],
  )
}

export function useCloseAllLaterals() {
  // use real hooks to correct teardown
  const [, handleOpenDiscussions] = useLayoutStateIsDiscussionsOpened()
  const [, handleOpenHistory] = useLayoutStateIsHistoryOpened()
  const [, handleOpenSidebar] = useLayoutStateIsSidebarOpened()

  const closeAllLaterals = useCallback(() => {
    handleOpenDiscussions(false)
    handleOpenHistory(false)
    handleOpenSidebar(false)
  }, [handleOpenDiscussions, handleOpenHistory, handleOpenSidebar])

  return { closeAllLaterals }
}
