import { useBoardsFoldersListQuery } from './useBoardsFoldersListQuery'
import { useMemo } from 'react'
import { BoardFolder } from '../types'
import { BOARD_FOLDER_ARCHIVE_ID } from '../const/board-folders'

export type UseBoardFolderNameValidationParams = {
  folderName: string
  editingFolderId?: BoardFolder['id']
}

export type UseBoardFolderNameValidationResult = {
  isValid: boolean
  error?: string
}

export function useBoardFolderNameValidation(
  params: UseBoardFolderNameValidationParams,
) {
  const { folderName, editingFolderId } = params

  const foldersQuery = useBoardsFoldersListQuery()

  const foldersNames = useMemo(() => {
    const namesMap: Record<string, BoardFolder['id']> = {
      Archive: BOARD_FOLDER_ARCHIVE_ID,
    }
    foldersQuery.data?.forEach((folder) => {
      namesMap[folder.name.toLowerCase()] = folder.id
    })
    return namesMap
  }, [foldersQuery.data])

  return useMemo(() => {
    const result: UseBoardFolderNameValidationResult = {
      isValid: true,
    }

    if (folderName === '') {
      result.isValid = false
      result.error = 'Mandatory field'
      return result
    }

    if (
      foldersNames[folderName.toLowerCase()] !== undefined &&
      foldersNames[folderName.toLowerCase()] !== editingFolderId
    ) {
      result.isValid = false
      result.error = `Name "${folderName}" already in use`
    }

    return result
  }, [editingFolderId, folderName, foldersNames])
}
