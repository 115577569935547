import { useCallback } from 'react'
import { Board, BoardFolder } from '@fintastic/web/feature/boards'
import { useBoardFolderOpen } from './useBoardFolderOpen'
import { useBoardOpen } from './useBoardOpen'

export function useBoardAndFolderOpen() {
  const openFolder = useBoardFolderOpen()
  const openBoard = useBoardOpen()

  return useCallback(
    (boardId: Board['id'], folderId?: BoardFolder['id'], design?: boolean) => {
      if (folderId) {
        openFolder(folderId)
      }
      openBoard(boardId, design)
    },
    [openBoard, openFolder],
  )
}
