import React, { useMemo } from 'react'
import type { Renderable, Toast, ToastPosition } from 'react-hot-toast/headless'
import { resolveValue } from 'react-hot-toast/headless'
import { prefersReducedMotion } from '../utils'
import { keyframes } from '@emotion/react'
import { styled, Box, SnackbarContent } from '@mui/material'
import { SuccessToast } from './SuccessToast'
import { ErrorToast } from './ErrorToast'
import { WarningToast } from './WarningToast'

const enterAnimation = (factor: number) => `
0% {transform: translate3d(0,${factor * -200}%,0) scale(.6); opacity:.5;}
100% {transform: translate3d(0,0,0) scale(1); opacity:1;}
`

const exitAnimation = (factor: number) => `
0% {transform: translate3d(0,0,-1px) scale(1); opacity:1;}
100% {transform: translate3d(0,${factor * -150}%,-1px) scale(.6); opacity:0;}
`

const fadeInAnimation = '0%{opacity:0;} 100%{opacity:1;}'
const fadeOutAnimation = '0%{opacity:1;} 100%{opacity:0;}'

const getAnimationStyle = (
  position: ToastPosition,
  visible: boolean,
): React.CSSProperties => {
  const top = position.includes('top')
  const factor = top ? 1 : -1

  const [enter, exit] = prefersReducedMotion()
    ? [fadeInAnimation, fadeOutAnimation]
    : [enterAnimation(factor), exitAnimation(factor)]

  return {
    animation: visible
      ? `${keyframes(enter)} 0.35s cubic-bezier(.21,1.02,.73,1) forwards`
      : `${keyframes(exit)} 0.4s forwards cubic-bezier(.06,.71,.55,1)`,
  }
}

const ToastBarBase = styled(Box)(({ theme }) => ({}))

export type ToastBarProps = {
  toast: Toast
  position?: ToastPosition
  style?: React.CSSProperties
  children?: (components: { message: Renderable }) => Renderable
}

export const ToastBar: React.FC<ToastBarProps> = React.memo(
  ({ toast, position, style, children }) => {
    const animationStyle: React.CSSProperties = toast.height
      ? getAnimationStyle(
          toast.position || position || 'top-center',
          toast.visible,
        )
      : { opacity: 0 }

    const messageValue = useMemo(
      () => resolveValue(toast.message, toast),
      [toast],
    )

    const message = useMemo(() => {
      if (toast.type === 'success') {
        return <SuccessToast toast={toast} messageContent={messageValue} />
      }
      if (toast.type === 'error') {
        return <ErrorToast toast={toast} messageContent={messageValue} />
      }
      if (toast.type === 'custom') {
        return <WarningToast toast={toast} messageContent={messageValue} />
      }
      return <SnackbarContent message={messageValue} />
    }, [messageValue, toast.type])

    return (
      <ToastBarBase
        className={toast.className}
        sx={{
          ...animationStyle,
          ...style,
          ...toast.style,
        }}
      >
        {typeof children === 'function' ? (
          children({
            message,
          })
        ) : (
          <>{message}</>
        )}
      </ToastBarBase>
    )
  },
)
