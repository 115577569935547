import { mapPeriodSelectionToParams } from '@fintastic/web/data-access/metrics-and-lists'
import { axios } from '@fintastic/web/data-access/service-axios'
import {
  EditListColumnsDataCompressedIntent,
  EditListColumnsDataIntent,
  EditMetricDataIntent,
} from '@fintastic/web/util/metric-data-editing'
import {
  MetricDataValue,
  SparseMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import Qs from 'qs'
import { DimensionId, DimensionValueId } from '@fintastic/web/util/dimensions'

export const sendEditMetricDataIntent = (
  versionId: string,
  metricId: string,
  intent: EditMetricDataIntent,
) =>
  axios.patch<EditMetricDataIntentResult>(
    `/planning/api/v2/versions/${versionId}/metrics/${metricId}/data/`,
    intent,
  )

type StreamEventId = string

export type EditMetricDataIntentResult =
  | {
      stream_event_id: StreamEventId
    }
  | {
      stream_event_id: StreamEventId
      metric_data: SparseMetricData
    }

export const sendEditListColumnsDataIntent = (
  versionId: string,
  listId: string,
  intent: EditListColumnsDataIntent | EditListColumnsDataCompressedIntent,
) =>
  axios.patch<EditListColumnsDataIntentResult>(
    `/planning/api/v2/versions/${versionId}/lists/${listId}/data/`,
    intent,
  )

export type EditListColumnsDataIntentResult =
  | {
      stream_event_id: StreamEventId
    }
  | {
      stream_event_id: StreamEventId
      list_data: {
        id: string
        metrics: Array<{
          id: string
          data: SparseMetricData
        }>
      }
    }

type StandaloneMetricId = string
type ColumnId = string

export type MetricCells = {
  entity_id: StandaloneMetricId | ColumnId
  dimension_ids: DimensionId[]
  dimension_value_ids: DimensionValueId[][]
  values: MetricDataValue[]
}

export function getMetricCellsByEventId(
  eventId: string[],
  versionId: string,
  periodSelection: PeriodSelection,
) {
  return axios.get<{ cells: MetricCells[] }>(
    `/planning/api/v2/versions/${versionId}/metrics/cells/`,
    {
      params: {
        event_id: eventId,
        ...mapPeriodSelectionToParams(periodSelection),
      },
      paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export function getListCellsByEventId(
  eventId: string,
  versionId: string,
  periodSelection: PeriodSelection,
) {
  return axios.get<{ cells: MetricCells[] }>(
    `/planning/api/v2/versions/${versionId}/lists/cells/`,
    {
      params: {
        event_id: eventId,
        ...mapPeriodSelectionToParams(periodSelection),
      },
      paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}
