import { Box, styled } from '@mui/material'
import { defaultPillValueStyle } from '../common-styles'

export const StyledDetailsCardColumn = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const StyledDetailsColumnValue = styled(Box)`
  ${defaultPillValueStyle};
  color: ${({ theme }) => theme.palette.primary.dark};
`
