import { Version } from '@fintastic/web/util/versions'
import { useMemo } from 'react'
import { VersionTable } from './types'
import {
  MetricOrListType,
  MetricOrListSource,
} from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

const SELECTED_VERSIONS_LIMIT = 3

export const versionNamesList = (versions: Version[]) =>
  versions
    .filter((version, index) => index <= SELECTED_VERSIONS_LIMIT)
    .reduce(
      (names, version, index) =>
        `${names}${names ? '\n' : ''}${
          index === SELECTED_VERSIONS_LIMIT
            ? `+ ${versions.length - SELECTED_VERSIONS_LIMIT} more`
            : version.name
        }`,
      '',
    )

export const useSelectedVersions = (selectedVersions: Version[]) => {
  const singleVersionSelected = selectedVersions.length === 1
  const versionNamesTitle = useMemo(
    () => versionNamesList(selectedVersions),
    [selectedVersions],
  )
  const selectedVersionsIds = useMemo(
    () => selectedVersions.map((version) => version.uuid),
    [selectedVersions],
  )

  return useMemo(
    () => ({
      singleVersionSelected,
      versionNamesTitle,
      selectedVersionsIds,
    }),
    [selectedVersionsIds, singleVersionSelected, versionNamesTitle],
  )
}

export function versionAmountTitle(selectedVersions: Version[]): string {
  return selectedVersions.length === 1 ? 'version' : 'versions'
}

export function generateSuccessMessage(
  selectedVersions: Version[],
  operationPastTime: string,
): string {
  if (selectedVersions.length === 1) {
    return `Version ${
      selectedVersions[0].name
    } ${operationPastTime.toLowerCase()} successfully`
  }
  return `${operationPastTime} successfully ${selectedVersions.length} versions`
}

export function generateErrorMessage(
  selectedVersions: Version[],
  operationPresentTime: string,
  details?: string,
): string {
  return `Failed to ${operationPresentTime.toLowerCase()} ${
    selectedVersions.length
  } ${versionAmountTitle(selectedVersions)}. Please contact support.${
    details ? ` Details: ${details}` : ''
  }`
}

export function generateVersionPlaceholder(
  params: {
    id: Maybe<string>
    label: string
  } & (
    | {
        type: MetricOrListType
        source: MetricOrListSource
      }
    | {
        type: 'report'
      }
  ),
): VersionTable {
  if (params.type === 'report') {
    return {
      id: params.id || '',
      label: params.label,
      type: 'report',
      creationDummy: true,
      _rowId: `${params.id || ''}:new`,
    }
  }

  return {
    id: params.id || '',
    label: params.label,
    type: params.type === 'list' ? 'list' : 'metric',
    source: params.source,
    info: null,
    metadata: {
      formula: params.source === 'calculated' ? '' : null,
    },
    creationDummy: true,
    _rowId: `${params.id || ''}:new`,
  }
}
