import { defaultChartGridMode } from '@fintastic/web/feature/charts'
import React from 'react'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { ChartSettingsSection } from '../shared/ChartSettingsSection'
import { ChartGridModeSelect } from '../../../../../settings-controls/controls'

export const GridModeSection: React.FC = () => {
  const { loading } = useChartSettingsContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartSettingsSection>
      <ChartGridModeSelect
        disabled={loading}
        gridMode={localSettings.gridMode || defaultChartGridMode}
        onChangeChartSettings={handleSettingsChange}
      />
    </ChartSettingsSection>
  )
}
