import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import { Maybe } from '@fintastic/shared/util/types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from './DatePicker'
import { StyledRangeContainer, StyledRangeDivider } from './DateRange.styled'

export type DateRangeProps = {
  disabled: boolean
  fromDate: Date
  onChangeFromDate: (date: dayjs.Dayjs) => void
  toDate: Date
  onChangeToDate: (date: dayjs.Dayjs) => void
}

export const DateRange: React.FC<DateRangeProps> = ({
  disabled,
  fromDate,
  onChangeFromDate,
  toDate,
  onChangeToDate,
}) => {
  const handleFromDateChange = useCallback(
    (date: Maybe<dayjs.Dayjs>) => {
      if (!date) {
        return
      }

      onChangeFromDate(date)

      if (dayjs(date).isAfter(toDate)) {
        onChangeToDate(date)
      }
    },
    [onChangeFromDate, onChangeToDate, toDate],
  )

  const handleToDateChange = useCallback(
    (date: Maybe<dayjs.Dayjs>) => {
      if (!date) {
        return
      }

      onChangeToDate(date)
      if (dayjs(date).isBefore(fromDate)) {
        onChangeFromDate(date)
      }
    },
    [fromDate, onChangeFromDate, onChangeToDate],
  )

  return (
    <StyledRangeContainer data-testid="date-range">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          label={'From date'}
          date={dayjs(fromDate)}
          onChange={handleFromDateChange}
        />
        <StyledRangeDivider>&ndash;</StyledRangeDivider>
        <DatePicker
          disabled={disabled}
          label={'To date'}
          date={dayjs(toDate)}
          onChange={handleToDateChange}
        />
      </LocalizationProvider>
    </StyledRangeContainer>
  )
}
