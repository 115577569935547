import { useMemo } from 'react'
import {
  GenericReportTreeRow,
  GenericReportRow,
  BasicColumnDefinition,
} from '@fintastic/web/util/generic-report'

export const useTreeViewData = (
  rowData: GenericReportRow[],
  dimensions: BasicColumnDefinition[],
) =>
  useMemo<GenericReportTreeRow[]>(() => {
    if (!rowData.length) {
      return []
    }
    return rowData.map<GenericReportTreeRow>((row) => ({
      ...row,
      path: dimensions.map(({ name }) => row.dimensions[name]) as string[],
    }))
  }, [rowData, dimensions])
