import React from 'react'
import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import {
  MetricChartDimensionsList,
  useChartSettingsEditContext,
} from '@fintastic/web/feature/charts'

export const DialogSectionData: React.FC = () => {
  const { pendingApi, data } = useWidgetSettingsDialogContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  if (!data.versionId || !data.id) {
    return <div>Please select a metric.</div>
  }

  if (
    !data.chartSettings?.dimensions ||
    !data.chartSettings?.dimensions.length
  ) {
    return null // generic message shown
  }

  return (
    <MetricChartDimensionsList
      metricId={data.id || ''}
      loading={pendingApi.isAnyPendingProcess}
      chartSettings={{ dimensions: localSettings.dimensions }}
      onChangeChartSettings={handleSettingsChange}
      version={data.versionId || ''}
    />
  )
}
