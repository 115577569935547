import * as slice from './slice'
import type { InferReduxConnectable } from '@fintastic/shared/data-access/redux'
import { namespace } from './state'
import sagas from './sagas'

export * from './selectors'
const { actions, reducer } = slice
export { actions }

const connectable = {
  namespace,
  reducer,
  sagas,
}

export default connectable as InferReduxConnectable<typeof connectable>
