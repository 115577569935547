import React from 'react'
import { createSvgIcon } from '@mui/material'

export const CollapseIcon = createSvgIcon(
  <>
    <path d="M7.41126 5.21414C7.54661 5.07703 7.73015 5 7.92152 5C8.1129 5 8.29644 5.07703 8.43178 5.21414L12.0043 8.83456L15.5768 5.21414C15.713 5.08091 15.8953 5.00719 16.0845 5.00886C16.2737 5.01053 16.4548 5.08745 16.5886 5.22305C16.7224 5.35866 16.7983 5.5421 16.7999 5.73387C16.8016 5.92564 16.7288 6.1104 16.5974 6.24834L12.5146 10.3859C12.3792 10.523 12.1957 10.6 12.0043 10.6C11.8129 10.6 11.6294 10.523 11.4941 10.3859L7.41126 6.24834C7.27596 6.11118 7.19995 5.92518 7.19995 5.73124C7.19995 5.5373 7.27596 5.3513 7.41126 5.21414Z" />
    <path d="M16.5887 19.7859C16.4533 19.923 16.2698 20 16.0784 20C15.887 20 15.7035 19.923 15.5682 19.7859L11.9956 16.1654L8.4231 19.7859C8.28698 19.9191 8.10467 19.9928 7.91544 19.9911C7.72621 19.9895 7.54519 19.9126 7.41137 19.7769C7.27756 19.6413 7.20166 19.4579 7.20001 19.2661C7.19837 19.0744 7.27112 18.8896 7.40258 18.7517L11.4854 14.6141C11.6207 14.477 11.8043 14.4 11.9956 14.4C12.187 14.4 12.3705 14.477 12.5059 14.6141L16.5887 18.7517C16.724 18.8888 16.8 19.0748 16.8 19.2688C16.8 19.4627 16.724 19.6487 16.5887 19.7859Z" />
    <path d="M4.75 11.5C4.33579 11.5 4 11.8358 4 12.25C4 12.6642 4.33579 13 4.75 13H19.25C19.6642 13 20 12.6642 20 12.25C20 11.8358 19.6642 11.5 19.25 11.5H4.75Z" />
  </>,
  'Collapse',
)
