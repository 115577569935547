import { useEffect } from 'react'
import {
  ErrorVersionIsEmpty,
  ErrorVersionUnavailable,
  useWidgetSettingsDialogContext,
} from '@fintastic/web/feature/boards'

export const useSetWidgetSettingsVersionErrorsEffect = (
  versionUnavailable?: boolean,
  versionIsEmpty?: boolean,
) => {
  const { errorsApi } = useWidgetSettingsDialogContext()

  useEffect(() => {
    if (versionUnavailable) {
      errorsApi.addPendingError(ErrorVersionUnavailable)
    } else {
      errorsApi.removePendingError(ErrorVersionUnavailable)
    }
  }, [errorsApi, versionUnavailable])

  useEffect(() => {
    if (versionIsEmpty) {
      errorsApi.addPendingError(ErrorVersionIsEmpty)
    } else {
      errorsApi.removePendingError(ErrorVersionIsEmpty)
    }
  }, [errorsApi, versionIsEmpty])
}
