import React, { useCallback, useMemo, useState } from 'react'
import { Filter, useFilterContext } from '@fintastic/web/util/filters'
import { FilterGroupContainer } from './FilterGroupContainer'
import {
  FilterGroup,
  FilterNoResults,
  FilterSubGroup,
} from '@fintastic/shared/ui/filters'
import { QuickSearch } from '@fintastic/shared/ui/components'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import { Box, Skeleton } from '@mui/material'
import { compact, isEmpty } from 'lodash'
import type { Maybe } from '@fintastic/shared/util/types'
import { InputGroupWrapper } from '../shared/components/InputGroupWrapper'

export const FilterSpreadByTimeDimension: React.FC<{
  filter: Filter
  topLevelSearch: string
}> = ({ filter, topLevelSearch }) => {
  const { appliedModel } = useFilterContext()
  const { versionId } = useFilterContext()
  const [search, setSearch] = useState<string>('')
  const [collapsed, setCollapsed] = useState(false)

  const versionEntities = useLoadVersionEntities(versionId)

  const handleToggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev)
  }, [])

  const filterTimeDimensionValuesLabelMap = useMemo(() => {
    if (!filter.time_dimension_values?.length || !versionEntities?.data) {
      return {}
    }

    const dimension = versionEntities.data.dimensions.find(
      (d) => d.id === filter.time_dimension_id,
    )

    if (dimension?.type !== 'Time') {
      return {}
    }

    return dimension.values
  }, [
    filter.time_dimension_id,
    filter.time_dimension_values?.length,
    versionEntities.data,
  ])

  const filteredFilters = useMemo(() => {
    if (!filter.time_dimension_values?.length) {
      return []
    }

    const initiallyFiltered = compact(
      filter.time_dimension_values.map<
        Maybe<
          Filter & { timeDimValueId: string; matchedByTopLevelSearch: boolean }
        >
      >((timeDimValueId) => {
        const label = filterTimeDimensionValuesLabelMap[timeDimValueId]

        if (!label) {
          return { ...filter, timeDimValueId, matchedByTopLevelSearch: true }
        }

        const matchedByLocalSearch = searchMatch(label, search)
        const matchedByTopLevelSearch = search.trim().length
          ? true
          : searchMatch(label, topLevelSearch)

        if (!matchedByLocalSearch) {
          return null
        }

        return {
          ...filter,
          label,
          timeDimValueId,
          matchedByTopLevelSearch,
        }
      }),
    )

    const matchedToTopLevel = initiallyFiltered.filter(
      (f) => f?.matchedByTopLevelSearch === true,
    )

    if (matchedToTopLevel.length) {
      return matchedToTopLevel
    }

    if (!searchMatch(filter.label, topLevelSearch)) {
      return []
    }

    return initiallyFiltered
  }, [filter, filterTimeDimensionValuesLabelMap, search, topLevelSearch])

  const hasValue = useMemo(
    () => !isEmpty(appliedModel.filterModelValues?.[filter.id]),
    [appliedModel.filterModelValues, filter.id],
  )

  if (!filter.time_dimension_values?.length) {
    if (!searchMatch(filter.label, topLevelSearch)) {
      return null
    }

    return (
      <InputGroupWrapper filter={filter} timeDimensionValueId={null}>
        <FilterGroupContainer filter={filter} timeDimensionValueId={null} />
      </InputGroupWrapper>
    )
  }

  if (!versionEntities.data) {
    return (
      <Box p={1} display="flex" gap={1} flexDirection="row">
        <Skeleton height={35} width={24} variant="rounded" />
        <Box flexGrow={1}>
          <Skeleton height={35} variant="rounded" />
        </Box>
      </Box>
    )
  }

  if (!filteredFilters.length && !search.length) {
    return null
  }

  return (
    <FilterGroup
      collapsed={collapsed}
      onToggleCollapse={handleToggleCollapsed}
      title={filter.label}
      hasValue={hasValue}
    >
      <FilterSubGroup
        searchSlot={<QuickSearch value={search} onChange={setSearch} />}
      >
        {filteredFilters.length ? (
          filteredFilters.map((filter) => (
            <InputGroupWrapper
              key={filter.timeDimValueId}
              filter={filter}
              timeDimensionValueId={filter.timeDimValueId}
            >
              <FilterGroupContainer
                filter={filter}
                timeDimensionValueId={filter.timeDimValueId}
              />
            </InputGroupWrapper>
          ))
        ) : (
          <Box my={2}>
            <FilterNoResults />
          </Box>
        )}
      </FilterSubGroup>
    </FilterGroup>
  )
}

FilterSpreadByTimeDimension.displayName = 'FilterSpreadByTimeDimension'

const searchMatch = (label: string, search: string): boolean => {
  if (search.trim().length === 0) {
    return true
  }

  return label.toLocaleLowerCase().includes(search.toLocaleLowerCase().trim())
}
