import { Maybe } from '@fintastic/shared/util/types'
import { WidgetRequiredSettings } from '@fintastic/shared/ui/widgets-framework'
import {
  METRIC_CHART_WIDGET_TYPE,
  MetricChartWidgetSettings,
} from '../../widgets/MetricChartWidget'
import {
  GENERIC_REPORT_WIDGET_TYPE,
  LIST_GRID_WIDGET_TYPE,
  METRIC_GRID_WIDGET_TYPE,
  TEXT_WIDGET_TYPE,
} from '../../widgets'
import { getChartWidgetSettingsOrDefaults } from '@fintastic/web/feature/charts'
import { ChangeWidgetDialogParams } from '@fintastic/web/feature/boards'
import { DesignerWidgetType } from '../widgets/board-design-type-utils'

export const newWidgetSettingsFromDialog = (
  params: ChangeWidgetDialogParams,
): Maybe<
  Record<string, unknown> &
    WidgetRequiredSettings &
    Partial<MetricChartWidgetSettings>
> => {
  const widgetType = params.type || TEXT_WIDGET_TYPE

  if (widgetType === TEXT_WIDGET_TYPE) {
    return {
      content: '',
      displayLabel: 'New text',
    }
  }

  if (widgetType === LIST_GRID_WIDGET_TYPE) {
    return {
      listId: params.id,
      show_net: true,
      displayLabel: params.displayLabel || 'List',
      versionId: params.versionId,
    }
  }

  if (widgetType === METRIC_GRID_WIDGET_TYPE) {
    return {
      metricId: params.id,
      displayLabel: params.displayLabel || 'Metric',
      versionId: params.versionId,
    }
  }

  if (widgetType === GENERIC_REPORT_WIDGET_TYPE) {
    return {
      reportId: params.id,
      reportCategoryId: params.id,
      displayLabel: params.displayLabel || 'Report',
      versionId: params.versionId,
    }
  }

  if (widgetType === METRIC_CHART_WIDGET_TYPE) {
    return {
      metricId: params.id,
      displayLabel: params.displayLabel || 'Metric Chart',
      versionId: params.versionId,
      chartSettings: {
        ...getChartWidgetSettingsOrDefaults(params.chartSettings),
      },
    }
  }

  return null
}

export const getNewWidgetDefaultHeight = (widgetType: DesignerWidgetType) => {
  if (widgetType === TEXT_WIDGET_TYPE) {
    return 2
  }

  if (widgetType === METRIC_CHART_WIDGET_TYPE) {
    return 6
  }

  return 4
}
