import React, { memo } from 'react'
import { BoardConfig, BoardParams } from '../../types'
import { StyledBoardDesignViewContent } from './BoardDesignView.styled'
import { widgetsRenderers } from '../widgets'
import { CircularProgress } from '@mui/material'
import { WidgetsArea, Widget } from '@fintastic/shared/ui/widgets-framework'

export type BoardDesignViewWidgetsAreaProps = {
  boardConfig: BoardConfig
  boardsParams: BoardParams
  widgetContextMenuBuilder: (widget: Widget) => React.ReactNode
  handleUpdateDesign: (config: BoardConfig, manualChange?: boolean) => void
}

export const BoardDesignViewWidgetsArea: React.FC<BoardDesignViewWidgetsAreaProps> =
  memo(
    ({
      boardConfig,
      boardsParams,
      widgetContextMenuBuilder,
      handleUpdateDesign,
    }) => (
      <StyledBoardDesignViewContent>
        {!boardConfig && <CircularProgress className="board-loading" />}
        {boardConfig && (
          <WidgetsArea
            boardConfig={boardConfig}
            widgetContextMenuBuilder={widgetContextMenuBuilder}
            onChangeBoardConfig={handleUpdateDesign}
            boardParams={{ ...boardsParams, isDesignMode: true }}
            widgetsRenderers={widgetsRenderers}
          />
        )}
      </StyledBoardDesignViewContent>
    ),
  )
