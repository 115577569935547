import React from 'react'

/** @deprecated */
type IconProps = {
  width?: number | string
  height?: number | string
  color?: string
  className?: string
}

/** @deprecated */
export const InfoIcon: React.FC<IconProps> = ({
  width = 26,
  height = 26,
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3C5.91015 3 3 5.91015 3 9.5C3 13.0899 5.91015 16 9.5 16Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 13V9.5"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9.4999" cy="6.85" r="0.85" fill="black" />
  </svg>
)
