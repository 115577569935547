import { styled, Box } from '@mui/material'

export const StyledAutocompleteRoot = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,

  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
  fontSize: 12,
}))
