import { VersionPermissionsApi } from '../version-permissions/types'
import { EditingPermissionsApi } from './types'
import { useMemo } from 'react'
import { getTooltipText } from './getTooltipText'
import { UserPermissionsApi } from '../user-permissions/types'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'

export function useEditingPermissions({
  userPermissionsApi,
  versionPermissionsApi,
  readonly,
  versionUserLock,
}: {
  userPermissionsApi: UserPermissionsApi
  versionPermissionsApi: VersionPermissionsApi
  readonly: boolean
  versionUserLock: VersionUserLockParsed
}): EditingPermissionsApi {
  return useMemo(
    () => ({
      allowed:
        userPermissionsApi.editingAllowed &&
        versionPermissionsApi.editingAllowed &&
        !readonly,
      versionUserLock,
      tooltipText: getTooltipText([
        userPermissionsApi.editingBlockedTooltip || null,
        versionPermissionsApi.editingBlockedTooltip || null,
      ]),
    }),
    [
      readonly,
      versionUserLock,
      userPermissionsApi.editingAllowed,
      userPermissionsApi.editingBlockedTooltip,
      versionPermissionsApi.editingAllowed,
      versionPermissionsApi.editingBlockedTooltip,
    ],
  )
}
