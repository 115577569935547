import React, { useCallback } from 'react'
import { ModelExplorerVersionSelectorUI } from './ModelExplorerVersionSelectorUI'
import { CircularProgress, IconButton } from '@mui/material'
import { useModelExplorerApi } from '@fintastic/web/feature/model-explorer'
import { useBoardVersionsForEntity } from '@fintastic/shared/ui/components'

export type ModelExplorerVersionSelectorForEntityProps = {
  disabled?: boolean
  entityId: string
  entityTitle?: string
  disabledVersionsDueToReports?: string[]
}

// data wrapper - calculates actual state for each entity in version
// is it exists?
export const ModelExplorerVersionSelectorForEntity: React.FC<
  ModelExplorerVersionSelectorForEntityProps
> = ({
  disabled = false,
  entityId,
  entityTitle,
  disabledVersionsDueToReports,
}) => {
  const { options, isLoading } = useBoardVersionsForEntity(
    entityId,
    undefined,
    disabledVersionsDueToReports,
  )

  const modelExplorerApi = useModelExplorerApi()

  const handleNavigate = useCallback(
    (versionId: string) => {
      if (
        !disabled &&
        modelExplorerApi &&
        !modelExplorerApi.isOpen &&
        !!entityId
      ) {
        modelExplorerApi.open(versionId, entityId)
      }
    },
    [entityId, modelExplorerApi, disabled],
  )

  if (isLoading) {
    // in progress
    return (
      <IconButton disabled={true} data-testid="GoVersionExplorerLoading">
        <CircularProgress size={16} />
      </IconButton>
    )
  }

  return (
    <ModelExplorerVersionSelectorUI
      disabled={disabled}
      options={options}
      onNavigate={handleNavigate}
      entityTitle={entityTitle}
    />
  )
}
