import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { Maybe } from '@fintastic/shared/util/types'
import FormControl from '@mui/material/FormControl'
import { createFilterOptions, TextField } from '@mui/material'
import { StyledCurrencySelect } from './StyledCurrencySelect'

type CurrencyCode = string

export type CurrencySelectProps = {
  currencies: Currency[]
  value: Maybe<CurrencyCode>
  onChange: (newValue: CurrencyCode) => void
  disabled?: boolean
}

const sortByLabel = (a: Currency, b: Currency): number => {
  const labelA = (a?.label || '').toUpperCase()
  const labelB = (b?.label || '').toUpperCase()
  if (labelA < labelB) {
    return -1
  }
  if (labelA > labelB) {
    return 1
  }

  return 0
}

const initialCurrency = {
  id: '',
  label: 'none',
}

const filterOptions = createFilterOptions<Partial<Currency>>({
  matchFrom: 'any',
  stringify: (option: Partial<Currency>) => option.code + ' ' + option.label,
})

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  value,
  onChange,
  disabled,
  currencies,
}) => {
  const sortedOptions = useMemo<Currency[]>(
    () => currencies.filter((d) => d && d.code && d.label).sort(sortByLabel),
    [currencies],
  )

  const val = useMemo(() => {
    const currency = currencies.find((d) => d.code === value)
    return currency ?? initialCurrency
  }, [value, currencies])

  const handleChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      value: Partial<Currency> | Partial<Currency>[] | null,
    ) => {
      if (!value || Array.isArray(value)) {
        // single select only
        return
      }
      const currency = currencies.find((d) => d.code === value.code)
      if (currency) {
        onChange(currency.code)
      }
    },
    [currencies, onChange],
  )

  return (
    <FormControl fullWidth disabled={disabled}>
      <StyledCurrencySelect
        id="autocomplete-currency-select"
        size="small"
        value={val}
        disabled={disabled}
        autoSelect={true}
        disableClearable={true}
        options={sortedOptions}
        onChange={handleChange}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            variant="outlined"
            onFocus={(e) => e.target?.select()}
            {...params}
          />
        )}
      />
    </FormControl>
  )
}
