import { styled, ButtonBase } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledTipRoot = styled(ButtonBase)(({ theme }) => ({
  'position': 'absolute',
  'right': 0,
  'bottom': 0,
  ...styledMixins.alignContentCenter(),
  'width': '14px',
  'height': '14px',

  'transition': theme.transitions.create('opacity', {
    duration: theme.transitions.duration.short,
  }),

  '&:hover': {
    opacity: 1,
  },

  'svg': {
    'opacity': 0.6,
    'transition': theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      opacity: 1,
    },
  },
}))
