import React from 'react'
import {
  StyledArrowIcon,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
} from './NavButton.styled'
import { ArrowIcon } from './ArrowIcon'
import { SxProps, Tooltip } from '@mui/material'

export type NavButtonProps = {
  onClick: () => void
  primaryLabel: string
  secondaryLabel?: string | React.ReactNode
  icon: React.ReactNode
  iconSx?: SxProps
}

export const NavButton: React.FC<NavButtonProps> = ({
  onClick,
  secondaryLabel,
  primaryLabel,
  icon,
  iconSx,
}) => (
  <StyledListItem onClick={onClick}>
    <StyledListItemIcon sx={iconSx}>{icon}</StyledListItemIcon>
    <Tooltip
      title={secondaryLabel}
      disableInteractive
      disableHoverListener={
        typeof secondaryLabel === 'string' && (secondaryLabel?.length || 0) < 28
      }
    >
      <StyledListItemText primary={primaryLabel} secondary={secondaryLabel} />
    </Tooltip>
    <StyledArrowIcon>
      <ArrowIcon fontSize="small" />
    </StyledArrowIcon>
  </StyledListItem>
)
