import React, { useCallback, useMemo } from 'react'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import { Board, BoardsFoldersTreeNode, BoardsFoldersTreeNodeFolder } from '../../../types'
import { useBoardDeleteMutation } from '../../../hooks/useBoardDeleteMutation'
import { Typography } from '@mui/material'
import { BOARD_FOLDER_ARCHIVE_ID, boardRoutes } from '@fintastic/web/feature/boards'
import { useNavigate } from 'react-router-dom'
import { useBoardsFoldersTree } from '../../../hooks/useBoardsFoldersTree'
import { Maybe } from '@fintastic/shared/util/types'

export type BoardArchiveFormProps = {
  boardId: Board['id']
  currentBoardId?: Board['id']
  boardName: Board['name']
  closeParentModal?: () => void
}

export const BoardArchiveForm: React.FC<BoardArchiveFormProps> = (props) => {
  const { closeParentModal, boardId, currentBoardId, boardName } = props

  const deleteBoardMutation = useBoardDeleteMutation()
  // to redirect
  const navigate = useNavigate()
  const data = useBoardsFoldersTree()

  const firstAvailableBoard = useMemo(() => {
    const getFirstBoard = (nodes: BoardsFoldersTreeNode[]): Maybe<string> => {
      if (nodes.length === 0) {
        return null
      }

      for (let i = 0; i < nodes.length; i += 1) {
        if (nodes[i].type === 'board') {
          return nodes[i].data.id
        }
        return getFirstBoard((nodes[i] as BoardsFoldersTreeNodeFolder).children)
      }

      return null
    }
    return data.isLoading
      ? null
      : getFirstBoard(data.tree.rootNodes.filter((folder) => folder.data.id !== BOARD_FOLDER_ARCHIVE_ID))

  }, [data])

  const handleSubmit = useCallback(async () => {
    if (closeParentModal) {
      closeParentModal()
    }
    await deleteBoardMutation.mutateAsync({ boardId })
    // redirect to default
    if (currentBoardId === boardId) {
      if (firstAvailableBoard !== null) {
        navigate(boardRoutes.board(firstAvailableBoard))
      }
    }
  }, [boardId, closeParentModal, deleteBoardMutation])

  return (
    <FormLayout submitButtonText="Confirm" onSubmit={handleSubmit} onCancel={closeParentModal}>
      <Typography>{`Are you sure you want to Archive board ${boardName}?`}</Typography>
    </FormLayout>
  )
}
