import { useCallback, useEffect } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import type { Version } from '../version-state/types'
import type { ChangeCallback } from './types'

// @todo add tests
export function useSyncEditingStateWithVersion(
  version: Maybe<Version>,
  change: ChangeCallback,
) {
  const sync = useCallback(() => {
    change(version?.formula || ' ')
  }, [change, version?.formula])

  useEffect(() => {
    sync()
  }, [sync])

  return sync
}
