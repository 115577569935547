import React, { useMemo } from 'react'
import {
  InlineSelectWithLabel,
  InlineSelectOption,
} from '@fintastic/shared/ui/components'
import {
  StyledButton,
  StyledButtonIcon,
  StyledButtonText,
  StyledRoot,
} from './ActionSelector.styled'
import { ArrowDropDown } from '@mui/icons-material'
import { MetricDataFillStrategy } from '@fintastic/web/util/metric-data-editing'

type Option = InlineSelectOption<MetricDataFillStrategy>

export type ActionSelectorProps = {
  value: MetricDataFillStrategy
  onChange: (newValue: MetricDataFillStrategy) => void
  disabledActions: MetricDataFillStrategy[]
}

export const ActionSelector: React.FC<ActionSelectorProps> = ({
  value,
  disabledActions,
  onChange,
}) => {
  const options = useMemo<Option[]>(() => {
    const items: Option[] = []

    if (!disabledActions.includes('split_evenly')) {
      items.push({
        label: 'Split evenly',
        value: 'split_evenly',
      })
    }

    if (!disabledActions.includes('duplicate')) {
      items.push({
        label: 'Duplicate',
        value: 'duplicate',
      })
    }

    return items
  }, [disabledActions])

  return (
    <StyledRoot>
      <InlineSelectWithLabel
        options={options}
        value={value}
        onChange={onChange}
        useExampleAsButtonLabel={false}
        menuMinWidth={180}
        renderButton={({
          selectedOptionLabel,
          requestOpen,
          requestClose,
          isOpen,
          disabled,
        }) => (
          <StyledButton
            disabled={disabled}
            onClick={isOpen ? requestClose : requestOpen}
          >
            <StyledButtonText variant="body2" fontWeight="bold">
              {selectedOptionLabel}
            </StyledButtonText>
            <StyledButtonIcon>
              <ArrowDropDown
                fontSize="small"
                sx={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
              />
            </StyledButtonIcon>
          </StyledButton>
        )}
      />
    </StyledRoot>
  )
}
