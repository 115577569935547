/**
 Grabbing all ducks together
 */

import { CombinedState, Reducer } from 'redux'

import { configMainDuck } from '@fintastic/web/feature/config'
import { appInitializationDuck } from '@fintastic/web/feature/initialization'
import { authDuck } from '@fintastic/web/feature/auth'
import { layoutDuck } from '@fintastic/shared/ui/app-layout-framework'
import { boardsUiDuck } from '@fintastic/web/feature/boards'
import { permissionsDuck } from '@fintastic/web/feature/settings'
import { metricOrListEditingDuck } from '@fintastic/web/data-access/metrics-and-lists'

const ducks = [
  layoutDuck,
  boardsUiDuck,
  appInitializationDuck,
  authDuck,
  configMainDuck,
  permissionsDuck,
  metricOrListEditingDuck,
]

export type RootReducer = Reducer<
  CombinedState<{
    [layoutDuck.namespace]: ReturnType<typeof layoutDuck.reducer>
    [boardsUiDuck.namespace]: ReturnType<typeof boardsUiDuck.reducer>
    [appInitializationDuck.namespace]: ReturnType<
      typeof appInitializationDuck.reducer
    >
    [authDuck.namespace]: ReturnType<typeof authDuck.reducer>
    [configMainDuck.namespace]: ReturnType<typeof configMainDuck.reducer>
    [permissionsDuck.namespace]: ReturnType<typeof permissionsDuck.reducer>
    [metricOrListEditingDuck.namespace]: ReturnType<
      typeof metricOrListEditingDuck.reducer
    >
  }>
>

export default ducks
