import { Box, styled, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledPanelTopbarTitleRoot = styled(Typography)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  position: 'relative',
}))

export const StyledPanelToolbarTitleIcon = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-right: ${({ theme }) => theme.spacing(1)};
`
