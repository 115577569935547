import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useLocalContext } from './context'

export type ConfirmButtonProps = Omit<ButtonProps, 'onClick'>

export const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
  const { onConfirm } = useLocalContext()
  return (
    <Button size="medium" variant="outlined" onClick={onConfirm} {...props} />
  )
}
