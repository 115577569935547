import { MappingDictionary } from './types'
import { DisplayToken } from '@fintastic/web/data-access/formulas'

/**
 * Goes through the formula, copies non-token strings as is and tries to map tokens.
 * Preserves all the spaces, tabs and newlines.
 */
export const applyTokensMapping = (
  formula: string,
  tokens: DisplayToken[],
  dictionary: MappingDictionary,
): string => {
  let mappedFormula = formula.slice(0, tokens[0].start)
  let prevTokenStop = Math.max(tokens[0].start - 1, 0)

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    mappedFormula = `${mappedFormula}${formula.slice(
      prevTokenStop + 1,
      token.start,
    )}`
    const mappedValue =
      dictionary[token.text] === undefined ? token.text : dictionary[token.text]
    mappedFormula = `${mappedFormula}${mappedValue}`
    prevTokenStop = token.stop
  }

  return `${mappedFormula}${formula.slice(prevTokenStop + 1)}`
}
