import { Button } from '@mui/material'
import React, { memo, useCallback, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { DesignerWidgetType } from '../../widgets/board-design-type-utils'
import {
  ListIcon,
  MetricIcon,
  TextIcon,
} from '@fintastic/web/feature/metrics-and-lists'
import {
  GENERIC_REPORT_WIDGET_TYPE,
  LIST_GRID_WIDGET_TYPE,
  METRIC_CHART_WIDGET_TYPE,
  METRIC_GRID_WIDGET_TYPE,
  TEXT_WIDGET_TYPE,
} from '../../../widgets'
import {
  ChangeWidgetDialogParams,
  ChangeWidgetDialogState,
} from '../../../../types/board-design-types'
import {
  StyledMenuItem,
  StyledMenuItemPrimaryText,
  StyledMenuItemSecondaryText,
  StyledTopbarMenu,
} from './BoardDesignerAddWidgetMenu.styled'

import { WidgetReportIcon } from '@fintastic/shared/ui/icons'
import { ChartSettingsIcon } from '@fintastic/web/feature/charts'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

type BoardDesignerAddWidgetMenuProps = {
  changeDialog?: ChangeWidgetDialogState
  getReferenceVersion: () => string
  directAddWidget: (params: ChangeWidgetDialogParams) => void
}

export const BoardDesignerAddWidgetMenu: React.FC<BoardDesignerAddWidgetMenuProps> =
  memo(({ changeDialog, directAddWidget, getReferenceVersion }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const chartsEnabled = useIsFeatureEnabled('enable_charts')

    const menuOpen = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleAddAction = useCallback(
      (widgetType: DesignerWidgetType) => {
        setAnchorEl(null)

        if (widgetType === TEXT_WIDGET_TYPE) {
          directAddWidget({
            type: widgetType,
            id: '',
            versionId: '',
            displayLabel: 'New text',
          })
          return
        }

        changeDialog?.openWithParams?.({
          type: widgetType,
          id: '',
          versionId: getReferenceVersion(),
          displayLabel: '',
        })
      },
      [changeDialog, directAddWidget, getReferenceVersion],
    )

    return (
      <>
        <Button
          id="designer-menu-button"
          variant="contained"
          onClick={handleClick}
          startIcon={<AddIcon sx={{ width: '20px' }} />}
        >
          Add widget
        </Button>
        <StyledTopbarMenu
          id="designer-add-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
        >
          <StyledMenuItem
            disableRipple
            onClick={() => handleAddAction(GENERIC_REPORT_WIDGET_TYPE)}
          >
            <StyledMenuItemPrimaryText>
              <WidgetReportIcon
                sx={{
                  color: '#D45702', // @todo extract colors to const later
                }}
              />{' '}
              Report grid
            </StyledMenuItemPrimaryText>
            <StyledMenuItemSecondaryText>
              Display Reporting data with advanced analysis capabilities
            </StyledMenuItemSecondaryText>
          </StyledMenuItem>

          <StyledMenuItem
            disableRipple
            onClick={() => handleAddAction(METRIC_GRID_WIDGET_TYPE)}
          >
            <StyledMenuItemPrimaryText>
              <MetricIcon /> Metric Grid
            </StyledMenuItemPrimaryText>
            <StyledMenuItemSecondaryText>
              Add tabular display of measurable dimensional data
            </StyledMenuItemSecondaryText>
          </StyledMenuItem>

          <StyledMenuItem
            disableRipple
            onClick={() => handleAddAction(LIST_GRID_WIDGET_TYPE)}
          >
            <StyledMenuItemPrimaryText>
              <ListIcon /> List grid
            </StyledMenuItemPrimaryText>
            <StyledMenuItemSecondaryText>
              Add tabular display of transactional data
            </StyledMenuItemSecondaryText>
          </StyledMenuItem>

          {chartsEnabled && (
            <StyledMenuItem
              disableRipple
              onClick={() => handleAddAction(METRIC_CHART_WIDGET_TYPE)}
            >
              <StyledMenuItemPrimaryText>
                <ChartSettingsIcon sx={{ fill: '#2C45FE' }} /> Metric chart
              </StyledMenuItemPrimaryText>
              <StyledMenuItemSecondaryText>
                Create insightful charts presenting Metric’s data
              </StyledMenuItemSecondaryText>
            </StyledMenuItem>
          )}

          <StyledMenuItem
            disableRipple
            onClick={() => handleAddAction(TEXT_WIDGET_TYPE)}
          >
            <StyledMenuItemPrimaryText>
              <TextIcon /> Text
            </StyledMenuItemPrimaryText>
            <StyledMenuItemSecondaryText>
              Add instructions, summary or any other textual data
            </StyledMenuItemSecondaryText>
          </StyledMenuItem>
        </StyledTopbarMenu>
      </>
    )
  })
