import type { ICellRendererParams } from 'ag-grid-community'
import type { GenericReportTreeRow } from '@fintastic/web/util/generic-report'
import React, { PropsWithChildren } from 'react'
import { useCellHasComment } from '../../hooks/useCellHasComment'
import { CellCommentIndicator } from '@fintastic/shared/ui/components'
import {
  CalculatedRowCellRenderer,
  FooterRowCellRenderer,
} from '@fintastic/shared/ui/ag-grid'
import { WithReportContext } from '../../types'

export const MaybeHaveCommentCellRendererWrapper: React.FC<
  PropsWithChildren<{
    params: WithReportContext<ICellRendererParams<GenericReportTreeRow>>
  }>
> = ({ params, children }) => {
  const hasComment = useCellHasComment(params)

  return (
    <span>
      {hasComment && <CellCommentIndicator />}
      {children}
    </span>
  )
}

export const MaybeHaveCommentCellRenderer: React.FC<
  ICellRendererParams<GenericReportTreeRow>
> = (params) => (
  <MaybeHaveCommentCellRendererWrapper params={params}>
    {params.valueFormatted || `${params.value}`}
  </MaybeHaveCommentCellRendererWrapper>
)

export const MaybeHaveCommentFooterRowCellRenderer: React.FC<
  ICellRendererParams<GenericReportTreeRow>
> = (params) => (
  <MaybeHaveCommentCellRendererWrapper params={params}>
    <FooterRowCellRenderer {...params} />
  </MaybeHaveCommentCellRendererWrapper>
)

export const MaybeHaveCommentCalculatedRowCellRenderer: React.FC<
  ICellRendererParams<GenericReportTreeRow>
> = (params) => (
  <MaybeHaveCommentCellRendererWrapper params={params}>
    <CalculatedRowCellRenderer {...params} />
  </MaybeHaveCommentCellRendererWrapper>
)
