import {
  ContextMenuItem,
  ContextMenuItemSubmenu,
} from '@fintastic/shared/ui/context-menu-framework'
import {
  Board,
  BoardContextMenuBuilderForSidebarBoardParams,
  BoardFolder,
  BoardShortened,
} from '../types'
import { MoveBoardToNewFolderContextMenuButton } from '../components/BoardsSidebarMenu/MoveBoardToNewFolderContextMenuButton'
import { Maybe } from '@fintastic/shared/util/types'

export type CreateSidebarBoardContextMenuBuilderParams = {
  folders: BoardFolder[]
  canDeleteBoard?: boolean
  handleCreateFolderAndMove: (
    boardId: Board['id'],
    boardName: Board['name'],
  ) => void
  handleMoveToFolder: (
    boardId: Board['id'],
    folderId: Maybe<BoardFolder['id']>,
  ) => void
  handleArchive: (boardId: Board['id'], boardName: Board['name']) => void
  handleUnArchive: (boardId: Board['id']) => void
  handleDelete: (boardId: Board['id'], boardName: Board['name']) => void
  handleRename: (boardId: Board['id']) => void
  handleDuplicate: (boardId: Board['id']) => void
  handleShare: (boardId: Board['id'], boardName: Board['name']) => void
}

export function createSidebarBoardContextMenuBuilder(
  params: CreateSidebarBoardContextMenuBuilderParams,
) {
  const {
    folders,
    canDeleteBoard,
    handleMoveToFolder,
    handleCreateFolderAndMove,
    handleArchive,
    handleUnArchive,
    handleDelete,
    handleRename,
    handleDuplicate,
    handleShare,
  } = params

  return (callbackParams: BoardContextMenuBuilderForSidebarBoardParams) => {
    const contextMenuItems: ContextMenuItem[] = []

    if (callbackParams.board.is_deleted) {
      contextMenuItems.push({
        type: 'button' as const,
        id: 'restore',
        label: 'Retrieve',
        onClick: () => handleUnArchive(callbackParams.board.id),
      })

      if (canDeleteBoard) {
        contextMenuItems.push(
          {
            type: 'divider' as const,
            id: 'div-archive',
          },
          {
            type: 'button' as const,
            id: 'delete',
            label: 'Delete',
            onClick: () =>
              handleDelete(callbackParams.board.id, callbackParams.board.name),
          },
        )
      }

      return contextMenuItems
    }

    contextMenuItems.push(
      {
        type: 'button' as const,
        id: 'rename',
        label: 'Rename',
        onClick: () => handleRename(callbackParams.board.id),
      },
      {
        type: 'button' as const,
        id: 'duplicate',
        label: 'Duplicate',
        onClick: () => handleDuplicate(callbackParams.board.id),
      },
      {
        type: 'button' as const,
        id: 'archive',
        label: 'Archive',
        onClick: () =>
          handleArchive(callbackParams.board.id, callbackParams.board.name),
      },
      {
        type: 'divider' as const,
        id: 'div-1',
      },
      {
        type: 'button' as const,
        id: 'share',
        label: 'Share',
        onClick: () =>
          handleShare(callbackParams.board.id, callbackParams.board.name),
      },
      {
        type: 'divider' as const,
        id: 'div-2',
      },
      createMoveToFolderSubmenu({
        folders,
        handleMoveToFolder,
        handleCreateFolderAndMove,
        board: callbackParams.board,
      }),
    )

    return contextMenuItems
  }
}

type CreateMoveToFolderSubmenuParams = Pick<
  CreateSidebarBoardContextMenuBuilderParams,
  'folders' | 'handleMoveToFolder' | 'handleCreateFolderAndMove'
> & {
  board: BoardShortened
}

function createMoveToFolderSubmenu(params: CreateMoveToFolderSubmenuParams) {
  const { folders, handleMoveToFolder, handleCreateFolderAndMove, board } =
    params

  const submenuItem: ContextMenuItemSubmenu = {
    id: 'moveToFolder',
    type: 'submenu' as const,
    label: 'Move to folder',
    items: [
      {
        id: 'noFolder',
        label: 'No folder',
        type: 'button' as const,
        onClick: () => {
          handleMoveToFolder(board.id, null)
        },
      },
      ...folders
        .filter((folder) => folder.id !== board.folder_id)
        .map((folder) => ({
          id: `moveTo-${folder.id}`,
          label: folder.name,
          type: 'button' as const,
          onClick: () => {
            handleMoveToFolder(board.id, folder.id)
          },
        })),
      {
        type: 'divider' as const,
        id: 'div-folders',
      },
      {
        id: 'createNewFolder',
        label: 'Create new',
        type: 'button' as const,
        onClick: () => {
          handleCreateFolderAndMove(board.id, board.name)
        },
        component: MoveBoardToNewFolderContextMenuButton,
      },
    ],
  }

  return submenuItem
}
