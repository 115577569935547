import {
  Box,
  BoxProps,
  ButtonBase,
  ButtonBaseProps,
  IconButton,
  IconButtonProps,
  styled,
  Theme,
  Typography,
} from '@mui/material'
import React, { forwardRef } from 'react'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'
export const StyledBoardRoot = styled(
  (props: BoxProps & { isHighlight?: boolean }) => {
    const { isHighlight, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'isHighlight',
  },
)(({ theme, isHighlight }) => ({
  'position': 'relative',
  'transition': `background ${theme.transitions.duration.standard}ms`,
  'background': isHighlight ? theme.palette.primary.light : undefined,
  '&:hover': {
    background: theme.palette.primary.light,
  },
  '&:hover .boards-context-menu-button': {
    opacity: 1,
    pointerEvents: 'all',
  },
}))
export const StyledBoardButton = styled(
  forwardRef<HTMLButtonElement, { level: number } & ButtonBaseProps>(
    (props, ref) => {
      const { level, ...boxProps } = props
      return <ButtonBase ref={ref} {...boxProps} />
    },
  ),
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'level',
  },
)(({ theme, level }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'paddingTop': theme.spacingFromPixels(3),
  'paddingBottom': theme.spacingFromPixels(3),
  'paddingRight': theme.spacingFromPixels(4 + 34),
  'paddingLeft': theme.spacingFromPixels(level * 24),
  'width': '100%',
  'minHeight': theme.spacingFromPixels(40),
  'textAlign': 'left',
  'transition': `background ${theme.transitions.duration.standard}ms`,
  '&.Mui-focusVisible': {
    background: theme.palette.primary.light,
  },
}))

const archiveActivePropsOptions = {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'active' && propName !== 'archive',
}

const getBoardIconColor = (
  theme: Theme,
  props: { archive?: boolean; active?: boolean },
) =>
  props.archive
    ? theme.palette.text.disabled
    : props.active
    ? theme.palette.primary.main
    : undefined

const getBoardTitleColor = (
  theme: Theme,
  props: { archive?: boolean; active?: boolean },
) =>
  props.archive
    ? theme.palette.text.disabled
    : props.active
    ? theme.palette.primary.main
    : theme.palette.text.primary

export const StyledBoardIcon = styled(
  (props: { active?: boolean; archive?: boolean } & BoxProps) => {
    const { active, archive, ...boxProps } = props
    return <Box {...boxProps} />
  },
  archiveActivePropsOptions,
)(({ theme, active, archive }) => ({
  marginRight: theme.spacingFromPixels(16),

  fontSize: 0,
  svg: {
    width: theme.spacingFromPixels(24),
    height: 'auto',

    path: {
      transition: `all ${theme.transitions.duration.standard}ms`,
      fill: getBoardIconColor(theme, { archive, active }),
    },
  },
}))

export const StyledBoardTitle = styled(
  Typography,
  archiveActivePropsOptions,
)<{
  active?: boolean
  archive?: boolean
}>(({ theme, active, archive }) => ({
  overflow: 'hidden',

  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 14,
  fontWeight: active ? 600 : 300,
  color: getBoardTitleColor(theme, { archive, active }),
}))

export const StyledBoardContextMenuButtonCont = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 4,
  ...styledMixins.alignContentCenter(),
  pointerEvents: 'none',
}))

export const StyledBoardContextMenuButton = styled(
  (props: IconButtonProps & { isVisible?: boolean }) => {
    const { isVisible, ...iconButtonProps } = props
    return <IconButton {...iconButtonProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'isVisible',
  },
)(({ theme, isVisible = false }) => ({
  'opacity': isVisible ? 1 : 0,
  'transition': `opacity ${theme.transitions.duration.standard}ms`,
  'pointerEvents': isVisible ? 'all' : 'none',

  '&.Mui-focusVisible': {
    opacity: 1,
    pointerEvents: 'all',
  },

  'svg': {
    width: 24,
    height: 'auto',
  },
}))
