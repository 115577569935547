import { DataUpdate, DataUpdateEvent } from '../../types'

export const versionId = (rawEvent: DataUpdateEvent): string =>
  rawEvent.version_id

export const userEmail = (rawEvent: DataUpdateEvent): string =>
  rawEvent.user_email

export const update = (rawEvent: DataUpdateEvent): DataUpdate =>
  rawEvent.update
