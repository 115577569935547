export const endpoints = {
  threads: () => '/collaboration/v1/threads/',
  threadsBulk: () => '/collaboration/api/v2/threads/',
  threadEvents: (thread_id: number) =>
    `/collaboration/v1/threads/${thread_id}/events/`,
  threadEventById: (thread_id: number, event_id: number) =>
    `/collaboration/v1/threads/${thread_id}/events/${event_id}/`,
  users: () => `/planning/api/v1/iam/users/`,
  threadLabelById: (thread_id: number, label_id: number) =>
    `/collaboration/api/v2/threads/${thread_id}/labels/${label_id}/`,
}
