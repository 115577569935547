import CloseIcon from '@mui/icons-material/Close'
import type { Maybe } from '@fintastic/shared/util/types'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import {
  classes,
  StyledGenericReportBottomTableWrap,
  StyledGenericReportRoot,
} from './GenericReportWrapper.styled'
import { VerticalTwoResizableContainers } from '@fintastic/shared/ui/resize-panel-v2'
import { GenericReportDetails } from '@fintastic/web/feature/generic-report-details'
import {
  GenericReportAggregatesRowMaskedData,
  GenericReportCategoryOptions,
  GenericReportDetailsQueryParams,
  GenericReportDiff,
  GenericReportId,
  GenericReportTreeRow,
  useMonthOverMonthContext,
} from '@fintastic/web/util/generic-report'
import type { AgGridReact } from 'ag-grid-react'
import type { FilterChangedEvent } from 'ag-grid-community'
import { useSyncDeeplinkValue } from '@fintastic/web/util/deeplink'
import {
  CollapseExpandButton as PanelFrameworkCollapseExpandButton,
  Panel,
  PanelBottomBar,
  PanelDragHandle,
  PanelTopbar,
  PanelTopbarTitle,
} from '@fintastic/shared/ui/panel-framework'
import { ModelExplorerVersionSelector } from '@fintastic/shared/ui/components'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Box, Tooltip } from '@mui/material'
import { MonthOverMonthToggleButton } from '@fintastic/shared/ui/grid-framework'
import { SelectedCellAggregation } from '@fintastic/web/feature/selected-cell-aggregation'
import { useWidgetContext } from '@fintastic/shared/ui/widgets-framework'
import {
  UsePeriodSelectionComponentReadyValue,
  usePeriodSelectorPredictedDataPeriodIds,
} from '@fintastic/web/feature/period-selector'
import { WidgetReportIcon } from '@fintastic/shared/ui/icons'
import { usePeriodSelectorResolution } from '@fintastic/web/util/period-selector'
import { compact } from 'lodash'
import { AxiosError } from 'axios'
import {
  castError,
  GenericReport,
  GenericReportProps,
  mapReportErrorToUi,
  successfulResponseToError,
  useFeatureTotalsColumnEnabled,
  useLoadNormalisedReportsList,
  useSetBaseTimeDimensionEffect,
} from '@fintastic/web/feature/generic-report-table'

type GenericReportWrapperProps = {
  reportCategoryId: string
  selectedReports: GenericReportId[]
  selectedDiffs?: GenericReportDiff[]
  reportNames: GenericReportProps['reportNames']
  options: GenericReportCategoryOptions
  periodSelectionComponentReadyValue: UsePeriodSelectionComponentReadyValue
}

export const GenericReportWrapper: React.FC<GenericReportWrapperProps> = memo(
  ({
    reportCategoryId,
    selectedReports,
    selectedDiffs,
    reportNames,
    options,
    periodSelectionComponentReadyValue,
  }) => {
    const widgetContext = useWidgetContext()
    const monthOverMonthContext = useMonthOverMonthContext()

    const totalsColumnFeatureEnabled = useFeatureTotalsColumnEnabled(options)
    const periodSelectorResolution = usePeriodSelectorResolution()
    const assumedPeriodList = usePeriodSelectorPredictedDataPeriodIds()

    const { isLoading, isFetching, results } = useLoadNormalisedReportsList({
      reportsOrVersionsIds: selectedReports,
      category: reportCategoryId,
      // Request total column only of we assume that we have more than 1 period
      includeTotalsColumn: totalsColumnFeatureEnabled && assumedPeriodList.length > 1,
    })
    const reportsData = useMemo(
      () => compact(results.map((r) => r.data)),
      [results],
    )

    const errorsPerVersion = useMemo(
      () =>
        compact(
          results.map(({ error, versionId, data }) => {
            if (error) {
              return (error as AxiosError).isAxiosError
                ? {
                    versionId: versionId,
                    error: castError(error as AxiosError),
                  }
                : null
            }

            if (data) {
              const error = successfulResponseToError(data || null)
              if (!error) {
                return null
              }
              return {
                versionId: versionId,
                error,
              }
            }

            return null
          }),
        ),
      [results],
    )
    const versionsWithError = useMemo(
      () => errorsPerVersion.map(({ versionId }) => versionId),
      [errorsPerVersion],
    )

    const errorUiMapping = useMemo(
      () =>
        errorsPerVersion[0]?.error
          ? mapReportErrorToUi(errorsPerVersion[0].error, {
              periodResolution: periodSelectorResolution,
            })
          : null,
      [errorsPerVersion, periodSelectorResolution],
    )

    useSetBaseTimeDimensionEffect(reportsData, errorsPerVersion)

    const [bottomPanelOpenUrl, setBottomPanelOpenUrl] =
      useSyncDeeplinkValue<boolean>({
        key: 'botttom-panel',
        defaultValue: false,
      })

    const [reportDetailsQueryParams, setReportDetailsQueryParams] =
      useState<Maybe<GenericReportDetailsQueryParams>>(null)

    const onCloseClick = React.useCallback(() => {
      setReportDetailsQueryParams(null)
      setBottomPanelOpenUrl(false)
    }, [setBottomPanelOpenUrl])

    // Close secondary table when time segmentation is changed
    const prevAggregationTypeRef = useRef(
      periodSelectionComponentReadyValue.contextValue.periodSelection
        .aggregationDimensionId,
    )
    useEffect(() => {
      if (
        periodSelectionComponentReadyValue.contextValue.periodSelection
          .aggregationDimensionId === prevAggregationTypeRef.current
      ) {
        return
      }
      onCloseClick()
      prevAggregationTypeRef.current =
        periodSelectionComponentReadyValue.contextValue.periodSelection.aggregationDimensionId
    }, [
      periodSelectionComponentReadyValue.contextValue.periodSelection
        .aggregationDimensionId,
      onCloseClick,
    ])

    // Close secondary table when version is unselected
    useEffect(() => {
      if (
        reportDetailsQueryParams?.reportOrVersionId &&
        !selectedReports.includes(reportDetailsQueryParams?.reportOrVersionId)
      ) {
        onCloseClick()
      }
    }, [selectedReports, reportDetailsQueryParams, onCloseClick])

    const onChangeDetailsParams = useCallback(
      (params: Maybe<GenericReportDetailsQueryParams>) => {
        setReportDetailsQueryParams(params)
        setBottomPanelOpenUrl(!!params)
      },
      [setBottomPanelOpenUrl],
    )

    const diffs = useMemo(() => selectedDiffs || [], [selectedDiffs])
    const detailsGridRef =
      useRef<AgGridReact<GenericReportAggregatesRowMaskedData>>(null)
    const tableFilterModel = useRef<Maybe<unknown>>(null)

    const setDetailFilter = useCallback(() => {
      detailsGridRef.current?.api.setFilterModel(tableFilterModel.current)
    }, [])

    const syncDetailsFilter = useCallback(
      (e: FilterChangedEvent<GenericReportTreeRow>) => {
        tableFilterModel.current = e?.api.getFilterModel() || null
        setDetailFilter()
      },
      [setDetailFilter],
    )

    return (
      <Panel
        topbar={
          <PanelTopbar
            enableBorderBottom={!widgetContext?.isCollapsedVert}
            leftContent={
              <>
                {widgetContext?.draggable && <PanelDragHandle />}{' '}
                {widgetContext?.collapsable && (
                  <PanelFrameworkCollapseExpandButton
                    isCollapsed={widgetContext.isCollapsedVert}
                    onClick={() =>
                      widgetContext.toggleCollapsedVert(
                        !widgetContext.isCollapsedVert,
                      )
                    }
                    data-testid="collapseExpandButton"
                  />
                )}
                {
                  <ModelExplorerVersionSelector
                    entityId={`Report.${reportCategoryId}`}
                    entityTitle={titleFormatter(options.displayLabel)}
                    disabled={widgetContext?.boardInDesignMode || isLoading}
                    disabledVersionsDueToReports={versionsWithError}
                  />
                }
              </>
            }
            centerContent={
              <PanelTopbarTitle
                icon={
                  <Tooltip title="Report">
                    <WidgetReportIcon
                      sx={{
                        color: '#D45702', // @todo extract colors to const later
                      }}
                    />
                  </Tooltip>
                }
              >
                {titleFormatter(options.displayLabel)}
              </PanelTopbarTitle>
            }
            rightContent={
              <>
                {periodSelectionComponentReadyValue.periodSelectorComponent && (
                  <Box mr={1}>
                    {periodSelectionComponentReadyValue.periodSelectorComponent}
                  </Box>
                )}
                <MonthOverMonthToggleButton
                  active={monthOverMonthContext.active}
                  setActive={monthOverMonthContext.setActive}
                  enabled={monthOverMonthContext.available}
                />
              </>
            }
          />
        }
        bottomBar={
          <PanelBottomBar>
            <SelectedCellAggregation />
          </PanelBottomBar>
        }
      >
        <StyledGenericReportRoot
          sx={
            widgetContext?.isCollapsedVert
              ? {
                  height: '0 !important',
                }
              : {}
          }
        >
          <VerticalTwoResizableContainers
            disablePaddingForHandleInBottom={true}
            topContainer={
              <GenericReport
                reportsData={reportsData}
                isLoading={isLoading}
                isFetching={isFetching}
                errorUiMapping={errorUiMapping}
                category={reportCategoryId}
                reportsOrVersionsIds={selectedReports}
                diffs={diffs}
                options={options}
                onChangeDetailsParams={onChangeDetailsParams}
                reportNames={reportNames}
                onFilterChanged={syncDetailsFilter}
                showBorderBottom={!!reportDetailsQueryParams}
                deeplinkWidgetId={widgetContext?.widgetId || ''}
                bottomPanelOpenUrl={bottomPanelOpenUrl}
              />
            }
            bottomContainer={
              (reportDetailsQueryParams && bottomPanelOpenUrl && (
                <StyledGenericReportBottomTableWrap data-testid="detailsTable">
                  <CloseIcon
                    fontSize="small"
                    className={clsx(classes.bottomTableCloseIcon)}
                    style={{ zIndex: 2 }}
                    onClick={onCloseClick}
                  />
                  <GenericReportDetails
                    params={reportDetailsQueryParams}
                    gridRef={detailsGridRef}
                    onGridReady={setDetailFilter}
                    deeplinkWidgetId={widgetContext?.widgetId || ''}
                  />
                </StyledGenericReportBottomTableWrap>
              )) ||
              undefined
            }
          />
        </StyledGenericReportRoot>
      </Panel>
    )
  },
)

