import type { WidgetRequiredSettings } from '@fintastic/shared/ui/widgets-framework'
import type { ChartSettings } from '@fintastic/web/feature/charts'

export type ChartWidgetSettings = ChartSettings

export type MetricChartWidgetSpecificSettings = {
  metricId: string
  chartSettings?: ChartWidgetSettings
}

export type MetricChartWidgetSettings = MetricChartWidgetSpecificSettings &
  WidgetRequiredSettings

export function isChartSettings(
  value: Record<string, unknown>,
): value is MetricChartWidgetSpecificSettings {
  return value?.['chartSettings'] !== undefined
}
