import { useFormulasTabs } from './useFormulasTabs'
import { createContext, useContext } from 'react'

export type FormulasTabContextValue = ReturnType<typeof useFormulasTabs>

const formulasTabContext = createContext<FormulasTabContextValue>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeFormula: () => {},
  column: null,
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openColumnFormula: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openListFormula: () => {},
  tab: null,
})

export const { Provider: FormulasTabContextProvider } = formulasTabContext

export function useFormulasTabContext() {
  return useContext(formulasTabContext)
}
