import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from '@fintastic/web/data-access/metrics-and-lists'

export const invalidateListPermissions = (
  qc: QueryClient,
  versionId: string,
  listId: string,
) =>
  qc.invalidateQueries(
    metricsAndListsCacheKeys.listPermissions({ versionId, listId }),
  )
