import { makeFromAxiosErrorFunction } from '@fintastic/shared/util/errors'
import { AxiosError } from 'axios'
import type { Maybe } from '@fintastic/shared/util/types'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'

export class UnsupportedGranularityAggregationError extends Error {
  public ERROR_TEXT = 'Entity does not support requested granularity'
  private base_time_dimension_id?: Maybe<TimeDimensionId>

  constructor(e?: AxiosError<unknown, any>) {
    super()
    Object.defineProperty(this, 'name', {
      value: 'UnsupportedGranularityAggregationError',
    })

    if (isUnsupportedGranularityAggregationErrorResponse(e)) {
      this.base_time_dimension_id =
        e.response?.data.error_message?.base_time_dimension_id
    }
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(this, (e) =>
    isUnsupportedGranularityAggregationErrorResponse(e),
  )

  public getBaseTimeDimensionId = () => this.base_time_dimension_id
}

export const isUnsupportedGranularityAggregationErrorResponse = (
  e?: AxiosError<unknown, any>,
): e is AxiosError<UnsupportedGranularityAggregationErrorAPIResponse, any> =>
  // @todo: Check response error code as well
  e?.response?.status === 422 &&
  typeof (e.response?.data as UnsupportedGranularityAggregationErrorAPIResponse)
    ?.error_message?.base_time_dimension_id === 'string'

type UnsupportedGranularityAggregationErrorAPIResponse = {
  error_message: {
    base_time_dimension_id: TimeDimensionId
  }
}
