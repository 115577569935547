import { isNodeTopCrossedContainerTop } from './isNodeTopCrossedContainerTop'
import { scrollContainerToNodeBottom } from './scrollContainerToNodeBottom'
import { scrollContainerToNodeTop } from './scrollContainerToNodeTop'
import { isNodeBottomCrossedContainerBottom } from './isNodeBottomCrossedContainerBottom'

// @todo add tests
export function scrollContainerToNode(
  container: HTMLElement,
  node: HTMLElement,
  padding = 0,
) {
  const crossedTop = isNodeTopCrossedContainerTop(container, node)
  const crossedBottom = isNodeBottomCrossedContainerBottom(container, node)

  if (crossedTop && !crossedBottom) {
    scrollContainerToNodeTop(container, node, padding)
    return
  }

  if (crossedBottom && !crossedTop) {
    scrollContainerToNodeBottom(container, node, padding)
  }
}
