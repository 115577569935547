import React, { useMemo } from 'react'
import { NavButton } from '@fintastic/shared/ui/view-layout'
import { Icon } from './Icon'
import {
  MetricConfigurableDataValueType,
  MetricDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { dataTypeNames } from '../../settings/value-data-format/data-type/options/data-type-options'

export type ValueDataFormatNavButtonProps = {
  onClick: () => void
  dataType: MetricDataValueType
}

export const ValueDataFormatNavButton: React.FC<
  ValueDataFormatNavButtonProps
> = ({ onClick, dataType }) => {
  const dataTypeLabel = useMemo(() => {
    const knownName = dataTypeNames[dataType as MetricConfigurableDataValueType]
    return knownName !== undefined ? knownName : dataType
  }, [dataType])

  return (
    <NavButton
      onClick={onClick}
      primaryLabel="Value data format"
      secondaryLabel={dataTypeLabel}
      icon={<Icon />}
    />
  )
}
