import { SyntaxLiteral } from '../../../syntax/types'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { getSuggestions } from './getSuggestions'

export function useSuggestions(
  literals: SyntaxLiteral[],
  query: string,
): SyntaxLiteral[] {
  const [suggestions, setSuggestions] = useState<SyntaxLiteral[]>([])

  const updateSuggestionsDebounced = useMemo(
    () =>
      debounce((l: SyntaxLiteral[], q: string) => {
        setSuggestions(getSuggestions(l, q))
      }, 75),
    [],
  )

  useEffect(() => {
    updateSuggestionsDebounced(literals, query)
  }, [query, literals, updateSuggestionsDebounced])

  // clean up debounce
  useEffect(
    () => () => {
      updateSuggestionsDebounced.cancel()
    },
    [updateSuggestionsDebounced],
  )

  return suggestions
}
