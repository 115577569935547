import { MetricWrapper } from './MetricWrapper'
import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  isDataTypeConversionNecessary,
  Metric,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DimensionId,
  Maybe,
  RollUpFunction,
} from '@fintastic/shared/util/types'
import * as mutations from '../common-metric/mutations'
import {
  toDimensionIdsOfType,
  VersionDimension,
} from '@fintastic/web/util/dimensions'
import {
  removeTimeDimensionWithoutAggregations,
  addDimension,
  removeDimension,
} from './data-mutation-helpers'
import { FormattingChanges } from '../common-metric/mutations'
import { MutableMetricDataWrapper } from '../metric-data'
import * as selectors from '../common-metric/selectors'

export class MutableMetricWrapper
  extends MetricWrapper
  implements MutableADTWrapper<Metric>
{
  readonly _rawData: Metric

  constructor(data: Metric) {
    super(data)
    this._rawData = data
  }

  data() {
    return new MutableMetricDataWrapper(selectors.data(this._rawData).unwrap())
  }

  setFormula(f: Maybe<string>) {
    mutations.setFormula(this.unwrap(), f)
  }

  applyCategoryAggregationChanges(
    aggFunc: RollUpFunction,
    weightsMetricId?: string,
  ) {
    mutations.applyCategoryAggregationChanges(
      this.unwrap(),
      aggFunc,
      weightsMetricId,
    )
  }

  applyTimeAggregationChanges(
    aggFunc: RollUpFunction,
    weightsMetricId?: string,
  ) {
    mutations.applyTimeAggregationChanges(
      this.unwrap(),
      aggFunc,
      weightsMetricId,
    )
  }

  applyFormattingChanges(changes: FormattingChanges) {
    mutations.applyFormattingChanges(this.unwrap(), changes)
  }

  changeDataType(
    dataType: MetricConfigurableDataValueType,
    currency: Maybe<string>,
    allDimensions: VersionDimension[],
  ) {
    const dataConversionNecessary = isDataTypeConversionNecessary(
      this.dataType(),
      dataType,
    )

    mutations.applyDataValueTypeChanges(this._rawData, {
      dataType,
      currency,
    })

    const allTimeDims = toDimensionIdsOfType(allDimensions, 'Time')
    const metricTimeDim = this.data().getTimeDimensionId(allTimeDims)
    if (
      !this.isCalculated() &&
      !this.allowedToHaveTimeDimension() &&
      metricTimeDim !== null
    ) {
      removeTimeDimensionWithoutAggregations(
        this.data(),
        metricTimeDim,
        allDimensions,
      )
      this._rawData.metadata.time_dimension_id = null
    }

    if (!this.isCalculated() && dataConversionNecessary) {
      this.data().regenerateValues(null)
    }

    // @todo remove after migration from dims metadata
    mutations.restoreDimensionsMetadataFromData(this.unwrap(), allDimensions)

    mutations.resetAggregationFunctionsToNoopIfNeeded(this.unwrap())
  }

  addDimension(dimensionId: DimensionId, allDimensions: VersionDimension[]) {
    if (this.isCalculated()) {
      throw new Error("can't add dimension to calculated metric")
    }

    addDimension(
      this,
      dimensionId,
      allDimensions,
      this.getLocalRangeDimensions(),
    )

    // @todo remove after migration from dims metadata
    mutations.restoreDimensionsMetadataFromData(this.unwrap(), allDimensions)
  }

  removeDimension(dimensionId: DimensionId, allDimensions: VersionDimension[]) {
    if (this.isCalculated()) {
      throw new Error("can't remove dimension from calculated metric")
    }

    removeDimension(this, dimensionId, allDimensions)

    // @todo remove after migration from dims metadata
    mutations.restoreDimensionsMetadataFromData(this.unwrap(), allDimensions)
  }

  setTimeDimension(
    newTimeDimensionId: Maybe<DimensionId>,
    allDimensions: VersionDimension[],
  ) {
    if (this.isCalculated()) {
      throw new Error("can't change time dimension for calculated metric")
    }

    const timeDim = this.timeDimension()

    if (timeDim) {
      this.removeDimension(timeDim, allDimensions)
    }

    if (newTimeDimensionId) {
      this.addDimension(newTimeDimensionId, allDimensions)
    }
  }
}
