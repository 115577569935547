import { useCallback, useMemo, useState } from 'react'
import { FormulaTab } from './types'
import { Maybe } from '@fintastic/shared/util/types'

// @todo add tests
export function useFormulasTabs() {
  const [isOpen, setIsOpen] = useState(false)
  const [tab, setTab] = useState<Maybe<FormulaTab>>(null)
  const [column, setColumn] = useState<Maybe<string>>(null)

  const closeFormula = useCallback(() => {
    setIsOpen(false)
  }, [])

  const openListFormula = useCallback(() => {
    setTab('list')
    setIsOpen(true)
  }, [])

  const openColumnFormula = useCallback((column: string) => {
    setColumn(column)
    setTab('column')
    setIsOpen(true)
  }, [])

  return useMemo(
    () => ({
      tab,
      column,
      isOpen,
      closeFormula,
      openListFormula,
      openColumnFormula,
    }),
    [closeFormula, column, isOpen, openColumnFormula, openListFormula, tab],
  )
}
