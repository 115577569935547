import type { QueryKey } from 'react-query'
import compact from 'lodash/fp/compact'

export const allThreadsBaseQueryKey = (): QueryKey => [
  'collaboration',
  'threads',
]

export const threadsByPageQueryKey = (page_key: string): QueryKey =>
  compact([...allThreadsBaseQueryKey(), 'raw-data', page_key])

export const threadsBulkByPageQueryKey = (page_key: string): QueryKey =>
  compact([...allThreadsBaseQueryKey(), 'bulk-data', page_key])

export const threadQueryKey = (threadId: number | number[]): QueryKey => [
  ...allThreadsBaseQueryKey(),
  threadId,
]

export const threadEventsQueryKey = (threadId: number): QueryKey => [
  ...threadQueryKey(threadId),
  'events',
]
