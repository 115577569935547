import {
  LegacyListGridRowData,
} from '@fintastic/web/feature/legacy-list-grid'

// @deprecated
export function isMasked(key: string, row: LegacyListGridRowData) {
  return false
}

export function isCellAllowClear(
  required?: boolean,
  editable?: boolean,
  isValueMasked?: boolean,
) {
  return !(!editable || required || isValueMasked)
}
