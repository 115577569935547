import React, { useMemo } from 'react'
import { DataContext, DataContextValue } from './data-context'
import { MetricAsOption, Metric } from '@fintastic/web/util/metrics-and-lists'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { Maybe } from '@fintastic/shared/util/types'
import { DependencyGraphWrapper } from '@fintastic/web/util/versions'
import { MetricWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export type MetricSettingsPanelDataProviderProps = {
  children: React.ReactNode
  metric: Maybe<Metric>
  dimensionsList: Maybe<VersionDimension[]>
  currenciesList: Maybe<Currency[]>
  metricsList: Maybe<MetricAsOption[]>
  dependencyGraph: Maybe<DependencyGraphWrapper>
  isLoading: boolean
  isLoaded: boolean
  load: () => void
}
export const MetricSettingsPanelDataProvider: React.FC<
  MetricSettingsPanelDataProviderProps
> = ({
  children,
  metric,
  dimensionsList,
  currenciesList,
  metricsList,
  isLoading,
  isLoaded,
  load,
  dependencyGraph,
}) => {
  // @todo remove rename if this part will be too temporary :)
  const wrappedMetric = useMemo(
    () => (metric ? new MetricWrapper(metric) : null),
    [metric],
  )

  const contextValue = useMemo<DataContextValue>(
    () => ({
      metric: wrappedMetric,
      dimensionsList,
      currenciesList,
      metricsList,
      isLoading,
      isLoaded,
      load,
      dependencyGraph,
    }),
    [
      wrappedMetric,
      dimensionsList,
      currenciesList,
      metricsList,
      isLoading,
      isLoaded,
      load,
      dependencyGraph,
    ],
  )

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  )
}
