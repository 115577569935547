import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnTimeBreakdownPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnTimeBreakdown = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeColumnTimeBreakdownPayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  if (!list.allowedToChangeBaseTimeDimension()) {
    // @todo move this check to list ADT
    throw new Error('setting column time dimension is not allowed')
  }

  const timeDimensionId = list.baseTimeDimension()
  if (timeDimensionId === null) {
    throw new Error('the dime dim is not defined in the list')
  }

  if (payload.applyBreakdown) {
    list
      .findColumnByClientId(payload.columnId)
      ?.addTimeDimension(timeDimensionId, payload.allDimensions)
  } else {
    list.findColumnByClientId(payload.columnId)?.removeTimeDimension()
  }
}
