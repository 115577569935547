import { useQuery } from 'react-query'
import { Maybe } from '@fintastic/shared/util/types'
import { metricsAndListsCacheKeys } from '../cache'
import { getMetricChartWithoutData } from '../api/metrics-chart-api'

export function useLoadMetricForCharts(
  versionId?: Maybe<string>,
  metricId?: Maybe<string>,
  enabled = true,
) {
  return useQuery(
    metricsAndListsCacheKeys.metricChartDims(versionId, metricId),
    async () => {
      if (!versionId || !metricId) {
        throw new Error('versionId or metricId is not defined')
      }
      const response = await getMetricChartWithoutData(versionId, metricId)
      return response.data
    },
    {
      enabled: !!versionId && !!metricId && enabled,
      refetchInterval: false as const,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
    },
  )
}
