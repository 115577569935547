import { useQuery } from 'react-query'
import { getList } from '../api/lists-api'
import type { Maybe } from '@fintastic/shared/util/types'
import type { List } from '@fintastic/web/util/metrics-and-lists'
import type { AxiosError } from 'axios'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'

export function useLoadList(
  versionId?: Maybe<string>,
  listId?: Maybe<string>,
  periodSelection?: PeriodSelection,
  enabled = true,
) {
  return useQuery<List, AxiosError | Error>(
    metricsAndListsCacheKeys.list(versionId, listId, periodSelection),
    async () => {
      if (!versionId || !listId) {
        throw new Error('versionId or listId is not defined')
      }
      const response = await getList(versionId, listId, periodSelection)
      return response.data
    },
    {
      enabled: enabled && !!versionId && !!listId,
      retry: 1,
    },
  )
}
