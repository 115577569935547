import React, { ReactNode } from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import {
  StyledDialogButtonContainer,
  StyledDialogCancelButton,
  StyledDialogSubmitButton,
} from './StandardConfirmationModal.styled'
import { Typography } from '@mui/material'
import { ErrorAlert } from './ErrorAlert'

export type StandardConfirmationModalProps = {
  open: boolean
  title: string
  text: string
  description?: string | ReactNode
  confirmText: string
  cancelText?: string
  onRequestClose: () => void
  onConfirm: () => void
}

export const StandardConfirmationModal: React.FC<
  StandardConfirmationModalProps
> = ({
  open,
  onRequestClose,
  onConfirm,
  title,
  text,
  confirmText,
  cancelText,
  description,
}) => (
  <Modal
    open={open}
    onRequestClose={onRequestClose}
    title={title}
    data-testid="board-confirm-widget-root"
  >
    <div style={{ marginTop: 16 }}>
      <ErrorAlert title={text} severity="warning" message={false} />
    </div>
    {description && <Typography mt={2}>{description}</Typography>}

    <StyledDialogButtonContainer>
      <StyledDialogCancelButton
        variant="outlined"
        disabled={false}
        onClick={onRequestClose}
      >
        {cancelText || 'Cancel'}
      </StyledDialogCancelButton>

      <StyledDialogSubmitButton
        variant="contained"
        type="submit"
        onClick={onConfirm}
      >
        {confirmText}
      </StyledDialogSubmitButton>
    </StyledDialogButtonContainer>
  </Modal>
)
