import { memo, MemoExoticComponent, useCallback, useMemo } from 'react'
import { compact } from 'lodash'
import { VersionSelectorItem } from '../types'
import {
  AutoDropdown,
  VersionLockUnlockIcon,
} from '@fintastic/shared/ui/components'
import {
  StyledChip,
  StyledChipsContainer,
  StyledMultipartDiffText,
} from './VersionsChipsContainer.styled'
import { DeleteChipButton } from './chip/DeleteChipButton'
import { useVersionUserLocks } from '@fintastic/web/data-access/versions'
import { Tooltip } from '@mui/material'
import {
  DiffMode,
  DiffPart,
  isDiffPairEqualsByVersions,
  versionIsLockedMessage,
} from '@fintastic/web/util/versions'
import { VersionsChipsDropdown } from './VersionChipsDropdown'

export type VersionsChipsContainerProps = {
  selectedVersions: string[]
  versions: VersionSelectorItem[]
  handleVersions: (version: VersionSelectorItem, updateStore?: boolean) => void
  selectedDiffs: [string, string, DiffMode][]
  getDiffPairLabels: (diffIds: [string, string, DiffMode]) => [string, string]
  selectDiffs: (diffs: [string, string, DiffMode][]) => void
  hideDiffs?: boolean
}

export const VersionsChipsContainer: MemoExoticComponent<
  (props: VersionsChipsContainerProps) => JSX.Element
> = memo(
  ({
    selectedVersions,
    versions,
    handleVersions,
    selectedDiffs,
    getDiffPairLabels,
    selectDiffs,
    hideDiffs = false,
  }) => {
    const currentVersions = useMemo(
      () =>
        compact(
          selectedVersions.map((sv) =>
            versions.find((version) => version.id === sv),
          ),
        ),
      [selectedVersions, versions],
    )
    const currentDiffs = useMemo(() => selectedDiffs, [selectedDiffs])

    const handleRemoveDiffs = useCallback(
      (selectedDiff: [string, string, DiffMode]) => {
        const filteredSelectedDiffs = selectedDiffs.filter(
          (diff) => diff !== selectedDiff,
        )
        selectDiffs(filteredSelectedDiffs)
      },
      [selectedDiffs, selectDiffs],
    )

    const handleChangeDiffMode = useCallback(
      (selectedDiff: [string, string, DiffMode]) => {
        let currentSelectedDiffs = [...selectedDiffs]

        const existingPair = currentSelectedDiffs.find((col) =>
          isDiffPairEqualsByVersions(col, selectedDiff),
        )

        if (!existingPair) {
          return
        }

        currentSelectedDiffs = currentSelectedDiffs.map((col) => {
          if (isDiffPairEqualsByVersions(col, selectedDiff)) {
            return selectedDiff
          }
          return col
        })
        selectDiffs(currentSelectedDiffs)
      },
      [selectedDiffs, selectDiffs],
    )

    const diffsChip = useMemo(
      () =>
        currentDiffs.map((selectedDiff) => {
          if (hideDiffs) {
            return null
          }

          const labels = getDiffPairLabels(selectedDiff)
          const labelComponent =
            labels.length === 2 ? (
              <StyledMultipartDiffText noWrap={true}>
                <span>{labels[0]}</span>
                <VersionsChipsDropdown
                  mode={selectedDiff[DiffPart.mode] as DiffMode}
                  setMode={(mode) =>
                    handleChangeDiffMode([
                      selectedDiff[DiffPart.firstVersion],
                      selectedDiff[DiffPart.secondVersion],
                      mode,
                    ])
                  }
                />
                <span>{labels[1]}</span>
              </StyledMultipartDiffText>
            ) : null

          return (
            <StyledChip
              key={labels.join('-')}
              label={labelComponent}
              onDelete={() => handleRemoveDiffs(selectedDiff)}
              deleteIcon={
                <DeleteChipButton
                  onClick={() => handleRemoveDiffs(selectedDiff)}
                />
              }
            />
          )
        }),
      [currentDiffs, getDiffPairLabels, handleRemoveDiffs, hideDiffs],
    )

    const currentVersionIds = useMemo(
      () => currentVersions.map((v) => v.id),
      [currentVersions],
    )
    const versionUserLocks = useVersionUserLocks(currentVersionIds)

    const currentChips = useMemo(
      () =>
        currentVersions.map((version) => {
          const verionUserLock = versionUserLocks.find(
            (lock) => lock.versionId === version.id,
          )

          const chip = (
            <StyledChip
              key={version.name}
              icon={
                <VersionLockUnlockIcon
                  isLocked={version.isLocked}
                  sx={{ margin: '0 -6px 0 10px' }}
                  editDisabled={verionUserLock?.editIsBlocked}
                />
              }
              label={version.name}
              data-testid={`versions-and-diff-select-chip-version-${version.name}`}
              onDelete={
                currentVersions.length > 1
                  ? () => handleVersions(version, true)
                  : undefined
              }
              deleteIcon={
                <DeleteChipButton
                  onClick={
                    currentVersions.length > 1
                      ? () => handleVersions(version, true)
                      : undefined
                  }
                />
              }
            />
          )

          if (verionUserLock?.editIsBlocked) {
            return (
              <Tooltip
                key={version.name}
                title={versionIsLockedMessage(verionUserLock)}
              >
                {chip}
              </Tooltip>
            )
          }

          return chip
        }),
      [currentVersions, handleVersions, versionUserLocks],
    )

    const items = useMemo(
      () =>
        diffsChip && diffsChip.length > 0
          ? [...currentChips, ...diffsChip]
          : [...currentChips],
      [currentChips, diffsChip],
    )

    return (
      <StyledChipsContainer data-testid="chips-container">
        <AutoDropdown items={items} />
      </StyledChipsContainer>
    )
  },
)

export default VersionsChipsContainer
