import { styled, Box } from '@mui/material'

export const StyledRoot = styled('div')`
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
`

export const StyledIconContainer = styled('div')`
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(44, 69, 254, 0.119);
  border-radius: 50%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`
