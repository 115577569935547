import { styled, Box, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'space-between',

  width: '100%',
}))
export const StyledLabel = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
