import {
  PeriodSelection,
  isRangeBasedSelection,
} from '@fintastic/web/util/period-selector'
import { Maybe } from '@fintastic/shared/util/types'

export const mapPeriodSelectionToParams = (
  periodSelection?: Maybe<PeriodSelection>,
) => {
  if (!periodSelection) {
    return {}
  }

  if (isRangeBasedSelection(periodSelection)) {
    return {
      from_dim_val: periodSelection.range[0],
      to_dim_val: periodSelection.range[1],
      aggregation: periodSelection.aggregationDimensionId,
      time_dim_id: periodSelection.dimensionId,
    }
  }

  return {
    periods: periodSelection.periods,
    aggregation: periodSelection.aggregationDimensionId,
    time_dim_id: periodSelection.dimensionId,
  }
}
