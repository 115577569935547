import { SaveFormulaApi } from './types'
import { useEffect, useRef } from 'react'

type SavingFlags = {
  hasError: boolean
  inProgress: boolean
}

export function useSyncEditingStateWithSavingState(
  saveApi: SaveFormulaApi,
  exitEditingMode: () => void,
) {
  const prevFlagsRef = useRef<SavingFlags>({
    hasError: !!saveApi.error,
    inProgress: saveApi.calculating,
  })

  useEffect(() => {
    if (
      !saveApi.calculating &&
      prevFlagsRef.current.inProgress !== saveApi.calculating &&
      !saveApi.error
    ) {
      exitEditingMode()
    }
    prevFlagsRef.current = {
      hasError: !!saveApi.error,
      inProgress: saveApi.calculating,
    }
  }, [exitEditingMode, saveApi.error, saveApi.calculating])
}
