import {
  getLocalStorageValue,
  setLocalStorageValue,
} from '@fintastic/shared/util/hooks'
import React, { useCallback, useRef } from 'react'
import { useAuthAccessToken } from '@fintastic/web/feature/auth'

interface ComponentState<T extends JSON = JSON> {
  userEmail: string
  name: string
  state: T
}

interface LocalStorageData<T extends JSON = JSON> {
  componentStates: Array<ComponentState<T>>
}

const KEY = 'componentStates'

const getComponentState = <T extends JSON = JSON>(
  userEmail: string,
  componentName: string,
): T => {
  const localStorageData = getLocalStorageValue(
    KEY,
  ) as unknown as LocalStorageData<T>
  const componentState = localStorageData?.componentStates.find(
    (cs) => cs.userEmail === userEmail && cs.name === componentName,
  )
  return componentState?.state as T
}

const saveComponentState = <T extends JSON = JSON>(
  userEmail: string,
  componentName: string,
  state: T,
): void => {
  const localStorageData = getLocalStorageValue(
    KEY,
  ) as unknown as LocalStorageData<T>
  const componentState: ComponentState<T> = {
    userEmail,
    name: componentName,
    state,
  }
  const componentStates =
    localStorageData?.componentStates.filter(
      (cs) => !(cs.userEmail === userEmail && cs.name === componentName),
    ) || []

  setLocalStorageValue(
    KEY,
    JSON.stringify({ componentStates: [...componentStates, componentState] }),
  )
}

export const useComponentState = <T extends JSON = JSON>(
  componentName: string,
): [T | undefined, React.Dispatch<React.SetStateAction<T>>] => {
  const userEmail = useAuthAccessToken()?.user?.email ?? 'unknown'

  const componentStateRef = useRef<T>()

  if (!componentStateRef.current) {
    componentStateRef.current = getComponentState<T>(userEmail, componentName)
  }

  const setComponentState = useCallback(
    (nextValue: React.SetStateAction<T>) => {
      if (typeof nextValue === 'function') {
        componentStateRef.current = nextValue(componentStateRef.current as T)
      } else {
        componentStateRef.current = nextValue
      }

      saveComponentState<T>(userEmail, componentName, componentStateRef.current)
    },
    [componentName, userEmail],
  )

  return [componentStateRef.current, setComponentState]
}

type JSON = string | number | boolean | { [x: string]: JSON } | Array<JSON>
