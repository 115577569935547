import { Maybe } from '@fintastic/shared/util/types'
import { RESOLUTIONS_ORDERED_MAP } from '@fintastic/web/util/period-selector'
import { useLoadVersionEntities } from '../api/useLoadVersionEntities'
import { useMemo } from 'react'
import { sortBy } from 'lodash'
import {
  TimeDimensionId,
  VersionTimeDimension,
} from '@fintastic/web/util/dimensions'

export const useVersionBaseTimeDimension = (
  versionId: string,
): Maybe<TimeDimensionId> => {
  const entities = useLoadVersionEntities(versionId)

  return useMemo<Maybe<TimeDimensionId>>(() => {
    if (!entities.data) {
      return null
    }

    const timeDimensions = sortBy(
      entities.data.dimensions.filter(
        (i) => i.type === 'Time',
      ) as VersionTimeDimension[],
      (d) => RESOLUTIONS_ORDERED_MAP[d.resolution] ?? 99,
    )

    return timeDimensions[0]?.id ?? null
  }, [entities.data])
}
