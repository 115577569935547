import { Box, styled } from '@mui/material'

export const StyledDataHeader = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`

export const StyledDateSeparator = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding-left: 8px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`

export const StyledHeaderIcon = styled(Box)`
  svg {
    fill: ${({ theme }) => theme.palette.text.secondary};
  }

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 32px;
  height: 100%;
`

export const StyledHeaderContent = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
`

export const StyledTitle = styled(Box)`
  display: flex;
  flex-flow: row nowrap;

  b {
    font-weight: 500;
    margin-left: 16px;
    position: relative;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      transform: translateY(-50%);
      background-color: ${({ theme }) => theme.palette.text.secondary};
      border-radius: 100%;
      top: 50%;
      left: -11px;
      display: block;
    }
  }
`

export const StyledSubTitle = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  color: ${({ theme }) => theme.palette.text.secondary};
`
