import {
  CommentEventIn,
  CommentEventOut,
  CommentThreadIn,
  CommentThreadOut,
  CommentsThreadsBulkItem,
  ReplaceEvent,
} from '../types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosResponse } from 'axios'

export function postThreadIn(threadIn: CommentThreadIn) {
  return axios.post<
    CommentThreadOut,
    AxiosResponse<CommentThreadOut>,
    CommentThreadIn
  >(endpoints.threads(), threadIn)
}

export function postEventIn(threadId: number, eventIn: CommentEventIn) {
  return axios.post<
    CommentEventOut,
    AxiosResponse<CommentEventOut>,
    CommentEventIn
  >(endpoints.threadEvents(threadId), eventIn)
}

export function deleteEvent(threadId: number, event_id: number) {
  return axios.delete(endpoints.threadEventById(threadId, event_id))
}

export function replaceEvent(threadId: number, eventReplacement: ReplaceEvent) {
  return axios.put(
    endpoints.threadEventById(threadId, eventReplacement.id),
    eventReplacement,
  )
}

export function attachThreadLabel(threadId: number, labelId: number) {
  return axios.post<string>(endpoints.threadLabelById(threadId, labelId))
}

export function detachThreadLabel(threadId: number, labelId: number) {
  return axios.delete<string>(endpoints.threadLabelById(threadId, labelId))
}

export function getAllThreadsBulk(
  page_key: string,
  is_resolved?: boolean,
  compact?: boolean,
) {
  return axios.get<{
    result: CommentsThreadsBulkItem[]
  }>(endpoints.threadsBulk(), {
    params: {
      page_key,
      is_resolved,
      compact,
    },
  })
}
