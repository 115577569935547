import React from 'react'
import {
  StyledColumnChip,
  StyledContent,
  StyledLabel,
  StyledRoot,
} from './CardColumnSection.styled'
import { CardSectionLabel } from './CardSectionLabel'

export const CardColumnSection: React.FC<{ columnLabel: string }> = ({
  columnLabel,
}) => (
  <StyledRoot>
    <StyledLabel>
      <CardSectionLabel>Column</CardSectionLabel>
    </StyledLabel>
    <StyledContent>
      <StyledColumnChip>{columnLabel}</StyledColumnChip>
    </StyledContent>
  </StyledRoot>
)
