import { IAggFuncParams } from 'ag-grid-community'

// copied from the AgGrid source code:
// https://github.com/ag-grid/ag-grid/blob/ca68754eef544c3ece8d3fb800f70d39e9be23e1/grid-enterprise-modules/row-grouping/src/rowGrouping/aggFuncService.ts#L97
export function sum(params: IAggFuncParams): number | bigint {
  const { values } = params
  let result: any = null // the logic ensures that we never combine bigint arithmetic with numbers, but TS is hard to please

  // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
  for (let i = 0; i < values.length; i++) {
    const value = values[i]

    if (typeof value === 'number' || typeof value === 'string') {
      const valueToProcess =
        typeof value === 'number' ? value : parseFloat(value)
      if (result === null) {
        result = valueToProcess
      } else {
        if (BigInt) {
          result +=
            typeof result === 'number' ? valueToProcess : BigInt(valueToProcess)
        } else {
          result += valueToProcess
        }
      }
    } else if (typeof value === 'bigint') {
      if (result === null) {
        result = value
      } else {
        result = (typeof result === 'bigint' ? result : BigInt(result)) + value
      }
    }
  }

  return result
}
