import {
  HistoryLogEntryChangeColumnValue,
  HistoryLogEntryChangeMetricValue,
  HistoryLogEntryType,
  HistoryLogEntryAddListLine,
  HistoryLogEntryDeleteListLine,
} from '@fintastic/web/util/history'

export const supportedEntryTypes: HistoryLogEntryType[] = [
  'change_column_value',
  'change_metric_value',
  'add_list_line',
  'delete_list_line',
]

export type SupportedEntry =
  | HistoryLogEntryChangeColumnValue
  | HistoryLogEntryChangeMetricValue
  | HistoryLogEntryAddListLine
  | HistoryLogEntryDeleteListLine
