import React, { useMemo } from 'react'
import { RouteObject } from './types'
import { useLocation } from './location/useLocation'
import { useRouteMatches } from './matching/useRouteMatches'
import { renderMatches } from './rendering/renderMatches'
import { RouterContext } from './context/router-context'

export type MemoryRouterProps = {
  routes: RouteObject[]
  location: ReturnType<typeof useLocation>
}

export const MemoryRouter: React.FC<MemoryRouterProps> = ({
  routes,
  location,
}) => {
  const matches = useRouteMatches(routes, location.path)

  return (
    <RouterContext.Provider
      value={useMemo(
        () => ({
          goTo: location.goTo,
          goBack: location.goBack,
        }),
        [location.goBack, location.goTo],
      )}
    >
      {useMemo(() => renderMatches(matches), [matches])}
    </RouterContext.Provider>
  )
}
