import {
  getAllowedRollUps,
  getDisallowedRollUps,
  Metric,
  MetricConfigurableDataValueType,
  MetricDisplaySettings,
  MetricDisplaySettingsFormatting,
} from '@fintastic/web/util/metrics-and-lists'
import { Maybe, RollUpFunction } from '@fintastic/shared/util/types'
import * as predicates from './predicates'
import * as selectors from './selectors'
import { MutableMetricDisplaySettingsWrapper } from '../display-settings'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export const setCategoryAggregation = (m: Metric, aggFunc: RollUpFunction) => {
  m.metadata.value.category_agg = selectors.getAllowedRollUp(
    m,
    aggFunc,
    'Category',
  )
}

export const setTimeAggregation = (m: Metric, aggFunc: RollUpFunction) => {
  m.metadata.value.roll_up_function = selectors.getAllowedRollUp(
    m,
    aggFunc,
    'Time',
  )
}

export const setAggregationWeightsMetricId = (
  m: Metric,
  metricId: Maybe<string>,
) => {
  m.metadata.value.weights_metric_id = metricId
}

export const setDataType = (
  m: Metric,
  dataType: MetricConfigurableDataValueType,
) => {
  m.metadata.value.type = dataType
}

export const setFormula = (m: Metric, formula: Maybe<string>) => {
  if (formula !== null && !predicates.isCalculated(m)) {
    throw new Error("Can't set formula on non-calculated metric")
  }

  m.metadata.formula = formula
}

const applyAggregationChanges = (
  setters: {
    primary: (m: Metric, a: RollUpFunction) => void
    secondary: (m: Metric, a: RollUpFunction) => void
  },
  m: Metric,
  aggFunc: RollUpFunction,
  weightsMetricId?: string,
) => {
  const isWeightedAverage = aggFunc === 'weighted_avg'

  if (isWeightedAverage && weightsMetricId === undefined) {
    throw new Error(
      'weightsMetricId should be provided when change the aggFunc to weighted average',
    )
  }

  const applyToBoth =
    isWeightedAverage || predicates.usesWeightedAverageAggregation(m)

  setters.primary(m, aggFunc)
  if (applyToBoth) {
    setters.secondary(m, aggFunc)
  }

  if (isWeightedAverage) {
    weightsMetricId !== undefined &&
      setAggregationWeightsMetricId(m, weightsMetricId)
  } else {
    setAggregationWeightsMetricId(m, null)
  }

  resetAggregationFunctionsToNoopIfNeeded(m)
}

export const applyCategoryAggregationChanges = (
  m: Metric,
  aggFunc: RollUpFunction,
  weightsMetricId?: string,
) =>
  applyAggregationChanges(
    {
      primary: setCategoryAggregation,
      secondary: setTimeAggregation,
    },
    m,
    aggFunc,
    weightsMetricId,
  )

export const applyTimeAggregationChanges = (
  m: Metric,
  aggFunc: RollUpFunction,
  weightsMetricId?: string,
) =>
  applyAggregationChanges(
    {
      primary: setTimeAggregation,
      secondary: setCategoryAggregation,
    },
    m,
    aggFunc,
    weightsMetricId,
  )

export function applyDataValueTypeChanges(
  m: Metric,
  changes: {
    dataType: MetricConfigurableDataValueType
    currency: Maybe<string>
  },
) {
  const { dataType, currency } = changes

  setDataType(m, dataType)
  const displaySettings = new MutableMetricDisplaySettingsWrapper(
    selectors.displaySettings(m).unwrap(),
  )
  displaySettings.setCurrency(currency)
  displaySettings.resetFormattingToDefault()
  displaySettings.setCurrencySignPosition('before')

  const allowedTimeDimensionRollups = getAllowedRollUps(dataType, 'Time')
  const disallowedTimeDimensionRollups = getDisallowedRollUps(dataType, 'Time')
  const allowedCategoryDimensionRollups = getAllowedRollUps(
    dataType,
    'Category',
  )
  const disallowedCategoryDimensionRollups = getDisallowedRollUps(
    dataType,
    'Category',
  )

  if (
    (allowedTimeDimensionRollups[0] === 'weighted_avg' ||
      allowedCategoryDimensionRollups[0] === 'weighted_avg') &&
    !selectors.weightsMetricId(m)
  ) {
    throw new Error(
      "can't preset the default roll up: need to set up the weights_metric_id field",
    )
  }

  if (disallowedTimeDimensionRollups.includes(selectors.timeAggregation(m))) {
    applyTimeAggregationChanges(m, allowedTimeDimensionRollups[0])
  }
  if (
    disallowedCategoryDimensionRollups.includes(
      selectors.categoryAggregation(m),
    )
  ) {
    applyCategoryAggregationChanges(m, allowedCategoryDimensionRollups[0])
  }
}

export function resetAggregationFunctionsToNoopIfNeeded(m: Metric) {
  if (predicates.usesAnyOfDataTypes(m, ['string', 'datetime', 'dimension'])) {
    setCategoryAggregation(m, 'noop')
    setTimeAggregation(m, 'noop')
    setAggregationWeightsMetricId(m, null)
  }
}

// @todo kill it, it's just for testing, until we migrate to entities EP
export function restoreDimensionsMetadataFromData(
  m: Metric,
  allDimensions: VersionDimension[],
) {
  m.metadata.dimensions = selectors
    .data(m)
    .toDimensionsList(allDimensions)
    .filter((d) => d.type !== 'Category') // @todo keep here only range dimensions
    .map((d) => {
      const localRangeDim = selectors
        .getLocalRangeDimensions(m)
        .find((ld) => ld.id === d.id)
      return localRangeDim
        ? {
            ...d,
            values: {
              ...localRangeDim.values,
            },
          }
        : d
    })
}

export type FormattingChanges = {
  decimalPlaces?: number
  compactFormatting?: Maybe<
    Exclude<MetricDisplaySettingsFormatting['compact_formatting'], 'no_format'>
  >
  currencySignPosition?: MetricDisplaySettings['currency_sign_position']
}

export const applyFormattingChanges = (
  m: Metric,
  changes: FormattingChanges,
) => {
  const displaySettings = new MutableMetricDisplaySettingsWrapper(
    selectors.displaySettings(m).unwrap(),
  )

  if (changes.decimalPlaces !== undefined) {
    displaySettings.setNumericDecimalPlaces(changes.decimalPlaces)
  }

  if (changes.compactFormatting !== undefined) {
    displaySettings.setNumericCompactFormat(changes.compactFormatting)
  }

  if (changes.currencySignPosition !== undefined) {
    displaySettings.setCurrencySignPosition(changes.currencySignPosition)
  }
}
