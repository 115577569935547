import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, styled, Tab } from '@mui/material'
import React, { useCallback, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { FiltersIcon } from '@fintastic/shared/ui/icons'

type FloatingPanelTab = 'column' | 'filter'

export const FloatingPanel: React.FC<FloatingPanelProps> = ({
  columnsTab,
  filtersTab,
}) => {
  const [tab, setTab] = useState<FloatingPanelTab>('column')

  const handleChange = useCallback(
    (event: React.SyntheticEvent, value: unknown) => {
      setTab(value as FloatingPanelTab)
    },
    [],
  )

  return (
    <Box width="240px" data-testid="column-floating-panel">
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList
            onChange={handleChange}
            aria-label="Table column menu"
            variant="fullWidth"
            sx={{ padding: 0, minHeight: '38px' }}
          >
            <StyledTab
              label={<MenuIcon />}
              value="column"
              sx={{ padding: 0, minHeight: '38px' }}
            />
            <StyledTab
              label={<FiltersIcon />}
              value="filter"
              sx={{ padding: 0, minHeight: '38px' }}
            />
          </TabList>
        </Box>
        <TabPanel value="column" sx={{ padding: 0 }}>
          {columnsTab}
        </TabPanel>
        <TabPanel value="filter" sx={{ padding: 0 }}>
          {filtersTab}
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export type FloatingPanelProps = {
  columnsTab: React.ReactNode
  filtersTab: React.ReactNode
}

const StyledTab =styled(Tab)`
  & .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`