import React, { useCallback } from 'react'
import {
  Avatar,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Check2Icon } from '@fintastic/shared/ui/icons'

export type LabelsEditMenuColorItemProps = {
  selected: boolean
  color: {
    hex: string
    label: string
    bg: string
  }
  onColorSelection: (color: { hex: string; label: string, bg: string }) => void
}

export const LabelsEditMenuColorItem: React.FC<
  LabelsEditMenuColorItemProps
> = ({ selected, color, onColorSelection }) => {
  const handleColorSelection = useCallback(() => {
    onColorSelection(color)
  }, [color, onColorSelection])

  return (
    <ListItem
      disablePadding
      secondaryAction={selected ? <Check2Icon htmlColor="#D45702" fontSize="small" /> : null}
    >
      <ListItemButton onClick={handleColorSelection} dense>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Avatar
            sx={{
              background: color.bg,
              width: '20px',
              height: '20px',
              marginRight: '8px',
            }}
          >
            {' '}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={
            <Box display="flex" alignItems="center">
              {color.label}
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
