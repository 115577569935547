import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeMetricTimeAggregationPayload } from '@fintastic/web/data-access/metrics-and-lists'
import { State } from '../types'
import { castToMetricEditingState } from './helpers'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

export const changeMetricTimeAggregation = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeMetricTimeAggregationPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  const metric = new MutableMetricWrapper(state.metric)

  metric.applyTimeAggregationChanges(
    payload.rollUpFunction,
    payload.weightsMetricId,
  )
}
