import { Box, Modal } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import styledComp from 'styled-components'

const PREFIX = 'ModalDialog'

const classes = {
  closeIcon: `${PREFIX}-closeIcon`,
}

const $ModalOuterWrap = styledComp.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  overflow: auto;
`

const $ModalInnerWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: 'auto',
  padding: '3rem 2rem 1.5rem',
  border: 'solid 1px var(--color-border-modal)',
  borderRadius: '10px',
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',
  background: 'var(--color-bg-modal)',

  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    top: '1.2rem',
    right: '1.2rem',
    color: 'var(--color-icon-nav-inactive)',
    cursor: 'pointer',
  },
}))

export type Props = {
  open: boolean
  maxWidth?: string
  onClose?: () => void
  sx?: SxProps
  children?: ReactNode
}

export const ModalDialog = ({
  open,
  maxWidth,
  onClose,
  children,
  sx,
}: Props) => {
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Modal open={open} sx={sx}>
      <$ModalOuterWrap className={`${PREFIX}-outerWrap`}>
        <$ModalInnerWrap
          className={`${PREFIX}-innerWrap`}
          style={{ maxWidth: maxWidth || '40rem' }}
        >
          {onClose && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
              fill="none"
              width="24"
              height="24"
              className={clsx(classes.closeIcon)}
              onClick={handleClose}
            >
              <path
                d="M22.8 1.2 1.2 22.8M1.2 1.2l21.6 21.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ stroke: 'var(--color-text)' }}
              />
            </svg>
          )}
          {children}
        </$ModalInnerWrap>
      </$ModalOuterWrap>
    </Modal>
  )
}
