import { createContext, useContext } from 'react'

export type DrawerContext = Record<string, unknown>

export type DrawerContextValue = {
  [key: string]: DrawerContext
}

const currentContextValue: DrawerContextValue = {}

const context = createContext<DrawerContextValue>(currentContextValue)

export const {
  Provider: DrawerContextProvider,
  Consumer: DrawerContextConsumer,
} = context

export function useDrawerContext() {
  return useContext(context)
}

export function useTypedDrawerContext(type: string) {
  return useContext(context)[type]
}
