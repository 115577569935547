import { useCallback } from 'react'
import { BoardFolder } from '@fintastic/web/feature/boards'
import { useDispatch } from 'react-redux'
import { expandFolder } from '../ducks/boards-ui'

export function useBoardFolderOpen() {
  const dispatch = useDispatch()
  return useCallback(
    (folderId: BoardFolder['id']) => {
      dispatch(expandFolder(folderId))
    },
    [dispatch],
  )
}
