import React, { useCallback, useMemo, useState } from 'react'
import { EntityFormSharedProps } from './types'
import { Box } from '@mui/material'
import { SourceField } from './SourceField'
import { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'
import {
  useListsActions,
  useLoadListOfLists,
} from '@fintastic/web/data-access/metrics-and-lists'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { validateEntityName } from './utils/validation'
import {
  invalidateAllVersionRelatedQueries,
  useLockVersionForUser,
} from '@fintastic/web/data-access/versions'
import { useQueryClient } from 'react-query'
import { toast } from '@fintastic/shared/ui/toast-framework'

export const NewListForm: React.FC<EntityFormSharedProps> = ({
  name,
  nameFieldSlot,
  typeFieldSlot,
  buttonsSlot,
  versionId,
  isLiveActuals,
  requestModalClose,
}) => {
  const [source, setSource] = useState<MetricOrListSource>('input')

  const existingListsQuery = useLoadListOfLists(versionId)
  const existingNames = useMemo(
    () =>
      Object.fromEntries(
        (existingListsQuery.data || []).map(({ label }) => [label, true]),
      ),
    [existingListsQuery.data],
  )

  const nameValidation = useMemo(
    () => validateEntityName(name, (n) => !existingNames[n]),
    [name],
  )

  const { startCreation: startListCreation } = useListsActions()
  const { mutate: lockVersionForUser, isLoading: isLockerStatusLoading } =
    useLockVersionForUser(versionId)
  const queryClient = useQueryClient()

  const handleSubmit = useCallback(() => {
    lockVersionForUser(undefined, {
      onSuccess: () => {
        startListCreation({ name, source })
        requestModalClose()
      },
      onError: async () => {
        toast.error('Another user is working with this version right now')
        await invalidateAllVersionRelatedQueries(queryClient, versionId)
      },
    })
  }, [
    lockVersionForUser,
    queryClient,
    startListCreation,
    versionId,
    requestModalClose,
    name,
    source,
  ])

  if (existingListsQuery.isLoading) {
    return <CenteredCircularProgress fullScreen={false} />
  }

  return (
    <Box>
      <Box>
        {nameFieldSlot({
          isValid: nameValidation === null,
          validationMessage: nameValidation || '',
          disabled: isLockerStatusLoading,
        })}
      </Box>
      <Box mt={2}>{typeFieldSlot({ disabled: isLockerStatusLoading })}</Box>
      <Box mt={2}>
        <SourceField
          value={source}
          onChange={setSource}
          disabled={isLockerStatusLoading}
          isLiveActuals={isLiveActuals}
        />
      </Box>
      <Box mt={3}>
        {buttonsSlot({
          continueEnabled: nameValidation === null,
          isSubmitting: isLockerStatusLoading,
          onContinue: handleSubmit,
        })}
      </Box>
    </Box>
  )
}
