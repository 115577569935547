import {
  MasterDetailTab,
  useMasterDetailTabsState,
} from './useMasterDetailTabsState'
import { useCallback, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { RowNode } from 'ag-grid-community'
import { nodeIsMaster } from './nodeIsMaster'
import { toggleMasterDetail } from './toggleMasterDetail'
import { useMasterDetailsExpandState } from './useMasterDetailsExpandState'

export function useMasterDetailsState() {
  const {
    markGridAsExpanded,
    markGridAsCollapsed,
    checkIsGridMarkedAsExpanded,
  } = useMasterDetailsExpandState()

  const { getMasterDetailTab, handleSwitchMasterDetailToTab } =
    useMasterDetailTabsState()

  const toggleMasterDetailTab = useCallback(
    (tab: MasterDetailTab, gridId: string, node?: Maybe<RowNode>) => {
      if (!nodeIsMaster(node)) {
        return
      }

      if (node?.expanded && getMasterDetailTab(gridId) === tab) {
        toggleMasterDetail(node, false)
        markGridAsCollapsed(gridId)
        return
      }
      handleSwitchMasterDetailToTab(gridId, tab)
      toggleMasterDetail(node, true)
      markGridAsExpanded(gridId)
    },
    [
      getMasterDetailTab,
      handleSwitchMasterDetailToTab,
      markGridAsCollapsed,
      markGridAsExpanded,
    ],
  )

  const toggleFormulaMasterDetail = useCallback(
    (gridId: string, node?: Maybe<RowNode>) => {
      toggleMasterDetailTab('formula', gridId, node)
    },
    [toggleMasterDetailTab],
  )

  const toggleGridMasterDetail = useCallback(
    (gridId: string, node?: Maybe<RowNode>) => {
      toggleMasterDetailTab('grid', gridId, node)
    },
    [toggleMasterDetailTab],
  )

  const checkIsTabOpened = useCallback(
    (tab: MasterDetailTab, gridId: string): boolean =>
      checkIsGridMarkedAsExpanded(gridId) && getMasterDetailTab(gridId) === tab,
    [checkIsGridMarkedAsExpanded, getMasterDetailTab],
  )

  const checkIsFormulaOpened = useCallback(
    (gridId: string): boolean => checkIsTabOpened('formula', gridId),
    [checkIsTabOpened],
  )

  const checkIsGridOpened = useCallback(
    (gridId: string): boolean => checkIsTabOpened('grid', gridId),
    [checkIsTabOpened],
  )

  return useMemo(
    () => ({
      toggleFormulaMasterDetail,
      toggleGridMasterDetail,
      getMasterDetailTab,
      checkIsFormulaOpened,
      checkIsGridOpened,
    }),
    [
      toggleFormulaMasterDetail,
      toggleGridMasterDetail,
      getMasterDetailTab,
      checkIsFormulaOpened,
      checkIsGridOpened,
    ],
  )
}
