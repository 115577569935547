import { Box, BoxProps, Typography } from '@mui/material'
import React from 'react'

export const FilterNoResults: React.FC<BoxProps> = (props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap={24}
    alignSelf="stretch"
    height="100%"
    {...props}
  >
    <Typography
      variant="body1"
      sx={(theme) => ({ color: theme.palette.text.secondary })}
    >
      No results match your search
    </Typography>
  </Box>
)
