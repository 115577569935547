import { ValueColumnFieldAndRollUp } from '../grid-columns/types'
import { Maybe, RollUpFunction } from '@fintastic/shared/util/types'
import {
  resolveAggregation,
  weightedAverage,
  WeightedAverageAgGridExtendedContextObject,
} from '@fintastic/shared/ui/ag-grid'
import { IAggFuncParams } from 'ag-grid-community'
import { WEIGHTED_AVERAGE_WEIGHT_COLUMN_POSTFIX } from '../weighted-average/const'

export const TOTAL_LINE_ROW_ID = 'totalLine'

type Row = Record<string, string | number>

// @todo add tests
export function calculateTotalRow(
  rows: Row[],
  valueColumnsWithRollups: ValueColumnFieldAndRollUp[],
) {
  const resultRow: Row & {
    _rowId: string
  } = {
    _rowId: TOTAL_LINE_ROW_ID,
  }

  Object.values(valueColumnsWithRollups).forEach((column) => {
    const aggregationResult = calculateColumn(
      rows,
      column.field,
      column.rollUpFunction,
    )
    if (aggregationResult !== null) {
      resultRow[column.field] = aggregationResult
    }
  })

  return resultRow
}

function calculateColumn(
  rows: Row[],
  columnField: string,
  rollUpFunction: RollUpFunction,
): Maybe<string | number> {
  if (rollUpFunction === 'noop') {
    return null
  }

  if (rollUpFunction !== 'weighted_avg') {
    return applyRegularAggFunction(
      rows.map((row) => row[columnField]),
      rollUpFunction,
    )
  }

  return applyWeightedAverage(rows, columnField)
}

function applyRegularAggFunction(
  values: Array<string | number | undefined>,
  rollUpFunction: Exclude<RollUpFunction, 'weighted_avg' | 'noop'>,
): Maybe<string | number> {
  const aggFunction = resolveAggregation(rollUpFunction)
  if (typeof aggFunction !== 'function') {
    return null
  }
  return aggFunction({ values } as IAggFuncParams)
}

function applyWeightedAverage(
  rows: Row[],
  columnField: string,
): Maybe<string | number> {
  const values: Array<string | number> = []
  const weights: Array<string | number> = []

  rows.forEach((row) => {
    values.push(row[columnField])
    weights.push(
      row[`${columnField}:${WEIGHTED_AVERAGE_WEIGHT_COLUMN_POSTFIX}`],
    )
  })

  return weightedAverage({
    values,
    context: {
      weightsGetter: () => weights,
    } as WeightedAverageAgGridExtendedContextObject,
  } as IAggFuncParams)
}
