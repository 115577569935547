import { useCallback, useMemo } from 'react'
import { ChangeColumnCategoryAggregationAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeColumnCategoryAggregationFlow() {
  const { changeCategoryAggregation } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnCategoryAggregationAction>(
    (columnId, aggregation) => {
      changeCategoryAggregation({
        columnId,
        weightsMetricId: aggregation.weightsMetricId,
        rollUpFunction: aggregation.rollUpFunction,
      })
    },
    [changeCategoryAggregation],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
