import { ListWithoutData } from '@fintastic/web/util/metrics-and-lists'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import { useQueriesWithMemo } from '@fintastic/shared/data-access/react-query'
import { makeLoadListQueryFn } from './useLoadListInDifferentVersions'

export function useLoadListWithoutData(
  versionId: string,
  listId: string,
  periodSelection?: PeriodSelection,
  enabled = true,
) {
  const querySettings = useMemo(
    () =>
      ({
        queryKey: metricsAndListsCacheKeys.listWithoutData(
          versionId,
          listId,
          periodSelection,
        ),
        queryFn: makeLoadListQueryFn(versionId, listId, periodSelection, false),
        enabled,
        retry: false,
      } as const),
    [enabled, listId, versionId, periodSelection],
  )

  return useQueriesWithMemo<
    {
      list: ListWithoutData
      originallyEmpty: boolean
    }[],
    Error
  >([querySettings])
}
