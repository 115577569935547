import React, { useEffect, useMemo, useRef } from 'react'
import { Box, Button, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useEditorMode } from './features/editing/useEditiorMode'
import { useTokens } from './features/tokens/useTokens'
import { FormulaInput } from './features/formula-input/FormulaInput'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import type {
  VersionWithRecognitionMap,
  VersionStateApi,
} from './features/version-state/types'
import { useSyncEditingStateWithVersion } from './features/editing/useSyncEditingStateWithVersion'
import { useUpdateFormulaCallback } from './features/editing/useUpdateFormulaCallback'
import { useDiscardChanges } from './features/editing/useDiscardChanges'
import { VersionSelector } from './features/version-state/VersionSelector'
import { useFormulaInputApi } from './features/formula-input/useFormulaInputApi'
import { useValidation } from './features/validation/useValidation'
import { ValidationStatus } from './features/validation/ValidationStatus'
import {
  useValidationHighlightContextValue,
  ValidationHighlightContextProvider,
} from './features/validation/validation-highlight-context'
import {
  StyledEditorRoot,
  StyledEditorHeader,
  StyledEditorMain,
  StyledEditorFooter,
  StyledEditorTitle,
  StyledEditorVersionSelector,
  StyledEditorValidation,
  StyledEditorHeaderLeft,
  StyledEditorHeaderRight,
} from './Editor.styled'
import { ValidationErrorsPopper } from './features/validation/ValidationErrorsPopper'
import { useAutocomplete } from './features/autocomplete/useAutocomplete'
import { AutocompleteContextProvider } from './features/autocomplete/autocomplete-context'
import { Autocomplete } from './features/autocomplete/Autocomplete'
import { useCaretState } from './features/caret/useCaretState'
import { useFormulaValueState } from './features/editing/useFormulaValueState'
import { useFocusedToken } from './features/focused-token/useFocusedToken'
import { getAllNonPunctuationLiterals } from './features/syntax/getAllNonPunctuationLiterals'
import { useFormulaMutations } from './features/formula-mutations/useFormulaMutations'
import { useVersionPermissions } from './features/version-permissions/useVersionPermissions'
import { useUserPermissions } from './features/user-permissions/useUserPermissions'
import { useEditingPermissions } from './features/editing-permissions/useEditingPermissions'
import { EditButton } from './features/editing/EditButton'
import { ApplyButton } from './features/editing/ApplyButton'
import { SyntaxApi } from './features/syntax/types'
import { useShouldDisableVersionsSelector } from './features/version-state/useShouldDisableVersionsSelector'
import { SaveFormulaApi } from './features/editing/types'
import { useSyncEditingStateWithSavingState } from './features/editing/useSyncEditingStateWithSavingState'
import type { CalculationError } from '@fintastic/web/data-access/calc'
import { useTokenHelper } from './features/token-helper/useTokenHelper'
import { TokenHelperContextProvider } from './features/token-helper/token-helper-context'
import { TokenHelperPopper } from './features/token-helper/TokenHelperPopper'
import { useConstantTokenRecognitionMap } from './features/syntax/useConstantTokenRecognitionMap'
import { useValidationCallbacks } from './features/validation/useValidationCallbacks'
import { FormulaValidationCallback } from './features/validation/types'
import { useControlledEditingMode } from './features/editing/useControlledEditingMode'
import { Maybe } from '@fintastic/shared/util/types'
import { useIndentation } from './features/indentation/useIndentation'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'

export type EditorProps = {
  title?: React.ReactNode
  versions: VersionStateApi<VersionWithRecognitionMap>
  showVersionsSelector?: boolean
  readonly?: boolean
  onRequestClose?: () => void
  defaultEditingState?: boolean
  noPadding?: boolean
  syntaxApi: SyntaxApi
  versionUserLock: VersionUserLockParsed
  saveFormulaApi: SaveFormulaApi
  onChangesDiscarded?: () => void
  onValidationFinished?: FormulaValidationCallback
  controlledEditingMode?: boolean
  showCloseButtonInControlledMode?: boolean
  controlledFormulaError?: Maybe<string>
}

// @todo add tests
export const Editor: React.FC<EditorProps> = ({
  title,
  versions,
  readonly = false,
  onRequestClose,
  showVersionsSelector = true,
  defaultEditingState = false,
  noPadding,
  syntaxApi: syntaxApiProp,
  versionUserLock,
  saveFormulaApi,
  onChangesDiscarded,
  onValidationFinished,
  controlledEditingMode = false,
  showCloseButtonInControlledMode = false,
  controlledFormulaError,
}) => {
  const inputKeyRef = useRef(0)
  const inputKey = useMemo(() => {
    inputKeyRef.current += 1
    return inputKeyRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions.version])

  const { api: syntaxApi } = syntaxApiProp
  const apiAvailable = syntaxApi !== null
  const constantsRecognitionMap = useConstantTokenRecognitionMap(
    syntaxApi?.getConstantsList ? syntaxApi.getConstantsList() : null,
  )

  const {
    formulaInputApiRef,
    focusFormulaInput,
    getTokensNodes,
    moveCaret: moveRealCaret,
    simulateFormulaChange,
  } = useFormulaInputApi()

  const { selectVersion, version } = versions
  const disableVersionsSelector = useShouldDisableVersionsSelector(
    versions.allVersions,
    showVersionsSelector,
  )

  const { isEditing, enterEditingMode, exitEditingMode } = useEditorMode(
    defaultEditingState,
    {
      focusInput: focusFormulaInput,
      readonly,
    },
  )
  useControlledEditingMode(controlledEditingMode, enterEditingMode)

  const { formulaValue, setFormulaValue, isDirty } = useFormulaValueState(
    version?.formula || '',
  )
  const formulaMutationsApi = useFormulaMutations({
    updateFormula: simulateFormulaChange,
    moveCaret: moveRealCaret,
  })

  const {
    caretPosition,
    moveCaret,
    movementReason: caretMovementReason,
  } = useCaretState(isEditing)
  const tokens = useTokens(
    formulaValue,
    version?.objectRecognitionMap || null,
    constantsRecognitionMap,
    syntaxApi,
  )
  const focusedToken = useFocusedToken(tokens, caretPosition)
  const tokenHelper = useTokenHelper(
    tokens,
    caretPosition,
    syntaxApi,
    formulaInputApiRef.current?.getTokensNodes || null,
  )

  const nonPunctuationLiterals = useMemo(
    () =>
      getAllNonPunctuationLiterals(
        syntaxApi?.getFunctionsList() || [],
        syntaxApi?.getConstantsList ? syntaxApi.getConstantsList() : [],
        version?.objectRecognitionMap || {},
      ),
    [syntaxApi, version?.objectRecognitionMap],
  )

  const resetEditingState = useSyncEditingStateWithVersion(
    version,
    setFormulaValue,
  )
  useSyncEditingStateWithSavingState(saveFormulaApi, exitEditingMode)
  const discardChanges = useDiscardChanges(
    exitEditingMode,
    resetEditingState,
    isDirty,
    onChangesDiscarded,
  )

  useEffect(() => {
    resetEditingState()
  }, [formulaInputApiRef, resetEditingState, versions.version])

  const userPermissionsApi = useUserPermissions(discardChanges)
  const versionPermissionsApi = useVersionPermissions(version, exitEditingMode)
  const editingPermissionsApi = useEditingPermissions({
    userPermissionsApi,
    versionPermissionsApi,
    readonly,
    versionUserLock,
  })

  const { isValid, isWaiting, errorMessage, highlightRanges } = useValidation(
    formulaValue,
    version?.objectRecognitionMap || null,
    syntaxApi,
  )
  useValidationCallbacks(
    isValid,
    isWaiting,
    version?.id || '',
    formulaValue,
    onValidationFinished,
  )
  const validationHighlight = useValidationHighlightContextValue(
    highlightRanges,
    isEditing,
    formulaValue,
  )

  const indentationApi = useIndentation(
    formulaValue,
    caretPosition,
    formulaMutationsApi,
  )

  const autocompleteApi = useAutocomplete({
    enabled: isEditing,
    formula: formulaValue,
    focusedToken,
    getTokensNodes,
    caretMovementReason,
    syntaxLiterals: nonPunctuationLiterals,
    formulaMutationsApi,
  })

  const updateFormula = useUpdateFormulaCallback({
    formula: formulaValue,
    saveFormulaApi,
  })

  const formulaInput = useMemo(
    () => (
      <FormulaInput
        key={inputKey}
        value={tokens}
        onChange={setFormulaValue}
        onCaretMove={moveCaret}
        readonly={!isEditing || saveFormulaApi.calculating}
        ref={formulaInputApiRef}
        keyDownEventInterceptor={autocompleteApi.keyboardInterceptors.keyDown}
        indentationApi={indentationApi}
      />
    ),
    [
      inputKey,
      tokens,
      setFormulaValue,
      moveCaret,
      isEditing,
      saveFormulaApi.calculating,
      formulaInputApiRef,
      autocompleteApi.keyboardInterceptors.keyDown,
      indentationApi,
    ],
  )

  const validationErrorMessage = useMemo(() => {
    if (controlledFormulaError) {
      return controlledFormulaError
    }

    return saveFormulaApi.error ? saveFormulaApi.error.message : errorMessage
  }, [controlledFormulaError, errorMessage, saveFormulaApi.error])

  if (!apiAvailable) {
    return (
      <Box p={2}>
        <Typography>Fintastic Syntax API is unavailable.</Typography>
      </Box>
    )
  }

  const editingHeaderRight = (
    <>
      <ApplyButton
        isValid={isValid}
        isDirty={isDirty}
        isCalculating={saveFormulaApi.calculating}
        isWaiting={isWaiting}
        onClick={updateFormula}
      />
      <Button
        variant="outlined"
        sx={{ fontSize: 14 }}
        onClick={discardChanges}
        disabled={saveFormulaApi.calculating}
        data-testid="formula-editor-cancel-button"
      >
        Cancel
      </Button>
    </>
  )

  const editButton = (
    <EditButton
      editingPermissions={editingPermissionsApi}
      onClick={enterEditingMode}
      savingInProgress={saveFormulaApi.calculating}
    />
  )

  const closeButton = (
    <Button
      startIcon={<CloseIcon fontSize="small" />}
      sx={{ fontSize: 14 }}
      onClick={onRequestClose}
      disabled={saveFormulaApi.calculating}
      data-testid="formula-editor-close-button"
    >
      Close
    </Button>
  )

  const closeButtonDivider = (
    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
  )

  const readingHeaderRight = (
    <>
      {editButton}
      {onRequestClose && (
        <>
          {closeButtonDivider}
          {closeButton}
        </>
      )}
    </>
  )

  const headerRight = controlledEditingMode ? (
    <>{showCloseButtonInControlledMode && closeButton}</>
  ) : (
    <>{isEditing ? editingHeaderRight : readingHeaderRight}</>
  )

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <ValidationHighlightContextProvider value={validationHighlight}>
        <AutocompleteContextProvider value={autocompleteApi}>
          <TokenHelperContextProvider value={tokenHelper}>
            <StyledEditorRoot
              sx={noPadding ? { p: 0 } : {}}
              data-testid="formula-editor"
            >
              <StyledEditorHeader data-testid="formula-editor-header">
                <StyledEditorHeaderLeft>
                  {title && (
                    <StyledEditorTitle
                      variant="overline"
                      data-testid="formula-editor-title"
                    >
                      {title}
                    </StyledEditorTitle>
                  )}
                  {showVersionsSelector && versions.allVersions.length > 0 && (
                    <StyledEditorVersionSelector data-testid="formula-editor-version-selector-container">
                      <VersionSelector
                        onChange={selectVersion}
                        value={version?.id || null}
                        versions={versions.allVersions}
                        disabled={
                          disableVersionsSelector || saveFormulaApi.calculating
                        }
                      />
                    </StyledEditorVersionSelector>
                  )}
                </StyledEditorHeaderLeft>
                <StyledEditorHeaderRight>{headerRight}</StyledEditorHeaderRight>
              </StyledEditorHeader>
              <StyledEditorMain>{formulaInput}</StyledEditorMain>
              {isEditing && (
                <StyledEditorFooter data-testid="formula-editor-footer">
                  <StyledEditorValidation data-testid="formula-editor-validation-container">
                    <ValidationStatus
                      isValid={
                        saveFormulaApi.error || controlledFormulaError
                          ? false
                          : isValid
                      }
                      errorMessage={validationErrorMessage}
                      severity={
                        controlledFormulaError
                          ? 'high'
                          : (saveFormulaApi?.error as CalculationError)
                              ?.calculationErrorSeverity
                      }
                    />
                  </StyledEditorValidation>
                </StyledEditorFooter>
              )}
            </StyledEditorRoot>
            {!saveFormulaApi.calculating && (
              <>
                <ValidationErrorsPopper />
                <TokenHelperPopper />
                <Autocomplete />
              </>
            )}
          </TokenHelperContextProvider>
        </AutocompleteContextProvider>
      </ValidationHighlightContextProvider>
    </FintasticThemeProvider>
  )
}
