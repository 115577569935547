import React from 'react'
import { NoSharedBoardsCircleStyled } from './NoSharedBoardsCircle.styled'

export const NoSharedBoardsCircle: React.FC = () => (
  <NoSharedBoardsCircleStyled>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4.5H15V8.39999L19.5 8.39999V5C19.5 4.72386 19.2761 4.5 19 4.5ZM15 14V9.89999L19.5 9.89999V14L15 14ZM15 15.5V19.5H19C19.2761 19.5 19.5 19.2761 19.5 19V15.5L15 15.5ZM5 4.5H13.5V11.2H4.5V5C4.5 4.72386 4.72386 4.5 5 4.5ZM3 12.7V11.2V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V12.7ZM4.5 12.7H13.5V19.5H5C4.72386 19.5 4.5 19.2761 4.5 19V12.7Z"
        fill="#2C45FE"
      />
    </svg>
  </NoSharedBoardsCircleStyled>
)
