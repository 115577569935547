import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  useIsLocalStorageOverrideDisabled,
  useReadDeeplinkValue,
  useSyncDeeplinkValue,
} from '@fintastic/web/util/deeplink'
import { deeplinkDiffToNormalDiff, normalDiffToDeeplinkDiff } from './utils'
import { useLocalStorage } from 'usehooks-ts'
import { useValidateVersionsIds } from '../useValidateVersionsIds'
import { useResetThreadId } from '@fintastic/web/data-access/comments'
import { DiffMode } from '@fintastic/web/util/versions'
import { isEqual } from 'lodash'

const defaultDiffs: Array<[string, string, DiffMode]> = []

const areVersionsDefault = (
  versions: string[],
  defaultVersions: string[],
): boolean =>
  versions.length === defaultVersions.length &&
  versions.join('-') === defaultVersions.join('-')

export function useBoardsParamsVersions(defaultVersions: string[]) {
  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const [deeplinkVersions, setDeeplinkVersions] = useSyncDeeplinkValue<
    string[]
  >({
    key: 'v',
    defaultValue: defaultVersions,
  })

  const [localStorageVersions, setLocalStorageVersions] = useLocalStorage<
    string[]
  >(localStorageKeys.version, defaultVersions)

  const versionsRef = useRef<string[]>(deeplinkVersions)
  versionsRef.current = deeplinkVersions

  const resetThreadId = useResetThreadId()

  const setVersions = useCallback(
    (versionsIds: string[]) => {
      versionsRef.current = versionsIds
      // When switching versions, we cannot be sure thread is still available
      resetThreadId()
      setLocalStorageVersions(versionsIds)
      setDeeplinkVersions(versionsIds)
    },
    [setDeeplinkVersions, setLocalStorageVersions, resetThreadId],
  )

  const versionListValidator = useValidateVersionsIds()

  const { versions, deeplinkInvalidated } = useMemo<{
    versions: string[]
    deeplinkInvalidated: boolean
  }>(() => {
    if (defaultVersions.length === 0) {
      return { versions: [], deeplinkInvalidated: false }
    }

    if (
      areVersionsDefault(deeplinkVersions, defaultVersions) &&
      localStorageVersions.length &&
      !localStorageOverrideDisabled
    ) {
      const validatedLocalStorageVersions =
        versionListValidator(localStorageVersions)

      if (validatedLocalStorageVersions.length) {
        return {
          versions: validatedLocalStorageVersions,
          deeplinkInvalidated: false,
        }
      }
    }

    if (deeplinkVersions.length) {
      const validatedDeeplinkVersions = versionListValidator(deeplinkVersions)
      if (validatedDeeplinkVersions.length) {
        return {
          versions: validatedDeeplinkVersions,
          deeplinkInvalidated:
            validatedDeeplinkVersions.length !== deeplinkVersions.length,
        }
      }
    }

    return { versions: defaultVersions, deeplinkInvalidated: false }
  }, [
    defaultVersions,
    deeplinkVersions,
    localStorageVersions,
    localStorageOverrideDisabled,
    versionListValidator,
  ])

  useEffect(() => {
    if (deeplinkVersions.length) {
      return
    }

    setLocalStorageVersions([])
  }, [deeplinkVersions, setLocalStorageVersions])

  const prevLocalStorageVersions = useRef<string[]>()

  // Put LS versions to URL if they are not default
  useEffect(() => {
    if (defaultVersions.length === 0) {
      return
    }

    const validatedLocalStorageVersions =
      versionListValidator(localStorageVersions)

    if (
      areVersionsDefault(versionsRef.current, defaultVersions) &&
      validatedLocalStorageVersions.length &&
      !areVersionsDefault(localStorageVersions, defaultVersions) &&
      !localStorageOverrideDisabled &&
      !isEqual(prevLocalStorageVersions.current, validatedLocalStorageVersions)
    ) {
      setDeeplinkVersions(validatedLocalStorageVersions)
      prevLocalStorageVersions.current = validatedLocalStorageVersions
    }
  }, [
    localStorageVersions,
    setDeeplinkVersions,
    defaultVersions,
    localStorageOverrideDisabled,
    versionListValidator,
  ])

  return useMemo(
    () => ({
      versions,
      setVersions,
      deeplinkInvalidated,
    }),
    [setVersions, versions, deeplinkInvalidated],
  )
}

const deeplinkDefaultValue = normalDiffToDeeplinkDiff(defaultDiffs)

const useReadDeeplinkDiff = (defaultVersions: string[]) => {
  const deeplinkDiffs = useReadDeeplinkValue<string[]>('diff')

  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const [localStorageDiffs] = useLocalStorage<string[]>(
    localStorageKeys.diffs,
    deeplinkDefaultValue,
  )

  const { versions } = useBoardsParamsVersions(defaultVersions)

  return useMemo(() => {
    if (deeplinkDiffs?.length) {
      const allDeeplinkDiffExists = deeplinkDiffToNormalDiff(
        deeplinkDiffs,
      ).every(([a, b]) => versions.includes(a) && versions.includes(b))
      if (allDeeplinkDiffExists) {
        return deeplinkDiffToNormalDiff(deeplinkDiffs)
      }
    }

    if (localStorageDiffs?.length && !localStorageOverrideDisabled) {
      const allLSDiffExists = deeplinkDiffToNormalDiff(localStorageDiffs).every(
        ([a, b]) => versions.includes(a) && versions.includes(b),
      )
      if (allLSDiffExists) {
        return deeplinkDiffToNormalDiff(localStorageDiffs)
      }
    }

    return defaultDiffs
  }, [deeplinkDiffs, localStorageDiffs, localStorageOverrideDisabled, versions])
}

export function useBoardsParamsDiffs(defaultVersions: string[]) {
  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const [deeplinkDiffs, setDeeplinkDiffs] = useSyncDeeplinkValue<string[]>({
    key: 'diff',
    defaultValue: deeplinkDefaultValue,
  })

  const [localStorageDiffs, setLocalStorageDiffs] = useLocalStorage<string[]>(
    localStorageKeys.diffs,
    deeplinkDefaultValue,
  )
  const resetThreadId = useResetThreadId()

  const deeplinkDiffsRef = useRef<string[]>(deeplinkDiffs)
  deeplinkDiffsRef.current = deeplinkDiffs

  const setDiffHandler = useCallback(
    (diffsValue: Array<[string, string, DiffMode]>) => {
      const nextDiffs = normalDiffToDeeplinkDiff(diffsValue)
      deeplinkDiffsRef.current = nextDiffs
      resetThreadId()
      setLocalStorageDiffs(nextDiffs)
      setDeeplinkDiffs(nextDiffs)
    },
    [setDeeplinkDiffs, setLocalStorageDiffs, resetThreadId],
  )

  // Put LS diffs to URL if they are not default
  useEffect(() => {
    if (
      deeplinkDiffsRef.current.length === 0 &&
      localStorageDiffs.length &&
      !localStorageOverrideDisabled
    ) {
      setDeeplinkDiffs(localStorageDiffs)
    }
  }, [
    localStorageDiffs,
    localStorageDiffs.length,
    setDeeplinkDiffs,
    localStorageOverrideDisabled,
  ])

  const diffs = useReadDeeplinkDiff(defaultVersions)

  return useMemo(
    () => ({
      diffs,
      setDiffs: setDiffHandler,
    }),
    [diffs, setDiffHandler],
  )
}

const defaultVersionsFallback: string[] = []

export function useBoardsParams(defaultVersions = defaultVersionsFallback) {
  const { diffs } = useBoardsParamsDiffs(defaultVersions)
  const { versions } = useBoardsParamsVersions(defaultVersions)

  return useMemo(
    () => ({
      diffs,
      versions,
    }),
    [diffs, versions],
  )
}

const localStorageKeys = {
  timeSegmentation: 'segmentation_selection',
  version: 'versions_selection',
  diffs: 'diffs_selection',
}
