import { createSvgIcon } from '@mui/material'
import React from 'react'

export const ArrowBackIosNew = createSvgIcon(
  <path
    d="M15.8619 21.8716L7.43693 13.4716C7.33693 13.3716 7.26609 13.2632 7.22443 13.1466C7.18276 13.0299 7.16193 12.9049 7.16193 12.7716C7.16193 12.6382 7.18276 12.5132 7.22443 12.3966C7.26609 12.2799 7.33693 12.1716 7.43693 12.0716L15.8619 3.64658C16.0953 3.41325 16.3869 3.29658 16.7369 3.29658C17.0869 3.29658 17.3869 3.42158 17.6369 3.67158C17.8869 3.92158 18.0119 4.21325 18.0119 4.54658C18.0119 4.87991 17.8869 5.17158 17.6369 5.42158L10.2869 12.7716L17.6369 20.1216C17.8703 20.3549 17.9869 20.6424 17.9869 20.9841C17.9869 21.3257 17.8619 21.6216 17.6119 21.8716C17.3619 22.1216 17.0703 22.2466 16.7369 22.2466C16.4036 22.2466 16.1119 22.1216 15.8619 21.8716Z"
    fill="currentColor"
  />,
  'ArrowBackIosNew',
)
