import type { RowNode } from 'ag-grid-community'
import { isUnknownGroupLabel } from './unknown-group-label'
import type {
  BasicColumnDefinition,
  GenericReportTreeRow,
} from '@fintastic/web/util/generic-report'
import { compact, isEqual } from 'lodash'
import type { DimensionId } from '@fintastic/shared/util/types'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export type DimensionValueTuple = Readonly<[dimension: string, value: string]>

/**
 * Extract dimensions values for current nesting level from RowNode
 * Original dimensions list is needed to get dimension key by it's level in the tree
 */
export const generateDimensionValue = (
  { key, parent, footer }: RowNode<GenericReportTreeRow>,
  localDimensions: BasicColumnDefinition[],
  dimensions?: VersionDimension[],
): DimensionValueTuple[] => {
  if (footer) {
    return [[FOOTER_DIMENSION_VALUE, 'true'] as const]
  }

  if (typeof parent?.level !== 'number') {
    return []
  }

  const field = localDimensions[parent.level + 1]?.name

  if (!field) {
    throw new Error(`Dimension level ${parent.level + 1} doesn't exist.`)
  }

  if (!key) {
    if (parent?.key) {
      const parentDimensions = generateDimensionValue(
        parent,
        localDimensions,
        dimensions,
      )
      return compact([...parentDimensions])
    }

    return []
  }

  const fieldKey = dimensions?.length
    ? mapValueToDimensionValueId(field, key, dimensions)
    : key
  const current = [field, fieldKey] as const

  if (parent?.key) {
    const parentDimensions = generateDimensionValue(
      parent,
      localDimensions,
      dimensions,
    )
    return compact([current, ...parentDimensions])
  }

  return compact([current])
}

const mapValueToDimensionValueId = (
  field: string,
  dimensionValue: string,
  dimensions: VersionDimension[],
): DimensionId => {
  const dimensionIdFromField = `Dim.${field}`
  const dimension = dimensions.find((i) => i.id === dimensionIdFromField)

  if (!dimension) {
    throw new Error(`cannot find dimension for ${dimensionIdFromField}`)
  }

  const dimensionValueIdPerValueText = Object.entries(
    'values' in dimension ? dimension.values : {},
  ).find(([, value]) => value === dimensionValue)?.[0]

  if (!dimensionValueIdPerValueText) {
    console.error(
      `cannot find dimension value "${dimensionValue}" for ${dimensionIdFromField}`,
    )
    // @todo: Investigate why this even happens
    return 'UNKNOWN_DIMENSION_VALUE'
  }

  // Remove dimension value id prefix due to legacy API
  return dimensionValueIdPerValueText.replace(`${dimensionIdFromField}.`, '')
}

const encodeValue = (value: string, applyURIEncode = true) => {
  const valueString = (value || '').trim()
  if (isUnknownGroupLabel(valueString)) {
    return ''
  }
  return applyURIEncode ? encodeURIComponent(valueString) : valueString
}

export const stringifyDimensionsValueAsDict = (
  dimensionsValues: DimensionValueTuple[],
): Record<string, Array<string>> =>
  dimensionsValues.reduce((prevFilter, [dimension, value]) => {
    const encodedDimension = encodeURIComponent(dimension)
    const prevValues = prevFilter[encodedDimension] || []
    const newValues = [...prevValues, encodeValue(value, false)]
    const newFilter = { ...prevFilter }
    newFilter[encodedDimension] = newValues
    return newFilter
  }, {} as Record<string, Array<string>>)

export const FOOTER_DIMENSION_VALUE = '__FOOTER__'

export const isFooterDimensionValue = (
  input: Record<string, string>,
): boolean =>
  isEqual(input, {
    [FOOTER_DIMENSION_VALUE]: 'true',
  })
