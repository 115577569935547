import { uniqBy } from 'lodash'
import {
  ExtendedServerSideIdentity,
  UsersAndGroupsData,
} from './BoardShareForm'
import { UserData } from  '@fintastic/web/data-access/iam'
import { GroupsAPIResult } from '@fintastic/web/feature/settings'
import { UserMultiSelectOption } from '@fintastic/shared/ui/components'

// dropdown list - all except power users, already selected records, deactivated users
export function prepareUsersForMultiselect(
  users: UserData[],
  groups: GroupsAPIResult,
  remoteAccessIdentities: ExtendedServerSideIdentity[],
): UserMultiSelectOption[] {
  return (
    uniqBy(
      // uniq by id
      [
        // add users
        ...users
          .filter(
            // - inactive users/power users
            (user) => user.is_active && !user.deactivated_at,
          )
          .filter(
            // - already selected
            (user) =>
              !remoteAccessIdentities
                .map((serverItem) => serverItem.identity_id)
                .includes(user.username),
          ),
        // add groups
        ...groups
          .filter(
            // - already selected
            (group) =>
              !remoteAccessIdentities
                .map((serverItem) => serverItem.identity_id)
                .includes(group.id),
          )
          .map((group) => ({
            username: group.id,
            isGroup: true,
          })),
      ],
      'username',
    )
      // map to options
      .map((user: UsersAndGroupsData) => ({
        email: user.username,
        name: user.full_name || user.username,
        isGroup: Boolean(user.isGroup),
        group: user.isGroup ? 'Groups' : 'Users',
      }))
  )
}
