import React from 'react'
import { ErrorToUiMapping } from '@fintastic/shared/ui/components'
import {
  MetricGridError,
  MetricDoesNotExistError,
  NetworkError,
  RestrictedByPermissionsError,
  VersionsAreNotSelectedError,
} from './errors'
import {
  EmptyFolderErrorIcon,
  PermissionsErrorIcon,
  WarningIcon,
  CalendarIcon,
} from '@fintastic/shared/ui/icons'
import {
  doNotSupportRequestedGranularityMessage,
  PeriodResolution,
  UnsupportedGranularityAggregationError,
} from '@fintastic/web/util/period-selector'

export const mapMetricErrorToUi = (
  error: MetricGridError,
  context: { periodResolution: PeriodResolution },
): ErrorToUiMapping => {
  if (error instanceof VersionsAreNotSelectedError) {
    return {
      title: error.ERROR_TEXT,
      message: null,
      icon: <EmptyFolderErrorIcon />,
      severity: 'info',
    }
  }

  if (error instanceof MetricDoesNotExistError) {
    return {
      title: "Metric doesn't exist in the selected versions",
      message: 'Try selecting another version',
      icon: <EmptyFolderErrorIcon />,
      severity: 'info',
    }
  }

  if (error instanceof NetworkError) {
    return {
      title: 'Something went wrong during the metric loading',
      message: 'Try to reload the page or reach out the administrator.',
      icon: <EmptyFolderErrorIcon />,
      severity: 'warning',
    }
  }

  if (error instanceof RestrictedByPermissionsError) {
    return {
      title: 'You need permissions to view this metric',
      message: 'Contact your Admin',
      icon: <PermissionsErrorIcon />,
      severity: 'info',
    }
  }

  if (error instanceof UnsupportedGranularityAggregationError) {
    return {
      title: doNotSupportRequestedGranularityMessage(
        'metric',
        context.periodResolution,
      ),
      message: 'Try selecting another time granularity',
      icon: <CalendarIcon />,
      severity: 'info',
    }
  }

  return {
    title: 'Something went wrong',
    message: 'Please try again or reach out the administrator.',
    icon: <WarningIcon />,
    severity: 'warning',
  }
}
