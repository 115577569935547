import {
  resolveDimensionValues,
  VersionCategoryDimension,
  VersionRangeDimension,
  VersionTimeDimension,
} from '@fintastic/web/util/dimensions'
import {
  DimensionWithoutLabel,
  RollUpFunction,
  toMaybe,
} from '@fintastic/shared/util/types'
import { MutableMetricDataWrapper } from '../metric-data'
import { chunk, flattenDeep } from 'lodash'
import {
  extendLastUnfoldLevelWith,
  getDimensionsSizes,
  mapUnfoldedLevel,
  MetricConfigurableDataValueType,
  MetricDataValue,
  rollUpTimeColumns,
  rollUpTree,
  unfoldMetricDataValues,
} from '@fintastic/web/util/metrics-and-lists'
import { MutableMetricWrapper } from './MutableMetricWrapper'
import { VersionDimension, DimensionId } from '@fintastic/web/util/dimensions'

const removeDimensionWithoutAggregations = (
  data: MutableMetricDataWrapper,
  dim: VersionDimension,
) => {
  const existDimIndex = data.dimensions().findIndex((d) => d === dim.id)
  if (existDimIndex === -1) {
    throw new Error(`Dimension "${dim.id}" doesn't exist in the data`)
  }

  if (data.dimensions.length === 1) {
    data.removeLastDimension()
    return
  }

  const dimensions = data.cloneDimensions()
  dimensions.splice(existDimIndex, 1)
  const dimensionValues = data.cloneDimensionValues()
  dimensionValues.splice(existDimIndex, 1)

  data.setDimensions(dimensions)
  data.setDimensionValues(dimensionValues)
  data.regenerateValues(null)
}

export const removeTimeDimensionWithoutAggregations = (
  data: MutableMetricDataWrapper,
  id: DimensionId,
  allDims: VersionDimension[],
) => {
  const dim = toMaybe(allDims.find((d) => d.id === id))
  if (dim === null) {
    throw new Error(`Can't resolve dim ${id} with it's values`)
  }

  if (dim.type !== 'Time') {
    throw new Error(`Dim ${id} is not a Time dimension`)
  }

  removeDimensionWithoutAggregations(data, dim)
}

export const addDimension = (
  metric: MutableMetricWrapper,
  id: DimensionId,
  allDims: VersionDimension[],
  localRangeDims: DimensionWithoutLabel[],
) => {
  const data = metric.data()

  const dim = toMaybe(allDims.find((d) => d.id === id))
  if (dim === null) {
    throw new Error(`Can't resolve dim ${id} with it's values`)
  }

  const isTimeDim = dim.type === 'Time'

  if (data.dimensionsAreEmpty()) {
    addFirstDimension(data, dim, localRangeDims)
    if (isTimeDim) {
      metric._rawData.metadata.time_dimension_id = dim.id
    }
    return
  }

  if (data.hasDimension(id)) {
    throw new Error(`The dimension "${id}" is already exist in this data`)
  }

  if (isTimeDim) {
    if (metric.hasTimeDimension()) {
      throw new Error('This data already contains the time dimension')
    }
    addTimeDimension(data, dim)
    metric._rawData.metadata.time_dimension_id = dim.id
    return
  }

  addCategoryOrRangeDimension(data, dim, localRangeDims)
}

const addFirstDimension = (
  data: MutableMetricDataWrapper,
  d: VersionDimension,
  localRangeDimensions: DimensionWithoutLabel[],
) => {
  const values = resolveDimensionValues(d, localRangeDimensions)
  if (values === null) {
    throw new Error("Can't resolve dimension values")
  }

  data.setDimensions([d.id])
  data.setDimensionValues([
    d.type === 'Time' ? d.ordered_values : Object.keys(values).sort(),
  ])
  data.regenerateValues(null)
}

const addTimeDimension = (
  data: MutableMetricDataWrapper,
  d: VersionTimeDimension,
) => {
  /**
   * Just copy current values to each time period
   *
   * For standalone metrics it's correct because in metric can have only 1 Time dimension,
   * and it's always first in the list of dimensions.
   *
   * BTW, we should move this logic to the backend, because for big metrics this operation
   * can eat all the available memory.
   */
  const values = flattenDeep(Array(d.ordered_values.length).fill(data.values()))

  const dimensions = data.cloneDimensions()
  dimensions.splice(0, 0, d.id)
  const dimensionValues = data.cloneDimensionValues()
  dimensionValues.splice(0, 0, d.ordered_values)

  data.setDimensions(dimensions)
  data.setDimensionValues(dimensionValues)
  data.setValues(values)
}

const addCategoryOrRangeDimension = (
  data: MutableMetricDataWrapper,
  d: VersionCategoryDimension | VersionRangeDimension,
  localRangeDims: DimensionWithoutLabel[],
) => {
  const dimValues = resolveDimensionValues(d, localRangeDims)
  if (dimValues === null) {
    throw new Error("Can't resolve dimension values")
  }

  const newDimValues = Object.keys(dimValues).sort()

  /**
   * Just add new dimension to the end
   */
  const dimensions = [...data.cloneDimensions(), d.id]
  const dimensionValues = [...data.cloneDimensionValues(), newDimValues]

  /**
   * We need to add a new level of dimensions.
   * All existing values should be saved into "_other" of new dimension.
   *
   * To do that, we unfold the array of values. Like this:
   * [1, 2, 3, 4] -> [[1, 2], [3, 4]]
   * in example above the data has 2 dimensions by size 2x2.
   *
   * Then we need to add a new level to unfolded values. Like this:
   * [[1, 2], [3, 4]] -> [[[null, 1], [null, 2]], [[null, 3], [null, 4]]]
   * we added a dimension with 2 values, so now the size is 2x2x2
   *
   * And then we just fold the values back:
   * [[[null, 1], [null, 2]], [[null, 3], [null, 4]]] -> [null, 1, null, 2, null, 3, null, 4]
   *
   * Doing it this way guarantees the right order for inserted values.
   */
  const unfoldedValues = unfoldMetricDataValues(
    data.values(),
    getDimensionsSizes(data.dimensionValues()),
  )
  const newUnfoldedData = extendLastUnfoldLevelWith(unfoldedValues, (value) =>
    newDimValues.map((dimValue) => {
      if (dimValue.endsWith('._other')) {
        return value
      }
      return null
    }),
  )
  const values = flattenDeep<MetricDataValue>(newUnfoldedData)

  data.setDimensions(dimensions)
  data.setDimensionValues(dimensionValues)
  data.setValues(values)
}

export const removeDimension = (
  metric: MutableMetricWrapper,
  id: DimensionId,
  allDims: VersionDimension[],
) => {
  const data = metric.data()

  const dim = toMaybe(allDims.find((d) => d.id === id))
  if (dim === null) {
    throw new Error(`Can't resolve dim ${id} with it's values`)
  }

  const existDimIndex = data.dimensions().findIndex((d) => d === id)
  if (existDimIndex === -1) {
    throw new Error(`Dimension "${id}" doesn't exist in the data`)
  }

  if (
    dim.type !== 'Time' &&
    (existDimIndex === 0 || existDimIndex < data.dimensions().length - 1)
  ) {
    removeDimensionWithoutAggregations(data, dim)
    return
  }

  const dimensions = data.cloneDimensions()
  dimensions.splice(existDimIndex, 1)
  const dimensionValues = data.cloneDimensionValues()
  dimensionValues.splice(existDimIndex, 1)

  const values =
    dim.type === 'Time'
      ? removeTimeDimensionFromValues(
          data,
          existDimIndex,
          dim,
          metric.dataType() as MetricConfigurableDataValueType,
          metric.timeAggregation(),
        )
      : removeCategoryOrRangeDimensionFromValues(
          data,
          existDimIndex,
          metric.dataType() as MetricConfigurableDataValueType,
          metric.categoryAggregation(),
        )

  if (dim.type === 'Time') {
    metric.unwrap().metadata.time_dimension_id = null
  }

  data.setDimensions(dimensions)
  data.setDimensionValues(dimensionValues)
  data.setValues(values)
}

const removeTimeDimensionFromValues = (
  data: MutableMetricDataWrapper,
  currentDimIndex: number,
  dim: VersionTimeDimension,
  dataType: MetricConfigurableDataValueType,
  aggregation: RollUpFunction,
): MetricDataValue[] =>
  rollUpTimeColumns(
    chunk(
      data.values(),
      Math.ceil(
        data.values().length / data.dimensionValues()[currentDimIndex].length,
      ),
    ),
    dataType,
    aggregation,
  )

const removeCategoryOrRangeDimensionFromValues = (
  data: MutableMetricDataWrapper,
  currentDimIndex: number,
  dataType: MetricConfigurableDataValueType,
  aggregation: RollUpFunction,
): MetricDataValue[] => {
  const unfoldedValues = unfoldMetricDataValues(
    data.values(),
    getDimensionsSizes(data.dimensionValues()),
  )

  mapUnfoldedLevel(unfoldedValues, currentDimIndex, (v) => {
    if (!Array.isArray(v)) {
      return v
    }
    return rollUpTree(v, dataType, aggregation)
  })

  return flattenDeep<MetricDataValue>(unfoldedValues)
}
