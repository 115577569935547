import { useMutation, useQueryClient } from 'react-query'
import { HandleMetricUpdateCallbackParams } from '../../types'
import { invalidateVersionUserLockerCache } from '@fintastic/web/data-access/versions'
import { MetricWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { changesToIntents } from './changesToIntents'
import { sendEditMetricDataIntent } from '@fintastic/web/data-access/metric-data-editing'
import { Maybe } from '@fintastic/shared/util/types'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import {
  metricDataToSetCellValuePayloads,
  SetMetricCellDataValuePayload,
} from '../../../../utils/setting-cell-data-value'
import { MetricDataFillStrategy } from '@fintastic/web/util/metric-data-editing'
import { notifyMetricUpdateFailed } from '../../../shared/features/notifications/notifyMetricUpdateFailed'

// @todo revisit
type Context = {
  dataFillStrategy: MetricDataFillStrategy
}

type Callbacks = {
  updateCellData: (payload: SetMetricCellDataValuePayload) => void
  awaitEventId: (id: string) => void
}

// @todo add tests
export function useUpdateMetricHandler(
  metric: Maybe<Metric>,
  { dataFillStrategy }: Context,
  { updateCellData, awaitEventId }: Callbacks,
) {
  const queryClient = useQueryClient()

  return useMutation(
    ['sendMetricUpdateIntent'],
    async (changeIntents: HandleMetricUpdateCallbackParams[]) => {
      if (!metric) {
        throw new Error(
          'Metric data is not provided when useUpdateMetricHandler called',
        )
      }

      const wrappedMetric = new MetricWrapper(metric)
      const intentsPerVersion = changesToIntents(
        changeIntents,
        wrappedMetric.data().dimensions(),
        wrappedMetric.aggregatedByTime() ? dataFillStrategy : null,
      )

      const intentsPerVersionsEntities = Object.entries(intentsPerVersion)
      for (let i = 0; i < intentsPerVersionsEntities.length; i++) {
        const [versionId, intent] = intentsPerVersionsEntities[i]

        const result = await sendEditMetricDataIntent(
          versionId,
          wrappedMetric.id(),
          intent.unwrap(),
        )

        if ('metric_data' in result.data) {
          metricDataToSetCellValuePayloads(
            {
              versionId,
              metricId: wrappedMetric.id(),
              timeDimension: wrappedMetric.timeDimension() || undefined,
            },
            result.data.metric_data,
          ).forEach(updateCellData)
        }

        awaitEventId(result.data.stream_event_id)
      }
    },
    {
      onError: async (error, params) => {
        notifyMetricUpdateFailed(error as Error, 'metric')
        await invalidateVersionUserLockerCache(
          queryClient,
          params.map((intent) => intent.versionId),
        )
      },
    },
  )
}
