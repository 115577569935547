import React from 'react'
import { MetricChartPanels } from './MetricChartPanels/MetricChartPanels'
import { MetricChartSettingsPanel } from '../settings-panel/MetricChartSettingsPanel'
import {
  ChartDebugDisplayMode,
  ChartRequestParams,
  ChartSettings,
  MergedMetricsData,
} from '@fintastic/web/feature/charts'
import { StyledChartAndSettingsContainer } from './MetricChartContent.styled'
import { Maybe } from '@fintastic/shared/util/types'

export type MetricChartContentProps = {
  mergedMetricsData: MergedMetricsData
  displayMode: ChartDebugDisplayMode
  isCollapsedVert?: boolean
  loading: boolean
  metricId: string
  request: ChartRequestParams
  versions: string[]

  widgetId: string

  hasResolutionError?: boolean

  chartSettings: ChartSettings
  referenceSettings?: ChartSettings
  isDesignMode: boolean

  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<ChartSettings>,
  ) => void
}

export const MetricChartContent: React.FC<MetricChartContentProps> = ({
  mergedMetricsData,
  displayMode,
  isCollapsedVert,
  loading,
  metricId,
  request,
  versions,
  chartSettings,
  referenceSettings,
  widgetId,
  isDesignMode,
  hasResolutionError,
  handleUpdateChartWidgetSettings,
}) => {
  if (isCollapsedVert) {
    return null
  }

  return (
    <StyledChartAndSettingsContainer>
      <MetricChartPanels
        mergedMetricsData={mergedMetricsData}
        displayMode={displayMode}
        chartSettings={chartSettings}
        loading={loading}
        request={request}
        isDesignMode={isDesignMode}
        hasResolutionError={hasResolutionError}
      />

      <MetricChartSettingsPanel
        loading={loading}
        widgetId={widgetId}
        metricId={metricId}
        versions={versions}
        chartSettings={chartSettings}
        referenceSettings={referenceSettings}
        isDesignMode={isDesignMode}
        handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
      />
    </StyledChartAndSettingsContainer>
  )
}
