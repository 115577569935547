import { createKeys } from './utils'

export const chartThemes = {
  fintastic: 'fintastic',
  light: 'Light',
  ocean: 'Ocean',
  spring: 'Spring',
  vintage: 'Vintage',
}

export type ChartTheme = keyof typeof chartThemes

export const chartThemeKeys: ChartTheme[] = createKeys<ChartTheme>(chartThemes)

export function isChartTheme(value: unknown): value is ChartTheme {
  return chartThemes[value as ChartTheme] !== undefined
}

export const defaultChartTheme: ChartTheme = 'fintastic'

export function getChartThemeName(theme?: ChartTheme) {
  return chartThemes[isChartTheme(theme) ? theme : defaultChartTheme]
}
