import { Maybe } from '@fintastic/shared/util/types'
import { useQuery } from 'react-query'
import {
  getListPermissions,
  metricsAndListsCacheKeys,
} from '@fintastic/web/data-access/metrics-and-lists'

/**
 * It just a data provider for list permissions API, please do not use it directly
 */
export const useLoadListPermissions = (
  params: Maybe<{ versionId: string; listId: string }>,
) =>
  useQuery(
    metricsAndListsCacheKeys.listPermissions(params),
    async () => {
      if (!params) {
        // actually, this code is unreachable
        throw new Error('empty params')
      }

      const response = await getListPermissions(params.versionId, params.listId)
      return response.data
    },
    {
      enabled: params !== null,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retryOnMount: true,
      keepPreviousData: true,
    },
  )
