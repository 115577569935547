import {
  ColumnEntityDefinitionWrapper,
  DependencyGraphWrapper,
  ListEntityDefinitionWrapper,
  MetricEntityDefinitionWrapper,
  VersionEntitiesWrapper,
} from '@fintastic/web/util/versions'
import { ParsedColumnId } from '@fintastic/web/util/metrics-and-lists'
import { ConfirmationModalParams, DependenciesModalParams } from './types'

type Deps = {
  graph: DependencyGraphWrapper
  openDepsModal: (params: DependenciesModalParams) => void
  openConfirmationModal: (params: ConfirmationModalParams) => void
  entities: VersionEntitiesWrapper
}

type Result = Error | undefined

export const routeRequestEntityDeletion = (
  entityId: string,
  deps: Deps,
): Result => {
  const { entities } = deps

  const entity = entities.findEntityById(entityId)
  if (!entity) {
    return new Error('entity not found')
  }

  switch (entity.type) {
    case 'column': {
      return deleteListColumn(entity, deps)
    }
    case 'metric': {
      return deleteMetric(entity, deps)
    }
    case 'list': {
      return deleteList(entity, deps, false)
    }
    default: {
      return new Error('not implemented')
    }
  }
}

const deleteMetric = (
  entity: MetricEntityDefinitionWrapper,
  deps: Deps,
): Result => {
  const successors = deps.graph.firstLevelSuccessors(entity.id)

  if (successors.length > 0) {
    deps.openDepsModal({
      entity,
      successorsIds: successors,
      lastColumnInList: false,
      weightMetricSuccessorsIds:
        deps.graph.keepWeightMetricSuccessors(successors),
    })
    return
  }

  deps.openConfirmationModal({
    entity,
    lastColumnInList: false,
  })
  return
}

const deleteList = (
  entity: ListEntityDefinitionWrapper,
  deps: Deps,
  dueToLastColumnDeletion: boolean,
): Result => {
  const successors = deps.graph.firstLevelSuccessors(entity.allEntitiesIds)

  if (successors.length > 0) {
    deps.openDepsModal({
      entity,
      successorsIds: successors,
      lastColumnInList: dueToLastColumnDeletion,
      weightMetricSuccessorsIds: [],
    })
    return
  }

  deps.openConfirmationModal({
    entity,
    lastColumnInList: dueToLastColumnDeletion,
  })
  return
}

const lastColumnInList = (
  graph: DependencyGraphWrapper,
  columnId: ParsedColumnId,
): boolean => getSiblingColumns(graph, columnId).length === 0

const getSiblingColumns = (
  graph: DependencyGraphWrapper,
  columnId: ParsedColumnId,
): string[] => {
  const columnIdString = columnId.toString()

  return graph.firstLevelPredecessors(columnId.listId).filter((predecessor) => {
    const siblingColumnId = ParsedColumnId.fromString(predecessor)
    return !(
      !siblingColumnId ||
      siblingColumnId.listId !== columnId.listId ||
      predecessor === columnIdString
    )
  })
}

const deleteListColumn = (
  entity: ColumnEntityDefinitionWrapper,
  deps: Deps,
): Result => {
  const parsedId = ParsedColumnId.fromString(entity.id)
  if (!parsedId) {
    return new Error('column id is not valid')
  }

  if (lastColumnInList(deps.graph, parsedId)) {
    const listEntity = deps.entities.findEntityById(parsedId.listId)
    if (listEntity?.type === 'list') {
      deleteList(listEntity, deps, true)
      return
    }
  }

  const successors = deps.graph
    .firstLevelSuccessors(entity.id)
    .filter((s) => s !== parsedId.listId)

  if (successors.length > 0) {
    deps.openDepsModal({
      entity,
      successorsIds: successors,
      lastColumnInList: false,
      weightMetricSuccessorsIds: [],
    })
    return
  }

  deps.openConfirmationModal({
    entity,
    lastColumnInList: false,
  })
  return
}
