import type {
  CompactMetricData,
  SparseMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import { compact, unzip } from 'lodash'

// @todo add tests
export const compactToSparse = ({
  dimensions,
  values,
  indexes,
}: CompactMetricData): SparseMetricData => {
  if (!values.length) {
    return {
      format: 'sparse',
      indexes: [],
      dimensions: [],
      values: [],
    }
  }

  // Performant case for single dimension metrics
  if (indexes.length === 1) {
    return {
      format: 'sparse',
      indexes,
      dimensions: [dimensions[0].filter((_, index) => values[index] !== null)],
      values: values.filter((v) => v !== null),
    }
  }

  const dimensionsLength = dimensions.map(({ length }) => length)

  const zippedDims = compact(
    values.map((value, valueIndex) => {
      if (value === null) {
        return null
      }

      const indices: number[] = []
      let currentIndex = valueIndex
      for (let i = 0; i < indexes.length; i++) {
        const dimensionLength = dimensionsLength[indexes.length - i - 1]
        indices.push(currentIndex % dimensionLength)
        currentIndex = Math.floor(currentIndex / dimensionLength)
      }

      const currentDimensions = indices
        .reverse()
        .map((dim_val_idx, idx) => dimensions[idx][dim_val_idx])

      return currentDimensions
    }),
  )

  return {
    format: 'sparse',
    indexes,
    dimensions: unzip(zippedDims),
    values: values.filter((v) => v !== null),
  }
}
