import { styled, Box, ButtonBase, Popper } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledContextMenuSubmenuButton = styled(ButtonBase)(
  ({ theme }) => ({
    ...styledMixins.alignContentCenter(),
    'justifyContent': 'flex-start',

    'paddingTop': theme.spacingFromPixels(3),
    'paddingBottom': theme.spacingFromPixels(3),
    'paddingRight': theme.spacingFromPixels(8),
    'paddingLeft': theme.spacingFromPixels(24),

    'width': '100%',
    'minHeight': 40,

    'textAlign': 'left',
    'fontSize': '1rem',
    'fontWeight': 500,
    'fontFamily': theme.typography.fontFamily,

    'transition': `background ${theme.transitions.duration.standard}ms`,

    '&.Mui-focusVisible': {
      background: theme.palette.primary.light,
    },

    '&:hover': {
      background: theme.palette.primary.light,
    },
  }),
)

export const StyledContextMenuSubmenuButtonIcon = styled(Box)(({ theme }) => ({
  fontSize: 0,
  marginLeft: 'auto',

  svg: {
    width: 16,
    height: 'auto',
  },
}))

export const StyledContextMenuSubmenuPopper = styled(Popper)(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  zIndex: theme.zIndex.modal,

  width: 240,
  maxHeight: '100vh',

  background: theme.palette.background.paper,
  filter:
    'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05)) drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.05))',
  borderRadius: 4,
}))

export const StyledContextMenuSubmenuPopperContainer = styled(Box)(
  ({ theme }) => ({}),
)
