import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { StartMetricCreationPayload } from '@fintastic/web/data-access/metrics-and-lists'
import { createEmptyMetricObject } from '../../../abstract-data-types/common-metric/constructors'
import { resetAggregationFunctionsToNoopIfNeeded } from '../../../abstract-data-types/common-metric/mutations'

export const startMetricCreation = (
  state: State,
  { payload }: PayloadAction<StartMetricCreationPayload>,
) => {
  state.type = 'metric'
  state.list = null
  state.flow = 'creation'
  state.metric = createEmptyMetricObject(payload.name, payload.source)
  resetAggregationFunctionsToNoopIfNeeded(state.metric)
}
