import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton, IconButtonProps, Tooltip } from '@mui/material'
import React from 'react'

export const TagsAddButton: React.FC<
  IconButtonProps & { fullSize: boolean }
> = ({ fullSize, ...props }) => (
  <Tooltip title="Add new label">
    {fullSize ? (
      <Button
        variant="text"
        data-testid="add-new-label"
        startIcon={<AddIcon htmlColor="#6E665E" />}
        color={'black' as any}
        sx={{ color: '#332E2A', height: '28px', padding: '4px' }}
        {...props}
      >
        Add label
      </Button>
    ) : (
      <IconButton
        aria-label="Add new label"
        data-testid="add-new-label"
        size="small"
        disableRipple
        sx={{ border: 0, borderRadius: 4 }}
        {...props}
      >
        <AddIcon fontSize="small" sx={{ fill: '#798590' }} />
      </IconButton>
    )}
  </Tooltip>
)
