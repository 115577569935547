import { useContext } from 'react'
import { PeriodSelectorContext } from './PeriodSelectorContext'

export const usePeriodSelectorContextCalendarConfig = (
  {
    limitToSelectedEntitiesOnly = true,
  }: {
    limitToSelectedEntitiesOnly: boolean
  } = { limitToSelectedEntitiesOnly: true },
) => {
  const cache = useContext(PeriodSelectorContext).cache

  return limitToSelectedEntitiesOnly
    ? cache.datePickerConfigForSelectedEntities
    : cache.fullDatePickerConfig
}
