import { SectionedModalSectionDefinition } from '@fintastic/shared/ui/modal-framework'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'
import React from 'react'

export const fallbackRoutes: SectionedModalSectionDefinition[] = [
  {
    path: '/initial',
    view: (
      <div>
        <ErrorAlert
          title="Settings dialog for this widget is not implemented yet."
          message="Please select another widget."
          severity="warning"
          icon={<EmptyFolderErrorIcon />}
        />
      </div>
    ),
  },
]
