import React, { forwardRef } from 'react'
import {
  StyledPanelRoot,
  StyledPanelToolbar,
  StyledPanelContent,
  StyledPanelBottomBar,
} from './Panel.styled'
import { BoxProps } from '@mui/material'
import { PanelLoader } from '../PanelLoader'
import { Maybe } from '@fintastic/shared/util/types'
import { PanelErrorContentWrapper } from '../PanelErrorContentWrapper'

export type PanelProps = {
  children?: React.ReactNode
  topbar?: React.ReactNode
  bottomBar?: React.ReactNode
  overlay?: React.ReactNode
  fullHeight?: boolean
  enableContentPaddings?: boolean
  hideShadow?: boolean
  hideBackground?: boolean
  hideRoundedBorders?: boolean
  isLoading?: boolean
  ['data-testid']?: string
  sx?: BoxProps['sx']
  rootDataAttributes?: Record<string, string>
  errorContent?: Maybe<React.ReactNode>
}

export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  (
    {
      children,
      topbar,
      bottomBar,
      overlay,
      fullHeight = true,
      enableContentPaddings = false,
      hideShadow = false,
      hideBackground = false,
      hideRoundedBorders = false,
      isLoading = false,
      sx,
      rootDataAttributes,
      errorContent,
      ...restProps
    },
    ref,
  ) => (
    <StyledPanelRoot
      hideShadow={hideShadow}
      hideBackground={hideBackground}
      hideRoundedBorders={hideRoundedBorders}
      fullHeight={fullHeight}
      data-testid={restProps['data-testid']}
      sx={sx}
      ref={ref}
      {...rootDataAttributes}
    >
      {topbar && (
        <StyledPanelToolbar data-testid="topbar-container">
          {topbar}
        </StyledPanelToolbar>
      )}
      {(children || errorContent) && (
        <StyledPanelContent
          fullHeight={fullHeight}
          enableContentPaddings={enableContentPaddings}
          data-testid="content-container"
          data-error={errorContent ? 'true' : undefined}
        >
          {isLoading && <PanelLoader />}
          {!isLoading && errorContent ? (
            <PanelErrorContentWrapper>{errorContent}</PanelErrorContentWrapper>
          ) : (
            children
          )}
        </StyledPanelContent>
      )}
      {overlay}
      {topbar && (
        <StyledPanelBottomBar data-testid="bottom-bar-container">
          {bottomBar}
        </StyledPanelBottomBar>
      )}
    </StyledPanelRoot>
  ),
)
