import { createContext, useContext, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { VersionEntitiesWrapper } from '@fintastic/web/util/versions'
import { useLoadMultipleVersionsEntities } from '../hooks/api/useLoadMultipleVersionsEntities'

type VersionId = string

export type VersionEntitiesContextValue = {
  isLoading: boolean
  isFetching: boolean
  entities: Record<VersionId, Maybe<VersionEntitiesWrapper>>
}

export const VersionEntitiesContext =
  createContext<VersionEntitiesContextValue>({
    isLoading: false,
    isFetching: false,
    entities: {},
  })

export const useVersionEntitiesContext = () =>
  useContext(VersionEntitiesContext)

export const useVersionEntitiesContextValue = (
  versionId: VersionId[],
): VersionEntitiesContextValue => {
  const entitiesQuery = useLoadMultipleVersionsEntities(versionId)

  return useMemo(
    () => ({
      isFetching: entitiesQuery.isFetching,
      isLoading: entitiesQuery.isLoading,
      entities: Object.fromEntries(
        entitiesQuery.data.map((data) => [
          data.versionId,
          data.entities
            ? new VersionEntitiesWrapper(data.versionId, data.entities)
            : null,
        ]),
      ),
    }),
    [entitiesQuery.data, entitiesQuery.isFetching, entitiesQuery.isLoading],
  )
}
