import type { BoxProps } from '@mui/material'
import { Box, styled } from '@mui/material'
import type {
  AgGridReactProps,
  AgReactUiProps,
} from 'ag-grid-react/lib/shared/interfaces'
import { AG_THEME_FINTASTIC_NAME } from './const'
import './styles/index.scss'
import type { AgFintasticThemeOptions } from './types'
import { AgFintasticChartThemeOptions } from './types'
import { agFintasticThemeVariants } from './theme-variants'
import React, { useMemo } from 'react'
import { AgChartThemeOverrides } from 'ag-grid-community/dist/lib/interfaces/iAgChartOptions'

type AgGridChartProps = {
  chartThemes?: string[]
  chartThemeOverrides?: AgChartThemeOverrides
}

export type AgGridThemeFintasticWrapperRenderProps = {
  defaultGridProps: (AgGridReactProps | AgReactUiProps) & AgGridChartProps
}

const highlightStyle = {
  series: {
    highlightStyle: {
      item: {
        fill: '#FF9800',
      },
    },
  },
}

export const defaultChartThemeOverrides: AgChartThemeOverrides = {
  bar: highlightStyle,
  column: highlightStyle,
  line: highlightStyle,
  area: highlightStyle,
  scatter: highlightStyle,
  histogram: highlightStyle,
  pie: highlightStyle,
  treemap: highlightStyle,
}

export const defaultChartThemes = [
  'ag-pastel',
  'ag-material',
  'ag-vivid',
  'ag-solar',
]

export type AgGridThemeFintasticWrapperProps = Omit<BoxProps, 'children'> & {
  themeOptions?: AgFintasticThemeOptions
  chartsThemeOptions?: AgFintasticChartThemeOptions
  children: (props: AgGridThemeFintasticWrapperRenderProps) => JSX.Element
}

const AgGridThemeWrapperComponent = React.forwardRef(
  (props: AgGridThemeFintasticWrapperProps, ref) => {
    const {
      className,
      children,
      themeOptions = {},
      chartsThemeOptions = {},
      ...boxProps
    } = props
    const { variant = 'default' } = themeOptions
    const {
      chartThemes = defaultChartThemes,
      chartThemeOverrides = defaultChartThemeOverrides,
    } = chartsThemeOptions

    const defaultGridProps = useMemo<Partial<AgGridReactProps>>(
      () => ({
        ...agFintasticThemeVariants[variant].gridPropsGetter(),
        chartThemes: chartThemes,
        chartThemeOverrides: chartThemeOverrides,
        // This doesn't play nice with the custom select https://fintastic.atlassian.net/browse/FIN-4009
        stopEditingWhenCellsLoseFocus: false,
        suppressDragLeaveHidesColumns: true,
      }),
      [chartThemeOverrides, chartThemes, variant],
    )

    return (
      <Box
        className={`${className} ${AG_THEME_FINTASTIC_NAME} ${AG_THEME_FINTASTIC_NAME}-${variant}`}
        ref={ref}
        {...boxProps}
      >
        {children({ defaultGridProps: defaultGridProps })}
      </Box>
    )
  },
)

export const AgGridThemeFintasticWrapper = styled(AgGridThemeWrapperComponent)(
  ({ theme, themeOptions = {} }) => {
    const { variant = 'default' } = themeOptions
    return agFintasticThemeVariants[variant].stylesBuilder({
      muiTheme: theme,
      ...themeOptions,
    })
  },
)
