import { ThemeComponents } from './types'

export const MuiSnackbarContent: ThemeComponents['MuiSnackbarContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: '12px 16px',
      backgroundColor: theme.palette.text.primary,
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
    }),
    message: ({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0,
      fontSize: theme.typography.fontSize,
      fontWeight: 500,
    }),
  },
}
