import { styled } from '@mui/material'

export const StyledIssueContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth: boolean }>`
  margin: 8px ${({ fullWidth, theme }) => (!fullWidth ? theme.spacing(4) : 0)}
    ${({ theme }) => theme.spacing(2)}
    ${({ fullWidth, theme }) => (!fullWidth ? theme.spacing(4) : 0)};

  border-radius: 8px;
  background: #fdf4e6;
  color: #634602;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};

  opacity: 0;
  animation: appearIssue 0.2s ease-in-out forwards;
  transform: translateX(10px);

  @keyframes appearIssue {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const StyledIssueContentIcon = styled('div')`
  color: #d45702;
  padding: 0 ${({ theme }) => theme.spacing(2)} 0 0;
`
export const StyledIssueContentText = styled('div')`
  display: inline;
`
export const StyledIssueContentMessage = styled('span')``
