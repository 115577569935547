import { useCallback, useEffect, useRef } from 'react'
import { toast } from 'react-hot-toast/headless'
import { UseMutationResult } from 'react-query'
import { HandleMetricUpdateCallbackParams } from '../components/metric-grid/types'

const MUTATION_THRESHOLD = 25

const mergeIntents = (
  changeIntents: HandleMetricUpdateCallbackParams[],
): HandleMetricUpdateCallbackParams[] => {
  const changesByVersionAndMetric: Record<
    string,
    HandleMetricUpdateCallbackParams
  > = {}

  changeIntents.forEach((intent) => {
    const { versionId, changes } = intent
    changes.forEach((change) => {
      const { metricId } = change
      const key = `${versionId}:${metricId}`
      if (!changesByVersionAndMetric[key]) {
        changesByVersionAndMetric[key] = {
          versionId,
          changes: [],
        }
      }
      changesByVersionAndMetric[key].changes.push(change)
    })
  })

  return Object.values(changesByVersionAndMetric)
}

export const useChangeIntentQueue = (
  {
    mutateAsync,
  }: UseMutationResult<
    void,
    unknown,
    HandleMetricUpdateCallbackParams[],
    unknown
  >,
  threshold = MUTATION_THRESHOLD,
) => {
  const queueRef = useRef<HandleMetricUpdateCallbackParams[]>([])
  const timerRef = useRef<NodeJS.Timeout>()

  // Handle component unmount
  useEffect(
    () => () => {
      if (queueRef.current.length) {
        toast.error('Some change intents could not be processed')
      }

      clearTimeout(timerRef.current)
    },
    [],
  )

  return useCallback(
    (intents: HandleMetricUpdateCallbackParams[]) =>
      new Promise((resolve, reject) => {
        queueRef.current.push(...intents)

        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }

        timerRef.current = setTimeout(() => {
          try {
            resolve(mutateAsync(mergeIntents(queueRef.current)))
          } catch (ex) {
            reject(ex)
          }

          queueRef.current = []
        }, threshold)
      }),
    [mutateAsync, threshold],
  )
}
