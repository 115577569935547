import React, { forwardRef } from 'react'
import {
  Box,
  BoxProps,
  ButtonBase,
  ButtonBaseProps,
  styled,
  Typography,
} from '@mui/material'

export const StyledFolderRoot = styled(Box)(({ theme }) => ({
  'position': 'relative',
  'transition': `background ${theme.transitions.duration.standard}ms`,
  '&:hover': {
    background: theme.palette.primary.light,
  },
  '&:hover .boards-context-menu-button': {
    opacity: 1,
    pointerEvents: 'all',
  },
}))
export const StyledFolderButton = styled(
  forwardRef<HTMLButtonElement, { level: number } & ButtonBaseProps>(
    (props, ref) => {
      const { level, ...boxProps } = props
      return <ButtonBase ref={ref} {...boxProps} />
    },
  ),
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'level',
  },
)(({ theme, level }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'paddingTop': theme.spacingFromPixels(3),
  'paddingBottom': theme.spacingFromPixels(3),
  'paddingRight': theme.spacingFromPixels(4 + 34),
  'paddingLeft': theme.spacingFromPixels(level * 24),
  'width': '100%',
  'minHeight': theme.spacingFromPixels(40),
  'textAlign': 'left',
  'transition': `background ${theme.transitions.duration.standard}ms`,
  '&.Mui-focusVisible': {
    background: theme.palette.primary.light,
  },
}))

export const StyledFolderIcon = styled(
  (props: { active?: boolean } & BoxProps) => {
    const { active, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'active',
  },
)(({ theme, active }) => ({
  marginRight: theme.spacingFromPixels(16),
  fontSize: 0,
  svg: {
    width: theme.spacingFromPixels(24),
    height: 'auto',
    path: {
      transition: `all ${theme.transitions.duration.standard}ms`,
      fill: active ? theme.palette.primary.main : undefined,
    },
  },
}))

export const StyledFolderTitle = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  overflow: 'hidden',

  marginTop: '2px',

  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 14,
  fontWeight: active ? 700 : 500,
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
}))

export const StyledFolderContent = styled(Box)(() => ({}))

export const StyledFolderContentContainer = styled(Box)(() => ({}))
