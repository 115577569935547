import type { ICellRendererParams } from 'ag-grid-community'
import { ClickAwayListener, LinearProgress, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VersionDescriptionSaveParams } from '@fintastic/web/data-access/metrics-and-lists'

import { VersionTable, VersionTableReport } from '../../types'
import { useSaveVersionDescription } from '@fintastic/web/data-access/versions'
import { StyledContainer, StyledInput } from './DescriptionCellEditor.styled'
import { toast } from '@fintastic/shared/ui/toast-framework'

function blurActiveElement() {
  if ((document.activeElement as HTMLInputElement)?.blur) {
    (document.activeElement as HTMLInputElement).blur()
  }
}

export const DescriptionCellEditor = (
  params: ICellRendererParams<Exclude<VersionTable, VersionTableReport>> & {
    versionId: string
    editable: boolean
    showHidden: boolean // true for LA
  },
) => {
  const saveParams = useMemo<Omit<VersionDescriptionSaveParams, 'description'>>(
    () => ({
      versionId: params.versionId,
      entityId: params.data?.id || '',
      entityType: params.data?.type || 'list',
    }),
    [params.data?.id, params.data?.type, params.versionId],
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState(false)

  const [localText, setLocalText] = useState<string>(
    params?.data?.info?.description || '',
  )

  useEffect(() => {
    setLocalText(() => params?.data?.info?.description || '')
    textRef.current = params?.data?.info?.description || ''
  }, [params?.data?.info?.description])

  const textRef = useRef<string>(params?.data?.info?.description || '') // for blur HTML event
  const saveMutation = useSaveVersionDescription(saveParams, params.showHidden)

  const handleChangeInput = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(({ currentTarget }) => {
    setLocalText(currentTarget.value)
    textRef.current = currentTarget.value
  }, [])

  const handleConfirmAttempt = useCallback(async () => {
    if (textRef.current === params.data?.info?.description) {
      return true
    }
    try {
      await saveMutation.mutateAsync({
        ...saveParams,
        description: textRef.current,
      })
    } catch (e) {
      console.error(e)
      return false
    }

    return true
  }, [params.data?.info?.description, saveMutation, saveParams])

  const handleKeyDown = useCallback<React.KeyboardEventHandler>(
    (e) => {
      if (e.key === 'Escape') {
        setLocalText(() => params.data?.info?.description || '')
        textRef.current = params.data?.info?.description || ''
        blurActiveElement()
        return
      }

      if (e.key !== 'Enter' && e.key !== 'Tab') {
        e.stopPropagation() // prevent grid navigation
        return
      }

      const saveSucceed = handleConfirmAttempt()

      if (!saveSucceed) {
        toast.error('Error on saving description. Please try again later.')
        e.preventDefault()
        e.stopPropagation()
      } else {
        blurActiveElement()
      }
    },
    [handleConfirmAttempt, params.data?.info?.description],
  )

  const tooltipText = useMemo(
    () =>
      saveMutation.isLoading || focused || localText.length < 12 // ~200px of W
        ? undefined
        : localText,
    [focused, localText, saveMutation.isLoading],
  )

  const handleClickAway = useCallback(() => {
    if (focused) {
      void handleConfirmAttempt()
    }
  }, [focused, handleConfirmAttempt])

  const handleBlur = useCallback(() => {
    void handleConfirmAttempt()
    setFocused(() => false)
  }, [handleConfirmAttempt])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledContainer>
        {saveMutation.isLoading ? (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
        ) : (
          <div /> // Maintain DOM-elements number and order
        )}
        <Tooltip title={tooltipText} disableInteractive>
          <StyledInput
            ref={inputRef}
            data-testid={'description-input'}
            onKeyDownCapture={handleKeyDown}
            disabled={!params.editable || saveMutation.isLoading}
            onChange={handleChangeInput}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            inputProps={{
              maxLength: 160,
            }}
            value={localText}
          />
        </Tooltip>
      </StyledContainer>
    </ClickAwayListener>
  )
}
