import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'

type SharedProps = {
  goBack: () => void
  goToReportingGroups: () => void
  goToMetric: (id: string) => void
}

export const Editable: React.FC<
  SharedProps & {
    metrics: Array<{
      id: string
      label: string
      reportingGroupLabel: Maybe<string>
      error?: string
    }>
    availableMetrics: Array<{
      id: string
      label: string
    }>
    addMetric: (id: string) => void
    reorderMetrics: (ids: string[]) => void
  }
> = () => <div>Editable</div>

export const Readonly: React.FC<
  SharedProps & {
    metrics: Array<{
      id: string
      label: string
      reportingGroupLabel: string
    }>
  }
> = () => <div>Readonly</div>

export const MetricsView = {
  Editable,
  Readonly,
} as const
