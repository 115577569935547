import { Box, Divider } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export const FilterPanel: React.FC<
  PropsWithChildren<{
    searchSlot: React.ReactNode
    footerSlot: React.ReactNode
  }>
> = ({ searchSlot, footerSlot, children }) => (
  <Box display="flex" flexDirection="column" height="100%">
    {searchSlot ? (
      <>
        <Box p={1}>{searchSlot}</Box>
        <Divider />
      </>
    ) : null}

    <Box flexGrow={1} sx={{overflowY: 'auto'}}>
      {children}
    </Box>
    {footerSlot ? (
      <>
        <Divider />
        <Box p={1.5}>{footerSlot}</Box>
      </>
    ) : null}
  </Box>
)

FilterPanel.displayName = 'FilterPanel'