import type { ReportDisplaySettings } from '@fintastic/web/util/generic-report'
import { applyCompactFormatting } from '@fintastic/shared/util/formatters'
import { format } from 'numerable'
import { BLANK_VALUE_UI_REPRESENTATION } from '@fintastic/web/util/blanks-and-masked'

export const formatWithDisplayConfig = (
  uom: 'percentage' | 'ea' | 'usd',
  displaySettings: ReportDisplaySettings,
  value: number,
): string => {
  const compact_format = displaySettings.compact_format ?? 'no_format'
  const wholePartTemplate = '0,0'

  const decimalPlacesTemplate =
    displaySettings.decimal_places > 0
      ? `.${Array(displaySettings.decimal_places).fill(0).join('')}`
      : ''

  let numberTemplate = `${wholePartTemplate}${decimalPlacesTemplate}`

  if (compact_format !== 'no_format') {
    numberTemplate = `${numberTemplate}${NUMERIC_FORMATTER_ABBREVIATION_REPLACEMENT_MARK}`
  }

  if (uom === 'usd') {
    numberTemplate = `${NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK}${numberTemplate}`
  } else if (uom === 'percentage') {
    numberTemplate = `${numberTemplate}%`
  }

  const numberWithSignTemplate = `(${numberTemplate})`

  const postProcessFormattedValue = (value: string): string => {
    const valueWithAbbreviationNumbers =
      compact_format !== 'no_format'
        ? value.replace(
            NUMERIC_FORMATTER_ABBREVIATION_REPLACEMENT_MARK,
            compact_format === 'thousands' ? 'K' : 'M',
          )
        : value
    const valueWithCurrency =
      uom === 'usd'
        ? valueWithAbbreviationNumbers.replace(
            NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK,
            currencySymbol,
          )
        : valueWithAbbreviationNumbers
    return `${valueWithCurrency}`
  }

  const result = postProcessFormattedValue(
    format(
      applyCompactFormatting(compact_format, value),
      numberWithSignTemplate,
    ),
  )

  if (result.includes('NaN')) {
    return BLANK_VALUE_UI_REPRESENTATION
  }

  return result
}

const NUMERIC_FORMATTER_ABBREVIATION_REPLACEMENT_MARK = '~;K;~'
const NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK = '~;C;~'
const currencySymbol = '$'
