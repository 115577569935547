import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { ModalState } from '@fintastic/shared/util/modal'
import { Board } from '@fintastic/web/feature/boards'
import { BoardArchiveForm } from '../../../forms/BoardArchiveForm'

export type CurrentBoardArchiveDialogProps = {
  state: ModalState
  boardName: string
  boardId: Board['id']
}

export const CurrentBoardArchiveDialog: React.FC<
  CurrentBoardArchiveDialogProps
> = ({ state, boardName, boardId }) => {
  if (!state.isOpen || !boardId) {
    return null
  }

  return (
    <Modal
      open={state.isOpen}
      onRequestClose={state.close}
      title="Archive current Board"
      data-testid="board-archive-modal-root"
    >
      <BoardArchiveForm
        closeParentModal={state.close}
        boardId={boardId}
        currentBoardId={boardId}
        boardName={boardName || ''}
      />
    </Modal>
  )
}
