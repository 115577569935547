import type { QueryParams } from './types'
import { useQueriesWithMemo } from '@fintastic/shared/data-access/react-query'
import { useMemo } from 'react'
import {
  getMetric,
  metricsAndListsCacheKeys,
} from '@fintastic/web/data-access/metrics-and-lists'
import type { Metric } from '@fintastic/web/util/metrics-and-lists'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'

// @todo add tests
export function useWeightsMetricsQueries(
  params: QueryParams[],
  enabled: boolean,
) {
  const periodSelection = usePeriodSelectorContext()

  return useQueriesWithMemo<Metric[]>(
    useMemo(
      () =>
        params.map(({ metric, version }) => ({
          queryKey: metricsAndListsCacheKeys.metric(
            version,
            metric,
            periodSelection,
          ),
          queryFn: async () => {
            const response = await getMetric(version, metric, periodSelection)
            return response?.data
          },
          retry: false,
          refetchInterval: false as const,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          enabled,
        })),
      [enabled, params, periodSelection],
    ),
  )
}
