import { Version } from '@fintastic/web/util/versions'
import { useMemo } from 'react'

export function useVersionsListMap(
  versions: Version[],
): Record<string, Version> {
  return useMemo(
    () => Object.fromEntries(versions.map((v) => [v.uuid, v])),
    [versions],
  )
}
