import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useEffect, useMemo, useReducer } from 'react'
import { reducer, initialState, actions } from './duck'

export const useEditingState = (initialValue: PeriodSelection) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selection: initialValue,
  })

  useEffect(() => {
    dispatch(actions.reset(initialValue))
  }, [initialValue])

  return useMemo(
    () =>
      ({
        state,
        dispatch,
        actions,
      } as const),
    [state],
  )
}
