import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { ColumnEntityDefinition } from '../../types'
import { CommonListOrMetricWrapperImplementation } from './CommonListOrMetricWrapperImplementation'

export class ColumnEntityDefinitionWrapper
  extends CommonListOrMetricWrapperImplementation
  implements ImmutableADTWrapper<ColumnEntityDefinition>
{
  unwrap(): ColumnEntityDefinition {
    return this._rawData
  }

  public type = 'column' as const
}
