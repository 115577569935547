import React from 'react'
import { FormattingFieldsetProps } from './types'
import { FormattingFieldWrapper } from './FormattingFieldWrapper'
import { CompactFormattingField } from './fields/CompactFormattingField'

export type IntegerFormattingProps = Omit<
  FormattingFieldsetProps,
  'currencySignPosition' | 'onChangeCurrencySignPosition' | 'currency'
>

export const IntegerFormatting: React.FC<IntegerFormattingProps> = ({
  displaySettings,
  onChange,
}) => (
  <FormattingFieldWrapper>
    <CompactFormattingField
      value={displaySettings.numericCompactFormat()}
      onChange={(v) => onChange({ compactFormatting: v })}
    />
  </FormattingFieldWrapper>
)
