import React, { useMemo } from 'react'
import { Modal } from '@fintastic/shared/ui/modal-framework'
import { CreateEntityForm } from './create-modal/CreateEntityForm'
import { useModalState } from '@fintastic/shared/util/modal'
import { NewEntityButton } from './NewEntityButton'
import {
  versionIsLockedMessage,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'
import { Tooltip } from '@mui/material'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'

export const useCreateEntityModal = (
  params: {
    versionId: string
    isLiveActuals: boolean
  },
  deps: {
    versionUserLock: VersionUserLockParsed
  },
) => {
  const { versionId, isLiveActuals } = params
  const { versionUserLock } = deps

  const isAnyEditorActive = useIsAnyEditorActive()

  const modalState = useModalState()

  const modal = useMemo(
    () => (
      <Modal
        open={modalState.isOpen}
        onRequestClose={modalState.close}
        title="Create new"
      >
        <CreateEntityForm
          versionId={versionId}
          isLiveActuals={isLiveActuals}
          requestModalClose={modalState.close}
        />
      </Modal>
    ),
    [modalState.isOpen, modalState.close],
  )

  const button = useMemo(() => {
    if (versionUserLock.editIsBlocked && versionUserLock.lockedBy !== 'me') {
      return (
        <Tooltip
          title={versionIsLockedMessage(versionUserLock, 'Adding a new entity')}
          placement="top"
        >
          <span>
            <NewEntityButton onClick={modalState.open} enabled={false} />
          </span>
        </Tooltip>
      )
    }

    return (
      <NewEntityButton onClick={modalState.open} enabled={!isAnyEditorActive} />
    )
  }, [
    isAnyEditorActive,
    versionUserLock.editIsBlocked,
    versionUserLock.lockedBy,
  ])

  return useMemo(
    () =>
      ({
        modal,
        button,
      } as const),
    [modal, button],
  )
}

const useIsAnyEditorActive = () => {
  const metricOrListEditorActive = useCurrentEditingFlow().flow !== null
  const reportEditorActive = !!useReportEditorApi()?.active
  return metricOrListEditorActive || reportEditorActive
}
