import { useQueriesWithMemo } from '@fintastic/shared/data-access/react-query'
import { useMemo } from 'react'
import { VersionAccess } from '@fintastic/web/util/versions'
import { createVersionAccessQueryHandler } from './version-access-query-handler'
import { versionsCacheKeys } from '../../cache'

export function useLoadAccessForVersionsList(versionIds: string[]) {
  return useQueriesWithMemo<VersionAccess[]>(
    useMemo(
      () =>
        versionIds.map(
          (versionId) =>
            ({
              queryKey: versionsCacheKeys.versionAccess(versionId),
              queryFn: createVersionAccessQueryHandler(versionId),
            } as const),
        ),
      [versionIds],
    ),
  )
}
