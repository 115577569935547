import React, { useCallback, useMemo } from 'react'
import {
  CheckboxesListChangeHandler,
  CheckboxesListOption,
  CheckboxesListOptionsIds,
} from './types'
import { StyledRoot, StyledItem } from './CheckboxesList.styled'
import Checkbox from '@mui/material/Checkbox'
import { createBooleanMapOfValues } from './utils/createBooleanMapOfValues'
import { createNewSelectedValues } from './utils/createNewSelectedValues'

export type CheckboxesListProps<TValue extends string | number = string> = {
  options: CheckboxesListOption<TValue>[]
  selectedOptions?: CheckboxesListOptionsIds<TValue>
  disabledOptions?: CheckboxesListOptionsIds<TValue>
  onChange?: CheckboxesListChangeHandler<TValue>
  selectAll?: boolean
  allSelected?: boolean
  onSelectAll?: () => void
}

export const CheckboxesList = <TValue extends string | number = string>({
  options,
  selectedOptions,
  disabledOptions,
  onChange,
  selectAll,
  onSelectAll,
  allSelected,
}: CheckboxesListProps<TValue>): React.ReactElement | null => {
  const selectedOptionsMap = useMemo(
    () => createBooleanMapOfValues(selectedOptions || []),
    [selectedOptions],
  )

  const disabledOptionsMap = useMemo(
    () => createBooleanMapOfValues(disabledOptions || []),
    [disabledOptions],
  )

  const handleCheckboxClick = useCallback(
    (clickedOptionValue: TValue) => {
      if (!onChange) {
        return
      }
      onChange(
        createNewSelectedValues(selectedOptions || [], clickedOptionValue),
        clickedOptionValue,
      )
    },
    [onChange, selectedOptions],
  )

  const handleSelectAll = useCallback(() => {
    if (!onChange) {
      return
    }

    if (allSelected) {
      onChange([])
    } else {
      onSelectAll?.()
    }
  }, [allSelected, onChange, onSelectAll])

  return (
    <StyledRoot>
      {selectAll && options.length > 0 ? (
        <StyledItem
          label="Select all"
          control={<Checkbox />}
          checked={allSelected}
          onChange={handleSelectAll}
        />
      ) : null}

      {options.map((option) => (
        <StyledItem
          disabled={!!disabledOptionsMap[option.value]}
          key={option.value}
          value={option.value}
          label={option.label}
          control={<Checkbox value={option.value} />}
          checked={!!selectedOptionsMap[option.value]}
          defaultChecked={!!selectedOptionsMap[option.value]}
          onChange={() => handleCheckboxClick(option.value)}
        />
      ))}
    </StyledRoot>
  )
}
