import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { setMetricCellDataValue } from '../../../../utils/setting-cell-data-value'
import { HandleMetricUpdateCallbackParams, MetricGridRow } from '../../types'
import { GridApi } from 'ag-grid-community'

export const revertCellEditChanges = (
  api: GridApi<MetricGridRow>,
  changes: HandleMetricUpdateCallbackParams[],
  timeDimension?: TimeDimensionId,
) => {
  changes.forEach((change) => {
    change.changes.forEach((c) => {
      try {
        setMetricCellDataValue(api as GridApi, {
          value: c.oldValue ?? null,
          coordinates: {
            ...c,
            versionId: change.versionId,
            timeDimension,
          },
        })
      } catch (ex) {
        console.warn('Cannot revert change', ex)
      }
    })
  })
}
