import React, { useState, useCallback } from 'react'
import { AgGridCellRendererProps } from '../types'
import { AG_CELL_RENDERER_CLASS_NAME } from '../classNames'
import { isCellAllowClear } from '../../utils/predicates'
import {
  StyledAgGridDefaultCellRendererRoot,
  StyledAgGridDefaultCellRendererValueContainer,
} from './AgGridDefaultCellRenderer.styled'
import {
  PopulateForwardCellRendererProps,
  PopulateForward,
} from '../../components/PopulateForward'
import { ClearCellButton } from '../../components/ClearCellButton'

export type AgGridDefaultCellRendererProps = {
  showTooltipForValue?: boolean
  isRequired?: boolean | undefined
  populateForward?: PopulateForwardCellRendererProps
  getClearValue?: () => unknown
  fallbackForNonEditable?:
    | string
    | ((
        props: AgGridCellRendererProps<AgGridDefaultCellRendererProps>,
      ) => string)
}

// ATTENTION: do not change my styles here,
// change them in frontend/main-client/libs/shared/ui/ag-grid-theme-fintastic/src/lib/theme-variants/default.ts
export const AgGridDefaultCellRenderer = (
  props: AgGridCellRendererProps<AgGridDefaultCellRendererProps>,
): JSX.Element => {
  const {
    valueFormatted,
    value,
    showTooltipForValue = false,
    data,
    column,
    isRequired,
    populateForward,
    node,
    api,
    setValue,
    getClearValue,
    fallbackForNonEditable,
  } = props

  const isEditable = column?.isCellEditable(node) || false
  const isCellClearable = isCellAllowClear(isRequired, isEditable)
  const isPopulateForwardEnabled = populateForward?.enabled && isEditable

  const [displayClearButton, setDisplayClearButton] = useState(false)
  const handleClearCell = useCallback(
    () => setValue?.(getClearValue ? getClearValue() : ''),
    [getClearValue, setValue],
  )

  const handleMouseOver = useCallback(() => {
    setDisplayClearButton(true)
  }, [setDisplayClearButton])

  const handleMouseLeave = useCallback(() => {
    setDisplayClearButton(false)
  }, [setDisplayClearButton])

  const displayValue = (() => {
    if (fallbackForNonEditable && !isEditable) {
      if (typeof fallbackForNonEditable === 'function') {
        return fallbackForNonEditable(props)
      }

      return fallbackForNonEditable
    }

    if (!valueFormatted && !value) {
      return ''
    }
    return valueFormatted || String(value)
  })()

  return (
    <StyledAgGridDefaultCellRendererRoot
      className={AG_CELL_RENDERER_CLASS_NAME + (isEditable ? '' : ' readonly')}
      title={showTooltipForValue ? displayValue : undefined}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      data-testid="cellRendererDiv"
    >
      {isCellClearable && displayClearButton && (
        <ClearCellButton onClick={handleClearCell} />
      )}
      <StyledAgGridDefaultCellRendererValueContainer
        hasPopulateForwardTip={isPopulateForwardEnabled}
      >
        {displayValue}
      </StyledAgGridDefaultCellRendererValueContainer>
      {isPopulateForwardEnabled && (
        <PopulateForward
          value={value}
          data={data}
          gridApi={api}
          rowNode={node}
          buttons={populateForward.buttons}
        />
      )}
    </StyledAgGridDefaultCellRendererRoot>
  )
}
