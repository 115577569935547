import React, { MouseEvent, useCallback, useMemo, useRef } from 'react'
import {
  ContextMenu,
  ContextMenuIcon,
  ContextMenuItem,
  useContextMenuState,
} from '@fintastic/shared/ui/context-menu-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { StyledTitleContextMenuButton } from './BoardTitle/BoardTitle.styled'
import { PopoverOrigin } from '@mui/material'
import { DesignerWidgetType } from '../widgets/board-design-type-utils'
import { TEXT_WIDGET_TYPE } from '../../widgets'

const contextMenuOrigin: {
  anchorOrigin: PopoverOrigin
  transformOrigin: PopoverOrigin
} = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: -6,
    horizontal: 'right',
  },
}

type BoardWidgetContextMenuProps = {
  id: string
  onChange: () => void
  onDelete: () => void
  widgetType: DesignerWidgetType
}

export const BoardWidgetContextMenu: React.FC<BoardWidgetContextMenuProps> = ({
  id,
  widgetType,
  onDelete,
  onChange,
}) => {
  const contextMenuButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const contextMenuState = useContextMenuState()

  const handleOpenContextMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (contextMenuButtonRef.current === null) {
        return
      }
      contextMenuState.handleClickOnAnchor({
        target: contextMenuButtonRef.current,
      })
    },
    [contextMenuState],
  )
  const menuItems: ContextMenuItem[] = useMemo(() => {
    const result: ContextMenuItem[] = []

    if (widgetType !== TEXT_WIDGET_TYPE) {
      result.push({
        id: 'editWidget',
        type: 'button',
        onClick: onChange,
        label: 'Widget settings',
      })
      result.push({
        id: 'div-1',
        type: 'divider',
      })
    }

    result.push({
      id: 'deleteWidget',
      type: 'button',
      onClick: onDelete,
      label: 'Delete widget',
    })

    return result
  }, [id, onDelete, onChange])

  return (
    <>
      <StyledTitleContextMenuButton
        ref={contextMenuButtonRef}
        size="small"
        onClick={handleOpenContextMenu}
      >
        <div style={{ transform: 'scale(0.7)' }}>
          <ContextMenuIcon />
        </div>
      </StyledTitleContextMenuButton>

      <ContextMenu
        menuItems={menuItems}
        open={contextMenuState.isOpen}
        anchorEl={contextMenuState.anchorElement}
        onClose={contextMenuState.handleClose}
        {...contextMenuOrigin}
      />
    </>
  )
}
