import React, { useCallback } from 'react'
import { Typography } from '@mui/material'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import {
  generateErrorMessage,
  generateSuccessMessage,
  useSelectedVersions,
} from '../utils'
import { useDeleteListOfVersions } from '@fintastic/web/data-access/versions'
import { Version } from '@fintastic/web/util/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'

export type VersionsDeleteFormProps = {
  selectedVersions: Version[]
  closeParentModal: () => void
  onAfterSuccess?: () => void
}

export const VersionsDeleteForm: React.FC<VersionsDeleteFormProps> = (
  props,
) => {
  const { closeParentModal, selectedVersions, onAfterSuccess } = props
  const { singleVersionSelected, versionNamesTitle, selectedVersionsIds } =
    useSelectedVersions(selectedVersions)

  const deleteVersionMutation = useDeleteListOfVersions(selectedVersionsIds)

  const handleSubmit = useCallback(() => {
    deleteVersionMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(generateSuccessMessage(selectedVersions, 'Deleted'))
        onAfterSuccess?.()
      },
      onError: () => {
        toast.error(generateErrorMessage(selectedVersions, 'Delete'))
      },
    })
    if (closeParentModal) {
      closeParentModal()
    }
  }, [
    deleteVersionMutation,
    closeParentModal,
    selectedVersions,
    onAfterSuccess,
  ])

  return (
    <FormLayout
      submitButtonText="Confirm"
      onSubmit={handleSubmit}
      testIdPrefix="delete"
    >
      <Typography sx={{ marginBottom: 3, whiteSpace: 'pre-line' }}>
        {singleVersionSelected ? (
          <>Are you sure you want to delete {versionNamesTitle}?</>
        ) : (
          <>
            Are you sure you want to delete versions
            <br />
            {versionNamesTitle}?
          </>
        )}
        <br />
        After deletion you won’t be able to restore it.
      </Typography>
    </FormLayout>
  )
}
