import { Button, ButtonProps, Menu, MenuItem, styled } from '@mui/material'
import React from 'react'

export const StyledSingleVersionSelectorMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`

export const StyledSingleVersionSelectorMenuItem = styled(MenuItem)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  span {
    margin-left: 0.4em;
  }
`

export const StyledSingleVersionButton = styled(
  (props: ButtonProps & { overrides?: boolean; opened?: boolean }) => {
    const { overrides, ...buttonProps } = props
    return <Button {...buttonProps} />
  },
  {
    shouldForwardProp: (propName) =>
      propName !== 'overrides' && propName !== 'opened',
  },
)`
  font-weight: bold;
  padding: 4px 4px 4px 8px;
  height: 32px;
  margin-right: 8px;

  white-space: nowrap;

  color: ${({ theme, overrides }) =>
    overrides ? theme.palette.primary.dark : theme.palette.text.primary};

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  transition: ${({ theme }) => theme.transitions.create('all')};

  background: ${({ theme, opened }) =>
    opened ? theme.palette.action.focus : 'transparent'};

  &:hover {
    color: ${({ theme, overrides }) =>
      overrides ? theme.palette.primary.dark : theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.action.hover};
  }

  svg {
    transition: ${({ theme }) => theme.transitions.create('all')};
    transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'none')};
  }
`
