import React from 'react'
import { createSvgIcon } from '@mui/material'

export const Icon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.18308 2.18308C2.35638 2.00978 2.61497 1.95376 2.84445 2.03981L9.91611 4.69147C10.1551 4.7811 10.3156 5.00708 10.3215 5.26228C10.3274 5.51749 10.1775 5.75062 9.94284 5.85116L7.07851 7.07851L5.85116 9.94284C5.75062 10.1775 5.51749 10.3274 5.26228 10.3215C5.00708 10.3156 4.7811 10.1551 4.69147 9.91611L2.03981 2.84445C1.95376 2.61497 2.00978 2.35638 2.18308 2.18308ZM3.69296 3.69296L5.31533 8.01962L6.02803 6.35635C6.09125 6.20881 6.20881 6.09125 6.35635 6.02803L8.01962 5.31533L3.69296 3.69296Z"
    />
    <path d="M18.25 5.12502C18.25 4.77984 18.5298 4.50002 18.875 4.50002C19.7038 4.50002 20.4986 4.82926 21.0847 5.41531C21.6707 6.00136 22 6.79622 22 7.62502C22 7.9702 21.7202 8.25002 21.375 8.25002C21.0298 8.25002 20.75 7.9702 20.75 7.62502C20.75 7.12774 20.5524 6.65082 20.2008 6.29919C19.8492 5.94756 19.3723 5.75002 18.875 5.75002C18.5298 5.75002 18.25 5.4702 18.25 5.12502Z" />
    <path d="M21.375 18.25C21.7202 18.25 22 18.5298 22 18.875C22 19.7038 21.6707 20.4987 21.0847 21.0847C20.4986 21.6708 19.7038 22 18.875 22C18.5298 22 18.25 21.7202 18.25 21.375C18.25 21.0298 18.5298 20.75 18.875 20.75C19.3723 20.75 19.8492 20.5525 20.2008 20.2008C20.5524 19.8492 20.75 19.3723 20.75 18.875C20.75 18.5298 21.0298 18.25 21.375 18.25Z" />
    <path d="M5.75 18.875C5.75 18.5298 5.47017 18.25 5.125 18.25C4.77982 18.25 4.5 18.5298 4.5 18.875C4.5 19.7038 4.82924 20.4987 5.41529 21.0847C6.00134 21.6708 6.79619 22 7.62499 22C7.97017 22 8.24999 21.7202 8.24999 21.375C8.24999 21.0298 7.97017 20.75 7.62499 20.75C7.12771 20.75 6.6508 20.5525 6.29917 20.2008C5.94754 19.8492 5.75 19.3723 5.75 18.875Z" />
    <path d="M5.125 12C5.47017 12 5.75 12.2798 5.75 12.625V15.125C5.75 15.4702 5.47017 15.75 5.125 15.75C4.77982 15.75 4.5 15.4702 4.5 15.125V12.625C4.5 12.2798 4.77982 12 5.125 12Z" />
    <path d="M22 11.375C22 11.0298 21.7202 10.75 21.375 10.75C21.0298 10.75 20.75 11.0298 20.75 11.375V15.125C20.75 15.4702 21.0298 15.75 21.375 15.75C21.7202 15.75 22 15.4702 22 15.125V11.375Z" />
    <path d="M10.75 21.375C10.75 21.0298 11.0298 20.75 11.375 20.75H15.125C15.4702 20.75 15.75 21.0298 15.75 21.375C15.75 21.7202 15.4702 22 15.125 22H11.375C11.0298 22 10.75 21.7202 10.75 21.375Z" />
    <path d="M12.625 4.50002C12.2798 4.50002 12 4.77984 12 5.12502C12 5.4702 12.2798 5.75002 12.625 5.75002H15.125C15.4702 5.75002 15.75 5.4702 15.75 5.12502C15.75 4.77984 15.4702 4.50002 15.125 4.50002H12.625Z" />
    <path d="M15.75 9.19366C15.3869 8.96746 14.9614 8.85563 14.5297 8.87676C14.185 8.89363 13.8918 8.62783 13.8749 8.28306C13.8581 7.9383 14.1239 7.64513 14.4686 7.62825C15.1416 7.59531 15.805 7.76537 16.375 8.11064C16.9445 7.76576 17.6071 7.59572 18.2794 7.62824C18.6241 7.64492 18.8901 7.93793 18.8734 8.28271C18.8568 8.62748 18.5637 8.89345 18.219 8.87677C17.7877 8.85591 17.3628 8.96774 17 9.19371V17.9344C17.3629 18.1613 17.7884 18.2738 18.2203 18.2532C18.5651 18.2368 18.8579 18.503 18.8743 18.8478C18.8907 19.1925 18.6245 19.4854 18.2797 19.5018C17.6071 19.5338 16.9444 19.3631 16.375 19.0175C15.8056 19.3631 15.1429 19.5338 14.4703 19.5018C14.1255 19.4854 13.8593 19.1925 13.8757 18.8478C13.8921 18.503 14.1849 18.2368 14.5297 18.2532C14.9616 18.2738 15.3871 18.1613 15.75 17.9344V9.19366Z" />
  </>,
  'Icon',
)
