import { useNavigate } from 'react-router-dom'
import { useBoardsFoldersTree } from './useBoardsFoldersTree'
import { useEffect, useMemo } from 'react'
import {
  Board,
  BOARD_FOLDER_ARCHIVE_ID,
  boardRoutes,
  BoardsFoldersTreeNode,
  BoardsFoldersTreeNodeFolder,
} from '@fintastic/web/feature/boards'
import { Maybe } from '@fintastic/shared/util/types'

// navigates to first active board if the board given is archived
export const useArchivedBoardGuard = (currentBoard?: Board) => {
  const navigate = useNavigate()
  const data = useBoardsFoldersTree()

  const firstAvailableBoard = useMemo(() => {
    const getFirstBoard = (nodes: BoardsFoldersTreeNode[]): Maybe<string> => {
      if (nodes.length === 0) {
        return null
      }

      for (let i = 0; i < nodes.length; i += 1) {
        if (nodes[i].type === 'board') {
          return nodes[i].data.id
        }

        return getFirstBoard((nodes[i] as BoardsFoldersTreeNodeFolder).children)
      }

      return null
    }

    return data.isLoading
      ? null
      : getFirstBoard(data.tree.rootNodes.filter((folder) => folder.data.id !== BOARD_FOLDER_ARCHIVE_ID))
  }, [data])

  useEffect(() => {
    if (currentBoard?.is_deleted && firstAvailableBoard) {
      navigate(boardRoutes.board(firstAvailableBoard))
    }
  }, [navigate, currentBoard, firstAvailableBoard])
}
