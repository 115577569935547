import React from 'react'
import {
  StyledContainer,
  StyledContentContainer,
  StyledFooter,
  StyledLoaderContainer,
  StyledLogoContainer,
  StyledMain,
  StyledRoot,
} from './AppLoader.styled'
import { Footer } from '../Footer'
import LinearProgress from '@mui/material/LinearProgress'
import { LinearProgressWithValueLabel } from '../LinearProgressWithValueLabel'

type RangeFromZeroTo100 = number

export const AppLoader: React.FC<{
  isLoading: boolean
  progress?: RangeFromZeroTo100
  children?: React.ReactNode
}> = ({ isLoading, progress, children }) => (
  <StyledRoot>
    <StyledMain>
      <StyledContainer data-testid="app-loader-container">
        <StyledLogoContainer data-testid="app-loader-logo">
          <img src="/fintastic.png" alt="Fintastic" />
        </StyledLogoContainer>
        {children && (
          <StyledContentContainer>{children}</StyledContentContainer>
        )}
        {isLoading && (
          <StyledLoaderContainer data-testid="app-loader-progress">
            {progress === undefined ? (
              <LinearProgress sx={{ mt: '12px' }} />
            ) : (
              <LinearProgressWithValueLabel
                sx={{ mt: '8px' }}
                value={progress}
              />
            )}
          </StyledLoaderContainer>
        )}
      </StyledContainer>
    </StyledMain>
    <StyledFooter>
      <Footer />
    </StyledFooter>
  </StyledRoot>
)
