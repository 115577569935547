import { NewPeriodsRangeGetter } from '../../components/types'
import {
  convertPeriodSelectionToRange,
  getDefaultPeriodSelectionAsRange,
  isEmptyPeriodSelection,
  isRangeBasedSelection,
} from '@fintastic/web/util/period-selector'
import { CalendarDatePickerConfig } from '@fintastic/web/util/period-selector'
import { isMoreGranularAggregation } from '../utils'
import { getLowerAggregationRange } from './getLowerAggregationRange'
import { getHigherAggregationRange } from './getHigherAggregationRange'
import { DimensionId } from '@fintastic/web/util/dimensions'

export const newPeriodsRangeGetterFabric =
  (
    datePickerConfig: CalendarDatePickerConfig,
    aggregationTypesPlain: DimensionId[],
  ): NewPeriodsRangeGetter =>
  (nextDimensionId, periodSelection) => {
    if (isEmptyPeriodSelection(periodSelection)) {
      return getDefaultPeriodSelectionAsRange(datePickerConfig, nextDimensionId)
    }

    if (nextDimensionId === periodSelection.dimensionId) {
      if (isRangeBasedSelection(periodSelection)) {
        return periodSelection.range
      }

      return (
        convertPeriodSelectionToRange(periodSelection, datePickerConfig) ||
        getDefaultPeriodSelectionAsRange(datePickerConfig, nextDimensionId)
      )
    }

    return isMoreGranularAggregation(
      aggregationTypesPlain,
      nextDimensionId,
      periodSelection.dimensionId,
    )
      ? getLowerAggregationRange(
          datePickerConfig,
          nextDimensionId,
          periodSelection,
        )
      : getHigherAggregationRange(
          datePickerConfig,
          nextDimensionId,
          periodSelection,
        )
  }
