import { styled, Box, CircularProgress } from '@mui/material'

export const StyledBoardsSidebarMenuRoot = styled(Box)`
  width: 100%;
`
export const StyledBoardsSidebarMenuLoader = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacingFromPixels(24)}`,
}))

export const StyledBoardsSidebarMenuLoaderProgress = styled(CircularProgress)(
  ({ theme }) => ({}),
)

export const StyledBoardsSidebarMenuTree = styled(Box)`
`

export const StyledBoardsSidebarMenuFooter = styled(Box)(({ theme }) => ({}))
