import React from 'react'
import {
  StyledNotFoundMessageRoot,
  StyledNotFoundMessageTitle,
  StyledNotFoundMessageMessage,
} from './NotFoundMessage.styled'

export const NotFoundMessage: React.FC = () => (
  <StyledNotFoundMessageRoot>
    <StyledNotFoundMessageTitle variant="h1">404</StyledNotFoundMessageTitle>
    <StyledNotFoundMessageMessage>Page not found</StyledNotFoundMessageMessage>
  </StyledNotFoundMessageRoot>
)
