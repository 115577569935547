import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'
import {
  ChartXAxisAlignment,
  chartXAxisAlignments,
  charXAxisAlignmentKeys,
  defaultChartXAxisAlignment,
  isChartXAxisAlignment,
} from '@fintastic/web/feature/charts'
import {
  StyledTextWithoutIcon,
  StyledTextWithoutIconField,
} from '../common-styles'

export type ChartXAxisAlignmentSelectProps = {
  xAxisAlignment: ChartXAxisAlignment
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartXAxisAlignmentSelect: React.FC<ChartXAxisAlignmentSelectProps> =
  memo(({ xAxisAlignment, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartXAxisAlignment(e.target.value)) {
          onChangeChartSettings({ xAxisAlignment: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextWithoutIconField
          id="chart-vaxis-align-select"
          fullWidth
          value={xAxisAlignment}
          size="small"
          select
          label="Labels alignment"
          defaultValue={defaultChartXAxisAlignment}
          onChange={handleSelect}
          disabled={disabled}
        >
          {charXAxisAlignmentKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithoutIcon
                data-testid={`chart-vaxis-alignment-item-${ct}`}
              >
                {chartXAxisAlignments[ct]}
              </StyledTextWithoutIcon>
            </MenuItem>
          ))}
        </StyledTextWithoutIconField>
      </FormField>
    )
  })
