import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { useCallback, useEffect } from 'react'
import { ColumnRowGroupChangedEvent, GridReadyEvent } from 'ag-grid-community'
import { useSyncDeeplinkValue } from '../useSyncDeeplinkValue'

const defaultColumnsValue: string[] = []

export const useDeeplinkRowGroups = <TData>(
  deeplinkWidgetId: string,
  gridRef: React.RefObject<AgGridReactType<TData>>,
) => {
  const [urlRowGroups, setUrlRowGroups] = useSyncDeeplinkValue<
    string[]
  >({
    key: `w${deeplinkWidgetId}_col`,
    defaultValue: defaultColumnsValue,
  })

  const handleColumnRowGroupChanged = useCallback(
    (event: ColumnRowGroupChangedEvent<TData>) => {
      if (event.source === 'api') {
        return
      }
      setUrlRowGroups(event.columns?.map(i => i.getColId()))
    },
    [setUrlRowGroups],
  )

  const handleGridReady = useCallback(
    (e: GridReadyEvent<TData>) => {
      e.columnApi.setRowGroupColumns(urlRowGroups)
    },
    [urlRowGroups],
  )

  useEffect(() => {
    if (!gridRef.current?.columnApi) {
      return
    }

    gridRef.current?.columnApi.setRowGroupColumns(urlRowGroups)
  }, [urlRowGroups])

  return { handleColumnRowGroupChanged, handleGridReady }
}
