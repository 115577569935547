type Colors = {
  bg: string
  fg: string
}

const COLORS_SET: Array<Colors> = [
  {
    bg: '#263646',
    fg: '#FFF6ED',
  },
  {
    bg: '#FFF6ED',
    fg: '#263646',
  },
  {
    bg: '#FF9568',
    fg: '#263646',
  },
  {
    bg: '#FF9568',
    fg: '#FFF6ED',
  },
  {
    bg: '#FE6422',
    fg: '#263646',
  },
  {
    bg: '#FE6422',
    fg: '#FFF6ED',
  },
  {
    bg: '#D64E15',
    fg: '#263646',
  },
  {
    bg: '#D64E15',
    fg: '#FFF6ED',
  },
]

type ColorVariant = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

function stringToColorVariant(s: string): ColorVariant {
  let hash = 0
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  hash += s.length
  // Ensure the result is within 1-8 range
  return (Math.abs(hash % 8) + 1) as ColorVariant
}

export function getFixedColors(): Colors {
  return COLORS_SET[5]
}

export function getColorsByString(s: string): Colors {
  const variant = stringToColorVariant(s)
  return COLORS_SET[variant] || COLORS_SET[0]
}
