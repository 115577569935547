import React from 'react'
import { StyledBoardNameEditContainer } from './BoardName.styled'

type BoardDisplayNameProps = {
  name: string
}

export const BoardDisplayName: React.FC<BoardDisplayNameProps> = ({ name }) => (
  <StyledBoardNameEditContainer>
    <h1 id="static-text-name">{name}</h1>
  </StyledBoardNameEditContainer>
)
