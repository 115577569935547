// @todo (mykola): add tests!!
import {
  type GenericReportTreeRow,
  type GenericReportTreeRowCellValue,
  type GenericReportValue,
  INVALID_FORMULA_VALUE,
} from '@fintastic/web/util/generic-report'
import type {
  IAggFunc,
  IAggFuncParams,
  RowNode,
  ValueGetterFunc,
  ValueGetterParams,
} from 'ag-grid-community'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  calculatedRowDiffValueGetter,
  calculatedRowValueGetter,
} from './calculated-row-logic'
import { getCellValue } from './cell-value-getter'
import {
  BLANK_VALUE,
  BlankOrMaskedValue,
  MathDivideByZeroError,
  containsBlankValue,
  percentageWithBlanks,
  stringifyBlankOrMaskedValue,
  subtractWithBlanks,
} from '@fintastic/web/util/blanks-and-masked'
import { DiffOperation } from '../types'
import { compact, sum } from 'lodash'

export const makeReportTableValueGetter = (
  reportOrVersionId: string,
  period: string,
) =>
  function (
    params: Pick<
      ValueGetterParams<GenericReportTreeRow>,
      'data' | 'column' | 'node'
    >,
  ): Maybe<Partial<GenericReportTreeRowCellValue>> {
    const formula = params.data?.calculatedRow

    if (formula) {
      return calculatedRowValueGetter(
        formula,
        params,
        reportOrVersionId,
        period,
      )
    }

    const relevantValues = (params.data?.values || []).filter(
      (v: GenericReportValue) =>
        v.reportOrVersionId === reportOrVersionId && v._timestamp === period,
    )

    const finalValue = getCellValue(relevantValues)

    return {
      ...params.data,
      values: relevantValues,
      finalValue,
      toString: () => stringifyBlankOrMaskedValue(finalValue),
    }
  }

export const reportAggFunc = ({
  values,
}: {
  values: GenericReportTreeRow[]
}): Maybe<Partial<GenericReportTreeRowCellValue>> => {
  if (!values?.length) {
    return null
  }

  const compactValues = values
    .filter((value) => !value?.isCalculated)
    .reduce<GenericReportValue[]>(
      (acc, obj) => acc.concat(obj?.values || []),
      [],
    )

  const finalValue = getCellValue(compactValues)

  return {
    ...values[0],
    isCalculated: false,
    calculatedRow: undefined,
    values: compactValues,
    finalValue,
    toString: () => stringifyBlankOrMaskedValue(finalValue),
  }
}

export const makeTotalsReportAggFunc =
  ({
    lookupForColumnId,
  }: {
    lookupForColumnId: string
  }): IAggFunc<GenericReportTreeRow, GenericReportTreeRow> =>
  (params): Maybe<Partial<GenericReportTreeRowCellValue>> => {
    if (!params.values?.length) {
      return null
    }

    if (!isFooterRowNode(params.rowNode)) {
      return reportAggFunc(params)
    }

    const finalValue = getAggregatedValuePerColumnId(
      params.rowNode,
      lookupForColumnId,
    )

    return {
      ...params.values[0],
      isCalculated: false,
      calculatedRow: undefined,
      finalValue,
      toString: () => stringifyBlankOrMaskedValue(finalValue),
    }
  }

export const makeTotalsReportDiffAggFunc =
  ({
    lookupForColumnIdA,
    lookupForColumnIdB,
    defaultDiffAggFunc,
  }: {
    lookupForColumnIdA: string
    lookupForColumnIdB: string
    defaultDiffAggFunc: IAggFunc<GenericReportTreeRow>
  }): IAggFunc<GenericReportTreeRow, GenericReportTreeRow> =>
  (params): Maybe<Partial<GenericReportTreeRowCellValue>> => {
    if (!params.values?.length) {
      return null
    }

    if (!isFooterRowNode(params.rowNode)) {
      return defaultDiffAggFunc(params)
    }

    const finalValueA = getAggregatedValuePerColumnId(
      params.rowNode,
      lookupForColumnIdA,
    )

    const finalValueB = getAggregatedValuePerColumnId(
      params.rowNode,
      lookupForColumnIdB,
    )

    const finalValue = subtractWithBlanks(finalValueA, finalValueB)

    return {
      ...params.values[0],
      isCalculated: false,
      calculatedRow: undefined,
      finalValue,
      toString: () => stringifyBlankOrMaskedValue(finalValue),
    }
  }

export const makeReportTableDiffValueGetter =
  (
    aReportId: string,
    bReportId: string,
    period: string,
    operation: DiffOperation,
  ): ValueGetterFunc<GenericReportTreeRow> =>
  (
    params: ValueGetterParams<GenericReportTreeRow>,
  ): Maybe<Partial<GenericReportTreeRowCellValue>> => {
    const formula = params.data?.calculatedRow

    if (formula) {
      const columnIds =
        params.column
          .getParent()
          .getChildren()
          ?.map((col) => col.getUniqueId()) || []

      const colIdA = columnIds.find((c) => c.includes(aReportId))
      const colIdB = columnIds.find((c) => c.includes(bReportId))

      if (!colIdA || !colIdB) {
        return null
      }

      return calculatedRowDiffValueGetter(
        formula,
        params,
        colIdA,
        colIdB,
        operation,
        aReportId,
        bReportId,
        period,
      ) as BlankOrMaskedValue
    }

    const periodValues = (params.data?.values || []).filter(
      ({ _timestamp }: GenericReportValue) => _timestamp === period,
    )

    const relevantValuesA = periodValues.filter(
      ({ reportOrVersionId }) => reportOrVersionId === aReportId,
    )

    const relevantValuesB = periodValues.filter(
      ({ reportOrVersionId }) => reportOrVersionId === bReportId,
    )

    const a = getCellValue(relevantValuesA)
    const b = getCellValue(relevantValuesB)

    let finalValue: BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE

    try {
      // Handle divide by 0 case
      if (
        operation === 'percentage' &&
        (containsBlankValue(b) || typeof b !== 'number' || Math.round(b) === 0)
      ) {
        finalValue = INVALID_FORMULA_VALUE
      } else {
        finalValue =
          operation === 'subtract'
            ? subtractWithBlanks(a, b)
            : percentageWithBlanks(a, b)
      }
    } catch (ex) {
      if (ex instanceof MathDivideByZeroError) {
        finalValue = INVALID_FORMULA_VALUE
      } else {
        throw ex
      }
    }

    return {
      ...params.data,
      isCalculated: false,
      calculatedRow: undefined,
      values: periodValues.map((v) => ({
        ...v,
        uom: operation === 'percentage' ? 'percentage' : v.uom,
      })),
      finalValue,
      toString: () => stringifyBlankOrMaskedValue(finalValue),
    }
  }

export const makeReportTableDiffAggFunc =
  (
    markerA: string,
    markerB: string,
    markerField: keyof GenericReportValue,
    operation: 'subtract' | 'percentage',
  ): IAggFunc<GenericReportTreeRow> =>
  (
    params: IAggFuncParams<GenericReportTreeRow, GenericReportTreeRow>,
  ): Maybe<Partial<GenericReportTreeRowCellValue>> => {
    const values = params.values
      .filter((value) => !value?.calculatedRow)
      .reduce<GenericReportValue[]>(
        (acc, obj) => acc.concat(obj?.values || []),
        [],
      )

    const relevantValuesA: GenericReportValue[] = []
    const relevantValuesB: GenericReportValue[] = []

    values.forEach((value) => {
      if (value[markerField] === markerA) {
        relevantValuesA.push(value)
        return
      }

      if (value[markerField] === markerB) {
        relevantValuesB.push(value)
      }
    })

    const a = getCellValue(relevantValuesA)
    const b = getCellValue(relevantValuesB)

    let subtractedValue: BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE

    try {
      if (
        operation === 'percentage' &&
        (containsBlankValue(b) || typeof b !== 'number' || Math.round(b) === 0)
      ) {
        subtractedValue = INVALID_FORMULA_VALUE
      } else {
        subtractedValue =
          operation === 'subtract'
            ? subtractWithBlanks(a, b)
            : percentageWithBlanks(a, b)
      }
    } catch (ex) {
      if (ex instanceof MathDivideByZeroError) {
        subtractedValue = INVALID_FORMULA_VALUE
      } else {
        throw ex
      }
    }

    const finalValue =
      typeof subtractedValue === 'number'
        ? convertExponentialToDecimal(subtractedValue)
        : subtractedValue

    return {
      ...params.values[0],
      isCalculated: false,
      calculatedRow: undefined,
      values,
      finalValue,
      toString: () => stringifyBlankOrMaskedValue(finalValue),
    }
  }

export const convertExponentialToDecimal = (number: number): number =>
  /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)$/.test(number.toString())
    ? parseFloat(Number(number).toFixed(10))
    : number

// There is no better way to idenntify whether row node is actually footer line
const isFooterRowNode = (node: RowNode<unknown>): boolean =>
  node.level === -1 && node.getRoute() === undefined

const getAggregatedValuePerColumnId = (
  rootNode: RowNode<unknown>,
  columnId: string,
) => {
  const rowsAggregatedDataForRelevantColumn = compact(
    rootNode.childrenAfterFilter?.map(
      (row) => row?.aggData?.[columnId ?? ''] as GenericReportTreeRowCellValue,
    ) ?? [],
  )

  const finalValue =
    rowsAggregatedDataForRelevantColumn.length === 0
      ? BLANK_VALUE
      : sum(
          rowsAggregatedDataForRelevantColumn.map((i) =>
            typeof i?.finalValue === 'number' ? i?.finalValue : 0,
          ),
        )

  return finalValue
}
