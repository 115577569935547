import { forwardRef, memo } from 'react'
import { styled, Avatar, AvatarProps } from '@mui/material'

export const StyledAvatarRoot = styled(
  memo(
    forwardRef<HTMLDivElement, AvatarProps & { size: 'small' | 'medium' }>(
      ({ size, ...props }, ref) => <Avatar {...props} ref={ref} />,
    ),
  ),
)(({ theme, size }) => ({
  width: size === 'medium' ? 40 : 32,
  height: size === 'medium' ? 40 : 32,
  fontSize: size === 'medium' ? 18 : 14,
}))
