import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledVersionsListRoot = styled(Box)(({ theme }) => ({
  ...styledMixins.fullSize(),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}))

export const StyledVersionsListTitle = styled(Typography)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

export const StyledVersionsListQuickSearch = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
}))

export const StyledVersionsTable = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
}))
