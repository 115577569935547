import React, { useCallback } from 'react'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { ChangeDataTypeCallback } from './types'
import {
  StyledRoot,
  StyledTypeField,
  StyledCurrencyField,
} from './DataType.styled'
import { DataTypeField } from './DataTypeField'
import { CurrencyField } from './CurrencyField'
import { useDataContext } from '../data-context'
import { getDefaultCurrency } from './utils/getDefaultCurrency'
import { getCurrencyForDataType } from './utils/getCurrencyForDataType'
import { Maybe } from '@fintastic/shared/util/types'

export type DataTypeProps = {
  dataType: MetricConfigurableDataValueType
  currency: Maybe<string>
  onChange: ChangeDataTypeCallback
}

export const DataType: React.FC<DataTypeProps> = ({
  dataType,
  currency,
  onChange,
}) => {
  const { currenciesList } = useDataContext()

  const handleChangeDataType = useCallback(
    (newDataType: MetricConfigurableDataValueType) => {
      onChange(
        newDataType,
        getCurrencyForDataType(
          newDataType,
          currency,
          getDefaultCurrency(currenciesList),
        ),
      )
    },
    [currenciesList, currency, onChange],
  )

  const handleChangeCurrency = useCallback(
    (newCurrency: string) => {
      onChange(dataType, newCurrency)
    },
    [dataType, onChange],
  )

  return (
    <StyledRoot>
      <StyledTypeField>
        <DataTypeField value={dataType} onChange={handleChangeDataType} />
      </StyledTypeField>
      {dataType === 'currency' && currency !== undefined && (
        <StyledCurrencyField>
          <CurrencyField value={currency} onChange={handleChangeCurrency} />
        </StyledCurrencyField>
      )}
    </StyledRoot>
  )
}
