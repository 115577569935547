import dayjs from 'dayjs'
import {
  CommentEventOut,
  CommentThreadOut,
  CommentsThreadsBulkCommentItem,
  CommentsThreadsBulkItem,
} from '../types'
import { AxiosResponse } from 'axios'

export const newBulkComment = (
  body: string,
  author_id: string,
): CommentsThreadsBulkCommentItem => ({
  id: NEW_EVENT_MOCK_ID,
  author_id,
  created_at: dayjs().format(),
  body,
})

export const newBulkThread = (
  {
    thread,
    event,
  }: {
    thread: AxiosResponse<CommentThreadOut>
    event: AxiosResponse<CommentEventOut>
  },
  body: string,
  author_id: string,
  input: Pick<
    CommentsThreadsBulkItem,
    | 'label_ids'
    | 'page_key'
    | 'object_key'
    | 'object_title'
    | 'page_title'
    | 'url'
  >,
): CommentsThreadsBulkItem => ({
  ...input,
  id: thread.data.id,
  is_public: true,
  is_resolved: false,
  comments: [
    {
      body,
      author_id,
      id: event.data.id,
      created_at: dayjs().format(),
    },
  ],
  mentioned_users: [],
})

const NEW_EVENT_MOCK_ID = 99999999888
