import { axios } from '@fintastic/web/data-access/service-axios'
import { VersionAccess } from '@fintastic/web/util/versions'

const endpoints = {
  versionAccess: (id: string) => `/planning/api/v2/versions/${id}/access/`,
} as const

export async function getVersionAccess(id: string) {
  return axios.get<VersionAccess>(endpoints.versionAccess(id))
}

export async function updateVersionAccess(id: string, access: VersionAccess) {
  return axios.put(endpoints.versionAccess(id), access)
}
