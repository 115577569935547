/**
 Keep here the hooks attached to the current duck.
 In perfect case there should be only one way for components to interact with a duck - it's thought the hooks.
 Our hooks it's like a public API for components, and they shouldn't use selectors or action creators directly.
 */

import {
  selectCurrentHistoryContext,
  selectIsHistoryOpened,
  setCurrentHistoryContext,
  setIsHistoryOpened,
} from './index'
import { useDispatch, useSelector } from 'react-redux'
import type {
  SetCurrentCurrentHistoryContextPayload,
  SetIsHistoryOpenedPayload,
} from './reducers'
import { useCallback } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

import { AuditLogEntityContext } from '@fintastic/web/util/audit-log'

export function useLayoutStateIsHistoryOpened(): [
  boolean,
  (payload: SetIsHistoryOpenedPayload) => void,
] {
  const dispatch = useDispatch()
  const isOpened = useSelector(selectIsHistoryOpened)

  const setHandler = useCallback(
    (payload: SetIsHistoryOpenedPayload) => {
      dispatch(setIsHistoryOpened(payload))
    },
    [dispatch],
  )

  return [isOpened, setHandler]
}

export function useCurrentHistoryContext(): [
  Maybe<AuditLogEntityContext>,
  (payload: SetCurrentCurrentHistoryContextPayload) => void,
] {
  const dispatch = useDispatch()
  const currentHistoryContext = useSelector(selectCurrentHistoryContext)

  const setHandler = useCallback(
    (payload: SetCurrentCurrentHistoryContextPayload) => {
      const entityName = payload?.entityName || payload?.entityId || ''

      const processedPayload = payload ? { ...payload, entityName } : payload

      dispatch(setCurrentHistoryContext(processedPayload))
    },
    [dispatch],
  )

  return [currentHistoryContext, setHandler]
}
