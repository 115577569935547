import { styled } from '@mui/material/styles'
import React from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

export const StyledVersionLine = styled(
  (props: { disabled?: boolean } & BoxProps) => {
    const { disabled, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'disabled',
  },
)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;

  label {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary};
    transition: all 0.2s ease;
  }

  svg {
    opacity: ${({ theme, disabled }) => (disabled ? 0.3 : 1)};
    transition: all 0.2s ease;
  }
`

export const StyledVersionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: bold !important;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex-flow: row nowrap;
  margin-bottom: ${({ theme }) => theme.spacing(1.7)};
  padding: 16px 16px 0 16px;
`

export const StyledVersionCounter = styled('span')`
  font-weight: bold;
  margin-left: auto;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const StyledSearchInputContainer = styled(Box)`
  padding: 0 16px ${({ theme }) => theme.spacing(1.7)} 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`

export const StyledVersionEmpty = styled('div')`
  font-weight: normal;
  margin: ${({ theme }) => theme.spacing(1)} 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`
export const StyledVersionName = styled('span')`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  span {
    margin-left: 0.4em;
  }
`
