import React, { useMemo } from 'react'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { FlagsContext, FlagsContextValue } from './flags-context'
import { StyledRoot } from './ValueDataFormatSettings.styled'
import { Formatting } from './formatting'
import { ChangeDataTypeCallback } from './data-type/types'
import {
  ChangeCurrencySignPositionCallback,
  ChangeFormattingCallback,
} from './formatting/types'
import { DataType } from './data-type'
import { DataContextValue, DataContext } from './data-context'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricOrListEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { MetricDisplaySettingsWrapper } from '@fintastic/web/data-access/metrics-and-lists'

export type ValueDataFormatSettingsProps = {
  dataType: MetricConfigurableDataValueType
  displaySettings: MetricDisplaySettingsWrapper
  onChangeDataType: ChangeDataTypeCallback
  onChangeFormatting: ChangeFormattingCallback
  readonly?: boolean
  currenciesList: Currency[]
  flow: Maybe<MetricOrListEditingFlow>
  onChangeCurrencySignPosition: ChangeCurrencySignPositionCallback
  allowDataTypeConversions: boolean
}

export const ValueDataFormatSettings: React.FC<
  ValueDataFormatSettingsProps
> = ({
  dataType,
  displaySettings,
  readonly = false,
  onChangeFormatting,
  onChangeDataType,
  currenciesList,
  flow,
  onChangeCurrencySignPosition,
  allowDataTypeConversions,
}) => {
  const flagsContextValue = useMemo<FlagsContextValue>(
    () => ({
      readonly,
    }),
    [readonly],
  )

  const dataContextValue = useMemo<DataContextValue>(
    () => ({
      currenciesList,
      flow,
      allowDataTypeConversions,
    }),
    [currenciesList, flow, allowDataTypeConversions],
  )

  return (
    <FlagsContext.Provider value={flagsContextValue}>
      <DataContext.Provider value={dataContextValue}>
        <StyledRoot>
          <DataType
            dataType={dataType}
            onChange={onChangeDataType}
            currency={displaySettings.currency()}
          />
          <Formatting
            dataType={dataType}
            onChange={onChangeFormatting}
            onChangeCurrencySignPosition={onChangeCurrencySignPosition}
            displaySettings={displaySettings}
          />
        </StyledRoot>
      </DataContext.Provider>
    </FlagsContext.Provider>
  )
}
