import type { QueryParams } from './types'
import { useWeightsMetricsQueries } from './useWeightsMetricsQueries'
import { useMemo } from 'react'
import type { MetricDataWithVersionId } from '../../types'

// @todo add tests
export function useLoadWeightMetrics(
  params: QueryParams[],
  enabled: boolean,
): {
  isLoading: boolean
  isFetching: boolean
  metrics: MetricDataWithVersionId[]
} {
  const queries = useWeightsMetricsQueries(params, enabled)

  return useMemo(
    () => ({
      isLoading: queries.some((q) => q.isLoading),
      isFetching: queries.some((q) => q.isFetching),
      metrics: queries.map((query, queryIndex) => ({
        version: params[queryIndex].version,
        metric: query.data || null,
      })),
    }),
    [params, queries],
  )
}
