import React, { useCallback, useMemo, useState } from 'react'
import {
  FormField,
  FormLayout,
  TextField,
  useTextFieldState,
} from '@fintastic/shared/ui/form-framework'
import { useBoardFolderNameValidation } from '../../../hooks/useBoardFolderNameValidation'
import { useBoardCreateMutation } from '../../../hooks/useBoardCreateMutation'
import { Board, BoardConfig } from '../../../types'
import { useBoardAndFolderOpen } from '../../../hooks/useBoardAndFolderOpen'
import { useBoardNameValidation } from '../../../hooks/useBoardNameValidation'
import { BoardNewSelectFolder } from './BoardNewSelectFolder'
import { Typography } from '@mui/material'
import { toast } from '@fintastic/shared/ui/toast-framework'

export const noFolderId = '__none__'
export const newFolderId = '__new__'

export type NewBoardFolderId =
  | typeof noFolderId
  | typeof newFolderId
  | Board['id']

export type BoardCreateFormProps = {
  closeParentModal?: () => void
}

export const BoardNewForm: React.FC<BoardCreateFormProps> = (props) => {
  const { closeParentModal } = props

  const boardNameFieldState = useTextFieldState()
  const folderNameFieldState = useTextFieldState()

  const [folderId, setFolderId] = useState(noFolderId)

  const boardNameValidation = useBoardNameValidation({
    boardName: boardNameFieldState.trimmedValue,
  })

  const folderNameValidation = useBoardFolderNameValidation({
    folderName: folderNameFieldState.trimmedValue,
  })

  const handleSelectFolderType = useCallback((folderId: string) => {
    setFolderId(folderId)
  }, [])

  const isNewFolder = folderId === newFolderId
  const isNoFolder = folderId === noFolderId

  const isEveryFieldFilled =
    boardNameFieldState.value.length > 0 &&
    boardNameValidation.isValid &&
    (isNewFolder ? folderNameFieldState.trimmedValue.length > 0 : true)

  const isSubmitEnabled =
    isEveryFieldFilled && (isNewFolder ? folderNameValidation.isValid : true)

  const boardCreateMutation = useBoardCreateMutation()

  const isSending = boardCreateMutation.isLoading
  const [busy, setBusy] = useState(false)

  const submitErrorMessage = useMemo(() => {
    if (!boardCreateMutation.error) {
      return null
    }
    console.error(boardCreateMutation.error)
    return "Can't create a new board"
  }, [boardCreateMutation.error])

  const openBoardAndFolder = useBoardAndFolderOpen()

  const handleSubmit = useCallback(async () => {
    setBusy(() => true)

    try {
      await boardCreateMutation.mutateAsync(
        {
          name: boardNameFieldState.value,
          config: {} as BoardConfig,
          newFolderName: isNewFolder
            ? folderNameFieldState.trimmedValue
            : undefined,
          folder_id: isNewFolder
            ? undefined
            : isNoFolder
            ? undefined
            : folderId,
        },
        {
          onSuccess: (data) => {
            if (closeParentModal) {
              closeParentModal()
            }
            // start design mode
            openBoardAndFolder(data.id, data.folder_id || undefined, true)
            toast.success(
              'New Board has been created. You can start design now!',
            )
          },
        },
      )
    } catch (e) {
      console.log(e)
      toast.error("Can't create a new board")
    }
    setBusy(() => false)
  }, [
    boardCreateMutation,
    boardNameFieldState.value,
    closeParentModal,
    folderNameFieldState.trimmedValue,
    folderId,
    openBoardAndFolder,
  ])

  return (
    <FormLayout
      submitEnabled={!isSending && isSubmitEnabled && !busy}
      showLoader={isSending}
      submitButtonText="Continue"
      submitErrorMessage={submitErrorMessage}
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
    >
      <TextField
        label="Name"
        fullWidth
        placeholder="Name"
        autoFocus={true}
        disabled={busy}
        value={boardNameFieldState.value}
        onChange={boardNameFieldState.setValue}
        error={
          boardNameFieldState.touched ? !boardNameValidation.isValid : false
        }
        errorMessage={
          boardNameFieldState.touched ? boardNameValidation.error : undefined
        }
      />

      <FormField fullWidth={true}>
        <BoardNewSelectFolder
          value={folderId}
          disabled={busy}
          onSelect={handleSelectFolderType}
        />
      </FormField>

      {folderId === newFolderId && (
        <TextField
          label="Folder name"
          placeholder="Folder name"
          disabled={busy}
          fullWidth
          value={folderNameFieldState.value}
          onChange={folderNameFieldState.setValue}
          error={
            folderNameFieldState.touched ? !folderNameValidation.isValid : false
          }
          errorMessage={
            folderNameFieldState.touched
              ? folderNameValidation.error
              : undefined
          }
        />
      )}
      <Typography variant={'body2'} mb={2}>
        The new Board will be visible to you and to power users only. You can
        share the Board from the Boards panel at any time.
      </Typography>
    </FormLayout>
  )
}
