import { ColDef } from 'ag-grid-community'

export type ColDefBuilderFunction<Params = unknown> = (
  columnDefinition: ColDef,
  params: Params,
) => ColDef

export type ColDefBuilderMatcher<Params = unknown> = (params: Params) => boolean

export type ColDefBuilderMatch<Params = unknown> = {
  matcher: ColDefBuilderMatcher<Params>
  builder: ColDefBuilderFunction<Params>
}

export class AgGridColDefBuilder<Params = unknown> {
  private matches: ColDefBuilderMatch<Params>[]

  constructor(matches: ColDefBuilderMatch<Params>[]) {
    this.matches = matches
  }

  private matchBuilders(params: Params): ColDefBuilderFunction<Params>[] {
    return this.matches
      .filter((match) => match.matcher(params))
      .map((match) => match.builder)
  }

  public build(params: Params): ColDef {
    return this.matchBuilders(params).reduce(
      (currentColDef, builder) => builder(currentColDef, params),
      {},
    )
  }
}
