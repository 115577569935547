import { getLocalStorageValue, prefixKey } from '@fintastic/shared/util/hooks'
import { useLocalStorage } from 'usehooks-ts'

import { Maybe } from '@fintastic/shared/util/types'
import { environment } from '@fintastic/web/data-access/environment'
import { useMemo } from 'react'

export const TENANT_OVERRIDE_KEY = 'tenant-override-key'
export const TENANT_OVERRIDE_NAME = 'tenant-override'

export const getTenantOverrideParams = (): {
  tenantName: string
  tenantKey: string
} => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const tenantName = params.get(TENANT_OVERRIDE_NAME) || ''
  const tenantKey =
    params.get(TENANT_OVERRIDE_KEY) ||
    environment.REACT_APP_TENANT_OVERRIDE_LOCAL_DEV_KEY ||
    ''
  return {
    tenantName,
    tenantKey,
  }
}

type UseTenantOverride = {
  tenantName: Maybe<string>
  tenantKey: Maybe<string>
}

export const useTenantOverride = (): UseTenantOverride => {
  const [tenantNameLocal] = useLocalStorage(TENANT_OVERRIDE_NAME, '')
  const [tenantKeyLocal] = useLocalStorage(prefixKey(TENANT_OVERRIDE_KEY), '')
  const { tenantName, tenantKey } = getTenantOverrideParams()

  return useMemo(
    () => ({
      tenantName: tenantName || tenantNameLocal || tenantKey || tenantKeyLocal,
      tenantKey,
    }),
    [tenantKey, tenantKeyLocal, tenantName, tenantNameLocal],
  )
}

export const getTenantOverrideHeader = (): Record<string, string> => {
  const tenant = getLocalStorageValue(TENANT_OVERRIDE_KEY)

  if (tenant) {
    return {
      'X-FINTASTIC-TENANT-OVERRIDE': tenant,
    }
  }

  return {}
}
