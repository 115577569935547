import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TextField, Box, Typography, Button } from '@mui/material'
import { Board } from '../../../types'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'
import { useBoardUpdateMutation } from '../../../hooks/useBoardUpdateMutation'
import { QueryErrorToast } from '@fintastic/shared/ui/components'

export type ConfigEditorProps = {
  board: Board
}

export const ConfigEditor: React.FC<ConfigEditorProps> = ({ board }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(JSON.stringify(board.config, undefined, 2))
  }, [board.config])

  const isValid = useMemo(() => {
    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  }, [value])

  const updateBoardMutation = useBoardUpdateMutation({
    boardId: board.id,
  })
  const updateBoardErrorMessageGetter = useCallback(
    () => "Can't update the board config. Something went wrong.",
    [],
  )

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback((event) => {
    setValue(event.target.value)
  }, [])

  const handleReset = useCallback(() => {
    setValue(JSON.stringify(board.config, undefined, 2))
  }, [board.config])

  const handleSave = useCallback(() => {
    updateBoardMutation.mutate({
      config: JSON.parse(value),
    })
  }, [updateBoardMutation, value])

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            ...sxMixins.alignContentCenter('row'),
            mb: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mr: 'auto',
            }}
          >
            Config editor:
          </Typography>
          <Button onClick={handleReset} sx={{ ml: 1 }} variant="contained">
            Reset to defaults
          </Button>
          <Button
            onClick={handleSave}
            sx={{ ml: 1 }}
            variant="contained"
            disabled={!isValid || updateBoardMutation.isLoading}
          >
            {updateBoardMutation.isLoading ? 'Updating...' : 'Save changes'}
          </Button>
        </Box>
        <TextField
          fullWidth
          multiline
          value={value}
          onChange={handleChange}
          sx={{
            textarea: {
              fontFamily: 'monospace',
            },
          }}
        />
      </Box>
      <QueryErrorToast
        error={updateBoardMutation.error as Error}
        errorMessageGetter={updateBoardErrorMessageGetter}
      />
    </>
  )
}
