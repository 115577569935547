import React from 'react'
import { ArrowBackIosNew } from '@fintastic/shared/ui/icons'

export const FilterCollapseStatus: React.FC<{ collapsed: boolean }> = ({
  collapsed,
}) => {
  if (collapsed) {
    return <ArrowBackIosNew style={{ transform: 'rotate(-90deg)' }} fontSize="small" />
  }

  return <ArrowBackIosNew style={{ transform: 'rotate(-180deg)' }} fontSize="small" />
}

FilterCollapseStatus.displayName = 'FilterCollapseStatus'
