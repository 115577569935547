import React, { FC, forwardRef, memo } from 'react'
import { AgGridCellEditorProps } from '@fintastic/shared/ui/ag-grid'
import { StyledRoot, StyledSelector } from './CellEditorWrapper.styled'
import { useAggregatedTimeCellEditorContext } from './cell-editor-context'
import { ActionSelector } from './ActionSelector'
import { MetricDataFillStrategy } from '@fintastic/web/util/metric-data-editing'

export const wrapCellEditor = (
  CellEditor: FC<AgGridCellEditorProps>,
  disabledActions: MetricDataFillStrategy[],
) =>
  memo(
    forwardRef<unknown, AgGridCellEditorProps>((props, ref) => {
      const { onChangeAction, action } = useAggregatedTimeCellEditorContext()

      const isFirstLine = props.rowIndex === 0

      return (
        <StyledRoot>
          <CellEditor {...props} ref={ref} />
          <StyledSelector
            sx={{
              top: !isFirstLine ? 'auto' : 'calc(100% + 1px)',
              bottom: isFirstLine ? 'auto' : 'calc(100% + 1px)',
            }}
          >
            <ActionSelector
              value={action}
              onChange={onChangeAction}
              disabledActions={disabledActions}
            />
          </StyledSelector>
        </StyledRoot>
      )
    }),
  )
