import { partial, uniq } from 'lodash'
import { postEventIn } from '../api/api'
import { CommentEventIn } from '../types'
import { useUserListQuery } from '@fintastic/web/data-access/iam'
import { useCallback } from 'react'

const extractEmails = (text: string) =>
  text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) || []

export const usePostEventWithMentions = () => {
  const userList = useUserListQuery()

  return useCallback(
    async (threadId: number, eventIn: CommentEventIn) => {
      if (eventIn.type === 'comment' && eventIn.body) {
        const mentions = uniq(extractEmails(eventIn.body))

        if (mentions?.length) {
          const mentionEvents = mentions
            .filter((mention) =>
              userList.data?.find((user) => user.username === mention),
            )
            .map<CommentEventIn>((mention) => ({
              type: 'mention',
              user_id: mention,
              body: '',
            }))

          // Post mentions
          await Promise.all(mentionEvents.map(partial(postEventIn, threadId)))
        }
      }

      // Post other events
      return postEventIn(threadId, eventIn)
    },
    [userList.data],
  )
}
