import { Maybe } from '@fintastic/shared/util/types'
import { Token } from '../../tokens/types'
import { isLeftParenthesis } from '../../tokens-utils/isLeftParenthesis'
import { isComma } from '../../tokens-utils/isComma'
import { isRightParenthesis } from '../../tokens-utils/isRightParenthesis'
import { isFunction } from '../../tokens-utils/isFunction'

export function getCurrentFunction(
  tokens: Token[],
  currentTokenIndex: number,
): Maybe<[Token, number]> {
  const token = tokens[currentTokenIndex]
  if (!token) {
    return null
  }

  // optimisation by requirements - we don't want to show helper if caret not at comma or open parenthesis
  if (!isLeftParenthesis(token) && !isComma(token)) {
    return null
  }

  // trying to find the closest left function that has unclosed open parenthesis
  let checkingTokenIndex = currentTokenIndex
  const parenthesisStack: string[] = []

  while (currentTokenIndex >= 0) {
    const checkingToken = tokens[checkingTokenIndex]
    checkingTokenIndex -= 1
    if (checkingToken === undefined) {
      return null
    }

    if (isLeftParenthesis(checkingToken) || isRightParenthesis(checkingToken)) {
      if (
        parenthesisStack.length > 0 &&
        parenthesisStack[parenthesisStack.length - 1] !== checkingToken.text
      ) {
        parenthesisStack.splice(parenthesisStack.length - 1, 1)
        continue
      }
      parenthesisStack.push(checkingToken.text)
    }

    if (
      isFunction(checkingToken) &&
      parenthesisStack.length > 0 &&
      parenthesisStack[parenthesisStack.length - 1] === '(' &&
      parenthesisStack[parenthesisStack.length - 2] !== '('
    ) {
      return [checkingToken, checkingTokenIndex + 1]
    }
  }

  return null
}
