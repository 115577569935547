import { ValidationError } from '@fintastic/web/data-access/formulas'
import { getErrorCode } from './getErrorCode'
import { ErrorCode, ERROR_CODES } from './error-codes'
import { formatErrorMessage } from './formatErrorMessage'

const messagesMap: Record<ErrorCode, string> = {
  [ERROR_CODES.MISSING_MANDATORY_ARGUMENT]: 'Missing mandatory argument',
  [ERROR_CODES.FORMULA_STRUCTURAL_ERROR]: 'Formula structural error',
  [ERROR_CODES.UNRECOGNIZED_ARG_OR_FUNCTION]:
    'Unrecognized argument or function',
  [ERROR_CODES.GENERIC_ERROR]: 'Formula error',
}

export function getErrorMessage(validationError: ValidationError): string {
  const code = getErrorCode(validationError)
  return formatErrorMessage(getErrorCode(validationError), messagesMap[code])
}
