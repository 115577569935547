import React from 'react'
import { createSvgIcon } from '@mui/material'

export const FiltersIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.91965 4.48433C3.04254 4.21946 3.30799 4.04999 3.59999 4.04999H20.4C20.692 4.04999 20.9574 4.21946 21.0803 4.48433C21.2032 4.7492 21.1612 5.06132 20.9727 5.28428L14.43 13.021V19.92C14.43 20.1799 14.2954 20.4213 14.0743 20.558C13.8532 20.6946 13.5771 20.7071 13.3446 20.5908L9.98458 18.9108C9.73049 18.7838 9.56999 18.5241 9.56999 18.24V13.021L3.02731 5.28428C2.83876 5.06132 2.79675 4.7492 2.91965 4.48433ZM5.21646 5.54999L10.8927 12.2621C11.0072 12.3975 11.07 12.5691 11.07 12.7464V17.7765L12.93 18.7065V12.7464C12.93 12.5691 12.9928 12.3975 13.1073 12.2621L18.7835 5.54999H5.21646Z"
    fill="currentColor"
  />,
  'Filters',
)
