import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import {
  useReadDeeplinkValue,
  useSyncDeeplinkValue,
} from '@fintastic/web/util/deeplink'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type {
  GenericReportTreeRowCellValue,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import type { RangeSelectionChangedEvent } from 'ag-grid-community/dist/lib/events'
import type { ColumnApi, GridApi } from 'ag-grid-community'
import {
  useAllThreadsBulk,
  useBoardId,
} from '@fintastic/web/data-access/comments'
import { parseObjectId } from '../utils/parse-object-id'
import {
  generateDimensionValue,
  isFooterDimensionValue,
} from '../utils/generate-dimension-value'
import { isEqual, slice } from 'lodash'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { getAgGridRootNode } from '@fintastic/shared/ui/ag-grid'
import { generateFieldId } from '../utils/field-id'
import { useResetThreadId } from '@fintastic/web/data-access/comments'

const deeplinkDefaultValue: ClickedCellUrl = []

type ClickedCellUrl = [cellId: string, rowIndex: number] | []

export const useDeeplinkRangeSelection = (
  deeplinkWidgetId: string,
  gridRef: React.RefObject<AgGridReactType<GenericReportTreeRowCellValue>>,
  onRangeSelectionApplied: ({
    api,
    columnApi,
  }: {
    api: GridApi<GenericReportTreeRowCellValue>
    columnApi: ColumnApi
  }) => void,
  dimensions: SortableDimension[],
) => {
  const [clickedCellUrl, setClickedCellUrl] =
    useSyncDeeplinkValue<ClickedCellUrl>({
      key: `w${deeplinkWidgetId}_cell`,
      defaultValue: deeplinkDefaultValue,
    })

  const boardId = useBoardId()
  const resetThreadId = useResetThreadId()
  const threadId = useReadDeeplinkValue<number>('thread_id')
  const { data: threads } = useAllThreadsBulk('board' + boardId, false)

  const versionsQuery = useLoadVersionsList({ showArchived: false })

  const selectedObjectKey = useMemo(() => {
    if (!threadId) {
      return undefined
    }
    return threads?.find((t) => t.id === threadId)?.object_key
  }, [threadId, threads])

  const [currentWidgetInUrl, setCurrentWidgetInUrl] =
    useSyncDeeplinkValue<string>({
      key: 'widget',
      defaultValue: '',
    })

  const selectedObjectKeyRef = useRef('')
  const toastShowedForObjectRef = useRef('')
  const toastShowedForObjectResetTimeoutRef = useRef<NodeJS.Timeout>()

  const handleRangeSelectionChange = useCallback(
    ({
      api,
      columnApi,
      finished,
      started,
    }: RangeSelectionChangedEvent<GenericReportTreeRowCellValue>) => {
      setCurrentWidgetInUrl(deeplinkWidgetId)
      const range = api.getCellRanges()?.[0]

      if (!range || !range.startColumn) {
        return
      }

      selectedObjectKeyRef.current = ''
      toastShowedForObjectRef.current = ''

      setClickedCellUrl([
        range.startColumn.getColId() || '',
        range.startRow?.rowIndex || 0,
      ])

      const nonAPICellRangeChange = finished && started
      if (nonAPICellRangeChange) {
        resetThreadId()
      }

      onRangeSelectionApplied?.({ api, columnApi })
    },
    [
      onRangeSelectionApplied,
      deeplinkWidgetId,
      setClickedCellUrl,
      setCurrentWidgetInUrl,
      resetThreadId,
    ],
  )

  // Find row index by combination of the dimensions
  const getRowIndexBySelectedObjectKey = useCallback(
    (key?: string) => {
      if (!gridRef.current?.api || !key) {
        return { threadRowIndex: undefined, parsed: null }
      }
      let threadRowIndex: number | undefined = undefined

      const parsed = parseObjectId(key)

      const lookingForFooter = isFooterDimensionValue(parsed?.dimensions ?? {})
      if (lookingForFooter) {
        const rootNode = getAgGridRootNode(gridRef.current.api)
        const rootSiblingRowIndex = rootNode?.sibling?.rowIndex

        return {
          threadRowIndex:
            typeof rootSiblingRowIndex === 'number'
              ? rootSiblingRowIndex
              : undefined,
          parsed,
        }
      }

      gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
        if (typeof threadRowIndex === 'number') {
          return
        }

        const generatedDimensions = Object.fromEntries(
          generateDimensionValue(rowNode, dimensions),
        )

        if (isEqual(parsed.dimensions, generatedDimensions)) {
          if (rowNode.rowIndex === null) {
            return
          }
          threadRowIndex = rowNode.rowIndex
        }
      })

      return { threadRowIndex, parsed }
    },
    [dimensions, gridRef],
  )

  const [syncWithUrlInProgress, setSyncWithUrlInProgress] = useState(true)

  const showErrorToast = useCallback(
    (message: string) => {
      if (!selectedObjectKey) {
        return
      }

      if (toastShowedForObjectRef.current === selectedObjectKey) {
        return
      }

      toastShowedForObjectRef.current = selectedObjectKey
      toast.error(message)
      gridRef.current?.api.clearRangeSelection()
      resetThreadId()
      if (toastShowedForObjectResetTimeoutRef.current) {
        clearTimeout(toastShowedForObjectResetTimeoutRef.current)
      }

      // Do now show the same error for 3sec
      toastShowedForObjectResetTimeoutRef.current = setTimeout(() => {
        toastShowedForObjectRef.current = ''
      }, 3000)
    },
    [gridRef, selectedObjectKey, resetThreadId],
  )

  useEffect(
    () => () => {
      clearTimeout(toastShowedForObjectResetTimeoutRef.current)
    },
    [],
  )

  const addCellRange = useCallback(() => {
    if (!gridRef.current?.api || !selectedObjectKey) {
      return
    }

    const [column] = clickedCellUrl ?? []
    const { threadRowIndex, parsed } =
      getRowIndexBySelectedObjectKey(selectedObjectKey)

    if (
      parsed?.deeplinkWidgetId !== deeplinkWidgetId &&
      currentWidgetInUrl !== deeplinkWidgetId
    ) {
      return
    }

    setCurrentWidgetInUrl(parsed?.deeplinkWidgetId ?? deeplinkWidgetId)

    const columnInfo =
      (parsed &&
        gridRef.current.columnApi.getColumn(
          generateFieldId(parsed?.version ?? '', parsed?.period ?? ''),
        )) ||
      (column && gridRef.current.columnApi.getColumn(column))

    if (!columnInfo) {
      const missingVersion =
        versionsQuery?.data?.length &&
        !versionsQuery.data.find((v) => v.uuid === parsed?.version)
      if (missingVersion) {
        showErrorToast(errorMessages.versionDoesNotExists)
      } else {
        showErrorToast(errorMessages.unableToLocateCell)
      }
      return
    }

    if (typeof threadRowIndex !== 'number') {
      const currentDimensionKeys = Object.keys(parsed?.dimensions || {})
      const dimensionNames = slice(
        dimensions.map((i) => i.name),
        0,
        currentDimensionKeys.length,
      )
      const hasMissingDimension =
        currentDimensionKeys.find((dim) => !dimensionNames.includes(dim)) &&
        !isFooterDimensionValue(parsed?.dimensions ?? {})

      showErrorToast(
        hasMissingDimension
          ? errorMessages.dimensionDoesNotExists
          : errorMessages.unableToLocateCell,
      )
      return
    }

    const range = gridRef.current.api.getCellRanges()?.[0]

    // Do not apply the same range
    if (
      range?.columns?.[0]?.getColId?.() === columnInfo.getColId() &&
      range.startRow?.rowIndex === threadRowIndex &&
      typeof threadRowIndex === 'number'
    ) {
      return
    }

    gridRef.current.api.clearRangeSelection()

    gridRef.current.api.addCellRange({
      columns: [columnInfo.getColId()],
      rowStartIndex: threadRowIndex,
      rowEndIndex: threadRowIndex,
    })

    gridRef.current.api.ensureColumnVisible(columnInfo.getColId())

    const node = gridRef.current?.api.getDisplayedRowAtIndex(threadRowIndex)
    if (node) {
      gridRef.current.api.ensureNodeVisible(node)
    }

    onRangeSelectionApplied?.({
      api: gridRef.current?.api,
      columnApi: gridRef.current?.columnApi,
    })
  }, [
    clickedCellUrl,
    currentWidgetInUrl,
    deeplinkWidgetId,
    dimensions,
    getRowIndexBySelectedObjectKey,
    gridRef,
    onRangeSelectionApplied,
    selectedObjectKey,
    setCurrentWidgetInUrl,
    showErrorToast,
    versionsQuery.data,
  ])

  // Apply range selection when URL changes
  useEffect(() => {
    if (!gridRef.current?.api) {
      return
    }

    if (syncWithUrlInProgress || !dimensions.length) {
      return
    }

    if (
      !selectedObjectKey ||
      selectedObjectKey === selectedObjectKeyRef.current
    ) {
      return
    }

    selectedObjectKeyRef.current = selectedObjectKey

    const t = setTimeout(() => {
      addCellRange()
    }, ANIMATION_FINISH_THRESHOLD)

    return () => {
      clearTimeout(t)
    }
  }, [
    addCellRange,
    clickedCellUrl,
    currentWidgetInUrl,
    deeplinkWidgetId,
    dimensions,
    getRowIndexBySelectedObjectKey,
    gridRef,
    selectedObjectKey,
    syncWithUrlInProgress,
  ])

  const handleSyncWithUrlStarted = useCallback(() => {
    setSyncWithUrlInProgress(true)
  }, [])

  const handleFirstDataRendered = useCallback(() => {
    // Need to wait for animation to finish :(
    setTimeout(() => {
      setSyncWithUrlInProgress(false)

      addCellRange()
    }, ANIMATION_FINISH_THRESHOLD)
  }, [addCellRange])

  return {
    handleFirstDataRendered,
    handleRangeSelectionChange,
    handleSyncWithUrlStarted,
  }
}

const ANIMATION_FINISH_THRESHOLD = 300

const errorMessages = {
  unableToLocateCell:
    'You are unable to view the cell linked to this comment due to your permissions settings or changes made to the report structure',
  versionDoesNotExists:
    'The comment relates to a version that was deleted or archived',
  dimensionDoesNotExists:
    'You are unable to view the cell linked to this comment as the dimentional structure has changed',
}
