import { styled } from '@mui/material'
import { styleKit } from '@fintastic/shared/ui/mui-theme'

export const StyledAddLinesGridRoot = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  overflow: auto;

  .add-lines-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.01;
    z-index: 1000;
  }

  // @todo: decide do we need that

  .ag-header-cell.calculated-column {
    background-color: ${styleKit.colors.general.notEditable};
  }
`
