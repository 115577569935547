import { useModalState } from '@fintastic/shared/util/modal'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { useMemo } from 'react'
import { ExitSettingsEditingModal } from './ExitSettingsEditingModal'
import { useStopEditingHandler } from './useStopEditingHandler'

export function useExitSettingsEditingMode(versionId: string) {
  const modalState = useModalState()
  const { type } = useCurrentEditingFlow()
  const isMetric = type === 'metric'
  const stopEditingHandler = useStopEditingHandler(versionId)

  return useMemo(
    () => ({
      open: modalState.open,
      popup: (
        <ExitSettingsEditingModal
          onConfirm={stopEditingHandler}
          onRequestClose={modalState.close}
          isMetric={isMetric}
          isOpen={modalState.isOpen}
        />
      ),
    }),
    [
      isMetric,
      modalState.close,
      modalState.isOpen,
      modalState.open,
      stopEditingHandler,
    ],
  )
}
