import React, { useEffect, useMemo, useRef } from 'react'
import { FormulaTab } from './types'
import {
  FormulaValidationCallback,
  Version as FormulaVersion,
  Version,
} from '@fintastic/web/feature/formulas'
import { ListGridColumnFormulaEditorWrapper } from './ListGridColumnFormulaEditorWrapper'
import { ListGridFormulaEditorWrapper } from './ListGridFormulaEditorWrapper'
import { Maybe } from '@fintastic/shared/util/types'
import { isEqual } from 'lodash'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import {
  ColumnFormulaError,
  ListColumnWrapper,
} from '@fintastic/web/data-access/metrics-and-lists'
import { ClientOnlyMapping } from '@fintastic/web/util/formulas'

export type FormulaEditorRouterProps = {
  tab: Maybe<FormulaTab>
  column: Maybe<string>
  versionId: Maybe<string>
  versionEditable?: boolean
  listFormula?: Maybe<string>
  controlledFormulaErrorForListItself: Maybe<string>
  controlledFormulaErrorForColumns: Maybe<ColumnFormulaError[]>
  columns: Record<string, Metric>
  onRequestClose: () => void
  listConnectedTable: Version['connectedTable']
  formulaEditorTitle?: string
  controlledMode?: boolean
  readonly?: boolean
  clientOnlyMapping: Maybe<ClientOnlyMapping>
  isSettingsEditingActive?: boolean
  onFormulaValidationFinished?: FormulaValidationCallback
  onColumnFormulaValidationFinished?: (
    columnId: string,
    formula: string,
    invalid?: boolean,
  ) => void
}

// @todo add tests
export const FormulaEditorRouter: React.FC<FormulaEditorRouterProps> = ({
  tab,
  column,
  listFormula,
  columns,
  versionId,
  onRequestClose,
  versionEditable,
  listConnectedTable,
  formulaEditorTitle,
  controlledMode,
  onColumnFormulaValidationFinished,
  onFormulaValidationFinished,
  readonly = false,
  clientOnlyMapping,
  isSettingsEditingActive,
  controlledFormulaErrorForListItself,
  controlledFormulaErrorForColumns,
}) => {
  // @todo remove after full migration to ADT
  const selectedColumn = useMemo(
    () =>
      column && columns[column] ? new ListColumnWrapper(columns[column]) : null,
    [column, columns],
  )
  const columnControlledFormulaError = useMemo(
    () =>
      controlledFormulaErrorForColumns?.find(
        (e) => e.id === selectedColumn?.id(),
      )?.error || null,
    [controlledFormulaErrorForColumns, selectedColumn],
  )

  const formula = useMemo<Maybe<string>>(() => {
    if (tab === 'list' && listFormula !== null && listFormula !== undefined) {
      return listFormula
    }
    if (
      tab === 'column' &&
      selectedColumn &&
      selectedColumn.formula() !== undefined &&
      selectedColumn.formula() !== null
    ) {
      return selectedColumn.formula() || null
    }

    return null
  }, [listFormula, selectedColumn, tab])

  useEffect(() => {
    if (tab === 'column' && !selectedColumn?.isCalculated()) {
      onRequestClose()
    }
  }, [onRequestClose, selectedColumn, tab])

  const versionsRef = useRef<FormulaVersion[]>([])
  const columnIdRef = useRef(selectedColumn?.id())

  // to keep formula editor working correctly keep the reference to the version object,
  // otherwise the cursor position will be reset after every application of autocomplete
  const versions = useMemo<FormulaVersion[]>(() => {
    const brandNewVersionObject = {
      title: '', // we don't need it because we don't show it
      id: versionId || '',
      formula: formula || '',
      locked: isSettingsEditingActive ? false : !versionEditable,
      connectedTable: selectedColumn
        ? {
            id: selectedColumn.id(),
          }
        : listConnectedTable,
    }

    if (!controlledMode) {
      return [brandNewVersionObject]
    }

    if (!versionsRef.current[0] || versionsRef.current[0]?.id !== versionId) {
      versionsRef.current = [brandNewVersionObject]
      return versionsRef.current
    }

    if (columnIdRef.current !== selectedColumn?.id()) {
      columnIdRef.current = selectedColumn?.id()
      versionsRef.current = [brandNewVersionObject]
      return versionsRef.current
    }

    versionsRef.current[0].formula = brandNewVersionObject.formula
    versionsRef.current[0].locked = brandNewVersionObject.locked
    versionsRef.current[0].connectedTable = brandNewVersionObject.connectedTable
    return versionsRef.current
  }, [
    versionId,
    formula,
    isSettingsEditingActive,
    versionEditable,
    selectedColumn,
    listConnectedTable,
    controlledMode,
  ])

  const clientOnlyMappingRef = useRef(clientOnlyMapping)
  // the same purpose here - to keep the cursor position
  const cachedClientOnlyMapping = useMemo(() => {
    if (!isEqual(clientOnlyMapping, clientOnlyMappingRef.current)) {
      clientOnlyMappingRef.current = clientOnlyMapping
    }
    return clientOnlyMappingRef.current
  }, [clientOnlyMapping])

  if (tab === 'list') {
    return (
      <ListGridFormulaEditorWrapper
        versions={versions}
        onRequestClose={onRequestClose}
        formulaEditorTitle={formulaEditorTitle}
        controlledMode={controlledMode}
        controlledFormulaError={controlledFormulaErrorForListItself}
        onValidationFinished={onFormulaValidationFinished}
        readonly={readonly}
      />
    )
  }

  if (tab === 'column' && selectedColumn?.isCalculated()) {
    return (
      <ListGridColumnFormulaEditorWrapper
        versions={versions}
        onRequestClose={onRequestClose}
        column={selectedColumn}
        controlledMode={controlledMode}
        controlledFormulaError={columnControlledFormulaError}
        onValidationFinished={onColumnFormulaValidationFinished}
        readonly={readonly}
        clientOnlyMapping={cachedClientOnlyMapping}
      />
    )
  }

  return null
}
