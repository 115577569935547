import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeMetricFormulaStatePayload } from '@fintastic/web/data-access/metrics-and-lists'
import { State } from '../types'
import {
  castToMetricEditingState,
  checkMetricSourceOrThrowError,
} from './helpers'

export const changeMetricFormulaState = (
  uncheckedState: State,
  { payload: { valid, error } }: PayloadAction<ChangeMetricFormulaStatePayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  checkMetricSourceOrThrowError(state.metric, 'calculated')

  /**
   * @see EditableMetric
   */
  if (valid) {
    delete state.metric.formulaInvalid
    delete state.metric.formulaCalculationError
    return
  }

  state.metric.formulaInvalid = true
  if (error) {
    state.metric.formulaCalculationError = error
  }
}
