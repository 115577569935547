import { ThemeComponents } from './types'
import { MuiButton } from './MuiButton'
import { MuiIconButton } from './MuiIconButton'
import { MuiSvgIcon } from './MuiSvgIcon'
import { MuiButtonBase } from './MuiButtonBase'
import { MuiTabs } from './MuiTabs'
import { MuiTab } from './MuiTab'
import { MuiSnackbar } from './MuiSnackbar'
import { MuiSnackbarContent } from './MuiSnackbarContent'
import { MuiMenu } from './MuiMenu'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiPopover } from './MuiPopover'
import { MuiSwitch } from './MuiSwitch'

export const themeComponentsOverride: ThemeComponents = {
  MuiButtonBase,
  MuiButton,
  MuiIconButton,
  MuiSvgIcon,
  MuiTabs,
  MuiTab,
  MuiSnackbar,
  MuiSnackbarContent,
  MuiMenu,
  MuiMenuItem,
  MuiPopover,
  MuiSwitch,

  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: '#FE6422',
        },
        '.MuiTouchRipple-root': {
          color: '#FE6422',
        },
      },
    },
  },
}
