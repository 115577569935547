import { PeriodOption, PeriodSelectionPickerConfig } from '../types'
import { Maybe } from '@fintastic/shared/util/types'
import { keyBy } from 'lodash'
import { Period } from '@fintastic/web/util/period-selector'

export const createPeriodLabelsResolver = (c: PeriodSelectionPickerConfig) => {
  const allPeriodsMap = c.periodTypes.reduce<Record<Period, PeriodOption>>(
    (acc, periodType) => ({
      ...acc,
      ...keyBy(periodType.periods, 'id'),
    }),
    {},
  )

  return (p: Period): Maybe<string> => allPeriodsMap[p]?.label || null
}
