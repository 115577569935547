import React, { useState } from 'react'
import {
  DimensionSettingsView,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'
import { Maybe } from '@fintastic/shared/util/types'

const dimension = {
  id: 'Dim.Country',
  label: 'Country',
}

const availableMappingLists: {
  id: string
  label: string
}[] = [
  {
    id: 'Table.CountryToCurrencyMapping',
    label: 'Country To Currency',
  },
  {
    id: 'Table.CountryToDepartmentMapping',
    label: 'Country To Department',
  },
]

// @todo RESP connect
export const DimensionSettingsViewConnector: React.FC = () => {
  const { goBack } = useReportSettingsPanelNavigationContext()

  const [dimensionNameOverride, setDimensionNameOverride] = useState('')

  const [mappingList, setMappingList] =
    useState<Maybe<{ id: string; label: string }>>(null)

  return (
    <DimensionSettingsView.Editable
      dimension={dimension}
      goBack={goBack}
      dimensionNameOverride={dimensionNameOverride}
      changeDimensionNameOverride={(_, name) => setDimensionNameOverride(name)}
      hasDimensionDependency={true}
      mappingList={mappingList}
      changeMappingList={(_, list) =>
        setMappingList(availableMappingLists.find((l) => l.id === list) || null)
      }
      availableMappingLists={availableMappingLists}
      removeDimension={(id) => console.log('remove dimension', id)}
    />
  )
}
