import { Box, Button, styled } from '@mui/material'


export const StyledSaveBox = styled(Box)`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing(2)};

  button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`

export const StyledSaveButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.dark};

  &:hover, &:focus, &:focus-within {
    border-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.primary.dark};
    background: #fff;
  }
`
