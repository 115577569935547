import { ThemeComponents } from './types'

export const MuiSvgIcon: ThemeComponents['MuiSvgIcon'] = {
  styleOverrides: {
    fontSizeSmall: {
      width: 16,
      height: 16,
    },
    fontSizeMedium: {
      width: 24,
      height: 24,
    },
    fontSizeLarge: {
      width: 32,
      height: 32,
    },
  },
}
