// @todo add tests
export function isNodeBottomCrossedContainerBottom(
  container: HTMLElement,
  node: HTMLElement,
): boolean {
  return (
    node.getBoundingClientRect().bottom >
    container.getBoundingClientRect().bottom
  )
}
