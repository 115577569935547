import React, { useCallback } from 'react'
import { ModalState } from '@fintastic/shared/util/modal'
import { StandardConfirmationModal } from '@fintastic/shared/ui/components'

type CancelDesignDialogProps = {
  state: ModalState
  onConfirm: () => void
}

export const CancelDesignDialog: React.FC<CancelDesignDialogProps> = ({
  state,
  onConfirm,
}) => {
  const handleConfirm = useCallback(() => {
    state.close()
    onConfirm()
  }, [onConfirm, state])

  return (
    <StandardConfirmationModal
      open={state.isOpen}
      title={'Cancel changes?'}
      text={'The changes made to the board will not be saved'}
      confirmText={'Cancel changes'}
      cancelText={'Back to edit'}
      onRequestClose={state.close}
      onConfirm={handleConfirm}
    />
  )
}
