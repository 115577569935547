import { Box, styled } from '@mui/material'

export const StyledModalHeaderRoot = styled(Box)(({ theme }) => ({
  position: 'relative',

  width: '100%',
  minHeight: theme.spacing(3),

  padding: theme.spacing(3, 3, 0),
}))

export const StyledModalHeaderCloseButton = styled(Box)`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing(3)};

  svg {
    width: 16px !important;
    height: 16px !important;
  }
`
