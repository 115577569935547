const makeCalculatedRowId = (formula: string, parent: string): string =>
  `calculated-row-parent_${formula}_${parent}`

const isCalculatedRowId = (id: string): boolean =>
  id.indexOf('calculated') !== -1

export const CalculatedRowIdUtils = {
  makeCalculatedRowId,
  isCalculatedRowId,
}
