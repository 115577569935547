import { ColGroupDef, IFiltersToolPanel, GridApi } from 'ag-grid-community'
import { compact } from 'lodash'
import { useCallback } from 'react'

// Flattens filters not to group them by metric in two-level layout
export function useSetFlattenFiltersLayoutCb() {
  return useCallback((e: { api: GridApi }) => {
    const colDef = e.api.getColumnDefs()
    const filtersPanel = e.api.getToolPanelInstance(
      'filters',
    ) as unknown as IFiltersToolPanel

    filtersPanel?.setFilterLayout?.(
      compact(
        colDef?.flatMap((col) =>
          (col as ColGroupDef).children?.length
            ? (col as ColGroupDef).children
            : [col],
        ),
      ),
    )
  }, [])
}
