import { BoardParams } from '@fintastic/web/feature/boards'
import { useMemo } from 'react'
import {
  GenericReportDiff,
  GenericReportId,
} from '@fintastic/web/util/generic-report'
import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { compact } from 'lodash'

export type UseGenericReportBoardsConnectorParams = BoardParams & {
  reportCategoryId: string
}

export function useGenericReportBoardsConnector({
  reportCategoryId,
  versions,
  diffs: versionsDiffs,
}: UseGenericReportBoardsConnectorParams) {
  const versionsListQuery = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: true,
  })

  const reports = useMemo<Array<{ id: GenericReportId; title?: string }>>(
    () =>
      compact(
        versions.map((versionId) =>
          (versionsListQuery.data || []).find((i) => i.uuid === versionId),
        ),
      )?.map(({ uuid, name }) => ({
        id: uuid as GenericReportId,
        title: name,
      })),

    [versionsListQuery.data, versions],
  )

  const diffs = versionsDiffs as GenericReportDiff[]

  const isLoading = versionsListQuery.isLoading

  const reportNameOverrides = useMemo(
    () =>
      Object.fromEntries<string>(
        reports
          .filter(({ title }) => !!title)
          .map(({ id, title }) => [id, title || '']),
      ),
    [reports],
  )

  return useMemo(
    () => ({
      isLoading,
      reports: reports.map(({ id }) => id),
      diffs,
      reportCategoryId,
      reportNameOverrides,
    }),
    [diffs, isLoading, reportCategoryId, reports, reportNameOverrides],
  )
}
