import { styled, Box, Typography } from '@mui/material'

export const StyledNotFoundMessageRoot = styled(Box)(({ theme }) => ({
  minHeight: '100%',

  textAlign: 'center',
  color: theme.palette.grey[700],
}))

export const StyledNotFoundMessageTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 0,

  fontSize: 50,
}))

export const StyledNotFoundMessageMessage = styled(Typography)(
  ({ theme }) => ({}),
)
