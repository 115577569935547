import React, { useCallback, useMemo, useState } from 'react'
import { ModalDialog } from '@fintastic/shared/ui/components'
import {
  Box,
  Button,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { usePermissionsFlattenedChanges } from '../hooks/usePermissionsFlattenedChanges'
import { useDispatch, useSelector } from 'react-redux'
import { loadPermissionsData, selectSaveState } from '../ducks'
import { GroupSelector } from '../GroupSelector'
import { useGroupList } from '../hooks/useGroupList'
import { useCopyGovernancePolicyMutation } from '../hooks/useCopyGovernancePolicyMutation'
import toast from 'react-hot-toast/headless'

export const CopyPermissionsDialog = () => {
  const [dialogOpen, setOpenDialog] = React.useState(false)
  const copyPermissionsMutation = useCopyGovernancePolicyMutation()
  const dispatch = useDispatch()

  const flattendChanges = usePermissionsFlattenedChanges()
  const isChanged = flattendChanges.length > 0
  const saving = useSelector(selectSaveState)
  const { currentGroup, groups } = useGroupList()
  const [targetGroup, setTargetGroup] = useState('')

  const currentGroupName = useMemo(
    () => groups.find((i) => i.id === currentGroup)?.name ?? '',
    [currentGroup, groups],
  )

  const targetGroupName = useMemo(
    () => groups.find((i) => i.id === targetGroup)?.name ?? '',
    [targetGroup, groups],
  )

  const onSaveClick = useCallback(async () => {
    try {
      await copyPermissionsMutation.mutateAsync({
        groupCopyFrom: currentGroup,
        targetPolicyId: targetGroup,
      })
      setOpenDialog(false)
      toast.success(
        `Permissions copied from ${currentGroupName} to ${targetGroupName}`,
      )
      dispatch(loadPermissionsData())
    } catch (ex) {
      console.error(ex)
      toast.error('Failed to copy permissions')
    }
  }, [
    copyPermissionsMutation,
    currentGroup,
    currentGroupName,
    targetGroup,
    targetGroupName,
    dispatch,
  ])

  const handleGroupChange = useCallback((e: SelectChangeEvent) => {
    setTargetGroup(e.target.value)
  }, [])

  const enabled = targetGroup && !saving && targetGroup !== currentGroup

  const modal = (
    <ModalDialog open={!!dialogOpen} maxWidth="40rem">
      <Typography variant="h6" mb={2}>
        Copy Permissions
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1">From "{currentGroupName}" to:</Typography>
        <GroupSelector
          onGroupChange={handleGroupChange}
          currentGroup={targetGroup}
          label="Select Group"
          variant="outlined"
          minWidth="100%"
        />
      </Box>

      <LoadingButton
        data-testid="copy-permissions-button-save"
        onClick={onSaveClick}
        style={{ marginTop: '5px' }}
        variant="contained"
        disabled={!enabled}
        loading={copyPermissionsMutation.isLoading}
      >
        Save
      </LoadingButton>
      <Button
        data-testid="copy-permissions-button-close"
        style={{ marginLeft: '5px', marginTop: '5px' }}
        onClick={() => {
          setOpenDialog(false)
        }}
        variant="outlined"
      >
        Close
      </Button>
    </ModalDialog>
  )

  if (isChanged) {
    return (
      <Tooltip
        title="Apply unsaved changes before making a copy"
        placement="top"
      >
        <span>
          <LoadingButton
            variant="outlined"
            data-testid="copy-permissions-button"
            disabled={true}
          >
            Copy Permissions
          </LoadingButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <LoadingButton
        data-testid="copy-permissions-button"
        variant="outlined"
        onClick={() => setOpenDialog(true)}
        loading={false}
      >
        Copy Permissions
      </LoadingButton>
      {modal}
    </>
  )
}
