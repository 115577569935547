import { Box, IconButton, styled, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const StyledHeader = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter('row'),
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 1),

  minHeight: 64,
  width: '100%',
}))

export const StyledBackButton = styled(IconButton)``

export const StyledTitle = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}))

export const StyledContent = styled(Box)`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  padding: ${({ theme }) => theme.spacing(1, 2)};
  width: 100%;
  height: 100%;
  ${styledMixins.thinVScrollbar()};
`

export const StyledFooter = styled(Box)`
  padding: 0 16px 8px;
  width: 100%;
`
