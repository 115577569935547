import { useQuery } from 'react-query'
import { CalendarDatePickerConfig } from '@fintastic/web/util/period-selector'
import { calendarDatePickerQueryKey } from '../utils/query-key'
import { getCalendarDatePicker } from '../api/api'
import { Maybe } from '@fintastic/shared/util/types'

export const useCalendarDatePickerQuery = (calendarId: Maybe<string>) => {
  const result = useQuery<CalendarDatePickerConfig>(
    calendarDatePickerQueryKey(calendarId || ''),
    async () => {
      if (!calendarId) {
        throw new Error('useCalendarDatePickerQuery run with no calendar ID')
      }

      const response = await getCalendarDatePicker(calendarId)
      return response.data
    },
    {
      keepPreviousData: true,
      enabled: !!calendarId,
    },
  )

  return result
}
