import React from 'react'
import {
  StyledRoot,
  StyledButton,
  StyledText,
  StyledLabel,
} from './Header.styled'
import { HistoryCloseIcon } from '@fintastic/shared/ui/icons'
import { Tooltip } from '@mui/material'

export const Header: React.FC<{ onClose: () => void; label: string }> = ({
  onClose,
  label,
}) => (
  <StyledRoot>
    <StyledButton onClick={onClose}>
      <HistoryCloseIcon />
    </StyledButton>
    <Tooltip title={label} placement="top" disableInteractive={true}>
      <StyledText>
        <b style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>History</b>
        <StyledLabel>{label}</StyledLabel>
      </StyledText>
    </Tooltip>
  </StyledRoot>
)
