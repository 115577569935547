import { Maybe } from '@fintastic/shared/util/types'
import {
  SelectedCell,
  SelectedCellAggregatedValue,
} from '@fintastic/web/util/selected-cell-aggregation'
import {
  BLANK_VALUE,
  BlankOrMaskedValue,
  containsBlankValue,
  containsMaskedValue,
  isRawBlankValue,
  isRawMaskedValue,
  sumWithBlanks,
} from '@fintastic/web/util/blanks-and-masked'
import { compact, meanBy, min, max } from 'lodash'

export const aggregateSelectedCells = (
  cells: SelectedCell[],
): SelectedCellAggregatedValue[] => {
  if (!cells.length) {
    return []
  }

  return compact([
    aggregateBySum(cells),
    aggregateByCount(cells),
    aggregateByAverage(cells),
    aggregateByMin(cells),
    aggregateByMax(cells),
  ])
}

const aggregateBySum = (
  cells: SelectedCell[],
): Maybe<SelectedCellAggregatedValue> => {
  const numericCells = cells.filter(
    (cell) => cell.format !== 'string' && typeof cell.value === 'number',
  )

  if (!numericCells.length || cells.length < 2) {
    return null
  }

  return {
    type: 'sum',
    format: aggregateFormat(cells),
    value: numericCells.reduce<BlankOrMaskedValue>(
      (acc, current) =>
        sumWithBlanks(
          acc,
          current.value === null ? BLANK_VALUE : (current.value as number),
        ),
      0,
    ),
    decimals: getDecimals(cells),
  }
}

const aggregateByCount = (
  cells: SelectedCell[],
): Maybe<SelectedCellAggregatedValue> => {
  if (cells.length < 2) {
    return null
  }

  return {
    type: 'count',
    value: cells.length,
    format: 'number',
    decimals: getDecimals(cells),
  }
}

const aggregateByAverage = (
  cells: SelectedCell[],
): Maybe<SelectedCellAggregatedValue> => {
  const noBlankCells = cells.filter(
    (cell) =>
      cell.format !== 'string' &&
      !containsBlankValue(cell.value) &&
      !containsMaskedValue(cell.value) &&
      !isRawBlankValue(cell.value),
  )

  if (noBlankCells.length < 2) {
    return null
  }

  return {
    type: 'average',
    value: meanBy(noBlankCells, 'value'),
    format: aggregateFormat(cells),
    decimals: getDecimals(cells),
  }
}

const aggregateByMinMaxFactory =
  (agg: 'min' | 'max') =>
  (cells: SelectedCell[]): Maybe<SelectedCellAggregatedValue> => {
    const numericCells = cells.filter(
      ({ format, value }) =>
        format !== 'string' &&
        !isRawMaskedValue(value) &&
        !containsMaskedValue(value),
    )

    if (numericCells.length < 2) {
      return null
    }

    if (
      agg === 'min' &&
      numericCells.find(
        (i) => isRawBlankValue(i.value) || containsBlankValue(i.value),
      )
    ) {
      return {
        type: 'min',
        value: BLANK_VALUE,
        format: aggregateFormat(cells),
        decimals: getDecimals(cells),
      }
    }

    if (
      agg === 'max' &&
      numericCells.every(
        (i) => isRawBlankValue(i.value) || containsBlankValue(i.value),
      )
    ) {
      return {
        type: 'max',
        value: BLANK_VALUE,
        format: aggregateFormat(cells),
        decimals: getDecimals(cells),
      }
    }

    const mapped = numericCells.map((i) =>
      typeof i.value !== 'number' ? 0 : i.value,
    )

    return {
      type: agg,
      value: (agg === 'max' ? max(mapped) : min(mapped)) ?? 0,
      format: aggregateFormat(cells),
      decimals: getDecimals(cells),
    }
  }

const aggregateByMin = aggregateByMinMaxFactory('min')
const aggregateByMax = aggregateByMinMaxFactory('max')

const aggregateFormat = (
  cells: SelectedCell[],
): SelectedCellAggregatedValue['format'] => {
  if (
    cells
      .filter((i) => i.format !== 'string')
      .every((i) => i.format === 'percent')
  ) {
    return 'percent'
  }

  return 'number'
}

const getDecimals = (cells: SelectedCell[]) => {
  const mostAccurateDecimals = max(
    cells.map((i) => (i.format === 'string' ? 0 : i.decimals)),
  )

  return typeof mostAccurateDecimals === 'number' ? mostAccurateDecimals : 2
}
