import { Box, ClickAwayListener, IconButton, Popper } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import { ThreeDotsHorizontalIcon } from '@fintastic/shared/ui/icons'
import { LabelsEditMenu } from './LabelsEditMenu'
import { LabelItem, OnLabelCallbacks } from './types'

export const LabelsDropdownEditButton: React.FC<
  { label: LabelItem } & Pick<OnLabelCallbacks, 'onLabelUpdated' | 'onLabelDeleted'>
> = ({ label, onLabelUpdated, onLabelDeleted }) => {
  const containerRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ position: 'relative' }}>
        <IconButton
          sx={{ border: 0 }}
          disableTouchRipple
          size="small"
          onClick={handleOpen}
          ref={containerRef}
        >
          <ThreeDotsHorizontalIcon fontSize="inherit" />
        </IconButton>
        <Popper open={open} anchorEl={containerRef.current}>
          <LabelsEditMenu
            label={label}
            onLabelUpdated={onLabelUpdated}
            onLabelDeleted={onLabelDeleted}
            closePopup={handleClose}
          />
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}
