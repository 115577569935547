import {
  MetricDataValue,
  SparseMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import { DimensionTuple } from '@fintastic/web/util/dimensions'
import { iterateOverSparse } from './iterateOverSparse'

export const reduceSparse = <TResult>(
  data: SparseMetricData,
  cb: (
    acc: TResult,
    dimensions: DimensionTuple[],
    value: MetricDataValue,
  ) => TResult,
  initialResult: TResult,
): TResult => {
  let result = initialResult

  iterateOverSparse(data, (d, v) => {
    result = cb(result, d, v)
  })

  return result
}
