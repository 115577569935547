import { uniqBy } from 'lodash'
import { weightedAverage } from '@fintastic/shared/util/numeric-array'
import type { GenericReportValue } from '@fintastic/web/util/generic-report'
import {
  BlankOrMaskedValue,
  isRawBlankValue,
  MASKED_VALUE,
  BLANK_VALUE,
} from '@fintastic/web/util/blanks-and-masked'

// Calculated the sum of all values that match current cell
export const getCellValue = (
  relevantValues?: GenericReportValue[],
): BlankOrMaskedValue => {
  if (!relevantValues?.length) {
    return BLANK_VALUE
  }

  // Mixed aggregation
  if (uniqBy(relevantValues, 'aggregation').length > 1) {
    return BLANK_VALUE
  }

  if (relevantValues[0].aggregation === 'weighted_avg') {
      const [amounts, weights] = relevantValues.reduce<
        readonly [readonly number[], readonly number[]]
      >(
        (
          [amounts, weights],
          { amount, mask, weightValue, weightValueMask },
        ) => {
          if (amount === mask || weightValue === weightValueMask) {
            return [amounts, weights]
          }

          if (
            isRawBlankValue(amount) ||
            isRawBlankValue(weightValue) ||
            !weightValue
          ) {
            return [amounts, weights]
          }

          return [
            [...amounts, amount],
            [...weights, weightValue],
          ] as const
        },
        [[], []],
      )

      if (!amounts.length && relevantValues.length) {
        return BLANK_VALUE
      }

      return weightedAverage(amounts as number[], weights as number[])
  }

  // Defaul sum aggregation

  return relevantValues.reduce<BlankOrMaskedValue>((sum, { amount, mask }) => {
    if (amount === mask) {
      // If sum is blank, it'll become masked
      return typeof sum === 'number' ? sum : MASKED_VALUE
    }

    if (isRawBlankValue(amount)) {
      return sum
    }

    if (
      sum === BLANK_VALUE ||
      sum === MASKED_VALUE
    ) {
      return amount
    }

    return sum + amount
  }, BLANK_VALUE)
}
