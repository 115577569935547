import { Box, styled } from '@mui/material'
import type { IHeaderParams } from 'ag-grid-community'
import { ReactNode, useCallback } from 'react'
import {
  Menu,
  Sort,
} from 'libs/shared/ui/ag-grid/src/lib/column-headers/AgGridColumnHeaderCustomizer'

type HeaderProps = {
  Info: ReactNode
} & IHeaderParams

const CustomHeader = (props: HeaderProps) => {
  const columnSort = props.column.getSort() || null

  const toggleSort = useCallback(() => {
    if (!props.enableSorting) {
      return
    }

    if (columnSort === 'asc') {
      props.setSort('desc', false)
    } else {
      if (columnSort === 'desc') {
        props.setSort(null, false)
      } else {
        props.setSort('asc', false)
      }
    }
  }, [columnSort, props])

  return (
    <Container info={!!props.Info}>
      <div className="custom-header-header" onClick={toggleSort}>
        <div className="custom-header-title">{props.displayName}</div>

        <Sort order={columnSort} sortIndex={null} />
      </div>

      <Menu showColumnMenu={props.showColumnMenu} />

      <Box sx={{ position: 'relative', left: '8px' }}>{props.Info}</Box>
    </Container>
  )
}

const Container = styled('div')<{ info: boolean }>(({ info }) => ({
  'display': 'flex',
  'flexDirection': 'row',
  'alignItems': 'center',
  'justifyContent': 'center',
  'width': '100%',
  'whiteSpace': 'nowrap',
  'overflow': 'hidden',
  'textOverflow': 'ellipsis',

  '& .custom-header-header': {
    'width': !info && '100%',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'display': 'inline-flex',
    'flexFlow': 'row nowrap',
    'alignItems': 'center',

    '& .custom-header-title': {
      whiteSpace: 'nowrap',
      textOverflow: ' ellipsis',
      overflow: 'hidden',
      display: 'inline',
    },

    '& span': {
      // sorting arrow fine tune
    },
  },
}))

export default CustomHeader
