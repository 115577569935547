import React, { useCallback, useMemo } from 'react'
import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import {
  DimensionId,
  VersionTimeDimension,
} from '@fintastic/web/util/dimensions'
import { sortedPeriodResolutions } from '@fintastic/web/util/period-selector'
import { normalizeTitle } from '@fintastic/shared/util/formatters'

export type TimeDimensionSelectorInputProps = {
  metricDimensions: string[]
  timeDimensions: VersionTimeDimension[]
  readonly?: boolean
  onChangeTimeDimension: (newTimeDimension: Maybe<VersionTimeDimension>) => void
}

export const TimeDimensionSelectorInput: React.FC<
  TimeDimensionSelectorInputProps
> = ({ metricDimensions, timeDimensions, readonly, onChangeTimeDimension }) => {
  const handleTimeDimensionChange = useCallback(
    (e: SelectChangeEvent) => {
      onChangeTimeDimension(
        timeDimensions.find((dim) => dim.id === e.target.value) ?? null,
      )
    },
    [onChangeTimeDimension, timeDimensions],
  )

  const value = useMemo(
    () => timeDimensions.find((dim) => metricDimensions.includes(dim.id))?.id,
    [metricDimensions, timeDimensions],
  )

  const preparedValues = useMemo<{ id: DimensionId; label: string }[]>(
    () =>
      (timeDimensions || [])
        .slice()
        .sort(
          (a, b) =>
            sortedPeriodResolutions.indexOf(a.resolution) -
            sortedPeriodResolutions.indexOf(b.resolution),
        )
        .map((v) => ({
          id: v.id,
          label: normalizeTitle(v.label || v.id),
        })),
    [timeDimensions],
  )

  if (!timeDimensions?.length) {
    return (
      <Alert>
        <AlertTitle>No time dimensions found for this version</AlertTitle>
      </Alert>
    )
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Time Dimension</InputLabel>
      <Select
        label="Time Dimension"
        disabled={readonly}
        value={value || defaultTimeDimensionValue}
        onChange={handleTimeDimensionChange}
        fullWidth={true}
        MenuProps={{
          sx: {
            '& .MuiMenu-list': {
              py: 1,
            },
          },
        }}
      >
        <MenuItem
          key={defaultTimeDimensionValue}
          value={defaultTimeDimensionValue}
          dense
        >
          None
        </MenuItem>
        {preparedValues.map((dim) => (
          <MenuItem key={dim.id} value={dim.id} dense>
            {dim.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const defaultTimeDimensionValue = 'none'
