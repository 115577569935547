import {
  ChartDisplaySettings,
  ChartSettings,
  defaultChartDecimalPlaces,
  defaultChartGridMode,
  defaultChartLegendFormat,
  defaultChartLegendPosition,
  defaultChartNumberFormat,
  defaultChartTheme,
  defaultChartType,
  defaultChartXAxisAlignment,
  defaultChartXAxisFormat,
} from './types'

export const defaultChartDisplaySettings: ChartDisplaySettings = {
  type: defaultChartType,
  theme: defaultChartTheme,
  legendPosition: defaultChartLegendPosition,
  legendFormat: defaultChartLegendFormat,
  xAxisAlignment: defaultChartXAxisAlignment,
  xAxisFormat: defaultChartXAxisFormat,
  gridMode: defaultChartGridMode,
  numberFormat: defaultChartNumberFormat,
  decimalPlaces: defaultChartDecimalPlaces,
}

export const getChartDisplaySettingsOrDefault = (
  chartSettings?: Partial<ChartSettings>,
) => ({
  theme: chartSettings?.theme || defaultChartTheme,
  type: chartSettings?.type || defaultChartType,

  legendFormat: chartSettings?.legendFormat || defaultChartLegendFormat,

  legendPosition: chartSettings?.legendPosition || defaultChartLegendPosition,

  xAxisAlignment: chartSettings?.xAxisAlignment || defaultChartXAxisAlignment,

  xAxisFormat: chartSettings?.xAxisFormat || defaultChartXAxisFormat,

  gridMode: chartSettings?.gridMode || defaultChartGridMode,

  decimalPlaces: chartSettings?.decimalPlaces || defaultChartDecimalPlaces,

  numberFormat: chartSettings?.numberFormat || defaultChartNumberFormat,
})

export const getChartWidgetSettingsOrDefaults = (
  widgetSettings?: Partial<ChartSettings>,
): ChartSettings => ({
  ...getChartDisplaySettingsOrDefault(widgetSettings),
  dimensions: widgetSettings?.dimensions || [],
})
