import React from 'react'
import { GridApi } from 'ag-grid-community'
import { createContext } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

type MaybeReactDispatch<T = any> = Maybe<
  React.Dispatch<React.SetStateAction<T>>
>

export type BaseGridContext = {
  gridApi: Maybe<GridApi>
  collapseState: [boolean, Maybe<(collapsed: boolean) => void>]
  groupingState: [boolean, MaybeReactDispatch<boolean>]
  refreshState: [number, MaybeReactDispatch<number>]
  embeddedView?: boolean
}
export const baseGridContext = createContext<BaseGridContext>({
  gridApi: null,
  collapseState: [false, null],
  groupingState: [false, null],
  refreshState: [0, null],
  embeddedView: false,
})
