import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  BaseGrid,
  BaseGridContextProp,
  BaseGridEventHandlers,
  BaseGridOnCellValueChangedMetadata,
  CollapseExpandButton,
  ResetGridButton,
  SetGridSizeCallback,
  ToggleGroupingButton,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import {
  CellValueChangedEvent,
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  type ProcessDataFromClipboardParams,
  ToolPanelDef,
} from 'ag-grid-community'
import { DimensionLabelMap, Maybe } from '@fintastic/shared/util/types'
import {
  extractChangeFromEvent,
  removeTimeDimension,
  VersionMetric,
} from '../../utils/metrics'
import {
  HandleMetricUpdateCallback,
  MetricGridApi,
  MetricGridRow,
  MetricGridSettingsPanelProps,
  MetricShape,
} from './types'
import {
  PanelBottomBar,
  PanelDragHandle,
  PanelLoadingOverlay,
} from '@fintastic/shared/ui/panel-framework'
import { buildListGridColumnDefinition } from '../../utils/buildListGridColumnDefinition'
import { dimensionsLabelsMapToValuesLabels } from './features/dimensions/dimensionsLabelsMapToValuesLabels'
import { totalRowAllowed } from './features/total-row/totalRowAllowed'
import { useTotalLine } from './features/total-row/useTotalLine'
import { useWeightedAverageAgContext } from './features/weighted-average/useWeightedAverageAgContext'
import { FormulaButton, FormulaCollapse } from '@fintastic/web/feature/formulas'
import { useFormulasState } from './features/formulas/useFormulasState'
import { MetricFormulaEditorWrapper } from './features/formulas/MetricFormulaEditorWrapper'
import {
  useFilterOutOthers,
  usePersistentFilterModel,
} from '@fintastic/shared/ui/ag-grid'
import { useProcessClipboardPaste } from '../../hooks/useProcessClipboardPaste'
import { getContextMenuItemsByMaxRows } from '../../utils/contextMenu'
import { metricSettingsToolPanelDefinition } from '../settings-panel/metric/aggrid-tool-panel'
import { Box, Button, Tooltip } from '@mui/material'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { createMetricColumns } from './features/grid-columns/createMetricColumns'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { useVersionToCategoryAggregationMap } from './features/aggregation-functions/useVersionToCategoryAggregationMap'
import { ValueColumnFieldAndRollUp } from './features/grid-columns/types'
import { cellEditors } from '../shared/features/aggregated-time-cell-editing/cell-editors-map'
import { useTableRows } from './features/table-data/useTableRows'
import { ColumnColorsGridProps } from '../column-color-selector/types'
import { GridColumnColorsCssWrapper } from '../column-color-selector/GridColumnColorsCssWrapper'
import { ColumnColorSelector } from '../column-color-selector/ColumnColorSelector'
import { MetricGridError } from './features/errors/errors'
import { isRowDataFullyBlank } from '@fintastic/web/util/blanks-and-masked'
import { useBlanksExternalFilter } from './features/show-blanks/useBlanksExternalFilter'
import { ExternalFilter } from '@fintastic/shared/util/ag-grid'
import { BlanksFilterPanelContext } from './features/show-blanks/blanks-filter-panel'
import { blanksFilterToolPanelDefinition } from './features/show-blanks/blanks-filter-panel/aggrid-tool-panel'
import {
  fintasticAgIcons,
  resolveFintasticAgIconKey,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { MetricIcon } from '../design-tokens/icons'
import { AgGridReact } from 'ag-grid-react'
import { ClientOnlyMapping } from '@fintastic/web/util/formulas'
import { maxRowsToExportExcel } from '@fintastic/shared/util/ag-grid-export'
import {
  CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE,
  setMetricCellDataValue,
} from '../../utils/setting-cell-data-value'
import { useVersionEntitiesContext } from '@fintastic/web/data-access/versions'
import { usePeriodSelectorResolution } from '@fintastic/web/util/period-selector'
import { useBaseTimeDimensionCurrentValue } from '@fintastic/web/data-access/base-time-dimension'
import {
  ColumnColor,
  extractSelectedCellFromEvent,
  resolveDimensionColumnColor,
} from '@fintastic/web/util/metrics-and-lists'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { getChangesThatAreNotApplicableForMetric } from './features/data-editing/getChangesThatAreNotApplicableForMetric'
import toast from 'react-hot-toast/headless'
import { revertCellEditChanges } from './features/data-editing/revertCellEditChanges'
import {
  ErrorAlert,
  ModelExplorerVersionSelector,
} from '@fintastic/shared/ui/components'
import {
  handleSelectedCellsForAggregation,
  SelectedCellAggregation,
  useSelectedCellAggregationAgGridContext,
} from '@fintastic/web/feature/selected-cell-aggregation'
import { mapMetricErrorToUi } from './features/errors/error-to-ui-mapping'
import {
  DiffMode,
  versionIsLockedMessage,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'
import { useHandleLockedCellClick } from './features/data-editing/useHandleLockedCellClick'
import { useWidgetContext } from '@fintastic/shared/ui/widgets-framework'
import { useIsAuditLogEnabled } from '@fintastic/web/feature/audit-log'
import {
  EntityLevelHistoryButton,
  EntityLevelHistoryMultiversionButton,
  useIsHistoryAllowed,
} from '@fintastic/web/feature/history'
import { isEqual } from 'lodash'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { useCommonDimensions } from './features/dimensions/useCommonDimensions'

type Diff = [string, string, DiffMode]
const defaultColumnColors: ColumnColor[] = []

export type MetricGridProps = {
  context?: BaseGridContextProp
  isLoading?: boolean
  title?: string
  data: VersionMetric[]
  weightedAverageData?: VersionMetric[]
  diffs?: Array<Diff>
  dimensions: DimensionLabelMap
  periods?: string[]
  onUpdate?: HandleMetricUpdateCallback
  enableGrouping?: boolean
  enableGridReset?: boolean
  setGridSizeCallback?: SetGridSizeCallback
  readonly?: boolean
  hasFormulas?: boolean
  isLiveActuals?: boolean
  settingsPanel?: MetricGridSettingsPanelProps
  isVersionPage?: boolean
  error: Maybe<MetricGridError>
  metricId: string
  versions: string[]
  periodSelectorComponent: React.ReactNode
  versionUserLocks: VersionUserLockParsed[]
} & MetricShape &
  Partial<ColumnColorsGridProps>

const MetricGridComponent = (
  {
    context,
    data,
    dimensions,
    dataType = 'number',
    dimensionId,
    onUpdate,
    title,
    diffs,
    enableGrouping,
    enableGridReset,
    setGridSizeCallback,
    isLoading = false,
    readonly = false,
    weightedAverageData,
    hasFormulas = false,
    settingsPanel,
    isVersionPage,
    columnColors = defaultColumnColors,
    enableColumnColors,
    handleUpdateColumnColors,
    metricId,
    versions,
    error,
    periodSelectorComponent,
    isLiveActuals,
    versionUserLocks,
  }: MetricGridProps,
  ref: ForwardedRef<MetricGridApi>,
): JSX.Element => {
  const widgetContext = useWidgetContext()

  const isError = !!error
  const firstMetric: Maybe<VersionMetric> = data[0] || null
  const titleText = useMemo(() => titleFormatter(title) || 'Metric', [title])

  const isFirstMetricSourceCalculated =
    firstMetric?.metricSource === 'calculated'
  const isInputMetric = firstMetric?.metricSource === 'input'
  const gridApiRef = useRef<Maybe<GridApi>>(null)
  const gridRef = useRef<Maybe<AgGridReact>>(null)
  const [gridHaveRenderedData, setGridHaveRenderedData] = useState(false)

  useImperativeHandle(ref, () => ({
    updateCellDataValue: (payload) => {
      if (!gridApiRef.current) {
        return
      }
      try {
        setMetricCellDataValue(gridApiRef.current, payload)
      } catch (error) {
        console.log(error)
      }
    },
  }))

  const versionsEntities = useVersionEntitiesContext()

  const categoryAggMap = useVersionToCategoryAggregationMap(data)

  const { formulasOpened, toggleFormulas, closeFormulas, openFormulas } =
    useFormulasState()

  useEffect(() => {
    if (settingsPanel?.editingActive && isFirstMetricSourceCalculated) {
      openFormulas()
    }
  }, [
    openFormulas,
    settingsPanel?.editingActive,
    settingsPanel?.isNewMetric,
    isFirstMetricSourceCalculated,
  ])

  useEffect(() => {
    if (!settingsPanel?.editingActive && isFirstMetricSourceCalculated) {
      closeFormulas()
    }
  }, [
    closeFormulas,
    settingsPanel?.editingActive,
    isFirstMetricSourceCalculated,
  ])

  useEffect(() => {
    if (settingsPanel?.editingActive) {
      gridApiRef.current?.stopEditing(true)
    }
  }, [settingsPanel?.editingActive])

  const commonDimensions = useCommonDimensions(data)
  const filterOutOthers = useFilterOutOthers(commonDimensions)
  const setDefaultFilters = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }
    filterOutOthers(gridApiRef.current)
  }, [filterOutOthers])

  useEffect(() => {
    setDefaultFilters()
  }, [setDefaultFilters])

  // @todo remove dimensions metadata usage
  const allDimensionValuesLabels = useMemo(
    () => dimensionsLabelsMapToValuesLabels(dimensions),
    [dimensions],
  )

  // @todo remove dimensions metadata usage
  const { nonTimeDimensions, timeDimension } = useMemo(
    () => removeTimeDimension(dimensions),
    [dimensions],
  )

  const isGroupingPossible = nonTimeDimensions.length > 0

  const selectedCellAgGridContext = useSelectedCellAggregationAgGridContext()
  const weightedAverageAgContext = useWeightedAverageAgContext()

  const agContext = useMemo(
    () => ({
      ...selectedCellAgGridContext,
      ...weightedAverageAgContext,
    }),
    [selectedCellAgGridContext, weightedAverageAgContext],
  )

  const rowData = useTableRows(data, weightedAverageData, {
    settingsEditingActive: !!settingsPanel?.editingActive,
    timeDimension,
    gridApi: gridApiRef.current,
    gridReady: gridHaveRenderedData,
  })

  const blockedVersionUserLocksSource = useMemo(
    () => versionUserLocks.filter((l) => l.editIsBlocked),
    [versionUserLocks],
  )
  const blockedVersionUserLocks = useReferenceMemo(
    blockedVersionUserLocksSource,
    isEqual,
  )

  const { colDefs, valueColumnsWithRollups, periodValues } = useMemo<{
    colDefs: ColDef<MetricGridRow>[]
    valueColumnsWithRollups: ValueColumnFieldAndRollUp[]
    periodValues: string[]
  }>(() => {
    const columnBuilder = buildListGridColumnDefinition(context)

    const dimensionColumns = nonTimeDimensions
      .map((dimensionId) =>
        columnBuilder({
          field: dimensionId,
          // @todo remove dimensions metadata usage
          title: dimensions[dimensionId].label,
          options: dimensions[dimensionId].values,
          dataType: 'dimension',
          pinned: 'left',
        }),
      )
      .map((colDef, idx) => ({
        ...colDef,
        headerComponentParams: {
          contentBeforeMenu:
            handleUpdateColumnColors && enableColumnColors ? (
              <ColumnColorSelector
                columnColors={columnColors}
                currentColumnColumn={resolveDimensionColumnColor(
                  columnColors,
                  nonTimeDimensions[idx],
                  null,
                )}
                handleUpdateColumnColors={handleUpdateColumnColors}
              />
            ) : null,
        },
      }))

    const useTwoLevels = !!timeDimension && data.length > 1

    const {
      colDefs: valueAndDiffColumns,
      valueColumnsWithRollups,
      periodValues,
    } = createMetricColumns({
      timeDimension,
      versionsEntities,
      data,
      diffs,
      dataType,
      dimensions,
      columnBuilder,
      valueColumnCommonParams: {
        dimensions,
        nonTimeDimensions,
        readonly,
        dataType,
        dimensionId,
        timeDimension,
        onUpdate,
        period: null,
        restPeriods: null,
      },
      useTwoLevels,
      currencies,
      enableColumnColors,
      columnColors,
      handleUpdateColumnColors,
      blockedVersionUserLocks,
    })

    const columnDefs = []
    if (useTwoLevels) {
      columnDefs.push({
        headerName: 'Dimensions',
        children: dimensionColumns,
      })
    } else {
      columnDefs.push(...dimensionColumns)
    }
    columnDefs.push(...valueAndDiffColumns)

    return {
      colDefs: columnDefs,
      valueColumnsWithRollups,
      periodValues,
    }
  }, [
    context,
    nonTimeDimensions,
    timeDimension,
    data,
    versionsEntities,
    diffs,
    dataType,
    dimensions,
    readonly,
    dimensionId,
    onUpdate,
    enableColumnColors,
    columnColors,
    handleUpdateColumnColors,
    blockedVersionUserLocks,
  ])

  const blanksFilter = useBlanksExternalFilter(
    gridApiRef.current,
    useCallback(
      (rowData) =>
        isRowDataFullyBlank(
          rowData,
          valueColumnsWithRollups.map((c) => c.field),
        ),
      [valueColumnsWithRollups],
    ),
    {
      widgetId: widgetContext?.widgetId,
    },
  )

  useEffect(() => {
    gridApiRef.current?.onFilterChanged()
  }, [colDefs])

  const allowTotalRow = useMemo(
    () => totalRowAllowed(categoryAggMap, dataType),
    [dataType, categoryAggMap],
  )

  const totalLine = useTotalLine(allowTotalRow, valueColumnsWithRollups)

  // @todo remove dimensions metadata usage
  // @todo use dimensions context
  const fallbackGroupValueFormatter = useCallback(
    (value: unknown): Maybe<string> =>
      allDimensionValuesLabels[value as string] || null,
    [allDimensionValuesLabels],
  )

  const customToolPanels = useMemo(() => {
    const toolPanels: ToolPanelDef[] = []

    if (!isFirstMetricSourceCalculated && !settingsPanel?.editingActive) {
      toolPanels.push(blanksFilterToolPanelDefinition)
    }

    if (settingsPanel?.enabled) {
      toolPanels.push(metricSettingsToolPanelDefinition)
    }

    return toolPanels
  }, [
    isFirstMetricSourceCalculated,
    settingsPanel?.editingActive,
    settingsPanel?.enabled,
  ])

  const handleCellValueChanged = useCallback(
    (
      params: CellValueChangedEvent,
      metadata?: BaseGridOnCellValueChangedMetadata,
    ) => {
      totalLine.updateTotalRowData(params.api)
      if (params.source === CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE) {
        return
      }
      const changes = extractChangeFromEvent(
        nonTimeDimensions,
        timeDimension,
        params,
        metadata,
      )

      const inapplicableChanges = getChangesThatAreNotApplicableForMetric(
        data,
        changes,
      )

      if (inapplicableChanges.length) {
        inapplicableChanges.forEach((change) => {
          const versionMetric = data.find(
            (d) => d.versionId === change.versionId,
          )
          const versionName = versionMetric?.versionLabel ?? change.versionId

          toast.error(
            `The metric does not contain the combination of dimension values in ${versionName}. Your changes have not been saved`,
          )
        })

        if (gridApiRef.current) {
          revertCellEditChanges(gridApiRef.current, changes, timeDimension)
        }
        return
      }

      return onUpdate?.(changes)
    },
    [data, nonTimeDimensions, onUpdate, timeDimension, totalLine],
  )

  const editProps = useMemo(
    () => ({
      rowIdField: '_rowId',
      ...(onUpdate
        ? {
            onCellValueChanged: handleCellValueChanged,
          }
        : undefined),
    }),
    [handleCellValueChanged, onUpdate],
  )

  const persistentFilterCallbacks = usePersistentFilterModel<MetricGridRow>(
    `metric_${metricId}_${widgetContext?.widgetId || versions?.[0] || ''}`,
  )

  const lockedCellClickHandler = useHandleLockedCellClick(
    data,
    versionUserLocks,
  )

  const eventHandlers = useMemo<BaseGridEventHandlers<MetricGridRow>>(
    () => ({
      onFilterChanged: (event) => {
        persistentFilterCallbacks.onFilterChanged(event)
        totalLine.eventHandlers.onFilterChanged(event)

        if (diffs?.length) {
          event.api.recomputeAggregates()
        }
      },
      onFirstDataRendered(event) {
        setDefaultFilters()
        persistentFilterCallbacks.onFirstDataRendered(event)
        gridApiRef.current?.onFilterChanged()
        setGridHaveRenderedData(true)
      },
      onGridReady: (event) => {
        totalLine.eventHandlers.onGridReady(event)
      },
      onRowDataUpdated: (event) => {
        persistentFilterCallbacks.onRowDataUpdated(event)
        totalLine.eventHandlers.onRowDataUpdated(event)
        gridApiRef.current?.onFilterChanged()
      },
      onRangeSelectionChanged: (e) => {
        handleSelectedCellsForAggregation<MetricGridRow>(
          e,
          extractSelectedCellFromEvent,
        )
      },
      onModelUpdated(e) {
        handleSelectedCellsForAggregation<MetricGridRow>(
          e,
          extractSelectedCellFromEvent,
        )
      },
      onCellClicked(e) {
        lockedCellClickHandler(e)
      },
    }),
    [
      persistentFilterCallbacks,
      totalLine.eventHandlers,
      diffs?.length,
      setDefaultFilters,
      lockedCellClickHandler,
    ],
  )

  const leftContent = useMemo(() => {
    if (
      !widgetContext?.draggable &&
      !widgetContext?.collapsable &&
      !hasFormulas
    ) {
      return undefined
    }

    return (
      <>
        {widgetContext?.draggable && <PanelDragHandle />}
        {widgetContext?.collapsable && (
          <CollapseExpandButton disabled={isLoading} />
        )}
        {hasFormulas && (
          <FormulaButton
            icon={isLiveActuals ? 'ax' : 'fx'}
            onClick={toggleFormulas}
            disabled={isError || isLoading}
            isActive={formulasOpened}
          />
        )}
        {!isVersionPage && !widgetContext?.boardInDesignMode && (
          <ModelExplorerVersionSelector
            entityId={metricId}
            entityTitle={titleText}
          />
        )}
      </>
    )
  }, [
    formulasOpened,
    hasFormulas,
    isError,
    isLiveActuals,
    isLoading,
    isVersionPage,
    metricId,
    titleText,
    toggleFormulas,
    widgetContext?.boardInDesignMode,
    widgetContext?.collapsable,
    widgetContext?.draggable,
  ])

  const baseTimeDimension = useBaseTimeDimensionCurrentValue()
  const showPeriodSelector =
    baseTimeDimension &&
    !(settingsPanel?.isNewMetric || settingsPanel?.editingActive)

  const editButton = useMemo(() => {
    if (!settingsPanel?.enabled || !settingsPanel.editingAllowed) {
      return null
    }

    if (settingsPanel?.editingActive || settingsPanel.isNewMetric) {
      return null
    }

    if (
      versionUserLocks[0].editIsBlocked &&
      versionUserLocks[0].lockedBy !== 'me'
    ) {
      return (
        <Tooltip title={versionIsLockedMessage(versionUserLocks[0])} arrow>
          <span>
            <Button
              variant="outlined"
              color="black"
              onClick={settingsPanel.onEdit}
              disabled
            >
              Edit
            </Button>
          </span>
        </Tooltip>
      )
    }

    const disabled =
      isError ||
      isLoading ||
      settingsPanel.isLockingVersion ||
      settingsPanel.isLoading

    const text =
      settingsPanel.isLockingVersion || settingsPanel.isLoading
        ? 'Loading...'
        : 'Edit'

    return (
      <Button
        variant="outlined"
        color="black"
        onClick={settingsPanel.onEdit}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  }, [isError, isLoading, settingsPanel, versionUserLocks])

  const auditLogEnabled = useIsAuditLogEnabled()
  const historyAllowed = useIsHistoryAllowed()

  const rightContent = useMemo(
    () => (
      <>
        <FintasticThemeProvider applyLegacyTheme={false}>
          {editButton}
          {settingsPanel?.enabled && settingsPanel?.editingActive && (
            <Button
              variant="contained"
              color="primary"
              disabled={!settingsPanel.isValid}
              onClick={settingsPanel.onSave}
              sx={{ mr: 1 }}
            >
              Save to version
            </Button>
          )}
          {settingsPanel?.editingActive && settingsPanel?.enabled && (
            <Button
              variant="outlined"
              color="black"
              onClick={settingsPanel.onCancel}
            >
              Cancel
            </Button>
          )}
        </FintasticThemeProvider>

        {showPeriodSelector && <Box mx={1}>{periodSelectorComponent}</Box>}

        {enableGrouping && (
          <ToggleGroupingButton
            disabled={isError || isLoading || !isGroupingPossible}
          />
        )}
        {enableGridReset && <ResetGridButton disabled={isLoading} />}
        {auditLogEnabled &&
          historyAllowed &&
          isInputMetric &&
          !widgetContext?.boardInDesignMode && (
            <>
              {isVersionPage ? (
                <EntityLevelHistoryButton
                  entityId={metricId}
                  versionId={versions?.[0]}
                  disabled={isLoading || isError}
                />
              ) : (
                <EntityLevelHistoryMultiversionButton
                  entityId={metricId}
                  disabled={isLoading || isError}
                />
              )}
            </>
          )}
      </>
    ),
    [
      editButton,
      settingsPanel?.enabled,
      settingsPanel?.editingActive,
      settingsPanel?.isValid,
      settingsPanel?.onSave,
      settingsPanel?.onCancel,
      showPeriodSelector,
      periodSelectorComponent,
      enableGrouping,
      isError,
      isLoading,
      isGroupingPossible,
      enableGridReset,
      auditLogEnabled,
      historyAllowed,
      isInputMetric,
      isVersionPage,
      metricId,
      versions,
      widgetContext?.boardInDesignMode,
    ],
  )

  const rootDataAttributes: Record<string, string> = useMemo(
    () => ({
      ...(readonly ? { 'data-readonly': '' } : {}),
    }),
    [readonly],
  )

  const handleProcessDataFromClipboard = useProcessClipboardPaste()

  const handlePasteClipboard = useCallback(
    (params: ProcessDataFromClipboardParams<MetricGridRow>) =>
      handleProcessDataFromClipboard(
        params,
        hasFormulas ? 'calculated-metric' : 'metric',
      ),
    [hasFormulas, handleProcessDataFromClipboard],
  )

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const sheetName = data.map((vm) => vm.versionLabel)?.join(', ')

    return getContextMenuItemsByMaxRows(
      params,
      maxRowsToExportExcel,
      fallbackGroupValueFormatter,
      title + '.Metric' + (hasFormulas ? ' (calculated)' : ''),
      sheetName,
    )
  }

  const externalFilters = useMemo<ExternalFilter<MetricGridRow>[]>(
    () =>
      settingsPanel?.editingActive || isFirstMetricSourceCalculated
        ? []
        : [blanksFilter.externalFilter],
    [
      settingsPanel?.editingActive,
      isFirstMetricSourceCalculated,
      blanksFilter.externalFilter,
    ],
  )

  const icons: Record<string, string> = useMemo(
    () => ({
      [resolveFintasticAgIconKey(fintasticAgIcons.rows.name)]:
        fintasticAgIcons.rows.makeIcon({
          title: 'Show/hide blank rows',
        }),
    }),
    [],
  )

  const formulaMapping = useMemo<Maybe<ClientOnlyMapping>>(() => {
    if (!settingsPanel?.isNewMetric || !firstMetric?.metricId) {
      return null
    }
    return {
      metrics: [
        {
          id: firstMetric.metricId,
          label: firstMetric.metricLabel || '',
          source: firstMetric.metricSource || 'input',
        },
      ],
    }
  }, [
    firstMetric?.metricId,
    firstMetric?.metricLabel,
    firstMetric?.metricSource,
    settingsPanel?.isNewMetric,
  ])

  const periodResolution = usePeriodSelectorResolution()
  const errorUiMapping = useMemo(
    () =>
      !error
        ? null
        : mapMetricErrorToUi(error, {
            periodResolution,
          }),
    [error, periodResolution],
  )

  return (
    <BlanksFilterPanelContext.Provider value={blanksFilter}>
      <GridColumnColorsCssWrapper
        columnColors={columnColors}
        periods={periodValues}
      >
        <BaseGrid
          agColumnDefs={colDefs}
          editProps={editProps}
          eventHandlers={eventHandlers}
          fallbackGroupValueFormatter={fallbackGroupValueFormatter}
          customToolPanels={customToolPanels}
          getContextMenuItems={getContextMenuItems}
          rowData={isError ? [] : rowData}
          rootDataAttributes={rootDataAttributes}
          apiRef={gridApiRef}
          gridRef={gridRef}
          isLoading={isLoading}
          context={context}
          allowGrouping={isGroupingPossible}
          suppressMovableColumns={true}
          onCollapseStateChanged={widgetContext?.toggleCollapsedVert}
          collapsedFromOutside={widgetContext?.isCollapsedVert}
          setGridSizeCallback={setGridSizeCallback}
          enableTitleDateAutoFormatting={false}
          agContext={agContext}
          processDataFromClipboard={handlePasteClipboard}
          components={cellEditors}
          externalFilters={externalFilters}
          agIcons={icons}
          themeWrapperSx={{
            '&.blanks-filter-is-applied [data-icon="custom-rows"]': {
              background: 'var(--fintastic-applied-sidebar-button-bg)',
            },
            '&.column-filter-is-applied': {
              '[data-icon="native-filter"]': {
                background: 'var(--fintastic-applied-sidebar-button-bg)',
              },
            },
          }}
          themeWrapperClassNames={{
            'blanks-filter-is-applied': blanksFilter.blanksVisible,
          }}
          overlay={
            settingsPanel?.isSaving ? (
              <PanelLoadingOverlay text="Saving Metric" />
            ) : undefined
          }
          errorContent={
            isError && errorUiMapping ? (
              <ErrorAlert {...errorUiMapping}></ErrorAlert>
            ) : null
          }
          topbar={
            <>
              <TopBar
                title={titleText}
                titleIcon={
                  <Tooltip title="Metric">
                    <MetricIcon />
                  </Tooltip>
                }
                leftContent={leftContent}
                rightContent={rightContent}
              />
              {hasFormulas && (
                <FormulaCollapse isOpen={formulasOpened}>
                  {formulasOpened && (
                    <MetricFormulaEditorWrapper
                      versions={data}
                      onRequestClose={closeFormulas}
                      showVersionsSelector={!setGridSizeCallback}
                      formulaEditorTitle={
                        isLiveActuals ? 'Actuals Formula' : ''
                      }
                      onValidationFinished={
                        settingsPanel?.editingActive
                          ? settingsPanel?.onFormulaValidationFinished
                          : undefined
                      }
                      creationOfCalculatedMetric={
                        settingsPanel?.isNewMetric &&
                        settingsPanel.editingActive &&
                        isFirstMetricSourceCalculated
                      }
                      editingOfCalculatedMetric={
                        !settingsPanel?.isNewMetric &&
                        settingsPanel?.editingActive &&
                        isFirstMetricSourceCalculated
                      }
                      controlledFormulaError={
                        settingsPanel?.formulaError || null
                      }
                      isVersionPage={isVersionPage}
                      isSettingsEditingActive={settingsPanel?.editingActive}
                      clientOnlyMapping={formulaMapping || undefined}
                    />
                  )}
                </FormulaCollapse>
              )}
            </>
          }
          bottomBar={
            <PanelBottomBar>
              <SelectedCellAggregation />
            </PanelBottomBar>
          }
        />
      </GridColumnColorsCssWrapper>
    </BlanksFilterPanelContext.Provider>
  )
}

export const MetricGrid = memo(
  forwardRef<MetricGridApi, MetricGridProps>(MetricGridComponent),
)
