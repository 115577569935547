import { Token, TokenWithNode } from '../tokens/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { getCurrentFunction } from './function-helper/getCurrentFunction'
import { findTokenByCaret } from '../caret/caret-utils'
import { FintasticSyntaxApi } from '@fintastic/web/data-access/formulas'
import { TokenHelperContextValue } from './token-helper-context'

export function useTokenHelper(
  tokens: Token[],
  caretPosition: number,
  syntaxApi: Maybe<FintasticSyntaxApi>,
  getTokensNodes: Maybe<() => HTMLElement[]>,
): TokenHelperContextValue {
  const [token, setToken] = useState<Maybe<TokenWithNode>>(null)
  const [helperContent, setHelperContent] = useState<Maybe<string>>(null)

  const reset = useCallback(() => {
    setToken(null)
    setHelperContent(null)
  }, [])

  useEffect(() => {
    if (
      caretPosition === 0 ||
      tokens.length === 0 ||
      !syntaxApi?.getFunctionDocumentation ||
      !getTokensNodes
    ) {
      reset()
      return
    }

    const tokenAtCaret = findTokenByCaret(tokens, caretPosition)
    if (tokenAtCaret === null) {
      reset()
      return
    }

    const functionToken = getCurrentFunction(tokens, tokenAtCaret[1])
    if (functionToken === null) {
      reset()
      return
    }

    const documentation = syntaxApi.getFunctionDocumentation(
      functionToken[0].text,
    )
    if (documentation === null) {
      reset()
      return
    }

    const tokensNodes = getTokensNodes()
    if (tokensNodes.length - 1 < tokenAtCaret[1]) {
      reset()
      return
    }

    const editingTokenNode = tokensNodes[tokenAtCaret[1]]
    setHelperContent(documentation.structureHint)
    setToken({
      ...functionToken[0],
      node: editingTokenNode,
    })
  }, [tokens, caretPosition, getTokensNodes, syntaxApi, reset])

  return useMemo<TokenHelperContextValue>(
    () => ({
      token,
      helperContent,
      closeHelper: reset,
    }),
    [helperContent, reset, token],
  )
}
