import React from 'react'
import {
  InlineSelectDefaultValue,
  InlineSelectOption,
  InlineSelectValueVariants,
} from '../types'
import { StyledLabel, StyledExample } from './MenuItemContent.styled'

export type MenuItemProps<
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
> = {
  option: InlineSelectOption<TValue>
}

export const MenuItemContent = <
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
>({
  option,
}: MenuItemProps<TValue>): JSX.Element => (
  <>
    <StyledLabel variant="body1">{option.label}</StyledLabel>
    {option.example && (
      <StyledExample variant="overline" color="text.secondary">
        {option.example}
      </StyledExample>
    )}
  </>
)
