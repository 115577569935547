/* eslint-disable no-param-reassign */
import {
  AddPeriodsPayload,
  ChangeAggregationTypePayload,
  ChangePeriodTypePayload,
  EditingState,
  RemovePeriodsPayload,
  ResetActionPayload,
  ToggleAllPeriodsPayload,
  TurnOffRangePayload,
  TurnOnRangePayload,
  ChangeRangePayload,
} from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createPeriodsBasedSelection } from '../../selection-constructors/period-selection-fabric'
import {
  PeriodsBasedSelection,
  RangeBasedSelection,
  isPeriodBasedSelection,
  isRangeBasedSelection,
} from '@fintastic/web/util/period-selector'
import { WritableDraft } from '@fintastic/shared/data-access/redux'

export const initialState: EditingState = {
  selection: createPeriodsBasedSelection(),
}

const slice = createSlice({
  name: 'selectionPickerState',
  initialState,
  reducers: {
    reset: (s, a: PayloadAction<ResetActionPayload>) => {
      s.selection = a.payload as
        | WritableDraft<PeriodsBasedSelection>
        | WritableDraft<RangeBasedSelection>
    },
    changePeriodType: (s, a: PayloadAction<ChangePeriodTypePayload>) => {
      s.selection.dimensionId = a.payload.dimension_id

      if ('range' in a.payload) {
        ;(s.selection as RangeBasedSelection).range = a.payload.range
        delete (s.selection as Partial<PeriodsBasedSelection>).periods
      } else {
        ;(s.selection as PeriodsBasedSelection).periods = a.payload.periods
        delete (s.selection as Partial<RangeBasedSelection>).range
      }
    },
    turnOnRange: (s, a: PayloadAction<TurnOnRangePayload>) => {
      ;(s.selection as RangeBasedSelection).range = a.payload.range
      delete (s.selection as Partial<PeriodsBasedSelection>).periods
    },
    turnOffRange: (s, a: PayloadAction<TurnOffRangePayload>) => {
      ;(s.selection as PeriodsBasedSelection).periods = a.payload.periods
      delete (s.selection as Partial<RangeBasedSelection>).range
    },
    addPeriods: (s, a: PayloadAction<AddPeriodsPayload>) => {
      if (!isPeriodBasedSelection(s.selection)) {
        throw new Error('not a periods-based selection')
      }
      s.selection.periods.push(...a.payload)
    },
    removePeriods: (s, a: PayloadAction<RemovePeriodsPayload>) => {
      if (!isPeriodBasedSelection(s.selection)) {
        throw new Error('not a periods-based selection')
      }
      s.selection.periods = s.selection.periods.filter(
        (p) => !a.payload.includes(p),
      )
    },
    toggleAllPeriods: (s, a: PayloadAction<ToggleAllPeriodsPayload>) => {
      if (!isPeriodBasedSelection(s.selection)) {
        throw new Error('not a periods-based selection')
      }
      if (a.payload.apply) {
        s.selection.periods = a.payload.allPeriods
      } else {
        s.selection.periods = []
      }
    },
    changeRange: (s, a: PayloadAction<ChangeRangePayload>) => {
      if (!isRangeBasedSelection(s.selection)) {
        throw new Error('not a range-based selection')
      }
      s.selection.range[0] = a.payload.range[0]
      s.selection.range[1] = a.payload.range[1]
    },
    changeAggregationType: (
      s,
      a: PayloadAction<ChangeAggregationTypePayload>,
    ) => {
      s.selection.aggregationDimensionId = a.payload
    },
  },
})

export const { actions, reducer } = slice
