import { ColumnColor, ValueColumnColor, ValueColumnLocation } from './types'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { createValueColumnColor } from './constructors'

export const resolveValueColumnColor = (
  colors: ColumnColor[],
  location: ValueColumnLocation,
): ValueColumnColor =>
  (toMaybe(
    colors.find(
      (color) =>
        color.column.type === 'value' &&
        color.versionId === location.versionId &&
        color.column.metricId === location.metricId &&
        color.column.period === location.period,
    ),
  ) as Maybe<ValueColumnColor>) || createValueColumnColor(location)
