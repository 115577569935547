import { Box, styled } from '@mui/material'

export const StyledPlanningVersionSelectRoot = styled(Box)(({ theme }) => ({
  'svg': {
    marginRight: '5px',
  },
  '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
    borderRadius: 0,
  },
  '.MuiInputBase-root': {
    paddingTop: 6,
    paddingBottom: 6,
    height: 32,
    borderRadius: 8,
  },
  '& > div:nth-of-type(1)': {
    'backgroundColor': theme.palette.common.white,
    'transition': 'all .2s ease',
    '& > div:nth-of-type(1)': {
      display: 'flex',
    },
  },
  '& > div:nth-of-type(1):hover, & > div:nth-of-type(1):focus': {
    backgroundColor: theme.palette.action.hover,
  },
}))

export const LabelSelectText = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 695px;
  white-space: nowrap;
  margin-left: 0.5em;
`

export const StyledVersionTitle = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
  flex-flow: row nowrap;
  margin: ${({ theme }) => theme.spacing(1.7)} 0;
  padding: 0 16px;
`
