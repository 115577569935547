import { styled } from '@mui/material'

export const StyledTableContainer = styled('div')`
  flex-grow: 1;

  .ag-row .ag-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ag-header-cell.ag-header-cell-sortable {
    padding: 0 0 0 16px;
  }

  .fintastic-cell-editor.fintastic-cell-editor-select-box {
    width: 100%;
  }
`
