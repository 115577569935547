import { QueryClient } from 'react-query'
import { versionsCacheKeys, versionsListRefreshKeys } from './cache-keys'

export async function invalidateVersionsListCache(queryClient: QueryClient) {
  const invalidators = versionsListRefreshKeys.map((key) =>
    // targeted
    queryClient.invalidateQueries(key),
  )
  invalidators.push(
    // full
    queryClient.invalidateQueries({
      queryKey: [versionsCacheKeys.versionsList()[0]],
      exact: false,
    }),
  )

  return Promise.all(invalidators)
}
