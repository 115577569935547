import { ExternalFilter } from './types'

export const combineExternalFilters = <TData = any>(
  filters: ExternalFilter<TData>[],
): ExternalFilter<TData> => {
  const enabled: boolean[] = []

  return {
    isExternalFilterPresent: (params) => {
      enabled.splice(0, enabled.length)
      filters.forEach((f) => {
        enabled.push(f.isExternalFilterPresent(params))
      })
      return enabled.some((enabled) => enabled)
    },
    doesExternalFilterPass: (node) => {
      const enabledFilters = filters.filter((f, i) => enabled[i])
      if (enabledFilters.length === 0) {
        return true
      }
      // it's more efficient to check that some enabled filter is not passed
      return !enabledFilters.some((f) => !f.doesExternalFilterPass(node))
    },
  }
}
