import { MetricDisplaySettingsFormatting } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

export type CompactFormattingOption = {
  value: Maybe<
    Exclude<MetricDisplaySettingsFormatting['compact_formatting'], 'no_format'>
  >
  label: string
  example: Maybe<string>
}

export const compactFormattingOptions: CompactFormattingOption[] = [
  {
    value: null,
    label: 'None',
    example: null,
  },
  {
    value: 'thousands',
    label: '1,000 → 1K',
    example: '10K',
  },
  {
    value: 'millions',
    label: '1,000,000 → 1M',
    example: '10M',
  },
]
