import { useCallback, useMemo } from 'react'
import { ChangeCurrencySignPositionAction } from '../types'
import { useMetricsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeCurrencySignPositionFlow() {
  const { changeCurrencySignPosition } = useMetricsActions()

  const handleAction = useCallback<ChangeCurrencySignPositionAction>(
    (position) => {
      changeCurrencySignPosition({ position })
    },
    [changeCurrencySignPosition],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
