import { toastErrorHandler } from '@fintastic/shared/ui/toast-framework'
import { notificationMessages } from './messages'
import { AxiosError } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'

export function notifyMetricUpdateFailed(
  error: AxiosError | Error,
  type: 'metric' | 'list',
) {
  const status = (error as AxiosError)?.response?.status

  if (status === 403) {
    toast.error(notificationMessages[type].noAccessToEdit)
    return
  }

  if ((status !== undefined && status >= 500) || status === 0) {
    toast.error(notificationMessages[type].technicalError)
    return
  }

  toastErrorHandler(error, undefined, notificationMessages[type].failed)
}
