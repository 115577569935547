import type { PayloadAction } from '@reduxjs/toolkit'
import type { WritableDraft } from '@fintastic/shared/data-access/redux'
import type { State } from './state'

// eslint-disable-next-line @typescript-eslint/no-empty-function
function emitStart(): void {}

function start(state: WritableDraft<State>): void {
  state.status = 'loading'
  state.error = null
}

function finish(
  state: WritableDraft<State>,
  action: PayloadAction<{ error: State['error'] }>,
): void {
  state.status = action.payload.error ? 'error' : 'success'
  state.error = action.payload.error
  if (action.payload.error) {
    console.error(
      `notifications subscription cancelled or wasn't created; reason: ${JSON.stringify(
        action.payload.error,
      )}`,
    )
  }
}

export default {
  emitStart,
  start,
  finish,
}
