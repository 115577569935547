import React, { useCallback, useMemo, useState } from 'react'
import { MetricChartTopBar } from './MetricChartTopBar/MetricChartTopBar'
import { MetricChartTopBarLeftContent } from './MetricChartTopBar/MetricChartTopBarLeftContent'
import { MetricChartTopBarRightContent } from './MetricChartTopBar/MetricChartTopBarRightContent'
import { MetricChartFormula } from './MetricChartFormula/MetricChartFormula'
import {
  useHasMetricFormula,
  useMetricGridDataProps,
  useMetricInVersions,
  useWeightedAverageMetrics,
} from '@fintastic/web/feature/metrics-and-lists'
import {
  useLoadVersionsList,
  useVersionEntitiesContextValue,
  useVersionsListMap,
} from '@fintastic/web/data-access/versions'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useBaseTimeDimensionCurrentValue } from '@fintastic/web/data-access/base-time-dimension'
import { titleFormatter } from '@fintastic/shared/util/formatters'

export type MetricChartTopPanelProps = {
  title?: string
  metricId: string
  versions: string[]
  isDesignMode?: boolean
  periodSelectionValue: PeriodSelection
  collapseButton?: React.ReactNode
  isCollapsedVert?: boolean
  invalidateQueries: () => void
  showDebugPanels: boolean
  periodSelectorComponent?: React.ReactNode
}

export const MetricChartTopPanel: React.FC<MetricChartTopPanelProps> = ({
  title,
  metricId,
  isDesignMode,
  versions,
  periodSelectionValue,
  collapseButton,
  isCollapsedVert,
  showDebugPanels,
  invalidateQueries,
  periodSelectorComponent,
}) => {
  const [formulasOpened, setFormulasOpened] = useState(false)

  const toggleFormulas = useCallback(() => {
    setFormulasOpened((prev) => !prev)
  }, [])

  const versionsListQuery = useLoadVersionsList({
    versionsIds: versions,
    withLiveActuals: true,
  })

  const versionsMetadata = useVersionsListMap(
    useMemo(() => versionsListQuery.data || [], [versionsListQuery.data]),
  )

  const metricInVersionsQuery = useMetricInVersions(
    versions,
    metricId,
    true,
    periodSelectionValue,
  )

  const existingMetrics = useMemo(
    () =>
      metricInVersionsQuery.metricsWithVersion.filter(
        (versionMetric) => !!versionMetric.metric,
      ),
    [metricInVersionsQuery.metricsWithVersion],
  )

  const weightMetrics = useWeightedAverageMetrics(existingMetrics)

  const entitiesContextValue = useVersionEntitiesContextValue(versions)

  const dataProps = useMetricGridDataProps(
    metricId,
    existingMetrics,
    versionsMetadata,
    weightMetrics.isLoading ? undefined : weightMetrics.weightMetrics,
    entitiesContextValue.entities,
  )

  const hasFormulas = useHasMetricFormula(dataProps.data)

  const baseTimeDimension = useBaseTimeDimensionCurrentValue()

  const showPeriodSelector = !!baseTimeDimension

  return (
    <>
      <MetricChartTopBar
        title={titleFormatter(title)}
        leftContent={
          <MetricChartTopBarLeftContent
            metricId={metricId}
            title={titleFormatter(title)}
            isDesignMode={isDesignMode}
            hasFormulas={hasFormulas}
            toggleFormulas={toggleFormulas}
            formulasOpened={formulasOpened}
            draggable={Boolean(collapseButton)}
            collapsible={Boolean(collapseButton)}
            collapseButton={collapseButton}
          />
        }
        rightContent={
          <MetricChartTopBarRightContent
            showDebugPanels={showDebugPanels}
            periodSelectorComponent={
              showPeriodSelector ? periodSelectorComponent : undefined
            }
            invalidateQueries={invalidateQueries}
          />
        }
      />

      <MetricChartFormula
        versions={dataProps.data}
        onRequestClose={() => setFormulasOpened(false)}
        formulasOpened={formulasOpened}
        hasFormulas={hasFormulas}
        isCollapsedVert={Boolean(isCollapsedVert)}
      />
    </>
  )
}
