import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { widgetTypeToName } from '../../widgets/board-design-type-utils'
import { ModalWithParamsState } from '@fintastic/shared/util/modal'
import {
  ChangeWidgetDialogParams,
  usePendingErrors,
  usePendingProcesses,
  validateWidgetSettings,
  WidgetSettingsDialogContext,
  WidgetSettingsDialogContextValue,
} from '@fintastic/web/feature/boards'
import { TEXT_WIDGET_TYPE, WidgetSettingsDialog } from '../../../widgets'
import { Maybe } from '@fintastic/shared/util/types'
import { isEqual } from 'lodash'

type ChangeWidgetProps = {
  state: ModalWithParamsState<ChangeWidgetDialogParams>
  onConfirm: (params: ChangeWidgetDialogParams) => void
}

const isSubObjectPartiallyEqualsParent = (
  maybeSubObject: Maybe<Record<string, unknown>>,
  parent?: Maybe<Record<string, unknown>>,
) => {
  if (!maybeSubObject) {
    return true // null or undefined
  }

  if (!parent) {
    return false
  }

  for (const key in maybeSubObject) {
    if (parent[key] !== maybeSubObject[key]) {
      return false
    }
  }
  return true
}

export const ChangeWidgetDialog: React.FC<ChangeWidgetProps> = ({
  state,
  onConfirm,
}) => {
  const title = useMemo(
    () =>
      state.params.widgetId
        ? `${widgetTypeToName(state.params?.type || TEXT_WIDGET_TYPE)} settings`
        : `Add ${widgetTypeToName(
            state.params?.type || TEXT_WIDGET_TYPE,
          )} widget`,
    [state.params?.type, state.params.widgetId],
  )

  const [valid, setValid] = useState(false)
  const [changed, setChanged] = useState(false)

  const [actualValue, setActualValue] = useState<
    Partial<ChangeWidgetDialogParams> | undefined
  >(undefined)

  const pendingApi = usePendingProcesses()
  const errorsApi = usePendingErrors()

  const handlePartialUpdate = useCallback(
    (update: Partial<ChangeWidgetDialogParams>) => {
      if (!actualValue || Object.keys(actualValue).length === 0) {
        // value is not yet established - skip
        return
      }

      if (isSubObjectPartiallyEqualsParent(update, actualValue)) {
        return
      }
      const newValue = {
        ...actualValue,
        ...update,
      }

      setChanged(() => !isEqual(initialSnapshot.current, newValue))

      setActualValue(newValue)
    },
    [actualValue],
  )

  const handleConfirm = useCallback(() => {
    if (actualValue) {
      onConfirm({ ...state.params, ...actualValue })
    }
    state.close()
  }, [actualValue, onConfirm, state])

  const initialSnapshot = useRef<Partial<ChangeWidgetDialogParams>>({})

  useMemo(() => {
    if (!state.isOpen) {
      setValid(false)
      setActualValue(undefined)
      initialSnapshot.current = {}
      setChanged(false)
    } else {
      setActualValue((v) => (v ? v : { ...state.params }))
      pendingApi.finishAll()
      errorsApi.clearAll()
      setChanged(false)
      initialSnapshot.current = state.params
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isOpen, state.params])

  const widgetSettingsDialogContextValue =
    useMemo<WidgetSettingsDialogContextValue>(
      () =>
        ({
          data: actualValue || {},
          title,
          isNew: !state.params.widgetId,
          isChanged: changed,
          valid,
          onConfirm: handleConfirm,
          onCancel: state.close,
          setPartialData: handlePartialUpdate,
          pendingApi,
          errorsApi,
        } as const),
      [
        actualValue,
        title,
        state.params.widgetId,
        state.close,
        changed,
        valid,
        handleConfirm,
        handlePartialUpdate,
        pendingApi,
        errorsApi,
      ],
    )

  useEffect(() => {
    setValid(
      validateWidgetSettings(actualValue) && !pendingApi.isAnyPendingProcess,
    )
  }, [actualValue, pendingApi.isAnyPendingProcess])

  if (!state.isOpen) {
    return null
  }

  return (
    <WidgetSettingsDialogContext.Provider
      value={widgetSettingsDialogContextValue}
    >
      <WidgetSettingsDialog widgetType={state.params.type} />
    </WidgetSettingsDialogContext.Provider>
  )
}
