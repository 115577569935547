import { Box, ButtonBase, Menu, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export const StyledMain = styled(Box)``

export const StyledMainButton = styled(ButtonBase)`
  padding: 4px 4px 4px 8px;

  white-space: nowrap;

  border-radius: ${({ theme }) => theme.shape.borderRadius / 2}px;
  transition: ${({ theme }) => theme.transitions.create('all')};

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  i {
    font-style: normal;

    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledMainButtonIcon = styled('span')`
  ${styledMixins.alignContentCenter()};

  margin-left: 4px;
`

export const StyledSecondary = styled(Box)`
  margin-left: 4px;
`

export const StyledAggregationButton = styled(ButtonBase, {
  shouldForwardProp: (p) => p !== 'opened',
})<{ opened: boolean }>`
  ${styledMixins.alignContentCenter()};
  padding: 4px 4px 4px 8px;
  height: 32px;

  white-space: nowrap;

  border-radius: ${({ theme }) => theme.shape.borderRadius / 2}px;
  transition: ${({ theme }) => theme.transitions.create('all')};

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

export const StyledAggregationButtonText = styled('span')`
  ${styledMixins.alignContentCenter()};

  .MuiTypography-root {
    line-height: 1.143;
  }
`

export const StyledAggregationButtonIcon = styled('span')`
  ${styledMixins.alignContentCenter()};

  margin-left: 4px;
`

export const StyledAggregationMenu = styled(Menu)`
  margin-top: ${({ theme }) => theme.spacing(1)};

  .MuiMenu-list {
    min-width: 82px;
  }
`
