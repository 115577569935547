import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import {
  FilterContext,
  useFilterContextValue,
} from '@fintastic/web/util/filters'
import { UseListFilterParams, useListFilters } from './useListFilters'
import { invalidateListFilterRelatedData } from '../utils/invalidate-list-data'
import { useQueryClient } from 'react-query'
import { useTenantOverride } from '@fintastic/web/data-access/service-auth0'

export const ListFilterContextProvider: React.FC<
  PropsWithChildren<UseListFilterParams>
> = ({ children, listId, versionId, visibleColumnIds }) => {
  const filters = useListFilters({ listId, versionId, visibleColumnIds })
  const queryClient = useQueryClient()

  const handleBeforeFilterApply = useCallback(() => {
    invalidateListFilterRelatedData(queryClient, versionId, listId)
  }, [listId, queryClient, versionId])

  const { tenantName } = useTenantOverride()

  const localStorageKey = useMemo(
    () => `list-filter_${tenantName}_${listId}`,
    [listId, tenantName],
  )

  const listFiltersContextValue = useFilterContextValue({
    filters,
    versionId,
    onBeforeFilterApply: handleBeforeFilterApply,
    localStorageKey,
  })

  return (
    <FilterContext.Provider value={listFiltersContextValue}>
      {children}
    </FilterContext.Provider>
  )
}
