/**
 Types for describe duck state and extend root state
 */

import { BoardFolder } from '../../types'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'boardsUi' as const

// extending root state to use it in selectors
export type RootState = {
  [namespace]: State
}

// duck state
export type State = {
  expandedFolders: Record<BoardFolder['id'], boolean | undefined>
  boardMode: 'display' | 'design'
}

export const initialState: State = {
  expandedFolders: {},
  boardMode: 'display',
}
