import { AxiosError } from 'axios'
import { Nullable } from '@fintastic/shared/util/functional-programming'
import { DimensionPermissionsForLists } from '@fintastic/web/data-access/metrics-and-lists'
import { DimensionId, DimensionValueId } from '@fintastic/web/util/dimensions'

export type ListDimensionsPermissionsApi = Readonly<ReturnType<typeof makeApi>>

export const makeApi = (
  listId: string,
  data:
    | {
        isPowerUser: false
        allListsPermissions: {
          isLoading: boolean
          error: Nullable<AxiosError | Error>
          data: Nullable<DimensionPermissionsForLists>
        }
      }
    | {
        isPowerUser: true
      },
) => {
  if (data.isPowerUser) {
    return {
      noRestrictions: true,
    } as const
  }

  if (data.allListsPermissions.error) {
    return {
      error: true,
      ready: false,
      errorMessage: 'Cannot load dimensions permissions',
    } as const
  }

  if (data.allListsPermissions.isLoading || !data.allListsPermissions.data) {
    return {
      loading: true,
      ready: false,
    } as const
  }

  const permissions = data.allListsPermissions.data
  const unrestrictedDims: Record<DimensionId, true> = Object.fromEntries(
    permissions.unrestricted_dim_ids.map((dimId) => [dimId, true]),
  )

  const listPermissions = permissions.lists.find((p) => p.list_id === listId)

  const listAllowedDimValues =
    listPermissions?.allowed_dim_value_ids || permissions.allowed_dim_value_ids
  const listAllowedDimValuesMap: Record<DimensionValueId, true> =
    Object.fromEntries(listAllowedDimValues.map((dimValId) => [dimValId, true]))

  const allDimValuesAreNonMasking =
    listPermissions?.non_masking_dim_value_ids === '*'
  const listNonMaskingDimValues =
    (allDimValuesAreNonMasking
      ? []
      : (listPermissions?.non_masking_dim_value_ids as DimensionValueId[])) ||
    []
  const listNonMaskingDimValuesMap: Record<DimensionValueId, true> =
    allDimValuesAreNonMasking
      ? {}
      : Object.fromEntries(
          listNonMaskingDimValues.map((dimValId) => [dimValId, true]),
        )

  return {
    ready: true,
    dimensionHasRestrictions: (dimensionId: DimensionId): boolean =>
      !unrestrictedDims[dimensionId],
    dimensionValueAllowedToBeChosen: (dimValueId: DimensionValueId): boolean =>
      !!listAllowedDimValuesMap[dimValueId] &&
      (listPermissions
        ? allDimValuesAreNonMasking || !!listNonMaskingDimValuesMap[dimValueId]
        : true),
  } as const
}
