import { styled } from '@mui/material'

export const StyledActionType = styled('div')`
  display: flex;
  flex-flow: column wrap;

  .MuiButtonBase-root {
    background: #fff;
  }
`
