import { KeyboardEventHandler, useCallback, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { CaretMovementReason } from '../caret/types'
import { KeyEventInterceptor } from './types'
import { IndentationApi } from '../indentation/useIndentation'

export function useKeyboardEventHandlers({
  trackCaretPosition,
  keyDownEventInterceptor,
  indentationApi,
}: {
  trackCaretPosition: (reason: Maybe<CaretMovementReason>) => void
  keyDownEventInterceptor?: KeyEventInterceptor
  indentationApi: IndentationApi
}) {
  const handleKeyEvent: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown'
      ) {
        trackCaretPosition('navigation')
      }
    },
    [trackCaretPosition],
  )

  const handleKeyUpEvent: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      handleKeyEvent(event)
    },
    [handleKeyEvent],
  )

  const handleKeyDownEvent: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (keyDownEventInterceptor) {
        const shouldStopExecution = keyDownEventInterceptor(event)
        if (shouldStopExecution) {
          return
        }
      }
      handleKeyEvent(event)
    },
    [handleKeyEvent, keyDownEventInterceptor],
  )

  const handleNativeKeyDownEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        event.stopPropagation()
        event.shiftKey
          ? indentationApi.decreaseIndentation()
          : indentationApi.increaseIndentation()
      }
    },
    [indentationApi],
  )

  return useMemo(
    () =>
      ({
        handleKeyUpEvent,
        handleKeyDownEvent,
        handleNativeKeyDownEvent,
      } as const),
    [handleKeyDownEvent, handleKeyUpEvent, handleNativeKeyDownEvent],
  )
}
