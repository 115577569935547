import {
  CalendarDatePickerConfig,
  CalendarDatePickerConfigValueItem,
} from '../types'

const getMonthByWeek = (
  weekIndex: number,
): CalendarDatePickerConfigValueItem => {
  const monthIndex = Math.ceil((weekIndex + 1) / 4) - 1
  const month = months[monthIndex]

  if (!month) {
    throw new Error('Cannot find month for weekly calendar')
  }

  return month
}

const getYearByQuarter = (
  quarterIndex: number,
): CalendarDatePickerConfigValueItem => {
  if (quarterIndex === 4) {
    return years[1]
  }

  return years[0]
}

const getQuarterByMonth = (
  monthIndex: number,
): CalendarDatePickerConfigValueItem => {
  const q = quarters[Math.ceil((monthIndex + 1) / 3) - 1]

  if (!q) {
    throw new Error('Cannot find quarter for weekly calendar')
  }

  return q
}

const years: CalendarDatePickerConfigValueItem[] = [
  {
    dimension_value_id: 'Dim.year.2022',
    groups: [],
    label: '2022',
  },
  {
    dimension_value_id: 'Dim.year.2023',
    groups: [],
    label: '2023',
  },
]

const quarters = new Array(5)
  .fill(null)
  .map<CalendarDatePickerConfigValueItem>((_, quarterIndex) => ({
    dimension_value_id: `Dim.quarter.${quarterIndex}`,
    groups: [getYearByQuarter(quarterIndex).dimension_value_id],
    label: `Q${quarterIndex + 1}`,
  }))

const months = new Array(13)
  .fill(null)
  .map<CalendarDatePickerConfigValueItem>((_, monthIndex) => ({
    dimension_value_id: `Dim.month.${monthIndex}`,
    groups: [getQuarterByMonth(monthIndex).dimension_value_id],
    label: `M${monthIndex + 1}`,
  }))

const weeks = new Array(48 + 4)
  .fill(null)
  .map<CalendarDatePickerConfigValueItem>((_, weekIndex) => ({
    dimension_value_id: `Dim.week.${weekIndex}`,
    groups: [getMonthByWeek(weekIndex).dimension_value_id],
    label: `W${weekIndex + 1}`,
  }))

// Last week in the year spreads to the next month
weeks[47].groups.push(months[12].dimension_value_id)

// 13 months divided by 4 week each
const simpleCalendarMock: CalendarDatePickerConfig = [
  {
    dimension_id: 'Dim.week',
    label: 'Week',
    resolution: 'week',
    today: 'Dim.week.7',
    values: weeks,
  },
  {
    dimension_id: 'Dim.month',
    label: 'Month',
    resolution: 'month',
    today: 'Dim.month.1',
    values: months,
  },
  {
    dimension_id: 'Dim.quarter',
    label: 'Quarter',
    resolution: 'quarter',
    today: 'Dim.quarter.0',
    values: quarters,
  },
  {
    dimension_id: 'Dim.year',
    label: 'Year',
    resolution: 'year',
    today: 'Dim.year.2022',
    values: years,
  },
]

export const PeriodSelectorTestUtils = {
  simpleCalendar: {
    mock: simpleCalendarMock,
    dimensions: {
      week: 'Dim.week',
      month: 'Dim.month',
      quarter: 'Dim.quarter',
      year: 'Dim.year',
    },
  },
}
