import React, { useMemo } from 'react'
import { Topbar } from '../Topbar'
import {
  StyledPageLayoutContainer,
  StyledPageLayoutRoot,
} from './PageLayout.styled'
import {
  pageLayoutContext,
  PageLayoutContextValue,
} from '../../contexts/page-layout-context'

import { useCloseLateralOnDestroy } from '../../ducks/layout/hooks-common'

export type PageLayoutProps = {
  children: React.ReactNode
  topbar?: React.ReactNode
  centerContent?: boolean
  disablePaddingVertical?: boolean
  disablePaddingBottom?: boolean
  disablePaddingTop?: boolean
  disablePaddingHorizontal?: boolean
  disableScrollX?: boolean
  disableScrollY?: boolean
}

export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const {
    children,
    topbar,
    centerContent = false,
    disablePaddingVertical = false,
    disablePaddingBottom,
    disablePaddingTop,
    disablePaddingHorizontal = false,
    disableScrollX = false,
    disableScrollY = false,
  } = props

  const contextValue = useMemo<PageLayoutContextValue>(
    () => ({
      centerContent,
      disablePaddingVertical,
      disablePaddingBottom,
      disablePaddingTop,
      disablePaddingHorizontal,
      disableScrollX,
      disableScrollY,
    }),
    [
      centerContent,
      disablePaddingBottom,
      disablePaddingHorizontal,
      disablePaddingTop,
      disablePaddingVertical,
      disableScrollX,
      disableScrollY,
    ],
  )

  useCloseLateralOnDestroy()

  return (
    <pageLayoutContext.Provider value={contextValue}>
      <StyledPageLayoutRoot
        data-testid="page-layout"
        sx={{
          ...(centerContent
            ? {
                display: 'flex',
              }
            : {}),
          ...(disableScrollY
            ? {
                overflowY: 'hidden',
              }
            : {}),
          ...(disableScrollX
            ? {
                overflowX: 'hidden',
              }
            : {}),
        }}
      >
        <StyledPageLayoutContainer
          sx={{
            ...(centerContent
              ? {
                  margin: 'auto',
                }
              : {}),
            ...(disablePaddingVertical
              ? {
                  paddingTop: 0,
                  paddingBottom: 0,
                }
              : {}),
            ...(disablePaddingHorizontal
              ? {
                  paddingLeft: 0,
                  paddingRight: 0,
                }
              : {}),
            ...(disablePaddingTop
              ? {
                  paddingTop: 0,
                }
              : {}),
            ...(disablePaddingBottom
              ? {
                  paddingBottom: 0,
                }
              : {}),
          }}
        >
          {children}
        </StyledPageLayoutContainer>
      </StyledPageLayoutRoot>
      {topbar || <Topbar />}
    </pageLayoutContext.Provider>
  )
}
