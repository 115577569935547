import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeListTimeDimensionPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeListTimeDimension = (
  uncheckedState: State,
  {
    payload: { dimensionId, allDimensions },
  }: PayloadAction<ChangeListTimeDimensionPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  if (state.flow !== 'creation' && state.list._initiallyHadBaseTimeDimension) {
    throw new Error(
      'changing of time dimension allowed only during the list creation',
    )
  }

  list.changeBaseTimeDimension(dimensionId, allDimensions)
}
