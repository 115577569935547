import { IAMResourceType } from '../types'
import type { Maybe } from '@fintastic/shared/util/types'

export const iamQueryKeyRoot = 'iam'

export const iamQueryKeys = {
  resources: (resource_type: Maybe<IAMResourceType>) => [
    iamQueryKeyRoot,
    'resources',
    resource_type,
  ],
  mutateResource: () => [iamQueryKeyRoot, 'mutate', 'resources'],
  userList: () => [iamQueryKeyRoot, 'users'],
  mutateUserList: () => [iamQueryKeyRoot, 'mutate', 'users'],
}
