import React, { useMemo, useRef } from 'react'
import {
  InlineSelectChangeHandler,
  InlineSelectOption,
  InlineSelectValueVariants,
  InlineSelectDefaultValue,
  InlineSelectRenderButtonProp,
  InlineSelectRenderButtonPropParams,
} from './types'
import { StyledRoot, StyledLabel } from './InlineSelectWithLabel.styled'
import { Maybe } from '@fintastic/shared/util/types'
import { useModalState } from '@fintastic/shared/util/modal'
import { useButtonLabel } from './Button/useButtonLabel'
import { Button } from './Button'
import { Menu } from './Menu'

export type InlineSelectWithLabelProps<
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
> = {
  options: InlineSelectOption<TValue>[]
  value: TValue
  onChange: InlineSelectChangeHandler<TValue>
  disabled?: boolean
  label?: React.ReactNode
  renderButton?: InlineSelectRenderButtonProp
  useExampleAsButtonLabel?: boolean
  menuMinWidth?: number
}

const defaultRenderButtonProp: InlineSelectRenderButtonProp = ({
  requestOpen,
  disabled,
  selectedOptionLabel,
}) => (
  <Button disabled={disabled} onClick={requestOpen}>
    {selectedOptionLabel}
  </Button>
)

export const InlineSelectWithLabel = <
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
>({
  options,
  value,
  onChange,
  disabled,
  label,
  renderButton = defaultRenderButtonProp,
  useExampleAsButtonLabel = true,
  menuMinWidth,
}: InlineSelectWithLabelProps<TValue>): JSX.Element => {
  const rootRef = useRef<Maybe<HTMLElement>>(null)
  const { isOpen, open, close } = useModalState()
  const buttonLabel = useButtonLabel(options, value, useExampleAsButtonLabel)

  const renderButtonProps = useMemo<InlineSelectRenderButtonPropParams>(
    () => ({
      requestOpen: open,
      requestClose: close,
      isOpen,
      selectedOptionLabel: buttonLabel,
      disabled: !!disabled,
    }),
    [buttonLabel, close, disabled, isOpen, open],
  )

  return (
    <>
      <StyledRoot ref={rootRef}>
        {label && <StyledLabel variant="body1">{label}</StyledLabel>}
        {renderButton(renderButtonProps)}
      </StyledRoot>
      {!disabled && (
        <Menu
          options={options}
          value={value}
          onClose={close}
          isOpen={isOpen}
          onChange={onChange}
          anchorElementRef={rootRef}
          minWidth={menuMinWidth}
        />
      )}
    </>
  )
}
