import { useMemo } from 'react'
import { useDataContext } from '../../connection/data/data-context'
import { useActionsContext } from '../../connection/actions/actions-context'
import { AggregationSettingsProps } from '../../../shared/settings/aggregation-function/types'

export function useTimeAggregation() {
  const { metric } = useDataContext()
  const {
    actions: { changeTimeAggregation },
  } = useActionsContext()

  const rollUpFunction = metric?.timeAggregation() || 'sum'
  const weightsMetricId = metric?.weightsMetricId() || undefined

  return useMemo<AggregationSettingsProps>(
    () => ({
      rollUpFunction,
      weightsMetricId:
        rollUpFunction === 'weighted_avg' ? weightsMetricId : undefined,
      onChange: changeTimeAggregation,
    }),
    [changeTimeAggregation, rollUpFunction, weightsMetricId],
  )
}
