import { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { Column, ColumnApi, IHeaderParams, GridApi } from 'ag-grid-community'
import { MouseEvent } from 'react'

export type OrderType = 'asc' | 'desc'

const orderType: Maybe<OrderType>[] = ['asc', 'desc', null]

// @todo add tests
const getNextOrder = (order: Maybe<OrderType>): Maybe<OrderType> => {
  const index = orderType.indexOf(order)
  return index + 1 > orderType.length - 1 ? orderType[0] : orderType[index + 1]
}

// @todo add tests
export function useSortState(
  enabled: boolean,
  column: Column,
  setSort: IHeaderParams['setSort'],
  api: GridApi,
  columnApi: ColumnApi,
) {
  const [order, serOrder] = useState<Maybe<OrderType>>(null)
  const [sortIndex, setSortIndex] = useState<Maybe<number>>(null)

  const handleSortChanged = useCallback(() => {
    if (!enabled) {
      return
    }
    let newOrder: Maybe<OrderType> = null
    if (column.isSortAscending()) {
      newOrder = 'asc'
    }
    if (column.isSortDescending()) {
      newOrder = 'desc'
    }
    serOrder(newOrder)
    const newSortIndex = column.getSortIndex()
    const sortedColumnsNumber = columnApi
      .getColumnState()
      .filter((c) => c.sort !== null).length
    setSortIndex(
      sortedColumnsNumber < 2 || newSortIndex === undefined
        ? null
        : newSortIndex,
    )
  }, [column, columnApi, enabled])

  const handleSortButtonClick = useCallback(
    (
      newOrder: Maybe<OrderType> | undefined,
      event: MouseEvent<HTMLElement>,
    ) => {
      if (!enabled) {
        return
      }
      const orderToUse =
        newOrder === undefined
          ? getNextOrder(column.getSort() || null)
          : newOrder
      setSort(orderToUse, event.shiftKey)
    },
    [column, enabled, setSort],
  )

  useEffect(() => {
    api.addEventListener('sortChanged', handleSortChanged)
  }, [api, column, handleSortChanged])

  return useMemo(
    () => ({
      order,
      handleSortButtonClick,
      sortIndex,
    }),
    [handleSortButtonClick, order, sortIndex],
  )
}
