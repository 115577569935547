import React, { PropsWithChildren } from 'react'
import { StyledChartTab } from './ChartTabs.styled'

export type ChartTabProp = {
  id: string
  icon: React.ReactNode
  title?: string
  disabled?: boolean
  highlight?: boolean
} & PropsWithChildren

export const ChartTab: React.FC<ChartTabProp> = ({ id, icon, title }) => (
  <StyledChartTab key={id}></StyledChartTab>
)
