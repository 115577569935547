import { DeeplinkKey, DeeplinkValue } from '../types'

let deeplinkCacheValue: Partial<Record<DeeplinkKey, DeeplinkValue>> = {}

let currentDeeplinkCacheKey = ''

export const DeeplinkCache = {
  cacheEntryExists(urlSearch: string, key: DeeplinkKey) {
    if (currentDeeplinkCacheKey !== urlSearch) {
      return false
    }

    return key in deeplinkCacheValue
  },

  setCacheEntry<T extends DeeplinkValue | undefined = DeeplinkValue>(
    urlSearch: string,
    key: DeeplinkKey,
    value: T,
  ) {
    if (currentDeeplinkCacheKey !== urlSearch) {
      deeplinkCacheValue = {}
      currentDeeplinkCacheKey = urlSearch
    }

    deeplinkCacheValue[key] = value
  },

  getCacheEntry<T extends DeeplinkValue = DeeplinkValue>(
    urlSearch: string,
    key: DeeplinkKey,
  ): T | undefined {
    if (urlSearch !== currentDeeplinkCacheKey) {
      return undefined
    }

    return deeplinkCacheValue[key] as T
  },
}
