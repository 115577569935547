import type { ICellRendererParams } from 'ag-grid-community'
import React from 'react'
import { AddLinesStateValuesRecord } from '@fintastic/web/feature/list-add-lines'
import { ValueInvalidIcon, ValueValidIcon } from '@fintastic/shared/ui/icons'
import { ValidityCellRoot } from './ValidityCellRenderer.styled'

export const ValidityCellRenderer = (
  params: ICellRendererParams & AddLinesStateValuesRecord,
) => {
  if (typeof params.value !== 'boolean') {
    return <ValidityCellRoot></ValidityCellRoot>
  }

  if (!params.value && params.colDef?.cellRendererParams?.onlyValid) {
    return <ValidityCellRoot></ValidityCellRoot>
  }

  if (params.value && params.colDef?.cellRendererParams?.onlyInvalid) {
    return <ValidityCellRoot></ValidityCellRoot>
  }

  return (
    <ValidityCellRoot valid={params.value}>
      {params.value ? <ValueValidIcon /> : <ValueInvalidIcon />}
    </ValidityCellRoot>
  )
}
