import { NotificationsApi } from './types'
import { useMemo } from 'react'
import { showChangedDiscardedToast } from './showChangedDiscardedToast'
import { showSavingSucceedToast } from './showSavingSucceedToast'
import { showSavingFailedToast } from './showSavingFailedToast'

export function useNotificationsApi(): NotificationsApi {
  return useMemo(
    () => ({
      showChangesDiscarded: showChangedDiscardedToast,
      showSavingSucceed: showSavingSucceedToast,
      showSavingFailed: showSavingFailedToast,
    }),
    [],
  )
}
