import React, { useMemo } from 'react'
import { OrderType } from './useSortState'
import { Maybe } from '@fintastic/shared/util/types'

export type SortProps = {
  order: Maybe<OrderType>
  sortIndex: Maybe<number>
}

export const Sort: React.FC<SortProps> = ({ order, sortIndex }) => {
  const sortIndexPart = useMemo(
    () =>
      sortIndex !== null ? (
        <span className="ag-sort-indicator-icon ag-sort-order">
          {sortIndex + 1}
        </span>
      ) : null,
    [sortIndex],
  )
  const iconPart = useMemo(() => {
    if (order === 'asc') {
      return (
        <span className="ag-sort-indicator-icon ag-sort-ascending-icon">
          <span
            className="ag-icon ag-icon-asc"
            unselectable="on"
            role="presentation"
          ></span>
        </span>
      )
    }
    if (order === 'desc') {
      return (
        <span className="ag-sort-indicator-icon ag-sort-descending-icon">
          <span
            className="ag-icon ag-icon-desc"
            unselectable="on"
            role="presentation"
          ></span>
        </span>
      )
    }
    return null
  }, [order])

  return (
    (sortIndexPart || iconPart) && (
      <span className="ag-sort-indicator-container">
        {sortIndexPart}
        {iconPart}
      </span>
    )
  )
}
