import React from 'react'
import { TokenType } from './types'
import { StyledTokenChipRoot } from './TokenChip.styled'
import { useTokenValidation } from '../validation/useTokenValidation'

export type TokenChipProps = {
  type: TokenType
  text: string
  noPaddings?: boolean
  start?: number
  stop?: number
}

// @todo add tests
export const TokenChip: React.FC<TokenChipProps> = ({
  type,
  noPaddings = false,
  text,
  start,
  stop,
}) => {
  const { error, handleHover } = useTokenValidation(start, stop)

  return (
    <StyledTokenChipRoot
      tokenType={type}
      noPaddings={noPaddings}
      error={!!error}
      data-testid="formula-editor-token-chip"
      data-token-type={type}
      onMouseOver={
        handleHover && error && start !== undefined && stop !== undefined
          ? (e) => {
              handleHover({
                node: e.target as HTMLElement,
                start,
                stop,
                text,
                type,
              })
            }
          : undefined
      }
      onMouseLeave={handleHover && error ? () => handleHover(null) : undefined}
    >
      {text}
    </StyledTokenChipRoot>
  )
}
