import React, { useCallback } from 'react'

import { Popover, Tooltip } from '@mui/material'

import { AddNewLineButtonDropdown } from './AddNewLineButtonDropdown'
import { AddCircleOutlineSharp, ArrowDropDown } from '@mui/icons-material'
import { ButtonWithDropDown } from './styled'

export type AddNewLineButtonProps = {
  onAddNewRows?: (numberOfRows: number) => void
  disabled?: boolean
  active?: boolean
  title?: string
}

export const AddNewLineButton: React.FC<AddNewLineButtonProps> = ({
  onAddNewRows,
  disabled,
  title,
  active = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        return
      }
      setAnchorEl(event.currentTarget)
    },
    [disabled],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAddNewRows = useCallback(
    (numberOfRows: number) => {
      if (numberOfRows > 0) {
        onAddNewRows?.(numberOfRows)
      }
      handleClose()
    },
    [handleClose, onAddNewRows],
  )

  const open = Boolean(anchorEl) && !disabled

  return (
    <>
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={title || 'Add empty line(s)'}
        disableInteractive={true}
        placement="top"
      >
        <span>
          <ButtonWithDropDown
            className={active ? 'active' : ''}
            onClick={handleClick}
            data-testid="AddNewLineButton"
            disabled={disabled}
          >
            <AddCircleOutlineSharp
              fontSize="small"
              color={disabled ? 'disabled' : undefined}
            />
            <ArrowDropDown
              color={disabled ? 'disabled' : undefined}
              fontSize="small"
            />
          </ButtonWithDropDown>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AddNewLineButtonDropdown onApply={handleAddNewRows} />
      </Popover>
    </>
  )
}
