import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AggregationSettingsChangeCallback } from '../../types'
import { useFlagsContext } from '../../flags-context'
import { useDataContext } from '../../data-context'
import { SelectableMenuItem } from '@fintastic/shared/ui/components'
import { resolveRollUpName } from '../../../../../../../utils/resolveRollUpName'
import { SmartSelect } from '@fintastic/shared/ui/dropdowns'
import { StyledMetricSelectRoot } from './WeightedAverageMenuItem.styled'

export type WeightedAverageMenuItemProps = {
  selected: boolean
  onChange: AggregationSettingsChangeCallback
  currentMetricId?: string
}

export const WeightedAverageMenuItem: React.FC<
  WeightedAverageMenuItemProps
> = ({ selected, currentMetricId, onChange }) => {
  const { readonly } = useFlagsContext()
  const { metrics } = useDataContext()

  const [localSelected, setLocalSelected] = useState(false)
  const [localMetricId, setLocalMetricId] = useState<string>(
    currentMetricId || '',
  )

  const handleSelectMetricId = useCallback(
    (newMetricId: string) => {
      onChange({
        rollUpFunction: 'weighted_avg',
        weightsMetricId: newMetricId,
      })
      setLocalSelected(() => false)
      setLocalMetricId(newMetricId)
    },
    [onChange, setLocalMetricId],
  )

  const metricOptions = useMemo(
    () => metrics.map((m) => ({ label: m.label, value: m.id })),
    [metrics],
  )

  const handleClickMenu = useCallback(() => {
    if (readonly) {
      setLocalSelected(() => false)
      return
    }
    if (!currentMetricId && !localMetricId) {
      setLocalSelected(() => true)
      return
    }

    if (!currentMetricId && localMetricId) {
      handleSelectMetricId(localMetricId)
    }
    setLocalSelected(() => true)
  }, [currentMetricId, handleSelectMetricId, localMetricId, readonly])

  const handleCloseDropDownReason = useCallback(() => {
    setLocalSelected(() => false)
  }, [])

  useEffect(() => {
    if (selected) {
      setLocalSelected(() => false)
    }
    return () => {
      setLocalSelected(() => false)
    }
  }, [selected])

  return (
    <>
      <SelectableMenuItem
        label={resolveRollUpName('weighted_avg')}
        onClick={handleClickMenu}
        disabled={readonly}
        selected={selected}
      />
      {(selected || localSelected) && (
        <StyledMetricSelectRoot>
          <SmartSelect
            options={metricOptions}
            disabled={readonly}
            label={'Weighting metric'}
            initialOpen={!currentMetricId && !readonly}
            value={currentMetricId || localMetricId || ''}
            onChange={handleSelectMetricId}
            onClose={handleCloseDropDownReason}
          />
        </StyledMetricSelectRoot>
      )}
    </>
  )
}
