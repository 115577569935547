import { Box, styled } from '@mui/material'

export const StyledContainerRoot = styled(Box)`
  font-size: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
`

export const StyledContainerStub = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
`
