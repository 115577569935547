import { ToolPanelDef } from 'ag-grid-community'
import { resolveFintasticAgIconKey } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { AgGridColumnsToolPanel } from './AgGridColumnsToolPanel'

export const COLUMNS_TOOL_PANEL_ID = 'listColumns'

export const columnsToolPanelDefinition: ToolPanelDef = {
  id: COLUMNS_TOOL_PANEL_ID,
  labelDefault: '',
  toolPanel: AgGridColumnsToolPanel,
  labelKey: '',
  iconKey: resolveFintasticAgIconKey('columns'),
  maxWidth: 320,
  minWidth: 320,
  width: 320,
}
