import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { useBoardsParamsVersions } from './boards-params/hooks'
import { useMemo } from 'react'
import { Version } from '@fintastic/web/util/versions'

export const useBoardSelectedVersions = ({
  defaultVersions,
}: {
  defaultVersions: string[]
}): { versions: Version[]; deeplinkInvalidated: boolean } => {
  const { versions, deeplinkInvalidated } =
    useBoardsParamsVersions(defaultVersions)

  const { data: versionsList } = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: false, // board cannot display archived versions
  })

  return useMemo(
    () => ({
      versions: (versionsList || [])?.filter((i) => versions.includes(i.uuid)),
      deeplinkInvalidated,
    }),
    [versions, versionsList, deeplinkInvalidated],
  )
}
