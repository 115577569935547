import React, { useCallback } from 'react'
import type { AvailableSettings } from './useAvailableSettingsList'
import { List, ListItemButton, ListItemText } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useLayoutStateIsSidebarOpened } from '@fintastic/shared/ui/app-layout-framework'

export const SettingsSidebarMenu: React.FC<{
  availableSettings: AvailableSettings
}> = ({ availableSettings }) => {
  const navigate = useNavigate()
  const [, setIsSubmenuOpened] = useLayoutStateIsSidebarOpened()

  const goToPermissions = useCallback(() => {
    navigate('/settings/permissions#users')
    setIsSubmenuOpened(false)
  }, [navigate, setIsSubmenuOpened])

  const goToDimensions = useCallback(() => {
    navigate('/settings/dimensions')
    setIsSubmenuOpened(false)
  }, [navigate, setIsSubmenuOpened])

  return (
    <List data-testid="settings-sidebar-menu">
      {availableSettings.permissions && (
        <ListItemButton
          onClick={goToPermissions}
          data-testid="settings-sidebar-menu-item-permissions"
        >
          <ListItemText primary="Permissions" />
        </ListItemButton>
      )}
      {availableSettings.dimensions && (
        <ListItemButton
          onClick={goToDimensions}
          data-testid="settings-sidebar-menu-item-dimensions"
        >
          <ListItemText primary="Dimensions management" />
        </ListItemButton>
      )}
    </List>
  )
}
