import {
  CalculationErrorCode,
  CalculateIntent,
  EntityDeletionErrorPayload,
} from './types'
import { useMutation } from 'react-query'
import { sendVersionChangeIntent } from './api'
import { useMemo, useRef, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useSubscribeToCalculationProgressEvent } from '@fintastic/web/data-access/service-pusher'
import {
  CalculationError,
  CalculationTimedOutError,
  DeletionError,
} from './errors'
import { AxiosError } from 'axios'

const CALCULATION_TIMEOUT = 30 * 1000

export type CalculationRequestError =
  | CalculationTimedOutError
  | CalculationError
  | DeletionError
  | Error

export const useCalculationRequest = (
  versionId: string,
  onSuccess?: () => void,
  onError?: (error: CalculationRequestError) => void,
) => {
  const [error, setError] = useState<Maybe<CalculationRequestError>>(null)
  const [calculating, setCalculating] = useState(false)

  const timeoutRef = useRef<NodeJS.Timeout>()

  const query = useMutation<
    { result: string },
    AxiosError<{ detail: EntityDeletionErrorPayload[] }> | Error,
    CalculateIntent
  >(
    ['version', versionId],
    async (intent) => {
      setError(null)
      setCalculating(true)

      const result = (await sendVersionChangeIntent(versionId, intent)).data

      timeoutRef.current = setTimeout(() => {
        const error = new CalculationTimedOutError('Calculation timed out')
        setError(error)
        setCalculating(false)
        onError?.(error)
      }, CALCULATION_TIMEOUT)

      return result
    },
    {
      onError: (error) => {
        let recognisedError = error
        if ('isAxiosError' in error && error.isAxiosError) {
          if (
            error.response?.status === 422 &&
            'detail' in error.response.data
          ) {
            recognisedError = new DeletionError(error.response.data.detail, 'Calculation error 422')
          }
        }

        setError(recognisedError)
        setCalculating(false)
        onError?.(recognisedError)
      },
    },
  )

  useSubscribeToCalculationProgressEvent(
    useMemo(() => [versionId], [versionId]),
    (event) => {
      if (!event.taskIdMatches(query.data?.result || '')) {
        return
      }

      clearTimeout(timeoutRef.current)
      setCalculating(false)

      if (
        event.failed() &&
        (event.error || event.errorCode || event.errorMessage)
      ) {
        let reportingError: Error

        if (event.errorMessage) {
          reportingError = new Error(event.errorMessage)
        } else {
          reportingError = event.errorCode
            ? new CalculationError(event.errorCode as CalculationErrorCode)
            : new Error(event.error || 'Unknown Calculation Error')
        }

        setError(reportingError)
        onError?.(reportingError)
      }

      if (event.successful()) {
        setError(null)
        onSuccess?.()
      }
    },
  )

  return { error, calculating, query }
}
