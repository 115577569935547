import { LegacyListGridColumnData } from '@fintastic/web/feature/legacy-list-grid'
import { VersionToCategoryAggregationMap } from '../aggregation-functions/useVersionToCategoryAggregationMap'
import {
  isDataTypeNumeric,
  MetricDataValueType,
} from '@fintastic/web/util/metrics-and-lists'

// @todo add tests
export function totalRowAllowed(
  categoryAggMap: VersionToCategoryAggregationMap,
  dataType?: LegacyListGridColumnData,
) {
  return (
    isDataTypeNumeric(dataType as unknown as MetricDataValueType) &&
    Object.values(categoryAggMap).filter((a) => a !== 'noop').length > 0
  )
}
