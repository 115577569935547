import React, { useCallback, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  StyledSingleVersionButton,
  StyledSingleVersionSelectorMenu,
  StyledSingleVersionSelectorMenuItem,
} from './SingleVersionSelector.styled'
import { VersionLockUnlockIcon } from '../VersionIcon'
import { ArrowDropDown } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'

export type SingleVersionSelectorOption = {
  label: string
  value: string
  disabled?: boolean
  isLocked?: boolean
  userLock?: VersionUserLockParsed
}

export type SingleVersionSelectorButtonRenderPropParams = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  selectedOption: Maybe<SingleVersionSelectorOption>
  disabled: boolean
  overridesGlobal: boolean
  opened?: boolean
}
export type SingleVersionSelectorButtonRenderProp = (
  params: SingleVersionSelectorButtonRenderPropParams,
) => React.ReactNode

const defaultRenderProp: SingleVersionSelectorButtonRenderProp = ({
  onClick,
  disabled,
  overridesGlobal,
  opened = false,
}) => (
  <StyledSingleVersionButton
    onClick={onClick}
    disabled={disabled}
    overrides={overridesGlobal && !disabled}
    opened={opened}
  >
    Versions
    <ArrowDropDown fontSize="small" />
  </StyledSingleVersionButton>
)

export type SingleVersionSelectorProps = {
  value: Maybe<string>
  onChange: (newValue: Maybe<string>) => void
  options: SingleVersionSelectorOption[]
  disabled?: boolean
  renderButton?: SingleVersionSelectorButtonRenderProp
  overridesGlobal: boolean
}

export const SingleVersionSelector: React.FC<SingleVersionSelectorProps> = ({
  value,
  onChange,
  options,
  disabled = false,
  renderButton = defaultRenderProp,
  overridesGlobal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = !!anchorEl

  const selectedOption = useMemo<Maybe<SingleVersionSelectorOption>>(
    () => options.find((o) => o.value === value) || null,
    [options, value],
  )

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClickOnMenuItem = useCallback(
    (newVersionId: string) => {
      handleClose()
      onChange(newVersionId)
    },
    [handleClose, onChange],
  )

  return (
    <>
      {renderButton({
        onClick: handleClick,
        disabled,
        selectedOption,
        overridesGlobal,
        opened: anchorEl !== null,
      })}
      <StyledSingleVersionSelectorMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => {
          const content = (
            <StyledSingleVersionSelectorMenuItem
              key={option.value}
              onClick={() => handleClickOnMenuItem(option.value)}
              selected={option.value === value}
              disabled={option.disabled}
            >
              <VersionLockUnlockIcon
                isLocked={option.isLocked}
                editDisabled={option.userLock?.editIsBlocked}
              />
              <span>{option.label}</span>
            </StyledSingleVersionSelectorMenuItem>
          )

          if (option.userLock?.editIsBlocked) {
            return (
              <Tooltip
                key={option.value}
                title={versionIsLockedMessage(option.userLock)}
                arrow
              >
                {content}
              </Tooltip>
            )
          }

          return content
        })}
      </StyledSingleVersionSelectorMenu>
    </>
  )
}
