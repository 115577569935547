import { axios } from '@fintastic/web/data-access/service-axios'
import { DimensionItem } from '@fintastic/web/data-access/model-globals'
import { endpoints } from './endpoints'

export async function getActualDimensionValues(dimensionId: string) {
  const { data } = await axios.get<{ result: DimensionItem[] }>(
    endpoints.getDimensionValues(dimensionId),
  )
  return data.result
}
