import { Maybe } from '@fintastic/shared/util/types'
import { RequestStateStatus } from '@fintastic/shared/data-access/redux-extension-request'
import { LoginError } from '../../shared/types'

export const namespace = 'appInitialization' as const

export type RootState = {
  [namespace]: State
}

export type State = {
  status: RequestStateStatus
  error: Maybe<LoginError>
}

export const initialState: State = {
  status: 'initial',
  error: null,
}
