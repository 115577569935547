import { useMutation, useQueryClient } from 'react-query'
import { CreateDimensionPayload, CreateDimensionResponse } from '../types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from '../api/endpoints'
import { AxiosError } from 'axios'
import { resetAllVersionsEntitiesCache } from '@fintastic/web/data-access/versions'
import { globalDimensionsKey } from '../api/keys'

export function useCreateGlobalDimension(onError: (error: AxiosError) => void) {
  const queryClient = useQueryClient()

  return useMutation(
    globalDimensionsKey,
    async (params: { payload: CreateDimensionPayload }) => {
      const { data } = await axios.post<CreateDimensionResponse>(
        endpoints.createDimensions,
        params.payload,
      )

      return data
    },
    {
      onError,
      onSettled() {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: globalDimensionsKey,
          }),
          resetAllVersionsEntitiesCache(queryClient),
        ])
      },
    },
  )
}
