import { Box, styled } from '@mui/material'

export const StyledLoadingBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
  border-radius: 6px;
`
