import { createMetricData } from './constructors'
import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  MetricData,
  MetricDataValue,
} from '@fintastic/web/util/metrics-and-lists'
import { DimensionId, DimensionValueId } from '@fintastic/shared/util/types'
import { MetricDataWrapper } from './MetricDataWrapper'

// @todo add tests
export class MutableMetricDataWrapper
  extends MetricDataWrapper
  implements MutableADTWrapper<MetricData>
{
  _rawData: MetricData

  constructor(data: MetricData) {
    super(data)
    this._rawData = data
  }

  setDimensions(dimensions: DimensionId[]) {
    this._rawData.indexes = dimensions
  }

  setDimensionValues(dimValues: Array<DimensionValueId[]>) {
    this._rawData.dimensions = dimValues
  }

  setValues(values: MetricDataValue[]) {
    this._rawData.values = values
  }

  removeLastDimension() {
    /**
     * Just delete everything, no aggregation
     */

    const newData = createMetricData()
    this.setValues(newData.values)
    this.setDimensions(newData.indexes)
    this.setDimensionValues(newData.dimensions)
  }

  regenerateValues(defaultValue: MetricDataValue = null) {
    this.setValues(this.getRegeneratedValues(defaultValue))
  }
}
