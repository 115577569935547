import {
  isDataTypeNumeric,
  MetricMetadata,
} from '@fintastic/web/util/metrics-and-lists'
import { ColDef } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ChartCellEditorProps,
  getChartNumericCellEditorProps,
  getChartPercentageCellEditorProps,
} from './chart-column-defs'

export const getColDef = (
  metadata?: Maybe<MetricMetadata>,
): Partial<ColDef> => {
  if (!metadata) {
    return {}
  }

  const isPercentageColumn =
    metadata.value.type === 'percentage' ||
    metadata.value.type === 'percentage_integer'

  const isNumericColumn = isDataTypeNumeric(metadata.value.type)

  const cellEditor = 'numericCellEditor'
  const columnType = 'numericColumn'
  let cellEditorProps: Partial<ChartCellEditorProps> = {}

  if (isNumericColumn) {
    cellEditorProps = getChartNumericCellEditorProps(metadata)
  }

  if (isPercentageColumn) {
    cellEditorProps = getChartPercentageCellEditorProps(metadata)
  }

  return {
    cellClass: () => ['readonly'],
    headerClass: () => ['readonly'],
    suppressMenu: true,
    valueGetter: (params) => params.data[params.colDef.field || ''],
    cellRenderer: 'defaultCellRenderer',
    headerComponent: 'defaultColumnHeader',
    resizable: true,
    editable: false,
    type: columnType,
    cellEditor,
    ...cellEditorProps,
  }
}
