import type { Maybe } from '@fintastic/shared/util/types'
import type { Token } from '../tokens/types'
import {
  findTokenByCaret,
  getTokenStringPartByCaret,
} from '../caret/caret-utils'
import type { FocusedToken } from './types'

export function deriveFocusedToken(
  tokens: Token[],
  caretPosition: number,
): Maybe<FocusedToken> {
  const tokenAndIndex = findTokenByCaret(tokens, caretPosition)
  return tokenAndIndex !== null
    ? {
        token: tokenAndIndex[0],
        index: tokenAndIndex[1],
        textBeforeCaret: getTokenStringPartByCaret(
          tokenAndIndex[0],
          caretPosition,
        ),
      }
    : null
}
