import { useCallback, useMemo } from 'react'
import type { PeriodSelection } from '@fintastic/web/util/period-selector'
import {
  ChartColumnData,
  ChartRequestParams,
  MergedMetricsData,
  MetricChartDimensions,
} from '../types'
import {
  useInvalidateMetricChartData,
  useLoadMetricChartData,
} from '@fintastic/web/data-access/metrics-and-lists'
import { iterateOverCompact } from '@fintastic/web/util/metrics-and-lists'
import { VersionDimension } from '@fintastic/web/util/dimensions'
import { Version } from '@fintastic/web/util/versions'
import {
  extractDimensionEssential,
  getFirstAvailableVersionFromData,
  makeProcessCallback,
  MetricChartDataRecords,
  VersionedMetricChartDataRow,
} from './chart-data-utils'
import { useSubscribeOnCellDataUpdateEvent } from '@fintastic/web/data-access/metric-data-editing'
import {
  EditMetricDataDataUpdate,
  FailedDataUpdateEventWrapper,
} from '@fintastic/web/util/metric-data-editing'

export const useChartData = (
  versions: string[],
  metricId: string,
  periodSelection: PeriodSelection,
  dimensions: MetricChartDimensions,
  versionDimensions: VersionDimension[],
  versionsMetadata: Record<string, Version>,
) => {
  const {
    data: metricRawData,
    isLoading,
    isFetching,
    isError,
    errors,
  } = useLoadMetricChartData(versions, metricId, periodSelection, dimensions)

  const metricData = useMemo<MergedMetricsData>(() => {
    if (
      isFetching ||
      isLoading ||
      !metricRawData ||
      versionDimensions.length === 0 ||
      Object.keys(versionsMetadata).length === 0
    ) {
      return {
        data: [] as ChartColumnData,
        metadata: null,
      }
    }
    // only if everything loaded and only versions without errors...
    const localColumns: ChartColumnData = []
    const rows: VersionedMetricChartDataRow[] = []
    const isSingleVersion = metricRawData.length === 1

    metricRawData.forEach((versionedRecord) => {
      if (!versionedRecord.metric?.data) {
        return
      }

      const versionChartRecords: MetricChartDataRecords = []

      const processCallback = makeProcessCallback({
        versionId: versionedRecord.versionId,
        versionsMetadata,
        versionDimensions,
        rows: versionChartRecords,
        isSingleVersion,
      })

      iterateOverCompact(
        {
          indexes: versionedRecord.metric?.data.indexes || [],
          dimensions: versionedRecord.metric?.data.dimensions || [],
          values: versionedRecord.metric?.data.values || [],
          format: 'compact',
        },
        processCallback,
      )
      //
      rows.push({
        versionId: versionedRecord.versionId,
        rows: versionChartRecords,
      })
    })

    const { firstVersionData, firstVersionId } =
      getFirstAvailableVersionFromData(rows, versions)

    const metadata = metricRawData.find(
      (data) => data.versionId === firstVersionId,
    )?.metric?.metadata

    const headerRow = firstVersionData[Object.keys(firstVersionData)[0]]

    if (headerRow) {
      const firstRowColumns = (Object.keys(headerRow) || []).filter(
        (name) => name !== '__label',
      )

      const headerLine = ['Label', ...firstRowColumns]

      localColumns.push(headerLine)

      const otherVersions = rows
        .filter((r) => r.versionId !== firstVersionId)
        .map((r) => r.versionId)

      for (const [key, value] of Object.entries(firstVersionData)) {
        const dataRow = [
          value.__label,
          ...firstRowColumns.map((timeKey) => value[timeKey]),
        ]

        localColumns.push(dataRow)

        otherVersions.forEach((vId) => {
          const dataSource = rows.find((r) => r.versionId === vId)?.rows

          if (!dataSource) {
            // version ${vId} not found in Chart Datasets
            return
          }

          const targetRow = dataSource[key]

          if (!targetRow) {
            return
          }
          const versionDataRow = [
            targetRow.__label,
            ...firstRowColumns.map((timeKey) => targetRow[timeKey]),
          ]
          localColumns.push(versionDataRow)
        })
      }
    }

    return {
      data: localColumns,
      metadata: metadata || null,
    }
  }, [
    isFetching,
    isLoading,
    metricRawData,
    versionDimensions,
    versions,
    versionsMetadata,
  ])

  const { invalidateQueries } = useInvalidateMetricChartData(
    versions,
    metricId,
    periodSelection,
    dimensions || [],
  )

  useSubscribeOnCellDataUpdateEvent(
    versions,
    useCallback(
      async (event) => {
        if (event instanceof FailedDataUpdateEventWrapper) {
          return
        }

        if (
          event.updateData.action !== 'edit_list_column_data' &&
          event.updateData.action !== 'edit_metric_data'
        ) {
          return
        }

        if (!versions.includes(event.versionId)) {
          return
        }

        const affectedEntries =
          (event.updateData as EditMetricDataDataUpdate)
            ?.user_modified_entities || []

        if (!affectedEntries.includes(metricId)) {
          return
        }

        invalidateQueries()
      },
      [invalidateQueries, metricId, versions],
    ),
  )

  return useMemo(() => {
    const request: ChartRequestParams = {
      versions: versions,
      metricId: metricId,
      dimensions: extractDimensionEssential(dimensions),
      periodSelection,
    }

    return {
      isLoading: isLoading || isFetching,
      isError,
      data: metricData,
      request,
      errors,
    }
  }, [
    versions,
    metricId,
    dimensions,
    periodSelection,
    isLoading,
    isFetching,
    isError,
    metricData,
    errors,
  ])
}
