import { Currency } from '@fintastic/shared/data-access/currencies'
import { createContext, useContext } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricOrListEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'

export type DataContextValue = {
  currenciesList: Currency[]
  flow: Maybe<MetricOrListEditingFlow>
  allowDataTypeConversions: boolean
}

export const DataContext = createContext<DataContextValue>({
  currenciesList: [],
  flow: null,
  allowDataTypeConversions: false,
})

export function useDataContext() {
  return useContext(DataContext)
}
