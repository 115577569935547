import React from 'react'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { FormattingFieldsetProps } from './types'
import { NumberFormatting } from './NumberFormatting'
import { IntegerFormatting } from './IntegerFormatting'
import { PercentIntegerFormatting } from './PercentIntegerFormatting'
import { PercentNumberFormatting } from './PercentNumberFormatting'
import { CurrencyFormatting } from './CurrencyFormatting'

export const dataTypeToFormattingFieldsetMap: Record<
  MetricConfigurableDataValueType,
  Maybe<React.FC<FormattingFieldsetProps>>
> = {
  number: NumberFormatting,
  integer: IntegerFormatting,
  percentage: PercentNumberFormatting,
  percentage_integer: PercentIntegerFormatting,
  currency: CurrencyFormatting,
  boolean: null,
  string: null,
  datetime: null,
}
