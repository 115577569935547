/**
 Keep here the hooks attached to the current duck.
 In perfect case there should be only one way for components to interact with a duck - it's thought the hooks.
 Our hooks it's like a public API for components, and they shouldn't use selectors or action creators directly.
 */
import {
  collapseAllFolders,
  collapseFolder,
  expandAllFolders,
  expandFolder,
  expandOnlyFolder,
  selectBoardMode,
  selectIsFolderExpanded,
  setBoardDesignMode,
  setBoardDisplayMode,
} from './index'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { BoardFolder } from '../../types'
import { RootState } from './state'

export function useBoardFolderExpandingState(folderId: BoardFolder['id']) {
  const dispatch = useDispatch()
  const isExpanded = useSelector((state) =>
    selectIsFolderExpanded(state as RootState, folderId),
  )

  const collapse = useCallback(() => {
    dispatch(collapseFolder(folderId))
  }, [dispatch, folderId])

  const expand = useCallback(() => {
    dispatch(expandFolder(folderId))
  }, [dispatch, folderId])

  const toggle = useCallback(() => {
    isExpanded ? collapse() : expand()
  }, [collapse, expand, isExpanded])

  return useMemo(
    () => ({
      isExpanded,
      collapse,
      expand,
      toggle,
    }),
    [collapse, expand, isExpanded, toggle],
  )
}

export function useBoardFolders() {
  const dispatch = useDispatch()

  const collapseAll = useCallback(
    (folderIds: string[]) => {
      dispatch(collapseAllFolders(folderIds))
    },
    [dispatch],
  )

  const expandOnly = useCallback(
    (folderId: string) => {
      dispatch(expandOnlyFolder(folderId))
    },
    [dispatch],
  )

  const expandAll = useCallback(
    (folderIds: string[]) => {
      dispatch(expandAllFolders(folderIds))
    },
    [dispatch],
  )

  return {
    collapseAll,
    expandOnly,
    expandAll,
  }
}

export function useBoardState() {
  const dispatch = useDispatch()
  const mode = useSelector((state) =>
    selectBoardMode(state as RootState),
  )
  const setDesignMode = useCallback(
    () => {
      dispatch(setBoardDesignMode())
    },
    [dispatch],
  )

  const setDisplayMode = useCallback(
    () => {
      dispatch(setBoardDisplayMode())
    },
    [dispatch],
  )

  return {
    setDesignMode,
    setDisplayMode,
    mode,
    isDesignMode: mode === 'design',
  }
}
