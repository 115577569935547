import React from 'react'
import { ColorDropOffIcon } from '@fintastic/shared/ui/icons'
import { styled } from '@mui/material'

export const ColorResetButton: React.FC = (props) => (
  <>
    <ColorDropOffIconStyled htmlColor="#263646" fontSize="small" /> {'Reset'}
  </>
)

const ColorDropOffIconStyled = styled(ColorDropOffIcon)`
  display: inline-block;
  margin-right: 8px;
`

ColorDropOffIconStyled.displayName = 'ColorDropOffIconStyled'
