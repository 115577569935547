import React, { useCallback, useEffect, useMemo } from 'react'
import { FormLayout, TextField, useTextFieldState } from '@fintastic/shared/ui/form-framework'
import { useBoardUpdateMutation } from '../../../hooks/useBoardUpdateMutation'
import { Board, BoardShortened } from '../../../types'
import { useBoardsListQuery } from '../../../hooks/useBoardsListQuery'
import { Maybe } from '@fintastic/shared/util/types'
import { useBoardNameValidation } from '../../../hooks/useBoardNameValidation'
import { AxiosError } from 'axios'

export type BoardRenameFormProps = {
  boardId: Board['id']
  closeParentModal?: () => void
}

export const BoardRenameForm: React.FC<BoardRenameFormProps> = (props) => {
  const { closeParentModal, boardId } = props

  const boardNameFieldState = useTextFieldState()
  const boardNameValidation = useBoardNameValidation({
    boardName: boardNameFieldState.trimmedValue,
    editingBoardId: boardId,
  })

  const boardsListQuery = useBoardsListQuery()
  const boardData: Maybe<BoardShortened | undefined> = useMemo(
    () =>
      boardsListQuery.data
        ? boardsListQuery.data.find((board) => board.id === boardId)
        : null,
    [boardId, boardsListQuery.data],
  )
  const isLoading = boardsListQuery.isLoading
  const isSubmitEnabled = boardNameFieldState.value.length > 0
    && boardNameValidation.isValid
    && boardData?.name !== boardNameFieldState.value

  useEffect(() => {
    if (boardData === undefined) {
      if (closeParentModal) {
        closeParentModal()
      }
      return
    }

    if (boardData === null) {
      return
    }

    boardNameFieldState.setValue(boardData.name)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardData, boardNameFieldState.setValue, closeParentModal])

  const boardUpdateMutation = useBoardUpdateMutation({ boardId })
  const isSending = boardUpdateMutation.isLoading
  const submitErrorMessage = useMemo(() => {
    if (!boardUpdateMutation.error) {
      return null
    }
    console.error(boardUpdateMutation.error)
    if ((boardUpdateMutation.error as AxiosError).response?.status === 409) {
      return 'A Board with this name already exists.'
    }
    return 'Can\'t save the board'
  }, [boardUpdateMutation.error])

  const handleSubmit = useCallback(() => {
    boardUpdateMutation.mutate(
      {
        name: boardNameFieldState.value,
      },
      {
        onSuccess: () => {
          if (closeParentModal) {
            closeParentModal()
          }
        },
      },
    )
  }, [boardUpdateMutation, boardNameFieldState.value, closeParentModal])

  return (
    <FormLayout
      submitEnabled={!isSending && isSubmitEnabled}
      showLoader={isLoading || isSending}
      submitButtonText="Save"
      submitErrorMessage={submitErrorMessage}
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
    >
      <TextField
        label="Board name"
        placeholder="Name"
        fullWidth={true}
        maxLength={128}
        value={boardNameFieldState.value}
        onChange={boardNameFieldState.setValue}
        error={boardNameFieldState.touched ? !boardNameValidation.isValid : false}
        errorMessage={boardNameFieldState.touched ? boardNameValidation.error : undefined}
      />
    </FormLayout>
  )
}
