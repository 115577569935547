import React, { useCallback, useMemo } from 'react'
import { Topbar } from '@fintastic/shared/ui/app-layout-framework'
import { useMatch, useNavigate } from 'react-router-dom'
import { Maybe } from '@fintastic/shared/util/types'
import { PlanningVersionSelect } from '../PlanningVersionSelect'
import { routes } from '../../routes'
import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { compact } from 'lodash'
import { VersionCalculationStatus } from '@fintastic/web/feature/versions'

export const VersionTopBar: React.FC<{ periodSelector: React.ReactNode }> = ({
  periodSelector,
}) => {
  const versionIdMatch = useMatch(routes.version())
  const versionId = versionIdMatch?.params.versionId

  const versionsQuery = useLoadVersionsList({ showArchived: false })
  const version = useMemo(
    () => versionsQuery.data?.find((v) => v.uuid === versionId),
    [versionId, versionsQuery.data],
  )

  const navigate = useNavigate()
  const handleGoToVersion = useCallback(
    (versionId: Maybe<string>) => {
      if (versionId === null) {
        return
      }
      navigate(routes.version(versionId))
    },
    [navigate],
  )

  const settingsItems = useMemo(
    () =>
      compact([
        version && {
          content: (
            <PlanningVersionSelect
              options={versionsQuery.data || []}
              onChange={handleGoToVersion}
              value={versionId || null}
            />
          ),
          sx: {
            marginRight: 'auto',
            marginLeft: 0,
          },
        },
        {
          content: periodSelector,
          sx: {},
        },
        {
          content: (
            <VersionCalculationStatus
              versionIds={version ? [version.uuid] : []}
            />
          ),
          mostRight: true,
        },
      ]),
    [version, versionsQuery.data, handleGoToVersion, versionId, periodSelector],
  )

  return <Topbar settingsItems={settingsItems} />
}
