import { axios } from '@fintastic/web/data-access/service-axios'
import { VersionLabelToggleParams } from '../types'

const endpoints = {
  entityLabel: ({
    versionId,
    entityType,
    entityId,
    labelId,
  }: VersionLabelToggleParams) =>
    `/planning/api/v2/versions/${versionId}/entities/${entityType}s/${entityId}/labels/${labelId}/`,
} as const

export function attachLabelToEntity(params: VersionLabelToggleParams) {
  return axios.post(endpoints.entityLabel(params))
}

export function detachLabelFromEntity(params: VersionLabelToggleParams) {
  return axios.delete(endpoints.entityLabel(params))
}
