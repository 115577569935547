export function createNewSelectedValues<
  TValue extends string | number = string,
>(currentValues: TValue[], clickedValue: TValue): TValue[] {
  if (isValueAlreadySelected(currentValues, clickedValue)) {
    return removeValueFromSelected(currentValues, clickedValue)
  }
  return addValueToSelected(currentValues, clickedValue)
}

function isValueAlreadySelected<TValue extends string | number = string>(
  selectedValues: TValue[],
  value: TValue,
) {
  return selectedValues.includes(value)
}

function removeValueFromSelected<TValue extends string | number = string>(
  selectedValues: TValue[],
  value: TValue,
) {
  return selectedValues.filter((v) => v !== value)
}

function addValueToSelected<TValue extends string | number = string>(
  selectedValues: TValue[],
  value: TValue,
) {
  return [...selectedValues, value]
}
