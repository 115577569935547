import React from 'react'
import { DialogProps } from '@mui/material'
import { StyledModalDialogRoot } from './ModalDialog.styled'

export type ModalDialogProps = {
  width?: number
} & DialogProps

export const ModalDialog: React.FC<ModalDialogProps> = (props) => {
  const { width, ...dialogProps } = props

  return <StyledModalDialogRoot {...dialogProps} width={width} />
}
