import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DimensionsChangeCallback } from '../types'
import { dimensionsToCheckboxesOptions } from '../utils/dimensionsToCheckboxesOptions'
import {
  CheckboxesList,
  CheckboxesListChangeHandler,
  CheckboxesListOption,
  CheckboxesListOptionsIds,
} from '@fintastic/shared/ui/components'
import { dimensionsToIds } from '../utils/dimensionsToIds'
import { Dimension } from '@fintastic/shared/util/types'
import { Box, Divider, TextField, Typography } from '@mui/material'
import {
  StyledBusyIndicator,
  StyledFoundHeader,
} from './InputMetricDimensions.styled'

export type InputMetricDimensionsProps = {
  allDimensions: Dimension[]
  metricDimensions: Dimension[]
  onChangeMetricDimensions: DimensionsChangeCallback
  readonly?: boolean
  isNewMetric: boolean
}

const sortDimensionsByLabel = (a: Dimension, b: Dimension): number => {
  const labelA = (a?.label || '').toUpperCase()
  const labelB = (b?.label || '').toUpperCase()
  if (labelA < labelB) {
    return -1
  }
  if (labelA > labelB) {
    return 1
  }

  return 0
}

// @todo add tests
export const InputMetricDimensions: React.FC<InputMetricDimensionsProps> = ({
  allDimensions,
  metricDimensions,
  onChangeMetricDimensions,
  readonly,
  isNewMetric,
}) => {
  const sortedDimensions = useMemo(
    () =>
      allDimensions
        .filter((d) => d && d.id && d.label && d.type !== 'Time')
        .sort(sortDimensionsByLabel),
    [allDimensions],
  )

  const dimensionsIdToDataMap = useMemo(
    () => Object.fromEntries(allDimensions.map((dim) => [dim.id, dim])),
    [allDimensions],
  )

  const selectedCheckboxesOptionsIds = useMemo<CheckboxesListOptionsIds>(
    () => dimensionsToIds(metricDimensions),
    [metricDimensions],
  )

  const disabledCheckboxesOptionsIds = useMemo<CheckboxesListOptionsIds>(() => {
    if (readonly) {
      return dimensionsToIds(sortedDimensions)
    }
    return isNewMetric ? [] : dimensionsToIds(metricDimensions)
  }, [readonly, isNewMetric, metricDimensions, sortedDimensions])

  const handleChangeEvent = useCallback<CheckboxesListChangeHandler>(
    (newDimensionsIds, changedDimensionId) => {
      if (!changedDimensionId) {
        throw new Error('changedDimensionId not provided to InputMetricDimensions.handleChangeEvent')
      }
      onChangeMetricDimensions(
        newDimensionsIds.map((id) => dimensionsIdToDataMap[id]),
        changedDimensionId,
      )
    },
    [dimensionsIdToDataMap, onChangeMetricDimensions],
  )

  const onlySelectedOptions = useMemo(
    () =>
      dimensionsToCheckboxesOptions(
        sortedDimensions.filter((d) =>
          selectedCheckboxesOptionsIds.includes(d.id),
        ),
      ),
    [sortedDimensions, selectedCheckboxesOptionsIds],
  )

  const onlyUnSelectedOptions = useMemo(
    () =>
      dimensionsToCheckboxesOptions(
        sortedDimensions.filter(
          (d) => !selectedCheckboxesOptionsIds.includes(d.id),
        ),
      ),
    [sortedDimensions, selectedCheckboxesOptionsIds],
  )

  const [search, setSearch] = useState('')
  const [searchBusy, setSearchBusy] = useState(false)
  const [filterString, setFilterString] = useState('')
  const searchDebounceHandler = useRef<ReturnType<typeof setTimeout>>()

  const handleSearchChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (e) => {
      setSearch(e.target.value)
      setSearchBusy(() => true)
    },
    [setSearch],
  )

  useEffect(() => {
    clearTimeout(searchDebounceHandler.current)
    searchDebounceHandler.current = setTimeout(() => {
      setFilterString(() => search)
    }, 500)

    return () => {
      clearTimeout(searchDebounceHandler.current)
    }
  }, [search, setFilterString])

  const filteredUnselectedDimensions = useMemo<CheckboxesListOption[]>(() => {
    setSearchBusy(() => false)
    const trimmedSearch = filterString.trim().toLocaleLowerCase()
    if (!trimmedSearch) {
      return onlyUnSelectedOptions
    }

    return onlyUnSelectedOptions.filter(({ label }) =>
      label.toLocaleLowerCase().includes(trimmedSearch),
    )
  }, [onlyUnSelectedOptions, filterString])

  return (
    <>
      {!readonly && (
        <Box py="6px">
          <TextField
            onChange={handleSearchChange}
            placeholder="Filter dimensions"
            fullWidth
            size="small"
            autoComplete="off"
          />
        </Box>
      )}
      {selectedCheckboxesOptionsIds.length > 0 && (
        <>
          <Box py={readonly ? '0' : '6px'}>
            <CheckboxesList
              options={onlySelectedOptions}
              selectedOptions={selectedCheckboxesOptionsIds}
              disabledOptions={disabledCheckboxesOptionsIds}
              onChange={handleChangeEvent}
            />
          </Box>
          <Divider />
        </>
      )}
      {!readonly && (
        <>
          <StyledFoundHeader>
            <Typography variant="body2" color="text.secondary">
              {filteredUnselectedDimensions.length > 0 ? (
                <>
                  Dimensions: {filteredUnselectedDimensions.length} of{' '}
                  {sortedDimensions.length}
                </>
              ) : (
                <>
                  No results match your search (total: {sortedDimensions.length}
                  )
                </>
              )}
              {searchBusy && <StyledBusyIndicator size=".8em" />}
            </Typography>
          </StyledFoundHeader>

          {filteredUnselectedDimensions.length > 0 ? (
            <CheckboxesList
              options={filteredUnselectedDimensions}
              disabledOptions={disabledCheckboxesOptionsIds}
              onChange={handleChangeEvent}
            />
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  )
}
