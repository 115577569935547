import React, { useCallback, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { SelectChangeEvent, Tooltip } from '@mui/material'
import { MenuItem } from '@mui/material'
import {
  LabelSelectText,
  StyledPlanningVersionSelectRoot,
  StyledVersionTitle,
} from './PlanningVersionSelect.styled'
import { isVersionLocked, Version } from '@fintastic/web/util/versions'
import { VersionLockUnlockIcon } from '@fintastic/shared/ui/components'
import { StyledPlanningVersionSelectSelect } from './select/Select'
import { useVersionUserLockQuery } from '@fintastic/web/data-access/versions'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'

type Props = {
  options: Version[]
  value: Maybe<Version['uuid']>
  onChange: (newValue: Maybe<Version['uuid']>) => void
}

export const PlanningVersionSelect: React.FC<Props> = (props) => {
  const { options, value, onChange } = props

  const sortedVersions = useMemo(
    () =>
      [...options].sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
      ),
    [options],
  )

  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      onChange(e.target.value as Maybe<string>)
    },
    [onChange],
  )

  const renderValueCallback = useMemo(
    () => (value === null ? () => 'Select a version' : undefined),
    [value],
  )

  const { lock } = useVersionUserLockQuery(value)

  const dropdown = (
    <StyledPlanningVersionSelectRoot data-testid="version-page-versions-selector">
      <StyledPlanningVersionSelectSelect
        value={value === null ? '' : value}
        onChange={handleChange}
        marginPaper=".5rem 0 0 0"
        renderValue={renderValueCallback}
        extraMenuProps={{ maxHeight: '40vh' }}
        displayEmpty={true}
      >
        <StyledVersionTitle>Versions</StyledVersionTitle>
        {sortedVersions.map((version) => (
          <MenuItem
            value={version.uuid}
            key={version.uuid}
            data-testid="planning-version-item"
            sx={{ marginLeft: '5px' }}
          >
            <VersionLockUnlockIcon
              isLocked={isVersionLocked(version)}
              editDisabled={
                value !== version.uuid ? false : lock.editIsBlocked
              }
            />
            <LabelSelectText>{version.name}</LabelSelectText>
          </MenuItem>
        ))}
      </StyledPlanningVersionSelectSelect>
    </StyledPlanningVersionSelectRoot>
  )

  if (lock.editIsBlocked) {
    return (
      <Tooltip arrow title={versionIsLockedMessage(lock)}>
        {dropdown}
      </Tooltip>
    )
  }

  return dropdown
}
