import type { DeeplinkKey, DeeplinkValue } from '../types'
import { useMemo, useRef } from 'react'
import { getObjectFromUrlSearchParams } from '../useSyncDeeplinkValue/serialize-to-search-params'
import { useLocation } from 'react-router-dom'
import { isEqual } from 'lodash'
import { DeeplinkCache } from '../cache/DeeplinkCache'

export function useReadDeeplinkValue<T extends DeeplinkValue>(
  key?: DeeplinkKey,
): T | undefined {
  const oldValueRef = useRef<T>()

  const { search } = useLocation()

  return useMemo<T | undefined>(() => {
    if (!key) {
      return undefined
    }

    if (DeeplinkCache.cacheEntryExists(search, key)) {
      return DeeplinkCache.getCacheEntry<T>(search, key)
    }

    const searchParams = new URLSearchParams(search)

    const nextValue = getObjectFromUrlSearchParams<T>({
      key,
      searchParams,
    })

    if (isEqual(oldValueRef.current, nextValue)) {
      DeeplinkCache.setCacheEntry(search, key, oldValueRef.current)
      return oldValueRef.current
    }

    oldValueRef.current = nextValue
    DeeplinkCache.setCacheEntry(search, key, nextValue)

    return nextValue
  }, [key, search])
}
