import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, FormControl, OutlinedInput } from '@mui/material'
import { FactoryOpts, InputMask } from 'imask'
import { IMaskMixin } from 'react-imask'
import { AgGridCellEditorProps } from '../types'
import { agGridNumericEditorDefaultNumberMask } from './defaultMasks'
import {
  AG_CELL_EDITOR_CLASS_NAME,
  AG_NUMERIC_CELL_EDITOR_CLASS_NAME,
} from '../classNames'
import { Maybe } from '@fintastic/shared/util/types'
import {
  createNumericValueInputMask,
  getPositionOfNumericValueInFormattedString,
  MetricDisplaySettings,
  MetricNumericDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { InlineFormulaInput } from './InlineFormulaInput'

export * from './defaultMasks'

export type AgGridNumericCellEditorProps = {
  mask?: FactoryOpts
  parseSourceValue?: (value: Maybe<string>) => Maybe<string>
  parseEditedValue?: (value: Maybe<string>) => Maybe<number | string>
  displaySettings?: MetricDisplaySettings
  currency?: Currency
  dataType: MetricNumericDataValueType
}

const isInlineFormulaAllowed = (dataType: MetricNumericDataValueType) =>
  dataType === 'number' ||
  dataType === 'currency' ||
  dataType === 'integer' ||
  dataType === 'percentage' ||
  dataType === 'percentage_integer'

const defaultValueParser = (value: Maybe<string>) => value

const Input = IMaskMixin(({ inputRef, ...props }) => {
  const propsToUse = props as Record<string, unknown>
  return <OutlinedInput size="small" inputRef={inputRef} {...propsToUse} />
})

// ATTENTION: do not change my styles here,
// change them in frontend/main-client/libs/shared/ui/ag-grid-theme-fintastic/src/lib/theme-variants/default.ts
export const AgGridNumericCellEditor = memo(
  forwardRef<unknown, AgGridNumericCellEditorProps & AgGridCellEditorProps>(
    ({ afterInput, ...props }, ref) => {
      const {
        mask = agGridNumericEditorDefaultNumberMask,
        parseSourceValue = defaultValueParser,
        parseEditedValue = defaultValueParser,
        displaySettings,
        dataType,
        currency,
        formatValue,
      } = props

      const numericValuePositionInFormattedString = useMemo(
        () =>
          displaySettings
            ? getPositionOfNumericValueInFormattedString(
                dataType,
                displaySettings,
                currency,
              )
            : 0,
        [currency, dataType, displaySettings],
      )
      const numericValuePositionInFormattedStringRef = useRef(
        numericValuePositionInFormattedString,
      )
      numericValuePositionInFormattedStringRef.current =
        numericValuePositionInFormattedString

      const usedMask = useMemo(
        () =>
          displaySettings
            ? createNumericValueInputMask(dataType, displaySettings, currency)
            : mask,
        [currency, dataType, displaySettings, mask],
      )

      const sourceValue = parseSourceValue(props.value)
      const [value, setValue] = useState<Maybe<string>>(
        sourceValue === null ? '' : sourceValue,
      )

      const containerRef = React.useRef<HTMLInputElement>(null)

      const sourceValueRef = React.useRef(sourceValue)
      const initialValueRef = React.useRef(`${props.value}`)

      useEffect(() => {
        window.setTimeout(() => {
          containerRef.current?.querySelector('input')?.focus()
          if (initialValueRef.current === '0') {
            containerRef.current
              ?.querySelector('input')
              ?.setSelectionRange(
                numericValuePositionInFormattedStringRef.current,
                numericValuePositionInFormattedStringRef.current + 1,
              )
          }
        }, 0)
      }, [])

      useImperativeHandle(ref, () => ({
        setValue(value: string) {
          setValue(value)
        },
        getValue() {
          const parsedValue = parseEditedValue(
            value === '' || value === '-' ? null : value,
          )

          // If the value hasn't changed, return what we accepted as the original value
          // This needed to prevent unnecessary onUpdate event due to formatting source value
          if (
            parsedValue === sourceValueRef.current &&
            sourceValueRef.current
          ) {
            return initialValueRef.current === null
              ? ''
              : sourceValueRef.current
          }

          return parsedValue
        },
        isCancelBeforeStart() {
          return false
        },
        isCancelAfterEnd() {
          return false
        },
      }))

      const handleChangeInput = useCallback(
        (value: string, maskRef: InputMask) => {
          const unmasked =
            maskRef.unmaskedValue === '' ? null : maskRef.unmaskedValue
          const nextValue = value === '-' ? '-' : unmasked
          setValue(nextValue)
        },
        [],
      )

      return (
        <Box
          position="relative"
          className={
            AG_CELL_EDITOR_CLASS_NAME + ' ' + AG_NUMERIC_CELL_EDITOR_CLASS_NAME
          }
        >
          <FormControl fullWidth ref={containerRef}>
            {isInlineFormulaAllowed(dataType) ? (
              <InlineFormulaInput
                value={value}
                onConfirmFormulaValue={setValue}
                onConfirmPlainValue={handleChangeInput}
                mask={usedMask}
                formatter={formatValue}
              />
            ) : (
              <Input
                value={`${value}`}
                onAccept={handleChangeInput}
                {...(usedMask as unknown as any)}
              />
            )}
          </FormControl>
          {afterInput}
        </Box>
      )
    },
  ),
)

export default AgGridNumericCellEditor
