import { ChangeWidgetDialogParams } from '@fintastic/web/feature/boards'
import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useTextFieldState } from '@fintastic/shared/ui/form-framework'

export type ListWidgetSettings = {
  listId?: Maybe<string>
  handleSelectList: (listId: Maybe<string>, listName: Maybe<string>) => void
  changed: boolean
}

export const useListWidgetSettings = (
  initialParams: Partial<ChangeWidgetDialogParams>,
  displayNameFieldState: ReturnType<typeof useTextFieldState>,
): ListWidgetSettings => {
  const [listId, setListId] = useState<Maybe<string>>(initialParams.id || null)

  const [changed, setChanged] = useState(false)

  const handleSelectList = useCallback(
    (listId: Maybe<string>, listName: Maybe<string>) => {
      setListId(listId)
      if (
        !displayNameFieldState.touched ||
        !displayNameFieldState.trimmedValue
      ) {
        displayNameFieldState.assignValue(listName || '')
      }
      setChanged(() => true)
    },
    [setListId, displayNameFieldState, setChanged],
  )
  return useMemo<ListWidgetSettings>(
    () => ({
      listId,
      handleSelectList,
      changed,
    }),
    [listId, handleSelectList, changed],
  )
}
