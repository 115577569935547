import React, { useCallback, useEffect, useRef } from 'react'
import {
  StyledCounter,
  StyledQuickAdornment,
  StyledQuickEndAdornment,
  StyledQuickInput,
  StyledQuickSearchRoot,
} from './QuickSearch.styled'
import { SearchIcon } from './SearchIcon'
import { IconButton } from '@mui/material'

import { CancelIcon } from '@fintastic/shared/ui/icons'

export type QuickSearchProps = {
  'value': string
  'onChange': (newValue: string) => void
  'data-testid'?: string
  'showClear'?: boolean
  'fullWidth'?: boolean
  'disabled'?: boolean
  'totalCount'?: number
  'visibleCount'?: number
  'autoFocus'?: boolean
}

export const QuickSearch: React.FC<QuickSearchProps> = ({
  value,
  onChange,
  showClear = true,
  fullWidth = false,
  disabled = false,
  totalCount,
  visibleCount,
  autoFocus = false,
  ...otherProps
}) => {
  const handleChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    ({ currentTarget }) => {
      onChange(currentTarget.value)
    },
    [onChange],
  )

  const hasCounter =
    typeof totalCount !== 'undefined' && typeof visibleCount !== 'undefined'

  const handleClear = useCallback(() => {
    onChange('')
  }, [onChange])

  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus()
    }
  }, [])

  return (
    <StyledQuickSearchRoot data-testid="quick-search" fullWidth={fullWidth}>
      <StyledQuickInput
        {...otherProps}
        value={value}
        onChange={handleChange}
        placeholder="Search"
        disabled={disabled}
        inputRef={ref}
        size="small"
        startAdornment={
          <StyledQuickAdornment position="start">
            <SearchIcon />
          </StyledQuickAdornment>
        }
        endAdornment={
          showClear &&
          value && (
            <StyledQuickEndAdornment position="end">
              {hasCounter && (
                <StyledCounter>
                  {visibleCount}/{totalCount}
                </StyledCounter>
              )}
              <IconButton
                aria-label="clear"
                color="primary"
                size="small"
                onClick={handleClear}
              >
                <CancelIcon />
              </IconButton>
            </StyledQuickEndAdornment>
          )
        }
      />
    </StyledQuickSearchRoot>
  )
}
