import React, { forwardRef, useMemo } from 'react'
import {
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material'
import { StyledPanelToolbarIconButtonRoot } from './PanelToolbarIconButton.styled'

export type PanelToolbarIconButtonProps = {
  enableTooltip?: boolean
  title?: string
  disabledTitle?: string
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>
  selected?: boolean
  ['data-testid']?: string
} & MuiIconButtonProps

const defaultTooltipProps: Omit<TooltipProps, 'title' | 'children'> = {
  placement: 'top',
}

// @todo @Ilya add margin 8px
export const PanelToolbarIconButton = forwardRef<
  HTMLButtonElement,
  PanelToolbarIconButtonProps
>(
  (
    {
      title,
      disabledTitle,
      enableTooltip = true,
      tooltipProps = defaultTooltipProps,
      color = 'secondary',
      selected,
      ...restProps
    },
    ref,
  ) => {
    const tooltipMergedProps = useMemo<
      PanelToolbarIconButtonProps['tooltipProps']
    >(
      () => ({
        ...defaultTooltipProps,
        ...tooltipProps,
      }),
      [tooltipProps],
    )

    if (enableTooltip) {
      return (
        <Tooltip
          enterNextDelay={700}
          enterDelay={700}
          leaveDelay={0}
          title={restProps.disabled ? disabledTitle || title : title}
          disableInteractive={true}
          {...tooltipMergedProps}
        >
          <span>
            <StyledPanelToolbarIconButtonRoot
              selected={selected}
              color={color}
              {...restProps}
              ref={ref}
            />
          </span>
        </Tooltip>
      )
    }

    return (
      <StyledPanelToolbarIconButtonRoot
        color={color}
        {...restProps}
        title={title}
        selected={selected}
        ref={ref}
      />
    )
  },
)
