export const SYSTEM_COLORS = [
  {
    label: 'Gray',
    bg: '#C5CBD2',
    hex: '#F3F5F8',
  },
  {
    label: 'Brown',
    bg: '#D2C9C0',
    hex: '#F8F4F1',
  },
  {
    label: 'Pink',
    bg: '#FCB2CC',
    hex: '#FCF2F5',
  },
  {
    label: 'Red',
    bg: '#FCB6B5',
    hex: '#FCF3F2',
  },
  {
    label: 'Orange',
    bg: '#FCB99B',
    hex: '#FCF3EF',
  },
  {
    label: 'Yellow',
    bg: '#FFBD33',
    hex: '#FDF4E6',
  },
  {
    label: 'Green',
    bg: '#36F074',
    hex: '#E6FCE9',
  },
  {
    label: 'Mint',
    bg: '#5EE5CC',
    hex: '#E4FBF5',
  },
  {
    label: 'Blue',
    bg: '#95D3FC',
    hex: '#EFF6FC',
  },
  {
    label: 'Violet',
    bg: '#C9C3FA',
    hex: '#F3F2FA',
  },
]
