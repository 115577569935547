import React, { useRef } from 'react'
import { SyntaxLiteral } from '../../../syntax/types'
import { Maybe } from '@fintastic/shared/util/types'
import { ListItem } from './ListItem'
import { StyledListRoot } from './List.styled'
import { useScrollToHighlightedItem } from './useScrollToHighlightedItem'
import { DATA_ATTRIBUTES } from '../const'

export type ListProps = {
  options: SyntaxLiteral[]
  highlighted: Maybe<string>
  onClickOnOption: (optionId: string) => void
}

export const List: React.FC<ListProps> = ({
  options,
  onClickOnOption,
  highlighted,
}) => {
  const rootRef = useRef<Maybe<HTMLUListElement>>(null)
  useScrollToHighlightedItem(options, highlighted, rootRef)

  return (
    <StyledListRoot
      dense={true}
      ref={rootRef}
      {...{ [DATA_ATTRIBUTES.list]: '' }}
    >
      {options.map((option) => (
        <ListItem
          option={option}
          key={option.literal}
          onClick={onClickOnOption}
          isHighlighted={option.literal === highlighted}
        />
      ))}
    </StyledListRoot>
  )
}
