import type { SaveFormulaApi } from '../../../Editor/features/editing/types'
import { Maybe } from '@fintastic/shared/util/types'
import { Version } from '../../../Editor/features/version-state/types'
import { UpdateFormulaCallback } from '../../types'
import { useCallback, useRef } from 'react'
import {
  editFormulaIntent,
  useCalculationRequest,
} from '@fintastic/web/data-access/calc'
import { useQueryClient } from 'react-query'
import {
  invalidateVersionUserLockerCache,
  switchVersionUserLockers,
} from '@fintastic/web/data-access/versions'

export function useSaveFormula(
  version: Maybe<Version>,
  onSuccess?: UpdateFormulaCallback,
  onError?: (error: Error) => void,
): SaveFormulaApi {
  const queryClient = useQueryClient()

  const formulaRef = useRef<string>('')

  const handleSuccess = useCallback(() => {
    onSuccess?.(version?.id || '', formulaRef.current)
  }, [onSuccess, version])

  const { query, error, calculating } = useCalculationRequest(
    version?.id || '',
    handleSuccess,
    onError,
  )

  const handleSave = useCallback(
    (formula: string) => {
      if (!version?.id || !version.connectedTable) {
        return
      }

      formulaRef.current = formula

      switchVersionUserLockers(queryClient, [version.id], 'calc')
      query.mutate(
        editFormulaIntent({
          formula,
          object_id: version.connectedTable.id,
        }),
        {
          onError: async () => {
            await invalidateVersionUserLockerCache(queryClient, [version.id])
          },
        },
      )
    },
    [query, queryClient, version?.connectedTable, version?.id],
  )

  return {
    calculating,
    save: handleSave,
    error: error || query.error,
  }
}
