import { ThemeComponents } from './types'

export const MuiPopover: ThemeComponents['MuiPopover'] = {
  styleOverrides: {
    root: ({ theme }) => ({}),
    paper: ({ theme }) => ({
      'borderRadius': theme.shape.borderRadius,
      '&.MuiPaper-root': {
        boxShadow:
          '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
      },
    }),
  },
}
