import React from 'react'
import { testIds } from '../../test/test-ids'
import { LoadingButton } from '@fintastic/shared/ui/components'

export type ApplyButtonProps = {
  isCalculating: boolean
  isWaiting: boolean
  isValid: boolean
  isDirty: boolean
  onClick: () => void
}

// @todo add tests
export const ApplyButton: React.FC<ApplyButtonProps> = ({
  isCalculating,
  isDirty,
  onClick,
  isValid,
  isWaiting,
}) => {
  const disabled = !isValid || !isDirty || isCalculating
  const loading = isCalculating
  const waiting = isWaiting || isCalculating

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      sx={{ fontSize: 14, mr: 1 }}
      onClick={waiting ? undefined : onClick}
      disabled={disabled}
      loading={loading}
      data-testid={testIds.applyButton}
    >
      Apply
    </LoadingButton>
  )
}
