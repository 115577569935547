import { useMemo } from 'react'
import { useLoadVersionsList } from './useLoadVersionsList'
import { Version } from '@fintastic/web/util/versions'
import { Maybe } from '@fintastic/shared/util/types'

export const useLiveActualsVersion = () => {
  const versionsListQuery = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: false,
  })

  const liveActualsVersion: Maybe<Version> = useMemo(
    () => versionsListQuery.data?.find((v) => v.is_live_actuals) || null,
    [versionsListQuery.data],
  )

  return {
    versionsListQuery,
    liveActualsVersion,
  }
}
