import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'
import {
  ChartGridMode,
  chartGridModeKeys,
  chartGridModes,
  defaultChartGridMode,
  isChartGridMode,
} from '@fintastic/web/feature/charts'
import {
  StyledTextWithoutIcon,
  StyledTextWithoutIconField,
} from '../common-styles'

export type ChartGridModeSelectProps = {
  gridMode: ChartGridMode
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartGridModeSelect: React.FC<ChartGridModeSelectProps> = memo(
  ({ gridMode, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartGridMode(e.target.value)) {
          onChangeChartSettings({ gridMode: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextWithoutIconField
          id="chart-grid-mode-select"
          fullWidth
          value={gridMode}
          size="small"
          select
          label="Grid mode"
          defaultValue={defaultChartGridMode}
          onChange={handleSelect}
          disabled={disabled}
        >
          {chartGridModeKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithoutIcon data-testid={`chart-grid-mode-item-${ct}`}>
                {chartGridModes[ct]}
              </StyledTextWithoutIcon>
            </MenuItem>
          ))}
        </StyledTextWithoutIconField>
      </FormField>
    )
  },
)
