import { IAggFuncParams } from 'ag-grid-community'
import mean from 'lodash/mean'
import { weightedAverage as calcWeightedAverage } from '@fintastic/shared/util/numeric-array'

export type WeightedAverageAgGridExtendedContextObject = {
  weightsGetter?: WeightsGetter
}

export type WeightsGetter<TData = any, TValue = any> = (
  params: IAggFuncParams<TData, TValue>,
) => TValue[]

export function weightedAverage<TData = any, TValue = any>(
  params: IAggFuncParams<TData, TValue>,
): number {
  const context = params.context as
    | WeightedAverageAgGridExtendedContextObject
    | undefined

  if (context === undefined || context.weightsGetter === undefined) {
    return mean(params.values) // fallback to standard average
  }

  const originalWeights = context.weightsGetter(params)

  // Ignore non-numeric pairs
  const values = []
  const weights = []

  for (let index = 0; index < params.values.length; index++) {
    const value = params.values[index]
    const weight = originalWeights[index]
    if (typeof value !== 'number' || typeof weight !== 'number') {
      continue
    }
    values.push(value)
    weights.push(weight)
  }

  const result = calcWeightedAverage(values, weights)

  if (Number.isNaN(result)) {
    return 0 // fallback to 0
  }

  return result
}
