import {
  FormulaParser,
  MathDivideByZeroError,
} from '@fintastic/web/util/blanks-and-masked'
import { Maybe } from '@fintastic/shared/util/types'
import { FactoryOpts } from 'imask'

export type InlineFormulaCalculationResult = {
  value: number | any[] | null
  error: string | null
  errorTitle: string | null
}

export const FORMULA_START_SYMBOL = '='

export const parseInlineFormula = (
  inputString: Maybe<string>,
  mask: FactoryOpts,
): InlineFormulaCalculationResult => {
  const textToParse = isPossibleFormula(inputString)
    ? (inputString || '').slice(1, (inputString || '').length)
    : inputString

  const calculation: InlineFormulaCalculationResult = {
    value: null,
    error: null,
    errorTitle: null,
  }

  if (!textToParse) {
    calculation.errorTitle = 'Empty formula'
    calculation.error = 'Nothing to calculate'
    return calculation
  }

  try {
    const parser = new FormulaParser(textToParse)
    const rawValue = parser.evaluate({})

    // ...throw post-checks like min...max here...

    const scale = (mask as any).blocks?.num?.scale

    if (typeof scale === 'number' && typeof rawValue === 'number') {
      // round
      const multiplier = 10 ** scale
      calculation.value = Math.round(rawValue * multiplier) / multiplier
    } else {
      calculation.value = rawValue
    }
  } catch (e) {
    calculation.errorTitle = 'Cell calculation error'

    if (e instanceof MathDivideByZeroError) {
      calculation.error = 'Division by zero is not allowed.'
    }

    // ...other error types like min...max values here...

    if (!calculation.error) {
      // fallback
      calculation.error = 'The formula has a structural error.'
    }
    calculation.value = null
  }

  return calculation
}

export const isPossibleFormula = (value: Maybe<string>) =>
  (value || '').startsWith(FORMULA_START_SYMBOL)

const allowedSymbolsInFormula = /[0-9+\-*.()^/ ]+$/

export const preCheckPossibleFormula = (value: Maybe<string>) => {
  // formula string must start with single =
  if (!isPossibleFormula(value)) {
    return false
  }

  const valueWithoutStartSymbol = (value || '').substring(
    1,
    (value || '').length,
  )

  if (valueWithoutStartSymbol === '') {
    // if just "=" - beginning the formula, allowed
    return true
  }

  // "=2+2"
  return allowedSymbolsInFormula.test(valueWithoutStartSymbol)
}

export const formulaMask: FactoryOpts = {
  mask: 'formula',
  lazy: false,
  blocks: {
    formula: {
      mask: preCheckPossibleFormula,
    },
  },
}
