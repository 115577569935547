import { IAggFuncParams } from 'ag-grid-community'
import { BlankOrMaskedValue, containsBlankValue, containsMaskedValue } from '@fintastic/web/util/blanks-and-masked'
import { last } from 'lodash'

export const firstNotBlank = (
  params: IAggFuncParams<any, BlankOrMaskedValue>,
) =>
  params.values.length > 0
    ? params.values.find(
        (value) => !containsBlankValue(value) && !containsMaskedValue(value),
      )
    : null

export const lastNotBlank = (params: IAggFuncParams<any, BlankOrMaskedValue>) =>
  params.values.length > 0
    ? last(
        params.values.filter(
          (value) => !containsBlankValue(value) && !containsMaskedValue(value),
        ),
      )
    : null
