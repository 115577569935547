export const PUSHER_EVENT_CALCULATION_PROGRESS = 'calculation_update'
export const PUSHER_EVENT_VERSION_CREATED = 'version_update'
export const PUSHER_EVENT_LOCKER_STATE_CHANGE = 'locker_state_change'
export const PUSHER_EVENT_CELL_DATA_UPDATE = 'cell_data_update'

export type VersionCreatedEventPayload = {
  version_id: string
  status: 'created' | 'fail'
}

export type LockerStateChangeEventPayload = {
  version_id: string
}
