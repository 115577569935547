import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'

type SharedProps = {
  goBack: () => void
  group: {
    id: string
    label: string
    aggregation: unknown
    weighedMetric: Maybe<{
      id: string
      label: string
    }>
    dataType: unknown
    currency: unknown
  }
}

const Editable: React.FC<
  SharedProps & {
    deleteReportingGroup: (groupId: string) => void
    changeAggregation: (groupId: string, aggregation: unknown) => void
    changeWeighedMetric: (groupId: string, metricId: string) => void
    changeDataType: (groupId: string, dataType: unknown) => void
    changeCurrency: (groupId: string, currency: unknown) => void
    availableWeighedMetrics: Array<{
      id: string
      label: string
    }>
  }
> = () => <div>Editable</div>

const Readonly: React.FC<SharedProps> = () => <div>Readonly</div>

export const ReportingGroupSettingsView = {
  Editable,
  Readonly,
} as const
