import React, { useCallback } from 'react'
import type { PopulateForwardButtonDefinition } from './types'
import type { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { Menu } from './Menu'
import { Tip } from './Tip'
import { RowNode } from 'ag-grid-community'
import { BaseGridRowData } from '@fintastic/shared/ui/grid-framework'
import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'

export type PopulateForwardProps<
  TData extends BaseGridRowData = BaseGridRowData,
> = {
  value: MetricDataValue
  data: TData
  gridApi: GridApi
  rowNode: RowNode
  buttons: PopulateForwardButtonDefinition<TData>[]
}

export const PopulateForward = <
  TData extends BaseGridRowData = BaseGridRowData,
>({
  buttons,
  value,
  data,
  gridApi,
  rowNode,
}: PopulateForwardProps<TData>): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClickButton = useCallback(
    (button: PopulateForwardButtonDefinition<TData>) => {
      setAnchorEl(null)
      button.onClick(value, data, rowNode, gridApi)
    },
    [value, data, rowNode, gridApi],
  )

  return (
    <>
      <Tip onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        onButtonClick={handleClickButton}
        buttons={buttons}
      />
    </>
  )
}
