import { IAggFuncParams } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'

// copied from the source code:
// https://github.com/ag-grid/ag-grid/blob/ca68754eef544c3ece8d3fb800f70d39e9be23e1/grid-enterprise-modules/row-grouping/src/rowGrouping/aggFuncService.ts#L127
export function first<TData = any, TValue = any>(
  params: IAggFuncParams<TData, TValue>,
): Maybe<TValue> {
  return params.values.length > 0 ? params.values[0] : null
}
