import React from 'react'
import { createSvgIcon } from '@mui/material'

export const MetricChartDimensionIcon = createSvgIcon(
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_147_5836)">
      <path
        d="M19.5917 6.29418L11.0833 1.89335C10.9009 1.79915 10.6986 1.75 10.4933 1.75C10.288 1.75 10.0857 1.79915 9.90333 1.89335L1.39666 6.29418C1.31286 6.33725 1.24256 6.40258 1.19347 6.48299C1.14438 6.56341 1.11841 6.6558 1.11841 6.75002C1.11841 6.84423 1.14438 6.93662 1.19347 7.01704C1.24256 7.09746 1.31286 7.16279 1.39666 7.20585L9.90416 11.6067C10.0866 11.7009 10.2889 11.75 10.4942 11.75C10.6994 11.75 10.9018 11.7009 11.0842 11.6067L19.5917 7.20585C19.6752 7.16258 19.7453 7.09718 19.7942 7.01679C19.8431 6.93639 19.8689 6.84411 19.8689 6.75002C19.8689 6.65592 19.8431 6.56364 19.7942 6.48325C19.7453 6.40286 19.6752 6.33745 19.5917 6.29418Z"
        stroke="#263646"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.13171 11.125L9.90421 15.3567C10.0866 15.4509 10.2889 15.5 10.4942 15.5C10.6995 15.5 10.9018 15.4509 11.0842 15.3567L19.8817 11.125"
        stroke="#263646"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.13171 14.875L9.90421 19.1067C10.0866 19.2009 10.2889 19.25 10.4942 19.25C10.6995 19.25 10.9018 19.2009 11.0842 19.1067L19.8817 14.875"
        stroke="#263646"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_147_5836">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>,
  'MetricChartColumn',
)
