import React, { useCallback, useMemo, useRef, useState } from 'react'
import { SmartSelectOption } from '../types'
import { keyBy } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'
import useResizeObserver from 'use-resize-observer'
import { matchSorter } from 'match-sorter'
import { SmartSelectInputButton } from '../common/SmartSelectInputButton'
import { Divider, Paper, Popover, SxProps, Theme } from '@mui/material'
import { SearchInput } from '../common/SearchInput'
import { OptionsList } from './OptionsList'
import { baseSorter } from '../common/match-sorter-utils'

export type SmartSelectProps<T> = {
  options: SmartSelectOption<T>[]
  value: T
  onChange: (v: T) => void
  placeholder?: React.ReactNode
  disabled?: boolean
  buttonSx?: SxProps<Theme>
  label?: string
  initialOpen?: boolean
  onClose?: (v?: T) => void
}

export const SmartSelect = <T,>({
  options,
  value,
  onChange,
  placeholder,
  disabled,
  buttonSx,
  label,
  initialOpen,
  onClose,
}: SmartSelectProps<T>) => {
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(initialOpen ?? false)

  const handleOpenMenu = useCallback(() => {
    setIsOpen(() => true)
  }, [])

  const handleCloseMenu = useCallback(
    (v?: T) => {
      if (isOpen) {
        // inform parent that selection is done and with which result
        onClose?.(v)
      }
      setIsOpen(false)
    },
    [isOpen, onClose],
  )

  const handleToggleMenu = useCallback(() => {
    if (isOpen) {
      handleCloseMenu()
    } else {
      handleOpenMenu()
    }
  }, [handleCloseMenu, handleOpenMenu, isOpen])

  const optionsMap = useMemo(() => keyBy(options, 'value'), [options])
  const selectedOption = optionsMap[`${value}`]

  const inputButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const { width: inputButtonWidth } = useResizeObserver({
    ref: inputButtonRef,
    box: 'border-box',
  })

  const filteredOptions = useMemo(
    () =>
      search === ''
        ? options
        : matchSorter(options, search, {
            keys: ['label'],
            baseSort: baseSorter,
          }),
    [options, search],
  )

  const handleSelectOption = useCallback(
    (v: T) => {
      handleCloseMenu(v)
      onChange(v)
    },
    [handleCloseMenu, onChange],
  )

  return (
    <>
      <SmartSelectInputButton<T>
        onClick={handleToggleMenu}
        isOpened={isOpen}
        values={[selectedOption]}
        placeholder={placeholder}
        ref={inputButtonRef}
        disabled={disabled}
        sx={buttonSx}
        label={label}
      />
      {inputButtonRef.current && (
        <Popover
          open={isOpen}
          anchorEl={inputButtonRef.current}
          onClose={() => handleCloseMenu()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Paper
            sx={{
              width: inputButtonWidth,
            }}
          >
            <SearchInput value={search} onChange={setSearch} />
            <Divider />
            <OptionsList<T>
              options={filteredOptions}
              selected={value}
              onSelectOption={handleSelectOption}
            />
          </Paper>
        </Popover>
      )}
    </>
  )
}
