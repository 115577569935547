import React, { useMemo } from 'react'
import { useBoardsFoldersTree } from '../../hooks/useBoardsFoldersTree'
import {
  StyledBoardsSidebarMenuFooter,
  StyledBoardsSidebarMenuLoader,
  StyledBoardsSidebarMenuLoaderProgress,
  StyledBoardsSidebarMenuRoot,
} from './BoardsSidebarMenu.styled'
import { AddNewBoardButton } from './AddNewBoardButton'
import {
  useModalState,
  useModalWithParamsState,
} from '@fintastic/shared/util/modal'
import { BoardCreateModal } from '../modals/BoardCreateModal'
import { BoardRenameModal } from '../modals/BoardRenameModal'
import { useBoardPageParams } from '../../hooks/useBoardPageParams'
import { createSidebarBoardContextMenuBuilder } from '../../utils/createSidebarBoardContextMenuBuilder'
import { createSidebarFolderContextMenuBuilder } from '../../utils/createSidebarFolderContextMenuBuilder'
import { useBoardsFoldersListQuery } from '../../hooks/useBoardsFoldersListQuery'
import { useBoardMoveToFolder } from '../../hooks/useBoardMoveToFolder'
import { Maybe } from '@fintastic/shared/util/types'
import { Board, BoardFolder } from '../../types'
import { FolderRenameModal } from '../modals/FolderRenameModal'
import { BoardDuplicateModal } from '../modals/BoardDuplicateModal'
import { BoardMoveToNewFolderModal } from '../modals/BoardMoveToNewFolderModal'
import { useOpenDefaultBoard } from '../../hooks/useOpenDefaultBoard'
import { BoardArchiveModal } from '../modals/BoardArchiveModal'
import { useBoardUnDeleteMutation } from '../../hooks/useBoardUnDeleteMutation'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'
import { BoardsSidebarTreeWithSearch } from './BoardsSidebarTreeWithSearch'
import { BoardShareModal } from '../modals/BoardShareModal'
import { NoSharedBoardsMessage } from '../BoardShare/NoSharedBoardsMessage'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { BoardNewDesignModal } from '../modals/BoardNewDesignModal'
import { BoardDeleteModal } from '../modals/BoardDeleteModal'

export const BoardsSidebarMenu: React.FC = () => {
  const { isLoading, tree } = useBoardsFoldersTree()
  const foldersListQuery = useBoardsFoldersListQuery()
  const activeBoard = useBoardPageParams().id

  useOpenDefaultBoard()

  const {
    isOpen: isCreateModalOpened,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModalState()

  const {
    isOpen: isNewDesignModalOpened,
    open: openNewDesignModal,
    close: closeNewDesignModal,
  } = useModalState()

  const {
    isOpen: isRenameModalOpened,
    openWithParams: openRenameModal,
    close: closeRenameModal,
    params: renameModalParams,
  } = useModalWithParamsState<{ boardId?: Maybe<Board['id']> }>({
    initialParams: { boardId: null },
  })

  const {
    isOpen: isDuplicateModalOpened,
    openWithParams: openDuplicateModal,
    close: closeDuplicateModal,
    params: duplicateModalParams,
  } = useModalWithParamsState<{ boardId?: Maybe<Board['id']> }>({
    initialParams: { boardId: null },
  })

  const {
    isOpen: isCreateAndMoveModalOpened,
    openWithParams: openCreateAndMoveModal,
    close: closeCreateAndMoveModal,
    params: createAndMoveModalParams,
  } = useModalWithParamsState<{
    boardId?: Maybe<Board['id']>
    boardName?: Maybe<Board['name']>
  }>({
    initialParams: { boardId: null, boardName: null },
  })

  const {
    isOpen: isFolderRenameModalOpened,
    openWithParams: openFolderRenameModal,
    close: closeFolderRenameModal,
    params: folderRenameModalParams,
  } = useModalWithParamsState<{ folderId?: Maybe<BoardFolder['id']> }>({
    initialParams: { folderId: null },
  })

  const {
    isOpen: isArchiveModalOpened,
    openWithParams: openArchiveModal,
    close: closeArchiveModal,
    params: archiveModalParams,
  } = useModalWithParamsState<{
    boardId?: Maybe<BoardFolder['id']>
    boardName?: Maybe<Board['name']>
  }>({
    initialParams: { boardId: null, boardName: null },
  })

  const {
    isOpen: isDeleteModalOpened,
    openWithParams: openDeleteModal,
    close: closeDeleteModal,
    params: deleteModalParams,
  } = useModalWithParamsState<{
    boardId?: Maybe<BoardFolder['id']>
    boardName?: Maybe<Board['name']>
  }>({
    initialParams: { boardId: null, boardName: null },
  })

  const {
    isOpen: isShareModalOpened,
    openWithParams: openShareModal,
    close: closeShareModal,
    params: shareModalParams,
  } = useModalWithParamsState<{
    boardId?: Maybe<BoardFolder['id']>
    boardName?: Maybe<Board['name']>
  }>({
    initialParams: { boardId: null, boardName: null },
  })

  const showCreateNew = useIsFeatureEnabled('layout_v2_show_add_new_board')
  const { moveBoardToFolder } = useBoardMoveToFolder()
  const unDeleteBoardMutation = useBoardUnDeleteMutation()

  const alwaysSeeAllBoards = Boolean(useRoleLevelAccess(['power_user', 'modeler']))
  const canDeleteBoard = Boolean(
    useRoleLevelAccess(['power_user', 'admin', 'modeler']),
  )

  const boardContextMenuBuilder = useMemo(
    () =>
      createSidebarBoardContextMenuBuilder({
        folders: foldersListQuery.data || [],
        canDeleteBoard,
        handleCreateFolderAndMove: (boardId, boardName) => {
          openCreateAndMoveModal({ boardId, boardName })
        },
        handleMoveToFolder: (boardId, folderId) => {
          moveBoardToFolder(boardId, folderId)
        },
        handleArchive: (boardId, boardName) => {
          openArchiveModal({ boardId, boardName })
        },
        handleUnArchive: (boardId) => {
          unDeleteBoardMutation.mutate({ boardId })
        },
        handleDelete: (boardId, boardName: string) => {
          openDeleteModal({ boardId, boardName })
        },
        handleDuplicate: (boardId) => {
          openDuplicateModal({ boardId })
        },
        handleRename: (boardId) => {
          openRenameModal({ boardId })
        },
        handleShare: (boardId, boardName) => {
          openShareModal({ boardId, boardName })
        },
      }),
    [
      foldersListQuery.data,
      canDeleteBoard,
      openCreateAndMoveModal,
      moveBoardToFolder,
      openArchiveModal,
      unDeleteBoardMutation,
      openDeleteModal,
      openDuplicateModal,
      openRenameModal,
      openShareModal,
    ],
  )

  const folderContextMenuBuilder = useMemo(
    () =>
      createSidebarFolderContextMenuBuilder({
        handleRename: (folderId) => {
          openFolderRenameModal({ folderId })
        },
      }),
    [openFolderRenameModal],
  )

  const hasOnlyArchive = useMemo<boolean>(
    () =>
      tree.rootNodes.filter((node) => node.data.id !== '_archive').length === 0,
    [tree],
  )

  return (
    <>
      <StyledBoardsSidebarMenuRoot data-testid={'boards-sidebar-menu-root'}>
        {isLoading && (
          <StyledBoardsSidebarMenuLoader
            data-testid={'boards-sidebar-menu-loader'}
          >
            <StyledBoardsSidebarMenuLoaderProgress size={24} />
          </StyledBoardsSidebarMenuLoader>
        )}
        {!isLoading && hasOnlyArchive && !alwaysSeeAllBoards && (
          <NoSharedBoardsMessage />
        )}
        {!isLoading && (!hasOnlyArchive || alwaysSeeAllBoards) && (
          <>
            <BoardsSidebarTreeWithSearch
              tree={tree}
              activeBoard={activeBoard}
              boardContextMenuBuilder={boardContextMenuBuilder}
              folderContextMenuBuilder={folderContextMenuBuilder}
              onNewBoard={openNewDesignModal}
            />

            {showCreateNew && (
              <StyledBoardsSidebarMenuFooter>
                <AddNewBoardButton onClick={openCreateModal} />
              </StyledBoardsSidebarMenuFooter>
            )}
          </>
        )}
      </StyledBoardsSidebarMenuRoot>

      <BoardNewDesignModal
        isOpen={isNewDesignModalOpened}
        onRequestClose={closeNewDesignModal}
      />
      <BoardCreateModal
        isOpen={isCreateModalOpened}
        onRequestClose={closeCreateModal}
      />
      <BoardRenameModal
        isOpen={isRenameModalOpened}
        onRequestClose={closeRenameModal}
        boardId={renameModalParams.boardId}
      />
      <FolderRenameModal
        isOpen={isFolderRenameModalOpened}
        onRequestClose={closeFolderRenameModal}
        folderId={folderRenameModalParams.folderId}
      />
      <BoardDuplicateModal
        isOpen={isDuplicateModalOpened}
        onRequestClose={closeDuplicateModal}
        boardId={duplicateModalParams.boardId}
      />
      <BoardMoveToNewFolderModal
        isOpen={isCreateAndMoveModalOpened}
        onRequestClose={closeCreateAndMoveModal}
        boardId={createAndMoveModalParams.boardId}
      />
      <BoardArchiveModal
        isOpen={isArchiveModalOpened}
        onRequestClose={closeArchiveModal}
        boardId={archiveModalParams.boardId}
        boardName={archiveModalParams.boardName}
      />
      <BoardDeleteModal
        isOpen={isDeleteModalOpened}
        onRequestClose={closeDeleteModal}
        boardId={deleteModalParams.boardId}
        boardName={deleteModalParams.boardName}
      />
      {isShareModalOpened && shareModalParams.boardId && (
        <BoardShareModal
          isOpen={isShareModalOpened}
          onRequestClose={closeShareModal}
          boardId={shareModalParams.boardId}
          boardName={shareModalParams.boardName}
        />
      )}
    </>
  )
}
