import React from 'react'
import { Typography } from '@mui/material'

export const CardSectionLabel: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Typography variant="overline" sx={{ color: '#6E665E' }}>
    {children}
  </Typography>
)
