/* eslint-disable no-param-reassign */

import { WritableDraft } from '@fintastic/shared/data-access/redux'
import {
  Metric,
  MetricOrListSource,
} from '@fintastic/web/util/metrics-and-lists'
import { State } from '../types'
import { MetricEditingState } from '../../state'
import {
  NotACalculatedMetricError,
  NotAMetricError,
  NotAnInputMetricError,
} from './errors'
import { MetricWrapper } from '../../../abstract-data-types/metric'

export function castToMetricEditingState(
  state: WritableDraft<State>,
): WritableDraft<MetricEditingState> | never {
  if (state.type !== 'metric') {
    throw new NotAMetricError()
  }
  return state
}

export function checkMetricSourceOrThrowError(
  metric: Metric,
  source: MetricOrListSource,
): never | void {
  if (new MetricWrapper(metric).source() === source) {
    return
  }

  if (source === 'input') {
    throw new NotAnInputMetricError()
  }
  throw new NotACalculatedMetricError()
}
