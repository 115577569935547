import { IconRootAttributes } from './types'

export function wrapSvgStringIcon(
  iconName: string,
  svgString: string,
  rootAttributes?: IconRootAttributes,
): string {
  return `<span class="ag-icon ag-fintastic-icon ag-fintastic-icon-${iconName}" unselectable="on" role="presentation"><span class="ag-fintastic-icon-container"${
    rootAttributes?.title !== undefined
      ? ` title="${rootAttributes?.title}"`
      : ''
  }>${svgString}</span></span>`
}
