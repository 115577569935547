import React from 'react'
import { StyledWidgetInvisibleContainerRoot } from './WidgetInvisibleContainer.styled'
import { BoxProps } from '@mui/material'

export type WidgetInvisibleContainerProps = BoxProps

export const WidgetInvisibleContainer = React.forwardRef<
  HTMLElement,
  WidgetInvisibleContainerProps
>((props, ref) => <StyledWidgetInvisibleContainerRoot ref={ref} {...props} />)
