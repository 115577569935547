import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { GetCellEditorProps } from './types'
import {
  containsMaskedValue,
  MASKED_VALUE_UI_REPRESENTATION,
} from '@fintastic/web/util/blanks-and-masked'
import {
  DimensionId,
  DimensionValuesMap,
  VersionDimension,
} from '@fintastic/web/util/dimensions'
import { ListDimensionsPermissionsApi } from '@fintastic/web/feature/lists'
import { styleKit } from '@fintastic/shared/ui/mui-theme'
import { MetricOptionDataValueType } from '@fintastic/web/util/metrics-and-lists'

type Option = { label: string; value: string }

type VersionDimensionWithValues = VersionDimension & {
  values: DimensionValuesMap
}

export const getDimensionalCellEditorProps = (
  column: ListColumnWrapper,
  versionDimensions: Record<DimensionId, VersionDimension>,
  dimensionsPermissions: ListDimensionsPermissionsApi,
): GetCellEditorProps => {
  const columnDimensionId = column.valuesDimensionId() || ''

  const columnDimension: VersionDimension = versionDimensions[columnDimensionId]

  const dimensionMetricOptions =
    (columnDimension as VersionDimensionWithValues)?.values || {}

  let dimensionOptions: Option[] = Object.entries(dimensionMetricOptions)
    .map(
      ([k, v]): Option => ({
        label: v,
        value: k,
      }),
    )
    .sort(({ label: a }, { label: b }) => a.localeCompare(b))

  if (dimensionsPermissions.dimensionValueAllowedToBeChosen) {
    dimensionOptions = dimensionOptions.filter(({ value }) => {
      if (
        dimensionsPermissions.noRestrictions ||
        !dimensionsPermissions.dimensionHasRestrictions?.(columnDimensionId)
      ) {
        return true
      }

      return dimensionsPermissions.dimensionValueAllowedToBeChosen(value)
    })
  }

  const valueFormatter = ({ value }: { value: unknown }) => {
    if (typeof value !== 'string' || value === '') {
      if (containsMaskedValue(value)) {
        return MASKED_VALUE_UI_REPRESENTATION
      }
      return 'Select value'
    }

    return dimensionOptions.find((r) => r.value === value)?.label || value
  }

  return {
    valueFormatter,
    cellStyle: (params: { value: unknown }) => {
      if (!params.value) {
        return { color: styleKit.colors.general.grey }
      }
      return null
    },
    cellEditorParams: {
      options: dimensionOptions,
      dataType: column.dataType() as MetricOptionDataValueType,
      emptyText: 'Select value',
    },
  }
}
