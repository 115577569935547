import { AgGridCellRendererProps } from './types'
import { StyledAgGridDefaultCellRendererRoot } from './AgGridDefaultCellRenderer/AgGridDefaultCellRenderer.styled'
import { Check } from '@mui/icons-material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'

export const CheckboxCellRenderer: React.FC<
  AgGridCellRendererProps<{
    fallbackForNonEditable?: string
  }>
> = ({ column, value, node, fallbackForNonEditable }) => {
  const isEditable = column?.isCellEditable(node) || false

  if (!isEditable && fallbackForNonEditable) {
    return <>{fallbackForNonEditable}</>
  }

  return (
    <StyledAgGridDefaultCellRendererRoot
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {value === 1 && <Check fontSize="small" />}
      {value !== 1 && <ClearOutlinedIcon fontSize="small" />}
    </StyledAgGridDefaultCellRendererRoot>
  )
}
