import { createSvgIcon } from '@mui/material'
import React from 'react'

export const CheckIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.06 6.86831L9.16472 18.7636L3 12.5989L3.86831 11.7306L9.16472 17.027L20.1917 6L21.06 6.86831Z"
  />,
  'Check',
)
