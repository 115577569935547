import { Box, Divider, List, TextField } from '@mui/material'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { LabelItem, OnLabelCallbacks } from './types'
import { LabelsDropdownItem } from './LabelsDropdownItem'
import { LabelsDropdownCreateNew } from './LabelsDropdownCreateNew'
import { LabelsDropdownNoItems } from './LabelsDropdownNoItems'

export type LabelsDropdownProps = {
  labels: Array<LabelItem>
} & OnLabelCallbacks

export const LabelsDropdown: React.FC<LabelsDropdownProps> = ({
  labels,
  ...callbacks
}) => {
  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >((e) => {
    setSearch(e.target.value)
  }, [])

  const filteredLabels = useMemo<LabelItem[]>(() => {
    const trimmedSearch = search.trim().toLocaleLowerCase()
    if (!trimmedSearch) {
      return labels
    }

    return labels.filter(({ title }) =>
      title.toLocaleLowerCase().includes(trimmedSearch),
    )
  }, [labels, search])

  const searchInputRef = useRef<HTMLInputElement>(null)

  const allowNewLabel = useMemo(
    () =>
      !!search.trim() &&
      !filteredLabels.find((label) => label.title === search),
    [filteredLabels, search],
  )

  return (
    <Box>
      <Box p="6px">
        <TextField
          onChange={handleSearchChange}
          placeholder="Add label"
          fullWidth
          size="small"
          autoComplete="off"
          inputRef={searchInputRef}
        />
      </Box>
      <Divider />
      <Box maxHeight={'40vh'} sx={{ overflowY: 'auto' }}>
        <List disablePadding>
          {allowNewLabel ? (
            <LabelsDropdownCreateNew
              text={search}
              onCreate={callbacks.onLabelCreated}
              searchInputRef={searchInputRef}
            />
          ) : null}
          {filteredLabels.length === 0 && !allowNewLabel ? (
            <LabelsDropdownNoItems />
          ) : null}
          {filteredLabels.map((label) => (
            <LabelsDropdownItem key={label.id} label={label} {...callbacks} />
          ))}
        </List>
      </Box>
    </Box>
  )
}
