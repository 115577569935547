import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useVersionUserLocks } from '@fintastic/web/data-access/versions'
import { Button, Tooltip } from '@mui/material'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'
import {
  ReportSettingsPanelNavigationContext,
  settingsPanelNavigationDefaultPathHistory,
  useReportEditorApi,
} from '@fintastic/web/feature/report-editor'
import {
  Panel,
  PanelTopbar,
  PanelTopbarTitle,
} from '@fintastic/shared/ui/panel-framework'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { WidgetReportIcon } from '@fintastic/shared/ui/icons'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { SetGridSizeCallback } from '@fintastic/shared/ui/grid-framework'
import { SideBarDef } from 'ag-grid-community/dist/lib/entities/sideBar'
import { settingsToolPanelDefinition } from '@fintastic/web/feature/generic-report-table'
import {
  AgGridThemeFintasticWrapper,
  getFintasticAgIconsRecord,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import {
  addButtonsIdentifiers,
  agGridComponentsMap,
} from '@fintastic/shared/ui/ag-grid'
import { AgGridReact } from 'ag-grid-react'
import { useLocation } from '@fintastic/shared/util/memory-router'

export const GenericReportVersionPageDummy: React.FC<{
  versionId: string
  reportId: string
  title: string
  settingsEditingAllowed: boolean
  setGridSizeCallback: SetGridSizeCallback
}> = ({
  versionId,
  reportId,
  title,
  settingsEditingAllowed,
  setGridSizeCallback,
}) => {
  const reportsEditorApi = useReportEditorApi()
  const maybeInctiveReportEditorApi =
    reportsEditorApi && !reportsEditorApi.active ? reportsEditorApi : null
  const metricOrListEditorActive = useCurrentEditingFlow().flow !== null

  useEffect(() => {
    setGridSizeCallback({
      // @todo should be implemented properly like in BaseGrid component
      headerHeight: 40,
      rowHeight: 40,
      rows: 1,
    })
  }, [setGridSizeCallback])

  const versionUserLocks = useVersionUserLocks(
    useMemo(() => [versionId], [versionId]),
  )

  const routerLocationApi = useLocation(
    settingsPanelNavigationDefaultPathHistory,
  )

  const handleEdit = useCallback(() => {
    if (!maybeInctiveReportEditorApi || metricOrListEditorActive) {
      return
    }
    maybeInctiveReportEditorApi.startEditingOperationApi.mutateAsync({
      reportId,
    })
  }, [
    maybeInctiveReportEditorApi,
    metricOrListEditorActive,
    reportId,
    versionId,
  ])

  const editButton = useMemo(() => {
    if (!settingsEditingAllowed) {
      return null
    }

    if (!maybeInctiveReportEditorApi || metricOrListEditorActive) {
      return null
    }

    if (
      versionUserLocks[0].editIsBlocked &&
      versionUserLocks[0].lockedBy !== 'me'
    ) {
      return (
        <Tooltip title={versionIsLockedMessage(versionUserLocks[0])} arrow>
          <span>
            <Button variant="outlined" color="black" disabled>
              Edit
            </Button>
          </span>
        </Tooltip>
      )
    }

    const disabled =
      maybeInctiveReportEditorApi.startEditingOperationApi.isLoading

    const text = maybeInctiveReportEditorApi.startEditingOperationApi.isLoading
      ? 'Loading...'
      : 'Edit'

    return (
      <Button
        variant="outlined"
        color="black"
        onClick={handleEdit}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  }, [
    settingsEditingAllowed,
    maybeInctiveReportEditorApi,
    metricOrListEditorActive,
    versionUserLocks,
    handleEdit,
  ])

  return (
    <ReportSettingsPanelNavigationContext.Provider value={routerLocationApi}>
      <Panel
        topbar={
          <PanelTopbar
            enableBorderBottom={true}
            leftContent={<span></span>}
            centerContent={
              <PanelTopbarTitle
                icon={
                  <Tooltip title="Report">
                    <WidgetReportIcon
                      sx={{
                        color: '#D45702',
                      }}
                    />
                  </Tooltip>
                }
              >
                {titleFormatter(title)}
              </PanelTopbarTitle>
            }
            rightContent={
              <FintasticThemeProvider applyLegacyTheme={false}>
                {editButton}
              </FintasticThemeProvider>
            }
          />
        }
      >
        <AgGridConnector />
      </Panel>
    </ReportSettingsPanelNavigationContext.Provider>
  )
}

const sidebar: SideBarDef = {
  toolPanels: [settingsToolPanelDefinition],
}

const icons = getFintasticAgIconsRecord()

export const AgGridConnector: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onGridReady = useCallback((event: GridReadyEvent) => {
    if (containerRef.current) {
      addButtonsIdentifiers(containerRef.current)
    }
  }, [])

  return (
    <AgGridThemeFintasticWrapper
      ref={containerRef}
      sx={{
        flex: 1,
        width: '100%',
        height: '100%',
      }}
      themeOptions={{
        inscribedShape: true,
      }}
    >
      {({ defaultGridProps }) => (
        <AgGridReact
          {...defaultGridProps}
          sideBar={sidebar}
          icons={icons}
          rowData={[]}
          columnDefs={[]}
          components={agGridComponentsMap}
          onGridReady={onGridReady}
          suppressContextMenu={true}
          suppressCellFocus={true}
        />
      )}
    </AgGridThemeFintasticWrapper>
  )
}
