import { Box, styled } from '@mui/material'

export const StyledEntityFiltersContainer = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const StyledEntityFiltersControls = styled(Box)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(2)} 16px;
`

export const StyledEntityFiltersDateControls = styled(Box)`
  grid-column: 1 / 3;
`
