import { useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  BaseTimeDimensionDynamicFilteredContext,
  BaseTimeDimensionDynamicFilteredEntitiesMap,
} from '../types'

/**
 * Applies single `baseTimeDimensionId` controlled by consumer
 * TBD: when we have API
 */
export const useBaseTimeDimensionDynamicFilteredAdapter =
  (): BaseTimeDimensionDynamicFilteredContext => {
    // Not used now - implement when API is ready
    const [, setTimeDimensionEntities] =
      useState<Maybe<BaseTimeDimensionDynamicFilteredEntitiesMap>>(null)

    return useMemo<BaseTimeDimensionDynamicFilteredContext>(
      () => ({
        baseTimeDimensionId: null,
        setTimeDimensionEntities,
        status: 'ready',
        type: 'global',
      }),
      [],
    )
  }
