import { createContext, useContext } from 'react'
import { AddLinesInternalContext } from '../types'
import { Maybe } from '@fintastic/shared/util/types'

export const AddLinesStateContext =
  createContext<Maybe<AddLinesInternalContext>>(null)

export function useAddLinesStateContext() {
  return useContext(AddLinesStateContext)
}
