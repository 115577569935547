import React, { useEffect } from 'react'
import {
  WidgetSelect,
  WidgetSelectDataWrapperProps,
  WidgetSelectEmpty,
} from '@fintastic/web/feature/boards'
import { useLoadListOfMetrics } from '@fintastic/web/data-access/metrics-and-lists'

export const PENDING_METRICS_ID = 'listOfMetrics'

export const WidgetSelectMetric: React.FC<WidgetSelectDataWrapperProps> = (
  props,
) => {
  const { data: entityList, isLoading } = useLoadListOfMetrics(
    props.versionId || '',
    false,
  )

  useEffect(() => {
    if (isLoading) {
      props.onFetching?.(PENDING_METRICS_ID)
    } else {
      props.onReady?.(PENDING_METRICS_ID)
    }
  }, [isLoading, entityList, props])

  if (!isLoading && (entityList || []).length === 0) {
    return (
      <WidgetSelectEmpty
        emptyText={
          props.value
            ? 'Metrics data in the version is not available'
            : 'No data to select'
        }
      />
    )
  }

  return (
    <WidgetSelect
      {...props}
      entityList={entityList || []}
      isLoading={isLoading}
      emptyText={'Select metric'}
      loadingText={'Loading list of Metrics...'}
    />
  )
}
