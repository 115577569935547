import { useCallback, useEffect, useRef } from 'react'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'

const REPORT_MESSAGE = 'Cancel Report editing? Unsaved settings will be lost.'
const makeMetricOrListMessage = (type: 'metric' | 'list') =>
  `Cancel ${
    type === 'metric' ? 'Metric' : 'List'
  } editing? Unsaved data and settings will be lost.`

export function useTabClosingPrompt() {
  const currentEditingFlow = useCurrentEditingFlow()
  const reportEditorApi = useReportEditorApi()

  const editingEntity = reportEditorApi?.active
    ? ('report' as const)
    : currentEditingFlow.type
  const editingEntityRef = useRef(editingEntity)
  editingEntityRef.current = editingEntity

  const handler = useCallback((e: BeforeUnloadEvent) => {
    if (editingEntityRef.current === null) {
      return ''
    }
    const message =
      editingEntityRef.current === 'report'
        ? REPORT_MESSAGE
        : makeMetricOrListMessage(editingEntityRef.current)
    e.returnValue = message
    return message
  }, [])

  useEffect(() => {
    if (editingEntity === null) {
      window.removeEventListener('beforeunload', handler, { capture: true })
      return
    }
    window.addEventListener('beforeunload', handler, { capture: true })
    return () => {
      window.removeEventListener('beforeunload', handler, { capture: true })
    }
  }, [editingEntity, handler])
}
