import { Box, styled } from '@mui/material'

export const StyledChartTable = styled(Box)`
  width: 100%;
  color: #bbb;
  display: flex;
  flex-flow: column wrap;
  place-content: center;
  place-items: center;

  .ag-root-wrapper.ag-layout-normal {
    border: none;
  }
`
