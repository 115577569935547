/* eslint-disable camelcase */
import { Maybe } from '@fintastic/shared/util/types'

export function isVersionLocked(
  version?: Maybe<{
    locked_at?: Maybe<string | Date>
  }>,
): boolean {
  return !!version?.locked_at
}

export function isVersionArchived(
  version?: Maybe<{
    archived_at?: Maybe<string | Date>
  }>,
): boolean {
  return !!version?.archived_at
}

export function isVersionEditable(
  version?: Maybe<{ editable?: Maybe<boolean> }>,
): boolean {
  return !!version?.editable
}
