import { Nullable } from '@fintastic/shared/util/functional-programming'
import { useQuery } from 'react-query'
import {
  DimensionPermissionsForLists,
  getDimensionsPermissionsForLists,
  metricsAndListsCacheKeys,
} from '@fintastic/web/data-access/metrics-and-lists'
import { UseQueryResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

export const useLoadDimensionsPermissionsForLists = (
  versionId: Nullable<string>,
  enabled: boolean,
): UseQueryResult<DimensionPermissionsForLists, AxiosError> =>
  useQuery(
    metricsAndListsCacheKeys.dimensionPermissionsForLists(versionId),
    async () => {
      const response = await getDimensionsPermissionsForLists(versionId!)
      return response.data
    },
    {
      enabled: enabled && !!versionId,
    },
  )
