import { useQuery } from 'react-query'
import { getReports } from '../api/reports-api'

// @todo: temporary solution! will be replaced with version-based soon
export function useLoadListOfReports() {
  return useQuery(
    ['reports'],
    async () => {
      const response = await getReports()
      return response.data.reports
    },
  )
}
