import { useDispatch } from 'react-redux'
import {
  AddColumnPayload,
  ChangeColumnCategoryAggregationPayload,
  ChangeColumnDimensionPayload,
  ChangeColumnFormulaPayload,
  ChangeColumnTimeAggregationPayload,
  ChangeColumnTimeBreakdownPayload,
  ChangeColumnTypePayload,
  ChangeColumnValueDataFormattingPayload,
  ChangeColumnValueDataTypePayload,
  RemoveColumnPayload,
  ReorderColumnsPayload,
  ChangeColumnNamePayload,
  ChangeColumnCurrencySignPositionPayload,
  ChangeColumnVisibilityPayload,
  ChangeColumnFormulaStatePayload,
} from '@fintastic/web/data-access/metrics-and-lists'
import { actions } from '../slice'
import { useMemo } from 'react'

export function useListColumnsActions() {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      addColumn: (p: AddColumnPayload) => {
        dispatch(actions.addColumn(p))
      },
      removeColumn: (p: RemoveColumnPayload) => {
        dispatch(actions.removeColumn(p))
      },
      reorderColumns: (p: ReorderColumnsPayload) => {
        dispatch(actions.reorderColumns(p))
      },
      changeType: (p: ChangeColumnTypePayload) => {
        dispatch(actions.changeColumnType(p))
      },
      changeName: (p: ChangeColumnNamePayload) => {
        dispatch(actions.changeColumnName(p))
      },
      changeDimension: (p: ChangeColumnDimensionPayload) => {
        dispatch(actions.changeColumnDimension(p))
      },
      changeFormula: (p: ChangeColumnFormulaPayload) => {
        dispatch(actions.changeColumnFormula(p))
      },
      changeFormulaState: (p: ChangeColumnFormulaStatePayload) => {
        dispatch(actions.changeColumnFormulaState(p))
      },
      changeTimeAggregation: (p: ChangeColumnTimeAggregationPayload) => {
        dispatch(actions.changeColumnTimeAggregation(p))
      },
      changeCategoryAggregation: (
        p: ChangeColumnCategoryAggregationPayload,
      ) => {
        dispatch(actions.changeColumnCategoryAggregation(p))
      },
      changeTimeBreakdown: (p: ChangeColumnTimeBreakdownPayload) => {
        dispatch(actions.changeColumnTimeBreakdown(p))
      },
      changeValueDataType: (p: ChangeColumnValueDataTypePayload) => {
        dispatch(actions.changeColumnValueDataType(p))
      },
      changeValueDataFormatting: (
        p: ChangeColumnValueDataFormattingPayload,
      ) => {
        dispatch(actions.changeColumnValueDataFormatting(p))
      },
      changeCurrencySignPosition: (
        p: ChangeColumnCurrencySignPositionPayload,
      ) => {
        dispatch(actions.changeColumnCurrencySignPosition(p))
      },
      changeVisibility: (p: ChangeColumnVisibilityPayload) => {
        dispatch(actions.changeColumnVisibility(p))
      },
    }),
    [dispatch],
  )
}
