import { SyntaxLiteralType } from './types'

export const literalTypeLabels: Record<SyntaxLiteralType, string> = {
  function: 'Function',
  constant: 'Constant',
  metric: 'Metric',
  calculatedMetric: 'ƒ(x) Metric',
  list: 'List',
  calculatedList: 'ƒ(x) List',
  listColumn: 'Column',
  calculatedListColumn: 'ƒ(x) Column',
  dimension: 'Dimension',
  dimensionValue: 'Dim. Value',
}
