import { styled } from '@mui/material'

export const StyledSectionRoot = styled('div')`
  opacity: 0;
  animation: appearSection 0.2s ease-in-out forwards;
  transform: translateX(10px);

  @keyframes appearSection {
    0% {
      opacity: 0;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
