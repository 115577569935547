import React, { useMemo } from 'react'
import { NavButton } from '@fintastic/shared/ui/view-layout'
import { Icon } from './Icon'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export type DimensionsNavButtonProps = {
  onClick: () => void
  dimensionsList: Omit<VersionDimension, 'values'>[]
  metricDimensionsIds: string[]
}

export const DimensionsNavButton: React.FC<DimensionsNavButtonProps> = ({
  onClick,
  dimensionsList,
  metricDimensionsIds,
}) => {
  const currentDimensionsLabel = useMemo(() => {
    if (metricDimensionsIds.length === 0) {
      return 'Nothing selected'
    }
    return dimensionsList
      .filter(({ id }) => metricDimensionsIds.includes(id))
      .map(({ label }) => label)
      .join(', ')
  }, [dimensionsList, metricDimensionsIds])

  return (
    <NavButton
      icon={<Icon />}
      onClick={onClick}
      primaryLabel="Dimensions"
      secondaryLabel={currentDimensionsLabel}
    />
  )
}
