import React, { memo, MouseEvent, useCallback, useMemo, useRef } from 'react'
import {
  ContextMenu,
  ContextMenuIcon,
  ContextMenuItem,
  useContextMenuState,
} from '@fintastic/shared/ui/context-menu-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { StyledTitleContextMenuButton } from './BoardTitle.styled'
import { PopoverOrigin } from '@mui/material'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'

const contextMenuOrigin: {
  anchorOrigin: PopoverOrigin
  transformOrigin: PopoverOrigin
} = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: -6,
    horizontal: 'right',
  },
}

type BoardTitleMenuProps = {
  onArchiveBoard?: () => void
  setDesignMode?: () => void
}

export const BoardTitleMenu: React.FC<BoardTitleMenuProps> = memo(
  ({ onArchiveBoard, setDesignMode }) => {
    const contextMenuButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
    const contextMenuState = useContextMenuState()
    const userAllowedToManageBoards = !!useRoleLevelAccess([
      'power_user',
      'admin',
      'modeler',
    ])
    const allowContextMenu = !!onArchiveBoard && !!setDesignMode

    const handleOpenContextMenu = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        if (!allowContextMenu) {
          return
        }
        if (contextMenuButtonRef.current === null) {
          return
        }
        contextMenuState.handleClickOnAnchor({
          target: contextMenuButtonRef.current,
        })
      },
      [contextMenuState, allowContextMenu],
    )

    const menuItems: ContextMenuItem[] = useMemo(
      () => [
        {
          id: 'editMode',
          type: 'button',
          onClick: setDesignMode,
          label: 'Edit',
        },
        {
          id: 'div-1',
          type: 'divider',
        },
        {
          id: 'archiveBoard',
          type: 'button',
          onClick: onArchiveBoard,
          label: 'Archive Board',
        },
      ],
      [setDesignMode, onArchiveBoard],
    )

    return (
      <>
        {userAllowedToManageBoards && (
          <StyledTitleContextMenuButton
            ref={contextMenuButtonRef}
            className={'design-menu'}
            size="small"
            disabled={!allowContextMenu}
            onClick={handleOpenContextMenu}
          >
            <ContextMenuIcon />
          </StyledTitleContextMenuButton>
        )}

        <ContextMenu
          menuItems={menuItems}
          open={contextMenuState.isOpen}
          anchorEl={contextMenuState.anchorElement}
          onClose={contextMenuState.handleClose}
          {...contextMenuOrigin}
        />
      </>
    )
  },
)
