import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'
import {
  ChartTheme,
  chartThemeKeys,
  chartThemes,
  defaultChartTheme,
  isChartTheme,
} from '@fintastic/web/feature/charts'
import {
  StyledTextWithoutIcon,
  StyledTextWithoutIconField,
} from '../common-styles'

export type ChartThemeSelectProps = {
  chartTheme: ChartTheme
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartThemeSelect: React.FC<ChartThemeSelectProps> = memo(
  ({ chartTheme, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartTheme(e.target.value)) {
          onChangeChartSettings({ theme: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextWithoutIconField
          id="chart-theme-select"
          fullWidth
          value={chartTheme}
          size="small"
          select
          label="Color palette"
          defaultValue={defaultChartTheme}
          onChange={handleSelect}
          disabled={disabled}
        >
          {chartThemeKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithoutIcon data-testid={`chart-theme-item-${ct}`}>
                {chartThemes[ct]}
              </StyledTextWithoutIcon>
            </MenuItem>
          ))}
        </StyledTextWithoutIconField>
      </FormField>
    )
  },
)
