import React, { useCallback, useRef } from 'react'
import {
  AgGridThemeFintasticWrapper,
  getFintasticAgIconsRecord,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { AgGridReact } from 'ag-grid-react'
import {
  addButtonsIdentifiers,
  agGridComponentsMap,
} from '@fintastic/shared/ui/ag-grid'
import { SideBarDef } from 'ag-grid-community/dist/lib/entities/sideBar'
import { listSettingsToolPanelDefinition } from '@fintastic/web/feature/metrics-and-lists'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { ColDef } from 'ag-grid-community'
import { ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { MetricGridRow } from '@fintastic/web/util/metrics-and-lists'

const sidebar: SideBarDef = {
  toolPanels: [listSettingsToolPanelDefinition],
}

const icons = getFintasticAgIconsRecord()

export type GridProps = {
  colDefs: (ColDef | ColGroupDef)[]
  rowData: MetricGridRow[]
}

export const Grid: React.FC<GridProps> = ({ colDefs, rowData }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onGridReady = useCallback((event: GridReadyEvent) => {
    if (containerRef.current) {
      addButtonsIdentifiers(containerRef.current)
    }
  }, [])

  return (
    <AgGridThemeFintasticWrapper
      ref={containerRef}
      sx={{
        flex: 1,
        width: '100%',
        height: '100%',
      }}
      themeOptions={{
        inscribedShape: true,
      }}
    >
      {({ defaultGridProps }) => (
        <AgGridReact
          {...defaultGridProps}
          sideBar={sidebar}
          icons={icons}
          rowData={rowData}
          columnDefs={colDefs}
          components={agGridComponentsMap}
          onGridReady={onGridReady}
          suppressContextMenu={true}
          suppressCellFocus={true}
        />
      )}
    </AgGridThemeFintasticWrapper>
  )
}
