import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeMetricCategoryAggregationPayload } from '@fintastic/web/data-access/metrics-and-lists'
import { State } from '../types'
import { castToMetricEditingState } from './helpers'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

export const changeMetricCategoryAggregation = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeMetricCategoryAggregationPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  const metric = new MutableMetricWrapper(state.metric)

  metric.applyCategoryAggregationChanges(
    payload.rollUpFunction,
    payload.weightsMetricId,
  )
}
