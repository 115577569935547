import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { MetricDisplaySettingsWrapper } from './MetricDisplaySettingsWrapper'
import { MetricDisplaySettings } from '@fintastic/web/util/metrics-and-lists'
import { createDefaultFormatting } from './constructors'

export class MutableMetricDisplaySettingsWrapper
  extends MetricDisplaySettingsWrapper
  implements MutableADTWrapper<MetricDisplaySettings>
{
  _rawData: MetricDisplaySettings

  constructor(displaySettings: MetricDisplaySettings) {
    super(displaySettings)
    this._rawData = displaySettings
  }

  setCurrency(currency: ReturnType<typeof this.currency>) {
    this._rawData.currency_name = currency === null ? '' : currency
  }

  setCurrencySignPosition(
    position: ReturnType<typeof this.currencySignPosition>,
  ) {
    this._rawData.currency_sign_position = position
  }

  setNumericDecimalPlaces(
    decimalPlaces: ReturnType<typeof this.numericDecimalPlaces>,
  ) {
    this._rawData.formatting.decimal_places = decimalPlaces
  }

  setNumericCompactFormat(
    formatting: ReturnType<typeof this.numericCompactFormat>,
  ) {
    this._rawData.formatting.compact_formatting =
      formatting === null ? 'no_format' : formatting
  }

  resetFormattingToDefault() {
    this._rawData.formatting = createDefaultFormatting()
  }

  setVisibleAsColumn(visible: boolean) {
    this._rawData.is_visible = visible
  }
}
