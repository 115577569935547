import { useCallback } from 'react'
import { ChangeColumnTimeBreakdownAction } from '../types'
import {
  MetricOrListEditingFlow,
  useListColumnsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useModalWithParamsState } from '@fintastic/shared/util/modal'
import { Maybe } from '@fintastic/shared/util/types'
import { DataContextValue } from '../../data/data-context'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

export function useChangeColumnTimeBreakdownFlow(
  { list, dimensionsList }: DataContextValue,
  flow: Maybe<MetricOrListEditingFlow>,
) {
  const { changeTimeBreakdown } = useListColumnsActions()

  const confirmationPopupState = useModalWithParamsState<{
    columnId: Maybe<string>
    applyBreakdown: Maybe<boolean>
  }>({
    initialParams: {
      columnId: null,
      applyBreakdown: null,
    },
  })

  const handleAction = useCallback<ChangeColumnTimeBreakdownAction>(
    (columnId, applyBreakdown) => {
      if (
        !list ||
        flow === null ||
        dimensionsList === null ||
        list.baseTimeDimension() === null
      ) {
        return
      }

      const column = list.findColumnByClientId(columnId)
      if (!column) {
        return
      }

      if (column.isNewColumn()) {
        changeTimeBreakdown({
          columnId,
          applyBreakdown,
          allDimensions: dimensionsList,
        })
        return
      }

      confirmationPopupState.openWithParams({
        columnId,
        applyBreakdown,
      })
    },
    [changeTimeBreakdown, confirmationPopupState, dimensionsList, flow, list],
  )

  const handleConfirm = useCallback(() => {
    const { columnId, applyBreakdown } = confirmationPopupState.params

    if (
      columnId !== null &&
      applyBreakdown !== null &&
      dimensionsList !== null
    ) {
      changeTimeBreakdown({
        columnId,
        applyBreakdown,
        allDimensions: dimensionsList,
      })
    }
  }, [changeTimeBreakdown, confirmationPopupState.params, dimensionsList])

  const applyBreakdown = !!confirmationPopupState.params.applyBreakdown
  const popups = (
    <ConfirmationPopup
      title={
        applyBreakdown
          ? 'Add time breakdown to this column?'
          : 'Remove time breakdown from this column?'
      }
      description={
        applyBreakdown
          ? 'Existing data will be copied to all new periods'
          : 'Existing data will be aggregated'
      }
      open={confirmationPopupState.isOpen}
      onRequestClose={confirmationPopupState.close}
      onConfirm={handleConfirm}
    >
      <ConfirmationPopup.DeclineButton
        variant="contained"
        color="primary"
        sx={{ mr: 1 }}
      >
        Cancel
      </ConfirmationPopup.DeclineButton>
      <ConfirmationPopup.ConfirmButton>
        {applyBreakdown ? 'Add breakdown' : 'Remove breakdown'}
      </ConfirmationPopup.ConfirmButton>
    </ConfirmationPopup>
  )

  return {
    action: handleAction,
    popups,
  }
}
