import { Maybe } from '@fintastic/shared/util/types'
import {
  DEFAULT_PERIOD_RESOLUTION,
  PeriodSelection,
  closestDimensionByResolution,
  useCalendarConfigForSelectedEntities,
} from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import { compact, first, last } from 'lodash'
import { Version } from '@fintastic/web/util/versions'
import { useCalendarDatePickerQuery } from '@fintastic/web/data-access/calendar'
import { useLoadMultipleVersionsEntities } from '../api/useLoadMultipleVersionsEntities'

export const useFullVersionRangePeriodSelection = (
  version?: Version,
): Maybe<PeriodSelection> => {
  const calendarQuery = useCalendarDatePickerQuery(version?.calendar_id ?? '')
  const versionIdAsArray = useMemo(
    () => compact([version?.uuid]),
    [version?.uuid],
  )
  const entitiesPerVersion = useLoadMultipleVersionsEntities(versionIdAsArray)
  const calendarConfigForSelectedEntities =
    useCalendarConfigForSelectedEntities({
      entitiesPerVersion: entitiesPerVersion?.data,
      calendarDatePickerConfig: calendarQuery?.data ?? null,
    })

  return useMemo<Maybe<PeriodSelection>>(() => {
    if (!calendarConfigForSelectedEntities) {
      return null
    }

    const activeDimension = closestDimensionByResolution(
      DEFAULT_PERIOD_RESOLUTION,
      calendarConfigForSelectedEntities,
    )

    if (!activeDimension?.values?.length) {
      return null
    }

    const from = first(activeDimension?.values)?.dimension_value_id
    const to = last(activeDimension?.values)?.dimension_value_id

    if (!from || !to) {
      return null
    }

    return {
      aggregationDimensionId: activeDimension.dimension_id,
      dimensionId: activeDimension.dimension_id,
      range: [from, to],
    }
  }, [calendarConfigForSelectedEntities])
}
