import {
  StyledModalFooterPanel,
  StyledModalFooterPanelCancelButton,
  StyledModalFooterPanelSubmitContainer,
  StyledModalFooterPanelSubmitErrorMessage,
} from './ModalFooterPanel.styled'
import React, { useMemo } from 'react'
import { LoadingButton } from '@mui/lab'
import { Tooltip } from '@mui/material'

export type ModalFooterPanelProps = {
  onCancel?: () => void
  loading?: boolean
  onSubmit?: () => void
  submitEnabled?: boolean
  cancelButtonText?: string
  submitButtonText?: string
  disabledReasonTooltip?: string
  submitErrorMessage?: string
}

export const ModalFooterPanel: React.FC<ModalFooterPanelProps> = ({
  onCancel,
  onSubmit,
  loading,
  cancelButtonText,
  submitEnabled,
  submitButtonText,
  disabledReasonTooltip,
  submitErrorMessage,
}) => {
  const submitButton = useMemo(() => {
    if (!onSubmit) {
      return null
    }

    if (disabledReasonTooltip && !submitEnabled && !loading) {
      return (
        <Tooltip
          enterNextDelay={700}
          enterDelay={700}
          leaveDelay={0}
          title={disabledReasonTooltip}
          disableInteractive={true}
          placement="top"
        >
          <span>
            <LoadingButton
              disabled={!submitEnabled}
              variant="contained"
              onClick={onSubmit}
              loading={loading}
              data-testid={'modal-footer-submit'}
            >
              {submitButtonText || 'Save'}
            </LoadingButton>
          </span>
        </Tooltip>
      )
    }

    return (
      <LoadingButton
        disabled={!submitEnabled}
        variant="contained"
        onClick={onSubmit}
        loading={loading}
        data-testid={'modal-footer-submit'}
      >
        {submitButtonText || 'Save'}
      </LoadingButton>
    )
  }, [
    disabledReasonTooltip,
    loading,
    onSubmit,
    submitButtonText,
    submitEnabled,
  ])

  return (
    <StyledModalFooterPanel data-testid={'modal-footer-panel'}>
      <StyledModalFooterPanelSubmitContainer>
        {onCancel && (
          <StyledModalFooterPanelCancelButton
            variant="outlined"
            onClick={onCancel}
            data-testid={'modal-footer-cancel'}
          >
            {cancelButtonText || 'Cancel'}
          </StyledModalFooterPanelCancelButton>
        )}
        {submitButton}
        {submitErrorMessage && (
          <StyledModalFooterPanelSubmitErrorMessage
            data-testid={'modal-footer-error-message'}
            variant="body2"
          >
            {submitErrorMessage}
          </StyledModalFooterPanelSubmitErrorMessage>
        )}
      </StyledModalFooterPanelSubmitContainer>
    </StyledModalFooterPanel>
  )
}
