import { ICellRendererParams } from 'ag-grid-community'
import styled from 'styled-components'

const $CellFullWidth = styled.div`
  display: inline-block;
  width: 100%;
  padding-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;

  .hidden-when-non-hovered {
    opacity: 0;
  }

  &:hover .hidden-when-non-hovered {
    opacity: 1;
  }
`

type LinkResolver = (value: string, params: ICellRendererParams) => string

export const ExternalLinkCellRenderer = (
  params: ICellRendererParams & { linkResolver: LinkResolver },
): JSX.Element => {
  const value = params.value
  const url = params.linkResolver(value, params)

  return (
    <$CellFullWidth>
      {url && (
        <a href={url} target="_blank" rel="noreferrer">
          {value}
        </a>
      )}
    </$CellFullWidth>
  )
}
