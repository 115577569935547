import type { ToolPanelDef } from 'ag-grid-community'
import { FilterToolPanel } from './FilterToolPanel'

export const LIST_FILTERS_TOOL_PANEL_ID = 'listFilters'

export const listFilterToolPanelDefinition: ToolPanelDef = {
  id: LIST_FILTERS_TOOL_PANEL_ID,
  labelDefault: '',
  toolPanel: FilterToolPanel,
  labelKey: 'filter',
  iconKey: 'filter',
  maxWidth: 250,
  minWidth: 250,
  width: 250,
}
