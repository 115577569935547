import React from 'react'
import { entityTypeToTypeLabel } from '../mapping'
import { WrappedVersionEntityDefinition } from '@fintastic/web/util/versions'

type StandardDescriptionProps = {
  lastColumnInList: boolean
  entityType: WrappedVersionEntityDefinition['type']
  affectedTypes?: string
}

export const StandardDescription: React.FC<StandardDescriptionProps> = ({
  lastColumnInList,
  entityType,
  affectedTypes = 'Metrics / Lists / Reports',
}) => {
  const entityText = entityTypeToTypeLabel[entityType]

  return (
    <>
      {lastColumnInList && (
        <span style={{ marginTop: '1em', display: 'block' }}>
          You're attempting to delete the last column in the list. It leads to
          the deletion of the list itself.
        </span>
      )}
      <span style={{ marginTop: '1em', display: 'block' }}>
        The {entityText} you are attempting to delete is currently being used by
        one or more {affectedTypes}' columns.
      </span>
      <span style={{ marginTop: '0.8em', display: 'block' }}>
        Please remove the {entityText} from the formula or from the aggregation
        settings of the following {affectedTypes}' columns and try again:
      </span>
    </>
  )
}
