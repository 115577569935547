import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { AllowDenyToggle } from './components/AllowDenyToggle'

import { styled } from '@mui/material/styles'
import { useTableAccessList } from './hooks/useTableAccessList'
import { useTableChangeHandlers } from './hooks/useTableChangeHandlers'

import { useGlobalModel } from './hooks/useGlobalModel'
import { ColDef } from 'ag-grid-community'
import { BaseGridWithContext } from '@fintastic/web/ui'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'

const StyledToggle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const TableAccessConfiguration = () => {
  const { onToggleAccess } = useTableChangeHandlers()
  const { tableAccessList } = useTableAccessList()
  const { tables: allTables, loading } = useGlobalModel()

  const accessMap = useMemo(
    () => Object.fromEntries(tableAccessList.map((table) => [table.id, table])),
    [tableAccessList],
  )

  const tablesList = useMemo(
    () =>
      allTables.map((table) => ({
        id: table.id,
        name: table.name,
        resourceType: table.resourceType,
        access: accessMap[table.id]?.allowed || false,
      })),
    [accessMap, allTables],
  )
  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
    }),
    [],
  )
  const tableColDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
      },
      {
        field: 'access',
        headerName: 'Access',
        cellRenderer: (value: any) => (
          <StyledToggle>
            <AllowDenyToggle
              value={value.data.access}
              onChange={(val) => {
                onToggleAccess({
                  id: value.data.id,
                  allowed: val === 'allow',
                  resourceType: value.data.resourceType,
                })
              }}
            />
          </StyledToggle>
        ),
      },
    ],
    [onToggleAccess],
  )

  const defaultGridStyles = {
    height: '100%',
    width: '100%',
  }
  const colDefs: ColDef[] = useMemo(
    () => tableColDefs.filter((colDef) => !colDef.hide),
    [tableColDefs],
  )

  if (loading) {
    return <CenteredCircularProgress />
  }

  return (
    <AgGridThemeFintasticWrapper sx={defaultGridStyles}>
      {() => (
        <BaseGridWithContext
          embeddedView={true}
          sideBarEnabled={false}
          defaultColDef={defaultColDef}
          agColumnDefs={colDefs}
          rowData={tablesList}
          editProps={{ rowIdField: 'id' }}
        ></BaseGridWithContext>
      )}
    </AgGridThemeFintasticWrapper>
  )
}
