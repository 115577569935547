import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { WrappedData } from './types'
import { ListWrapper } from './ListWrapper'
import {
  createDimensionColumn,
  createNewCalculatedColumn,
  createNewInputColumn,
} from '../list-column/constructors'
import { MutableListColumnWrapper } from '../list-column'
import { DimensionId, Maybe } from '@fintastic/shared/util/types'
import { ColumnType } from '@fintastic/web/util/metrics-and-lists'
import { MutableWrappedData } from '../list-column/types'
import {
  TimeDimensionId,
  VersionDimension,
} from '@fintastic/web/util/dimensions'

const defaultNameRegexp = new RegExp(/^column_(\d+)$/i)
const nameAndNumberExtractorRegexp = new RegExp(/^(.*?)(?:_([\d]+))?$/i)

function isColumnNameLikeDefaultName(columnName: string): boolean {
  return defaultNameRegexp.test(columnName)
}

export class MutableListWrapper
  extends ListWrapper
  implements MutableADTWrapper<WrappedData>
{
  readonly _rawData: WrappedData

  constructor(data: WrappedData) {
    super(data)
    this._rawData = data
  }

  columns(): MutableListColumnWrapper[] {
    return this._rawData.metrics.map(
      (m) => new MutableListColumnWrapper(m as MutableWrappedData),
    )
  }

  findColumn(
    predicate: (c: MutableListColumnWrapper) => boolean,
  ): Maybe<MutableListColumnWrapper> {
    return this.columns().find(predicate) || null
  }

  findColumnByClientId(id: string) {
    return this.findColumn((c) => c.clientOnlyId() === id || c.id() === id)
  }

  renameColumn(
    clientOnlyId: string,
    columnName: string,
    dimensionNames?: string[],
  ) {
    if (this.isCalculated()) {
      throw new Error("Can't rename a column in calculated list")
    }

    const column = this.findColumnByClientId(clientOnlyId)
    if (column === null) {
      throw new Error(`Column with client id ${clientOnlyId} does not exist`)
    }

    // if dimensionNames[] is passed, it means (https://fintastic.atlassian.net/browse/FIN-7628)
    // so rename column:
    // - if the current name is like Column_# (default)
    // - if the current name === one of dimension names
    // - if the current name === one if dimension name + _#
    if (dimensionNames && dimensionNames.length > 0) {
      const currentName = column.label() || ''
      // 1. Current name is default Column_#?
      let shouldRename = isColumnNameLikeDefaultName(currentName)

      nameAndNumberExtractorRegexp.lastIndex = 0
      const currentNameRootMatcher =
        nameAndNumberExtractorRegexp.exec(currentName)
      const currentNameRoot = currentNameRootMatcher?.[1] // aaa_bbb_123 -> aaa_bbb, remove tail

      if (!shouldRename) {
        // 2, 3. Current name is one of other dimensions + optional _#?
        shouldRename =
          !!currentNameRoot && dimensionNames.includes(currentNameRoot)
      }

      if (!shouldRename) {
        return
      }

      // Check all existing columns if a column with that name already exists
      // if yes, add _#+1 to new name
      const maxNumberWithTheSameName = this.columns()
        .map((c) => {
          if (c.clientOnlyId() === clientOnlyId) {
            return false
          }
          nameAndNumberExtractorRegexp.lastIndex = 0
          const currentColumnRootMatcher = nameAndNumberExtractorRegexp.exec(
            c.label(),
          )
          const currentColumnRoot = currentColumnRootMatcher?.[1] || '' // aaa_bbb_123 -> aaa_bbb, remove tail
          const num = currentColumnRootMatcher?.[2] // 123
          if (currentColumnRoot === columnName) {
            return num ? parseInt(num, 10) : 0
          }
          return false
        })
        .filter((num) => typeof num === 'number')

      if (maxNumberWithTheSameName.length > 0) {
        const max =
          Math.max.call(null, ...(maxNumberWithTheSameName as number[])) || 0
        columnName += '_' + (max + 1)
      }
    }

    const anotherColumnWithSameName = this.findColumn(
      (c) => c.label() === columnName && c.clientOnlyId() !== clientOnlyId,
    )
    if (anotherColumnWithSameName !== null) {
      throw new Error(`Another column with name "${columnName}" already exist`)
    }

    column.rename(columnName, this.id())
  }

  addNewColumn(
    columnName: string,
    clientOnlyId: string,
  ): MutableListColumnWrapper {
    if (this.isCalculated()) {
      throw new Error("Can't add column to calculated list")
    }

    if (this.hasColumn((c) => c.clientOnlyId() === clientOnlyId)) {
      throw new Error(`Column with client id ${clientOnlyId} already exist`)
    }

    if (this.hasColumn((c) => c.label() === columnName)) {
      throw new Error(`Column with label ${columnName} already exist`)
    }

    const newColumn = new MutableListColumnWrapper(
      createNewInputColumn({
        clientOnlyId,
        columnName,
        listId: this.id(),
        rowDimension: this.extractRowDimension() || this.simulateRowDimension(),
      }),
    )

    this._rawData.metrics.push(newColumn.unwrap())

    return newColumn
  }

  removeColumn(clientOnlyId: string) {
    if (this.isCalculated()) {
      throw new Error("Can't remove column from calculated list")
    }

    if (!this.hasColumn((c) => c.clientOnlyId() === clientOnlyId)) {
      throw new Error(`Column with client id ${clientOnlyId} does not exist`)
    }

    const columnIndex = this.columns().findIndex(
      (c) => c.clientOnlyId() === clientOnlyId,
    )
    this._rawData.metrics.splice(columnIndex, 1)
  }

  reorderColumns(clientOnlyIds: string[]) {
    const columns = [...this.columns()]
    columns.sort(
      (a, b) =>
        clientOnlyIds.indexOf(a.clientOnlyId()) -
        clientOnlyIds.indexOf(b.clientOnlyId()),
    )

    this._rawData.metrics = columns.map((c) => c.unwrap())
  }

  replaceColumn(
    clientOnlyId: string,
    params:
      | {
          type: Exclude<ColumnType, 'dimension'>
        }
      | {
          type: 'dimension'
          dimensionId: DimensionId
          allDimensions: VersionDimension[]
        },
  ): MutableListColumnWrapper {
    if (this.isCalculated()) {
      throw new Error("Can't replace column in calculated list")
    }

    const existingColumn = this.findColumnByClientId(clientOnlyId)
    if (existingColumn === null) {
      throw new Error(`Column with client id ${clientOnlyId} does not exist`)
    }

    if (!existingColumn.isNewColumn()) {
      throw new Error("Can't change type of an existing column")
    }

    const commonConstructorParams = {
      clientOnlyId: existingColumn.clientOnlyId(),
      columnName: existingColumn.label(),
      listId: this.id(),
      rowDimension: this.extractRowDimension() || this.simulateRowDimension(),
    }

    const replace = (newColumn: MutableListColumnWrapper) => {
      // using splice because of immer Arrays updating pattern
      this.unwrap().metrics.splice(
        this.columns().findIndex(
          (c) => c.clientOnlyId() === existingColumn.clientOnlyId(),
        ),
        1,
        newColumn.unwrap(),
      )
    }

    switch (params.type) {
      case 'calculated': {
        const column = new MutableListColumnWrapper(
          createNewCalculatedColumn(commonConstructorParams),
        )
        replace(column)
        return column
      }
      case 'dimension': {
        const dimension = params.allDimensions.find(
          (d) => d.id === params.dimensionId,
        )
        if (!dimension) {
          throw new Error(`Can't resolve dim "${params.dimensionId}"`)
        }
        if (!('values' in dimension)) {
          throw new Error(`Can't resolve values of dim "${params.dimensionId}"`)
        }

        const column = new MutableListColumnWrapper(
          createDimensionColumn({
            ...commonConstructorParams,
            dimension: {
              ...dimension,
              label: dimension.id,
            },
          }),
        )
        replace(column)
        return column
      }
      default: {
        const column = new MutableListColumnWrapper(
          createNewInputColumn(commonConstructorParams),
        )
        replace(column)
        return column
      }
    }
  }

  changeFormula(formula: string) {
    if (!this.isCalculated()) {
      throw new Error('Only calculated lists can have formula')
    }
    this._rawData.metadata.formula = formula
  }

  // @todo add tests
  changeBaseTimeDimension(
    timeDimensionId: Maybe<TimeDimensionId>,
    allDimensions: VersionDimension[],
  ) {
    if (this.isCalculated()) {
      throw new Error("Can't change base time dimension in calculated list")
    }

    if (this.baseTimeDimension() === timeDimensionId) {
      return
    }

    if (this.baseTimeDimension() !== null && timeDimensionId === null) {
      this.removeBaseTimeDimensionFromTimeColumns()
      this._rawData.metadata.base_time_dimension_id = null
      return
    }

    this.changeBaseTimeDimensionInTimeColumns(
      timeDimensionId as TimeDimensionId,
      allDimensions,
    )
    this._rawData.metadata.base_time_dimension_id = timeDimensionId
  }

  protected removeBaseTimeDimensionFromTimeColumns() {
    this.columns().forEach((column) => {
      if (!column.hasTimeDimension() || !column.allowedToHaveTimeDimension()) {
        return
      }
      column.removeTimeDimension()
    })
  }

  protected changeBaseTimeDimensionInTimeColumns(
    timeDimensionId: TimeDimensionId,
    allDimensions: VersionDimension[],
  ) {
    this.columns().forEach((column) => {
      if (!column.hasTimeDimension() || !column.allowedToHaveTimeDimension()) {
        return
      }
      column.removeTimeDimension()
      column.addTimeDimension(timeDimensionId, allDimensions)
    })
  }
}
