import { styled } from '@mui/material'

export const StyledTableContainer = styled('div')`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 2;
  flex-grow: 2;

  width: 100%;
  height: 100%;
`
