import { useCallback, useMemo, useState } from 'react'

export type UseModalStateParams = {
  initialIsOpen?: boolean
}

const defaultParams: UseModalStateParams = {}

export function useModalState(
  params: UseModalStateParams = defaultParams,
): ModalState {
  const { initialIsOpen = false } = params

  const [isOpen, setIsOpen] = useState(initialIsOpen)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return useMemo(
    () => ({
      isOpen: isOpen,
      open: handleOpen,
      close: handleClose,
    }),
    [handleClose, handleOpen, isOpen],
  )
}

export type ModalState = {
  isOpen: boolean
  open: () => void
  close: () => void
}
