import React from 'react'
import { useMemoryRouterContext } from '@fintastic/shared/util/memory-router'
import { memoryRoutesPaths } from '../memory-routes-paths'
import {
  NavButtonsContainer,
  ViewLayout,
} from '@fintastic/shared/ui/view-layout'
import { DimensionsNavButton } from '../nav-buttons/dimensions'
import { useDataContext } from '../connection/data/data-context'
import { ValueDataFormatNavButton } from '../../shared/nav-buttons/value-data-format'
import { AggregationFunctionNavButton } from '../../shared/nav-buttons/aggregation-function'
import { Divider } from '@mui/material'
import { GeneralSettings } from '../settings/general'
import { VersionDimension } from '@fintastic/web/util/dimensions'

const defaultDimensionsList: VersionDimension[] = []

export const Metric: React.FC = () => {
  const { goTo } = useMemoryRouterContext()
  const { metric, dimensionsList } = useDataContext()

  if (!metric) {
    return null
  }

  return (
    <ViewLayout title={'Metric settings'} enableBackButton={false}>
      <GeneralSettings name={metric.label()} />
      <NavButtonsContainer>
        <DimensionsNavButton
          metricDimensionsIds={metric.data().dimensions()}
          dimensionsList={dimensionsList || defaultDimensionsList}
          onClick={() => goTo(memoryRoutesPaths.metricDimensions)}
        />
      </NavButtonsContainer>
      <Divider sx={{ my: 1 }} />
      <NavButtonsContainer>
        <ValueDataFormatNavButton
          onClick={() => goTo(memoryRoutesPaths.metricValueDataFormat)}
          dataType={metric.dataType()}
        />
        <AggregationFunctionNavButton
          onClick={() => goTo(memoryRoutesPaths.metricAggregationFunction)}
          categoryAggregation={metric.categoryAggregation()}
          timeAggregation={metric.timeAggregation()}
        />
      </NavButtonsContainer>
    </ViewLayout>
  )
}
