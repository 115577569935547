import React, { PropsWithChildren, useMemo } from 'react'
import { useAutoHideGlobalVersionLockWarning } from '@fintastic/web/data-access/versions'
import { ReportEditorProvider } from '@fintastic/web/feature/report-editor'

export const CommonVersionPageContainer: React.FC<
  PropsWithChildren<{ versionId: string }>
> = ({ versionId, children }) => {
  const versionIdAsArray = useMemo(() => [versionId], [versionId])
  useAutoHideGlobalVersionLockWarning(versionIdAsArray)

  return (
    <ReportEditorProvider versionId={versionId}>
      {children}
    </ReportEditorProvider>
  )
}
