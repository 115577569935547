import { Maybe } from '@fintastic/shared/util/types'

export function isDomNodeVisible(elem: HTMLElement): boolean {
  const style = getComputedStyle(elem)
  if (style.display === 'none') {
    return false
  }
  if (style.visibility !== 'visible') {
    return false
  }
  if (parseFloat(style.opacity) < 0.1) {
    return false
  }
  if (
    elem.offsetWidth +
      elem.offsetHeight +
      elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width ===
    0
  ) {
    return false
  }
  const elemCenter = {
    x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
    y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
  }
  if (elemCenter.x < 0) {
    return false
  }
  if (
    elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)
  ) {
    return false
  }
  if (elemCenter.y < 0) {
    return false
  }
  if (
    elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)
  ) {
    return false
  }
  let pointContainer: Maybe<Element | ParentNode> = document.elementFromPoint(
    elemCenter.x,
    elemCenter.y,
  )
  do {
    if (pointContainer === elem) {
      return true
    }
  } while ((pointContainer = pointContainer?.parentNode || null))
  return false
}
