import { VersionDimension } from '../types'
import { WrappedDimension } from './types'
import * as p from './common-predicates'
import { TimeDimensionWrapper } from './TimeDimensionWrapper'
import { CategoryDimensionWrapper } from './CategoryDimensionWrapper'
import { RangeDimensionWrapper } from './RangeDimensionWrapper'

export const wrapDimension = (d: VersionDimension): WrappedDimension => {
  if (p.isTimeDimension(d)) {
    return new TimeDimensionWrapper(d)
  }
  if (p.isCategoryDimension(d)) {
    return new CategoryDimensionWrapper(d)
  }
  return new RangeDimensionWrapper(d)
}
