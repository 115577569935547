import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Board } from '../../../types'
import {
  CheckboxField,
  FormLayout,
  TextField,
  useTextFieldState,
} from '@fintastic/shared/ui/form-framework'
import { useBoardFolderNameValidation } from '../../../hooks/useBoardFolderNameValidation'
import { useBoardAndFolderOpen } from '../../../hooks/useBoardAndFolderOpen'
import { useBoardQuery } from '../../../hooks/useBoardQuery'
import { useBoardCopyMutation } from '../../../hooks/useBoardCopyMutation'
import { useBoardNameValidation } from '../../../hooks/useBoardNameValidation'

export type BoardDuplicateFormProps = {
  boardId: Board['id']
  closeParentModal?: () => void
}
export const BoardDuplicateForm: React.FC<BoardDuplicateFormProps> = (
  props,
) => {
  const { closeParentModal, boardId } = props
  const boardNameFieldState = useTextFieldState()
  const folderNameFieldState = useTextFieldState()
  const [isAddNewFolder, setIsAddNewFolder] = useState(false)
  const [isShareWithTheSame, setShareWithTheSame] = useState(true)

  const folderNameValidation = useBoardFolderNameValidation({
    folderName: folderNameFieldState.trimmedValue,
  })

  const boardNameValidation = useBoardNameValidation({
    boardName: boardNameFieldState.trimmedValue,
  })

  const isEveryFieldFilled =
    boardNameFieldState.value.length > 0 &&
    boardNameValidation.isValid &&
    (isAddNewFolder ? folderNameFieldState.trimmedValue.length > 0 : true)

  const isSubmitEnabled =
    isEveryFieldFilled && (isAddNewFolder ? folderNameValidation.isValid : true)
  const boardQuery = useBoardQuery({ boardId })
  const isLoading = boardQuery.isLoading
  useEffect(() => {
    if (boardQuery.isError) {
      if (closeParentModal) {
        closeParentModal()
      }
      return
    }
    if (!boardQuery.data) {
      return
    }
    boardNameFieldState.setValue(`${boardQuery.data.name} Copy`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    boardQuery.isError,
    boardQuery.data,
    boardNameFieldState.setValue,
    closeParentModal,
  ])

  const boardCopyMutation = useBoardCopyMutation(boardId)
  const isSending = boardCopyMutation.isLoading
  const submitErrorMessage = useMemo(() => {
    if (!boardCopyMutation.error) {
      return null
    }
    console.error(boardCopyMutation.error)
    return "Can't create a new board"
  }, [boardCopyMutation.error])

  const openBoardAndFolder = useBoardAndFolderOpen()

  const handleSubmit = useCallback(() => {
    if (!boardQuery.data) {
      return
    }

    boardCopyMutation.mutate(
      {
        name: boardNameFieldState.value,
        config: boardQuery.data.config,
        inherit_access: isShareWithTheSame,
        folder_id: boardQuery.data.is_deleted
          ? null
          : boardQuery.data.folder_id,
        newFolderName: isAddNewFolder
          ? folderNameFieldState.trimmedValue
          : undefined,
      },
      {
        onSuccess: (data) => {
          if (closeParentModal) {
            closeParentModal()
          }
          openBoardAndFolder(data.id, data.folder_id || undefined)
        },
      },
    )
  }, [
    boardCopyMutation,
    boardNameFieldState.value,
    boardQuery.data,
    closeParentModal,
    folderNameFieldState.trimmedValue,
    isAddNewFolder,
    isShareWithTheSame,
    openBoardAndFolder,
  ])

  return (
    <FormLayout
      submitEnabled={!isSending && isSubmitEnabled}
      showLoader={isSending || isLoading}
      submitButtonText="Duplicate"
      submitErrorMessage={submitErrorMessage}
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
      testIdPrefix="board-duplicate"
    >
      <TextField
        label="New board name"
        placeholder="Name"
        fullWidth={true}
        value={boardNameFieldState.value}
        onChange={boardNameFieldState.setValue}
        error={
          boardNameFieldState.touched ? !boardNameValidation.isValid : false
        }
        errorMessage={
          boardNameFieldState.touched ? boardNameValidation.error : undefined
        }
        data-testid="board-duplicate-field-board-name"
      />
      <CheckboxField
        label="Create in a new folder"
        checked={isAddNewFolder}
        onChange={setIsAddNewFolder}
        data-testid="board-duplicate-field-create-new-folder"
      />
      {isAddNewFolder && (
        <TextField
          label="New folder name"
          placeholder="Folder name"
          fullWidth={true}
          value={folderNameFieldState.value}
          onChange={folderNameFieldState.setValue}
          error={
            folderNameFieldState.touched ? !folderNameValidation.isValid : false
          }
          errorMessage={
            folderNameFieldState.touched
              ? folderNameValidation.error
              : undefined
          }
          data-testid="board-duplicate-field-folder-name"
        />
      )}
      <CheckboxField
        label="Share with the same people"
        checked={isShareWithTheSame}
        onChange={setShareWithTheSame}
        data-testid="board-duplicate-field-share-with-the-same-people"
      />
    </FormLayout>
  )
}
