import React, { RefObject, useCallback } from 'react'
import {
  InlineSelectChangeHandler,
  InlineSelectDefaultValue,
  InlineSelectOption,
  InlineSelectValueVariants,
} from '../types'
import { Maybe } from '@fintastic/shared/util/types'
import { MenuItemContent } from './MenuItemContent'
import { useMenuWidth } from './useMenuWidth'
import { StyledRoot } from './Menu.styled'
import { MenuItem } from '@mui/material'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'

export type MenuProps<
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
> = {
  anchorElementRef: RefObject<Maybe<HTMLElement>>
  options: InlineSelectOption<TValue>[]
  value: TValue
  onChange: InlineSelectChangeHandler<TValue>
  onClose: () => void
  isOpen: boolean
  minWidth?: number
}

export const Menu = <
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
>({
  anchorElementRef,
  options,
  value,
  onChange,
  onClose,
  isOpen,
  minWidth,
}: MenuProps<TValue>): JSX.Element => {
  const width = useMenuWidth(anchorElementRef, minWidth)

  const handleSelect = useCallback<InlineSelectChangeHandler<TValue>>(
    (newValue) => {
      onChange(newValue)
      onClose()
    },
    [onChange, onClose],
  )

  return (
    <StyledRoot
      anchorEl={anchorElementRef.current}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: width || undefined,
        },
      }}
    >
      {options.map((option) => (
        // to keep keyboard navigation working you must pass the native MenuItem component as a child of the native Menu component
        <MenuItem
          key={option.value}
          onClick={() => handleSelect(option.value)}
          selected={option.value === value}
          sx={{
            ...sxMixins.alignContentCenter(),
            justifyContent: 'space-between',
            padding: 1,
          }}
        >
          <MenuItemContent option={option} />
        </MenuItem>
      ))}
    </StyledRoot>
  )
}
