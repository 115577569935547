import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { archiveVersion } from '../../api/versions-management-api'
import { invalidateVersionManagementRelatedQueries } from '../../cache'

export function useArchiveListOfVersions(versionsIds: string[]) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.archiveVersions(versionsIds),
    async () =>
      Promise.all(
        versionsIds.map((id) =>
          (async () => {
            const response = await archiveVersion(id)
            return response.data
          })(),
        ),
      ),
    {
      onSuccess: async () => {
        await invalidateVersionManagementRelatedQueries(
          queryClient,
          versionsIds,
        )
      },
    },
  )
}
