import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnValueDataFormattingPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnValueDataFormatting = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeColumnValueDataFormattingPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  list.findColumnByClientId(payload.columnId)?.applyFormattingChanges({
    compactFormatting: payload.compactFormatting,
    decimalPlaces: payload.decimalPlaces,
  })
}
