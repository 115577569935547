const ID_PATH_PARAMETER = ':id'

const encodeId = (id = ID_PATH_PARAMETER) => {
  if (id !== ID_PATH_PARAMETER) {
    return encodeURIComponent(id)
  }
  return id
}

export const memoryRoutesPaths = {
  list: '/list',
  column: (id = ID_PATH_PARAMETER) => `/list/column/${encodeId(id)}`,
  columnValueDataFormat: (id = ID_PATH_PARAMETER) =>
    `/list/column/${encodeId(id)}/value-data-format`,
  columnAggregationFunction: (id = ID_PATH_PARAMETER) =>
    `/list/column/${encodeId(id)}/aggregation-function`,
} as const
