import { AgGridSelectboxCellEditor } from '@fintastic/shared/ui/ag-grid'
import { BaseGridWithContext } from '@fintastic/web/ui'
import { Box, CircularProgress, LinearProgress } from '@mui/material'
import { ColDef, NewValueParams, ValueFormatterParams } from 'ag-grid-community'
import React, { useMemo } from 'react'
import { useUserList } from './hooks/useUserList'
import { UserData, UserRole } from '@fintastic/web/data-access/iam'
import { ColumnConfig } from './types'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

const roles: Array<{
  label: string
  value: UserRole
}> = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Modeler',
    value: 'modeler',
  },
  {
    label: 'Power User',
    value: 'power_user',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
]

export const UserAdmin: React.FC = () => {
  const showModelerRoleOption = useIsFeatureEnabled('show_modeler_role')
  const { users, onUserChange, loading, mutating } = useUserList()

  const colDefs: ColDef[] = useMemo<Array<ColDef<UserData>>>(
    () => [
      {
        field: 'username',
        headerName: 'Email Address',
      },
      {
        field: 'full_name',
        headerName: 'Name',
      },
      {
        field: 'role',
        headerName: 'Role',
        valueFormatter: (params: ValueFormatterParams) =>
          roles.find((r) => r.value === params.value)?.label || '',
        cellEditor: AgGridSelectboxCellEditor,
        cellEditorParams: {
          options: showModelerRoleOption
            ? roles
            : roles.filter((r) => r.value !== 'modeler'),
        },
        onCellValueChanged: (params: NewValueParams) =>
          onUserChange(params.data as UserData),
        editable: true,
      },
      {
        field: 'groups',
        headerName: 'Groups',
      },
      {
        field: 'is_active',
        headerName: 'Status',
        valueFormatter: (params: ValueFormatterParams) =>
          params.value ? 'Active' : 'Inactive',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['true', 'false'],
          valueFormatter: (params: ValueFormatterParams<ColumnConfig>) => {
            if (params.value === true || params.value === 'true') {
              return 'Active'
            }
            return 'Inactive'
          },
        },
        cellEditor: AgGridSelectboxCellEditor,
        cellEditorParams: {
          options: [
            {
              label: 'Active',
              value: true,
            },
            {
              label: 'Inactive',
              value: false,
            },
          ],
        },
        onCellValueChanged: (params: NewValueParams) =>
          onUserChange(params.data as UserData),
        editable: true,
      },
    ],
    [onUserChange, showModelerRoleOption],
  )

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Box sx={{ height: '100%' }}>
      {mutating ? <LinearProgress /> : <div />}
      <BaseGridWithContext
        agColumnDefs={colDefs}
        rowData={users}
      ></BaseGridWithContext>
    </Box>
  )
}
