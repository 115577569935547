import {
  CalendarDatePickerConfig,
  CalendarDatePickerConfigValueItem,
  convertPeriodSelectionToRange,
  getDefaultPeriodSelectionAsRange,
  isEmptyPeriodSelection,
} from '@fintastic/web/util/period-selector'
import {
  getAggregationTypes,
  getPlainAggregationTypes,
  getAggregationTypesDiffList,
  isTheLowestGranularAggregation,
} from '../utils'
import {
  PeriodSelection,
  PeriodsRange,
} from '@fintastic/web/util/period-selector'
import { DimensionId } from '@fintastic/web/util/dimensions'

export const getLowerAggregationRange = (
  datePickerConfig: CalendarDatePickerConfig,
  nextDimensionId: DimensionId,
  periodSelection: PeriodSelection,
): PeriodsRange => {
  if (isEmptyPeriodSelection(periodSelection)) {
    return periodSelection
  }

  const aggregationTypesPlain = getPlainAggregationTypes(
    getAggregationTypes(datePickerConfig),
  )
  const defaultRangeFallback = getDefaultPeriodSelectionAsRange(
    datePickerConfig,
    nextDimensionId,
  )

  if (!aggregationTypesPlain.includes(nextDimensionId)) {
    throw new Error('Calendar does not include neded dim id')
  }

  const steps = [
    nextDimensionId,
    ...getAggregationTypesDiffList(
      aggregationTypesPlain,
      nextDimensionId,
      periodSelection.dimensionId,
    ),
  ]

  const range = convertPeriodSelectionToRange(periodSelection, datePickerConfig)

  if (
    isTheLowestGranularAggregation(
      aggregationTypesPlain,
      periodSelection.dimensionId,
    )
  ) {
    return range || defaultRangeFallback
  }

  return steps.reduceRight<PeriodsRange>(([from, to], step) => {
    const allAvailablePeriods =
      datePickerConfig
        .find((c) => c.dimension_id === step)
        ?.values?.map((v) => v.dimension_value_id) ?? []

    const nextFrom = datePickerConfig.reduce<
      CalendarDatePickerConfigValueItem | undefined
    >((acc, pickerConfig) => {
      if (acc) {
        return acc
      }
      if (step !== pickerConfig.dimension_id) {
        return acc
      }

      const directFrom = pickerConfig.values.find(
        (valueItem) =>
          valueItem.groups[0] === from &&
          allAvailablePeriods.includes(valueItem.dimension_value_id),
      )

      if (directFrom) {
        return directFrom
      }

      return pickerConfig.values.find(
        (valueItem) =>
          valueItem.groups.includes(from) &&
          allAvailablePeriods.includes(valueItem.dimension_value_id),
      )
    }, undefined)

    const nextTo = datePickerConfig.reduce<
      CalendarDatePickerConfigValueItem | undefined
    >((acc, pickerConfig) => {
      if (acc) {
        return acc
      }
      if (step !== pickerConfig.dimension_id) {
        return acc
      }

      const attachedToGroupDirectly = pickerConfig.values.filter(
        (valueItem) => valueItem.groups[0] === to,
      )

      const attachedToGroup = attachedToGroupDirectly?.length
        ? attachedToGroupDirectly
        : pickerConfig.values.filter((valueItem) =>
            valueItem.groups.includes(to),
          )

      const id = allAvailablePeriods.findLast(
        (p) => !!attachedToGroup.find((g) => g.dimension_value_id === p),
      )

      return pickerConfig.values.find(
        (valueItem) => valueItem.dimension_value_id === id,
      )
    }, undefined)

    if (!nextFrom?.dimension_value_id || !nextTo?.dimension_value_id) {
      throw new Error('Could not find lower granularity value!')
    }

    return [nextFrom.dimension_value_id, nextTo.dimension_value_id] as const
  }, range || defaultRangeFallback)
}
