import { axios } from '@fintastic/web/data-access/service-axios'
import { BoardAccess } from '@fintastic/web/feature/boards'

const endpoints = {
  boardAccess: (id: string) => `/planning/api/v1/boards/${id}/access/`,
  groupList: 'planning/api/v1/iam/groups/',
} as const

export async function getBoardAccess(id: string) {
  return axios.get<BoardAccess>(endpoints.boardAccess(id))
}

export async function updateBoardAccess(id: string, access: BoardAccess) {
  return axios.put(endpoints.boardAccess(id), access)
}
