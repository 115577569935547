import { useMemo } from 'react'
import type {
  ConstantTokenRecognitionMap,
  ObjectTokenRecognitionMap,
} from './types'
import type { FintasticSyntaxApi } from '@fintastic/web/data-access/formulas'
import type { Maybe } from '@fintastic/shared/util/types'
import { antlr4TokensToFintasticTokens } from '../syntax/antlr4TokensToFintasticTokens'

const defaultObjectRecognitionMap = {} as ObjectTokenRecognitionMap
const defaultConstantRecognitionMap = {} as ConstantTokenRecognitionMap

// @todo add tests
export function useTokens(
  formula: string,
  objectRecognitionMap: Maybe<ObjectTokenRecognitionMap>,
  constantRecognitionMap: Maybe<ConstantTokenRecognitionMap>,
  syntaxApi: Maybe<FintasticSyntaxApi>,
) {
  return useMemo(
    () =>
      syntaxApi
        ? antlr4TokensToFintasticTokens(
            formula,
            objectRecognitionMap || defaultObjectRecognitionMap,
            constantRecognitionMap || defaultConstantRecognitionMap,
            syntaxApi.getDisplayTokens(
              formula,
              objectRecognitionMap || defaultObjectRecognitionMap,
            ),
          )
        : [],
    [constantRecognitionMap, formula, objectRecognitionMap, syntaxApi],
  )
}
