import { TokenWithNode } from '../tokens/types'
import { Maybe } from '@fintastic/shared/util/types'
import { createContext, useContext } from 'react'

const stub = (): never => {
  throw new Error('TokenHelperContext used out of provider')
}

export type TokenHelperContextValue = {
  token: Maybe<TokenWithNode>
  helperContent: Maybe<string>
  closeHelper: () => void
}

const TokenHelperContext = createContext<TokenHelperContextValue>({
  token: null,
  helperContent: null,
  closeHelper: stub,
})

export const { Provider: TokenHelperContextProvider } = TokenHelperContext

export function useTokenHelperContext() {
  return useContext(TokenHelperContext)
}
