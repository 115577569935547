import { Box, Typography } from '@mui/material'
import React from 'react'

export const BoardsSidebarNoData: React.FC = () => (
  <Box py="24px">
    <Typography variant="body2" color="text.secondary" textAlign="center">
      No results match your search
    </Typography>
  </Box>
)
