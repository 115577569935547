import React from 'react'
import { StyledPanelErrorContentWrapperRoot } from './PanelErrorContentWrapper.styled'

export type PanelErrorContentWrapperProps = {
  children: React.ReactNode
}

export const PanelErrorContentWrapper: React.FC<
  PanelErrorContentWrapperProps
> = ({ children }) => (
  <StyledPanelErrorContentWrapperRoot>
    {children}
  </StyledPanelErrorContentWrapperRoot>
)
