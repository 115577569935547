import {
  styled,
  backdropClasses,
  alpha,
  Drawer,
  drawerClasses,
} from '@mui/material'

export const StyledRoot = styled(Drawer)`
  .${backdropClasses.root} {
    background: ${alpha('#000', 0.2)};
  }

  .${drawerClasses.paper} {
    box-shadow: 0px 8px 38px 7px #0000001f, 0px 20px 31px 3px #00000024,
      0px 10px 13px -6px #00000033;
  }
`

export const StyledContainer = styled('div')`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

export const StyledHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;

  width: 100%;
  height: 64px;
  min-height: 64px;
`

export const StyledCloseButtonCont = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
`

export const StyledTextCont = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const StyledContent = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};

  height: 100%;
  width: 100%;

  background: #f8f4f1;
`

export const StyledLoader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`

export const StyledError = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`
