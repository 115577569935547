import { Paper, styled } from '@mui/material'

export const StyledRoot = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background: ${({ theme }) => theme.palette.grey[200]};
`

export const StyledMain = styled('div')`
  margin: auto auto 0 auto;
`

export const StyledContainer = styled(Paper)`
  padding: 16px;

  width: 440px;
`

export const StyledLogoContainer = styled('div')`
  margin-top: 4px;

  img {
    width: 100px;
    height: auto;
  }
`

export const StyledContentContainer = styled('div')`
  margin-top: 16px;
`

export const StyledLoaderContainer = styled('div')``

export const StyledFooter = styled('div')`
  margin-top: auto;
  padding-bottom: 15px;
`
