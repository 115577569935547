import { useCallback, useMemo, useState } from 'react'
import { WidgetSettingsDialogError } from '@fintastic/web/feature/boards'

export const ErrorVersionUnavailable = 'ERROR_VERSION_UNAVAILABLE'
export const ErrorVersionIsEmpty = 'ERROR_VERSION_IS_EMPTY'

export type UsePendingErrors = {
  addPendingError: (
    error:
      | WidgetSettingsDialogError
      | typeof ErrorVersionIsEmpty
      | typeof ErrorVersionUnavailable,
  ) => void
  clearAll: () => void
  removePendingError: (errorId: string) => void
  hasPendingError: (errorId: string) => boolean
  pendingErrors: WidgetSettingsDialogError[]
  isAnyPendingError: boolean
}

const isErrorExists = (
  v: WidgetSettingsDialogError[],
  error: WidgetSettingsDialogError,
) => v.some((err) => err.id === error.id)

const isErrorExistsById = (v: WidgetSettingsDialogError[], errorId: string) =>
  v.some((err) => err.id === errorId)

export const usePendingErrors = (): UsePendingErrors => {
  const [pendingErrors, setPendingErrors] = useState<
    WidgetSettingsDialogError[]
  >([])

  const addPendingError = useCallback(
    (error: WidgetSettingsDialogError | string) => {
      if (typeof error === 'string') {
        const err: WidgetSettingsDialogError = {
          id: error,
        }

        if (error === ErrorVersionUnavailable) {
          err.message =
            'The reference version has been locked, archived or deleted. Please select another version.'
        }

        if (error === ErrorVersionIsEmpty) {
          err.message =
            'The reference version is not set. Please select a version.'
        }
        setPendingErrors((v) => (isErrorExists(v, err) ? v : [...v, err]))
      } else {
        setPendingErrors((v) => (isErrorExists(v, error) ? v : [...v, error]))
      }
    },
    [],
  )

  const removePendingError = useCallback((errorId: string) => {
    setPendingErrors((v) =>
      isErrorExistsById(v, errorId) ? v.filter((p) => p.id !== errorId) : v,
    )
  }, [])

  const hasPendingError = useCallback(
    (errorId: string) => isErrorExistsById(pendingErrors, errorId),
    [pendingErrors],
  )

  const clearAll = useCallback(() => {
    setPendingErrors((_) => [])
  }, [])

  return useMemo(
    () => ({
      addPendingError,
      removePendingError,
      clearAll,
      pendingErrors,
      hasPendingError,
      isAnyPendingError: pendingErrors.length > 0,
    }),
    [
      addPendingError,
      clearAll,
      removePendingError,
      hasPendingError,
      pendingErrors,
    ],
  )
}
