import { styled, Box, ListItemButton, alpha } from '@mui/material'
import { MIN_ROW_HEIGHT } from './const'

export const StyledListItemRoot = styled(ListItemButton)(({ theme }) => ({
  'padding': theme.spacing(0.5, 1),
  'minHeight': MIN_ROW_HEIGHT,
  'transition': 'none',

  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.06),
  },

  '&.Mui-selected': {
    background: alpha(theme.palette.primary.main, 0.12),
  },

  '&.Mui-selected:hover': {
    background: alpha(theme.palette.primary.main, 0.18),
  },
}))

export const StyledListItemText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',

  width: '100%',

  fontSize: 12,
  lineHeight: '15.5px',
}))

export const StyledListItemLiteral = styled(Box)(({ theme }) => ({
  overflow: 'hidden',

  width: '100%',

  textOverflow: 'ellipsis',
}))

export const StyledListItemLiteralType = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),

  fontSize: 10,
  fontWeight: '500',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',

  color: theme.palette.text.disabled,
}))
