import { useMemo } from 'react'
import { LocalColumnActions } from './types'
import { Actions } from '../../connection/actions/types'

export function useLocalColumnActions(columnId: string, actions: Actions) {
  return useMemo<LocalColumnActions>(
    () => ({
      remove: (...params) => actions.removeColumn(columnId, ...params),
      changeType: (...params) => actions.changeColumnType(columnId, ...params),
      changeName: (...params) => actions.changeColumnName(columnId, ...params),
      changeDimension: (...params) =>
        actions.changeColumnDimension(columnId, ...params),
    }),
    [actions, columnId],
  )
}
