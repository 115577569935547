import { buildPopulateForwardProps } from '../../../../utils/populate-forward'
import { extractChangesFromPopulate } from '../../../../utils/metrics'
import { createFieldKey } from '@fintastic/web/util/metrics-and-lists'
import type { CreateValueColumnParams } from '../grid-columns/createValueColumn'
import { CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE } from '@fintastic/web/feature/metrics-and-lists'

export function getPopulateForward({
  metric,
  period,
  restPeriods,
  nonTimeDimensions,
  timeDimension,
  onUpdate,
  isEditable,
  allowed,
}: Pick<
  CreateValueColumnParams,
  | 'metric'
  | 'period'
  | 'restPeriods'
  | 'nonTimeDimensions'
  | 'timeDimension'
  | 'onUpdate'
> & {
  isEditable: boolean
  allowed: boolean
}) {
  return buildPopulateForwardProps({
    allowed,
    isEditable,
    populatedColumnsFields: (restPeriods || []).map((p) =>
      createFieldKey(metric.versionId, metric.metricId, p),
    ),
    hasPeriod: period !== null && !!restPeriods?.length,
    onPopulate: (value, context) => {
      context.populatedColumnsFields.forEach((field) => {
        context.rowNode.setDataValue(
          field,
          value,
          CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE,
        )
      })
      onUpdate?.(
        extractChangesFromPopulate(
          metric.versionId,
          metric.metricId,
          value,
          context.rowData,
          nonTimeDimensions,
          timeDimension,
          restPeriods || [],
        ),
      )
    },
  })
}
