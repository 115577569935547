import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeMetricFormulaStatePayload } from '@fintastic/web/data-access/metrics-and-lists'
import { State } from '../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

// @todo add tests for formula state
export const changeListFormulaState = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeMetricFormulaStatePayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  if (!list.isCalculated()) {
    // @todo move to the list ADT
    throw new Error('not allowed in non-calculated lists')
  }

  /**
   * @see EditableMetric
   */
  if (payload.valid) {
    delete state.list.formulaInvalid
    delete state.list.formulaCalculationError
    return
  }

  state.list.formulaInvalid = true
  if (payload.error) {
    state.list.formulaCalculationError = payload.error
  }
}
