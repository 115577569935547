import type { ValueFormatterParams } from 'ag-grid-community'
import type { ValueUom } from '@fintastic/web/util/generic-report'
import numeral from 'numeral'
import { formatNumeric } from '@fintastic/shared/util/formatters'

export type ValueFormatter = (
  params: ValueFormatterParams,
  short?: boolean,
) => string

numeral.nullFormat('-')

const formatUSD: ValueFormatter = ({ value }, short = false) =>
  formatNumeric.currency(value, { short })

const formatValueNumber: ValueFormatter = ({ value }, short = false) =>
  formatNumeric.number(value, { short })

const formatPercentage: ValueFormatter = ({ value }, short = false) => {
  const v = formatNumeric.percentage(value, { short })

  if (v.includes('NaN')) {
    return '-'
  }

  return v
}

export const valueFormatters: Record<ValueUom, ValueFormatter> = {
  usd: formatUSD,
  percentage: formatPercentage,
  ea: formatValueNumber,
}
