import { WeightsGetter } from '@fintastic/shared/ui/ag-grid'
import { MetricGridRow } from '../../types'
import { isNullish } from '@fintastic/shared/util/functional-programming'

// @todo add tests
export const weightsGetter: WeightsGetter<MetricGridRow> = (params) => {
  let result: number[] = []

  if (
    !params.rowNode.childrenAfterFilter ||
    params.rowNode.childrenAfterFilter.length === 0
  ) {
    return result
  }

  result = params.rowNode.childrenAfterFilter.map((row) => {
    const value = params.api.getValue(params.column, row)
    return isNullish(value) ? 0 : (value as number)
  })

  return result
}
