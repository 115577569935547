import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

export const NumberOfLinesButtonStyled = styled('div')`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 8px;
  line-height: 24px;
  text-align: center;
  padding: 4px;

  &:hover {
    background: #f9f7f5;
  }
`

export const NumberOfLinesInputStyled = styled('input')`
  height: 32px;
  line-height: 24px;
  padding: 4px;
  border: 1px solid #e7e6e4;
  border-radius: 8px;
  width: 44px;
  text-align: center;

  &:focus {
    outline: 0;
    padding: 3px;
    border: 2px solid #ff9568;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const VerticalDividerStyled = styled('div')`
  background: #e7e6e4;
  width: 1px;
  margin: 2px 8px;
`

export const ButtonWithDropDown = styled(IconButton)<{
  disabled?: boolean
  active?: boolean
}>`
  width: 48px;
  border-radius: 8px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ disabled, theme }) =>
      disabled ? theme.palette.action.disabledBackground : '#f9f7f5'};
  }

  &.active {
    background: ${({ theme }) => theme.styleKit.colors.orange.dark};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    border-color: ${({ theme }) => theme.styleKit.colors.orange.dark2};

    svg {
      color: #fff;
    }

    &:hover {
      background: ${({ theme }) => theme.styleKit.colors.orange.dark2}
    }
  }
}
`
