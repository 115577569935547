import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  ColumnType,
  CompactMetricData,
  Metric,
  MetricDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DeepReadonly,
  DimensionType,
  Maybe,
  RollUpFunction,
} from '@fintastic/shared/util/types'
import * as selectors from '../common-metric/selectors'
import * as predicates from '../common-metric/predicates'
import { WrappedData } from './types'
import { MetricDataWrapper } from '../metric-data'
import { MetricDisplaySettingsWrapper } from '../display-settings'
import { resolutionSupportsAggregatedEditing } from '@fintastic/web/util/period-selector'
import { VersionEntitiesWrapper } from '@fintastic/web/util/versions'
import { TimeDimensionWrapper } from '@fintastic/web/util/dimensions'

export class ListColumnWrapper implements ImmutableADTWrapper<WrappedData> {
  readonly _rawData: DeepReadonly<WrappedData>
  private _cachedData: Maybe<MetricDataWrapper> = null
  private _cachedDisplaySettings: Maybe<MetricDisplaySettingsWrapper> = null

  constructor(data: WrappedData) {
    this._rawData = data
  }

  unwrap(): WrappedData {
    return this._rawData
  }

  id() {
    return selectors.id(this._rawData)
  }

  // @todo add tests
  clientOnlyId(): string {
    return this._rawData.clientOnlyId || this.id() // fallback to the metric id for the view settings mode
  }

  // @todo add tests
  isNewColumn(): boolean {
    return !!this._rawData.newColumn
  }

  breakdownByTimeAllowed(): boolean {
    return (
      this.type() !== 'dimension' &&
      this.dataType() !== 'datetime' &&
      this.hasTimeDimension()
    )
  }

  // @todo add tests
  type(): ColumnType {
    if (this.isCalculated()) {
      return 'calculated'
    }
    if (this.dataType() === 'dimension') {
      return 'dimension'
    }
    return 'input'
  }

  label() {
    return selectors.label(this._rawData)
  }

  source() {
    return selectors.source(this._rawData)
  }

  data() {
    // We need it because lists can load columns totally without data
    if (this._rawData.data === null) {
      throw new Error('Metric data is null')
    }
    if (this._cachedData === null) {
      this._cachedData = selectors.data(this._rawData as Metric)
    }
    return this._cachedData
  }

  visible(): boolean {
    return this.displaySettings().visibleAsColumn()
  }

  dataType() {
    return selectors.dataType(this._rawData)
  }

  valuesDimensionId() {
    return selectors.valuesDimensionId(this._rawData)
  }

  maskedValueMask() {
    return selectors.maskedValueMask(this._rawData)
  }

  categoryAggregation() {
    return selectors.categoryAggregation(this._rawData)
  }

  timeAggregation() {
    return selectors.timeAggregation(this._rawData)
  }

  timeDimension() {
    return selectors.timeDimension(this._rawData)
  }

  baseTimeDimension() {
    return selectors.baseTimeDimension(this._rawData)
  }

  aggregationCellEditingAllowed(
    versionEntities: VersionEntitiesWrapper,
  ): boolean {
    const baseTimeDimensionId = this.baseTimeDimension()
    if (!baseTimeDimensionId || !this.aggregatedByTime()) {
      return false
    }

    const baseTimeDimension =
      versionEntities.findDimensionById(baseTimeDimensionId)?.dimension
    if (!baseTimeDimension) {
      return false
    }

    if (!(baseTimeDimension instanceof TimeDimensionWrapper)) {
      throw new Error('Base time dimension is type of Time for list column')
    }

    return resolutionSupportsAggregatedEditing(baseTimeDimension.resolution)
  }

  weightsMetricId() {
    return selectors.weightsMetricId(this._rawData)
  }

  description() {
    return selectors.description(this._rawData)
  }

  formula() {
    return selectors.formula(this._rawData)
  }

  displaySettings() {
    if (this._cachedDisplaySettings === null) {
      this._cachedDisplaySettings = selectors.displaySettings(this._rawData)
    }
    return this._cachedDisplaySettings
  }

  hasTimeDimension(): boolean {
    return predicates.hasTimeDimension(this._rawData)
  }

  aggregatedByTime() {
    return predicates.aggregatedByTime(this._rawData)
  }

  // @todo add tests
  rowsAmount(): number {
    return this.data().dimensionValues()[0].length
  }

  getAllowedRollUp(
    rollUp: RollUpFunction,
    dimensionType: Exclude<DimensionType, 'Range'>,
  ) {
    return selectors.getAllowedRollUp(this._rawData, rollUp, dimensionType)
  }

  usesWeightedAverageAggregation() {
    return predicates.usesWeightedAverageAggregation(this._rawData)
  }

  allowedToHaveTimeDimension() {
    return (
      predicates.allowedToHaveTimeDimension(this._rawData) &&
      this.type() === 'input'
    )
  }

  isCalculated() {
    return predicates.isCalculated(this._rawData)
  }

  usesAnyOfDataTypes(dataTypes: MetricDataValueType[]) {
    return predicates.usesAnyOfDataTypes(this._rawData, dataTypes)
  }

  getLocalRangeDimensions() {
    // We need it because lists can load columns totally without data
    if (this._rawData.metadata.dimensions === null) {
      throw new Error('Dimensions metadata is null')
    }
    return selectors.getLocalRangeDimensions(this._rawData as Metric)
  }

  periodsList() {
    if (this._rawData.data === null) {
      return []
    }
    return selectors.periodsList(
      this._rawData as WrappedData & {
        data: CompactMetricData
      },
    )
  }

  size(): Maybe<number> {
    return selectors.size(this._rawData)
  }
}
