import { PayloadAction } from '@reduxjs/toolkit'
import { AddDimensionToMetricPayload } from '../../types'
import { State } from '../types'
import {
  castToMetricEditingState,
  checkMetricSourceOrThrowError,
} from './helpers'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

/* @todo add tests */
export const addDimensionToMetric = (
  uncheckedState: State,
  { payload }: PayloadAction<AddDimensionToMetricPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  checkMetricSourceOrThrowError(state.metric, 'input')

  const metric = new MutableMetricWrapper(state.metric)
  metric.addDimension(payload.dimensionId, payload.allDimensions)
}
