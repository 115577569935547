import { InferReduxConnectable } from '@fintastic/shared/data-access/redux'
import { reducer } from './slice'
import { namespace } from './state'
export * from './types'
export * from './hooks'
export type { ChangeValueDataFormattingPayload } from './types/actions/shared'

const connectable = {
  namespace,
  reducer,
}

export const metricOrListEditingDuck = connectable as InferReduxConnectable<
  typeof connectable
>
