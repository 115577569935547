import React, { useState } from 'react'
import type { IHeaderParams } from 'ag-grid-community'
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
} from '@mui/material'
import PushPinIcon from '@mui/icons-material/PushPin'
import ArrowTopIcon from '@mui/icons-material/ArrowDropUp'
import ArrowBottomIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import SegmentIcon from '@mui/icons-material/Segment'

export const FloatingPanelColumnActions: React.FC<
  IHeaderParams & { onClose: () => void }
> = ({ column, columnApi, onClose }) => {
  const [pinOpen, setPinOpen] = useState(() => column.isPinned())

  return (
    <List disablePadding>
      <ListItem disablePadding onClick={() => setPinOpen((prev) => !prev)}>
        <ListItemButton>
          <Box display="flex" mr={2}>
            <PushPinIcon fontSize="small" color="disabled" />
          </Box>
          Pin column
          <ListItemSecondaryAction>
            <Box display="flex">
              {pinOpen ? <ArrowTopIcon /> : <ArrowBottomIcon />}
            </Box>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
      <Collapse in={pinOpen} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              column.setPinned('left')
              columnApi.autoSizeColumn(column)
              onClose()
            }}
          >
            <Box display="flex" mr={2}>
              {column.isPinnedLeft() ? (
                <CheckIcon fontSize="small" color="disabled" />
              ) : (
                <DummyIcon />
              )}
            </Box>
            Pin Left
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              column.setPinned('right')
              columnApi.autoSizeColumn(column)
              onClose()
            }}
          >
            <Box display="flex" mr={2}>
              {column.isPinnedRight() ? (
                <CheckIcon fontSize="small" color="disabled" />
              ) : (
                <DummyIcon />
              )}
            </Box>
            Pin Right
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              column.setPinned(false)
              columnApi.autoSizeColumn(column)
              onClose()
            }}
          >
            <Box display="flex" mr={2}>
              {!column.isPinned() ? (
                <CheckIcon fontSize="small" color="disabled" />
              ) : (
                <DummyIcon />
              )}
            </Box>
            No Pin
          </ListItemButton>
        </List>
      </Collapse>
      <Divider />
      <ListItem
        disablePadding
        onClick={() => {
          columnApi.autoSizeColumn(column)
          onClose()
        }}
      >
        <ListItemButton>
          <DummyIcon hasMargin />
          Autosize This Column
        </ListItemButton>
      </ListItem>
      <ListItem
        disablePadding
        onClick={() => {
          columnApi.autoSizeAllColumns()
          onClose()
        }}
      >
        <ListItemButton>
          <DummyIcon hasMargin />
          Autosize All Columns
        </ListItemButton>
      </ListItem>
      {column.isAllowRowGroup() ? (
        <>
          <Divider />
          <ListItem
            disablePadding
            onClick={() => {
              columnApi.addRowGroupColumn(column)
              onClose()
            }}
          >
            <ListItemButton>
              <Box display="flex" mr={2}>
                <SegmentIcon fontSize="small" color="disabled" />
              </Box>
              Group by {column.getColDef().headerName}
            </ListItemButton>
          </ListItem>
        </>
      ) : null}
      <Divider />
      <ListItem
        disablePadding
        onClick={() => {
          columnApi.resetColumnState()
          onClose()
        }}
      >
        <ListItemButton>
          <DummyIcon hasMargin />
          Reset Columns
        </ListItemButton>
      </ListItem>
    </List>
  )
}

const DummyIcon: React.FC<{ hasMargin?: boolean }> = ({ hasMargin }) => (
  <Box display="flex" width="16px" mr={hasMargin ? 2 : 0} />
)
