import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ChartFilterIcon = createSvgIcon(
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666626 2.58982C0.666626 1.50089 1.50079 0.666748 2.58971 0.666748H15.4102C16.4991 0.666748 17.3333 1.50089 17.3333 2.58982C17.3333 2.83361 17.3028 3.07622 17.2109 3.31514C17.1182 3.55637 16.9733 3.7665 16.7801 3.95972L11.524 9.21581V12.9063C11.524 13.0467 11.4673 13.1812 11.3667 13.2792L7.36035 17.1855C7.21037 17.3317 6.9874 17.3739 6.7944 17.2925C6.60139 17.2111 6.47592 17.022 6.47592 16.8126V9.21581L1.21983 3.95971C1.21983 3.95971 1.21983 3.95971 1.21983 3.95971C1.02662 3.7665 0.881773 3.55639 0.788989 3.31516C0.697094 3.07624 0.666626 2.83363 0.666626 2.58982ZM2.58971 1.70841C2.07607 1.70841 1.70829 2.07619 1.70829 2.58982C1.70829 2.74665 1.72791 2.85461 1.76122 2.94121C1.79364 3.0255 1.84912 3.11587 1.95639 3.22314L7.36504 8.63179C7.46271 8.72947 7.51759 8.86194 7.51759 9.00007V15.5773L10.4823 12.6867V9.00007C10.4823 8.86194 10.5372 8.72946 10.6349 8.63179L16.0435 3.22314C16.1508 3.11588 16.2063 3.02552 16.2387 2.94123C16.272 2.85463 16.2916 2.74667 16.2916 2.58982C16.2916 2.07618 15.9238 1.70841 15.4102 1.70841H2.58971Z"
    />
  </svg>,
  'ChartFilterIcon',
)
