import React, { useEffect } from 'react'
import { Typography } from '@mui/material'

export const CalculatingIndicator: React.FC = () => {
  const [ticks, setTicks] = React.useState(2)

  useEffect(() => {
    const iid = setInterval(() => {
      setTicks((t) => t + 1)
    }, 500)
    return () => clearInterval(iid)
  }, [])

  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        color: '#979797',
      }}
    >
      Calculating
      {Array((ticks % 3) + 1)
        .fill('.')
        .join('')}
    </Typography>
  )
}
