import { useAwaitingEventIds } from '@fintastic/web/util/metric-data-editing'
import { useCallback, useMemo } from 'react'
import {
  getMetricCellsByEventId,
  MetricCells,
  useSubscribeOnCellDataUpdateEvent,
} from '@fintastic/web/data-access/metric-data-editing'
import { SetMetricCellDataValuePayload } from '@fintastic/web/feature/metrics-and-lists'
import { Nullable } from '@fintastic/shared/util/functional-programming'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useQueryClient } from 'react-query'
import { invalidateMetricCache } from '@fintastic/web/data-access/metrics-and-lists'

export const useCellLevelUpdate = (
  versionIds: string[],
  metricId: string,
  deps: {
    periodSelection: PeriodSelection
    updateCellDataValue: (payload: SetMetricCellDataValuePayload) => void
    maskedValueMask: string | number
  },
) => {
  const queryCLient = useQueryClient()
  const awaitingEventIds = useAwaitingEventIds()

  const { updateCellDataValue, periodSelection } = deps

  const handleEventIdReceived = useCallback(
    (versionId: string, id: string) => {
      if (awaitingEventIds.ids.includes(id)) {
        awaitingEventIds.remove(id)
        return
      }

      getMetricCellsByEventId([id], versionId, deps.periodSelection)
        .then(({ data: { cells } }) => {
          const cellUpdates = metricCellsToUpdateCoordinates(
            cells,
            {
              versionId,
              metricId,
              timeDimension: deps.periodSelection.aggregationDimensionId,
            },
            deps.maskedValueMask,
          )

          console.log('perform cell level update (metric)', {
            versionId,
            metricId,
            eventId: id,
            periodSelection,
            cellsFromBE: cells,
            mappedOnTheClientCells: cellUpdates,
          })

          cellUpdates.forEach((update) => {
            try {
              updateCellDataValue(update)
            } catch (e) {
              console.error(e)
            }
          })
        })
        .catch((e) => {
          console.error(e)
          invalidateMetricCache(queryCLient, versionId, metricId)
        })
    },
    [
      awaitingEventIds,
      deps.maskedValueMask,
      deps.periodSelection,
      metricId,
      periodSelection,
      queryCLient,
      updateCellDataValue,
    ],
  )

  useSubscribeOnCellDataUpdateEvent(
    versionIds,
    useCallback(
      (event) => {
        if (
          !event.successful ||
          event.updateData.action !== 'edit_metric_data' ||
          !event.updateData.user_modified_entities.includes(metricId)
        ) {
          return
        }

        handleEventIdReceived(event.versionId, event.updateData.event_id)
      },
      [handleEventIdReceived, metricId],
    ),
  )

  return useMemo(
    () =>
      ({
        awaitEventId: awaitingEventIds.add,
      } as const),
    [awaitingEventIds.add],
  )
}

const metricCellsToUpdateCoordinates = (
  cellsList: MetricCells[],
  genericCoordinates: {
    versionId: string
    metricId: string
    timeDimension?: Nullable<TimeDimensionId>
  },
  maskedValueMask: string | number,
): SetMetricCellDataValuePayload[] => {
  const result: SetMetricCellDataValuePayload[] = []

  for (let i = 0; i < cellsList.length; i++) {
    const cells = cellsList[i]
    if (cells.entity_id !== genericCoordinates.metricId) {
      continue
    }
    for (let j = 0; j < cells.values.length; j++) {
      const value = cells.values[j]
      result.push({
        value: value === maskedValueMask ? null : value,
        coordinates: {
          versionId: genericCoordinates.versionId,
          metricId: genericCoordinates.metricId,
          timeDimension: genericCoordinates.timeDimension || undefined,
          dimensions: Object.fromEntries(
            cells.dimension_ids.map((dimId, dimIndex) => [
              dimId,
              cells.dimension_value_ids[dimIndex][j],
            ]),
          ),
        },
      })
    }
  }

  return result
}
