import { styled, Box } from '@mui/material'
import { AG_CELL_EDITOR_CLASS_NAME } from '@fintastic/shared/ui/ag-grid'

export const StyledRoot = styled(Box)`
  position: relative;

  border: 1px solid ${({ theme }) => theme.palette.primary.dark};

  height: 100%;

  .${AG_CELL_EDITOR_CLASS_NAME} fieldset {
    border: none;
  }
`

export const StyledSelector = styled(Box)`
  position: absolute;
  left: -1px;
  right: -1px;
`
