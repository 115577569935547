import { useCallback, useMemo } from 'react'
import { ChangeColumnVisibilityAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeColumnVisibilityFlow() {
  const { changeVisibility } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnVisibilityAction>(
    (columnId, visible) => {
      changeVisibility({
        columnId,
        visible,
      })
    },
    [changeVisibility],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
