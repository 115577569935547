import React from 'react'

export const SearchIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8835 9.93315L14.4702 13.5198C14.5962 13.646 14.667 13.817 14.667 13.9954C14.6669 14.1737 14.596 14.3447 14.4698 14.4708C14.3437 14.5969 14.1726 14.6677 13.9943 14.6676C13.8159 14.6675 13.6449 14.5966 13.5188 14.4705L9.93217 10.8838C8.85998 11.7143 7.51169 12.1051 6.16159 11.9767C4.81149 11.8484 3.56099 11.2105 2.66449 10.1929C1.76799 9.17528 1.29283 7.85436 1.33567 6.49885C1.37851 5.14333 1.93614 3.85505 2.89511 2.89608C3.85408 1.93711 5.14236 1.37949 6.49787 1.33665C7.85338 1.29381 9.1743 1.76897 10.1919 2.66547C11.2095 3.56197 11.8474 4.81246 11.9757 6.16257C12.1041 7.51267 11.7133 8.86096 10.8828 9.93315H10.8835ZM6.66684 10.6671C7.7277 10.6671 8.74512 10.2457 9.49526 9.49557C10.2454 8.74543 10.6668 7.72801 10.6668 6.66715C10.6668 5.60628 10.2454 4.58887 9.49526 3.83872C8.74512 3.08857 7.7277 2.66715 6.66684 2.66715C5.60597 2.66715 4.58856 3.08857 3.83841 3.83872C3.08826 4.58887 2.66684 5.60628 2.66684 6.66715C2.66684 7.72801 3.08826 8.74543 3.83841 9.49557C4.58856 10.2457 5.60597 10.6671 6.66684 10.6671Z"
      fill="currentColor"
    />
  </svg>
)
