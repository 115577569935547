import { Auth0Client } from '@auth0/auth0-spa-js'
import { environment } from '@fintastic/web/data-access/environment'
import { AUTH0_SCOPE } from './auth0-config'
import { getTenantConfig } from './tenant-config'
import {
  TestingAuth0Client,
  shouldUseTestAuth0Client,
} from '@fintastic/web/data-access/testing-auth0-client'

let client = null as unknown as Auth0Client

if (shouldUseTestAuth0Client()) {
  client = new TestingAuth0Client() as unknown as Auth0Client
} else if (!navigator.userAgent.includes('jsdom')) {
  client = new Auth0Client({
    domain: environment.REACT_APP_AUTH0_DOMAIN,
    client_id: getTenantConfig()?.auth0ClientId,
    scope: AUTH0_SCOPE.join(','),
    audience: environment.REACT_APP_AUTH0_AUDIENCE,
    // Empty string should be undefined
    organization: getTenantConfig()?.auth0OrgId || undefined,
    redirect_uri: window?.location.origin,
  })
}

export const auth0Client = client
