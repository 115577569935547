import { MetricDataWithVersionId } from '../../types'

// @todo add tests
export function validateMetricsShapeInDifferentVersions(
  metricData: MetricDataWithVersionId[],
): boolean {
  const dimensionId = metricData.map(
    (versionMetric) => versionMetric.metric?.metadata.value.dimension_id,
  )

  if ([...new Set(dimensionId)].length > 1) {
    return false
  }

  return true
}
