import { Box } from '@mui/material'
import React, { useCallback, useMemo, useRef } from 'react'
import {
  RestrictedToggle,
  RestrictedToggleValue,
} from './components/RestrictedToggle'
import { AgGridReact } from 'ag-grid-react'
import { ColumnConfig } from './types'
import { useGlobalModel } from './hooks/useGlobalModel'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  ISetFilterParams,
  SideBarDef,
  ValueGetterParams,
} from 'ag-grid-community'
import {
  cleanupListName,
  removePrefixFromName,
} from '@fintastic/web/util/metrics-and-lists'
import {
  useIAMResourceMutation,
  IAMResourceRestrictionAudience,
} from '@fintastic/web/data-access/iam'

const OrphanCellRenderer = (props: { data: ColumnConfig }) => (
  <span>
    {removePrefixFromName(
      cleanupListName(props?.data?.name || 'unknown value'),
    )}
  </span>
)

export const RestrictColumnsSelection: React.FC = () => {
  const { columns: allColumns, loading } = useGlobalModel()

  const iamResourceMutation = useIAMResourceMutation()

  const onColumnToggle = useCallback(
    (
      colOrTableId: string,
      restricted_for: IAMResourceRestrictionAudience,
      type: 'table' | 'column',
    ) => {
      if (type === 'column') {
        iamResourceMutation.mutate({
          resource_ids: [colOrTableId],
          resource_type: 'metric',
          restricted_for,
        })
      } else {
        const affectedColumns = allColumns
          .filter((i) => i.list_id === colOrTableId)
          .map(({ id }) => id)
        iamResourceMutation.mutate({
          resource_ids: affectedColumns,
          resource_type: 'metric',
          restricted_for,
        })
      }
    },
    [allColumns, iamResourceMutation],
  )

  const onColumnToggleRef = useRef(onColumnToggle)
  onColumnToggleRef.current = onColumnToggle

  const tableColDefs = useMemo<ColDef<ColumnConfig>[]>(
    () => [
      {
        field: 'list_id',
        headerName: 'List Name',
        rowGroup: true,
        hide: true,
        valueFormatter: ({ value }) =>
          removePrefixFromName(cleanupListName(value)),
        filterParams: {
          valueFormatter: ({ value }) =>
            removePrefixFromName(cleanupListName(value)),
        } as ISetFilterParams,
      },
      {
        field: 'name',
        headerName: 'Name',
        filter: false,
        showRowGroup: 'list_id',
        valueFormatter: ({ value }) =>
          removePrefixFromName(cleanupListName(value)),
        filterParams: {
          valueFormatter: ({ value }) =>
            removePrefixFromName(cleanupListName(value)),
        } as ISetFilterParams,
        cellRendererSelector: (params) => {
          if (params.node?.key === null) {
            return { component: OrphanCellRenderer }
          }
          return { component: 'agGroupCellRenderer' }
        },
      },
      {
        field: 'allowed',
        headerName: 'Access',
        valueGetter: (
          params: ValueGetterParams<ColumnConfig>,
        ): RestrictedToggleValue =>
          params.data?.restriction_audience || 'nobody',
        aggFunc: (params): RestrictedToggleValue => {
          if (params.values.every((v) => v === params.values[0])) {
            return params.values[0]
          }
          return 'mixed'
        },
        filter: 'agSetColumnFilter',
        cellRenderer: (params: ICellRendererParams<ColumnConfig>) => (
          <RestrictedToggle
            restricted_for={params.value}
            onChange={(value) => {
              const appliedValue =
                value === 'nobody'
                  ? null
                  : (value as IAMResourceRestrictionAudience)

              if (params.data) {
                onColumnToggleRef.current(
                  params.data?.id,
                  appliedValue,
                  'column',
                )
              } else {
                onColumnToggleRef.current(
                  params.node?.key || '',
                  appliedValue,
                  'table',
                )
              }
            }}
          />
        ),
        width: 180,
      },
    ],
    [],
  )

  const getRowIdCallBack = useCallback(
    (params: GetRowIdParams<ColumnConfig>) => params.data.id,
    [],
  )

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      minWidth: 385,
      pinned: 'left',
      sort: 'asc',
    }),
    [],
  )

  if (loading) {
    return <CenteredCircularProgress />
  }

  return (
    <Box height="100%">
      <AgGridThemeFintasticWrapper sx={defaultGridStyles}>
        {({ defaultGridProps }) => (
          <AgGridReact
            {...defaultGridProps}
            columnDefs={tableColDefs}
            defaultColDef={defaultColDef}
            rowData={allColumns}
            getRowId={getRowIdCallBack}
            autoGroupColumnDef={autoGroupColumnDef}
            sideBar={sidebarDef}
            groupDisplayType="custom"
            groupRemoveSingleChildren={true}
            suppressAggFuncInHeader
          ></AgGridReact>
        )}
      </AgGridThemeFintasticWrapper>
    </Box>
  )
}

const defaultGridStyles = {
  height: '100%',
  width: '100%',
}

const defaultColDef = {
  filter: true,
  sortable: true,
  resizable: true,
  flex: 1,
}

const sidebarDef: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: '',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
}
