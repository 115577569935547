import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type ConfirmSaveOrphanedRecordsModalProps = {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmSaveOrphanedRecordsModal: React.FC<
  ConfirmSaveOrphanedRecordsModalProps
> = ({ open, onConfirm, onCancel }) => (
  <ConfirmationPopup
    open={open}
    onRequestClose={onCancel}
    onConfirm={onConfirm}
    onDecline={onCancel}
    title={'Not all the columns are displayed'}
    autoClose={false}
  >
    <Box width="100%">
      <ErrorAlert
        title={'Data of invisible columns will not be saved'}
        message="It won’t be possible to retrieve the current data"
        severity="warning"
      />
      <Box mt={3} width="100%" display="flex" justifyContent="center">
        <ConfirmationPopup.DeclineButton>
          Continue editing lines
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton
          sx={{ ml: 1 }}
          variant="contained"
          color="primary"
        >
          Save lines
        </ConfirmationPopup.ConfirmButton>
      </Box>
    </Box>
  </ConfirmationPopup>
)
