import React from 'react'
import { StyledList } from './NavButtonsContainer.styled'

export type NavButtonsContainerProps = {
  children: React.ReactNode
}

export const NavButtonsContainer: React.FC<NavButtonsContainerProps> = ({
  children,
}) => <StyledList>{children}</StyledList>
