import {
  DisplayErrorCallback,
  LoadListMetadataCallback,
  LockVersionCallback,
  UnlockVersionCallback,
} from '../shared-types'
import { OperationStateApi } from '@fintastic/shared/util/operation'
import { VersionEntities } from '@fintastic/web/util/versions'
import {
  List,
  mapAllFormulasInList,
} from '@fintastic/web/util/metrics-and-lists'

export type InactiveListEditorApi = ReturnType<typeof makeApi>

type State = {
  editOperationStateApi: OperationStateApi
}

type Dependencies = {
  displayError: DisplayErrorCallback
  lockVersion: LockVersionCallback
  unlockVersion: UnlockVersionCallback
  loadListMetadata: LoadListMetadataCallback
  loadVersionEntities: () => Promise<VersionEntities>
  startEditing: (list: List) => void
  isUserAllowedToEditList: boolean
}

type Context = {
  isVersionEditable: boolean
}

export const makeApi = (state: State, deps: Dependencies, context: Context) =>
  ({
    active: false,
    allowedToEnterEditMode:
      context.isVersionEditable && deps.isUserAllowedToEditList,
    editList: {
      run: async (listId: string) => {
        try {
          state.editOperationStateApi.start()
          await deps.lockVersion()
          const originalList = await deps.loadListMetadata(listId)
          const entities = await deps.loadVersionEntities()
          const list = mapAllFormulasInList(originalList, entities, 'labelToId')
          deps.startEditing(list)
          state.editOperationStateApi.finish(true)
        } catch (e) {
          state.editOperationStateApi.finish(false)
          deps.displayError("Can't start the List editing")
          console.error(e)
          try {
            await deps.unlockVersion()
          } catch (e) {
            console.error(e)
          }
        }
      },
      running: state.editOperationStateApi.status === 'processing',
    },
  } as const)
