import type { IHeaderParams } from 'ag-grid-community'
import React, { useCallback } from 'react'
import { Menu, Sort } from '../AgGridColumnHeaderCustomizer'
import { Container } from './HeaderWithTooltip.styled'
import { InlineInfoTooltip } from '@fintastic/shared/ui/components'

type HeaderProps = {
  tooltip: string
} & IHeaderParams

export const HeaderWithTooltip: React.FC<HeaderProps> = (props) => {
  const columnSort = props.column.getSort() || null

  const toggleSort = useCallback(() => {
    if (!props.enableSorting) {
      return
    }

    if (columnSort === 'asc') {
      props.setSort('desc', false)
    } else {
      if (columnSort === 'desc') {
        props.setSort(null, false)
      } else {
        props.setSort('asc', false)
      }
    }
  }, [columnSort, props])

  return (
    <Container>
      <div className="tooltip-header" onClick={toggleSort}>
        <div className="tooltip-header-title">
          <span className={'header-with-tooltip-icon'}>
            {props.displayName}
            <InlineInfoTooltip tooltip={props.tooltip} />
          </span>
        </div>

        <Sort order={columnSort} sortIndex={null} />
      </div>
      <Menu showColumnMenu={props.showColumnMenu} />
    </Container>
  )
}
