import { styled, Box, ButtonBase } from '@mui/material'

export const StyledBarNavItemRoot = styled(Box)(({ theme }) => ({}))

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})<{ active: boolean; disabled: boolean }>`
  position: relative;

  display: block;

  width: 100%;

  color: ${({ active, disabled, theme }) =>
    active ? '#FF7933' : disabled ? theme.palette.grey[500] : '#263646'};
  transition: all 400ms;
  background: transparent;

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.palette.grey[100]};
  }
`

export const StyledButtonBg = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 4px;

    border-radius: 0 3px 3px 0;
    background: ${({ active }) => (active ? '#FF7933' : 'transparent')};
    transition: all 400ms;
  }
`

export const StyledButtonFg = styled('div')`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 6px;

  width: 100%;
  height: 100%;
`

export const StyledButtonIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: auto;
  }
`

export const StyledButtonText = styled('div')`
  margin-top: 2px;

  text-align: center;
`
