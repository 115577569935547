import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import {
  Auth0Client,
  LogoutOptions,
  LogoutUrlOptions,
  PopupLoginOptions,
  PopupConfigOptions,
  RedirectLoginOptions as Auth0RedirectLoginOptions,
  GetTokenWithPopupOptions,
  RedirectLoginResult,
  GetTokenSilentlyOptions,
  GetIdTokenClaimsOptions,
} from '@auth0/auth0-spa-js'
import Auth0Context, { RedirectLoginOptions } from './auth0-context'
import { hasAuthParams, loginError, tokenError } from './utils'
import { reducer } from './reducer'
import { initialAuthState } from './auth-state'

/**
 * The state of the application before the user was redirected to the login page.
 */
export type AppState = {
  returnTo?: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

/**
 * The main configuration to instantiate the `Auth0Provider`.
 */
export interface Auth0ProviderOptions {
  /**
   * The auth0 client instance
   */
  client: Auth0Client
  /**
   * The child nodes your Provider has wrapped
   */
  children?: React.ReactNode
  /**
   * By default this removes the code and state parameters from the url when you are redirected from the authorize page.
   * It uses `window.history` but you might want to overwrite this if you are using a custom router, like `react-router-dom`
   * See the EXAMPLES.md for more info.
   */
  onRedirectCallback?: (appState?: AppState) => void
  /**
   * By default, if the page url has code/state params, the SDK will treat them as Auth0's and attempt to exchange the
   * code for a token. In some cases the code might be for something else (another OAuth SDK perhaps). In these
   * instances you can instruct the client to ignore them eg
   *
   * ```jsx
   * <Auth0Provider
   *   clientId={clientId}
   *   domain={domain}
   *   skipRedirectCallback={window.location.pathname === '/stripe-oauth-callback'}
   * >
   * ```
   */
  skipRedirectCallback?: boolean
}

/**
 * @ignore
 */
const toAuth0LoginRedirectOptions = (
  opts?: RedirectLoginOptions,
): Auth0RedirectLoginOptions | undefined => {
  if (!opts) {
    return
  }
  const { redirectUri, ...validOpts } = opts
  return {
    ...validOpts,
    redirect_uri: redirectUri,
  }
}

/**
 * @ignore
 */
const defaultOnRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname,
  )
}

/**
 * ```jsx
 * <Auth0Provider
 *   client={auth0client}>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 */
const Auth0Provider = (opts: Auth0ProviderOptions): JSX.Element => {
  const {
    children,
    skipRedirectCallback,
    onRedirectCallback = defaultOnRedirectCallback,
    client: clientInstance,
  } = opts
  const [client] = useState(() => clientInstance)
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const didInitialise = useRef(false)

  useEffect(() => {
    if (didInitialise.current) {
      return
    }
    didInitialise.current = true
    ;(async (): Promise<void> => {
      try {
        if (hasAuthParams() && !skipRedirectCallback) {
          const { appState } = await client.handleRedirectCallback()
          onRedirectCallback(appState)
        } else {
          await client.checkSession()
        }
        const user = await client.getUser()
        dispatch({ type: 'INITIALISED', user })
      } catch (error) {
        console.warn('Auth0-Provider Error', error)
        dispatch({ type: 'ERROR', error: loginError(error as Error) })
      }
    })()
  }, [client, onRedirectCallback, skipRedirectCallback])

  const buildAuthorizeUrl = useCallback(
    (opts?: RedirectLoginOptions): Promise<string> =>
      client.buildAuthorizeUrl(toAuth0LoginRedirectOptions(opts)),
    [client],
  )

  const buildLogoutUrl = useCallback(
    (opts?: LogoutUrlOptions): string => client.buildLogoutUrl(opts),
    [client],
  )

  const loginWithRedirect = useCallback(
    (opts?: RedirectLoginOptions): Promise<void> =>
      client.loginWithRedirect(toAuth0LoginRedirectOptions(opts)),
    [client],
  )

  const loginWithPopup = useCallback(
    async (
      options?: PopupLoginOptions,
      config?: PopupConfigOptions,
    ): Promise<void> => {
      dispatch({ type: 'LOGIN_POPUP_STARTED' })
      try {
        await client.loginWithPopup(options, config)
      } catch (error) {
        console.warn('Auth0-Provider Error', error)
        dispatch({ type: 'ERROR', error: loginError(error as Error) })
        return
      }
      const user = await client.getUser()
      dispatch({ type: 'LOGIN_POPUP_COMPLETE', user })
    },
    [client],
  )

  const logout = useCallback(
    (opts: LogoutOptions = {}): Promise<void> | void => {
      const maybePromise = client.logout(opts)
      if (opts.localOnly) {
        if (maybePromise && typeof maybePromise.then === 'function') {
          return maybePromise.then(() => dispatch({ type: 'LOGOUT' }))
        }
        dispatch({ type: 'LOGOUT' })
      }
      return maybePromise
    },
    [client],
  )

  const getAccessTokenSilently = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (opts?: GetTokenSilentlyOptions): Promise<any> => {
      let token
      try {
        token = await client.getTokenSilently(opts)
      } catch (error) {
        throw tokenError(error as Error)
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser(),
        })
      }
      return token
    },
    [client],
  )

  const getAccessTokenWithPopup = useCallback(
    async (
      opts?: GetTokenWithPopupOptions,
      config?: PopupConfigOptions,
    ): Promise<string> => {
      let token
      try {
        token = await client.getTokenWithPopup(opts, config)
      } catch (error) {
        throw tokenError(error as Error)
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser(),
        })
      }
      return token
    },
    [client],
  )

  const getIdTokenClaims = useCallback(
    (opts?: GetIdTokenClaimsOptions) => client.getIdTokenClaims(opts),
    [client],
  )

  const handleRedirectCallback = useCallback(
    async (url?: string): Promise<RedirectLoginResult> => {
      try {
        return await client.handleRedirectCallback(url)
      } catch (error) {
        throw tokenError(error as Error)
      } finally {
        dispatch({
          type: 'HANDLE_REDIRECT_COMPLETE',
          user: await client.getUser(),
        })
      }
    },
    [client],
  )

  const contextValue = useMemo(
    () => ({
      ...state,
      buildAuthorizeUrl,
      buildLogoutUrl,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      getIdTokenClaims,
      loginWithRedirect,
      loginWithPopup,
      logout,
      handleRedirectCallback,
    }),
    [
      state,
      buildAuthorizeUrl,
      buildLogoutUrl,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      getIdTokenClaims,
      loginWithRedirect,
      loginWithPopup,
      logout,
      handleRedirectCallback,
    ],
  )

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
