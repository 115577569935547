import { PayloadAction } from '@reduxjs/toolkit'
import { RemoveDimensionFromMetricPayload } from '../../types'
import { State } from '../types'
import {
  castToMetricEditingState,
  checkMetricSourceOrThrowError,
} from './helpers'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

/* @todo add tests */
export const removeDimensionFromMetric = (
  uncheckedState: State,
  {
    payload: { dimensionId, allDimensions },
  }: PayloadAction<RemoveDimensionFromMetricPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  checkMetricSourceOrThrowError(state.metric, 'input')

  const metric = new MutableMetricWrapper(state.metric)
  metric.removeDimension(dimensionId, allDimensions)
}
