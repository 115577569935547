import { isArray } from 'lodash'
import { UserRole } from '../../types'
import { useUserRole } from './useUserRole'
import { Maybe } from '@fintastic/shared/util/types'

/**
 * Checks whether current user has role-level access
 * (!) You may need to handle role loading status, which is `undefined`
 * If you don't care about loading status, just use:
 * @example `Boolean(useRoleLevelAccess('power_user'))`
 * @param requiredRole Role or roles array you are looking for
 * @returns `true` If user has the required role
 * @returns `false` If user does not have required role
 * @returns `undefined` If current user role is not resolved yet
 * @returns `null` If user role is not defined in DB
 */
export const useRoleLevelAccess = (
  requiredRole: UserRole | UserRole[],
): Maybe<boolean | undefined> => {
  const role = useUserRole()

  if (role === undefined) {
    return undefined
  }

  if (role === null) {
    return null
  }

  if (isArray(requiredRole)) {
    return requiredRole.includes(role)
  }

  return requiredRole === role
}
