import { format } from 'numerable'
import {
  MetricDataValue,
  MetricDataValueType,
  MetricDisplaySettings,
  MetricNumericDataValueType,
} from '../../types'
import { ValueFormatterFunc } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import { getDataTypeGroup } from './data-type-groups'
import { NumerableFormatNumberOptions } from 'numerable/formatter/types/format-number-options'
import { FactoryOpts } from 'imask'
import { Currency } from '@fintastic/shared/data-access/currencies'
import {
  isRawBlankValue,
  BLANK_VALUE_UI_REPRESENTATION,
  containsMaskedValue,
  MASKED_VALUE_UI_REPRESENTATION,
} from '@fintastic/web/util/blanks-and-masked'
import { applyCompactFormatting } from '@fintastic/shared/util/formatters'

export function createValueFormatter(
  dataType: MetricDataValueType,
  displaySettings: MetricDisplaySettings,
  currency?: Currency,
): Maybe<ValueFormatterFunc<MetricDataValue>> {
  if (getDataTypeGroup(dataType) === 'numeric') {
    return createNumericValueFormatter(
      dataType as MetricNumericDataValueType,
      displaySettings,
      currency,
    )
  }

  return null
}

const NUMERIC_FORMATTER_ABBREVIATION_REPLACEMENT_MARK = '~;K;~'
const NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK = '~;C;~'

function createNumericValueFormatter(
  dataType: MetricNumericDataValueType,
  { formatting, currency_name, currency_sign_position }: MetricDisplaySettings,
  currency?: Currency,
): ValueFormatterFunc<MetricDataValue> {
  const {
    prefix,
    suffix,
    decimal_places,
    compact_formatting,
    negatives,
    thousands_separator,
  } = formatting

  const formatterOptions: NumerableFormatNumberOptions = currency
    ? {}
    : {
        currency: currency_name,
      }

  const wholePartTemplate = thousands_separator ? '0,0' : '0'

  let decimalPlacesTemplate = ''
  if (dataType !== 'integer' && dataType !== 'percentage_integer') {
    decimalPlacesTemplate =
      decimal_places > 0 ? `.${Array(decimal_places).fill(0).join('')}` : ''
  }

  let numberTemplate = `${wholePartTemplate}${decimalPlacesTemplate}`
  if (compact_formatting !== 'no_format') {
    numberTemplate = `${numberTemplate}${NUMERIC_FORMATTER_ABBREVIATION_REPLACEMENT_MARK}`
  }

  const currencySymbol = getCurrencySymbol(currency)
  if (dataType === 'currency' && currency_sign_position) {
    numberTemplate =
      currency_sign_position === 'before'
        ? `${NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK}${numberTemplate}`
        : `${numberTemplate} ${NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK}`
  }

  if (dataType === 'percentage' || dataType === 'percentage_integer') {
    numberTemplate = `${numberTemplate}%`
  }

  const numberWithSignTemplate =
    negatives === '-' ? `+${numberTemplate}` : `(${numberTemplate})`

  const resultTemplate = numberWithSignTemplate

  const postProcessFormattedValue = (value: string): string => {
    const valueWithAbbreviationNumbers =
      compact_formatting !== 'no_format'
        ? value.replace(
            NUMERIC_FORMATTER_ABBREVIATION_REPLACEMENT_MARK,
            compact_formatting === 'thousands' ? 'K' : 'M',
          )
        : value
    const valueWithCurrency =
      dataType === 'currency'
        ? valueWithAbbreviationNumbers.replace(
            NUMERIC_FORMATTER_CURRENCY_REPLACEMENT_MARK,
            currencySymbol,
          )
        : valueWithAbbreviationNumbers
    return `${prefix}${valueWithCurrency}${suffix}`
  }

  return ({ value }) => {
    if (containsMaskedValue(value)) {
      return MASKED_VALUE_UI_REPRESENTATION
    }
    // Undefined appears here when we remove unused dimensions
    // from sparse version `A`, but version `B` contain those dimensions
    // Optimistically we threat them as BLANK
    // @see frontend/main-client/libs/web/feature/metrics-and-lists/src/lib/utils/__mocks__/expand-metric-data.data.ts
    if (isRawBlankValue(value) || value === undefined) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    return postProcessFormattedValue(
      format(
        applyCompactFormatting(compact_formatting, value),
        resultTemplate,
        formatterOptions,
      ),
    )
  }
}

export function createNumericValueInputMask(
  dataType: MetricNumericDataValueType,
  { formatting, currency_name, currency_sign_position }: MetricDisplaySettings,
  currency?: Currency,
): FactoryOpts {
  const mask: FactoryOpts = {
    mask: 'num',
    lazy: false,
    blocks: {
      num: {
        expose: true,
        mask: Number,
        radix: '.',
        thousandsSeparator: formatting.thousands_separator ? ',' : '',
        scale:
          dataType === 'number' ||
          dataType === 'percentage' ||
          dataType === 'currency'
            ? formatting.decimal_places
            : 0,
      },
    },
  }

  if (dataType === 'percentage_integer' || dataType === 'percentage') {
    mask.mask = 'num%'
  }

  if (
    dataType === 'currency' &&
    currency_name !== '' &&
    currency_sign_position
  ) {
    const currencySymbol = getCurrencySymbol(currency)
    mask.mask =
      currency_sign_position === 'after'
        ? `num ${currencySymbol}`
        : `${currencySymbol}num`
  }

  return mask
}

export function getPositionOfNumericValueInFormattedString(
  dataType: MetricNumericDataValueType,
  displaySettings: MetricDisplaySettings,
  currency?: Currency,
): number {
  let position = 0

  if (
    dataType === 'currency' &&
    displaySettings.currency_sign_position === 'before'
  ) {
    position += getCurrencySymbol(currency).length
  }

  return position
}

export function getCurrencySymbol(currency?: Currency): string {
  return currency ? currency.symbol : '$'
}
