import React, { useCallback } from 'react'
import { Version } from '../Editor/features/version-state/types'
import { useSyntaxApi } from './features/syntax/useSyntaxApi'
import { useVersionState } from './features/version-state/useVersionState'
import { useSaveFormula } from './features/editing/useSaveFormula'
import { useNotificationsApi } from './features/notifications/useNotificationsApi'
import {
  CalculationRequestError,
  CalculationError,
} from '@fintastic/web/data-access/calc'
import { FormulaValidationCallback } from '../Editor/features/validation/types'
import { Maybe } from '@fintastic/shared/util/types'
import { EditorWithVersionEntities } from '../Editor/features/version-entites/EditorWithVersionEntities'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import isEqual from 'lodash/isEqual'
import { ContextParameterContext } from '../Editor/features/context-parameter'
import { ClientOnlyMapping } from '@fintastic/web/util/formulas'
import { useVersionUserLockQuery } from '@fintastic/web/data-access/versions'

export type ConnectorProps<TContext = unknown> = {
  title?: React.ReactNode
  versions: Version[]
  showVersionsSelector?: boolean
  readonly?: boolean
  onRequestClose?: () => void
  defaultEditingState?: boolean
  noPadding?: boolean
  onValidationFinished?: FormulaValidationCallback<TContext>
  controlledEditingMode?: boolean
  showCloseButtonInControlledMode?: boolean
  /**
   * For support of recognition and mapping of client-only entities,
   * such as metrics and columns during their creation.
   */
  clientOnlyMapping?: ClientOnlyMapping
  controlledFormulaError?: Maybe<string>
  context?: TContext
}

export const Connector: {
  <TContext = unknown>(params: ConnectorProps<TContext>): JSX.Element
} = ({
  versions: _versions,
  title,
  showVersionsSelector,
  readonly,
  onRequestClose,
  defaultEditingState,
  noPadding,
  onValidationFinished,
  controlledEditingMode = false,
  showCloseButtonInControlledMode = false,
  controlledFormulaError,
  context,
  clientOnlyMapping,
}) => {
  const versions = useReferenceMemo(_versions, isEqual)
  const syntaxApi = useSyntaxApi()
  const versionsState = useVersionState(versions)
  const versionUserLockQuery = useVersionUserLockQuery(versionsState.version?.id)
  const notificationsApi = useNotificationsApi()

  const handleUpdateFailedFormulaWithToast = useCallback(
    (error: CalculationRequestError) => {
      notificationsApi.showSavingFailed(
        error instanceof CalculationError
          ? 'calculationFailed'
          : 'clientSideError',
      )
    },
    [notificationsApi],
  )

  const saveFormulaApi = useSaveFormula(
    versionsState.version,
    undefined,
    handleUpdateFailedFormulaWithToast,
  )

  return (
    <ContextParameterContext.Provider value={context}>
      <EditorWithVersionEntities
        title={title}
        showVersionsSelector={showVersionsSelector}
        readonly={readonly}
        onRequestClose={onRequestClose}
        defaultEditingState={defaultEditingState}
        noPadding={noPadding}
        versions={versionsState}
        syntaxApi={syntaxApi}
        saveFormulaApi={saveFormulaApi}
        controlledFormulaError={controlledFormulaError}
        versionUserLock={versionUserLockQuery.lock}
        onChangesDiscarded={notificationsApi.showChangesDiscarded}
        onValidationFinished={onValidationFinished as FormulaValidationCallback}
        controlledEditingMode={controlledEditingMode}
        showCloseButtonInControlledMode={showCloseButtonInControlledMode}
        clientOnlyMapping={clientOnlyMapping}
      />
    </ContextParameterContext.Provider>
  )
}
