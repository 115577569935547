import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'
import {
  StyledColumnItem,
  StyledColumnItemContainer,
  StyledDimensionName,
  StyledSwitch,
} from './MetricChartColumns.styled'
import { MetricDimensionContent } from '../../../../../types'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined'
import { MetricChartDimensionIcon } from './MetricChartDimensionIcon'
import { TypographyWithEllipsisTooltip } from '@fintastic/shared/ui/components'
import { titleFormatter } from '@fintastic/shared/util/formatters'

type MetricChartSortableColumnProps = {
  dimension: MetricDimensionContent
  onSetDimensionAggregateState: (id: string, checked: boolean) => void
  staticColumn?: boolean
}

export const MetricChartSortableColumn: React.FC<
  MetricChartSortableColumnProps
> = ({ dimension, onSetDimensionAggregateState, staticColumn = false }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: dimension.id,
      disabled: staticColumn,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const title =
    dimension.type === 'Time' ? 'Time' : titleFormatter(dimension.label)

  return (
    <StyledColumnItemContainer>
      <StyledColumnItem
        data-testid="dimensions-sorter-panel-item"
        data-test-value={dimension.id}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
      >
        <DragIndicatorOutlinedIcon sx={{ opacity: staticColumn ? 0.4 : 1 }} />

        <StyledDimensionName>
          <MetricChartDimensionIcon sx={{ opacity: staticColumn ? 0.4 : 1 }} />

          <TypographyWithEllipsisTooltip
            title={title}
            sx={{
              fontWeight: staticColumn ? 300 : 400,
              opacity: staticColumn ? 0.4 : 1,
              width:
                typeof dimension.aggregate === 'boolean'
                  ? 'calc(100% - 80px)'
                  : '100%',
            }}
          />
        </StyledDimensionName>

        {typeof dimension.aggregate === 'boolean' && (
          <StyledSwitch
            className={'drag-switch'}
            checked={dimension.aggregate}
          />
        )}
      </StyledColumnItem>

      {typeof dimension.aggregate === 'boolean' && (
        <StyledSwitch
          className={'real-switch'}
          checked={dimension.aggregate}
          onChange={(_, checked) => {
            onSetDimensionAggregateState(dimension.id, checked)
          }}
        />
      )}
    </StyledColumnItemContainer>
  )
}
