import React, { useMemo } from 'react'
import { useListEditorApi } from '../../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../../features/active/makeApi'
import { PanelContainer } from '../shared/PanelContainer'
import { Grid } from '../shared/Grid'
import { FormulasEditor } from '../shared/FormulasEditor'
import { MetricGridRow } from '@fintastic/web/util/metrics-and-lists'
import { useVersionDimensions } from '../shared/useVersionDimensions'
import { useColDefs } from '../shared/useColDefs'

export const NewListEditor: React.FC = () => {
  const api = useListEditorApi() as ActiveListEditorApi
  const { isLoadingDependencies } = api

  const { dimensions: versionDimensions, isLoading: dimensionsAreLoading } =
    useVersionDimensions()
  const colDefs = useColDefs(versionDimensions)

  const rowData = useMemo<MetricGridRow[]>(
    () => [
      {
        _rowId: 'dummy',
      },
    ],
    [],
  )

  const isLoading = isLoadingDependencies || dimensionsAreLoading

  return (
    <PanelContainer isLoading={isLoading} formulaEditor={<FormulasEditor />}>
      {!isLoading && <Grid colDefs={colDefs} rowData={rowData} />}
    </PanelContainer>
  )
}
