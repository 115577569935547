import { Box, CircularProgress, styled } from '@mui/material'

export const StyledFoundHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
}))

export const StyledBusyIndicator = styled(CircularProgress)(() => ({
  marginLeft: '1em',
  color: 'inherit',
  display: 'inline-block',
}))
