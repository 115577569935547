import type { ICellRendererParams } from 'ag-grid-community'
import React, { useCallback } from 'react'
import { Box, Switch, Tooltip } from '@mui/material'

export const ReplaceAllSwitchCellRenderer = (
  params: ICellRendererParams & { setValue: (value: boolean) => void },
) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      params.setValue(event.target.checked)
    },
    [params.data.is_replacement],
  )
  return (
    <Box
      py={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Tooltip title={params.data?.is_replacement ? 'Yes' : 'No'}>
        <Switch checked={params.data?.is_replacement} onChange={handleChange} />
      </Tooltip>
    </Box>
  )
}
