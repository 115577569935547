import { styled } from '@mui/material'

export const StyledEntityView = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: auto;
`

export const StyledFilters = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)} 18px;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
`

export const StyledContent = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;

  overflow: auto;
`

export const StyledPagination = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 10px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: auto;
`
