import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  setDimensionAccess,
  setTableAccess,
  setTableAccessBulk,
  setTableOverride,
} from '../ducks'
import { DimensionMap, TableAccess, TableAccessBulk } from '../types'
import type { Maybe } from '@fintastic/shared/util/types'
import type { PolicyTableResourceType } from '../api'

export const useTableChangeHandlers = (): {
  onToggleAccess: (table: TableAccess) => void
  onToggleAccessBulk: (table: TableAccessBulk) => void
  onDimensionAccessChange: (
    table: string | null,
    resourceType: Maybe<PolicyTableResourceType>,
    dimensions: DimensionMap,
  ) => void
  onAddTableOverride: (
    table: string,
    resourceType: PolicyTableResourceType,
  ) => void
  onRemoveTableOverride: (
    table: string,
    resourceType: PolicyTableResourceType,
  ) => void
} => {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      onToggleAccess: (table: TableAccess) => dispatch(setTableAccess(table)),
      onToggleAccessBulk: (tables: TableAccessBulk) =>
        dispatch(setTableAccessBulk(tables)),
      onDimensionAccessChange: (
        table: string | null,
        resourceType: Maybe<PolicyTableResourceType>,
        dimensions: DimensionMap,
      ) =>
        dispatch(
          setDimensionAccess({
            table,
            dimensions,
            resourceType,
          }),
        ),
      onAddTableOverride: (
        table: string,
        resourceType: PolicyTableResourceType,
      ) => dispatch(setTableOverride({ table, override: true, resourceType })),
      onRemoveTableOverride: (
        table: string,
        resourceType: PolicyTableResourceType,
      ) => dispatch(setTableOverride({ table, override: false, resourceType })),
    }),
    [dispatch],
  )
}
