import { styled, Box, CircularProgress, BoxProps } from '@mui/material'
import { layoutConfig } from '../../layout-config'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledPageLayoutContentLoaderRoot = styled(
  (
    props: BoxProps & {
      disablePaddingVertical?: boolean
    },
  ) => {
    const { disablePaddingVertical, ...rest } = props

    return <Box {...rest} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'disablePaddingVertical',
  },
)(({ theme, disablePaddingVertical }) => ({
  position: 'relative',

  ...styledMixins.alignContentCenter(),

  height: `calc(100vh - ${
    !disablePaddingVertical
      ? layoutConfig.page.paddingTop + layoutConfig.page.paddingBottom
      : 0
  }px - ${layoutConfig.topbar.height}px)`,
}))

export const StyledPageLayoutContentLoaderCircularProgress = styled(
  CircularProgress,
)(({ theme }) => ({}))
