import React from 'react'
import { CalendarDatePickerConfig, PeriodSelection } from '../types'
import { VersionEntityWithVersionId } from '@fintastic/web/data-access/versions'

export type PeriodSelectionContextValue = {
  periodSelection: PeriodSelection
  parentSelectionUsed: boolean
  // So you don't have to handle loading status with this cache
  cache: {
    versionEntities: VersionEntityWithVersionId[]
    /**
     * Date picker config with limited data according to entities selected
     */
    datePickerConfigForSelectedEntities: CalendarDatePickerConfig
    /**
     * Original date picker config that comes from API
     */
    fullDatePickerConfig: CalendarDatePickerConfig
  }
}

export const PeriodSelectorContextInitialValue: PeriodSelectionContextValue = {
  parentSelectionUsed: false,
  periodSelection: {
    aggregationDimensionId: '',
    dimensionId: '',
    range: ['', ''],
  },
  cache: {
    versionEntities: [],
    datePickerConfigForSelectedEntities: [],
    fullDatePickerConfig: [],
  },
}

// Initial value is not supposed to be ever used
export const PeriodSelectorContext =
  React.createContext<PeriodSelectionContextValue>(
    PeriodSelectorContextInitialValue,
  )

PeriodSelectorContext.displayName = 'PeriodSelectorContext'
