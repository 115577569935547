import React, { MouseEventHandler, useCallback } from 'react'

// @todo add tests
export type MenuProps = {
  showColumnMenu: (source: HTMLElement) => void
}

export const Menu: React.FC<MenuProps> = ({ showColumnMenu }) => {
  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      showColumnMenu(e.currentTarget)
    },
    [showColumnMenu],
  )

  return (
    <span
      className="ag-header-icon ag-header-cell-menu-button"
      onClick={handleClick}
    >
      <span
        className="ag-icon ag-icon-menu"
        unselectable="on"
        role="presentation"
      ></span>
    </span>
  )
}
