import React, { MouseEventHandler, useCallback } from 'react'
import { ColumnType } from '@fintastic/web/util/metrics-and-lists'
import {
  StyledRoot,
  StyledArrow,
  StyledText,
  StyledIcon,
  StyledDragHandle,
  StyledVisibilityButtonContainer,
  StyledTextContainer,
} from './Column.styled'
import { columnTypeToIconMap } from '../../../../../list-columns-icons'
import { DragHandle } from './DragHandle'
import { ArrowIcon } from './ArrowIcon'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useColumnsSettingsContext } from '../columns-settings-context'
import { IconButton, Tooltip } from '@mui/material'
import { ViewOnIcon, ViewOffIcon } from '@fintastic/shared/ui/icons'

export type ColumnProps = {
  type: ColumnType
  name: string
  id: string
  visible: boolean
}
export const Column: React.FC<ColumnProps> = ({ type, name, id, visible }) => {
  const { onGoToColumn, readonly, onChangeColumnVisibility } =
    useColumnsSettingsContext()

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled: readonly })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging
      ? {
          zIndex: 1,
        }
      : {}),
  }

  const handleClick = useCallback(() => {
    onGoToColumn(id)
  }, [id, onGoToColumn])

  const handleToggleVisibility = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      onChangeColumnVisibility(id, !visible)
    },
    [id, onChangeColumnVisibility, visible],
  )

  const TypeIcon = columnTypeToIconMap[type]

  return (
    <StyledRoot onClick={handleClick} style={style} ref={setNodeRef}>
      {!readonly && (
        <StyledDragHandle
          {...listeners}
          {...attributes}
          ref={setActivatorNodeRef}
        >
          <DragHandle />
        </StyledDragHandle>
      )}
      {TypeIcon && (
        <StyledIcon>
          <TypeIcon />
        </StyledIcon>
      )}
      <StyledText
        primary={
          <Tooltip placement="top" title={name} disableInteractive>
            <StyledTextContainer>{name}</StyledTextContainer>
          </Tooltip>
        }
      />
      <StyledVisibilityButtonContainer>
        <Tooltip
          disableInteractive
          placement="top"
          title={visible ? 'Hide by default' : 'Show by default'}
        >
          <span>
            <IconButton
              size="medium"
              color="textBlack"
              onClick={handleToggleVisibility}
              disabled={readonly}
            >
              {visible ? (
                <ViewOnIcon fontSize="small" />
              ) : (
                <ViewOffIcon fontSize="small" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </StyledVisibilityButtonContainer>
      <StyledArrow>
        <ArrowIcon fontSize="small" />
      </StyledArrow>
    </StyledRoot>
  )
}
