import React, { useContext, useMemo } from 'react'
import {
  ImportConfigItem,
  useImportConfigQuery,
} from '@fintastic/web/data-access/import-configs'
import { ImportActualsConfigContext } from '../context/ImportActualsConfigContext'
import { Box, CircularProgress } from '@mui/material'
import { ImportEntitiesTable } from './ImportEntitiesTable'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'
import {
  useLoadListOfLists,
  useLoadListOfMetrics,
} from '@fintastic/web/data-access/metrics-and-lists'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export const ImportEntitiesTableConnector: React.FC = () => {
  const { versionId } = useContext(ImportActualsConfigContext)
  const query = useImportConfigQuery(versionId, { refetchOnMount: true })

  const { data: labelsData, isLoading: labelsLoading } =
    useLabelsListQuery('versions')

  const listsQuery = useLoadListOfLists(versionId || '', false)
  const metricsQuery = useLoadListOfMetrics(versionId || '', false)

  const enrichedRecords = useMemo<ImportConfigItem[]>(() => {
    if (!query.data || query.data.length === 0) {
      return []
    }
    const result = [...query.data]

    listsQuery?.data?.forEach((list) => {
      const record = result.find((record) => record.resource_id === list.id)
      if (record) {
        record.label_ids = list.info?.label_ids || []
      }
    })

    metricsQuery?.data?.forEach((metric) => {
      const record = result.find((record) => record.resource_id === metric.id)
      if (record) {
        record.label_ids = metric.info?.label_ids || []
      }
    })
    return result
  }, [query.data, listsQuery?.data, metricsQuery?.data])

  if (query.isLoading || labelsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <FintasticThemeProvider applyLegacyTheme={true}>
      <ImportEntitiesTable
        initialData={enrichedRecords}
        labels={labelsData || []}
      />
    </FintasticThemeProvider>
  )
}
