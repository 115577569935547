import { MetricDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { DimensionType, RollUpFunction } from '@fintastic/shared/util/types'
import { DataTypeGroup, getDataTypeGroup } from './data-type-groups'
import { supportedRollUps } from '../aggregation-functions/supported'

const disallowedRollUpsForTimeDimension: Record<
  DataTypeGroup,
  RollUpFunction[]
> = {
  numeric: ['noop'],
  text: supportedRollUps.filter((r) => r !== 'noop'),
  date: supportedRollUps.filter((r) => r !== 'noop'),
  logic: ['noop'],
  other: supportedRollUps.filter((r) => r !== 'noop'),
}

const disallowedRollUpsForCategoryDimension: Record<
  DataTypeGroup,
  RollUpFunction[]
> = {
  numeric: ['first', 'last', 'noop'],
  text: supportedRollUps.filter((r) => r !== 'noop'),
  date: supportedRollUps.filter((r) => r !== 'noop'),
  logic: ['first', 'last', 'noop'],
  other: supportedRollUps.filter((r) => r !== 'noop'),
}

export function getDisallowedRollUps(
  dataType: MetricDataValueType,
  dimensionType: Exclude<DimensionType, 'Range'>,
): RollUpFunction[] {
  const rollupsMap =
    dimensionType === 'Time'
      ? disallowedRollUpsForTimeDimension
      : disallowedRollUpsForCategoryDimension
  return rollupsMap[getDataTypeGroup(dataType)] || []
}

export function getAllowedRollUps(
  dataType: MetricDataValueType,
  dimensionType: Exclude<DimensionType, 'Range'>,
): RollUpFunction[] {
  const disallowed = getDisallowedRollUps(dataType, dimensionType)
  const allowed = supportedRollUps.filter(
    (supported) => !disallowed.includes(supported),
  )
  return allowed.length === 0 ? ['noop'] : allowed
}
