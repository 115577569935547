import { styled, Box } from '@mui/material'

export const StyledBarRoot = styled(Box)(({ theme }) => ({
  position: 'relative',

  width: '100%',
  height: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledBarNav = styled(Box)(({ theme }) => ({
  position: 'relative',

  width: '100%',
}))

export const StyledBarFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingBottom: theme.spacingFromPixels(28),

  width: '100%',
}))

export const StyledBarUserPanel = styled(Box)(({ theme }) => ({
  marginTop: theme.spacingFromPixels(21),
}))
