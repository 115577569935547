import { max, min, slice } from 'lodash'
import { CalendarDatePickerConfig } from '@fintastic/web/util/period-selector'
import { AggregationTypeOption } from '../components/types'
import { DimensionId } from '@fintastic/web/util/dimensions'

export const isTheHighestGranularAggregation = (
  aggregationTypesPlain: DimensionId[],
  aggregation: DimensionId,
): boolean => {
  if (!aggregationTypesPlain.length) {
    return false
  }
  return (
    aggregationTypesPlain.indexOf(aggregation) ===
    aggregationTypesPlain.length - 1
  )
}

export const isTheLowestGranularAggregation = (
  aggregationTypesPlain: DimensionId[],
  aggregation: DimensionId,
): boolean => {
  if (!aggregationTypesPlain.length) {
    return false
  }
  return aggregationTypesPlain.indexOf(aggregation) === 0
}

export const isMoreGranularAggregation = (
  aggregationTypesPlain: DimensionId[],
  resolutionA: DimensionId,
  resolutionB: DimensionId,
): boolean => {
  const indexA = aggregationTypesPlain.indexOf(resolutionA)
  const indexB = aggregationTypesPlain.indexOf(resolutionB)

  return indexA < indexB
}

export const getAggregationTypesDiffList = (
  aggregationTypesPlain: DimensionId[],
  resolutionA: DimensionId,
  resolutionB: DimensionId,
): DimensionId[] => {
  const indexA = aggregationTypesPlain.indexOf(resolutionA)
  const indexB = aggregationTypesPlain.indexOf(resolutionB)

  const start = min([indexA, indexB])
  const end = max([indexA, indexB])

  if (typeof start !== 'number' || typeof end !== 'number') {
    throw new Error('Could not find resolution list!')
  }

  const diff = slice(aggregationTypesPlain, start, end)
  return diff.filter((i) => i !== resolutionA && i !== resolutionB)
}

export const getAggregationTypes = (
  datePickerConfig: CalendarDatePickerConfig,
): AggregationTypeOption[] =>
  datePickerConfig.map<AggregationTypeOption>((i) => ({
    dimension_id: i.dimension_id,
    label: i.label,
    resolution: i.resolution,
  }))

export const getPlainAggregationTypes = (
  aggregationTypes: AggregationTypeOption[],
) => aggregationTypes.map((i) => i.dimension_id)

export const cleanDimensionLabel = (label: string): string =>
  label.replaceAll('_', ' ')
