import React from 'react'
import { MetricDisplaySettingsFormatting } from '@fintastic/web/util/metrics-and-lists'
import { useFlagsContext } from '../../flags-context'
import { InlineSelectWithLabel } from '@fintastic/shared/ui/components'
import { compactFormattingOptions } from './options/compact-formatting-options'
import { Maybe } from '@fintastic/shared/util/types'

type Value = Maybe<
  Exclude<MetricDisplaySettingsFormatting['compact_formatting'], 'no_format'>
>

export type CompactFormattingFieldProps = {
  value: Value
  onChange: (newValue: Value) => void
}

export const CompactFormattingField: React.FC<CompactFormattingFieldProps> = ({
  value,
  onChange,
}) => {
  const { readonly } = useFlagsContext()

  return (
    <InlineSelectWithLabel<Value>
      label="Compact formatting"
      value={value}
      onChange={onChange}
      disabled={readonly}
      options={compactFormattingOptions}
    />
  )
}
