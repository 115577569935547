import { MetricChartDimensionsList } from '@fintastic/web/feature/charts'
import React from 'react'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { StyledDataTypeRoot, StyledDimensionsRoot } from './DataSection.styled'
import { ChartSettingsSection } from '../shared'

export const DataSection: React.FC = () => {
  const { loading, metricId, initialChartSettings, versions } =
    useChartSettingsContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartSettingsSection>
      <StyledDataTypeRoot data-testid="chart-data-type-root">
        <StyledDimensionsRoot>
          <MetricChartDimensionsList
            metricId={metricId}
            loading={loading}
            initialSettings={initialChartSettings || null}
            chartSettings={{ dimensions: localSettings.dimensions }}
            onChangeChartSettings={handleSettingsChange}
            version={versions[0]}
          />
        </StyledDimensionsRoot>
      </StyledDataTypeRoot>
    </ChartSettingsSection>
  )
}
