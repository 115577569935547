import { Box, styled } from '@mui/material'
import { defaultPillValueStyle } from '../common-styles'

export const StyledDetailsCardDimensions = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const StyledDimensionName = styled(Box)`
  display: inline-flex;
  flex-flow: row nowrap;
  height: auto;
  line-height: 1;
  padding: 4px 0;
  margin: 0 ${({ theme }) => theme.spacing(1)} 0 0;
`

export const StyledDetailsDimensionValue = styled(Box)`
  ${defaultPillValueStyle};
  color: ${({ theme }) => theme.palette.info.main};
`
