import React, { useCallback, useMemo } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import type { Maybe } from '@fintastic/shared/util/types'

export type ShowMaskToggleProps = {
  value: Maybe<boolean>
  onChange: (newValue: string) => void
}
export const ShowMaskToggle: React.FC<ShowMaskToggleProps> = ({
  onChange,
  value,
}) => {
  let status = ''
  if (typeof value === 'boolean') {
    status = value ? 'allow' : 'deny'
  } else {
    status = 'mixed'
  }

  const handleChange = useCallback(
    (event: unknown, newValue: string) => {
      onChange(newValue)
    },
    [onChange],
  )

  const style = useMemo(
    () => ({
      margin: '4px',
      ...(status === 'mixed' && {
        background: 'repeating-linear-gradient(45deg, #bebebe, #dedede 10px)',
      }),
    }),

    [status],
  )

  return (
    <ToggleButtonGroup
      color="primary"
      value={status}
      onChange={handleChange}
      exclusive
      size="small"
      sx={style}
    >
      <ToggleButton color="secondary" value="allow">
        Show
      </ToggleButton>
      <ToggleButton value="deny">Mask</ToggleButton>
    </ToggleButtonGroup>
  )
}
