import { VersionUserLockParsed } from '@fintastic/web/util/versions'
import { useMemo } from 'react'

/** @deprecated Use `versionUserLock` directly */
export const useVersionLockFlags = (versionUserLock: VersionUserLockParsed) =>
  useMemo(() => {
    const isLockedForCurrentUser =
      versionUserLock.lockReasons && versionUserLock.lockedBy !== 'me'
    const isLockedForCalculation = versionUserLock.lockReasons?.calc

    return { isLockedForCurrentUser, isLockedForCalculation }
  }, [versionUserLock.lockReasons, versionUserLock.lockedBy])
