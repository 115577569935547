// @todo @ipomazkin-fin decide about the config placement
// eslint-disable-next-line @nx/enforce-module-boundaries
import auth0clients from '../../../../../../apps/web/src/tenant-config.json'
import wcmatch from 'wildcard-match'

export type TenantConfig = {
  auth0ClientId: string
  auth0OrgId?: string
  tenantLogo: string | null
}

const currentTenantOrigin = window?.location.hostname || ''

const tenantConfig = Object.entries(
  auth0clients as Record<string, TenantConfig>,
).find(([originKey]) => {
  const isMatch = wcmatch(originKey)

  return isMatch(currentTenantOrigin)
})?.[1]

export const getTenantConfig = (): TenantConfig => {
  if (!tenantConfig) {
    throw new Error('Could not find a tenant config for current origin')
  }

  return tenantConfig
}
