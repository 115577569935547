import { routes } from '@fintastic/web/feature/versions'
import { useLocation } from 'react-router-dom'
import { Maybe } from '@fintastic/shared/util/types'
import { useEffect, useMemo, useRef } from 'react'
import {
  idLooksLikeList,
  idLooksLikeMetric,
} from '@fintastic/web/util/metrics-and-lists'

const ENTITY_PARAM = 'entity'

export const makeLinkToEntity = (
  versionId: string,
  isLiveActuals: boolean,
  entityId: string,
) =>
  `${
    isLiveActuals ? routes.liveActualsPage() : routes.version(versionId)
  }?${ENTITY_PARAM}=${entityId}`

export const useLinkToEntity = (
  requestEntityOpen: Maybe<(entityId: string) => void>,
) => {
  const { search } = useLocation()
  const entityIdFromUrl = useMemo<Maybe<string>>(() => {
    const params = new URLSearchParams(search)
    if (!params.has(ENTITY_PARAM)) {
      return null
    }
    const uncheckedEntityId = params.get(ENTITY_PARAM) || ''
    if (
      idLooksLikeMetric(uncheckedEntityId) ||
      idLooksLikeList(uncheckedEntityId)
    ) {
      return uncheckedEntityId
    }
    return null
  }, [search])

  const openedInThisSession = useRef<Record<string, boolean>>({})

  useEffect(() => {
    if (
      !entityIdFromUrl ||
      openedInThisSession.current[entityIdFromUrl] ||
      !requestEntityOpen
    ) {
      return
    }
    requestEntityOpen(`${entityIdFromUrl}:existing`)
    openedInThisSession.current[entityIdFromUrl] = true
  }, [entityIdFromUrl, requestEntityOpen])
}
