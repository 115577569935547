import React, {
  forwardRef,
  MouseEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useEditable } from 'use-editable'
import { Maybe } from '@fintastic/shared/util/types'
import type { Token } from '../tokens/types'
import {
  EDITING_CLASS_NAME,
  READONLY_CLASS_NAME,
  StyledCopyButton,
  StyledFormulaInputRoot,
} from './FormulaInput.styles'
import { TokenChip } from '../tokens/TokenChip'
import { Position } from 'use-editable/dist/types/useEditable'
import { ChangeCallback } from '../editing/types'
import { CaretMoveCallback, KeyEventInterceptor } from './types'
import { useKeyboardEventHandlers } from './useKeyboardEventHandlers'
import { useCaret } from './useCaret'
import { IndentationApi } from '../indentation/useIndentation'
import { CopyIcon } from '@fintastic/shared/ui/icons'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { Tooltip } from '@mui/material'

export type FormulaInputApi = {
  focus: () => void
  getTokensNodes: () => HTMLElement[]
  moveCaret: (position: number) => void
  simulateFormulaChange: (newFormula: string) => void
}

export type FormulaInputProps = {
  value: Token[]
  onChange: ChangeCallback
  readonly?: boolean
  onCaretMove: CaretMoveCallback
  keyDownEventInterceptor?: KeyEventInterceptor
  indentationApi: IndentationApi
}

// @todo add tests
export const FormulaInput = forwardRef<FormulaInputApi, FormulaInputProps>(
  (
    {
      value,
      onChange,
      readonly = true,
      onCaretMove,
      keyDownEventInterceptor,
      indentationApi,
    },
    ref,
  ) => {
    const inputRef = useRef<Maybe<HTMLDivElement>>(null)
    const containerRef = useRef<Maybe<HTMLDivElement>>(null)

    const handleChangeCallback = useCallback(
      (text: string, position: Position) => {
        onChange(text)
        onCaretMove(position.position, 'editing')
      },
      [onCaretMove, onChange],
    )

    const editableApi = useEditable(inputRef, handleChangeCallback, {
      disabled: readonly,
    })

    const { moveToTheEnd, trackCaretPosition, moveTo } = useCaret({
      inputValue: value,
      editableApi,
      onCaretMove,
    })

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (!inputRef.current) {
          return
        }
        inputRef.current.focus()
        inputRef.current.scrollTop = inputRef.current.scrollHeight
        moveToTheEnd()
      },
      getTokensNodes: () => {
        if (!inputRef.current) {
          return []
        }
        return [].slice.call(
          inputRef.current.children as unknown as HTMLElement[],
        )
      },
      moveCaret: (position: number) => {
        moveTo(position)
      },
      simulateFormulaChange: (newFormula: string) => {
        onChange(newFormula)
        onCaretMove(editableApi.getState().position.position, 'other')
      },
    }))

    useEffect(() => {
      const selection = window.getSelection()
      if (!selection?.rangeCount || selection.focusNode === document) {
        moveToTheEnd()
      }
    }, [moveToTheEnd, value])

    const { handleKeyUpEvent, handleKeyDownEvent, handleNativeKeyDownEvent } =
      useKeyboardEventHandlers({
        trackCaretPosition,
        keyDownEventInterceptor,
        indentationApi,
      })

    const handleNativeKeyDownEventRef = useRef(handleNativeKeyDownEvent)

    useEffect(() => {
      if (!inputRef.current) {
        return
      }
      const element = inputRef.current

      const keyDownHandler = (event: KeyboardEvent) => {
        handleNativeKeyDownEventRef.current(event)
      }

      element.addEventListener('keydown', keyDownHandler)

      return () => {
        element.removeEventListener('keydown', keyDownHandler)
      }
    }, [])

    useEffect(() => {
      if (!containerRef.current) {
        return
      }

      const container = containerRef.current

      const scrollableObserver = new ResizeObserver(function () {
        const hasScroll =
          (inputRef.current?.scrollHeight ?? 0) >
          (inputRef.current?.clientHeight ?? 0)
        container.classList.toggle('scrollable', hasScroll)
      })

      scrollableObserver.observe(container)

      return () => {
        if (container && scrollableObserver) {
          scrollableObserver.unobserve(container)
        }
      }
    }, [])

    const handleMouseEvent: MouseEventHandler<HTMLDivElement> =
      useCallback(() => {
        trackCaretPosition('navigation')
      }, [trackCaretPosition])

    const handleCopyClick = useCallback(() => {
      const text = inputRef.current?.innerText
      if (!text) {
        return
      }

      navigator.clipboard.writeText(text).then(
        () => {
          toast.success('Formula copied')
        },
        (err) => {
          toast.error('Could not copy text. Something went wrong')
          console.error('Async: Could not copy text: ', err)
        },
      )
    }, [])

    return (
      <StyledFormulaInputRoot
        data-testid="formula-editor-input"
        ref={containerRef}
      >
        <div
          className={`FormulaInput-root ${
            readonly ? READONLY_CLASS_NAME : EDITING_CLASS_NAME
          }`}
          ref={inputRef}
          spellCheck={false}
          onKeyUp={handleKeyUpEvent}
          onKeyDown={handleKeyDownEvent}
          onClick={handleMouseEvent}
          data-testid="formula-editor-input-tokens-container"
        >
          {value.map((token, i) => (
            <TokenChip
              noPaddings={token.text === ' '}
              key={`${i}-${token.text}`}
              {...token}
            />
          ))}
          {value.length === 0 && (
            <TokenChip key="__cursor_keeper__" type="primitive" text={' '} />
          )}
        </div>
        <div className={'formula-copy'}>
          <Tooltip title={'Copy formula'}>
            <StyledCopyButton
              disableRipple
              size="small"
              onClick={handleCopyClick}
            >
              <CopyIcon />
            </StyledCopyButton>
          </Tooltip>
        </div>
      </StyledFormulaInputRoot>
    )
  },
)
