import { DateRange } from '@fintastic/shared/util/types'
import { rangeToEdges } from './date-utils'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from './const'

// @deprecated in favor of new calendar
export const buildPeriodListFromRange = (dateRange: DateRange): string[] => {
  const edgedRange = rangeToEdges(dateRange)

  const start = dayjs(edgedRange.dateRangeFrom, DEFAULT_DAYJS_DATE_FORMAT)
  const end = dayjs(edgedRange.dateRangeTo, DEFAULT_DAYJS_DATE_FORMAT)

  if (dateRange.timeSegmentationFrame === 'month') {
    const numberOfMonths = Math.floor(Math.abs(end.diff(start, 'month', true))) + 1

    return new Array(numberOfMonths)
      .fill(null)
      .map((_, i) => start.add(i, 'month').format('MMM YYYY'))
  }

  if (dateRange.timeSegmentationFrame === 'quarter') {
    const numberOfQuarters = Math.abs(end.diff(start, 'quarter'))

    return new Array(numberOfQuarters + 1).fill(null).map((_, i) => {
      const t = start.add(i, 'quarter')
      return `Q${t.quarter()} ${t.year()}`
    })
  }

  if (dateRange.timeSegmentationFrame === 'half_year') {
    const numberOfMonths = Math.abs(end.diff(start, 'months'))

    if (!numberOfMonths) {
      return []
    }

    const startingFromTheFirstArg = start.month() === 0 ? -1 : 1
    let year = start.year()

    return new Array(Math.ceil(numberOfMonths / 6)).fill(null).map((_, i) => {
      const half = i % 2 ? 1 : -1
      const adjustedHalf = half * startingFromTheFirstArg
      const firstHalf = adjustedHalf === 1 ? 1 : 0
      const result = `H${firstHalf ? 1 : 2} ${year}`

      if (!firstHalf) {
        year += 1
      }

      return result
    })
  }

  if (dateRange.timeSegmentationFrame === 'year') {
    const numberOfYears = Math.abs(end.diff(start, 'year'))

    return new Array(numberOfYears + 1)
      .fill(null)
      .map((_, i) => start.add(i, 'year').format('YYYY'))
  }

  throw new Error('Unsupported time segmentation applied!')
}
