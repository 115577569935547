import React, { useMemo } from 'react'
import { HistoryLogEntryChangeColumnValue } from '@fintastic/web/util/history'
import {
  DimensionId,
  DimensionLabel,
  DimensionValueLabel,
  VersionDimension,
} from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { Box } from '@mui/material'
import {
  CardColumnSection,
  CardDimensionsSection,
  CardHeader,
  CardValueSection,
} from '../shared-card-elements'
import { WidgetListIcon } from '@fintastic/shared/ui/icons'
import { resolveMetricOrListLabelFromId } from '../../tmp-utilities'
import { OldToNewValueTransition } from '../old-and-new-values'

export const ChangeColumnValueDetailsCard: React.FC<{
  entry: HistoryLogEntryChangeColumnValue
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entry, dimensions }) => {
  const dimensionsToDisplay = useMemo<
    Maybe<
      Array<{
        dimensionLabel: DimensionLabel
        dimensionValueLabel: DimensionValueLabel
      }>
    >
  >(() => {
    if (entry.dim_ids.length === 0) {
      return null
    }

    // skip first dimension because it's always a row dim
    return entry.dim_ids.slice(1).map((dimId, dimIndex) => {
      const dimValueId = entry.dim_value_ids.slice(1)[dimIndex]

      const dimension = dimensions[dimId]

      if (!dimension) {
        return {
          dimensionLabel: dimId,
          dimensionValueLabel: dimValueId,
        }
      }

      if (dimension.type !== 'Category' && dimension.type !== 'Time') {
        return {
          dimensionLabel: dimension.label,
          dimensionValueLabel: dimValueId,
        }
      }

      return {
        dimensionLabel: dimension.label,
        dimensionValueLabel: dimension.values[dimValueId] || dimValueId,
      }
    })
  }, [dimensions, entry.dim_ids, entry.dim_value_ids])

  return (
    <Box>
      <CardHeader
        icon={
          <WidgetListIcon
            sx={{
              color: '#047D34',
            }}
          />
        }
        title={resolveMetricOrListLabelFromId(entry.entity_id)}
      />
      <CardValueSection>
        <OldToNewValueTransition entry={entry} dimensions={dimensions} />
      </CardValueSection>
      <CardColumnSection
        columnLabel={resolveMetricOrListLabelFromId(entry.target_entity_id)}
      />
      {dimensionsToDisplay && dimensionsToDisplay.length > 0 && (
        <CardDimensionsSection dimensions={dimensionsToDisplay} />
      )}
    </Box>
  )
}
