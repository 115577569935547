import { Box, Collapse } from '@mui/material'
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import {
  CollapsibleContainerFooter,
  CollapsibleContainerStyled,
  CollapsiblePanelHeaderContainerStyled,
  StyledArrowIcon,
  StyledCounterText,
  StyledCloseText,
  StyledOpenText,
} from './CollapsibleContainer.styled'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'

export type CollapsibleContainerProps = {
  title?: string
  openTitleText?: string
  closeTitleText?: string
  closeHighlightedText?: string
  counter?: number
  initialOpen?: boolean
  actions?: ReactNode
} & PropsWithChildren

export const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  initialOpen,
  openTitleText,
  closeTitleText,
  counter,
  children,
  actions,
  ...props
}) => {
  const [open, setOpen] = useState(initialOpen ?? true)

  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  return (
    <CollapsibleContainerStyled {...props}>
      <CollapsiblePanelHeaderContainerStyled onClick={handleToggleOpen}>
        <StyledOpenText open={open}>
          {openTitleText || 'Show'}

          {!!counter && counter > 0 && (
            <StyledCounterText>({counter})</StyledCounterText>
          )}
        </StyledOpenText>

        <StyledCloseText open={open}>
          {closeTitleText || 'Hide'}

          {!!counter && counter > 0 && (
            <StyledCounterText>({counter})</StyledCounterText>
          )}
        </StyledCloseText>

        <StyledArrowIcon open={open}>
          <KeyboardArrowUpOutlinedIcon />
        </StyledArrowIcon>
      </CollapsiblePanelHeaderContainerStyled>

      <Collapse in={open}>
        <Box>{children}</Box>
        {actions && (
          <CollapsibleContainerFooter>{actions}</CollapsibleContainerFooter>
        )}
      </Collapse>
    </CollapsibleContainerStyled>
  )
}
