import { GridApi, RowNode } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'

export const getAgGridRootNode = (api: GridApi): Maybe<RowNode> => {
  const firstNode = api.getRenderedNodes()[0]

  if (!firstNode) {
    return null
  }

  let rootNode: RowNode | null = firstNode

  while (rootNode && rootNode.uiLevel !== -1) {
    if (!rootNode.parent) {
      break
    }
    rootNode = rootNode.parent
  }

  return rootNode
}
