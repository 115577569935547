import React, { useMemo } from 'react'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'
import { Skeleton } from '@mui/material'
import { Tags, Tag } from '@fintastic/shared/ui/components'
import { LabelTagsProps } from './types'

export const LabelTagsContainer: React.FC<LabelTagsProps> = ({
  context,
  activeLabels,
  limit,
  singleline,
  onDelete,
}) => {
  const labelsQuery = useLabelsListQuery(context)

  const tags = useMemo<Tag[]>(() => {
    if (!labelsQuery.data?.length) {
      return []
    }

    return labelsQuery.data.filter((i) => activeLabels.includes(i.id))
  }, [activeLabels, labelsQuery.data])

  if (labelsQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Tags
      tags={tags}
      limit={limit}
      singleline={singleline}
      onDelete={onDelete}
    />
  )
}
