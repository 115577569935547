import { useCallback } from 'react'

// @todo add tests
export function useDiscardChanges(
  exitEditingMode: () => void,
  resetEditingState: () => void,
  isDirty: boolean,
  onChangesDiscarded?: () => void,
) {
  return useCallback(() => {
    exitEditingMode()
    resetEditingState()
    if (onChangesDiscarded && isDirty) {
      onChangesDiscarded()
    }
  }, [isDirty, exitEditingMode, onChangesDiscarded, resetEditingState])
}
