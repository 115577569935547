import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type ConfirmPartialSaveModalProps = {
  open: boolean
  invalidRecordsCount: number
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmPartialSaveModal: React.FC<
  ConfirmPartialSaveModalProps
> = ({ open, invalidRecordsCount, onConfirm, onCancel }) => (
  <ConfirmationPopup
    open={open}
    onRequestClose={onCancel}
    onConfirm={onConfirm}
    onDecline={onCancel}
    title={'Save Lines?'}
    autoClose={false}
  >
    <Box width="100%">
      <ErrorAlert
        title={`Data of invalid lines (${invalidRecordsCount}) will be lost`}
        message="It won’t be possible to retrieve the current data"
        severity="warning"
      />
      <Box mt={3} width="100%" display="flex" justifyContent="center">
        <ConfirmationPopup.DeclineButton>
          Continue editing lines
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton
          sx={{ ml: 1 }}
          variant="contained"
          color="primary"
        >
          Save lines
        </ConfirmationPopup.ConfirmButton>
      </Box>
    </Box>
  </ConfirmationPopup>
)
