import {
  CalendarDatePickerConfig,
  convertRangeSelectionToPeriods,
  getDefaultPeriodSelection,
  isEmptyPeriodSelection,
  isRangeBasedSelection,
  mostGranularDimensionInConfig,
  PeriodSelection,
  usePeriodSelectorContext,
} from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { getAggregationTypes } from '../period-selector-container/utils'
import { newPeriodsRangeGetterFabric } from '../period-selector-container/utils/new-period-range-getter'

export const useParentSelection = (
  datePickerConfigForSelectedEntities: Maybe<CalendarDatePickerConfig>,
  fullDatePickerConfig: Maybe<CalendarDatePickerConfig>,
  baseTimeDimensionId?: Maybe<TimeDimensionId>,
): Maybe<PeriodSelection> => {
  const parentSelection = usePeriodSelectorContext()

  return useMemo(() => {
    if (
      isEmptyPeriodSelection(parentSelection) ||
      !datePickerConfigForSelectedEntities ||
      !fullDatePickerConfig
    ) {
      return null
    }

    const hasTimeDimension = datePickerConfigForSelectedEntities.some(
      (c) => c.dimension_id === parentSelection.dimensionId,
    )

    // Convert to the least granular time dim, if parent selection granularity is not available
    if (!hasTimeDimension) {
      const locallyAvailableDimensionId = mostGranularDimensionInConfig(
        datePickerConfigForSelectedEntities,
      )?.dimension_id
      const getNewPeriodsRange = newPeriodsRangeGetterFabric(
        fullDatePickerConfig,
        getAggregationTypes(fullDatePickerConfig).map((i) => i.dimension_id),
      )

      if (!locallyAvailableDimensionId) {
        return getDefaultPeriodSelection(
          datePickerConfigForSelectedEntities,
          baseTimeDimensionId ?? undefined,
        )
      }

      const nextRangeSelection = {
        range: getNewPeriodsRange(locallyAvailableDimensionId, parentSelection),
        dimensionId: locallyAvailableDimensionId,
        aggregationDimensionId: parentSelection.aggregationDimensionId,
      }

      if (isRangeBasedSelection(parentSelection)) {
        return nextRangeSelection
      }

      return {
        periods: convertRangeSelectionToPeriods(
          nextRangeSelection,
          datePickerConfigForSelectedEntities,
        ),
        dimensionId: locallyAvailableDimensionId,
        aggregationDimensionId: parentSelection.aggregationDimensionId,
      }
    }

    return parentSelection
  }, [
    baseTimeDimensionId,
    datePickerConfigForSelectedEntities,
    fullDatePickerConfig,
    parentSelection,
  ])
}
