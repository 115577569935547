import { HandleMetricUpdateCallbackParams } from '@fintastic/web/feature/metrics-and-lists'
import { MutableEditListColumnsDataIntentWrapper } from '@fintastic/web/util/metric-data-editing'

type VersionId = string

type VersionsToIntentsMap = Record<
  VersionId,
  MutableEditListColumnsDataIntentWrapper
>

export const changesToIntents = (
  changes: HandleMetricUpdateCallbackParams[],
): VersionsToIntentsMap => {
  const intentsPerVersion: VersionsToIntentsMap = {}

  for (let i = 0; i < changes.length; i++) {
    const changesForVersion = changes[i]
    if (intentsPerVersion[changesForVersion.versionId] === undefined) {
      intentsPerVersion[changesForVersion.versionId] =
        MutableEditListColumnsDataIntentWrapper.fromConstructor()
    }
    const intent = intentsPerVersion[changesForVersion.versionId]

    for (let j = 0; j < changesForVersion.changes.length; j++) {
      const change = changesForVersion.changes[j]
      intent.addChange(change.metricId, change.dimensions, change.value)
    }
  }

  return intentsPerVersion
}
