import { styled } from '@mui/material/styles'

export const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}))

export const StyledShareButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  marginTop: '4px',
  marginRight: '4px',
}))

export const StyledHistoryButton = styled('div')`
  cursor: pointer;
  margin-top: 4px;
  margin-right: 16px;
`
