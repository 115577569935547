import { Box, DialogContent, IconButton, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledHeader = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(1)};
  height: 32px;
`

export const StyledBackButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  margin-left: -${({ theme }) => theme.spacing(1)};
  opacity: 0;

  animation: appearBackButton 0.2s ease-in-out forwards;

  transform: translateX(10px);

  @keyframes appearBackButton {
    0% {
      opacity: 0;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const StyledTitle = styled('div')`
  display: flex;
  flex-flow: column wrap;
  padding-right: ${({ theme }) => theme.spacing(1)};
`

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const StyledSectionedModalContentRoot = styled(DialogContent)`
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: 0;

  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
`

export const StyledContentWrapper = styled('div')`
  &.no-top-padding {
    .MuiDialogContent-root {
      padding-top: 0;
    }
  }
`

export const StyledContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'animation',
})<{ animation: boolean }>`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  padding: ${({ theme }) => theme.spacing(1, 2)};
  padding-bottom: 0;
  width: 100%;
  height: 100%;
  ${styledMixins.thinVScrollbar()};

  opacity: ${({ animation }) => (animation ? 0 : 1)};

  animation: ${({ animation }) =>
    animation ? 'appearSection 0.2s ease-in-out forwards' : 'none'};

  transform: ${({ animation }) => (animation ? 'translateX(10px)' : 'none')};

  @keyframes appearSection {
    0% {
      opacity: 0;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const StyledFooter = styled(Box)`
  padding: 0 16px 8px;
  width: 100%;
`
