import { styled, Box } from '@mui/material'
import { layoutConfig } from '../../layout-config'

export const StyledPageLayoutRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'auto',

  width: '100%',
  height: '100%',
}))

export const StyledPageLayoutContainer = styled(Box)(({ theme }) => ({
  paddingTop: layoutConfig.page.paddingTop,
  paddingLeft: layoutConfig.page.paddingLeft,
  paddingRight: layoutConfig.page.paddingRight,
  paddingBottom: layoutConfig.page.paddingBottom,

  width: '100%',
  height: 'auto',
}))
