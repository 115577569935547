import React, { useCallback, useEffect, useState } from 'react'
import {
  useCurrentDiscussionObjectId,
  useLayoutStateIsDiscussionsOpened,
} from '../../../ducks/layout/hooks'
import { triggerWindowResizeSmoothly } from '@fintastic/shared/util/web-api'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'

const CommentsSidebarContainer = React.lazy(
  () => import('@fintastic/web/feature/comments'),
)

export const Discussions: React.FC = () => {
  const [isOpen, setIsOpen] = useLayoutStateIsDiscussionsOpened()
  const [openAtLeastOnce, setOpenAtLeastOnce] = useState(isOpen)

  const [currentDiscussionObjectId, setCurrentDiscussionObjectId] =
    useCurrentDiscussionObjectId()

  useEffect(() => triggerWindowResizeSmoothly(300, 10), [isOpen])

  useEffect(() => {
    if (isOpen) {
      setOpenAtLeastOnce(true)
    }
  }, [isOpen])

  const handleCloseSidebar = useCallback(() => {
    setIsOpen(false)
    setCurrentDiscussionObjectId(null)
  }, [setCurrentDiscussionObjectId, setIsOpen])

  if (!isOpen) {
    return null
  }

  return (
    <React.Suspense fallback={<CenteredCircularProgress />}>
      {openAtLeastOnce && (
        <CommentsSidebarContainer
          currentDiscussionObjectId={currentDiscussionObjectId}
          setCurrentDiscussionObjectId={setCurrentDiscussionObjectId}
          onClose={handleCloseSidebar}
        />
      )}
    </React.Suspense>
  )
}
