import { axios } from '@fintastic/web/data-access/service-axios'

export function importActualsToVersion(
  id: string,
  periodStart: string,
  periodEnd: string,
) {
  return axios.patch(
    `/planning/api/v2/versions/${id}/models/data/from_actuals/`,
    {
      period_start: periodStart,
      period_end: periodEnd,
    },
  )
}
