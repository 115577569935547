import { Alert, alertClasses, AlertProps, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Alert)<
  AlertProps & { displayVariant: 'warning' | 'neutral' }
>(
  ({ displayVariant }) => `
  ${styledMixins.alignContentCenter()};
  align-items: flex-start;
  padding: 8px 16px;


  .${alertClasses.icon} {
    ${styledMixins.alignContentCenter()};
    padding: 0;
    margin-right: 8px;
    margin-left: 0;

    svg {
      width: 24px;
      height: auto;
    }
  }

  .${alertClasses.message} {
    ${styledMixins.alignContentCenter()};
    padding: 0;
    margin: 0;

    min-height: 24px;
  }

  ${
    displayVariant === 'neutral'
      ? `
        color: #304c5b;
        background: #C1E4FB;
        `
      : `
        color: #634602;
        background: #FCD89B;
      `
  }
`,
)
StyledRoot.displayName = 'StyledRoot'
