import type { ICellRendererParams } from 'ag-grid-community'
import React, { useCallback, useRef } from 'react'
import { StyledVersionSourceCol } from '../Version.styled'
import type { Maybe } from '@fintastic/shared/util/types'
import { VersionTable, VersionTableReport } from '../types'
import { useAttachEventListenerToNativeDom } from '@fintastic/shared/util/ag-grid'
import { useModelExplorerApi } from '@fintastic/web/feature/model-explorer'
import { IconButton } from '@mui/material'
import { ModelExplorerIcon } from '@fintastic/shared/ui/icons'

export type TableActionsCellRendererProps = ICellRendererParams<
  Exclude<VersionTable, VersionTableReport>
> & {
  versionId: string
}

export const TableActionsCellRenderer = ({
  versionId,
  data,
}: TableActionsCellRendererProps) => {
  const entityId: Maybe<string> = data?.id || null

  const modelExplorerApi = useModelExplorerApi()
  const openExplorerCallbackDeps = useRef({
    modelExplorerApi,
    versionId,
    entityId,
  })
  openExplorerCallbackDeps.current = {
    modelExplorerApi,
    versionId,
    entityId,
  }

  const explorerButtonRef = useAttachEventListenerToNativeDom(
    'click',
    useCallback(() => {
      const { modelExplorerApi, entityId, versionId } =
        openExplorerCallbackDeps.current
      if (modelExplorerApi && !modelExplorerApi.isOpen && entityId !== null) {
        modelExplorerApi.open(versionId, entityId)
      }
    }, []),
    true,
  )

  return (
    <StyledVersionSourceCol sx={{ justifyContent: 'center' }}>
      <IconButton sx={{ ml: '-4px' }} ref={explorerButtonRef}>
        <ModelExplorerIcon />
      </IconButton>
    </StyledVersionSourceCol>
  )
}
