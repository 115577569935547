import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { unlockVersion } from '../../api/versions-management-api'
import { invalidateVersionManagementRelatedQueries } from '../../cache'

export function useUnlockVersion(versionId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.unlockVersion(versionId),
    async () => {
      const response = await unlockVersion(versionId)
      return response.data
    },
    {
      onSuccess: async () => {
        await invalidateVersionManagementRelatedQueries(queryClient, versionId)
      },
    },
  )
}
