import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { CalendarDatePickerConfigItem } from '../types'
import { compact, last, uniq } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'
import { sortTimeDimensionByResolution } from './sort-time-dimensions-by-resolution'

/**
 * Finds the highest resolution for time dimension IDs provided
 * The highest from the base is a common time dimension for every item
 */
export const getCommomBaseTimeDimension = (
  baseTimeDimensions: TimeDimensionId[],
  calendarConfig: Array<
    Pick<CalendarDatePickerConfigItem, 'resolution' | 'dimension_id'>
  >,
): Maybe<TimeDimensionId> => {
  if (!baseTimeDimensions.length || !calendarConfig.length) {
    return null
  }

  try {
    const sortedTimeDimensions = sortTimeDimensionByResolution(
      uniq(compact(baseTimeDimensions)),
      calendarConfig,
    )
    return last(sortedTimeDimensions) ?? null
  } catch (ex) {
    return null
  }
}
