import { useMutation, useQueryClient } from 'react-query'
import { fetchPolicy, putPolicy } from '../api'
import { GroupId } from '../types'

export const useCopyGovernancePolicyMutation = () => {
  const queryClient = useQueryClient()

  const m = useMutation(
    ['permissions', 'governancePolicy'],
    async ({
      groupCopyFrom,
      targetPolicyId,
    }: {
      groupCopyFrom: GroupId
      targetPolicyId: GroupId
    }) => {
      const [policy, targetPolicy] = await Promise.all([
        fetchPolicy(groupCopyFrom),
        fetchPolicy(targetPolicyId),
      ])

      if (!policy) {
        throw new Error(`Could not find policy to copy from: ${groupCopyFrom}`)
      }

      if (!targetPolicy) {
        throw new Error(`Could not find policy to copy into: ${targetPolicyId}`)
      }

      return putPolicy(
        {
          ...policy,
          group_id: targetPolicyId,
        },
        targetPolicy.hash ?? '',
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['restrictedColumns'])
      },
    },
  )

  return m
}
