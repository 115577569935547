import { styled } from '@mui/material/styles'
import type { ICellRendererParams } from 'ag-grid-community'
import { Box } from '@mui/material'
import { FormulaButton, FormulaIcon } from '@fintastic/web/feature/formulas'

const tableSourceStaticCellRendererFactory = (type: FormulaIcon) =>
  function ({
    value,
    valueFormatted,
    iconOnly,
  }: ICellRendererParams<any> & TableSourceStaticCellRendererProps) {
    if (value === 'raw') {
      return <StyledVersionSourceCol>Raw</StyledVersionSourceCol>
    }

    if (value === 'input') {
      return <StyledVersionSourceCol>User input</StyledVersionSourceCol>
    }

    if (value === 'calculated') {
      return (
        <StyledVersionSourceCol>
          <FormulaButton icon={type} sx={{ ml: '-4px' }} iconOnly={iconOnly} />
        </StyledVersionSourceCol>
      )
    }

    return (
      <StyledVersionSourceCol>{valueFormatted ?? value}</StyledVersionSourceCol>
    )
  }

export const TableSourceStaticCellRendererFx =
  tableSourceStaticCellRendererFactory('fx')
export const TableSourceStaticCellRendererAx =
  tableSourceStaticCellRendererFactory('ax')

export const StyledVersionSourceCol = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))

export type TableSourceStaticCellRendererProps = {
  iconOnly?: boolean
}
