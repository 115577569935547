import {
  CompactMetricData,
  MetricDataValue,
} from '@fintastic/web/util/metrics-and-lists'
import { DimensionTuple } from '@fintastic/web/util/dimensions'

export const iterateOverCompact = (
  { indexes, dimensions, values }: CompactMetricData,
  callback: (dimensions: DimensionTuple[], value: MetricDataValue) => void,
) => {
  for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
    let offset = 1
    const cellDimensions: DimensionTuple[] = []

    // iterate over indexes in reversed direction to simplify the calculation of the dim value position
    for (let dimIndex = indexes.length - 1; dimIndex >= 0; dimIndex--) {
      const dimValues = dimensions[dimIndex]
      const currentDimValue =
        dimValues[Math.floor(valueIndex / offset) % dimValues.length]

      // assign values to specific array indexes to preserve the original indexes' order
      cellDimensions[dimIndex] = [indexes[dimIndex], currentDimValue]
      offset = offset * dimValues.length
    }

    callback(cellDimensions, values[valueIndex])
  }
}
