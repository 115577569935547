import { Box, styled, Typography, TypographyProps } from '@mui/material'

export const StyledModalHeaderRoot = styled(Box)(({ theme }) => ({
  position: 'relative',

  width: '100%',
  minHeight: 24,

  padding: '24px 24px 0',
}))

export const StyledModalHeaderTitle = styled(
  (props: TypographyProps & { hasDescription?: boolean }) => {
    const { hasDescription, ...typographyProps } = props
    return <Typography {...typographyProps} />
  },
  { shouldForwardProp: (propName) => propName !== 'hasDescription' },
)(({ theme, hasDescription }) => ({
  paddingRight: 36,
  marginBottom: hasDescription ? 24 : undefined,

  fontSize: 18,
  lineHeight: 25 / 18,
  fontWeight: 400,
}))

export const StyledModalHeaderDescription = styled(
  (props: TypographyProps & { hasTitle?: boolean }) => {
    const { hasTitle, ...typographyProps } = props
    return <Typography {...typographyProps} />
  },
  { shouldForwardProp: (propName) => propName !== 'hasTitle' },
)(({ theme, hasTitle }) => ({
  paddingRight: hasTitle ? undefined : 36,

  fontSize: 14,
}))

export const StyledModalHeaderCloseButton = styled(Box)`
  position: absolute;
  top: 50%;
  right: 19px;

  svg {
    width: 16px;
    height: 16px;
  }
`
