import React, { useRef } from 'react'
import { Icon } from './Icon'
import {
  StyledIcon,
  StyledLabel,
  StyledList,
  StyledListItem,
  StyledListIcon,
  StyledListText,
} from './StatusPanel.styled'
import { useModalState } from '@fintastic/shared/util/modal'
import { Maybe } from '@fintastic/shared/util/types'
import { Typography, IconButton, Popover } from '@mui/material'

export type StatusPanelProps = {
  calculatingVersionNames: string[]
}

export const StatusPanel: React.FC<StatusPanelProps> = ({
  calculatingVersionNames,
}) => {
  const { isOpen, open, close } = useModalState()
  const buttonRef = useRef<Maybe<HTMLButtonElement>>(null)

  return (
    <>
      <IconButton
        ref={buttonRef}
        onMouseEnter={open}
        onMouseLeave={close}
        color="textBlack"
      >
        <StyledIcon
          sx={{
            color: (theme) =>
              calculatingVersionNames.length > 0
                ? theme.palette.primary.main
                : undefined,
          }}
        >
          <Icon inProgress={calculatingVersionNames.length > 0} />
        </StyledIcon>
      </IconButton>

      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          pointerEvents: 'none',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableRestoreFocus
        onClose={close}
      >
        <StyledLabel>
          <Typography variant="button">
            {calculatingVersionNames.length === 0
              ? 'No versions are currently being calculated'
              : calculatingVersionNames.length === 1
              ? '1 version is currently being calculated:'
              : `${calculatingVersionNames.length} versions are currently being calculated:`}
          </Typography>
        </StyledLabel>
        {calculatingVersionNames.length > 0 && (
          <StyledList>
            {calculatingVersionNames.map((item) => (
              <StyledListItem key={item}>
                <StyledListIcon>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.58896 4.47799C9.29137 3.97965 8.87013 3.50682 8.37288 3.12449C7.67223 2.58577 6.85191 2.25018 6.05753 2.25003C4.46722 2.25466 3.00816 3.27365 2.47808 4.75001C2.40797 4.94528 2.19037 5.04761 1.99205 4.97858C1.79373 4.90955 1.6898 4.69529 1.75991 4.50002C2.39739 2.72452 4.14339 1.5053 6.05593 1.5H6.057C7.06389 1.5 8.04481 1.92063 8.8417 2.53335C9.35627 2.92898 9.80714 3.41449 10.1485 3.94166C10.1825 3.77424 10.2116 3.59352 10.2417 3.37467C10.2698 3.16942 10.4617 3.02552 10.6701 3.05327C10.8786 3.08101 11.0247 3.26989 10.9965 3.47513C10.9066 4.12989 10.8192 4.49315 10.6058 5.09292C10.5463 5.26034 10.3757 5.36377 10.1971 5.34081C9.55707 5.25855 9.18824 5.17201 8.55394 4.95645C8.35511 4.88888 8.24955 4.67538 8.31818 4.4796C8.38681 4.28382 8.60363 4.17989 8.80247 4.24746C9.11346 4.35315 9.34911 4.42376 9.58896 4.47799Z"
                      fill="#FF7933"
                    />
                    <path
                      d="M6.06752 9.74996C4.59803 9.666 3.26188 8.82571 2.54294 7.58639C2.75919 7.65478 2.97607 7.7384 3.25448 7.85637C3.44769 7.93824 3.67173 7.85038 3.75488 7.66014C3.83803 7.46989 3.7488 7.2493 3.55558 7.16743C2.94319 6.90794 2.58257 6.79455 1.95808 6.66691C1.88854 6.6501 1.8134 6.65237 1.74083 6.67759C1.62667 6.71725 1.54374 6.80504 1.50726 6.90965C1.25809 7.47587 1.14495 7.83063 1.00832 8.46671C0.964796 8.66934 1.09634 8.86835 1.30214 8.9112C1.50793 8.95406 1.71005 8.82453 1.75357 8.6219C1.80582 8.37866 1.85322 8.18352 1.90692 8.00177C2.75995 9.43845 4.31851 10.4056 6.03585 10.4994C6.04286 10.4998 6.04987 10.5 6.05689 10.5C7.95979 10.5003 9.70277 9.29837 10.3529 7.53749C10.4248 7.34284 10.3228 7.12767 10.1251 7.05691C9.92742 6.98614 9.7089 7.08657 9.63703 7.28122C9.09753 8.7424 7.64597 9.7458 6.06752 9.74996Z"
                      fill="#FF7933"
                    />
                  </svg>
                </StyledListIcon>
                <StyledListText>
                  <Typography variant="body2" color="#6E665E">
                    {item}
                  </Typography>
                </StyledListText>
              </StyledListItem>
            ))}
          </StyledList>
        )}
      </Popover>
    </>
  )
}
