import React, { useCallback, useMemo, useState } from 'react'
import {
  MetricsView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'
import { Maybe } from '@fintastic/shared/util/types'
import { cloneDeep } from 'lodash'

// @todo RESP connect
const allMetrics = [
  {
    id: 'Cube.Rate_a',
    label: 'Rate A',
  },
  {
    id: 'Table.Headcount.Rate_b',
    label: 'Rate B',
  },
  {
    id: 'Cube.Rate_c',
    label: 'Rate C',
  },
  {
    id: 'Cube.Rate_d',
    label: 'Rate D',
  },
  {
    id: 'Cube.Rate_e',
    label: 'Rate E',
  },
  {
    id: 'Cube.Rate_f',
    label: 'Rate F',
  },
]

export const MetricsViewConnector: React.FC = () => {
  const { goBack, goTo } = useReportSettingsPanelNavigationContext()
  const goToMetric = useCallback(
    (id: string) => goTo(settingsPanelNavigationPaths.metricSettings(id)),
    [goTo],
  )
  const goToReportingGroups = useCallback(
    () => goTo(settingsPanelNavigationPaths.reportingGroups),
    [goTo],
  )

  const [metrics, setMetrics] = useState<
    {
      id: string
      label: string
      reportingGroupLabel: Maybe<string>
      error?: string
    }[]
  >([
    {
      id: 'Cube.Rate_a',
      label: 'Rate A',
      reportingGroupLabel: 'RG1',
    },
    {
      id: 'Table.Headcount.Rate_b',
      label: 'Rate B',
      reportingGroupLabel: null,
      error: 'Some error text',
    },
  ])

  const availableMetrics = useMemo(
    () => allMetrics.filter((m1) => !metrics.find((m2) => m1.id === m2.id)),
    [metrics],
  )

  const addMetric = useCallback(
    (metricId: string) => {
      const metric = availableMetrics.find((m) => m.id === metricId)
      if (!metric) {
        return
      }
      setMetrics((s) => [
        ...s,
        {
          ...metric,
          reportingGroupLabel: 'RG1',
        },
      ])
    },
    [availableMetrics],
  )

  const reorderMetrics = useCallback((metricIds: string[]) => {
    setMetrics((metrics) => {
      const newMetrics = cloneDeep(metrics)
      newMetrics.sort(
        (a, b) => metricIds.indexOf(a.id) - metricIds.indexOf(b.id),
      )
      return newMetrics
    })
  }, [])

  return (
    <MetricsView.Editable
      goToMetric={goToMetric}
      goBack={goBack}
      goToReportingGroups={goToReportingGroups}
      metrics={metrics}
      availableMetrics={availableMetrics}
      addMetric={addMetric}
      reorderMetrics={reorderMetrics}
    />
  )
}
