import { MappingDictionary } from './types'
import { getFintasticSyntaxApi } from '@fintastic/web/data-access/formulas'
import { applyTokensMapping } from './applyTokensMapping'

export function mapFormula(
  formula: string,
  dictionary: MappingDictionary,
): string {
  const syntaxApi = getFintasticSyntaxApi()
  if (!syntaxApi) {
    throw new Error('Syntax API is not available')
  }

  const tokens = syntaxApi.getDisplayTokens(formula, {})
  if (tokens.length === 0) {
    return formula
  }

  return applyTokensMapping(formula, tokens, dictionary)
}
