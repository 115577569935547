import { ToolPanelDef } from 'ag-grid-community'
import { resolveFintasticAgIconKey } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { Panel } from './Panel'

export const TOOL_PANEL_ID = 'reportSettings'

export const settingsToolPanelDefinition: ToolPanelDef = {
  id: TOOL_PANEL_ID,
  labelDefault: '',
  toolPanel: Panel,
  labelKey: '',
  iconKey: resolveFintasticAgIconKey('settings'),
  maxWidth: 320,
  minWidth: 320,
  width: 320,
}
