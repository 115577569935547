import { toast } from '@fintastic/shared/ui/toast-framework'

export type SavingFailedReason = 'calculationFailed' | 'clientSideError'

export function showSavingFailedToast(
  reason: SavingFailedReason = 'clientSideError',
) {
  if (reason === 'calculationFailed') {
    toast.error('Calculation failed, changes of formula were not saved')
    return
  }
  toast.error('Something went wrong, changes of formula were not saved')
}
