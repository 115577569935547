import React from 'react'
import { LabelsMenuProps } from './types'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import { LabelsMenuContainer } from './LabelsMenuContainer'
import { Box } from '@mui/material'

export const LabelsMenu: React.FC<LabelsMenuProps> = ({
  minWidth = 240,
  ...props
}) => (
  <ErrorBoundary>
    <Box minWidth={minWidth}>
      <LabelsMenuContainer {...props} />
    </Box>
  </ErrorBoundary>
)
