import { useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { BaseTimeDimensionStaticWidgetContext } from '../types'

/**
 * Applies single `baseTimeDimensionId` controlled by consumer
 */
export const useBaseTimeDimensionStaticWidgetAdapter = (
  initialValue?: Maybe<TimeDimensionId>,
): BaseTimeDimensionStaticWidgetContext => {
  const [baseTimeDimensionId, setBaseTimeDimensionId] = useState<
    Maybe<TimeDimensionId>
  >(initialValue || null)

  return useMemo(
    () => ({
      baseTimeDimensionId,
      setBaseTimeDimensionId,
      type: 'local',
    }),
    [baseTimeDimensionId],
  )
}
