import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

export const StyledDataTableContainer = styled(
  (props: { loading?: boolean } & BoxProps) => {
    const { loading, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'loading',
  },
)`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: #fff;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0 -4px 10px 0 #0000000d, 0 4px 10px 0 #0000000d;

  opacity: ${({ loading }) => (loading ? 0.8 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};

  transition: opacity 0.2s ease-in-out;
`
