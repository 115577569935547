import { styled, Box } from '@mui/material'
import { layoutConfig } from '../../layout-config'

export const TopbarTabsRoot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: layoutConfig.sidebar.bar.width,
  right: 0,

  display: 'flex',
  alignItems: 'end',
  justifyContent: 'flex-start',
  padding: `0 ${layoutConfig.page.paddingRight}px 0 ${layoutConfig.page.paddingLeft}px`,

  height: layoutConfig.topbar.height,

  background: theme.palette.common.white,
  border: '1px solid transparent',
  borderBottomColor: theme.palette.divider,
}))
