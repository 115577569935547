import { NameValidationResult } from './types'

export function resolveNameValidationErrorMessage(
  validationResult: NameValidationResult,
): string {
  if (!validationResult.filled) {
    return 'Name is required'
  }

  if (!validationResult.unique) {
    return 'This name is already in use'
  }

  return 'Invalid name'
}
