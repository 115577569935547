import React from 'react'
import { DataExportParams } from '../types'
import { ExportWithCommentsData } from './ExportWithCommentsData'
import { Maybe } from '@fintastic/shared/util/types'
import { ExportData } from './ExportData'

export type ExportXLSProps = {
  open: boolean
  onClose: () => void
  deeplinkWidgetId: string
  exportParams: Maybe<DataExportParams>
}

export const ExportXLS: React.FC<ExportXLSProps> = ({
  open,
  onClose,
  exportParams,
  deeplinkWidgetId,
}) => {
  if (!open || !exportParams) {
    return null
  }

  const withComments =
    exportParams.mode === 'current-with-comments' ||
    exportParams.mode === 'flat-with-comments'

  if (!withComments) {
    return <ExportData exportParams={exportParams} onClose={onClose} />
  }

  return (
    <ExportWithCommentsData
      exportParams={exportParams}
      onClose={onClose}
      deeplinkWidgetId={deeplinkWidgetId}
    />
  )
}
