import React from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { EntityType } from './types'
import { useIsReportEditorEnabled } from '@fintastic/web/feature/report-editor'

export const TypeField: React.FC<{
  type: EntityType
  setType: (newValue: EntityType) => void
  disabled: boolean
}> = ({ type, disabled, setType }) => {
  const reportsEnabled = useIsReportEditorEnabled()

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive={true}
      fullWidth={true}
      onChange={(_, newValue) => setType(newValue === null ? type : newValue)}
      value={type}
      size="small"
      disabled={disabled}
    >
      <ToggleButton value="metric">Metric</ToggleButton>
      <ToggleButton value="list">List</ToggleButton>
      {reportsEnabled && <ToggleButton value="report">Report</ToggleButton>}
    </ToggleButtonGroup>
  )
}
