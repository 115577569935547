import { styled, Box, Paper } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Paper)`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
`

export const StyledPeriodSection = styled(Box)`
  padding: 12px;
`

export const StyledPeriods = styled(Box)`
  margin-top: 8px;

  ${styledMixins.alignContentCenter()};
`

export const StyledDatePickers = styled(Box)`
  ${styledMixins.alignContentCenter()};
  justify-content: flex-start;

  width: 100%;
`

export const StyledRangeToggle = styled(Box)`
  ${styledMixins.alignContentCenter()};
  padding-left: ${({ theme }) => theme.spacing(1)};

  label {
    margin: 0;
  }
`

export const StyledAggregationSection = styled(Box)`
  padding: 0 12px 12px;
`

export const StyledAggregationSectionError = styled(Box)`
  padding-top: 8px;
`

export const StyledFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px;
`

export const StyledFooterLeft = styled(Box)``

export const StyledFooterRight = styled(Box)``
