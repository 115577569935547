import { useMemo } from 'react'
import { useLoadVersionEntities } from '../api/useLoadVersionEntities'

export const useEntitityLabel = (
  entityId: string,
  versionId: string,
): string => {
  const entitiesQuery = useLoadVersionEntities(versionId)

  return useMemo<string>(() => {
    if (!entitiesQuery?.data) {
      return entityId
    }

    const listLabel = entitiesQuery.data.lists?.find(
      (i) => i.id === entityId,
    )?.label

    if (listLabel) {
      return listLabel
    }

    const metricLabel = entitiesQuery.data.metrics?.find(
      (i) => i.id === entityId,
    )?.label

    return metricLabel || entityId
  }, [entitiesQuery.data, entityId])
}
