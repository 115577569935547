import React from 'react'
import {
  defaultChartGridMode,
  useChartSettingsEditContext,
} from '@fintastic/web/feature/charts'
import { ChartGridModeSelect } from '../../../settings-controls/controls'

export const DialogSectionGridDisplay: React.FC = () => {
  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartGridModeSelect
      gridMode={localSettings.gridMode || defaultChartGridMode}
      onChangeChartSettings={handleSettingsChange}
    />
  )
}
