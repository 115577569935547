import React from 'react'
import {
  PageLayout,
  PageLayoutContentLoader,
} from '@fintastic/shared/ui/app-layout-framework'
import { BoardContainer } from '../containers/BoardContainer'
import { useBoardDefaultVersions } from '../hooks/useBoardDefaultVersions'

export const BoardPage: React.FC = () => {
  const { defaultVersions, query } = useBoardDefaultVersions()

  if (query.isLoading || !defaultVersions.length) {
    return (
      <PageLayout disableScrollX={true}>
        <PageLayoutContentLoader />
      </PageLayout>
    )
  }

  return <BoardContainer defaultVersions={defaultVersions} />
}
