import { replaceUnderscores } from './string-operations'

export function titleFormatter(title: string): string
export function titleFormatter<T>(title: T): T
export function titleFormatter<T>(title: T): T | string {
  if (typeof title === 'string') {
    return replaceUnderscores(title)
  }
  return title
}
