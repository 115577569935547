import { styled, ButtonBase, Typography, Box } from '@mui/material'

export const StyledRoot = styled(ButtonBase, {
  shouldForwardProp: (p) => p !== 'isOpened',
})<{ isOpened: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 8px 14px;

  width: 100%;
  height: 32px;

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &:hover {
    border-color: ${({ theme, isOpened }) =>
      !isOpened ? theme.palette.common.black : undefined};
  }

  &:disabled {
    pointer-events: none;
    border-color: ${({ theme }) => theme.palette.divider};
    color: ${({ theme }) => theme.palette.text.disabled};
  }

  &:hover fieldset {
    border-color: ${({ theme }) => theme.palette.common.black};
  }
`

export const StyledText = styled(Box)`
  width: calc(100% - 32px);

  text-align: left;
  white-space: nowrap;
`

export const StyledPlaceholder = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.palette.text.disabled};
`

export const StyledOptionText = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`

export const StyledTextMain = styled('span')`
  overflow: hidden;

  text-overflow: ellipsis;
`

export const StyledHighlightedText = styled('span')`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const StyledIcon = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  color: ${({ theme }) => theme.palette.grey[600]};
`

export const StyledFieldset = styled('fieldset')<{
  isOpened: boolean
  hasLabel: boolean
}>`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: ${({ hasLabel }) => (hasLabel ? '-5px' : '0')};
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  overflow: hidden;
  min-width: 0%;
  ${({ theme }) => theme.typography.body1};

  border: ${({ isOpened }) => (isOpened ? '2px' : '1px')} solid
    ${({ theme, isOpened }) =>
      isOpened ? theme.palette.primary.main : theme.palette.divider};

  legend {
    float: unset;
    width: auto;
    overflow: hidden;
    display: block;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: hidden;
    max-width: 100%;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    white-space: nowrap;
  }

  legend span {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    opacity: 0;
    visibility: visible;
  }
`
