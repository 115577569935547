import React from 'react'
import { MemoryRouter } from '@fintastic/shared/util/memory-router'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { memoryRoutes } from './memory-routes'
import { useNavigationContext } from '../shared/connection/navigation/navigation-context'

export const ListSettingsPanel: React.FC = () => {
  const location = useNavigationContext()

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <MemoryRouter routes={memoryRoutes} location={location} />
    </FintasticThemeProvider>
  )
}
