import { Maybe } from '@fintastic/shared/util/types'
import { compact, first, isEqual, uniq } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import {
  CalculatedRowIdUtils,
  type BasicColumnDefinition,
  type CalculatedRowOptions,
  type GenericReportId,
  type GenericReportTreeRow,
  type ValueDefinition,
} from '@fintastic/web/util/generic-report'
import { toast } from '@fintastic/shared/ui/toast-framework'

// Definition for the calculated row
export const calculatedRowPlaceholder = (label: string) => `Calc (${label})`

export const useReportCalculatedRows = (
  opts: CalculatedRowOptions[],
  rows: GenericReportTreeRow[],
  dimensions: BasicColumnDefinition[],
  periods: string[],
) => {
  const { reportCalculatedRowsHasReachedLimit, treeDataWithCalculatedRows } =
    useMemo<UseReportCalculatedRowsResult>(() => {
      if (!rows?.length) {
        return {
          reportCalculatedRowsHasReachedLimit: false,
          treeDataWithCalculatedRows: [],
        }
      }

      if (!opts?.length) {
        return {
          reportCalculatedRowsHasReachedLimit: false,
          treeDataWithCalculatedRows: rows,
        }
      }

      const firstRow = first(rows)

      let totalNumberOfCalcRows = 0

      const calculatedRows = opts.flatMap((options) => {
        if (totalNumberOfCalcRows >= CALCULATED_LINES_LIMIT) {
          return []
        }

        const dimensionsPlaceholders = dimensions.map((dim) => [
          dim.name,
          calculatedRowPlaceholder(options.label),
        ])

        const getDimensionsByParent = (
          parent: string,
        ): Record<string, Maybe<string | number>> => {
          const parentPath = parent ? parent.split('/') : []
          const levelDimensions = dimensions.slice(0, parentPath.length)

          const dimensionValues = levelDimensions.map((dim, index) => [
            dim.name,
            parentPath[index] ?? null,
          ])

          return Object.fromEntries([
            ...dimensionsPlaceholders,
            ...dimensionValues,
          ])
        }

        const makeValue = (parent: string): GenericReportTreeRow => ({
          id: CalculatedRowIdUtils.makeCalculatedRowId(options.formula, parent),
          dimensions: getDimensionsByParent(parent),
          values: periods.map((_timestamp) => ({
            ...(firstRow?.values[0] || ({} as ValueDefinition)),
            _timestamp,
            reportOrVersionId: (firstRow?.values?.[0].reportOrVersionId ||
              '') as GenericReportId,
            amount: 0,
            aggregation: 'sum',
            uom: options.uom,
          })),
          path: compact([...parent.split('/'), options.label]),
          calculatedRow: options,
        })

        const dimensionIndex = dimensions.findIndex(
          ({ name }) => name === options.dimension,
        )

        if (dimensionIndex === -1) {
          console.warn('Invalid dimension used for the report calc row', {
            dimensions,
            options,
          })
          return []
        }

        const leafs = compact(
          uniq(
            rows.map((row) => row.path.slice(0, dimensionIndex + 1).join('/')),
          ),
        )

        const parents =
          dimensionIndex > 0
            ? compact(
                uniq(
                  leafs.map((i) =>
                    i.split('/').slice(0, dimensionIndex).join('/'),
                  ),
                ),
              )
            : ['']

        totalNumberOfCalcRows += parents.length

        if (totalNumberOfCalcRows >= CALCULATED_LINES_LIMIT) {
          return []
        }

        return parents.map(makeValue)
      })

      const reportCalculatedRowsHasReachedLimit =
        totalNumberOfCalcRows >= CALCULATED_LINES_LIMIT

      return {
        treeDataWithCalculatedRows: reportCalculatedRowsHasReachedLimit
          ? rows
          : [...rows, ...calculatedRows],
        reportCalculatedRowsHasReachedLimit,
      }
    }, [rows, dimensions, periods, opts])

  const toastShownForDimensions = useRef<BasicColumnDefinition[]>([])

  useEffect(() => {
    if (
      reportCalculatedRowsHasReachedLimit &&
      !isEqual(toastShownForDimensions.current, dimensions)
    ) {
      toast.custom(
        'Calculated rows are omitted because the number of calculated rows in the selected view exceeds the maximum limit.',
        {
          className: 'neutral',
          duration: 6000,
        },
      )

      toastShownForDimensions.current = dimensions
    }
  }, [
    dimensions,
    reportCalculatedRowsHasReachedLimit,
    treeDataWithCalculatedRows,
  ])

  useEffect(() => {
    if (!isEqual(toastShownForDimensions.current, dimensions)) {
      toastShownForDimensions.current = []
    }
  }, [dimensions])

  return treeDataWithCalculatedRows
}

const CALCULATED_LINES_LIMIT = 30 * 1000

export type UseReportCalculatedRowsResult = {
  treeDataWithCalculatedRows: GenericReportTreeRow[]
  reportCalculatedRowsHasReachedLimit: boolean
}
