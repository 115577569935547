import { RowNode } from 'ag-grid-community'
import { ExtendedColumn } from './utils'
import { CommentsThreadsBulkItem } from '@fintastic/web/data-access/comments'
import { buildObjectId } from '@fintastic/web/feature/generic-report-table'
import { LabelTagListItem } from '@fintastic/web/data-access/labels'

export type getCommentsParams = {
  threads?: CommentsThreadsBulkItem[]
  labels?: LabelTagListItem[]
  monthOverMonthEnabled: boolean
  context: any
  rowsToDisplay: RowNode[]
  columns: ExtendedColumn[]
}

export type CellRemark = CommentsThreadsBulkItem[] | undefined
export type ExtendedCell = CommentsThreadsBulkItem & { label_titles: string[] }

export const getComments = ({
  threads,
  labels,
  monthOverMonthEnabled,
  context,
  rowsToDisplay,
  columns,
}: getCommentsParams): CellRemark[][] => {
  const result: CellRemark[][] = []

  rowsToDisplay.forEach((row) => {
    const rowData: CellRemark[] = []
    columns.forEach((col, idx) => {
      const objectId = buildObjectId(
        col,
        row,
        context.dimensions,
        context.deeplinkWidgetId,
        context.periodSelection,
        monthOverMonthEnabled,
      )

      const comments = threads?.filter(
        (thread) => thread.object_key === objectId,
      )

      comments?.forEach((comment: Partial<ExtendedCell>) => {
        if (comment.label_ids && comment.label_ids.length > 0) {
          comment.label_titles = labels
            ?.filter((label) => comment.label_ids!.includes(label.id))
            .map((label) => '(' + label.title + ')')
        }
      })

      rowData.push(comments)
    })
    result.push(rowData)
  })

  return result
}
