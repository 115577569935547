import { useCallback, useMemo, useState } from 'react'

const initialStateValues = {
  requestRunning: false,
  waitingCalcEvent: false,
}

export function useSettingsSavingFlags() {
  const [isSaveRequestRunning, setIsSaveRequestRunning] = useState(
    initialStateValues.requestRunning,
  )
  const [isWaitingForCalcEvent, setIsWaitingForCalcEvent] = useState(
    initialStateValues.waitingCalcEvent,
  )

  const markSaveRequestAsRunning = useCallback(() => {
    setIsSaveRequestRunning(true)
  }, [])

  const markSaveRequestAsNotRunning = useCallback(() => {
    setIsSaveRequestRunning(false)
  }, [])

  const startWaitingForCalcEvent = useCallback(() => {
    setIsWaitingForCalcEvent(true)
  }, [])

  const reset = useCallback(() => {
    setIsSaveRequestRunning(initialStateValues.requestRunning)
    setIsWaitingForCalcEvent(initialStateValues.waitingCalcEvent)
  }, [])

  return useMemo(
    () =>
      ({
        isSaveRequestRunning,
        isWaitingForCalcEvent,
        markSaveRequestAsRunning,
        markSaveRequestAsNotRunning,
        startWaitingForCalcEvent,
        reset,
      } as const),
    [
      isSaveRequestRunning,
      isWaitingForCalcEvent,
      markSaveRequestAsNotRunning,
      markSaveRequestAsRunning,
      startWaitingForCalcEvent,
      reset,
    ],
  )
}
