import { RollUpFunction } from '@fintastic/shared/util/types'
import { VersionMetric } from '../../../../utils/metrics'
import { useMemo } from 'react'

export type VersionToCategoryAggregationMap<VersionId extends string = string> =
  Record<VersionId, RollUpFunction>

export function useVersionToCategoryAggregationMap(
  metrics: VersionMetric[],
): VersionToCategoryAggregationMap {
  return useMemo(
    () =>
      Object.fromEntries(
        metrics.map((m) => [m.versionId, m.metricMetadata.value.category_agg]),
      ),
    [metrics],
  )
}
