import type { Token } from '../tokens/types'
import { Maybe } from '@fintastic/shared/util/types'

export function caretAtToken(token: Token, caret: number): boolean {
  return !caretBeforeToken(token, caret) && !caretAfterToken(token, caret)
}

export function caretBeforeToken(token: Token, caret: number): boolean {
  return caret <= token.start
}

export function caretAfterToken(token: Token, caret: number): boolean {
  return caret > token.stop + 1
}

export function findTokenByCaret(
  tokens: Token[],
  caretPosition: number,
): Maybe<[Token, number]> {
  if (tokens.length === 0) {
    return null
  }

  // in ltr order there will be no token
  if (caretPosition === 0) {
    return null
  }

  // often the editing token is the last one
  const lastIndex = tokens.length - 1
  const lastToken = tokens[lastIndex]
  if (caretAtToken(lastToken, caretPosition)) {
    return [lastToken, lastIndex]
  }

  // run binary search
  let minIndex = 0
  let maxIndex = tokens.length - 1

  while (minIndex <= maxIndex) {
    const middleIndex = Math.floor((minIndex + maxIndex) / 2)
    const middleToken = tokens[middleIndex]
    if (caretAtToken(middleToken, caretPosition)) {
      return [middleToken, middleIndex]
    }
    if (caretBeforeToken(middleToken, caretPosition)) {
      maxIndex = middleIndex - 1
      continue
    }
    if (caretAfterToken(middleToken, caretPosition)) {
      minIndex = middleIndex + 1
    }
  }

  return null
}

// this function used in context when we 100% sure that caret is inside the token
export function getTokenStringPartByCaret(
  token: Token,
  caretPosition: number,
): string {
  return token.text.slice(0, caretPosition - token.start)
}
