import { useQuery } from 'react-query'
import { getVersionsList } from '../../api/versions-management-api'
import { versionsCacheKeys } from '../../cache'
import { Version } from '@fintastic/web/util/versions'
import { sortBy } from 'lodash'

export type UseVersionsListParams = {
  withLiveActuals?: boolean
  showArchived?: boolean
  showDeleted?: boolean
  versionsIds?: string[]
}

export function useLoadVersionsList({
  showArchived,
  showDeleted,
  withLiveActuals,
  versionsIds,
}: UseVersionsListParams = {}) {
  return useQuery<Version[], Error>(
    versionsCacheKeys.versionsList(
      showArchived,
      showDeleted,
      withLiveActuals,
      versionsIds,
    ),
    async () => {
      const response = await getVersionsList(
        showArchived,
        showDeleted,
        withLiveActuals,
      )

      const renamedData = renameLiveActualsName(response.data)

      const versions = versionsIds
        ? renamedData.filter((v) => versionsIds.includes(v.uuid))
        : renamedData
      return sortBy(versions, ['is_live_actuals', 'name'])
    },
  )
}

const renameLiveActualsName = (versions: Version[]): Version[] => {
  const liveActualsNumber = versions.filter((i) => i.is_live_actuals).length

  if (liveActualsNumber === 1) {
    return versions.map((version) => {
      if (version.is_live_actuals) {
        return {
          ...version,
        }
      }

      return version
    })
  }

  return versions
}
