import { Box, CircularProgress, Typography } from '@mui/material'
import type { CircularProgressProps } from '@mui/material/CircularProgress'
import React, { useMemo } from 'react'

export const CenteredCircularProgress: React.FC<
  CircularProgressProps & {
    message?: string
    fullScreen?: boolean
    onTop?: boolean
  }
> = ({ message, onTop, fullScreen = true, style, ...props }) => {
  const resStyle = useMemo<React.CSSProperties>(
    () =>
      onTop
        ? {
            ...(style || {}),
            zIndex: 9999,
            position: 'absolute' as React.CSSProperties['position'],
            left: '50%',
          }
        : style || {},
    [style, onTop],
  )

  return (
    <Box
      display="flex"
      height={fullScreen ? '100vh' : '100%'}
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      style={resStyle || {}}
      {...props}
    >
      <Box textAlign="center">
        <CircularProgress />
        <br />
        {message ? <Typography variant="caption">{message}</Typography> : null}
      </Box>
    </Box>
  )
}
