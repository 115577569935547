import type { Maybe } from '@fintastic/shared/util/types'
import { QueryKey, useQuery } from 'react-query'
import { getReportDetailsAPI } from '../api'
import {
  GenericReportDetails,
  GenericReportDetailsQueryParams,
  reportQueryKeyBase,
} from '@fintastic/web/util/generic-report'

export function genericReportReportDetailsQueryKeyGetter(
  params: Maybe<GenericReportDetailsQueryParams>,
): QueryKey {
  const {
    period,
    reportOrVersionId,
    timestampField,
    dimensionFilter,
    categoryId,
  } = params || {}
  return [
    ...reportQueryKeyBase(reportOrVersionId || ''),
    'details',
    period,
    period,
    timestampField,
    categoryId,
    JSON.stringify(dimensionFilter || {}),
  ]
}

export function useGenericReportReportDetailsQuery(
  params: Maybe<GenericReportDetailsQueryParams>,
) {
  const {
    period,
    dimensionFilter,
    reportOrVersionId,
    timestampField,
    categoryId,
  } = params || {}
  return useQuery<Maybe<GenericReportDetails>>(
    genericReportReportDetailsQueryKeyGetter(params),
    async () => {
      if (!params || !timestampField || !period) {
        return null
      }
      const response = await getReportDetailsAPI(
        reportOrVersionId || '',
        categoryId || '',
        {
          metric_filters:
            dimensionFilter || ({} as Record<string, Array<string>>),
          time_filter: {
            dim_name: timestampField,
            dim_value_label: period,
          },
        },
      )

      return response.data
    },
    {
      enabled: !!(period && reportOrVersionId && timestampField),
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
}
