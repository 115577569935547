import {
  Box,
  BoxProps,
  InputAdornment,
  OutlinedInput,
  styled,
} from '@mui/material'

export const StyledQuickSearchRoot = styled(
  (props: BoxProps & { fullWidth?: boolean }) => {
    const { fullWidth, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'fullWidth',
  },
)(({ theme, fullWidth }) => ({
  width: '100%',
  maxWidth: fullWidth ? '100%' : 330,
}))

export const StyledQuickInput = styled(OutlinedInput)(({ theme }) => ({
  'paddingLeft': 9,

  'width': '100%',
  'height': 40,

  'background': theme.palette.common.white,
  'borderRadius': 8,

  'fontSize': 14,

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },
}))

export const StyledQuickAdornment = styled(InputAdornment)(({ theme }) => ({
  marginRight: 9,

  color: '#B6B6B6',
}))

export const StyledQuickEndAdornment = styled(InputAdornment)(({ theme }) => ({
  'marginRight': -7,

  'button': {
    padding: 0,
    border: 'none',
    background: 'none',
  },

  'button svg': {
    width: 16,
    fill: '#b6b6b6',
  },

  'button:hover': {
    background: 'none',
    border: 'none',
  },

  'button:hover svg': {
    fill: theme.palette.primary.main,
  },
}))

export const StyledCounter = styled('span')`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 10px;
`
