import { idLooksLikeList } from '@fintastic/web/util/metrics-and-lists'
import {
  CalculationProgressEvent,
  CalculationProgressEventCalcAction,
} from './types'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'

type Event = CalculationProgressEvent

export const status = (e: Event) => e.status

export const user = (e: Event): Maybe<string> => e.user || null

export const versionId = (e: Event) => e.version_id

export const taskId = (e: Event) => e.task_id

export const error = (e: Event): Maybe<string> => e.error || null

export const errorCode = (e: Event): Maybe<number> => e.error_code || null

export const errorMessage = (e: Event): Maybe<string> => e.error_string || null

export const errorDetails = (
  e: Event,
): Maybe<CalculationProgressEvent['error_details']> => e.error_details || null

export const errorDetailsObjectId = (e: Event): Maybe<string> =>
  toMaybe(errorDetails(e)?.object_id)

export const userModifiedEntities = (e: Event): Maybe<string[]> =>
  e.user_modified_entities || null

export const dependenciesGraphAffectedFlag = (e: Event) =>
  !!e.dependencies_graph_is_affected

export const processedCalculationActions = (
  e: Event,
): Maybe<CalculationProgressEventCalcAction[]> =>
  (e.processed_calc_actions as CalculationProgressEventCalcAction[]) || null

export const createdListRows = (
  e: Event,
): Maybe<{ listId: string; rows: string[] }> => {
  if (e.list_rows_change?.action !== 'create') {
    return null
  }
  return {
    listId: e.list_rows_change.list_id,
    rows: e.list_rows_change.row_ids,
  }
}

export const deletedListRows = (
  e: Event,
): Maybe<{ listId: string; rows: string[] }> => {
  if (e.list_rows_change?.action !== 'delete') {
    return null
  }
  return {
    listId: e.list_rows_change.list_id,
    rows: e.list_rows_change.row_ids,
  }
}

export const userModifiedListIds = (e: Event): string[] =>
  (userModifiedEntities(e) || []).filter((entityId) =>
    idLooksLikeList(entityId),
  )
