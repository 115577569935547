import type {
  GenericReportNormalised,
  Report,
} from '@fintastic/web/util/generic-report'

export const buildReportNormalised = ({
  reportOrVersionId,
  definition,
  result,
  metadata,
  ordered_time_labels,
}: Report): GenericReportNormalised => {
  const normalizedReport = result.map((row) => ({
    ...row,
    _timestamp: `${row[definition.timestamp.name]}`,
  }))

  return {
    reportOrVersionId,
    definition,
    metadata: metadata,
    result: normalizedReport,
    ordered_time_labels,
  }
}
