import { Maybe } from '@fintastic/shared/util/types'
import { VirtualElement } from '@popperjs/core'
import { useMemo, useRef } from 'react'

export function useVirtualAnchorElement(
  node?: Maybe<HTMLElement>,
): VirtualElement {
  const domRectRef = useRef<Maybe<DOMRect>>(null)
  const nodeRef = useRef<Maybe<HTMLElement>>(null)
  nodeRef.current = node || null

  return useMemo<VirtualElement>(
    () => ({
      getBoundingClientRect: () => {
        if (!nodeRef.current && !domRectRef.current) {
          return {} as DOMRect
        }
        if (nodeRef.current && nodeRef.current.isConnected) {
          domRectRef.current = nodeRef.current.getBoundingClientRect()
        }
        return domRectRef.current || ({} as DOMRect)
      },
    }),
    [],
  )
}
