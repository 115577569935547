import { useMutation, useQueryClient } from 'react-query'
import { axios } from '@fintastic/web/data-access/service-axios'
import {
  CreateDimensionValuesPayload,
  CreateDimensionValuesResponse,
} from '../types'
import { AxiosResponse } from 'axios'
import { endpoints } from '../api/endpoints'
import {
  renameDimensionValues,
  StoreDimensionValuesError,
} from '../api/rename-dimension-value-api'
import { invalidateDimensionsRelated } from '../utils/invalidate-dimensions-utils'

type DimValuePayload = {
  label: string
  id: string
}

type Payload = {
  dimensionId: string
  createdValues: DimValuePayload[]
  renamedValues: DimValuePayload[]
}

type DimValueError = {
  item: DimValuePayload
  error: StoreDimensionValuesError | Error
}

export const useSaveDimensionValuesChanges = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ dimensionId, createdValues, renamedValues }: Payload) => {
      const errors: DimValueError[] = []
      const saved: string[] = []

      if (renamedValues.length !== 0) {
        const errorsLocal = await renameDimValues(dimensionId, renamedValues)
        errors.push(...errorsLocal)
        if (errorsLocal.length === 0) {
          saved.push(...renamedValues.map(({ id }) => id))
        }
      }

      if (createdValues.length !== 0) {
        const errorsLocal = await createDimValues(dimensionId, createdValues)
        errors.push(...errorsLocal)
        if (errorsLocal.length === 0) {
          saved.push(...createdValues.map(({ id }) => id))
        }
      }

      return { errors, savedValues: saved } as const
    },
    {
      // can happen only if body of mutation throws a runtime error
      onError: () => invalidateDimensionsRelated(queryClient),
    },
  )
}

const createDimValues = async (
  dimId: string,
  values: DimValuePayload[],
): Promise<DimValueError[]> => {
  try {
    await axios.post<
      CreateDimensionValuesResponse,
      AxiosResponse<CreateDimensionValuesResponse>,
      CreateDimensionValuesPayload
    >(endpoints.createDimensionValues(dimId), {
      items: values.map(({ label }) => ({ label })),
    })
    return []
  } catch (e) {
    console.error(e)
    return values.map((v) => ({
      item: v,
      error: new Error('Creation of dim values failed'),
    }))
  }
}

const renameDimValues = async (
  dimId: string,
  values: DimValuePayload[],
): Promise<DimValueError[]> => {
  const error = await renameDimensionValues(dimId, values)
  if (error instanceof Error) {
    console.error(error)
    return values.map((v) => ({
      item: v,
      error: error,
    }))
  }
  return []
}
