import type {
  TimeDimensionId,
  TimeDimensionValueId,
} from '@fintastic/web/util/dimensions'

export type CalendarDatePickerConfigValueItem = {
  dimension_value_id: TimeDimensionValueId
  label: string
  groups: TimeDimensionValueId[]
}

export type CalendarDatePickerConfigItem = {
  resolution: PeriodResolution
  dimension_id: TimeDimensionId
  values: CalendarDatePickerConfigValueItem[]
  label: string
  today: TimeDimensionValueId
}

export type CalendarDatePickerConfig = CalendarDatePickerConfigItem[]

export const sortedPeriodResolutions = [
  'year',
  'half_year',
  'quarter',
  'month',
  'week',
  'day',
] as const

export type PeriodResolution = typeof sortedPeriodResolutions[number]

/** Period is basically `Dimension Value Id` */
export type Period = TimeDimensionValueId
export type PeriodsList = Period[]
export type PeriodsRange = readonly [from: Period, to: Period]

type PeriodSelectionType = {
  /**
   * Period type selection
   * Affects calendar dropdowns, like you select "Year", and see Years in the period list
   * You still can select different aggregation via `aggregationDimensionId`
   */
  dimensionId: TimeDimensionId
  /**
   * Values aggregation
   * Controls data aggregation and columns of the tables.
   * Like if you select "month", you "Jan 2024" as a column
   **/
  aggregationDimensionId: TimeDimensionId
}

export type PeriodSelection = PeriodsBasedSelection | RangeBasedSelection

export type PeriodsBasedSelection = PeriodSelectionType & {
  periods: PeriodsList
}

export type RangeBasedSelection = PeriodSelectionType & {
  range: PeriodsRange
}
