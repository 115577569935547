import React, { useCallback } from 'react'
import { SyntaxLiteral } from '../../../syntax/types'
import { DATA_ATTRIBUTES } from '../const'
import {
  StyledListItemRoot,
  StyledListItemText,
  StyledListItemLiteral,
  StyledListItemLiteralType,
} from './ListItem.styled'
import { literalColors } from '../../../syntax/literal-colors'
import { literalTypeLabels } from '../../../syntax/literal-type-labels'

export type ListItemProps = {
  option: SyntaxLiteral
  isHighlighted: boolean
  onClick: (optionId: string) => void
}

export const ListItem: React.FC<ListItemProps> = ({
  option,
  isHighlighted,
  onClick,
}) => {
  const color = literalColors[option.type]
  const typeLabel = literalTypeLabels[option.type]

  const handleClick = useCallback(() => {
    onClick(option.literal)
  }, [onClick, option.literal])

  return (
    <StyledListItemRoot
      selected={isHighlighted}
      onClick={handleClick}
      {...{ [DATA_ATTRIBUTES.listItem]: '' }}
      title={option.literal}
    >
      <StyledListItemText>
        <StyledListItemLiteral sx={{ color }}>
          {option.literal}
        </StyledListItemLiteral>
        <StyledListItemLiteralType>{typeLabel}</StyledListItemLiteralType>
      </StyledListItemText>
    </StyledListItemRoot>
  )
}
