import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo } from 'react'
import {
  ChartTypeBarsIcon,
  ChartTypeLinesIcon,
} from '@fintastic/web/feature/charts'

export const DialogSectionGeneralIcon: React.FC = memo(() => {
  const { data } = useWidgetSettingsDialogContext()

  return data.chartSettings?.type === 'bars' ? (
    <ChartTypeBarsIcon />
  ) : (
    <ChartTypeLinesIcon />
  )
})
