import React from 'react'
import { IToolPanelParams } from 'ag-grid-community'
import { MemoryRouter, RouteObject } from '@fintastic/shared/util/memory-router'
import { DimensionSettingsViewConnector } from './view-connectors/DimensionSettingsViewConnector'
import { DimensionsViewConnector } from './view-connectors/DimensionsViewConnector'
import { MetricSettingsViewConnector } from './view-connectors/MetricSettingsViewConnector'
import { MetricsViewConnector } from './view-connectors/MetricsViewConnector'
import { ReportingGroupSettingsViewConnector } from './view-connectors/ReportingGroupSettingsViewConnector'
import { ReportingGroupsViewConnector } from './view-connectors/ReportingGroupsViewConnector'
import { RootViewConnector } from './view-connectors/RootViewConnector'
import {
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export const Panel: React.FC<IToolPanelParams> = ({ api }) => {
  const navigationApi = useReportSettingsPanelNavigationContext()

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <MemoryRouter routes={routes} location={navigationApi} />
    </FintasticThemeProvider>
  )
}

export const routes: RouteObject[] = [
  {
    path: settingsPanelNavigationPaths.root,
    element: <RootViewConnector />,
  },
  {
    path: settingsPanelNavigationPaths.dimensions,
    element: <DimensionsViewConnector />,
  },
  {
    path: settingsPanelNavigationPaths.dimensionSettings(),
    element: <DimensionSettingsViewConnector />,
  },
  {
    path: settingsPanelNavigationPaths.metrics,
    element: <MetricsViewConnector />,
  },
  {
    path: settingsPanelNavigationPaths.metricSettings(),
    element: <MetricSettingsViewConnector />,
  },
  {
    path: settingsPanelNavigationPaths.reportingGroups,
    element: <ReportingGroupsViewConnector />,
  },
  {
    path: settingsPanelNavigationPaths.reportingGroupSettings(),
    element: <ReportingGroupSettingsViewConnector />,
  },
]
