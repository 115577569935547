import { getVersionEntities } from '../../api/version-entities-api'
import { Maybe } from '@fintastic/shared/util/types'
import { VersionEntities } from '@fintastic/web/util/versions'

export async function loadVersionEntities(
  id?: Maybe<string>,
): Promise<VersionEntities> {
  const { data } = await getVersionEntities(id || '')

  return data
}
