import React from 'react'
import { FormattingFieldsetProps } from './types'
import { IntegerFormatting } from './IntegerFormatting'

export type PercentIntegerFormattingProps = FormattingFieldsetProps

export const PercentIntegerFormatting: React.FC<
  PercentIntegerFormattingProps
> = ({ onChange, displaySettings }) => (
  <IntegerFormatting onChange={onChange} displaySettings={displaySettings} />
)
