import React, { useMemo } from 'react'
import {
  StyledBarFooter,
  StyledBarNav,
  StyledBarRoot,
  StyledBarUserPanel,
} from './Bar.styled'
import { UserPanel } from './UserPanel'
import { Maybe } from '@fintastic/shared/util/types'
import { BarNavItem } from './BarNavItem'
import { LayoutSidebarNavSectionConfig } from '../../types'
import { useLocation, useParams } from 'react-router-dom'
import { useAppLayoutContext } from '../../contexts/app-layout-context'

export const Bar: React.FC = () => {
  const { sidebarConfig: sidebar } = useAppLayoutContext()
  const location = useLocation()
  const params = useParams()

  const activeSection = useMemo<Maybe<LayoutSidebarNavSectionConfig>>(() => {
    const validate = (sectionConfig: LayoutSidebarNavSectionConfig): boolean =>
      sectionConfig.isActive(location, params)

    return sidebar.footer.find(validate) || sidebar.top.find(validate) || null
  }, [sidebar, location, params])

  return (
    <StyledBarRoot data-testid="sidebar-bar">
      <StyledBarNav>
        {sidebar.top.map((config) => (
          <BarNavItem
            key={config.key}
            config={config}
            isActive={config.key === activeSection?.key}
            disabled={config.disabled}
          />
        ))}
      </StyledBarNav>
      <StyledBarFooter>
        <StyledBarNav>
          {sidebar.footer.map((config) => (
            <BarNavItem
              key={config.key}
              config={config}
              isActive={config.key === activeSection?.key}
            />
          ))}
        </StyledBarNav>
        <StyledBarUserPanel>
          <UserPanel />
        </StyledBarUserPanel>
      </StyledBarFooter>
    </StyledBarRoot>
  )
}
