import React, { useCallback, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { LayoutSidebarNavSectionConfig } from '../../types'
import {
  useLayoutStateIsSidebarOpened,
  useLayoutStateSidebarSubmenu,
} from '../../ducks/layout/hooks'
import {
  StyledBarNavItemRoot,
  StyledButton,
  StyledButtonBg,
  StyledButtonFg,
  StyledButtonIcon,
  StyledButtonText,
} from './BarNavItem.styled'

export type BarNavItemProps = {
  config: LayoutSidebarNavSectionConfig
  isActive: boolean
  disabled?: boolean
}

export const BarNavItem: React.FC<BarNavItemProps> = ({
  config,
  isActive,
  disabled,
}) => {
  const isActiveRef = useRef(isActive)

  useEffect(() => {
    isActiveRef.current = isActive
  }, [isActive])

  const [isSubmenuOpened, setIsSubmenuOpened] = useLayoutStateIsSidebarOpened()
  const [, selectSubmenu] = useLayoutStateSidebarSubmenu()

  const handleHideSubmenu = useCallback(() => {
    setIsSubmenuOpened(false)
  }, [setIsSubmenuOpened])

  const handleToggleSubmenu = useCallback(() => {
    selectSubmenu(config.key)
    if (isActive !== isActiveRef.current) {
      setIsSubmenuOpened(true)
    } else {
      setIsSubmenuOpened(!isSubmenuOpened)
    }
  }, [config.key, isActive, isSubmenuOpened, selectSubmenu, setIsSubmenuOpened])

  return (
    <StyledBarNavItemRoot data-testid={`sidebar-bar-item-${config.key}`}>
      <StyledButton
        data-testid={`sidebar-bar-item-button-${config.key}`}
        {...(config.type === 'link' && !disabled
          ? {
              component: Link,
              to: config.to,
              onClick: handleHideSubmenu,
            }
          : {
              onClick: disabled ? undefined : handleToggleSubmenu,
            })}
        sx={
          disabled
            ? {
                cursor: 'default',
              }
            : undefined
        }
        active={isActive}
        disabled={!!disabled}
      >
        <StyledButtonBg active={isActive}>
          <span />
        </StyledButtonBg>
        <StyledButtonFg>
          <StyledButtonIcon>
            <config.icon />
          </StyledButtonIcon>
          <StyledButtonText>
            <Typography variant="body2">{config.title}</Typography>
          </StyledButtonText>
        </StyledButtonFg>
      </StyledButton>
    </StyledBarNavItemRoot>
  )
}
