import React from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { theme } from './theme'
import { theme as legacyTheme } from './legacy/theme'

export type FintasticThemeProviderProps = {
  children: React.ReactNode
  includeBaseline?: boolean
  applyLegacyTheme?: boolean
}

export const FintasticThemeProvider: React.FC<FintasticThemeProviderProps> = ({
  children,
  includeBaseline = false,
  applyLegacyTheme = true,
}) => (
  <MuiThemeProvider theme={applyLegacyTheme ? legacyTheme : theme}>
    {includeBaseline && <CssBaseline />}
    {children}
  </MuiThemeProvider>
)
