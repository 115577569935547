import React, { useCallback, useMemo } from 'react'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { useFlagsContext } from '../flags-context'
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material'
import { DataTypeOption, dataTypeOptions } from './options/data-type-options'
import { useDataContext } from '../data-context'
import { getDataTypeGroup } from '@fintastic/web/util/metrics-and-lists'

export type DataTypeFieldProps = {
  value: MetricConfigurableDataValueType
  onChange: (newValue: MetricConfigurableDataValueType) => void
}

export const DataTypeField: React.FC<DataTypeFieldProps> = ({
  value,
  onChange,
}) => {
  const { readonly } = useFlagsContext()
  const { allowDataTypeConversions } = useDataContext()
  const disabled = readonly

  const handleChange = useCallback(
    (event: SelectChangeEvent<MetricConfigurableDataValueType>) => {
      onChange(event.target.value as MetricConfigurableDataValueType)
    },
    [onChange],
  )

  const allowedOptions = useMemo<DataTypeOption[]>(() => {
    if (!allowDataTypeConversions) {
      return dataTypeOptions.filter(
        ({ value: dataType }) =>
          getDataTypeGroup(value) === getDataTypeGroup(dataType),
      )
    }
    return dataTypeOptions
  }, [allowDataTypeConversions, value])

  return (
    <FormControl fullWidth>
      <InputLabel>Data type</InputLabel>
      <Select
        disabled={disabled}
        value={value}
        onChange={handleChange}
        label="Data type"
        MenuProps={{
          sx: {
            '& .MuiMenu-list': {
              py: 1,
            },
          },
        }}
      >
        {allowedOptions.map(({ label, value }) => (
          <MenuItem value={value} key={value} dense={true}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
