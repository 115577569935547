import {
  AgGridCustomCellEditorsMap,
  agGridCellEditorsMap,
} from '@fintastic/shared/ui/ag-grid'
import {
  AgGridNumericCellEditor,
  AgGridTextCellEditor,
  AgGridDateCellEditor,
  AgGridSelectboxCellEditor,
} from '@fintastic/shared/ui/ag-grid'
import { wrapCellEditor } from './CellEditorWrapper'
import type { FC } from 'react'

type WrappedEditors = `${keyof AgGridCustomCellEditorsMap}ForAggregatedTime`

export const cellEditors: AgGridCustomCellEditorsMap &
  Partial<Record<WrappedEditors, FC>> = {
  ...agGridCellEditorsMap,
  numericCellEditorForAggregatedTime: wrapCellEditor(
    AgGridNumericCellEditor,
    [],
  ),
  textCellEditorForAggregatedTime: wrapCellEditor(AgGridTextCellEditor, [
    'split_evenly',
  ]),
  dateCellEditorForAggregatedTime: wrapCellEditor(AgGridDateCellEditor, [
    'split_evenly',
  ]),
  selectBoxCellEditorForAggregatedTime: wrapCellEditor(
    AgGridSelectboxCellEditor,
    ['split_evenly'],
  ),
}
