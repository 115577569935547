import {
  CalendarDatePickerConfig,
  CalendarDatePickerConfigItem,
  CalendarDatePickerConfigValueItem,
} from '@fintastic/web/util/period-selector'

export const filterItemsAndValues = (
  config: CalendarDatePickerConfig,
  dimPredicate: (configItem: CalendarDatePickerConfigItem) => boolean,
  dimValuePredicate: (
    configItemValue: CalendarDatePickerConfigValueItem,
    configItem: CalendarDatePickerConfigItem,
  ) => boolean,
): CalendarDatePickerConfig =>
  config.filter(dimPredicate).map((configItem) => ({
    ...configItem,
    values: configItem.values.filter((v) => dimValuePredicate(v, configItem)),
  }))
