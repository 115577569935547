import { styled } from '@mui/material'

export const ValidityCellRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'valid',
})<{ valid?: boolean }>`
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    fill: ${({ valid }) => (valid ? '#05BC52' : '#F20644')};
  }
`
