import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CollapsePanelStyled = styled(
  (props: BoxProps & { open?: boolean }) => {
    const { open, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'open',
  },
)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 8px;
  overflow: ${({ open }) => (open ? 'visible' : 'hidden')};
`

export const CollapsePanelHeaderStyled = styled(
  (props: BoxProps & { open?: boolean }) => {
    const { open, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'open',
  },
)`
  background-color: #fcfaf8;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  color: #263646;
  font-size: 1rem;
  padding: 8px 12px;

  border-bottom: ${({ open, theme }) =>
    open ? '1px solid ' + theme.palette.divider : 'none'};

  display: flex;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;

  span {
    display: flex;
    line-height: 1;
    align-items: center;

    b {
      margin: 0 0.4em;
    }
  }
`
