import React, { createContext, useContext } from 'react'
import Pusher from 'pusher-js'

export const PusherContext = createContext<Pusher>(null as unknown as Pusher)

export const PusherProvider: React.FC<{
  children: React.ReactNode
  client: Pusher
}> = ({ children, client }) => (
  <PusherContext.Provider value={client}>{children}</PusherContext.Provider>
)

export const usePusher = () => useContext(PusherContext)
