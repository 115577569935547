import { SelectedCellsForAggregationProcessValueCallback } from '@fintastic/web/feature/selected-cell-aggregation'
import { GenericReportTreeRowCellValue } from '@fintastic/web/util/generic-report'
import { SelectedCell } from '@fintastic/web/util/selected-cell-aggregation'
import { Maybe } from '@fintastic/shared/util/types'
import { BLANK_VALUE } from '@fintastic/web/util/blanks-and-masked'
import { ColDef } from 'ag-grid-community'

export const extractSelectedCellFromEvent: SelectedCellsForAggregationProcessValueCallback<
  GenericReportTreeRowCellValue
> = (v, node, column, event): Maybe<SelectedCell> => {
  if (typeof v !== 'object') {
    return {
      format: 'string',
      value: '',
    }
  }

  if (v.finalValue) {
    if (v.calculatedRow) {
      return {
        value: v.finalValue === '-' ? BLANK_VALUE : v.finalValue,
        format: v.calculatedRow.uom === 'percentage' ? 'percent' : 'number',
        decimals: v.calculatedRow.uom === 'percentage' ? 2 : 0,
      }
    }

    const format = v.values.every(({ uom }) => uom === 'percentage')
      ? 'percent'
      : 'number'

    return {
      value: v.finalValue === '-' ? BLANK_VALUE : v.finalValue,
      format,
      decimals: format === 'percent' ? 2 : 0,
    }
  }

  const colDef = column.getColDef() as ColDef<GenericReportTreeRowCellValue>
  const valueGetter = colDef?.valueGetter

  if (typeof valueGetter !== 'function') {
    return null
  }

  const value = valueGetter({
    ...event,
    data: v,
    node,
    column,
    colDef,
    getValue: () => {
      throw new Error(
        'Get value is not implemented for selected cell aggregations',
      )
    },
  }) as GenericReportTreeRowCellValue

  if (!value) {
    return null
  }

  if (value.calculatedRow) {
    return {
      value:
        value.finalValue === undefined || value.finalValue === '-'
          ? BLANK_VALUE
          : value.finalValue,
      format: value.calculatedRow.uom === 'percentage' ? 'percent' : 'number',
      decimals: value.calculatedRow.uom === 'percentage' ? 2 : 0,
    }
  }

  const format = value.values?.every(({ uom }) => uom === 'percentage')
    ? 'percent'
    : 'number'

  return {
    format,
    value:
      value.finalValue === undefined || value.finalValue === '-'
        ? BLANK_VALUE
        : value.finalValue,
    decimals: format === 'percent' ? 2 : 0,
  }
}
