/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { Selector } from 'reselect'
import { createSelector } from '@reduxjs/toolkit'
import { makeSelectors as makeRequestSelectors } from '@fintastic/shared/data-access/redux-extension-request'
import type { RootState, State } from './state'
import { Maybe } from '@fintastic/shared/util/types'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s.auth

export const {
  selectStatus,
  selectIsInitial,
  selectIsError,
  selectIsLoading,
  selectError,
} = makeRequestSelectors(selectAll)

export const selectAuthUserInfo: Selector<RootState, State['userInfo']> = (s) =>
  selectAll(s).userInfo

export const selectAuthTenantId: Selector<RootState, Maybe<string>> = (s) =>
  selectAuthUserInfo(s)?.tenant_id || null

export const selectAllWithStatus: Selector<
  RootState,
  State & {
    isInitial: ReturnType<typeof selectIsInitial>
    isError: ReturnType<typeof selectIsError>
    isLoading: ReturnType<typeof selectIsLoading>
  }
> = createSelector(
  selectAll,
  selectIsLoading,
  selectIsInitial,
  selectIsError,
  (all, isLoading, isInitial, isError) => ({
    ...all,
    isLoading,
    isInitial,
    isError,
  }),
)
