import { useLocalStorage } from 'usehooks-ts'
import type { SortableDimension } from '@fintastic/web/util/generic-report'
import {
  useSyncDeeplinkValue,
  useIsLocalStorageOverrideDisabled,
} from '@fintastic/web/util/deeplink'
import { useCallback, useEffect, useMemo } from 'react'
import { isEqual, map, uniq } from 'lodash'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { useResetThreadId } from '@fintastic/web/data-access/comments'

export const useDeeplinkDimensions = (
  category: string,
  deeplinkWidgetId: string,
  defaultLocalDimensions: SortableDimension[],
) => {
  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const deeplinkDefaultValue = useMemo(
    () => map(defaultLocalDimensions, 'name'),
    [defaultLocalDimensions],
  )

  const [localStorageDimensions, setLocalStorageDimensions] = useLocalStorage<
    SortableDimension[]
  >(
    `generic_report_dimensions_order_${category}`,
    useMemo(() => [], []),
  )

  const [_urlDimensions, setUrlDimensions] = useSyncDeeplinkValue<string[]>({
    key: `w${deeplinkWidgetId}_dimensions`,
    defaultValue: deeplinkDefaultValue,
  })
  const resetThreadId = useResetThreadId()
  const urlDimensionsOrig = useMemo(
    () => uniq(_urlDimensions),
    [_urlDimensions],
  )
  const urlDimensions = useReferenceMemo(urlDimensionsOrig, isEqual)

  // Priority: URL Deeplink, LocalStorage, Defaults
  const localDimensions = useMemo<SortableDimension[]>(() => {
    const areLocalStorageDimensionsOk =
      localStorageDimensions.length === defaultLocalDimensions.length &&
      localStorageDimensions.every((fromLocalStorage) =>
        defaultLocalDimensions.find(
          (fromDefault) => fromLocalStorage.name === fromDefault.name,
        ),
      )

    const effectiveFallbackDimensions =
      localStorageDimensions.length &&
      areLocalStorageDimensionsOk &&
      !localStorageOverrideDisabled
        ? localStorageDimensions
        : defaultLocalDimensions

    if (urlDimensions?.length) {
      const equalsUrlAndDefault =
        map(defaultLocalDimensions, 'name').join('') === urlDimensions.join('')

      if (!equalsUrlAndDefault) {
        if (
          urlDimensions.find(
            (dim) => !defaultLocalDimensions.find((loc) => loc.name === dim),
          )
        ) {
          return effectiveFallbackDimensions
        }

        if (urlDimensions.length !== defaultLocalDimensions.length) {
          return effectiveFallbackDimensions
        }

        return urlDimensions.map((dim) => ({
          id: dim,
          name: dim,
          label:
            defaultLocalDimensions.find(({ name }) => name === dim)?.label ||
            dim,
        }))
      }
    }

    return effectiveFallbackDimensions
  }, [
    defaultLocalDimensions,
    localStorageDimensions,
    urlDimensions,
    localStorageOverrideDisabled,
  ])

  const setLocalDimensions = useCallback(
    (
      valueGetter:
        | SortableDimension[]
        | ((prev: SortableDimension[]) => SortableDimension[]),
    ) => {
      const nextValue =
        typeof valueGetter === 'function'
          ? valueGetter(localDimensions)
          : valueGetter
      const valueStr = map(nextValue, 'name').join('')
      const defaultStr = map(localDimensions, 'name').join('')
      if (valueStr === defaultStr) {
        return
      }

      resetThreadId()
      setUrlDimensions(map(nextValue, 'name'))
      setLocalStorageDimensions(nextValue)
    },
    [
      localDimensions,
      resetThreadId,
      setUrlDimensions,
      setLocalStorageDimensions,
    ],
  )

  // Put LS dimensions to URL if they are not default
  useEffect(() => {
    if (!defaultLocalDimensions.length || !localStorageDimensions.length) {
      return
    }

    if (localStorageOverrideDisabled) {
      return
    }

    const equalsLocalStorageAndDefault =
      map(defaultLocalDimensions, 'name').join('') ===
      map(localStorageDimensions, 'name').join('')

    if (!equalsLocalStorageAndDefault) {
      const equalsUrlAndDefault =
        map(defaultLocalDimensions, 'name').join('') === urlDimensions.join('')

      if (equalsUrlAndDefault) {
        setUrlDimensions(map(localStorageDimensions, 'name'))
      }
    }
  }, [
    localStorageOverrideDisabled,
    defaultLocalDimensions,
    localStorageDimensions,
    setUrlDimensions,
    urlDimensions,
  ])

  return useMemo(
    () => ({
      setLocalDimensions,
      localDimensions,
    }),
    [localDimensions, setLocalDimensions],
  )
}
