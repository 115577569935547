import React, { useCallback } from 'react'
import { StyledEntityType } from './EntityType.styled'
import { SmartMultiselect } from 'libs/shared/ui/dropdowns/src/lib/smart-selects'
import { resolveHistoryLogEntityTypeLabel } from '@fintastic/web/util/history'

type EntityType = 'list' | 'metric'

const entityTypesOptions: { label: string; value: EntityType }[] = [
  'metric' as const,
  'list' as const,
].map((entityType) => ({
  label: resolveHistoryLogEntityTypeLabel(entityType),
  value: entityType,
}))

export type EntityTypeProps = {
  disabled?: boolean
  entityTypes?: EntityType[]
  onChangeType: (v: EntityType[] | null) => void
}

export const EntityType: React.FC<EntityTypeProps> = ({
  onChangeType,
  entityTypes,
  disabled,
}) => {
  const handleAddType = useCallback(
    (v: EntityType[] | null) => {
      if (!v) {
        return
      }

      if (entityTypes) {
        onChangeType([...entityTypes, ...v])
      } else {
        onChangeType([...v])
      }
    },
    [onChangeType, entityTypes],
  )

  const handleRemoveType = useCallback(
    (v: EntityType[] | null) => {
      if (!v) {
        return
      }

      if (entityTypes) {
        onChangeType(entityTypes.filter((val) => !v.includes(val)))
      } else {
        onChangeType([])
      }
    },
    [onChangeType, entityTypes],
  )

  return (
    <StyledEntityType>
      <SmartMultiselect
        value={entityTypes || []}
        onSelectOptions={handleAddType}
        onUnselectOptions={handleRemoveType}
        options={entityTypesOptions}
        placeholder="Entity type"
        label="Entity type"
        disabled={disabled}
      />
    </StyledEntityType>
  )
}
