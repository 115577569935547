import type { ColumnApi, GridApi, IFilter } from 'ag-grid-community'
import { compact, isEmpty, isEqual } from 'lodash'
import { SetFilter } from 'ag-grid-enterprise'

export const getUncheckedSetFilterParameters = (
  filter: SetFilter<string>,
): string[] => {
  const allValues = filter.getValues()
  const checkedValues = filter.getModel()?.values

  if (!allValues.length || !checkedValues?.length) {
    return []
  }

  return compact(allValues.filter((v) => !checkedValues.includes(v)))
}

export const getSetFilterParametersToCheck = (
  filter: SetFilter<string>,
  checkedValues: string[],
): string[] => {
  const allValues = filter.getValues()

  if (!allValues.length || !checkedValues?.length) {
    return []
  }

  return compact(allValues.filter((v) => !checkedValues.includes(v as string)))
}

export const getFilterUrlValue = (filter: IFilter) => {
  if (filter instanceof SetFilter) {
    return getUncheckedSetFilterParameters(filter)
  }

  throw new Error('Filter is not serialazable')
}

export const syncFilterValue = <T>(
  { api, columnApi }: { api: GridApi<T>; columnApi: ColumnApi },
  filterValues: Record<string, string[]>,
) => {
  if (!api) {
    return
  }

  const allEmptyFilters = compact(
    (columnApi.getColumns() || [])
      .filter((c) => c.isFilterAllowed())
      .map((c) => c.getUserProvidedColDef()?.field || '')
      .filter((c) => filterValues[c] === undefined),
  )

  let anyFilterChanged = false

  Object.entries(filterValues).forEach(([key, values]) => {
    const filterInstance = api.getFilterInstance(key)

    if (!(filterInstance instanceof SetFilter)) {
      throw new Error('Only SetFilter is supported by syncFilterValue')
    }

    const newValues = getSetFilterParametersToCheck(filterInstance, values)

    const sortedA = [...(filterInstance.getModel()?.values || [])].sort()
    const sortedB = [...newValues].sort()

    if (isEqual(sortedA, sortedB)) {
      return
    }

    filterInstance
      .setModel({ values: newValues })
      .then(() => filterInstance.applyModel())

    anyFilterChanged = true
  })

  allEmptyFilters.forEach((filter) => {
    const filterInstance = api.getFilterInstance(filter)

    if (!filterInstance) {
      return
    }

    if (!(filterInstance instanceof SetFilter)) {
      throw new Error('Only SetFilter is supported by syncFilterValue')
    }

    if (isEmpty(filterInstance.getModel()?.values)) {
      return
    }

    filterInstance
      .setModel(null)
      ?.then(() => (filterInstance as SetFilter<unknown>)?.applyModel?.())

    anyFilterChanged = true
  })

  if (anyFilterChanged) {
    api.onFilterChanged()
  }
}
