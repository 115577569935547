import { MenuItem, styled } from '@mui/material'

export const StyledOrdering = styled('div')`
  display: flex;
  flex-flow: column wrap;

  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart {
    padding-left: 0;
    text-align: left;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-sizeSmall {
    background: #fff;
    font-size: 12px;

    .MuiSelect-icon {
      width: 24px;
      height: 24px;
      transform: translateY(-50%) translateX(4px);
    }
  }
`

export const StyledMenuItem = styled(MenuItem)``
