import { ValidationError } from '@fintastic/web/data-access/formulas'
import { ErrorCode, ERROR_CODES } from './error-codes'

export function getErrorCode(validationError: ValidationError): ErrorCode {
  if (validationError.type === 'objectRecognition') {
    // object is unknown
    // Indicates that we don't know about that token on the frontend
    return ERROR_CODES.UNRECOGNIZED_ARG_OR_FUNCTION
  }

  const { message } = validationError

  // NoViableAlternative
  // Indicates that the parser could not decide which of two or more paths to take based upon the remaining input.
  if (message.startsWith('no viable alternative at input ')) {
    return ERROR_CODES.FORMULA_STRUCTURAL_ERROR
  }

  // InputMismatch
  // This signifies any kind of mismatched input exceptions such as when the current input does not match the expected token.
  if (message.startsWith('mismatched input ')) {
    return ERROR_CODES.FORMULA_STRUCTURAL_ERROR
  }

  // FailedPredicate
  // A semantic predicate failed during validation
  if (message.startsWith('rule ')) {
    return ERROR_CODES.FORMULA_STRUCTURAL_ERROR
  }

  // UnwantedToken
  // Syntax error which requires the removal of a token from the input stream
  if (message.startsWith('extraneous input ')) {
    return ERROR_CODES.FORMULA_STRUCTURAL_ERROR
  }

  // MissingToken
  // Syntax error which requires the insertion of a missing token into the input stream
  if (message.startsWith('missing ')) {
    return ERROR_CODES.MISSING_MANDATORY_ARGUMENT
  }

  return ERROR_CODES.GENERIC_ERROR
}
