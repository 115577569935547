import React from 'react'
import { createSvgIcon } from '@mui/material'

export const FormulaIcon = createSvgIcon(
  <>
    <path d="M6.87189 7.03922C6.14715 6.97039 5.50805 7.52796 5.44217 8.29204L5.27745 10.2814H7.13543V11.6581H5.15886L4.86896 15.1481C4.83947 15.5082 4.74218 15.8586 4.58267 16.1793C4.42317 16.5 4.2046 16.7846 3.93951 17.0168C3.67442 17.249 3.36804 17.4242 3.03796 17.5323C2.70789 17.6404 2.36062 17.6793 2.01611 17.6468C1.60745 17.6106 1.21248 17.4755 0.862528 17.252C0.512575 17.0286 0.21726 16.723 0 16.3596L0.988287 15.3271C1.14641 15.8365 1.58126 16.2288 2.12811 16.277C2.85285 16.3458 3.49195 15.7883 3.55783 15.0242L3.84114 11.6581H1.86457V10.2814H3.95315L4.13104 8.16813C4.25622 6.65374 5.53441 5.53172 6.9839 5.66939C7.847 5.74511 8.57174 6.24761 9 6.95662L8.01171 7.98916C7.85359 7.47977 7.41874 7.08741 6.87189 7.03922Z"/>
    <path d="M12.906 6.63796L12.892 6.69396C12.332 7.47796 11.8933 8.34129 11.576 9.28396C11.2587 10.2173 11.1 11.1646 11.1 12.126C11.1 13.0966 11.2587 14.0533 11.576 14.996C11.8933 15.9293 12.332 16.788 12.892 17.572L11.716 18.342C11.072 17.4553 10.568 16.4846 10.204 15.43C9.84933 14.3753 9.672 13.274 9.672 12.126C9.672 10.9873 9.84933 9.89063 10.204 8.83596C10.568 7.77196 11.072 6.79663 11.716 5.90996L12.906 6.63796Z" />
    <path d="M15.8306 12.546L13.5066 9.43796H15.5506L16.8946 11.454L18.3506 9.43796H20.2686L17.9726 12.546L20.6746 16.158H18.6306L16.8806 13.75L15.1166 16.158H13.1286L15.8306 12.546Z" />
    <path d="M20.9001 17.614L20.9141 17.558C21.4741 16.774 21.9128 15.9153 22.2301 14.982C22.5474 14.0393 22.7061 13.0873 22.7061 12.126C22.7061 11.1553 22.5474 10.2033 22.2301 9.26996C21.9128 8.33663 21.4741 7.47796 20.9141 6.69396L22.0901 5.90996C22.7341 6.79663 23.2334 7.76729 23.5881 8.82196C23.9521 9.87663 24.1341 10.978 24.1341 12.126C24.1341 13.2646 23.9521 14.366 23.5881 15.43C23.2334 16.4846 22.7341 17.4553 22.0901 18.342L20.9001 17.614Z" />
  </>,
  'Formula',
)
