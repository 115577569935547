import { useBoardsFoldersTree } from './useBoardsFoldersTree'
import { useEffect, useMemo } from 'react'
import { BoardsFoldersTreeNode, BoardsFoldersTreeNodeFolder } from '../types'
import { Maybe } from '@fintastic/shared/util/types'
import { useNavigate } from 'react-router-dom'
import { boardRoutes } from '../routes'

export function useRedirectToDefaultBoard() {
  const navigate = useNavigate()
  const tree = useBoardsFoldersTree()

  const board = useMemo(() => {
    const getFirstBoard = (nodes: BoardsFoldersTreeNode[]): Maybe<string> => {
      if (nodes.length === 0) {
        return null
      }

      for (let i = 0; i < nodes.length; i += 1) {
        if (nodes[i].type === 'board') {
          return nodes[i].data.id
        }
        return getFirstBoard((nodes[i] as BoardsFoldersTreeNodeFolder).children)
      }

      return null
    }

    return tree.isLoading ? null : getFirstBoard(tree.tree.rootNodes)
  }, [tree])

  useEffect(() => {
    if (board !== null) {
      navigate(boardRoutes.board(board))
    }
  }, [board, navigate])

  return useMemo(
    () => ({
      isLoading: tree.isLoading,
    }),
    [tree.isLoading],
  )
}
