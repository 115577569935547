import { createKeys } from './utils'

// Position
export const chartLegendPositions = {
  left: 'Left',
  right: 'Right',
  top: 'Top',
  bottom: 'Bottom',
}

export type ChartLegendPosition = keyof typeof chartLegendPositions

export const chartLegendPositionKeys: ChartLegendPosition[] =
  createKeys<ChartLegendPosition>(chartLegendPositions)

export function isChartLegendPosition(
  value: unknown,
): value is ChartLegendPosition {
  return chartLegendPositions[value as ChartLegendPosition] !== undefined
}

// Format
export const chartLegendFormats = {
  standard: 'Standard',
  compact: 'Compact',
}

export type ChartLegendFormat = keyof typeof chartLegendFormats

export const chartLegendFormatKeys: ChartLegendFormat[] =
  createKeys<ChartLegendFormat>(chartLegendFormats)

export function isChartLegendFormat(
  value: unknown,
): value is ChartLegendFormat {
  return chartLegendFormats[value as ChartLegendFormat] !== undefined
}

export const defaultChartLegendPosition: ChartLegendPosition = 'bottom'
export const defaultChartLegendFormat: ChartLegendFormat = 'standard'

export function getLegendPositionName(legendPosition?: ChartLegendPosition) {
  return chartLegendPositions[
    isChartLegendPosition(legendPosition)
      ? legendPosition
      : defaultChartLegendPosition
  ]
}

export function getLegendFormatName(legendFormat?: ChartLegendFormat) {
  return chartLegendFormats[
    isChartLegendFormat(legendFormat) ? legendFormat : defaultChartLegendFormat
  ]
}
