import { Maybe } from '@fintastic/shared/util/types'

export function addButtonsIdentifiers(containerElement: HTMLElement) {
  const sidebarButtons = containerElement.querySelectorAll(
    '.ag-root-wrapper .ag-side-buttons .ag-side-button',
  )

  sidebarButtons.forEach((buttonElement) => {
    const iconName = findIconName(buttonElement)
    if (iconName) {
      buttonElement.setAttribute('data-icon', iconName)
    }
  })
}

function findIconName(buttonElement: Element): Maybe<string> {
  const nativeAgGridIcon = buttonElement.querySelector(
    '.ag-side-button-icon-wrapper .ag-icon',
  )

  let iconName: Maybe<string> = null

  if (nativeAgGridIcon) {
    nativeAgGridIcon.classList.forEach((className) => {
      if (className.startsWith('ag-icon-')) {
        iconName = `native-${className.replace('ag-icon-', '')}`
      }
    })

    if (iconName !== null) {
      return iconName
    }
  }

  const fintasticIcon = buttonElement.querySelector(
    '.ag-icon.ag-fintastic-icon',
  )
  if (fintasticIcon) {
    fintasticIcon.classList.forEach((className) => {
      if (className.startsWith('ag-fintastic-icon-')) {
        iconName = `custom-${className.replace('ag-fintastic-icon-', '')}`
      }
    })
  }

  return iconName
}
