// https://stackoverflow.com/questions/68036783/how-to-hide-scrollbar-track

export const thinVScrollbar = () => ({
  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '15px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '20px',
    border: '4px solid transparent',
    backgroundClip: 'content-box',
  },
})
