import {
  MetricDataValue,
  UnfoldedMetricDataValues,
} from '@fintastic/web/util/metrics-and-lists'

// @todo add tests
export function mapUnfoldedLevel(
  data: UnfoldedMetricDataValues,
  level: number,
  mapper: (
    v: UnfoldedMetricDataValues | MetricDataValue,
  ) => UnfoldedMetricDataValues | MetricDataValue,
) {
  if (level === 0) {
    throw new Error("can't modify this level")
  }

  if (level === 1) {
    data.forEach((d, i, l) => {
      l[i] = mapper(d)
    })
    return
  }

  data.forEach((d) => {
    if (!Array.isArray(d)) {
      throw new Error('level does not exist')
    }
    mapUnfoldedLevel(d, level - 1, mapper)
  })
}
