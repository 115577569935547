import { Maybe } from '@fintastic/shared/util/types'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast/headless'

export type ErrorLike = Maybe<Error | AxiosError | string> | undefined

export type ErrorMessageGetter = (
  e: ErrorLike,
  defaultMessage: string,
) => Maybe<string>

export function toastErrorHandler(
  error?: Maybe<Error | AxiosError | string>,
  messageGetter?: ErrorMessageGetter,
  defaultMessage = 'Something went wrong',
) {
  if (!error) {
    return
  }

  let message = (error as AxiosError)?.message || defaultMessage

  if (typeof error === 'string') {
    message = error
  }

  if (error instanceof Error && typeof messageGetter === 'function') {
    message = messageGetter(error, defaultMessage) || defaultMessage
  }

  toast.error(message)
}
