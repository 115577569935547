import { styled } from '@mui/material'
import { oldToNewValueTransitionClassNames } from '../../../../entry-details'

export const StyledRoot = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  height: 100%;
`

export const StyledCellContent = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  height: 100%;
  margin: 4px 0;

  .${oldToNewValueTransitionClassNames.root}
    .${oldToNewValueTransitionClassNames.value}.${oldToNewValueTransitionClassNames.valueWithBlankModificator} {
    color: #b6b6b6;
  }
`
