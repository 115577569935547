import { ColumnColor, DimensionColumnColor } from './types'
import { CategoryDimensionId } from '@fintastic/web/util/dimensions'
import { createDimensionColumnColor } from './constructors'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'

export const resolveDimensionColumnColor = (
  colors: ColumnColor[],
  dimensionId: CategoryDimensionId | string,
  versionId: Maybe<string>,
): DimensionColumnColor => {
  const existingColor = toMaybe(
    colors.find(
      (color) =>
        color.column.type === 'dimension' &&
        color.versionId === versionId &&
        color.column.dimensionId === dimensionId,
    ),
  ) as Maybe<DimensionColumnColor>

  return existingColor ?? createDimensionColumnColor(dimensionId, versionId)
}
