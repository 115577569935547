import { styled } from '@mui/material'

export const StyledTransitionIcon = styled('div')`
  display: inline-flex;
  padding: 0 ${({ theme }) => theme.spacing(1)};

  svg {
    width: 14px;
    height: 14px;
  }
`
