import { UseQueryResult, useQuery } from 'react-query'
import { importConfigsByVersionQueryKey } from '../utils/query-key'
import { getImportConfigs } from '../api/api'
import { ImportConfigItem } from '../types'
import { useMemo } from 'react'

export const useImportConfigQuery = (
  versionId: string,
  { refetchOnMount = false, keepPreviousData = false, includeArchived = false },
) => {
  const query = useQuery<ImportConfigItem[]>(
    importConfigsByVersionQueryKey(versionId),
    async () => {
      const response = await getImportConfigs(versionId)
      return response.data.result
    },
    {
      keepPreviousData,
      refetchOnMount,
    },
  )

  const data = useMemo(() => {
    if (!query.data?.length) {
      return []
    }

    if (includeArchived) {
      return query.data
    }

    return query.data.filter((i) => Boolean(i.is_archived) === false)
  }, [includeArchived, query.data])

  return {
    ...query,
    data,
  } as UseQueryResult<ImportConfigItem[]>
}
