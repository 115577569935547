import { ThemeComponents } from './types'

export const MuiButtonBase: ThemeComponents['MuiButtonBase'] = {
  styleOverrides: {
    root: {
      '.MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
}
