import React, { ReactNode } from 'react'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { useChartSectionTitle } from '../../hooks'
import { SettingsSavePanel } from './SettingsSavePanel'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { StyledSectionRoot } from './ChartSettingsSection.styled'

export type ChartSettingsSectionProps = {
  allowBack?: boolean
  allowSavePanel?: boolean
  children?: ReactNode
}

export const ChartSettingsSection: React.FC<ChartSettingsSectionProps> = ({
  children,
  allowSavePanel = false,
  allowBack = true,
}) => {
  const sectionTitle = useChartSectionTitle()

  return (
    <ViewLayout
      title={sectionTitle}
      footer={allowSavePanel ? <SettingsSavePanel /> : undefined}
      enableBackButton={allowBack}
    >
      <FintasticThemeProvider applyLegacyTheme={true}>
        <StyledSectionRoot>{children}</StyledSectionRoot>
      </FintasticThemeProvider>
    </ViewLayout>
  )
}
