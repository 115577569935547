import { wrapSvgStringIcon } from './wrapSvgStringIcon'
import { IconDefinition } from './types'

const name = 'rows' as const

const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.3755 6.68001C21.3706 4.07524 21.374 4.26429 21.3288 4.07119C21.2204 3.60804 20.935 3.20014 20.532 2.93237C20.3157 2.78861 20.0463 2.68643 19.768 2.64252C19.6796 2.62857 19.3749 2.62801 12 2.62801C4.62231 2.62801 4.32042 2.62857 4.23228 2.64253C3.61142 2.74088 3.10161 3.11094 2.82507 3.66401C2.73261 3.84891 2.67772 4.02449 2.64249 4.24801C2.62857 4.33636 2.62799 4.6435 2.62799 12C2.62799 19.3565 2.62857 19.6637 2.64249 19.752C2.70108 20.1238 2.83838 20.4314 3.07075 20.7113C3.26639 20.9471 3.5553 21.1501 3.85085 21.2597C3.95153 21.297 4.11491 21.3389 4.23228 21.3575C4.32042 21.3714 4.62217 21.372 11.996 21.372C18.265 21.372 19.6812 21.3701 19.74 21.3616C20.0968 21.3098 20.4121 21.1748 20.68 20.9592C20.7717 20.8854 20.9221 20.7319 20.9892 20.6437C21.2028 20.363 21.3328 20.0361 21.3678 19.692C21.378 19.5919 21.3832 10.7962 21.3755 6.68001ZM20.1187 4.40107C20.1011 4.27787 20.039 4.15753 19.9447 4.06366C19.8514 3.97089 19.7538 3.91541 19.6421 3.89175C19.5953 3.88184 18.5581 3.88026 11.9993 3.88014C4.45442 3.88001 4.41019 3.88009 4.34727 3.89589C4.24715 3.92104 4.16026 3.96919 4.08294 4.04237C4.00867 4.11268 3.95607 4.18617 3.92487 4.26319C3.87725 4.38081 3.87999 4.24751 3.87999 6.44367L3.87999 8.45601L12.004 8.45601L20.128 8.45601L20.127 6.45401C20.1264 5.29818 20.1229 4.43048 20.1187 4.40107ZM12.004 9.71201L3.87999 9.71201L3.87999 12L3.87999 14.288L12.004 14.288L20.128 14.288L20.128 12L20.128 9.71201L12.004 9.71201ZM12.004 15.544L3.87999 15.544L3.88013 17.558C3.88025 19.2938 3.88189 19.5797 3.89197 19.628C3.93972 19.8566 4.12159 20.0456 4.35199 20.1062L4.41999 20.124L12 20.124C19.1028 20.124 19.5837 20.1232 19.6381 20.1104C19.7574 20.0825 19.8513 20.0292 19.9447 19.9364C20.0424 19.8391 20.1008 19.7232 20.1193 19.5894C20.1254 19.5458 20.128 18.9228 20.128 17.5354L20.128 15.544L12.004 15.544Z" fill="black"/>
</svg>`

export const rowsIcon: IconDefinition<typeof name> = {
  name,
  makeIcon: (attributes) => wrapSvgStringIcon(name, svg, attributes),
}
