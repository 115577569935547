import React, { useMemo } from 'react'
import { useBoardDefaultVersions } from '@fintastic/web/feature/boards'
import { WidgetRendererProps } from '@fintastic/shared/ui/widgets-framework'
import {
  useLoadVersion,
  useReducedVersionRangePeriodSelection,
} from '@fintastic/web/data-access/versions'
import { WidgetPeriodSelectionContainerDesignMode } from './WidgetPeriodSelectionContainerDesignMode'
import { WidgetPeriodSelectionComponentProps } from './WidgetPeriodSelectionContainer'
import { compact } from 'lodash'
import { Box, CircularProgress } from '@mui/material'
import { StyledLoadingBox } from './WidgetPeriodSelection.styled'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'

export const WidgetPeriodSelectionContainerDesignModeWrapper: React.FC<
  {
    Component: React.FC<WidgetPeriodSelectionComponentProps>
  } & WidgetRendererProps
> = (props) => {
  // usually synchronous, already resolved
  const boardDefaultVersions = useBoardDefaultVersions()

  const referenceVersionId = useMemo(
    () =>
      props.widget.settings.versionId ??
      boardDefaultVersions?.defaultVersions?.[0] ??
      '',
    [boardDefaultVersions?.defaultVersions, props.widget.settings.versionId],
  )

  const versionData = useLoadVersion(referenceVersionId)

  const versionDefaultPeriodSelection = useReducedVersionRangePeriodSelection(
    versionData.data,
  )

  const selectedVersions = useMemo(
    () => compact([versionData.data]),
    [versionData.data],
  )

  if (versionData.isError) {
    return (
      <Box
        alignItems="center"
        height="100%"
        display="flex"
        justifyContent="center"
      >
        <ErrorAlert
          title="The reference version has been archived or deleted."
          message="Please select another version."
          severity="info"
          icon={<EmptyFolderErrorIcon />}
        />
      </Box>
    )
  }

  if (!selectedVersions.length || !versionDefaultPeriodSelection) {
    return (
      <StyledLoadingBox>
        <CircularProgress />
      </StyledLoadingBox>
    )
  }

  return (
    <WidgetPeriodSelectionContainerDesignMode
      versionDefaultPeriodSelection={versionDefaultPeriodSelection}
      selectedVersions={selectedVersions}
      {...props}
    />
  )
}
