import { Box, styled, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledGroupPanelRoot = styled(Box)(() => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'flexStart',
}))

export const StyledGroupPanelIconCont = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.info.main};
  border-radius: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  svg {
    width: 18px;
    height: 18px;
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`

export const StyledGroupPanelText = styled(Box)(() => ({}))

export const StyledGroupPanelName = styled(Typography)(() => ({
  fontWeight: 500,
}))
