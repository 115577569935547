import { toast } from 'react-hot-toast/headless'
import { Maybe } from '@fintastic/shared/util/types'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import {
  invalidateVersionUserLockerCache,
  switchVersionUserLockers,
} from '@fintastic/web/data-access/versions'
import { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import { AxiosError } from 'axios'
import { ListGridApi } from '../../types'
import {
  usePusherDataUpdateTaskOperation,
  TaskRequestErrorHandler,
} from '@fintastic/web/data-access/metric-data-editing'
import { deleteListRows } from '@fintastic/web/data-access/metrics-and-lists'
import { DataUpdateEvent } from '@fintastic/web/util/metric-data-editing'
import {
  PUSHER_EVENT_CELL_DATA_UPDATE,
  useSubscribeToTenantEvent,
} from '@fintastic/web/data-access/service-pusher'

export const useDeleteRowsOperation = (
  versionId: Maybe<string>,
  listId: string,
  rowDimensionId: Maybe<string>,
  listGridApi: React.MutableRefObject<Maybe<ListGridApi>>,
) => {
  const queryClient = useQueryClient()

  const requestFunction = useCallback(
    async (rows: BaseGridRow[]) => {
      if (!versionId) {
        throw new Error('versionId is not defined for useDeleteRowsOperation')
      }

      switchVersionUserLockers(queryClient, [versionId], 'calc')
      const response = await deleteListRows(
        versionId,
        listId,
        rows.map((row) => row[rowDimensionId || ''] as string),
      )
      return response.data
    },
    [listId, queryClient, rowDimensionId, versionId],
  )

  const handleError = useCallback<TaskRequestErrorHandler>(
    async (errorOrEvent) => {
      let errorMessage = 'Failed to delete line(s). Please try again.'

      if (errorOrEvent instanceof AxiosError) {
        if (errorOrEvent.response?.status === 403) {
          errorMessage =
            'You do not have the necessary permissions to delete these line(s).'
        } else if (errorOrEvent.message) {
          errorMessage = errorOrEvent.message
        }
      }
      toast.error(errorMessage)

      if (versionId) {
        await invalidateVersionUserLockerCache(queryClient, versionId)
      }
    },
    [queryClient, versionId],
  )

  useSubscribeToTenantEvent<DataUpdateEvent>(
    PUSHER_EVENT_CELL_DATA_UPDATE,
    useCallback(
      (event) => {
        if (
          event.version_id !== versionId ||
          !event.success ||
          event.update.action !== 'delete_list_rows' ||
          event.update.list_id !== listId
        ) {
          return
        }
        const { row_dim, row_dim_values } = event.update
        listGridApi.current?.removeRows(
          row_dim_values.map((rowIndex) => `${row_dim}.${rowIndex}`),
        )
      },
      [listGridApi, listId, versionId],
    ),
  )

  return usePusherDataUpdateTaskOperation<[rows: BaseGridRow[]]>({
    requestFunction,
    onError: handleError,
  })
}
