import React, { PropsWithChildren } from 'react'
import { SelectedCellAggregationContext } from './SelectedCellAggregationContext'
import {
  UseSelectedCellAggregationContextValueParams,
  useSelectedCellAggregationContextValue,
} from './useSelectedCellAggregationContextValue'

export const SelectedCellAggregationProvider: React.FC<
  PropsWithChildren<UseSelectedCellAggregationContextValueParams>
> = ({ children, entityIdForPersistentSettings }) => {
  const value = useSelectedCellAggregationContextValue({
    entityIdForPersistentSettings,
  })

  return (
    <SelectedCellAggregationContext.Provider value={value}>
      {children}
    </SelectedCellAggregationContext.Provider>
  )
}
