import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ArrowButtonRightIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.7699 2.00888C2.54453 2.04644 2.33873 2.23609 2.27914 2.46116C2.25932 2.53603 2.25543 2.68188 2.27129 2.756C2.2861 2.8253 2.31712 2.89984 2.35783 2.964C2.3865 3.00918 3.26109 3.88787 6.88266 7.51L11.372 12L6.88266 16.49C3.26109 20.1121 2.3865 20.9908 2.35783 21.036C2.28733 21.1471 2.26063 21.2438 2.26176 21.384C2.26327 21.5714 2.33476 21.7257 2.47726 21.8491C2.60078 21.9562 2.71329 21.9963 2.888 21.9956C3.03678 21.995 3.11241 21.9734 3.2274 21.8986C3.27202 21.8695 4.23924 20.9066 7.79718 17.3489C12.7327 12.4137 12.3852 12.7679 12.4872 12.5675C12.5836 12.3779 12.6198 12.223 12.6198 12C12.6198 11.777 12.5836 11.6222 12.4872 11.4325C12.3852 11.2321 12.7327 11.5863 7.79718 6.65114C3.04726 1.90155 3.24112 2.0926 3.12215 2.04425C3.02408 2.0044 2.88229 1.99016 2.7699 2.00888ZM12.128 2.01194C11.9763 2.04622 11.8503 2.12851 11.7543 2.256C11.6028 2.45714 11.5934 2.75018 11.7317 2.96427C11.7531 2.99754 13.124 4.37388 16.2552 7.506L20.748 12L16.2552 16.494C13.124 19.6261 11.7531 21.0025 11.7317 21.0357C11.5934 21.2498 11.6028 21.5429 11.7543 21.744C11.8878 21.9214 12.0743 22.0093 12.2918 21.9974C12.4206 21.9904 12.4998 21.9648 12.604 21.8967C12.6476 21.8682 13.661 20.8585 17.18 17.3372C20.1261 14.3892 21.7171 12.7916 21.7492 12.7491C22.0826 12.3082 22.0818 11.6908 21.7473 11.2483C21.6733 11.1504 12.6714 2.14771 12.5998 2.09999C12.546 2.06408 12.4894 2.03847 12.42 2.0186C12.3602 2.00147 12.1914 1.99761 12.128 2.01194Z"
  />,
  'ArrowButtonRight',
)
