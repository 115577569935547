import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  ChipLabelStyled,
  ChipStyled,
  StyledChipDropdownMenuPopover,
  StyledNothingToDisplay,
  StyledSearchInputContainer,
} from './ChipDropdown.styled'
import { ArrowDropDownIcon } from '@fintastic/shared/ui/icons'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { isArray, isEqual } from 'lodash'
import { QuickSearchDebounced } from '../QuickSearch'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'

export type ChipDropdownProps<T, Multiple extends boolean = false> = {
  items: T[] | readonly T[]
  value: Multiple extends false ? T : T[]
  onChange: (value: Multiple extends false ? T : T[]) => void
  testId: string
  formatItem?: (
    item: Multiple extends false ? T : T[],
    extractItemLabel?: (item: T) => string,
    allValuesLabel?: string,
  ) => React.ReactNode
  getItemKey?: (item: T) => string
  extractItemLabel?: (item: T) => string
  formatListItem?: (
    item: T,
    extractItemLabel?: (item: T) => string,
  ) => React.ReactNode
  populated?: boolean
  prefix?: string
  allValuesLabel?: string
  withSearch?: boolean
} & (Multiple extends true ? { multiple: true } : { multiple?: never })

const defaultItemFormatter = <T, Multiple>(
  item: Multiple extends false ? T : T[],
  extractItemLabel?: (item: T) => string,
  allValuesLabel = 'All',
): React.ReactNode => {
  if (isArray(item)) {
    if (!item.length) {
      return allValuesLabel
    }
    const first = extractItemLabel ? extractItemLabel(item[0]) : item[0]
    const surplus = item.length > 1 ? ` +${item.length - 1}` : ''

    return `${first}${surplus}`
  }

  return `${extractItemLabel ? extractItemLabel(item as T) : item}`
}

const defaultItemKeyGetter = <T,>(item: T) => String(item)

export const ChipDropdown = <T = string, Multiple extends boolean = false>({
  items,
  value,
  testId,
  onChange,
  formatItem = defaultItemFormatter<T, Multiple>,
  formatListItem = undefined,
  getItemKey = defaultItemKeyGetter<T>,
  extractItemLabel = defaultItemKeyGetter<T>,
  multiple,
  populated,
  prefix = '',
  withSearch = false,
  allValuesLabel = 'All',
}: ChipDropdownProps<T, Multiple>): React.ReactElement => {
  const [isOpen, setOpen] = useState(false)
  const rootNodeRef = useRef<HTMLDivElement>(null)
  const [selectedValues, setSelectedValues] = useState<T[]>(() => {
    if (isArray(value)) {
      return value
    }

    return value ? [value as T] : []
  })

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setFilterString('')
  }, [])

  const handleChange = useCallback(
    (value: T) => {
      if (multiple) {
        const existing = selectedValues.find((i) =>
          isEqual(getItemKey(i), getItemKey(value)),
        )
        let nextValues = selectedValues
        if (existing) {
          nextValues = selectedValues.filter(
            (i) => !isEqual(getItemKey(i), getItemKey(value)),
          )
        } else {
          nextValues = [...selectedValues, value]
        }

        setSelectedValues(nextValues)
        onChange(nextValues as Multiple extends false ? T : T[])
        return
      } else {
        onChange(value as Multiple extends false ? T : T[])
      }

      handleClose()
    },
    [multiple, handleClose, selectedValues, onChange, getItemKey],
  )

  const showSearch = (withSearch ?? false) && !!extractItemLabel

  const [filterString, setFilterString] = useState('')

  // @todo: isOpen initiates asynchronous fading out and the content is going to be re-rendered
  // and flicks. We need to have [onClosed] event to correctly reset filtering AFTER popup disappear.
  // setTimeout is not safe enough because creates closure and can lead to leaks. Current approach is a safe tradeoff.
  const filteredItems = useMemo<T[] | readonly T[]>(() => {
    if (!showSearch) {
      return items
    }
    if (!isOpen) {
      return []
    }
    const trimmedSearch = filterString.trim()

    if (!trimmedSearch) {
      return items
    }

    return items.filter((item: T) => {
      const itemText = extractItemLabel(item)
      return (itemText || '')
        .toLowerCase()
        .includes(trimmedSearch.toLowerCase())
    })
  }, [showSearch, isOpen, filterString, items, extractItemLabel])

  return (
    <div data-testid={testId} ref={rootNodeRef}>
      <ChipStyled onClick={handleOpen} active={isOpen} populated={!!populated}>
        <ChipLabelStyled>
          {prefix}
          {formatItem(value, extractItemLabel, allValuesLabel)}
        </ChipLabelStyled>
        <ArrowDropDownIcon viewBox="0 0 16 16" />
      </ChipStyled>
      <StyledChipDropdownMenuPopover
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        withSearch={withSearch}
        anchorEl={rootNodeRef.current}
      >
        {showSearch && (
          <StyledSearchInputContainer>
            <QuickSearchDebounced
              onFilter={setFilterString}
              fullWidth={true}
              totalCount={items.length}
              visibleCount={filteredItems.length}
              autoFocus={true}
            />
          </StyledSearchInputContainer>
        )}
        {filteredItems.length === 0 && isOpen && (
          <StyledNothingToDisplay>
            No results match your search
          </StyledNothingToDisplay>
        )}

        {filteredItems.length > 0 && (
          <List
            sx={{
              minWidth: 128,
              maxHeight: 320,
              overflowY: 'auto',
              ...sxMixins.thinVScrollbar(),
            }}
          >
            {filteredItems.map((item) => (
              <ListItem key={getItemKey(item)} disablePadding>
                <ListItemButton onClick={() => handleChange(item)}>
                  {multiple ? (
                    <Checkbox
                      edge="start"
                      checked={
                        (value as T[]).findIndex((i) =>
                          isEqual(getItemKey(i), getItemKey(item)),
                        ) !== -1
                      }
                      tabIndex={-1}
                      onChange={() => handleChange(item)}
                      disableRipple
                      sx={{ paddingTop: 0, paddingBottom: 0 }}
                    />
                  ) : null}
                  <ListItemText>
                    {formatListItem
                      ? formatListItem(item, extractItemLabel)
                      : formatItem(
                          item as Multiple extends false ? T : T[],
                          extractItemLabel,
                          allValuesLabel,
                        )}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </StyledChipDropdownMenuPopover>
    </div>
  )
}
