import React from 'react'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import {
  useModalSectionDefinition,
  useSectionedModalNavigationContext,
  useSectionedModalRoutesContext,
} from '../hooks'
import { ModalHeader } from '../../ModalHeader'
import { ArrowLeftIcon } from '@fintastic/shared/ui/icons'
import {
  StyledBackButton,
  StyledContent,
  StyledContentWrapper,
  StyledHeader,
  StyledRoot,
  StyledSectionedModalContentRoot,
  StyledTitle,
} from './ModalSection.styled'

export const ModalSection = () => {
  const { view, contentClass, title, path } = useModalSectionDefinition()

  const {
    onRequestClose,
    title: dialogTitle,
    isFirstSection,
    sectionCount,
    allowNavigation,
    allowLeaveSection,
  } = useSectionedModalRoutesContext()

  const { goBack } = useSectionedModalNavigationContext()

  return (
    <FintasticThemeProvider applyLegacyTheme={true}>
      <ModalHeader
        onRequestClose={onRequestClose}
        title={
          <StyledHeader>
            {!isFirstSection && allowNavigation && (
              <StyledBackButton
                color="textBlack"
                onClick={goBack}
                disabled={allowLeaveSection ? !allowLeaveSection(path) : false}
              >
                <ArrowLeftIcon fontSize="small" color="inherit" />
              </StyledBackButton>
            )}

            <StyledTitle>
              {allowNavigation ? title || dialogTitle : dialogTitle}
            </StyledTitle>
          </StyledHeader>
        }
        showCloseButton={true}
      />
      <StyledContentWrapper className={contentClass || ''}>
        <StyledSectionedModalContentRoot>
          <StyledRoot>
            <StyledContent animation={sectionCount > 1}>{view}</StyledContent>
          </StyledRoot>
        </StyledSectionedModalContentRoot>
      </StyledContentWrapper>
    </FintasticThemeProvider>
  )
}
