import React, { forwardRef } from 'react'
import AgGridSelectboxCellEditor, {
  AgGridSelectboxCellEditorAllProps,
  AgGridSelectboxCellEditorOption,
} from './AgGridSelectboxCellEditor'
import { QueryKey, useQuery } from 'react-query'

export type AgGridAsyncSelectboxCellEditorProps = {
  getValues: (
    props: AgGridSelectboxCellEditorAllProps,
  ) => Promise<AgGridSelectboxCellEditorOption[]>
  getQueryKey: (props: AgGridSelectboxCellEditorAllProps) => QueryKey
}

export const AgGridAsyncSelectboxCellEditor = forwardRef<
  unknown,
  AgGridAsyncSelectboxCellEditorProps & AgGridSelectboxCellEditorAllProps
>(({ getValues, getQueryKey, ...props }, ref) => {
  const valuesQuery = useQuery<AgGridSelectboxCellEditorOption[]>(
    getQueryKey(props),
    () => getValues(props),
    {
      refetchOnMount: false,
      keepPreviousData: true,
    },
  )

  if (valuesQuery.isLoading) {
    return (
      <span
        style={{
          display: 'inline-block',
          paddingLeft: '12px',
        }}
      >
        Loading...
      </span>
    )
  }

  return (
    <AgGridSelectboxCellEditor
      ref={ref}
      {...props}
      options={valuesQuery.data}
    />
  )
})

export default AgGridAsyncSelectboxCellEditor
