import { styled, Box } from '@mui/material'

export const StyledRoot = styled(Box)`
  width: 100%;
`

export const StyledCont = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: 0 -4px;
`

export const StyledFrom = styled(Box)`
  padding: 0 4px;

  width: 50%;
`

export const StyledTo = styled(Box)`
  padding: 0 4px;

  width: 50%;
`
