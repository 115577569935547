import { GoToCallback, GoBackCallback, PathHistory } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'

const defaultPathHistory: PathHistory = []

export type UseLocationResult = ReturnType<typeof useLocation>

export function useLocation(
  initialPathHistory: PathHistory = defaultPathHistory,
) {
  const [pathHistory, setPathHistory] = useState(initialPathHistory)

  useEffect(() => {
    setPathHistory(initialPathHistory)
  }, [initialPathHistory])

  const goTo = useCallback<GoToCallback>((path, forgetHistory) => {
    setPathHistory((prevHistory) => {
      if (forgetHistory) {
        return [path]
      }
      if (prevHistory[prevHistory.length - 1] === path) {
        return prevHistory
      }
      return [...prevHistory, path]
    })
  }, [])

  const goBack = useCallback<GoBackCallback>(() => {
    setPathHistory((prevHistory) => {
      if (prevHistory.length === 0) {
        return pathHistory
      }
      return prevHistory.slice(0, prevHistory.length - 1)
    })
  }, [pathHistory])

  const reset = useCallback(() => {
    setPathHistory(initialPathHistory)
  }, [initialPathHistory])

  return useMemo(
    () =>
      ({
        path: pathHistory[pathHistory.length - 1] || null,
        goTo,
        goBack,
        reset,
      } as const),
    [goBack, goTo, pathHistory, reset],
  )
}
