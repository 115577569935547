import { isNullish } from '@fintastic/shared/util/functional-programming'

export const BLANK_VALUE_UI_REPRESENTATION = '-'
export const BLANK_VALUE = Symbol('Blank value')

export const isRawBlankValue = isNullish

export const containsBlankValue = (
  value: unknown,
): value is typeof BLANK_VALUE => value === BLANK_VALUE

export const isRowDataFullyBlank = <TData extends Record<string, unknown>>(
  rowData: TData,
  fieldsToCheck?: string[],
): boolean => {
  const keys = fieldsToCheck ?? Object.keys(rowData)

  for (let i = 0; i < keys.length; i++) {
    const v = rowData[keys[i]]
    if (!(containsBlankValue(v) || isRawBlankValue(v))) {
      return false
    }
  }

  return true
}
