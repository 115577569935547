import { Maybe } from '@fintastic/shared/util/types'
import { agGridNumericEditorDefaultNumberMask } from '@fintastic/shared/ui/ag-grid'
import {
  createValueFormatter,
  MetricNumericDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { isRawBlankValue } from '@fintastic/web/util/blanks-and-masked'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { ValueFormatterParams } from 'ag-grid-community'
import { formatNumeric } from '@fintastic/shared/util/formatters'
import { applyValueFormatter } from '@fintastic/shared/ui/grid-framework'
import { GetCellEditorProps } from './types'

export const getNumericCellEditorProps = (
  column: ListColumnWrapper,
): GetCellEditorProps => {
  const dataType = column.dataType()

  const displaySettings = column.displaySettings().unwrap()

  const currency = currencies.find(
    (c) => c.code === column.displaySettings().currency(),
  )

  const valueFormatter = displaySettings
    ? createValueFormatter(dataType as never, displaySettings, currency) ||
      undefined
    : (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          formatNumeric.number(value),
        )

  return {
    valueFormatter,
    cellEditorParams: {
      mask: displaySettings ? undefined : agGridNumericEditorDefaultNumberMask,
      dataType: dataType as MetricNumericDataValueType,
      displaySettings: displaySettings,
      currency: currency,

      parseSourceValue: (value: Maybe<string>) => {
        if (isRawBlankValue(value)) {
          return value
        }

        const parsed = parseFloat(`${value}`)

        if (Number.isNaN(parsed)) {
          return ''
        }

        // IMask does not support doubles if no decimal_places
        if (
          displaySettings?.formatting?.decimal_places === 0 ||
          dataType === 'integer'
        ) {
          return `${Math.round(parsed)}`
        }
        return `${parsed}`
      },
      parseEditedValue: (value: Maybe<string>) => {
        if (isRawBlankValue(value)) {
          return value
        }

        const parsed = parseFloat(`${value}`)

        if (Number.isNaN(parsed)) {
          return ''
        }
        // @todo: percent / 100?

        // IMask does not support doubles if no decimal_places
        if (
          dataType === 'integer' ||
          displaySettings?.formatting?.decimal_places === 0
        ) {
          return `${Math.round(parsed)}`
        }
        return parsed
      },
    },
  }
}
