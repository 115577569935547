import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  CheckCircleIcon,
  CancelIcon,
  WarningIcon,
} from '@fintastic/shared/ui/icons'
import {
  StyledValidationStatusIcon,
  StyledValidationStatusRoot,
  StyledValidationStatusMessage,
} from './ValidationStatus.styled'
import { useTheme } from '@mui/material'
import type { FintasticMuiTheme } from '@fintastic/shared/ui/mui-theme'

export type ValidationStatusProps = {
  isValid: boolean
  errorMessage?: Maybe<string>
  severity?: 'low' | 'high'
}

const VALID_MESSAGE = 'Valid'

// @todo add tests
export const ValidationStatus: React.FC<ValidationStatusProps> = React.memo(
  ({ isValid, errorMessage, severity }) => {
    const theme = useTheme<FintasticMuiTheme>()
    let icon = <CheckCircleIcon fontSize="small" />
    let color = theme.palette.success.dark

    if (!isValid) {
      icon =
        severity === 'low' ? (
          <WarningIcon fontSize="small" />
        ) : (
          <CancelIcon fontSize="small" />
        )
      color =
        severity === 'low'
          ? theme.palette.warning.dark
          : theme.palette.error.dark
    }

    return (
      <StyledValidationStatusRoot
        data-testid="formula-editor-validation-status"
        data-valid={isValid}
      >
        <StyledValidationStatusIcon
          sx={{
            color,
          }}
        >
          {icon}
        </StyledValidationStatusIcon>
        <StyledValidationStatusMessage
          sx={{
            color,
          }}
        >
          {isValid ? VALID_MESSAGE : errorMessage}
        </StyledValidationStatusMessage>
      </StyledValidationStatusRoot>
    )
  },
)

ValidationStatus.displayName = 'ValidationStatus'
