import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { EntityId, VersionDependencyGraph } from '../../types'
import cytoscape from 'cytoscape'
import { cytoscapeFromGraph } from '../cytoscapeFromGraph'
import { uniq } from 'lodash'

export class DependencyGraphWrapper
  implements ImmutableADTWrapper<VersionDependencyGraph>
{
  _rawData: VersionDependencyGraph
  protected cytoscapeInstance: cytoscape.Core

  constructor(graph: VersionDependencyGraph) {
    this._rawData = graph
    this.cytoscapeInstance = cytoscapeFromGraph(graph)
  }

  unwrap(): VersionDependencyGraph {
    return this._rawData
  }

  protected selectNodes(entityIds: EntityId[]): cytoscape.NodeCollection {
    return this.cytoscapeInstance.nodes(
      entityIds.map((id) => `#${id}`).join(', '),
    )
  }

  protected uniqNodeIdsFromMappedCollection(
    nodeId: string | string[],
    collectionMapper: (
      nodes: cytoscape.NodeCollection,
    ) => cytoscape.NodeCollection,
    ignoreSelf = true,
  ): string[] {
    const ids = Array.isArray(nodeId) ? nodeId : [nodeId]

    let result = uniq(
      collectionMapper(this.selectNodes(ids))
        .map((s) => {
          if (s.group() === 'nodes') {
            return s.id()
          }
          return null
        })
        .filter(Boolean) as string[],
    )
    if (ignoreSelf) {
      result = result.filter((id) => !ids.includes(id))
    }

    return result
  }

  /**
   * In a directed graph (sometimes abbreviated as digraph), the edges are directed: that is, they have a direction,
   * proceeding from a source vertex to a sink (or destination) vertex.
   * The sink vertex is a successor of the source, and the source is a predecessor of the sink.
   */
  public successors(
    entityId: EntityId | EntityId[],
    ignoreSelf = true,
  ): EntityId[] {
    return this.uniqNodeIdsFromMappedCollection(
      entityId,
      (nodes) => nodes.successors(),
      ignoreSelf,
    )
  }

  public firstLevelSuccessors(
    entityId: EntityId | EntityId[],
    ignoreSelf = true,
  ): EntityId[] {
    return this.uniqNodeIdsFromMappedCollection(
      entityId,
      (nodes) => nodes.outgoers(),
      ignoreSelf,
    )
  }

  public keepWeightMetricSuccessors(successors: EntityId[]): EntityId[] {
    return uniq(
      this.cytoscapeInstance
        .edges()
        .filter((edge) => {
          return (
            !!edge.data('weight') && successors.includes(edge.data('target'))
          )
        })
        .map((edge) => edge.data('target')),
    )
  }

  public firstLevelPredecessors(
    entityId: EntityId | EntityId[],
    ignoreSelf = true,
  ): EntityId[] {
    return this.uniqNodeIdsFromMappedCollection(
      entityId,
      (nodes) => nodes.incomers(),
      ignoreSelf,
    )
  }

  public hasAtLeastOneSuccessor(
    entityId: EntityId | EntityId[],
    ignoreSelf = true,
  ): boolean {
    return this.firstLevelSuccessors(entityId, ignoreSelf).length > 0
  }
}
