import React, { useCallback, useRef, useState } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { ClickAwayListener } from '@mui/material'
import {
  StyledDropdownButton,
  StyledDropdownPaper,
  VersionsChipsDropdownRoot,
} from './VersionsChipsDropdown.styled'
import { DiffModeSelector } from '../versions-popover/diff-pairs'
import Popover from '@mui/material/Popover'
import { DiffMode } from '@fintastic/web/util/versions'

export type VersionsChipsDropdownProps = {
  mode: DiffMode
  setMode: (mode: DiffMode) => void
}

export const VersionsChipsDropdown: React.FC<VersionsChipsDropdownProps> = ({
  mode,
  setMode,
}) => {
  const anchorEl = useRef<Maybe<HTMLDivElement>>(null)

  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(() => false)
  }, [])

  const handleToggle = useCallback(() => {
    setOpen((v) => !v)
  }, [])

  const handleSetMode = useCallback(
    (mode: DiffMode) => {
      setOpen(() => false)

      setTimeout(() => {
        setMode(mode)
      }, 20)
    },
    [setMode],
  )

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <VersionsChipsDropdownRoot ref={anchorEl}>
        <StyledDropdownButton onClick={handleToggle} size={'small'}>
          vs.
        </StyledDropdownButton>
        <Popover
          anchorEl={anchorEl.current}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StyledDropdownPaper>
            <span className={'arrow-top'}></span>
            <DiffModeSelector mode={mode} setMode={handleSetMode} />
          </StyledDropdownPaper>
        </Popover>
      </VersionsChipsDropdownRoot>
    </ClickAwayListener>
  )
}
