import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type ConfirmRemoveLinesModalProps = {
  open: boolean
  linesCount: number
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmRemoveLinesModal: React.FC<
  ConfirmRemoveLinesModalProps
> = ({ open, linesCount, onConfirm, onCancel }) => (
  <ConfirmationPopup
    open={open}
    onRequestClose={onCancel}
    onConfirm={onConfirm}
    onDecline={onCancel}
    title={'Remove line' + (linesCount > 1 ? 's' : '')}
    autoClose={false}
  >
    <Box width="100%">
      <ErrorAlert
        title={
          'Are you sure you want to remove ' +
          linesCount +
          ' line' +
          (linesCount > 1 ? 's' : '') +
          '?'
        }
        message=""
        severity="warning"
      />
      <Box mt={3} width="100%" display="flex" justifyContent="center">
        <ConfirmationPopup.DeclineButton>
          Cancel
        </ConfirmationPopup.DeclineButton>

        <ConfirmationPopup.ConfirmButton
          sx={{ ml: 1 }}
          variant="contained"
          color="primary"
        >
          {'Remove ' + linesCount + ' line' + (linesCount > 1 ? 's' : '')}
        </ConfirmationPopup.ConfirmButton>
      </Box>
    </Box>
  </ConfirmationPopup>
)
