/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { AppServerConfig } from '../../types'
import type { Maybe } from '@fintastic/shared/util/types'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { State } from './state'
import type { WritableDraft } from '@fintastic/shared/data-access/redux'

const initStart = (state: WritableDraft<State>): void => {
  state.status = 'loading'
  state.error = null
  state.config = undefined
}

export type InitFinishedPayload = {
  error: Maybe<string>
  config?: AppServerConfig
}
export type InitFinished = PayloadAction<InitFinishedPayload>

const initFinished = (
  state: WritableDraft<State>,
  action: InitFinished,
): void => {
  const { error, config } = action.payload
  state.error = error
  if (error !== null) {
    state.status = 'error'
    state.config = undefined
  } else {
    state.status = 'success'
    state.config = config
  }
}

export const reducers = {
  initStart,
  initFinished,
}
