import React, { useMemo } from 'react'
import { DataContext, DataContextValue } from './data-context'
import { MetricAsOption } from '@fintastic/web/util/metrics-and-lists'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { Maybe } from '@fintastic/shared/util/types'
import {
  EditableListWithoutData,
  ListWrapper,
} from '@fintastic/web/data-access/metrics-and-lists'
import { DependencyGraphWrapper } from '@fintastic/web/util/versions'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export type ListSettingsPanelDataProviderProps = {
  children: React.ReactNode
  list: Maybe<EditableListWithoutData>
  dimensionsList: Maybe<VersionDimension[]>
  currenciesList: Maybe<Currency[]>
  metricsList: Maybe<MetricAsOption[]>
  dependencyGraph: Maybe<DependencyGraphWrapper>
  isLoading: boolean
  isLoaded: boolean
  load: () => void
}

export const ListSettingsPanelDataProvider: React.FC<
  ListSettingsPanelDataProviderProps
> = ({
  children,
  list,
  dimensionsList,
  currenciesList,
  metricsList,
  isLoading,
  isLoaded,
  load,
  dependencyGraph,
}) => {
  const dataContextValue = useMemo<DataContextValue>(
    () => ({
      list: list === null ? null : new ListWrapper(list),
      dimensionsList,
      currenciesList,
      metricsList,
      isLoading,
      isLoaded,
      load,
      dependencyGraph,
    }),
    [
      list,
      dimensionsList,
      currenciesList,
      metricsList,
      isLoading,
      isLoaded,
      load,
      dependencyGraph,
    ],
  )

  return (
    <DataContext.Provider value={dataContextValue}>
      {children}
    </DataContext.Provider>
  )
}
