import React, { useMemo } from 'react'
import { CheckboxesList } from '@fintastic/shared/ui/components'
import { dimensionsToCheckboxesOptions } from '../utils/dimensionsToCheckboxesOptions'
import { dimensionsToIds } from '../utils/dimensionsToIds'
import { Dimension } from '@fintastic/shared/util/types'

export type CalculatedMetricDimensionsProps = {
  metricDimensions: Dimension[]
}

export const CalculatedMetricDimensions: React.FC<
  CalculatedMetricDimensionsProps
> = ({ metricDimensions }) => {
  const options = useMemo(
    () => dimensionsToCheckboxesOptions(metricDimensions),
    [metricDimensions],
  )

  const selectedIds = useMemo(
    () => dimensionsToIds(metricDimensions),
    [metricDimensions],
  )

  return (
    <CheckboxesList
      options={options}
      disabledOptions={selectedIds}
      selectedOptions={selectedIds}
    />
  )
}
