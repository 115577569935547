import { QueryClient } from 'react-query'
import { versionsCacheKeys } from './cache-keys'

export async function invalidateVersionDataCache(
  queryClient: QueryClient,
  versionIds: string | string[],
) {
  const ids = Array.isArray(versionIds) ? versionIds : [versionIds]
  return Promise.all(
    ids.map((id) =>
      queryClient.invalidateQueries(versionsCacheKeys.versionData(id)),
    ),
  )
}
