import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'
import React, { useMemo } from 'react'
import { HomePage } from './pages/HomePage'
import { ProtectedRoute } from '@fintastic/web/feature/auth'
import { BoardPage, boardRoutes } from '@fintastic/web/feature/boards'
import {
  routes as versionsRoutes,
  VersionPage,
  VersionsListPage,
  LiveActualsPage,
} from '@fintastic/web/feature/versions'
import { PermissionsPage } from '@fintastic/web/feature/settings'
import { NotFoundPage } from './pages/NotFoundPage'
import { RootRoute } from './RootRoute'

const DimensionManagementPage = React.lazy(
  () => import('@fintastic/web/feature/dimension-management'),
)

const LiveActualsMappingPage = React.lazy(
  () => import('@fintastic/web/feature/live-actuals-mapping'),
)

export const AppRouter: React.FC = () => {
  const router = useMemo(() => {
    const routes: RouteObject[] = [
      {
        path: '/',
        element: <ProtectedRoute component={HomePage} />,
      },
      {
        path: boardRoutes.board(),
        element: <ProtectedRoute component={BoardPage} />,
      },
      {
        path: versionsRoutes.versionsList,
        element: <ProtectedRoute component={VersionsListPage} />,
      },
      {
        path: versionsRoutes.liveActualsPage(),
        element: <ProtectedRoute component={LiveActualsPage} />,
      },
      {
        path: versionsRoutes.version(),
        element: <ProtectedRoute component={VersionPage} />,
      },
      {
        path: '/settings/permissions',
        element: <ProtectedRoute component={PermissionsPage} />,
      },
      {
        path: versionsRoutes.versionLiveActualsMapping(),
        element: <ProtectedRoute component={LiveActualsMappingPage} />,
      },
      {
        path: '/settings/dimensions',
        element: <ProtectedRoute component={DimensionManagementPage} />,
      },
    ]

    routes.push({
      path: '*',
      element: <ProtectedRoute component={NotFoundPage} />,
    })

    return createBrowserRouter([
      {
        path: '/',
        element: <RootRoute />,
        children: routes,
      },
    ])
  }, [])

  return <RouterProvider router={router} />
}
