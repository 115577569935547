/**
 Sagas attached to current duck.
 @see https://redux-saga.js.org/docs/introduction/GettingStarted
 */

import type { AllEffect, Effect } from 'redux-saga/effects'
import { all } from 'redux-saga/effects'

import type { Saga } from 'redux-saga'

/**
 Keep here all "watchers" generators
 @see https://redux-saga.js.org/docs/Glossary#watcherworker
 */
function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([])
}

// here can be any other generators besides watchers
const sagas: Saga[] = [watchersSaga]

// export only array of sagas
export default sagas
