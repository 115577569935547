import React from 'react'
import { NavButton } from '@fintastic/shared/ui/view-layout'
import { Icon } from './Icon'
import { RollUpFunction } from '@fintastic/shared/util/types'
import { resolveRollUpName } from '../../../../../utils/resolveRollUpName'

export type AggregationFunctionNavButtonProps = {
  onClick: () => void
  categoryAggregation: RollUpFunction
  timeAggregation?: RollUpFunction
}

export const AggregationFunctionNavButton: React.FC<
  AggregationFunctionNavButtonProps
> = ({ onClick, categoryAggregation, timeAggregation }) => {
  const functionName =
    resolveRollUpName(categoryAggregation) +
    (timeAggregation
      ? ', ' + resolveRollUpName(timeAggregation) + ' (Time)'
      : '')

  return (
    <NavButton
      onClick={onClick}
      primaryLabel="Aggregation Function"
      secondaryLabel={functionName}
      icon={<Icon />}
    />
  )
}
