import React from 'react'
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { DeleteIcon } from '@fintastic/shared/ui/icons'

export const LabelsEditMenuDeleteButton: React.FC<{
  onClick?: React.MouseEventHandler<HTMLDivElement>
}> = ({ onClick }) => (
  <ListItem disablePadding>
    <ListItemButton onClick={onClick}>
      <ListItemIcon sx={{ minWidth: 32 }}>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Delete" />
    </ListItemButton>
  </ListItem>
)
