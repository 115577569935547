import { useQueryClient } from 'react-query'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import {
  useSubscribeToCalculationProgressEvent,
  useSubscribeToLockerStateChangedEvent,
  useSubscribeToVersionCreatedEvent,
} from '@fintastic/web/data-access/service-pusher'
import { useCallback } from 'react'
import { handleCalcProgressEvent } from './logic/calc-progress-event-handling'
import { invalidateVersionsListCache } from '@fintastic/web/data-access/versions'
import { invalidateVersionUserLockerCache } from '@fintastic/web/data-access/versions'
import { useSubscribeOnCellDataUpdateEvent } from '@fintastic/web/data-access/metric-data-editing'
import { handleCellDataUpdateEvent } from './logic/cell-data-update-event-handling'

export function useCentralisedVersionsEventsListener() {
  const queryClient = useQueryClient()
  const userEmail = useAuthUserInfo()?.email || null

  useSubscribeToCalculationProgressEvent(
    '*',
    useCallback(
      async (event) =>
        handleCalcProgressEvent(event, queryClient, {
          userEmail,
        }),
      [queryClient, userEmail],
    ),
  )

  useSubscribeOnCellDataUpdateEvent(
    '*',
    useCallback(
      async (e) => handleCellDataUpdateEvent(e, queryClient, { userEmail }),
      [queryClient, userEmail],
    ),
  )

  useSubscribeToVersionCreatedEvent(
    '*',
    useCallback(async () => {
      await invalidateVersionsListCache(queryClient)
    }, [queryClient]),
  )

  useSubscribeToLockerStateChangedEvent(
    '*',
    useCallback(
      async (params) => {
        await invalidateVersionUserLockerCache(queryClient, params.version_id)
      },
      [queryClient],
    ),
  )
}
