import {
  ChartSettings,
  defaultChartDecimalPlaces,
  defaultChartGridMode,
  defaultChartLegendFormat,
  defaultChartLegendPosition,
  defaultChartNumberFormat,
  defaultChartTheme,
  defaultChartType,
  defaultChartXAxisAlignment,
  defaultChartXAxisFormat,
} from '@fintastic/web/feature/charts'
import { Maybe } from '@fintastic/shared/util/types'

export const updateChartSettings = (
  oldSettings: Maybe<ChartSettings>,
  newSettings: Partial<ChartSettings>,
) => ({
  type: newSettings.type || oldSettings?.type || defaultChartType,

  theme: newSettings.theme || oldSettings?.theme || defaultChartTheme,

  legendPosition:
    newSettings.legendPosition ||
    oldSettings?.legendPosition ||
    defaultChartLegendPosition,

  legendFormat:
    newSettings.legendFormat ||
    oldSettings?.legendFormat ||
    defaultChartLegendFormat,

  xAxisAlignment:
    newSettings.xAxisAlignment ||
    oldSettings?.xAxisAlignment ||
    defaultChartXAxisAlignment,

  xAxisFormat:
    newSettings.xAxisFormat ||
    oldSettings?.xAxisFormat ||
    defaultChartXAxisFormat,

  gridMode:
    newSettings.gridMode || oldSettings?.gridMode || defaultChartGridMode,

  numberFormat:
    newSettings.numberFormat ||
    oldSettings?.numberFormat ||
    defaultChartNumberFormat,

  decimalPlaces:
    newSettings.decimalPlaces ||
    oldSettings?.decimalPlaces ||
    defaultChartDecimalPlaces,

  dimensions: Array.isArray(newSettings.dimensions)
    ? [...newSettings.dimensions]
    : Array.isArray(oldSettings?.dimensions)
    ? [...oldSettings.dimensions]
    : [],
})
