// @todo add tests
export function scrollContainerToNodeTop(
  container: HTMLElement,
  node: HTMLElement,
  padding = 0,
) {
  const topDiff =
    node.getBoundingClientRect().top - container.getBoundingClientRect().top
  // eslint-disable-next-line no-param-reassign
  container.scrollTop += topDiff - padding
}
