import { ICellRendererParams } from 'ag-grid-community'
import React from 'react'
import { StyledInlineButton } from '../Version.styled'
import { CollapseIcon, ExpandIcon } from '@fintastic/shared/ui/icons'
import { titleFormatter } from '@fintastic/shared/util/formatters'

export type TableNameExpandableGroupRendererProps = ICellRendererParams & {
  checkIsGridOpened: (gridId: string) => boolean
}

/**
 * @deprecated
 * Use TableNameExpandableGroupRenderer from @fintastic/shared/ui/ag-grid instead
 **/
export const TableNameExpandableGroupRenderer = ({
  data,
  value,
  checkIsGridOpened,
}: TableNameExpandableGroupRendererProps) => (
  <>
    <StyledInlineButton>
      {checkIsGridOpened(data?.id || '') ? (
        <CollapseIcon fontSize="small" />
      ) : (
        <ExpandIcon fontSize="small" />
      )}
    </StyledInlineButton>
    {titleFormatter(value)}
  </>
)
