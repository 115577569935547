import {
  IAMResource,
  IAMResourceRestrictionAudience,
  IAMResourceType,
} from '../types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosResponse } from 'axios'

export const getIAMResources = (
  resource_type: IAMResourceType,
  is_restricted?: boolean,
) =>
  axios
    .get<{ result: IAMResource[] }>(endpoints.resources, {
      params: {
        resource_type,
        is_restricted,
      },
    })
    .then((res) => res.data.result)

export const patchIAMResource = (payload: PatchIAMResourcePayload) =>
  axios.patch<void, AxiosResponse<void>, PatchIAMResourcePayload>(
    endpoints.resources,
    payload,
  )

export type PatchIAMResourcePayload = {
  resource_ids: string[]
  resource_type: IAMResourceType
  restricted_for: IAMResourceRestrictionAudience
}
