import React from 'react'
import type {
  WidgetRendererComponent,
  WidgetRenderersMap,
} from '@fintastic/shared/ui/widgets-framework'
import {
  LIST_GRID_WIDGET_TYPE,
  ListGridWidgetContainer,
} from './ListGridWidget'
import {
  METRIC_GRID_WIDGET_TYPE,
  MetricGridWidgetContainer,
} from './MetricGridWidget'
import {
  GENERIC_REPORT_WIDGET_TYPE,
  GenericReportWidgetContainer,
} from './GenericReportWidget'
import { TEXT_WIDGET_TYPE } from './TextWidget/widgetType'
import {
  METRIC_CHART_WIDGET_TYPE,
  MetricChartWidgetContainer,
} from './MetricChartWidget'

export const widgetsRenderers: WidgetRenderersMap = {
  [GENERIC_REPORT_WIDGET_TYPE]: {
    component: GenericReportWidgetContainer as WidgetRendererComponent,
    allowLazyLoading: true,
  },

  [LIST_GRID_WIDGET_TYPE]: {
    component: ListGridWidgetContainer as WidgetRendererComponent,
    allowLazyLoading: true,
  },

  [METRIC_GRID_WIDGET_TYPE]: {
    component: MetricGridWidgetContainer as WidgetRendererComponent,
    allowLazyLoading: true,
  },

  [METRIC_CHART_WIDGET_TYPE]: {
    component: MetricChartWidgetContainer as WidgetRendererComponent,
    allowLazyLoading: true,
  },

  [TEXT_WIDGET_TYPE]: {
    component: React.lazy(
      () => import('./TextWidget'),
    ) as WidgetRendererComponent,
    allowLazyLoading: false,
  },
}
