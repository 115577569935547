import {
  useGlobalDimensions,
  useGlobalTables,
} from '@fintastic/web/data-access/model-globals'
import { useMemo } from 'react'
import { ColumnConfig, DimensionValueTuple, TableConfig } from '../types'
import { useIAMResourcesQuery } from '@fintastic/web/data-access/iam'
import { keyBy } from 'lodash'

export const useGlobalModel = () => {
  const globalDimensions = useGlobalDimensions(true, true, [
    'dynamic',
    'manual',
  ])

  const dimensions = useMemo(() => {
    if (!globalDimensions.data) {
      return []
    }

    return globalDimensions.data.reduce(
      (flat: DimensionValueTuple[], dimension) => [
        ...flat,
        ...dimension.values.map((value) => ({
          id: value.id,
          dimensionId: dimension.id,
          dimension: dimension.label,
          value: value.label,
        })),
      ],
      [],
    )
  }, [globalDimensions.data])

  const { tablesQuery, columnsQuery } = useGlobalTables()
  const metricResourceRestrictionsQuery = useIAMResourcesQuery('metric')

  const tables = useMemo<TableConfig[]>(
    () =>
      (tablesQuery.data || []).map((table) => ({
        id: table.id,
        name: table.label,
        resourceType: table.type,
      })),
    [tablesQuery.data],
  )

  const metricResourceRestrictionsHash = useMemo(
    () => keyBy(metricResourceRestrictionsQuery.data ?? [], 'resource_id'),
    [metricResourceRestrictionsQuery.data],
  )

  const columns = useMemo<ColumnConfig[]>(
    () =>
      (columnsQuery.data || []).map((column) => ({
        id: column.id,
        list_id: column.list_id,
        name: getColumnName(column.id, column.label),
        restriction_audience:
          metricResourceRestrictionsHash[column.id]?.restricted_for ===
          undefined
            ? 'regular_users'
            : metricResourceRestrictionsHash[column.id]?.restricted_for,
      })),
    [columnsQuery.data, metricResourceRestrictionsHash],
  )

  return {
    tables,
    columns,
    dimensions,
    loading:
      globalDimensions.isLoading ||
      metricResourceRestrictionsQuery.isLoading ||
      tablesQuery.isLoading ||
      columnsQuery.isLoading,
  }
}

export const getColumnName = (id: string, label: string) => {
  const columnId = id.split('.')[1]
  return columnId ? `${columnId}.${label}` : label
}
