import React from 'react'
import { HistoryLogEntryChangeMetricValue } from '@fintastic/web/util/history'
import { OldToNewValueTransition } from '../old-and-new-values'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'

export const ChangeMetricValueShortDetails: React.FC<{
  entry: HistoryLogEntryChangeMetricValue
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entry, dimensions }) => (
  <OldToNewValueTransition entry={entry} dimensions={dimensions} />
)
