import { useCallback } from 'react'
import { RemoveColumnAction } from '../types'
import {
  MetricOrListEditingFlow,
  useListColumnsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import {
  useModalState,
  useModalWithParamsState,
} from '@fintastic/shared/util/modal'
import { Maybe } from '@fintastic/shared/util/types'
import { DataContextValue } from '../../data/data-context'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { idLooksLikeColumn } from '@fintastic/web/util/metrics-and-lists'

export function useRemoveColumnFlow(
  { list, dependencyGraph }: DataContextValue,
  flow: Maybe<MetricOrListEditingFlow>,
  {
    requestEntityDeletion,
  }: {
    requestEntityDeletion: (entityId: string) => void
  },
) {
  const { removeColumn } = useListColumnsActions()

  const confirmationPopupState = useModalWithParamsState<{
    columnId: Maybe<string>
  }>({
    initialParams: {
      columnId: null,
    },
  })

  const dependenciesWarningPopupState = useModalState()

  const handleAction = useCallback<RemoveColumnAction>(
    (columnId) => {
      if (!list || flow === null) {
        if (idLooksLikeColumn(columnId)) {
          requestEntityDeletion?.(columnId)
        }
        return
      }

      const column = list.findColumnByClientId(columnId)
      if (!column) {
        return
      }

      if (
        !column.isNewColumn() &&
        dependencyGraph &&
        dependencyGraph.hasAtLeastOneSuccessor([column.id(), list.id()])
      ) {
        dependenciesWarningPopupState.open()
        return
      }

      if (column.isNewColumn()) {
        removeColumn({ columnId })
        return
      }

      confirmationPopupState.openWithParams({
        columnId,
      })
    },
    [
      confirmationPopupState,
      dependenciesWarningPopupState,
      dependencyGraph,
      flow,
      list,
      removeColumn,
      requestEntityDeletion,
    ],
  )

  const handleConfirm = useCallback(() => {
    const { columnId } = confirmationPopupState.params

    if (columnId !== null) {
      removeColumn({ columnId })
    }
  }, [confirmationPopupState.params, removeColumn])

  const popups = (
    <>
      <ConfirmationPopup
        title="Delete this column?"
        open={confirmationPopupState.isOpen}
        onRequestClose={confirmationPopupState.close}
        onConfirm={handleConfirm}
      >
        <ConfirmationPopup.DeclineButton
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
        >
          Cancel
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton>
          Delete column
        </ConfirmationPopup.ConfirmButton>
      </ConfirmationPopup>
      <ConfirmationPopup
        title="Column deletion is not possible due to dependencies."
        description="Please remove this Column from related formula and try again."
        open={dependenciesWarningPopupState.isOpen}
        onRequestClose={dependenciesWarningPopupState.close}
      >
        <ConfirmationPopup.DeclineButton>Close</ConfirmationPopup.DeclineButton>
      </ConfirmationPopup>
    </>
  )

  return {
    action: handleAction,
    popups,
  }
}
