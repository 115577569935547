import React from 'react'
import { SelectedCell, SelectedCellAggregationType } from '../types'
import { Maybe } from '@fintastic/shared/util/types'

const stub = (): never => {
  throw new Error(
    'Setter cannot be called out of SelectedCellAggregationContext',
  )
}

export const SelectedCellAggregationContext =
  React.createContext<SelectedCellAggregationContextValue>({
    selectedCells: [],
    setSelectedCells: stub,
    aggregation: null,
    setAggregation: stub,
  })

SelectedCellAggregationContext.displayName = 'SelectedCellAggregationContext'

export type SelectedCellAggregationContextValue = {
  selectedCells: SelectedCell[]
  setSelectedCells: (nextCells: SelectedCell[]) => void
  aggregation: Maybe<SelectedCellAggregationType>
  setAggregation: (nextAggregation: SelectedCellAggregationType) => void
}
