import {
  BaseGrid,
  BaseGridProps,
  BaseGridRow,
} from '@fintastic/shared/ui/grid-framework'

import React from 'react'
import { useGridContextProp } from '../utils/useGridContextProp'

export const BaseGridWithContext = <T extends BaseGridRow>(
  props: BaseGridProps<T>,
): JSX.Element => {
  const context = useGridContextProp(props.context)

  return <BaseGrid {...props} context={context} />
}
