import type { ValueGetterFunc, ValueGetterParams } from 'ag-grid-community'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  GenericReportTreeRow,
  GenericReportValue,
  GenericReportTreeRowCellValue,
  INVALID_FORMULA_VALUE,
} from '@fintastic/web/util/generic-report'
import { getCellValue } from './cell-value-getter'
import { calculatedRowDiffValueGetter } from './calculated-row-logic'
import {
  BlankOrMaskedValue,
  stringifyBlankOrMaskedValue,
  subtractWithBlanks,
  percentageWithBlanks,
  MathDivideByZeroError,
} from '@fintastic/web/util/blanks-and-masked'
import { DiffOperation } from '../types'

export const makeReportTableTimeDiffValueGetter =
  (
    periodA: string,
    periodB: string,
    reportId: string,
    operation: DiffOperation,
  ): ValueGetterFunc<GenericReportTreeRow> =>
  (
    params: ValueGetterParams<GenericReportTreeRow>,
  ): Maybe<Partial<GenericReportTreeRowCellValue>> => {
    const formula = params.data?.calculatedRow

    if (formula) {
      return calculatedRowDiffValueGetter(
        formula,
        params,
        `amount__${reportId}_1`,
        `amount__${reportId}`,
        operation,
        periodA,
        periodB,
        reportId,
      ) as BlankOrMaskedValue
    }

    const [periodValuesA, periodValuesB] = [periodA, periodB].map((period) =>
      (params.data?.values || []).filter(
        ({ _timestamp }: GenericReportValue) => _timestamp === period,
      ),
    )

    const [a, b] = [periodValuesA, periodValuesB].map((values) =>
      getCellValue(values),
    )

    let finalValue: BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE

    try {
      finalValue =
        operation === 'subtract'
          ? subtractWithBlanks(a, b)
          : percentageWithBlanks(a, b)
    } catch (ex) {
      if (ex instanceof MathDivideByZeroError) {
        finalValue = INVALID_FORMULA_VALUE
      } else {
        throw ex
      }
    }

    return {
      ...params.data,
      values: params.data?.values.map((v) => ({
        ...v,
        uom: operation === 'percentage' ? 'percentage' : v.uom,
      })),
      finalValue,
      toString: () => stringifyBlankOrMaskedValue(finalValue),
    }
  }
