import React, { useRef, useState } from 'react'
import { SxProps, Tooltip, Typography } from '@mui/material'
import useResizeObserver from 'use-resize-observer'

type TypographyWithEllipsisTooltipProps = {
  title: string
  sx?: SxProps
}

export const TypographyWithEllipsisTooltip: React.FC<
  TypographyWithEllipsisTooltipProps
> = ({ title, sx }) => {
  const textRef = useRef<HTMLSpanElement>(null)

  const [hasEllipsis, setHasEllipsis] = useState(false)

  useResizeObserver({
    ref: textRef,
    box: 'border-box',
    onResize: ({ width }) => {
      setHasEllipsis((width ?? 0) < (textRef.current?.scrollWidth ?? 0))
    },
  })

  return (
    <Tooltip
      title={title}
      disableInteractive
      placement={'bottom-start'}
      disableTouchListener={!hasEllipsis}
      disableHoverListener={!hasEllipsis}
    >
      <Typography noWrap ref={textRef} sx={{ ...sx }}>
        {title}
      </Typography>
    </Tooltip>
  )
}
