import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'

export type DataTypeOption = {
  label: string
  value: MetricConfigurableDataValueType
}

export const dataTypeNames: Record<MetricConfigurableDataValueType, string> = {
  number: 'Number',
  integer: 'Integer',
  percentage: 'Percent Number',
  percentage_integer: 'Percent Integer',
  currency: 'Currency',
  boolean: 'Boolean',
  datetime: 'Date',
  string: 'Text',
}

export const dataTypeOptions: Array<DataTypeOption> = [
  {
    label: dataTypeNames.number,
    value: 'number',
  },
  {
    label: dataTypeNames.integer,
    value: 'integer',
  },
  {
    label: dataTypeNames.percentage,
    value: 'percentage',
  },
  {
    label: dataTypeNames.percentage_integer,
    value: 'percentage_integer',
  },
  {
    label: dataTypeNames.currency,
    value: 'currency',
  },
  {
    label: dataTypeNames.boolean,
    value: 'boolean',
  },
  {
    label: dataTypeNames.datetime,
    value: 'datetime',
  },
  {
    label: dataTypeNames.string,
    value: 'string',
  },
]
