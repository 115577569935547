import { Box, styled, TextField } from '@mui/material'

export const StyledTextWithoutIcon = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
`

export const StyledTextWithoutIconField = styled(TextField)`
  transition: all ${({ theme }) => theme.transitions.duration.standard}ms
    ${({ theme }) => theme.transitions.easing.easeInOut};

  .Mui-disabled {
    pointer-events: none;
  }
`
