import { useDispatch, useSelector } from 'react-redux'
import { selectStatuses } from './selectors'
import { useCallback } from 'react'
import { actions } from './index'

export function useAppInitializationSelectStatuses() {
  return useSelector(selectStatuses)
}

export function useAppInitializationStart() {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.emitStart())
  }, [dispatch])
}
