import { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { CaretMovementReason } from './types'

export type CaretState = {
  position: number
  movementReason: Maybe<CaretMovementReason>
}

const defaultState: CaretState = {
  position: 0,
  movementReason: null,
}

export function useCaretState(isEditing = false) {
  const [state, setState] = useState<CaretState>(defaultState)

  const reset = useCallback(() => {
    setState(defaultState)
  }, [])

  const moveCaret = useCallback(
    (position: number, reason: Maybe<CaretMovementReason> = null) => {
      setState({
        position,
        movementReason: reason,
      })
    },
    [],
  )

  useEffect(() => {
    if (!isEditing) {
      reset()
    }
  }, [isEditing, reset])

  return useMemo(
    () => ({
      caretPosition: state.position,
      movementReason: state.movementReason,
      moveCaret,
      resetCaretState: reset,
    }),
    [moveCaret, reset, state.movementReason, state.position],
  )
}
