import { createEmptyMetricObject } from '../common-metric/constructors'
import { MutableWrappedData } from './types'
import { v4 as uuid } from 'uuid'
import { DimensionWithoutLabel } from '@fintastic/shared/util/types'
import { MutableMetricDataWrapper } from '../metric-data'
import { VersionDimension } from '@fintastic/web/util/dimensions'
import {
  resetAggregationFunctionsToNoopIfNeeded,
  restoreDimensionsMetadataFromData,
} from '../common-metric/mutations'
import { getDefaultDimValueIdForCategoryDim } from './utils'

type ColumnConstructorParams = {
  clientOnlyId: string
  columnName: string
  listId: string
  rowDimension: DimensionWithoutLabel
}

export const createNewInputColumn = ({
  clientOnlyId,
  columnName,
  listId,
  rowDimension,
}: ColumnConstructorParams): MutableWrappedData => {
  const column = createEmptyMetricObject(
    columnName,
    'input',
  ) as MutableWrappedData

  column.id = createColumnId(listId, columnName)
  column.newColumn = true
  column.clientOnlyId = clientOnlyId

  const metricData = new MutableMetricDataWrapper(column.data)
  metricData.setDimensions([rowDimension.id])
  metricData.setDimensionValues([Object.keys(rowDimension.values).sort()])
  metricData.regenerateValues(null)

  resetAggregationFunctionsToNoopIfNeeded(column)

  // @todo remove it after migration from dims metadata
  column.metadata.dimensions = [rowDimension]
  restoreDimensionsMetadataFromData(column, [])

  return column
}

export const createNewCalculatedColumn = (
  params: ColumnConstructorParams,
): MutableWrappedData => {
  const column = createNewInputColumn(params)

  column.source = 'calculated'
  column.metadata.formula = ''

  return column
}

export const createDimensionColumn = ({
  dimension,
  ...params
}: ColumnConstructorParams & {
  dimension: VersionDimension
}): MutableWrappedData => {
  if (dimension.type !== 'Category') {
    throw new Error(
      'Only Category dimensions allowed to be used in dimension columns',
    )
  }

  const column = createNewInputColumn(params)
  column.metadata.value.dimension_id = dimension.id
  column.metadata.value.type = 'dimension'
  const data = new MutableMetricDataWrapper(column.data)
  data.regenerateValues(getDefaultDimValueIdForCategoryDim(dimension))
  resetAggregationFunctionsToNoopIfNeeded(column)

  // @todo remove it after migration from dims metadata
  column.metadata.dimensions.push(dimension)

  return column
}

export const createColumnId = (listId: string, label: string) =>
  `${listId}.${label}`

// @todo revisit if we really need it
export const createClientOnlyColumnId = (listId: string) =>
  `${listId}.${uuid()}`
