import { MetricAsOption } from '@fintastic/web/util/metrics-and-lists'
import { createContext, useContext } from 'react'

export type DataContextValue = {
  metrics: MetricAsOption[]
}

export const DataContext = createContext<DataContextValue>({
  metrics: [],
})

export function useDataContext() {
  return useContext(DataContext)
}
