import { WEIGHTED_AVERAGE_WEIGHT_COLUMN_POSTFIX } from './const'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  createFieldKey,
  KEY_DELIMITER,
} from '@fintastic/web/util/metrics-and-lists'

// @todo add tests
export function createWeightedAverageWeightFieldKey(
  versionId: string,
  metricId: string,
  period: Maybe<string> = null,
): string {
  return `${createFieldKey(
    versionId,
    metricId,
    period,
  )}${KEY_DELIMITER}${WEIGHTED_AVERAGE_WEIGHT_COLUMN_POSTFIX}`
}
