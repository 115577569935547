import { QueryClient, useQuery } from 'react-query'
import { queryKeys } from './query-keys'
import { getMyUncompletedTasks } from '@fintastic/web/data-access/calc'

export const useLoadMyUncompletedTasks = (enabled: boolean) =>
  useQuery(
    queryKeys.myUncompletedTasks,
    async () => {
      const result = await getMyUncompletedTasks()
      return result.data.pending
    },
    {
      refetchInterval: 60 * 1000 * 0.05, // @todo correct when BE integration is ready
      refetchOnMount: false,
      cacheTime: 60 * 1000,
      enabled,
    },
  )

export const invalidateMyUncompletedTasks = (qc: QueryClient) =>
  qc.invalidateQueries(queryKeys.myUncompletedTasks)
