import React from 'react'
import { BaseTimeDimensionContextValue } from '../types'

const stub = (): never => {
  throw new Error('BaseTimeDimensionContext is not initialised')
}

// Initial value is not supposed to be ever used
export const BaseTimeDimensionContext =
  React.createContext<BaseTimeDimensionContextValue>({
    baseTimeDimensionId: null,
    setBaseTimeDimensionId: stub,
    type: 'local',
  })

BaseTimeDimensionContext.displayName = 'BaseTimeDimensionContext'
