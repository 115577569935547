import { Button, styled, Typography } from '@mui/material'

export const StyledEnabled = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,

  'paddingLeft': 4,
  'paddingRight': 0,
  'marginRight': -4,

  'minWidth': 'auto',

  'color': theme.palette.text.secondary,
  'borderRadius': 4,

  '.MuiButton-endIcon': {
    margin: 0,
    color: theme.palette.black.main,
  },
}))

export const StyledDisabled = styled(Typography)(({ theme }) => ({}))
