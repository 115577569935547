import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DimensionIcon = createSvgIcon(
  <>
    <path
      d="M22.91 6.953 12.7 1.672a1.543 1.543 0 0 0 -1.416 0L1.076 6.953a0.615 0.615 0 0 0 0 1.094l10.209 5.281a1.543 1.543 0 0 0 1.416 0L22.91 8.047a0.616 0.616 0 0 0 0 -1.094Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    ></path>
    <path
      d="m0.758 12.75 10.527 5.078a1.543 1.543 0 0 0 1.416 0l10.557 -5.078"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    ></path>
    <path
      d="m0.758 17.25 10.527 5.078a1.543 1.543 0 0 0 1.416 0l10.557 -5.078"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    ></path>
  </>,
  'Dimension',
)
