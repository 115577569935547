import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { ModelExplorerIcon } from '@fintastic/shared/ui/icons'

export type ModelExplorerVersionSelectorDisabledProps = {
  reason?: string
}

export const ModelExplorerVersionSelectorUIDisabled: React.FC<
  ModelExplorerVersionSelectorDisabledProps
> = ({ reason }) => (
  <Tooltip
    enterNextDelay={700}
    enterDelay={700}
    leaveDelay={0}
    title={reason || 'Version Explorer is not available in design mode'}
    disableInteractive={true}
    placement="top"
  >
    <span>
      <IconButton data-testid="GoVersionExplorerUnavailable" disabled={true}>
        <ModelExplorerIcon sx={{ width: 18 }} />
      </IconButton>
    </span>
  </Tooltip>
)
