import { QueryClient } from 'react-query'
import { VersionUserLock } from '@fintastic/web/util/versions'
import { versionsCacheKeys } from './cache-keys'

export const switchVersionUserLockers = (
  queryClient: QueryClient,
  versionsIds: string[],
  state: 'calc' | 'none',
) => {
  versionsIds.forEach((versionId) => {
    const data: VersionUserLock =
      state === 'calc'
        ? {
            ops: 'calc',
          }
        : {}
    queryClient.setQueryData(versionsCacheKeys.versionUserLock(versionId), data)
  })
}
