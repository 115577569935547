import { settingsIcon } from './fintastic-settings'
import { columnsIcon } from './fintastic-columns'
import { rowsIcon } from './fintastic-rows'
import { IconDefinition } from './types'

const iconsList = [settingsIcon, columnsIcon, rowsIcon] as const

export const resolveFintasticAgIconKey = (icon: AgGridFintasticIconName) =>
  `fintastic-${icon}`

export const getFintasticAgIconsRecord = () =>
  Object.fromEntries(
    iconsList.map((icon) => [
      resolveFintasticAgIconKey(icon.name),
      icon.makeIcon(),
    ]),
  )

export type AgGridFintasticIconName = typeof iconsList[number]['name']

export const fintasticAgIcons = Object.fromEntries(
  iconsList.map((icon) => [icon.name, icon]),
) as {
  [Name in AgGridFintasticIconName]: IconDefinition<Name>
}
