import { createContext, useContext } from 'react'
import { Column } from './types'

export type ColumnsPanelContextValue = {
  columns: Column[]
  hiddenColumns: string[]
  defaultHiddenColumns: string[]
  onChangeVisibility: (hiddenColumns: string[]) => void
}

const stub = () => {
  throw new Error('columns panel context is not initialised')
}

export const ColumnsPanelContext = createContext<ColumnsPanelContextValue>({
  columns: [],
  hiddenColumns: [],
  defaultHiddenColumns: [],
  onChangeVisibility: stub,
})

export const useColumnsPanelContext = () => useContext(ColumnsPanelContext)
