import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { CalendarDatePickerConfigItem } from '../types'
import { sortBy } from 'lodash'
import { RESOLUTIONS_ORDERED_MAP } from './const'

export const sortTimeDimensionByResolution = (
  timeDimensions: TimeDimensionId[],
  calendarConfig: Array<
    Pick<CalendarDatePickerConfigItem, 'resolution' | 'dimension_id'>
  >,
): TimeDimensionId[] =>
  sortBy(timeDimensions, (timeDimensionId) => {
    const resolution = calendarConfig.find(
      (configItem) => configItem.dimension_id === timeDimensionId,
    )?.resolution

    if (!resolution) {
      throw new Error(
        'Could not find resolution in sortTimeDimensionByResolution',
      )
    }

    return RESOLUTIONS_ORDERED_MAP[resolution]
  })
