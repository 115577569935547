import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { usePeriodSelectorContextCalendarConfig } from '../period-selector-context'
import { useMemo } from 'react'
import { getCommomBaseTimeDimension } from '../utils'

export const useCommonBaseDimensionByDimensionIds = (
  timeDimensionIds: TimeDimensionId[],
): Maybe<TimeDimensionId> => {
  const calendarConfig = usePeriodSelectorContextCalendarConfig({
    limitToSelectedEntitiesOnly: false,
  })

  return useMemo<Maybe<TimeDimensionId>>(() => {
    if (!calendarConfig?.length || !timeDimensionIds?.length) {
      return null
    }

    return getCommomBaseTimeDimension(timeDimensionIds, calendarConfig)
  }, [calendarConfig, timeDimensionIds])
}
