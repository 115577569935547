import { RowNode } from 'ag-grid-community'

// Originally form AG Grid sources
export const comparatorWithBlanks = (
  valueA: any,
  valueB: any,
  nodeA: RowNode<any>,
  nodeB: RowNode<any>,
  accentedCompare: boolean,
) => {
  // Support blanks
  if (typeof valueA === 'symbol') {
    valueA = null
  }
  if (typeof valueA === 'symbol') {
    valueB = null
  }

  const valueAMissing = valueA == null
  const valueBMissing = valueB == null
  // this is for aggregations sum and avg, where the result can be a number that is wrapped.
  // if we didn't do this, then the toString() value would be used, which would result in
  // the strings getting used instead of the numbers.
  if (valueA && valueA.toNumber) {
    valueA = valueA.toNumber()
  }
  if (valueB && valueB.toNumber) {
    valueB = valueB.toNumber()
  }
  if (valueAMissing && valueBMissing) {
    return 0
  }
  if (valueAMissing) {
    return -1
  }
  if (valueBMissing) {
    return 1
  }
  function doQuickCompare(a: any, b: any) {
    return a > b ? 1 : a < b ? -1 : 0
  }
  if (typeof valueA !== 'string') {
    return doQuickCompare(valueA, valueB)
  }
  if (!accentedCompare) {
    return doQuickCompare(valueA, valueB)
  }
  try {
    // using local compare also allows chinese comparisons
    return valueA.localeCompare(valueB as string)
  } catch (e) {
    // if something wrong with localeCompare, eg not supported
    // by browser, then just continue with the quick one
    return doQuickCompare(valueA, valueB)
  }
}
