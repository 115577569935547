import { useSyncDeeplinkValue } from '@fintastic/web/util/deeplink'
import { useCallback } from 'react'

/**
 * When view changes and we cannot gurantee cell is still available after such a change, we have to reset thread
 * Let's user changes version, and we still keep thread id to previous version
 * This leads to falsy warning
 */
export const useResetThreadId = () => {
  const [, setThreadId] = useSyncDeeplinkValue<number>({
    key: 'thread_id',
    defaultValue: 0,
  })

  return useCallback(() => {
    setThreadId(0)
  }, [setThreadId])
}
