import {
  StyledExportBackdrop,
  StyledExportProgress,
} from './ExportWithComments.styled'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { DataExportParams } from '../types'
import {
  useAllThreadsBulk,
  useBoardId,
} from '@fintastic/web/data-access/comments'
import { useReadDeeplinkValue } from '@fintastic/web/util/deeplink'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useIsMountedRef } from '@fintastic/shared/util/hooks'
import { exportGrid } from '../utils/grid-export'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'

export type ExportWithCommentsDataProps = {
  exportParams: DataExportParams
  deeplinkWidgetId: string
  onClose: () => void
}

export const ExportWithCommentsData: React.FC<ExportWithCommentsDataProps> = ({
  exportParams,
  deeplinkWidgetId,
  onClose,
}) => {
  const [shouldExport, setShouldExport] = useState(false)

  const prepared = useRef(false)
  const processed = useRef(false)

  const boardId = useBoardId()

  const { data: labels, isLoading: labelsLoading } =
    useLabelsListQuery('collaboration')

  const { data: threads, isLoading } = useAllThreadsBulk(
    'board' + boardId,
    false,
  )

  const monthOverMonthEnabled = useReadDeeplinkValue<boolean>(
    `w${deeplinkWidgetId}_mom`,
  )

  const isMounted = useIsMountedRef()

  useLayoutEffect(() => {
    if (
      isLoading ||
      labelsLoading ||
      !threads ||
      !isMounted.current ||
      prepared.current
    ) {
      return
    }

    prepared.current = true
    setTimeout(() => {
      if (isMounted.current) {
        setShouldExport(() => true)
      }
    }, 100)
  }, [
    threads,
    onClose,
    prepared,
    isLoading,
    labelsLoading,
    isMounted,
    exportParams,
  ])

  useLayoutEffect(() => {
    if (!shouldExport || processed.current) {
      return
    }
    processed.current = true
    try {
      exportGrid({ ...exportParams, threads, monthOverMonthEnabled, labels })
    } catch (e) {
      toast.error(e?.toString() || 'Unknown error')
    }
    setTimeout(() => {
      if (isMounted.current) {
        onClose()
      }
    }, 1200)
  }, [
    shouldExport,
    threads,
    onClose,
    exportParams,
    monthOverMonthEnabled,
    isMounted,
    processed,
  ])

  return (
    <StyledExportBackdrop open={true}>
      <StyledExportProgress />
    </StyledExportBackdrop>
  )
}
