import React, { useCallback } from 'react'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { Currency } from '@fintastic/shared/data-access/currencies'
import {
  ChangeColumnValueDataFormattingAction,
  ChangeColumnValueDataTypeAction,
  ChangeColumnCurrencySignPositionAction,
} from '../../connection/actions/types'
import { ValueDataFormatSettings } from '../../../shared/settings/value-data-format'
import { ChangeDataTypeCallback } from '../../../shared/settings/value-data-format/data-type/types'
import {
  ChangeCurrencySignPositionCallback,
  ChangeFormattingCallback,
} from '../../../shared/settings/value-data-format/formatting/types'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ListColumnWrapper,
  MetricOrListEditingFlow,
} from '@fintastic/web/data-access/metrics-and-lists'

export type ColumnValueDataFormatSettingsProps = {
  column: ListColumnWrapper
  currenciesList: Currency[]
  onChangeDataType: ChangeColumnValueDataTypeAction
  onChangeFormatting: ChangeColumnValueDataFormattingAction
  onChangeCurrencySignPosition: ChangeColumnCurrencySignPositionAction
  readonly: boolean
  editingFlow: Maybe<MetricOrListEditingFlow>
}

export const ColumnValueDataFormatSettings: React.FC<
  ColumnValueDataFormatSettingsProps
> = ({
  column,
  onChangeFormatting,
  onChangeDataType,
  onChangeCurrencySignPosition,
  readonly,
  currenciesList,
  editingFlow,
}) => {
  const handleChangeDataType = useCallback<ChangeDataTypeCallback>(
    (newDataType, newCurrency) => {
      onChangeDataType(column.clientOnlyId(), newDataType, newCurrency)
    },
    [column, onChangeDataType],
  )

  const handleChangeFormatting = useCallback<ChangeFormattingCallback>(
    (formatting) => {
      onChangeFormatting(column.clientOnlyId(), formatting)
    },
    [column, onChangeFormatting],
  )

  const handleChangeCurrencySignPosition =
    useCallback<ChangeCurrencySignPositionCallback>(
      (position) => {
        onChangeCurrencySignPosition(column.clientOnlyId(), position)
      },
      [column, onChangeCurrencySignPosition],
    )

  return (
    <ValueDataFormatSettings
      displaySettings={column.displaySettings()}
      allowDataTypeConversions={column.isNewColumn()}
      dataType={column.dataType() as MetricConfigurableDataValueType}
      readonly={readonly}
      onChangeDataType={handleChangeDataType}
      onChangeFormatting={handleChangeFormatting}
      onChangeCurrencySignPosition={handleChangeCurrencySignPosition}
      currenciesList={currenciesList}
      flow={editingFlow}
    />
  )
}
