import { useCallback, useMemo } from 'react'
import {
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { MetricGridRow } from '../../types'
import { updateTotalRowData } from './updateTotalRowData'
import { ValueColumnFieldAndRollUp } from '../grid-columns/types'

// @todo add tests
export function useTotalLine(
  enabled: boolean,
  valueColumnsWithRollups: ValueColumnFieldAndRollUp[],
) {
  const handleUpdateTotalRow = useCallback(
    (api: GridApi<MetricGridRow>) => {
      if (!enabled) {
        return
      }
      updateTotalRowData(api, valueColumnsWithRollups)
    },
    [enabled, valueColumnsWithRollups],
  )

  return useMemo(
    () =>
      ({
        updateTotalRowData: handleUpdateTotalRow,
        eventHandlers: {
          onGridReady: (event: GridReadyEvent<MetricGridRow>) => {
            handleUpdateTotalRow(event.api)
          },
          onFilterChanged: (event: FilterChangedEvent<MetricGridRow>) => {
            handleUpdateTotalRow(event.api)
          },
          onRowDataUpdated: (event: RowDataUpdatedEvent<MetricGridRow>) => {
            handleUpdateTotalRow(event.api)
          },
        },
      } as const),
    [handleUpdateTotalRow],
  )
}
