import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import Popover from '@mui/material/Popover'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const ChipStyled = styled('div')<{
  active: boolean
  populated: boolean
}>(
  ({ active, populated }) => `
  display: flex;
  justify-items: center;
  border-radius: 16px;
  border: 1px solid rgba(51, 46, 42, 0.12);
  color: #332e2a;
  padding-left: 6px;
  padding-right: 2px;
  cursor: pointer;
  white-space: nowrap;

  svg {
    opacity: 0.6;
    fill: #524A42;
  }

  &:hover {
    border-color: rgb(51, 46, 42);
    color: #332e2a;

    svg {
      opacity: 1;
      fill: #524A42;
    }
  }

  ${
    populated &&
    `
    background: rgba(169, 68, 1, 0.06);
    border-color: rgba(169, 68, 1, 1);
    color: #A94401;

    svg {
      opacity: 1;
      fill: #A94401;
    }
  `
  }

  ${
    active &&
    `
    background: rgba(51, 46, 42, 0.24);
    border-color: rgb(51, 46, 42);
    color: #332e2a;

    svg {
      opacity: 1;
      fill: #524A42;
    }
  `
  }
`,
)

export const StyledChipDropdownMenuPopover = styled(Popover, {
  shouldForwardProp: (prop) => prop !== 'withSearch',
})<{ withSearch?: boolean }>`
  .MuiPopover-paper {
    ${styledMixins.thinVScrollbar()};
    width: ${({ withSearch }) => (withSearch ? '275px' : 'auto')};
  }
`

export const ChipLabelStyled = styled('span')`
  line-height: 16px;
  padding: 4px;
`

export const StyledSearchInputContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1.7)} 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`

export const StyledNothingToDisplay = styled(Typography)`
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`
