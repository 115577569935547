import { createContext, useContext } from 'react'
import { SectionedModalSectionDefinition } from '../types'

export type SectionedModalRoutesContextValue = {
  routes: SectionedModalSectionDefinition[]
  title?: string
  path: string
  allowNavigation?: boolean // overall navigation - displaying [<] button
  allowLeaveSection?: (
    sectionPath: SectionedModalSectionDefinition['path'],
  ) => boolean // local navigation - disabling [<] button
  onRequestClose: () => void

  sectionCount: number
  isFirstSection: boolean
}

const stub = () => {
  throw new Error('SectionedModalNavigationRoutesValue is not initialised')
}

export const SectionedModalRoutesContext =
  createContext<SectionedModalRoutesContextValue>({
    routes: [],
    title: '',
    path: '',
    onRequestClose: stub,
    allowNavigation: true,
    allowLeaveSection: undefined,
    sectionCount: 0,
    isFirstSection: false,
  })

export function useSectionedModalRoutesContext() {
  return useContext(SectionedModalRoutesContext)
}
