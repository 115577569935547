import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { BoardRenameForm } from '../../forms/BoardRenameForm'
import { Board } from '../../../types'
import { Maybe } from '@fintastic/shared/util/types'

export type BoardRenameModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  boardId?: Maybe<Board['id']>
}

export const BoardRenameModal: React.FC<BoardRenameModalProps> = (props) => {
  const { isOpen, onRequestClose, boardId } = props

  return (
    <Modal open={isOpen} onRequestClose={onRequestClose} title="Rename Board">
      {boardId && (
        <BoardRenameForm closeParentModal={onRequestClose} boardId={boardId} />
      )}
    </Modal>
  )
}
