import { Maybe } from '@fintastic/shared/util/types'
import { RefObject, useEffect, useState } from 'react'

export function useMenuWidth(
  anchorElementRef: RefObject<Maybe<HTMLElement>>,
  minWidth?: number,
) {
  const [width, setWidth] = useState<Maybe<number>>(null)

  useEffect(() => {
    if (anchorElementRef.current) {
      const anchorElementWidth =
        anchorElementRef.current.getBoundingClientRect().width

      setWidth(
        Math.floor(
          minWidth !== undefined
            ? Math.max(anchorElementWidth, minWidth)
            : anchorElementWidth,
        ),
      )
      return
    }
    setWidth(null)
  }, [anchorElementRef, minWidth])

  return width
}
