import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { DimensionEntityDefinition } from '../../types'
import {
  DimensionId,
  wrapDimension,
  WrappedDimension,
} from '@fintastic/web/util/dimensions'

export class DimensionEntityDefinitionWrapper
  implements ImmutableADTWrapper<DimensionEntityDefinition>
{
  readonly _rawData: DimensionEntityDefinition
  protected readonly wrappedDimension: WrappedDimension

  constructor(data: DimensionEntityDefinition) {
    this._rawData = data
    this.wrappedDimension = wrapDimension(data)
  }

  unwrap(): DimensionEntityDefinition {
    return this._rawData
  }

  public type = 'dimension' as const

  public get id(): DimensionId {
    return this.wrappedDimension.id
  }

  public get label(): DimensionId {
    return this.wrappedDimension.label
  }

  public get dimension(): WrappedDimension {
    return this.wrappedDimension
  }
}
