import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useGroupList } from './hooks/useGroupList'
import { GroupId } from './types'

export const GroupSelector: React.FC<{
  onGroupChange: (event: SelectChangeEvent) => void
  currentGroup: GroupId
  label: string
  variant?: 'standard' | 'outlined' | 'filled'
  minWidth?: number | string
}> = ({
  onGroupChange,
  currentGroup,
  label,
  variant = 'standard',
  minWidth = 360,
}) => {
  const { groups } = useGroupList()

  return (
    <div>
      <FormControl variant={variant} sx={{ m: 1, minWidth }}>
        <InputLabel id="permissions-group-select-label">
          {label}
        </InputLabel>
        <Select
          labelId="permissions-group-select-label"
          id="permissions-group-select"
          value={currentGroup}
          onChange={onGroupChange}
          label={label}
        >
          {groups.map((group) => (
            <MenuItem key={group.name} value={group.name}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
