import React from 'react'
import { CustomisedMuiDatePicker } from '@fintastic/shared/ui/components'
import { Maybe } from '@fintastic/shared/util/types'
import dayjs from 'dayjs'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { pickersLayoutClasses } from '@mui/x-date-pickers'
import { StyledDatePickerContainer } from './DateRange.styled'

export type DatePickerProps = {
  disabled?: boolean
  label: string
  date: dayjs.Dayjs
  onChange: (date: Maybe<dayjs.Dayjs>) => void
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  date,
  onChange,
  disabled,
}) => (
  <StyledDatePickerContainer data-testid="date-picker">
    <CustomisedMuiDatePicker
      views={['year', 'month', 'day']}
      label={label}
      sx={{ minWidth: 120, width: '100%' }}
      format="DD-MMM-YYYY"
      disabled={disabled}
      slotProps={{
        textField: { size: 'small' },
        layout: {
          sx: {
            [`.${pickersLayoutClasses.contentWrapper}`]: {
              'button': {
                fontSize: '12px',
              },
              '.MuiDayCalendar-header span': {
                fontSize: '14px',
              },
            },
          },
        },
        actionBar: {
          actions: ['today'],
        },
      }}
      slots={{
        openPickerIcon: ArrowDropDownOutlinedIcon,
      }}
      value={date}
      onChange={onChange}
      maxDate={dayjs(new Date())}
      openTo="day"
    />
  </StyledDatePickerContainer>
)
