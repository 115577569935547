import { ColDef } from 'ag-grid-community'
import { ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'

export type ColumnIdParts = {
  field: string
  versionId: string
  listId: string
  columnName: string
  isDimension: boolean
  isTimeBreakdown: boolean

  listTimeBreakdownDimId?: string
  timeBreakdownDimId?: string
  timeBreakdownDims?: string[]
  timeBreakdownField?: string

  isCalculated: boolean
  isText?: boolean
}

export type ExtendedDef = {
  columnIdParts: ColumnIdParts
}

export type ExtendedColDef = ColDef & ExtendedDef

export type ExtendedColGroupDef = ColGroupDef & ExtendedDef

export type AddLinesColDef = ColDef | ExtendedColDef | ExtendedColGroupDef

export function isExtendedColDef(
  colDef: ColDef,
): colDef is ExtendedColGroupDef {
  return (colDef as ExtendedColGroupDef).columnIdParts !== undefined
}

export type AddLinesColDefWithChildren = ColDef &
  ExtendedDef & { children?: (ColDef & ExtendedDef)[] }

export type AddLinesColDefs =
  | ColDef[]
  | ExtendedColDef[]
  | ExtendedColGroupDef[]
