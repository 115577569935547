import { LayoutSidebarConfig } from '../types'
import { createContext, useContext, useMemo } from 'react'

export type AppLayoutContextValue = {
  sidebarConfig: LayoutSidebarConfig
}

const defaultContextValue: AppLayoutContextValue = {
  sidebarConfig: {
    top: [],
    footer: [],
  },
}

const context = createContext<AppLayoutContextValue>(defaultContextValue)
export const {
  Provider: AppLayoutContextProvider,
  Consumer: AppLayoutContextConsumer,
} = context

export function useAppLayoutContext() {
  return useContext(context)
}

export function useAppLayoutContextValue(
  sidebarConfig: LayoutSidebarConfig,
): AppLayoutContextValue {
  return useMemo<AppLayoutContextValue>(
    () => ({
      sidebarConfig,
    }),
    [sidebarConfig],
  )
}
