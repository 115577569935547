import React, { useMemo } from 'react'
import { useValidationHighlightContext } from './validation-highlight-context'
import { getRangeHash } from './getRangeHash'
import {
  StyledValidationErrorsPopperContent,
  StyledValidationErrorsPopperRoot,
} from './ValidationErrorsPopper.styled'
import {
  PropertiesList,
  PropertiesListProperty,
} from '@fintastic/shared/ui/components'

// @todo add tests
export const ValidationErrorsPopper: React.FC = () => {
  const { highlightingToken, ranges, enabled } = useValidationHighlightContext()
  const errorText =
    ranges[getRangeHash(highlightingToken?.start, highlightingToken?.stop)]

  const properties = useMemo<PropertiesListProperty[]>(
    () => [
      {
        key: 'main',
        label: 'Error',
        labelSx: {
          color: (theme) => theme.palette.error.dark,
        },
        items: [
          {
            key: 'main',
            value: errorText,
          },
        ],
      },
    ],
    [errorText],
  )

  if (!enabled) {
    return null
  }

  return (
    <StyledValidationErrorsPopperRoot
      anchorEl={highlightingToken?.node}
      open={!!highlightingToken?.node && !!errorText}
      placement="top"
      data-testid="formula-editor-validation-error-popper"
    >
      <StyledValidationErrorsPopperContent>
        <PropertiesList properties={properties} variant="compact" />
      </StyledValidationErrorsPopperContent>
    </StyledValidationErrorsPopperRoot>
  )
}
