import React from 'react'
import { Grid } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { UseVersionDateRangeInputStateResult } from './useVersionDateRangeInputState'
import { CustomisedMuiDatePicker } from '@fintastic/shared/ui/components'
import { Dayjs } from 'dayjs'

type Props = UseVersionDateRangeInputStateResult & {
  horizontalLayout?: boolean
  fullWidth?: boolean
  minEndDate?: Dayjs
  maxEndDate?: Dayjs
  disabled?: boolean
}

export const VersionDateRangeInput: React.FC<Props> = ({
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  horizontalLayout,
  fullWidth,
  minEndDate,
  maxEndDate,
  disabled
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid
      container
      spacing={3}
      sx={{
        'py': 3,
        'flexDirection': horizontalLayout ? 'row' : 'column',
        '& .MuiTextField-root': {
          width: horizontalLayout || fullWidth ? '100%' : 'auto',
        },
      }}
    >
      <Grid item sm={horizontalLayout ? 6 : undefined}>
        <CustomisedMuiDatePicker
          views={['year', 'month']}
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          maxDate={endDate || undefined}
          openTo="month"
          disabled
        />
      </Grid>
      <Grid item sm={horizontalLayout ? 6 : undefined}>
        <CustomisedMuiDatePicker
          views={['year', 'month']}
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          minDate={minEndDate || startDate || undefined}
          maxDate={maxEndDate}
          openTo="month"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  </LocalizationProvider>
)
