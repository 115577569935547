import { useCommonBaseDimensionByDimensionIds } from '@fintastic/web/util/period-selector'
import { useEffect } from 'react'
import { useSetStaticWidgetBaseTimeDimension } from '@fintastic/web/data-access/base-time-dimension'
import type { TimeDimensionId } from '@fintastic/web/util/dimensions'

export const useSetBaseTimeDimensionEffect = (
  baseTimeDimensionIds: TimeDimensionId[],
  isLoaded: boolean,
): void => {
  const commonBaseTimeDimension =
    useCommonBaseDimensionByDimensionIds(baseTimeDimensionIds)
  const setStaticWidgetBaseTimeDimension = useSetStaticWidgetBaseTimeDimension()

  useEffect(() => {
    if (!isLoaded) {
      return
    }
    setStaticWidgetBaseTimeDimension(commonBaseTimeDimension)
  }, [commonBaseTimeDimension, isLoaded, setStaticWidgetBaseTimeDimension])
}
