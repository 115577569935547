import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { WrappedData } from './types'
import { DeepReadonly, Dimension, Maybe } from '@fintastic/shared/util/types'
import { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'
import { ListColumnWrapper } from '../list-column'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { sum } from 'lodash'

// @todo add tests
export class ListWrapper implements ImmutableADTWrapper<WrappedData> {
  readonly _rawData: DeepReadonly<WrappedData>
  private _columnsCache: Maybe<ListColumnWrapper[]> = null

  constructor(data: WrappedData) {
    this._rawData = data
  }

  unwrap(): WrappedData {
    return this._rawData
  }

  id(): string {
    return this._rawData.id
  }

  label(): string {
    return this._rawData.label
  }

  source(): MetricOrListSource {
    return this._rawData.source
  }

  columns(): ListColumnWrapper[] {
    if (this._columnsCache === null) {
      this._columnsCache = this._rawData.metrics.map(
        (m) => new ListColumnWrapper(m),
      )
    }
    return this._columnsCache
  }

  rowDimensionId(): string {
    return this._rawData.row_dim
  }

  formula(): Maybe<string> {
    return this._rawData.metadata.formula
  }

  baseTimeDimension(): Maybe<TimeDimensionId> {
    return this._rawData.metadata.base_time_dimension_id
  }

  isCalculated(): boolean {
    return this.source() === 'calculated'
  }

  columnsAreEmpty(): boolean {
    return this._rawData.metrics.length === 0
  }

  hasColumn(predicate: (c: ListColumnWrapper) => boolean): boolean {
    return this.columns().some(predicate)
  }

  findColumn(
    predicate: (c: ListColumnWrapper) => boolean,
  ): Maybe<ListColumnWrapper> {
    return this.columns().find(predicate) || null
  }

  findColumnByClientId(id: string) {
    return this.findColumn((c) => c.clientOnlyId() === id)
  }

  extractRowDimension(): Maybe<Dimension> {
    /**
     * For now list doesn't contain the row dimension,
     * so we assume that row dimensions are the same in every column
     */

    const columns = this.columns()

    if (this.columnsAreEmpty() || columns[0].data().dimensionsAreEmpty()) {
      return null
    }

    const rowDim = columns[0]
      .data()
      .findDimension((did) => did === this.rowDimensionId())
    if (!rowDim) {
      return null
    }

    return {
      id: this.rowDimensionId(),
      type: 'Range',
      label: '',
      values: Object.fromEntries(rowDim.valueIds.map((d) => [d, d])),
    }
  }

  simulateRowDimension(): Dimension {
    const dimValueId = `${this.rowDimensionId()}._new-${new Date().getTime()}`

    return {
      id: this.rowDimensionId(),
      type: 'Range',
      label: '',
      values: {
        [dimValueId]: dimValueId,
      },
    }
  }

  // @todo add tests
  amountOfNonCalculatedColumns(): number {
    return this.columns().filter((c) => !c.isCalculated()).length
  }

  allowedToChangeBaseTimeDimension() {
    return !this.isCalculated()
  }

  // @todo add tests
  size(includeColumns: Maybe<string[]>): Maybe<number> {
    let columns = this.columns()
    if (includeColumns) {
      columns = columns.filter((c) => includeColumns.includes(c.id()))
    }
    const columnsSizes = columns.map((c) => c.size())
    if (columnsSizes.includes(null)) {
      return null
    }
    return sum(columnsSizes)
  }
}
