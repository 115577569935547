import { UseLocationResult } from '@fintastic/shared/util/memory-router'
import { createContext, useContext } from 'react'

export type NavigationContextValue = UseLocationResult

const stub = () => {
  throw new Error('navigation context is not initialised')
}

export const NavigationContext = createContext<NavigationContextValue>({
  path: null,
  goTo: stub,
  goBack: stub,
  reset: stub,
})

export function useNavigationContext() {
  return useContext(NavigationContext)
}
