/* eslint-disable no-param-reassign */
import type { Maybe } from '@fintastic/shared/util/types'
import type {
  ListMetricData,
  MergedDimensions,
  VersionMetricData,
} from './expand-metric-data'
import type { RowsMap } from './types'
import type { VersionEntities } from '@fintastic/web/util/versions'
import { compact, uniq } from 'lodash'
import { fillNonExistDimsWithOther, getNotExistDimensions } from './utils'
import {
  TimeDimensionId,
  NonTimeDimensionId,
  NonTimeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { makeEmptyTableRows } from './table-rows-structure'

export const populateNullRowsMapForDimensions = (
  metricsPerVersion: Array<VersionMetricData | ListMetricData>,
  _entitiesPerVersion: Array<{
    entities?: VersionEntities
    versionId: string
  }>,
  timeDimensionId: Maybe<TimeDimensionId>,
  allMetricsDimensions: MergedDimensions = {},
): RowsMap => {
  const metric = metricsPerVersion[0]
  const nonTimeDimensions: NonTimeDimensionId[] = (
    metric.metricData?.indexes || []
  ).filter((indexName) => indexName !== timeDimensionId)

  const entitiesPerVersion = compact(
    metricsPerVersion.map(({ versionId }) =>
      _entitiesPerVersion.find((el) => el.versionId === versionId),
    ),
  )

  const nonTimeDimValuesCrossVersionUnion: NonTimeDimensionValueId[][] =
    nonTimeDimensions.map((dimId) =>
      uniq(
        compact(
          entitiesPerVersion.flatMap(({ entities }) => {
            const d = entities?.dimensions?.find((dim) => dim.id === dimId)
            return d && 'values' in d ? Object.keys(d.values) : []
          }),
        ),
      ),
    )

  return makeEmptyTableRows({
    dimIds: nonTimeDimensions,
    dimValueIds: nonTimeDimValuesCrossVersionUnion,
    modifyRowDimensions: (dims) => {
      const notExistDims = getNotExistDimensions(
        allMetricsDimensions,
        dims,
        timeDimensionId,
      )

      if (notExistDims.length > 0) {
        fillNonExistDimsWithOther(dims, notExistDims, allMetricsDimensions)
      }
    },
  })
}
