import { MetricDataWithVersionId, MetricShape } from '../../types'

// @todo add tests
export function extractMetricShape(
  metricData: MetricDataWithVersionId,
): MetricShape {
  return {
    dataType: metricData.metric?.metadata.value.type,
    dimensionId: metricData.metric?.metadata.value.dimension_id || undefined,
  }
}
