import { useQueries, UseQueryResult } from 'react-query'
import { QueriesOptions } from 'react-query/types/react/useQueries'
import { useMemo } from 'react'
import { UseQueriesWithMemoKeyGetter } from './memoKeyGetters/types'
import { getMemoKeyByBaseStateFlagsAndUpdatedDate } from './memoKeyGetters/getMemoKeyByBaseStateFlags'

/**
 * For max performance optimisation - pass the queries using useMemo
 * @example
 * useQueriesWithMemo(useMemo(() => ids.map((id) => ({
 *   queryKey: [id],
 *   queryFn: async () => {},
 * })), [ids]))
 */
export function useQueriesWithMemo<T extends any[], TError = unknown>(
  queries: readonly [...QueriesOptions<T>],
  memoKeyGetter: UseQueriesWithMemoKeyGetter<T> = getMemoKeyByBaseStateFlagsAndUpdatedDate,
) {
  const queriesResults = useQueries<T>(queries) // returns new array each render

  // dump all query keys to string for re-creating an array if some of them has changed
  const queryKeysString = useMemo(
    () =>
      queries.reduce(
        (s, q) =>
          s + q.queryKey ? JSON.stringify(q.queryKey) : 'withoutQueryKey',
        '',
      ),
    [queries],
  )

  // create a custom memo key to return new array from the hook only if something has really changed
  const memoKey = useMemo(
    () => memoKeyGetter(queriesResults),
    [memoKeyGetter, queriesResults],
  )

  // that may be dangerous in usual cases, but here we need to manually decide: should we re-create array or not
  return useMemo<UseQueryResult<T[0], TError>[]>(
    () => queriesResults as unknown as UseQueryResult<T[0], TError>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoKey, queryKeysString],
  )
}
