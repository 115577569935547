import { ColumnColor } from './types'

export const columnColorEqualByTypeAndLocation = (
  a: Omit<ColumnColor, 'hexColor'>,
  b: Omit<ColumnColor, 'hexColor'>,
): boolean => {
  if (a.column.type !== b.column.type || a.versionId !== b.versionId) {
    return false
  }

  if (a.column.type === 'dimension' && b.column.type === 'dimension') {
    return a.column.dimensionId === b.column.dimensionId
  }

  if (a.column.type === 'value' && b.column.type === 'value') {
    return (
      a.column.metricId === b.column.metricId &&
      a.column.period === b.column.period
    )
  }

  return false
}
