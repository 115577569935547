import React, { useCallback, useMemo } from 'react'
import { ListGridWidgetSettings } from './types'
import {
  WidgetDependenciesWarning,
  WidgetInvisibleContainer,
  WidgetRendererProps,
} from '@fintastic/shared/ui/widgets-framework'
import { UsePeriodSelectionComponentReadyValue } from '@fintastic/web/feature/period-selector'
import type { ColumnColor } from '@fintastic/web/util/metrics-and-lists'
import { ListConnector } from '@fintastic/web/feature/lists'

export type ListGridWidgetProps =
  WidgetRendererProps<ListGridWidgetSettings> & {
    periodSelection: UsePeriodSelectionComponentReadyValue
  }

export const ListGridWidget: React.FC<ListGridWidgetProps> = (props) => {
  const {
    widget,
    boardParams,
    isEnoughSpace,
    onChangeSettings,
    periodSelection,
  } = props

  const handleUpdateColumnColors = useCallback(
    (columnColors: ColumnColor[]) => {
      onChangeSettings(widget.id, {
        ...widget.settings,
        columnColors,
      })
    },
    [onChangeSettings, widget.id, widget.settings],
  )

  const widgetContent = useMemo(() => {
    if (!isEnoughSpace) {
      return (
        <WidgetDependenciesWarning>Not enough space</WidgetDependenciesWarning>
      )
    }

    return (
      <ListConnector
        listId={widget.settings.listId}
        selectableVersions={boardParams.versions}
        title={widget.settings.displayLabel}
        enableLocalVersionSelector={true}
        columnColors={
          boardParams.isDesignMode
            ? emptyColumnColors
            : widget.settings.columnColors || emptyColumnColors
        }
        enableColumnColors
        handleUpdateColumnColors={
          boardParams.isDesignMode ? undefined : handleUpdateColumnColors
        }
        periodSelectorComponent={periodSelection.periodSelectorComponent}
      />
    )
  }, [
    isEnoughSpace,
    widget.settings.listId,
    widget.settings.displayLabel,
    widget.settings.columnColors,
    boardParams.versions,
    boardParams.isDesignMode,
    handleUpdateColumnColors,
    periodSelection.periodSelectorComponent,
  ])

  return (
    <WidgetInvisibleContainer
      data-testid={`board-widget-${widget.id}`}
      data-widget-type={widget.type}
    >
      {widgetContent}
    </WidgetInvisibleContainer>
  )
}

const emptyColumnColors: ColumnColor[] = []
