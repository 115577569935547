import { IAggFunc } from 'ag-grid-community'
import type { Maybe, RollUpFunction } from '@fintastic/shared/util/types'
import { weightedAverage } from './weightedAverage'
import { firstNotBlank, lastNotBlank } from './not-blank'
import { mean } from './mean'
import { sum } from './sum'
import { first } from './first'
import { last } from './last'

export { weightedAverage }

const nativeAgGridAggregationFunctions = [
  'sum',
  'avg',
  'count',
  'min',
  'max',
  'first',
  'last',
]

const ROLL_UP_TO_AGG_MAP: Record<RollUpFunction, Maybe<string | IAggFunc>> = {
  sum,
  mean,
  first,
  last,
  fin_first: first,
  fin_last: last,
  noop: null,
  weighted_avg: weightedAverage,
  first_not_blank: firstNotBlank,
  last_not_blank: lastNotBlank,
}

export const emptyAggregationFunc = () => ''

export const resolveAggregation: (
  agg: RollUpFunction | undefined,
) => string | IAggFunc | undefined = (agg) => {
  if (!agg) {
    return undefined
  }

  if (ROLL_UP_TO_AGG_MAP[agg]) {
    return ROLL_UP_TO_AGG_MAP[agg] || undefined
  }

  return nativeAgGridAggregationFunctions.includes(agg)
    ? (agg as string)
    : emptyAggregationFunc
}
