import type { RangeSelectionChangedEvent } from 'ag-grid-community/dist/lib/events'
import { SelectedCellAggregationAgGridContext } from '../types'
import { SelectedCell } from '@fintastic/web/util/selected-cell-aggregation'
import { forEachCellInRangeList } from '@fintastic/shared/util/ag-grid'
import { Column, RowNode } from 'ag-grid-community'
import { compact } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'

export type HandleSelectedCellsForAggregationEvent<T = any> = Pick<
  RangeSelectionChangedEvent<T>,
  'api' | 'columnApi' | 'context'
>

export const handleSelectedCellsForAggregation = <T = any>(
  event: HandleSelectedCellsForAggregationEvent<T>,
  processValue: SelectedCellsForAggregationProcessValueCallback<T>,
) => {
  const setSelectedCells = (
    event.context as SelectedCellAggregationAgGridContext
  )?.setSelectedCells

  if (!setSelectedCells) {
    throw new Error(
      'SelectedCellAggregationAgGridContext is not added for handleSelectedCellsForAggreation',
    )
  }

  const ranges = event.api.getCellRanges()

  if (!ranges?.length) {
    setSelectedCells([])
    return
  }

  const nextCells: Maybe<SelectedCell>[] = []

  forEachCellInRangeList(ranges, event.api, (rowNode, column) => {
    if (rowNode.aggData) {
      const selectedCell = processValue(
        rowNode.aggData[column.getColId()],
        rowNode,
        column,
        event,
      )
      nextCells.push(selectedCell)
      return
    }

    if (rowNode.data) {
      const selectedCell = processValue(rowNode.data, rowNode, column, event)
      nextCells.push(selectedCell)
      return
    }
  })

  setSelectedCells(compact(nextCells))
}

export type SelectedCellsForAggregationProcessValueCallback<T> = (
  value: T,
  rowNode: RowNode<T>,
  column: Column,
  event: HandleSelectedCellsForAggregationEvent<T>,
) => Maybe<SelectedCell>
