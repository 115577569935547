import React from 'react'
import { createSvgIcon } from '@mui/material'

const path =
  'M1.875 6.5C1.875 3.94568 3.94568 1.875 6.5 1.875H17.5C20.0543 1.875 22.125 3.94568 22.125 6.5V17.5C22.125 20.0543 20.0543 22.125 17.5 22.125H6.5C3.94568 22.125 1.875 20.0543 1.875 17.5V6.5ZM17.5 20.875H12.6666V12.6177H20.875V17.5C20.875 19.364 19.364 20.875 17.5 20.875ZM20.875 11.3632H12.6666V3.125H17.5C19.364 3.125 20.875 4.63604 20.875 6.5V11.3632ZM11.412 11.3632V3.125H6.5C4.63604 3.125 3.125 4.63604 3.125 6.5V11.3632H11.412ZM3.125 12.6177V17.5C3.125 19.364 4.63604 20.875 6.5 20.875H11.412V12.6177H3.125Z'

export const WidgetMetricIcon = createSvgIcon(
  <path fillRule="evenodd" clipRule="evenodd" d={path} />,
  'WidgetMetric',
)

/**
 * Add "F0" to support even
 * @see https://gojs.net/latest/intro/geometry.html#EvenOdd
 */
export const widgetMetricIconSvgPathDef = `F0 ${path}`
