import {
  styled,
  FormControlLabel,
  switchClasses,
  formControlLabelClasses,
} from '@mui/material'

export const StyledRoot = styled(FormControlLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing(1, 0)};
  margin: 0;

  width: 100%;

  .${formControlLabelClasses.label} {
    margin-right: ${({ theme }) => theme.spacing(1)};
    order: 1;

    &.${formControlLabelClasses.disabled} {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  .${switchClasses.root} {
    order: 2;
    margin-left: auto;
  }
`
