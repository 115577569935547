import React, { useEffect, useState } from 'react'
import { useRedirectToDefaultBoard } from '@fintastic/web/feature/boards'
import {
  PageLayout,
  PageLayoutContentLoader,
} from '@fintastic/shared/ui/app-layout-framework'
import { NotFoundMessage } from '@fintastic/shared/ui/components'

export const HomePage: React.FC = () => {
  const { isLoading } = useRedirectToDefaultBoard()
  const [shouldShow404, setShouldShow404] = useState(false)

  // Wait 100ms for redirect inside of `useRedirectToDefaultBoard` before showing 404
  useEffect(() => {
    if (isLoading) {
      return
    }

    const t = setTimeout(() => {
      setShouldShow404(true)
    }, 100)

    return () => clearTimeout(t)
  }, [isLoading])

  return (
    <PageLayout centerContent={true}>
      {isLoading || !shouldShow404 ? (
        <PageLayoutContentLoader />
      ) : (
        <NotFoundMessage />
      )}
    </PageLayout>
  )
}
