import { GridApi } from 'ag-grid-community'
import { useEffect, useState } from 'react'

export function useIsToolPanelOpened(toolPanelId: string, api: GridApi) {
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    const eventHandler = () => {
      setIsOpened(() => api.getOpenedToolPanel() === toolPanelId)
    }
    api.addEventListener('toolPanelVisibleChanged', eventHandler)
    eventHandler()

    return () => {
      api.removeEventListener('toolPanelVisibleChanged', eventHandler)
    }
  }, [api, toolPanelId])

  return isOpened
}
