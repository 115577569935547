import React from 'react'
import { filterUtils } from '@fintastic/web/util/filters'
import { useColumnFilter } from './hooks/useColumnFilter'
import type { IHeaderParams } from 'ag-grid-community'
import { Box } from '@mui/material'
import { FloatingPanelHasFilterIndicator } from '@fintastic/shared/ui/filters'

export const FloatingPanelHasFilter: React.FC<IHeaderParams> = (props) => {
  const { value, currentFilter } = useColumnFilter(props.column.getColId())

  if (!currentFilter) {
    return null
  }

  if (
    filterUtils.isFilterValueEmpty(currentFilter, value) ||
    !filterUtils.filterHasMeaningfulValue(value)
  ) {
    return null
  }

  return (
    <Box
      width="24px"
      height="32px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <FloatingPanelHasFilterIndicator />
    </Box>
  )
}
