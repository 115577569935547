import React, { PropsWithChildren, useState } from 'react'
import { StyledDrawerNotificationRoot } from './DrawerNotification.styled'

export const DrawerNotifications: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [childrenCount, setChildrenCount] = useState(0)

  const ref = React.useCallback((el: HTMLElement | null) => {
    if (el) {
      const checkChildren = () => {
        setChildrenCount(() => el.children.length)
      }
      checkChildren()

      new MutationObserver(checkChildren).observe(el, {
        subtree: false,
        childList: true,
        characterData: false,
      })
    }
  }, [])

  return (
    <StyledDrawerNotificationRoot ref={ref} hasChildren={childrenCount > 0}>
      {children}
    </StyledDrawerNotificationRoot>
  )
}
