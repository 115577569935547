import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadPermissionsData } from '../ducks'

export function useLoadPermissionDataEffect() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadPermissionsData())
  }, [dispatch])
}
