import { styled } from '@mui/material'

export const StyledError = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: -1;
  pointer-events: none;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: #fcfaf8;
`

export const StyledContainerRoot = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`

export const StyledGridContainer = styled('div')`
  flex-grow: 2;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  overflow: hidden;
`
