import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnCategoryAggregationPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnCategoryAggregation = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeColumnCategoryAggregationPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  list
    .findColumnByClientId(payload.columnId)
    ?.applyCategoryAggregationChanges(
      payload.rollUpFunction,
      payload.weightsMetricId,
    )
}
