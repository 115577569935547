import { LabelsContext, LabelTagListItem } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { labelsQueryKey } from './query-keys'
import { deleteLabel } from '../api'

export const useDeleteLabelMutation = (context: LabelsContext) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse<LabelTagListItem>,
    AxiosError,
    LabelTagListItem,
    {
      previousLabels: LabelTagListItem[]
    }
  >(
    labelsQueryKey.deleteLabel(context),
    (label) => deleteLabel(context, label.id),
    {
      onMutate: async (label) => {
        await queryClient.cancelQueries(labelsQueryKey.deleteLabel(context))

        const previousLabels =
          queryClient.getQueryData<LabelTagListItem[]>(
            labelsQueryKey.list(context),
          ) || []

        queryClient.setQueryData<LabelTagListItem[]>(
          labelsQueryKey.list(context),
          (prev) => [...(prev || []).filter((i) => i.id !== label.id)],
        )

        return { previousLabels }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData<LabelTagListItem[]>(
          labelsQueryKey.list(context),
          ctx?.previousLabels || [],
        )

        console.error(err)
        toast.error(
          `Failed to delete a label. Error: ${err?.code || 'unknown'}`,
        )
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: labelsQueryKey.list(context),
        })
      },
      onSuccess() {
        toast.success('Label was removed.')
      },
    },
  )

  return result
}
