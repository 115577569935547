import { Box, BoxProps, InputLabel, styled, TextField } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledTextFieldRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: theme.spacingFromPixels(24),
}))

export const StyledTextFieldLabelCont = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export const StyledTextFieldLabel = styled(InputLabel)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'flex-start',

  minHeight: 40,

  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: 500,
  whiteSpace: 'pre-wrap',

  color: theme.palette.text.primary,
}))

export const StyledTextFieldFieldCont = styled(
  (props: BoxProps & { fullWidth?: boolean }) => {
    const { fullWidth, ...boxProps } = props
    return <Box {...boxProps}
    />
  },
  {
    shouldForwardProp: (propName) => propName !== 'fullWidth',
  },
)(({ theme, fullWidth }) => ({
  marginLeft: fullWidth ? 0 : theme.spacingFromPixels(24),
  width: fullWidth ? '100%' : 240,
  minWidth: fullWidth ? '100%' : 240,
}))

export const StyledTextFieldField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-input': {
    'padding': '10px 16px',

    'fontSize': '1rem',
    'fontWeight': 500,

    '&::placeholder': {
      fontWeight: 400,
    },
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },

  '.MuiOutlinedInput-root:not(.Mui-focused)': {
    ':hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
  },
}))
