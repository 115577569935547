import React, { useCallback } from 'react'
import { FilterRange, FilterValue } from '@fintastic/web/util/filters'
import { Box } from '@mui/material'
import { isArray } from 'lodash'
import { NumberFilterInputSharedProps } from './types'
import { NumberFieldWithDisplayConfig } from './NumberFieldWithDisplayConfig'

export const RangeNumberField: React.FC<RangeNumberFieldProps> = ({
  filterValue,
  onChange,
  dataType,
  displayConfig,
}) => {
  const [from, to] = isValidNumberRange(filterValue.value)
    ? filterValue.value
    : []

  const handleChangeFrom = useCallback(
    (nextValue: FilterValue<number | ''>) => {
      onChange({
        value: [nextValue.value, to ?? ''],
        operator: filterValue.operator ?? 'range',
      })
    },
    [filterValue.operator, onChange, to],
  )

  const handleChangeTo = useCallback(
    (nextValue: FilterValue<number | ''>) => {
      onChange({
        value: [from ?? '', nextValue.value],
        operator: filterValue.operator ?? 'range',
      })
    },
    [filterValue.operator, from, onChange],
  )

  return (
    <Box display="flex" flexDirection="column">
      <NumberFieldWithDisplayConfig
        placeholder="From"
        filterValue={{
          value: from,
          operator: 'range',
        }}
        dataType={dataType}
        displayConfig={displayConfig}
        onChange={handleChangeFrom}
      />
      <NumberFieldWithDisplayConfig
        placeholder="To"
        filterValue={{
          value: to,
          operator: 'range',
        }}
        dataType={dataType}
        displayConfig={displayConfig}
        onChange={handleChangeTo}
      />
    </Box>
  )
}

export type RangeNumberFieldProps = {
  filterValue: Partial<FilterValue<FilterRange>>
  onChange: (nextValue: FilterValue<FilterRange>) => void
} & NumberFilterInputSharedProps

const isValidNumberRange = (range: unknown): range is FilterRange =>
  isArray(range) && range.length === 2
