import {
  metricsAndListsCacheKeys,
  VersionDescriptionSaveParams,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { setDescription } from '../../api/version-description-api'
import type { CompactMetricOrList } from '@fintastic/web/util/metrics-and-lists'
import { toast } from '@fintastic/shared/ui/toast-framework'

export const useSaveVersionDescription = (
  params: Omit<VersionDescriptionSaveParams, 'description'>,
  showHidden = false, // true for LA, false for version (lists/metrics reload)
) => {
  const queryClient = useQueryClient()
  // @todo: check for LA
  // const cacheKey = versionsCacheKeys.versionsList(false, false, true)
  return useMutation(
    mutationKeys.setDescription(
      params.versionId,
      params.entityType,
      params.entityId,
    ),
    async (params: VersionDescriptionSaveParams) => {
      const response = await setDescription(
        params.versionId,
        params.entityType,
        params.entityId,
        params.description,
      )
      return response.data
    },
    {
      onMutate: async ({ description, versionId, entityType, entityId }) => {
        // optimistic mutation
        const cacheKey =
          entityType === 'list'
            ? metricsAndListsCacheKeys.lists(versionId, showHidden)
            : metricsAndListsCacheKeys.metrics(versionId, showHidden)

        await queryClient.cancelQueries(cacheKey)

        const previousEntities =
          queryClient.getQueryData<CompactMetricOrList[]>(cacheKey) || []

        queryClient.setQueryData<CompactMetricOrList[]>(cacheKey, (prev) => [
          ...(prev || []).map((i) => {
            if (i.id !== entityId) {
              return i
            }
            return {
              ...i,
              info: {
                ...i.info,
                description,
              },
            }
          }),
        ])

        return { previousEntities, cacheKey }
      },
      onError: (err: any, v, ctx) => {
        if (ctx?.cacheKey) {
          // Reset optimistic update
          queryClient.setQueryData(ctx.cacheKey, ctx.previousEntities || [])
        }

        console.error(err)
        toast.error(
          `Failed to save version labels. Error: ${err?.code || 'unknown'}`,
        )
      },
      async onSettled(data, err, v, ctx) {
        if (ctx?.cacheKey) {
          return queryClient.invalidateQueries({
            queryKey: ctx?.cacheKey,
          })
        }
      },
    },
  )
}
