import React from 'react'

export const ArrowHorizontalIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.26768 2.26414C5.09628 2.43332 5 2.66274 5 2.90196C5 3.14118 5.09628 3.37061 5.26768 3.53978L9.7932 8.00545L5.26768 12.4711C5.10114 12.6413 5.00899 12.8691 5.01107 13.1057C5.01316 13.3422 5.10931 13.5685 5.27882 13.7358C5.44832 13.903 5.67763 13.9979 5.91734 14C6.15705 14.002 6.388 13.9111 6.56042 13.7468L11.7323 8.64327C11.9037 8.47409 12 8.24467 12 8.00545C12 7.76623 11.9037 7.5368 11.7323 7.36762L6.56042 2.26414C6.38898 2.09501 6.15648 2 5.91405 2C5.67163 2 5.43913 2.09501 5.26768 2.26414Z"
      fill="#263646"
    />
  </svg>
)
