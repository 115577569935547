import { RollUpFunction } from '@fintastic/shared/util/types'

export type SupportedRollUpFunction = Extract<
  RollUpFunction,
  'sum' | 'mean' | 'first' | 'last' | 'weighted_avg' | 'noop'
>

export const supportedRollUps: SupportedRollUpFunction[] = [
  'sum',
  'mean',
  'first',
  'last',
  'weighted_avg',
  'noop',
]
