import { useCallback, useMemo } from 'react'
import { ChangeColumnValueDataFormattingAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeColumnValueDataFormattingFlow() {
  const { changeValueDataFormatting } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnValueDataFormattingAction>(
    (columnId, changes) => {
      changeValueDataFormatting({
        columnId,
        ...changes,
      })
    },
    [changeValueDataFormatting],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
