import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosError, AxiosResponse, default as axiosStatic } from 'axios'
import {
  fromAxiosErrorToFirstMatchedError,
  makeFromAxiosErrorFunction,
  WithUserFriendlyErrorText,
} from '@fintastic/shared/util/errors'
import { DimensionId } from '@fintastic/web/util/dimensions'
import {
  BadRequestError,
  NetworkError,
  RuntimeError,
} from './save-dimension-errors'

type RenameDimPayload = {
  label: string
  description?: string
}

export const renameDimension = async (
  dimId: DimensionId,
  editedValues: RenameDimPayload,
) => {
  try {
    return await axios.patch<
      void,
      AxiosResponse<void, RenameDimPayload>,
      RenameDimPayload
    >(endpoints.renameDimension(dimId), {
      label: editedValues.label,
      description: editedValues.description || '',
    })
  } catch (e) {
    console.log('Error in renameDimension', e)
    return castRenameError(e as Error)
  }
}

export type RenameDimensionError =
  | NetworkError
  | RuntimeError
  | BadRequestError
  | ValidationNameError
  | DuplicationNameError

const castRenameError = (e: AxiosError | Error): RenameDimensionError => {
  if (!axiosStatic.isAxiosError(e)) {
    return new RuntimeError(e)
  }

  return (
    fromAxiosErrorToFirstMatchedError(e, [
      ValidationNameError,
      DuplicationNameError,
      BadRequestError,
    ]) || new NetworkError()
  )
}

class DuplicationNameError
  extends Error
  implements WithUserFriendlyErrorText
{
  getUiMessage() {
    return 'Something went wrong during the saving of the changes in Name/Description'
  }

  constructor() {
    super(
      'Dimension name is already taken (it can be Dimension, List or Metric)',
    )
    this.name = 'DuplicationNameError'
    Object.setPrototypeOf(this, DuplicationNameError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    this,
    (e) => e.response?.status === 409,
  )
}

class ValidationNameError
  extends Error
  implements WithUserFriendlyErrorText
{
  getUiMessage() {
    return 'Something went wrong'
  }

  constructor() {
    super('The validation on BE is failed: empty or incorrect name.')
    this.name = 'ValidationNameError'
    Object.setPrototypeOf(this, ValidationNameError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    this,
    (e) => e.response?.status === 422,
  )
}
