import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from './cache-keys'
import { Maybe } from '@fintastic/shared/util/types'
import { PeriodSelection } from '@fintastic/web/util/period-selector'

export async function invalidateMetricCache(
  queryClient: QueryClient,
  versionId: string,
  metricId: string,
  periodSelection?: Maybe<PeriodSelection>,
) {
  const key = metricsAndListsCacheKeys.metric(versionId, metricId)

  // @todo: Magic 4 is unreliable
  await queryClient.invalidateQueries(!periodSelection ? key.slice(0, 4) : key)
}
