import React, { forwardRef } from 'react'
import {
  StyledRoot,
  StyledIcon,
  StyledLabel,
  StyledAfterLabel,
} from './SelectableMenuItem.styled'
import { CheckIcon } from './CheckIcon'

export type SelectableMenuItemProps = {
  label: string
  afterLabelContent?: React.ReactNode
  disabled?: boolean
  selected?: boolean
  onClick: () => void
}

export const SelectableMenuItem = forwardRef<
  HTMLLIElement,
  SelectableMenuItemProps
>(({ label, afterLabelContent, onClick, selected, disabled }, ref) => (
  <StyledRoot ref={ref} disabled={disabled} onClick={onClick}>
    <StyledIcon>{selected && <CheckIcon />}</StyledIcon>
    <StyledLabel>{label}</StyledLabel>
    {afterLabelContent && (
      <StyledAfterLabel>{afterLabelContent}</StyledAfterLabel>
    )}
  </StyledRoot>
))
