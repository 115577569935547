import { styled } from '@mui/material'
import { oldToNewValueTransitionClassNames } from '../../entry-details'

export const StyledRoot = styled('div')`
  padding: 4px 16px;

  background: transparent;
  transition: all 300ms;
  border-radius: 8px;

  &:hover {
    background: #ff79330a;

    box-shadow: 0px -4px 10px 0px #0000000d, 0px 4px 10px 0px #0000000d;
  }
`

export const StyledHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const StyledHeaderIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  color: #6e665e;
`

export const StyledHeaderText = styled('div')`
  padding: 8px 0;
`

export const StyledHeaderTimeAndAction = styled('div')``

export const StyledHeaderAuthor = styled('div')`
  margin-top: 3px;
`

export const StyledDetails = styled('div')`
  margin-top: 4px;
  padding-left: 30px;

  .${oldToNewValueTransitionClassNames.root}
    .${oldToNewValueTransitionClassNames.icon} {
    margin: 0 8px;
  }
`
