import { ThemeComponents } from './types'

export const MuiMenuItem: ThemeComponents['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      'paddingTop': 10.5,
      'paddingBottom': 10.5,
      'paddingLeft': theme.spacing(3),
      'paddingRight': theme.spacing(3),

      '&:hover, &.Mui-selected:hover': {
        background: '#FFF6ED',
      },

      '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
        background: '#FFE9DA',
      },

      '&.Mui-selected': {
        color: '#D64E15',
        background: 'transparent',
        fontWeight: 900,
      },
    }),

    dense: ({ theme }) => ({
      padding: theme.spacing(0.5, 1),
      fontSize: 14,
    }),
  },
}
