import { setLocalStorageValue } from '@fintastic/shared/util/hooks'
import {
  getTenantOverrideParams,
  TENANT_OVERRIDE_KEY,

} from '@fintastic/web/data-access/service-auth0'
import { call } from 'redux-saga/effects'

export function* tenantOverrideSaga() {
  const { tenantName }: ReturnType<typeof getTenantOverrideParams> =
    yield call(getTenantOverrideParams)

  if (tenantName.length) {
    if (tenantName) {
      yield call(
        setLocalStorageValue,
        TENANT_OVERRIDE_KEY,
        JSON.stringify(tenantName),
      )
    }
  }
}
