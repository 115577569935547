import { AxiosResponse } from 'axios'
import { UserData, UserRole } from '../types'
import { endpoints } from './endpoints'
import { axios } from '@fintastic/web/data-access/service-axios'

export type UsersListResponse = {
  result: UserData[]
}

export function getUsersList() {
  return axios.get<UsersListResponse>(endpoints.usersList())
}

export const updateUser = (user: UserData) =>
  axios.put<void, AxiosResponse<void>, UserAPIPostObject>(
    `planning/api/v1/iam/users/${user.username}/`,
    {
      full_name: user.full_name,
      is_active: user.is_active,
      role: user.role,
    },
  )

export type UserAPIPostObject = {
  full_name: string
  is_active: boolean
  role: UserRole
}
