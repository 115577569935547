import React from 'react'
import { useListEditorApi } from '../../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../../features/active/makeApi'
import {
  Panel,
  PanelLoadingOverlay,
} from '@fintastic/shared/ui/panel-framework'
import { TopBar } from '@fintastic/shared/ui/grid-framework'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Button, Tooltip } from '@mui/material'
import { ListIcon } from '@fintastic/web/feature/metrics-and-lists'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type PanelContainerProps = {
  children: React.ReactNode
  isLoading: boolean
  formulaEditor: React.ReactNode
}

export const PanelContainer: React.FC<PanelContainerProps> = ({
  isLoading,
  children,
  formulaEditor,
}) => {
  const api = useListEditorApi() as ActiveListEditorApi
  const { list, isValid, cancelEditing, saveChanges } = api

  return (
    <Panel
      fullHeight={true}
      isLoading={isLoading}
      overlay={
        saveChanges.running ? <PanelLoadingOverlay text="Saving List" /> : null
      }
      topbar={
        <>
          <TopBar
            title={titleFormatter(list.label)}
            titleIcon={
              <Tooltip title="List">
                <ListIcon />
              </Tooltip>
            }
            rightContent={
              <FintasticThemeProvider applyLegacyTheme={false}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isValid || saveChanges.running}
                  onClick={() => saveChanges.run()}
                  sx={{ mr: 1 }}
                >
                  {saveChanges.running ? 'Saving...' : 'Save to version'}
                </Button>
                <Button
                  variant="outlined"
                  color="black"
                  disabled={cancelEditing.running}
                  onClick={() => cancelEditing.run()}
                >
                  {cancelEditing.running ? 'Cancelling...' : 'Cancel'}
                </Button>
              </FintasticThemeProvider>
            }
          />
          {formulaEditor}
        </>
      }
    >
      {children}
    </Panel>
  )
}
