import { invalidateEverythingForVersion } from './invalidateEverythingForVersion'
import { getVersionDependenciesGraph } from '@fintastic/web/data-access/versions'
import { getAffectedEntities } from './getAffectedEntities'
import { performAffectedEntitiesInvalidation } from './performAffectedEntitiesInvalidation'
import { QueryClient } from 'react-query'
import { Maybe } from '@fintastic/shared/util/types'
import { uniq } from 'lodash'

export const invalidateEntitiesAffectedByList = async (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
  userModifiedEntities: Maybe<string[]>,
) => {
  const dependenciesGraph = await getVersionDependenciesGraph(
    queryClient,
    versionId,
  )
  if (!dependenciesGraph) {
    await invalidateEverythingForVersion(versionId, queryClient)
    return
  }

  const affectedEntities = getAffectedEntities(
    uniq([...(userModifiedEntities || []), listId]),
    dependenciesGraph,
    false,
  ).filter((id) => !id.startsWith(listId))

  await performAffectedEntitiesInvalidation(
    queryClient,
    versionId,
    affectedEntities,
  )
}
