import { styled } from '@mui/material'

export const CellCommentIndicator = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 0;
  border-color: transparent #ffb085 transparent transparent;
`
