import {
  useCurrentEditingFlow,
  useManualLoaderForMetric,
  useMetricsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useMemo } from 'react'
import { useUpdateFormulaInEditingMetric } from './useUpdateFormulaInEditingMetric'
import { MetricGridConnectorSettingsPanelProp } from '../../types'
import { Maybe } from '@fintastic/shared/util/types'
import { useSaveFlow } from './useSaveFlow'
import {
  invalidateAllVersionRelatedQueries,
  texts,
  useLoadVersionEntities,
  useLockVersionForUser,
} from '@fintastic/web/data-access/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { useIsMountedRef } from '@fintastic/shared/util/hooks'
import { useQueryClient } from 'react-query'
import {
  createMappingDictionary,
  mapFormula,
} from '@fintastic/web/util/formulas'

export function useSettingsPanelProps(
  versionId: Maybe<string>,
  metricId: Maybe<string>,
  settingsProp: Maybe<MetricGridConnectorSettingsPanelProp>,
) {
  const isMounted = useIsMountedRef()
  const entitiesQuery = useLoadVersionEntities(versionId)
  const {
    load: loadMetricManually,
    stopWatchingStatus: stopWatchingMetricStatus,
    query: { isFetching: isMetricLoading },
  } = useManualLoaderForMetric({
    versionId,
    metricId,
  })

  const { id, type, flow, isValid, formulaError } = useCurrentEditingFlow()
  const updateFormulaInEditingMetric = useUpdateFormulaInEditingMetric()
  const { startEditing } = useMetricsActions()
  const { mutate: lockVersionForUser, isLoading: isLockingVersion } =
    useLockVersionForUser(versionId)
  const saveFlow = useSaveFlow({
    versionId,
    metricId,
    onCreated: settingsProp?.onCreated || undefined,
  })
  const queryClient = useQueryClient()

  const editingActive = id === metricId && type === 'metric'

  return useMemo(
    () =>
      ({
        onFormulaValidationFinished: updateFormulaInEditingMetric,
        onCancel: () => {
          stopWatchingMetricStatus()
          settingsProp?.onCancel?.()
        },
        onSave: () => {
          if (flow === 'creation') {
            sessionStorage.setItem('_blanks_once_after_edit', 'true')
          }
          stopWatchingMetricStatus()
          saveFlow.action()
        },
        onEdit: async () => {
          if (!versionId || !metricId) {
            toast.error(texts.cantLoadExistingMetric)
            return
          }

          if (!entitiesQuery.data) {
            toast.error(texts.cantLoadEntities)
            return
          }

          const mappingDictionary = createMappingDictionary(
            entitiesQuery.data,
            'labelToId',
          )

          let existingMetric: Metric
          try {
            existingMetric = await loadMetricManually()
            if (
              existingMetric.source === 'calculated' &&
              existingMetric.metadata.formula
            ) {
              existingMetric.metadata.formula = mapFormula(
                existingMetric.metadata.formula,
                mappingDictionary,
              )
            }
          } catch (e) {
            toast.error(texts.cantLoadExistingMetric)
            return
          }
          if (!isMounted.current) {
            return
          }

          lockVersionForUser(undefined, {
            onSuccess: () => {
              startEditing({ metric: existingMetric })
            },
            onError: async () => {
              toast.error(texts.versionLockedForCurrentUser)
              if (versionId) {
                await invalidateAllVersionRelatedQueries(queryClient, versionId)
              }
            },
          })
        },
        editingAllowed: Boolean(settingsProp?.editingAllowed),
        enabled: !!settingsProp?.enabled,
        editingActive,
        isNewMetric: flow === 'creation',
        isValid,
        isSaving: saveFlow.isSaving,
        isLockingVersion,
        isLoading: isMetricLoading || entitiesQuery.isLoading,
        formulaError,
      } as const),
    [
      updateFormulaInEditingMetric,
      flow,
      editingActive,
      settingsProp,
      isValid,
      saveFlow,
      isLockingVersion,
      isMetricLoading,
      entitiesQuery.isLoading,
      entitiesQuery.data,
      stopWatchingMetricStatus,
      versionId,
      metricId,
      isMounted,
      lockVersionForUser,
      loadMetricManually,
      startEditing,
      queryClient,
      formulaError,
    ],
  )
}
