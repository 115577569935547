import { uniq } from 'lodash'
import { useMemo } from 'react'
import { Version } from '@fintastic/web/util/versions'

export function usePeriodSelectorCalendarAndEntitiesIds(
  selectedVersions: Version[],
) {
  return useMemo(() => {
    if (!selectedVersions?.length) {
      return {
        calendarId: null,
        entitiesVersionIds: [],
      }
    }

    const calendarIds = uniq(selectedVersions.map((i) => i.calendar_id))

    if (calendarIds.length > 1) {
      throw new Error('Multiple calendar IDs are not yet supported!')
    }

    return {
      calendarId: selectedVersions[0]?.calendar_id || null,
      entitiesVersionIds: selectedVersions.map(({ uuid }) => uuid),
    } as const
  }, [selectedVersions])
}
