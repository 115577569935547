import React, { useCallback, useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { CollapseIcon, ExpandIcon } from '@fintastic/shared/ui/icons'
import { Button } from '@mui/material'

export const TableNameExpandableGroupRenderer = ({
  value,
  node,
}: ICellRendererParams) => {
  const [expanded, setExpanded] = useState(node.expanded)

  const handleClick = useCallback(() => {
    setExpanded(!node.expanded)
    node.setExpanded(!node.expanded)
  }, [node])

  return (
    <div onClick={handleClick}>
      <Button color="black">
        {expanded ? (
          <CollapseIcon fontSize="small" />
        ) : (
          <ExpandIcon fontSize="small" />
        )}
      </Button>
      {value}
    </div>
  )
}
