import React, { useCallback, useMemo } from 'react'
import {
  BuildLegacyListGridColumnDefinitionParams,
  LegacyListGridRow,
} from '../../types'
import {
  BaseGrid,
  BaseGridSharedProps,
  CollapseExpandButton,
  ResetGridButton,
  ToggleGroupingButton,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import { CheckboxSelectionCallbackParams, ColDef } from 'ag-grid-community'
import { buildLegacyListGridColumnDefinition } from '../../utils/buildLegacyListGridColumnDefinition'
import { isMasked } from '@fintastic/shared/ui/ag-grid'
import { isValueFilled } from '@fintastic/shared/util/ag-grid'

/** @deprecated */
export type LegacyListGridProps<TData extends LegacyListGridRow> =
  BaseGridSharedProps<TData> & {
    columns: BuildLegacyListGridColumnDefinitionParams[]
    readonly?: boolean
  }

/** @deprecated */
export const LegacyListGrid = <TData extends LegacyListGridRow>(
  props: LegacyListGridProps<TData>,
): JSX.Element => {
  const {
    readonly,
    columns,
    context,
    initialGrouping = false,
    ...otherProps
  } = props

  const columnDefinitions: ColDef[] = useMemo(
    () => columns.map(buildLegacyListGridColumnDefinition(context)),
    [columns, context],
  )

  const checkboxSelection = useCallback(
    (params: CheckboxSelectionCallbackParams<TData>) => {
      const rowData = params.node.data as LegacyListGridRow
      if (!rowData) {
        return false
      }

      return columns.reduce<boolean>(
        (accumulatedIsValid, { field, isRequired }) =>
          accumulatedIsValid &&
          !isMasked(field, rowData) &&
          (!isRequired || isValueFilled(rowData[field])),
        true,
      )
    },
    [columns],
  )

  return (
    <BaseGrid
      {...otherProps}
      topbar={
        otherProps.topbar || (
          <TopBar
            title={otherProps.title || ''}
            leftContent={<CollapseExpandButton />}
            rightContent={
              <>
                <ToggleGroupingButton />
                <ResetGridButton />
              </>
            }
          />
        )
      }
      context={context}
      agColumnDefs={columnDefinitions}
      initialGrouping={initialGrouping}
      checkboxSelection={readonly ? undefined : checkboxSelection}
      enableTitleDateAutoFormatting={false}
    />
  )
}
