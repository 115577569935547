import React, { useMemo } from 'react'
import { AvatarProps as MuiAvatarProps } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import { getColorsByString, getFixedColors } from './colors'
import { StyledAvatarRoot } from './Avatar.styled'
import type { SxProps } from '@mui/system'

export type AvatarProps = {
  size?: 'medium' | 'small'
  text?: Maybe<string>
  src?: Maybe<string>
  sx?: SxProps
  fixedColors?: boolean
  MuiAvatarProps?: Partial<Omit<MuiAvatarProps, 'sx'>>
}

export const Avatar: React.FC<AvatarProps> = ({
  size = 'medium',
  text,
  src,
  sx,
  fixedColors,
  MuiAvatarProps,
}) => {
  const avatarText = useMemo<Maybe<string>>(() => {
    if (text && text.length <= 2) {
      return text
    }
    return (text || '')
      .split(' ')
      .map((s) => s.slice(0, 1).toUpperCase())
      .join('')
  }, [text])

  const colors = useMemo(
    () => (fixedColors ? getFixedColors() : getColorsByString(text || ' ')),
    [text, fixedColors],
  )

  return (
    <StyledAvatarRoot
      size={size}
      sx={{
        background: colors.bg,
        color: colors.fg,
        ...sx,
      }}
      src={src || undefined}
      {...MuiAvatarProps}
    >
      {avatarText}
    </StyledAvatarRoot>
  )
}
