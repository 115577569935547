import React from 'react'
import { NoSharedBoardsMessageRootStyled } from './NoSharedBoardsMessage.styled'
import { NoSharedBoardsCircle } from './NoSharedBoardsCircle'
import { Typography } from '@mui/material'

export const NoSharedBoardsMessage: React.FC = () => (
  <NoSharedBoardsMessageRootStyled>
    <NoSharedBoardsCircle />
    <Typography variant="h5">Nothing to show</Typography>
    <Typography variant="body1" color="#757575">
      No Boards have been shared with you yet
    </Typography>
  </NoSharedBoardsMessageRootStyled>
)
