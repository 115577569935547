import type { ICellRendererParams } from 'ag-grid-community'
import { useEntitityLabel } from '@fintastic/web/data-access/versions'
import {
  EntityTypeChip,
  EntityTypeChipVariant,
} from '@fintastic/shared/ui/components'

import { idLooksLikeList } from '@fintastic/web/util/metrics-and-lists'
import React from 'react'

export const EntityLabelCellRenderer = (
  params: ICellRendererParams & EntityLabelCellRendererParams,
) => {
  const label = useEntitityLabel(params.value, params.versionId)

  const variant: EntityTypeChipVariant = idLooksLikeList(params.value || '')
    ? 'list'
    : 'metric'

  if (!params.value || !label || !params.versionId) {
    return params.EmptyLabelComponent ? (
      <params.EmptyLabelComponent {...params} />
    ) : (
      '-'
    )
  }

  if (label === '-') {
    return params.EmptyLabelComponent ? (
      <params.EmptyLabelComponent {...params} />
    ) : (
      '-'
    )
  }

  return <EntityTypeChip variant={variant}>{label}</EntityTypeChip>
}

export type EntityLabelCellRendererParams<TData = any> = {
  versionId: string
  EmptyLabelComponent?: React.FC<
    Partial<ICellRendererParams<TData> & EntityLabelCellRendererParams>
  >
}
