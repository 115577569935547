import { createContext, useCallback, useContext, useMemo } from 'react'
import { WidgetRendererProps } from './types'
import { useWidgetLayoutActions } from './hooks/useWidgetLayoutActions'
import { Maybe } from '@fintastic/shared/util/types'

export type WidgetContextValue = ReturnType<
  typeof useWidgetContextImplementation
>

export const WidgetContext = createContext<Maybe<WidgetContextValue>>(null)

export const useWidgetContextImplementation = (
  {
    widget,
    layout,
    onChangeLayout,
    draggable,
    collapsable,
    gridRowHeightPixels,
    handleCollapse,
    isCollapsedVert,
  }: Pick<
    WidgetRendererProps,
    | 'widget'
    | 'layout'
    | 'draggable'
    | 'collapsable'
    | 'onChangeLayout'
    | 'gridRowHeightPixels'
    | 'handleCollapse'
    | 'isCollapsedVert'
  >,
  boardInDesignMode: boolean,
) => {
  const { collapseVert, expandVert } = useWidgetLayoutActions({
    widget,
    layout,
    gridLayoutRowHeightInPixels: gridRowHeightPixels,
    changeLayout: onChangeLayout,
  })

  const toggleCollapsedVert = useCallback(
    (isCollapsed: boolean) => {
      handleCollapse?.()
      isCollapsed ? collapseVert() : expandVert()
    },
    [collapseVert, expandVert, handleCollapse],
  )

  return useMemo(
    () =>
      ({
        widgetId: widget.id,
        toggleCollapsedVert,
        draggable,
        collapsable,
        isCollapsedVert,
        boardInDesignMode,
      } as const),
    [
      boardInDesignMode,
      collapsable,
      draggable,
      isCollapsedVert,
      toggleCollapsedVert,
      widget.id,
    ],
  )
}

export const useWidgetContext = () => useContext(WidgetContext)
