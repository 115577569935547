import { ListWithOptionalData } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { useMemo } from 'react'

export function useDefaultHiddenColumnsIds(
  list: Maybe<ListWithOptionalData>,
): Maybe<string[]> {
  return useMemo(() => {
    if (list === null) {
      return null
    }
    return list.metrics
      .filter((m) => !m.metadata.display_config.is_visible)
      .map((m) => m.id)
  }, [list])
}
