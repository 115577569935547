import React, { useCallback } from 'react'
import {
  MetricsView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

// @todo RESP connect
export const MetricsViewConnector: React.FC = () => {
  const { goBack, goTo } = useReportSettingsPanelNavigationContext()
  const goToMetric = useCallback(
    (id: string) => goTo(settingsPanelNavigationPaths.metricSettings(id)),
    [goTo],
  )
  const goToReportingGroups = useCallback(
    () => goTo(settingsPanelNavigationPaths.reportingGroups),
    [goTo],
  )

  return (
    <MetricsView.Readonly
      goToMetric={goToMetric}
      goBack={goBack}
      goToReportingGroups={goToReportingGroups}
      metrics={[
        {
          id: 'Cube.Rate_a',
          label: 'Rate A',
          reportingGroupLabel: 'RG1',
        },
        {
          id: 'Table.Headcount.Rate_b',
          label: 'Rate B',
          reportingGroupLabel: 'RG1',
        },
      ]}
    />
  )
}
