import React, { memo, useCallback } from 'react'
import { useTypedDrawerContext } from '@fintastic/shared/ui/drawer-framework'
import { AddLinesDrawerContext, addLinesPayloadType } from '../types'
import { useLayoutStateIsBottomDrawerOpened } from '@fintastic/shared/ui/app-layout-framework'
import { AddLinesConnector } from './AddLinesConnector/AddLinesConnector'
import { useAddLinesStateContext } from '../contexts'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import {
  StyledAddLinesDrawerDiscard,
  StyledAddLinesDrawerRoot,
} from './AddLinesDrawer.styled'
import { ModalCloseButton } from '@fintastic/shared/ui/legacy-modal-framework'

export const AddLinesDrawer: React.FC = memo(() => {
  // get drawer context (public, with target)
  const drawerContext = useTypedDrawerContext(
    addLinesPayloadType,
  ) as AddLinesDrawerContext

  // get internal context
  const addLineContext = useAddLinesStateContext()

  const [isDrawerOpened, setDrawerOpened] = useLayoutStateIsBottomDrawerOpened()

  const handleDiscard = useCallback(() => {
    addLineContext?.addLinesTargetApi.targetFlow.closeTarget()
    setDrawerOpened(false)
  }, [addLineContext?.addLinesTargetApi.targetFlow, setDrawerOpened])

  if (
    !isDrawerOpened ||
    !drawerContext ||
    !drawerContext.target.versionId ||
    !drawerContext.target.listId
  ) {
    // @todo: loading? nothing found?
    return null
  }

  if (!addLineContext?.addLinesData || !addLineContext?.addLinesTargetApi) {
    throw new Error('No internal context provided for AddLineDrawer!')
  }

  return (
    <StyledAddLinesDrawerRoot>
      <ErrorBoundary
        title={'Error when creating a List for Add Lines'}
        message={'Something went wrong during columns creation.'}
        errorComponent={
          <StyledAddLinesDrawerDiscard>
            <ModalCloseButton onClick={handleDiscard} />
          </StyledAddLinesDrawerDiscard>
        }
      >
        <AddLinesConnector
          versionId={drawerContext.target.versionId}
          listId={drawerContext.target.listId}
          addLinesData={addLineContext.addLinesData}
          targetApi={addLineContext.addLinesTargetApi}
        />
      </ErrorBoundary>
    </StyledAddLinesDrawerRoot>
  )
})
