export type CommonError = NetworkError

export class NetworkError extends Error {
  public ERROR_TEXT = 'Something went wrong during the report data loading'

  constructor() {
    super()
    this.name = 'NetworkError'
    Object.setPrototypeOf(this, NetworkError.prototype)
  }
}
