import React, { useMemo } from 'react'
import { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'
import { DimensionsChangeCallback } from './types'
import { CalculatedMetricDimensions } from './calculated-metric/CalculatedMetricDimensions'
import { InputMetricDimensions } from './input-metric/InputMetricDimensions'
import {
  StyledFooter,
  StyledList,
  StyledRoot,
} from './DimensionsSettings.styled'
import { DefinedByFormulaAlert } from '../../../shared/DefinedByFormulaAlert'
import { Dimension, DimensionId } from '@fintastic/shared/util/types'
import { compact } from 'lodash'

export type DimensionsSettingsProps = {
  metricSource: MetricOrListSource
  allDimensions: Dimension[]
  metricDimensions: DimensionId[]
  readonly?: boolean
  isNewMetric: boolean
  onChangeMetricDimensions: DimensionsChangeCallback
}

// @todo add tests
export const DimensionsSettings: React.FC<DimensionsSettingsProps> = ({
  metricSource,
  metricDimensions,
  allDimensions,
  readonly,
  isNewMetric,
  onChangeMetricDimensions,
}) => {
  const isInputMetric = metricSource === 'input'

  const currentDimensions = useMemo(
    () =>
      compact(
        metricDimensions.map((dimId) =>
          allDimensions.find((dim) => dim.id === dimId),
        ),
      ),
    [allDimensions, metricDimensions],
  )

  return (
    <StyledRoot>
      <StyledList>
        {isInputMetric ? (
          <InputMetricDimensions
            metricDimensions={currentDimensions}
            allDimensions={allDimensions}
            onChangeMetricDimensions={onChangeMetricDimensions}
            readonly={readonly}
            isNewMetric={isNewMetric}
          />
        ) : (
          <CalculatedMetricDimensions metricDimensions={currentDimensions} />
        )}
      </StyledList>
      {!isInputMetric && (
        <StyledFooter>
          <DefinedByFormulaAlert
            description={'Edit formula to add or remove new dimensions'}
          />
        </StyledFooter>
      )}
    </StyledRoot>
  )
}
