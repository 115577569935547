import { IconButton, styled } from '@mui/material'

export const StyledModalCloseButtonRoot = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.primary};

  svg {
    width: 24px;
    height: 24px;
  }
`
