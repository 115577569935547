import { useColDefs as useGenericColDefs } from '@fintastic/web/feature/lists'
import { useListEditorApi } from '../../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../../features/active/makeApi'
import { WrappedDimension } from '@fintastic/web/util/dimensions'
import { useMemo } from 'react'

export const useColDefs = (versionDimensions: WrappedDimension[]) => {
  const api = useListEditorApi() as ActiveListEditorApi
  const { list, versionMetadata, isLiveActuals } = api

  const dimensionsLoaded = versionDimensions.length > 0
  const visibleColumns = useMemo(
    () =>
      dimensionsLoaded
        ? list.metrics.filter((m) => m.metadata.display_config.is_visible)
        : [],
    [dimensionsLoaded, list.metrics],
  )

  return useGenericColDefs(
    versionMetadata?.uuid || '',
    visibleColumns,
    {
      isLiveActuals,
      isCalculatedList: list.source === 'calculated',
    },
    {
      versionDimensions,
    },
  )
}
