import React from 'react'
import { StyledChartParams } from './ChartDebugParams.styled'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'
import {
  ChartRequestParams,
  ChartSettings,
  MergedMetricsData,
} from '../../../../../types'
import { Maybe } from '@fintastic/shared/util/types'

export type ChartParamsProps = {
  chartSettings: ChartSettings
  request: ChartRequestParams
  mergedMetricsData: Maybe<MergedMetricsData>
}

export const ChartDebugParams: React.FC<ChartParamsProps> = ({
  chartSettings,
  request,
  mergedMetricsData,
}) => {
  const isDebugModalEnabled = useIsFintasticUser()

  if (!isDebugModalEnabled) {
    return null
  }

  return (
    <StyledChartParams>
      <table>
        <thead>
          <tr>
            <th>Chart params</th>
            <th>Request</th>
            <th>Raw data</th>
            <th>Metadata</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <pre>{JSON.stringify(chartSettings, null, 2)}</pre>
            </td>
            <td>
              <pre>{JSON.stringify(request, null, 2)}</pre>
            </td>
            <td>
              <pre>{JSON.stringify(mergedMetricsData?.data, null, 2)}</pre>
            </td>
            <td>
              <pre>{JSON.stringify(mergedMetricsData?.metadata, null, 2)}</pre>
            </td>
          </tr>
        </tbody>
      </table>
    </StyledChartParams>
  )
}
