import { useVersionUserLocks } from '@fintastic/web/data-access/versions'

export type UseReadonlyParams = {
  versions: string[]
  metricsLoading: boolean
  versionsLoading: boolean
  settingsEditingActive: boolean
}

export function useReadonly({
  versions,
  metricsLoading,
  versionsLoading,
  settingsEditingActive,
}: UseReadonlyParams) {
  const versionUserLocks = useVersionUserLocks(versions)

  const atLeastOneVersionLocked = versionUserLocks.some(
    (l) => l.lockReasons?.edit_mode && l.lockedBy !== 'me',
  )

  return (
    atLeastOneVersionLocked ||
    metricsLoading ||
    versionsLoading ||
    settingsEditingActive
  )
}
