import { useMemo } from 'react'
import { VersionMetric } from '../../../../utils/metrics'
import { intersection } from 'lodash'

export const useCommonDimensions = (metrics: VersionMetric[]): string[] =>
  useMemo(
    () =>
      intersection(
        ...metrics.map((m) => m.metricMetadata.dimensions.map((d) => d.id)),
      ),
    [metrics],
  )
