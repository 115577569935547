import { Maybe } from '@fintastic/shared/util/types'
import { useLoadVersionUserLocker } from '../api/useLoadVersionUserLocker'
import { useMemo } from 'react'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import {
  doesUserLockPreventEdit,
  parseVersionUserLock,
} from '@fintastic/web/util/versions'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'

export function useVersionUserLockQuery(versionId?: Maybe<string>): {
  lock: VersionUserLockParsed
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
} {
  const { isLoading, isFetching, isError, isSuccess, data } =
    useLoadVersionUserLocker(versionId)
  const userInfo = useAuthUserInfo()

  return useMemo(() => {
    const email = userInfo?.email
    if (!versionId || !data || !email) {
      return {
        isLoading,
        isFetching,
        isError,
        isSuccess,
        lock: { ...NO_LOCK_FALLBACK, versionId: versionId || '' },
      }
    }

    const lock = parseVersionUserLock(versionId, data, email)
    const editIsBlocked = doesUserLockPreventEdit(lock)

    return {
      isLoading,
      isFetching,
      isError,
      isSuccess,
      lock: { ...lock, editIsBlocked },
    }
  }, [
    userInfo?.email,
    versionId,
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  ])
}

const NO_LOCK_FALLBACK: Omit<VersionUserLockParsed, 'versionId'> = {
  lockOps: [],
  originalLock: undefined,
  lockReasons: null,
  editIsBlocked: false,
}
