import { ColDef } from 'ag-grid-community'

export const selectorColumnDefinition: ColDef = {
  colId: '_checkboxSelection',
  pinned: true,
  suppressMenu: true,
  sortable: true,
  resizable: false,
  headerName: '',
  initialWidth: 52,
  minWidth: 52,
  maxWidth: 52,
  valueFormatter: () => '',
  filter: false,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
}
