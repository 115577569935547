import {
  Dimension,
  GenericReportRow,
  GenericReportValue,
  GenericReportAggregatesRow,
  GenericReportNormalised,
} from '@fintastic/web/util/generic-report'
import { compact } from 'lodash'
import { unknownGroupLabel } from '../utils/unknown-group-label'
import {
  MASKED_VALUE,
  BLANK_VALUE,
  isRawBlankValue,
} from '@fintastic/web/util/blanks-and-masked'
/**
 * Convert reports and dimensions to AG Rows
 */
export const buildGenericReportAgRows = (
  reports: GenericReportNormalised[],
  dimensions: Dimension[],
): GenericReportRow[] => {
  if (!reports?.length || !dimensions?.length) {
    return []
  }

  // hash[timestamp] = GenericReportRow
  const hash: Record<string, GenericReportRow> = {}

  const dimensionsToHashKey = (row: GenericReportAggregatesRow): string =>
    compact(dimensions.map((dimension) => row[dimension.name] || 'NaN')).join(
      '--',
    )

  reports.forEach(
    ({ reportOrVersionId, result: aggregates, definition, metadata }) => {
      aggregates.forEach((row) => {
        const key = dimensionsToHashKey(row)
        // Build an initial empty object for the dimension/timestamp intersection
        if (!hash[key]) {
          const dimensionValues = Object.fromEntries(
            dimensions.map((dimension) => {
              const mask = metadata?.columns.find(
                ({ name }) => name === dimension.name,
              )?.mask

              const isMasked =
                mask !== undefined && row[dimension.name] === mask

              if (isMasked) {
                return [dimension.name, MASKED_VALUE]
              }

              // If value is defined and contains blank, convert to blank symbol
              if (
                isRawBlankValue(row[dimension.name]) &&
                row[dimension.name] !== undefined
              ) {
                return [dimension.name, BLANK_VALUE]
              }

              return [
                dimension.name,
                row[dimension.name] || unknownGroupLabel(dimension.label),
              ]
            }),
          )

          hash[key] = {
            id: `${reportOrVersionId}_${key}_${reports.length}`,
            values: [],
            dimensions: dimensionValues,
          }
        }

        // Fill object with values
        const newValuesSet: GenericReportValue[] = compact(
          definition.values.map((valueBp) =>
            // Values are optional
            typeof row[valueBp.name] === 'number'
              ? {
                  ...valueBp,
                  _timestamp: row._timestamp,
                  amount: row[valueBp.name] as number,
                  reportOrVersionId,
                  mask: metadata?.columns.find(
                    ({ name }) => name === valueBp.name,
                  )?.mask,
                  ...(valueBp.aggregation === 'weighted_avg'
                    ? {
                        weightValue: row[valueBp.weight || ''] as number,
                        weightValueMask: metadata?.columns.find(
                          ({ name }) => name === valueBp.weight,
                        )?.mask,
                      }
                    : {}),
                }
              : null,
          ),
        )

        hash[key].values.push(...newValuesSet)
      })
    },
  )

  const results: GenericReportRow[] = Object.values(hash)

  return results
}
