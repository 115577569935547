import { isBaseTimeDimensionStaticWidgetContext } from '../utils'
import { useBaseTimeDimensionContext } from './useBaseTimeDimensionContext'

export const useSetStaticWidgetBaseTimeDimension = () => {
  const ctx = useBaseTimeDimensionContext()

  if (!isBaseTimeDimensionStaticWidgetContext(ctx)) {
    throw new Error('Cannot find static widget base time dimension value setter outside of local context')
  }

  return ctx.setBaseTimeDimensionId
}
