import React from 'react'
import { createSvgIcon } from '@mui/material'

export const Icon = createSvgIcon(
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.12524C0 1.08971 0.839466 0.250244 1.875 0.250244H3.125C4.16053 0.250244 5 1.08971 5 2.12524V18.3752C5 19.4108 4.16053 20.2502 3.125 20.2502H1.875C0.839466 20.2502 0 19.4108 0 18.3752V2.12524ZM1.875 1.50024C1.52982 1.50024 1.25 1.78007 1.25 2.12524V18.3752C1.25 18.7204 1.52982 19.0002 1.875 19.0002H3.125C3.47018 19.0002 3.75 18.7204 3.75 18.3752V2.12524C3.75 1.78007 3.47018 1.50024 3.125 1.50024H1.875Z"
      fill="#263646"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50081 2.12524C7.50081 1.08971 8.34028 0.250244 9.37581 0.250244H10.6258C11.6613 0.250244 12.5008 1.08971 12.5008 2.12524V18.3752C12.5008 19.4108 11.6613 20.2502 10.6258 20.2502H9.37581C8.34028 20.2502 7.50081 19.4108 7.50081 18.3752V2.12524ZM9.37581 1.50024C9.03064 1.50024 8.75081 1.78007 8.75081 2.12524V18.3752C8.75081 18.7204 9.03064 19.0002 9.37581 19.0002H10.6258C10.971 19.0002 11.2508 18.7204 11.2508 18.3752V2.12524C11.2508 1.78007 10.971 1.50024 10.6258 1.50024H9.37581Z"
      fill="#263646"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.875 0.250244C15.8395 0.250244 15 1.08971 15 2.12524V18.3752C15 19.4108 15.8395 20.2502 16.875 20.2502H18.125C19.1605 20.2502 20 19.4108 20 18.3752V2.12524C20 1.08971 19.1605 0.250244 18.125 0.250244H16.875ZM16.25 2.12524C16.25 1.78007 16.5298 1.50024 16.875 1.50024H18.125C18.4702 1.50024 18.75 1.78007 18.75 2.12524V18.3752C18.75 18.7204 18.4702 19.0002 18.125 19.0002H16.875C16.5298 19.0002 16.25 18.7204 16.25 18.3752V2.12524Z"
      fill="#263646"
    />
  </svg>,
  'Icon',
)

export const ChartSettingsSubsectionIcon = () => <Icon sx={{ width: 20 }} />
