export class NotAMetricError extends Error {
  constructor() {
    super('Mismatched editing state: not a metric')
    this.name = 'NotAMetricError'
    Object.setPrototypeOf(this, NotAMetricError.prototype)
  }
}

export class NotAnInputMetricError extends Error {
  constructor() {
    super('This is not an input metric')
    this.name = 'NotAnInputMetricError'
    Object.setPrototypeOf(this, NotAnInputMetricError.prototype)
  }
}

export class NotACalculatedMetricError extends Error {
  constructor() {
    super('This is not an calculated metric')
    this.name = 'NotACalculatedMetricError'
    Object.setPrototypeOf(this, NotACalculatedMetricError.prototype)
  }
}
