import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { MetricGridRow } from '../components/metric-grid/types'
import { applyPartialUpdateTransaction } from '@fintastic/shared/util/ag-grid'

export type PartialRowToUpdate = Pick<MetricGridRow, '_rowId'>

export function updateColumns(
  gridApi: GridApi<MetricGridRow>,
  partialRowsToUpdate: PartialRowToUpdate[],
) {
  applyPartialUpdateTransaction(gridApi, partialRowsToUpdate, '_rowId')
}
