import React, { useCallback } from 'react'
import Chip from '@mui/material/Chip'
import { Tag } from './types'
import { CancelOutlined } from '@mui/icons-material'
import { Avatar } from '@mui/material'

export type TagsTagProps = {
  onDelete?: (tag: Tag) => void
  tag: Tag
  hidden?: boolean
}

export const TagsTag: React.FC<TagsTagProps> = ({ onDelete, tag, hidden }) => {
  const handleDelete = useCallback(() => {
    onDelete?.(tag)
  }, [onDelete, tag])

  return (
    <Chip
      label={tag.title}
      onDelete={onDelete ? handleDelete : undefined}
      deleteIcon={<CancelOutlined />}
      size="small"
      avatar={<Avatar sx={{ background: tag.color }}> </Avatar>}
      data-tag={tag.title}
      data-testid="tag"
      sx={hidden ? { visibility: 'hidden' } : undefined}
    />
  )
}
