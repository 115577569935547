import React, { useCallback } from 'react'
import {
  FormulaEditor,
  Version,
  FormulaEditorTitleHighlight,
  FormulaValidationCallback,
} from '@fintastic/web/feature/formulas'
import { ListGridSettingsPanelProps } from '../../components/list-grid/types'
import { Maybe } from '@fintastic/shared/util/types'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { ClientOnlyMapping } from '@fintastic/web/util/formulas'

export type ListGridColumnFormulaEditorWrapperProps = {
  versions: Version[]
  column: ListColumnWrapper
  onRequestClose: () => void
  controlledMode?: boolean
  controlledFormulaError: Maybe<string>
  onValidationFinished?: ListGridSettingsPanelProps['onColumnFormulaValidationFinished']
  readonly?: boolean
  clientOnlyMapping?: Maybe<ClientOnlyMapping>
}

// @todo add tests
export const ListGridColumnFormulaEditorWrapper: React.FC<
  ListGridColumnFormulaEditorWrapperProps
> = ({
  versions,
  column,
  onRequestClose,
  controlledMode,
  onValidationFinished,
  readonly,
  clientOnlyMapping,
  controlledFormulaError,
}) => {
  const handleValidationFinished = useCallback<
    FormulaValidationCallback<string>
  >(
    (versionId, formula, invalid, columnId) => {
      if (!columnId) {
        return
      }

      if (onValidationFinished) {
        onValidationFinished(columnId, formula, invalid)
      }
    },
    [onValidationFinished],
  )

  return (
    <FormulaEditor
      title={
        <>
          Forecast formula (column)
          <FormulaEditorTitleHighlight>
            {column.label()}
          </FormulaEditorTitleHighlight>
        </>
      }
      versions={versions}
      showVersionsSelector={false}
      onRequestClose={onRequestClose}
      controlledEditingMode={controlledMode}
      onValidationFinished={handleValidationFinished}
      clientOnlyMapping={clientOnlyMapping || undefined}
      readonly={readonly}
      controlledFormulaError={controlledFormulaError}
      context={column.clientOnlyId()}
    />
  )
}
