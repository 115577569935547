import React, { useCallback } from 'react'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import { Board } from '../../../types'
import { useBoardDeleteMutation } from '../../../hooks/useBoardDeleteMutation'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type BoardDeleteFormProps = {
  boardId: Board['id']
  boardName: Board['name']
  closeParentModal?: () => void
}

export const BoardDeleteForm: React.FC<BoardDeleteFormProps> = (props) => {
  const { closeParentModal, boardId, boardName } = props

  const deleteBoardMutation = useBoardDeleteMutation()

  const handleSubmit = useCallback(() => {
    if (closeParentModal) {
      closeParentModal()
    }
    deleteBoardMutation.mutate({ boardId, permanently: true })
  }, [boardId, closeParentModal, deleteBoardMutation])

  return (
    <FormLayout
      submitButtonText="Delete board"
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
    >
      <ErrorAlert
        title={
          <>
            Are you sure you want to permanently delete board <b>{boardName}</b>
            ?
          </>
        }
        message="Deleting the Board will also delete all related comments."
        severity="warning"
      />
    </FormLayout>
  )
}
