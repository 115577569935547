import React from 'react'
import { createSvgIcon } from '@mui/material'

export const EditOffIcon = createSvgIcon(
  <path
    d="M15.55 12.675L14.15 11.275L16.2 9.22502L14.775 7.80002L12.725 9.85002L11.325 8.45002L16.2 3.57502C16.4 3.37502 16.6209 3.22919 16.8625 3.13752C17.1042 3.04586 17.3584 3.00002 17.625 3.00002C17.8917 3.00002 18.15 3.05002 18.4 3.15002C18.65 3.25002 18.8667 3.40002 19.05 3.60002L20.425 5.00002C20.625 5.18336 20.7709 5.40002 20.8625 5.65002C20.9542 5.90002 21 6.15002 21 6.40002C21 6.66669 20.9542 6.92086 20.8625 7.16252C20.7709 7.40419 20.625 7.62502 20.425 7.82502L15.55 12.675ZM5.00002 19H6.42502L11.3 14.125L10.6 13.4L9.87502 12.7L5.00002 17.575V19ZM19.8 22.6L12.725 15.55L7.25002 21H3.00002V16.775L8.47502 11.3L1.40002 4.20002L2.82502 2.77502L21.225 21.175L19.8 22.6ZM10.6 13.4L9.87502 12.7L11.3 14.125L10.6 13.4Z"
    fill="currentColor"
  />,
  'EditOff',
)
