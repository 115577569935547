import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { ModelExplorerVersionSelectorForEntity } from './components/ModelExplorerVersionSelectorForEntity'
import { ModelExplorerVersionSelectorUIDisabled } from './components/ModelExplorerVersionSelectorUIDisabled'

export type ModelExplorerVersionSelectorProps = {
  disabled?: boolean
  entityId?: Maybe<string>
  entityTitle?: string
  disabledVersionsDueToReports?: string[]
}

export const ModelExplorerVersionSelector: React.FC<
  ModelExplorerVersionSelectorProps
> = ({
  disabled = false,
  entityId,
  entityTitle,
  disabledVersionsDueToReports,
}) => {
  const hasModelerRole = useRoleLevelAccess(['power_user', 'modeler'])

  // do not display and do not request anything if it has no sense
  if (!hasModelerRole || !entityId) {
    return null
  }

  // do not display if disabled (i.e. in design mode)
  if (disabled) {
    return <ModelExplorerVersionSelectorUIDisabled />
  }

  return (
    <ModelExplorerVersionSelectorForEntity
      disabled={disabled}
      entityTitle={entityTitle}
      entityId={entityId}
      disabledVersionsDueToReports={disabledVersionsDueToReports}
    />
  )
}
