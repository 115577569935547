import { styled, Box, MenuItem } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(MenuItem)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'flex-start',
  padding: theme.spacing(1, 1, 1, 2.25),
}))

export const StyledIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),

  width: 24,
  minWidth: 24,
  fontSize: 0,

  svg: {
    width: '100%',
    height: 'auto',
  },
}))

export const StyledLabel = styled(Box)(({ theme }) => ({}))

export const StyledAfterLabel = styled(Box)(({ theme }) => ({
  maxWidth: 130,
  marginLeft: 'auto',
}))
