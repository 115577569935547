import { styled, Box, BoxProps } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledPanelDragHandleRoot = styled(
  ({
    placement,
    ...restProps
  }: BoxProps & {
    placement: 'left' | 'right'
  }) => <Box {...restProps} />,
)(({ theme, placement }) => ({
  ...styledMixins.alignContentCenter(),
  display: 'inline-flex',
  margin: placement === 'right' ? '0 0 0 8px' : '0 8px 0 0',

  width: '10px',
  minWidth: '10px',

  color: theme.palette.grey[400],
  cursor: 'move',
}))
