import React from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { MessageBubbleSquare24x24Icon } from '@fintastic/shared/ui/icons'

export const CommentButton: React.FC<IconButtonProps> = (props) => (
  <Tooltip title={'Comments'} placement="bottom" arrow>
    <IconButton disableRipple {...props}>
      <MessageBubbleSquare24x24Icon />
    </IconButton>
  </Tooltip>
)
