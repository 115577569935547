import type { ICellRendererParams } from 'ag-grid-community'
import { VersionTable } from '../types'
import { TagsTag } from '@fintastic/shared/ui/components'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'
import { Box, Skeleton } from '@mui/material'
import { useMemo } from 'react'

export const FilterLabelCellRenderer = ({
  value,
}: ICellRendererParams<VersionTable>) => {
  const labelsQuery = useLabelsListQuery('versions', false)

  const label = useMemo(
    () => (labelsQuery.data || []).find((i) => `${i.id}` === value),
    [labelsQuery.data, value],
  )

  if (value === '(Select All)') {
    return <span>(Select All)</span>
  }

  if (value === '-1') {
    return <span>(Blanks)</span>
  }

  if (labelsQuery.isLoading && !label) {
    return <Skeleton width={80} />
  }

  if (!label) {
    return <span>(Unknown label #{value})</span>
  }

  return (
    <Box
      py="3px"
      sx={{
        background: 'white',
        overflow: 'hidden',
        height: '24px',
        boxSizing: 'border-box',
      }}
    >
      <Box marginTop="-1.5px">
        <TagsTag tag={label} />
      </Box>
    </Box>
  )
}
