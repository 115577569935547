import React, { forwardRef } from 'react'
import { WidgetListIcon } from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

export type ListIconProps = {
  size?: SvgIconProps['fontSize']
}

export const ListIcon = forwardRef<SVGSVGElement, ListIconProps>(
  ({ size, ...props }, ref) => (
    <WidgetListIcon
      {...props}
      fontSize={size}
      ref={ref}
      sx={{
        color: '#047D34',
      }}
    />
  ),
)
