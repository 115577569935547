// @todo refactoring and revision are needed

import type { DateRange, Maybe } from '@fintastic/shared/util/types'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import {
  DEFAULT_DAYJS_DATE_FORMAT,
  DEFAULT_DAYJS_DATE_TIME_FORMAT,
} from './const'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)
dayjs.extend(customParseFormat)

export const tzLocal = dayjs.tz.guess() // "Europe/Madrid)
export const tzGMT = dayjs(new Date()).format('z') // 'GMT+2'

export const dateToLocalDate = (date?: Date | string) => {
  if (!date) {
    return null
  }

  const dateToProcess = typeof date === 'string' ? new Date(date) : date

  if (!dayjs(dateToProcess).isValid()) {
    return null
  }

  if (!dayjs(dateToProcess).utc(true).isUTC()) {
    return null
  }

  return dayjs(dateToProcess).utc(true).tz(tzLocal)
}

export const dateToLocalDateTimeString = (
  date?: Date | string,
  invalidDateText = '',
  format = DEFAULT_DAYJS_DATE_TIME_FORMAT,
) => {
  const preparedDate = dateToLocalDate(date)
  if (!preparedDate) {
    return invalidDateText
  }

  return preparedDate.format(format)
}

/**
 * Converts range with period starts to renge with period end in `to` field
 * E. g. year range 2022-06-02:2023-06-02 to be 2022-01-01:2023-12-31
 * @deprecated In favor of new period selector
 **/
export const rangeToEdges = (
  range: DateRange,
  toDayOffset = false,
): DateRange => {
  if (
    !range?.timeSegmentationFrame ||
    !range?.dateRangeFrom ||
    !range?.dateRangeTo
  ) {
    return range
  }

  const dateFrom = dayjs.utc(range.dateRangeFrom, DEFAULT_DAYJS_DATE_FORMAT)
  const dateTo = dayjs.utc(range.dateRangeTo, DEFAULT_DAYJS_DATE_FORMAT)

  if (range.timeSegmentationFrame === 'half_year') {
    const fromMonth = dateFrom.month() >= 5 ? 5 : 0
    const toMonth = dateTo.month() >= 5 ? 11 : 5

    let dateRangeTo = dateTo.set('month', toMonth).endOf('month')

    if (toDayOffset) {
      dateRangeTo = dateRangeTo.add(1, 'day')
    }

    return {
      timeSegmentationFrame: 'half_year',
      dateRangeFrom: dateFrom
        .set('month', fromMonth)
        .endOf('month')
        .format(DEFAULT_DAYJS_DATE_FORMAT),
      dateRangeTo: dateRangeTo.format(DEFAULT_DAYJS_DATE_FORMAT),
    }
  }

  const edgeFrom = dateFrom
    .startOf(range.timeSegmentationFrame)
    .format(DEFAULT_DAYJS_DATE_FORMAT)

  let edgeTo = dateTo.endOf(range.timeSegmentationFrame)

  if (toDayOffset) {
    edgeTo = edgeTo.add(1, 'day')
  }

  return {
    timeSegmentationFrame: range.timeSegmentationFrame,
    dateRangeFrom: edgeFrom,
    dateRangeTo: edgeTo.format(DEFAULT_DAYJS_DATE_FORMAT),
  }
}
