import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  padding: 0 16px 16px;
`

export const StyledLabel = styled('div')`
  margin-bottom: 3px;
`

export const StyledContent = styled('div')``

export const StyledColumnChip = styled('div')`
  display: inline-block;
  padding: 4px 8px;

  font-size: 12px;

  border-radius: 4px;
  background: #f8f4f1;
  color: #a94401; ;
`
