import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  width: 100%;
`

export const StyledHeader = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  width: 100%;
`

export const StyledFilters = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  width: 100%;
`

export const StyledList = styled('div')`
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

export const StyledPagination = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 10px 8px 10px 18px;

  width: 100%;
`
