import { styled } from '@mui/material/styles'
import { IconButton, Paper } from '@mui/material'

export const VersionsChipsDropdownRoot = styled('span')`
  font-size: inherit;
  display: inline-flex;
  padding: 0 0.2em;
  z-index: 10;
`

export const StyledDropdownButton = styled(IconButton)`
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 10;
`

export const StyledDropdownPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1.5)}
    ${({ theme }) => theme.spacing(0.5)};
  z-index: 10;
  position: relative;
  margin-top: 4px;

  span.arrow-top {
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(0, 0, 0, 0.1);

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
  }
`
