import { ContextMenuItem } from '@fintastic/shared/ui/context-menu-framework'
import { BoardFolder } from '../types'
import { BOARD_FOLDER_ARCHIVE_ID } from '../const/board-folders'

export type CreateSidebarFolderContextMenuBuilderParams = {
  handleRename: (folderId: BoardFolder['id']) => void
}

export function createSidebarFolderContextMenuBuilder(
  params: CreateSidebarFolderContextMenuBuilderParams,
) {
  const { handleRename } = params

  return (folderId: BoardFolder['id']) => {
    const contextMenuItems: ContextMenuItem[] = []

    if (folderId === BOARD_FOLDER_ARCHIVE_ID) {
      return contextMenuItems
    }

    contextMenuItems.push({
      type: 'button' as const,
      id: 'rename',
      label: 'Rename',
      onClick: () => handleRename(folderId),
    })

    return contextMenuItems
  }
}
