import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { LabelsDropdownEditButton } from './LabelsDropdownEditButton'
import { AddIcon } from '@fintastic/shared/ui/icons'
import { LabelItem } from './types'
import { SYSTEM_COLORS } from '@fintastic/shared/util/colors'
import { sample } from 'lodash'

export const LabelsDropdownCreateNew: React.FC<{
  text: string
  onCreate: (label: LabelItem) => void
  searchInputRef: React.RefObject<HTMLInputElement>
}> = ({ text, onCreate, searchInputRef }) => {
  const [color, setColor] = useState(() => sample(SYSTEM_COLORS)?.bg || '')
  const [title, setTitle] = useState(text)

  const newLabel = useMemo<LabelItem>(
    () => ({
      id: null,
      title,
      color,
      checked: true,
      is_removable: false,
    }),
    [color, title],
  )

  const handleCreated = useCallback(() => {
    onCreate(newLabel)
  }, [newLabel, onCreate])

  const handleLabelUpdated = useCallback(async (label: LabelItem) => {
    setColor(label.color)
    setTitle(label.title)
    return 201
  }, [])

  useEffect(() => setTitle(text), [text])

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        handleCreated()
      }
    }
    const searhInput = searchInputRef.current
    searhInput?.addEventListener('keypress', handleKeyPress)

    return () => searhInput?.removeEventListener('keypress', handleKeyPress)
  }, [handleCreated])

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <LabelsDropdownEditButton
          onLabelUpdated={handleLabelUpdated}
          label={newLabel}
        />
      }
    >
      <ListItemButton onClick={handleCreated}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <AddIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box display="flex" alignItems="center">
              {color && (
                <Avatar
                  sx={{
                    background: color,
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                  }}
                >
                  {' '}
                </Avatar>
              )}{' '}
              {title}
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
