import React, { useCallback, useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { TableConfig } from '../types'
import { PolicyTableResourceType } from '../api'

type TableSelectorProps = {
  tables: TableConfig[]
  onSelectTable: (table: string, resourceType: PolicyTableResourceType) => void
}

export const TableSelector: React.FC<TableSelectorProps> = (props) => {
  const [currentTableId, setCurrentTableId] = React.useState('')
  const currentTable = useMemo(
    () => props.tables.find((table) => table.id === currentTableId),
    [currentTableId, props.tables],
  )

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setCurrentTableId(event.target.value)
  }, [])

  const handleSelectTable = useCallback(() => {
    if (!currentTable) {
      return
    }
    props.onSelectTable(currentTable.id, currentTable.resourceType)
  }, [currentTable, props.onSelectTable])

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1 }} style={{flexDirection: 'row'}}>
        <Select
          style={{minWidth: '200px'}}
          id="permissions-table-selector"
          value={currentTableId}
          onChange={handleChange}
          data-testid="permissions-table-selector"
        >
          {props.tables.map((table) => (
            <MenuItem key={table.id} value={table.id}>
              {table.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          style={{width: 'max-content'}}
          disabled={!currentTableId}
          onClick={handleSelectTable}
          data-testid="permissions-table-override-button"
        >
          <AddIcon /> Add pemissions override for this table
        </Button>
      </FormControl>
    </div>
  )
}
