import { useCallback, useMemo } from 'react'
import { ReorderColumnsAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useReorderColumnsFlow() {
  const { reorderColumns } = useListColumnsActions()

  const handleAction = useCallback<ReorderColumnsAction>(
    (columnsIds) => {
      reorderColumns({ columnsIds })
    },
    [reorderColumns],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
