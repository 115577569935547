import { StyledDiffModeRoot } from './DiffModeSelector.styled'
import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import {
  defaultDiffMode,
  DiffMode,
  diffModes,
  diffModeTitles,
} from '@fintastic/web/util/versions'

export type DiffModeSelectorProps = {
  mode?: Maybe<DiffMode>
  setMode: (mode: DiffMode) => void
}

export const DiffModeSelector: React.FC<DiffModeSelectorProps> = ({
  mode,
  setMode,
}) => {
  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: DiffMode | null,
  ) => {
    if (newMode) {
      setMode(newMode)
    }
  }

  return (
    <StyledDiffModeRoot>
      <ToggleButtonGroup
        value={mode || defaultDiffMode}
        exclusive
        onChange={handleModeChange}
        size="small"
      >
        {diffModes.map((mode) => (
          <ToggleButton value={mode} key={'k_' + mode}>
            {diffModeTitles[mode]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </StyledDiffModeRoot>
  )
}
