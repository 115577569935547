import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { Board } from '../../../types'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { BoardMoveToNewFolderForm } from '../../forms/BoardMoveToNewFolderForm'

export type BoardMoveToNewFolderModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  boardId?: Maybe<Board['id']>
}

export const BoardMoveToNewFolderModal: React.FC<
  BoardMoveToNewFolderModalProps
> = (props) => {
  const { isOpen, onRequestClose, boardId } = props

  return (
    <Modal
      open={isOpen}
      onRequestClose={onRequestClose}
      title="Create new folder"
    >
      {boardId && (
        <BoardMoveToNewFolderForm
          closeParentModal={onRequestClose}
          boardId={boardId}
        />
      )}
    </Modal>
  )
}
