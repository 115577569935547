import { useCallback, useEffect, useMemo } from 'react'
import { GetHistoryLogEntriesParams } from './api'
import { HistoryLogFilters } from '@fintastic/web/data-access/history'
import { useLocalStorage } from 'usehooks-ts'
import { isEqual } from 'lodash'
import { iso8601 } from '@fintastic/shared/util/date'
import dayjs from 'dayjs'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'

export const useHistoryLogParamsState = (
  overridingParams: Partial<{
    paginationLimit: number
    filter: Pick<
      HistoryLogFilters,
      | 'entity_id'
      | 'parent_entity_type'
      | 'parent_entity_id'
      | 'action'
      | 'entity_type'
    >
  }>,
  localStorageKeyPostfix: string,
) => {
  const entriesPerPage = overridingParams.paginationLimit || 50

  const defaultParams = useMemo<GetHistoryLogEntriesParams>(
    () => ({
      desc: true,
      pagination: {
        limit: entriesPerPage,
        offset: 0,
      },
      filter: {
        ...getDefaultFilterDateParams(),
        ...overridingParams.filter,
      },
    }),
    [entriesPerPage, overridingParams.filter],
  )

  const [params, setParams] = useLocalStorage<GetHistoryLogEntriesParams>(
    makeLocalStorageKey(localStorageKeyPostfix),
    defaultParams,
  )

  const defaultParamsDeeplyCompared = useReferenceMemo(defaultParams, isEqual)
  useEffect(() => {
    setParams(defaultParamsDeeplyCompared)
  }, [defaultParamsDeeplyCompared, setParams])

  const changeCurrentPage = useCallback(
    (targetPage: number) => {
      setParams((val) => ({
        ...val,
        pagination: {
          offset: targetPage * entriesPerPage,
          limit: entriesPerPage,
        },
      }))
    },
    [entriesPerPage, setParams],
  )

  const changeParams = useCallback(
    (newParams: Partial<Omit<GetHistoryLogEntriesParams, 'pagination'>>) => {
      setParams((v) => ({
        ...v,
        desc: newParams.desc === undefined ? v.desc : newParams.desc,
        filter: {
          ...v.filter,
          ...newParams.filter,
          ...overridingParams.filter,
        },
      }))
    },
    [overridingParams.filter, setParams],
  )

  const reset = useCallback(() => {
    setParams(defaultParams)
  }, [defaultParams, setParams])

  return useMemo(
    () =>
      ({
        params,
        defaultParams,
        changeCurrentPage,
        changeParams,
        reset,
      } as const),
    [changeCurrentPage, changeParams, defaultParams, params, reset],
  )
}

const makeLocalStorageKey = (scope: string) => `historyLogParams/${scope}`

export function getDefaultFilterDateParams(): Partial<
  GetHistoryLogEntriesParams['filter']
> {
  const toDate = iso8601.toUtc(
    iso8601.fromDate(dayjs(new Date()).endOf('day').toDate()),
  )

  const fromDate = iso8601.toUtc(
    iso8601.fromDate(
      dayjs(new Date()).subtract(30, 'day').startOf('day').toDate(),
    ),
  )
  return {
    min_date: fromDate,
    max_date: toDate,
    entity_type: ['list', 'metric'],
    action: ['add_line', 'change_value', 'delete_line'],
  }
}
