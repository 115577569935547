import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { SmartSelectOption } from '../types'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import List from '@mui/material/List'
import { OptionsListItem } from './OptionsListItem'
import { Maybe } from '@fintastic/shared/util/types'
import ListItem from '@mui/material/ListItem'

export type OptionsListProps<T> = {
  options: SmartSelectOption<T>[]
  selected: T[]
  onSelectOptions: (o: T[]) => void
  onUnselectOptions: (o: T[]) => void
}

type VirtualizedListData<T> = {
  options: SmartSelectOption<T>[]
  selected: T[]
  handleSelectAll: () => void
  handleUnselectAll: () => void
  handleSelectItem: (o: SmartSelectOption<T>, withShift: boolean) => void
  handleUnselectItem: (o: SmartSelectOption<T>) => void
}

const Row = <T,>({
  data,
  index,
  style,
}: ListChildComponentProps<VirtualizedListData<T>>) => {
  if (index === 0) {
    const enabledOptions = data.options.filter((o) => !o.disabled)
    const selected =
      enabledOptions.length <= data.selected.length
        ? enabledOptions.every((o) => data.selected.includes(o.value))
        : false

    return (
      <OptionsListItem
        option="all"
        selected={selected}
        onClick={() =>
          selected ? data.handleUnselectAll() : data.handleSelectAll()
        }
        disabled={false}
        style={style}
      />
    )
  }

  const rowData = data.options[index - 1]
  const selected = data.selected.includes(rowData.value)

  return (
    <OptionsListItem
      option={rowData}
      selected={selected}
      onClick={(withShift) =>
        selected
          ? data.handleUnselectItem(rowData)
          : data.handleSelectItem(rowData, withShift)
      }
      disabled={!!rowData.disabled}
      style={style}
    />
  )
}

export const OptionsList = <T,>({
  options,
  onUnselectOptions,
  onSelectOptions,
  selected,
}: OptionsListProps<T>): JSX.Element => {
  const listRef = useRef<Maybe<FixedSizeList<VirtualizedListData<T>>>>(null)

  const scrollToFirstSelected = useCallback(() => {
    if (!listRef.current || selected.length === 0) {
      return
    }
    const optionIndex = options.findIndex((o) => o.value === selected[0])
    if (optionIndex === -1) {
      return
    }
    listRef.current.scrollToItem(optionIndex + 1, 'smart')
  }, [options, selected])
  const scrollToFirstSelectedRef = useRef(scrollToFirstSelected)
  scrollToFirstSelectedRef.current = scrollToFirstSelected

  useEffect(() => {
    scrollToFirstSelectedRef.current()
  }, [])

  const displayedListItems = options.length + 1

  const itemHeight = 40
  const maxItemsForViewPort = 8
  const listHeight =
    displayedListItems > maxItemsForViewPort
      ? maxItemsForViewPort * itemHeight + itemHeight / 2
      : displayedListItems * itemHeight

  const handleSelectAll = useCallback(() => {
    lastSelectedOption.current = null
    onSelectOptions(options.filter((o) => !o.disabled).map((o) => o.value))
  }, [onSelectOptions, options])

  const handleUnselectAll = useCallback(() => {
    onUnselectOptions(options.filter((o) => !o.disabled).map((o) => o.value))
    lastSelectedOption.current = null
  }, [onUnselectOptions, options])

  const lastSelectedOption = useRef<Maybe<SmartSelectOption<T>>>(null)
  useEffect(() => {
    lastSelectedOption.current = null
  }, [options])

  const handleSelectItem = useCallback(
    (o: SmartSelectOption<T>, withShift: boolean) => {
      if (lastSelectedOption.current !== null && withShift) {
        const exclusiveFrom = lastSelectedOption.current.value
        const inclusiveTo = o.value
        const fromIndex =
          options.findIndex((o) => o.value === exclusiveFrom) + 1
        const toIndex = options.findIndex((o) => o.value === inclusiveTo) + 1
        onSelectOptions(
          options
            .slice(
              fromIndex > toIndex ? toIndex - 1 : fromIndex,
              toIndex > fromIndex ? toIndex : fromIndex,
            )
            .filter((o) => !o.disabled)
            .map((o) => o.value),
        )
        lastSelectedOption.current = null
        return
      }

      lastSelectedOption.current = o
      onSelectOptions([o.value])
    },
    [onSelectOptions, options],
  )

  const handleUnselectItem = useCallback(
    (o: SmartSelectOption<T>) => {
      lastSelectedOption.current = null
      onUnselectOptions([o.value])
    },
    [onUnselectOptions],
  )

  const listData = useMemo<VirtualizedListData<T>>(
    () => ({
      selected,
      options,
      handleSelectAll,
      handleUnselectAll,
      handleSelectItem,
      handleUnselectItem,
    }),
    [
      handleSelectAll,
      handleSelectItem,
      handleUnselectAll,
      handleUnselectItem,
      options,
      selected,
    ],
  )

  return (
    <List sx={{ p: 0 }}>
      {options.length === 0 && <ListItem>Nothing found</ListItem>}
      {options.length > 0 && (
        <FixedSizeList<VirtualizedListData<T>>
          ref={listRef}
          width="100%"
          height={listHeight}
          itemSize={itemHeight}
          itemCount={displayedListItems}
          itemData={listData}
        >
          {Row}
        </FixedSizeList>
      )}
    </List>
  )
}
