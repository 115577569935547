import React from 'react'

export type IconProps = {
  width?: number | string
  height?: number | string
  color?: string
  className?: string
}

export const DeleteIcon: React.FC<IconProps> = ({
  width = 16,
  height = 16,
  color = 'black',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6831 5.17426C14.9174 4.93995 14.9174 4.56005 14.6831 4.32574C14.4488 4.09142 14.0689 4.09142 13.8346 4.32574L9.50442 8.65589L5.17426 4.32574C4.93995 4.09142 4.56005 4.09142 4.32574 4.32574C4.09142 4.56005 4.09142 4.93995 4.32574 5.17426L8.65589 9.50442L4.32574 13.8346C4.09142 14.0689 4.09142 14.4488 4.32574 14.6831C4.56005 14.9174 4.93995 14.9174 5.17426 14.6831L9.50442 10.3529L13.8346 14.6831C14.0689 14.9174 14.4488 14.9174 14.6831 14.6831C14.9174 14.4488 14.9174 14.0689 14.6831 13.8346L10.3529 9.50442L14.6831 5.17426Z"
      fill={color}
    />
  </svg>
)
