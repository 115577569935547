import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`
