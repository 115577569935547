import { useMemo } from 'react'
import { useSectionedModalNavigationContext } from './useSectionedModalNavigationContext'
import { SectionedModalSectionDefinition } from '../types'
import { useSectionedModalRoutesContext } from './useSectionedModalRoutesContext'

export const useModalSectionDefinition = () => {
  const { path } = useSectionedModalNavigationContext()
  const { routes } = useSectionedModalRoutesContext()

  return useMemo<SectionedModalSectionDefinition>(
    () =>
      (routes || []).find((sm) => sm.path === path) ??
      ({
        path,
        title: 'Unknown section',
        subTitle: 'Unknown subsection',
        icon: undefined,
        view: null,
      } as SectionedModalSectionDefinition),
    [path, routes],
  )
}
