import { Box, styled } from '@mui/material'

export const StyledChartError = styled(Box)`
  align-self: center;
  justify-self: center;
  width: 100%;
  position: relative;
  flex-grow: 2;
  flex-shrink: 1;
  display: flex;
  flex-flow: column wrap;
`

export const StyledChartContent = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  flex-grow: 2;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
`

export const StyledChartPanel = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 2;
  width: 100%;
`
