import { QueryClient } from 'react-query'
import { versionsListRefreshKeys } from './cache-keys'
import { Version } from '@fintastic/web/util/versions'

export function optimisticDeleteVersionsListCache(
  queryClient: QueryClient,
  versionsIds: string[],
) {
  versionsListRefreshKeys.forEach((key) => {
    queryClient.setQueryData<Version[]>(key, (old) => {
      if (!old) {
        return []
      }
      return old.filter((r) => !versionsIds.includes(r.uuid))
    })
  })
}
