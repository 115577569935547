import {
  SectionedModal,
  SectionedModalSectionDefinition,
} from '@fintastic/shared/ui/modal-framework'
import {
  allRoutes,
  ChartSettings,
  chartSettingsDialogGeneralRoute,
  chartSettingsDialogNavigationRoute,
  getChartWidgetSettingsOrDefaults,
  MetricChartDimensions,
} from '@fintastic/web/feature/charts'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useCommonDataWidgetSettings,
  useSetWidgetSettingsVersionErrorsEffect,
  useWidgetSettingsDialogContext,
  WidgetSettingsButtonPanel,
  WidgetSettingsDialogIssues,
} from '@fintastic/web/feature/boards'
import { ChartSettingsEditContext } from '../chart-container/components/settings-panel/contexts'
import { useEditChartSettings } from '../chart-container/components'
import { Maybe } from '@fintastic/shared/util/types'
import { useCheckCurrentMetricDimensions } from './hooks/useCheckCurrentMetricDimensions'

export const PENDING_METRIC_CHART_DIMS = 'METRIC_CHART_DIMS_LOADING'
export const ERROR_METRIC_CHART_DIMS = 'METRIC_CHART_DIMS_ERROR'

export const ChartWidgetSettings: React.FC = () => {
  const {
    title,
    data,
    onConfirm,
    onCancel,
    isNew,
    valid,
    setPartialData,
    pendingApi,
    errorsApi,
  } = useWidgetSettingsDialogContext()

  const { showRefVersionProblem } = useCommonDataWidgetSettings(data)

  const showNoReferenceVersionSelected =
    !pendingApi.isAnyPendingProcess &&
    !data.versionId &&
    !showRefVersionProblem &&
    Boolean(data.id)

  useSetWidgetSettingsVersionErrorsEffect(
    showRefVersionProblem,
    showNoReferenceVersionSelected,
  )

  const handleUpdateChartWidgetSettings = useCallback(
    (chartSettings: Maybe<ChartSettings>) => {
      setPartialData({
        ...data,
        chartSettings: { ...data.chartSettings, ...chartSettings },
      })
    },
    [data, setPartialData],
  )

  // according to https://fintastic.atlassian.net/browse/FIN-8572
  // for new charts user see only general section with Next button
  const [nextClicked, setNextClicked] = useState<boolean>(false)
  // to prevent [<] blinking
  const [navigationAllowed, setNavigationAllowed] = useState<boolean>(false)
  const [allowLeaveGeneral, setAllowLeaveGeneral] = useState<boolean>(true)

  const goToNavigationSection = useCallback(() => {
    setNextClicked(() => true)
    setTimeout(() => {
      setNavigationAllowed(() => true)
    }, 200)
  }, [])

  const settingsEditContextValue = useEditChartSettings({
    chartSettings: getChartWidgetSettingsOrDefaults(data.chartSettings),
    referenceSettings: { ...data.chartSettings },
    handleUpdateChartWidgetSettings,
    immediatelyApply: true,
    extraPayload: {
      navigationAllowed,
    },
  })

  const handleCheckUpdateDims = useCallback(
    (dimensions: MetricChartDimensions) => {
      // update outer context
      handleUpdateChartWidgetSettings({
        dimensions,
      })
      // update inner edit context
      settingsEditContextValue.localSettings.dimensions = dimensions
    },
    [handleUpdateChartWidgetSettings, settingsEditContextValue.localSettings],
  )

  const { loading: dimsLoading, error: dimsError } =
    useCheckCurrentMetricDimensions({
      metricId: data.id,
      versionId: data.versionId,
      storedDimensions: data.chartSettings?.dimensions,
      updateDimensions: handleCheckUpdateDims,
    })

  useEffect(() => {
    if (dimsLoading) {
      pendingApi.addPendingProcess(PENDING_METRIC_CHART_DIMS)
      errorsApi.removePendingError(ERROR_METRIC_CHART_DIMS)
    } else {
      pendingApi.finishPendingProcess(PENDING_METRIC_CHART_DIMS)
    }
  }, [dimsLoading, errorsApi, pendingApi])

  useEffect(() => {
    if (dimsError) {
      errorsApi.addPendingError({
        id: ERROR_METRIC_CHART_DIMS,
        message:
          'The data type of the selected Metric is not numerical and cannot be displayed in a chart. Please select a different Metric.',
      })
    } else {
      errorsApi.removePendingError(ERROR_METRIC_CHART_DIMS)
    }
  }, [dimsError, errorsApi])

  const actualRoutes = useMemo(() => {
    if (!isNew || nextClicked) {
      return {
        initialPath: chartSettingsDialogNavigationRoute.path,
        submitText: 'Save and close',
        onSubmit: onConfirm,
      }
    }
    return {
      initialPath: chartSettingsDialogGeneralRoute.path,
      submitText: 'Next',
      onSubmit: goToNavigationSection,
    }
  }, [goToNavigationSection, isNew, nextClicked, onConfirm])

  const isSectionValid = useCallback(
    (sectionPath: SectionedModalSectionDefinition['path']): boolean => {
      if (sectionPath === chartSettingsDialogGeneralRoute.path) {
        return (
          Boolean(data?.id) &&
          Boolean((data?.displayLabel || '').trim()) &&
          Boolean(data?.versionId)
        )
      }
      return true
    },
    [data?.displayLabel, data?.id, data?.versionId],
  )

  return (
    <ChartSettingsEditContext.Provider value={settingsEditContextValue}>
      <SectionedModal
        title={title}
        onCancel={onCancel}
        routes={allRoutes}
        allowNavigation={!isNew ? true : navigationAllowed}
        allowLeaveSection={isSectionValid}
        initialPath={actualRoutes.initialPath}
        bottomPanel={<WidgetSettingsDialogIssues />}
        footer={
          <WidgetSettingsButtonPanel
            onSubmit={actualRoutes.onSubmit}
            submitText={actualRoutes.submitText}
          />
        }
      />
    </ChartSettingsEditContext.Provider>
  )
}
