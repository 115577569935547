import { styled } from '@mui/material'
import type {
  ColumnColor,
  ValueColumnColor,
} from '@fintastic/web/util/metrics-and-lists'
import React, { PropsWithChildren, useMemo } from 'react'
import { compact } from 'lodash'
import {
  resolveValueColumnColor,
  createFieldKey,
} from '@fintastic/web/util/metrics-and-lists'

export const GridColumnColorsCssWrapper: React.FC<
  PropsWithChildren<{
    columnColors: ColumnColor[]
    periods: string[]
  }>
> = ({ columnColors, children, periods }) => {
  const colorsPerColumn = useMemo<ColorColumnInfo[]>(
    () =>
      compact(
        columnColors.flatMap((columnColor) => {
          if (
            columnColor.column.type === 'value' &&
            columnColor.column.period
          ) {
            const color = columnColor as ValueColumnColor

            return periods
              .map<ColorColumnInfo>((period) => ({
                hex:
                  resolveValueColumnColor(columnColors, {
                    versionId: color.versionId,
                    period,
                    metricId: color.column.metricId,
                  })?.hexColor || '',
                colId: createFieldKey(
                  color.versionId,
                  color.column.metricId,
                  period,
                ),
              }))
              .filter((i) => !!i.hex)
          }

          if (!columnColor.hexColor) {
            return []
          }

          if (
            columnColor.column.type === 'value' &&
            columnColor.column.period === null
          ) {
            return [
              {
                colId: createFieldKey(
                  columnColor.versionId,
                  columnColor.column.metricId,
                ),
                hex: columnColor.hexColor,
              },
            ]
          }

          if (columnColor.column.type === 'dimension') {
            return [
              {
                colId: createFieldKey(
                  columnColor.versionId,
                  columnColor.column.dimensionId,
                ),
                hex: columnColor.hexColor,
              },
            ]
          }

          return []
        }),
      ),
    [columnColors, periods],
  )

  return (
    <GridColumnColorsCssWrapperStyled colorsPerColumn={colorsPerColumn}>
      {children}
    </GridColumnColorsCssWrapperStyled>
  )
}

const GridColumnColorsCssWrapperStyled = styled('div')<{
  colorsPerColumn: ColorColumnInfo[]
}>(
  ({ colorsPerColumn }) => `
    position: relative;
    width: 100%;
    height: 100%;

    ${colorsPerColumn.map(
      (columnColor) => `
      & [col-id="${columnColor.colId}"] {
        background-color: ${columnColor.hex};
      }
    `,
    )}
`,
)

type ColorColumnInfo = { colId: string; hex: string }
