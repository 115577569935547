import React, { useMemo } from 'react'
import { ActionsContext, ActionsContextValue } from './actions-context'
import { useAddDimensionFlow } from './hooks/useAddDimensionFlow'
import { useRemoveDimensionFlow } from './hooks/useRemoveDimensionFlow'
import { useChangeValueDataFormattingFlow } from './hooks/useChangeValueDataFormattingFlow'
import { useChangeValueDataTypeFlow } from './hooks/useChangeValueDataTypeFlow'
import { useChangeTimeAggregationFlow } from './hooks/useChangeTimeAggregationFlow'
import { useChangeCategoryAggregationFlow } from './hooks/useChangeCategoryAggregationFlow'
import { useChangeCurrencySignPositionFlow } from './hooks/useChangeCurrencySignPositionFlow'
import { MetricOrListEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { useDataContext } from '../data/data-context'
import { useChangeTimeDimensionFlow } from './hooks/useChangeTimeDimensionFlow'

export type MetricSettingsPanelActionsProviderProps = {
  readonly?: boolean
  flow: Maybe<MetricOrListEditingFlow>
  children: React.ReactNode
}

export const MetricSettingsPanelActionsProvider: React.FC<
  MetricSettingsPanelActionsProviderProps
> = ({ readonly = false, children, flow }) => {
  const data = useDataContext()

  const addDimensionFlow = useAddDimensionFlow(data, flow)
  const changeTimeDimensionFlow = useChangeTimeDimensionFlow(data, flow)
  const removeDimensionFlow = useRemoveDimensionFlow(data)
  const changeDataTypeFlow = useChangeValueDataTypeFlow(data, flow)
  const changeDatsFormattingFlow = useChangeValueDataFormattingFlow()
  const changeTimeAggregationFlow = useChangeTimeAggregationFlow()
  const changeCategoryAggregationFlow = useChangeCategoryAggregationFlow()
  const changeCurrencySignPositionFlow = useChangeCurrencySignPositionFlow()

  const contextValue = useMemo<ActionsContextValue>(
    () => ({
      readonly,
      flow,
      actions: {
        addDimension: addDimensionFlow.action,
        removeDimension: removeDimensionFlow.action,
        changeValueDataType: changeDataTypeFlow.action,
        changeValueDataFormatting: changeDatsFormattingFlow.action,
        changeTimeAggregation: changeTimeAggregationFlow.action,
        changeCategoryAggregation: changeCategoryAggregationFlow.action,
        changeCurrencySignPosition: changeCurrencySignPositionFlow.action,
        changeTimeDimension: changeTimeDimensionFlow.action,
      },
    }),
    [
      readonly,
      flow,
      addDimensionFlow.action,
      removeDimensionFlow.action,
      changeDataTypeFlow.action,
      changeDatsFormattingFlow.action,
      changeTimeAggregationFlow.action,
      changeCategoryAggregationFlow.action,
      changeCurrencySignPositionFlow.action,
      changeTimeDimensionFlow.action
    ],
  )

  return (
    <ActionsContext.Provider value={contextValue}>
      {children}
      {changeDataTypeFlow.popups}
      {addDimensionFlow.popups}
    </ActionsContext.Provider>
  )
}
