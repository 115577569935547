import { useCallback, useMemo } from 'react'
import { RemoveDimensionAction } from '../types'
import { useMetricsActions } from '@fintastic/web/data-access/metrics-and-lists'
import { DataContextValue } from '../../data/data-context'

export function useRemoveDimensionFlow({ dimensionsList }: DataContextValue) {
  const { removeDimension } = useMetricsActions()

  const handleAction = useCallback<RemoveDimensionAction>(
    (dimensionId) => {
      if (dimensionsList === null) {
        return
      }

      removeDimension({
        dimensionId,
        allDimensions: dimensionsList,
      })
    },
    [dimensionsList, removeDimension],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
