import { styled } from '@mui/material/styles'
import { Box, InputLabel } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledSwitchRoot = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacingFromPixels(24)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledSwitchLabelCont = styled(Box)`
  width: 100%;
`

export const StyledSwitchLabel = styled(InputLabel)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'flex-start',

  minHeight: 40,

  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: 'normal',
  whiteSpace: 'pre-wrap',

  color: theme.palette.text.primary,
}))

export const StyledSwitchSwitcher = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: flex-end;
  min-height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0;

  .MuiButtonBase-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal {
    font-size: 12px;
    height: 26px;
    min-height: 26px;
    min-width: 26px;
    font-weight: normal;
    padding: 8px 12px;
    text-transform: uppercase;

    &:nth-of-type(1) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .MuiButtonBase-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(218, 126, 21, 0.1);
  }

  button[disabled] {
    filter: grayscale(100%);
  }
`
