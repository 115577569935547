import { ThemeComponents } from './types'

export const MuiInputBase: ThemeComponents['MuiInputBase'] = {
  styleOverrides: {
    root: ({ theme }) => ({}),
    sizeSmall: ({ theme }) => ({
      '& .MuiSelect-icon': {
        top: '50%',
        transform: 'translate(0, -50%)',

        width: 16,
        height: 16,
      },
    }),
  },
}
