import { styled, Alert } from '@mui/material'
import { alertClasses } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Alert)`
  ${styledMixins.alignContentCenter()};
  align-items: flex-start;
  padding: 8px 16px;

  color: #025c25;
  background: #ccfbd3;

  .${alertClasses.icon} {
    ${styledMixins.alignContentCenter()};
    padding: 0;
    margin-right: 8px;
    margin-left: 0;

    svg {
      width: 24px;
      height: auto;
    }
  }

  .${alertClasses.message} {
    ${styledMixins.alignContentCenter()};
    padding: 0;
    margin: 0;

    min-height: 24px;
  }
`
