import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { SelectOption } from './WidgetSelect'

type WidgetSelectEmptyProps = {
  emptyText: string // 'list' | 'metric' | 'report'
}

export const WidgetSelectEmpty: React.FC<WidgetSelectEmptyProps> = ({
  emptyText,
}) => (
  <Autocomplete<Maybe<SelectOption>, false, true | false, undefined>
    size={'small'}
    value={null}
    options={[]}
    disabled={true}
    disableClearable={true}
    renderInput={(params) => <TextField {...params} label={emptyText} />}
  />
)
