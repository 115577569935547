import { MutationKey, useMutation, useQueryClient } from 'react-query'
import { updateBoard, EditableBoardParams, createFolder } from '../api'
import { boardsListQueryKeyGetter } from './useBoardsListQuery'
import type { Board, BoardShortened } from '../types'
import { boardQueryKeyGetter } from './useBoardQuery'
import { Maybe } from '@fintastic/shared/util/types'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'
import { isEqual } from 'lodash'

export const boardUpdateMutationKeyGetter = (boardId: Maybe<Board['id']>) => {
  const key: MutationKey = `board/update/${boardId}`
  return key
}

export type UseBoardUpdateMutationParams = {
  boardId?: Maybe<Board['id']>
  refetchList?: boolean
  refetchItem?: boolean
  updateListCache?: boolean
  updateItemCache?: boolean
}

const defaultParams: UseBoardUpdateMutationParams = {}

export function useBoardUpdateMutation(
  params: UseBoardUpdateMutationParams = defaultParams,
) {
  const {
    boardId = null,
    refetchList = true,
    refetchItem = true,
    updateListCache = false,
    updateItemCache = false,
  } = params
  const queryClient = useQueryClient()

  return useMutation(
    boardUpdateMutationKeyGetter(boardId),
    async (
      variables: EditableBoardParams & {
        boardId?: Maybe<Board['id']>
        newFolderName?: string
      },
    ) => {
      const {
        boardId: boardIdVariable,
        newFolderName,
        ...restVariables
      } = variables
      const boardIdToUse = boardIdVariable || boardId

      if (!boardIdToUse) {
        throw new Error('Board ID is not provided')
      }

      if (newFolderName !== undefined) {
        const folderResponse = await createFolder({ name: newFolderName })
        restVariables.folder_id = folderResponse.data.id
      }

      // Prevent doubled requests
      // @see frontend/main-client/libs/web/feature/boards/src/lib/components/BoardDisplayView/BoardDisplayView.tsx
      const currentBoardCache = queryClient.getQueryData<Board>(
        boardQueryKeyGetter(boardId),
      )
      if (
        restVariables.config &&
        isEqual(currentBoardCache?.config, restVariables.config)
      ) {
        return currentBoardCache as Board
      }

      const response = await updateBoard(boardIdToUse, restVariables)
      return response.data
    },
    {
      onSuccess: (board, variables) => {
        if (variables.newFolderName !== undefined) {
          queryClient.invalidateQueries(boardsFoldersListQueryKeyGetter(), {
            exact: true,
          })
        }

        if (updateListCache) {
          const { config: boardConfig, ...boardShortened } = board

          queryClient.setQueryData<BoardShortened[]>(
            boardsListQueryKeyGetter(),
            (boardsList) => {
              if (!boardsList) {
                return boardsList as unknown as BoardShortened[]
              }

              const boardIndex = boardsList.findIndex(
                (boardInList) => boardInList.id === board.id,
              )
              if (boardIndex === -1) {
                return boardsList
              }

              const newBoardList = [...boardsList]
              newBoardList[boardIndex] = {
                ...newBoardList[boardIndex],
                ...boardShortened,
              }

              return newBoardList
            },
          )
        }

        if (updateItemCache) {
          queryClient.setQueryData<Board>(
            boardQueryKeyGetter(board.id),
            (currentBoardData) => {
              if (!currentBoardData) {
                return board
              }

              return {
                ...currentBoardData,
                ...board,
              }
            },
          )
        }

        if (refetchList) {
          queryClient.invalidateQueries(boardsListQueryKeyGetter(), {
            exact: true,
          })
        }

        if (refetchItem) {
          queryClient.invalidateQueries(boardQueryKeyGetter(board.id), {
            exact: true,
          })
        }
      },
    },
  )
}
