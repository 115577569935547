/* eslint-disable no-param-reassign */
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import type { Dimension, Maybe } from '@fintastic/shared/util/types'
import { DimensionWithOptionalValues } from '@fintastic/shared/util/types'

/**
 * Our data structures heavily dependant on metric.metadata to include global dimension
 * It is not the case anymore, so we extend metric.metadata with global
 * dimensions from the entities.
 *
 * @todo Add tests
 * @todo Remove usage of metric.metadata.dimension from everywhere and access global
 * dimensions directly via entities endpoint.
 */
export function enrichMetricDimensionWithEntities(
  metric: Metric,
  dimensions: Maybe<DimensionWithOptionalValues[]>,
): Metric {
  if (!dimensions) {
    return metric
  }

  const dimensionsWithValues = dimensions.filter(
    (d) => !!d.values,
  ) as Dimension[]

  const newMetric = {
    ...metric,
    metadata: {
      ...metric.metadata,
      dimensions: metric.data.indexes.map((indexName) => {
        const existingDimension = metric.metadata.dimensions.find(
          (dimension) => dimension.id === indexName,
        )
        if (existingDimension) {
          if (existingDimension.type === 'Range') {
            return existingDimension
          }
        }

        const globalDimension = dimensionsWithValues.find(
          ({ id }) => id === indexName,
        )

        if (!globalDimension) {
          throw new Error(
            `Dimension ${indexName} does not exist in the entities`,
          )
        }

        return globalDimension
      }),
    },
  }

  if (metric.metadata.value.dimension_id) {
    let globalValueDimension = dimensions.find(
      ({ id }) => id === metric.metadata.value.dimension_id,
    )
    if (!globalValueDimension) {
      throw new Error(
        `Value dimension ${metric.metadata.value.dimension_id} does not exist in the entities`,
      )
    }

    if (globalValueDimension.type === 'Range') {
      const existingDimension = metric.metadata.dimensions.find(
        (d) => d.id === metric.metadata.value.dimension_id,
      )
      globalValueDimension = existingDimension
        ? { ...existingDimension, label: globalValueDimension.label }
        : globalValueDimension
    }

    newMetric.metadata.dimensions.push(globalValueDimension as Dimension)
  }

  return newMetric
}
