import React from 'react'
import { FormattingFieldsetProps } from './types'
import { DecimalPlacesField } from './fields/DecimalPlacesField'
import { CompactFormattingField } from './fields/CompactFormattingField'
import { FormattingFieldWrapper } from './FormattingFieldWrapper'

export type NumberFormattingProps = Omit<
  FormattingFieldsetProps,
  'currencySignPosition' | 'onChangeCurrencySignPosition' | 'currency'
>

export const NumberFormatting: React.FC<NumberFormattingProps> = ({
  displaySettings,
  onChange,
}) => (
  <>
    <FormattingFieldWrapper>
      <DecimalPlacesField
        value={displaySettings.numericDecimalPlaces()}
        onChange={(v) => onChange({ decimalPlaces: v })}
      />
    </FormattingFieldWrapper>
    <FormattingFieldWrapper>
      <CompactFormattingField
        value={displaySettings.numericCompactFormat()}
        onChange={(v) => onChange({ compactFormatting: v })}
      />
    </FormattingFieldWrapper>
  </>
)
