import React, { useCallback } from 'react'
import { StyledMenuItem, StyledOrdering } from './OrderByCreatedAt.styled'
import { InputAdornment, TextField } from '@mui/material'
import { OrderingIcon } from '@fintastic/shared/ui/icons'

const ordersAvailable = [
  { value: 'desc', label: 'Latest first' },
  { value: 'asc', label: 'Earliest first' },
]

export type OrderByCreatedAtProps = {
  disabled?: boolean
  desc: boolean
  onChangeOrdering: (asc: boolean) => void
}

export const OrderByCreatedAt: React.FC<OrderByCreatedAtProps> = ({
  desc,
  onChangeOrdering,
  disabled,
}) => {
  const handleSetOrder = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeOrdering(event.target.value === 'desc')
    },
    [onChangeOrdering],
  )

  return (
    <StyledOrdering>
      <TextField
        data-testid="select-sorting-direction"
        value={desc ? 'desc' : 'asc'}
        size={'small'}
        fullWidth={true}
        select
        onChange={handleSetOrder}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <OrderingIcon />
            </InputAdornment>
          ),
        }}
      >
        {ordersAvailable.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </TextField>
    </StyledOrdering>
  )
}
