import type {
  CellRange,
  GridApi,
  IRowModel,
  RowPosition,
} from 'ag-grid-community'
import { Column, RowNode } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import {
  isNullish,
  Nullable,
} from '@fintastic/shared/util/functional-programming'

export function isCellRangeContainsMoreThanOneCell(range: CellRange): boolean {
  if (range.columns.length > 1) {
    return true
  }
  return range.startRow?.rowIndex !== range.endRow?.rowIndex
}

export function isCellRangesContainMoreThanOneCell(
  ranges: CellRange[],
): boolean {
  if (ranges.length > 1) {
    return true
  }
  return isCellRangeContainsMoreThanOneCell(ranges[0])
}

export function forEachCellInRange<TData = any>(
  cellRange: CellRange,
  api: GridApi<TData>,
  cb: (
    rowNode: RowNode<TData>,
    column: Column,
    cellRange: CellRange,
    model: IRowModel,
  ) => void,
) {
  const { startRow, endRow, columns } = cellRange

  if (!startRow || !endRow) {
    return
  }

  const iterateOverRows = (
    indexes: Maybe<[start: number, end: number]>,
    rowNodeGetter: (index: number) => Nullable<RowNode<TData>>,
  ) => {
    if (indexes === null) {
      return
    }
    for (let rowIndex = indexes[0]; rowIndex <= indexes[1]; rowIndex++) {
      const row = rowNodeGetter(rowIndex)
      if (!row) {
        continue
      }
      columns.forEach((column) => {
        cb(row, column, cellRange, api.getModel())
      })
    }
  }

  const indexes = getRowIndexes(startRow, endRow, {
    pinnedTop: api.getPinnedTopRowCount(),
    main: api.getDisplayedRowCount(),
    pinnedBottom: api.getPinnedBottomRowCount(),
  })

  iterateOverRows(indexes.pinnedTop, (i) => api.getPinnedTopRow(i))
  iterateOverRows(indexes.main, (i) => api.getModel().getRow(i))
  iterateOverRows(indexes.pinnedBottom, (i) => api.getPinnedBottomRow(i))
}

/**
 * Calculates rows indexes in top to bottom direction
 */
export const getRowIndexes = (
  startRow: RowPosition,
  endRow: RowPosition,
  rowsCount: {
    pinnedTop: number
    main: number
    pinnedBottom: number
  },
) => {
  let pinnedTop: Maybe<[startIndex: number, endIndex: number]> = null
  let main: Maybe<[startIndex: number, endIndex: number]> = null
  let pinnedBottom: Maybe<[startIndex: number, endIndex: number]> = null

  let includesOnlyTop = false
  let includesOnlyBottom = false

  if (startRow.rowPinned === 'top' || endRow.rowPinned === 'top') {
    let startIndex!: number
    let endIndex!: number

    if (startRow.rowPinned === endRow.rowPinned) {
      const isReverse = startRow.rowIndex > endRow.rowIndex
      startIndex = isReverse ? endRow.rowIndex : startRow.rowIndex
      endIndex = isReverse ? startRow.rowIndex : endRow.rowIndex
      includesOnlyTop = true
    }

    if (startRow.rowPinned !== 'top') {
      startIndex = endRow.rowIndex
      endIndex = rowsCount.pinnedTop - 1
    }

    if (endRow.rowPinned !== 'top') {
      startIndex = startRow.rowIndex
      endIndex = rowsCount.pinnedTop - 1
    }

    pinnedTop = [startIndex, endIndex]
  }

  if (startRow.rowPinned === 'bottom' || endRow.rowPinned === 'bottom') {
    let startIndex!: number
    let endIndex!: number

    if (startRow.rowPinned === endRow.rowPinned) {
      const isReverse = startRow.rowIndex > endRow.rowIndex
      startIndex = isReverse ? endRow.rowIndex : startRow.rowIndex
      endIndex = isReverse ? startRow.rowIndex : endRow.rowIndex
      includesOnlyBottom = true
    }

    if (startRow.rowPinned !== 'bottom') {
      startIndex = 0
      endIndex = endRow.rowIndex
    }

    if (endRow.rowPinned !== 'bottom') {
      startIndex = 0
      endIndex = startRow.rowIndex
    }

    pinnedBottom = [startIndex, endIndex]
  }

  if (!includesOnlyTop && !includesOnlyBottom) {
    let startIndex!: number
    let endIndex!: number

    if (pinnedTop && pinnedBottom) {
      startIndex = 0
      endIndex = rowsCount.main - 1
    }

    if (pinnedTop && !pinnedBottom) {
      startIndex = 0
      endIndex = isNullish(startRow.rowPinned)
        ? startRow.rowIndex
        : endRow.rowIndex
    }

    if (!pinnedTop && pinnedBottom) {
      startIndex = isNullish(startRow.rowPinned)
        ? startRow.rowIndex
        : endRow.rowIndex
      endIndex = rowsCount.main - 1
    }

    if (!pinnedTop && !pinnedBottom) {
      const isReverse = startRow.rowIndex > endRow.rowIndex
      startIndex = isReverse ? endRow.rowIndex : startRow.rowIndex
      endIndex = isReverse ? startRow.rowIndex : endRow.rowIndex
    }

    main = [startIndex, endIndex]
  }

  return {
    pinnedTop,
    main,
    pinnedBottom,
  } as const
}

export function forEachCellInRangeList<TData = any>(
  cellRanges: CellRange[],
  api: GridApi<TData>,
  cb: (
    rowNode: RowNode<TData>,
    column: Column,
    cellRange: CellRange,
    cellRangeList: CellRange[],
    model: IRowModel,
  ) => void,
) {
  cellRanges.forEach((cellRange) => {
    forEachCellInRange(cellRange, api, (row, column, cellRange, model) => {
      cb(row, column, cellRange, cellRanges, model)
    })
  })
}
