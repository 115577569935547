import { Maybe } from '@fintastic/shared/util/types'
import { AddLinesStateValuesRecord } from '@fintastic/web/feature/list-add-lines'
import { ColumnIdParts, ColumnsIdStructure } from '../columns'

export const validateRecord = (
  record: Maybe<AddLinesStateValuesRecord>,
  mandatoryFields: ColumnIdParts[],
): boolean => {
  if (!record) {
    return false
  }
  if (!mandatoryFields.length) {
    return true
  }
  const filledMandatoryFieldCounter = mandatoryFields.filter(
    (item) => record[item.field] ?? false,
  )
  return filledMandatoryFieldCounter.length === mandatoryFields.length
}

export const getAllChangedFieldsFromValues = (
  data: AddLinesStateValuesRecord[],
): Set<string> => {
  const allEditedFieldNames = new Set<string>()

  // stateData - sparse rows, [{_rowId: string, [fieldName]: value | null}]
  data.forEach((record: AddLinesStateValuesRecord) => {
    Object.keys(record).forEach((fieldName) => {
      if (fieldName === '_rowId' || fieldName === '_valid') {
        return
      }
      allEditedFieldNames.add(fieldName)
    })
  })

  return allEditedFieldNames
}

export const getAllVisibleFields = (
  structure: ColumnsIdStructure,
): Set<string> => {
  const flattenActualFields = new Set<string>()

  structure.forEach((column) => {
    if ((column as ColumnIdParts).isCalculated) {
      return
    }

    if (!Array.isArray(column)) {
      flattenActualFields.add(column.field)
      return
    }

    if (column.length === 0) {
      return
    }

    const subColumns = column.filter((col) => !col.isCalculated)

    if (subColumns.length === 0) {
      return
    }

    subColumns.forEach((subColumn) => {
      flattenActualFields.add(subColumn.field)
    })
  })
  return flattenActualFields
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/difference
// Since June 2024, this feature works across the latest devices and browser versions. This feature might not work in older devices or browsers.
// so, let's implement
export const isAllSet1IncludedInSet2 = (
  xs: Set<string>,
  ys: Set<string>,
): boolean => [...xs].every((x) => ys.has(x))
