import { Maybe } from '@fintastic/shared/util/types'
import { useMutation } from 'react-query'
import {
  ColumnMissingNoopAggregationErrorPayload,
  ListErrorResponseWithDetailResponse,
  createList,
  updateList,
} from '../api/lists-api'
import { EditableList } from '../types'
import { AxiosError } from 'axios'

type SaveListError =
  | Error
  | AxiosError<ColumnMissingNoopAggregationErrorPayload | undefined>

export function useSaveList(versionId: Maybe<string>) {
  return useMutation<
    void,
    SaveListError,
    { isNewList: boolean; list: EditableList }
  >(['version', versionId, 'saveList'], async (params) => {
    if (!versionId) {
      throw new Error('version id is not set')
    }
    const response = await (params.isNewList
      ? createList(versionId, params.list)
      : updateList(versionId, params.list))
    return response.data
  })
}

export const isSaveListErrorAboutNoopAggregation = (
  error: unknown,
): boolean => {
  if (!(error instanceof Error) || !(error as AxiosError).isAxiosError) {
    return false
  }

  const castedError = error as AxiosError<
    ColumnMissingNoopAggregationErrorPayload | undefined
  >

  return Boolean(
    castedError.response?.data?.detail?.some?.(
      (detail) => detail.type === 'value_error.missingnoopaggregation',
    ),
  )
}

export const isSaveListErrorAboutNoColumnAccess = (error: unknown) => {
  const apiErrorMessage =
    'you are not allowed to edit a list with restricted columns'

  if (!(error instanceof Error) || !(error as AxiosError).isAxiosError) {
    return false
  }

  const castedError = error as AxiosError<
    ListErrorResponseWithDetailResponse | undefined
  >

  return castedError.response?.data?.detail === apiErrorMessage
}

export const isRestrictedColumnUsedInFormula = (error: unknown) => {
  const apiErrorMessage = 'RESTRICTED_ENTITY_NOT_ALLOWED_IN_FORMULA'

  if (!(error instanceof Error) || !(error as AxiosError).isAxiosError) {
    return false
  }

  const castedError = error as AxiosError<
    ListErrorResponseWithDetailResponse | undefined
  >

  return castedError.response?.data?.detail === apiErrorMessage
}

export const isFilteredOrMaskedCoordinatesInEditIntent = (error: unknown) => {
  const apiErrorMessage = 'FILTERED_OR_MASKED_COORDINATES_IN_LIST_EDIT_INTENT'

  if (!(error instanceof Error) || !(error as AxiosError).isAxiosError) {
    return false
  }

  const castedError = error as AxiosError<
    ListErrorResponseWithDetailResponse | undefined
  >

  return castedError.response?.data?.detail === apiErrorMessage
}
