import {
  MetricData,
  MetricDataFormat,
} from '@fintastic/web/util/metrics-and-lists'

export const createMetricData = (
  format: MetricDataFormat = 'compact',
): MetricData => ({
  indexes: [],
  dimensions: [],
  values: [null],
  format,
})
