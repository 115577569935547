import React, { forwardRef, ReactElement, ReactNode, useMemo } from 'react'
import {
  Select as MuiSelect,
  InputBase as MuiInputBase,
  SelectChangeEvent,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

/** @deprecated */
export type DropdownProps<T = unknown> = {
  children: ReactNode
  onChange?: (event: SelectChangeEvent<T>, child: ReactNode) => void | undefined
  value?: T
  defaultValue?: T
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  marginPaper?: string
  IconComponent?: () => ReactElement
  placeholder?: string
  renderValue?: (value: T) => React.ReactNode
  displayEmpty?: boolean
  extraMenuProps?: Record<string, unknown>
}

/** @deprecated */
const StyledSelect = styled(MuiSelect)({
  'minWidth': '180px',
  'padding': '10px',
  'fontSize': '14px',
  '& .MuiInputBase-input': {
    padding: '0',
  },
  '& .MuiMenuItem-root': {
    fontSize: '14px',
  },
  '& svg': {
    color: 'inherit',
    fontSize: 'inherit',
  },
})

/** @deprecated */
const ArrowIcon = ({ onChange, ...rest }: DropdownProps) => (
  <svg
    width={16}
    height={16}
    style={{ marginRight: '8px' }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.64645 8.35355C5.45118 8.15829 5.45118 7.84171 5.64645 7.64645C5.84171 7.45118 6.15829 7.45118 6.35355 7.64645L5.64645 8.35355ZM10 12L10.3536 12.3536C10.2598 12.4473 10.1326 12.5 10 12.5C9.86739 12.5 9.74021 12.4473 9.64645 12.3536L10 12ZM13.6464 7.64645C13.8417 7.45119 14.1583 7.45119 14.3536 7.64645C14.5488 7.84171 14.5488 8.15829 14.3536 8.35355L13.6464 7.64645ZM6.35355 7.64645L10.3536 11.6464L9.64645 12.3536L5.64645 8.35355L6.35355 7.64645ZM9.64645 11.6464L13.6464 7.64645L14.3536 8.35355L10.3536 12.3536L9.64645 11.6464Z"
      fill="black"
    />
  </svg>
)

/** @deprecated */
export const StyledPlanningVersionSelectSelect = forwardRef(
  (props: DropdownProps, ref): JSX.Element => {
    const {
      value,
      onChange,
      children,
      variant,
      defaultValue = 0,
      marginPaper = '',
      IconComponent,
      placeholder,
      renderValue,
      displayEmpty,
      extraMenuProps,
    } = props

    const theme = useTheme()

    const menuProps = useMemo(
      () => ({
        '&::-webkit-scrollbar, &::-webkit-scrollbar-track': {
          width: 'auto',
        },
        'boxShadow': '0px 0px 8px rgba(0, 0, 0, 0.25) !important',
        'borderRadius': '10px !important',
        'maxHeight': '230px',
        '.MuiListSubheader-root': {
          color: theme.palette.text.disabled,
          fontWeight: 'normal',
          paddingLeft: '20px',
        },
        'li:not(.MuiListSubheader-root)': {
          'display': 'flex',
          'alignItems': 'center',
          'fontSize': '14px',
          'padding': '8px 12px 8px 8px',
          'width': '100%',
          '&:hover': {
            backgroundColor: 'rgb(255, 247, 239)',
          },
        },
        'svg': {
          marginRight: '5px',
        },
      }),
      [theme.palette.text.disabled],
    )

    return (
      <StyledSelect
        ref={ref}
        sx={{ borderRadius: '3px' }}
        value={value}
        onChange={onChange}
        input={<MuiInputBase />}
        fullWidth
        placeholder={placeholder}
        renderValue={renderValue}
        displayEmpty={displayEmpty}
        MenuProps={{
          PaperProps: {
            sx: {
              ...menuProps,
              marginTop: '1rem',
              marginLeft: '.5rem',
              margin: marginPaper,
              ...extraMenuProps,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        variant={variant}
        IconComponent={IconComponent || ArrowIcon}
        defaultValue={defaultValue}
      >
        {children}
      </StyledSelect>
    )
  },
)
