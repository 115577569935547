import { Box, styled } from '@mui/material'

export const StyledRoot = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'loading',
})<{
  loading: boolean
}>`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: #fff;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0 -4px 10px 0 #0000000d, 0 4px 10px 0 #0000000d;

  opacity: ${({ loading }) => (loading ? 0.8 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};

  transition: opacity 0.2s ease-in-out;
`
