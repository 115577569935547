export const diffModes = ['num', 'percent', 'mixed'] as const

export type DiffMode = typeof diffModes[number]

export const defaultDiffMode: DiffMode = 'num'

export const diffModeTitles: Record<DiffMode, string> = {
  num: '#',
  percent: '%',
  mixed: '#,%',
} as const

export const DiffPart = {
  firstVersion: 0,
  secondVersion: 1,
  mode: 2,
}
