import { useCallback, useMemo, useRef, useState } from 'react'
import { RowHeightParams } from 'ag-grid-community'
import { VersionTable } from '../../types'
import { SetGridSizeProps } from '@fintastic/shared/ui/grid-framework'
import type { MasterDetailTab } from './useMasterDetailTabsState'
import { getDetailTableId } from './getDetaiTablelId'

export const DETAIL_GRID_RENDER_HEIGHT = 300
const DETAIL_GRID_OFFSET = 32
const DETAIL_GRID_MIN_ROWS = 12
const DETAIL_GRID_MAX_ROWS = 14
const DETAIL_GRID_DEFAULT_SIZES = {
  rows: 10,
  rowHeight: 27,
  headerHeight: 27,
} as SetGridSizeProps

export function useMasterDetailsRowsSizes(
  getMasterDetailTab: (gridId: string) => MasterDetailTab,
) {
  const [rowsSizes, setRowsSizes] = useState<Record<string, SetGridSizeProps>>(
    {},
  )
  const rowsSizesRef = useRef(rowsSizes)
  rowsSizesRef.current = rowsSizes
  const getMasterDetailTabRef = useRef(getMasterDetailTab)
  getMasterDetailTabRef.current = getMasterDetailTab

  const setGridSizeCallback = useCallback(
    (tableId: string, sizes: SetGridSizeProps) => {
      // save sizes in local state
      setRowsSizes((prevState) => {
        const detailId = getDetailTableId(tableId)
        const newState = { ...prevState }
        newState[detailId] = sizes
        return newState
      })
    },
    [],
  )

  const getRowHeight = useCallback(
    (params: RowHeightParams<VersionTable>): number | null => {
      if (!params.node || !params.node.detail) {
        // default value for usual rows
        return null
      }
      const tableId = params.data?.id || ''
      const maxRows =
        getMasterDetailTabRef.current(tableId) === 'formula'
          ? 1
          : DETAIL_GRID_MIN_ROWS
      const detailRowSizes =
        rowsSizesRef.current[getDetailTableId(tableId)] ||
        DETAIL_GRID_DEFAULT_SIZES

      if (detailRowSizes.rows === 0 && detailRowSizes.rowHeight === 0) {
        return DETAIL_GRID_RENDER_HEIGHT
      }

      const rowsToShow = Math.min(
        DETAIL_GRID_MAX_ROWS,
        Math.max(detailRowSizes.rows, maxRows),
      )
      return (
        DETAIL_GRID_OFFSET +
        (detailRowSizes.headerHeight + 2) +
        rowsToShow * (detailRowSizes.rowHeight + 2)
      )
    },
    [],
  )

  return useMemo(
    () => ({
      rowsSizes,
      setGridSizeCallback,
      getRowHeight,
    }),
    [getRowHeight, rowsSizes, setGridSizeCallback],
  )
}
