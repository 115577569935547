import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from './cache-keys'

export async function invalidateMetricsListCache(
  queryClient: QueryClient,
  versionId: string,
) {
  await Promise.all([
    queryClient.invalidateQueries(
      metricsAndListsCacheKeys.metrics(versionId, true),
    ),
    queryClient.invalidateQueries(
      metricsAndListsCacheKeys.metrics(versionId, false),
    ),
  ])
}
