import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnCurrencySignPositionPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnCurrencySignPosition = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeColumnCurrencySignPositionPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  list.findColumnByClientId(payload.columnId)?.applyFormattingChanges({
    currencySignPosition: payload.position,
  })
}
