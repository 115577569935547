import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnFormulaPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'
import { EditableMetric } from '../../../types'

// @todo add tests for formula state
export const changeColumnFormula = (
  uncheckedState: State,
  {
    payload: { metricId, ...restPayload },
  }: PayloadAction<ChangeColumnFormulaPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  if (list.isCalculated()) {
    // @todo move this check to list ADT
    throw new Error('not allowed in calculated lists')
  }

  const column = list.findColumnByClientId(metricId)
  if (!column) {
    throw new Error(`Column with id "${metricId}" does not exist`)
  }

  if (column.formula() === restPayload.formula) {
    /**
     * @see EditableMetric
     */
    delete (column.unwrap() as EditableMetric).formulaInvalid
    return
  }

  if (restPayload.invalid) {
    ;(column.unwrap() as EditableMetric).formulaInvalid = true
    return
  }

  delete (column.unwrap() as EditableMetric).formulaCalculationError
  delete (column.unwrap() as EditableMetric).formulaInvalid

  column.changeFormula(restPayload.formula)
}
