import type { Widget } from '@fintastic/shared/ui/widgets-framework'
import { isEqual } from 'lodash'
import { METRIC_CHART_WIDGET_TYPE } from '../widgets'

export const areWidgetsSettingsUpdated = (
  globalWidgets: Widget[],
  localWidgets: Widget[],
) => {
  if (globalWidgets.length !== localWidgets.length) {
    return true
  }

  // exclude METRIC_CHART_WIDGET_TYPE - their settings are handled separately
  return globalWidgets
    .filter((w) => w.type !== METRIC_CHART_WIDGET_TYPE)
    .some((globalWidget) => {
      const localWidget = localWidgets.find((w) => w.id === globalWidget.id)
      if (!localWidget) {
        throw new Error(`Local widget not found: ${globalWidget.id}`)
      }

      return !isEqual(globalWidget.settings, localWidget.settings)
    })
}

export const getReferenceWidget = (
  widgetId: string,
  referenceWidgets?: Widget[],
) => (referenceWidgets || []).find((widget) => widget.id === widgetId)

/**
 * Returns all the widgets from configWidgets if they are not METRIC_CHART_WIDGET_TYPE.
 * If they are, returns corresponding widget from referenceWidgets.
 * METRIC_CHART_WIDGET_TYPE settings are stored locally and can't override database except explicit Reset.
 * @param configWidgets
 * @param referenceWidgets
 */
export const getWidgetsToSave = (
  configWidgets?: Widget[],
  referenceWidgets?: Widget[],
) => (configWidgets ?? []).map((w) => {
    if (w.type !== METRIC_CHART_WIDGET_TYPE) {
      return w
    }
    return getReferenceWidget(w.id, referenceWidgets) || w
  })
