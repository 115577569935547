import React from 'react'
import { Button } from '@mui/material'
import { EditIcon } from '@fintastic/shared/ui/icons'
import { Tooltip } from '@mui/material'
import { EditingPermissionsApi } from '../editing-permissions/types'
import { testIds } from '../../test/test-ids'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'

export type EditButtonProps = {
  onClick: () => void
  editingPermissions: EditingPermissionsApi
  savingInProgress: boolean
}

export const EditButton: React.FC<EditButtonProps> = ({
  onClick,
  editingPermissions,
  savingInProgress,
}) => {
  const button = (
    <Button
      startIcon={<EditIcon fontSize="small" />}
      variant="outlined"
      sx={{ fontSize: 14 }}
      onClick={onClick}
      disabled={
        !editingPermissions.allowed ||
        savingInProgress ||
        editingPermissions.versionUserLock.editIsBlocked
      }
      data-testid={testIds.editButton}
    >
      Edit
    </Button>
  )

  if (!editingPermissions.allowed) {
    return (
      <Tooltip title={editingPermissions.tooltipText} placement="top" arrow>
        <span>{button}</span>
      </Tooltip>
    )
  }

  if (editingPermissions.versionUserLock.editIsBlocked) {
    return (
      <Tooltip
        title={versionIsLockedMessage(editingPermissions.versionUserLock)}
        placement="top"
        arrow
      >
        <span>{button}</span>
      </Tooltip>
    )
  }

  return button
}
