import { useQuery } from 'react-query'
import { Maybe } from '@fintastic/shared/util/types'
import { loadVersionEntities } from './version-entities-query-handler'
import { VersionEntities } from '@fintastic/web/util/versions'
import { versionsCacheKeys } from '../../cache'

export function useLoadVersionEntities(
  versionId?: Maybe<string>,
  enabled = true,
) {
  return useQuery<VersionEntities, Error>(
    versionsCacheKeys.versionEntities(versionId || ''),
    () => loadVersionEntities(versionId),
    {
      enabled: enabled && versionId !== null && versionId !== undefined,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      staleTime: Infinity,
      retry: 1,
    },
  )
}
