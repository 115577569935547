import React, {
  ChangeEventHandler,
  forwardRef,
  memo,
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  FocusEventHandler,
  useRef,
} from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import { AgGridCellEditorProps } from '../types'
import {
  AG_CELL_EDITOR_CLASS_NAME,
  AG_TEXT_CELL_EDITOR_CLASS_NAME,
} from '../classNames'

// ATTENTION: do not change my styles here,
// change them in frontend/main-client/libs/shared/ui/ag-grid-theme-fintastic/src/lib/theme-variants/default.ts
export const AgGridTextCellEditor = memo(
  forwardRef<unknown, AgGridCellEditorProps>(
    ({ afterInput, ...props }, ref) => {
      const [value, setValue] = useState<string>(props.value || '')
      const valueRef = useRef(value)
      const inputRef = useRef<HTMLInputElement>(null)

      useEffect(() => {
        window.setTimeout(() => {
          inputRef.current?.focus()
        }, 0)
      }, [])

      useImperativeHandle(ref, () => ({
        setValue(value: string) {
          setValue(value)
          valueRef.current = value
        },
        getValue() {
          return valueRef.current
        },
        isCancelBeforeStart() {
          return false
        },
        isCancelAfterEnd() {
          return false
        },
      }))

      const handleChangeInput = useCallback<
        ChangeEventHandler<HTMLInputElement>
      >((event) => {
        setValue(event.target.value)
        valueRef.current = event.target.value
      }, [])

      const handleBlurInput = useCallback<
        FocusEventHandler<HTMLInputElement>
      >(() => {
        setValue(inputRef.current?.value || '')
        valueRef.current = inputRef.current?.value || ''
      }, [])

      return (
        <Box
          position="relative"
          className={
            AG_CELL_EDITOR_CLASS_NAME + ' ' + AG_TEXT_CELL_EDITOR_CLASS_NAME
          }
        >
          <FormControl fullWidth>
            <TextField
              inputRef={inputRef}
              size="small"
              variant="outlined"
              value={value}
              onChange={handleChangeInput}
              onBlur={handleBlurInput}
            />
          </FormControl>
          {afterInput}
        </Box>
      )
    },
  ),
)

export default AgGridTextCellEditor
