import { ThemeComponents } from './types'
import { alpha } from '@mui/system'

export const MuiButton: ThemeComponents['MuiButton'] = {
  defaultProps: {
    color: 'black',
    disableElevation: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      'paddingLeft': theme.spacing(1),
      'paddingRight': theme.spacing(1),
      'minWidth': 0,

      'fontSize': 12,
      'fontWeight': 500,

      '.MuiTouchRipple-root': {
        display: 'none',
      },
    }),

    sizeSmall: ({ theme }) => ({
      // this variant doesn't exist in design
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontSize: `${11 / theme.typography.fontSize}rem`,
    }),
    sizeMedium: ({ theme }) => ({
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      height: 32,
    }),
    sizeLarge: ({ theme }) => ({
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      height: 40,
    }),

    contained: ({ theme }) => ({
      'borderWidth': '1px',
      'borderStyle': 'solid',

      '&:disabled': {
        borderColor: theme.palette.action.disabledBackground,
        background: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled,
      },
    }),
    containedPrimary: ({ theme }) => ({
      'color': theme.palette.primary.contrastText,
      'background': theme.palette.primary.main,
      'borderColor': theme.palette.primary.dark,

      '&:hover, &.Mui-focusVisible': {
        background: theme.palette.primary.dark,
      },
    }),
    containedBlack: ({ theme }) => ({
      'color': theme.palette.black.contrastText,
      'background': theme.palette.black.main,
      'borderColor': theme.palette.black.dark,

      '&:hover, &.Mui-focusVisible': {
        background: theme.palette.black.dark,
      },
    }),
    containedSecondary: ({ theme }) => ({
      'color': theme.palette.secondary.contrastText,
      'background': theme.palette.secondary.main,
      'borderColor': theme.palette.secondary.dark,

      '&:hover, &.Mui-focusVisible': {
        background: theme.palette.secondary.dark,
      },
    }),

    text: ({ theme }) => ({
      'background': 'transparent',

      '&:disabled': {
        color: theme.palette.text.disabled,
      },
    }),
    textPrimary: ({ theme }) => ({
      'color': theme.palette.primary.main,

      '&:hover, &.Mui-focusVisible': {
        color: theme.palette.primary.dark,
      },
    }),
    textSecondary: ({ theme }) => ({
      'color': theme.palette.secondary.main,

      '&:hover, &.Mui-focusVisible': {
        color: theme.palette.secondary.dark,
      },
    }),
    textBlack: ({ theme }) => ({
      'color': theme.palette.black.main,

      '&:hover, &.Mui-focusVisible': {
        color: theme.palette.black.dark,
      },
    }),

    outlined: ({ theme }) => ({
      '&:disabled': {
        borderColor: theme.palette.action.disabledBackground,
        background: 'transparent',
        color: theme.palette.text.disabled,
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      'color': theme.palette.primary.main,

      '&:hover, &.Mui-focusVisible': {
        color: theme.palette.primary.dark,
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      'color': theme.palette.secondary.main,

      '&:hover, &.Mui-focusVisible': {
        color: theme.palette.secondary.dark,
      },
    }),
    outlinedBlack: ({ theme }) => ({
      'color': theme.palette.black.main,

      '&:hover, &.Mui-focusVisible': {
        color: theme.palette.black.dark,
      },
    }),

    startIcon: ({ theme }) => ({
      marginRight: theme.spacing(0.5),
    }),
    endIcon: ({ theme }) => ({
      marginLeft: theme.spacing(0.5),
    }),
  },
}
