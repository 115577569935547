import { Widget } from '@fintastic/shared/ui/widgets-framework'

import {
  GENERIC_REPORT_WIDGET_TYPE,
  LIST_GRID_WIDGET_TYPE,
  METRIC_CHART_WIDGET_TYPE,
  METRIC_GRID_WIDGET_TYPE,
  TEXT_WIDGET_TYPE,
} from '../../widgets'

export type DesignerWidgetType =
  | typeof TEXT_WIDGET_TYPE
  | typeof LIST_GRID_WIDGET_TYPE
  | typeof METRIC_GRID_WIDGET_TYPE
  | typeof METRIC_CHART_WIDGET_TYPE
  | typeof GENERIC_REPORT_WIDGET_TYPE

export const getWidgetTypeByWidget = (widget: Widget): DesignerWidgetType => {
  if (widget.type === TEXT_WIDGET_TYPE) {
    return TEXT_WIDGET_TYPE
  }
  if (widget.type === LIST_GRID_WIDGET_TYPE) {
    return LIST_GRID_WIDGET_TYPE
  }
  if (widget.type === METRIC_GRID_WIDGET_TYPE) {
    return METRIC_GRID_WIDGET_TYPE
  }
  if (widget.type === METRIC_CHART_WIDGET_TYPE) {
    return METRIC_CHART_WIDGET_TYPE
  }
  if (widget.type === GENERIC_REPORT_WIDGET_TYPE) {
    return GENERIC_REPORT_WIDGET_TYPE
  }
  throw new Error(`Unknown widget type ${widget.type}`)
}

export const widgetTypeToName = (widgetType: DesignerWidgetType) => {
  switch (widgetType) {
    case TEXT_WIDGET_TYPE:
      return 'Text'
    case LIST_GRID_WIDGET_TYPE:
      return 'List'
    case METRIC_GRID_WIDGET_TYPE:
      return 'Metric'
    case METRIC_CHART_WIDGET_TYPE:
      return 'Metric Chart'
    case GENERIC_REPORT_WIDGET_TYPE:
      return 'Report'
  }
}
