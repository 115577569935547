import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 12px;
`

export const StyledIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
`
