import { ThemeComponents } from './types'

export const MuiMenu: ThemeComponents['MuiMenu'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingTop: 0,
      paddingBottom: 0,
    }),
    paper: ({ theme }) => ({
      'borderRadius': theme.shape.borderRadius,
      '&.MuiPaper-root': {
        boxShadow:
          '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
      },
    }),
    list: {
      'paddingTop': 0,
      'paddingBottom': 0,

      '& .MuiDivider-root': {
        margin: 0,
      },
    },
  },
}
