import { getVersionsUserLocker } from '../../api/version-locking-api'
import { Maybe } from '@fintastic/shared/util/types'

export function createVersionUserLockerQueryHandler(
  versionId: Maybe<string> | undefined,
) {
  return async () => {
    const response = await getVersionsUserLocker(versionId || '')
    return response.data
  }
}
