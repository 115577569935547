import { Box, Button } from '@mui/material'
import React from 'react'

export const FilterActions: React.FC<FilterActionsProps> = ({
  applyDisabled,
  onApply,
  onCancel,
  onClearAll,
}) => (
  <Box display="flex" gap={1}>
    {onClearAll ? (
      <Button variant="text" onClick={onClearAll}>
        CLEAR ALL
      </Button>
    ) : <div />}
    <Box alignSelf="flex-end" display="flex" gap="inherit" ml="auto">
      {onCancel ? <Button onClick={onCancel}>Cancel</Button> : null}
      {onApply ? (
        <Button variant="contained" disabled={applyDisabled} onClick={onApply}>
          Apply
        </Button>
      ) : null}
    </Box>
  </Box>
)

export type FilterActionsProps = {
  onClearAll?: () => void
  onCancel?: () => void
  onApply?: () => void
  applyDisabled?: boolean
}
