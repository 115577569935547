import React from 'react'
import { Box, Collapse } from '@mui/material'

export type FormulaCollapseProps = {
  isOpen: boolean
  children: React.ReactNode
}

// @todo add tests
export const FormulaCollapse: React.FC<FormulaCollapseProps> = ({
  isOpen,
  children,
}) => (
  <Collapse in={isOpen}>
    <Box
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {children}
    </Box>
  </Collapse>
)
