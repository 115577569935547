import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useReadDeeplinkValue } from '../useReadDeeplinkValue'

/**
 * Local storage override should disable if deeplink is present and thread id is selected
 * In case if thread id is selected, we need to fallback to default view unless anything is set
 * directly by the deeplink.
 * So local storage should be ignored in this case
 */
export const useIsLocalStorageOverrideDisabled = (): boolean => {
  const hasThreadSelected = Boolean(useReadDeeplinkValue('thread_id'))
  const { search, pathname } = useLocation()

  const deeplinkApplied = useMemo(
    () => !!(pathname && searchContainAnyDeeplink(search)),
    [pathname, search],
  )

  return deeplinkApplied && hasThreadSelected
}

const searchContainAnyDeeplink = (search: string): boolean => search.length > 0
