import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import { useCallback, useEffect } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  LockerStateChangeEventPayload,
  PUSHER_EVENT_CALCULATION_PROGRESS,
  PUSHER_EVENT_LOCKER_STATE_CHANGE,
  PUSHER_EVENT_VERSION_CREATED,
  VersionCreatedEventPayload,
} from './events'
import {
  CalculationProgressEvent,
  ImmutableCalculationProgressEvent,
} from '@fintastic/web/data-access/calc'
import { usePusherChannel } from '@fintastic/shared/data-access/pusher-react'

export const useSubscribeToTenantEvent = <T = unknown>(
  eventName: string,
  // @todo: Actually it's `T | unknown`. Needs to be fixed.
  callback: (params: T) => void,
  key = '',
) => {
  const user = useAuthUserInfo()
  const channel = usePusherChannel(user?.tenant_id || null)

  const cb = useCallback(
    (params: T) => {
      callback(params)
    },
    [callback],
  )

  useEffect(() => {
    if (!channel) {
      return
    }
    channel?.bind(eventName, cb)
    return () => {
      channel?.unbind(eventName, cb)
    }
  }, [channel, eventName, cb, user?.tenant_id, key])
}

export function useSubscribeToCalculationProgressEvent(
  versionsIds: Maybe<string>[] | '*',
  callback: (payload: ImmutableCalculationProgressEvent) => void,
  enabled = true,
) {
  useSubscribeToTenantEvent<CalculationProgressEvent>(
    PUSHER_EVENT_CALCULATION_PROGRESS,
    useCallback(
      (params: CalculationProgressEvent) => {
        if (!enabled) {
          return
        }

        const wrappedEvent = new ImmutableCalculationProgressEvent(params)

        if (!(wrappedEvent.successful || wrappedEvent.failed)) {
          return
        }

        if (
          versionsIds === '*' ||
          versionsIds.includes(wrappedEvent.versionId)
        ) {
          callback(wrappedEvent)
        }
      },
      [callback, versionsIds, enabled],
    ),
    `versions ${versionsIds}`,
  )
}

export function useSubscribeToVersionCreatedEvent(
  versionsIds: Maybe<string>[] | '*',
  callback: (payload: VersionCreatedEventPayload) => void,
) {
  useSubscribeToTenantEvent<VersionCreatedEventPayload>(
    PUSHER_EVENT_VERSION_CREATED,
    useCallback(
      (params: VersionCreatedEventPayload) => {
        if (
          ['created', 'fail'].includes(params?.status) &&
          params?.version_id &&
          (versionsIds === '*' || versionsIds.includes(params.version_id))
        ) {
          callback(params)
        }
      },
      [callback, versionsIds],
    ),
    `versions ${versionsIds}`,
  )
}

export function useSubscribeToLockerStateChangedEvent(
  versionsIds: Maybe<string>[] | '*',
  callback: (payload: LockerStateChangeEventPayload) => void,
) {
  useSubscribeToTenantEvent<LockerStateChangeEventPayload>(
    PUSHER_EVENT_LOCKER_STATE_CHANGE,
    useCallback(
      (params: LockerStateChangeEventPayload) => {
        callback(params)
      },
      [callback],
    ),
    `versions ${versionsIds}`,
  )
}
