import React from 'react'
import {
  FilterByNumber,
  FilterRange,
  FilterValue,
} from '@fintastic/web/util/filters'
import { RangeNumberField } from './RangeNumberField'
import { NormalNumberField } from './NormalNumberField'
import { RangedFilterInput } from '../shared/RangedFilterInput'
import { NumberFilterInputSharedProps } from './types'

export const NumberFilterInput: React.FC<NumberFilterInputProps> = ({
  filterValue,
  onChange,
  dataType,
  displayConfig,
  disableClear,
  filter,
}) => (
  <RangedFilterInput<number, FilterRange, NumberFilterInputSharedProps>
    filterValue={filterValue}
    onChange={onChange}
    dataType={dataType}
    displayConfig={displayConfig}
    RangeFieldComponent={RangeNumberField}
    NormalFieldComponent={NormalNumberField}
    disableClear={disableClear ?? false}
    filter={filter}
  />
)

export type NumberFilterInputProps = {
  filterValue: Partial<FilterValue<number | FilterRange>>
  onChange: (nextValue: FilterValue<number | FilterRange | ''>) => void
  disableClear?: boolean
  filter: FilterByNumber
} & NumberFilterInputSharedProps
