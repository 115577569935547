import type { Maybe } from '@fintastic/shared/util/types'
import type { Column } from '../grid-columns/types'
import { createWeightedAverageWeightFieldKey } from './createWeightedAverageWeightFieldKey'

export function createHiddenWeightsColumn(
  versionId: string,
  metricId: string,
  period: Maybe<string>,
): Column {
  return {
    field: createWeightedAverageWeightFieldKey(versionId, metricId, period),
    dataType: 'number',
    isEditable: () => false,
    title: 'weight',
    rollUpFunction: 'sum',
    disableFilter: true,
    hide: true,
  }
}
