import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useSetUrlSearchParams = () => {
  const navigate = useNavigate()

  return useCallback(
    (nextSearchParams: URLSearchParams, replace = true, state = {}) => {
      const nextUrl =
        window.document.location.pathname + '?' + nextSearchParams.toString()
      navigate(nextUrl, {
        replace,
        state,
      })
    },
    [navigate],
  )
}
