import { Maybe } from '@fintastic/shared/util/types'
import { useMutation } from 'react-query'
import { createMetric, updateMetric } from '../api/metrics-api'
import { Metric } from '@fintastic/web/util/metrics-and-lists'

export function useSaveMetric(versionId: Maybe<string>) {
  return useMutation(
    ['version', versionId, 'saveMetric'],
    async (params: { isNewMetric: boolean; metric: Metric }) => {
      if (!versionId) {
        throw new Error('version id is not set')
      }
      const response = await (params.isNewMetric
        ? createMetric(versionId, params.metric)
        : updateMetric(versionId, params.metric))
      return response.data
    },
  )
}
