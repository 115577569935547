import { createContext, useContext } from 'react'
import {
  ChangeWidgetDialogParams,
  emptyWidgetDialogState,
  UsePendingErrors,
  UsePendingProcesses,
} from '@fintastic/web/feature/boards'

export type WidgetSettingsDialogError = {
  id?: string
  message?: string
}

export type WidgetSettingsDialogContextValue = {
  data: Partial<ChangeWidgetDialogParams>
  title: string
  isNew: boolean
  valid: boolean
  isChanged: boolean
  onConfirm: () => void
  onCancel: () => void
  setPartialData: (partialValue: Partial<ChangeWidgetDialogParams>) => void
  pendingApi: UsePendingProcesses
  errorsApi: UsePendingErrors
}

function makeStub(err: string) {
  return () => {
    throw new Error(
      `WidgetSettingsDialogContextValue is not initialised (${err})`,
    )
  }
}

export const emptyWidgetSettingsDialogContext = {
  data: { ...emptyWidgetDialogState },
  title: '-',
  isNew: false,
  isChanged: false,
  valid: false,
  onConfirm: makeStub('onConfirm'),
  onCancel: makeStub('onCancel'),
  setPartialData: makeStub('setPartialData'),
  pendingApi: {
    addPendingProcess: makeStub('addPendingProcess'),
    finishAll: makeStub('finishAll'),
    finishPendingProcess: makeStub('finishPendingProcess'),
    hasPendingProcess: makeStub('hasPendingProcess'),
    pendingProcesses: [] as string[],
    isAnyPendingProcess: false,
  },
  errorsApi: {
    addPendingError: makeStub('addPendingError'),
    clearAll: makeStub('clearAll'),
    removePendingError: makeStub('removePendingError'),
    hasPendingError: makeStub('hasPendingError'),
    pendingErrors: [] as WidgetSettingsDialogError[],
    isAnyPendingError: false,
  },
}

export const WidgetSettingsDialogContext =
  createContext<WidgetSettingsDialogContextValue>({
    ...emptyWidgetSettingsDialogContext,
  })

export function useWidgetSettingsDialogContext() {
  return useContext(WidgetSettingsDialogContext)
}
