import { PayloadAction } from '@reduxjs/toolkit'
import { State } from '../types'
import { castToMetricEditingState } from './helpers'
import { ChangeCurrencySignPositionPayload } from '../../types/actions/shared'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

export const changeMetricCurrencySignPosition = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeCurrencySignPositionPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  const metric = new MutableMetricWrapper(state.metric)
  metric.applyFormattingChanges({
    currencySignPosition: payload.position,
  })
}
