import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CollapsibleContainerStyled = styled(Box)``

export const CollapsiblePanelHeaderContainerStyled = styled(Box)`
  font-size: 1rem;
  height: 48px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  cursor: default;
  width: 100%;
  position: relative;

  svg {
    cursor: pointer;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export const StyledArrowIcon = styled(
  (props: BoxProps & { open: boolean }) => {
    const { open, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'open',
  },
)`
  width: 18px;
  height: 18px;
  margin-left: auto;

  svg {
    width: 18px;
    height: 18px;
    transition: all 0.2s ease;
    transform: ${({ open }) => (open ? 'none' : 'rotate(180deg)')};
  }
`

const sharedTextStyles = `
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  transition: all 0.4s ease;
  position: absolute;
  top: 0;
  left: 19px;
  bottom: 0;
`

export const StyledOpenText = styled(
  (props: BoxProps & { open: boolean }) => {
    const { open, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'open',
  },
)`
  ${sharedTextStyles};
  opacity: ${({ open }) => (open ? 0 : 1)};
`

export const StyledCloseText = styled(
  (props: BoxProps & { open: boolean }) => {
    const { open, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'open',
  },
)`
  ${sharedTextStyles};
  opacity: ${({ open }) => (open ? 1 : 0)};
`

export const StyledCounterText = styled('span')`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.palette.primary.main};
`

export const CollapsibleContainerFooter = styled(Box)``
