import React from 'react'
import { styled } from '@mui/material'

export const StyledRoot = styled('svg')`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .arrows {
    // center of the element in relative to svg viewbox
    transform-origin: 13.802614688873291px 13.647799015045166px;
  }

  .arrows.animate {
    animation: 1s infinite rotate linear;
  }
`

export const Icon: React.FC<{ inProgress: boolean }> = ({ inProgress }) => (
  <StyledRoot
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.57685 2.70843C3.34662 2.70843 3.12581 2.79989 2.963 2.9627C2.8002 3.1255 2.70874 3.34631 2.70874 3.57655V5.48641H14.8624V3.57655C14.8624 3.34631 14.7709 3.1255 14.6081 2.9627C14.4453 2.79989 14.2245 2.70843 13.9943 2.70843H3.57685ZM15.9042 3.57655C15.9042 3.07002 15.7029 2.58424 15.3448 2.22607C14.9866 1.8679 14.5008 1.66669 13.9943 1.66669H3.57685C3.07033 1.66669 2.58455 1.8679 2.22638 2.22607C1.86821 2.58424 1.66699 3.07002 1.66699 3.57655V12.2577C1.66699 12.7643 1.86821 13.2501 2.22638 13.6082C2.58455 13.9664 3.07033 14.1676 3.57685 14.1676H7.39658C7.68425 14.1676 7.91745 13.9344 7.91745 13.6467C7.91745 13.3591 7.68425 13.1259 7.39658 13.1259H3.57685C3.34661 13.1259 3.12581 13.0344 2.963 12.8716C2.8002 12.7088 2.70874 12.488 2.70874 12.2577V10.3479H6.63402V10.6951C6.63402 10.9828 6.86723 11.216 7.1549 11.216C7.44257 11.216 7.67577 10.9828 7.67577 10.6951V10.3479H9.13282C9.42049 10.3479 9.65369 10.1147 9.65369 9.82701C9.65369 9.53934 9.42049 9.30614 9.13282 9.30614H7.67577V6.52816H10.7829V7.57684C10.7829 7.86451 11.0161 8.09772 11.3038 8.09772C11.5915 8.09772 11.8247 7.86451 11.8247 7.57684V6.52816H14.8624V6.95249C14.8624 7.24016 15.0956 7.47336 15.3833 7.47336C15.6709 7.47336 15.9042 7.24016 15.9042 6.95249V3.57655ZM6.63402 6.52816H2.70874V9.30614H6.63402V6.52816Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.922 10.0091C13.2116 9.9691 12.5082 10.167 11.9229 10.5718C11.3356 10.9779 10.9011 11.5687 10.6885 12.2503C10.6028 12.525 10.3108 12.6781 10.0361 12.5925C9.7615 12.5068 9.60832 12.2148 9.69398 11.9401C9.97582 11.0366 10.5519 10.2533 11.3304 9.71496C12.1089 9.17663 13.0451 8.91423 13.99 8.96953L14.0042 8.97056C14.9492 9.05192 15.8405 9.44398 16.5391 10.0854C16.8282 10.351 17.0776 10.653 17.282 10.9822V10.0013C17.282 9.71369 17.5152 9.48049 17.8029 9.48049C18.0906 9.48049 18.3238 9.71369 18.3238 10.0013V12.6057C18.3238 12.8934 18.0906 13.1266 17.8029 13.1266H15.3027C15.015 13.1266 14.7818 12.8934 14.7818 12.6057C14.7818 12.318 15.015 12.0848 15.3027 12.0848H16.6715C16.4927 11.6175 16.2072 11.195 15.8345 10.8527C15.3072 10.3686 14.6349 10.072 13.922 10.0091ZM10.9343 15.21H12.3025C12.5902 15.21 12.8234 14.9768 12.8234 14.6892C12.8234 14.4015 12.5902 14.1683 12.3025 14.1683H10.2637C10.2575 14.1682 10.2512 14.1682 10.245 14.1683H9.8023C9.51463 14.1683 9.28143 14.4015 9.28143 14.6892V17.2935C9.28143 17.5812 9.51463 17.8144 9.8023 17.8144C10.09 17.8144 10.3232 17.5812 10.3232 17.2935V16.3119C10.5276 16.6413 10.7771 16.9437 11.0663 17.2095C11.7649 17.8513 12.6565 18.2436 13.6016 18.325L13.6158 18.326C14.5611 18.3816 15.4978 18.119 16.2765 17.5802C17.0552 17.0413 17.631 16.2571 17.9121 15.3528C17.9975 15.0781 17.844 14.7862 17.5693 14.7008C17.2946 14.6155 17.0027 14.7689 16.9173 15.0436C16.7053 15.7257 16.271 16.3171 15.6837 16.7235C15.0983 17.1286 14.3946 17.3267 13.684 17.2865C12.9709 17.2235 12.2984 16.9268 11.7711 16.4423C11.3985 16.1 11.1131 15.6774 10.9343 15.21Z"
      fill="currentColor"
      className={`arrows ${inProgress ? 'animate' : ''}`}
    />
  </StyledRoot>
)
