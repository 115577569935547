import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import { UserData } from '@fintastic/web/data-access/iam'
import Switch from '@mui/material/Switch'
import {
  GroupPanel,
  UserMultiSelectOption,
  UserPanel,
  UsersMultiSelect,
} from '@fintastic/shared/ui/components'

import { isEqual, sortBy } from 'lodash'
import { GroupsAPIResult } from '@fintastic/web/feature/settings'
import { updateBoardAccess } from '../../api'
import { DeleteIcon } from '@fintastic/shared/ui/icons'
import { useModalState } from '@fintastic/shared/util/modal'
import { BoardShareRemoveAllModal } from './BoardShareRemoveAllModal'
import {
  StyledBoardShareBusy,
  StyledServerSideData,
  StyledServerSideDataHeader,
} from './BoardShareForm.styled'
import {
  Board,
  BoardAccess,
  BoardAccessIdentityAccess,
  BoardAccessIdentityTypes,
} from '../../types'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useBoardInvalidate } from '../../hooks/useBoardsInvalidation'
import { prepareAccesses } from './boards-share-prepare-remote-utils'
import { prepareUsersForMultiselect } from './boards-share-prepare-users-utils'

export type UsersAndGroupsData = {
  username: string
  full_name?: string
  isGroup?: boolean
  group?: string
}

export type ExtendedServerSideIdentity = Omit<
  BoardAccessIdentityAccess,
  'access_level'
> & { identity_name: string }

export type BoardShareFormProps = {
  boardName: string
  selectedBoard: Board['id']
  closeParentModal: () => void
  users: UserData[]
  groups: GroupsAPIResult // @todo: consistency, []
  remoteAccess: BoardAccess
}

export const BoardShareForm: React.FC<BoardShareFormProps> = ({
  closeParentModal,
  boardName,
  selectedBoard,
  users,
  groups,
  remoteAccess,
}) => {
  const confirmationModal = useModalState()

  const [saving, setSaving] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const [isRestricted, setIsRestricted] = useState(remoteAccess.is_restricted)

  const handleGeneralAccessChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsRestricted(!event.target.checked) // opposite value, checked = is_restricted === false
    },
    [setIsRestricted],
  )

  // remap remote data - check user/group actually exists,
  // get actual name for user,
  // sort by type (users first) and id (as name),
  // and left only existing and consistent records
  const allRemoteData = useMemo<ExtendedServerSideIdentity[]>(
    () => prepareAccesses(remoteAccess?.accesses, users, groups),
    [remoteAccess, users, groups],
  )

  const [remoteAccessIdentities, setRemoteAccessIdentities] = useState<
    ExtendedServerSideIdentity[]
  >([])

  useEffect(() => {
    // set filtered and prepared remote data which will be later modified with setRemoteData
    // (on removing records)
    setRemoteAccessIdentities(allRemoteData)
    return () => setRemoteAccessIdentities([])
  }, [allRemoteData])

  // dropdown list - all except power users, already selected records, deactivated users
  const usersToSelect = useMemo<UserMultiSelectOption[]>(
    () => prepareUsersForMultiselect(users, groups, remoteAccessIdentities),
    [remoteAccessIdentities, users, groups],
  )

  const currentAccess = useMemo<BoardAccess>(
    () => ({
      is_restricted: isRestricted,
      accesses: [
        ...remoteAccessIdentities.map((identity) => ({
          identity_id: identity.identity_id,
          identity_type: identity.identity_type,
          identity_name: identity.identity_name,
          access_level: 'viewer',
        })),
        ...selectedUsers.map((item) => ({
          identity_id: item,
          identity_type: (groups.some((g) => g.id === item)
            ? 'group'
            : 'user') as BoardAccessIdentityTypes,
          access_level: 'viewer', // not specified yet!
        })),
      ],
    }),
    [isRestricted, selectedUsers, remoteAccessIdentities, groups],
  )

  const accessChanged = useMemo<boolean>(
    () =>
      currentAccess.is_restricted !== remoteAccess.is_restricted ||
      !isEqual(
        sortBy(remoteAccess.accesses.map((identity) => identity.identity_id)),
        sortBy(currentAccess.accesses.map((identity) => identity.identity_id)),
      ),
    [currentAccess, remoteAccess],
  )

  const { invalidateBoard, invalidateList } = useBoardInvalidate()

  const handleSubmit = useCallback(async () => {
    setSaving(true)

    try {
      await updateBoardAccess(selectedBoard, currentAccess)
      // reload board list
      // @todo: detect if own access affected (general access/public, user list, not a power user) and refresh only if needed
      await invalidateList()

      if (closeParentModal) {
        closeParentModal()
        await invalidateBoard(selectedBoard)
      }
      setSaving(false)
      toast.success(`Sharing settings for '${boardName}' applied successfully`)
    } catch (e) {
      console.error(e)
      setSaving(false)

      toast.error(
        `Oops, something went wrong on saving settings for '${boardName}'}`,
      )
    }
  }, [
    boardName,
    currentAccess,
    closeParentModal,
    selectedBoard,
    setSaving,
    invalidateBoard,
    invalidateList,
  ])

  const handleRemoveAllDialog = useCallback(() => {
    confirmationModal.open()
  }, [confirmationModal])

  const handleRemoveAll = useCallback(() => {
    confirmationModal.close()
    setRemoteAccessIdentities([])
  }, [confirmationModal])

  const handleRemoveIdentity = useCallback(
    (identity_id: string) => {
      setRemoteAccessIdentities((data) =>
        data.filter((identity) => identity.identity_id !== identity_id),
      )
    },
    [setRemoteAccessIdentities],
  )

  const disableRemoveAll = saving || remoteAccessIdentities.length === 0

  return (
    <>
      <FormLayout
        submitButtonText="Apply"
        onSubmit={handleSubmit}
        onCancel={closeParentModal}
        testIdPrefix="share"
        submitEnabled={!saving && accessChanged}
      >
        {saving && (
          <StyledBoardShareBusy>
            <CircularProgress />
          </StyledBoardShareBusy>
        )}
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: '100%' }}>
            <UsersMultiSelect
              id="sharing-user-select"
              disabled={usersToSelect.length === 0 || saving || !isRestricted}
              values={selectedUsers}
              options={usersToSelect}
              onChange={setSelectedUsers}
              label={
                usersToSelect.length
                  ? 'User or Group to share board with'
                  : 'Board is already shared with all users'
              }
            />
          </Box>
        </Box>
        {remoteAccessIdentities.length > 0 && (
          <StyledServerSideData>
            <StyledServerSideDataHeader>
              <Typography>People with access</Typography>
              <FormControl variant="standard" size="small">
                <Button
                  aria-label="delete"
                  size="small"
                  disabled={disableRemoveAll}
                  onClick={handleRemoveAllDialog}
                  startIcon={<DeleteIcon fontSize="inherit" />}
                >
                  Remove all
                </Button>
              </FormControl>
            </StyledServerSideDataHeader>
            <Divider orientation="horizontal" />
            <Stack
              spacing={3}
              maxHeight={'30vh'}
              sx={{
                mt: 2,
                mb: 2,
                pr: 2,
                overflowY: 'auto',
              }}
            >
              {remoteAccessIdentities.map((identity) => (
                <Stack
                  key={identity.identity_id}
                  direction="row"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {identity.identity_type === 'user' && (
                    <UserPanel
                      name={identity.identity_name}
                      disabled={!isRestricted}
                      email={identity.identity_id}
                    />
                  )}
                  {identity.identity_type === 'group' && (
                    <GroupPanel
                      name={identity.identity_id}
                      disabled={!isRestricted}
                    />
                  )}
                  <FormControl variant="standard" sx={{ minWidth: 80 }}>
                    <Button
                      aria-label="delete"
                      disabled={saving}
                      size="small"
                      onClick={() => handleRemoveIdentity(identity.identity_id)}
                      startIcon={<DeleteIcon fontSize="inherit" />}
                    >
                      Remove
                    </Button>
                  </FormControl>
                </Stack>
              ))}
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </StyledServerSideData>
        )}
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Switch
              sx={{ m: 1, ml: '12px' }}
              checked={!isRestricted}
              onChange={handleGeneralAccessChange}
            />
          }
          label="Public"
        />
        <Typography variant="body2" sx={{ mt: 1 }}>
          {!isRestricted ? (
            'This board will be visible to all users'
          ) : (
            <>&nbsp;</>
          )}
        </Typography>
      </FormLayout>

      {confirmationModal.isOpen && (
        <BoardShareRemoveAllModal
          onRequestClose={confirmationModal.close}
          onRemove={handleRemoveAll}
        />
      )}
    </>
  )
}
