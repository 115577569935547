import React from 'react'
import { ModalDialog } from '../ModalDialog'
import { ModalContent } from '../ModalContent'
import { ModalHeader } from '../ModalHeader'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type ModalProps = {
  open: boolean
  onRequestClose?: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  showCloseButton?: boolean
  children?: React.ReactNode
  ['data-testid']?: string
  width?: number | string
  keepMounted?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  open,
  onRequestClose,
  title,
  showCloseButton = true,
  children,
  width = 400,
  keepMounted = false,
  description,
  ...restProps
}) => (
  <FintasticThemeProvider applyLegacyTheme={false}>
    <ModalDialog
      open={open}
      onClose={onRequestClose}
      width={width}
      data-testid={restProps['data-testid']}
      keepMounted={keepMounted}
    >
      {(title !== undefined ||
        description !== undefined ||
        showCloseButton) && (
        <ModalHeader
          onRequestClose={onRequestClose}
          title={title}
          description={description}
          showCloseButton={showCloseButton}
        />
      )}
      {children && <ModalContent>{children}</ModalContent>}
    </ModalDialog>
  </FintasticThemeProvider>
)
