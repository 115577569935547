import { Box, Button, styled, Typography } from '@mui/material'

export const StyledModalFooterPanel = styled(Box)`
  margin: ${({ theme }) => theme.spacing(2)} 0;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

export const StyledModalFooterPanelSubmitContainer = styled(Box)`
  text-align: center;
`

export const StyledModalFooterPanelCancelButton = styled(Button)`
  margin-right: 6px;
`

export const StyledModalFooterPanelSubmitErrorMessage = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacingFromPixels(8)};
  color: ${({ theme }) => theme.palette.error.main};
`
