import { useDispatch } from 'react-redux'
import { actions } from '../slice'
import { useCallback } from 'react'

export function useCancelEditing() {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.cancelEditing())
  }, [dispatch])
}
