import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { sortBy } from 'lodash'
import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { Maybe } from '@fintastic/shared/util/types'
import { InlineInfoTooltip } from '@fintastic/shared/ui/components'

type VersionSelectOption = { id: string; label: string }

type ReferenceVersionSelectProps = {
  value: Maybe<string>
  onSelect: (versionId: Maybe<string>) => void
  onNoRefVersion: (notFound?: boolean) => void
  onFetching?: (processName: string) => void
  onReady?: (processName: string) => void
}

const renderInput = (params: AutocompleteRenderInputParams) => (
  <TextField
    {...params}
    label="Reference version"
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <>
          <InputAdornment position="end">
            <InlineInfoTooltip
              tooltip={
                "The reference version is the source from which you'll select entities such as Reports, Lists and Metrics to add to this Board"
              }
            />
          </InputAdornment>
          {params.InputProps.endAdornment}
        </>
      ),
    }}
  />
)

const renderOptions = (
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: Maybe<VersionSelectOption>,
) => (
  <Box component="li" {...optionProps} key={option?.id || 'none'}>
    {option?.label} &nbsp;
  </Box>
)

export const PENDING_VERSION_ID = 'versionList'

export const ReferenceVersionSelect: React.FC<ReferenceVersionSelectProps> = ({
  onSelect,
  value,
  onNoRefVersion,
  onReady,
  onFetching,
}) => {
  const { data: versionsList, isLoading } = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: false,
    showDeleted: false,
  })

  const versionNames: VersionSelectOption[] = useMemo(
    () => [
      ...sortBy(
        versionsList?.map((f) => ({ id: f.uuid, label: f.name })),
        ['label'],
      ),
    ],
    [versionsList],
  )

  const currentValue: Maybe<VersionSelectOption> = useMemo(() => {
    if (!value) {
      // @todo: no default for first widget?
      // https://fintastic.atlassian.net/browse/FIN-7319
      return null
    }
    const dataValue = versionNames.find((o) => o.id === value)
    if (!dataValue && versionNames && versionNames.length > 0) {
      onNoRefVersion(true)
    }
    if (dataValue && versionNames && versionNames.length > 0) {
      onNoRefVersion(false)
    }
    return dataValue || null
  }, [onNoRefVersion, value, versionNames])

  useEffect(() => {
    if (isLoading) {
      onFetching?.(PENDING_VERSION_ID)
    } else {
      if (versionNames.length > 0) {
        onReady?.(PENDING_VERSION_ID)
      }
    }
  }, [isLoading, onReady, onFetching, versionNames])

  if (!versionNames || isLoading) {
    return (
      <Skeleton
        width={'100%'}
        height={40}
        variant="rectangular"
        sx={{ borderRadius: '6px' }}
      />
    )
  }

  return (
    <Autocomplete<Maybe<VersionSelectOption>, false, true | false, false>
      size={'small'}
      value={currentValue}
      options={versionNames}
      disableClearable={true}
      onChange={(_, newInputValue) =>
        onSelect(newInputValue ? newInputValue.id : null)
      }
      renderInput={renderInput}
      isOptionEqualToValue={(fo, option) => fo?.id === option?.id}
      renderOption={renderOptions}
    />
  )
}
