import {
  StyledInlineButton,
  StyledVersionEditableNameField,
} from '../Version.styled'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { ClickAwayListener } from '@mui/material'

export type EditableVersionTitleProps = {
  'versionName': string
  'onNameChange': (newVersionName: string) => void
  'validateValueCallback': (newVersionName: string) => boolean
  'readonly': boolean
  'data-testid'?: string
}

const FONT_SIZE = 15
const MIN_INPUT_SIZE = 320

export const EditableVersionName: React.FC<EditableVersionTitleProps> = (
  props,
) => {
  const {
    versionName,
    onNameChange,
    validateValueCallback,
    readonly,
    ...otherProps
  } = props

  const [editMode, setEditMode] = useState(false)
  const [newVersionName, setNewVersionName] = useState(versionName)
  useEffect(() => {
    if (editMode) {
      setNewVersionName(versionName)
    }
  }, [editMode, versionName])

  const [errorMessage, setErrorMessage] = useState('')

  const [inputWidth, setInputWidth] = useState(
    Math.max(versionName.length * FONT_SIZE, MIN_INPUT_SIZE),
  )
  useEffect(() => {
    setInputWidth(Math.max(newVersionName.length * FONT_SIZE, MIN_INPUT_SIZE))
  }, [newVersionName])

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value
      setNewVersionName(newName)
      if (!validateValueCallback(newName)) {
        setErrorMessage('Name is not unique')
      } else {
        setErrorMessage('')
      }
    },
    [validateValueCallback],
  )

  const handleCancelNameEdit = useCallback(() => {
    setEditMode(false)
    setNewVersionName(versionName)
    setErrorMessage('')
  }, [versionName])

  useEffect(() => {
    if (readonly) {
      handleCancelNameEdit()
    }
  }, [handleCancelNameEdit, readonly])

  const handleSubmitNameChange = useCallback(() => {
    if (!errorMessage.length) {
      if (newVersionName && newVersionName !== versionName) {
        onNameChange(newVersionName)
      }
      setEditMode(false)
      setErrorMessage('')
    }
  }, [errorMessage.length, newVersionName, onNameChange, versionName])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSubmitNameChange()
      }
    },
    [handleSubmitNameChange],
  )

  const clickAwayHandle = useCallback(() => {
    if (!editMode) {
      return
    }
    if (errorMessage.length || newVersionName === versionName) {
      handleCancelNameEdit()
    } else {
      handleSubmitNameChange()
    }
  }, [
    editMode,
    errorMessage.length,
    handleCancelNameEdit,
    handleSubmitNameChange,
    newVersionName,
    versionName,
  ])

  return (
    <ClickAwayListener onClickAway={clickAwayHandle}>
      <StyledVersionEditableNameField
        {...otherProps}
        id="standard-basic"
        variant="standard"
        size="medium"
        onFocus={useCallback(() => !readonly && setEditMode(true), [readonly])}
        value={editMode ? newVersionName : versionName}
        onChange={handleNameChange}
        onKeyDown={handleKeyDown}
        error={!!errorMessage.length}
        helperText={errorMessage}
        disabled={readonly}
        inputProps={{ maxLength: 255 }}
        InputProps={{
          style: { width: `${inputWidth}px`, maxWidth: '100%' },
          disableUnderline: !editMode,
          endAdornment: editMode && (
            <>
              {!errorMessage.length && (
                <StyledInlineButton>
                  <CheckIcon
                    fontSize="small"
                    onClick={handleSubmitNameChange}
                  />
                </StyledInlineButton>
              )}
              <StyledInlineButton>
                <ClearIcon fontSize="small" onClick={handleCancelNameEdit} />
              </StyledInlineButton>
            </>
          ),
        }}
      />
    </ClickAwayListener>
  )
}
