import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'

type SharedProps = {
  goBack: () => void
  metric: {
    id: string
    label: string
    isColumn: boolean
    reportingGroup: Maybe<{
      id: string
      label: string
    }>
    aggregation: unknown
    timeAggregation: unknown
    requiresDateColumn: boolean
    dateColumn: Maybe<{
      id: string
      label: string
    }>
  }
}

const Editable: React.FC<
  SharedProps & {
    availableReportingGroups: Array<{
      id: string
      label: string
    }>
    changeReportingGroup: (metricId: string, groupId: string) => void
    changeAggregation: (metricId: string, aggregation: unknown) => void
    changeTimeAggregation: (metricId: string, aggregation: unknown) => void
    availableDateColumns: Maybe<
      Array<{
        id: string
        label: string
      }>
    >
    changeDateColumn: (metricId: string, dateColumnId: string) => void
    removeMetric: (metricId: string) => void
  }
> = () => <div>Editable</div>

const Readonly: React.FC<SharedProps> = () => <div>Readonly</div>

export const MetricSettingsView = {
  Editable,
  Readonly,
} as const
