import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { LabelItem, OnLabelCallbacks } from './types'
import { LabelsEditMenuDeleteButton } from './LabelsEditMenuDeleteButton'
import { SYSTEM_COLORS } from '@fintastic/shared/util/colors'
import { LabelsEditMenuColorItem } from './LabelsEditMenuColorItem'
import { debounce } from 'lodash'
import { toast } from '@fintastic/shared/ui/toast-framework'

export type LabelsEditMenuProps = {
  label: LabelItem
  closePopup: () => void
} & Pick<OnLabelCallbacks, 'onLabelUpdated' | 'onLabelDeleted'>

export const LabelsEditMenu: React.FC<LabelsEditMenuProps> = ({
  label,
  onLabelUpdated,
  onLabelDeleted,
  closePopup,
}) => {
  const [editedValue, setEditedValue] = useState(label.title)
  const [labelNameBusy, setLabelNameBusy] = useState(false)

  const wrappedOnLabelUpdated = useCallback(
    async (title: string) => {
      const status = await onLabelUpdated({
        ...label,
        title,
      })

      if (status === 409) {
        setLabelNameBusy(true)
        toast.error('Label with this name already exists')
      } else {
        setLabelNameBusy(false)
      }
    },
    [label, onLabelUpdated],
  )

  const onLabelUpdatedDebounced = useMemo(
    () => debounce(wrappedOnLabelUpdated, 500),
    [wrappedOnLabelUpdated],
  )

  const handleSearchChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (e) => {
      setEditedValue(e.target.value)
      setLabelNameBusy(false)
      onLabelUpdatedDebounced(e.target.value)
    },
    [onLabelUpdatedDebounced],
  )

  const handleColorSelection = useCallback(
    (color: { bg: string }) => {
      onLabelUpdated({
        ...label,
        color: color.bg,
      })
      closePopup()
    },
    [label, onLabelUpdated, closePopup],
  )

  const handleLabelDelete = useCallback(() => {
    onLabelDeleted?.(label)
    closePopup()
  }, [onLabelDeleted, label, closePopup])

  const handleLabelFormSubmit = useCallback<
    React.FormEventHandler<HTMLFormElement>
  >(
    (e) => {
      e.preventDefault()
      closePopup()
    },
    [closePopup],
  )

  const labelIsValid = editedValue.length > 0

  const helperText = useMemo(() => {
    if (!labelIsValid) {
      return 'Label is required'
    }

    if (labelNameBusy) {
      return 'Label with this name already exists'
    }

    return ''
  }, [labelIsValid, labelNameBusy])

  return (
    <Paper elevation={1}>
      <Box p="6px">
        <form onSubmit={handleLabelFormSubmit}>
          <TextField
            onChange={handleSearchChange}
            value={editedValue}
            placeholder="Add label"
            fullWidth
            size="small"
            error={!labelIsValid || labelNameBusy}
            helperText={helperText}
          />
        </form>
      </Box>
      {label.is_removable && (
        <List disablePadding>
          <LabelsEditMenuDeleteButton onClick={handleLabelDelete} />
        </List>
      )}
      <Divider />
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton disabled dense>
            <ListItemText primary="COLORS" />
          </ListItemButton>
        </ListItem>
        {SYSTEM_COLORS.map((color) => (
          <LabelsEditMenuColorItem
            key={color.bg}
            color={color}
            selected={label.color === color.bg}
            onColorSelection={handleColorSelection}
          />
        ))}
      </List>
    </Paper>
  )
}
