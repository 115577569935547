import { useEffect, useMemo, useState } from 'react'
import { isDirty } from './isDirty'

export function useFormulaValueState(defaultState: string) {
  const [state, setState] = useState(defaultState)

  useEffect(() => {
    setState(defaultState)
  }, [defaultState])

  return useMemo(
    () => ({
      formulaValue: state,
      setFormulaValue: setState,
      isDirty: isDirty(state, defaultState),
    }),
    [defaultState, state],
  )
}
