import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnNamePayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnName = (
  uncheckedState: State,
  {
    payload: { columnId, newName, dimensionNames },
  }: PayloadAction<ChangeColumnNamePayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  list.renameColumn(columnId, newName, dimensionNames)
}
