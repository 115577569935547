import { StyledEngineProvider } from '@mui/material/styles'
import { QueryClientProvider } from 'react-query'
import { Auth0Provider } from '@fintastic/shared/data-access/auth0-react'
import React, { Suspense } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux/store'
import { queryClient } from '@fintastic/web/data-access/service-query-client'
import { auth0Client } from '@fintastic/web/data-access/service-auth0'
import { pusherClient } from '@fintastic/web/data-access/service-pusher'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import { AppInitializer } from './AppInitializer'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import './styles/ag-grid-custom.css'
import './styles/_colors.css'
import './styles/base.css'
import { environment } from '@fintastic/web/data-access/environment'
import { LicenseManager } from 'ag-grid-enterprise'
import { Toaster } from '@fintastic/shared/ui/toast-framework'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { LinearProgress } from '@mui/material'
import { ReactQueryDevtools } from 'react-query/devtools'
import { PusherProvider } from '@fintastic/shared/data-access/pusher-react'
import {
  AwaitForMyTasksProvider,
  LostConnectionNotification,
} from '@fintastic/web/feature/realtime'

const NewDeploymentNotification = React.lazy(
  () => import('@fintastic/web/feature/new-deployment-notification'),
)

if (environment.REACT_APP_AGGRID_LICENSE) {
  LicenseManager.setLicenseKey(environment.REACT_APP_AGGRID_LICENSE)
}

export const AppContainer: React.FC = () => (
  <ErrorBoundary>
    <StyledEngineProvider injectFirst>
      <FintasticThemeProvider includeBaseline={true}>
        <ErrorBoundary>
          <PusherProvider client={pusherClient}>
            <ReduxProvider store={store}>
              <QueryClientProvider client={queryClient}>
                <Auth0Provider client={auth0Client}>
                  <AwaitForMyTasksProvider>
                    <Suspense fallback={<LinearProgress />}>
                      <AppInitializer />
                      <Toaster />

                      <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-right"
                      />
                    </Suspense>
                    <Suspense fallback={null}>
                      <NewDeploymentNotification />
                    </Suspense>
                    <LostConnectionNotification />
                  </AwaitForMyTasksProvider>
                </Auth0Provider>
              </QueryClientProvider>
            </ReduxProvider>
          </PusherProvider>
        </ErrorBoundary>
      </FintasticThemeProvider>
    </StyledEngineProvider>
  </ErrorBoundary>
)
