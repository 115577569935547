import { NameValidationResult } from './types'
import { isFilled } from './isFilled'
import { isNameUnique } from './isNameUnique'
import { doesMatchPattern } from './doesMatchPattern'

export function validateName(
  name: string,
  existingNames: Record<string, boolean>,
): NameValidationResult {
  const filled = isFilled(name)
  const unique = isNameUnique(name, existingNames)
  const matchPattern = doesMatchPattern(name)

  return {
    filled,
    unique,
    matchPattern,
  }
}
