import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  StyledBoardNameEditBox,
  StyledBoardNameEditContainer,
  StyledNameErrorTooltip,
} from './BoardName.styled'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTextFieldState } from '@fintastic/shared/ui/form-framework'
import { useBoardNameValidation } from '../../../../hooks/useBoardNameValidation'

type BoardNameProps = {
  boardName: string
  boardId: string
  onUpdateName: (value: string) => void
}

export const BoardDesignName: React.FC<BoardNameProps> = memo(
  ({ boardName, boardId, onUpdateName }) => {
    const [editState, setEditState] = useState(false)

    const boardNameFieldState = useTextFieldState(boardName)
    const boardNameValidation = useBoardNameValidation({
      boardName: boardNameFieldState.trimmedValue,
      editingBoardId: boardId,
    })

    useEffect(() => {
      boardNameFieldState.setValue(boardName)
    }, [boardName])

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        boardNameFieldState.setValue(event.target.value)
      },
      [],
    )

    const isSubmitEnabled =
      boardNameFieldState.value.length > 0 && boardNameValidation.isValid

    const startEdit = useCallback(() => {
      setEditState(() => true)
    }, [])

    const resetEdit = useCallback(() => {
      setEditState(() => false)
      boardNameFieldState.setValue(boardName)
    }, [boardName, boardNameFieldState])

    const commitEdit = useCallback(() => {
      onUpdateName(boardNameFieldState.trimmedValue)
      setEditState(() => false)
    }, [boardNameFieldState.trimmedValue, onUpdateName])

    const exampleTextRef = useRef<HTMLHeadingElement>(null)
    const [inputWidth, setInputWidth] = useState('300px')

    useLayoutEffect(() => {
      if (!exampleTextRef.current) {
        setInputWidth('200px')
        return
      }

      let refWidth = exampleTextRef.current?.clientWidth

      if (refWidth) {
        refWidth = refWidth < 800 ? refWidth : 800
      } else {
        refWidth = 120
      }

      if (!editState) {
        setInputWidth(refWidth + 'px')
        return
      }

      if (refWidth < 120) {
        refWidth = 120
      }
      setInputWidth(refWidth + 110 + 'px')
    }, [boardNameFieldState.trimmedValue, exampleTextRef, editState])

    const hasError = editState && !!boardNameValidation.error

    return (
      <StyledBoardNameEditContainer>
        <h1 id="reference-text" ref={exampleTextRef}>
          {boardNameFieldState.value}
        </h1>
        <StyledBoardNameEditBox
          allowEdit={editState}
          hasError={hasError}
          designMode={true}
          onClick={editState ? undefined : startEdit}
        >
          <TextField
            autoFocus={false}
            autoComplete="off"
            size="small"
            sx={{ minWidth: `${inputWidth}`, width: `${inputWidth}` }}
            value={boardNameFieldState.value}
            onChange={handleChange}
            error={!isSubmitEnabled}
            inputProps={{
              maxLength: 128,
            }}
            InputProps={{
              readOnly: !editState,
              endAdornment: editState && (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ marginLeft: 2 }}
                    disabled={!isSubmitEnabled}
                    onClick={commitEdit}
                  >
                    <CheckOutlinedIcon sx={{ width: '16px' }} />
                  </IconButton>

                  <IconButton onClick={resetEdit}>
                    <CloseOutlinedIcon sx={{ width: '16px' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {hasError && (
            <StyledNameErrorTooltip>
              {boardNameValidation.error}
            </StyledNameErrorTooltip>
          )}
        </StyledBoardNameEditBox>
      </StyledBoardNameEditContainer>
    )
  },
)
