import { ClientOnlyMapping, MappingDictionary } from './types'
import { VersionEntities } from '@fintastic/web/util/versions'
import { Maybe } from '@fintastic/shared/util/types'
import { wrapDimValueLabelInQuotesIfNecessary } from './helpers'
import { ParsedDimensionValueId } from '@fintastic/web/util/dimensions'

type MappingType = 'idToLabel' | 'labelToId'

export function createMappingDictionary(
  versionEntities: Maybe<VersionEntities>,
  type: MappingType,
  clientOnlyMapping?: ClientOnlyMapping,
): MappingDictionary {
  const result: MappingDictionary = {}

  if (versionEntities === null) {
    return result
  }

  versionEntities.metrics.forEach((metric) => {
    const [key, value] = extractEntry(type, metric.id, metric.label)
    result[key] = value
  })
  clientOnlyMapping?.metrics?.forEach((m) => {
    const [key, value] = extractEntry(type, m.id, m.label)
    result[key] = value
  })

  versionEntities.lists.forEach((list) => {
    const [key, value] = extractEntry(type, list.id, list.label)
    result[key] = value

    list.columns.forEach((column) => {
      const [key, value] = extractEntry(
        type,
        column.id,
        `${list.label}.${column.label}`,
      )
      result[key] = value
    })
  })
  clientOnlyMapping?.listColumns?.forEach((c) => {
    const [key, value] = extractEntry(type, c.id, `${c.listLabel}.${c.label}`)
    result[key] = value
  })

  versionEntities.dimensions.forEach((dimension) => {
    const [key, value] = extractEntry(type, dimension.id, dimension.label)
    result[key] = value

    if (!('values' in dimension)) {
      return
    }
    Object.entries(dimension.values).forEach(([dimValueId, dimValueLabel]) => {
      const dimValueIdWithQuotes =
        ParsedDimensionValueId.fromString(dimValueId)?.toWrappedString(
          wrapDimValueLabelInQuotesIfNecessary,
        ) ?? null
      if (dimValueIdWithQuotes === null) {
        return
      }

      const [key, value] = extractEntry(
        type,
        dimValueIdWithQuotes,
        `${dimension.label}.${wrapDimValueLabelInQuotesIfNecessary(
          dimValueLabel,
        )}`,
      )
      result[key] = value
    })
  })

  return result
}

function extractEntry(
  type: MappingType,
  id: string,
  label: string,
): [string, string] {
  return [type === 'idToLabel' ? id : label, type === 'idToLabel' ? label : id]
}
