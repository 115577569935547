export const notificationMessages = {
  list: {
    start: 'Fintastic auto saves your changes.',
    failed: 'Failed to save edited metric.',
    noAccessToEdit: 'You do not have permissions to update data in this list',
    technicalError:
      'Unknown technical error occurred. Please try again later or contact the administrator.',
  },
  metric: {
    start: 'Fintastic auto saves your changes.',
    failed: 'Failed to save edited list.',
    noAccessToEdit: 'You do not have permissions to update data in this metric',
    technicalError:
      'Unknown technical error occurred. Please try again later or contact the administrator.',
  },
} as const
