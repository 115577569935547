import { AG_THEME_FINTASTIC_DEFAULT_OPTIONS } from '../const'
import type {
  AgFintasticThemeVariantConfig,
  AgFintasticThemeVariantGridPropsGetter,
  AgFintasticThemeVariantStylesBuilder,
} from '../types'
import { CSSObject } from '@mui/styled-engine'
import color from 'color'
import {
  AG_CELL_EDITOR_CLASS_NAME,
  AG_DATE_CELL_EDITOR_CLASS_NAME,
  AG_SELECT_BOX_CELL_EDITOR_CLASS_NAME,
} from '@fintastic/shared/ui/ag-grid'
import { defaultGridProps } from '@fintastic/shared/util/ag-grid'

const stylesBuilder: AgFintasticThemeVariantStylesBuilder = (options) => {
  const {
    muiTheme,
    oddRowsHighlighting,
    inscribedShape,
    hideColumnsSelectionSidebar,
  } = {
    ...AG_THEME_FINTASTIC_DEFAULT_OPTIONS,
    ...options,
  }
  const t = muiTheme

  /**
   * to see all available variables, check it here:
   * ag-grid-community/src/styles/ag-theme-base/sass/ag-theme-base-default-params
   */
  const cssVariables: CSSObject = {
    '--ag-foreground-color': t.palette.text.primary,
    '--ag-background-color': t.palette.common.white,

    '--ag-cell-color-readonly': t.palette.grey[400],
    '--ag-header-cell-color-readonly': t.palette.grey[400],

    '--ag-header-background-color': t.palette.common.white,
    '--ag-alpine-active-color': t.palette.primary.main,
    '--ag-row-hover-color': '#FFF6ED',
    '--ag-pinned-row-background-color': '#fff',
    // disabled because we don't need it yet
    // '--ag-row-focused-color': color(t.palette.primary.main).alpha(0.2).string(),
    '--ag-row-highlighted-color': color(t.palette.primary.main)
      .alpha(0.4)
      .string(),
    '--ag-column-hover-color': color(t.palette.primary.main)
      .alpha(0.1)
      .string(),
    '--ag-value-change-value-highlight-background-color': '#FFE9DA',

    '--ag-font-family': t.typography.fontFamily,
    '--ag-font-size': `${t.typography.fontSize}px`,
    '--ag-font-heading': '12px',
    '--ag-font-size-pinned-row': '12px',
    '--ag-root-font-size': `${t.typography.fontSize}px`,
    '--ag-sidebar-font-size': '13px',
    '--ag-line-height': '37px',

    '--ag-cell-padding-x': '17px',
    '--ag-odd-row-background-color': oddRowsHighlighting
      ? t.palette.grey[50]
      : 'initial',
    '--ag-range-selection-background-color': '#f8f6f4',
    '--ag-selected-row-background-color': color(t.palette.primary.main)
      .alpha(0.2)
      .string(),
    '--ag-row-border-color': t.palette.divider,
    '--ag-border-color': t.palette.divider,
    '--ag-secondary-border-color': t.palette.divider,
    '--ag-header-column-resize-handle-color': t.palette.divider,
    '--ag-range-selection-border-color': t.palette.primary?.main,
    '--ag-input-focus-border-color': t.palette.divider,
    '--ag-control-panel-background-color': t.palette.common.white,
    '--ag-sub-label-spacing': t.spacing(0.5),

    '--ag-fintastic-sidebar-columns-selection-display':
      hideColumnsSelectionSidebar ? 'none' : 'flex',
  }

  const cellEditors: CSSObject = {
    [`& .${AG_CELL_EDITOR_CLASS_NAME}`]: {
      '.MuiFormControl-root': {
        height: '100%',
      },
      '.MuiInputBase-root': {
        height: '100%',
        fontSize: 'inherit',
      },
      '.MuiInputBase-root.Mui-focused': {},
      '.MuiInputBase-input': {
        padding: `0 var(--ag-cell-padding-x, ${t.spacing(2)})`,
        boxSizing: 'border-box',
        height: '100%',
      },
      '.MuiOutlinedInput-notchedOutline': {
        top: 0,
        borderRadius: 0,
        padding: 0,
        borderWidth: 1,

        legend: {
          display: 'none',
        },
      },
      '.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    },
    [`& .${AG_SELECT_BOX_CELL_EDITOR_CLASS_NAME}`]: {
      '.MuiSelect-select': {
        lineHeight: 'var(--ag-line-height, 1.3)',
      },
    },
    [`& .${AG_DATE_CELL_EDITOR_CLASS_NAME}`]: {
      '.MuiFormControl-root': {
        width: '100%',
      },
    },
  }

  const inscribedStyles: CSSObject = inscribedShape
    ? {
        '& div.ag-root-wrapper': {
          borderLeft: 'none',
          borderBottom: 'none',
          borderRight: 'none',
          borderTop: 'none',
          borderRadius: `0 0 ${muiTheme.shape.borderRadius}px ${muiTheme.shape.borderRadius}px`,
        },
      }
    : {}

  return {
    ...cssVariables,
    ...cellEditors,
    ...inscribedStyles,
  }
}

const gridPropsGetter: AgFintasticThemeVariantGridPropsGetter = () => ({
  ...defaultGridProps,
  rowHeight: 40,
  headerHeight: 40,
})

export const agFintasicThemeVariantDefault: AgFintasticThemeVariantConfig = {
  stylesBuilder,
  gridPropsGetter,
}
