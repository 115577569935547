import { ColDef, EditableCallback } from 'ag-grid-community'

export function extendEditableCallback<TData = any>(
  originalCb: EditableCallback<TData>,
  additionalCb: EditableCallback<TData>,
  ...additionalCbs: EditableCallback<TData>[]
): EditableCallback<TData> {
  return (params) =>
    additionalCbs.reduce(
      // @todo: Support isEditable callback
      (isEditable, cb) => isEditable && cb(params),
      originalCb(params) && additionalCb(params),
    )
}

export function extendEditableColDefProp<TData = any>(
  oldEditable: ColDef<TData>['editable'],
  additionalCb: EditableCallback<TData>,
  ...additionalCbs: EditableCallback<TData>[]
): ColDef<TData>['editable'] {
  if (typeof oldEditable !== 'function') {
    if (!oldEditable) {
      return false
    }

    return (params) =>
      additionalCbs.reduce(
        // @todo: Support isEditable callback
        (isEditable, cb) => isEditable && cb(params),
        oldEditable === true
          ? oldEditable && additionalCb(params)
          : additionalCb(params),
      )
  }

  return extendEditableCallback(oldEditable, additionalCb, ...additionalCbs)
}
