import React, { forwardRef } from 'react'
import { WidgetTextIcon } from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

export type TextIconProps = {
  size?: SvgIconProps['fontSize']
}

export const TextIcon = forwardRef<SVGSVGElement, TextIconProps>(
  ({ size, ...props }, ref) => (
    <WidgetTextIcon
      {...props}
      fontSize={size}
      ref={ref}
      sx={{
        color: '#555',
      }}
    />
  ),
)
