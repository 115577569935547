import React from 'react'
import { createSvgIcon } from '@mui/material'

export const CopyIcon = createSvgIcon(
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.1155 14C4.65517 14 4.27083 13.8458 3.9625 13.5375C3.65417 13.2292 3.5 12.8448 3.5 12.3845V1.6155C3.5 1.15517 3.65417 0.770833 3.9625 0.4625C4.27083 0.154167 4.65517 0 5.1155 0H12.8845C13.3448 0 13.7292 0.154167 14.0375 0.4625C14.3458 0.770833 14.5 1.15517 14.5 1.6155V12.3845C14.5 12.8448 14.3458 13.2292 14.0375 13.5375C13.7292 13.8458 13.3448 14 12.8845 14H5.1155ZM5.1155 13H12.8845C13.0385 13 13.1796 12.9359 13.3077 12.8077C13.4359 12.6796 13.5 12.5385 13.5 12.3845V1.6155C13.5 1.4615 13.4359 1.32042 13.3077 1.19225C13.1796 1.06408 13.0385 1 12.8845 1H5.1155C4.9615 1 4.82042 1.06408 4.69225 1.19225C4.56408 1.32042 4.5 1.4615 4.5 1.6155V12.3845C4.5 12.5385 4.56408 12.6796 4.69225 12.8077C4.82042 12.9359 4.9615 13 5.1155 13ZM2.1155 17C1.65517 17 1.27083 16.8458 0.9625 16.5375C0.654167 16.2292 0.5 15.8449 0.5 15.3848V3.6155H1.5V15.3848C1.5 15.5386 1.56408 15.6796 1.69225 15.8077C1.82042 15.9359 1.9615 16 2.1155 16H10.8845V17H2.1155Z"
      fill="#6E665E"
    />
  </svg>,
  'Copy',
)
