import React, { KeyboardEventHandler, useCallback, useState } from 'react'
import { Box } from '@mui/material'
import {
  NumberOfLinesButtonStyled,
  NumberOfLinesInputStyled,
  VerticalDividerStyled,
} from './styled'

export type AddNewLineButtonNumberOfLinesPickerProps = {
  onApply: (numberOfRows: number) => void
}

export const AddNewLineButtonNumberOfLinesPicker: React.FC<
  AddNewLineButtonNumberOfLinesPickerProps
> = ({ onApply }) => {
  const [value, setValue] = useState<number>()

  const handlePressEnter = useCallback<KeyboardEventHandler>(
    (e) => {
      if (e.key === 'Enter' && value) {
        onApply(value)
      }
      if (e.key === 'Escape') {
        onApply(0)
      }
    },
    [onApply, value],
  )

  return (
    <Box display="flex" p="4px">
      {predefinedOptions.map((i) => (
        <NumberOfLinesButtonStyled key={i} onClick={() => onApply(i)}>
          {i}
        </NumberOfLinesButtonStyled>
      ))}
      <VerticalDividerStyled />
      <NumberOfLinesInputStyled
        placeholder="100"
        value={value ?? ''}
        onChange={(e) => {
          if (!e.target.value?.length) {
            setValue(undefined)
            return
          }
          if (
            e.target.value === '0' ||
            e.target.value === '-' ||
            e.target.value === '.'
          ) {
            setValue(undefined)
            return
          }
          const cleanedUpValue = e.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')

          if (cleanedUpValue === '1000') {
            setValue(100)
            return
          }

          const preparsedValue =
            cleanedUpValue.length > 2 && cleanedUpValue !== '100'
              ? cleanedUpValue.substring(0, 2)
              : cleanedUpValue
          const nextValue = parseInt(preparsedValue)
          setValue(nextValue > 100 ? 100 : nextValue)
        }}
        onKeyDown={(e) => {
          if (e.key === '.' || e.key === '-') {
            e.preventDefault()
          }
        }}
        onKeyUp={handlePressEnter}
        type="number"
        max={100}
        min={1}
      />
    </Box>
  )
}

const predefinedOptions = [1, 5, 10]
