import React from 'react'
import { ErrorAlert, ErrorAlertProps } from '@fintastic/shared/ui/components'
import { StyledPageLayoutContentErrorRoot } from './PageLayoutContentError.styled'
import { usePageLayoutContext } from '../../contexts/page-layout-context'

export type PageLayoutContentErrorProps = ErrorAlertProps

export const PageLayoutContentError: React.FC<PageLayoutContentErrorProps> = (
  props,
) => {
  const { ...rest } = props
  const { disablePaddingVertical } = usePageLayoutContext()

  return (
    <StyledPageLayoutContentErrorRoot
      data-testid="page-layout-content-error"
      disablePaddingVertical={disablePaddingVertical}
    >
      <ErrorAlert {...rest} />
    </StyledPageLayoutContentErrorRoot>
  )
}
