import { SyntaxLiteral } from '../../../syntax/types'
import { Maybe } from '@fintastic/shared/util/types'

export function getOptionIndex(
  options: SyntaxLiteral[],
  optionId: Maybe<string>,
): Maybe<number> {
  const index = options.findIndex((o) => o.literal === optionId)
  return index > -1 ? index : null
}

export function findPrev(
  options: SyntaxLiteral[],
  current: Maybe<string>,
): Maybe<string> {
  if (options.length === 0) {
    return null
  }

  const currentIndex = getOptionIndex(options, current)
  if (current === null || currentIndex === null) {
    return options[0].literal
  }

  const nextIndex = currentIndex - 1
  return nextIndex >= 0
    ? options[nextIndex].literal
    : options[options.length - 1].literal
}

export function findNext(
  options: SyntaxLiteral[],
  current: Maybe<string>,
): Maybe<string> {
  if (options.length === 0) {
    return null
  }

  const currentIndex = getOptionIndex(options, current)
  if (current === null || currentIndex === null) {
    return options[0].literal
  }

  const nextIndex = currentIndex + 1
  return nextIndex >= options.length
    ? options[0].literal
    : options[nextIndex].literal
}
