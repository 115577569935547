import { QueryClient, useMutation } from 'react-query'
import { useMemo } from 'react'
import {
  deleteReport,
  invalidateReportsListCache,
} from '@fintastic/web/data-access/generic-report'
import { DisplayErrorCallback, DisplaySuccessCallback } from '../types'

export type DeleteOperationApi = Pick<
  ReturnType<typeof useDeleteOperation>,
  'error' | 'mutateAsync' | 'isLoading'
>

export type DeleteOperationParams = {
  versionId: string
}

export type DeleteOperationDependencies = {
  openConfirmationModal: (params: { reportId: string }) => void
  closeConfirmationModal: () => void
  queryClient: QueryClient
  displayError: DisplayErrorCallback
  displaySuccess: DisplaySuccessCallback
}

export const useDeleteOperation = (
  params: DeleteOperationParams,
  deps: DeleteOperationDependencies,
) =>
  useMutation({
    mutationFn: useMemo(() => makeOperationFn(params, deps), [deps, params]),
  })

export const makeOperationFn =
  (params: DeleteOperationParams, deps: DeleteOperationDependencies) =>
  async (variables: { confirmed?: true; reportId: string }) => {
    if (!variables.confirmed) {
      deps.openConfirmationModal({ reportId: variables.reportId })
      return
    }

    try {
      await deleteReport(params.versionId, variables.reportId)
    } catch (error) {
      deps.displayError('Failed to delete Report')
      throw error
    }

    deps.displaySuccess('Report deleted successfully.')
    deps.closeConfirmationModal()
    invalidateReportsListCache(deps.queryClient, params.versionId)
  }
