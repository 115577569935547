/**
 Keep here the hooks attached to the current duck.
 In perfect case there should be only one way for components to interact with a duck - it's thought the hooks.
 Our hooks it's like a public API for components, and they shouldn't use selectors or action creators directly.
 */

import { selectAuthTenantId, selectAuthUserInfo } from './index'
import { useSelector } from 'react-redux'

export function useAuthUserInfo(): ReturnType<typeof selectAuthUserInfo> {
  return useSelector(selectAuthUserInfo)
}

export function useAuthTenantId(): ReturnType<typeof selectAuthTenantId> {
  return useSelector(selectAuthTenantId)
}
