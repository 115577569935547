import { styled, Box, Typography } from '@mui/material'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledIcon = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-top: 4px;

  width: 16px;

  svg {
    width: 100%;
    height: auto;
  }
`

export const StyledText = styled(Typography)``
