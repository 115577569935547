import { Maybe } from '@fintastic/shared/util/types'
import { useQueryClient } from 'react-query'
import { useCallback } from 'react'
import { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import {
  invalidateVersionUserLockerCache,
  switchVersionUserLockers,
} from '@fintastic/web/data-access/versions'
import { duplicateListRows } from '@fintastic/web/data-access/metrics-and-lists'
import {
  TaskRequestErrorHandler,
  usePusherDataUpdateTaskOperation,
} from '@fintastic/web/data-access/metric-data-editing'
import { toast } from 'react-hot-toast/headless'
import { AxiosError } from 'axios'

export const useDuplucateRowsOperation = (
  versionId: Maybe<string>,
  listId: string,
  rowDimensionId: Maybe<string>,
) => {
  const queryClient = useQueryClient()

  const requestFunction = useCallback(
    async (rows: BaseGridRow[]) => {
      if (!versionId) {
        throw new Error(
          'versionId is not defined for useDuplucateRowsOperation',
        )
      }

      switchVersionUserLockers(queryClient, [versionId], 'calc')
      const response = await duplicateListRows(
        versionId,
        listId,
        rows.map((row) => row[rowDimensionId || ''] as string),
      )
      return response.data
    },
    [listId, queryClient, rowDimensionId, versionId],
  )

  const handleError = useCallback<TaskRequestErrorHandler>(
    async (errorOrEvent) => {
      let errorMessage = 'Failed to duplicate line(s). Please try again.'

      if (errorOrEvent instanceof AxiosError) {
        if (errorOrEvent.response?.status === 403) {
          errorMessage =
            'You do not have the necessary permissions to duplicate these line(s).'
        } else if (errorOrEvent.message) {
          errorMessage = errorOrEvent.message
        }
      }
      toast.error(errorMessage)

      if (versionId) {
        await invalidateVersionUserLockerCache(queryClient, versionId)
      }
    },
    [queryClient, versionId],
  )

  return usePusherDataUpdateTaskOperation<[rows: BaseGridRow[]]>({
    requestFunction,
    onError: handleError,
  })
}
