import { CommentsThreadsBulkItem } from '../types'
import { useQuery } from 'react-query'
import { threadsBulkByPageQueryKey } from '../utils/query-key'
import { getAllThreadsBulk } from '../api/api'

export const useAllThreadsBulk = (page_key: string, enableRefetch = false) => {
  const result = useQuery<CommentsThreadsBulkItem[]>(
    threadsBulkByPageQueryKey(page_key),
    async () => {
      const response = await getAllThreadsBulk(page_key)
      return response.data.result
    },
    {
      keepPreviousData: true,
      refetchOnMount: enableRefetch,
      retryOnMount: enableRefetch,
      refetchOnWindowFocus: enableRefetch,
      refetchOnReconnect: true,
      refetchInterval: enableRefetch ? COMMENTS_POLLING_INTERVAL : undefined,
    },
  )

  return result
}

const COMMENTS_POLLING_INTERVAL = 1000 * 60
