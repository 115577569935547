import { useEffect, useRef } from 'react'
import { FormulaValidationCallback } from './types'
import { useContextParameter } from '../context-parameter'

export function useValidationCallbacks(
  isValid: boolean,
  isWaiting: boolean,
  versionId: string,
  formula: string,
  onValidationFinished?: FormulaValidationCallback,
) {
  const contextParam = useContextParameter()

  const formulaRef = useRef(formula)
  formulaRef.current = formula
  const contextParamRef = useRef(contextParam)
  contextParamRef.current = contextParam

  useEffect(() => {
    if (isWaiting) {
      return
    }
    onValidationFinished?.(
      versionId,
      formulaRef.current,
      !isValid,
      contextParamRef.current,
    )
  }, [isValid, isWaiting, onValidationFinished, versionId])
}
