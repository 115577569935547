import {
  styled,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material'

export const StyledRoot = styled(Box)(({ theme }) => ({}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(1, 1.5),
}))

export const StyledSearch = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 1),
}))

export const StyledList = styled(List)(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',

  padding: 0,

  maxHeight: 260,
}))

export const StyledListItem = styled(ListItem)(({ theme }) => ({}))

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({}))
