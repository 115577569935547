import { sortBy } from 'lodash'
import { PeriodResolution } from '../types'

export const RESOLUTIONS_ORDERED_MAP: Record<PeriodResolution, number> = {
  day: 0,
  week: 1,
  month: 2,
  quarter: 3,
  half_year: 4,
  year: 5,
}

export const RESOLUTIONS_ORDERED_ENTRIES = sortBy(
  Object.entries(RESOLUTIONS_ORDERED_MAP),
  ([, weight]) => weight,
) as Array<[periodResolution: PeriodResolution, weight: number]>

export const RESOLUTIONS_ORDERED = RESOLUTIONS_ORDERED_ENTRIES.map(
  ([periodResolution]) => periodResolution,
)

export const DEFAULT_PERIOD_RESOLUTION = 'month'
export const MOST_GRANULAR_RESOLUTION = 'day'
