import {
  ColumnEntityDefinition,
  ListEntityDefinition,
  MetricEntityDefinition,
} from '../../types'

export class CommonListOrMetricWrapperImplementation {
  readonly _rawData:
    | MetricEntityDefinition
    | ListEntityDefinition
    | ColumnEntityDefinition

  constructor(
    data:
      | MetricEntityDefinition
      | ListEntityDefinition
      | ColumnEntityDefinition,
  ) {
    this._rawData = data
  }

  get id(): string {
    return this._rawData.id
  }

  get label(): string {
    return this._rawData.label
  }

  get isCalculated(): boolean {
    return this._rawData.source === 'calculated'
  }
}
