import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { StartListCreationPayload } from '../../types'
import { createEmptyListObject } from '../../../abstract-data-types/list/constructors'
import { ListWrapper } from '../../../abstract-data-types/list'
import { EditableList } from '../../../types'

// @todo add tests
export const startListCreation = (
  state: State,
  { payload }: PayloadAction<StartListCreationPayload>,
) => {
  state.type = 'list'
  state.metric = null
  state.flow = 'creation'
  state.list = createEmptyListObject(
    payload.name,
    payload.source,
  ) as EditableList

  const wrappedList = new ListWrapper(state.list)
  if (wrappedList.isCalculated()) {
    state.list.formulaInvalid = true
  }
}
