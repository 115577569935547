import { useCallback, useMemo, useState } from 'react'

export type MasterDetailTab = 'grid' | 'formula'

export function useMasterDetailTabsState() {
  const [masterDetailsTabs, setMasterDetailsTabs] = useState<
    Record<string, MasterDetailTab | undefined>
  >({})

  const handleSwitchMasterDetailToTab = useCallback(
    (gridId: string, tab: MasterDetailTab) => {
      setMasterDetailsTabs((current) => ({ ...current, [gridId]: tab }))
    },
    [],
  )

  const getMasterDetailTab = useCallback(
    (gridId: string): MasterDetailTab => masterDetailsTabs[gridId] || 'grid',
    [masterDetailsTabs],
  )

  return useMemo(
    () => ({
      handleSwitchMasterDetailToTab,
      getMasterDetailTab,
    }),
    [getMasterDetailTab, handleSwitchMasterDetailToTab],
  )
}
