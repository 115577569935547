export const vintageTheme = {
  color: [
    '#c12e34',
    '#f1bf02',
    '#0098d9',
    '#2b821d',
    '#005eaa',
    '#30b9c8',
    'rgba(198,175,97,0.62)',
    'rgba(50,164,135,0.64)',
  ],
}
