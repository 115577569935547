import { useCallback } from 'react'
import { ChangeListTimeDimensionAction } from '../types'
import {
  MetricOrListEditingFlow,
  useListsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { DataContextValue } from '../../data/data-context'

export function useChangeListTimeDimensionFlow(
  { dimensionsList }: DataContextValue,
  flow: Maybe<MetricOrListEditingFlow>,
) {
  const { changeListTimeDimension } = useListsActions()

  const handleAction = useCallback<ChangeListTimeDimensionAction>(
    (dimensionId) => {
      if (flow === null || dimensionsList === null) {
        return
      }

      changeListTimeDimension({
        dimensionId: dimensionId ?? null,
        allDimensions: dimensionsList,
      })
    },
    [changeListTimeDimension, dimensionsList, flow],
  )

  return {
    action: handleAction,
  }
}
