import { useCallback, useMemo, useRef } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { CaretMovementReason } from '../caret/types'
import { CaretMoveCallback, EditableApi } from './types'
import { Token } from '../tokens/types'

// @todo add tests
export function useCaret({
  inputValue,
  editableApi,
  onCaretMove,
}: {
  inputValue: Token[]
  editableApi: EditableApi
  onCaretMove: CaretMoveCallback
}) {
  const prevCaretPosition = useRef(-1)
  const trackCaretPosition = useCallback(
    (reason: Maybe<CaretMovementReason>) => {
      const position = editableApi.getState().position.position
      if (position !== prevCaretPosition.current) {
        prevCaretPosition.current = position
        onCaretMove(position, reason)
      }
    },
    [editableApi, onCaretMove],
  )

  const moveToTheEnd = useCallback(() => {
    editableApi.move(
      inputValue.reduce<number>((pos, token) => pos + token.text.length, 0),
    )
    trackCaretPosition('other')
  }, [editableApi, inputValue, trackCaretPosition])

  const moveTo = useCallback(
    (position: number, reason: CaretMovementReason = 'other') => {
      editableApi.move(position)
      trackCaretPosition(reason)
    },
    [editableApi, trackCaretPosition],
  )

  return useMemo(
    () => ({
      trackCaretPosition,
      moveToTheEnd,
      moveTo,
    }),
    [moveTo, moveToTheEnd, trackCaretPosition],
  )
}
