import { AddLinesDataPayload } from './types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'

export const saveNewRows = async (
  versionId: string,
  listId: string,
  data: AddLinesDataPayload,
) => axios.post<AddLinesDataPayload>(
    endpoints.newRows(versionId, listId),
    data,
  )
