import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import {
  StyledSwitchLabel,
  StyledSwitchLabelCont,
  StyledSwitchRoot,
  StyledSwitchSwitcher,
} from './GroupButtonField.styled'
import { Maybe } from '@fintastic/shared/util/types'

export type GroupButtonFieldProps<T extends string> = {
  value: T
  title?: string
  onChange: (value: Maybe<T>) => void
  options: Array<{ title: string; value: T }>
  disabled?: boolean
}

export const GroupButtonField = <T extends string>({
  value,
  title,
  onChange,
  options,
  disabled,
}: GroupButtonFieldProps<T>) => {
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newMode: Maybe<T>,
  ) => {
    if (newMode && !disabled) {
      onChange(newMode)
    }
  }

  return (
    <StyledSwitchRoot>
      {Boolean(title) && (
        <StyledSwitchLabelCont>
          <StyledSwitchLabel disabled={disabled}>{title}</StyledSwitchLabel>
        </StyledSwitchLabelCont>
      )}

      <StyledSwitchSwitcher>
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={handleChange}
          size="small"
          disabled={disabled}
        >
          {options.map((btn) => (
            <ToggleButton value={btn.value as string} key={'k_' + btn.value}>
              {btn.title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </StyledSwitchSwitcher>
    </StyledSwitchRoot>
  )
}
