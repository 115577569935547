import React, { useCallback, useContext, useMemo } from 'react'
import { baseGridContext } from '@fintastic/shared/ui/grid-framework'
import {
  PanelToolbarIconButton,
  PanelToolbarIconButtonProps,
} from '@fintastic/shared/ui/panel-framework'
import { SvgIconProps } from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import SegmentIcon from '@mui/icons-material/Segment'

const defaultIconProps: SvgIconProps = {
  fontSize: 'medium',
}

export type ToggleGroupingButtonProps = Omit<
  PanelToolbarIconButtonProps,
  'children' | 'title' | 'onClick'
> & {
  iconProps?: SvgIconProps
}

export const ToggleGroupingButton = ({
  iconProps = defaultIconProps,
  ...rest
}: ToggleGroupingButtonProps): JSX.Element | null => {
  const gridContext = useContext(baseGridContext)
  const [isGrouped, setGroupingState] = gridContext.groupingState

  const title = isGrouped ? 'Flatten' : 'Group'
  const disabledTitle = 'Grouping is not allowed'

  const mergedIconProps = useMemo(
    () => ({
      ...defaultIconProps,
      ...iconProps,
    }),
    [iconProps],
  )

  const handleClick = useCallback(() => {
    if (setGroupingState) {
      setGroupingState((s) => !s)
    }
  }, [setGroupingState])

  return (
    <PanelToolbarIconButton
      data-testid="toggleTableGrouping"
      title={title}
      disabledTitle={disabledTitle}
      {...rest}
      onClick={handleClick}
    >
      {isGrouped ? (
        <ListIcon {...mergedIconProps} />
      ) : (
        <SegmentIcon {...mergedIconProps} />
      )}
    </PanelToolbarIconButton>
  )
}
