import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  position: relative;
  margin-bottom: auto;

  width: 100%;

  text-align: left;
`

export const StyledList = styled('div')`
  position: relative;

  padding: 1px 12px;
`

export const StyledListElementContainer = styled('div')`
  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 16px;
  }
`
