import { ThemeComponents } from './types'
import { styleKit } from '../styleKit'
import { switchClasses } from '@mui/material/Switch'

const sizes = {
  medium: {
    root: {
      width: 36,
      height: 20,
    },
    thumb: {
      width: 18,
      height: 18,
    },
  },
} as const

const SWITCH_BASE_PADDING = 1

export const MuiSwitch: ThemeComponents['MuiSwitch'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      'padding': 0,
      'display': 'flex',
      'width': sizes.medium.root.width,
      'height': sizes.medium.root.height,
      'borderRadius': sizes.medium.root.height / 2,
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    }),

    thumb: ({ theme }) => ({
      boxShadow: 'none',
      width: sizes.medium.thumb.width,
      height: sizes.medium.thumb.height,
      borderRadius: '50%',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    }),

    switchBase: ({ theme, ownerState }) => ({
      'padding': SWITCH_BASE_PADDING,
      '&:hover': {
        background: 'transparent',
      },
      [`&.${switchClasses.checked}`]: {
        transform: `translateX(${
          sizes.medium.thumb.width - SWITCH_BASE_PADDING * 2
        }px)`,
        color: '#fff',
        [`& + .${switchClasses.track}`]: {
          opacity: 1,
        },
      },
      [`&.${switchClasses.disabled}, &.${switchClasses.checked}.${switchClasses.disabled}`]:
        {
          color: '#fff',
          opacity: 0.8,
        },
      [`&.${switchClasses.disabled} + .${switchClasses.track}, &.${switchClasses.checked}.${switchClasses.disabled} + .${switchClasses.track}`]:
        {
          opacity: 0.6,
        },
    }),

    track: ({ theme }) => ({
      borderRadius: sizes.medium.root.height / 2,
      opacity: 1,
      backgroundColor: styleKit.colors.general.grey,
      boxSizing: 'border-box',
    }),

    sizeMedium: {},

    sizeSmall: {
      // @todo unimplemented
    },

    colorPrimary: {
      [`&.Mui-checked + .${switchClasses.track}`]: {
        background: styleKit.colors.orange.dark,
      },
    },
  },
}
