import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import type { PeriodResolution } from '@fintastic/web/util/period-selector'
import { DimensionValueId, VersionTimeDimension } from '../types'
import * as selectors from './common-selectors'
import * as helpers from './common-helpers'

export class TimeDimensionWrapper
  implements ImmutableADTWrapper<VersionTimeDimension>
{
  _rawData: VersionTimeDimension

  constructor(d: VersionTimeDimension) {
    this._rawData = d
  }

  unwrap(): VersionTimeDimension {
    return this._rawData
  }

  get id() {
    return selectors.id(this._rawData)
  }

  get label() {
    return selectors.label(this._rawData)
  }

  get type() {
    return selectors.type(this._rawData)
  }

  get orderedValuesIds(): DimensionValueId[] {
    return this._rawData.ordered_values
  }

  get resolution(): PeriodResolution {
    return this._rawData.resolution
  }

  resolveValueLabel(valueId: DimensionValueId) {
    return helpers.resolveValueLabel(this._rawData, valueId)
  }
}
