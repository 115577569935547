import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

export type ExitSettingsEditingModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
  isMetric: boolean
}

// @todo remove lists support
export const ExitSettingsEditingModal: React.FC<
  ExitSettingsEditingModalProps
> = ({ isMetric, onRequestClose, isOpen, onConfirm }) => (
  <ConfirmationPopup
    open={isOpen}
    onRequestClose={onRequestClose}
    onConfirm={onConfirm}
    title={`Cancel ${isMetric ? 'Metric' : 'List'} editing?`}
    description="Unsaved data and settings will be lost."
  >
    <ConfirmationPopup.DeclineButton variant="contained" color="primary">
      Continue editing
    </ConfirmationPopup.DeclineButton>
    <ConfirmationPopup.ConfirmButton sx={{ ml: 1 }}>
      Yes, Cancel
    </ConfirmationPopup.ConfirmButton>
  </ConfirmationPopup>
)
