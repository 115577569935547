import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { saveChanges } from '../ducks'

export function useSaveChanges() {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(saveChanges())
  }, [dispatch])
}
