import React, { forwardRef } from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'

export type LoadingButtonProps = ButtonProps & {
  loading?: boolean
  loadingText?: string
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (
    {
      loading = false,
      children,
      loadingText = '',
      startIcon,
      endIcon,
      disabled,
      ...props
    },
    ref,
  ) => (
    <Button
      ref={ref}
      {...props}
      startIcon={loading ? undefined : startIcon}
      endIcon={loading ? undefined : endIcon}
      disabled={loading ? true : disabled}
    >
      {loading ? (
        <>
          <CircularProgress
            sx={{ color: 'inherit', marginRight: loadingText ? 1 : 0 }}
            size={20}
          />
          {loadingText}
        </>
      ) : (
        children
      )}
    </Button>
  ),
)
