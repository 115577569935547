import { createContext, useContext } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { FocusedToken } from '../focused-token/types'
import { SyntaxLiteral } from '../syntax/types'
import { NavigationApi } from './useAutocomplete/navigation/types'

export type AutocompleteContextValue = {
  editingToken: Maybe<FocusedToken>
  popperTarget: Maybe<HTMLElement>
  isShow?: boolean
  suggestions: SyntaxLiteral[]
  navigation: Maybe<NavigationApi>
}

export const autocompleteContext = createContext<AutocompleteContextValue>({
  editingToken: null,
  popperTarget: null,
  isShow: false,
  suggestions: [],
  navigation: null,
})

export const { Provider: AutocompleteContextProvider } = autocompleteContext

export function useAutocompleteContext() {
  return useContext(autocompleteContext)
}
