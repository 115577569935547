import React from 'react'
import { Filter } from '@fintastic/web/util/filters'
import { DimensionFilterGroup } from '../shared/DimensionFilterGroup'
import { NumericFilterGroup } from '../shared/NumericFilterGroup'
import { TextFilterGroup } from '../shared/TextFilterGroup'
import type { Maybe } from '@fintastic/shared/util/types'
import { DateFilterGroup } from '../shared/DateFilterGroup'

export const FilterGroupContainer: React.FC<{
  filter: Filter
  timeDimensionValueId: Maybe<string>
  disableClear?: boolean
}> = ({ filter, timeDimensionValueId, disableClear }) => {
  if (filter.type === 'dimension') {
    return (
      <DimensionFilterGroup
        filter={filter}
        timeDimensionValueId={timeDimensionValueId}
      />
    )
  }

  if (filter.type === 'numeric') {
    return (
      <NumericFilterGroup
        filter={filter}
        timeDimensionValueId={timeDimensionValueId}
        disableClear={disableClear}
      />
    )
  }

  if (filter.type === 'string') {
    return (
      <TextFilterGroup
        filter={filter}
        timeDimensionValueId={timeDimensionValueId}
        disableClear={disableClear}
      />
    )
  }

  if (filter.type === 'date') {
    return (
      <DateFilterGroup
        filter={filter}
        timeDimensionValueId={timeDimensionValueId}
        disableClear={disableClear}
      />
    )
  }

  return null
}

FilterGroupContainer.displayName = 'FilterGroupContainer'
