import { useSelectedCellAggregationContext } from '@fintastic/web/util/selected-cell-aggregation'
import { useMemo } from 'react'
import { SelectedCellAggregationAgGridContext } from '../types'

export const useSelectedCellAggregationAgGridContext =
  (): SelectedCellAggregationAgGridContext => {
    const context = useSelectedCellAggregationContext()

    return useMemo<SelectedCellAggregationAgGridContext>(
      () => ({
        setSelectedCells: context.setSelectedCells,
      }),
      [context.setSelectedCells],
    )
  }
