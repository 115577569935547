import { Maybe } from '@fintastic/shared/util/types'
import {
  ChartColumnData,
  ChartDisplaySettings,
} from '@fintastic/web/feature/charts'
import { EChartsOption } from 'echarts'
import { extractChartData, prepareOptions } from './process'
import { MetricMetadata } from '@fintastic/web/util/metrics-and-lists'

export const encodeForLines = (
  data?: ChartColumnData,
  metadata?: Maybe<MetricMetadata>,
  displaySettings?: ChartDisplaySettings,
): Maybe<EChartsOption> => {
  if (!data || data.length <= 1) {
    return null
  }
  const { headers, meaningRows } = extractChartData(data)

  const series: EChartsOption['series'] = meaningRows.map((row) => ({
    name: row[0],
    type: 'line',
    data: row.toSpliced(0, 1) as number[],
    label: {
      show: false,
      position: 'top',
    },
  }))

  return prepareOptions(headers, series, metadata, displaySettings)
}
