import { styled, Box, IconButton } from '@mui/material'
import { layoutConfig } from '../../layout-config'

export const StyledTopbarRoot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: layoutConfig.sidebar.bar.width,
  right: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: `0 ${layoutConfig.page.paddingRight}px 0 ${layoutConfig.page.paddingLeft}px`,

  height: layoutConfig.topbar.height,

  background: theme.palette.common.white,
  border: '1px solid transparent',
  borderBottomColor: theme.palette.divider,
}))

export const StyledDisableOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: grayscale(100%);
  z-index: 2;
  animation: fadeOverlayIn 0.4s ease;

  @keyframes fadeOverlayIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const StyledTopbarSettings = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  width: '100%',
}))

export const StyledTopbarSettingsItem = styled(Box)(({ theme }) => ({
  marginLeft: 24,
}))

export const StyledTopbarDiscussionsButton = styled(IconButton)(
  ({ theme }) => ({
    'svg path': {
      transition: `${theme.transitions.duration.short}ms`,
    },
    '&.active svg path': {
      fill: theme.palette.primary.main,
    },
  }),
)
