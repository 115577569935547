import { QueryClient } from 'react-query'
import { isListV2QueryKey } from './utils/query-keys'

const invalidateAll = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({
    predicate: (query) => isListV2QueryKey(query.queryKey),
  })

const invalidateVersion = (queryClient: QueryClient, versionId: string) =>
  queryClient.invalidateQueries({
    predicate: (query) => isListV2QueryKey(query.queryKey, versionId),
  })

const invalidateList = (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
) =>
  queryClient.invalidateQueries({
    predicate: (query) => isListV2QueryKey(query.queryKey, versionId, listId),
  })

const invalidateListColumn = (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
  metricId: string,
) =>
  queryClient.invalidateQueries({
    predicate: (query) =>
      isListV2QueryKey(query.queryKey, versionId, listId, metricId),
  })

const refetchListColumn = (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
  metricId: string,
) =>
  queryClient.refetchQueries({
    predicate: (query) =>
      isListV2QueryKey(query.queryKey, versionId, listId, metricId),
  })

export const invalidateV2MetricCache = {
  invalidateAll,
  invalidateVersion,
  invalidateList,
  invalidateListColumn,
  refetchListColumn,
}
