import React from 'react'
import { StyledPageLayoutContentFullHeightRoot } from './PageLayoutContentFullHeight.styled'
import { usePageLayoutContext } from '../../contexts/page-layout-context'

export type PageLayoutContentFullHeightProps = {
  children: React.ReactNode
}

export const PageLayoutContentFullHeight: React.FC<
  PageLayoutContentFullHeightProps
> = ({ children }) => {
  const { disablePaddingVertical, disablePaddingBottom, disablePaddingTop } =
    usePageLayoutContext()

  return (
    <StyledPageLayoutContentFullHeightRoot
      disablePaddingVertical={disablePaddingVertical}
      disablePaddingBottom={disablePaddingBottom}
      disablePaddingTop={disablePaddingTop}
    >
      {children}
    </StyledPageLayoutContentFullHeightRoot>
  )
}
