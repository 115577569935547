import React from 'react'
import {createSvgIcon} from '@mui/material'


export const SuccessIcon = createSvgIcon(
  <>
    <path d="M16.2014 8.84493C16.5044 8.57007 16.5272 8.10161 16.2524 7.79861C15.9775 7.4956 15.509 7.47279 15.206 7.74766C13.9824 8.85768 13.0705 9.84869 12.3019 11.0483C11.6998 11.9881 11.1981 13.0365 10.6987 14.3394L8.82809 12.4103C8.5433 12.1166 8.07434 12.1094 7.78065 12.3941C7.48696 12.6789 7.47974 13.1479 7.76453 13.4416L10.4581 16.2194C10.637 16.4039 10.8988 16.4825 11.1498 16.427C11.4007 16.3715 11.605 16.19 11.6895 15.9472C12.3143 14.1524 12.8726 12.9037 13.5493 11.8475C14.2231 10.7958 15.0344 9.90359 16.2014 8.84493Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2ZM3.48148 12C3.48148 7.29535 7.29535 3.48148 12 3.48148C16.7047 3.48148 20.5185 7.29535 20.5185 12C20.5185 16.7047 16.7047 20.5185 12 20.5185C7.29535 20.5185 3.48148 16.7047 3.48148 12Z"/>
  </>,
  'Success',
)


