import { QueryKey, useQuery, UseQueryResult } from 'react-query'
import { getBoardAccess } from '../api'
import { BoardAccess } from '../types'

export const boardsAccessQueryKeyGetter = (boardId: string): QueryKey =>
  `board/${boardId}/access`

export function useLoadAccessForBoard(
  boardId: string,
): UseQueryResult<BoardAccess> {
  return useQuery(boardsAccessQueryKeyGetter(boardId), async () => {
    const response = await getBoardAccess(boardId)
    return response.data
  })
}
