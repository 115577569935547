import {
  Autocomplete,
  autocompleteClasses,
  inputBaseClasses,
  outlinedInputClasses,
  styled,
} from '@mui/material'
import { Currency } from '@fintastic/shared/data-access/currencies'

export const StyledCurrencySelect = styled(
  Autocomplete<Partial<Currency>, boolean, boolean>,
)`
  .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall} {
    padding-top: 5px;
  }

  .${autocompleteClasses.endAdornment} button {
    padding: 0;
    width: 26px;
    height: 28px;
    background: none;
  }

  .${autocompleteClasses.popupIndicator} {
    outline: none;
    border: none;
    background: none;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .${autocompleteClasses.popupIndicator}:hover {
    background: none;
  }
`
