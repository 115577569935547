export const springTheme = {
  color: [
    '#3965ff',
    '#ffaacc',
    '#fac858',
    '#ee6666',
    '#73c0de',
    '#3ba272',
    '#fc8452',
    '#9a60b4',
  ],
}
