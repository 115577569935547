import { Maybe } from '@fintastic/shared/util/types'
import { VersionEntityWithVersionId } from '@fintastic/web/data-access/versions'
import { CalendarDatePickerConfig } from '../types'
import { useMemo } from 'react'
import {
  keepOnlyAllowedTimeDimensions,
  keepOnlyAllowedTimeResolutions,
  makeAllowedTimeDimsMap,
} from '../utils'
import {
  TimeDimensionId,
  VersionTimeDimension,
  isVersionTimeDimension,
} from '@fintastic/web/util/dimensions'
import { isEmpty } from 'lodash'

/**
 * Limit date picker config to selected entities
 * Like if dimension is not present in any of version, it's cut off
 */
export const useCalendarConfigForSelectedEntities = ({
  entitiesPerVersion,
  calendarDatePickerConfig,
  baseTimeDimensionId = null,
}: UseCalendarConfigForSelectedEntitiesParams) => {
  const allowedDimsMap = useMemo(() => {
    if (!entitiesPerVersion) {
      return null
    }

    return makeAllowedTimeDimsMap(
      entitiesPerVersion.flatMap<VersionTimeDimension>(
        ({ entities }) =>
          entities?.dimensions?.filter(isVersionTimeDimension) ?? [],
      ),
    )
  }, [entitiesPerVersion])

  const datePickerConfigForSelectedEntities = useMemo<
    Maybe<CalendarDatePickerConfig>
  >(() => {
    if (!calendarDatePickerConfig || isEmpty(allowedDimsMap)) {
      return null
    }

    // Cut off date picker config according to entities selected
    return keepOnlyAllowedTimeDimensions(
      keepOnlyAllowedTimeResolutions(
        calendarDatePickerConfig,
        baseTimeDimensionId,
      ),
      allowedDimsMap,
    )
  }, [allowedDimsMap, baseTimeDimensionId, calendarDatePickerConfig])

  return datePickerConfigForSelectedEntities
}

export type UseCalendarConfigForSelectedEntitiesParams = {
  entitiesPerVersion: Maybe<VersionEntityWithVersionId[]>
  calendarDatePickerConfig: Maybe<CalendarDatePickerConfig>
  baseTimeDimensionId?: Maybe<TimeDimensionId>
}
