import {
  CellDataType,
  cellDataTypeIsInteger,
  cellDataTypeIsPercentage,
  cellDataTypeIsPureNumeric,
  removeSigns,
} from '../hooks/clipboard-cell-utils'

const pointsCounter = /\./g

export const getCharIndexFromEnd = (str: string, letter: string): number => {
  if (!str || !letter) {
    return -1
  }

  if (letter.length > 1) {
    return -1
  }

  if (str.indexOf(letter) === -1) {
    return -1
  }
  return str.length - str.lastIndexOf(letter) - 1
}

const extractValueFromCommaString = (maybeNumber: string) => {
  const pointPos = maybeNumber.indexOf('.')
  const commaPos = maybeNumber.indexOf(',')
  const hasComma = commaPos !== -1
  const hasPoint = pointPos !== -1

  let normalizedString = maybeNumber

  if (!hasComma) {
    return normalizedString
  }

  const maybeNumberPositive = removeSigns(maybeNumber)

  // 1,333,444.2 -> 1333444.2
  if (hasPoint) {
    if (pointPos < commaPos) {
      // 100.222,00
      // 100.200.400,20
      normalizedString = maybeNumber.replaceAll('.', '')
      normalizedString = normalizedString.replaceAll(',', '.')
    } else {
      // 100,200.00
      normalizedString = maybeNumber.replaceAll(',', '')
    }
  } else {
    // 100,200
    if (getCharIndexFromEnd(maybeNumberPositive, ',') === 3) {
      normalizedString = maybeNumber.replaceAll(',', '')
    } else {
      // 0,12
      normalizedString = maybeNumber.replaceAll(',', '.')
    }
  }

  normalizedString = normalizedString.replaceAll(',', '')

  return normalizedString
}

export const replaceAtString = (
  str: string,
  index: number,
  replacement: string,
) => {
  if (!str || index < 0 || index >= str.length || !replacement) {
    return str
  }

  const originalLength = str.length

  return (
    str.substring(0, index) +
    replacement +
    str.substring(index + replacement.length)
  ).substring(0, originalLength)
}

// for USD values like $60,000 always use point as decimal separator and comma as thousands separator
const extractValueFromUSD = (maybeNumber: string) => {
  const stringWithoutGarbage = maybeNumber.replace(/[^\d.,]/g, '') // numbers and "," and "."

  const lastCommaIndexFromEnd = getCharIndexFromEnd(stringWithoutGarbage, ',')

  if (lastCommaIndexFromEnd < 3) {
    // invalid separator position like $2000,00
    return replaceAtString(maybeNumber, maybeNumber.lastIndexOf(','), '.')
      .replaceAll(',', '')
      .replaceAll(' ', '')
  }

  return maybeNumber.replaceAll(',', '').replaceAll(' ', '')
}

const cleanMultiplePoints = (maybeNumber: string) => {
  if (
    maybeNumber.includes('.') &&
    (maybeNumber.match(pointsCounter) || []).length > 1
  ) {
    // 100.200.300.1
    return maybeNumber.replaceAll('.', '')
  }

  return maybeNumber
}

export const parseFormattedNumber = (
  maybeNumber: string,
  targetDataType: CellDataType = 'NUMERIC',
): number => {
  let normalizedString: string

  const negativeMultiplier =
    (maybeNumber.startsWith('(') && maybeNumber.endsWith(')')) ||
    maybeNumber.startsWith('-')
      ? -1
      : 1

  const isPercentage = maybeNumber.includes('%')
  const isUSD = maybeNumber.includes('$')

  normalizedString = isUSD
    ? extractValueFromUSD(maybeNumber)
    : extractValueFromCommaString(maybeNumber)

  normalizedString = cleanMultiplePoints(normalizedString)

  // remove anything except 1-9 and .
  const number = parseFloat(normalizedString.replace(/[^\d.]/g, ''))
  if (!Number.isFinite(number)) {
    return Number.NaN
  }

  if (isPercentage) {
    // pasting 100.23%, value with % sign
    // to numeric: Divide by 100 and remove % sign and paste

    if (cellDataTypeIsPureNumeric(targetDataType)) {
      // numeric, integer, currency
      let value = (number / 100) * negativeMultiplier
      if (cellDataTypeIsInteger(targetDataType)) {
        value = Math.trunc(value)
      }
      return value
    }

    if (cellDataTypeIsPercentage(targetDataType)) {
      // percent, percent_integer
      let value = (number / 100) * negativeMultiplier
      if (cellDataTypeIsInteger(targetDataType)) {
        value = Math.trunc(value * 100) / 100
      }
      return value
    }
  }

  // not a percentage value, generic number
  if (cellDataTypeIsPercentage(targetDataType)) {
    // Multiply by 100, add % sign and paste (10.23>1023.00%)
    // but *100 is automatic for cell type
    let value = number * negativeMultiplier
    if (cellDataTypeIsInteger(targetDataType)) {
      value = Math.trunc(value * 100) / 100
    }
    return value
  }

  // duplicate logic
  let value = number * negativeMultiplier
  if (cellDataTypeIsInteger(targetDataType)) {
    value = Math.trunc(value * 100) / 100
  }
  return value
}
