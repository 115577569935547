import { SyntaxLiteral } from '../../../syntax/types'
import { DEFAULT_RESULTS_LIMIT, MIN_QUERY_LENGTH } from './const'

export function getSuggestions(
  literals: SyntaxLiteral[],
  query: string,
  resultsLimit = DEFAULT_RESULTS_LIMIT,
): SyntaxLiteral[] {
  if (query.trim().length < MIN_QUERY_LENGTH) {
    return []
  }

  const s = query.toLocaleUpperCase()

  const results: Array<{ weight: number; literal: SyntaxLiteral }> = []
  for (let i = 0; i < literals.length; i++) {
    const indexOf = literals[i].upperCaseLiteral.indexOf(s)
    if (indexOf === -1) {
      continue
    }

    results.push({
      weight: indexOf, // smallest weight closer to the first place
      literal: literals[i],
    })
  }

  results.sort(
    (a, b) =>
      // sorting DESC because weight is inverted
      a.weight - b.weight,
  )

  return results.slice(0, resultsLimit).map(({ literal }) => literal)
}
