import { toast } from 'react-hot-toast/headless'
import { addListRows } from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { useCallback } from 'react'
import { usePusherDataUpdateTaskOperation } from '@fintastic/web/data-access/metric-data-editing'
import { TaskRequestErrorHandler } from '@fintastic/web/data-access/metric-data-editing'

export const useAddNewRowsTaskOperation = (
  versionId: Maybe<string>,
  listId: string,
) => {
  const handleAddNewLineRequest = useCallback(
    async (numberOfRows: number) => {
      if (!versionId) {
        throw new Error(
          'versionId is not defined for useAddNewRowsTaskOperation',
        )
      }
      return (await addListRows(versionId, listId, numberOfRows)).data
    },
    [listId, versionId],
  )

  const handleError = useCallback<TaskRequestErrorHandler>((errorOrEvent) => {
    toast.error('Failed to add line(s). Please try again.')
  }, [])

  return usePusherDataUpdateTaskOperation<[numberOfRows: number]>({
    requestFunction: handleAddNewLineRequest,
    onError: handleError,
  })
}
