import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { AddColumnPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'
import { EditableMetric } from '../../../types'

// @todo add tests for formula-related staff
export const addColumn = (
  uncheckedState: State,
  { payload }: PayloadAction<AddColumnPayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  const column = list.addNewColumn(payload.columnName, payload.columnId)

  if (column.isCalculated()) {
    ;(column.unwrap() as EditableMetric).formulaInvalid = true
  }
}
