import type { ObjectTokenRecognitionMap, Token } from '../tokens/types'
import type { DisplayToken } from '@fintastic/web/data-access/formulas'
import type { TokenType } from '../tokens/types'
import { ConstantTokenRecognitionMap } from '../tokens/types'

export function antlr4TokensToFintasticTokens(
  formula: string,
  objectRecognitionMap: ObjectTokenRecognitionMap,
  constantRecognitionMap: ConstantTokenRecognitionMap,
  antlr4Tokens: DisplayToken[],
): Token[] {
  const tokens: Token[] = []

  let prevStop = -1
  antlr4Tokens.forEach(({ displayType, objectType, start, stop, text }) => {
    // add unrecognized strings before recognized token
    const unknownBeforeStartPosition = prevStop + 1
    if (start > unknownBeforeStartPosition) {
      tokens.push({
        text: formula.slice(unknownBeforeStartPosition, start),
        type: 'primitive',
        start: unknownBeforeStartPosition,
        stop: start - 1,
      } as Token)
    }

    // add recognized token
    let type: TokenType = 'primitive'
    if (constantRecognitionMap[text]) {
      type = 'constant'
    } else if (displayType === 'object') {
      type =
        objectType !== undefined ? (objectType as TokenType) : 'unknownObject'
    } else if (displayType === 'function') {
      type = 'function'
    }
    tokens.push({
      text,
      type,
      start,
      stop,
    } as Token)

    prevStop = stop
  })

  // add rest strings as unrecognized tokens
  const unknownAfterAllStartPosition = prevStop + 1
  if (unknownAfterAllStartPosition < formula.length - 1) {
    tokens.push({
      text: formula.slice(unknownAfterAllStartPosition),
      type: 'primitive',
      start: unknownAfterAllStartPosition,
      stop: formula.length - 1,
    } as Token)
  }

  return tokens
}
