import type { Maybe } from '@fintastic/shared/util/types'
import { RequestStateStatus } from './types'

export type RequestState = {
  status: RequestStateStatus
  error: Maybe<string>
}

export const requestInitialState: RequestState = {
  status: 'initial',
  error: null,
}
