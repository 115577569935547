import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { StyledContextMenuButtonRoot } from './ContextMenuButton.styled'
import { ContextMenuItemButtonRendererProps } from '../../types'
import { useTheme } from '@mui/material'

export type ContextMenuButtonProps = ContextMenuItemButtonRendererProps

export const ContextMenuButton: React.FC<ContextMenuButtonProps> = (props) => {
  const { contextMenuItem, hideContextMenu } = props
  const { onClick, to, label, id, active } = contextMenuItem

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
      hideContextMenu()
    }
  }, [hideContextMenu, onClick])

  const theme = useTheme()

  return (
    <StyledContextMenuButtonRoot
      onClick={handleClick}
      {...{ to }}
      LinkComponent={to ? Link : undefined}
      data-testid={`context-menu-button-${id}`}
      sx={
        active
          ? {
              backgroundColor: theme.palette.primary.light,
              fontWeight: 'bold'
            }
          : undefined
      }
    >
      {label}
    </StyledContextMenuButtonRoot>
  )
}
