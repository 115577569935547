import { styled, Typography } from '@mui/material'

export const StyledSubtitle = styled(Typography)`
  height: ${({ theme }) => theme.spacing(6)};
  align-content: flex-start;
  display: flex;
  flex-flow: row nowrap;
`

export const StyledTopStub = styled('div')`
  height: ${({ theme }) => theme.spacing(4)};
`
