import { Auth0ContextInterface } from '@fintastic/shared/data-access/auth0-react'
import { AxiosRequestConfig } from 'axios'
import {
  getTenantOverrideHeader,
  auth0Client,
} from '@fintastic/web/data-access/service-auth0'

export type AxiosAuthCustomParams = {
  applyAuth?: boolean
  tokenSource?: string | Auth0ContextInterface['getAccessTokenSilently']
  applyTenantOverride?: boolean
}

export const axiosAuthRequestInterceptor = {
  onFulfilled: async (config: AxiosRequestConfig) => {
    const { applyAuth = true, applyTenantOverride = true } =
      config.authCustomParams || {}

    if (applyAuth) {
      try {
        const token = await auth0Client.getTokenSilently()

        return {
          ...config,
          headers: {
            ...config.headers,
            ...(applyTenantOverride ? getTenantOverrideHeader() : {}),
            Authorization: `Bearer ${token}`,
          },
        }
      } catch (e) {
        throw new Error(`Couldn't get an authorization token`)
      }
    }

    return config
  },
  onRejected: (error: unknown) => {
    return Promise.reject(error)
  },
}
