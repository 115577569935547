import React from 'react'
import {
  BoardFoldersTree,
  Board as BoardType,
  BoardContextMenuBuilderForSidebarBoard,
  BoardContextMenuBuilderForSidebarFolder,
} from '../../types'
import { StyledFoldersTreeRoot } from './FoldersTree.styled'
import { Folder } from './Folder'
import { Board } from './Board'
import { Maybe } from '@fintastic/shared/util/types'

export type Props = {
  tree: BoardFoldersTree
  activeBoard: Maybe<BoardType['id']>
  boardContextMenuBuilder: BoardContextMenuBuilderForSidebarBoard
  folderContextMenuBuilder: BoardContextMenuBuilderForSidebarFolder
}

export const FoldersTree: React.FC<Props> = (props) => {
  const {
    tree,
    activeBoard,
    boardContextMenuBuilder,
    folderContextMenuBuilder,
  } = props

  return (
    <StyledFoldersTreeRoot>
      {tree.rootNodes.map((node) =>
        node.type === 'folder' ? (
          <Folder
            key={node.data.id}
            level={1}
            folder={node.data}
            childNodes={node.children}
            activeBoard={activeBoard}
            boardContextMenuBuilder={boardContextMenuBuilder}
            contextMenuBuilder={folderContextMenuBuilder}
          />
        ) : (
          <Board
            level={1}
            key={node.data.id}
            board={node.data}
            isActive={activeBoard === node.data.id}
            contextMenuBuilder={boardContextMenuBuilder}
          />
        ),
      )}
    </StyledFoldersTreeRoot>
  )
}
