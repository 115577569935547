import {
  CalendarDatePickerConfig,
  PeriodSelection,
  validatePeriodSelection,
} from '@fintastic/web/util/period-selector'
import { newPeriodsRangeGetterFabric } from './new-period-range-getter'
import { getAggregationTypes } from '../utils'
import { getNewPeriodList } from './getNewPeriodList'

/**
 * Build predicted periods list
 * Let's say you request 1 year aggregated by months, you should see 12 columns (or periods)
 */
export const getPeriodSelectorDataPeriodList = (
  calendarConfig: CalendarDatePickerConfig,
  periodSelection: PeriodSelection,
): string[] => {
  if (!validatePeriodSelection(periodSelection, calendarConfig)) {
    return []
  }

  const newPeriodsRangeGetter = newPeriodsRangeGetterFabric(
    calendarConfig,
    getAggregationTypes(calendarConfig).map((i) => i.dimension_id),
  )

  return getNewPeriodList(
    calendarConfig,
    newPeriodsRangeGetter,
    periodSelection.aggregationDimensionId,
    periodSelection,
  )
}
