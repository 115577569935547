import {
  LegacyListGrid,
  LegacyListGridProps,
  LegacyListGridRow,
} from '@fintastic/web/feature/legacy-list-grid'

import React from 'react'
import { useGridContextProp } from '../utils/useGridContextProp'

/** @deprecated */
export const LegacyListGridWithContext = <T extends LegacyListGridRow>(
  props: LegacyListGridProps<T>,
): JSX.Element => {
  const context = useGridContextProp(props.context)

  return <LegacyListGrid {...props} context={context} />
}
