import { useQuery, QueryKey } from 'react-query'
import { getBoards } from '../api'
import type { BoardsApiError } from '../api'
import type { BoardShortened } from '../types'

export const boardsListQueryKeyGetter = () => {
  const key: QueryKey = 'boards/list'
  return key
}

export function useBoardsListQuery() {
  return useQuery<BoardShortened[], BoardsApiError>(
    boardsListQueryKeyGetter(),
    async () => {
      const response = await getBoards()
      return response.data.result
    },
    {
      refetchIntervalInBackground: false,
      refetchInterval: false,
      staleTime: Infinity,
      retry: 1,
    },
  )
}
