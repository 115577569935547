import React, { useMemo } from 'react'
import {
  PageLayout,
  PageLayoutContentError,
  PageLayoutContentLoader,
} from '@fintastic/shared/ui/app-layout-framework'
import {
  useLoadListOfLists,
  useLoadListOfMetrics,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useVersionUserLockQuery } from '@fintastic/web/data-access/versions'
import { Version } from '@fintastic/web/util/versions'
import { useUserListQuery } from '@fintastic/web/data-access/iam'
import { LinearProgress } from '@mui/material'
import { LiveActualsTopBar } from '../components/LiveActualsTopBar/LiveActualsTopBar'
import { VersionTable } from '@fintastic/web/feature/versions'
import { getLiveActualsStatus } from '../api'
import { useQuery } from 'react-query'
import { LiveActualsPageContainer } from './LiveActualsPageContainer'
import { useLoadListOfReports } from '@fintastic/web/data-access/generic-report'

export const LiveActualsLoadingContainer: React.FC<{
  liveActualsVersion: Version
}> = ({ liveActualsVersion }) => {
  const pageTitle = 'Live Actuals'

  const POLLING_INTERVAL = 60 * 1000

  const { data: isDataUpToDate } = useQuery(
    ['liveActualsStatus', liveActualsVersion?.uuid || ''],
    async () => {
      if (!liveActualsVersion?.uuid) {
        throw new Error('No live actuals version defined')
      }
      const response = await getLiveActualsStatus(
        liveActualsVersion?.uuid || '',
      )
      return response.data
    },
    {
      refetchInterval: POLLING_INTERVAL,
    },
  )
  const isUpdated = isDataUpToDate ?? false

  const showHiddenLists = true
  const showHiddenMetrics = true

  const { data: usersListData } = useUserListQuery()

  const listsQuery = useLoadListOfLists(
    liveActualsVersion.uuid || '',
    showHiddenLists,
  )
  const metricsQuery = useLoadListOfMetrics(
    liveActualsVersion.uuid || '',
    showHiddenMetrics,
  )
  const reportsQuery = useLoadListOfReports(liveActualsVersion.uuid || '')
  const versionUserLock = useVersionUserLockQuery(
    liveActualsVersion.uuid || null,
  )

  const queries = [listsQuery, metricsQuery, versionUserLock, reportsQuery]
  const isLoading = queries.some((q) => q.isLoading)
  const isFetching = queries.some((q) => q.isFetching)
  const isError = queries.some((q) => q.isError)
  const isSuccess = queries.every((q) => q.isSuccess)

  const versionTables = useMemo<VersionTable[]>(() => {
    if (!isSuccess) {
      return []
    }
    const listsWithType =
      listsQuery?.data?.map((list) => ({
        ...list,
        type: 'list' as const,
        _rowId: `${list.id}:existing`,
      })) || []
    const metricsWithType =
      metricsQuery?.data?.map((metric) => ({
        ...metric,
        type: 'metric' as const,
        _rowId: `${metric.id}:existing`,
      })) || []

    return [
      ...listsWithType,
      ...metricsWithType,
      ...(reportsQuery.data || []).map((report) => ({
        type: 'report' as const,
        id: report.id,
        label: report.name,
        _rowId: `${report.id}:existing`,
      })),
    ]
  }, [isSuccess, listsQuery?.data, metricsQuery?.data, reportsQuery.data])

  if (liveActualsVersion.uuid && isSuccess) {
    return (
      <LiveActualsPageContainer
        version={liveActualsVersion}
        users={usersListData || []}
        versionTables={versionTables}
        pageTitle={pageTitle}
        isUpdated={isUpdated}
        versionUserLock={versionUserLock.lock}
      />
    )
  }

  return (
    <PageLayout
      topbar={
        liveActualsVersion.uuid && (
          <LiveActualsTopBar
            versionId={liveActualsVersion.uuid}
            pageTitle={pageTitle}
            isUpdated={isUpdated}
          />
        )
      }
    >
      {isLoading && <PageLayoutContentLoader />}
      {isSuccess && isFetching ? (
        <LinearProgress
          sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
        />
      ) : (
        <div />
      )}
      {isError && (
        <PageLayoutContentError
          title="Can't load the version"
          message="Something went wrong."
        />
      )}
    </PageLayout>
  )
}
