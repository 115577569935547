import { useIsFeatureEnabled } from '@fintastic/web/feature/config'
import type { GenericReportCategoryOptions } from '@fintastic/web/util/generic-report'

export const useFeatureTotalsColumnEnabled = (
  options: GenericReportCategoryOptions,
): boolean => {
  const totalsColumnFeatureEnabled = useIsFeatureEnabled(
    'generic_reports_totals_column',
  )

  return totalsColumnFeatureEnabled && (options.enable_total_column ?? true)
}
