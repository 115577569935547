import { ChangeWidgetDialogParams } from '../../../types'
import { GENERIC_REPORT_WIDGET_TYPE } from '../GenericReportWidget'
import { METRIC_GRID_WIDGET_TYPE } from '../MetricGridWidget'
import { METRIC_CHART_WIDGET_TYPE } from '../MetricChartWidget'
import { LIST_GRID_WIDGET_TYPE } from '../ListGridWidget'
import { validateChartWidgetSettings } from '@fintastic/web/feature/charts'

export const validateWidgetSettings = (
  state?: Partial<ChangeWidgetDialogParams>,
): boolean => {
  if (state?.type === GENERIC_REPORT_WIDGET_TYPE) {
    return Boolean(state?.id) && Boolean((state?.displayLabel || '').trim())
  }

  if (
    state?.type === METRIC_GRID_WIDGET_TYPE ||
    state?.type === LIST_GRID_WIDGET_TYPE
  ) {
    return (
      Boolean(state?.id) &&
      Boolean((state?.displayLabel || '').trim()) &&
      Boolean(state?.versionId)
    )
  }

  if (state?.type === METRIC_CHART_WIDGET_TYPE) {
    return validateChartWidgetSettings(state)
  }

  return false
}
