import React, { useMemo, useRef } from 'react'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import {
  GenericReportWrapper,
  useGenericReportBoardsConnector,
} from '@fintastic/web/feature/generic-report'
import {
  MonthOverMonthContext,
  useMonthOverMonthContextValue,
} from '@fintastic/web/util/generic-report'
import { useScrollToCurrentWidgetEffect } from '@fintastic/web/util/deeplink'
import { texts } from '@fintastic/web/data-access/versions'
import { UsePeriodSelectionComponentReadyValue } from '@fintastic/web/feature/period-selector'
import { SelectedCellAggregationProvider } from '@fintastic/web/util/selected-cell-aggregation'
import { GenericReportWidgetProps } from './types'
import { WidgetDependenciesWarning } from '@fintastic/shared/ui/widgets-framework'

export const GenericReportWidget: React.FC<
  GenericReportWidgetProps & {
    periodSelection: UsePeriodSelectionComponentReadyValue
  }
> = (props) => {
  const { widget, boardParams, isEnoughSpace, periodSelection } = props
  const { settings } = widget

  const { reports, reportCategoryId, diffs, isLoading, reportNameOverrides } =
    useGenericReportBoardsConnector({
      ...boardParams,
      reportCategoryId: widget.settings.reportCategoryId,
    })

  const widgetContent = useMemo(() => {
    if (!isEnoughSpace) {
      return (
        <WidgetDependenciesWarning>Not enough space</WidgetDependenciesWarning>
      )
    }

    if (isLoading) {
      return <CenteredCircularProgress />
    }

    if (!boardParams.versions.length) {
      return (
        <WidgetDependenciesWarning>
          {texts.noVersionsSharedWithUser}
        </WidgetDependenciesWarning>
      )
    }

    if (!reports.length) {
      return (
        <WidgetDependenciesWarning>
          Please, select another version
        </WidgetDependenciesWarning>
      )
    }

    return (
      <GenericReportWrapper
        reportCategoryId={reportCategoryId}
        selectedReports={reports}
        selectedDiffs={diffs}
        reportNames={reportNameOverrides}
        options={settings}
        periodSelectionComponentReadyValue={periodSelection}
      />
    )
  }, [
    isEnoughSpace,
    isLoading,
    boardParams.versions.length,
    reports,
    reportCategoryId,
    diffs,
    reportNameOverrides,
    settings,
    periodSelection,
  ])

  const domRef = useRef<HTMLDivElement>(null)

  const monthOverMonthContext = useMonthOverMonthContextValue(widget.id)
  useScrollToCurrentWidgetEffect(widget.id, domRef)

  return (
    <SelectedCellAggregationProvider
      entityIdForPersistentSettings={reportCategoryId}
    >
      <MonthOverMonthContext.Provider value={monthOverMonthContext}>
        <div
          ref={domRef}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          {widgetContent}
        </div>
      </MonthOverMonthContext.Provider>
    </SelectedCellAggregationProvider>
  )
}
