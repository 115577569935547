import React, { useMemo } from 'react'
import {
  Button,
  ButtonProps as MuiButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material'

export type PanelToolbarButtonProps = {
  enableTooltip?: boolean
  title?: string
  disabledTitle?: string
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>
  ['data-testid']?: string
} & MuiButtonProps

const defaultTooltipProps: Omit<TooltipProps, 'title' | 'children'> = {
  placement: 'top',
}

export const PanelToolbarButton: React.FC<PanelToolbarButtonProps> = ({
  title,
  disabledTitle,
  enableTooltip = true,
  tooltipProps = defaultTooltipProps,
  color = 'primary',
  variant = 'outlined',
  ...restProps
}) => {
  const tooltipMergedProps = useMemo(
    () => ({
      ...defaultTooltipProps,
      ...tooltipProps,
    }),
    [tooltipProps],
  )

  if (enableTooltip) {
    return (
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={restProps.disabled ? disabledTitle || title : title}
        {...tooltipMergedProps}
      >
        <span>
          <Button color={color} variant={variant} {...restProps} />
        </span>
      </Tooltip>
    )
  }

  return <Button color={color} variant={variant} {...restProps} title={title} />
}
