import { useCallback, useMemo } from 'react'
import { AddColumnAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'
import { DataContextValue } from '../../data/data-context'

export function useAddColumnFlow(data: DataContextValue) {
  const { addColumn } = useListColumnsActions()

  const handleAction = useCallback<AddColumnAction>(
    (newColumnName, columnId) => {
      addColumn({
        columnName: newColumnName,
        columnId: columnId,
      })
    },
    [addColumn],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
