import { ISetFilterCellRendererParams } from 'ag-grid-community'
import { ImportActualsConfigContext } from '../context/ImportActualsConfigContext'
import { useContext } from 'react'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'

export const DateFilterCellRenderer: React.FC<ISetFilterCellRendererParams> = ({
  value,
  valueFormatted,
  colDef,
}) => {
  const ctx = useContext(ImportActualsConfigContext)

  if (value === 'user_input') {
    const key = colDef?.filterParams.userInputFieldValue
    const field = key === 'dateTo' ? 'dateTo' : 'dateFrom'
    const date = ctx[field]

    if (date) {
      return <>{dayjs(date, DEFAULT_DAYJS_DATE_FORMAT).format('MMM YYYY')}</>
    }
  }

  if (value === 'from_source') {
    return <>{colDef?.filterParams.fromSourceLabel || value}</>
  }

  return <>{valueFormatted || value}</>
}
