import React, { MouseEventHandler, useCallback, useEffect, useRef } from 'react'
import { StyledButton, StyledDrawerResizeHandler } from './ResizeHandler.styled'
import { ThreeDotsHorizontalIcon } from '@fintastic/shared/ui/icons'
import { layoutConfig } from '@fintastic/shared/ui/app-layout-framework'

export type ResizeHandlerProps = {
  onResizeEnd?: (height: number) => void
}

export const ResizeHandler: React.FC<ResizeHandlerProps> = ({
  onResizeEnd,
}) => {
  const resizeHandlerRef = useRef<HTMLDivElement>(null)
  const resizeContainerRef = useRef<HTMLDivElement | null>(null)

  const startHeightRef = useRef<number>(0)
  const startYRef = useRef<number>(0)
  const currentHeightRef = useRef<number>(0)

  useEffect(() => {
    resizeContainerRef.current = resizeHandlerRef.current
      ?.parentElement as HTMLDivElement
  }, [])

  const handlePointerMove = useCallback((e: PointerEvent) => {
    e.stopPropagation()
    e.preventDefault() // prevent text selection

    const delta = startYRef.current - e.clientY
    currentHeightRef.current = startHeightRef.current + delta

    if (resizeContainerRef?.current) {
      resizeContainerRef.current.style.height = currentHeightRef.current + 'px'
    }
  }, [])

  const handleESC = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        document.removeEventListener('pointermove', handlePointerMove)
        document.removeEventListener('pointerup', handlePointerUp)
        document.removeEventListener('keydown', handleESC)

        if (onResizeEnd) {
          if (resizeContainerRef?.current) {
            resizeContainerRef.current.style.height =
              startHeightRef.current + 'px'
          }
          onResizeEnd(startHeightRef.current)
        }
        if (resizeHandlerRef.current) {
          resizeHandlerRef.current.classList.remove('in-resize')
        }
      }
    },
    [handlePointerMove, onResizeEnd],
  )

  const handlePointerUp = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()

      document.removeEventListener('pointermove', handlePointerMove)
      document.removeEventListener('pointerup', handlePointerUp)
      document.removeEventListener('keydown', handleESC)

      if (resizeHandlerRef.current) {
        resizeHandlerRef.current.classList.remove('in-resize')
      }

      if (onResizeEnd) {
        if (resizeContainerRef?.current) {
          const containerNode = resizeContainerRef.current
          const { height } = containerNode.getBoundingClientRect()

          onResizeEnd(height)
        }
      }
    },
    [handleESC, handlePointerMove, onResizeEnd],
  )

  const handleDoubleClick = useCallback(() => {
    if (onResizeEnd) {
      onResizeEnd(layoutConfig.bottomdrawer.height)
    }
  }, [onResizeEnd])

  const handlePointerDown = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation()

      if (resizeContainerRef?.current) {
        const containerNode = resizeContainerRef.current
        const { height } = containerNode.getBoundingClientRect()

        startYRef.current = e.clientY
        startHeightRef.current = height
      }

      if (resizeHandlerRef.current) {
        resizeHandlerRef.current.classList.add('in-resize')
      }

      document.addEventListener('pointermove', handlePointerMove)
      document.addEventListener('pointerup', handlePointerUp)
      document.addEventListener('keydown', handleESC)
    },
    [handleESC, handlePointerMove, handlePointerUp],
  )

  return (
    <StyledDrawerResizeHandler role={'separator'} ref={resizeHandlerRef}>
      <StyledButton
        onMouseDown={handlePointerDown}
        onDoubleClick={handleDoubleClick}
        className={'resize-handler'}
      >
        <ThreeDotsHorizontalIcon fontSize="inherit" />
      </StyledButton>
    </StyledDrawerResizeHandler>
  )
}
