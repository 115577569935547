import React from 'react'
import {
  PageLayout,
  PageLayoutContentError,
  PageLayoutContentFullHeight,
  PageLayoutContentLoader,
} from '@fintastic/shared/ui/app-layout-framework'
import { VersionsList } from '../components/version-management/VersionsList'
import { AccessDeninedAlert } from '@fintastic/shared/ui/components'
import { useUserListQuery } from '@fintastic/web/data-access/iam'
import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { useIsVersionManagementEnabled } from '../hooks/useIsVersionManagementEnabled'

export const VersionsListPage: React.FC = () => {
  const versionManagementEnabled = useIsVersionManagementEnabled()
  const versionsListQuery = useLoadVersionsList({
    showArchived: true,
  })
  const { data: usersListData, isSuccess: isUsersSuccess } = useUserListQuery()

  return (
    <PageLayout>
      <>
        {versionsListQuery.isLoading && <PageLayoutContentLoader />}
        {versionsListQuery.error && (
          <PageLayoutContentError
            title="Can't load the versions list"
            message="Something went wrong."
          />
        )}
        {!versionsListQuery.isLoading &&
          !versionsListQuery.error &&
          isUsersSuccess &&
          (versionManagementEnabled ? (
            <PageLayoutContentFullHeight>
              <VersionsList
                versions={versionsListQuery.data ?? []}
                users={usersListData || []}
              />
            </PageLayoutContentFullHeight>
          ) : (
            <AccessDeninedAlert />
          ))}
      </>
    </PageLayout>
  )
}
