export const getScrolledParent = (
  node: HTMLDivElement | null,
): HTMLDivElement | null => {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrolledParent((node.parentNode as HTMLDivElement) ?? null)
  }
}
