import { QueryClient, useQueryClient } from 'react-query'
import { boardsListQueryKeyGetter } from './useBoardsListQuery'
import { boardsAccessQueryKeyGetter } from './useLoadAccessForBoardsList'

const invalidateBoardsList = async (queryClient: QueryClient) => {
  queryClient.removeQueries(boardsListQueryKeyGetter())
  await queryClient.invalidateQueries(boardsListQueryKeyGetter())
}

const invalidateBoard = async (queryClient: QueryClient, boardId: string) => {
  queryClient.removeQueries(boardsAccessQueryKeyGetter(boardId))
  await queryClient.invalidateQueries(boardsAccessQueryKeyGetter(boardId))
}

export function useBoardInvalidate() {
  const queryClient = useQueryClient()
  return {
    invalidateList: () => invalidateBoardsList(queryClient) ,
    invalidateBoard: (boardId: string) => invalidateBoard(queryClient, boardId)
  }
}
