import { useCallback } from 'react'
import { AddDimensionAction } from '../types'
import {
  MetricOrListEditingFlow,
  useMetricsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import { Dimension, Maybe } from '@fintastic/shared/util/types'
import { useModalWithParamsState } from '@fintastic/shared/util/modal'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { DataContextValue } from '../../data/data-context'

export function useAddDimensionFlow(
  { dimensionsList }: DataContextValue,
  flow: Maybe<MetricOrListEditingFlow>,
) {
  const { addDimension } = useMetricsActions()

  const confirmationPopupState = useModalWithParamsState<{
    dimension: Maybe<Dimension>
  }>({
    initialParams: {
      dimension: null,
    },
  })

  const handleAction = useCallback<AddDimensionAction>(
    (dimension) => {
      if (flow === null || dimensionsList === null) {
        return
      }

      if (flow === 'editing') {
        confirmationPopupState.openWithParams({ dimension })
        return
      }

      addDimension({
        dimensionId: dimension.id,
        allDimensions: dimensionsList,
      })
    },
    [addDimension, confirmationPopupState, dimensionsList, flow],
  )

  const handleConfirm = useCallback(() => {
    const { dimension } = confirmationPopupState.params

    if (dimension !== null && dimensionsList !== null) {
      addDimension({
        dimensionId: dimension.id,
        allDimensions: dimensionsList,
      })
    }
  }, [addDimension, confirmationPopupState.params, dimensionsList])

  const popups = (
    <ConfirmationPopup
      title={`Add ${confirmationPopupState.params.dimension?.label}?`}
      description={
        confirmationPopupState.params.dimension?.type === 'Time'
          ? 'Existing data will be copied to all new periods'
          : `Existing data will be assigned to ‘_other’ value in ${confirmationPopupState.params.dimension?.label} dimension.`
      }
      open={confirmationPopupState.isOpen}
      onRequestClose={confirmationPopupState.close}
      onConfirm={handleConfirm}
    >
      <ConfirmationPopup.DeclineButton
        variant="contained"
        color="primary"
        sx={{ mr: 1 }}
      >
        Cancel
      </ConfirmationPopup.DeclineButton>
      <ConfirmationPopup.ConfirmButton>
        Add new dimension
      </ConfirmationPopup.ConfirmButton>
    </ConfirmationPopup>
  )

  return {
    action: handleAction,
    popups,
  }
}
