import { Box, BoxProps, styled } from '@mui/material'

export const StyledWidgetWrapperRoot = styled(
  ({
    isCollapsedVert,
    ...restProps
  }: {
    isCollapsedVert: boolean
  } & BoxProps) => <Box {...restProps} />,
)(({ theme, isCollapsedVert }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  ...(isCollapsedVert
    ? {
        '& + .widget-resize-handler': {
          display: 'none',
        },
      }
    : {}),
}))

export const StyledWidgetWrapperContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
}))

export const EditorContextActions = styled(
  (props: BoxProps & { isText?: boolean }) => {
    const { isText, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'isText',
  },
)`
  position: absolute;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  top: 10px;
  right: 6px;
  z-index: 5;
  display: flex;
  align-items: center;
  place-content: center;

  &:hover::after {
    opacity: 0.8;
  }
`
