import { ThemeComponents } from './types'

export const MuiTab: ThemeComponents['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      textTransform: 'none',
    }),
  },
}
