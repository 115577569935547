import React, { useEffect } from 'react'
import {
  WidgetSelect,
  WidgetSelectDataCallbacksProps,
} from '@fintastic/web/feature/boards'
import { useLoadListOfReports } from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

export type WidgetSelectReportsProps = {
  disabled?: boolean
  value: Maybe<string>
  onSelect: (listId: Maybe<string>, listName: Maybe<string>) => void
}

export const PENDING_REPORTS_ID = 'reportsList'

export const WidgetSelectReports: React.FC<
  WidgetSelectReportsProps & WidgetSelectDataCallbacksProps
> = (props) => {
  const { data: entityList, isLoading } = useLoadListOfReports()

  useEffect(() => {
    if (isLoading) {
      props.onFetching?.(PENDING_REPORTS_ID)
    } else {
      if (entityList && entityList.length > 0) {
        props.onReady?.(PENDING_REPORTS_ID)
      }
    }
  }, [isLoading, entityList, props])

  return (
    <WidgetSelect
      {...props}
      entityList={entityList || []}
      isLoading={isLoading}
      emptyText={'Select report'}
      loadingText={'Loading list of reports...'}
    />
  )
}
