import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { lockVersionForUser } from '../../api/version-locking-api'
import { Maybe } from '@fintastic/shared/util/types'
import { invalidateVersionUserLockerCache } from '../../cache'

export function useLockVersionForUser(versionId: Maybe<string>) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.lockVersionForUser(versionId || ':'),
    async () => {
      if (!versionId) {
        throw new Error('versionId is not defined')
      }
      const response = await lockVersionForUser(versionId)
      return response.data
    },
    {
      onSettled: async () => {
        if (!versionId) {
          return
        }
        await invalidateVersionUserLockerCache(queryClient, versionId)
      },
    },
  )
}
