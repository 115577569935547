import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'
import {
  ChartNumberFormat,
  chartNumberFormatKeys,
  chartNumberFormats,
  defaultChartNumberFormat,
  isChartNumberFormat,
} from '@fintastic/web/feature/charts'
import {
  StyledTextWithoutIcon,
  StyledTextWithoutIconField,
} from '../common-styles'

export type ChartNumberFormatSelectProps = {
  numberFormat: ChartNumberFormat
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartNumberFormatSelect: React.FC<ChartNumberFormatSelectProps> =
  memo(({ numberFormat, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartNumberFormat(e.target.value)) {
          onChangeChartSettings({ numberFormat: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextWithoutIconField
          id="chart-num-format-select"
          fullWidth
          value={numberFormat}
          size="small"
          select
          label="Numbers compact formatting"
          defaultValue={defaultChartNumberFormat}
          onChange={handleSelect}
          disabled={disabled}
        >
          {chartNumberFormatKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithoutIcon
                data-testid={`chart-num-format-item-${ct}`}
              >
                {chartNumberFormats[ct]}
              </StyledTextWithoutIcon>
            </MenuItem>
          ))}
        </StyledTextWithoutIconField>
      </FormField>
    )
  })
