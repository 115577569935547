/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { RootState, State } from './state'
import { Selector, createSelector } from 'reselect'
import { namespace } from './state'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s[namespace]

export const selectIsLoading = createSelector(
  [selectAll],
  (reducerState) => reducerState.status === 'loading',
)

export const selectIsInitial = createSelector(
  [selectAll],
  (reducerState) => reducerState.status === 'initial',
)

export const selectIsFinished = createSelector(
  [selectAll],
  (reducerState) =>
    reducerState.status !== 'initial' && reducerState.status !== 'loading',
)

export const selectError = createSelector(
  [selectAll],
  (reducerState) => reducerState.error,
)

export const selectStatuses = createSelector(
  [selectIsLoading, selectIsInitial, selectIsFinished, selectError],
  (isLoading, isInitial, isFinished, error) => ({
    isLoading,
    isInitial,
    isFinished,
    error,
  }),
)
