import { isArray } from 'lodash'
import { QueryKey } from 'react-query'
import { MetricV2CacheListColumnParams } from '../types'

export const metricsV2QueryKeys = {
  listColumn: ({
    versionId,
    listId,
    metricId,
    filters,
    period,
    pagination,
  }: MetricV2CacheListColumnParams) => [
    METRIC_V2_CACHE_PREFIX,
    versionId,
    listId,
    metricId,
    JSON.stringify({
      filters: filters ?? {},
      period: period ?? null,
      pagination: pagination ?? {},
    }),
  ],
}

const METRIC_V2_CACHE_PREFIX = 'metrics-v2'

export const isListV2QueryKey = (
  queryKey: QueryKey,
  versionId?: string,
  listId?: string,
  metricId?: string,
): boolean => {
  if (!isArray(queryKey)) {
    return false
  }

  const [prefix, qVersionId, qListId, qMetricId] = queryKey as string[]

  if (prefix !== METRIC_V2_CACHE_PREFIX) {
    return false
  }

  if (versionId && versionId !== qVersionId) {
    return false
  }

  if (listId && listId !== qListId) {
    return false
  }

  if (metricId && metricId !== qMetricId) {
    return false
  }

  return true
}
