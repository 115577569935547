import { ChangeWidgetDialogParams } from '@fintastic/web/feature/boards'
import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useTextFieldState } from '@fintastic/shared/ui/form-framework'

export type GenericReportWidgetSettings = {
  reportId?: Maybe<string>
  handleSelectReport: (
    reportId: Maybe<string>,
    reportName: Maybe<string>,
  ) => void
  changed: boolean
}

export const useGenericReportWidgetSettings = (
  initialParams: Partial<ChangeWidgetDialogParams>,
  displayNameFieldState: ReturnType<typeof useTextFieldState>,
): GenericReportWidgetSettings => {
  const [reportId, setReportId] = useState<Maybe<string>>(
    initialParams.id || null,
  )

  const [changed, setChanged] = useState(false)

  const handleSelectReport = useCallback(
    (reportId: Maybe<string>, reportName: Maybe<string>) => {
      setReportId(reportId)
      if (
        !displayNameFieldState.touched ||
        !displayNameFieldState.trimmedValue
      ) {
        displayNameFieldState.assignValue(reportName || '')
      }
      setChanged(() => true)
    },
    [setReportId, displayNameFieldState, setChanged],
  )

  return useMemo<GenericReportWidgetSettings>(
    () => ({
      reportId,
      handleSelectReport,
      changed,
    }),
    [reportId, handleSelectReport, changed],
  )
}
