import { styled, Box, BoxProps } from '@mui/material'
import React, { forwardRef } from 'react'

export const StyledVerticalTwoResizableContainersRoot = styled(Box)(
  ({ theme }) => ({
    position: 'relative',

    width: '100%',
    height: '100%',
    minHeight: '100%',
  }),
)

export const StyledVerticalTwoResizableContainersWrapper = styled(Box)(
  ({ theme }) => ({
    position: 'relative',

    display: 'flex',
    flexFlow: 'nowrap column',

    width: '100%',
    height: '100%',
    minHeight: '100%',
  }),
)

export const StyledVerticalTwoResizableContainersContainer = styled(
  forwardRef<
    HTMLElement,
    BoxProps & {
      isSingle: boolean
    }
  >((props, ref) => {
    const { isSingle, ...boxProps } = props
    return <Box ref={ref} {...boxProps} />
  }),
  {
    shouldForwardProp: (propName) => propName !== 'isSingle',
  },
)(({ theme, isSingle }) => ({
  width: '100%',
  height: '100%',
}))
