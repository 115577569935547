import {
  CalculationProgressEvent,
  CalculationProgressEventCalcAction,
} from './types'
import {
  dependenciesGraphAffectedFlag,
  processedCalculationActions,
  status,
  taskId as selectTaskId,
  user,
  userModifiedEntities,
  errorDetailsObjectId,
} from './selectors'
import { idLooksLikeColumn } from '@fintastic/web/util/metrics-and-lists'

type Event = CalculationProgressEvent

export const successful = (e: Event) => status(e) === 'success'
export const failed = (e: Event) => status(e) === 'fail'

export const triggeredByUser = (e: Event, userEmail: string) =>
  user(e) === userEmail

export const triggeredByAction = (
  e: Event,
  action: CalculationProgressEventCalcAction,
) => {
  const actions = processedCalculationActions(e)
  if (!actions) {
    return false
  }
  return actions.includes(action)
}

export const triggeredByFormulaEditAction = (e: Event) =>
  triggeredByAction(e, 'edit:formula')

export const triggeredByUpdateModelAction = (e: Event) =>
  triggeredByAction(e, 'update:model_data')

export const triggeredByInputEditAction = (e: Event) =>
  triggeredByAction(e, 'edit')

export const triggeredByDuplicateListRowsAction = (e: Event) =>
  triggeredByAction(e, 'duplicate')

export const triggeredByAddListRowsAction = (e: Event) =>
  triggeredByAction(e, 'add')

export const triggeredByDeleteListRowsAction = (e: Event) =>
  triggeredByAction(e, 'delete')

export const triggeredByReplaceListAction = (e: Event) =>
  triggeredByAction(e, 'replace:list')

export const triggeredByCreateListAction = (e: Event) =>
  triggeredByAction(e, 'new:list') ||
  triggeredByAction(e, 'create:calculated_list')

export const triggeredByReplaceMetricAction = (e: Event) =>
  triggeredByAction(e, 'replace:metric')

export const triggeredByCreateMetricAction = (e: Event) =>
  triggeredByAction(e, 'new:metric') ||
  triggeredByAction(e, 'create:calculated_metric')

export const triggeredByDeleteEntityAction = (e: Event) =>
  triggeredByAction(e, 'delete:metric') ||
  triggeredByAction(e, 'delete:list') ||
  triggeredByAction(e, 'delete:metric_in_list')

export const someEntityIsModifiedByUser = (e: Event) =>
  Boolean(userModifiedEntities(e)?.length)

export const dependenciesGraphAffected = (e: Event) =>
  dependenciesGraphAffectedFlag(e)

export const taskIdMatches = (e: Event, taskId: string) =>
  selectTaskId(e) === taskId

export const errorCausedByEntity = (e: Event, entityId: string) =>
  errorDetailsObjectId(e) === entityId

export const errorCausedByListColumn = (e: Event, listId: string) => {
  const objectId = errorDetailsObjectId(e)
  if (!objectId) {
    return false
  }
  return idLooksLikeColumn(objectId) && objectId.startsWith(listId)
}
