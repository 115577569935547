import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type ConfirmationPopupProps = {
  isOpen: boolean
  count: number
  onClose: () => void
  onConfirm: () => void
}

// Check count > 0 is here because modal is mounted all the time and
// between cancel and fading out the parent resets count to 0
// and there is enough time of visibility to notice that.
export const LinesDeleteConfirmationModal: React.FC<ConfirmationPopupProps> = ({
  isOpen,
  count,
  onConfirm,
  onClose,
}) => (
  <ConfirmationPopup
    open={isOpen}
    onRequestClose={onClose}
    onConfirm={onConfirm}
    onDecline={onClose}
    title={
      count > 0 ? `Delete ${count} line${count > 1 ? 's' : ''}?` : 'Delete'
    }
    autoClose={false}
  >
    {count > 0 && (
      <Box width="100%">
        <ErrorAlert
          title="Existing data will be lost"
          message="It won't be possible to retrieve the current data"
          severity="warning"
        />
        <Box mt={3} width="100%" display="flex" justifyContent="center">
          <ConfirmationPopup.DeclineButton variant="contained" color="primary">
            Cancel
          </ConfirmationPopup.DeclineButton>
          <ConfirmationPopup.ConfirmButton sx={{ ml: 1 }}>
            Delete {count > 1 ? count : ''} line{count > 1 ? 's' : ''}
          </ConfirmationPopup.ConfirmButton>
        </Box>
      </Box>
    )}
  </ConfirmationPopup>
)
