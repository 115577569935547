import { useLoadDimensionsPermissionsForLists } from '@fintastic/web/data-access/metrics-and-lists'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { useMemo } from 'react'
import { makeApi } from './makeApi'

export const useListDimensionsPermissionsApi = (
  versionId: string,
  listId: string,
) => {
  const isPowerUser = !!useRoleLevelAccess('power_user')
  const { isLoading, error, data } = useLoadDimensionsPermissionsForLists(
    versionId,
    !isPowerUser,
  )

  return useMemo(
    () =>
      makeApi(listId, {
        isPowerUser,
        allListsPermissions: {
          isLoading,
          error,
          data,
        },
      }),
    [data, error, isLoading, isPowerUser, listId],
  )
}
