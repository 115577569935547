import { useCallback, useMemo } from 'react'
import VersionsContainer from './versions-container/VersionsContainer'
import { VersionSelectorItem } from '../types'
import { StyledPopoverContainer } from './VersionPopover.styled'
import { combination } from './combination'
import { DiffPairsContainer } from './diff-pairs'
import {
  defaultDiffMode,
  DiffMode,
  isDiffPairEqualsByVersions,
} from '@fintastic/web/util/versions'

export type VersionsPopoverProps = {
  localSelectedVersions: string[]
  handleVersions: (version: VersionSelectorItem) => void
  versions: VersionSelectorItem[]
  localSelectedDiffs: [string, string, DiffMode][]
  setLocalDiffs: (diffs: [string, string, DiffMode][]) => void
  getDiffPairLabels: (diffIds: [string, string]) => [string, string]
  hideDiffs?: boolean
}

export const VersionsPopover: React.FC<VersionsPopoverProps> = ({
  localSelectedVersions,
  handleVersions,
  localSelectedDiffs,
  versions,
  setLocalDiffs,
  getDiffPairLabels,
  hideDiffs = false,
}) => {
  const currentLocalVersions = useMemo(
    () =>
      localSelectedVersions.map((sv) =>
        versions.find((version) => version.id === sv),
      ) as VersionSelectorItem[],
    [localSelectedVersions, versions],
  )

  const allDiffs = useMemo(() => {
    if (currentLocalVersions.length < 2) {
      return []
    }
    const versionsId = currentLocalVersions
      .filter((version) => version !== undefined)
      .map((version) => version.id)

    const diffs = combination(versionsId, 2)
    return diffs as [string, string, DiffMode][]
  }, [currentLocalVersions])

  const handleDiffPairToggle = useCallback(
    (column: [string, string, DiffMode]) => {
      let currentSelectedDiffs = [...localSelectedDiffs]

      const existingPair = currentSelectedDiffs.find((col) =>
        isDiffPairEqualsByVersions(col, column),
      )

      if (existingPair) {
        // checkbox - remove record
        currentSelectedDiffs = currentSelectedDiffs.filter(
          (col) => !isDiffPairEqualsByVersions(col, column),
        )
      } else {
        // checkbox - add record
        currentSelectedDiffs.push([column[0], column[1], defaultDiffMode])
      }
      setLocalDiffs(currentSelectedDiffs)
    },
    [setLocalDiffs, localSelectedDiffs],
  )

  const handleDiffPairMode = useCallback(
    (column: [string, string, DiffMode]) => {
      let currentSelectedDiffs = [...localSelectedDiffs]

      const existingPair = currentSelectedDiffs.find((col) =>
        isDiffPairEqualsByVersions(col, column),
      )

      if (!existingPair) {
        return
      }

      currentSelectedDiffs = currentSelectedDiffs.map((col) => {
        if (isDiffPairEqualsByVersions(col, column)) {
          return column
        }
        return col
      })
      setLocalDiffs(currentSelectedDiffs)
    },
    [setLocalDiffs, localSelectedDiffs],
  )

  return (
    <StyledPopoverContainer data-testid="version-selector-popover">
      <VersionsContainer
        versions={versions}
        currentVersions={currentLocalVersions}
        handleVersions={handleVersions}
      />
      {!hideDiffs && currentLocalVersions.length > 1 && (
        <DiffPairsContainer
          getDiffPairLabels={getDiffPairLabels}
          diffPairs={allDiffs}
          selectedDiffs={localSelectedDiffs}
          handleDiffPairToggle={handleDiffPairToggle}
          handleDiffPairMode={handleDiffPairMode}
        />
      )}
    </StyledPopoverContainer>
  )
}

export default VersionsPopover
