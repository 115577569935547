import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export function useContextMenuState() {
  const [anchorElement, setAnchorElement] = useState<Maybe<HTMLElement>>(null)

  const handleClickOnAnchor = useCallback(
    ({ target }: { target: HTMLElement }) => {
      setAnchorElement(target)
    },
    [],
  )

  const handleClose = useCallback(() => {
    setAnchorElement(null)
  }, [])

  return useMemo(
    () => ({
      isOpen: !!anchorElement,
      anchorElement,
      handleClickOnAnchor,
      handleClose,
    }),
    [anchorElement, handleClickOnAnchor, handleClose],
  )
}
