import React, { useCallback } from 'react'
import { Typography } from '@mui/material'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import {
  useLockVersion,
  useUnlockVersion,
} from '@fintastic/web/data-access/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'

export type LockVersionFormProps = {
  versionId: string
  versionName: string
  isLocked?: boolean
  closeParentModal?: () => void
  onAfterLock?: () => void
  onAfterUnlock?: () => void
}

export const LockVersionForm: React.FC<LockVersionFormProps> = (props) => {
  const {
    versionId,
    versionName,
    isLocked,
    closeParentModal,
    onAfterLock,
    onAfterUnlock,
  } = props

  const lockVersionMutation = useLockVersion(versionId)
  const unlockVersionMutation = useUnlockVersion(versionId)

  const handleLockSubmit = useCallback(() => {
    lockVersionMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(`Version ${versionName} locked`)
      },
      onError: () => {
        toast.error(`Lock of version ${versionName} failed`)
      },
    })
    if (onAfterLock) {
      onAfterLock()
    }
    if (closeParentModal) {
      closeParentModal()
    }
  }, [closeParentModal, lockVersionMutation, onAfterLock, versionName])

  const handleUnlockSubmit = useCallback(() => {
    unlockVersionMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(`Version ${versionName} unlocked`)
      },
      onError: () => {
        toast.error(`Unlock of version ${versionName} failed`)
      },
    })
    if (onAfterUnlock) {
      onAfterUnlock()
    }
    if (closeParentModal) {
      closeParentModal()
    }
  }, [closeParentModal, onAfterUnlock, unlockVersionMutation, versionName])

  return (
    <FormLayout
      submitButtonText={isLocked ? 'Unlock' : 'Lock'}
      onSubmit={isLocked ? handleUnlockSubmit : handleLockSubmit}
    >
      <Typography sx={{ marginBottom: 3, whiteSpace: 'pre-line' }}>
        {versionName}
      </Typography>
      <Typography>{`Are you sure you want to ${
        isLocked ? 'unlock' : 'lock'
      } this version?`}</Typography>
    </FormLayout>
  )
}
