import { Maybe } from '@fintastic/shared/util/types'
import { VersionLabelToggleParams } from '../types'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { Nullable } from '@fintastic/shared/util/functional-programming'
import { FilterListAPIPayload } from '@fintastic/web/util/filters'
import { isEmpty } from 'lodash'
import { ChartAggRequestDimension } from '../api/metrics-chart-api'

export const metricsAndListsCacheKeys = {
  metric: (
    versionId?: Maybe<string>,
    metricId?: Maybe<string>,
    periodSelection?: Maybe<PeriodSelection>,
  ) => [
    'version',
    versionId || 'none',
    'metric',
    metricId || 'none',
    periodSelection || 'none',
  ],
  metricChart: (
    versionId?: Maybe<string>,
    metricId?: Maybe<string>,
    periodSelection?: Maybe<PeriodSelection>,
    dimensions?: Maybe<ChartAggRequestDimension[]>,
  ) => [
    'version',
    versionId || 'none',
    'metric',
    metricId || 'none',
    periodSelection || 'none',
    dimensions || 'none',
  ],
  metricChartDims: (versionId?: Maybe<string>, metricId?: Maybe<string>) => [
    'version',
    versionId || 'none',
    'metric',
    metricId || 'none',
  ],
  metrics: (versionId: string, showHidden: boolean) => [
    'version',
    versionId,
    'metrics',
    showHidden,
  ],
  list: (
    versionId?: Maybe<string>,
    listId?: Maybe<string>,
    periodSelection?: Maybe<Partial<PeriodSelection>>,
  ) => [
    'version',
    versionId || 'none',
    'list',
    listId || 'none',
    periodSelection || 'none',
  ],
  listWithoutData: (
    versionId?: Maybe<string>,
    listId?: Maybe<string>,
    periodSelection?: Maybe<Partial<PeriodSelection>>,
  ) => [
    'version',
    versionId || 'none',
    'listWithoutData',
    listId || 'none',
    periodSelection || 'none',
  ],
  lists: (versionId: string, showHidden: boolean) => [
    'version',
    versionId,
    'lists',
    showHidden,
  ],
  listColumnsLoader: (
    versionId?: Maybe<string>,
    listId?: Maybe<string>,
    columnId?: Maybe<string[]>,
    periodSelection?: Maybe<Partial<PeriodSelection>>,
    filters?: FilterListAPIPayload,
  ) => [
    'version',
    versionId || 'none',
    'listColumnsLoader',
    listId || 'none',
    periodSelection || 'none',
    (columnId || []).join(','),
    isEmpty(filters) ? 'none' : filters,
  ],
  paginatedListColumns: (
    versionId: Maybe<string>,
    listId: Maybe<string>,
    columnId: Maybe<string[]>,
    periodSelection: Maybe<Partial<PeriodSelection>>,
    page: number,
    itemsPerPage: number,
    filters?: Maybe<Partial<FilterListAPIPayload>>,
  ) => [
    'version',
    versionId || 'none',
    'paginatedListColumns',
    listId || 'none',
    periodSelection || 'none',
    (columnId || []).join(','),
    'page',
    page,
    isEmpty(filters) ? 'none' : filters,
  ],
  listColumn: (
    versionId?: Maybe<string>,
    metricId?: Maybe<string>,
    periodSelection?: Maybe<Partial<PeriodSelection>>,
  ) => [
    'version',
    versionId || 'none',
    'column',
    metricId || 'none',
    periodSelection || 'none',
  ],
  dimensionPermissionsForLists: (versionId?: Nullable<string>) => [
    'version',
    versionId || 'none',
    'dimensionPermissionsForLists',
  ],
  listPermissions: (params: Maybe<{ versionId: string; listId: string }>) => [
    'version',
    params?.versionId || 'none',
    'listPermissions',
    params?.listId || 'none',
  ],
} as const

export const metricsAndListsMutationsOnlyKeys = {
  labels: (params: Omit<VersionLabelToggleParams, 'labelId'>) => [
    'version',
    params.versionId || 'none',
    'labels',
    params.entityType,
    params.entityId,
  ],
} as const
