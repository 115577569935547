import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { importActualsToVersion } from '../../api/versions-import'
import { invalidateAllVersionRelatedQueries } from '../../cache/invalidateAllVersionRelatedQueries'

export function useImportActualsToVersion(versionId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.importActuals(versionId),
    async (params: {
      periodStart: string
      periodEnd: string
    }): Promise<string> => {
      const result = await importActualsToVersion(
        versionId,
        params.periodStart,
        params.periodEnd,
      )
      return result.data?.task_id
    },
    {
      onSuccess: async () => {
        await invalidateAllVersionRelatedQueries(queryClient, versionId)
      },
    },
  )
}
