import {
  MetricDisplaySettings,
  MetricDisplaySettingsFormatting,
} from '@fintastic/web/util/metrics-and-lists'

export const createDisplaySettings = (): MetricDisplaySettings => ({
  ordering: 0,
  show_total: true,
  sorting: 'descending',
  currency_name: '',
  grouping: 'flat',
  numeric_alignment: 'left',
  formatting: createDefaultFormatting(),
  currency_sign_position: 'before',
  is_visible: true,
})

export const createDefaultFormatting = (): MetricDisplaySettingsFormatting => ({
  decimal_places: 2,
  prefix: '',
  suffix: '',
  compact_formatting: 'no_format',
  thousands_separator: true,
  negatives: '()',
  date_formatting: 'DD-MMM-YYYY',
  time_formatting: '24H',
})
