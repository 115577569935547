import React from 'react'
import {
  StyledPageLayoutContentLoaderCircularProgress,
  StyledPageLayoutContentLoaderRoot,
} from './PageLayoutContentLoader.styled'
import { usePageLayoutContext } from '../../contexts/page-layout-context'

export const PageLayoutContentLoader: React.FC = () => {
  const { disablePaddingVertical } = usePageLayoutContext()
  return (
    <StyledPageLayoutContentLoaderRoot
      data-testid="page-layout-content-loading"
      disablePaddingVertical={disablePaddingVertical}
    >
      <StyledPageLayoutContentLoaderCircularProgress />
    </StyledPageLayoutContentLoaderRoot>
  )
}
