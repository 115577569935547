import { useCallback, useMemo, useState } from 'react'

export type OperationState = {
  status: OperationStatus
}

export type OperationStatus = 'idle' | 'processing' | 'succeed' | 'failed'
export type OperationStateApi = ReturnType<typeof useOperationState>

export const useOperationState = () => {
  const [state, setState] = useState<OperationState>({
    status: 'idle',
  })

  const start = useCallback(() => {
    setState({
      status: 'processing',
    })
  }, [])

  const finish = useCallback((success: boolean) => {
    setState({
      status: success ? 'succeed' : 'failed',
    })
  }, [])

  const reset = useCallback(() => {
    setState({
      status: 'idle',
    })
  }, [])

  return useMemo(
    () =>
      ({
        ...state,
        start,
        finish,
        reset,
      } as const),
    [finish, reset, start, state],
  )
}
