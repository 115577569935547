import { removePrefixFromName } from '@fintastic/web/util/metrics-and-lists'

export function textCaseInsensitiveComparator(
  valueA: string,
  valueB: string,
): number {
  return `${valueA}`.toLowerCase().localeCompare(`${valueB}`.toLowerCase())
}

export function removePrefixComparator(valueA: string, valueB: string): number {
  return `${removePrefixFromName(valueA)}`
    .toLowerCase()
    .localeCompare(`${removePrefixFromName(valueB)}`.toLowerCase())
}
