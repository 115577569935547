import { useQuery } from 'react-query'
import { queryKeys } from './query-keys'
import { getReportsList } from '../api'
import { useIsReportEditorEnabled } from '@fintastic/web/feature/report-editor'

export const useLoadListOfReports = (versionId: string) => {
  const reportsEditorEnabled = useIsReportEditorEnabled()

  return useQuery(queryKeys.reportsList(versionId), async () => {
    if (!reportsEditorEnabled) {
      // @todo remove after release
      return []
    }

    const response = await getReportsList(versionId)
    return response.data.result
  })
}
