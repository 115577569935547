import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeListFormulaPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

// @todo add tests for formula state
export const changeListFormula = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeListFormulaPayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  if (!list.isCalculated()) {
    throw new Error("Can't change formula in non-calculated list")
  }

  if (list.formula() === payload.formula) {
    /**
     * @see EditableMetric
     */
    delete state.list.formulaInvalid
    return
  }

  if (payload.invalid) {
    state.list.formulaInvalid = true
    return
  }

  delete state.list.formulaCalculationError
  delete state.list.formulaInvalid

  list.changeFormula(payload.formula)
}
