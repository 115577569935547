import { SmartSelectOption } from '../types'
import { ComparatorResult } from '@fintastic/shared/util/types'

type RankedItem<T> = {
  item: T
  index: number
}

export const baseSorter = <T>(
  a: RankedItem<SmartSelectOption<T>>,
  b: RankedItem<SmartSelectOption<T>>,
): ComparatorResult => (a.index - b.index) as ComparatorResult
