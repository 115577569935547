import { useCallback, useMemo } from 'react'
import { ChangeColumnDimensionAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'
import { DataContextValue } from '../../data/data-context'

export function useChangeColumnDimensionFlow({
  dimensionsList,
}: DataContextValue) {
  const { changeDimension, changeName } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnDimensionAction>(
    (columnId, dimensionId) => {
      if (!dimensionsList) {
        return
      }
      changeDimension({ columnId, dimensionId, allDimensions: dimensionsList })
      const dimensionName =
        dimensionsList.find((d) => d.id === dimensionId)?.label || ''

      if (dimensionName) {
        changeName({
          columnId,
          newName: dimensionName,
          dimensionNames: dimensionsList.map((d) => d.label),
        })
      }
    },
    [changeDimension, changeName, dimensionsList],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
