export const applyCompactFormatting = (
  compact_formatting: 'no_format' | 'thousands' | 'millions',
  value: number,
): number => {
  if (compact_formatting === 'no_format') {
    return value
  }

  const numericValue = parseFloat(`${value}`)
  if (Number.isNaN(numericValue)) {
    return value
  }

  return numericValue / (compact_formatting === 'thousands' ? 1_000 : 1_000_000)
}
