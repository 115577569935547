import { SyntaxLiteralType } from './types'

export const literalColors: Record<SyntaxLiteralType, string> = {
  function: '#B62363',
  constant: '#263646',
  metric: '#135EF5',
  calculatedMetric: '#135EF5',
  list: '#147A03',
  calculatedList: '#147A03',
  listColumn: '#99491F',
  calculatedListColumn: '#99491F',
  dimension: '#7042C2',
  dimensionValue: '#467887',
}
