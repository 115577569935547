import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { RangesMap } from './types'
import type { Maybe } from '@fintastic/shared/util/types'
import type { TokenWithNode } from '../tokens/types'

// @todo add tests

export type ValidationHighlightContextValue = {
  ranges: RangesMap
  enabled: boolean
  highlightingToken: Maybe<TokenWithNode>
  setHighlightingToken: (element: Maybe<TokenWithNode>) => void
}

const stub = (): never => {
  throw new Error('ValidationHighlightContext method is called out of provider')
}

export const validationHighlightContext =
  createContext<ValidationHighlightContextValue>({
    ranges: {},
    enabled: false,
    highlightingToken: null,
    setHighlightingToken: stub,
  })

export const { Provider: ValidationHighlightContextProvider } =
  validationHighlightContext

export function useValidationHighlightContext() {
  return useContext(validationHighlightContext)
}

export function useValidationHighlightContextValue(
  rangesMap: RangesMap,
  isEditing: boolean,
  formulaValue: string,
) {
  const [highlightingToken, setHighlightingToken] =
    useState<Maybe<TokenWithNode>>(null)

  useEffect(() => {
    setHighlightingToken(null)
  }, [formulaValue])

  return useMemo<ValidationHighlightContextValue>(
    () => ({
      ranges: rangesMap,
      enabled: isEditing,
      highlightingToken,
      setHighlightingToken,
    }),
    [highlightingToken, isEditing, rangesMap],
  )
}
