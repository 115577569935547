import React from 'react'
import { Board } from '../../../types'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { BoardInfo } from './BoardInfo'
import { ConfigEditor } from './ConfigEditor'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export type PanelProps = {
  board: Board
  open: boolean
  onClose: () => void
}

export const Panel: React.FC<PanelProps> = ({ board, open, onClose }) => {
  const { name } = board

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Debug & Editing Panel for the board "{name}"
          </Typography>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <BoardInfo board={board} />
        <ConfigEditor board={board} />
      </Box>
    </Dialog>
  )
}
