import { List } from '@fintastic/web/util/metrics-and-lists'
import { VersionEntities } from '@fintastic/web/util/versions'
import {
  createMappingDictionary,
  mapFormula,
} from '@fintastic/web/util/formulas'
import { cloneDeep } from 'lodash'

export const mapAllFormulasInList = (
  sourceList: List,
  entities: VersionEntities,
  mappingType: 'idToLabel' | 'labelToId' = 'labelToId',
): List => {
  const list = cloneDeep(sourceList)
  const dictionary = createMappingDictionary(entities, mappingType)

  if (list.source === 'calculated' && list.metadata.formula) {
    list.metadata.formula = mapFormula(list.metadata.formula, dictionary)
  }

  if (list.source === 'input') {
    list.metrics.forEach((metric) => {
      if (metric.source !== 'calculated' || !metric.metadata.formula) {
        return
      }
      metric.metadata.formula = mapFormula(metric.metadata.formula, dictionary)
    })
  }

  return list
}
