import {
  MetricGridError,
  MetricDoesNotExistError,
  NetworkError,
  RestrictedByPermissionsError,
  UnknownError,
  VersionsAreNotSelectedError,
} from './errors'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricInVersionLoadingResult } from '../../../../hooks/useMetricInVersions'
import { AxiosError } from 'axios'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { metricEmptyBecauseOfPermissions } from '../../../shared/features/errors/metricEmptyBecauseOfPermissions'
import {
  UnsupportedGranularityAggregationError,
  isUnsupportedGranularityAggregationErrorResponse,
} from '@fintastic/web/util/period-selector'
import { compact } from 'lodash'

export const getMetricGridError = (
  versions: string[],
  queries: {
    metrics: MetricInVersionLoadingResult[]
  },
): Maybe<MetricGridError> => {
  if (versions.length === 0) {
    return new VersionsAreNotSelectedError()
  }

  const errors = compact(
    versions.map((i, index) =>
      getErrorFromFailedMetric(queries.metrics[index]),
    ),
  )

  return errors.length === versions.length ? errors[0] : null
}

const getErrorFromFailedMetric = (
  status: MetricInVersionLoadingResult,
): Maybe<MetricGridError> => {
  if (status) {
    return statusToMetricGridError(status)
  }
  return null
}

const statusToMetricGridError = (
  status: MetricInVersionLoadingResult,
): Maybe<MetricGridError> => {
  if (status.error) {
    if ('isAxiosError' in status.error && status.error.isAxiosError) {
      return axiosErrorToGridError(status.error)
    }
    return errorToGridError(status.error)
  }

  if (status.metric) {
    const error = metricToGridError(status.metric, status.originallyEmpty)
    if (error) {
      return error
    }
  }

  return null
}

const axiosErrorToGridError = (axiosError: AxiosError): MetricGridError => {
  if (axiosError.response?.status === 404) {
    return new MetricDoesNotExistError()
  }

  if (axiosError.response?.status === 403) {
    return new RestrictedByPermissionsError()
  }

  if (isUnsupportedGranularityAggregationErrorResponse(axiosError)) {
    return new UnsupportedGranularityAggregationError(axiosError)
  }

  return new NetworkError()
}

const errorToGridError = (error: Error): MetricGridError =>
  new UnknownError(error)

const metricToGridError = (
  metric: Metric,
  originallyEmpty: boolean,
): Maybe<MetricGridError> => {
  if (!originallyEmpty && metricEmptyBecauseOfPermissions(metric)) {
    return new RestrictedByPermissionsError()
  }
  return null
}
