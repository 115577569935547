import React, { useCallback } from 'react'
import { StyledNewBoardButton } from './BoardsSidebarSearch.styled'
import AddIcon from '@mui/icons-material/Add'
import { useLayoutStateIsSidebarOpened } from '@fintastic/shared/ui/app-layout-framework'
import { Tooltip } from '@mui/material'
import { useBoardState } from '@fintastic/web/feature/boards'

type BoardsSidebarNewBoardProps = {
  onNewBoard: () => void
}
export const BoardsSidebarNewBoard: React.FC<BoardsSidebarNewBoardProps> = ({
  onNewBoard,
}) => {
  const [, setIsSubmenuOpened] = useLayoutStateIsSidebarOpened()

  const createNewBoard = useCallback(() => {
    setIsSubmenuOpened(false)
    onNewBoard()
  }, [onNewBoard, setIsSubmenuOpened])

  const { isDesignMode } = useBoardState()

  return (
    <Tooltip title="Create Board">
      <span>
        <StyledNewBoardButton
          onClick={createNewBoard}
          disabled={isDesignMode}
          variant="outlined"
        >
          <AddIcon />
        </StyledNewBoardButton>
      </span>
    </Tooltip>
  )
}
