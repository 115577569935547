import { useCallback } from 'react'
import { Board, boardRoutes, useBoardState } from '@fintastic/web/feature/boards'
import { useNavigate } from 'react-router-dom'

export function useBoardOpen() {
  const navigate = useNavigate()
  const { setDesignMode } = useBoardState()

  return useCallback(
    (boardId: Board['id'], design?: boolean) => {
      navigate(boardRoutes.board(boardId))
      if (design) {
        // because by BoardContainer resets edit state between boardId change
        setTimeout(() => {
          setDesignMode()
        }, 500)
      }
    },
    [navigate],
  )
}
