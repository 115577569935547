import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { StyledChart, StyledLoadingChart } from './Chart.styled'
import { EChartContainer } from './EChartContainer'
import {
  ChartColumnData,
  ChartDisplaySettings,
  MergedMetricsData,
} from '../../types'
import { Maybe } from '@fintastic/shared/util/types'
import { defaultChartTheme } from '@fintastic/web/feature/charts'
import { EChartsOption } from 'echarts'
import { isEqual } from 'lodash'
import { encodeForBars, encodeForLines } from './processing'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'

type ChartProps = {
  isDesignMode?: boolean
  displaySettings: ChartDisplaySettings
  mergedMetricsData: Maybe<MergedMetricsData>
  loading?: boolean
}

export const Chart: React.FC<ChartProps> = ({
  displaySettings,
  isDesignMode,
  mergedMetricsData,
  loading,
}) => {
  const oldData = useRef<Maybe<ChartColumnData>>(null)
  const oldSettings = useRef<Maybe<ChartDisplaySettings>>(null)

  const oldOptions = useRef<Maybe<EChartsOption>>(null)

  const destroying = useRef(false)

  useEffect(() => {
    destroying.current = false
    return () => {
      destroying.current = true
    }
  }, [])

  const option = useMemo(() => {
    if (
      isEqual(oldData.current, mergedMetricsData?.data) &&
      isEqual(oldSettings.current, displaySettings) &&
      oldOptions.current
    ) {
      return oldOptions.current
    }

    oldData.current = mergedMetricsData?.data || null
    oldSettings.current = displaySettings

    let result: Maybe<EChartsOption> = null
    if (displaySettings.type === 'lines') {
      result = encodeForLines(
        mergedMetricsData?.data,
        mergedMetricsData?.metadata,
        displaySettings,
      )
    }

    if (displaySettings.type === 'bars') {
      result = encodeForBars(
        mergedMetricsData?.data,
        mergedMetricsData?.metadata,
        displaySettings,
      )
    }

    oldOptions.current = { ...result, animation: !isDesignMode }

    return oldOptions.current
  }, [displaySettings, mergedMetricsData, isDesignMode])

  const [openAtLeastOnce, setOpenAtLeastOnce] = useState(Boolean(isDesignMode))

  useLayoutEffect(() => {
    if (openAtLeastOnce || !option) {
      return
    }
    // first display animation
    setTimeout(() => {
      if (!destroying.current && !openAtLeastOnce) {
        setOpenAtLeastOnce(() => true)
      }
    }, 20 + Math.floor(Math.random() * 1000)) // to prevent complex simultaneous updates
  }, [openAtLeastOnce, option])

  return (
    <StyledChart>
      {loading ? (
        <StyledLoadingChart>
          <CenteredCircularProgress fullScreen={false} />
        </StyledLoadingChart>
      ) : (
        <EChartContainer
          option={openAtLeastOnce ? option : {}}
          theme={displaySettings.theme || defaultChartTheme}
        />
      )}
    </StyledChart>
  )
}
