import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setColumnAccess, setColumnMask } from '../ducks'
import { selectColumnMaskConfigForCurrentGroup } from '../ducks/selectors'
import { ColumnAccessType, ColumnId, DimensionMap } from '../types'

export const useColumnRestriction = () => {
  const dispatch = useDispatch()

  const columnMasking = useSelector(selectColumnMaskConfigForCurrentGroup)

  return useMemo(
    () => ({
      columnMasking,
      onColumnAccessChange: (id: ColumnId, access: ColumnAccessType) =>
        dispatch(setColumnAccess({ id, access })),
      onColumnMaskChange: (id: ColumnId, restrictions: DimensionMap) =>
        dispatch(setColumnMask({ id, restrictions })),
    }),
    [columnMasking, dispatch],
  )
}
