/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { RootState, State } from './state'
import type { Selector } from 'reselect'
import { namespace } from './state'
import { createSelector } from 'reselect'
import { BoardFolder } from '../../types'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s[namespace]

export const selectExpandedFolders: Selector<
  RootState,
  State['expandedFolders']
> = (s) => selectAll(s).expandedFolders

export const selectIsFolderExpanded = createSelector(
  [selectExpandedFolders, (_, folderId: BoardFolder['id']) => folderId],
  (expandedFolders, folderId: BoardFolder['id']) => !!expandedFolders[folderId],
)

export const selectBoardMode: Selector<RootState, State['boardMode']> = (s) =>
  selectAll(s).boardMode
