import {
  FormulaParser,
  BlankOrMaskedValue,
} from '@fintastic/web/util/blanks-and-masked'

const formulaCache: Record<string, FormulaParser> = {}

const getCompiledFormula = (formula: string) => {
  if (formulaCache[formula]) {
    return formulaCache[formula]
  }

  const compiled = new FormulaParser(formula)

  formulaCache[formula] = compiled

  return compiled
}

export const executeCalculatedRowFormula = (
  formula: string,
  scope: Record<string, BlankOrMaskedValue>,
): BlankOrMaskedValue => {
  const mappedFormula = wrapNamedVariablesIntoBrakets(formula, scope)

  const fObj = getCompiledFormula(mappedFormula)
  return fObj.evaluate(scope) as BlankOrMaskedValue
}

export const wrapNamedVariablesIntoBrakets = (
  formula: string,
  scope: Record<string, unknown>,
) =>
  Object.keys(scope).reduce(
    (mappedFormula, key) => mappedFormula.replaceAll(key, `[${key}]`),
    formula,
  )
