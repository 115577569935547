// https://docs.sheetjs.com/docs/getting-started/installation/frameworks
// https://www.npmjs.com/package/xlsx-js-style
import { isHeaderCell } from './utils'
import { HeaderCell } from './header-utils'
import { CellData } from './data-values'

export function prepareTempCSVArray(
  dataTable: Array<HeaderCell[] | CellData[]>,
) {
  return dataTable.map((row) => {
    const excelRow: Array<string | unknown> = []

    row.forEach((cellData) => {
      excelRow.push(isHeaderCell(cellData) ? cellData.label : cellData.value)
    })

    return excelRow
  })
}
