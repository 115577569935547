import { Box, BoxProps, styled, Typography } from '@mui/material'
import React from 'react'
import { defaultPillValueStyle } from './common-styles'

export const StyledDetailsCardValue = styled(
  (props: { empty?: boolean } & BoxProps) => {
    const { empty, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'empty',
  },
)`
  ${defaultPillValueStyle};

  color: ${({ theme, empty }) =>
    empty ? theme.palette.text.disabled : theme.palette.text.primary};
`

export const StyledTransitionIcon = styled('div')`
  display: inline-flex;
  padding: 0 ${({ theme }) => theme.spacing(1)};

  svg {
    width: 14px;
    height: 14px;
  }
`

export const StyledDetailsCard = styled(Box)`
  padding: 0 1em 1em 1em;
`

export const StyledDetailsCardHeader = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  height: 52px;
  font-weight: bold;
  align-items: center;
`

export const StyledDetailsCardHeaderIcon = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  display: flex;
  align-self: center;
`

export const StyledDetailsCardHeaderTitle = styled(Box)``

export const StyledDetailsCardHeaderSubTitle = styled(Typography)`
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const StyledDetailsCardContent = styled(Box)`
  display: flex;
  flex-flow: column wrap;
`

export const StyledDetailsCardRawContent = styled('pre')`
  font-size: 12px;
`
