import { styleKit } from '@fintastic/shared/ui/mui-theme'
import { GetCellEditorProps } from './types'

export const calculateColumnProps = (): GetCellEditorProps => ({
  valueFormatter: () => 'Save to calculate',

  cellStyle: () => ({
    color: styleKit.colors.general.grey,
    backgroundColor: styleKit.colors.general.notEditable,
  }),
})
