import { PeriodSelectionPickerConfig } from '../types'
import { PeriodSelection } from '@fintastic/web/util/period-selector'

export const getSelectedPeriodsLength = (
  c: PeriodSelectionPickerConfig,
  t: PeriodSelection,
): number => {
  const isRangeMode = 'range' in t
  const periodConfig = c.periodTypes.find(
    (pt) => pt.dimension_id === t.dimensionId,
  )

  if (!isRangeMode) {
    return t.periods.length
  }
  const rangeFromPeriodIndex =
    periodConfig?.periods.findIndex((p) => p.id === t.range[0]) ?? -1
  const rangeToPeriodIndex =
    periodConfig?.periods.findIndex((p) => p.id === t.range[1]) ?? -1
  if (rangeFromPeriodIndex === -1 || rangeToPeriodIndex === -1) {
    return 0
  }
  return rangeToPeriodIndex - rangeFromPeriodIndex + 1
}
