import React, { useEffect } from 'react'
import {
  ReferenceVersionSelect,
  useCommonDataWidgetSettings,
  useSetWidgetSettingsVersionErrorsEffect,
  useWidgetSettingsDialogContext,
} from '@fintastic/web/feature/boards'
import { FormField, TextField } from '@fintastic/shared/ui/form-framework'
import { useGenericReportWidgetSettings } from '../hooks/useGenericReportWidgetSettings'
import { WidgetSelectReports } from './WidgetSelectReports'

export const GenericReportWidgetForm: React.FC = () => {
  const { data, setPartialData, pendingApi } = useWidgetSettingsDialogContext()

  // common piece of data
  const {
    versionId,
    displayNameFieldState,
    handleSelectVersion,
    handleShowNoRefVersionMessage,
    showRefVersionProblem,
  } = useCommonDataWidgetSettings(data)

  // report-specific pieces of data
  const { reportId, handleSelectReport } = useGenericReportWidgetSettings(
    data,
    displayNameFieldState,
  )

  useEffect(() => {
    setPartialData({
      versionId: versionId || '',
      id: reportId || '',
      displayLabel: displayNameFieldState.trimmedValue || '',
    })
  }, [
    data,
    displayNameFieldState.trimmedValue,
    reportId,
    setPartialData,
    versionId,
  ])

  useSetWidgetSettingsVersionErrorsEffect(showRefVersionProblem)

  return (
    <>
      <FormField fullWidth={true}>
        <ReferenceVersionSelect
          value={versionId || ''}
          onNoRefVersion={handleShowNoRefVersionMessage}
          onSelect={handleSelectVersion}
          onFetching={pendingApi.addPendingProcess}
          onReady={pendingApi.finishPendingProcess}
        />
      </FormField>

      <FormField fullWidth={true}>
        <WidgetSelectReports
          value={reportId || ''}
          onSelect={handleSelectReport}
          onFetching={pendingApi.addPendingProcess}
          onReady={pendingApi.finishPendingProcess}
        />
      </FormField>

      <TextField
        label="Display name"
        fullWidth
        placeholder="Display name"
        value={displayNameFieldState.value}
        onChange={displayNameFieldState.setValue}
        error={
          displayNameFieldState.touched
            ? !displayNameFieldState.trimmedValue
            : false
        }
        errorMessage={
          displayNameFieldState.touched && !displayNameFieldState.trimmedValue
            ? 'Mandatory field'
            : undefined
        }
      />
    </>
  )
}
