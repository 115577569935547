import { NotAListError } from './errors'
import { WritableDraft } from '@fintastic/shared/data-access/redux'
import { State } from '../types'
import { ListEditingState } from '../../state'

export function castToListEditingState(
  state: WritableDraft<State>,
): WritableDraft<ListEditingState> | never {
  if (state.type !== 'list') {
    throw new NotAListError()
  }
  return state
}
