import { ThemeComponents } from './types'

export const MuiDialog: ThemeComponents['MuiDialog'] = {
  styleOverrides: {
    root: {
      '.MuiBackdrop-root': {
        background: 'rgba(38, 54, 70, 0.15)',
      },
    },
    paper: ({ theme }) => ({
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
      borderRadius: theme.shape.borderRadius,
    }),
  },
}
