import React from 'react'
import { LinearProgress } from '@mui/material'
import {
  GenericReportDetailsTable,
  GenericReportDetailsTableProps,
} from './GenericReportDetailsTable'

import { useGenericReportReportDetailsQuery } from './hooks/useGenericReportReportDetailsQuery'
import type {
  GenericReportDetailsQueryParams,
  GenericReportAggregatesRowMaskedData,
} from '@fintastic/web/util/generic-report'
import type { AgGridReact } from 'ag-grid-react'
import { useIsVersionEditable } from '@fintastic/web/data-access/versions'

export const GenericReportDetails: React.FC<
  {
    params: GenericReportDetailsQueryParams
    gridRef: React.RefObject<AgGridReact<GenericReportAggregatesRowMaskedData>>
    deeplinkWidgetId: string
  } & Pick<GenericReportDetailsTableProps, 'onGridReady'>
> = ({ params, gridRef, deeplinkWidgetId, onGridReady }) => {
  const {
    data: reportDetails,
    isLoading,
    isFetching,
  } = useGenericReportReportDetailsQuery(params)

  const isVersionEditable = useIsVersionEditable(params.reportOrVersionId)

  if (isLoading || isVersionEditable.isLoading) {
    return <LinearProgress />
  }

  if (!params || !reportDetails) {
    return null
  }

  return (
    <>
      {isFetching ? (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        />
      ) : (
        <div />
      )}
      <GenericReportDetailsTable
        gridRef={gridRef}
        // Report details get's "b" prefix to differentiate from upper table
        deeplinkWidgetId={`b${deeplinkWidgetId}`}
        reportDetails={reportDetails}
        onGridReady={onGridReady}
        versionEditable={isVersionEditable.editable}
      />
    </>
  )
}
