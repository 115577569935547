import {
  ChartLegendFormatSwitch,
  ChartLegendPositionSelect,
  defaultChartLegendFormat,
  defaultChartLegendPosition,
} from '@fintastic/web/feature/charts'

import React from 'react'

import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { ChartSettingsSection } from '../shared'

export const LegendSection: React.FC = () => {
  const { loading } = useChartSettingsContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartSettingsSection>
      <ChartLegendPositionSelect
        disabled={loading}
        legendPosition={
          localSettings.legendPosition || defaultChartLegendPosition
        }
        onChangeChartSettings={handleSettingsChange}
      />

      <ChartLegendFormatSwitch
        legendFormat={localSettings.legendFormat || defaultChartLegendFormat}
        disabled={loading}
        onChangeChartSettings={handleSettingsChange}
      />
    </ChartSettingsSection>
  )
}
