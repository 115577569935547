import { Box, styled } from '@mui/material'

export const StyledChartSaveButtons = styled(Box)`
  height: 48px;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`
