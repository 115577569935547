import { styled } from '@mui/material'
import { oldToNewValueTransitionClassNames } from '../old-and-new-values'

export const StyledRoot = styled('div')`
  padding: 0 16px 16px;
`

export const StyledLabel = styled('div')`
  margin-bottom: 3px;
`

export const StyledContent = styled('div')`
  .${oldToNewValueTransitionClassNames.root}
    .${oldToNewValueTransitionClassNames.icon} {
    margin: 0 8px;
  }
`
