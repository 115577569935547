import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { useCallback, useEffect, useRef } from 'react'
import type { FilterChangedEvent, GridReadyEvent } from 'ag-grid-community'
import { getFilterUrlValue, syncFilterValue } from './filter-utils'
import { useSyncDeeplinkValue } from '../useSyncDeeplinkValue'
import { isEqual } from 'lodash'
import { toast } from '@fintastic/shared/ui/toast-framework'

const defaultFilterValue = {}

export const useDeeplinkFilters = <TData>(
  deeplinkWidgetId: string,
  gridRef: React.RefObject<AgGridReactType<TData>>,
) => {
  const [urlFilter, setUrlFilter] = useSyncDeeplinkValue<
    Record<string, string[]>
  >({
    key: `w${deeplinkWidgetId}_filter`,
    defaultValue: defaultFilterValue,
  })

  const urlFilterRef = useRef<Record<string, string[]>>({})
  urlFilterRef.current = urlFilter

  // Ignore filter changes when programmatically update filters
  const stopFilterHandlingRef = useRef(false)

  const handleFilterChanged = useCallback(
    (e: FilterChangedEvent<TData>) => {
      if (stopFilterHandlingRef.current) {
        return
      }

      if (e.columns.length !== 1) {
        console.warn(
          'Only single column filter change event can be handled by useDeeplinkFilters',
        )
        return
      }

      const coldId = e.columns[0]?.getColId()

      if (!coldId) {
        console.warn('ColId is not provided to useDeeplinkFilters')
        return
      }

      const filterInstance = e.api.getFilterInstance(coldId)

      if (!filterInstance) {
        console.warn(
          'useDeeplinkFilters could not find filter instance by colId',
        )
        return
      }

      const nextFilterValue = getFilterUrlValue(filterInstance)
      const nextFilter = {
        ...urlFilter,
        [coldId]: nextFilterValue,
      }
      urlFilterRef.current = nextFilter
      setUrlFilter(nextFilter)
    },
    [setUrlFilter, urlFilter],
  )

  const prevUrlFilter = useRef<Record<string, string[]>>()
  const prevSyncUrlFilter = useRef<Record<string, string[]>>()

  const handleSyncInitialFilter = useCallback((e: GridReadyEvent<TData>) => {
    if (e.type === 'gridReady') {
      prevSyncUrlFilter.current = undefined
    }
    if (!urlFilterRef.current) {
      return
    }
    if (
      !gridRef.current ||
      isEqual(urlFilterRef.current, prevSyncUrlFilter.current)
    ) {
      return
    }
    stopFilterHandlingRef.current = true
    prevSyncUrlFilter.current = urlFilterRef.current
    syncFilterValue<TData>(e, urlFilterRef.current)
    stopFilterHandlingRef.current = false
  }, [])

  // Apply filters from the url
  useEffect(() => {
    if (!urlFilterRef.current) {
      return
    }

    if (
      !gridRef.current ||
      isEqual(urlFilterRef.current, prevUrlFilter.current)
    ) {
      return
    }

    stopFilterHandlingRef.current = true
    try {
      syncFilterValue<TData>(gridRef.current, urlFilterRef.current)
    } catch (ex) {
      console.error(ex)
      toast.error('Failed to apply deeplink filter')
    }

    prevUrlFilter.current = urlFilterRef.current
    stopFilterHandlingRef.current = false
  }, [urlFilter])

  return {
    handleFilterChanged,
    handleSyncInitialFilter,
  }
}
