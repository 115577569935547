import React from 'react'
import {
  ErrorAlertSeverity,
  StyledIcon,
  StyledMessage,
  StyledRoot,
  StyledText,
  StyledTitle,
} from './ErrorAlert.styled'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { WarningIcon } from '@fintastic/shared/ui/icons'
import { Maybe } from '@fintastic/shared/util/types'
import { Nullable } from '@fintastic/shared/util/functional-programming'

export type ErrorAlertProps = {
  title?: Nullable<React.ReactNode>
  message?: Nullable<React.ReactNode>
  tryAgainCallback?: Nullable<() => void>
  icon?: Nullable<React.ReactNode>
  severity?: Nullable<ErrorAlertSeverity>
  noPaddings?: boolean
  alignment?: Nullable<'center' | 'left'>
  children?: React.ReactNode
  ['data-testid']?: string
}

export type ErrorToUiMapping = {
  title: Maybe<React.ReactNode>
  message: Maybe<React.ReactNode>
  severity: ErrorAlertSeverity
  icon: Maybe<React.ReactNode>
}

export const ErrorAlert: React.FC<ErrorAlertProps> = (props) => {
  const {
    title = 'Something went wrong',
    message = 'Please try again or reach out administrator.',
    tryAgainCallback,
    icon = <WarningIcon />,
    severity = 'warning',
    noPaddings = false,
    alignment = 'center',
    children,
  } = props

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <StyledRoot
        noPaddings={!!noPaddings}
        alignment={alignment || 'center'}
        data-testid={props['data-testid']}
      >
        <StyledIcon
          severity={severity || 'warning'}
          data-testid="error-alert-icon"
        >
          <i>{icon}</i>
        </StyledIcon>
        <StyledText data-testid="error-alert-content">
          {title && (
            <StyledTitle data-testid="error-alert-title">{title}</StyledTitle>
          )}
          {(message || tryAgainCallback) && (
            <StyledMessage data-testid="error-alert-message">
              {message}
              {tryAgainCallback ? (
                <div data-testid="error-alert-try-again">
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault()
                      tryAgainCallback?.()
                    }}
                  >
                    Try again.
                  </a>
                </div>
              ) : null}
              {children}
            </StyledMessage>
          )}
        </StyledText>
      </StyledRoot>
    </FintasticThemeProvider>
  )
}
