// @todo add tests
import type { VersionEntities } from '@fintastic/web/util/versions'
import type { ObjectTokenRecognitionMap } from '@fintastic/web/feature/formulas'
import { wrapDimValueLabelInQuotesIfNecessary } from './helpers'
import { ClientOnlyMapping } from './types'

export function entitiesToRecognitionMap(
  entities: VersionEntities,
  clientOnlyMapping?: ClientOnlyMapping,
): ObjectTokenRecognitionMap {
  const map: ObjectTokenRecognitionMap = {}

  entities.metrics.forEach(({ label, source }) => {
    map[label] = source === 'calculated' ? 'calculatedMetric' : 'metric'
  })
  clientOnlyMapping?.metrics?.forEach((m) => {
    map[m.label] = m.source === 'calculated' ? 'calculatedMetric' : 'metric'
  })

  entities.lists.forEach(({ label, columns, source }) => {
    map[label] = source === 'calculated' ? 'calculatedList' : 'list'
    columns.forEach((column) => {
      map[`${label}.${column.label}`] =
        column.source === 'calculated' ? 'calculatedListColumn' : 'listColumn'
    })
  })
  clientOnlyMapping?.listColumns?.forEach((c) => {
    map[`${c.listLabel}.${c.label}`] =
      c.source === 'calculated' ? 'calculatedListColumn' : 'listColumn'
  })

  entities.dimensions.forEach((d) => {
    map[d.label] = 'dimension'
    Object.values('values' in d ? d.values : {}).forEach((valueLabel) => {
      map[`${d.label}.${wrapDimValueLabelInQuotesIfNecessary(valueLabel)}`] =
        'dimensionValue'
    })
  })

  return map
}
