import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useAutocompleteContext } from '../autocomplete-context'
import { usePopper } from 'react-popper'
import { Maybe } from '@fintastic/shared/util/types'
import { usePortal } from './usePortal'
import { StyledAutocompleteRoot } from './Autocomplete.styled'
import { List } from './List'
import { DATA_ATTRIBUTES } from './const'
import { useVirtualAnchorElement } from '../../popper-utils/useVirtualAnchorElement'

// @todo add tests
export const Autocomplete: React.FC = () => {
  const {
    editingToken,
    popperTarget,
    isShow: isShowByParent,
    suggestions,
    navigation,
  } = useAutocompleteContext()

  const [popperElement, setPopperElement] = useState<Maybe<HTMLElement>>(null)
  const virtualTargetNode = useVirtualAnchorElement(popperTarget)

  const { styles, attributes, update } = usePopper(
    virtualTargetNode,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'preventOverflow',
        },
      ],
    },
  )
  const selectedSuggestion = navigation?.selectedOptionId || null

  const portalRoot = usePortal()

  useEffect(() => {
    update?.()
  }, [suggestions, update])

  const isShow =
    isShowByParent &&
    portalRoot &&
    editingToken !== null &&
    editingToken.textBeforeCaret.length > 0

  const handleClickOnSuggestion = useCallback(
    (literal: string) => {
      if (!navigation) {
        return
      }
      navigation.applySelection(literal)
    },
    [navigation],
  )

  return (
    <>
      {isShow &&
        createPortal(
          <StyledAutocompleteRoot
            ref={setPopperElement}
            style={styles['popper']}
            {...attributes['popper']}
            {...{ [DATA_ATTRIBUTES.root]: '' }}
            data-testid="formula-editor-autocomplete-popper"
          >
            <List
              options={suggestions}
              highlighted={selectedSuggestion}
              onClickOnOption={handleClickOnSuggestion}
            />
          </StyledAutocompleteRoot>,
          portalRoot,
        )}
    </>
  )
}
