import React, { useEffect } from 'react'
import { IToolPanelParams } from 'ag-grid-community'
import { METRIC_SETTINGS_TOOL_PANEL_ID } from './tool-panel-definition'
import { useIsToolPanelOpened } from '../../shared/aggrid-tool-panel/useIsToolPanelOpened'
import { MetricSettingsPanel } from '../MetricSettingsPanel'
import { useDataContext } from '../connection/data/data-context'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { useActionsContext } from '../connection/actions/actions-context'

export type MetricSettingsToolPanelProps = IToolPanelParams

export const MetricSettingsToolPanel: React.FC<
  MetricSettingsToolPanelProps
> = ({ api }) => {
  const isOpened = useIsToolPanelOpened(METRIC_SETTINGS_TOOL_PANEL_ID, api)
  const { isLoaded, isLoading, load, metric } = useDataContext()
  const { flow } = useActionsContext()

  const isMetricCalculated = metric?.isCalculated()

  useEffect(() => {
    if (isOpened && !isLoaded && !isLoading) {
      load()
    }
  }, [isLoaded, isLoading, isOpened, load])

  useEffect(() => {
    if (!isMetricCalculated) {
      api.openToolPanel(METRIC_SETTINGS_TOOL_PANEL_ID)
    }
    if (flow === null) {
      api.closeToolPanel()
    }
  }, [api, flow, isMetricCalculated, metric])

  if (!isLoaded || isLoading) {
    return <CenteredCircularProgress fullScreen={false} />
  }

  return <MetricSettingsPanel />
}
