import React from 'react'
import {
  PanelToolbarButton,
  PanelToolbarButtonProps,
} from '@fintastic/shared/ui/panel-framework'
import {
  BaseGridRow,
  ToolbarRowsActionFn,
} from '@fintastic/shared/ui/grid-framework'
import { useRowsActionButton } from '../hooks/useRowsActionButton'

export type ToolbarRowsActionButtonProps<T extends BaseGridRow> = Omit<
  PanelToolbarButtonProps,
  'onClick'
> & {
  onClick: ToolbarRowsActionFn<T>
}

export const ToolbarRowsActionButton = <T extends BaseGridRow>({
  onClick,
  disabled,
  ...restProps
}: ToolbarRowsActionButtonProps<T>): JSX.Element | null => {
  const { ready, rowsSelected, handleClick } = useRowsActionButton({ onClick })

  if (!ready) {
    return null
  }

  return (
    <PanelToolbarButton
      onClick={handleClick}
      disabled={!rowsSelected || disabled}
      {...restProps}
    />
  )
}
