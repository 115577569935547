import { matchPath, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { boardRoutes } from '../routes'

export function useBoardPageParams() {
  const location = useLocation()

  const match = useMemo(
    () => matchPath<'id', string>(boardRoutes.board(), location.pathname),
    [location.pathname],
  )

  const boardId = match?.params.id

  return useMemo(
    () => ({
      id: boardId,
    }),
    [boardId],
  )
}
