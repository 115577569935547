import React from 'react'
import { ModalDialog } from '../ModalDialog'
import { ModalContent } from '../ModalContent'
import { ModalFooter } from '../ModalFooter'
import { ModalHeader } from '../ModalHeader'

export type ModalProps = {
  open: boolean
  fixAutofocus?: boolean
  onRequestClose?: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  showCloseButton?: boolean
  children?: React.ReactNode
  footerButtons?: React.ReactNode
  ['data-testid']?: string
  width?: number
  keepMounted?: boolean
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    open,
    onRequestClose,
    title,
    description,
    showCloseButton = true,
    fixAutofocus = false,
    children,
    footerButtons,
    width = 400,
    keepMounted = false,
  } = props

  return (
    <ModalDialog
      open={open}
      onClose={onRequestClose}
      disableRestoreFocus={fixAutofocus ? true : undefined}
      width={width}
      data-testid={props['data-testid']}
      keepMounted={keepMounted}
    >
      {(title !== undefined ||
        description !== undefined ||
        showCloseButton) && (
        <ModalHeader
          onRequestClose={onRequestClose}
          title={title}
          description={description}
          showCloseButton={showCloseButton}
        />
      )}
      {children && <ModalContent>{children}</ModalContent>}
      {footerButtons && <ModalFooter>{footerButtons}</ModalFooter>}
    </ModalDialog>
  )
}
