import { Box, IconButton, styled, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledSubmenuRoot = styled(Box)`
`
export const StyledSubmenuHeader = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'space-between',
  padding: `0 0 0 ${theme.spacingFromPixels(24)}`,

  height: theme.spacingFromPixels(64),
}))

export const StyledSubmenuHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  lineHeight: 1.2,
}))

export const StyledSubmenuHeaderButton = styled(IconButton)(({ theme }) => ({
  svg: {
    transform: 'rotate(180deg)',
  },
  width: '39px',
  marginRight: theme.spacing(1)
}))

export const StyledSubmenuMain = styled(Box)`
`

export const StyledSubmenuMainContainer = styled(Box)`
`
