import React, { memo, useMemo } from 'react'
import { StyledChartTable } from './ChartDebugDataTable.styled'
import { MergedMetricsData } from '../../../../../types'
import { AgGridReact } from 'ag-grid-react'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { ColDef } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import { getColDef } from './chart-data-table-utils'

type ChartDebugDataTableProps = {
  mergedMetricsData: Maybe<MergedMetricsData>
}

export const ChartDebugDataTable: React.FC<ChartDebugDataTableProps> = memo(
  ({ mergedMetricsData }) => {
    const colDefs: ColDef[] = useMemo(() => {
      if (!mergedMetricsData || mergedMetricsData.data.length === 0) {
        return []
      }

      const cols: ColDef[] = mergedMetricsData.data[0].map((d, idx) => ({
        field: (d ?? '').toString(),
        headerName: (d ?? '').toString(),
        ...(idx > 0 ? getColDef(mergedMetricsData?.metadata) : {}), // 0 - header column, string
      }))

      cols[0].flex = 1

      return cols
    }, [mergedMetricsData])

    const rowData = useMemo(() => {
      if (!mergedMetricsData || mergedMetricsData.data.length === 0) {
        return []
      }

      const columns = mergedMetricsData.data[0] // time jan feb
      const mappedData: any[] = []

      mergedMetricsData.data.forEach((row, idx) => {
        if (idx === 0) {
          return
        }

        const data = {}

        row.forEach((cell, colIdx) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[columns[colIdx]] = cell
        })

        mappedData.push(data)
      })
      return mappedData
    }, [mergedMetricsData])

    return (
      <StyledChartTable>
        <AgGridThemeFintasticWrapper
          sx={{
            flex: 1,
            width: '100%',
            height: '100%',
          }}
          themeOptions={{ variant: 'default' }}
        >
          {({ defaultGridProps }) => (
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              {...defaultGridProps}
            />
          )}
        </AgGridThemeFintasticWrapper>
      </StyledChartTable>
    )
  },
)
