import {
  Dimension,
  DimensionId,
  DimensionType,
  DimensionWithoutLabel,
  Maybe,
  toMaybe,
} from '@fintastic/shared/util/types'
import {
  DimensionValuesMap,
  VersionDimension,
} from './types'

type DimensionLike = Pick<Dimension, 'id' | 'type'>

// @todo add tests
export const toDimensionIds = (
  dims: DimensionLike[],
  predicate: (d: DimensionLike) => boolean,
): DimensionId[] => dims.filter(predicate).map((d) => d.id)

// @todo add tests
export const toDimensionIdsOfType = (
  dims: DimensionLike[],
  type: DimensionType,
): DimensionId[] => toDimensionIds(dims, (d) => d.type === type)

export const resolveDimensionValues = (
  d: VersionDimension,
  localRangeDims: DimensionWithoutLabel[],
): Maybe<DimensionValuesMap> =>
  toMaybe(
    d.type === 'Range'
      ? localRangeDims.find((rd) => rd.id === d.id)?.values
      : d.values,
  )
