import React from 'react'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import {
  MetricSettingsPanelDataProvider,
  MetricSettingsPanelActionsProvider,
} from '../../../../settings-panel/metric'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { useSettingsPanelData } from './useSettingsPanelData'
import { UseLocationResult } from '@fintastic/shared/util/memory-router'
import { NavigationContext } from '../../../../settings-panel/shared/connection/navigation/navigation-context'

export type SettingsPanelContextsWrapperProps = {
  versionId: Maybe<string>
  metric: Maybe<Metric>
  editingAllowed: boolean
  children: React.ReactNode
  location: UseLocationResult
}

export const SettingsPanelContextsWrapper: React.FC<
  SettingsPanelContextsWrapperProps
> = ({ versionId, metric, editingAllowed, children, location }) => {
  const { flow, type } = useCurrentEditingFlow()
  const data = useSettingsPanelData(versionId)

  return (
    <NavigationContext.Provider value={location}>
      <MetricSettingsPanelDataProvider metric={metric} {...data}>
        <MetricSettingsPanelActionsProvider
          flow={flow}
          readonly={!editingAllowed || type !== 'metric'}
        >
          {children}
        </MetricSettingsPanelActionsProvider>
      </MetricSettingsPanelDataProvider>
    </NavigationContext.Provider>
  )
}
