import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeMetricValueDataFormattingPayload } from '../../types'
import { State } from '../types'
import { castToMetricEditingState } from './helpers'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

export const changeMetricValueDataFormatting = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeMetricValueDataFormattingPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  const metric = new MutableMetricWrapper(state.metric)
  metric.applyFormattingChanges(payload)
}
