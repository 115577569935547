import { useIsFeatureEnabled } from '@fintastic/web/feature/config'
import { Maybe } from '@fintastic/shared/util/types'

const LOCAL_FEATURE_FLAG_ENABLED_OVERRIDE: Maybe<boolean> = null // @todo set to null before the merge to master!!!

export const useIsReportEditorEnabled = () => {
  const featureEnabled = useIsFeatureEnabled('enable_report_editor')
  return LOCAL_FEATURE_FLAG_ENABLED_OVERRIDE === null
    ? featureEnabled
    : LOCAL_FEATURE_FLAG_ENABLED_OVERRIDE
}
