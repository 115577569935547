import React, { useCallback, useMemo } from 'react'
import {
  ChartXAxisFormat,
  chartXAxisFormatKeys,
  chartXAxisFormats,
  defaultChartXAxisFormat,
  isChartXAxisFormat,
} from '@fintastic/web/feature/charts'
import { OnChangeChartSettings } from '../../../../internal-types'
import { GroupButtonField } from '@fintastic/shared/ui/form-framework'
import { Maybe } from '@fintastic/shared/util/types'

export type ChartXAxisFormatSwitchProps = {
  xAxisFormat: ChartXAxisFormat
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartXAxisFormatSwitch: React.FC<ChartXAxisFormatSwitchProps> = ({
  xAxisFormat,
  onChangeChartSettings,
  disabled,
}) => {
  const handleModeChange = useCallback(
    (value: Maybe<ChartXAxisFormat>) => {
      if (value) {
        onChangeChartSettings({
          xAxisFormat: isChartXAxisFormat(value)
            ? value
            : defaultChartXAxisFormat,
        })
      }
    },
    [onChangeChartSettings],
  )

  const options = useMemo(
    () =>
      chartXAxisFormatKeys.map((x) => ({
        value: x,
        title: chartXAxisFormats[x],
      })),
    [],
  )

  return (
    <GroupButtonField<ChartXAxisFormat>
      options={options}
      value={xAxisFormat || defaultChartXAxisFormat}
      title={'Labels format'}
      disabled={disabled}
      onChange={handleModeChange}
    />
  )
}
