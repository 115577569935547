import { useChartSettingsNavigationContext } from '../contexts'
import { useMemo } from 'react'
import {
  ChartSettingsSectionDefinition,
  chartSettingsSections,
} from '../routes/routes'

export const useChartSectionTitle = () => {
  const { path } = useChartSettingsNavigationContext()

  return useMemo(() => {
    const section: ChartSettingsSectionDefinition | undefined =
      chartSettingsSections.find((sm) => sm.path === path)

    if (!section) {
      return 'Unknown section'
    }

    return section.titleComponent || section.title || 'No title'
  }, [path])
}
