import { Box, styled } from '@mui/material'

export const StyledBoardArchivedRoot = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  place-content: center;
`


export const StyledBoardIsArchived = styled(Box)`
  place-self: center;
  color: ${({ theme }) => theme.palette.text.disabled};
`
