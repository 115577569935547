import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo, useMemo } from 'react'
import {
  getXAxisAlignmentName,
  getXAxisFormatName,
} from '@fintastic/web/feature/charts'

export const DialogSectionXAxisSubtitle: React.FC = memo(() => {
  const { data } = useWidgetSettingsDialogContext()

  const title = useMemo(
    () =>
      getXAxisAlignmentName(data.chartSettings?.xAxisAlignment) +
      ', ' +
      getXAxisFormatName(data.chartSettings?.xAxisFormat),
    [data.chartSettings?.xAxisAlignment, data.chartSettings?.xAxisFormat],
  )

  return <span>{title}</span>
})
