import React, { useCallback, useEffect, useMemo, useState } from 'react'

export function useTextFieldState(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleSetValue = useCallback((value: string) => {
    setValue(value)
    setTouched(() => true)
  }, [setValue])

  const assignValue = useCallback((value: string) => {
    setValue(value)
    setTouched(() => false)
  }, [setValue])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setValue(event.target.value)
    },
    [],
  )

  return useMemo(
    () => ({
      value,
      setValue: handleSetValue,
      assignValue,
      touched,
      handleChange,
      trimmedValue: value.trim(),
    }),
    [handleChange, value, touched, assignValue],
  )
}
