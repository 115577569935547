import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnDimensionPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnDimension = (
  uncheckedState: State,
  {
    payload: { columnId, dimensionId, allDimensions },
  }: PayloadAction<ChangeColumnDimensionPayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  if (list.isCalculated()) {
    // @todo move this check to list ADT
    throw new Error("Can't change column dimension in calculated list")
  }

  list
    .findColumnByClientId(columnId)
    ?.changeValueDimension(dimensionId, allDimensions)
}
