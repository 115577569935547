/**
 * This component should be used only once in thw whole app
 */

import React, { useEffect } from 'react'
import { useToaster, toast } from 'react-hot-toast/headless'
import type { ToastPosition, ToasterProps } from 'react-hot-toast/headless'
import { ToastWrapper } from './ToastWrapper'
import { ToastBar } from './ToastBar'
import { prefersReducedMotion } from '../utils'
import { Box } from '@mui/material'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

// copied from https://github.com/timolins/react-hot-toast/blob/main/src/components/toaster.tsx
const getPositionStyle = (
  position: ToastPosition,
  offset: number,
): React.CSSProperties => {
  const top = position.includes('top')
  const verticalStyle: React.CSSProperties = top ? { top: 0 } : { bottom: 0 }
  const horizontalStyle: React.CSSProperties = position.includes('center')
    ? {
        justifyContent: 'center',
      }
    : position.includes('right')
    ? {
        justifyContent: 'flex-end',
      }
    : {}
  return {
    left: 0,
    right: 0,
    display: 'flex',
    position: 'absolute',
    transition: prefersReducedMotion()
      ? undefined
      : 'all 230ms cubic-bezier(.21,1.02,.73,1)',
    transform: `translateY(${offset * (top ? 1 : -1)}px)`,
    ...verticalStyle,
    ...horizontalStyle,
  }
}

const DEFAULT_OFFSET = 16

// basically copied from https://github.com/timolins/react-hot-toast/blob/main/src/components/toaster.tsx
// but then modified
export const Toaster: React.FC<ToasterProps & { limit?: number }> = ({
  reverseOrder,
  position = 'bottom-center',
  toastOptions,
  gutter,
  children,
  containerStyle,
  containerClassName,
  limit = 10,
}) => {
  const { toasts, handlers } = useToaster({
    error: {
      duration: Infinity,
      ...toastOptions?.error,
    },
    ...toastOptions,
  })

  // hide toasts that are out of limit
  useEffect(() => {
    toasts.forEach((t, index) => {
      if (index >= limit) {
        toast.dismiss(t.id)
      }
    })
  }, [limit, toasts])

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <Box
        sx={{
          position: 'fixed',
          zIndex: (theme) => theme.zIndex.snackbar,
          top: DEFAULT_OFFSET,
          left: DEFAULT_OFFSET,
          right: DEFAULT_OFFSET,
          bottom: DEFAULT_OFFSET,
          pointerEvents: 'none',
          ...containerStyle,
        }}
        className={containerClassName}
        onMouseEnter={handlers.startPause}
        onMouseLeave={handlers.endPause}
      >
        {toasts.map((t) => {
          const toastPosition = t.position || position
          const offset = handlers.calculateOffset(t, {
            reverseOrder,
            gutter,
            defaultPosition: position,
          })
          const positionStyle = getPositionStyle(toastPosition, offset)

          return (
            <ToastWrapper
              id={t.id}
              key={t.id}
              onHeightUpdate={handlers.updateHeight}
              visible={t.visible}
              style={positionStyle}
            >
              <ToastBar toast={t} position={toastPosition} />
            </ToastWrapper>
          )
        })}
      </Box>
    </FintasticThemeProvider>
  )
}
