import React, { useEffect } from 'react'
import {
  SetGridSizeCallback,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import {
  Panel,
  PanelLoadingOverlay,
} from '@fintastic/shared/ui/panel-framework'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Button, Tooltip } from '@mui/material'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { ActiveReportEditorApi, useReportEditorApi } from '../public-api'
import { WidgetReportIcon } from '@fintastic/shared/ui/icons'
import { AgGridConnector } from './AgGridConnector'
import { useLocation } from '@fintastic/shared/util/memory-router'
import {
  ReportSettingsPanelNavigationContext,
  settingsPanelNavigationDefaultPathHistory,
} from '../settings-panel-navigation'

export const ReportEditor: React.FC<{
  setGridSizeCallback: SetGridSizeCallback
}> = ({ setGridSizeCallback }) => {
  const api = useReportEditorApi() as ActiveReportEditorApi

  const isValid = true // @todo implement
  const isLoading = false // @todo implement

  useEffect(() => {
    setGridSizeCallback({
      // fixed sizes used because it anyway works
      headerHeight: 40,
      rowHeight: 40,
      rows: 1,
    })
  }, [setGridSizeCallback])

  const routerLocationApi = useLocation(
    settingsPanelNavigationDefaultPathHistory,
  )

  return (
    <ReportSettingsPanelNavigationContext.Provider value={routerLocationApi}>
      <Panel
        fullHeight={true}
        isLoading={isLoading}
        overlay={
          api.saveOperationApi.isLoading ? (
            <PanelLoadingOverlay text="Saving Report" />
          ) : null
        }
        topbar={
          <TopBar
            title={titleFormatter(api.reportDefinition.name)}
            titleIcon={
              <Tooltip title="Report">
                <WidgetReportIcon
                  sx={{
                    color: '#D45702',
                  }}
                />
              </Tooltip>
            }
            rightContent={
              <FintasticThemeProvider applyLegacyTheme={false}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isValid || api.saveOperationApi.isLoading}
                  onClick={() => api.saveOperationApi.mutateAsync()}
                  sx={{ mr: 1 }}
                >
                  {api.saveOperationApi.isLoading
                    ? 'Saving...'
                    : 'Save to version'}
                </Button>
                <Button
                  variant="outlined"
                  color="black"
                  disabled={api.discardOperationApi.isLoading}
                  onClick={() => api.discardOperationApi.mutateAsync({})}
                >
                  {api.discardOperationApi.isLoading
                    ? 'Cancelling...'
                    : 'Cancel'}
                </Button>
              </FintasticThemeProvider>
            }
          />
        }
      >
        <AgGridConnector />
      </Panel>
    </ReportSettingsPanelNavigationContext.Provider>
  )
}
