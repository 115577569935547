import { versionsCacheKeys } from '../../cache'

export const mutationKeys = {
  updateVersion: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'update',
  ],
  copyVersion: () => [...versionsCacheKeys.versionPrefix('shared'), 'copy'],
  lockVersion: (id: string) => [...versionsCacheKeys.versionPrefix(id), 'lock'],
  unlockVersion: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'unlock',
  ],
  archiveVersions: (ids: string[]) => ['version', 'archive', ...ids],
  unarchiveVersions: (ids: string[]) => ['version', 'unarchive', ...ids],
  deleteVersions: (ids: string[]) => ['version', 'delete', ...ids],
  extendVersion: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'extend',
  ],
  lockVersionForUser: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'lockVersionForUser',
  ],
  releaseVersionUserLocker: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'releaseVersionUserLocker',
  ],
  importActuals: (id: string) => [
    ...versionsCacheKeys.versionPrefix(id),
    'importActuals',
  ],
  setDescription: (versionId: string, entityType: string, entityId: string) => [
    'version',
    'setEntityDescription',
    versionId,
    entityType,
    entityId,
  ],
} as const
