import { useCallback, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  useSaveMetric,
  useCurrentEditingFlow,
  useEditingMetric,
  useCancelEditing,
  useMetricsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useSettingsSavingFlags } from '../../../shared/features/settings/useSettingsSavingFlags'
import { useSubscribeToCalculationProgressEvent } from '@fintastic/web/data-access/service-pusher'
import { useReleaseVersionUserLocker } from '@fintastic/web/data-access/versions'
import { ImmutableCalculationProgressEvent } from '@fintastic/web/data-access/calc'

type Params = {
  versionId: Maybe<string>
  metricId: Maybe<string>
  onCreated?: (metricId: string) => void
}

export function useSaveFlow({ metricId, onCreated, versionId }: Params) {
  const { type, flow, isValid } = useCurrentEditingFlow()
  const isNewMetric = flow === 'creation'
  const { mutate: releaseVersionUserLocker } =
    useReleaseVersionUserLocker(versionId)

  const { changeFormulaState } = useMetricsActions()
  const editingMetric = useEditingMetric()
  const cancelEditing = useCancelEditing()
  const savingFlags = useSettingsSavingFlags()

  // useCentralisedVersionsEventsListener will automatically invalidate the version's cache
  const { mutate } = useSaveMetric(versionId)

  const handleSuccessEditing = useCallback(() => {
    savingFlags.reset()
    cancelEditing()
    if (isNewMetric && metricId && onCreated) {
      onCreated(metricId)
    }
    releaseVersionUserLocker()
  }, [
    cancelEditing,
    isNewMetric,
    metricId,
    onCreated,
    releaseVersionUserLocker,
    savingFlags,
  ])

  const handleFailedEditing = useCallback(
    (event?: ImmutableCalculationProgressEvent) => {
      savingFlags.reset()
      toast.error(
        isNewMetric ? 'Failed to create metric' : 'Failed to save metric',
      )
      if (
        event &&
        event.errorCausedByEntity(metricId || '') &&
        event.errorMessage
      ) {
        changeFormulaState({
          valid: false,
          error: event.errorMessage,
        })
      }
    },
    [changeFormulaState, isNewMetric, metricId, savingFlags],
  )

  useSubscribeToCalculationProgressEvent(
    [versionId],
    useCallback(
      async (event) => {
        if (!savingFlags.isWaitingForCalcEvent) {
          return
        }
        if (event.successful()) {
          handleSuccessEditing()
        }
        if (event.failed()) {
          handleFailedEditing(event)
        }
      },
      [
        handleFailedEditing,
        handleSuccessEditing,
        savingFlags.isWaitingForCalcEvent,
      ],
    ),
  )

  const handleAction = useCallback(() => {
    if (type !== 'metric') {
      throw new Error('Wrong flow: not a metric')
    }
    if (!isValid) {
      throw new Error('Validation failed')
    }
    if (!editingMetric) {
      throw new Error('Editing metric is not set')
    }

    savingFlags.markSaveRequestAsRunning()
    savingFlags.startWaitingForCalcEvent()
    mutate(
      {
        isNewMetric,
        metric: editingMetric,
      },
      {
        onSuccess: () => {
          savingFlags.markSaveRequestAsNotRunning()
        },
        onError: () => handleFailedEditing(),
      },
    )
  }, [
    type,
    isValid,
    editingMetric,
    savingFlags,
    mutate,
    isNewMetric,
    handleFailedEditing,
  ])

  return useMemo(
    () => ({
      action: handleAction,
      isSaving:
        savingFlags.isSaveRequestRunning || savingFlags.isWaitingForCalcEvent,
    }),
    [
      handleAction,
      savingFlags.isSaveRequestRunning,
      savingFlags.isWaitingForCalcEvent,
    ],
  )
}
