import React, { useCallback } from 'react'
import {
  ReportingGroupsView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

// @todo RESP connect
export const ReportingGroupsViewConnector: React.FC = () => {
  const { goBack, goTo } = useReportSettingsPanelNavigationContext()
  const goToReportingGroup = useCallback(
    (id: string) => {
      goTo(settingsPanelNavigationPaths.reportingGroupSettings(id))
    },
    [goTo],
  )

  return (
    <ReportingGroupsView.Readonly
      goBack={goBack}
      goToReportingGroup={goToReportingGroup}
      groups={[
        {
          id: '1',
          label: 'Reporting Group 1',
        },
        {
          id: '2',
          label: 'Reporting Group 2',
        },
      ]}
    />
  )
}
