export type DecimalPlacesOption = {
  label: string
  value: number
  example: string
}

export const decimalPlacesOptions: DecimalPlacesOption[] = [
  {
    value: 0,
    label: '0 digits',
    example: '',
  },
  {
    value: 1,
    label: '1 digit',
    example: '.0',
  },
  ...[2, 3, 4, 5, 6].map((value) => ({
    value,
    label: `${value} digits`,
    example: `.${Array(value).fill('0').join('')}`,
  })),
]
