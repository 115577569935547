import { Box, styled } from '@mui/material'

export const StyledBoardShareBusy = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -56px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: grayscale(1);
  z-index: 10;
`

export const StyledServerSideData = styled(Box)`
  button {
    color: ${({ theme }) => theme.palette.text.secondary};

    svg {
      path: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`

export const StyledServerSideDataHeader = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(2)} 0;

  div {
    margin-left: auto;
  }
`
