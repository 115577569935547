import React, { useCallback, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { LayoutSidebarNavSectionConfig } from '../../types'
import {
  StyledSubmenuRoot,
  StyledSubmenuHeader,
  StyledSubmenuMain,
  StyledSubmenuMainContainer,
  StyledSubmenuHeaderTitle,
  StyledSubmenuHeaderButton,
} from './Submenu.styled'
import { ArrowHorizontalIcon } from '../ArrowHorizontalIcon'
import {
  useLayoutStateIsSidebarOpened,
  useLayoutStateSidebarSubmenu,
} from '../../ducks/layout/hooks'
import { useAppLayoutContext } from '../../contexts/app-layout-context'

export const Submenu: React.FC = () => {
  const [submenu] = useLayoutStateSidebarSubmenu()
  const sidebar = useAppLayoutContext().sidebarConfig

  const config = useMemo<Maybe<LayoutSidebarNavSectionConfig>>(
    () =>
      sidebar.top.find((sectionConfig) => sectionConfig.key === submenu) ||
      sidebar.footer.find((sectionConfig) => sectionConfig.key === submenu) ||
      null,
    [sidebar, submenu],
  )

  const menu = config?.type === 'menu' ? config.menu : null

  const [, setIsSubmenuOpened] = useLayoutStateIsSidebarOpened()

  const handleHideSubmenu = useCallback(() => {
    setIsSubmenuOpened(false)
  }, [setIsSubmenuOpened])

  return (
    <StyledSubmenuRoot data-testid="sidebar-submenu">
      <StyledSubmenuHeader>
        <StyledSubmenuHeaderTitle>{config?.title}</StyledSubmenuHeaderTitle>
        <StyledSubmenuHeaderButton
          data-testid="sidebar-submenu-close-button"
          onClick={handleHideSubmenu}
        >
          <ArrowHorizontalIcon />
        </StyledSubmenuHeaderButton>
      </StyledSubmenuHeader>
      {menu && (
        <StyledSubmenuMain data-testid="sidebar-submenu-menu">
          <StyledSubmenuMainContainer>
            {menu}
          </StyledSubmenuMainContainer>
        </StyledSubmenuMain>
      )}
    </StyledSubmenuRoot>
  )
}
