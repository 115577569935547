import {
  styled,
  Popper,
  autocompleteClasses,
  Autocomplete,
  outlinedInputClasses,
  inputBaseClasses,
} from '@mui/material'

export const StyledAutocomplete = styled(Autocomplete)`
  height: 100%;
  line-height: 100%;

  .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall} {
    padding-top: 7px;
    padding-left: 10px;
    height: 100%;
  }

  .${autocompleteClasses.endAdornment} button {
    padding: 0;
    width: 22px;
    height: 28px;
  }

  .${autocompleteClasses.popupIndicator} {
    outline: none;
    border: none;
    background: none;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .${autocompleteClasses.popupIndicator}:hover {
    background: none;
  }
`

export const StyledPopper = styled(Popper)`
  & .${autocompleteClasses.listbox} {
    box-sizing: border-box;

    & ul {
      padding: 0;
      margin: 0;
    }
  }
`
