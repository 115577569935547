/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { AuthUserInfo } from '../../types'
import type { Maybe } from '@fintastic/shared/util/types'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { State } from './state'
import type { WritableDraft } from '@fintastic/shared/data-access/redux'

export type UserInfoLoadStart = PayloadAction

function userInfoLoadStart(
  state: WritableDraft<State>,
  action: UserInfoLoadStart,
): void {
  state.status = 'loading'
  state.error = null
  state.userInfo = undefined
}

export type UserInfoLoadFinishPayload = {
  error: Maybe<string>
  userInfo?: AuthUserInfo
}
export type UserInfoLoadFinish = PayloadAction<UserInfoLoadFinishPayload>

function userInfoLoadEnd(
  state: WritableDraft<State>,
  action: UserInfoLoadFinish,
): void {
  const { error, userInfo } = action.payload
  state.error = error
  if (error !== null) {
    state.status = 'error'
    state.userInfo = undefined
  } else {
    state.status = 'success'
    state.userInfo = userInfo
  }
}

export default {
  userInfoLoadStart,
  userInfoLoadEnd,
}
