import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import type { Maybe } from '@fintastic/shared/util/types'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Box, TextFieldProps } from '@mui/material'
import { AgGridCellEditorProps } from '../types'
import {
  AG_CELL_EDITOR_CLASS_NAME,
  AG_DATE_CELL_EDITOR_CLASS_NAME,
} from '../classNames'
import { CalendarIcon } from '@fintastic/shared/ui/icons'

export type AgGridDateCellEditorProps = {
  // date-fns string format
  displayingFormat?: string
  // date-fns string format
  storingFormat?: string
  minDateField?: string
  maxDateField?: string
}

// ATTENTION: do not change my styles here,
// change them in frontend/main-client/libs/shared/ui/ag-grid-theme-fintastic/src/lib/theme-variants/default.ts
export const AgGridDateCellEditor = memo(
  forwardRef<unknown, AgGridCellEditorProps & AgGridDateCellEditorProps>(
    ({ afterInput, ...props }, ref) => {
      const {
        displayingFormat = 'yyyy-MM-dd',
        storingFormat = 'yyyy-MM-dd',
        minDateField,
        maxDateField,
      } = props
      const data: Record<string, unknown> = props.data
      const [value, setValue] = useState<Date | null>(
        props.value ? new Date(props.value) : null,
      )
      const inputRef = React.useRef<HTMLInputElement>(null)

      const minDate = useMemo(() => {
        if (minDateField && data[minDateField]) {
          return dayjs(data[minDateField] as string)
            .add(1, 'day')
            .toDate()
        }
        return null
      }, [minDateField, data])

      const maxDate = useMemo(() => {
        if (maxDateField && data[maxDateField]) {
          return dayjs(data[maxDateField] as string)
            .subtract(1, 'day')
            .toDate()
        }
        return null
      }, [maxDateField, data])

      useEffect(() => {
        window.setTimeout(() => {
          inputRef.current?.focus()
        }, 0)
      }, [])

      useImperativeHandle(ref, () => ({
        getValue() {
          if (value instanceof Date && Number.isNaN(value.getTime())) {
            return null
          }
          return value ? format(value, storingFormat) : null
        },
        isCancelBeforeStart() {
          return false
        },
        isCancelAfterEnd() {
          return false
        },
      }))

      const handleChangeInput = useCallback(
        (date: Maybe<Date> | DateValidationError) => {
          if (date instanceof Date) {
            setValue(date)
          }
        },
        [],
      )

      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            position="relative"
            className={
              AG_CELL_EDITOR_CLASS_NAME + ' ' + AG_DATE_CELL_EDITOR_CLASS_NAME
            }
          >
            <DatePicker
              format={displayingFormat}
              value={value}
              minDate={minDate || undefined}
              maxDate={maxDate || undefined}
              onChange={handleChangeInput}
              slotProps={{
                textField: {
                  size: 'small',
                } as TextFieldProps,
              }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />
            {afterInput}
          </Box>
        </LocalizationProvider>
      )
    },
  ),
)

export default AgGridDateCellEditor
