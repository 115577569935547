import { ChartDisplaySettings } from '@fintastic/web/feature/charts'

export const processGridSettings = (
  option: any,
  settings: ChartDisplaySettings,
) => {
  if (!option) {
    return
  }

  if (!option.xAxis) {
    option.xAxis = {}
  }

  if (!option.yAxis) {
    option.yAxis = {}
  }

  let showX = false
  let showY = false

  if (settings.gridMode === 'none' || !settings.gridMode) {
    //
  }

  if (settings.gridMode === 'vertical') {
    showX = true
    showY = false
  }

  if (settings.gridMode === 'horizontal') {
    showX = false
    showY = true
  }

  if (settings.gridMode === 'both') {
    showX = true
    showY = true
  }

  option.xAxis.splitLine = {
    ...option.xAxis.splitLine,
    show: showX,
  }
  option.yAxis.splitLine = {
    ...option.yAxis.splitLine,
    show: showY,
  }
  option.xAxis.axisLine = {
    ...option.xAxis.axisLine,
    show: true,
  }
  option.yAxis.axisLine = {
    ...option.yAxis.axisLine,
    show: true,
  }
}
