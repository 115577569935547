import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import type { DimensionId } from '@fintastic/shared/util/types'
import {
  UnrestrictedDimensionsItem,
  UnrestrictedDimensionsResult,
  putDimensionRestrictions,
} from '../api'
import { unrestrictedDimensionsQueryKey } from './useUnrestrictedDimensionsQuery'
import { compact } from 'lodash'
import toast from 'react-hot-toast'
import { globalDimensionsKey } from '@fintastic/web/data-access/model-globals'

export const usePatchUnrestrictedDimensionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<void>,
    AxiosError,
    {
      dimensionIds: DimensionId[]
      isRestricted: boolean
    },
    {
      previousDimensions: UnrestrictedDimensionsResult
    }
  >(
    ['settings', 'unrestricted-dimensions', 'patch'],
    ({ dimensionIds, isRestricted }) =>
      putDimensionRestrictions(dimensionIds, isRestricted),
    {
      onMutate({ dimensionIds, isRestricted }) {
        queryClient.cancelQueries(unrestrictedDimensionsQueryKey)
        const previousDimensions =
          queryClient.getQueryData<UnrestrictedDimensionsResult>(
            unrestrictedDimensionsQueryKey,
          ) || []

        if (isRestricted) {
          queryClient.setQueryData<UnrestrictedDimensionsResult>(
            unrestrictedDimensionsQueryKey,
            (prev) =>
              compact(
                (prev ?? [])?.filter(
                  (d) => dimensionIds.includes(d.resource_id) === false,
                ),
              ),
          )
        } else {
          queryClient.setQueryData<UnrestrictedDimensionsResult>(
            unrestrictedDimensionsQueryKey,
            (prev) => [
              ...(prev ?? []),
              ...dimensionIds.map(
                (d) =>
                  ({
                    resource_id: d,
                    resource_type: 'dimension',
                  } as UnrestrictedDimensionsItem),
              ),
            ],
          )
        }

        return { previousDimensions }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData(
          unrestrictedDimensionsQueryKey,
          ctx?.previousDimensions || [],
        )

        console.error(err)
        toast.error(
          `Failed to update dimension restriction. Error: ${
            err?.code || 'unknown'
          }`,
        )
      },
      async onSettled() {
        return await Promise.all([
          queryClient.invalidateQueries(unrestrictedDimensionsQueryKey),
          queryClient.invalidateQueries(globalDimensionsKey),
          // force default dimensions to reload
          // invaldiation does not work for some reason
          queryClient.removeQueries([
            ...globalDimensionsKey,
            'true',
            'true',
            'dynamic%2Cmanual',
          ]),
        ])
      },
    },
  )
}
