import React from 'react'

export const Logo: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fintastic-logo"
  >
    <g clipPath="url(#clip0_191_8503)">
      <path
        d="M8.00341 26.8093V23.1087H6.18609C3.56524 23.1087 1.44067 25.2052 1.44067 27.7914V31.492H3.25854C5.87884 31.492 8.00341 29.3955 8.00341 26.8093Z"
        fill="url(#paint0_linear_191_8503)"
      />
      <path
        d="M15.3675 26.8093V14.8109H13.5496C10.9288 14.8109 8.8042 16.9074 8.8042 19.4936V31.492H10.6221C13.2429 31.492 15.3675 29.3955 15.3675 26.8093Z"
        fill="url(#paint1_linear_191_8503)"
      />
      <path
        d="M22.731 26.8093V7.57049H20.9131C18.2923 7.57049 16.1677 9.66698 16.1677 12.2532V31.492H17.9856C20.6064 31.492 22.731 29.3955 22.731 26.8093Z"
        fill="url(#paint2_linear_191_8503)"
      />
      <path
        d="M30.0945 26.0497V0.27523H28.2767C25.6558 0.27523 23.5312 2.712 23.5312 5.71751V31.492H25.3491C27.97 31.492 30.0945 29.0552 30.0945 26.0497Z"
        fill="url(#paint3_linear_191_8503)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_191_8503"
        x1="0.957384"
        y1="31.0154"
        x2="8.38657"
        y2="23.4867"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA726" />
        <stop offset="1" stopColor="#FF5722" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_191_8503"
        x1="6.21878"
        y1="28.9409"
        x2="17.7966"
        y2="17.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA726" />
        <stop offset="1" stopColor="#FF5722" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_191_8503"
        x1="11.7481"
        y1="27.1308"
        x2="26.9458"
        y2="11.7295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA726" />
        <stop offset="1" stopColor="#FF5722" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_191_8503"
        x1="17.2635"
        y1="25.307"
        x2="36.1085"
        y2="6.20965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA726" />
        <stop offset="1" stopColor="#FF5722" />
      </linearGradient>
      <clipPath id="clip0_191_8503">
        <rect width="30" height="32" fill="white" transform="translate(1)" />
      </clipPath>
    </defs>
  </svg>
)
