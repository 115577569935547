import React from 'react'
import {
  ChangeCurrencySignPositionCallback,
  FormattingFieldsetProps,
} from './types'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { dataTypeToFormattingFieldsetMap } from './typeToFieldsetMap'
import {
  StyledRoot,
  StyledDivider,
  StyledTitle,
  StyledFields,
} from './Formatting.styled'

export type FormattingProps = FormattingFieldsetProps & {
  dataType: MetricConfigurableDataValueType
  onChangeCurrencySignPosition: ChangeCurrencySignPositionCallback
}

export const Formatting: React.FC<FormattingProps> = ({
  dataType,
  ...rest
}) => {
  const FieldsetComponent = dataTypeToFormattingFieldsetMap[dataType]

  if (!FieldsetComponent) {
    return null
  }

  return (
    <StyledRoot>
      <StyledDivider />
      <StyledTitle variant="overline" color="text.secondary">
        Formatting
      </StyledTitle>
      <StyledFields>
        <FieldsetComponent {...rest} />
      </StyledFields>
    </StyledRoot>
  )
}
