import { ValueFormatterParams } from 'ag-grid-community'
import type { AgGridReactProps } from 'ag-grid-react'
import { useCallback } from 'react'
import {
  BLANK_VALUE_UI_REPRESENTATION,
  isRawBlankValue,
} from '@fintastic/web/util/blanks-and-masked'

export const useCopyFormattedValue = () =>
  useCallback<NonNullable<AgGridReactProps['processCellForClipboard']>>(
    (params) => {
      const colDef = params.column.getColDef()

      if (typeof colDef.valueFormatter === 'string') {
        console.warn(
          'String value formatter is not fully supported by useCopyFormattedValue',
        )
        return params.value
      }

      if (colDef.type === 'numericColumn') {
        if (isRawBlankValue(params.value)) {
          return BLANK_VALUE_UI_REPRESENTATION
        }
        return params.value
      }

      if (typeof colDef.valueFormatter === 'function') {
        return colDef.valueFormatter({
          ...params,
          data: params.node?.data,
          colDef: colDef,
        } as ValueFormatterParams)
      }

      return params.value
    },
    [],
  )
