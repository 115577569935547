import {
  styled,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material'

export const StyledRoot = styled(ListItemButton)(({ theme }) => ({
  'padding': theme.spacing(0.5),

  'background': '#fff',

  '&:hover': {
    background: '#f5f5f5',
  },
}))
export const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),

  color: theme.palette.text.primary,

  width: 32,
  minWidth: 32,
}))
export const StyledText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
}))

export const StyledTextContainer = styled('span')`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 100%;
`

export const StyledDragHandle = styled(Box)(({ theme }) => ({
  fontSize: 0,

  cursor: 'move',
}))

export const StyledVisibilityButtonContainer = styled(Box)(({ theme }) => ({}))

export const StyledArrow = styled(Box)(({ theme }) => ({
  fontSize: 0,
  color: theme.palette.text.disabled,
}))
