/**
 Sagas attached to current duck.
 @see https://redux-saga.js.org/docs/introduction/GettingStarted
 */

import {
  AllEffect,
  put,
  call,
  Effect,
  takeLeading,
  select,
} from 'redux-saga/effects'
import { all } from 'redux-saga/effects'
import { actions } from './slice'
import type { Saga } from 'redux-saga'
import {
  fetchGroupList,
  fetchPolicy,
  GroupsAPIResult,
  Policy,
  putPolicy,
} from '../api'
import {
  selectChanges,
  selectColumnMasking,
  selectDefaultDimensionConfig,
  selectPolicyHash,
  selectTableAccessList,
} from './selectors'
import { State } from './state'
import { ColumnMaskConfig } from '../types'
import { SetSelectedGroupPayload } from './reducers'

function* loadPermissionsDataSaga() {
  try {
    yield put(actions.resetPolicies())

    const groups: GroupsAPIResult = yield call(fetchGroupList)

    const groupsWithNames = groups.map((g) => ({
      ...g,
      name: g.id,
    }))

    yield put(actions.setGroupList(groupsWithNames))
    const groupId: string = groupsWithNames[0].id
    yield call(loadAndSetPermissionsGroupDataSaga, {
      payload: groupId,
    } as SetSelectedGroupPayload)
  } catch (error) {
    console.error('loadPermissionsDataSaga Error', error)
  }
}

function* loadAndSetPermissionsGroupDataSaga(action: SetSelectedGroupPayload) {
  try {
    const policy: Policy = yield call(fetchPolicy, action.payload)

    const tableAccessList = policy.tables.map((table) => {
      const dimensions = // this means that its not overridden, so don't appear that way
        Object.keys(table.allowed_dimensions || {}).length > 0
          ? table.allowed_dimensions
          : null
      return {
        id: table.resource_id,
        allowed: table.is_allowed,
        resourceType: table.resource_type,
        ...(dimensions && { dimensions }),
      }
    })

    const columnMasking = policy.columns.map((column) => {
      const dimensions = // this means that its not overridden, so don't appear that way
        Object.keys(column.allowed_dimensions || {}).length > 0
          ? column.allowed_dimensions
          : null
      return {
        id: column.resource_id,
        access: dimensions ? 'conditional' : 'never',
        ...(dimensions && { dimensions }),
      } as ColumnMaskConfig
    })

    const defaultDimensions = policy.allowed_dimensions
    const policyHash = policy.hash ?? ''

    yield put(
      actions.setGroupPolicy({
        tableAccessList,
        defaultDimensions,
        policyHash,
        columnMasking,
        groupId: policy.group_id,
      }),
    )
    yield put(actions.setSelectedGroup(policy.group_id))
  } catch (error) {
    console.error('loadPermissionGroupPolicyData Error', error)
  } finally {
    yield put(actions.loadPermissionsDone())
  }
}

function* saveChangesSaga() {
  const changes: State['changes'] = yield select(selectChanges)
  const defaultDimensionConfig: State['defaultDimensionConfig'] = yield select(
    selectDefaultDimensionConfig,
  )
  const tableAccessList: State['tableAccessList'] = yield select(
    selectTableAccessList,
  )

  const columnMasking: State['columnMasking'] = yield select(
    selectColumnMasking,
  )
  const changedGroupIds = new Set(
    Object.values(changes).map((change) => change.groupId),
  )

  const policyHash: State['policyHash'] = yield select(selectPolicyHash)
  const policyFromStore: (groupId: string) => Policy = (groupId) => ({
    group_id: groupId,
    columns: columnMasking[groupId].map((column) => ({
      resource_id: column.id,
      resource_type: 'metric',
      allowed_dimensions: column.dimensions || {},
    })),
    allowed_dimensions: defaultDimensionConfig[groupId],
    tables: tableAccessList[groupId].map((table) => ({
      resource_id: table.id,
      resource_type: table.resourceType,
      is_allowed: table.allowed,
      allowed_dimensions:
        table.dimensions === undefined ? null : table.dimensions,
    })),
  })

  const calls = [...changedGroupIds].map((groupId) =>
    call(putPolicy, policyFromStore(groupId), policyHash[groupId] || ''),
  )
  try {
    const newGroups: Policy[] = yield all(calls)

    const stateChanges = newGroups.map((groupResponse: Policy) =>
      put(
        actions.updatePolicyHash({
          groupId: groupResponse.group_id,
          hash: groupResponse.hash || '',
        }),
      ),
    )

    yield all(stateChanges)
    yield put(actions.saveChangesDone())
  } catch (error) {
    alert('Something went wrong')
  }
}

function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([
    takeLeading(actions.loadPermissionsData, loadPermissionsDataSaga),
    takeLeading(
      actions.loadPermissionGroupPolicyData,
      loadAndSetPermissionsGroupDataSaga,
    ),
    takeLeading(actions.saveChanges, saveChangesSaga),
  ])
}

// here can be any other generators besides watchers
const sagas: Saga[] = [watchersSaga]

// export only array of sagas
export default sagas
