import { map, uniq } from 'lodash'
import { createRowKey, type FieldKeyGetter } from './field-key'
import type { ListMetric, RowsMap, VersionMetric } from './types'
import type { Maybe } from '@fintastic/shared/util/types'
import { DimensionId, DimensionValueId } from '@fintastic/web/util/dimensions'
import { CompactMetricData } from '../../types'
import { VersionEntities } from '@fintastic/web/util/versions'
import { populateNullRowsMapForDimensions } from './populate-rows-map-for-missing-dimensions'
import { BaseGridRecordValue } from '@fintastic/shared/ui/grid-framework'
import {
  fillNonExistDimsWithOther,
  getNotExistDimensions,
  mergeDimensions,
} from './utils'

type DataFields =
  | 'metricData'
  | 'versionId'
  | 'metricId'
  | 'metricMetadata'
  | 'metricSource'

export type VersionMetricData = Pick<VersionMetric, DataFields>

export type ListMetricData = Pick<ListMetric, DataFields>

export type MergedDimensions = Record<DimensionId, DimensionValueId[]>

export function expandCompactMetricData(
  data: (VersionMetricData | ListMetricData)[],
  periodDim = '',
  fieldKeyGetter: FieldKeyGetter,
  entities?: Maybe<
    Array<{
      entities?: VersionEntities
      versionId: string
    }>
  >,
  allMetricsDimensions: MergedDimensions = {},
  allVersionsBlankValues: Record<string, BaseGridRecordValue> = {},
  populateNullDimensions = true,
): RowsMap {
  if (!data?.length) {
    return {}
  }

  let mutableRowsMap: RowsMap = {}

  // Populate rowsmap with null to enable editing empty values
  // Does not work for metric comparison
  if (
    populateNullDimensions &&
    data.length === 1 &&
    entities?.length &&
    data[0].metricSource !== 'calculated'
  ) {
    mutableRowsMap = populateNullRowsMapForDimensions(
      data,
      entities,
      periodDim,
      allMetricsDimensions,
    )
  }

  data.forEach((versionMetric) => {
    if (!versionMetric.metricData) {
      return
    }

    const metricIndexes = versionMetric.metricData.indexes
    const metricDimensions = versionMetric.metricData.dimensions
    const values = versionMetric.metricData.values

    for (let i = 0; i < values.length; i++) {
      const value = values[i]

      let offset = 1
      const currentDimensionSet: Record<
        DimensionId,
        DimensionValueId | string
      > = {}
      let currentPeriod: Maybe<string> = null

      for (let k = metricIndexes.length - 1; k >= 0; k--) {
        const currentDimension = metricDimensions[k]
        const currentLen = currentDimension.length
        const currentDimValue =
          currentDimension[Math.floor(i / offset) % currentLen]

        if (metricIndexes[k] === periodDim) {
          currentPeriod = currentDimValue
        } else {
          currentDimensionSet[metricIndexes[k]] = currentDimValue
        }

        offset = offset * currentLen
      }

      const notExistDims = getNotExistDimensions(
        allMetricsDimensions,
        currentDimensionSet,
        periodDim,
      )

      if (notExistDims.length > 0) {
        fillNonExistDimsWithOther(
          currentDimensionSet,
          notExistDims,
          allMetricsDimensions,
        )
      }

      const key = createRowKey(currentDimensionSet)
      if (!(key in mutableRowsMap)) {
        mutableRowsMap[key] = {
          ...currentDimensionSet,
          ...allVersionsBlankValues,
        }
      }

      mutableRowsMap[key][
        fieldKeyGetter(
          versionMetric.versionId,
          versionMetric.metricId,
          currentPeriod,
        )
      ] = value
    }
  })

  return mutableRowsMap
}

export function sparseToCompact(
  metric: VersionMetricData,
  shouldIgnoreBlankRows: boolean,
): CompactMetricData {
  if (metric.metricData.format === 'compact') {
    return metric.metricData
  }

  const dimensionToIndexMap = Object.fromEntries(
    metric.metricData.indexes.map((v, i) => [v, i]),
  )

  const dimensionsList = metric.metricData.indexes.slice()
  const dimensionsValuesList: string[][] = []
  let valuesTotalAmount = 0

  // precalculate indexes and child dimensions lengths to speed up the algorithm
  const dimensionsPrecalculatedMap: Record<
    string,
    {
      values: string[]
      valuesIndexes: Record<string, number>
      childDimsValuesLength: number
    }
  > = {}

  dimensionsList.forEach((dim) => {
    const values = shouldIgnoreBlankRows
      ? // Get only actually used dimensions values
        uniq(metric.metricData.dimensions[dimensionToIndexMap[dim]])
      : // Get all dimension values
        Object.keys(
          // @todo remove dimensions metadata usage
          metric.metricMetadata.dimensions.find((d) => d.id === dim)?.values ||
            {},
        )

    dimensionsValuesList.push(values)

    if (valuesTotalAmount === 0) {
      valuesTotalAmount = values.length
    } else {
      valuesTotalAmount *= values.length
    }

    dimensionsPrecalculatedMap[dim] = {
      values,
      valuesIndexes: Object.fromEntries(
        values.map((dimValue, i) => [dimValue, i]),
      ),
      childDimsValuesLength: 0,
    }
  })

  dimensionsList.forEach((id, index) => {
    const childDims = dimensionsList.slice(index + 1)
    let valuesLength = 0
    if (childDims.length > 0) {
      valuesLength = childDims.reduce(
        (amount, dimId) =>
          amount * dimensionsPrecalculatedMap[dimId].values.length,
        1,
      )
    }
    dimensionsPrecalculatedMap[id].childDimsValuesLength = valuesLength
  })

  // create a compact metric data filled with blanks
  const compactMetricData: CompactMetricData = {
    indexes: dimensionsList,
    dimensions: dimensionsValuesList,
    values: Array(valuesTotalAmount).fill(null),
    format: 'compact',
  }

  // go over existing values in sparse metric and put them to the compact data
  // to do that we need to calculate the index of value in the compact metric
  for (
    let sparseValueIndex = 0;
    sparseValueIndex < metric.metricData.values.length;
    sparseValueIndex++
  ) {
    let compactValueIndex = 0
    for (let dimIndex = 0; dimIndex < dimensionsList.length; dimIndex++) {
      const dim = dimensionsList[dimIndex]
      const dimValue = metric.metricData.dimensions[dimIndex][sparseValueIndex]
      const dimValueIndex =
        dimensionsPrecalculatedMap[dim].valuesIndexes[dimValue]

      if (dimIndex === dimensionsList.length - 1) {
        compactValueIndex += dimValueIndex
      } else {
        compactValueIndex +=
          dimensionsPrecalculatedMap[dim].childDimsValuesLength * dimValueIndex
      }
    }
    compactMetricData.values[compactValueIndex] =
      metric.metricData.values[sparseValueIndex]
  }

  return compactMetricData
}

const getAllVersionsBlankValues = (
  data: (VersionMetricData | ListMetricData)[],
  periodDim: string,
): string[] =>
  uniq(
    data.flatMap(({ metricData }) => {
      if (metricData === null) {
        return []
      }
      const periodDimIndex = metricData.indexes.indexOf(periodDim)
      if (periodDimIndex === -1) {
        return []
      }
      return metricData.dimensions[periodDimIndex]
    }),
  )

const getBlankColumnsWithPeriods = (
  versionId: string[],
  metricId: string,
  periods: Maybe<string[]>,
  fieldKeyGetter: FieldKeyGetter,
) =>
  Object.fromEntries<BaseGridRecordValue>(
    versionId.flatMap((vid) => {
      if (periods !== null) {
        return periods.map((dimValue) => [
          fieldKeyGetter(vid, metricId, dimValue),
          null,
        ])
      }
      return [[fieldKeyGetter(vid, metricId, null), null]]
    }),
  )

export function expandSparseMetricData(
  data: (VersionMetricData | ListMetricData)[],
  periodDim = '',
  fieldKeyGetter: FieldKeyGetter,
  entities?: Maybe<
    Array<{
      entities?: VersionEntities
      versionId: string
    }>
  >,
  allMetricsDimensions: MergedDimensions = {},
  allVersionsBlankValues: Record<string, BaseGridRecordValue> = {},
  populateNullDimensions = true,
): RowsMap {
  let mutableRowsMap: RowsMap = {}

  if (!data?.length) {
    return mutableRowsMap
  }

  // Populate rowsmap with null to enable editing empty values
  // Does not work for metric comparison
  if (
    populateNullDimensions &&
    data.length === 1 &&
    entities?.length &&
    data[0].metricSource !== 'calculated'
  ) {
    mutableRowsMap = populateNullRowsMapForDimensions(
      data,
      entities,
      periodDim,
      allMetricsDimensions,
    )
  }

  data.forEach(({ versionId, metricData, metricId }) => {
    if (!metricData) {
      return
    }

    for (
      let valueIndex = 0;
      valueIndex < metricData.values.length;
      valueIndex++
    ) {
      let periodDimValue: Maybe<string> = null
      const dimensions: Record<string, string> = {}

      for (
        let metricIndexIndex = 0;
        metricIndexIndex < metricData.indexes.length;
        metricIndexIndex++
      ) {
        const dimId = metricData.indexes[metricIndexIndex]
        const dimValue = metricData.dimensions[metricIndexIndex][valueIndex]
        if (dimId === periodDim) {
          periodDimValue = dimValue
        } else {
          dimensions[dimId] = dimValue
        }
      }

      const notExistDims = getNotExistDimensions(
        allMetricsDimensions,
        dimensions,
        periodDim,
      )

      if (notExistDims.length > 0) {
        fillNonExistDimsWithOther(
          dimensions,
          notExistDims,
          allMetricsDimensions,
        )
      }

      const rowKey = createRowKey(dimensions)
      if (mutableRowsMap[rowKey] === undefined) {
        mutableRowsMap[rowKey] = {
          ...dimensions,
          ...allVersionsBlankValues,
        }
      }

      mutableRowsMap[rowKey][
        fieldKeyGetter(versionId, metricId, periodDimValue)
      ] = metricData.values[valueIndex]
    }
  })

  return mutableRowsMap
}

export const mergeRowMaps = (
  aMap: RowsMap,
  bMap: RowsMap,
  allVersionsBlankValues: Record<string, BaseGridRecordValue> = {},
): RowsMap => {
  const allKeys = uniq([...Object.keys(aMap), ...Object.keys(bMap)])

  return Object.fromEntries(
    allKeys.map((key) => {
      const allInternalKeys = uniq([
        ...Object.keys(aMap[key] ?? {}),
        ...Object.keys(bMap[key] ?? {}),
      ])

      const populatedValues = Object.fromEntries(
        // Select value from the map where it is not null
        allInternalKeys.map((internalKey) => [
          internalKey,
          aMap[key]?.[internalKey] || bMap[key]?.[internalKey] || null,
        ]),
      )

      return [
        key,
        {
          ...allVersionsBlankValues,
          ...populatedValues,
        },
      ]
    }),
  )
}

export function expandMetricData(
  data: (VersionMetricData | ListMetricData)[],
  periodDim = '',
  fieldKeyGetter: FieldKeyGetter,
  entities?: Maybe<
    Array<{
      entities?: VersionEntities
      versionId: string
    }>
  >,
): RowsMap {
  const allMetricsDimensions: MergedDimensions = mergeDimensions(data, entities)
  const allVersionsBlankValues = getBlankColumnsWithPeriods(
    data.map(({ versionId }) => versionId),
    data[0].metricId,
    periodDim !== '' ? getAllVersionsBlankValues(data, periodDim) : null,
    fieldKeyGetter,
  )

  if (uniq(map(data, 'metricId')).length > 1) {
    throw new Error('Cannot expand multiple metrics with different metric ids')
  }

  if (data.every((m) => m.metricData?.format === 'sparse')) {
    return expandSparseMetricData(
      data,
      periodDim,
      fieldKeyGetter,
      entities,
      allMetricsDimensions,
      allVersionsBlankValues,
      true,
    )
  }

  if (data.every((m) => m.metricData?.format === 'compact')) {
    return expandCompactMetricData(
      data,
      periodDim,
      fieldKeyGetter,
      entities,
      allMetricsDimensions,
      allVersionsBlankValues,
      true,
    )
  }

  const compactData = data.filter((i) => i.metricData?.format === 'compact')
  const sparseData = data.filter((i) => i.metricData?.format === 'sparse')

  const compactRowsMap = expandCompactMetricData(
    compactData,
    periodDim,
    fieldKeyGetter,
    entities,
    allMetricsDimensions,
    allVersionsBlankValues,
    false,
  )
  const sparseRowsMap = expandSparseMetricData(
    sparseData,
    periodDim,
    fieldKeyGetter,
    entities,
    allMetricsDimensions,
    allVersionsBlankValues,
    false,
  )

  const merged = mergeRowMaps(
    compactRowsMap,
    sparseRowsMap,
    allVersionsBlankValues,
  )

  return merged
}
