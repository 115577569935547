import { UseLocationResult } from '@fintastic/shared/util/memory-router'
import { createContext, useContext } from 'react'

export type ReportSettingsPanelNavigationContextValue = UseLocationResult

const stub = () => {
  throw new Error('navigation context is not initialised')
}

export const ReportSettingsPanelNavigationContext =
  createContext<ReportSettingsPanelNavigationContextValue>({
    path: null,
    goTo: stub,
    goBack: stub,
    reset: stub,
  })

export function useReportSettingsPanelNavigationContext() {
  return useContext(ReportSettingsPanelNavigationContext)
}
