import { styled, Box } from '@mui/material'

export const StyledWidgetDraggableHandlerRoot = styled(Box)(({ theme }) => ({
  marginRight: theme.spacingFromPixels(10),

  fontSize: 0,
  cursor: 'move',
  svg: {
    width: '24px',
    height: 'auto',
  },
}))
