import React, { useCallback } from 'react'
import { ContextMenuItemButtonRendererProps } from '@fintastic/shared/ui/context-menu-framework'
import { AddNewBoardButton } from './AddNewBoardButton'

export type MoveBoardToNewFolderContextMenuButtonProps =
  ContextMenuItemButtonRendererProps

export const MoveBoardToNewFolderContextMenuButton: React.FC<
  MoveBoardToNewFolderContextMenuButtonProps
> = (props) => {
  const { contextMenuItem, hideContextMenu } = props
  const { onClick } = contextMenuItem

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
      hideContextMenu()
    }
  }, [hideContextMenu, onClick])

  return <AddNewBoardButton onClick={handleClick} />
}
