import { Autocomplete, AutocompleteRenderInputParams, Box, Divider, TextField } from '@mui/material'
import { NewBoardFolderId, newFolderId, noFolderId } from './BoardNewForm'
import { useBoardsFoldersListQuery } from '../../../hooks/useBoardsFoldersListQuery'
import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'

type FolderSelectOption = { id: NewBoardFolderId, label: string }

type BoardNewSelectFolderProps = {
  value: string
  disabled?: boolean
  onSelect: (folderId: NewBoardFolderId) => void
}

const renderInput = (params: AutocompleteRenderInputParams) => (<TextField {...params} label="Folder" />)

const renderOptions = (
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: FolderSelectOption,
) => (
  <React.Fragment key={option.id}>
    <Box component="li" {...optionProps}>
      {option.id === noFolderId || option.id === newFolderId ? (<>
          {option.id === newFolderId ? <AddCircleOutlineOutlinedIcon sx={{ marginRight: .5, width: '16px' }} /> : null}
          <b>{option.label}</b>
        </>)
        : (
          option.label
        )}
    </Box>
    {option.id === newFolderId && <Divider key={'div-1'} />}
  </React.Fragment>
)

export const BoardNewSelectFolder: React.FC<BoardNewSelectFolderProps> = ({ onSelect, value, disabled }) => {
  const foldersQuery = useBoardsFoldersListQuery()

  const foldersNames: FolderSelectOption[] = useMemo(() => (
    [
      { id: noFolderId, label: 'No folder' },
      { id: newFolderId, label: 'New folder' },
      ...sortBy(foldersQuery.data?.map((f) => ({ id: f.id, label: f.name })), ['label']),
    ]
  ), [foldersQuery.data])

  const currentValue = useMemo(() => foldersNames.find((o) => o.id === value), [value, foldersNames])

  return (
    <Autocomplete<FolderSelectOption, false, true, undefined>
      size={'small'}
      value={currentValue}
      options={foldersNames}
      disabled={disabled || false}
      disableClearable={true}
      onChange={(_, newInputValue) => onSelect(newInputValue.id)}
      renderInput={renderInput}
      isOptionEqualToValue={(fo, option) => fo.id === option.id}
      renderOption={renderOptions} />
  )
}
