import React from 'react'
import { PanelToolbarIconButton } from '@fintastic/shared/ui/panel-framework'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'

export type MetricChartTopBarRightContentProps = {
  showDebugPanels: boolean
  invalidateQueries?: () => void
  periodSelectorComponent?: React.ReactNode
}

export const MetricChartTopBarRightContent: React.FC<
  MetricChartTopBarRightContentProps
> = ({ showDebugPanels, invalidateQueries, periodSelectorComponent }) => (
    <>
      {showDebugPanels && (
        <PanelToolbarIconButton
          data-testid="refreshChart"
          title={'Refresh'}
          onClick={invalidateQueries}
        >
          <CachedOutlinedIcon />
        </PanelToolbarIconButton>
      )}
      {periodSelectorComponent}
    </>
  )
