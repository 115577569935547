import { BoardWidgetContextMenu } from '../components/BoardWidgetContextMenu'
import React from 'react'
import {
  LIST_GRID_WIDGET_TYPE,
  METRIC_CHART_WIDGET_TYPE,
  METRIC_GRID_WIDGET_TYPE,
  TEXT_WIDGET_TYPE,
} from '../../widgets'
import type { Widget } from '@fintastic/shared/ui/widgets-framework'
import {
  getWidgetTypeByWidget,
  widgetTypeToName,
} from './board-design-type-utils'
import {
  ChangeWidgetDialogParams,
  RemoveWidgetDialogParams,
} from '../../../types/board-design-types'
import { isChartSettings } from '../../widgets/MetricChartWidget'

export function buildWidgetContextActions(
  widget: Widget,
  params: {
    onChange?: (params: ChangeWidgetDialogParams) => void
    onDelete?: (params: RemoveWidgetDialogParams) => void
  },
) {
  const type = getWidgetTypeByWidget(widget)
  const humanType = widgetTypeToName(type)
  const name =
    type === TEXT_WIDGET_TYPE
      ? 'text'
      : ` ${humanType} "${widget.settings.displayLabel}"`

  const id =
    type === LIST_GRID_WIDGET_TYPE
      ? widget.settings.listId
      : type === METRIC_GRID_WIDGET_TYPE || type === METRIC_CHART_WIDGET_TYPE
      ? widget.settings.metricId
      : widget.settings.reportCategoryId

  const chartSettings = isChartSettings(widget.settings)
    ? widget.settings.chartSettings || {}
    : undefined

  return (
    <BoardWidgetContextMenu
      widgetType={type}
      onDelete={() =>
        params?.onDelete?.({ name, type: humanType, id: widget.id })
      }
      onChange={() => {
        params?.onChange?.({
          type,
          id,
          widgetId: widget.id,
          displayLabel: widget.settings.displayLabel,
          versionId: widget.settings.versionId,
          chartSettings,
        })
      }}
      id={widget.id}
    />
  )
}
