import React, { useMemo } from 'react'
import { Tooltip } from '@mui/material'
import {
  DEFAULT_DAYJS_DATE_TIME_FORMAT,
  LONG_DAYJS_DATE_TIME_FORMAT,
} from '../const'
import { StyledDate, StyledGMT } from './LocalDate.styled'
import { dateToLocalDate, tzGMT, tzLocal } from '../date-utils'

const badDate = {
  dateText: '',
  dateLongText: '',
}

type LocalDateProps = {
  date?: Date
  emptyText?: string
  withTooltip?: boolean
  withGMT?: boolean
}

export const LocalDate: React.FC<LocalDateProps> = ({
  date,
  emptyText,
  withTooltip = true,
  withGMT = true,
}) => {
  const { dateText, dateLongText } = useMemo(() => {
    const datePrepared = dateToLocalDate(date)
    if (!datePrepared) {
      return badDate
    }

    return {
      dateText: datePrepared.format(DEFAULT_DAYJS_DATE_TIME_FORMAT),
      dateLongText:
        datePrepared.format(LONG_DAYJS_DATE_TIME_FORMAT) +
        ' (' +
        tzGMT +
        '), ' +
        tzLocal,
    }
  }, [date])

  if (!dateText) {
    return <>{emptyText || ''} </>
  }

  if (!withTooltip) {
    return (
      <StyledDate>
        {dateText}
        {withGMT && <StyledGMT>({tzGMT})</StyledGMT>}
      </StyledDate>
    )
  }

  return (
    <Tooltip enterDelay={600} title={dateLongText}>
      <StyledDate>
        {dateText}
        {withGMT && <StyledGMT>({tzGMT})</StyledGMT>}
      </StyledDate>
    </Tooltip>
  )
}
