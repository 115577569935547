import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'
import { MASKED_VALUE } from '@fintastic/web/util/blanks-and-masked'
import memoize from 'lodash/memoize'

// Replaces masked values with dash
export const hideMaskedValues = (
  values: MetricDataValue[],
  mask?: string | number,
): MetricDataValue[] => {
  if (!mask) {
    return values
  }

  const newValues: MetricDataValue[] = []
  let masksEncountered = 0

  for (let i = 0; i < values.length; i += 1) {
    if (values[i] === mask) {
      masksEncountered += 1
      newValues.push(MASKED_VALUE)
      continue
    }
    newValues.push(values[i])
  }

  // keep the reference to an original array if needed
  // to safe on aggrid re-render and avoid a bug with cell values changing to stale ones
  return masksEncountered > 0 ? newValues : values
}

export const hideMaskedValuesMemorized = memoize(hideMaskedValues)
