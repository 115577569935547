import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import {
  StyledDialogButtonContainer,
  StyledDialogCancelButton,
  StyledDialogSubmitButton,
} from './BoardShareRemoveAll.styled'

export type BoardShareRemoveAllModalProps = {
  onRequestClose: () => void
  onRemove: () => void
}

export const BoardShareRemoveAllModal: React.FC<
  BoardShareRemoveAllModalProps
> = ({ onRequestClose, onRemove }) => (
  <Modal
    open={true}
    onRequestClose={onRequestClose}
    data-testid="board-share-modal-root"
  >
    Are you sure to remove all users/groups access?
    <StyledDialogButtonContainer>
      <StyledDialogCancelButton
        variant="outlined"
        disabled={false}
        onClick={onRequestClose}
      >
        Cancel
      </StyledDialogCancelButton>

      <StyledDialogSubmitButton
        variant="contained"
        type="submit"
        onClick={onRemove}
      >
        Remove all
      </StyledDialogSubmitButton>
    </StyledDialogButtonContainer>
  </Modal>
)
