import React from 'react'
import { FormattingFieldsetProps } from './types'
import { NumberFormatting } from './NumberFormatting'

export type PercentNumberFormattingProps = FormattingFieldsetProps

export const PercentNumberFormatting: React.FC<
  PercentNumberFormattingProps
> = ({ displaySettings, onChange }) => (
  <NumberFormatting displaySettings={displaySettings} onChange={onChange} />
)
