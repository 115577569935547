import {
  ChartXAxisAlignmentSelect,
  ChartXAxisFormatSwitch,
  defaultChartXAxisAlignment,
  defaultChartXAxisFormat,
} from '@fintastic/web/feature/charts'
import React from 'react'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { ChartSettingsSection } from '../shared/ChartSettingsSection'

export const XAxisSection: React.FC = () => {
  const { loading } = useChartSettingsContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartSettingsSection>
      <ChartXAxisAlignmentSelect
        disabled={loading}
        xAxisAlignment={
          localSettings.xAxisAlignment || defaultChartXAxisAlignment
        }
        onChangeChartSettings={handleSettingsChange}
      />
      <ChartXAxisFormatSwitch
        disabled={loading}
        xAxisFormat={localSettings.xAxisFormat || defaultChartXAxisFormat}
        onChangeChartSettings={handleSettingsChange}
      />
    </ChartSettingsSection>
  )
}
