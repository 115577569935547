import React from 'react'
import {
  StyledGroupPanelIconCont,
  StyledGroupPanelName,
  StyledGroupPanelRoot,
  StyledGroupPanelText,
} from './GroupPanel.styled'
import { SxProps } from '@mui/system'
import GroupIcon from '@mui/icons-material/Group'

export type GroupPanelProps = {
  name?: string
  disabled?: boolean
  sx?: SxProps
}

export const GroupPanel: React.FC<GroupPanelProps> = ({
  name,
  sx,
  disabled,
}) => (
  <StyledGroupPanelRoot sx={sx}>
    <StyledGroupPanelIconCont
      sx={{ bgcolor: disabled ? 'lightgray' : undefined }}
    >
      <GroupIcon />
    </StyledGroupPanelIconCont>
    <StyledGroupPanelText>
      {name !== undefined && (
        <StyledGroupPanelName sx={{ color: disabled ? 'gray' : undefined }}>
          {name}
        </StyledGroupPanelName>
      )}
    </StyledGroupPanelText>
  </StyledGroupPanelRoot>
)
