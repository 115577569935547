import React, { useCallback } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { Box, Popover } from '@mui/material'
import { FloatingPanel } from '@fintastic/shared/ui/filters'
import type { IHeaderParams } from 'ag-grid-community'
import { FloatingPanelColumnActions } from './FloatingPanelColumnActions'
import { FloatingPanelFilters } from './FloatingPanelFilters'
import { useColumnFilter } from './hooks/useColumnFilter'
import { useFilterContext } from '@fintastic/web/util/filters'
import { FloatingPanelButton } from '@fintastic/shared/ui/filters'

export const FloatingPanelTrigger: React.FC<IHeaderParams> = (props) => {
  const { column } = props

  const { metricId, period, currentFilter } = useColumnFilter(column.getColId())
  const filterContext = useFilterContext()

  const [anchorEl, setAnchorEl] = React.useState<Maybe<HTMLButtonElement>>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleFullClose = useCallback(() => {
    handleClose()
    filterContext.resetFilter(metricId, period ?? null)
  }, [filterContext, handleClose, metricId, period])

  const open = Boolean(anchorEl)
  const id = open ? `floating-panel-${column.getColId()}` : undefined

  if (!currentFilter) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <FloatingPanelButton onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFullClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FloatingPanel
          columnsTab={
            <FloatingPanelColumnActions {...props} onClose={handleClose} />
          }
          filtersTab={<FloatingPanelFilters {...props} onClose={handleClose} />}
        />
      </Popover>
    </Box>
  )
}
