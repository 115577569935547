import React, { useCallback, useMemo } from 'react'
import { Box, Button, Grid, SelectChangeEvent } from '@mui/material'
import { DimensionAccessConfiguration } from './DimensionAccessConfiguration'
import { TableAccessConfiguration } from './TableAccessConfiguration'
import { GroupSelector } from './GroupSelector'
import { ColumnAccessConfiguration } from './ColumnAccessConfiguration'
import { ApplyChangesDialog } from './components/ApplyChangesDialog'
import { TableSelector } from './components/TableSelector'
import { useTableAccessList } from './hooks/useTableAccessList'
import { useGlobalModel } from './hooks/useGlobalModel'
import { useTableChangeHandlers } from './hooks/useTableChangeHandlers'
import { DimensionMap, TableAccess } from './types'
import { DimensionAccessGrid } from './DimensionAccessGrid'
import { Maybe } from '@fintastic/shared/util/types'
import { PolicyTableResourceType } from './api'
import { styled } from '@mui/material/styles'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { BulkMetricAndListsToggle } from './components/BulkMetricAndListsToggle'
import { useGroupList } from './hooks/useGroupList'
import { CopyPermissionsDialog } from './components/CopyPermissionsDialog'

export const TablesAndRowsAdmin = () => {
  const { onDimensionAccessChange, onRemoveTableOverride } =
    useTableChangeHandlers()
  const { tableAccessList } = useTableAccessList()
  const { onAddTableOverride } = useTableChangeHandlers()
  const {
    tables: allTables,
    dimensions: allDimensions,
    loading,
  } = useGlobalModel()

  const tableNameMap = useMemo(
    () => Object.fromEntries(allTables.map((table) => [table.id, table])),
    [allTables],
  )
  const overriddenTables = useMemo(
    () =>
      tableAccessList.filter(
        (t) => t.allowed && t.dimensions && t.id in tableNameMap,
      ),
    [tableAccessList, tableNameMap],
  )

  const handleChange = useCallback(
    (table: string | null, resourceType: Maybe<PolicyTableResourceType>) =>
      (newRestrictions: DimensionMap) =>
        onDimensionAccessChange(table, resourceType, newRestrictions),
    [onDimensionAccessChange],
  )

  const selectableTables = useMemo(
    () =>
      tableAccessList
        .filter((t) => t.allowed && !t.dimensions && t.id in tableNameMap)
        .map((t) => tableNameMap[t.id]),
    [tableAccessList, tableNameMap],
  )

  const { onGroupChange, currentGroup } = useGroupList()

  const handleGroupChange = useCallback(
    (e: SelectChangeEvent) => {
      onGroupChange(e.target.value)
    },
    [onGroupChange],
  )

  if (loading) {
    return <CenteredCircularProgress />
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        spacing={2}
        style={{ height: '100%', justifyContent: 'space-between' }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'row', padding: '0 8px' }}
        >
          <GroupSelector
            onGroupChange={handleGroupChange}
            currentGroup={currentGroup}
            label="Current Group"
          />
          <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <Grid item xs={5}>
              {selectableTables.length > 0 && (
                <TableSelector
                  onSelectTable={onAddTableOverride}
                  tables={selectableTables}
                />
              )}
            </Grid>
          </div>
        </div>
        <Box
          alignItems="flex-end"
          gap="8px"
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <CopyPermissionsDialog />
          <ApplyChangesDialog />
        </Box>
      </Grid>
      <Grid container spacing={2} style={{ height: '100%' }}>
        <StyledGridItem item xs={4} sx={{ paddingLeft: 0 }}>
          <Box
            display="flex"
            sx={{
              margin: '0.83em 0',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <h2 style={{ margin: 0 }}>Metrics and Lists access</h2>
            <BulkMetricAndListsToggle />
          </Box>
          <TableAccessConfiguration />
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          <h2>Dimensions default access</h2>
          <DimensionAccessConfiguration showRestrictionsOnly />
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          <h2>Columns Masking</h2>
          <ColumnAccessConfiguration />
        </StyledGridItem>

        {overriddenTables.map((tableDimensionConfig: TableAccess) => (
          <StyledGridItem
            key={tableDimensionConfig.id}
            item
            xs={4}
            style={{ marginBottom: '96px' }}
          >
            <Grid
              style={{
                height: '63px',
                alignItems: 'center',
                display: 'flex',
                fontSize: '16pt',
                fontWeight: '600',
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {`${tableNameMap[tableDimensionConfig.id].name} override`}
              <Button
                onClick={() =>
                  onRemoveTableOverride(
                    tableDimensionConfig.id,
                    tableDimensionConfig.resourceType,
                  )
                }
              >
                revert to defaults
              </Button>
            </Grid>
            <DimensionAccessGrid
              dimensions={allDimensions}
              restrictions={tableDimensionConfig.dimensions || {}}
              onChange={handleChange(
                tableDimensionConfig.id,
                tableDimensionConfig.resourceType,
              )}
            />
          </StyledGridItem>
        ))}
      </Grid>
    </Grid>
  )
}

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 210px)',
  minHeight: '480px',
  marginBottom: '16px',
}))
