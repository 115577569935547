import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../routes'
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
  ValueGetterParams,
} from 'ag-grid-community'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { useModalState } from '@fintastic/shared/util/modal'
import { ArchiveIcon, ShareIcon } from '@fintastic/shared/ui/icons'
import {
  AddRowButton,
  BaseGrid,
  BaseGridEventHandlers,
  DeleteRowsButton,
  DuplicateRowsButton,
  ResetGridButton,
  ToolbarRowsActionButton,
  ToolbarRowsActionIconButton,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import {
  StyledVersionsListQuickSearch,
  StyledVersionsListRoot,
  StyledVersionsListTitle,
  StyledVersionsTable,
} from './VersionsList.styled'
import { Snackbar } from '@mui/material'
import {
  isVersionArchived,
  isVersionLocked,
  Version,
  VersionAccess,
} from '@fintastic/web/util/versions'
import { LockVersionAction } from './LockVersionAction'
import { VersionsDuplicateForm } from './Forms/VersionsDuplicateForm'
import { VersionsArchiveForm } from './Forms/VersionsArchiveForm'
import { VersionsDeleteForm } from './Forms/VersionsDeleteForm'
import { VersionsRetrieveForm } from './Forms/VersionsRetrieveForm'
import {
  CellClickedEvent,
  GridReadyEvent,
} from 'ag-grid-community/dist/lib/events'
import { QuickSearch, useQuickSearch } from '@fintastic/shared/ui/components'
import type { UserData } from '@fintastic/web/data-access/iam'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { VersionsShareForm } from './Forms/VersionsShareForm'
import {
  texts,
  useLoadAccessForVersionsList,
} from '@fintastic/web/data-access/versions'
import { textCaseInsensitiveComparator } from '@fintastic/shared/ui/ag-grid'
import { versionAmountTitle } from './utils'

type Props = {
  versions: Version[]
  users: UserData[]
}

type VersionWithShareStatus = Version & { sharingStatus: string }

export const VersionsList: React.FC<Props> = ({ versions, users }) => {
  const hasEditingAccess = Boolean(
    useRoleLevelAccess(['power_user', 'modeler']),
  )

  const apiRef = useRef<Maybe<GridApi<VersionWithShareStatus>>>(null)

  const clearFocusAndRangeSelection = useCallback(() => {
    const gridApi = apiRef.current
    if (!gridApi) {
      return
    }
    gridApi.clearFocusedCell()
    gridApi.clearRangeSelection()
  }, [])

  const deselectAllRows = useCallback(() => {
    const gridApi = apiRef.current
    if (!gridApi) {
      return
    }
    gridApi.deselectAll()
  }, [])

  const versionsAccessResponse = useLoadAccessForVersionsList(
    useMemo(() => versions.map((version) => version.uuid), [versions]),
  )

  const versionsWithSharingStatus = useMemo<VersionWithShareStatus[]>(() => {
    const versionsAccess = versionsAccessResponse.reduce(
      (res, versionAccess, index) => {
        const versionAccessData = versionAccess.data as VersionAccess
        return {
          ...res,
          [versions[index].uuid]:
            versionAccessData?.is_restricted === undefined
              ? true
              : versionAccessData.is_restricted,
        }
      },
      {} as Record<string, boolean>,
    )

    return versions.map((version) => ({
      ...version,
      sharingStatus: versionsAccess[version.uuid] ? 'Private' : 'Shared',
    }))
  }, [versions, versionsAccessResponse])

  const columns = useMemo(
    () => [
      {
        field: 'name',
        title: 'Version name',
        dataType: 'string' as const,
        comparator: textCaseInsensitiveComparator,
        minWidth: 200,
      },
      {
        field: 'created_at',
        title: 'Creation date',
        dataType: 'date' as const,
        defaultSort: 'desc' as const,
        maxWidth: 140,
      },
      {
        field: 'period_start',
        title: 'Period start date',
        dataType: 'date' as const,
        maxWidth: 160,
      },
      {
        field: 'period_end',
        title: 'Period end date',
        dataType: 'date' as const,
        maxWidth: 160,
      },
      {
        field: 'updated_at',
        title: 'Last modified',
        dataType: 'datetime' as const,
        maxWidth: 140,
      },
      {
        field: 'archived_at',
        title: 'Status',
        valueGetter: (
          params: ValueGetterParams<VersionWithShareStatus>,
        ): string => (isVersionArchived(params?.data) ? 'Archived' : 'Active'),
        maxWidth: 120,
      },
      {
        field: 'sharingStatus',
        title: 'Sharing status',
        maxWidth: 140,
      },
      {
        field: 'created_by',
        title: 'Creator',
        valueGetter: (
          params: ValueGetterParams<VersionWithShareStatus>,
        ): string => {
          const userEmail = params.data?.created_by || ''
          const userData = users.find((user) => user.username === userEmail)
          if (!userData) {
            return '-'
          }
          return userData.full_name || userData?.username || userEmail
        },
      },
      {
        field: 'lock',
        title: 'Locked',
        maxWidth: 100,
        resizable: false,
        valueGetter: (params: ValueGetterParams<VersionWithShareStatus>) => {
          const versions = params.data
          return isVersionLocked(versions)
        },
        cellRenderer: (params: ICellRendererParams<VersionWithShareStatus>) => {
          const version = params?.data
          if (!version || !version.uuid || !version.name) {
            return <div></div>
          }
          return (
            <LockVersionAction
              versionId={version.uuid}
              versionName={version.name}
              isLocked={isVersionLocked(version)}
              archived={isVersionArchived(version)}
              disabled={!hasEditingAccess}
              onAfterLock={clearFocusAndRangeSelection}
              onAfterUnlock={clearFocusAndRangeSelection}
            />
          )
        },
      },
    ],
    [users, hasEditingAccess, clearFocusAndRangeSelection],
  )

  const handleAdd = useCallback(() => console.log('not yet implemented'), [])

  const {
    isOpen: isDuplicateModalOpened,
    open: openDuplicateModal,
    close: closeDuplicateModal,
  } = useModalState()

  const {
    isOpen: isArchiveModalOpened,
    open: openArchiveModal,
    close: closeArchiveModal,
  } = useModalState()

  const {
    isOpen: isDeleteModalOpened,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModalState()

  const {
    isOpen: isRetrieveModalOpened,
    open: openRetrieveModal,
    close: closeRetrieveModal,
  } = useModalState()

  const {
    isOpen: isShareModalOpened,
    open: openShareModal,
    close: closeShareModal,
  } = useModalState()

  const {
    isOpen: isOpenErrorToastOpened,
    open: openOpenErrorToast,
    close: closeOpenErrorToast,
  } = useModalState()

  const navigate = useNavigate()

  const [selectedVersions, setSelectedVersions] = useState<
    VersionWithShareStatus[]
  >([])
  const [visibleCount, setVisibleCount] = useState(0)

  const eventHandlers = useMemo<BaseGridEventHandlers<VersionWithShareStatus>>(
    () => ({
      onCellClicked: (event: CellClickedEvent<VersionWithShareStatus>) => {
        if (!event?.data) {
          return
        }
        if (isVersionArchived(event.data)) {
          openOpenErrorToast()
        } else {
          navigate(routes.version(event.data.uuid))
        }
      },
      onFilterChanged: ({ api }) => {
        setVisibleCount(() => api.getDisplayedRowCount())
      },
      onRowSelected: (event: RowSelectedEvent<VersionWithShareStatus>) => {
        apiRef?.current && setSelectedVersions(apiRef.current.getSelectedRows())
      },
      // archived versions are filtered out by default
      onGridReady: (event: GridReadyEvent<VersionWithShareStatus>) => {
        const instance = event.api.getFilterInstance('archived_at')
        instance && instance.setModel({ values: ['Active', ''] })
        event.api.onFilterChanged()
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, apiRef.current],
  )

  const onlyArchivedVersionsSelected = useMemo(
    () =>
      !!selectedVersions.length &&
      selectedVersions.every((version) => isVersionArchived(version)),
    [selectedVersions],
  )

  const onlyActiveVersionsSelected = useMemo(
    () => selectedVersions.every((version) => !isVersionArchived(version)),
    [selectedVersions],
  )

  const { quickFilterText, handleQuickFilterTextChange } = useQuickSearch(
    apiRef?.current,
  )

  const defaultColDef = useMemo<ColDef<VersionWithShareStatus>>(
    () => ({
      cellClassRules: {
        'ag-cell-clickable': ({ data }) => !isVersionArchived(data),
      },
      filter: true,
      sortable: true,
      resizable: true,
    }),
    [],
  )

  return (
    <>
      <StyledVersionsListRoot data-testid="versions-list-v3-root">
        <StyledVersionsListTitle variant="h1">Versions</StyledVersionsListTitle>
        <StyledVersionsListQuickSearch>
          <QuickSearch
            value={quickFilterText}
            onChange={handleQuickFilterTextChange}
            totalCount={versionsWithSharingStatus.length}
            visibleCount={visibleCount}
            data-testid="versions-page-quick-search"
          />
        </StyledVersionsListQuickSearch>
        <StyledVersionsTable>
          <BaseGrid<VersionWithShareStatus>
            rowData={versionsWithSharingStatus}
            columns={columns}
            eventHandlers={eventHandlers}
            apiRef={apiRef}
            fitToSizeOnDisplay={true}
            checkboxSelection={hasEditingAccess}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate={texts.noVersionsSharedWithUser}
            topbar={
              <TopBar
                leftContent={
                  <>
                    {false && (
                      <AddRowButton onClick={handleAdd} disabled={true} />
                    )}
                    {hasEditingAccess && onlyActiveVersionsSelected && (
                      <DuplicateRowsButton<Version>
                        onClick={openDuplicateModal}
                        disabled={selectedVersions.length !== 1} // FIN-4891 for now duplication can be done on single version
                      />
                    )}
                    {hasEditingAccess && onlyActiveVersionsSelected && (
                      <ToolbarRowsActionIconButton<Version>
                        title="Archive"
                        onClick={openArchiveModal}
                        data-testid="ArchiveRowsButton"
                      >
                        <ArchiveIcon fontSize="small" />
                      </ToolbarRowsActionIconButton>
                    )}
                    {hasEditingAccess && (
                      <DeleteRowsButton<Version> onClick={openDeleteModal} />
                    )}
                    {hasEditingAccess && onlyActiveVersionsSelected && (
                      <ToolbarRowsActionIconButton
                        title="Share"
                        onClick={openShareModal}
                        data-testid="ShareRowsButton"
                      >
                        <ShareIcon fontSize="small" />
                      </ToolbarRowsActionIconButton>
                    )}
                    {hasEditingAccess && onlyArchivedVersionsSelected && (
                      <ToolbarRowsActionButton
                        title="Retrieve"
                        onClick={openRetrieveModal}
                        data-testid="RetrieveRowsButton"
                      >
                        Retrieve
                      </ToolbarRowsActionButton>
                    )}
                  </>
                }
                rightContent={<ResetGridButton />}
              />
            }
          />
        </StyledVersionsTable>
      </StyledVersionsListRoot>
      <>
        <Modal
          open={isDuplicateModalOpened}
          onRequestClose={closeDuplicateModal}
          title={`Duplicate ${versionAmountTitle(selectedVersions)}`}
          keepMounted={true}
        >
          {selectedVersions.length > 0 && (
            <VersionsDuplicateForm
              closeParentModal={closeDuplicateModal}
              versions={versions}
              selectedVersions={selectedVersions}
              onAfterSuccess={deselectAllRows}
            />
          )}
        </Modal>
        <Modal
          open={isArchiveModalOpened}
          onRequestClose={closeArchiveModal}
          title={`Archive ${versionAmountTitle(selectedVersions)}`}
          keepMounted={true}
        >
          <VersionsArchiveForm
            closeParentModal={closeArchiveModal}
            selectedVersions={selectedVersions}
            onAfterSuccess={deselectAllRows}
          />
        </Modal>
        <Modal
          open={isDeleteModalOpened}
          onRequestClose={closeDeleteModal}
          title={`Delete  ${versionAmountTitle(selectedVersions)}`}
          keepMounted={true}
        >
          <VersionsDeleteForm
            closeParentModal={closeDeleteModal}
            selectedVersions={selectedVersions}
            onAfterSuccess={deselectAllRows}
          />
        </Modal>
        <Modal
          open={isRetrieveModalOpened}
          onRequestClose={closeRetrieveModal}
          title={`Retrieve ${versionAmountTitle(selectedVersions)}`}
          keepMounted={true}
        >
          <VersionsRetrieveForm
            closeParentModal={closeRetrieveModal}
            selectedVersions={selectedVersions}
            onAfterSuccess={deselectAllRows}
          />
        </Modal>
        <Modal
          open={isShareModalOpened}
          onRequestClose={closeShareModal}
          title={`Share ${versionAmountTitle(selectedVersions)}`}
          width={400}
          keepMounted={true}
        >
          <VersionsShareForm
            closeParentModal={closeShareModal}
            selectedVersions={selectedVersions}
            onAfterSuccess={deselectAllRows}
            users={users}
          />
        </Modal>
        <Snackbar
          open={isOpenErrorToastOpened}
          autoHideDuration={6000}
          onClose={closeOpenErrorToast}
          message="Archived versions cannot be opened"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </>
    </>
  )
}
