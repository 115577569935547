import { Column, RowNode } from 'ag-grid-community'
import { generateDimensionValue } from './generate-dimension-value'
import { BasicColumnDefinition } from '@fintastic/web/util/generic-report'
import {
  PeriodSelection,
  isEmptyPeriodSelection,
  isRangeBasedSelection,
} from '@fintastic/web/util/period-selector'

/**
 * Builds `object_key` for comment
 */
export const buildObjectId = (
  column: Column,
  node: RowNode,
  dimensions: BasicColumnDefinition[],
  deeplinkWidgetId: string,
  periodSelection: PeriodSelection,
  monthOverMonthEnabled = false,
) => {
  const columnId = getColumnIdFromFieldId(column.getColId())
  let period = column.getParent().getColGroupDef()?.headerName

  const dimensionValue = generateDimensionValue(node, dimensions)

  const dimensionValueString = dimensionValue
    .map((d) => d.map(encodeURIComponent).join('='))
    .join('&')

  if (period === 'Total' && !isEmptyPeriodSelection(periodSelection)) {
    period = `total-${periodSelectionToObjectKeyId(periodSelection)}`
  }

  if (
    period === 'Variance' &&
    !isEmptyPeriodSelection(periodSelection) &&
    monthOverMonthEnabled
  ) {
    period = `diff-${periodSelectionToObjectKeyId(periodSelection)}`
  }

  return (
    `deeplinkWidgetId=${encodeURIComponent(deeplinkWidgetId)}` +
    `&version=${encodeURIComponent(columnId)}&period=${encodeURIComponent(
      period === undefined ? 'undefined' : period,
    )}&${dimensionValueString}` +
    (monthOverMonthEnabled ? `&mom=true` : '')
  )
}

const periodSelectionToObjectKeyId = (
  periodSelection: PeriodSelection,
): string => {
  if (isRangeBasedSelection(periodSelection)) {
    return periodSelection.range.join('-')
  }

  return `periods-${periodSelection.periods.join('-')}`
}

/**
 * We rely on old field ids
 * Diff columns has version `amount` in legacy historical data
 * @see `<GenericReportTable />`
 * @see `generateDiffFieldId()`
 * @see `generateFieldId()`
 *
 * It parses fields id like (see generator functions for more details):
 *
 * `amount_period_diff` (legacy)
 * `amount_period_diff_percent` (legacy)
 * `amount_period_diff_1` (legacy)
 * `amount_period_diff_percent_1` (legacy)
 * `amount__VERSION-ID__Q2 2022` (legacy)
 * `ag-Grid-AutoColumn`
 */
const getColumnIdFromFieldId = (fieldId: string): string => {
  if (fieldId.includes('amount_period_diff_percent')) {
    return 'percent'
  }

  if (fieldId.includes('amount_two_period_diff_percentage')) {
    return 'amount_two_period_diff_percentage'
  }

  if (
    fieldId.includes('amount_period_diff') ||
    fieldId.includes('amount_two_period_diff') ||
    fieldId.includes('amount_two_period_diff_percentage')
  ) {
    // hack to support legacy field ids
    return 'amount'
  }

  if (fieldId.includes('ag-Grid-AutoColumn')) {
    return 'ag-Grid-AutoColumn'
  }

  // Normal field case (`amount__VERSION-ID__2022`)
  const split = fieldId.replace('amount__', '').split('_')
  return split[0]
}
