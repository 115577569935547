import { styled, Box, Popover } from '@mui/material'

export const StyledContextMenuPopover = styled(Popover)(({ theme }) => ({
  '.MuiPopover-paper': {
    width: 240,

    filter:
      'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05)) drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.05))',
    boxShadow: 'none',
    borderRadius: 4,
  },
}))

export const StyledContextMenuContainer = styled(Box)(({ theme }) => ({}))
