import React from 'react'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'

export const NewEntityButton: React.FC<{
  onClick?: () => void
  enabled: boolean
}> = ({ onClick, enabled }) => (
  <FintasticThemeProvider applyLegacyTheme={false}>
    <Button
      variant="outlined"
      color="black"
      onClick={onClick}
      startIcon={<Add fontSize="small" />}
      sx={{
        marginLeft: 1,
      }}
      disabled={!enabled}
    >
      New
    </Button>
  </FintasticThemeProvider>
)
