import React from 'react'
import { ChartSettings, ChartSettingsIcon } from '@fintastic/web/feature/charts'
import { ChartSettingsContextWrapper } from './ChartSettingsContextWrapper'
import { Maybe } from '@fintastic/shared/util/types'
import { ChartTab, ChartTabs } from '../../../settings-controls/side-tabs'

export type MetricChartSettingsPanelProps = {
  loading: boolean
  widgetId: string
  metricId: string
  versions: string[]
  chartSettings: ChartSettings
  referenceSettings?: ChartSettings
  isDesignMode: boolean
  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<ChartSettings>,
  ) => void
}

export const MetricChartSettingsPanel: React.FC<
  MetricChartSettingsPanelProps
> = ({
  widgetId,
  loading,
  metricId,
  versions,
  chartSettings,
  referenceSettings,
  isDesignMode,
  handleUpdateChartWidgetSettings,
}) => (
  <ChartTabs>
    <ChartTab
      icon={<ChartSettingsIcon />}
      title={'Customize chart'}
      highlight={false}
      disabled={isDesignMode}
      id={'settings'}
    >
      <ChartSettingsContextWrapper
        widgetId={widgetId}
        loading={loading}
        metricId={metricId}
        versions={versions}
        chartSettings={chartSettings}
        referenceSettings={referenceSettings}
        handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
      />
    </ChartTab>
  </ChartTabs>
)
