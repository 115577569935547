import { createContext, useContext } from 'react'
import { MemoryRouterContextValue } from './types'

const stub = () => {
  throw new Error('Memory router context is not initialised')
}

export const RouterContext = createContext<MemoryRouterContextValue>({
  goBack: stub,
  goTo: stub,
})

export function useMemoryRouterContext() {
  return useContext(RouterContext)
}
