import React, { useMemo, useState } from 'react'
import { Box, Checkbox, FormControl } from '@mui/material'
import { compact } from 'lodash'
import { VersionSelectorItem } from '../../types'
import {
  StyledSearchInputContainer,
  StyledVersionCounter,
  StyledVersionEmpty,
  StyledVersionLine,
  StyledVersionName,
  StyledVersionTitle,
} from './VersionsContainer.styled'
import { matchSorter } from 'match-sorter'
import {
  QuickSearchDebounced,
  VersionLockUnlockIcon,
} from '@fintastic/shared/ui/components'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'

export type VersionsContainerProps = {
  currentVersions: VersionSelectorItem[]
  handleVersions: (version: VersionSelectorItem) => void
  versions: VersionSelectorItem[]
}

export const VersionsContainer: React.FC<VersionsContainerProps> = ({
  currentVersions,
  handleVersions,
  versions,
}) => {
  const [filterString, setFilterString] = useState('')
  const filteredVersions = useMemo<VersionSelectorItem[]>(() => {
    const trimmedSearch = filterString.trim()

    if (!trimmedSearch) {
      return versions
    }

    return matchSorter(versions, trimmedSearch, {
      keys: ['name'],
    })
  }, [versions, filterString])

  const currentVersionsIds = useMemo(
    () =>
      compact(currentVersions).reduce((arr, version) => {
        arr.push(version.id)
        return arr
      }, [] as string[]),
    [currentVersions],
  )

  return (
    <>
      <FintasticThemeProvider applyLegacyTheme={false}>
        <StyledVersionTitle variant="overline">
          Versions
          {currentVersionsIds.length > 0 && (
            <StyledVersionCounter>
              {currentVersionsIds.length} / 3
            </StyledVersionCounter>
          )}
        </StyledVersionTitle>
      </FintasticThemeProvider>

      <StyledSearchInputContainer>
        <QuickSearchDebounced
          onFilter={setFilterString}
          fullWidth={true}
          autoFocus={true}
        />
      </StyledSearchInputContainer>

      <FormControl sx={{ width: '100%', padding: '0 0 16px 16px' }}>
        <Box
          sx={{
            maxHeight: '40vh',
            overflow: 'hidden auto',
            ...sxMixins.thinVScrollbar(),
          }}
        >
          <FormGroup>
            {filteredVersions.length === 0 && (
              <StyledVersionEmpty>
                No results match your search
              </StyledVersionEmpty>
            )}
            {filteredVersions.map((version) => {
              const checked = currentVersionsIds.includes(version.id)

              return (
                <StyledVersionLine
                  key={'line_' + version.id}
                  disabled={currentVersionsIds.length > 2 && !checked}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={currentVersionsIds.length > 2 && !checked}
                        checked={checked}
                        onChange={() => handleVersions(version)}
                        size="medium"
                        disableRipple={true}
                        color="primary"
                      />
                    }
                    disableTypography
                    label={
                      <StyledVersionName>
                        <VersionLockUnlockIcon isLocked={version.isLocked} />
                        <span>{version.name}</span>
                      </StyledVersionName>
                    }
                  />
                </StyledVersionLine>
              )
            })}
          </FormGroup>
        </Box>
      </FormControl>
    </>
  )
}

export default VersionsContainer
