import {
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { InfoIcon } from '@fintastic/shared/ui/icons'
import { ChangeEvent, FC } from 'react'

export type ShowBlanksSwitchButtonProps = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  isLoading: boolean
  disabled?: boolean
  label: string
  tooltipTitle: string
}

export const ShowBlanksSwitchButton: FC<ShowBlanksSwitchButtonProps> = ({
  onChange,
  checked,
  isLoading,
  disabled,
  label,
  tooltipTitle,
}) => (
  <Box>
    <FormControlLabel
      sx={{ marginLeft: 0 }}
      data-testid="display-metric-blank-rows-toggle"
      control={
        <Switch
          onChange={onChange}
          checked={checked}
          disabled={isLoading || disabled}
        />
      }
      label={
        <Tooltip title={tooltipTitle}>
          <Box display="flex" alignItems="center" gap={'5px'} mr={1}>
            {isLoading && !disabled ? (
              <CircularProgress size={18} thickness={3} />
            ) : null}
            <Typography component="span">{label}</Typography>
            <InfoIcon fontSize="small" />
          </Box>
        </Tooltip>
      }
      labelPlacement="start"
    />
  </Box>
)
