import { useDispatch } from 'react-redux'
import {
  AddDimensionToMetricPayload,
  StartMetricCreationPayload,
  StartMetricEditingPayload,
  ChangeMetricValueDataTypePayload,
  ChangeMetricCategoryAggregationPayload,
  ChangeMetricTimeAggregationPayload,
  ChangeMetricValueDataFormattingPayload,
  ChangeMetricFormulaPayload,
  RemoveDimensionFromMetricPayload,
  ChangeMetricFormulaStatePayload,
  ChangeTimeDimensionToMetricPayload,
} from '../types'
import { actions } from '../slice'
import { useMemo } from 'react'
import { ChangeCurrencySignPositionPayload } from '../types/actions/shared'

export function useMetricsActions() {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      startEditing: (p: StartMetricEditingPayload) => {
        dispatch(actions.startMetricEditing(p))
      },
      startCreation: (p: StartMetricCreationPayload) => {
        dispatch(actions.startMetricCreation(p))
      },
      addDimension: (p: AddDimensionToMetricPayload) => {
        dispatch(actions.addDimensionToMetric(p))
      },
      changeTimeDimension: (p: ChangeTimeDimensionToMetricPayload) => {
        dispatch(actions.changeTimeDimensionToMetric(p))
      },
      removeDimension: (p: RemoveDimensionFromMetricPayload) => {
        dispatch(actions.removeDimensionFromMetric(p))
      },
      changeValueDataType: (p: ChangeMetricValueDataTypePayload) => {
        dispatch(actions.changeMetricDataValueType(p))
      },
      changeValueDataFormatting: (
        p: ChangeMetricValueDataFormattingPayload,
      ) => {
        dispatch(actions.changeMetricValueDataFormatting(p))
      },
      changeCurrencySignPosition: (p: ChangeCurrencySignPositionPayload) => {
        dispatch(actions.changeMetricCurrencySignPosition(p))
      },
      changeTimeAggregation: (p: ChangeMetricTimeAggregationPayload) => {
        dispatch(actions.changeMetricTimeAggregation(p))
      },
      changeCategoryAggregation: (
        p: ChangeMetricCategoryAggregationPayload,
      ) => {
        dispatch(actions.changeMetricCategoryAggregation(p))
      },
      changeFormula: (p: ChangeMetricFormulaPayload) => {
        dispatch(actions.changeMetricFormula(p))
      },
      changeFormulaState: (p: ChangeMetricFormulaStatePayload) => {
        dispatch(actions.changeMetricFormulaState(p))
      },
    }),
    [dispatch],
  )
}
