import {
  Metric,
  MetricDataValueType,
  MetricMetadata,
} from '@fintastic/web/util/metrics-and-lists'
import * as selectors from './selectors'

export const hasTimeDimension = (m: {
  metadata: Pick<MetricMetadata, 'time_dimension_id'>
}): boolean => selectors.timeDimension(m) !== null

export const aggregatedByTime = (m: {
  metadata: Pick<MetricMetadata, 'time_dimension_id' | 'base_time_dimension_id'>
}) =>
  hasTimeDimension(m) &&
  selectors.timeDimension(m) !== selectors.baseTimeDimension(m)

export const usesWeightedAverageAggregation = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): boolean =>
  selectors.categoryAggregation(m) === 'weighted_avg' ||
  selectors.timeAggregation(m) === 'weighted_avg'

export const allowedToHaveTimeDimension = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): boolean => selectors.dataType(m) !== 'datetime'

export const allowedToSetTimeDimension = (m: Metric): boolean =>
  selectors.timeDimension(m) === null

export const isCalculated = (m: Pick<Metric, 'source'>): boolean =>
  selectors.source(m) === 'calculated'

export const usesAnyOfDataTypes = (
  m: {
    metadata: Pick<MetricMetadata, 'value'>
  },
  dataTypes: MetricDataValueType[],
): boolean => dataTypes.includes(selectors.dataType(m))
