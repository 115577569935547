// @see https://github.com/MatthewBarker/hash-string/blob/master/source/hash-string.js
export function shortStringHash(text: string): number {
  let hash = 5381
  let index = text.length

  while (index) {
    // eslint-disable-next-line no-plusplus
    hash = (hash * 33) ^ text.charCodeAt(--index)
  }

  return hash >>> 0
}
