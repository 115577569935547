import React from 'react'
import { createSvgIcon } from '@mui/material'

export const Check2Icon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20.6279 2.00824C20.2747 2.04189 19.957 2.2246 19.7369 2.52071C19.699 2.5717 17.0526 6.26228 13.856 10.722L8.04398 18.8306L6.06398 16.8528C3.89929 14.6905 4.03169 14.8172 3.83598 14.7206C3.65339 14.6304 3.53076 14.6005 3.31998 14.5944C3.19297 14.5908 3.15801 14.5926 3.07342 14.607C2.61991 14.6845 2.24552 15.0025 2.09746 15.436C2.04851 15.5793 2.03662 15.6583 2.03662 15.84C2.03662 15.9789 2.0393 16.0163 2.05415 16.0845C2.09077 16.2528 2.15212 16.3984 2.24591 16.5398C2.29306 16.6109 2.37094 16.6925 2.92897 17.256C3.27538 17.6058 4.42037 18.7528 5.4734 19.805C7.34938 21.6794 7.38984 21.7192 7.4801 21.7786C7.62403 21.8734 7.76271 21.932 7.92982 21.9687C8.02628 21.9899 8.24273 21.9981 8.34617 21.9845C8.63766 21.9462 8.87949 21.8262 9.08798 21.6166C9.18797 21.516 9.18849 21.5153 15.476 12.7529C18.9344 7.93316 21.7796 3.96596 21.7987 3.93688C21.8711 3.82654 21.9285 3.69573 21.9624 3.56401C21.9929 3.44554 22.0028 3.34485 21.9976 3.20548C21.9905 3.01272 21.9564 2.87988 21.8678 2.70001C21.6936 2.3463 21.344 2.08711 20.952 2.02094C20.8606 2.00552 20.716 1.99985 20.6279 2.00824Z"
  />,
  'Check2',
)
