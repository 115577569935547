import { useEffect } from 'react'
import { UnsupportedGranularityAggregationError } from '@fintastic/web/util/period-selector'
import { useSetStaticWidgetBaseTimeDimension } from './useSetStaticWidgetBaseTimeDimension'
import { Maybe } from '@fintastic/shared/util/types'
import { isArray } from 'lodash'

export const useSetBaseTimeDimensionFromErrorEffect = (
  errors?: Maybe<Error> | Array<Maybe<Error>>,
): void => {
  const setStaticWidgetBaseTimeDimension = useSetStaticWidgetBaseTimeDimension()

  useEffect(() => {
    const handleError = (err?: Maybe<Error>) => {
      if (err && err instanceof UnsupportedGranularityAggregationError) {
        const baseDimensionId = err.getBaseTimeDimensionId()
        if (baseDimensionId) {
          setStaticWidgetBaseTimeDimension(baseDimensionId)
        }
      }
    }

    if (isArray(errors)) {
      errors.map(handleError)
    } else {
      handleError(errors)
    }
  }, [errors, setStaticWidgetBaseTimeDimension])
}
