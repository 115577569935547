import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  height: 100%;
  width: 100%;
`

export const StyledFilters = styled('div')`
  width: 100%;
`

export const StyledData = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(2)} 0;
`

export const StyledPagination = styled('div')`
  width: 100%;
`
