import { Maybe } from '@fintastic/shared/util/types'
import {
  getDefaultPeriodSelection,
  PeriodSelection,
  useCalendarConfigForSelectedEntities,
} from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import { Version } from '@fintastic/web/util/versions'
import { useCalendarDatePickerQuery } from '@fintastic/web/data-access/calendar'
import { useLoadMultipleVersionsEntities } from '../api/useLoadMultipleVersionsEntities'
import { compact } from 'lodash'

export const useReducedVersionRangePeriodSelection = (
  version?: Version,
): Maybe<PeriodSelection> => {
  const calendarQuery = useCalendarDatePickerQuery(version?.calendar_id ?? '')

  const versionIdAsArray = useMemo(
    () => compact([version?.uuid]),
    [version?.uuid],
  )
  const entitiesPerVersion = useLoadMultipleVersionsEntities(versionIdAsArray)

  const calendarConfigForSelectedEntities =
    useCalendarConfigForSelectedEntities({
      entitiesPerVersion: entitiesPerVersion?.data,
      calendarDatePickerConfig: calendarQuery?.data ?? null,
    })

  return useMemo<Maybe<PeriodSelection>>(() => {
    if (!calendarConfigForSelectedEntities) {
      return null
    }
    return getDefaultPeriodSelection(calendarConfigForSelectedEntities)
  }, [calendarConfigForSelectedEntities])
}
