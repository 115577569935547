import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  CalendarDatePickerConfig,
  isPeriodBasedSelection,
  isRangeBasedSelection,
  PeriodSelection,
  validatePeriodSelection,
} from '@fintastic/web/util/period-selector'
import {
  useIsLocalStorageOverrideDisabled,
  useSyncDeeplinkValue,
} from '@fintastic/web/util/deeplink'
import { useLocalStorage } from 'usehooks-ts'
import { Maybe, NullableFields } from '@fintastic/shared/util/types'
import { useResetThreadId } from '@fintastic/web/data-access/comments'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { isEqual } from 'lodash'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { useTenantOverride } from '@fintastic/web/data-access/service-auth0'

export const usePersistedPeriodSelectionValue = (
  persistingKey: string | undefined,
  defaultSelection: PeriodSelection,
  calendarConfig: Maybe<CalendarDatePickerConfig>,
  baseTimeDimensionId?: Maybe<TimeDimensionId>,
) => {
  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const resetThreadId = useResetThreadId()
  const [deeplinkValue, setDeeplinkValue] = useSyncDeeplinkValue<
    NullableFields<PeriodSelection>
  >({
    defaultValue: defaultSelection,
    key: persistingKey ? `w${persistingKey}_date` : null,
  })

  const { tenantName } = useTenantOverride()

  const [localStorageValue, setLocalStorageValue] = useLocalStorage<
    Maybe<PeriodSelection>
  >(persistingKey ? `${tenantName}_w${persistingKey}_date` : '', null)

  const defaultValueRef = useRef(defaultSelection)
  defaultValueRef.current = defaultSelection

  const origValue = useMemo<Maybe<PeriodSelection>>(() => {
    if (!calendarConfig) {
      return null
    }

    const validatedDeepLink = fromNullablePeriodSelection(deeplinkValue)

    if (
      !localStorageOverrideDisabled &&
      validatePeriodSelection(
        localStorageValue,
        calendarConfig,
        baseTimeDimensionId,
      )
    ) {
      return localStorageValue
    }

    if (
      validatedDeepLink &&
      !isEqual(validatedDeepLink, defaultValueRef.current)
    ) {
      return validatedDeepLink
    }

    return null
  }, [
    localStorageOverrideDisabled,
    baseTimeDimensionId,
    calendarConfig,
    deeplinkValue,
    localStorageValue,
  ])

  const value = useReferenceMemo<Maybe<PeriodSelection>>(origValue, isEqual)

  const setPersistedValue = useCallback(
    (nextValue: PeriodSelection) => {
      setLocalStorageValue(
        isEqual(nextValue, defaultSelection) ? null : nextValue,
      )
      setDeeplinkValue(periodSelectionWithNullableValues(nextValue))
      resetThreadId()
    },
    [defaultSelection, setDeeplinkValue, setLocalStorageValue, resetThreadId],
  )

  useEffect(() => {
    if (localStorageOverrideDisabled || !calendarConfig) {
      return
    }

    if (
      !validatePeriodSelection(
        localStorageValue,
        calendarConfig,
        baseTimeDimensionId,
      )
    ) {
      return
    }

    setDeeplinkValue(localStorageValue)
  }, [
    localStorageOverrideDisabled,
    baseTimeDimensionId,
    calendarConfig,
    defaultSelection,
    localStorageValue,
    setDeeplinkValue,
  ])

  return [value, setPersistedValue, !!origValue] as const
}

const periodSelectionWithNullableValues = (
  value: PeriodSelection,
): NullableFields<PeriodSelection> => {
  if (isRangeBasedSelection(value)) {
    return {
      ...value,
      periods: undefined,
    }
  }

  return {
    ...value,
    range: undefined,
  }
}

const fromNullablePeriodSelection = (
  value: NullableFields<PeriodSelection>,
): Maybe<PeriodSelection> => {
  if (!value.aggregationDimensionId || !value.dimensionId) {
    return null
  }

  if (isRangeBasedSelection(value)) {
    return {
      aggregationDimensionId: value.aggregationDimensionId,
      dimensionId: value.dimensionId,
      range: value.range,
    }
  }

  if (isPeriodBasedSelection(value)) {
    return {
      aggregationDimensionId: value.aggregationDimensionId,
      dimensionId: value.dimensionId,
      periods: value.periods,
    }
  }

  return null
}
