import React from 'react'
import { useReportEditorStateApi } from '../editor-state'
import { ReportEditorApiContext } from './ReportEditorApiContext'
import {
  useLockVersionForUser,
  useReleaseVersionUserLocker,
} from '@fintastic/web/data-access/versions'
import {
  DeleteConfirmationModal,
  useDeleteOperation,
} from '../lifecycle-operations/delete'
import { useStartCreationOperation } from '../lifecycle-operations/start-creation'
import { useStartEditingOperation } from '../lifecycle-operations/start-editing'
import { useSaveOperation } from '../lifecycle-operations/save'
import {
  DiscardConfirmationModal,
  useDiscardOperation,
} from '../lifecycle-operations/discard'
import { usePublicApiImplementation } from './implementation'
import { useQueryClient } from 'react-query'
import {
  useModalState,
  useModalWithParamsState,
} from '@fintastic/shared/util/modal'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { Maybe } from '@fintastic/shared/util/types'

export const ReportEditorProvider: React.FC<{
  versionId: string
  children: React.ReactNode
}> = ({ children, versionId }) => {
  const stateApi = useReportEditorStateApi()

  const queryClient = useQueryClient()

  const { mutateAsync: lockVersion } = useLockVersionForUser(versionId)
  const { mutateAsync: unlockVersion } = useReleaseVersionUserLocker(versionId)

  const deleteConfirmationModalApi = useModalWithParamsState<
    Maybe<{
      reportId: string
    }>
  >({
    initialParams: null,
  })
  const deleteOperationApi = useDeleteOperation(
    {
      versionId,
    },
    {
      openConfirmationModal: deleteConfirmationModalApi.openWithParams,
      closeConfirmationModal: deleteConfirmationModalApi.close,
      displayError: toast.error,
      displaySuccess: toast.success,
      queryClient,
    },
  )
  const startCreationOperationApi = useStartCreationOperation(
    {
      versionId,
    },
    {
      stateApi,
      lockVersion,
      displayError: toast.error,
    },
  )
  const startEditingOperationApi = useStartEditingOperation(
    {
      versionId,
    },
    {
      stateApi,
      lockVersion,
      displayError: toast.error,
    },
  )

  const saveOperationApi = useSaveOperation(
    {
      versionId,
    },
    {
      stateApi,
      unlockVersion,
      displayError: toast.error,
      displaySuccess: toast.success,
      queryClient,
    },
  )

  const discardConfirmationModalApi = useModalState()
  const discardOperationApi = useDiscardOperation(
    {},
    {
      stateApi,
      unlockVersion,
      displayError: toast.error,
      openConfirmationModal: discardConfirmationModalApi.open,
      closeConfirmationModal: discardConfirmationModalApi.close,
    },
  )

  const api = usePublicApiImplementation(
    {},
    {
      stateApi,
      saveOperationApi,
      discardOperationApi,
      deleteOperationApi,
      startCreationOperationApi,
      startEditingOperationApi,
    },
  )

  return (
    <ReportEditorApiContext.Provider value={api}>
      {children}
      <DiscardConfirmationModal
        isOpen={discardConfirmationModalApi.isOpen}
        close={discardConfirmationModalApi.close}
        onConfirm={() => discardOperationApi.mutateAsync({ confirmed: true })}
        discarding={discardOperationApi.isLoading}
      />
      <DeleteConfirmationModal
        isOpen={deleteConfirmationModalApi.isOpen}
        close={deleteConfirmationModalApi.close}
        onConfirm={() =>
          deleteOperationApi.mutateAsync({
            confirmed: true,
            reportId: deleteConfirmationModalApi.params?.reportId || '',
          })
        }
        deleting={deleteOperationApi.isLoading}
      />
    </ReportEditorApiContext.Provider>
  )
}
