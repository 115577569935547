import { DimensionValue, DimensionValueId } from '@fintastic/shared/util/types'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  BuildLegacyListGridColumnDefinitionParams,
  LegacyListGridValueMap,
} from '../types'
import {
  BaseGridAgChartDataType,
  BuildBaseGridColumnDefinitionParams,
  BaseGridColumnId,
  BaseGridContextProp,
  baseGridColumnsMatches,
} from '@fintastic/shared/ui/grid-framework'
import { AgGridColDefBuilder } from '@fintastic/shared/util/ag-grid'
import {
  AgGridCustomCellEditorName,
  AgGridCustomCellRendererName,
  AgGridDefaultCellRendererProps,
  AgGridSelectboxCellEditorProps,
  isMasked,
  ExternalLinkCellRenderer,
} from '@fintastic/shared/ui/ag-grid'
import { EditableCallback } from 'ag-grid-community/dist/lib/entities/colDef'
import { resolveAggregation } from '@fintastic/shared/ui/ag-grid'
import { comparatorWithBlanks, stringifyBlankOrMaskedValue } from '@fintastic/web/util/blanks-and-masked'

/** @deprecated */
const listDefaultColumnMatch = {
  matcher: () => true,
  builder: (
    colDef: ColDef,
    params: BuildLegacyListGridColumnDefinitionParams,
  ) => {
    const { title, field, isEditable, isRequired } = params
    const cellEditor: AgGridCustomCellEditorName = 'textCellEditor'
    const cellRenderer: AgGridCustomCellRendererName = 'defaultCellRenderer'
    const cellRendererParams: AgGridDefaultCellRendererProps = {
      showTooltipForValue: true,
      isRequired: isRequired,
      populateForward: params.populateForward,
      getClearValue: params.getCellClearValue,
    }

    const editableCallback: EditableCallback = (editableCbParams) => {
      const data = editableCbParams.data as LegacyListGridValueMap

      return !!isEditable && !isMasked(field, data)
    }

    return {
      ...colDef,
      editable: editableCallback,
      colId: field,
      field: field,
      headerName: title,
      headerTooltip: title,
      cellClass: () => (isEditable ? [] : ['readonly']),
      headerClass: () => (isEditable ? [] : ['readonly']),
      cellEditor,
      cellRenderer,
      cellRendererParams,
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
      aggFunc: resolveAggregation(params.rollUpFunction),
      valueFormatter: ({ value }) => stringifyBlankOrMaskedValue(value),
      comparator: comparatorWithBlanks,
      ...(params.isRowId && { pinned: true }),
    } as ColDef
  },
}

/** @deprecated */
export const legacyListColumnsMatches = (context: BaseGridContextProp = {}) => [
  {
    matcher: (params: BuildLegacyListGridColumnDefinitionParams) =>
      params.dataType === 'dimension',
    builder: (
      colDef: ColDef,
      params: BuildLegacyListGridColumnDefinitionParams,
    ) => {
      let dimensionValues: Record<DimensionValueId, DimensionValue>
      if (typeof params.options === 'string' && params.dimensionsConfig) {
        // backwards compatibility for older non id approach
        if (params.dimensionsConfig[params.options]?.values) {
          dimensionValues =
            Object.fromEntries(
              params.dimensionsConfig[params.options]?.values?.map((v) => [
                v,
                v,
              ]),
            ) || {}
        } else {
          dimensionValues = {}
        }
      } else if (typeof params.options === 'object') {
        dimensionValues = params.options as Record<string, string>
      } else {
        dimensionValues = {}
      }

      const dimensionColDef: ColDef = {
        ...colDef,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        valueGetter: ({ data }) => {
          if (!colDef.field || !data) {
            return '-'
          }
          const dimensionValueId = data[colDef.field]
          return dimensionValues[dimensionValueId] || dimensionValueId
        },
        valueParser: ({ newValue: dimensionValueLabelOrId }) => {
          const dimValuePair = Object.entries(dimensionValues).find(
            ([dimValueId, dimValueLabel]) =>
              dimValueLabel === dimensionValueLabelOrId ||
              dimValueId === dimensionValueLabelOrId,
          )
          return dimValuePair?.[0] || dimensionValueLabelOrId
        },
        valueFormatter: ({ value }) => stringifyBlankOrMaskedValue(value),
        chartDataType: 'category' as BaseGridAgChartDataType,
      }

      // @todo: Support isEditable callback
      if (params.isEditable) {
        const dimensionOptions = Object.entries(dimensionValues)
          .map(([k, v]) => ({
            label: v,
            value: k,
          }))
          .sort(({ label: a }, { label: b }) => a.localeCompare(b))

        dimensionColDef.cellEditor = 'selectBoxCellEditor'
        dimensionColDef.cellEditorParams = {
          options: dimensionOptions,
        }
      }

      return dimensionColDef
    },
  },
  {
    matcher: ({ dataType }: BuildLegacyListGridColumnDefinitionParams) =>
      [
        'salesforce_account',
        'netsuite_account',
        'netsuite_transaction_id',
      ].includes(dataType || ''),
    builder: (
      colDef: ColDef,
      { dataType }: BuildLegacyListGridColumnDefinitionParams,
    ) => {
      const linkResolver = context?.linkResolver
      if (!linkResolver) {
        console.error('received id column but not link resolver specified')
        return colDef
      }
      return {
        ...colDef,
        cellRenderer: ExternalLinkCellRenderer,
        cellRendererParams: {
          linkResolver: (value: any, params: ICellRendererParams) =>
            linkResolver(dataType as BaseGridColumnId, value, params),
        },
        chartDataType: 'category' as BaseGridAgChartDataType,
      }
    },
  },

  {
    matcher: (params: BuildLegacyListGridColumnDefinitionParams) =>
      params.dataType === 'values',
    builder: (
      colDef: ColDef,
      params: BuildLegacyListGridColumnDefinitionParams,
    ) => {
      const options = params.options instanceof Array ? params.options : []

      const cellEditor: AgGridCustomCellEditorName = 'selectBoxCellEditor'
      const cellEditorProps: AgGridSelectboxCellEditorProps = {
        options: options
          .sort((a, b) => a.localeCompare(b))
          .map((option) => ({
            label: option,
            value: option,
          })),
      }

      return {
        ...colDef,
        filter: 'agSetColumnFilter',
        cellEditor,
        cellEditorParams: cellEditorProps,
        chartDataType: 'category' as BaseGridAgChartDataType,
        valueFormatter: ({ value }) => stringifyBlankOrMaskedValue(value),
      } as ColDef
    },
  },
]

/** @deprecated */
export const buildLegacyListGridColumnDefinition =
  (context: BaseGridContextProp = {}) =>
  (params: BuildLegacyListGridColumnDefinitionParams): ColDef => {
    const listMatches = legacyListColumnsMatches(context)
    const everythingMatches = baseGridColumnsMatches(context)

    const builder = new AgGridColDefBuilder<
      BuildBaseGridColumnDefinitionParams &
        BuildLegacyListGridColumnDefinitionParams
    >([listDefaultColumnMatch, ...everythingMatches, ...listMatches])

    return builder.build(
      params as BuildBaseGridColumnDefinitionParams &
        BuildLegacyListGridColumnDefinitionParams,
    )
  }
