import { ListId, MetricId, ModelExplorerApi, ReportId } from './types'
import { useMemo, useReducer } from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDiagramConfig } from './diagram-config'

type State =
  | {
      isOpen: false
      versionId: null
      entityId: null
    }
  | {
      isOpen: true
      versionId: string
      entityId: MetricId | ListId | ReportId
    }

const initialState: State = {
  isOpen: false,
  versionId: null,
  entityId: null,
}

const slice = createSlice({
  name: 'modelExplorerGlobalApi',
  initialState: initialState as State,
  reducers: {
    open: (
      s,
      a: PayloadAction<{
        versionId: string
        entityId: MetricId | ListId | ReportId
      }>,
    ) => {
      s.isOpen = true
      s.versionId = a.payload.versionId
      s.entityId = a.payload.entityId
    },
    close: (s) => {
      s.isOpen = false
    },
    moveFocusToEntity: (
      s,
      a: PayloadAction<{
        entityId: MetricId | ListId | ReportId
      }>,
    ) => {
      if (!s.isOpen) {
        return
      }
      s.entityId = a.payload.entityId
    },
  },
})

export const useModelExplorerApiImplementation = (): ModelExplorerApi => {
  const [state, dispatch] = useReducer(slice.reducer, slice.getInitialState())

  const diagramConfig = useDiagramConfig()

  return useMemo(
    () =>
      state.isOpen
        ? {
            ...state,
            close: () => {
              dispatch(slice.actions.close())
            },
            moveFocusToEntity: (entityId: MetricId | ListId | ReportId) => {
              dispatch(slice.actions.moveFocusToEntity({ entityId }))
            },
            diagramConfig,
          }
        : {
            isOpen: false,
            open: (
              versionId: string,
              entityId: MetricId | ListId | ReportId,
            ) => {
              dispatch(slice.actions.open({ versionId, entityId }))
            },
          },
    [state, diagramConfig],
  )
}
