import {
  PeriodsList,
  PeriodsRange,
  PeriodsBasedSelection,
  RangeBasedSelection,
} from '@fintastic/web/util/period-selector'
import { DimensionId } from '@fintastic/web/util/dimensions'

export const createPeriodsBasedSelection = (
  periods: PeriodsList = [],
  dimensionId: DimensionId = 'dim.year',
  aggregationDimensionId: DimensionId = 'dim.year',
): PeriodsBasedSelection => ({
  dimensionId,
  aggregationDimensionId,
  periods,
})

export const createRangeBasedSelection = (
  range: PeriodsRange,
  dimensionId: DimensionId = 'dim.year',
  aggregationDimensionId: DimensionId = 'dim.year',
): RangeBasedSelection => ({
  dimensionId,
  aggregationDimensionId,
  range,
})
