import React from 'react'
import {
  StyledArrowsSyncIcon,
  StyledLiveActualsPageStatus,
  StyledLiveActualsPageTitle,
  StyledLiveActualsPageTitleRoot,
  StyledLiveActualsStatus,
  StyledSuccessIcon,
} from './LiveActualsStatus.styled'
import { Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'
import { EditOffIcon } from '@fintastic/shared/ui/icons'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'

type Props = {
  pageTitle: string
  isUpdated: boolean
  lock: VersionUserLockParsed
}

export const LiveActualsStatus: React.FC<Props> = ({
  pageTitle,
  isUpdated,
  lock,
}) => (
  <StyledLiveActualsPageTitleRoot>
    <StyledLiveActualsPageTitle>
      {lock.editIsBlocked ? (
        <Tooltip title={versionIsLockedMessage(lock)} arrow>
          <span>
            <span style={{ color: '#FF7933' }}>
              <EditOffIcon
                fontSize="small"
                sx={{ marginRight: '8px', marginTop: '2px' }}
              />
            </span>
            {pageTitle}
          </span>
        </Tooltip>
      ) : (
        pageTitle
      )}
    </StyledLiveActualsPageTitle>
    <StyledLiveActualsPageStatus>
      {isUpdated ? (
        <StyledLiveActualsStatus>
          <StyledSuccessIcon />
          <span>Up to date</span>
        </StyledLiveActualsStatus>
      ) : (
        <StyledLiveActualsStatus>
          <StyledArrowsSyncIcon />
          <span>Data may be outdated</span>
          <Tooltip
            title={
              'We are working to resolve the issue. No action required on your side.'
            }
            placement="right"
            arrow
          >
            <InfoOutlinedIcon sx={{ width: '13px' }} />
          </Tooltip>
        </StyledLiveActualsStatus>
      )}
    </StyledLiveActualsPageStatus>
  </StyledLiveActualsPageTitleRoot>
)
