import React, { forwardRef } from 'react'
import { StyledWidgetResizeHandlerRoot } from './WidgetResizeHandler.styled'

type Props = {
  handleAxis?: string
}

export const WidgetResizeHandler = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { handleAxis, ...rest } = props

    return (
      <StyledWidgetResizeHandlerRoot
        className={`widget-resize-handler handle-${handleAxis}`}
        ref={ref}
        {...rest}
      />
    )
  },
)
