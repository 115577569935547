import React, { useCallback, useContext, useMemo } from 'react'
import { baseGridContext } from '@fintastic/shared/ui/grid-framework'
import {
  PanelToolbarIconButton,
  PanelToolbarIconButtonProps,
} from '@fintastic/shared/ui/panel-framework'
import { SvgIconProps } from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

const defaultIconProps: SvgIconProps = {
  fontSize: 'medium',
}

export type ResetGridButtonProps = Omit<
  PanelToolbarIconButtonProps,
  'children' | 'title' | 'onClick'
> & {
  onClick?: () => void
  iconProps?: SvgIconProps
}

export const ResetGridButton = ({
  iconProps = defaultIconProps,
  onClick,
  ...rest
}: ResetGridButtonProps): JSX.Element | null => {
  const gridContext = useContext(baseGridContext)
  const [, setRefreshKey] = gridContext.refreshState

  const mergedIconProps = useMemo(
    () => ({
      ...defaultIconProps,
      ...iconProps,
    }),
    [iconProps],
  )

  const handleClick = useCallback(() => {
    onClick?.()
    if (setRefreshKey) {
      setRefreshKey((prevKey) => prevKey + 1)
    }
  }, [onClick, setRefreshKey])

  return (
    <PanelToolbarIconButton
      data-testid="reset-grid-button"
      title="Reset grid"
      {...rest}
      onClick={handleClick}
    >
      <RestartAltIcon {...mergedIconProps} />
    </PanelToolbarIconButton>
  )
}
