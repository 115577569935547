import React, { Suspense } from 'react'
import { useModelExplorerApi } from '../model-explorer-api-context'
import { useLoadVersion } from '@fintastic/web/data-access/versions'
import {
  StyledContent,
  StyledHeader,
  StyledRoot,
  StyledLoader,
  StyledContainer,
  StyledCloseButtonCont,
  StyledTextCont,
  StyledError,
} from './Popup.styled'
import {
  CenteredCircularProgress,
  ErrorAlert,
} from '@fintastic/shared/ui/components'
import {
  Typography,
  Skeleton,
  IconButton,
  Breadcrumbs,
  Link,
} from '@mui/material'
import { CloseIcon } from '@fintastic/shared/ui/icons'
import { ModelExplorerActiveApi } from '@fintastic/web/feature/model-explorer'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { Link as RouterLink } from 'react-router-dom'
import { routes as versionsRoutes } from '@fintastic/web/feature/versions'

const ModelExplorer = React.lazy(() => import('../../local'))

const PopupContent: React.FC = () => {
  const api = useModelExplorerApi() as ModelExplorerActiveApi

  const versionQuery = useLoadVersion(api.isOpen ? api.versionId : null)
  const isLoading = versionQuery.isLoading
  const isError = versionQuery.isError

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledCloseButtonCont>
          <IconButton
            onClick={api.close}
            color="textBlack"
            sx={{ fontSize: 20 }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </StyledCloseButtonCont>
        <StyledTextCont>
          <Breadcrumbs>
            {isLoading ? (
              <Skeleton width={120} />
            ) : (
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                onClick={api.close}
                to={versionsRoutes.version(versionQuery.data?.uuid)}
              >
                {versionQuery.data?.name}
              </Link>
            )}
            <Typography color="text.primary" fontWeight={600}>
              Version Explorer
            </Typography>
          </Breadcrumbs>
        </StyledTextCont>
      </StyledHeader>
      <StyledContent>
        {isLoading && (
          <StyledLoader>
            <CenteredCircularProgress fullScreen={false} />
          </StyledLoader>
        )}
        {!isLoading && !isError && (
          <Suspense
            fallback={
              <StyledLoader>
                <CenteredCircularProgress fullScreen={false} />
              </StyledLoader>
            }
          >
            {api.isOpen && (
              <ModelExplorer
                versionId={api.versionId}
                entityId={api.entityId}
                requestMoveFocusToEntity={api.moveFocusToEntity}
                diagramConfigApi={api.diagramConfig}
                isLiveActuals={!!versionQuery.data?.is_live_actuals}
              />
            )}
          </Suspense>
        )}
        {!isLoading && isError && (
          <StyledError>
            <ErrorAlert
              title="Something went wrong."
              message="Please contact the administrator."
              severity="warning"
            />
          </StyledError>
        )}
      </StyledContent>
    </StyledContainer>
  )
}

export const Popup: React.FC = () => {
  const api = useModelExplorerApi()!

  /**
   * Only if modal is open the PopupContent will be mounted and rendered,
   * so we don't make unnecessary requests to BE.
   */
  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <StyledRoot
        open={api.isOpen}
        onClose={(api.isOpen && api.close) || undefined}
        anchor="bottom"
        transitionDuration={400}
      >
        <PopupContent />
      </StyledRoot>
    </FintasticThemeProvider>
  )
}
