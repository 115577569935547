import { TimeDimensionValueId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'

type XAxisCoordinates = {
  metricId: string
  period: Maybe<TimeDimensionValueId>
  versionId: string
}

export const makeXAxisCoordinateKey = ({
  metricId,
  period,
  versionId,
}: XAxisCoordinates) => `${versionId}:${metricId}${period ? `:${period}` : ''}`
