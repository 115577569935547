import { axios } from '@fintastic/web/data-access/service-axios'
import { Board, BoardShortened } from '../types'

const baseURL = `${axios.defaults.baseURL}/planning/api/v1/boards`

export type GetBoardsResponse = {
  result: BoardShortened[]
}

export function getBoards() {
  return axios.get<GetBoardsResponse>('/', {
    baseURL,
  })
}

export function getBoard(boardId: Board['id']) {
  return axios.get<Board>(`/${boardId}/`, {
    baseURL,
  })
}

export type EditableBoardParams = Partial<{
  name: Board['name']
  config: Board['config']
  inherit_access: Board['inherit_access']
  folder_id: Board['folder_id']
}>

export function copyBoard(boardId: Board['id'], params: EditableBoardParams) {
  return axios.post<Board>(`/${boardId}/copy`, params, {
    baseURL,
  })
}

export function createBoard(params: EditableBoardParams) {
  return axios.post<Board>('/', params, {
    baseURL,
  })
}

export function updateBoard(boardId: Board['id'], params: EditableBoardParams) {
  return axios.patch<Board>(`/${boardId}/`, params, {
    baseURL,
  })
}

export function deleteBoard(boardId: Board['id'], permanently?: boolean) {
  return axios.delete(`/${boardId}/`, {
    baseURL,
    params: { permanently },
  })
}

export function unDeleteBoard(boardId: Board['id']) {
  return axios.put(
    `/${boardId}/`,
    {},
    {
      baseURL,
    },
  )
}
