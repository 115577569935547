import {
  SideBarDef,
  ToolPanelDef,
} from 'ag-grid-community/dist/lib/entities/sideBar'
import { WithRequired } from '@fintastic/shared/util/types'
import { buildColumnsToolPanelDefinition } from './buildColumnsToolPanelDefinition'

type Params = {
  showToggleGrouping?: boolean
  showColumnsSelection?: boolean
  customToolPanels?: ToolPanelDef[]
  suppressFiltersToolPanel?: boolean
}

export function buildSidebarDefinition({
  showToggleGrouping,
  showColumnsSelection,
  customToolPanels,
  suppressFiltersToolPanel,
}: Params): SideBarDef {
  const definition: WithRequired<SideBarDef, 'toolPanels'> = {
    toolPanels: [],
  }

  const columnsToolPanel = buildColumnsToolPanelDefinition(
    !!showColumnsSelection,
    !!showToggleGrouping,
  )
  if (columnsToolPanel !== null) {
    definition.toolPanels.push(columnsToolPanel)
  }

  if (!suppressFiltersToolPanel) {
    definition.toolPanels.push({
      id: 'filters',
      labelDefault: '',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    })
  }

  definition.toolPanels.push(...(customToolPanels || []))

  return definition
}
