import React, { useCallback, useEffect, useRef } from 'react'
import { sanitizeName } from '@fintastic/shared/util/ag-grid'
import { TextField } from '@mui/material'

export const NameField: React.FC<{
  onChange: (newName: string) => void
  value: string
  isValid: boolean
  dirty: boolean
  validationMessage: string
  disabled: boolean
  autofocus: boolean
  onAutoFocus: () => void
}> = ({
  onChange,
  isValid,
  dirty,
  value,
  validationMessage,
  disabled,
  autofocus,
  onAutoFocus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!autofocus) {
      return
    }
    inputRef.current?.focus()
    onAutoFocus()
  }, [autofocus, onAutoFocus])

  const handleChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (event) => {
      onChange(sanitizeName(event.target.value))
    },
    [onChange],
  )

  return (
    <TextField
      inputRef={inputRef}
      fullWidth={true}
      label="Name"
      size="small"
      onChange={handleChange}
      value={value}
      error={dirty ? !isValid : false}
      helperText={dirty && !isValid ? validationMessage : undefined}
      disabled={disabled}
    />
  )
}
