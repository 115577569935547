import {
  CalendarDatePickerConfig,
  PeriodsList,
  convertPeriodSelectionToRange,
  convertRangeSelectionToPeriods,
  isEmptyPeriodSelection,
} from '@fintastic/web/util/period-selector'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { NewPeriodsRangeGetter } from '../../components/types'

export const getNewPeriodList = (
  datePickerConfig: CalendarDatePickerConfig,
  newPeriodsRangeGetter: NewPeriodsRangeGetter,
  nextDimensionId: DimensionId,
  periodSelection: PeriodSelection,
): PeriodsList => {
  if (isEmptyPeriodSelection(periodSelection)) {
    return []
  }

  const range = convertPeriodSelectionToRange(periodSelection, datePickerConfig)

  if (!range) {
    return []
  }

  const nextGranularityRange = newPeriodsRangeGetter(
    nextDimensionId,
    periodSelection,
  )
  return convertRangeSelectionToPeriods(
    {
      dimensionId: nextDimensionId,
      aggregationDimensionId: nextDimensionId,
      range: nextGranularityRange,
    },
    datePickerConfig,
  )
}
