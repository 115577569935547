import { ThemeComponents } from './types'
import { alpha } from '@mui/system'
import { outlinedInputClasses } from '@mui/material'

export const MuiOutlinedInput: ThemeComponents['MuiOutlinedInput'] = {
  styleOverrides: {
    input: {
      lineHeight: '24px',
    },
    sizeSmall: ({ theme }) => ({
      [`.${outlinedInputClasses.input}`]: {
        padding: theme.spacing(1, 1.75),
        height: 24,
      },
    }),
    root: {
      [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: alpha('#D9D9D9', 0.54),
        },
    },
    notchedOutline: ({ theme }) => ({
      borderColor: alpha('#D9D9D9', 0.54),
    }),
  },
}
