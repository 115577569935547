import {
  Metric,
  hideMaskedValues,
  parseList,
} from '@fintastic/web/util/metrics-and-lists'
import { useMemo } from 'react'
import { RangeDimensionId } from '@fintastic/web/util/dimensions'
import { toMaybe } from '@fintastic/shared/util/types'

type Context = {
  versionId: string
  rowDimension: RangeDimensionId
}

export const useRowData = (columns: Metric[], context: Context) =>
  useMemo(() => columnsToRowData(columns, context), [columns, context])

const columnsToRowData = (
  columns: Metric[],
  { rowDimension, versionId }: Context,
) => {
  const timeDimension = toMaybe(
    columns.find((c) => c.metadata.time_dimension_id)?.metadata
      .time_dimension_id,
  )

  return (
    parseList(
      columns.map((c) => ({
        ...c,
        data: {
          ...c.data,
          values: hideMaskedValues(c.data.values, c.metadata.value.mask),
        },
      })),
      rowDimension || '',
      versionId || '',
      timeDimension || undefined,
    ) || []
  )
}
