import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'

import {
  ChartLegendPosition,
  chartLegendPositionKeys,
  chartLegendPositions,
  defaultChartLegendPosition,
  isChartLegendPosition,
} from '@fintastic/web/feature/charts'
import {
  StyledTextWithoutIcon,
  StyledTextWithoutIconField,
} from '../common-styles'

export type ChartLegendPositionSelectProps = {
  legendPosition: ChartLegendPosition
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartLegendPositionSelect: React.FC<ChartLegendPositionSelectProps> =
  memo(({ legendPosition, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartLegendPosition(e.target.value)) {
          onChangeChartSettings({ legendPosition: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextWithoutIconField
          id="chart-type-select"
          fullWidth
          value={legendPosition}
          size="small"
          select
          label="Legend position"
          defaultValue={defaultChartLegendPosition}
          onChange={handleSelect}
          disabled={disabled}
        >
          {chartLegendPositionKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithoutIcon data-testid={`chart-type-item-${ct}`}>
                {chartLegendPositions[ct]}
              </StyledTextWithoutIcon>
            </MenuItem>
          ))}
        </StyledTextWithoutIconField>
      </FormField>
    )
  })
