import { ColumnType, MetricDataValueType, MetricWithoutData } from '../../types'

type ColumnLike = Pick<MetricWithoutData, 'source'> & {
  metadata?: {
    value?: {
      type: MetricDataValueType
    }
  }
}

/**
 * @deprecated
 * use ListColumnWrapper instead
 */
export function getColumnType(column: ColumnLike): ColumnType {
  if (column.metadata?.value?.type === 'dimension') {
    return 'dimension'
  }
  return column.source === 'raw' ? 'input' : column.source || 'input'
}
