import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnFormulaStatePayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'
import { EditableMetric } from '../../../types'

// @todo add tests for formula state
export const changeColumnFormulaState = (
  uncheckedState: State,
  {
    payload: { metricId, ...restPayload },
  }: PayloadAction<ChangeColumnFormulaStatePayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  if (list.isCalculated()) {
    // @todo move it in the list ADT
    throw new Error('not allowed in calculated lists')
  }

  const column = list.findColumnByClientId(metricId)
  if (!column) {
    throw new Error('Column does not exist')
  }

  if (!column.isCalculated()) {
    throw new Error('Column is not calculated')
  }

  /**
   * @see EditableMetric
   */
  if (restPayload.valid) {
    delete (column.unwrap() as EditableMetric).formulaInvalid
    delete (column.unwrap() as EditableMetric).formulaCalculationError
    return
  }

  ;(column.unwrap() as EditableMetric).formulaInvalid = true
  if (restPayload.error) {
    ;(column.unwrap() as EditableMetric).formulaCalculationError =
      restPayload.error
  }
}
