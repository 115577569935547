import React, { useMemo, useRef, useState, useEffect } from 'react'
import { createContext, useContext } from 'react'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'

const TotalLineLoadingContext = createContext(false)

export const useTotalLineLoadingContext = () =>
  useContext(TotalLineLoadingContext)

export const TotalLineLoadingContextProvider: React.FC<{
  versionUserLocks: VersionUserLockParsed[]
  weightsAreFetching: boolean
  weightsAreCalculated: boolean
  children: React.ReactNode
}> = ({
  children,
  weightsAreFetching,
  versionUserLocks,
  weightsAreCalculated,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const isLoadingRef = useRef(isLoading)
  isLoadingRef.current = isLoading

  const calculating = useMemo(
    () => versionUserLocks.some((l) => l.lockReasons?.calc),
    [versionUserLocks],
  )

  useEffect(() => {
    const newIsLoading = weightsAreFetching || calculating
    if (newIsLoading && !isLoadingRef.current) {
      setIsLoading(true)
      return
    }
    if (!newIsLoading && isLoadingRef.current) {
      const tid = setTimeout(() => {
        setIsLoading(false)
      }, 300)
      return () => clearTimeout(tid)
    }
    return
  }, [weightsAreFetching, calculating])

  return (
    <TotalLineLoadingContext.Provider
      value={weightsAreCalculated ? isLoading : false}
    >
      {children}
    </TotalLineLoadingContext.Provider>
  )
}
