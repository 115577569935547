import React from 'react'
import { createSvgIcon } from '@mui/material'

export const SendEmailIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.15605 2.63718C3.67394 2.66495 3.22711 2.87216 2.89631 3.22137C2.63792 3.49415 2.47365 3.8259 2.40593 4.21172C2.39182 4.29215 2.389 4.3409 2.38881 4.50799C2.38861 4.68175 2.39098 4.72146 2.40687 4.81065C2.44275 5.01199 2.4977 5.17992 2.584 5.35187C2.606 5.39573 3.52822 6.90648 4.63337 8.7091C5.73851 10.5117 6.64262 11.9905 6.64251 11.9953C6.64239 12.0001 5.73819 13.4809 4.63318 15.2861C3.52816 17.0913 2.60605 18.6042 2.58402 18.6481C2.4977 18.8201 2.44275 18.988 2.40687 19.1893C2.39098 19.2785 2.38861 19.3182 2.38881 19.492C2.389 19.6591 2.39182 19.7078 2.40593 19.7883C2.47365 20.1741 2.63792 20.5058 2.89631 20.7786C3.41189 21.3229 4.19204 21.5069 4.901 21.2516C4.94448 21.2359 5.03765 21.1951 5.10805 21.1608C5.31694 21.0591 20.9177 13.122 20.9946 13.0783C21.3078 12.9002 21.5247 12.5976 21.6012 12.232C21.6243 12.1219 21.6265 11.8912 21.6056 11.7794C21.5419 11.4386 21.3484 11.1423 21.0673 10.9553C21.0312 10.9312 20.95 10.885 20.8869 10.8525C20.6097 10.7099 5.23904 2.90324 5.12487 2.84707C4.97684 2.77422 4.90305 2.74484 4.77571 2.70801C4.57572 2.65018 4.35946 2.62546 4.15605 2.63718ZM4.10805 3.90435C3.93312 3.94833 3.78807 4.0636 3.70368 4.22569C3.65597 4.31732 3.63828 4.39284 3.63755 4.50799C3.63683 4.62038 3.65449 4.69642 3.70253 4.78782C3.71877 4.81872 4.63205 6.31188 5.73204 8.10599L7.73202 11.368L13.432 11.3678L19.1321 11.3676L18.9241 11.2608C17.6813 10.6224 4.49929 3.93495 4.44805 3.91694C4.38834 3.89595 4.36737 3.89275 4.27605 3.89064C4.19257 3.88872 4.15943 3.89143 4.10805 3.90435ZM5.73109 15.89C4.63161 17.6863 3.71877 19.1813 3.70253 19.2122C3.65449 19.3036 3.63683 19.3796 3.63755 19.492C3.63828 19.6071 3.65597 19.6827 3.70368 19.7743C3.78989 19.9399 3.93547 20.0537 4.11605 20.0966C4.22082 20.1216 4.36114 20.1157 4.45205 20.0826C4.48725 20.0698 7.76865 18.4048 11.7441 16.3826C15.7195 14.3603 19.0081 12.6876 19.0521 12.6653L19.1321 12.6248L13.4311 12.6244L7.73011 12.624L5.73109 15.89Z"
  />,
  'SendEmail',
)
