import { useNavigate } from 'react-router-dom'

import React, { useEffect } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useAuthAccessToken } from '../hooks/useAuthAccessToken'

type ProtectedRouteProps = {
  isEnabled?: boolean
  component: React.FC
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isEnabled = true,
  component: RouteComponent,
}): Maybe<JSX.Element> => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuthAccessToken()

  useEffect(() => {
    if (isAuthenticated && !isEnabled) {
      navigate('/')
    }
  }, [isEnabled, isAuthenticated, navigate])

  if (!isEnabled) {
    return null
  }

  return <RouteComponent />
}
