import type { GenericReportDetails } from '@fintastic/web/util/generic-report'
import { endpoints } from './endpoints'
import { axios } from '@fintastic/web/data-access/service-axios'

export function getReportDetailsAPI(
  versionId: string,
  category: string,
  dimensionFilters: DimensionFiltersAPIPayload,
) {
  return axios.post<GenericReportDetails>(
    endpoints.details(versionId, category),
    dimensionFilters,
  )
}

export type DimensionFiltersAPIPayload = {
  metric_filters: Record<string, string[]>
  time_filter: {
    dim_name: string
    dim_value_label: string
  }
}
