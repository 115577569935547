import React, { useMemo } from 'react'
import { MetricDisplaySettings } from '@fintastic/web/util/metrics-and-lists'
import { useFlagsContext } from '../../flags-context'
import { InlineSelectWithLabel } from '@fintastic/shared/ui/components'
import { createCurrencySignPositionOptions } from './options/currency-sign-position-options'

type Value = MetricDisplaySettings['currency_sign_position']

export type CurrencySignPositionFieldProps = {
  value: Value
  onChange: (newValue: Value) => void
  currencySymbol: string
}

export const CurrencySignPositionField: React.FC<
  CurrencySignPositionFieldProps
> = ({ value, onChange, currencySymbol }) => {
  const { readonly } = useFlagsContext()

  const options = useMemo(
    () => createCurrencySignPositionOptions(currencySymbol),
    [currencySymbol],
  )

  return (
    <InlineSelectWithLabel<Value>
      label="Currency format"
      value={value}
      onChange={onChange}
      disabled={readonly}
      options={options}
    />
  )
}
