import React from 'react'
import { StyledRoot } from './FormattingFieldWrapper.styled'

export type FormattingFieldWrapperProps = {
  children: React.ReactNode
}

export const FormattingFieldWrapper: React.FC<FormattingFieldWrapperProps> = ({
  children,
}) => <StyledRoot>{children}</StyledRoot>
