import React from 'react'
import { TextField } from '@mui/material'

export type NameFieldProps = {
  value: string
  readonly?: boolean
}

export const NameField: React.FC<NameFieldProps> = ({ value, readonly }) => (
  <TextField
    size="small"
    variant="outlined"
    fullWidth={true}
    value={value}
    disabled={readonly}
  />
)
