import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'

export function generateNewColumnName(columns: ListColumnWrapper[]): string {
  const existingNames = Object.fromEntries(
    columns.map((c) => [c.label(), true]),
  )

  let counter = columns.length + 1
  let newName = `Column_${counter}`
  while (existingNames[newName]) {
    counter += 1
    newName = `Column_${counter}`
  }

  return newName
}
