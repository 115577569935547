import React, { useMemo } from 'react'
import {
  CenteredCircularProgress,
  ErrorAlert,
} from '@fintastic/shared/ui/components'
import { MetricChartWidget, MetricChartWidgetProps } from './MetricChartWidget'
import {
  WidgetPeriodSelectionComponentProps,
  WidgetPeriodSelectionContainer,
} from '../containers/WidgetPeriodSelectionContainer'
import {
  useWidgetContextImplementation,
  WidgetContext,
  WidgetInvisibleContainer,
} from '@fintastic/shared/ui/widgets-framework'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

const MetricChartWidgetContainerRenderer: React.FC<
  WidgetPeriodSelectionComponentProps & MetricChartWidgetProps
> = (props) => {
  const { periodSelection, widget, ...restProps } = props
  const chartsEnabled = useIsFeatureEnabled('enable_charts')

  const content = useMemo(() => {
    if (!chartsEnabled) {
      return (
        <ErrorAlert
          title="Feature is disabled"
          message="Charts feature is disabled for this tenant. Please contact the administrator."
          severity="info"
          icon={<EmptyFolderErrorIcon />}
        />
      )
    }
    if (periodSelection.isLoading) {
      return <CenteredCircularProgress />
    } else if (periodSelection.isError) {
      console.error(periodSelection.error)
      return (
        <ErrorAlert
          title="Something is wrong with the period selection"
          message="Select different periods in the calendar or contact the administrator."
          severity="info"
          icon={<EmptyFolderErrorIcon />}
        />
      )
    } else {
      return (
        <MetricChartWidget
          periodSelection={periodSelection}
          widget={widget}
          {...restProps}
        />
      )
    }
  }, [periodSelection, restProps, widget])

  return (
    <WidgetInvisibleContainer
      data-testid={`board-widget-${widget.id}`}
      data-widget-type={widget.type}
    >
      {content}
    </WidgetInvisibleContainer>
  )
}

export const MetricChartWidgetContainer: React.FC<MetricChartWidgetProps> = (
  props,
) => {
  const widgetContext = useWidgetContextImplementation(
    props,
    !!props.boardParams.isDesignMode,
  )

  return (
    <WidgetContext.Provider value={widgetContext}>
      <WidgetPeriodSelectionContainer
        {...props}
        Component={
          MetricChartWidgetContainerRenderer as React.FC<WidgetPeriodSelectionComponentProps>
        }
      />
    </WidgetContext.Provider>
  )
}
