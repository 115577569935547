import {
  addObjectToUrlSearchParams,
  getObjectFromUrlSearchParams,
} from './serialize-to-search-params'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import type { DeeplinkKey, DeeplinkValue } from '../types'
import { isEqual, isObject, isUndefined, omitBy } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Maybe } from '@fintastic/shared/util/types'
import { DeeplinkCache } from '../cache/DeeplinkCache'
import { useSetUrlSearchParams } from './useSetUrlSearchParams'

export const useSyncDeeplinkValue = <T extends DeeplinkValue>({
  key,
  defaultValue,
}: DeepLinkValueParams<T>): [
  T,
  React.Dispatch<React.SetStateAction<T | undefined>>,
] => {
  const { search } = useLocation()

  const searchValueRef = useRef(search)
  searchValueRef.current = search

  const setUrlSearchParams = useSetUrlSearchParams()

  const oldValueRef = useRef<T>()

  const currentUrlValue = useMemo<T | undefined>(() => {
    if (!key) {
      return undefined
    }

    if (DeeplinkCache.cacheEntryExists(search, key)) {
      return DeeplinkCache.getCacheEntry<T>(search, key)
    }

    const searchParams = new URLSearchParams(search)
    const nextValue = getObjectFromUrlSearchParams<T>({
      key,
      searchParams,
    })

    if (isEqual(oldValueRef.current, nextValue)) {
      DeeplinkCache.setCacheEntry(search, key, oldValueRef.current)
      return oldValueRef.current
    }

    DeeplinkCache.setCacheEntry(search, key, nextValue)

    oldValueRef.current = nextValue
    return nextValue
  }, [key, search])

  const currentUrlValueRef = useRef<T | undefined>()
  currentUrlValueRef.current = currentUrlValue

  const defaultValueRef = useRef<T>(defaultValue)
  defaultValueRef.current = defaultValue

  const setCurrentStateValue = useCallback(
    (valueGetter?: T | ((prevValue?: T) => T | undefined)): void => {
      if (!key) {
        return undefined
      }

      const value =
        typeof valueGetter === 'function'
          ? valueGetter(currentUrlValueRef.current)
          : valueGetter

      const currentSearchParams = new URLSearchParams(document.location.search)
      const originalString = currentSearchParams.toString()

      const isDefaultValue = checkIsDefaultValue(defaultValueRef.current, value)
      const nextValue = isDefaultValue ? undefined : value

      if (isEqual(nextValue, currentUrlValueRef.current)) {
        return
      }

      addObjectToUrlSearchParams(currentSearchParams, key, nextValue)

      DeeplinkCache.setCacheEntry(searchValueRef.current, key, nextValue)

      if (originalString !== currentSearchParams.toString()) {
        setUrlSearchParams(currentSearchParams)
      }
    },
    [key, setUrlSearchParams],
  )

  const appliedValue =
    currentUrlValue === undefined ? defaultValue : currentUrlValue

  if ((process.env as any) === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (key?.includes('[') || key?.includes(']')) {
        throw new Error(
          '"[" and "]" symbols are not supported in a Deeplink key!',
        )
      }
    }, [key])
  }

  return [appliedValue, setCurrentStateValue]
}

export type DeepLinkValueParams<T extends DeeplinkValue> = {
  key?: Maybe<DeeplinkKey>
  defaultValue: T
}

const checkIsDefaultValue = (valueA: unknown, valueB: unknown): boolean => {
  if (isObject(valueA)) {
    return isEqual(
      omitBy(valueA || {}, isUndefined),
      omitBy(valueB || {}, isUndefined),
    )
  }

  return isEqual(valueA, valueB)
}
