import { useMemo, useState } from 'react'

export type UseModalParamsStateParams<P> = {
  initialParams: P
}

export function useModalParamsState<P>(params: UseModalParamsStateParams<P>) {
  const [modalParams, setModalParams] = useState<P>(params.initialParams)

  return useMemo(
    () => ({
      params: modalParams,
      setParams: setModalParams,
    }),
    [modalParams],
  )
}
