import { EditableCallbackParams } from 'ag-grid-community'
import { ListDimensionsPermissionsApi } from './makeApi'
import { DimensionId, DimensionValueId } from '@fintastic/web/util/dimensions'

export const makeEditableCallback =
  (
    api: ListDimensionsPermissionsApi,
    columnFieldKey: string,
    dimensionId: DimensionId,
  ) =>
  (params: EditableCallbackParams): boolean => {
    if (params.data === undefined) {
      return false
    }

    if (api.noRestrictions || !api.dimensionHasRestrictions?.(dimensionId)) {
      return true
    }

    const value = params.data[columnFieldKey] as DimensionValueId
    return api.dimensionValueAllowedToBeChosen(value)
  }

export const makeDimensionFilterCallback =
  (api: ListDimensionsPermissionsApi, dimensionId: DimensionId) =>
  (dimValueId: DimensionValueId): boolean => {
    if (api.noRestrictions || !api.dimensionHasRestrictions?.(dimensionId)) {
      return true
    }

    return api.dimensionValueAllowedToBeChosen(dimValueId)
  }
