import { Box, MenuItem, styled } from '@mui/material'
import Menu, { MenuProps } from '@mui/material/Menu'

export const StyledTopbarMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))`
  .MuiPaper-root {
    border-radius: 6px;
    margin-top: ${({ theme }) => theme.spacing(1)};
    min-width: 150px;
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-flow: column nowrap;
`

export const StyledMenuItemPrimaryText = styled(Box)`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 0;
`

export const StyledMenuItemSecondaryText = styled(Box)`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 4px 0 0 0;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.text.secondary};
`
