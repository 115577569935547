import * as React from 'react'
import { RouteObject } from '@fintastic/shared/util/memory-router'
import { MainSection, MainSectionTitle } from '../subsections/Main'
import { DataSection } from '../subsections/ChartData/DataSection'
import { MoreSettingsSection } from '../subsections/More/MoreSettingsSection'
import { LegendSection } from '../subsections/Legend/LegendSection'
import { XAxisSection } from '../subsections/XAxis/XAxisSection'
import { GridModeSection } from '../subsections/GridMode/GridModeSection'
import { YAxisSection } from '../subsections/YAxis/YAxisSection'
import {
  ChartDataSectionIcon,
  ChartGridModeSectionIcon,
  ChartLegendSectionIcon,
  ChartMoreSectionIcon,
  ChartXAxisSectionIcon,
  ChartYAxisSectionIcon,
} from '../subsections/shared'

export type ChartSettingsSectionDefinition = {
  path: string
  title: string
  titleComponent?: React.ReactNode
  icon?: React.ReactNode
  view: React.ReactNode | null
}

export const chartSettingsSections = [
  {
    path: '/initial',
    title: 'Customize chart',
    titleComponent: <MainSectionTitle />,
    view: <MainSection />,
  },
  {
    path: '/data',
    title: 'Chart data',
    view: <DataSection />,
    icon: <ChartDataSectionIcon />,
  },
  {
    path: '/yAxis',
    title: 'Vertical axis',
    view: <YAxisSection />,
    icon: <ChartYAxisSectionIcon />,
  },
  {
    path: '/xAxis',
    title: 'Horizontal axis',
    view: <XAxisSection />,
    icon: <ChartXAxisSectionIcon />,
  },
  {
    path: '/legend',
    title: 'Legend',
    view: <LegendSection />,
    icon: <ChartLegendSectionIcon />,
  },
  {
    path: '/grid',
    title: 'Grid display',
    view: <GridModeSection />,
    icon: <ChartGridModeSectionIcon />,
  },
  {
    path: '/moreSettings',
    title: 'More',
    view: <MoreSettingsSection />,
    icon: <ChartMoreSectionIcon />,
  },
] as const

export type ChartSettingsSectionPath =
  typeof chartSettingsSections[number]['path']

export const chartSettingsSectionRoutes: RouteObject[] =
  chartSettingsSections.map((sm) => ({
    path: sm.path,
    element: sm.view,
  }))
