import React, { useMemo } from 'react'
import { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import {
  ToolbarRowsActionIconButton,
  ToolbarRowsActionIconButtonProps,
} from '../ToolbarRowsActionIconButton'
import { DuplicateIcon } from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

const defaultIconProps: SvgIconProps = {
  fontSize: 'small',
}

export type DuplicateRowsButtonProps<T extends BaseGridRow> = Omit<
  ToolbarRowsActionIconButtonProps<T>,
  'children'
> & {
  iconProps?: SvgIconProps
}

export const DuplicateRowsButton = <T extends BaseGridRow>({
  title = 'Duplicate',
  iconProps = defaultIconProps,
  ...rest
}: DuplicateRowsButtonProps<T>): JSX.Element | null => {
  const mergedIconProps = useMemo(
    () => ({
      ...defaultIconProps,
      ...iconProps,
    }),
    [iconProps],
  )

  return (
    <ToolbarRowsActionIconButton
      data-testid="DuplicateRowsButton"
      title={title}
      {...rest}
    >
      <DuplicateIcon {...mergedIconProps} />
    </ToolbarRowsActionIconButton>
  )
}
