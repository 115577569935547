import React, { useMemo } from 'react'
import { ContextValue, context } from './context'
import { Modal, ModalProps } from '../Modal'
import { Box } from '@mui/material'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type ConfirmationPopupProps = Omit<ModalProps, 'children'> & {
  onConfirm?: () => void
  onDecline?: () => void
  children: React.ReactNode
  description?: React.ReactNode
  autoClose?: boolean
}

export const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  onDecline,
  onConfirm,
  onRequestClose,
  children,
  autoClose = true,
  ...modalProps
}) => {
  const contextValue: ContextValue = useMemo(
    () => ({
      onDecline: () => {
        onDecline?.()
        autoClose && onRequestClose?.()
      },
      onConfirm: () => {
        onConfirm?.()
        autoClose && onRequestClose?.()
      },
    }),
    [onConfirm, onDecline, onRequestClose, autoClose],
  )

  return (
    <context.Provider value={contextValue}>
      <FintasticThemeProvider applyLegacyTheme={false}>
        <Modal
          onRequestClose={onRequestClose}
          showCloseButton={Boolean(onRequestClose)}
          {...modalProps}
        >
          <Box sx={sxMixins.alignContentCenter()}>{children}</Box>
        </Modal>
      </FintasticThemeProvider>
    </context.Provider>
  )
}
