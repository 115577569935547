import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { RemoveColumnPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const removeColumn = (
  uncheckedState: State,
  { payload }: PayloadAction<RemoveColumnPayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  if (list.isCalculated()) {
    // @todo move to the list ADT
    throw new Error('not allowed in calculated lists')
  }

  list.removeColumn(payload.columnId)
}
