import React, { useCallback, useMemo } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import type { Maybe } from '@fintastic/shared/util/types'

export type AllowDenyToggleProps = {
  value: Maybe<boolean>
  onChange: (newValue: string) => void
}
export const AllowDenyToggle: React.FC<AllowDenyToggleProps> = (props) => {
  let value = ''
  if (typeof props.value === 'boolean') {
    value = props.value ? 'allow' : 'deny'
  } else {
    value = 'mixed'
  }

  const handleChange = useCallback((event: unknown, newValue: string) => {
    props.onChange(newValue)
  }, [])

  const style = useMemo(
    () => ({
      margin: '4px',
      ...(value === 'mixed' && {
        background:
          'repeating-linear-gradient(            45deg,            #bebebe,            #dedede 10px       )',
      }),
    }),

    [value],
  )

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      onChange={handleChange}
      exclusive
      size="small"
      sx={style}
    >
      <ToggleButton color="secondary" value="allow">
        Allow
      </ToggleButton>
      <ToggleButton value="deny">Deny</ToggleButton>
    </ToggleButtonGroup>
  )
}
