import { Filter, FilterModel } from '../types'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { useMemo, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { filterUtils } from '../utils'
import { DEFAULT_FILTERS_VALUE_MODEL } from './default-filter-model'

export const useFilterContextValueModels = (
  localStorageKey: string,
  actualFilters: Filter[],
) => {
  const periodSelection = usePeriodSelectorContext()

  const [appliedModel, setAppliedModel] = useLocalStorage<FilterModel>(
    localStorageKey,
    DEFAULT_FILTERS_VALUE_MODEL,
  )
  const [model, setModel] = useState<FilterModel>(() => appliedModel)

  return useMemo(
    () => ({
      model: filterUtils.cleanUpModel(model, {
        time_dimension_id: periodSelection.aggregationDimensionId,
        // Prefer actual filter if they loaded. Otherwise, optimistically use metadata filters
        filters: actualFilters.length ? actualFilters : model.metadata.filters,
      }),
      appliedModel: filterUtils.cleanUpModel(appliedModel, {
        time_dimension_id: periodSelection.aggregationDimensionId,
        // Prefer actual filter if they loaded. Otherwise, optimistically use metadata filters
        filters: actualFilters.length
          ? actualFilters
          : appliedModel.metadata.filters,
      }),
      setModel,
      setAppliedModel,
    }),
    [
      actualFilters,
      appliedModel,
      model,
      periodSelection.aggregationDimensionId,
      setAppliedModel,
    ],
  )
}
