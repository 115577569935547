import React from 'react'
import { Box, Button } from '@mui/material'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'

export type ButtonsProps = {
  onContinue: () => void
  onCancel: () => void
  continueEnabled: boolean
  isSubmitting: boolean
}

export const Buttons: React.FC<ButtonsProps> = ({
  onContinue,
  continueEnabled,
  onCancel,
  isSubmitting,
}) => (
  <Box
    sx={{
      ...sxMixins.alignContentCenter(),
    }}
  >
    <Button variant="outlined" onClick={onCancel}>
      Cancel
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={onContinue}
      disabled={!continueEnabled || isSubmitting}
      sx={{
        ml: 1,
      }}
    >
      {isSubmitting ? 'Locking the version...' : 'Continue'}
    </Button>
  </Box>
)
