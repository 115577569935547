import { StyledDrawer, StyledDrawerContent } from './BottomDrawer.styled'
import {
  layoutConfig,
  useLayoutStateIsBottomDrawerOpened,
} from '@fintastic/shared/ui/app-layout-framework'
import React, {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { ResizeHandler } from './Resize/ResizeHandler'
import { useLocalStorage } from 'usehooks-ts'
import { Maybe } from '@fintastic/shared/util/types'

export type BottomDrawerProps = {
  pageRef?: MutableRefObject<Maybe<HTMLDivElement>>
} & PropsWithChildren

const titleDefaultHeight = 48
const displayClassName = 'show'
const displayResizeClassName = 'should-display-resize'

export const BottomDrawer: React.FC<BottomDrawerProps> = ({
  pageRef,
  children,
}) => {
  const [isDrawerOpened] = useLayoutStateIsBottomDrawerOpened()
  const drawerRef = useRef<HTMLDivElement>(null)

  // @todo: maybe diff height per payload/payload type?
  const [height, setHeight] = useLocalStorage<Maybe<number>>(
    'bottom_drawer_height',
    layoutConfig.bottomdrawer.height,
  )

  const applySize = useCallback(() => {
    const appLayoutPageContent = pageRef?.current

    if (!appLayoutPageContent) {
      return
    }

    const h = height || layoutConfig.bottomdrawer.height

    appLayoutPageContent.style.paddingBottom = isDrawerOpened ? h + 'px' : '0'
    if (drawerRef.current) {
      drawerRef.current.style.height = h + 'px'
    }
  }, [height, isDrawerOpened])

  useEffect(() => {
    applySize()
  }, [applySize, isDrawerOpened])

  const processMouseHover = useCallback((e: MouseEvent) => {
    const rect = drawerRef.current?.getBoundingClientRect()
    const y = e.clientY - (rect?.top ?? 0) // y position within the element.

    if (y > 0 && y < titleDefaultHeight) {
      drawerRef.current?.classList.add(displayResizeClassName)
    } else {
      drawerRef.current?.classList.remove(displayResizeClassName)
    }
  }, [])

  const processMouseOut = useCallback(() => {
    drawerRef.current?.removeEventListener('mouseover', processMouseHover)
    drawerRef.current?.classList.remove(displayResizeClassName)
  }, [processMouseHover])

  const processMouseIn = useCallback(
    (e: MouseEvent) => {
      drawerRef.current?.addEventListener('mouseover', processMouseHover)
      processMouseHover(e)
    },
    [processMouseHover],
  )

  useEffect(() => {
    if (isDrawerOpened) {
      drawerRef.current?.classList.add(displayClassName)

      drawerRef.current?.addEventListener('mouseleave', processMouseOut)
      drawerRef.current?.addEventListener('mouseenter', processMouseIn)
    } else {
      drawerRef.current?.classList.remove(displayClassName)

      drawerRef.current?.classList.remove(displayResizeClassName)
      drawerRef.current?.removeEventListener('mouseleave', processMouseOut)
      drawerRef.current?.removeEventListener('mouseleave', processMouseIn)
    }
  }, [isDrawerOpened, processMouseIn, processMouseOut])

  const handleResizeEnd = useCallback(
    (height: number) => {
      setHeight(height)
      applySize()
    },
    [applySize, setHeight],
  )

  return (
    <StyledDrawer
      open={isDrawerOpened}
      ref={drawerRef}
      data-testid="layout-drawer"
    >
      <ResizeHandler onResizeEnd={handleResizeEnd} />
      <StyledDrawerContent>{children}</StyledDrawerContent>
    </StyledDrawer>
  )
}
