import React from 'react'
import { usePeriodSelectionComponent } from '@fintastic/web/feature/period-selector'
import {
  PeriodSelection,
  PeriodSelectorContext,
} from '@fintastic/web/util/period-selector'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { WidgetRendererProps } from '@fintastic/shared/ui/widgets-framework'
import {
  BaseTimeDimensionStaticWidgetProvider,
  useBaseTimeDimensionStaticWidgetAdapter,
} from '@fintastic/web/data-access/base-time-dimension'
import { WidgetPeriodSelectionComponentProps } from './WidgetPeriodSelectionContainer'
import { Version } from '@fintastic/web/util/versions'
import { CircularProgress } from '@mui/material'
import { StyledLoadingBox } from './WidgetPeriodSelection.styled'

export const WidgetPeriodSelectionContainerDesignMode: React.FC<
  {
    Component: React.FC<WidgetPeriodSelectionComponentProps>
  } & WidgetRendererProps & {
      versionDefaultPeriodSelection: PeriodSelection
      selectedVersions: Version[]
    }
> = ({
  Component,
  versionDefaultPeriodSelection,
  selectedVersions,
  ...props
}) => {
  const baseTimeDimensionContextValue = useBaseTimeDimensionStaticWidgetAdapter(
    versionDefaultPeriodSelection.dimensionId,
  )

  const periodSelection = usePeriodSelectionComponent({
    selectedVersions,
    buttonDesign: 'none',
    baseTimeDimensionId: versionDefaultPeriodSelection.aggregationDimensionId,
    defaultSelection: versionDefaultPeriodSelection,
  })

  // If loading - no context provided
  if (periodSelection.isLoading) {
    return (
      <StyledLoadingBox>
        <CircularProgress />
      </StyledLoadingBox>
    )
  }

  if (periodSelection.isError) {
    return (
      <ErrorAlert
        title="Loading error"
        message="Version entities or calendar could not be loaded."
      />
    )
  }

  // Otherwise add context
  return (
    <BaseTimeDimensionStaticWidgetProvider
      value={baseTimeDimensionContextValue}
    >
      <PeriodSelectorContext.Provider value={periodSelection.contextValue}>
        <Component {...props} periodSelection={periodSelection} />
      </PeriodSelectorContext.Provider>
    </BaseTimeDimensionStaticWidgetProvider>
  )
}
