import React from 'react'
import { createSvgIcon } from '@mui/material'

export const WidgetTextIcon = createSvgIcon(
  <>
    <path
      d="M9.10319 7.54654C8.85104 7.59209 8.63754 7.80078 8.58116 8.05678C8.56097 8.14849 8.56713 8.31386 8.59392 8.39928C8.66109 8.61337 8.84241 8.78778 9.06247 8.84996C9.12747 8.86832 9.13062 8.86838 10.2309 8.87103L11.3342 8.87369V12.7277C11.3342 13.0153 11.3342 13.2819 11.3342 13.529C11.334 16.5747 11.334 16.6646 11.3695 16.7369C11.3706 16.7391 11.3717 16.7412 11.3729 16.7435C11.3759 16.7495 11.3793 16.756 11.3828 16.7645C11.453 16.9321 11.6174 17.0838 11.7928 17.1427C11.8537 17.1632 11.8708 17.165 12 17.165C12.1292 17.165 12.1463 17.1632 12.2072 17.1427C12.3825 17.0838 12.547 16.9321 12.6172 16.7645C12.6207 16.756 12.6241 16.7495 12.6271 16.7435C12.6283 16.7412 12.6294 16.7391 12.6305 16.7369C12.666 16.6646 12.666 16.5747 12.6658 13.529C12.6658 13.2819 12.6658 13.0153 12.6658 12.7277V8.87369L14.8723 8.86838L14.9388 8.84963C15.1758 8.78279 15.3707 8.58034 15.419 8.35081C15.4345 8.27715 15.4362 8.14415 15.4227 8.06876C15.3757 7.80743 15.1382 7.58028 14.8715 7.54174C14.8332 7.5362 13.8249 7.53343 11.9915 7.53382C9.59259 7.53433 9.16028 7.53623 9.10319 7.54654Z"
    />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M4.18534 4.00826C3.91005 4.03641 3.62938 4.11825 3.36589 4.24719C2.68596 4.57992 2.18803 5.22715 2.04336 5.9663C2.04206 5.97295 2.0408 5.97936 2.03957 5.98558C2.03476 6.00998 2.03054 6.0314 2.02684 6.05292C2.00023 6.20731 2.0001 6.36627 2.00013 7.66069L2 16.6065L2.00013 16.6732C2.0001 17.9851 2.0001 18.1448 2.02684 18.3C2.03054 18.3215 2.03477 18.3429 2.03957 18.3673C2.0408 18.3735 2.04206 18.3799 2.04336 18.3866C2.16445 19.0052 2.53575 19.567 3.06286 19.929C3.39893 20.1599 3.79554 20.3054 4.19691 20.3451C4.32009 20.3573 19.6799 20.3573 19.8031 20.3451C20.6463 20.2617 21.3962 19.7327 21.7644 18.9617C21.8833 18.7127 21.9507 18.4807 21.9887 18.19C21.9974 18.1238 21.9998 17.7913 21.9999 16.6065L22 7.66069C21.9998 6.54542 21.9971 6.22744 21.9887 6.16286C21.9507 5.87214 21.8833 5.64015 21.7644 5.39115C21.3961 4.62003 20.6464 4.09122 19.8031 4.00775C19.6954 3.99709 4.28966 3.99759 4.18534 4.00826ZM20.6723 7.66069C20.6715 6.95105 20.6682 6.42793 20.6639 6.37345C20.6475 6.16251 20.5917 5.99848 20.4776 5.82569C20.3989 5.70662 20.2548 5.56788 20.133 5.49401C20.0269 5.42961 19.8667 5.36958 19.7378 5.34587C19.6495 5.32961 4.35053 5.32961 4.26216 5.34587C4.13329 5.36958 3.97312 5.42961 3.86695 5.49401C3.74517 5.56788 3.60104 5.70662 3.52239 5.82569C3.44218 5.94711 3.4002 6.04208 3.36016 6.19274L3.34087 6.2653L3.33571 7.82311C3.33531 7.9458 3.33496 8.05919 3.33463 8.16404C3.33412 8.33207 3.33366 8.47815 3.33316 8.60537V15.7475C3.33366 15.8747 3.33412 16.0208 3.33463 16.1888C3.33496 16.2937 3.33531 16.4071 3.33571 16.5298L3.34087 18.0876L3.36016 18.1601C3.4002 18.3108 3.44218 18.4058 3.52239 18.5272C3.68354 18.7711 3.96211 18.9518 4.26216 19.007C4.35053 19.0233 19.6495 19.0233 19.7378 19.007C19.9754 18.9633 20.1952 18.845 20.3612 18.6714C20.5418 18.4825 20.6428 18.2517 20.6639 17.9794C20.6684 17.9228 20.6717 17.3594 20.6724 16.6065H20.6668V7.66069H20.6723Z"
    />
  </>
  ,
  'WidgetText',
)
