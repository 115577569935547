import { axios } from '@fintastic/web/data-access/service-axios'
import { CompactReport } from '@fintastic/web/util/metrics-and-lists'

const endpoints = {
  reportList: () =>
    '/planning/api/v1/reports/',
} as const


export function getReports() {
  return axios.get<{
    reports: CompactReport[]
  }>(endpoints.reportList(), {})
}
