import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnTypePayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'
import { EditableMetric } from '../../../types'

// @todo add tests for formula related staff
export const changeColumnType = (
  uncheckedState: State,
  { payload: { columnId, ...params } }: PayloadAction<ChangeColumnTypePayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)
  if (list.isCalculated()) {
    // @todo move it to list ADT
    throw new Error('not allowed in calculated lists')
  }

  const newColumn = list.replaceColumn(columnId, params)
  if (newColumn.isCalculated()) {
    ;(newColumn.unwrap() as EditableMetric).formulaInvalid = true
  }
}
