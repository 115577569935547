import { useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { iamQueryKeys } from '../../utils/query-keys'
import { UserData } from '../../types'
import { updateUser } from '../../api/users'

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient()

  const updateUserMutation = useMutation<AxiosResponse<void>, AxiosError, UserData>(
    iamQueryKeys.mutateUserList(),
    updateUser,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          toast(
            'You are not authorized to update user role, please contact your Admin',
          )
        } else {
          toast('Something went wrong')
        }
      },
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: iamQueryKeys.userList(),
        }),
    },
  )

  return updateUserMutation
}
