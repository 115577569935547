import {
  CommentEventIn,
  CommentEventOut,
  CommentThreadIn,
  CommentThreadOut,
  CommentsThreadsBulkItem,
} from '../types'
import { useMutation, useQueryClient } from 'react-query'
import {
  threadsBulkByPageQueryKey,
  threadsByPageQueryKey,
} from '../utils/query-key'
import { attachThreadLabel, postThreadIn } from '../api/api'
import { AxiosError, AxiosResponse } from 'axios'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { usePostEventWithMentions } from '../utils/usePostEventWithMentions'
import { newBulkThread } from './utils'

export const usePostNewThread = (pageKey: string) => {
  const queryClient = useQueryClient()
  const user = useAuthUserInfo()
  const postEventWithMentions = usePostEventWithMentions()

  const postNewThreadMutation = useMutation<
    {
      thread: AxiosResponse<CommentThreadOut>
      event: AxiosResponse<CommentEventOut>
    },
    AxiosError,
    {
      threadIn: CommentThreadIn
      eventIn: CommentEventIn
      label_ids: number[]
      onThreadIdReceived: (id: number) => void
    },
    {
      previousThreadsBulk: CommentsThreadsBulkItem[]
    }
  >(
    threadsByPageQueryKey(pageKey),
    async ({ threadIn, eventIn, label_ids }) => {
      const thread = await postThreadIn(threadIn)

      if (!thread?.data?.id) {
        throw new Error('Could not create thread')
      }

      const event = await postEventWithMentions(thread?.data.id, {
        type: eventIn.type,
        body: eventIn.body,
      })

      // Attach labels
      await Promise.all(
        label_ids.map((label_id) =>
          attachThreadLabel(thread.data.id, label_id),
        ),
      )

      return {
        thread,
        event,
      }
    },
    {
      onMutate: ({ eventIn }) => {
        if (eventIn.type !== 'comment') {
          throw new Error(
            'Cannot create a thread with non comment type of event',
          )
        }

        const previousThreadsBulk =
          queryClient.getQueryData<CommentsThreadsBulkItem[]>(
            threadsBulkByPageQueryKey(pageKey),
          ) || []

        return { previousThreadsBulk }
      },
      onSuccess: (
        { thread, event },
        { eventIn, threadIn, label_ids, onThreadIdReceived },
      ) => {
        queryClient.setQueryData<CommentsThreadsBulkItem[]>(
          threadsBulkByPageQueryKey(pageKey),
          (prev) => [
            ...(prev || []),
            newBulkThread(
              { thread, event },
              eventIn.body ?? '',
              user?.email ?? '',
              {
                ...threadIn,
                label_ids,
              },
            ),
          ],
        )

        onThreadIdReceived(thread.data.id)
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update to threads
        queryClient.setQueryData(
          threadsBulkByPageQueryKey(pageKey),
          ctx?.previousThreadsBulk || [],
        )

        console.error(err)
        toast.error(
          `Failed to post new thread. Error: ${err?.code || 'unknown'}`,
        )
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: threadsBulkByPageQueryKey(pageKey),
        })
      },
    },
  )

  return postNewThreadMutation
}
