import { createContext, useContext } from 'react'
import { MetricDataFillStrategy } from '@fintastic/web/util/metric-data-editing'

export type AggregatedTimeCellEditorContextValue = {
  action: MetricDataFillStrategy
  onChangeAction: (newAction: MetricDataFillStrategy) => void
}

const stub = () => {
  throw new Error('cell editor context is not initialised')
}

export const AggregatedTimeCellEditorContext =
  createContext<AggregatedTimeCellEditorContextValue>({
    action: 'duplicate',
    onChangeAction: stub,
  })

export const useAggregatedTimeCellEditorContext = () =>
  useContext(AggregatedTimeCellEditorContext)
