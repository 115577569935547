import React, { useMemo, useRef, useState } from 'react'
import { SmartSelectOption } from '../types'
import { matchSorter } from 'match-sorter'
import { SmartSelectInputButton } from '../common/SmartSelectInputButton'
import { Maybe } from '@fintastic/shared/util/types'
import { Divider, Paper, Popover } from '@mui/material'
import useResizeObserver from 'use-resize-observer'
import { SearchInput } from '../common/SearchInput'
import { OptionsList } from './OptionsList'
import { baseSorter } from '../common/match-sorter-utils'

export type SmartMultiselectProps<T> = {
  value: T[]
  onSelectOptions: (v: T[]) => void
  onUnselectOptions: (v: T[]) => void
  options: SmartSelectOption<T>[]
  placeholder: string | React.ReactNode
  label?: string
  disabled?: boolean
  autoWidth?: boolean
}

export const SmartMultiselect = <T,>({
  value,
  onSelectOptions,
  onUnselectOptions,
  options,
  placeholder,
  label,
  disabled,
  autoWidth,
}: SmartMultiselectProps<T>): JSX.Element => {
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const optionsMap = useMemo<
    Record<
      string,
      {
        option: SmartSelectOption<T>
        index: number
      }
    >
  >(
    () =>
      Object.fromEntries(
        options.map((option, index) => [
          option.value,
          {
            option,
            index,
          },
        ]),
      ),
    [options],
  )

  const sortedValues = useMemo(
    () =>
      [...value].sort(
        (a, b) => optionsMap[`${a}`].index - optionsMap[`${b}`].index,
      ),
    [optionsMap, value],
  )

  const selectedOptions = useMemo(
    () => sortedValues.map((v) => optionsMap[`${v}`].option),
    [optionsMap, sortedValues],
  )

  const inputButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const { width: inputButtonWidth } = useResizeObserver({
    ref: inputButtonRef,
    box: 'border-box',
  })

  const filteredOptions = useMemo(
    () => [
      ...(search === ''
        ? options
        : matchSorter(options, search, {
            keys: ['label'],
            baseSort: baseSorter,
          })),
    ],
    [options, search],
  )

  let sx: Record<string, number> = {
    width: inputButtonWidth || 50,
  }

  if (autoWidth) {
    sx = {
      minWidth: inputButtonWidth || 50,
    }
  }

  return (
    <>
      <SmartSelectInputButton<T>
        onClick={() => setIsOpen((v) => !v)}
        isOpened={isOpen}
        values={selectedOptions}
        ref={inputButtonRef}
        placeholder={placeholder}
        label={label}
        disabled={disabled}
      />
      <Popover
        open={isOpen}
        anchorEl={inputButtonRef.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper sx={sx}>
          <SearchInput value={search} onChange={setSearch} />
          <Divider />
          <OptionsList<T>
            options={filteredOptions}
            selected={sortedValues}
            onSelectOptions={onSelectOptions}
            onUnselectOptions={onUnselectOptions}
          />
        </Paper>
      </Popover>
    </>
  )
}
