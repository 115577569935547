import React, { useCallback, useMemo } from 'react'
import {
  ChartLegendFormat,
  chartLegendFormatKeys,
  chartLegendFormats,
  defaultChartLegendFormat,
  isChartLegendFormat,
} from '@fintastic/web/feature/charts'
import { OnChangeChartSettings } from '../../../../internal-types'
import { GroupButtonField } from '@fintastic/shared/ui/form-framework'
import { Maybe } from '@fintastic/shared/util/types'

export type ChartLegendFormatSwitchProps = {
  legendFormat: ChartLegendFormat
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartLegendFormatSwitch: React.FC<
  ChartLegendFormatSwitchProps
> = ({ legendFormat, onChangeChartSettings, disabled }) => {
  const handleModeChange = useCallback(
    (value: Maybe<ChartLegendFormat>) => {
      if (value) {
        onChangeChartSettings({
          legendFormat: isChartLegendFormat(value)
            ? value
            : defaultChartLegendFormat,
        })
      }
    },
    [onChangeChartSettings],
  )

  const options = useMemo(
    () =>
      chartLegendFormatKeys.map((x) => ({
        value: x,
        title: chartLegendFormats[x],
      })),
    [],
  )

  return (
    <GroupButtonField<ChartLegendFormat>
      options={options}
      value={legendFormat || defaultChartLegendFormat}
      title={'Legend format'}
      disabled={disabled}
      onChange={handleModeChange}
    />
  )
}
