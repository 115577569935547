import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export const PanelBottomBar: React.FC<PropsWithChildren> = ({ children }) => {
  if (!children) {
    return null
  }

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <Box
        sx={(theme) => ({
          'borderTop': `1px solid ${theme.palette.divider}`,
          'display': 'flex',
          'alignItems': 'center',
          'padding': '8px',
          'width': '100%',
          'height': '40px',
          'justifyContent': 'flex-end',
          '.MuiToolbar-root': {
            padding: 0,
            minHeight: 'unset !important',
          },
          '&:empty': {
            display: 'none',
          },
        })}
      >
        {children}
      </Box>
    </FintasticThemeProvider>
  )
}
