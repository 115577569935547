import * as go from 'gojs'
import { useCallback, useMemo, useState } from 'react'

const initialConfig: DiagramConfig = {
  layout: {
    direction: 0,
    layerSpacing: 50,
    columnSpacing: 40,
    layeringOption: go.LayeredDigraphLayering.OptimalLinkLength,
    alignOption: go.LayeredDigraphAlign.All,
    aggressiveOption: go.LayeredDigraphAggressive.Less,
  },
  links: {
    routing: go.Routing.Orthogonal,
    corner: 8,
    curve: go.Curve.None,
    curviness: Number.NaN,
    fromEndSegmentLength: Number.NaN,
    toEndSegmentLength: Number.NaN,
  },
}

export type DiagramConfigApi = ReturnType<typeof useDiagramConfig>

export const useDiagramConfig = () => {
  const [config, replaceConfig] = useState(initialConfig)

  const reset = useCallback(() => replaceConfig(initialConfig), [])

  return useMemo(
    () =>
      ({
        config,
        replaceConfig,
        reset,
      } as const),
    [config, reset],
  )
}

export type DiagramConfig = {
  layout: {
    direction: DiagramLayoutDirection
    layerSpacing: PositiveNumber
    columnSpacing: PositiveNumber
    layeringOption: go.LayeredDigraphLayering
    alignOption: go.LayeredDigraphAlign
    aggressiveOption: go.LayeredDigraphAggressive
  }
  links: {
    routing: go.Routing
    corner: PositiveNumber
    curve: go.Curve
    curviness: number
    fromEndSegmentLength: number
    toEndSegmentLength: number
  }
}

export const DIAGRAM_CONFIG_ENUMS = {
  layout: {
    direction: {
      leftToRight: 0,
      topToBottom: 90,
      rightToLeft: 180,
      bottomToTop: 270,
    },
  },
} as const

type PositiveNumber = number

export type DiagramLayoutDirection =
  typeof DIAGRAM_CONFIG_ENUMS.layout.direction[keyof typeof DIAGRAM_CONFIG_ENUMS.layout.direction]
