import { CommentsThreadsBulkItem } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import {
  threadsBulkByPageQueryKey,
  threadsByPageQueryKey,
} from '../utils/query-key'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { attachThreadLabel, detachThreadLabel } from '../api/api'

export const useToggleThreadLabelMutation = (pageKey: string) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse<string>,
    AxiosError,
    {
      operation: 'attach' | 'detach'
      labelId: number
      threadId: number
    },
    {
      previousThreadsBulk: CommentsThreadsBulkItem[]
    }
  >(
    threadsByPageQueryKey(pageKey),
    ({ operation, labelId, threadId }) =>
      operation === 'attach'
        ? attachThreadLabel(threadId, labelId)
        : detachThreadLabel(threadId, labelId),
    {
      onMutate: async ({ labelId, operation, threadId }) => {
        await queryClient.cancelQueries(threadsByPageQueryKey(pageKey))

        const previousThreadsBulk =
          queryClient.getQueryData<CommentsThreadsBulkItem[]>(
            threadsBulkByPageQueryKey(pageKey),
          ) || []

        queryClient.setQueryData<CommentsThreadsBulkItem[]>(
          threadsBulkByPageQueryKey(pageKey),
          (prev) =>
            (prev || []).map<CommentsThreadsBulkItem>((thread) => {
              if (thread.id !== threadId) {
                return thread
              }

              return {
                ...thread,
                label_ids:
                  operation === 'attach'
                    ? [...thread.label_ids, labelId]
                    : thread.label_ids.filter((lId) => lId !== labelId),
              }
            }),
        )

        return { previousThreadsBulk }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData(
          threadsBulkByPageQueryKey(pageKey),
          ctx?.previousThreadsBulk || [],
        )

        console.error(err)
        toast.error(
          `Failed to save thread labels. Error: ${err?.code || 'unknown'}`,
        )
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: threadsBulkByPageQueryKey(pageKey),
        })
      },
    },
  )

  return result
}
