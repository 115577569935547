import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

type BoardSaveCurtainProps = {
  show: boolean
}

export const BoardSaveCurtain: React.FC<BoardSaveCurtainProps> = ({ show }) => (
  <Backdrop
    sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={show}
  >
    <CircularProgress />
  </Backdrop>
)
