import { axios } from '@fintastic/web/data-access/service-axios'
import { AppServerConfig } from '../types'

export function getAppServerConfig() {
  return axios.get<GetAppServerConfigResponse>('/planning/api/v1/tenant_config/')
}

export type GetAppServerConfigResponse = {
  groups: unknown[]
  result: AppServerConfig[]
}
