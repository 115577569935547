import { BlanksExternalFilterApi } from '../useBlanksExternalFilter'
import { createContext, useContext } from 'react'

export type BlanksFilterPanelContextValue = Pick<
  BlanksExternalFilterApi,
  'toggleBlanks' | 'blanksVisible'
>

const stub = () => {
  throw new Error('BlanksFilterPanelContext is not initialised')
}

export const BlanksFilterPanelContext =
  createContext<BlanksFilterPanelContextValue>({
    blanksVisible: false,
    toggleBlanks: stub,
  })

export const useBlanksFilterPanelContext = () =>
  useContext(BlanksFilterPanelContext)
