import CurrencyList from './currencies-en-us.json'
import { Currency } from './types'

export const currencies: Currency[] = Object.values(CurrencyList)
  .map(({ name, code, symbol }) => ({
    label: name,
    code,
    symbol,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))
