import {
  styled,
  FormControlLabel,
  formControlLabelClasses,
} from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,

  [`.${formControlLabelClasses.label}`]: {
    ...styledMixins.alignContentCenter(),
    marginLeft: theme.spacing(1),
  },
}))
