import { useCallback, useMemo } from 'react'
import { VersionMetric } from '../../../../utils/metrics'
import {
  VersionUserLockParsed,
  showVersionIsLockedForDataEditingToast,
} from '@fintastic/web/util/versions'
import { MetricGridRow } from '../../types'
import { CellClickedEvent } from 'ag-grid-community'

export const useHandleLockedCellClick = (
  metrics: VersionMetric[],
  versionUserLocks: VersionUserLockParsed[],
) => {
  const anyMetricIsInput = useMemo(
    () => metrics.some((m) => m.metricSource === 'input'),
    [metrics],
  )

  return useCallback(
    (e: CellClickedEvent<MetricGridRow, any>) => {
      const isCellEditable = e.column.isCellEditable(e.node)
      if (isCellEditable) {
        return
      }

      if (!anyMetricIsInput) {
        return
      }

      const lock = versionUserLocks.find((lock) => lock.editIsBlocked)

      if (!lock) {
        return
      }

      showVersionIsLockedForDataEditingToast(lock)
    },
    [anyMetricIsInput, versionUserLocks],
  )
}
