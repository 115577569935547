import { Box, styled } from '@mui/material'

export const StyledMetricChartRoot = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-flow: column nowrap;
  position: relative;
`
