import React from 'react'

export const ContextMenuSubmenuIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 3L5 13L11 8L5 3Z" fill="#263646" />
  </svg>
)
