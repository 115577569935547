import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { EditableList } from '../../../types'
import { StartListEditingPayload } from '../../types'
import { ListWrapper } from '@fintastic/web/data-access/metrics-and-lists'

// @todo add tests
export const startListEditing = (
  state: State,
  { payload }: PayloadAction<StartListEditingPayload>,
) => {
  state.type = 'list'
  state.metric = null
  state.flow = 'editing'
  state.list = structuredClone(payload.list as EditableList)
  state.list.metrics = state.list.metrics.map((m) => ({
    ...m,
    clientOnlyId: m.id,
  }))
  const wrappedList = new ListWrapper(state.list)
  state.list._initiallyHadBaseTimeDimension =
    wrappedList.baseTimeDimension() !== null
}
