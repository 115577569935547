import { styled, Box } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledHandleRoot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,

  ...styledMixins.alignContentCenter(),
}))

export const StyledHandleDraggler = styled(Box)(({ theme }) => ({
  'position': 'absolute',
  'top': '50%',
  'left': '50%',
  'transform': 'translate(-50%, -50%)',
  'zIndex': 1,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'width': 53,
  'height': 17,
  'borderRadius': 6,
  'fontSize': 0,
  'background': theme.palette.common.white,
  'border': '1px solid #DDDDDD',
  'transition': `background ${theme.transitions.duration.short}ms`,
  'cursor': 'ns-resize',

  '&:hover': {
    background: theme.palette.grey[100],
  },
}))

export const StyledHandleDragglerIcon = styled(Box)(({ theme }) => ({
  '& svg': {
    width: 24,
    height: 'auto',
  },
}))
