import {
  BoardFoldersTree,
  BoardsFoldersTreeNode,
  BoardsFoldersTreeNodeFolder,
} from '../../types/folders-tree'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'

function isFolder(
  node: BoardsFoldersTreeNode,
): node is BoardsFoldersTreeNodeFolder {
  return node.type === 'folder'
}

function filterTree(
  tree: BoardFoldersTree,
  cb: (node: BoardsFoldersTreeNode) => boolean,
): BoardFoldersTree {
  const getNodes = (
    result: BoardsFoldersTreeNode[],
    current: BoardsFoldersTreeNode,
  ) => {
    if (cb(current)) {
      result.push(current)
      return result
    }

    if (isFolder(current)) {
      const children = current.children.reduce(getNodes, [])
      if (children.length) {
        result.push({ ...current, children })
      }
    }
    return result
  }

  return { rootNodes: tree.rootNodes.reduce(getNodes, []) }
}

export function filterTreeByName(
  tree: BoardFoldersTree,
  text: string,
): BoardFoldersTree {
  const _text = (text || '').substring(0, 32).trim().toLocaleLowerCase()

  return filterTree(tree, (node: BoardsFoldersTreeNode) =>
    (node.data?.name || '').toLocaleLowerCase().includes(_text),
  )
}

export function findRootFolderIdByBoardId(
  tree: BoardFoldersTree,
  boardId: string | undefined,
): Maybe<string> {
  if (!boardId) {
    return null
  }

  const folder = tree.rootNodes.find((node) => {
    if (!isFolder(node)) {
      return false
    }
    return node.children.findIndex((leaf) => leaf.data?.id === boardId) !== -1
  })

  return toMaybe(folder?.data?.id)
}

const countInArray = (
  inputArr: BoardsFoldersTreeNode[],
  test: (value: BoardsFoldersTreeNode) => boolean,
) => {
  let count = 0

  const iterate = (
    arr: BoardsFoldersTreeNode[],
    test: (value: BoardsFoldersTreeNode) => boolean,
  ) => {
    for (const a of arr) {
      if (test(a)) {
        count += 1
      }

      if (isFolder(a) && Array.isArray(a.children)) {
        iterate(a.children, test)
      }
    }
  }
  iterate(inputArr, test)

  return count
}

export function treeBoardCount(tree: BoardFoldersTree) {
  return countInArray(
    tree.rootNodes,
    (node: BoardsFoldersTreeNode) => !isFolder(node),
  )
}
