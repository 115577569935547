import React, { useCallback, useMemo } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export type RestrictedToggleValue =
  | 'regular_users'
  | 'all_users'
  | 'nobody'
  | 'mixed'

export type RestrictedToggleProps = {
  restricted_for: RestrictedToggleValue
  onChange: (newValue: Omit<RestrictedToggleValue, 'mixed'>) => void
}

export const RestrictedToggle: React.FC<RestrictedToggleProps> = ({
  onChange,
  restricted_for,
}) => {
  const handleChange = useCallback(
    (event: unknown, newValue: RestrictedToggleValue) => {
      onChange(newValue)
    },
    [onChange],
  )

  const style = useMemo(
    () => ({
      margin: '4px',
      ...(restricted_for === 'mixed' && {
        background: 'repeating-linear-gradient(45deg, #bebebe, #dedede 10px)',
      }),
    }),
    [restricted_for],
  )

  return (
    <ToggleButtonGroup
      color="primary"
      value={restricted_for}
      onChange={handleChange}
      exclusive
      size="small"
      sx={style}
    >
      <ToggleButton color="secondary" value="regular_users">
        Restrict for standart
      </ToggleButton>
      <ToggleButton color="secondary" value="all_users">
        Restrict for all
      </ToggleButton>
      <ToggleButton value="nobody">Available</ToggleButton>
    </ToggleButtonGroup>
  )
}
