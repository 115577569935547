import {
  ChartDecimalPlacesSelect,
  ChartNumberFormatSelect,
  defaultChartDecimalPlaces,
  defaultChartNumberFormat,
} from '@fintastic/web/feature/charts'
import React from 'react'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { ChartSettingsSection } from '../shared/ChartSettingsSection'

export const YAxisSection: React.FC = () => {
  const { loading } = useChartSettingsContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartSettingsSection>
      <ChartNumberFormatSelect
        disabled={loading}
        numberFormat={localSettings.numberFormat || defaultChartNumberFormat}
        onChangeChartSettings={handleSettingsChange}
      />
      <ChartDecimalPlacesSelect
        disabled={loading}
        decimalPlaces={localSettings.decimalPlaces || defaultChartDecimalPlaces}
        onChangeChartSettings={handleSettingsChange}
      />
    </ChartSettingsSection>
  )
}
