import { useModalState, UseModalStateParams } from './useModalState'
import {
  useModalParamsState,
  UseModalParamsStateParams,
} from './useModalParamsState'
import { useCallback, useMemo } from 'react'

export type UseModalWithParamsState<P> = UseModalStateParams &
  UseModalParamsStateParams<P>

export type ModalWithParamsState<P> = {
  isOpen: boolean
  open: () => void
  openWithParams: (params: P) => void
  close: () => void
  closeWithParams: (params: P) => void
  params: P
}

export function useModalWithParamsState<P>(
  params: UseModalWithParamsState<P>,
): ModalWithParamsState<P> {
  const { isOpen, open, close } = useModalState(params)
  const { params: modalParams, setParams: setModalParams } =
    useModalParamsState(params)

  const handleOpenWithParams = useCallback(
    (params: P) => {
      setModalParams(params)
      open()
    },
    [open, setModalParams],
  )

  const handleCloseWithParams = useCallback(
    (params: P) => {
      setModalParams(params)
      close()
    },
    [close, setModalParams],
  )

  return useMemo(
    () => ({
      isOpen,
      open,
      openWithParams: handleOpenWithParams,
      close,
      closeWithParams: handleCloseWithParams,
      params: modalParams,
    }),
    [
      close,
      handleCloseWithParams,
      handleOpenWithParams,
      isOpen,
      modalParams,
      open,
    ],
  )
}
