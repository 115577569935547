import { create, windowScheduler } from '@yornaath/batshit'
import { getListColumns } from '../../api/lists-api'
import { flatten, uniqBy } from 'lodash'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { MetricV2CacheListColumnParams } from '../types'

type MetricWithQueryKey = Metric & {
  batshitQueryKey: string
}

/**
 * Batched loader for list columns
 * It stacks column fetch per 50ms window and requests multiple columns for the same version
 * within single network request
 */
export const listColumnsBatshit = create<
  MetricWithQueryKey[],
  MetricV2CacheListColumnParams,
  MetricWithQueryKey
>({
  fetcher: async (columnParams) => {
    if (!columnParams.length) {
      return []
    }

    const listsToBatch = uniqBy(columnParams, columnParamsBaseBatchingKey)

    return flatten(
      await Promise.all(
        listsToBatch.map(async (batchedParams) => {
          const listColumn = await getListColumns(
            batchedParams.versionId,
            batchedParams.listId,
            getMetricIdsForList(
              batchedParams.versionId,
              batchedParams.listId,
              columnParams,
            ),
            batchedParams.period,
            true /* always include data */,
            undefined /* row keys are not supported */,
            batchedParams.filters,
            batchedParams.pagination,
          )

          return listColumn.data.result.map<MetricWithQueryKey>((m) => ({
            ...m,
            batshitQueryKey: columnParamsBatchingKey({
              ...batchedParams,
              metricId: m.id, // Uniq record per metric in batched request
            }),
          }))
        }),
      ),
    )
  },
  resolver: (items, query) =>
    items.find(
      ({ batshitQueryKey }) =>
        batshitQueryKey === columnParamsBatchingKey(query),
    ) as MetricWithQueryKey,
  scheduler: windowScheduler(50),
})

const getMetricIdsForList = (
  versionId: string,
  listId: string,
  columnParams: MetricV2CacheListColumnParams[],
) =>
  columnParams
    .filter((p) => p.versionId === versionId && p.listId === listId)
    .map((p) => p.metricId)

// batch queries only if list, version, period, filter and page match
const columnParamsBaseBatchingKey = (
  p: MetricV2CacheListColumnParams,
): string =>
  `${p.versionId}_${p.listId}_${JSON.stringify({
    f: p.filters ?? {},
    p: p.period ?? null,
    o: p.pagination ?? {},
  })}`

// Uniq key per metric column
const columnParamsBatchingKey = (p: MetricV2CacheListColumnParams): string =>
  `${columnParamsBaseBatchingKey(p)}_${p.metricId}`
