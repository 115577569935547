import { styled, Dialog, DialogProps } from '@mui/material'

export const StyledModalDialogRoot = styled(
  (props: DialogProps & { width?: number }) => {
    const { width, ...dialogProps } = props
    return <Dialog {...dialogProps} />
  },
  { shouldForwardProp: (propName) => propName !== 'width' },
)(({ theme, width }) => ({
  // todo ipomazkin-fin probably it should be applied on the theme's level, but now I'm not sure

  '.MuiBackdrop-root': {
    background: 'rgba(38, 54, 70, 0.15)',
  },

  '.MuiDialog-paper': {
    width,
    maxWidth: width ? '100%' : undefined,

    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
  },
}))
