import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

type ColumnMaskToggleProps = {
  value: string
  onChange: (newValue: string) => void
}

export const ColumnMaskToggle: React.FC<ColumnMaskToggleProps> = ({
  value,
  onChange,
}) => {
  const handleChange = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: string) => {
      onChange(newValue)
    },
    [onChange],
  )

  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      sx={{ marginBottom: '6px' }}
    >
      <ToggleButton value="never">Never</ToggleButton>
      <ToggleButton value="conditional">By Dimension</ToggleButton>
    </ToggleButtonGroup>
  )
}
