import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosError, AxiosResponse, default as axiosStatic } from 'axios'
import { NetworkError, RuntimeError } from './save-dimension-errors'
import { DimensionId } from '@fintastic/web/util/dimensions'
import {
  fromAxiosErrorToFirstMatchedError,
  makeFromAxiosErrorFunction,
  WithUserFriendlyErrorText,
} from '@fintastic/shared/util/errors'

type RenameDimValuePayload = {
  items: Array<{
    id: string
    label: string
  }>
}

export const renameDimensionValues = async (
  dimId: DimensionId,
  editedValues: RenameDimValuePayload['items'],
) => {
  try {
    return await axios.put<
      void,
      AxiosResponse<void, RenameDimValuePayload>,
      RenameDimValuePayload
    >(endpoints.renameDimensionValues(dimId), {
      items: editedValues,
    })
  } catch (e) {
    return castError(e as Error)
  }
}

export type StoreDimensionValuesError =
  | NetworkError
  | RuntimeError
  | ValidationError
  | DuplicationError

const castError = (e: AxiosError | Error): StoreDimensionValuesError => {
  if (!axiosStatic.isAxiosError(e)) {
    return new RuntimeError(e)
  }

  return (
    fromAxiosErrorToFirstMatchedError(e, [ValidationError, DuplicationError]) ||
    new NetworkError()
  )
}

class DuplicationError
  extends Error
  implements WithUserFriendlyErrorText
{
  getUiMessage() {
    return 'Something went wrong during the saving of the changes'
  }

  constructor() {
    super('Some dim values in payload have duplicated labels')
    this.name = 'DuplicationError'
    Object.setPrototypeOf(this, DuplicationError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    this,
    (e) => e.response?.status === 409,
  )
}

class ValidationError extends Error implements WithUserFriendlyErrorText {
  getUiMessage() {
    return 'Something went wrong'
  }

  constructor() {
    super('The validation on BE is failed')
    this.name = 'ValidationError'
    Object.setPrototypeOf(this, ValidationError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    this,
    (e) => e.response?.status === 422,
  )
}

export const renameDimensionValueApiErrors = {
  ValidationError,
  DuplicationError,
}
