import React, { useEffect } from 'react'
import {
  ReferenceVersionSelect,
  useCommonDataWidgetSettings,
  useSetWidgetSettingsVersionErrorsEffect,
  useWidgetSettingsDialogContext,
  WidgetSelectEmpty,
} from '@fintastic/web/feature/boards'
import { FormField, TextField } from '@fintastic/shared/ui/form-framework'
import { useListWidgetSettings } from '../hooks/useListWidgetSettings'
import { PENDING_LISTS_ID, WidgetSelectList } from './WidgetSelectList'

export const ListWidgetForm: React.FC = () => {
  const { data, setPartialData, pendingApi } = useWidgetSettingsDialogContext()

  // common piece of data
  const {
    versionId,
    displayNameFieldState,
    handleSelectVersion,
    handleShowNoRefVersionMessage,
    showRefVersionProblem,
  } = useCommonDataWidgetSettings(data)

  // list-specific pieces of data
  const { listId, handleSelectList } = useListWidgetSettings(
    data,
    displayNameFieldState,
  )
  const isListOfListLoading = pendingApi.hasPendingProcess(PENDING_LISTS_ID)

  const showNoReferenceVersionSelected =
    !versionId &&
    !showRefVersionProblem &&
    Boolean(listId) &&
    !pendingApi.isAnyPendingProcess // version is not selected but it can be selected

  useEffect(() => {
    setPartialData({
      versionId: versionId || '',
      id: listId || '',
      displayLabel: displayNameFieldState.trimmedValue || '',
    })
  }, [
    data,
    displayNameFieldState.trimmedValue,
    listId,
    setPartialData,
    versionId,
  ])

  useEffect(() => {
    if (!versionId) {
      pendingApi.finishPendingProcess(PENDING_LISTS_ID) // reference version is not available anymore - stop any pending ops
    }
  }, [pendingApi, versionId])

  useSetWidgetSettingsVersionErrorsEffect(
    showRefVersionProblem,
    showNoReferenceVersionSelected,
  )

  return (
    <>
      <FormField fullWidth={true}>
        <ReferenceVersionSelect
          value={versionId || ''}
          onNoRefVersion={handleShowNoRefVersionMessage}
          onSelect={handleSelectVersion}
          onFetching={pendingApi.addPendingProcess}
          onReady={pendingApi.finishPendingProcess}
        />
      </FormField>

      <FormField fullWidth={true}>
        {versionId ? (
          <WidgetSelectList
            disabled={!versionId}
            isLoading={isListOfListLoading}
            versionId={versionId}
            value={listId || ''}
            onSelect={handleSelectList}
            onFetching={pendingApi.addPendingProcess}
            onReady={pendingApi.finishPendingProcess}
          />
        ) : (
          <WidgetSelectEmpty
            emptyText={'Please select the reference version first'}
          />
        )}
      </FormField>

      <TextField
        label="Display name"
        fullWidth
        placeholder="Display name"
        value={displayNameFieldState.value}
        onChange={displayNameFieldState.setValue}
        error={
          displayNameFieldState.touched
            ? !displayNameFieldState.trimmedValue
            : false
        }
        errorMessage={
          displayNameFieldState.touched && !displayNameFieldState.trimmedValue
            ? 'Mandatory field'
            : undefined
        }
      />
    </>
  )
}
