import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ArrowIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.99983 2.18306C7.24391 1.93898 7.63964 1.93898 7.88371 2.18306L16.8167 11.1161C16.9329 11.2321 17.0251 11.3699 17.088 11.5216C17.1509 11.6732 17.1832 11.8358 17.1832 12C17.1832 12.1642 17.1509 12.3268 17.088 12.4784C17.0251 12.6301 16.9329 12.7679 16.8167 12.8839L7.88371 21.8169C7.63964 22.061 7.24391 22.061 6.99983 21.8169C6.75575 21.5729 6.75575 21.1771 6.99983 20.9331L15.9329 12L6.99983 3.06694C6.75575 2.82286 6.75575 2.42714 6.99983 2.18306Z"
  />,
  'ArrowIcon',
)
