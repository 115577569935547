import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export const FilterSubGroup: React.FC<
  PropsWithChildren<{
    searchSlot: React.ReactNode
  }>
> = ({ searchSlot, children }) => (
  <Box display="flex" flexDirection="column">
    {searchSlot ? <Box p={1}>{searchSlot}</Box> : null}
    <Box pl={1}>{children}</Box>
  </Box>
)

FilterSubGroup.displayName = 'FilterSubGroup'
