import { useMutation } from 'react-query'
import { useMemo } from 'react'
import { DisplayErrorCallback, UnlockVersionCallback } from '../types'
import { StateApi } from '../../editor-state'

export type DiscardOperationApi = Pick<
  ReturnType<typeof useDiscardOperation>,
  'error' | 'mutateAsync' | 'isLoading'
>

export type DiscardOperationParams = {}

export type DiscardOperationDependencies = {
  stateApi: StateApi
  unlockVersion: UnlockVersionCallback
  displayError: DisplayErrorCallback
  openConfirmationModal: () => void
  closeConfirmationModal: () => void
}

export const useDiscardOperation = (
  params: DiscardOperationParams,
  deps: DiscardOperationDependencies,
) =>
  useMutation({
    mutationFn: useMemo(() => makeOperationFn(params, deps), [deps, params]),
  })

export const makeOperationFn =
  (params: DiscardOperationParams, deps: DiscardOperationDependencies) =>
  async (variables: { confirmed?: true }) => {
    if (!deps.stateApi.state.active) {
      throw new Error('There is no report being editing right now')
    }

    if (!variables.confirmed) {
      deps.openConfirmationModal()
      return
    }

    try {
      await deps.unlockVersion()
    } catch (error) {
      deps.displayError('Cannot unlock the version')
      throw error
    }

    deps.stateApi.discardEditing()
    deps.closeConfirmationModal()
  }
