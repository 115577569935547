import type { ICellRendererParams, RowNode } from 'ag-grid-community'
import React, { useCallback, useRef } from 'react'
import { FormulaIcon } from '@fintastic/shared/ui/icons'
import { StyledVersionSourceCol } from '../Version.styled'
import type { Maybe } from '@fintastic/shared/util/types'
import { VersionTable } from '../types'
import { isFormulaAvailable } from '../features/formula/isFormulaAvailable'
import { FormulaButton } from '@fintastic/web/feature/formulas'
import { FormulaIcon as FormulaIconType } from '@fintastic/web/feature/formulas'
import { useAttachEventListenerToNativeDom } from '@fintastic/shared/util/ag-grid'

export type TableSourceColRendererProps = ICellRendererParams<VersionTable> & {
  toggleFormulaMasterDetail: (gridId: string, node?: Maybe<RowNode>) => void
  checkIsFormulaOpened: (gridId: string) => boolean
  icon: FormulaIconType
}

export const TableSourceColRenderer = ({
  value,
  toggleFormulaMasterDetail,
  data,
  node,
  checkIsFormulaOpened,
  icon,
}: TableSourceColRendererProps) => {
  const isFormula = value !== 'input'
  const isRaw = value === 'raw'
  const formulaAvailable =
    data?.type === 'report' ? false : isFormulaAvailable(data)
  const gridId: Maybe<string> = data?.id || null
  const isFormulaOpened = gridId ? checkIsFormulaOpened(gridId) : false

  // we need actual data inside the event handler
  const gridIdRef = useRef<Maybe<string>>(gridId)
  gridIdRef.current = gridId
  const nodeRef = useRef(node)
  nodeRef.current = node
  const toggleFormulaMasterDetailRef = useRef(toggleFormulaMasterDetail)
  toggleFormulaMasterDetailRef.current = toggleFormulaMasterDetail

  const iconButtonCallbackRef = useAttachEventListenerToNativeDom(
    'click',
    useCallback((event) => {
      if (!gridIdRef.current) {
        return
      }
      toggleFormulaMasterDetailRef.current(gridIdRef?.current, nodeRef.current)
    }, []),
    true,
  )

  if (!value) {
    return <>{'-'}</>
  }

  return (
    <StyledVersionSourceCol>
      {!isFormula && !isRaw && 'User input'}
      {isFormula && !formulaAvailable && !isRaw && <FormulaIcon />}
      {isRaw && 'Raw'}
      {isFormula && formulaAvailable && (
        <FormulaButton
          ref={iconButtonCallbackRef}
          isActive={isFormulaOpened}
          icon={icon}
          sx={{ ml: '-4px' }}
        />
      )}
    </StyledVersionSourceCol>
  )
}
