import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo, useMemo } from 'react'
import {
  getYAxisDecimalPlacesName,
  getYAxisFormatName,
} from '@fintastic/web/feature/charts'

export const DialogSectionYAxisSubtitle: React.FC = memo(() => {
  const { data } = useWidgetSettingsDialogContext()

  const title = useMemo(() => (
      getYAxisFormatName(data.chartSettings?.numberFormat) +
      ', ' +
      getYAxisDecimalPlacesName(data.chartSettings?.decimalPlaces)
    ), [data.chartSettings?.decimalPlaces, data.chartSettings?.numberFormat])

  return <span>{title}</span>
})
