import React, { useEffect } from 'react'
import { useModalState } from '@fintastic/shared/util/modal'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'
import { Panel as DebugPanel } from './board-config-debug/Panel'
import { Board } from '@fintastic/web/feature/boards'
import { toast } from '@fintastic/shared/ui/toast-framework'

type BoardDebugProps = {
  board: Board
  isDesignMode?: boolean
}
export const BoardDebug: React.FC<BoardDebugProps> = ({ board, isDesignMode }) => {
  const debugModalState = useModalState()
  const isDebugModalEnabled = useIsFintasticUser()

  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'B') {
        debugModalState.open()
        if (isDesignMode) {
          toast('Design mode - displayed the state of the Board BEFORE any editing.')
        }
      }
    }

    window.addEventListener('keydown', downHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [debugModalState, debugModalState.open, isDesignMode])

  if (!isDebugModalEnabled) {
    return null
  }

  return (
    <DebugPanel
      board={board}
      open={debugModalState.isOpen}
      onClose={debugModalState.close}
    />
  )
}
