import { styled } from '@mui/material'

export const FilterToolPanelNoResultsIfEmpty = styled('div')`
  & ~ [data-no-resutls] {
    display: none;
  }
  &:empty ~ [data-no-resutls] {
    display: flex;
  }
`
