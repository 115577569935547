import { Grid } from '@mui/material'
import React, { useCallback, useContext, useMemo } from 'react'
import { CustomisedMuiDatePicker } from '@fintastic/shared/ui/components'
import { ImportActualsConfigContext } from '../context/ImportActualsConfigContext'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import { Maybe } from '@fintastic/shared/util/types'
import { CalendarIcon } from '@fintastic/shared/ui/icons'

export const DateRangeSelector: React.FC = () => {
  const {
    dateFrom,
    dateTo,
    maxPossibleDate,
    minPossibleDate,
    updateDateRange,
    loading,
  } = useContext(ImportActualsConfigContext)

  const handleDateFromChange = useCallback(
    (date: Maybe<dayjs.Dayjs>) => {
      if (!date) {
        return
      }

      updateDateRange(
        date.startOf('month').format(DEFAULT_DAYJS_DATE_FORMAT),
        dateTo || '',
      )
    },
    [dateTo, updateDateRange],
  )

  const handleDateToChange = useCallback(
    (date: Maybe<dayjs.Dayjs>) => {
      if (!date) {
        return
      }

      updateDateRange(dateFrom || '', date.format(DEFAULT_DAYJS_DATE_FORMAT))
    },
    [dateFrom, updateDateRange],
  )

  const parsedMinPossibleDate = useMemo(
    () => (minPossibleDate ? dayjs(minPossibleDate) : undefined),
    [minPossibleDate],
  )
  const parsedMaxPossibleDate = useMemo(
    () => (maxPossibleDate ? dayjs(maxPossibleDate) : undefined),
    [maxPossibleDate],
  )

  const parsedDateFrom = useMemo(
    () => (dateFrom ? dayjs(dateFrom) : parsedMinPossibleDate),
    [dateFrom, parsedMinPossibleDate],
  )
  const parsedDateTo = useMemo(
    () => (dateTo ? dayjs(dateTo) : parsedMaxPossibleDate),
    [dateTo, parsedMaxPossibleDate],
  )

  return (
    <Grid
      container
      spacing={3}
      pb={1}
      sx={{
        '& .MuiTextField-root': {
          width: '100%',
        },
        'maxWidth': '690px',
      }}
    >
      <Grid item sm={6}>
        <CustomisedMuiDatePicker
          views={['year', 'month']}
          label="From"
          value={parsedDateFrom}
          onChange={handleDateFromChange}
          minDate={parsedMinPossibleDate}
          maxDate={parsedDateTo || parsedMaxPossibleDate || undefined}
          openTo="month"
          slotProps={{ textField: { size: 'small' } }}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          disabled={loading}
        />
      </Grid>
      <Grid item sm={6}>
        <CustomisedMuiDatePicker
          views={['year', 'month']}
          label="To"
          value={parsedDateTo}
          onChange={handleDateToChange}
          minDate={parsedDateFrom || parsedMinPossibleDate || undefined}
          maxDate={parsedMaxPossibleDate}
          openTo="month"
          slotProps={{ textField: { size: 'small' } }}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          disabled={loading}
        />
      </Grid>
    </Grid>
  )
}
