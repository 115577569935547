import { axios } from '@fintastic/web/data-access/service-axios'
import MockAdapter from 'axios-mock-adapter'
import { cloneDeep } from 'lodash'

const mock = new MockAdapter(axios, {
  onNoMatch: 'passthrough',
})

type ReportMock = {
  id: string
  name: string
}

type VersionId = string

const reportsMocksStorage: {
  reportsPerVersion: Record<VersionId, ReportMock[]>
} = {
  reportsPerVersion: {},
}

export type ReportsListItem = {
  id: string
  name: string
}

export const getReportsList = (versionId: string) =>
  axios.get<{ result: ReportsListItem[] }>(
    `/__mock__/planning/api/v2/versions/${versionId}/reports/`,
  )
;(() => {
  const matcher =
    /^\/__mock__\/planning\/api\/v2\/versions\/([^\/]+)\/reports\/$/

  mock
    .onGet(matcher)
    .withDelayInMs(700)
    .reply((config) => {
      const urlMathes = config.url!.match(matcher)
      if (!urlMathes) {
        return [500]
      }
      const versionId = urlMathes[1]

      return [
        200,
        {
          result: (reportsMocksStorage.reportsPerVersion[versionId] || []).map(
            ({ id, name }) => ({ id, name }),
          ),
        },
      ]
    })
})()

export const getReport = (versionId: string, reportId: string) =>
  axios.get<{ id: string; name: string }>(
    `/__mock__/planning/api/v2/versions/${versionId}/reports/${reportId}/`,
  )
;(() => {
  const matcher =
    /^\/__mock__\/planning\/api\/v2\/versions\/([^\/]+)\/reports\/([^\/]+)\/$/
  mock
    .onGet(matcher)
    .withDelayInMs(700)
    .reply((config) => {
      const urlMathes = config.url!.match(matcher)
      if (!urlMathes) {
        return [500]
      }
      const versionId = urlMathes[1]
      const reportId = urlMathes[2]

      const report = (
        reportsMocksStorage.reportsPerVersion[versionId] || []
      ).find((r) => r.id === reportId)

      if (!report) {
        return [404]
      }

      return [200, cloneDeep(report)]
    })
})()

export const createReport = (
  versionId: string,
  reportDefinition: { name: string },
) =>
  axios.post(
    `/__mock__/planning/api/v2/versions/${versionId}/reports/`,
    reportDefinition,
  )
;(() => {
  const matcher = /\/__mock__\/planning\/api\/v2\/versions\/([^\/]+)\/reports\//

  mock
    .onPost(matcher)
    .withDelayInMs(700)
    .reply((config) => {
      const urlMathes = config.url!.match(matcher)
      if (!urlMathes) {
        return [500]
      }
      const versionId = urlMathes[1]
      if (!reportsMocksStorage.reportsPerVersion[versionId]) {
        reportsMocksStorage.reportsPerVersion[versionId] = []
      }
      const payload = JSON.parse(config.data)
      const reportId = `Report.${payload.name}`
      const report = {
        ...cloneDeep(payload),
        id: reportId,
      }
      reportsMocksStorage.reportsPerVersion[versionId].push(report)
      return [201, cloneDeep(report)]
    })
})()

export const deleteReport = (versionId: string, reportId: string) =>
  axios.delete(
    `/__mock__/planning/api/v2/versions/${versionId}/reports/${reportId}/`,
  )
;(() => {
  const matcher =
    /\/__mock__\/planning\/api\/v2\/versions\/([^\/]+)\/reports\/([^\/]+)\//

  mock
    .onDelete(matcher)
    .withDelayInMs(700)
    .reply((config) => {
      const urlMathes = config.url!.match(matcher)
      if (!urlMathes) {
        return [500]
      }
      const versionId = urlMathes[1]
      const reportId = urlMathes[2]

      const report = (
        reportsMocksStorage.reportsPerVersion[versionId] || []
      ).find((r) => r.id === reportId)

      if (!report) {
        return [404]
      }

      reportsMocksStorage.reportsPerVersion[versionId] =
        reportsMocksStorage.reportsPerVersion[versionId].filter(
          ({ id }) => id !== reportId,
        )

      return [204]
    })
})()

export const updateReport = (
  versionId: string,
  reportId: string,
  reportDefinition: {},
) =>
  axios.put(
    `/__mock__/planning/api/v2/versions/${versionId}/reports/${reportId}/`,
    reportDefinition,
  )
;(() => {
  const matcher =
    /\/__mock__\/planning\/api\/v2\/versions\/([^\/]+)\/reports\/([^\/]+)\//

  mock
    .onPut(matcher)
    .withDelayInMs(700)
    .reply((config) => {
      const urlMathes = config.url!.match(matcher)
      if (!urlMathes) {
        return [500]
      }
      const versionId = urlMathes[1]
      const reportId = urlMathes[2]
      const payload = JSON.parse(config.data)

      const reportIndex = (
        reportsMocksStorage.reportsPerVersion[versionId] || []
      ).findIndex((r) => r.id === reportId)
      const report =
        reportIndex < 0
          ? undefined
          : reportsMocksStorage.reportsPerVersion[versionId][reportIndex]

      if (!report) {
        return [404]
      }

      reportsMocksStorage.reportsPerVersion[versionId][reportIndex] = {
        ...cloneDeep(report),
        ...cloneDeep(payload),
      }

      return [200]
    })
})()
