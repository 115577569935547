import { AxiosResponseHeaders } from 'axios'
import { toMaybe } from '@fintastic/shared/util/types'

type OriginallyEmptyHeaderValue = '0' | '1'

export const ORIGINALLY_EMPTY_HEADER = 'x-fintastic-is-originally-empty'

const selectHeaderValue = (headers: AxiosResponseHeaders) =>
  toMaybe(headers[ORIGINALLY_EMPTY_HEADER] as OriginallyEmptyHeaderValue)

export const isOriginallyEmpty = (headers: AxiosResponseHeaders) =>
  selectHeaderValue(headers) === '1'
