import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useLocalContext } from './context'

export type DeclineButtonProps = Omit<ButtonProps, 'onClick'>

export const DeclineButton: React.FC<DeclineButtonProps> = (props) => {
  const { onDecline } = useLocalContext()
  return (
    <Button size="medium" variant="outlined" onClick={onDecline} {...props} />
  )
}
