import { ColumnIdParts } from './types'

export type ExtendedColWithOptionalChildren = {
  columnIdParts?: ColumnIdParts
  children?: ExtendedColWithOptionalChildren[]
}

// [{...ids...}, {...ids...}, [{...ids...}, {...ids...}]]
export type ColumnsIdStructure = (ColumnIdParts | ColumnIdParts[])[]

// makes flat [ColumnIdParts, ColumnIdParts, ColumnIdParts...]
// from [column.ColumnIdParts, column.children[ColumnIdParts, ColumnIdParts...]]
export const getColumnsIdStructure = (
  colDefs: ExtendedColWithOptionalChildren[],
): ColumnsIdStructure =>
  colDefs
    .filter((cd) => Boolean(cd.columnIdParts)) // remove _valid and others like that
    .map((cd) => {
      if (!cd.children) {
        return cd.columnIdParts as ColumnIdParts // just a column ids...
      }

      // [sub-column, sub-column...]
      return cd.children.map((child) => child.columnIdParts).filter(Boolean) // children always must have columnIdParts but let's check one more time
    }) as ColumnsIdStructure
