import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box } from '@mui/material'
import { WrappedVersionEntityDefinition } from '@fintastic/web/util/versions'
import { entityTypeToTypeLabel } from './mapping'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type ConfirmationPopupProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  deleting: boolean
  entityType: WrappedVersionEntityDefinition['type']
  lastColumnInList: boolean
}

export const ConfirmationModal: React.FC<ConfirmationPopupProps> = ({
  isOpen,
  onConfirm,
  onClose,
  deleting,
  entityType,
  lastColumnInList,
}) => (
  <ConfirmationPopup
    open={isOpen}
    onRequestClose={onClose}
    onConfirm={onConfirm}
    onDecline={onClose}
    title={`Delete ${entityTypeToTypeLabel[entityType]}?`}
    description={
      lastColumnInList
        ? "You're attempting to delete the last column in the list. It leads to the deletion of the list itself."
        : undefined
    }
    autoClose={false}
  >
    <Box width="100%">
      <ErrorAlert
        title="Existing data will be lost"
        message="It won't be possible to retrieve the current data"
        severity="warning"
      />
      <Box mt={3} width="100%" display="flex" justifyContent="center">
        <ConfirmationPopup.DeclineButton
          variant="contained"
          color="primary"
          disabled={deleting}
        >
          Cancel
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton sx={{ ml: 1 }} disabled={deleting}>
          {deleting
            ? 'Deleting...'
            : `Delete ${entityTypeToTypeLabel[entityType]}`}
        </ConfirmationPopup.ConfirmButton>
      </Box>
    </Box>
  </ConfirmationPopup>
)
