import { isObject } from 'lodash'
import { BaseTimeDimensionStaticWidgetContext } from './types'

export const isBaseTimeDimensionStaticWidgetContext = (
  obj: unknown,
): obj is BaseTimeDimensionStaticWidgetContext => {
  if (!isObject(obj)) {
    return false
  }

  return (obj as BaseTimeDimensionStaticWidgetContext).type === 'local'
}
