import { createTheme } from '@mui/material'
import { themeComponentsOverride } from './components-override'

export type FintasticMuiTheme = typeof theme

export const theme = createTheme({
  spacing: 8,
  palette: {
    mode: 'light',
    primary: {
      light: '#FFAF85',
      main: '#FF7933',
      dark: '#E56D2E',
      contrastText: '#fff',
    },
    black: {
      light: '#A8AFB5',
      main: '#263646',
      dark: '#17202A',
      contrastText: '#fff',
    },
    background: {
      default: '#FDFCFB',
      paper: '#fff',
    },
    text: {
      primary: '#263646',
      secondary: '#757575',
      disabled: '#B6B6B6',
    },
    divider: '#E7E6E4',
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    secondary: {
      main: '#333AFF',
      light: '#ADBFFF',
      dark: '#213DA3',
      contrastText: '#fff',
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#f5f5f5',
      'A200': '#eeeeee',
      'A400': '#bdbdbd',
      'A700': '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
      borderOpacity: 0.3,
    },
  },
  // @todo needs to be synchronized with designer
  shadows: [
    'none',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 16px rgba(0, 0, 0, 0.05)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
  ],
  shape: {
    borderRadius: 8,
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: "'Ubuntu', sans-serif;",
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontSize: '4rem',
      fontWeight: 400,
      lineHeight: 5.714,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    h2: {
      fontSize: '3.429rem',
      fontWeight: 400,
      lineHeight: 4.571,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    h3: {
      fontSize: '2.857rem',
      fontWeight: 400,
      lineHeight: 4,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    h4: {
      fontSize: '2.286rem',
      fontWeight: 400,
      lineHeight: 3.429,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    h5: {
      fontSize: '1.714rem',
      fontWeight: 400,
      lineHeight: 2.286,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    h6: {
      fontSize: '1.286rem',
      fontWeight: 400,
      lineHeight: 1.714,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    button: {
      fontSize: '0.8571428571428571rem',
      fontWeight: 500,
      lineHeight: 1.714,
      textTransform: 'none',
      fontFamily: "'Ubuntu', sans-serif;",
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 2.286,
      textDecoration: 'none',
      fontFamily: "'Ubuntu', sans-serif;",
    },
    subtitle2: {
      fontSize: '0.8571428571428571rem',
      fontWeight: 400,
      lineHeight: 1.714,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.714,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    body2: {
      fontSize: '0.8571428571428571rem',
      fontWeight: 400,
      lineHeight: 1.143,
      fontFamily: "'Ubuntu', sans-serif;",
    },
    fontWeightLight: 300,
    fontWeightMedium: 500,
    caption: {
      fontFamily: "'Ubuntu', sans-serif;",
      fontWeight: 500,
      fontSize: '0.714rem',
      lineHeight: 1.143,
    },
    overline: {
      fontFamily: "'Ubuntu', sans-serif;",
      fontWeight: 500,
      fontSize: '0.714rem',
      lineHeight: 1.143,
      textTransform: 'uppercase',
    },
  },

  components: themeComponentsOverride,
})
