import { Maybe } from '@fintastic/shared/util/types'
import { SelectedCell, SelectedCellAggregationType } from '../types'

export const getDefaultAggregation = (
  cells: SelectedCell[],
): Maybe<SelectedCellAggregationType> => {
  if (!cells.length) {
    return null
  }

  const numericCells = cells.filter(
    (cell) => cell.format !== 'string' && typeof cell.value === 'number',
  )

  if (!numericCells.length) {
    return 'count'
  }

  return 'sum'
}
