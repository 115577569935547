import { FormulaMutation, FormulaMutationsApi } from './types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

// @todo add tests
export function useFormulaMutations({
  updateFormula,
  moveCaret,
}: {
  updateFormula: (formula: string) => void
  moveCaret: (position: number) => void
}): FormulaMutationsApi {
  const waitingForRenderFinished = useRef(false)
  const newCaretPosition = useRef<Maybe<number>>(null)

  const applyMutation = useCallback(
    (mutation: FormulaMutation) => {
      updateFormula(mutation.newFormula)
      waitingForRenderFinished.current = true
      if (mutation.newCaretPosition) {
        newCaretPosition.current = mutation.newCaretPosition
      }
    },
    [updateFormula],
  )

  useEffect(() => {
    if (waitingForRenderFinished.current && newCaretPosition.current !== null) {
      moveCaret(newCaretPosition.current)
      waitingForRenderFinished.current = false
    }
  })

  return useMemo(
    () => ({
      applyMutation,
    }),
    [applyMutation],
  )
}
