import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

type ConfirmationModalProps = {
  onConfirm: () => void
  onDecline: () => void
  isOpen: boolean
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onDecline,
  isOpen,
}) => (
  <ConfirmationPopup
    onConfirm={onConfirm}
    onDecline={onDecline}
    open={isOpen}
    onRequestClose={onDecline}
    title="Confirm Import Live Actuals"
    description="Data modified by the import cannot be retrieved."
  >
    <ConfirmationPopup.DeclineButton>Cancel</ConfirmationPopup.DeclineButton>
    <ConfirmationPopup.ConfirmButton
      variant="contained"
      color="primary"
      sx={{ ml: 1 }}
    >
      Import Live Actuals
    </ConfirmationPopup.ConfirmButton>
  </ConfirmationPopup>
)
