import React, { useMemo } from 'react'
import { ModalFooterPanel } from '@fintastic/shared/ui/modal-framework'
import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'

export type WidgetSettingsButtonPanelProps = {
  onSubmit?: () => void
  submitText?: string
}

export const WidgetSettingsButtonPanel: React.FC<
  WidgetSettingsButtonPanelProps
> = ({ onSubmit, submitText }) => {
  const { isNew, onConfirm, onCancel, valid, isChanged, pendingApi } =
    useWidgetSettingsDialogContext()

  const disabledReasonText = useMemo(() => {
    if (!valid) {
      return 'Not all the fields filled'
    }
    if (!isChanged) {
      return 'There are no changes yet'
    }
    return ''
  }, [isChanged, valid])

  return (
    <ModalFooterPanel
      submitEnabled={valid && isChanged}
      loading={pendingApi.isAnyPendingProcess}
      submitButtonText={
        submitText ? submitText : isNew ? 'Add widget' : 'Save and close'
      }
      disabledReasonTooltip={disabledReasonText}
      onCancel={onCancel}
      onSubmit={onSubmit ? onSubmit : onConfirm}
    />
  )
}
