import React from 'react'
import { FilterValue } from '@fintastic/web/util/filters'
import { NumberFilterInputSharedProps } from './types'
import { NumberFieldWithDisplayConfig } from './NumberFieldWithDisplayConfig'

export const NormalNumberField: React.FC<NormalNumberFieldProps> = ({
  filterValue,
  onChange,
  dataType,
  displayConfig,
}) => (
  <NumberFieldWithDisplayConfig
    placeholder="Value"
    filterValue={filterValue}
    dataType={dataType}
    displayConfig={displayConfig}
    onChange={onChange}
  />
)

export type NormalNumberFieldProps = {
  filterValue: Partial<FilterValue<number | ''>>
  onChange: (nextValue: FilterValue<number | ''>) => void
} & NumberFilterInputSharedProps
