import { styled } from '@mui/material'

export const StyledRangeContainer = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  flex-grow: 1;
`

export const StyledRangeDivider = styled('div')`
  min-width: 16px;
  width: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const StyledDatePickerContainer = styled('div')`
  min-width: 120px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    background: #fff;
    height: 32px;
    font-size: 12px;

    .MuiInputAdornment-root {
      width: auto;
    }
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedEnd {
    padding-right: 0;
  }

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeSmall {
    margin-left: 0;
  }
`
