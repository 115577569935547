import React, { useMemo } from 'react'
import { FormattingFieldsetProps } from './types'
import { NumberFormatting } from './NumberFormatting'
import { FormattingFieldWrapper } from './FormattingFieldWrapper'
import { CurrencySignPositionField } from './fields/CurrencySignPositionField'
import { useDataContext } from '../data-context'
import { getCurrencySymbol } from '@fintastic/web/util/metrics-and-lists'

export type CurrencyFormattingProps = FormattingFieldsetProps

export const CurrencyFormatting: React.FC<CurrencyFormattingProps> = ({
  onChangeCurrencySignPosition,
  displaySettings,
  onChange,
}) => {
  const { currenciesList } = useDataContext()

  const currency = displaySettings.currency()

  const currencySymbol = useMemo(() => {
    const currencyObject = currenciesList.find((c) => c.code === currency)
    return getCurrencySymbol(currencyObject)
  }, [currenciesList, currency])

  return (
    <>
      <FormattingFieldWrapper>
        <CurrencySignPositionField
          value={displaySettings.currencySignPosition()}
          currencySymbol={currencySymbol}
          onChange={(v) => onChangeCurrencySignPosition(v)}
        />
      </FormattingFieldWrapper>
      <NumberFormatting displaySettings={displaySettings} onChange={onChange} />
    </>
  )
}
