import type { Maybe } from '@fintastic/shared/util/types'
import { FormulaMutation } from '../../formula-mutations/types'
import { addIndentation } from './utils'

export const makeIncreaseIndentationMutation = (
  formula: string,
  caretPosition: number,
): Maybe<FormulaMutation> => {
  const newFormula = addIndentation(formula, caretPosition)

  if (newFormula === formula) {
    return null
  }

  return {
    newFormula,
    newCaretPosition: caretPosition + 1,
  }
}
