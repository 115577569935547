import React, { useCallback, useEffect } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { UserAdmin } from './permissions/UserAdmin'
import { SystemAdmin } from './permissions/SystemAdmin'
import { TablesAndRowsAdmin } from './permissions/TablesAndRowsAdmin'
import {
  PageLayout,
  PageLayoutContentFullHeight,
  TopbarTabs,
} from '@fintastic/shared/ui/app-layout-framework'
import { ColumnRestrictionAdmin } from './permissions/ColumnRestrictionAdmin'
import { useLoadPermissionDataEffect } from './permissions/hooks/useLoadPermissionDataEffect'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import {
  AccessDeninedAlert,
  CenteredCircularProgress,
} from '@fintastic/shared/ui/components'
import { useSelector } from 'react-redux'
import { selectLoadingState } from './permissions/ducks'
import { usePermissionsRouteBlocker } from './permissions/hooks/usePermissionsRouteBlocker'
import { RestrictedDimensionsContainer } from './permissions/features/RestrictedDimensions/RestrictedDimensionsContainer'

const tabs = [
  /* {
    label: 'System',
    value: 'system',
  },*/
  {
    label: 'Users',
    value: 'users',
  },
  {
    label: 'Permissions',
    value: 'tablesrows',
  },
  {
    label: 'Restricted Dimensions',
    value: 'restricteddimensions',
  },
  {
    label: 'Restricted Columns',
    value: 'restrictedcolumns',
  },
] as const

type PermissionsTab = typeof tabs[number]['value']

const components: Record<PermissionsTab | string, React.ReactElement> = {
  system: <SystemAdmin />,
  users: <UserAdmin />,
  tablesrows: <TablesAndRowsAdmin />,
  restrictedcolumns: <ColumnRestrictionAdmin />,
  restricteddimensions: <RestrictedDimensionsContainer />,
}

export const PermissionsPage = () => {
  const loading = useSelector(selectLoadingState)
  const isAdminUser = Boolean(useRoleLevelAccess(['admin', 'power_user']))
  usePermissionsRouteBlocker()

  useLoadPermissionDataEffect()

  const tabFromHash = useCallback(
    () =>
      tabs
        .map((t) => t.value)
        .includes(window.location.hash.slice(1) as PermissionsTab)
        ? window.location.hash.slice(1)
        : 'system',
    [],
  )

  const [currentTab, setCurrentTab] = React.useState(tabFromHash())

  useEffect(() => {
    const onHashChange = () => {
      setCurrentTab(tabFromHash())
    }
    window.addEventListener('hashchange', onHashChange)
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [tabFromHash])

  const handleChange = React.useCallback(
    (event: unknown, newValue: string) => {
      if (window && tabFromHash() !== newValue) {
        window.location.hash = `#${newValue}`
      }

      setCurrentTab(newValue)
    },
    [tabFromHash],
  )

  if (loading) {
    return <CenteredCircularProgress />
  }

  if (!isAdminUser) {
    return <AccessDeninedAlert />
  }

  return (
    <TabContext value={currentTab}>
      <PageLayout topbar={<TopbarTabs tabs={tabs} onChange={handleChange} />}>
        <PageLayoutContentFullHeight>
          {tabs.map((tabProps) => (
            <TabPanel
              key={tabProps.value}
              value={tabProps.value}
              sx={{ height: '100%', padding: 0 }}
            >
              {components[tabProps.value] || tabProps.value}
            </TabPanel>
          ))}
        </PageLayoutContentFullHeight>
      </PageLayout>
    </TabContext>
  )
}
