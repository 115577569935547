import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnValueDataTypePayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnValueDataType = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeColumnValueDataTypePayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  list
    .findColumnByClientId(payload.columnId)
    ?.changeDataType(payload.dataType, payload.currency, payload.allDimensions)
}
