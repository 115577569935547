import { RouteObject } from '../types'
import { useMemo } from 'react'
import { matchRoutes } from './matchRoutes'
import { Maybe } from '@fintastic/shared/util/types'

export function useRouteMatches(
  routes: RouteObject[],
  location: Maybe<string>,
) {
  return useMemo(
    () => (location === null ? null : matchRoutes(routes, location)),
    [location, routes],
  )
}
