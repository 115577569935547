import { QueryClient, useQuery } from 'react-query'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { getVersionDependencyGraph } from '../../api/version-entities-api'
import { versionsCacheKeys } from '../../cache'

const makeQueryFunction = (versionId: Maybe<string>) => async () => {
  const response = await getVersionDependencyGraph(versionId || '')
  return response.data
}

export function useLoadVersionDependencyGraph(
  id?: Maybe<string>,
  enabled = true,
) {
  return useQuery(
    versionsCacheKeys.versionDependencyGraph(id || ''),
    makeQueryFunction(toMaybe(id)),
    {
      enabled: enabled && id !== null && id !== undefined,
      staleTime: Infinity,
    },
  )
}

export const loadManuallyVersionDependenciesGraph = (
  qc: QueryClient,
  versionId: Maybe<string>,
) =>
  qc.fetchQuery(
    versionsCacheKeys.versionDependencyGraph(versionId || ''),
    makeQueryFunction(toMaybe(versionId)),
    {
      staleTime: Infinity,
    },
  )
