import {
  PopulateForwardButtonDefinition,
  PopulateForwardCellRendererProps,
} from '@fintastic/shared/ui/ag-grid'
import { BaseGridRowData } from '@fintastic/shared/ui/grid-framework'
import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { RowNode } from 'ag-grid-community'

export type BuildPopulateForwardPropsParams<
  TData extends BaseGridRowData = BaseGridRowData,
> = {
  isEditable?: boolean
  hasPeriod: boolean
  allowed: boolean
  populatedColumnsFields: string[]
  onPopulate: (
    value: MetricDataValue,
    context: {
      rowData: TData
      rowNode: RowNode<TData>
      gridApi: GridApi<TData>
      populatedColumnsFields: string[]
    },
  ) => void
}

// @todo add tests
export function buildPopulateForwardProps<
  TData extends BaseGridRowData = BaseGridRowData,
>(
  params: BuildPopulateForwardPropsParams<TData>,
): PopulateForwardCellRendererProps<TData> {
  const { isEditable, hasPeriod, allowed } = params

  const rendererProps: PopulateForwardCellRendererProps<TData> = {
    enabled: allowed && isEditable && hasPeriod,
    buttons: [],
  }

  if (!rendererProps.enabled) {
    return rendererProps
  }

  rendererProps.buttons.push(buildDefaultButtonDef(params))

  return rendererProps
}

// @todo add tests
function buildDefaultButtonDef<
  TData extends BaseGridRowData = BaseGridRowData,
>({
  onPopulate,
  populatedColumnsFields,
}: BuildPopulateForwardPropsParams<TData>): PopulateForwardButtonDefinition<TData> {
  return {
    key: 'default',
    label: 'Apply to future periods',
    onClick: (value, data, rowNode, gridApi) => {
      onPopulate(value, {
        rowData: data,
        populatedColumnsFields,
        rowNode,
        gridApi,
      })
      gridApi.flashCells({
        rowNodes: [rowNode],
        columns: populatedColumnsFields,
        fadeDelay: 5000,
      })
    },
  }
}
