import React, { useContext } from 'react'
import { baseGridContext } from '../../base-grid'
import {
  PanelTopbar,
  PanelTopbarProps,
  PanelTopbarTitle,
} from '@fintastic/shared/ui/panel-framework'

export type TopBarProps = Omit<PanelTopbarProps, 'centerContent'> & {
  title?: React.ReactNode
  titleIcon?: React.ReactNode
}

export const TopBar: React.FC<TopBarProps> = (props) => {
  const { title, titleIcon, ...rest } = props

  const gridContext = useContext(baseGridContext)
  const [collapsed] = gridContext.collapseState

  return (
    <PanelTopbar
      enableBorderBottom={collapsed ? false : !gridContext.embeddedView}
      data-testid="grid-topbar"
      centerContent={
        title && <PanelTopbarTitle icon={titleIcon}>{title}</PanelTopbarTitle>
      }
      {...rest}
    />
  )
}
