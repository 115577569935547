import React from 'react'
import {
  StyledModalHeaderRoot,
  StyledModalHeaderCloseButton,
  StyledModalHeaderDescription,
  StyledModalHeaderTitle,
} from './ModalHeader.styled'
import { ModalCloseButton } from '../ModalCloseButton'

export type ModalHeaderProps = {
  onRequestClose?: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  showCloseButton?: boolean
}

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { onRequestClose, showCloseButton = true, title, description } = props

  return (
    <StyledModalHeaderRoot>
      {title && (
        <StyledModalHeaderTitle hasDescription={!!description}>
          {title}
        </StyledModalHeaderTitle>
      )}
      {description && (
        <StyledModalHeaderDescription hasTitle={!!title}>
          {description}
        </StyledModalHeaderDescription>
      )}
      {showCloseButton && (
        <StyledModalHeaderCloseButton>
          <ModalCloseButton onClick={onRequestClose} />
        </StyledModalHeaderCloseButton>
      )}
    </StyledModalHeaderRoot>
  )
}
