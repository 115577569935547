import { isArray, isEmpty, isObject } from 'lodash'
import type { DeeplinkValue, DeeplinkValueElement } from '../types'
import {
  containsSimpleArray,
  deeplinkValueElementParser,
  parseSimpleArray,
} from './value-parser'
import {
  convertMaybeArray,
  getObjectFieldName,
  isConvertableToArray,
} from './utils'
import {
  deeplinkValueElementStringify,
  serialazeSimpleArray,
} from './value-stringify'
import { JOIN_SYMBOL } from './const'

export const getObjectFromUrlSearchParams = <T extends DeeplinkValue>({
  searchParams,
  key,
}: {
  searchParams: URLSearchParams
  key: string
}): T | undefined => {
  if (searchParams.has(key)) {
    const v = searchParams.get(key)
    if (v === undefined || v === null) {
      return undefined
    }

    if (containsSimpleArray(v)) {
      return parseSimpleArray(v) as T
    }

    return deeplinkValueElementParser(v) as T
  }

  const out: Record<string, DeeplinkValueElement | DeeplinkValueElement[]> = {}
  let hasAnyProp = false

  // Add every searchParams[key][field] to `out`
  searchParams.forEach((v, paramKey) => {
    if (!v) {
      return
    }

    if (!paramKey.startsWith(`${key}[`)) {
      return
    }

    const name = getObjectFieldName(paramKey)

    if (!name) {
      return
    }

    hasAnyProp = true
    out[name] = containsSimpleArray(v)
      ? parseSimpleArray(v)
      : deeplinkValueElementParser(v)
  })

  const outMaybeArray = isConvertableToArray(out) ? convertMaybeArray(out) : out

  return hasAnyProp ? (outMaybeArray as T) : undefined
}

/**
 * Mutates `searchParams`
 **/
export const addObjectToUrlSearchParams = (
  searchParams: URLSearchParams,
  key: string,
  value: DeeplinkValue | undefined,
): URLSearchParams => {
  if (
    value === undefined ||
    (isArray(value) && value?.length === 0) ||
    (isObject(value) && isEmpty(value))
  ) {
    searchParams.delete(key)
    const originalValue = getObjectFromUrlSearchParams<string[]>({
      searchParams,
      key,
    })

    if (!isArray(originalValue) && isObject(originalValue)) {
      const keys = Object.keys(originalValue || {})
      keys?.forEach((internalKey) => {
        searchParams.delete(`${key}[${internalKey}]`)
      })
    }

    return searchParams
  }

  if (!isObject(value)) {
    searchParams.set(key, deeplinkValueElementStringify(value))
    return searchParams
  }

  if (isArray(value)) {
    if ((process.env as any) === 'development') {
      if (value.find((i) => typeof i === 'string' && i.includes(JOIN_SYMBOL))) {
        throw new Error('Deeplink array value must not include JOIN_SYMBOL')
      }
    }

    searchParams.set(key, serialazeSimpleArray(value))

    return searchParams
  }

  if (isObject(value)) {
    const keys = Object.keys(value)

    keys.forEach((internalKey) => {
      const arrayInside =
        typeof value[internalKey] === 'object' &&
        value[internalKey] !== null &&
        (value[internalKey] as string[])?.length

      if (arrayInside) {
        const stringified = serialazeSimpleArray(value[internalKey] as string[])
        searchParams.set(`${key}[${internalKey}]`, stringified)
      } else {
        if (value[internalKey] === undefined) {
          searchParams.delete(`${key}[${internalKey}]`)
        } else {
          const stringified = deeplinkValueElementStringify(
            value[internalKey] as DeeplinkValueElement,
          )

          searchParams.set(`${key}[${internalKey}]`, stringified)
        }
      }
    })
    return searchParams
  }

  return searchParams
}
