import { Maybe } from '@fintastic/shared/util/types'
import { useEffect, useState } from 'react'
import { FocusedToken } from '../../focused-token/types'

export function usePopperTargetNode(
  focusedToken: Maybe<FocusedToken>,
  getTokensNodes: Maybe<() => HTMLElement[]>,
) {
  const [node, setNode] = useState<Maybe<HTMLElement>>(null)

  useEffect(() => {
    if (!focusedToken || !getTokensNodes) {
      return
    }
    const nodes = getTokensNodes()
    if (!nodes[focusedToken.index]) {
      return
    }
    setNode(nodes[focusedToken.index])
  }, [focusedToken, getTokensNodes])

  return node
}
