import { Box, styled } from '@mui/material'

export const StyledControlButtons = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: ${({ theme }) => theme.spacing(2)};
  margin: ${({ theme }) => theme.spacing(0.25)} 0
    ${({ theme }) => theme.spacing(0.25)} auto;

  align-content: space-between;
  align-items: center;
  gap: 4px ${({ theme }) => theme.spacing(1)};

  .MuiButtonBase-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    background: #fff;
    border-color: ${({ theme }) => theme.palette.divider};

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
