import { ThemeComponents } from './types'

export const MuiSnackbar: ThemeComponents['MuiSnackbar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('sm')]: {
        bottom: '16px',
      },
    }),
  },
}
