import { useLocalStorage } from 'usehooks-ts'
import { Maybe } from '@fintastic/shared/util/types'

const getLocalStorageKey = (versionId: string, listId: string) =>
  `fin_ls-${versionId}:${listId}:hiddenColumns`

export function useHiddenColumnsLocalStorage(
  versionId: string,
  listId: string,
) {
  return useLocalStorage<Maybe<string[]>>(
    getLocalStorageKey(versionId, listId),
    null,
  )
}
