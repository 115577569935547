export class TooManyRecordsToEdit extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'TooManyRecordsToEdit'
    Object.setPrototypeOf(this, TooManyRecordsToEdit.prototype)
  }
}

// recoverable
export class RecoverableSaveError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'RecoverableSaveError'
    Object.setPrototypeOf(this, RecoverableSaveError.prototype)
  }
}

// non-recoverable
export class NonRecoverableSaveError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NonRecoverableSaveError'
    Object.setPrototypeOf(this, NonRecoverableSaveError.prototype)
  }
}
