import { useLocation } from '@fintastic/shared/util/memory-router'
import { useEffect } from 'react'
import { listMemoryRoutesPaths } from '@fintastic/web/feature/metrics-and-lists'

const defaultInitialLocation = [listMemoryRoutesPaths.list]

export function useSettingsRouterLocation(isEditingActive: boolean) {
  const location = useLocation(defaultInitialLocation)
  const { reset } = location

  useEffect(() => {
    if (!isEditingActive) {
      reset()
    }
  }, [isEditingActive, reset])

  return location
}
