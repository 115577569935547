import { useQuery } from 'react-query'
import { getMetrics } from '../api/metrics-api'
import { metricsAndListsCacheKeys } from '../cache'

export function useLoadListOfMetrics(versionId: string, showHidden = false) {
  return useQuery(
    metricsAndListsCacheKeys.metrics(versionId, showHidden),
    async () => {
      const response = await getMetrics(versionId, showHidden)
      return response.data.result
    },
  )
}
