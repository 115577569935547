import React, { memo } from 'react'
import { StyledRoot } from './EntityTypeCellRenderer.styled'
import { resolveHistoryLogEntityTypeLabel } from '@fintastic/web/util/history'
import { WidgetListIcon, WidgetMetricIcon } from '@fintastic/shared/ui/icons'

const iconsMapping = {
  metric: (
    <WidgetMetricIcon
      sx={{
        color: '#2C45FE',
      }}
    />
  ),
  list: (
    <WidgetListIcon
      sx={{
        color: '#047D34',
      }}
    />
  ),
}

export const EntityTypeCellRenderer = memo(
  (props: { value: 'list' | 'metric' }) => (
    <StyledRoot>
      {iconsMapping[props.value]}
      {resolveHistoryLogEntityTypeLabel(props?.value)}
    </StyledRoot>
  ),
)
