import React, { useCallback, useMemo, useState } from 'react'
import { QuickSearch } from '@fintastic/shared/ui/components'
import { debounce } from 'lodash'

type QuickSearchDebouncedProps = {
  fullWidth?: boolean
  onFilter: (filter: string) => void
  totalCount?: number
  visibleCount?: number
  autoFocus?: boolean
}

export const QuickSearchDebounced: React.FC<QuickSearchDebouncedProps> = ({
  onFilter,
  fullWidth = false,
  totalCount,
  visibleCount,
  autoFocus = false,
}) => {
  const [search, setSearch] = useState('')
  const onFilterDebounced = useMemo(() => debounce(onFilter, 200), [onFilter])

  const handleChange = useCallback(
    (value: string) => {
      setSearch(value)

      onFilterDebounced.cancel()
      if (value.trim()) {
        onFilterDebounced(value)
      } else {
        onFilter(value)
      }
    },
    [setSearch, onFilterDebounced, onFilter],
  )

  return (
    <QuickSearch
      value={search}
      onChange={handleChange}
      showClear={true}
      fullWidth={fullWidth}
      totalCount={totalCount}
      visibleCount={visibleCount}
      autoFocus={autoFocus}
    />
  )
}
