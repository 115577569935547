import React from 'react'
import { AlertTitle, Alert } from '@mui/material'

export type DefinedByFormulaAlertProps = {
  description: string
}

export const DefinedByFormulaAlert: React.FC<DefinedByFormulaAlertProps> = ({
  description,
}) => (
  <Alert severity="info" icon={false} sx={{ whiteSpace: 'initial' }}>
    <AlertTitle>Defined by formula</AlertTitle>
    {description}
  </Alert>
)
