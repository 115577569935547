import { createContext, useContext } from 'react'
import { MasterDetailTab } from './useMasterDetailTabsState'
import { Maybe } from '@fintastic/shared/util/types'
import { RowNode } from 'ag-grid-community'
import type { SetGridSizeProps } from '@fintastic/shared/ui/grid-framework'

export type MasterDetailContextValue = {
  getTab: (gridId: string) => MasterDetailTab
  versionId: string
  versionLocked?: boolean
  toggleFormulaMasterDetail: (gridId: string, node?: Maybe<RowNode>) => void
  setGridSizeCallback: (tableId: string, sizes: SetGridSizeProps) => void
  onMetricOrListCreated?: (tableId: string) => void
  onCancelMetricOrListEditing?: () => void
  settingsEditingAllowed: boolean
  requestEntityDeletion: (entityId: string) => void
}

const stub = (): never => {
  throw new Error('Used out of context')
}

const defaultValue: MasterDetailContextValue = {
  getTab: () => 'grid',
  versionId: '',
  versionLocked: false,
  toggleFormulaMasterDetail: stub,
  setGridSizeCallback: stub,
  onMetricOrListCreated: stub,
  onCancelMetricOrListEditing: stub,
  settingsEditingAllowed: false,
  requestEntityDeletion: stub,
}

export const masterDetailContext =
  createContext<MasterDetailContextValue>(defaultValue)

export const { Provider: MasterDetailContextProvider } = masterDetailContext

export function useMasterDetailContext() {
  return useContext(masterDetailContext)
}
