import { useCallback, useMemo } from 'react'
import { Board, BoardFolder } from '../types'
import { useBoardUpdateMutation } from './useBoardUpdateMutation'
import { Maybe } from '@fintastic/shared/util/types'

export function useBoardMoveToFolder() {
  const updateBoardMutation = useBoardUpdateMutation()

  const callback = useCallback(
    (boardId: Board['id'], folderId: Maybe<BoardFolder['id']>) =>
      updateBoardMutation.mutate({
        boardId,
        folder_id: folderId,
      }),
    [updateBoardMutation],
  )

  return useMemo(
    () => ({
      mutationState: updateBoardMutation,
      moveBoardToFolder: callback,
    }),
    [callback, updateBoardMutation],
  )
}
