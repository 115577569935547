import { ErrorToUiMapping } from '@fintastic/shared/ui/components'
import {
  GenericReportAggregatesError,
  MissingLookupError,
  RestrictedByPermissionsError,
} from '@fintastic/web/feature/generic-report-table'
import {
  doNotSupportRequestedGranularityMessage,
  PeriodResolution,
  UnsupportedGranularityAggregationError,
} from '@fintastic/web/util/period-selector'
import {
  EmptyFolderErrorIcon,
  WarningIcon,
  CalendarIcon,
} from '@fintastic/shared/ui/icons'
import React from 'react'
import { NetworkError } from '../api/errors'

export const mapReportErrorToUi = (
  error: RestrictedByPermissionsError | GenericReportAggregatesError,
  context: { periodResolution: PeriodResolution },
): ErrorToUiMapping => {
  if (error instanceof UnsupportedGranularityAggregationError) {
    return {
      title: doNotSupportRequestedGranularityMessage(
        'report',
        context.periodResolution,
      ),
      message: 'Try selecting another time granularity',
      icon: <CalendarIcon />,
      severity: 'info',
    }
  }

  if (error instanceof RestrictedByPermissionsError) {
    return {
      title:
        "Report data doesn't exist in the selected versions, or\n" +
        'you need permissions view it',
      message: 'Try selecting another version or contact your Admin',
      icon: <EmptyFolderErrorIcon />,
      severity: 'info',
    }
  }

  if (error instanceof MissingLookupError) {
    return {
      title:
        "Report data (dimension dependencies) doesn't exist in the selected versions",
      message: 'Try selecting another version',
      icon: <EmptyFolderErrorIcon />,
      severity: 'warning',
    }
  }

  if (error instanceof NetworkError) {
    return {
      title: 'Something went wrong during the report loading',
      message: 'Try to reload the page or reach out the administrator.',
      icon: <EmptyFolderErrorIcon />,
      severity: 'warning',
    }
  }

  return {
    title: 'Something went wrong',
    message: 'Please try again or reach out the administrator.',
    icon: <WarningIcon />,
    severity: 'warning',
  }
}
