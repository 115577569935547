import { styled, Box, Typography } from '@mui/material'

export const StyledValidationStatusRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}))

export const StyledValidationStatusIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginTop: 2,
}))

export const StyledValidationStatusMessage = styled(Typography)(
  ({ theme }) => ({
    fontSize: 12,
    whiteSpace: 'pre-wrap',
  }),
)
