import { OutlinedInput, styled } from '@mui/material'

export const StyledContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`

export const StyledInput = styled(OutlinedInput)`
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  padding: 0;

  fieldset {
    border-radius: 0;
    border: none;
    padding: 0;
  }

  input[type='text'] {
    border-radius: 0;
    border: none;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: content-box;
  }
`
