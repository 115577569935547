import React, { useCallback, useMemo, useState } from 'react'
import {
  LabelTagListItem,
  useCreateLabelMutation,
  useDeleteLabelMutation,
  useLabelsListQuery,
  useUpdateLabelMutation,
} from '@fintastic/web/data-access/labels'
import { Box, CircularProgress, LinearProgress, Paper } from '@mui/material'
import { LabelItem, LabelsDropdown } from '@fintastic/shared/ui/components'
import { LabelsMenuProps } from './types'
import { AxiosError } from 'axios'

export const LabelsMenuContainer: React.FC<LabelsMenuProps> = ({
  context,
  activeLabels,
  onActiveLabelChange,
  showFetching,
  onCreated,
}) => {
  const labelsQuery = useLabelsListQuery(context, false)

  const labels = useMemo<LabelItem[]>(() => {
    if (!labelsQuery.data?.length) {
      return []
    }

    return labelsQuery.data.map<LabelItem>((item) => ({
      ...item,
      checked: activeLabels.includes(item.id),
    }))
  }, [labelsQuery.data, activeLabels])

  const [labelCreationInProgress, setLabelCreationInProgress] = useState(false)
  const createLabelMutation = useCreateLabelMutation(context)
  const handleLableCreated = useCallback(
    async (newLabel: LabelItem) => {
      try {
        setLabelCreationInProgress(true)
        const createdLabel = await createLabelMutation.mutateAsync({
          color: newLabel.color,
          title: newLabel.title,
        })

        await onActiveLabelChange(createdLabel.data.id, newLabel.checked)
        onCreated(createdLabel.data)
      } catch (ex) {
        console.error(ex)
      } finally {
        setLabelCreationInProgress(false)
      }
    },
    [createLabelMutation, onActiveLabelChange, onCreated],
  )

  const updateLabelMutation = useUpdateLabelMutation(context)
  const handleLabelUpdated = useCallback(
    async (label: LabelItem) => {
      if (!label.id) {
        throw new Error('Label with no ID gets updated')
      }

      try {
        await updateLabelMutation.mutateAsync(label as LabelTagListItem)
      } catch (ex) {
        if ((ex as AxiosError).response?.status === 409) {
          return 409
        }

        throw ex
      }

      return 201
    },
    [updateLabelMutation],
  )

  const deleteLabelMutation = useDeleteLabelMutation(context)
  const handleLabelDeleted = useCallback(
    async (label: LabelItem) => {
      if (!label.id) {
        throw new Error('Label with no ID gets deleted')
      }

      deleteLabelMutation.mutate(label as LabelTagListItem)
    },
    [deleteLabelMutation],
  )

  const handleLabelCheckChanged = useCallback(
    (label: LabelItem) => {
      if (!label.id) {
        throw new Error('Label with no ID gets check change')
      }
      onActiveLabelChange(label.id, label.checked)
    },
    [onActiveLabelChange],
  )

  const isFetching =
    showFetching ||
    labelsQuery.isFetching ||
    updateLabelMutation.isLoading ||
    deleteLabelMutation.isLoading ||
    labelCreationInProgress

  if (labelsQuery.isLoading && !labels.length) {
    return (
      <Paper elevation={1}>
        <Box display="flex" alignItems="center" p={3}>
          <CircularProgress thickness={4} size={16} />
        </Box>
      </Paper>
    )
  }

  return (
    <Paper elevation={1}>
      {isFetching ? (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
          borderRadius={8}
          overflow="hidden"
          width="100%"
          height="8px"
        >
          <LinearProgress />
        </Box>
      ) : (
        <div /> // Maintain DOM-elements number and order
      )}
      <LabelsDropdown
        labels={labels}
        onLabelUpdated={handleLabelUpdated}
        onLabelCreated={handleLableCreated}
        onLabelDeleted={handleLabelDeleted}
        // Handled by parent container
        onLabelChecked={handleLabelCheckChanged}
        onLabelUnchecked={handleLabelCheckChanged}
      />
    </Paper>
  )
}
