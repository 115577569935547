import { styled, alpha } from '@mui/material'
import React from 'react'
import type { TokenType } from './types'
import type { Interpolation } from '@mui/styled-engine'
import type { FintasticMuiTheme } from '@fintastic/shared/ui/mui-theme'
import { literalColors } from '../syntax/literal-colors'

const tokenTypeStylesMap: Record<
  TokenType,
  Interpolation<FintasticMuiTheme>
> = {
  primitive: {},
  unknownObject: {},
  function: {
    color: literalColors.function,
  },
  constant: {
    color: literalColors.constant,
  },
  metric: {
    color: literalColors.metric,
  },
  calculatedMetric: {
    color: literalColors.calculatedMetric,
  },
  list: {
    color: literalColors.list,
  },
  calculatedList: {
    color: literalColors.calculatedList,
  },
  listColumn: {
    color: literalColors.listColumn,
  },
  calculatedListColumn: {
    color: literalColors.calculatedListColumn,
  },
  dimension: {
    color: literalColors.dimension,
  },
  dimensionValue: {
    color: literalColors.dimensionValue,
  },
}

export const StyledTokenChipRoot = styled(
  'span' as unknown as React.FC<
    {
      tokenType: TokenType
      noPaddings?: boolean
      error?: boolean
      hasTooltip?: boolean
      children: React.ReactNode
    } & React.HTMLProps<HTMLSpanElement>
  >,
  {
    shouldForwardProp: (propName: PropertyKey) =>
      propName !== 'tokenType' &&
      propName !== 'noPaddings' &&
      propName !== 'error',
  },
)(({ theme, tokenType, noPaddings, error, hasTooltip = false }) => {
  const looksLikePrimitive =
    tokenType === 'primitive' ||
    tokenType === 'unknownObject' ||
    tokenType === 'constant'

  const styles: Interpolation<FintasticMuiTheme> = {
    padding: '3px 8px',

    fontSize: '14px',
    lineHeight: '1',
    whiteSpace: 'pre-wrap',

    color: '#263646',
    background: 'transparent',
    borderRadius: '4px',
    transition: theme.transitions.create('background', {
      duration: theme.transitions.duration.shorter,
    }),
  }

  if (looksLikePrimitive) {
    styles.paddingLeft = '2px'
    styles.paddingRight = '2px'
  } else {
    styles.background = '#FAF7F5'
    if (hasTooltip) {
      styles['&:hover'] = {
        background: tokenType === 'metric' ? '#F5F5F5' : '#F7F1EE',
      }
    }
  }

  if (noPaddings) {
    styles.paddingLeft = 0
    styles.paddingRight = 0
  }

  if (error) {
    styles.textDecoration = `underline ${theme.palette.error.main} dashed`
    styles.borderRadius = 0
    if (looksLikePrimitive) {
      styles['&:hover'] = {
        background: alpha(theme.palette.error.main, 0.03),
      }
    }
  }

  styles['&'] = tokenTypeStylesMap[tokenType]

  return styles
})
