import { AggregatedTimeCellEditorContextValue } from './cell-editor-context'
import { useMemo, useState } from 'react'
import { MetricDataFillStrategy } from '@fintastic/web/util/metric-data-editing'

export function useAggregatedTimeCellEditing() {
  const [action, setAction] = useState<MetricDataFillStrategy>('duplicate')

  return useMemo<AggregatedTimeCellEditorContextValue>(
    () => ({
      action,
      onChangeAction: setAction,
    }),
    [action, setAction],
  )
}
