import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { useLoadListPermissions } from '@fintastic/web/data-access/metrics-and-lists'
import { useMemo } from 'react'
import { ListPermissionsApi, makeApi } from './makeApi'
import { Maybe } from '@fintastic/shared/util/types'

export const useListPermissions = (
  versionId: string,
  listId: string,
  deps: {
    // it's easier to pass this dependency as a param rather then to get it from the cache,
    // because it will require period selector context
    isInputList: Maybe<boolean>
  },
) => {
  const isPowerUser = !!useRoleLevelAccess('power_user')

  const permissionsQuery = useLoadListPermissions(
    isPowerUser || !deps.isInputList
      ? null
      : {
          versionId,
          listId,
        },
  )

  return useMemo<ListPermissionsApi>(
    () =>
      makeApi({
        permissionsQuery,
        isPowerUser,
        isInputList: deps.isInputList,
      }),
    [permissionsQuery, isPowerUser, deps.isInputList],
  )
}
