import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  EditListColumnsDataCompressedIntent,
  EditListColumnsDataIntent,
  MetricDataFillStrategy,
} from '../../types'
import { DimensionId, DimensionValueId } from '@fintastic/web/util/dimensions'
import { createEditListColumnsDataIntent } from './consturctors'
import {
  MetricDataValue,
  ParsedColumnId,
} from '@fintastic/web/util/metrics-and-lists'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { tryCompress } from './tryCompress'
import { uniq } from 'lodash'
import { DimensionEntityDefinitionWrapper } from '@fintastic/web/util/versions'

type ColumnId = string

export class MutableEditListColumnsDataIntentWrapper
  implements MutableADTWrapper<EditListColumnsDataIntent>
{
  _rawData: EditListColumnsDataIntent

  protected columnIndexes: Record<ColumnId, number> = {}

  static fromConstructor(
    dataFillStrategy: Maybe<MetricDataFillStrategy> = null,
  ): MutableEditListColumnsDataIntentWrapper {
    return new MutableEditListColumnsDataIntentWrapper(
      createEditListColumnsDataIntent(dataFillStrategy),
    )
  }

  toCompressed(): Maybe<EditListColumnsDataCompressedIntent> {
    return tryCompress(this.unwrap())
  }

  constructor(rawIntent: EditListColumnsDataIntent) {
    this._rawData = rawIntent
  }

  unwrap(): EditListColumnsDataIntent {
    return this._rawData
  }

  get dataFillStrategy() {
    return this._rawData.fill_strategy
  }

  protected addEmptyColumn(columnName: string) {
    this._rawData.columns.push({
      column_name: columnName,
      indexes: [],
      dimensions: [],
      values: [],
    })
  }

  addChange(
    columnId: string,
    dimensions: Record<DimensionId, DimensionValueId>,
    value: MetricDataValue,
  ) {
    const parsedColumnId = ParsedColumnId.fromString(columnId)
    if (parsedColumnId === null) {
      throw new Error('wrong format of the column id')
    }

    if (this.columnIndexes[columnId] === undefined) {
      this.addEmptyColumn(parsedColumnId.columnIdWithoutPrefix)
      this.columnIndexes[columnId] = this._rawData.columns.length - 1
    }

    const orderedDims = Object.keys(dimensions).sort()
    const rawColumnReference =
      this._rawData.columns[this.columnIndexes[columnId]]

    if (rawColumnReference.indexes.length === 0 && orderedDims.length > 0) {
      rawColumnReference.indexes = orderedDims
      rawColumnReference.dimensions = orderedDims.map(() => [])
    } else if (rawColumnReference.indexes.join(';') !== orderedDims.join(';')) {
      throw new Error('dimensions mismatch')
    }

    rawColumnReference.indexes.forEach((dimId, dimIndex) => {
      const dimValueId = dimensions[dimId]
      rawColumnReference.dimensions[dimIndex].push(dimValueId)
    })

    rawColumnReference.values.push(value)
  }

  // @todo add tests
  changeDataFillStrategy(strategy: Maybe<MetricDataFillStrategy>) {
    const columnsAmount = uniq(
      this._rawData.columns.map((column) => column.column_name),
    ).length
    if (columnsAmount > 1) {
      throw new Error(
        "Can't apply fill strategy in case of different columns per one intent",
      )
    }
    this._rawData.fill_strategy = strategy
  }

  // @todo add tests
  getFirstColumnWidthTimeDimension(
    dimensions: DimensionEntityDefinitionWrapper[],
  ) {
    return toMaybe(
      this._rawData.columns.find((column) =>
        column.indexes.some((dimId) =>
          dimensions.find(
            (dimensionEntity) =>
              dimensionEntity.id === dimId &&
              dimensionEntity.dimension.type === 'Time',
          ),
        ),
      ),
    )
  }

  hasTimeDimension(dimensions: DimensionEntityDefinitionWrapper[]): boolean {
    return this.getFirstColumnWidthTimeDimension(dimensions) !== null
  }
}
