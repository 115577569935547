import { SelectedCellsForAggregationProcessValueCallback } from '@fintastic/web/feature/selected-cell-aggregation'
import { SelectedCell } from '@fintastic/web/util/selected-cell-aggregation'
import { Maybe } from '@fintastic/shared/util/types'
import {
  BLANK_VALUE,
  BlankOrMaskedValue,
  isRawBlankValue,
  isRawMaskedValue,
  MASKED_VALUE,
} from '@fintastic/web/util/blanks-and-masked'
import {
  createFieldKey,
  destructureDiffField,
  isDiffFieldKey,
  MetricGridRow,
} from '../data-to-table-rows'
import { isObject } from 'lodash'
import { Column } from 'ag-grid-community'
import { convertDiffToSymbolIfNeeded, metricDiffValueGetter } from '../diff'
import { AgGridNumericCellEditorProps } from '@fintastic/shared/ui/ag-grid'

export const extractSelectedCellFromEvent: SelectedCellsForAggregationProcessValueCallback<
  MetricGridRow
> = (v, node, column): Maybe<SelectedCell> => {
  if (typeof v === 'number') {
    return {
      format: getFormatFromColumn(column),
      value: v,
      decimals: getDecimalsFromColumn(column),
    }
  }

  if (v === null) {
    return {
      format: 'string',
      value: '',
    }
  }

  const colId = column.getColId()
  const value = v?.[colId]

  if (typeof value === 'undefined') {
    if (isDiffFieldKey(colId)) {
      const [[diffA, diffB], metricId, period] = destructureDiffField(colId)

      const diffValue = metricDiffValueGetter(
        createFieldKey(diffA, metricId, period),
        createFieldKey(diffB, metricId, period),
        'subtract',
        { data: v },
      )

      return {
        format: getFormatFromColumn(column),
        value: convertDiffToSymbolIfNeeded(diffValue),
        decimals: getDecimalsFromColumn(column),
      }
    }

    return {
      format: 'string',
      value: '',
    }
  }

  if (typeof value === 'string') {
    return {
      format: 'string',
      value,
    }
  }

  if (typeof value === 'boolean') {
    return {
      format: 'string',
      value: String(value),
    }
  }

  if (isObject(value)) {
    console.warn('extractSelectedCellFromEvent does not support objects')
    return null
  }

  // Remove this convertation when we actually keep blank and maksed values as symbols
  let convertedValue = value as BlankOrMaskedValue

  if (isRawBlankValue(value)) {
    convertedValue = BLANK_VALUE
  }

  if (isRawMaskedValue(value)) {
    convertedValue = MASKED_VALUE
  }

  return {
    value: convertedValue,
    format: getFormatFromColumn(column),
    decimals: getDecimalsFromColumn(column),
  }
}

const getFormatFromColumn = (column: Column): 'percent' | 'number' => {
  const dataType = (
    column?.getColDef?.()?.cellEditorParams as AgGridNumericCellEditorProps
  )?.dataType
  return dataType === 'percentage' || dataType === 'percentage_integer'
    ? 'percent'
    : 'number'
}

const getDecimalsFromColumn = (column: Column): number => {
  const cellEditorParams = column?.getColDef?.()
    ?.cellEditorParams as AgGridNumericCellEditorProps

  if (!cellEditorParams?.displaySettings) {
    return 2
  }

  return cellEditorParams.displaySettings.formatting.decimal_places
}
