import { getHistoryLogEntries, GetHistoryLogEntriesParams } from './api'
import { QueryClient, QueryKey, useQuery } from 'react-query'
import {
  HistoryLogEntry,
  HistoryLogLevel,
  mapHistoryLogEntryToType,
} from '@fintastic/web/util/history'
import { cloneDeep, isEqual } from 'lodash'

const HISTORY_LOG_QUERY_KEY_PREFIX = 'historyLog'

const makeHistoryLogQueryKey = (
  params: GetHistoryLogEntriesParams,
  level: HistoryLogLevel,
): QueryKey => [HISTORY_LOG_QUERY_KEY_PREFIX, level, JSON.stringify(params)]

export const useLoadHistoryLog = (
  params: GetHistoryLogEntriesParams,
  level: HistoryLogLevel,
  enabled: boolean,
) =>
  useQuery(
    makeHistoryLogQueryKey(params, level),
    async () => {
      // @todo adjust when more actions are supported
      const alteredParams = cloneDeep(params)
      if (
        !alteredParams.filter.action ||
        alteredParams.filter.action?.length === 0 ||
        !alteredParams.filter.entity_type ||
        alteredParams.filter.entity_type?.length === 0
      ) {
        return {
          total: 0,
          result: [],
        }
      }

      const response = await getHistoryLogEntries(alteredParams)
      const entries: HistoryLogEntry[] = []
      const unsupportedEntries: Array<Omit<HistoryLogEntry, '_type'>> = []

      // keep in the list only entries with supported entry type
      response.data.result.forEach((entry) => {
        const type = mapHistoryLogEntryToType(entry)
        if (type === null) {
          unsupportedEntries.push(entry)
          return
        }
        const entryWithType = entry as HistoryLogEntry
        entryWithType._type = type
        entries.push(entryWithType)
      })

      if (unsupportedEntries.length) {
        console.error(
          'History Log API: Received unsupported entries',
          unsupportedEntries,
        )
      }

      return {
        total: response.data.total,
        result: entries,
      }
    },
    {
      enabled,
      cacheTime: 1000 * 60 * 60,
      refetchInterval: Infinity,
      keepPreviousData: true,
    },
  )

export const invalidateHistoryLog = async (
  qc: QueryClient,
  filters:
    | {
        level: Extract<HistoryLogLevel, 'version'>
        versionId: string[]
      }
    | {
        level: Extract<HistoryLogLevel, 'entity'>
        versionId: string[]
        entityId: string[]
      },
) =>
  qc.invalidateQueries({
    predicate: (query) => {
      if (
        !(
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === HISTORY_LOG_QUERY_KEY_PREFIX
        )
      ) {
        return false
      }

      if (filters.level !== query.queryKey[1]) {
        return false
      }

      const paramsInKey = JSON.parse(
        query.queryKey[2],
      ) as GetHistoryLogEntriesParams

      if (filters.level === 'version') {
        return (
          isEqual(paramsInKey.filter.parent_entity_type, ['version']) &&
          filters.versionId.includes(
            paramsInKey.filter.parent_entity_id?.[0] || '',
          )
        )
      }

      if (filters.level === 'entity') {
        return (
          isEqual(paramsInKey.filter.parent_entity_type, ['version']) &&
          filters.versionId.includes(
            paramsInKey.filter.parent_entity_id?.[0] || '',
          ) &&
          filters.entityId.includes(paramsInKey.filter.entity_id?.[0] || '')
        )
      }

      return false
    },
  })
