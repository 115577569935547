import type { ICellRendererParams } from 'ag-grid-community'
import { Tag, Tags } from '@fintastic/shared/ui/components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LabelTagListItem } from '@fintastic/web/data-access/labels'
import { compact } from 'lodash'
import { Box, ClickAwayListener } from '@mui/material'

export const ReadonlyLabelsCellRenderer = (
  params: ICellRendererParams<any> & {
    labels: LabelTagListItem[]
  },
) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const prevRowHeight = useRef(40)

  const tags = useMemo<Tag[]>(() => {
    // @todo: make it more generic (custom field name/data getter)
    const localLabelIds = params?.data?.label_ids || []
    if (!params.labels.length || !params?.data?.label_ids.length) {
      return []
    }

    return compact(params.labels?.filter((i) => localLabelIds.includes(i.id)))
  }, [params])

  const [focused, setFocused] = useState(false)

  useEffect(() => {
    const wrapperHeight = wrapperRef.current?.clientHeight || 40
    let nextRowHeight = focused ? wrapperHeight : 40

    if (nextRowHeight < 40) {
      nextRowHeight = 40
    }

    if (prevRowHeight.current === nextRowHeight) {
      return
    }

    params.node.setRowHeight(nextRowHeight)
    params.api.onRowHeightChanged()
    prevRowHeight.current = nextRowHeight
  }, [focused, params.api, params.node, tags])

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFocused(false)
      }}
    >
      <Box
        ref={wrapperRef}
        py={1}
        sx={{ width: '100%' }}
        onClick={() => setFocused(true)}
      >
        <Tags
          tags={tags}
          singleline={!focused}
          limit={!focused}
          showAddButton={false}
        />
      </Box>
    </ClickAwayListener>
  )
}
