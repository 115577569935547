import React from 'react'
import {
  PanelToolbarIconButton,
  PanelToolbarIconButtonProps,
} from '../PanelToolbarIconButton'
import { CollapseIcon, ExpandIcon } from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

export type CollapseExpandButtonProps = Omit<
  PanelToolbarIconButtonProps,
  'children'
> & {
  isCollapsed?: boolean
  iconProps?: SvgIconProps
}

export const CollapseExpandButton: React.FC<CollapseExpandButtonProps> = ({
  isCollapsed,
  iconProps,
  ...restProps
}) => (
  <PanelToolbarIconButton
    title={isCollapsed ? 'Expand' : 'Collapse'}
    {...restProps}
  >
    {isCollapsed ? (
      <ExpandIcon data-testid="expand-icon" {...iconProps} />
    ) : (
      <CollapseIcon data-testid="collapse-icon" {...iconProps} />
    )}
  </PanelToolbarIconButton>
)
