import { useEffect, useRef } from 'react'
import { useReadDeeplinkValue } from '../useReadDeeplinkValue'

export const useScrollToCurrentWidgetEffect = (
  currentWidgetId: string,
  domNodeRef: React.RefObject<HTMLElement>,
) => {
  const widgetId = useReadDeeplinkValue<string>('widget')
  const currentWidgetIdRef = useRef(currentWidgetId)
  currentWidgetIdRef.current = currentWidgetId

  const lastScrolledWidgetRef = useRef('')

  useEffect(() => {
    if (!domNodeRef.current) {
      return
    }

    if (currentWidgetIdRef.current !== widgetId) {
      return
    }

    if (lastScrolledWidgetRef.current === widgetId) {
      return
    }

    const boundingClientRect = domNodeRef.current.getBoundingClientRect()

    let scrollHappen = false

    // scroll down
    if (boundingClientRect.top > window.scrollY + window.innerHeight) {
      domNodeRef.current.scrollIntoView({
        behavior: 'smooth',
      })
      scrollHappen = true
    } else if (
      window.scrollY >
      boundingClientRect.top + boundingClientRect.height
    ) {
      domNodeRef.current.scrollIntoView({
        behavior: 'smooth',
      })
      scrollHappen = true
    }

    if (scrollHappen) {
      // In 5 sec, let user scroll to current widget as well
      setTimeout(() => {
        lastScrolledWidgetRef.current = ''
      }, 5000)
    }
  }, [widgetId, domNodeRef])
}
