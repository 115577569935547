import React, { useCallback } from 'react'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { PanelToolbarIconButton } from '@fintastic/shared/ui/panel-framework'
import { HistoryIcon } from '@fintastic/shared/ui/icons'

export const EntityLevelHistoryButton: React.FC<{
  versionId: string
  entityId: string
  disabled: boolean
}> = ({ versionId, entityId, disabled }) => {
  const historyApi = useHistoryLogGlobalApi()
  const currentEntityOpened =
    historyApi?.state.isOpen &&
    historyApi?.state.level === 'entity' &&
    historyApi?.state.versionId === versionId &&
    historyApi?.state.entityId === entityId

  const handleOpen = useCallback(() => {
    if (currentEntityOpened) {
      historyApi?.close()
      return
    }

    historyApi?.openOnEntityLevel({
      versionId,
      entityId,
    })
  }, [currentEntityOpened, entityId, historyApi, versionId])

  return (
    <PanelToolbarIconButton
      title={currentEntityOpened ? 'Hide history' : 'Show history'}
      onClick={handleOpen}
      color={currentEntityOpened ? 'primary' : 'secondary'}
      sx={{
        background: currentEntityOpened
          ? 'rgba(255, 121, 51, 0.08)'
          : 'transparent',
      }}
      disabled={disabled}
    >
      <HistoryIcon fontSize="medium" />
    </PanelToolbarIconButton>
  )
}
