import React, { useCallback, useMemo, useState } from 'react'
import {
  StyledChartTab,
  StyledChartTabContent,
  StyledChartTabs,
  StyledChartTabsRoot,
} from './ChartTabs.styled'
import { ChartTabProp } from './ChartTab'
import { IconButton, Tooltip } from '@mui/material'

export type ChartTabsProps = {
  children:
    | React.ReactElement<ChartTabProp>[]
    | React.ReactElement<ChartTabProp>
}

const filterValidTabs = (tabProp: ChartTabProp) =>
  !!tabProp.id && !!tabProp.icon && !!tabProp.children

export const ChartTabs: React.FC<ChartTabsProps> = (props) => {
  const [currentTabId, setCurrentTabId] = useState('')

  const validTabs = useMemo(
    () =>
      (
        React.Children.map(
          props.children,
          (child: React.ReactElement<ChartTabProp>) => child.props,
        ) || []
      ).filter(filterValidTabs),
    [props.children],
  )

  const handleToggleActive = useCallback(
    (id: string) => {
      if (!currentTabId) {
        setCurrentTabId(() => id)
        return
      }
      if (currentTabId === id) {
        setCurrentTabId(() => '')
      } else {
        setCurrentTabId(() => id)
      }
    },
    [currentTabId],
  )

  const currentTabContent = useMemo(
    () => validTabs.find((tab) => tab.id === currentTabId && !tab.disabled),
    [currentTabId, validTabs],
  )

  if (!validTabs || validTabs.length === 0) {
    return null
  }

  return (
    <StyledChartTabsRoot data-testid="widget-tabs">
      {currentTabContent && (
        <StyledChartTabContent>
          {currentTabContent.children}
        </StyledChartTabContent>
      )}

      <StyledChartTabs>
        {validTabs.map((tab) => (
          <StyledChartTab
            key={tab.id}
            disabled={tab.disabled}
            highlight={tab.highlight}
            active={tab.id === currentTabId && tab.disabled !== true}
          >
            <Tooltip
              title={tab.title}
              placement="left"
              enterDelay={1200}
              disableInteractive
            >
              <IconButton onClick={() => handleToggleActive(tab.id)}>
                {tab.icon}
              </IconButton>
            </Tooltip>
          </StyledChartTab>
        ))}
      </StyledChartTabs>
    </StyledChartTabsRoot>
  )
}
