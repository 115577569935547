import React from 'react'
import { StyledModalCloseButtonRoot } from './ModalCloseButton.styled'
import { IconButtonProps } from '@mui/material'
import { Close } from '@mui/icons-material'

export type ModalCloseButtonProps = Omit<IconButtonProps, 'children'>

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = (props) => (
  <StyledModalCloseButtonRoot {...props} size="small">
    <Close />
  </StyledModalCloseButtonRoot>
)
