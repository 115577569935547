import { ChangeWidgetDialogParams } from '@fintastic/web/feature/boards'
import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useTextFieldState } from '@fintastic/shared/ui/form-framework'

export type MetricWidgetSettings = {
  metricId?: Maybe<string>
  handleSelectMetric: (
    metricId: Maybe<string>,
    metricName: Maybe<string>,
  ) => void
  changed: boolean
}

export const useMetricWidgetSettings = (
  initialParams: Partial<ChangeWidgetDialogParams>,
  displayNameFieldState: ReturnType<typeof useTextFieldState>,
): MetricWidgetSettings => {
  const [metricId, setMetricId] = useState<Maybe<string>>(
    initialParams.id || null,
  )

  const [changed, setChanged] = useState(false)

  const handleSelectMetric = useCallback(
    (metricId: Maybe<string>, metricName: Maybe<string>) => {
      setMetricId(metricId)
      if (
        !displayNameFieldState.touched ||
        !displayNameFieldState.trimmedValue
      ) {
        displayNameFieldState.assignValue(metricName || '')
      }
      setChanged(() => true)
    },
    [setMetricId, displayNameFieldState, setChanged],
  )
  return useMemo<MetricWidgetSettings>(
    () => ({
      metricId,
      handleSelectMetric,
      changed,
    }),
    [metricId, handleSelectMetric, changed],
  )
}
