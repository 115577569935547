import React, { Ref, useCallback, useMemo } from 'react'
import { Option } from './types'
import { Maybe } from '@fintastic/shared/util/types'
import { eqOption } from './utils'
import { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete'
import {
  PopperProps,
  TextField,
  Typography,
  Autocomplete as UnstyledAutocomplete,
} from '@mui/material'
import {
  StyledAutocomplete,
  StyledPopper,
} from './AutocompleteAsCellEditor.styled'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

export type AutocompleteAsCellEditorProps<T extends string> = {
  options: Option<T>[]
  value: Maybe<Option<T>>
  onChange: (value: T) => void
  inputRef: Ref<HTMLInputElement>
  minWidth: number
}

export const AutocompleteAsCellEditor = <T extends string>({
  options,
  inputRef,
  value,
  onChange,
  minWidth,
}: AutocompleteAsCellEditorProps<T>): JSX.Element => {
  const longestOptionLabel = useMemo(
    () =>
      options.reduce(
        (longestLabel, option) =>
          option.label.length > longestLabel.length
            ? option.label
            : longestLabel,
        '',
      ),
    [options],
  )

  const handleChange = useCallback(
    (
      _: React.SyntheticEvent,
      value: AutocompleteValue<Option<T>, false, true, false>,
    ) => {
      onChange(value.value)
    },
    [onChange],
  )

  const Autocomplete = StyledAutocomplete as typeof UnstyledAutocomplete<
    Option<T>,
    false,
    true,
    false
  >

  return (
    <Autocomplete
      size="small"
      value={value || undefined}
      ref={inputRef}
      fullWidth
      autoSelect={false}
      disableClearable={true}
      disableListWrap
      isOptionEqualToValue={eqOption}
      options={options}
      multiple={false}
      onChange={handleChange}
      componentsProps={{
        popper: popperProps,
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={
        ListboxComponent as unknown as React.JSXElementConstructor<
          React.HTMLAttributes<HTMLElement>
        >
      }
      ListboxProps={
        {
          minWidth,
          longestOptionLabel,
        } as React.HTMLAttributes<HTMLElement>
      }
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          variant="outlined"
          onFocus={(e) => e.target?.select()}
          {...params}
        />
      )}
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
    />
  )
}

const popperProps: Partial<PopperProps> = {
  style: { minWidth: 'fit-content' },
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'preventOverflow',
      enabled: true,
    },
    {
      name: 'sameWidth',
      enabled: true,
      fn: ({ state }) => {
        state.styles['popper'].width = `${state.rects.reference.width}px`
      },
      phase: 'beforeWrite',
      requires: ['computeStyles'],
    },
  ],
}

type CompatibleWithReactWindowOption<T extends string> = [
  React.HTMLAttributes<HTMLLIElement>,
  Option<T>,
  number,
]

function renderRow<T extends string>({
  data,
  index,
  style,
}: ListChildComponentProps<CompatibleWithReactWindowOption<T>[]>) {
  const [props, option] = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8,
  }

  return (
    <Typography component="li" noWrap style={inlineStyle} {...props}>
      {option.label}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & {
    minWidth: number
    longestOptionLabel: string
  }
>(function ListboxComponent(props, ref) {
  const { children, minWidth, longestOptionLabel, ...other } = props
  const options = children as CompatibleWithReactWindowOption<string>[]

  const itemCount = options.length
  const minItemSize = 38

  const width = useMemo(() => {
    const longestOptionLabelRuler = document.createElement('div')
    longestOptionLabelRuler.innerText = Array(longestOptionLabel.length)
      .fill('W')
      .join('')
    longestOptionLabelRuler.style.width = 'fit-content'
    longestOptionLabelRuler.style.whiteSpace = 'no-wrap'
    longestOptionLabelRuler.style.fontFamily = 'Ubuntu'
    longestOptionLabelRuler.style.fontSize = '1rem'
    const container = document.createElement('div')
    container.style.overflow = 'hidden'
    container.style.height = '0'
    container.style.width = '0'
    container.style.visibility = 'none'
    document.body.appendChild(container)
    container.appendChild(longestOptionLabelRuler)
    const textWidthInPixels = longestOptionLabelRuler.clientWidth
    const paddingHorizontal = 16
    const scrollbarWidth = 14
    const additionalSpareSpace = 10
    const totalWidth =
      textWidthInPixels +
      paddingHorizontal * 2 +
      scrollbarWidth +
      additionalSpareSpace
    const maxWidth = 500
    container.remove()
    return Math.max(Math.min(totalWidth, maxWidth), minWidth)
  }, [longestOptionLabel, minWidth])

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={options}
          height={minItemSize * Math.min(itemCount, 8) + 2 * 8}
          width={width}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={minItemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
