import React, { useMemo } from 'react'
import {
  useSelectedCellAggregationContext,
  aggregateSelectedCells,
  SelectedCellAggregationType,
  getDefaultAggregation,
} from '@fintastic/web/util/selected-cell-aggregation'
import { SelectedCellAggregationButton } from './components/SelectedCellAggregationButton'
import { Maybe } from '@fintastic/shared/util/types'

export const SelectedCellAggregation: React.FC = () => {
  const context = useSelectedCellAggregationContext()

  const aggregatedValues = useMemo(
    () => aggregateSelectedCells(context.selectedCells),
    [context.selectedCells],
  )

  const effectiveAggregation = useMemo<
    Maybe<SelectedCellAggregationType>
  >(() => {
    if (context.aggregation) {
      if (aggregatedValues.find((i) => i.type === context.aggregation)) {
        return context.aggregation
      }
    }

    return getDefaultAggregation(context.selectedCells)
  }, [aggregatedValues, context.aggregation, context.selectedCells])

  if (!effectiveAggregation) {
    return null
  }

  return (
    <SelectedCellAggregationButton
      aggregatedValues={aggregatedValues}
      aggregation={effectiveAggregation}
      onChangeAggregation={context.setAggregation}
    />
  )
}
