import { selectIsBottomDrawerOpened, setIsBottomDrawerOpened } from './index'
import { useDispatch, useSelector } from 'react-redux'
import type { SetIsBottomDrawerOpenedPayload } from './reducers'
import { useCallback } from 'react'

export function useLayoutStateIsBottomDrawerOpened(): [
  boolean,
  (payload: SetIsBottomDrawerOpenedPayload) => void,
] {
  const dispatch = useDispatch()
  const isOpened = useSelector(selectIsBottomDrawerOpened)

  const setHandler = useCallback(
    (payload: SetIsBottomDrawerOpenedPayload) => {
      dispatch(setIsBottomDrawerOpened(payload))
    },
    [dispatch],
  )

  return [isOpened, setHandler]
}
