import {
  CommentEventIn,
  CommentEventOut,
  CommentsThreadsBulkItem,
} from '../types'
import { useMutation, useQueryClient } from 'react-query'
import {
  threadEventsQueryKey,
  threadsBulkByPageQueryKey,
} from '../utils/query-key'
import { AxiosError, AxiosResponse } from 'axios'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { usePostEventWithMentions } from '../utils/usePostEventWithMentions'
import { compact } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'
import { newBulkComment } from './utils'

export const usePostEvent = (threadId: number, pageKey: string) => {
  const user = useAuthUserInfo()
  const queryClient = useQueryClient()
  const postEventWithMentions = usePostEventWithMentions()

  const result = useMutation<
    AxiosResponse<CommentEventOut>,
    AxiosError,
    CommentEventIn,
    {
      previousThreadsBulk: CommentsThreadsBulkItem[]
    }
  >(
    threadEventsQueryKey(threadId),
    (newEvent) => postEventWithMentions(threadId, newEvent),
    {
      onMutate: async (newEvent) => {
        await queryClient.cancelQueries(threadEventsQueryKey(threadId))

        const previousThreadsBulk =
          queryClient.getQueryData<CommentsThreadsBulkItem[]>(
            threadsBulkByPageQueryKey(pageKey),
          ) || []

        queryClient.setQueryData<CommentsThreadsBulkItem[]>(
          threadsBulkByPageQueryKey(pageKey),
          (prev) =>
            compact(
              (prev || []).map<Maybe<CommentsThreadsBulkItem>>((thread) => {
                if (thread.id !== threadId) {
                  return thread
                }

                if (newEvent.type === 'resolve') {
                  return {
                    ...thread,
                    is_resolved: true,
                  }
                }

                if (newEvent.type === 'delete_thread') {
                  return null
                }

                return {
                  ...thread,
                  comments: [
                    ...thread.comments,
                    newBulkComment(newEvent.body ?? '', user?.email ?? ''),
                  ],
                }
              }),
            ),
        )

        return { previousThreadsBulk }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData(
          threadsBulkByPageQueryKey(pageKey),
          ctx?.previousThreadsBulk || [],
        )
        console.error(err)
        toast.error(`Failed to save an event. Error: ${err?.code || 'unknown'}`)
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: threadsBulkByPageQueryKey(pageKey),
        })
      },
    },
  )

  return result
}
