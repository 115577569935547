import React, { useMemo } from 'react'
import {
  ContextMenuButton,
  ContextMenuDivider,
  ContextMenuItem,
  ContextMenuSubmenu,
} from '../../../index'

export type ContextMenuTreeProps = {
  menuItems: ContextMenuItem[]
  hideContextMenu: () => void
}

export const ContextMenuTree: React.FC<ContextMenuTreeProps> = (props) => {
  const { menuItems, hideContextMenu } = props

  const renderDefs = useMemo(() => {
    const defs: Array<{
      component: React.FC
      props?: Record<string, unknown>
      id: string
    }> = []

    menuItems.forEach((item) => {
      if (item.type === 'divider') {
        defs.push({
          id: item.id,
          component: ContextMenuDivider,
        })
      }

      if (item.type === 'button') {
        defs.push({
          id: item.id,
          component: item.component
            ? (item.component as React.FC)
            : (ContextMenuButton as React.FC),
          props: {
            contextMenuItem: item,
            ...item.componentProps,
            hideContextMenu,
          },
        })
      }

      if (item.type === 'submenu') {
        defs.push({
          id: item.id,
          component: item.component
            ? (item.component as React.FC)
            : (ContextMenuSubmenu as React.FC),
          props: {
            contextMenuItem: item,
            ...item.componentProps,
            hideContextMenu,
          },
        })
      }
    })

    return defs
  }, [menuItems, hideContextMenu])

  return (
    <>
      {renderDefs.map((def) => (
        <def.component {...def.props} key={def.id} />
      ))}
    </>
  )
}
