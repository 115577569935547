// if caret position = 0, it means that it stands before the char with index 0
type CaretPositionIndex = number
type CharIndex = number

const startOfTheLine = (
  s: string,
  _caretPosition: CaretPositionIndex,
): CharIndex => {
  const caretPosition = Math.min(_caretPosition, s.length - 1)

  for (let i = caretPosition; i > 0; i -= 1) {
    // skip all non-newline chars
    if (s.at(i) !== '\n') {
      continue
    }
    // skip newline that is exact after the caret
    if (i === caretPosition) {
      continue
    }
    // if it's a last newline on the string - then the line starts right before the \n
    if (i === s.length - 1) {
      return i
    }
    // usual case - line starts after \n
    return i + 1
  }
  return 0
}

const insertAt = (s: string, index: CharIndex, inserting: string): string =>
  `${s.slice(0, index)}${inserting}${s.slice(index)}`

const removeAt = (s: string, index: CharIndex, removing: string): string => {
  const prefix = s.slice(0, index)
  let postfix = s.slice(index)
  if (postfix.startsWith(removing)) {
    postfix = postfix.replace(removing, '')
  }
  return `${prefix}${postfix}`
}

export const addIndentation = (
  s: string,
  caretPosition: CaretPositionIndex,
  indentation = '\t',
): string => insertAt(s, startOfTheLine(s, caretPosition), indentation)

export const removeIndentation = (
  s: string,
  caretPosition: CaretPositionIndex,
  indentation = '\t',
): string => removeAt(s, startOfTheLine(s, caretPosition), indentation)
