import { WrappedVersionEntityDefinition } from '@fintastic/web/util/versions'
import { CalculateIntent } from '@fintastic/web/data-access/calc'

export const entityTypeToTypeLabel: Partial<
  Record<WrappedVersionEntityDefinition['type'], string>
> = {
  metric: 'Metric',
  list: 'List',
  column: 'Column',
}

export const entityTypeToDeleteAction: Partial<
  Record<WrappedVersionEntityDefinition['type'], CalculateIntent['action']>
> = {
  metric: 'delete:metric',
  list: 'delete:list',
  column: 'delete:metric_in_list',
}
