import { HandleMetricUpdateCallbackParams } from '../../types'
import { DimensionId } from '@fintastic/web/util/dimensions'
import {
  MetricDataFillStrategy,
  MutableEditMetricDataIntentWrapper,
} from '@fintastic/web/util/metric-data-editing'
import { Maybe } from '@fintastic/shared/util/types'

type VersionId = string

type VersionsToIntentsMap = Record<
  VersionId,
  MutableEditMetricDataIntentWrapper
>

export const changesToIntents = (
  changes: HandleMetricUpdateCallbackParams[],
  metricDimensions: DimensionId[],
  dataFillStrategy: Maybe<MetricDataFillStrategy> = null,
): VersionsToIntentsMap => {
  const intentsPerVersion: VersionsToIntentsMap = {}

  for (let i = 0; i < changes.length; i++) {
    const changesForVersion = changes[i]
    if (intentsPerVersion[changesForVersion.versionId] === undefined) {
      intentsPerVersion[changesForVersion.versionId] =
        MutableEditMetricDataIntentWrapper.fromConstructor(
          metricDimensions,
          dataFillStrategy,
        )
    }
    const intent = intentsPerVersion[changesForVersion.versionId]

    for (let j = 0; j < changesForVersion.changes.length; j++) {
      const change = changesForVersion.changes[j]
      intent.addChange(
        metricDimensions.map((dimId) => change.dimensions[dimId]),
        change.value,
      )
    }
  }

  return intentsPerVersion
}
