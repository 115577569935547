import { useCallback, useMemo, useState } from 'react'

export function useFormula() {
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const hide = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggle = useCallback(() => {
    setIsOpen((v) => !v)
  }, [])

  return useMemo(
    () => ({
      isOpen,
      open,
      hide,
      toggle,
    }),
    [hide, isOpen, open, toggle],
  )
}
