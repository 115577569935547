import { createTheme } from '@mui/material'
import type { StyleKit } from './styleKit'
import { styleKit } from './styleKit'
import { themeComponentsOverride } from './components-override'

declare module '@mui/material/styles' {
  interface Theme {
    spacingFromPixels: (pixels: number) => string
    remFromPixels: (pixels: number) => string
    styleKit: StyleKit
  }

  interface Palette {
    border: {
      main: string
    }
  }

  interface PaletteOptions {
    border?: {
      main?: string
    }
  }
}

export type FintasticMuiTheme = typeof theme

export const theme = createTheme({
  spacing: styleKit.spacing.base,
  palette: {
    primary: {
      light: styleKit.colors.orange.light,
      main: styleKit.colors.orange.medium,
      dark: styleKit.colors.orange.dark,
    },
    background: {
      default: styleKit.colors.general.bg,
    },
    text: {
      primary: styleKit.colors.general.black2,
      secondary: styleKit.colors.general.darkGrey,
      disabled: styleKit.colors.general.grey,
    },
    divider: styleKit.colors.general.dividers,
    common: {
      black: styleKit.colors.general.black,
      white: styleKit.colors.general.white,
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
  shadows: [
    'none',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 16px rgba(0, 0, 0, 0.05)',
    // In case you need other boxshadow settings, change the ones below and use
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
    '0px 0px 6px rgba(0, 0, 0, 0.25)',
  ],
  shape: {
    borderRadius: styleKit.shapes.borderRadius,
  },
  typography: {
    htmlFontSize: styleKit.typography.defaults.fontSize,
    fontSize: styleKit.typography.defaults.fontSize,
    fontFamily: "'Ubuntu', sans-serif;", // should be Avenir, but this font isn't purchased yet
    fontWeightRegular: styleKit.typography.defaults.fontWeightRegular,
    fontWeightBold: styleKit.typography.defaults.fontWeightBold,
    h1: {
      fontSize: `${
        styleKit.typography.h1.fontSize / styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.h1.fontWeight,
      lineHeight: styleKit.typography.h1.lineHeight,
    },
    h2: {
      fontSize: `${
        styleKit.typography.h2.fontSize / styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.h2.fontWeight,
      lineHeight: styleKit.typography.h2.lineHeight,
    },
    h3: {
      fontSize: `${
        styleKit.typography.h3.fontSize / styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.h3.fontWeight,
      lineHeight: styleKit.typography.h3.lineHeight,
    },
    h4: {
      fontSize: `${
        styleKit.typography.h4.fontSize / styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.h4.fontWeight,
      lineHeight: styleKit.typography.h4.lineHeight,
    },
    h5: {
      fontSize: `${
        styleKit.typography.h5.fontSize / styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.h5.fontWeight,
      lineHeight: styleKit.typography.h5.lineHeight,
    },
    h6: {
      fontSize: `${
        styleKit.typography.h6.fontSize / styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.h6.fontWeight,
      lineHeight: styleKit.typography.h6.lineHeight,
    },
    button: {
      fontSize: `${
        styleKit.typography.button.fontSize /
        styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.button.fontWeight,
      lineHeight: styleKit.typography.button.lineHeight,
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: `${
        styleKit.typography.specialTitle2.fontSize /
        styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.specialTitle2.fontWeight,
      lineHeight: styleKit.typography.specialTitle2.lineHeight,
      textDecoration: 'underline',
    },
    subtitle2: {
      fontSize: `${
        styleKit.typography.specialTitle1.fontSize /
        styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.specialTitle1.fontWeight,
      lineHeight: styleKit.typography.specialTitle1.lineHeight,
    },
    body1: {
      fontSize: `${
        styleKit.typography.paragraph1.fontSize /
        styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.paragraph1.fontWeight,
      lineHeight: styleKit.typography.paragraph1.lineHeight,
    },
    body2: {
      fontSize: `${
        styleKit.typography.paragraph3.fontSize /
        styleKit.typography.defaults.fontSize
      }rem`,
      fontWeight: styleKit.typography.paragraph3.fontWeight,
      lineHeight: styleKit.typography.paragraph3.lineHeight,
    },
  },

  components: themeComponentsOverride,
})

theme.spacingFromPixels = function (pixels: number) {
  return this.spacing((1 / styleKit.spacing.base) * pixels)
}

theme.remFromPixels = function (pixels: number) {
  return `${pixels / styleKit.typography.defaults.fontSize}rem`
}

theme.styleKit = styleKit
