import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { LinearProgress } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import {
  historyLogEntryAdt,
  resolveHistoryLogActionLabel,
} from '@fintastic/web/util/history'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { StyledRoot } from './Table.styled'
import { ColDef, ColumnApi, GridReadyEvent } from 'ag-grid-community'
import {
  CreatedAtCellRenderer,
  CreatedAtHeaderRenderer,
} from './columns-renderers/created-at'
import { EntityTypeCellRenderer } from './columns-renderers/entity-type'
import { DetailsCellRenderer } from './columns-renderers/details'
import { SupportedEntry } from '../supported-entry-types'
import { resolveMetricOrListLabelFromId } from '../../tmp-utilities'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'

export const Table: React.FC<{
  entries: SupportedEntry[]
  isBusy: boolean
  orderingDesc: boolean
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entries, isBusy, orderingDesc, dimensions }) => {
  const colDefs = useMemo<ColDef<SupportedEntry>[]>(
    () => [
      {
        headerName: 'Update',
        field: 'action',
        valueFormatter: (params) => resolveHistoryLogActionLabel(params.value),
      },
      { headerName: 'User', field: 'user_email' },
      {
        headerName: 'Date & Time',
        field: 'created_at',
        headerComponent: CreatedAtHeaderRenderer,
        headerComponentParams: { orderingDesc },
        cellRenderer: CreatedAtCellRenderer,
      },
      {
        headerName: 'Entity name',
        field: 'entity_id',
        valueFormatter: (params) => {
          const entry = params.data
          if (!entry) {
            return ''
          }
          return resolveMetricOrListLabelFromId(entry.entity_id)
        },
      },
      {
        headerName: 'Entity type',
        field: 'entity_type',
        cellRenderer: EntityTypeCellRenderer,
      },
      {
        headerName: 'Details',
        field: '_details',
        flex: 2,
        cellRenderer: DetailsCellRenderer,
        cellRendererParams: { dimensions },
      },
    ],
    [orderingDesc, dimensions],
  )

  const autosize = useCallback((event: { columnApi: ColumnApi }) => {
    event.columnApi.autoSizeAllColumns()
  }, [])

  return (
    <StyledRoot loading={isBusy}>
      {isBusy && (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            borderRadius: entries.length === 0 ? 0 : '8px 8px 0 0',
          }}
        />
      )}

      <AgGridThemeFintasticWrapper
        className={clsx({
          'ag-theme-alpine': true,
          'main-grid': true,
        })}
        sx={{
          'height': '100%',
          'width': '100%',
          '& .ag-root-wrapper': {
            boxShadow: 'none !important',
            borderRight: 'none !important',
            borderRadius: '8px',
          },
          '& .gr-value': {
            cursor: 'default',
          },
          '& .ag-cell': {
            cursor: 'default !important',
          },
          '& .ag-row::before': {
            background: 'none !important',
          },
        }}
      >
        {({ defaultGridProps }) => (
          <AgGridReact
            {...defaultGridProps}
            suppressCellFocus={true}
            enableRangeSelection={false}
            suppressClickEdit={true}
            onGridReady={autosize}
            onFirstDataRendered={autosize}
            onRowDataChanged={autosize}
            defaultColDef={{
              flex: 1,
              minWidth: 50,
              width: 180,
              filter: false,
              sortable: false,
              resizable: true,
              suppressMenu: true,
            }}
            suppressNoRowsOverlay={true}
            columnDefs={colDefs}
            rowData={entries}
            animateRows={false}
          ></AgGridReact>
        )}
      </AgGridThemeFintasticWrapper>
    </StyledRoot>
  )
}
