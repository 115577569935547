import React from 'react'
import { Button } from '@mui/material'
import { CalendarIcon } from '@fintastic/shared/ui/icons'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type PeriodSelectionLiteButtonProps = {
  active: boolean
  onClick: () => void
}

export const PeriodSelectionLiteButton: React.FC<
  PeriodSelectionLiteButtonProps
> = ({ active, onClick }) => (
  <FintasticThemeProvider applyLegacyTheme={true}>
    <Button
      onClick={onClick}
      variant={active ? 'contained' : 'outlined'}
      color={'primary'}
      sx={{
        px: 0.4,
      }}
    >
      <CalendarIcon fontSize="medium" />
    </Button>
  </FintasticThemeProvider>
)
