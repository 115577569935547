import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { DimensionValueId, VersionRangeDimension } from '../types'
import * as selectors from './common-selectors'

export class RangeDimensionWrapper
  implements ImmutableADTWrapper<VersionRangeDimension>
{
  _rawData: VersionRangeDimension

  private _valuesReference: DimensionValueId[] = []

  constructor(d: VersionRangeDimension) {
    this._rawData = d
  }

  unwrap(): VersionRangeDimension {
    return this._rawData
  }

  get id() {
    return selectors.id(this._rawData)
  }

  get label() {
    return selectors.label(this._rawData)
  }

  get type() {
    return selectors.type(this._rawData)
  }

  get orderedValuesIds(): DimensionValueId[] {
    return this._valuesReference
  }
}
