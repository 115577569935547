import { ToolPanelDef } from 'ag-grid-community'
import { MetricSettingsToolPanel } from './MetricSettingsToolPanel'
import { resolveFintasticAgIconKey } from '@fintastic/shared/ui/ag-grid-theme-fintastic'

export const METRIC_SETTINGS_TOOL_PANEL_ID = 'metricSettings'

export const metricSettingsToolPanelDefinition: ToolPanelDef = {
  id: METRIC_SETTINGS_TOOL_PANEL_ID,
  labelDefault: '',
  toolPanel: MetricSettingsToolPanel,
  labelKey: '',
  iconKey: resolveFintasticAgIconKey('settings'),
  maxWidth: 320,
  minWidth: 320,
  width: 320,
}
