import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  Metric,
  MetricDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DeepReadonly,
  DimensionType,
  RollUpFunction,
} from '@fintastic/shared/util/types'
import * as selectors from '../common-metric/selectors'
import * as predicates from '../common-metric/predicates'

/* @todo add tests */
export class MetricWrapper implements ImmutableADTWrapper<Metric> {
  readonly _rawData: DeepReadonly<Metric>

  constructor(data: Metric) {
    this._rawData = data
  }

  unwrap(): Metric {
    return this._rawData
  }

  id() {
    return selectors.id(this._rawData)
  }

  label() {
    return selectors.label(this._rawData)
  }

  source() {
    return selectors.source(this._rawData)
  }

  // @todo add caching
  data() {
    return selectors.data(this._rawData)
  }

  dataType() {
    return selectors.dataType(this._rawData)
  }

  categoryAggregation() {
    return selectors.categoryAggregation(this._rawData)
  }

  timeAggregation() {
    return selectors.timeAggregation(this._rawData)
  }

  weightsMetricId() {
    return selectors.weightsMetricId(this._rawData)
  }

  timeDimension() {
    return selectors.timeDimension(this._rawData)
  }

  description() {
    return selectors.description(this._rawData)
  }

  formula() {
    return selectors.formula(this._rawData)
  }

  displaySettings() {
    return selectors.displaySettings(this._rawData)
  }

  hasTimeDimension() {
    return predicates.hasTimeDimension(this._rawData)
  }

  aggregatedByTime() {
    return predicates.aggregatedByTime(this._rawData)
  }

  allowedToSetTimeDimension() {
    return predicates.allowedToSetTimeDimension(this._rawData)
  }

  getAllowedRollUp(
    rollUp: RollUpFunction,
    dimensionType: Exclude<DimensionType, 'Range'>,
  ) {
    return selectors.getAllowedRollUp(this._rawData, rollUp, dimensionType)
  }

  usesWeightedAverageAggregation() {
    return predicates.usesWeightedAverageAggregation(this._rawData)
  }

  allowedToHaveTimeDimension() {
    return predicates.allowedToHaveTimeDimension(this._rawData)
  }

  isCalculated() {
    return predicates.isCalculated(this._rawData)
  }

  usesAnyOfDataTypes(dataTypes: MetricDataValueType[]) {
    return predicates.usesAnyOfDataTypes(this._rawData, dataTypes)
  }

  getLocalRangeDimensions() {
    return selectors.getLocalRangeDimensions(this._rawData)
  }
}
