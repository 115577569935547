import type { CellRange, Column, GridApi, RowNode } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import type {
  GenericReportDetailsQueryParams,
  GenericReportNormalised,
  GenericReportTreeRowCellValue,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  generateDimensionValue,
  stringifyDimensionsValueAsDict,
} from '../utils/generate-dimension-value'
import { parseReportIdFromFieldId } from '../utils/field-id'
import { compact, flow, isEqual, partialRight } from 'lodash'
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { useLoadMultipleVersionsEntities } from '@fintastic/web/data-access/versions'
import { isTotalPeriodName } from '../utils/is-total-period-name'
import { VersionDimension } from '@fintastic/web/util/dimensions'
import { useReadDeeplinkValue } from '@fintastic/web/util/deeplink'

export type UseCellRangeSelectorParams = {
  gridRef: React.RefObject<AgGridReactType<GenericReportTreeRowCellValue>>
  reportsData: GenericReportNormalised[]
  localDimensions: SortableDimension[]
  onChangeDetailsParams: (
    nextParams: Maybe<GenericReportDetailsQueryParams>,
  ) => void
  category: string
}

export const useCellRangeSelector = ({
  reportsData,
  localDimensions,
  onChangeDetailsParams,
  category,
  gridRef,
}: UseCellRangeSelectorParams) => {
  const bottomPanelOpenUrl =
    useReadDeeplinkValue<string>('botttom-panel') ?? false
  const versionIDs = useMemo(
    () =>
      reportsData
        .map((i) => i?.reportOrVersionId)
        .filter((r) => r !== undefined),
    [reportsData],
  )

  const entitiesQuery = useLoadMultipleVersionsEntities(versionIDs)

  const reportsNormalisedMap = useMemo(
    () =>
      Object.fromEntries(
        compact(
          reportsData.map((report) =>
            report?.reportOrVersionId
              ? [report.reportOrVersionId, report]
              : null,
          ),
        ),
      ),
    [reportsData],
  )

  const queryParamsRef = useRef<Maybe<GenericReportDetailsQueryParams>>()

  const setQueryParams = useCallback(
    (params: Maybe<GenericReportDetailsQueryParams>) => {
      queryParamsRef.current = params
      onChangeDetailsParams(params)
    },
    [onChangeDetailsParams],
  )

  const getDimensionsFilter = useCallback(
    (
      node: RowNode<GenericReportTreeRowCellValue>,
      dimensions: VersionDimension[],
    ) =>
      flow(
        partialRight(generateDimensionValue, localDimensions, dimensions),
        stringifyDimensionsValueAsDict,
      )(node),
    [localDimensions],
  )

  const appliedRangeRef = useRef<CellRange>()

  const applyCellRange = useCallback(
    ({ api }: { api: GridApi<GenericReportTreeRowCellValue> }) => {
      const range = api.getCellRanges()?.[0]

      if (
        !range ||
        !range.startColumn ||
        range.startColumn.isPinned() ||
        range.startRow?.rowIndex === undefined
      ) {
        return
      }

      const header =
        range.startColumn?.getOriginalParent()?.getColGroupDef()?.headerName ||
        ''
      const field = range.startColumn.getColDef().field
      const node = api.getModel().getRow(range.startRow?.rowIndex)

      if (range.startColumn.isPinned()) {
        return
      }

      if (!node || !header || !field) {
        return
      }

      if (isTotalPeriodName(header)) {
        setQueryParams(null)
        return
      }

      if (node.data?.calculatedRow) {
        setQueryParams(null)
        return
      }

      const reportOrVersionId = parseReportIdFromFieldId(field)

      if (!reportOrVersionId) {
        setQueryParams(null)
        return
      }

      const extractTimestampFromColumn = (col?: Column): string =>
        col?.getOriginalParent()?.getColGroupDef()?.headerName || ''

      const timestamp = extractTimestampFromColumn(range.startColumn)

      const timestampField =
        reportsNormalisedMap[reportOrVersionId]?.definition.timestamp?.name

      const entityDimensions = entitiesQuery.data?.find(
        (i) => i.versionId === reportOrVersionId,
      )?.entities?.dimensions

      if (!entityDimensions?.length) {
        throw new Error('No entity dimensions found for applyCellRange')
      }

      if (timestamp && timestampField) {
        appliedRangeRef.current = range
        setQueryParams({
          period: timestamp,
          dimensionFilter: getDimensionsFilter(node, entityDimensions),
          reportOrVersionId,
          timestampField,
          categoryId: category,
        })
      }
    },
    [
      category,
      entitiesQuery.data,
      getDimensionsFilter,
      reportsNormalisedMap,
      setQueryParams,
    ],
  )

  const bottomPanelOpenUrlRef = useRef(bottomPanelOpenUrl)
  bottomPanelOpenUrlRef.current = bottomPanelOpenUrl

  // In case localDimensions changes,
  // then we need to apply new params according to new dimension filter
  useEffect(() => {
    if (!bottomPanelOpenUrlRef.current) {
      return
    }

    if (!queryParamsRef.current || !gridRef.current?.api) {
      return
    }

    const selectedRow = appliedRangeRef.current?.startRow?.rowIndex

    if (typeof selectedRow !== 'number') {
      return
    }

    const node = gridRef.current?.api.getDisplayedRowAtIndex(selectedRow)

    if (!node) {
      setQueryParams(null)
      return
    }

    const reportOrVersionId = parseReportIdFromFieldId(
      appliedRangeRef.current?.startColumn.getColDef().field || '',
    )

    if (!reportOrVersionId) {
      return
    }

    const entityDimensions = entitiesQuery.data?.find(
      (i) => i.versionId === reportOrVersionId,
    )?.entities?.dimensions

    if (!entityDimensions) {
      return
    }

    const potentialNextParams = {
      ...queryParamsRef.current,
      dimensionFilter: getDimensionsFilter(node, entityDimensions),
    }

    if (isEqual(potentialNextParams, queryParamsRef.current)) {
      return
    }

    setQueryParams(potentialNextParams)
  }, [setQueryParams, localDimensions, getDimensionsFilter, entitiesQuery.data])

  return { applyCellRange, entitiesQuery }
}
