import {
  ListDimensionsPermissionsApi,
  makeValueColumnValueFormatter,
  makeXAxisCoordinateKey,
} from '@fintastic/web/feature/lists'
import {
  getDataTypeGroup,
  isDataTypeNumeric,
  Metric,
  MetricWithoutData,
  ParsedColumnId,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DimensionId,
  TimeDimensionId,
  VersionDimension,
  VersionTimeDimension,
} from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import {
  AgGridCustomCellEditorName,
  AgGridCustomCellRendererName,
  selectorColumnDefinition,
} from '@fintastic/shared/ui/ag-grid'
import {
  calculateColumnProps,
  GetCellEditorProps,
  getCurrencyCellEditorProps,
  getDateCellEditorProps,
  getDimensionalCellEditorProps,
  getNumericCellEditorProps,
  getPercentageCellEditorProps,
  makeBaseColDef,
  makeDefaultSetter,
  resolveTimeDimensionValueLabel,
} from './cell-definitions'
import {
  AddLinesColDef,
  ExtendedColDef,
  ExtendedColGroupDef,
  isExtendedColDef,
} from './types'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { ValidityCellRenderer } from '../components/cells'
import { validityColumnDefinition } from '../consts'

export type CreateAddLinesColumnDefsProps = {
  versionId: string
  versionDimensions: VersionDimension[]
  timeDimensionId: Maybe<string>
  dimensionsPermissions: ListDimensionsPermissionsApi
  setCellValue: (rowId?: string, fieldName?: string, value?: unknown) => void
  columns?: Metric[] | MetricWithoutData[]
  currentPeriodDims?: string[]
}

type Dependencies = {
  versionDimensions: Record<DimensionId, VersionDimension>
}

type AddLinesColumnContext = {
  twoLevelsHeader: boolean
  versionId: string
  timeDimensionId: Maybe<TimeDimensionId>
  dimensionsPermissions: ListDimensionsPermissionsApi
  setCellValue: (rowId?: string, fieldName?: string, value?: unknown) => void
  currentPeriodDims?: string[] // ['Dim.usX.Ba', 'Dim.usX.Ca', 'Dim.usX.Da', 'Dim.usX.Ea']
}

// @todo: hide calculated columns for now; will be dynamic switch in a future
const DISPLAY_CALCULATED_COLUMNS = false

export const createAddLinesColumnDefs = ({
  versionId,
  versionDimensions,
  timeDimensionId,
  dimensionsPermissions,
  setCellValue,
  columns,
  currentPeriodDims,
}: CreateAddLinesColumnDefsProps): AddLinesColDef[] => {
  if (!columns || !columns.length) {
    return []
  }

  const context = {
    dimensionsPermissions,
    setCellValue,
    currentPeriodDims,
    timeDimensionId,
  }

  const dimensionsMap = Object.fromEntries(
    versionDimensions.map((d) => [d.id, d]),
  )

  const result = [
    {
      ...validityColumnDefinition,
      cellClass: 'add-lines-validity-column',
      field: '_valid',
      cellRenderer: ValidityCellRenderer,
      cellRendererParams: {
        onlyInvalid: true,
      },
    },
    {
      ...selectorColumnDefinition,
      checkboxSelection: true,
      sortable: false,
      cellClass: 'add-lines-selection-column',
    },
    ...columns.map((c) =>
      createColDef(
        c,
        {
          ...context,
          versionId,
          twoLevelsHeader: timeDimensionId !== null,
          timeDimensionId,
        },
        {
          versionDimensions: dimensionsMap,
        },
      ),
    ),
  ]

  return DISPLAY_CALCULATED_COLUMNS
    ? result
    : result.filter((col) =>
        isExtendedColDef(col) ? !col.columnIdParts.isCalculated : true,
      )
}

const createColDef = (
  column: Metric | MetricWithoutData,
  context: AddLinesColumnContext,
  deps: Dependencies,
): AddLinesColDef => {
  const wrapper = new ListColumnWrapper(column)

  if (wrapper.breakdownByTimeAllowed()) {
    return timeColumnToColDef(wrapper, context, deps)
  }

  let colDef: ExtendedColDef

  switch (wrapper.type()) {
    case 'calculated': {
      if (wrapper.valuesDimensionId() !== null) {
        colDef = {
          ...dimensionColumnToColDef(wrapper, context, deps),
        }
      } else {
        colDef = regularColumnToColDef(wrapper, context)
      }
      break
    }
    case 'input': {
      colDef = regularColumnToColDef(wrapper, context)
      break
    }
    case 'dimension': {
      colDef = dimensionColumnToColDef(wrapper, context, deps)
      break
    }
  }

  return colDef
}

export const dimensionColumnToColDef = (
  column: ListColumnWrapper,
  { versionId, dimensionsPermissions, setCellValue }: AddLinesColumnContext,
  deps: Dependencies,
): ExtendedColDef => {
  const field = makeXAxisCoordinateKey({
    versionId,
    metricId: column.id(),
    period: null,
  })

  if (column.isCalculated()) {
    const parsedColumnId = ParsedColumnId.fromString(column.id())
    if (!parsedColumnId) {
      throw new Error(`Invalid column id ${column.id()}!`)
    }

    return {
      ...makeBaseColDef(),
      field,
      columnIdParts: {
        field,
        versionId,
        listId: parsedColumnId.listId,
        columnName: parsedColumnId.columnIdWithoutPrefix,
        isDimension: false,
        isTimeBreakdown: false, // unknown here but will be overridden
        isCalculated: true,
      },
      headerName: titleFormatter(column.label()),
      headerTooltip: titleFormatter(column.label()),

      ...calculateColumnProps(),
      editable: false,
      headerClass: 'calculated-column',
    }
  }

  const cellEditor: AgGridCustomCellEditorName = 'selectBoxCellEditor'
  const parsedColumnId = ParsedColumnId.fromString(column.id())
  if (!parsedColumnId) {
    throw new Error(`Invalid column id ${column.id()}!`)
  }

  const cellEditorProps = getDimensionalCellEditorProps(
    column,
    deps.versionDimensions,
    dimensionsPermissions,
  )

  return {
    ...makeBaseColDef(),
    ...makeDefaultSetter(setCellValue, false),
    field,
    columnIdParts: {
      field,
      versionId,
      listId: parsedColumnId.listId,
      columnName: parsedColumnId.columnIdWithoutPrefix,
      isDimension: true,
      isCalculated: column.isCalculated(),
      isTimeBreakdown: false,
    },
    cellEditor,
    headerName: titleFormatter(column.label()),
    headerTooltip: titleFormatter(column.label()),
    ...cellEditorProps,
    headerClass: column.isCalculated() ? 'calculated-column' : undefined,
  }
}

export const regularColumnToColDef = (
  column: ListColumnWrapper,
  { versionId, setCellValue }: AddLinesColumnContext,
): ExtendedColDef => {
  const field = makeXAxisCoordinateKey({
    versionId,
    metricId: column.id(),
    period: null,
  })

  let cellEditorProps: Partial<GetCellEditorProps> = {}
  let cellEditor: AgGridCustomCellEditorName | undefined = undefined
  let cellRenderer: AgGridCustomCellRendererName = 'defaultCellRenderer'
  let columnType: string | undefined = undefined
  const typeGroup = getDataTypeGroup(column.dataType())
  const dataType = column.dataType()

  const parsedColumnId = ParsedColumnId.fromString(column.id())
  if (!parsedColumnId) {
    throw new Error(`Invalid column id ${column.id()}!`)
  }

  if (column.isCalculated()) {
    return {
      ...makeBaseColDef(),
      field,
      columnIdParts: {
        field,
        versionId,
        listId: parsedColumnId.listId,
        columnName: parsedColumnId.columnIdWithoutPrefix,
        isDimension: false,
        isTimeBreakdown: false, // unknown here but will be overridden
        isCalculated: true,
        isText: typeGroup === 'text',
      },
      headerName: titleFormatter(column.label()),
      headerTooltip: titleFormatter(column.label()),

      ...calculateColumnProps(),
      editable: false,
      type: columnType,
      headerClass: 'calculated-column',
    }
  }

  if (typeGroup === 'text') {
    cellEditor = 'textCellEditor'
  }

  if (isDataTypeNumeric(column.dataType())) {
    // numeric, currency, percentage
    cellEditor = 'numericCellEditor'
    columnType = 'numericColumn'

    cellEditorProps = getNumericCellEditorProps(column)
  }

  const isCurrencyColumn = dataType === 'currency'

  if (isCurrencyColumn) {
    cellEditor = 'numericCellEditor'
    columnType = 'numericColumn'
    cellEditorProps = getCurrencyCellEditorProps(column)
  }

  const isPercentageColumn =
    dataType === 'percentage' || dataType === 'percentage_integer'

  if (isPercentageColumn) {
    cellEditor = 'numericCellEditor'
    columnType = 'numericColumn'
    cellEditorProps = getPercentageCellEditorProps(column)
  }

  const isBooleanColumn = dataType === 'boolean'

  if (isBooleanColumn) {
    cellEditor = 'checkboxCellEditor'
    cellRenderer = 'checkboxCellRenderer'
  }

  const isDateColumn = dataType === 'datetime'

  if (isDateColumn) {
    cellEditor = 'dateCellEditor'
    // @todo: params.dataFormat?
    cellEditorProps = getDateCellEditorProps(column)
  }

  const valueFormatter = makeValueColumnValueFormatter(column)

  return {
    ...makeBaseColDef(),
    ...makeDefaultSetter(setCellValue),
    field,
    columnIdParts: {
      field,
      versionId,
      listId: parsedColumnId.listId,
      columnName: parsedColumnId.columnIdWithoutPrefix,
      isDimension: false,
      isTimeBreakdown: false,
      isCalculated: false,
      isText: typeGroup === 'text',
    },

    headerName: titleFormatter(column.label()),
    headerTooltip: titleFormatter(column.label()),
    valueFormatter,
    type: columnType,
    cellRenderer,
    cellEditor,
    ...cellEditorProps,
  }
}

export const timeColumnToColDef = (
  column: ListColumnWrapper,
  context: AddLinesColumnContext,
  deps: Dependencies,
): ExtendedColGroupDef => {
  const timeDimensionId = column.timeDimension() as string
  const versionId = context.versionId

  const timeDimension = deps.versionDimensions[
    timeDimensionId
  ] as VersionTimeDimension

  if (!timeDimension) {
    throw new Error(`Can't resolve time dimension "${timeDimensionId}"`)
  }

  const allPeriods = timeDimension.ordered_values
  const existingPeriods = context.currentPeriodDims
    ? [...context.currentPeriodDims]
    : []

  existingPeriods.sort((a, b) => allPeriods.indexOf(a) - allPeriods.indexOf(b))

  const field = makeXAxisCoordinateKey({
    versionId,
    metricId: column.id(),
    period: timeDimensionId,
  })

  const parsedColumnId = ParsedColumnId.fromString(column.id())
  if (!parsedColumnId) {
    throw new Error(`Invalid column id ${column.id()}!`)
  }

  const typeGroup = getDataTypeGroup(column.dataType())

  return {
    headerName: titleFormatter(column.label()),
    headerTooltip: titleFormatter(column.label()),
    columnIdParts: {
      field,
      versionId,
      listId: parsedColumnId.listId,
      columnName: parsedColumnId.columnIdWithoutPrefix,
      listTimeBreakdownDimId: context.timeDimensionId || '',
      timeBreakdownDims: existingPeriods,
      isDimension: false,
      isTimeBreakdown: false,
      isCalculated: column.isCalculated(),
      isText: typeGroup === 'text',
    },
    headerGroupComponent: 'defaultColumnHeaderGroup',
    children: existingPeriods.map<ExtendedColDef>((period) => {
      const field = makeXAxisCoordinateKey({
        versionId,
        metricId: column.id(),
        period,
      })

      const baseField = makeXAxisCoordinateKey({
        versionId,
        metricId: column.id(),
        period: null,
      })

      const preDef = regularColumnToColDef(column, context)
      const typeGroup = getDataTypeGroup(column.dataType())

      return {
        ...preDef,
        field,
        columnIdParts: {
          ...preDef.columnIdParts,
          field: field,
          isTimeBreakdown: true,
          listTimeBreakdownDimId: context.timeDimensionId || '',
          timeBreakdownField: baseField,
          timeBreakdownDimId: period,
          timeBreakdownDims: existingPeriods,
          isText: typeGroup === 'text',
        },
        headerName: titleFormatter(
          resolveTimeDimensionValueLabel(timeDimension, period),
        ),
        headerTooltip: titleFormatter(column.label()),
        headerClass: column.isCalculated() ? 'calculated-column' : undefined,
      }
    }),
  }
}
