import React from 'react'

export const FilterIndicator: React.FC = () => (
  <span className="ag-header-icon ag-header-label-icon ag-filter-icon">
    <span
      title="Filter"
      className="ag-icon ag-icon-filter"
      unselectable="on"
      role="presentation"
    ></span>
  </span>
)
