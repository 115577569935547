import React, { useCallback, useMemo, useRef } from 'react'
import { debounce } from '@mui/material'
import {
  StyledContextMenuSubmenuButton,
  StyledContextMenuSubmenuButtonIcon,
  StyledContextMenuSubmenuPopper,
  StyledContextMenuSubmenuPopperContainer,
} from './ContextMenuSubmenu.styled'
import { ContextMenuItemSubmenuRendererProps } from '../../types'
import { ContextMenuTree } from '../ContextMenuTree'
import { Maybe } from '@fintastic/shared/util/types'
import { useContextMenuState } from '../../hooks/useContextMenuState'
import { ContextMenuSubmenuIcon } from '../ContextMenuSubmenuIcon'

export type ContextMenuSubmenuProps = ContextMenuItemSubmenuRendererProps

export const ContextMenuSubmenu: React.FC<ContextMenuSubmenuProps> = (
  props,
) => {
  const { contextMenuItem, hideContextMenu } = props
  const { label, items, onClick, id } = contextMenuItem
  const buttonRef = useRef<Maybe<HTMLButtonElement>>(null)

  const popoverState = useContextMenuState()

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
      hideContextMenu()
    }
  }, [hideContextMenu, onClick])

  const handleToggleSubmenuPopper = useMemo(
    () =>
      debounce((open = true) => {
        if (open) {
          if (buttonRef.current) {
            popoverState.handleClickOnAnchor({ target: buttonRef.current })
          }
          return
        }

        popoverState.handleClose()
      }, 200),
    [popoverState],
  )

  const handleMouseOver = useCallback(() => {
    handleToggleSubmenuPopper(true)
  }, [handleToggleSubmenuPopper])

  const handleMouseOut = useCallback(() => {
    handleToggleSubmenuPopper(false)
  }, [handleToggleSubmenuPopper])

  return (
    <>
      <StyledContextMenuSubmenuButton
        ref={buttonRef}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        data-testid={`context-menu-submenu-button-${id}`}
      >
        {label}
        <StyledContextMenuSubmenuButtonIcon>
          <ContextMenuSubmenuIcon />
        </StyledContextMenuSubmenuButtonIcon>
      </StyledContextMenuSubmenuButton>
      <StyledContextMenuSubmenuPopper
        anchorEl={popoverState.anchorElement}
        open={popoverState.isOpen}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        placement="right-start"
        data-testid={`context-menu-submenu-content-${id}`}
      >
        <StyledContextMenuSubmenuPopperContainer>
          <ContextMenuTree
            hideContextMenu={hideContextMenu}
            menuItems={items}
          />
        </StyledContextMenuSubmenuPopperContainer>
      </StyledContextMenuSubmenuPopper>
    </>
  )
}
