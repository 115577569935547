import { Maybe } from '@fintastic/shared/util/types'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { MetricChartDimensions } from '@fintastic/web/feature/charts'
import { useCallback, useMemo } from 'react'
import { compact } from 'lodash'
import { queryClient } from '@fintastic/web/data-access/service-query-client'
import { getChartRequestDimensions } from '@fintastic/web/data-access/metrics-and-lists'

export function useInvalidateMetricChartData(
  versionIds: string[],
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: Maybe<MetricChartDimensions>,
) {
  // remap to ChartAggDimension
  const chartRequestDimensions = getChartRequestDimensions(dimensions)

  const sortedVersions = useMemo(() => compact(versionIds).sort(), [versionIds])

  const queryKeys = useMemo(() => sortedVersions.map((versionId) =>
      metricsAndListsCacheKeys.metricChart(
        versionId,
        metricId,
        periodSelection,
        chartRequestDimensions,
      ),
    ), [chartRequestDimensions, metricId, periodSelection, sortedVersions])

  const invalidateQueries = useCallback(() => {
    queryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries({
        queryKey,
      })
    })
  }, [queryKeys])

  return {
    invalidateQueries,
  }
}
