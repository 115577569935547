import { useCallback, useMemo } from 'react'
import { ChangeColumnNameAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeColumnNameFlow() {
  const { changeName } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnNameAction>(
    (columnId, newName) => {
      changeName({
        columnId,
        newName,
      })
      // @todo add flow checks
    },
    [changeName],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
