import { useMutation } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { copyVersion } from '../../api/versions-management-api'
import { queryClient } from '@fintastic/web/data-access/service-query-client'
import { invalidateVersionsListCache } from '../../cache'

export function useCopyVersion() {
  return useMutation(
    mutationKeys.copyVersion(),
    async (variables: {
      versions: {
        newName: string
        originId: string
      }[]
      dateRange: {
        start: Date
        end: Date
      }
      duplicatePermissions?: boolean
      duplicateLabels?: boolean
    }) =>
      Promise.all(
        variables.versions.map((version) =>
          (async function () {
            const response = await copyVersion(
              version.originId,
              version.newName,
              variables.dateRange,
              variables.duplicatePermissions,
              variables.duplicateLabels,
            )
            return response.data
          })(),
        ),
      ),
    {
      onSettled: async () => {
        await invalidateVersionsListCache(queryClient)
      },
    },
  )
}
