import { styled, Box } from '@mui/material'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: ${({ theme }) => theme.spacing(1)};

  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05),
    0px -4px 10px 0px rgba(0, 0, 0, 0.05);
`

export const StyledButtonContainer = styled(Box)`
  padding: 0 ${({ theme }) => theme.spacing(1)};

  width: 50%;
`
