import { styled } from '@mui/material'

export const StyledRoot = styled('div')`
  padding: 0 16px 16px;
`

export const StyledLabel = styled('div')`
  margin-bottom: 3px;
`

export const StyledContent = styled('div')``

export const StyledDimension = styled('div')`
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const StyledDimensionChip = styled('div')`
  display: inline-block;
  padding: 4px 8px;

  font-size: 12px;

  border-radius: 4px;
  background: #f8f4f1;
  color: #046f9f; ;
`
