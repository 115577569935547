import {
  BLANK_VALUE,
  BLANK_VALUE_UI_REPRESENTATION,
  BlankOrMaskedValue,
  MASKED_VALUE,
  MASKED_VALUE_UI_REPRESENTATION,
  MathDivideByZeroError,
  percentageWithBlanks,
  stringifyBlankOrMaskedValueIfNecessary,
  subtractWithBlanks,
} from '@fintastic/web/util/blanks-and-masked'
import { Maybe } from '@fintastic/shared/util/types'
import { DiffOperation } from '../../../../../../feature/generic-report-table/src/lib/types'
import { INVALID_FORMULA_VALUE } from '@fintastic/web/util/generic-report'

const getDiffOperandValue = (input: unknown) => {
  if (typeof input === 'number' && isFinite(input)) {
    return input
  }

  if (input === undefined || input === null) {
    return BLANK_VALUE
  }

  if (input === MASKED_VALUE) {
    return input
  }

  if (typeof input === 'string') {
    const maybeNumber = parseFloat(input)
    if (isFinite(maybeNumber)) {
      return maybeNumber
    }
  }

  return null
}

const calculateDiff = (
  data: any,
  diffLeftColField: string,
  diffRightColField: string,
  operation: DiffOperation = 'subtract',
) => {
  if (!data) {
    return 0
  }
  const diffLeftValue = getDiffOperandValue(data[diffLeftColField])
  const diffRightValue = getDiffOperandValue(data[diffRightColField])
  if (diffLeftValue === null || diffRightValue === null) {
    return '-'
  }

  let finalValue: BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE

  try {
    finalValue =
      operation === 'subtract'
        ? subtractWithBlanks(diffLeftValue, diffRightValue)
        : percentageWithBlanks(diffLeftValue, diffRightValue)
  } catch (ex) {
    if (ex instanceof MathDivideByZeroError) {
      finalValue = INVALID_FORMULA_VALUE
    } else {
      throw ex
    }
  }
  return stringifyBlankOrMaskedValueIfNecessary(finalValue)
}

export const metricDiffValueGetter = (
  diffLeftColField: string,
  diffRightColField: string,
  operation: DiffOperation = 'subtract',
  params: {
    node?: Maybe<{ data: any; aggData: any }>
    rowNode?: Maybe<{ data: any; aggData: any }>
    data?: any
  },
): string | number => {
  const node = params.node || params.rowNode
  const data = node?.aggData || node?.data || params.data

  return calculateDiff(data, diffLeftColField, diffRightColField, operation)
}

export const convertDiffToSymbolIfNeeded = (
  value: string | number,
): BlankOrMaskedValue => {
  if (
    value === BLANK_VALUE_UI_REPRESENTATION ||
    value === null ||
    value === undefined
  ) {
    return BLANK_VALUE
  }

  if (value === MASKED_VALUE_UI_REPRESENTATION) {
    return MASKED_VALUE
  }

  if (typeof value === 'number') {
    return value
  }

  return BLANK_VALUE
}
