import { createSvgIcon } from '@mui/material'

export const HistoryCloseIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.30827 1.67409C2.12046 1.70538 1.94896 1.86343 1.8993 2.05099C1.88279 2.11338 1.87955 2.23492 1.89276 2.29669C1.90511 2.35444 1.93095 2.41656 1.96488 2.47002C1.98877 2.50767 2.71759 3.23991 5.73557 6.25836L9.47665 10L5.73557 13.7417C2.71759 16.7601 1.98877 17.4924 1.96488 17.53C1.90613 17.6226 1.88388 17.7032 1.88482 17.82C1.88608 17.9762 1.94565 18.1047 2.06441 18.2076C2.16733 18.2968 2.26109 18.3302 2.40669 18.3297C2.53067 18.3292 2.59369 18.3112 2.68952 18.2488C2.7267 18.2246 3.53272 17.4222 6.49767 14.4574C10.6106 10.3448 10.321 10.64 10.406 10.4729C10.4864 10.3149 10.5165 10.1859 10.5165 10C10.5165 9.81418 10.4864 9.68515 10.406 9.52712C10.321 9.36007 10.6106 9.65529 6.49767 5.54264C2.5394 1.58464 2.70095 1.74385 2.60181 1.70356C2.52009 1.67036 2.40193 1.65849 2.30827 1.67409ZM10.1067 1.67664C9.98026 1.7052 9.87525 1.77378 9.79525 1.88002C9.66903 2.04764 9.66123 2.29184 9.7764 2.47024C9.7943 2.49797 10.9367 3.64492 13.546 6.25502L17.29 10L13.546 13.745C10.9367 16.3551 9.7943 17.5021 9.7764 17.5298C9.66123 17.7082 9.66903 17.9524 9.79525 18.12C9.90655 18.2678 10.0619 18.3411 10.2432 18.3312C10.3505 18.3253 10.4165 18.3041 10.5034 18.2473C10.5397 18.2235 11.3842 17.3821 14.3167 14.4477C16.7718 11.991 18.0976 10.6597 18.1244 10.6243C18.4022 10.2568 18.4015 9.74234 18.1228 9.37359C18.0611 9.29202 10.5595 1.78978 10.4999 1.75001C10.455 1.72009 10.4078 1.69874 10.35 1.68219C10.3001 1.66791 10.1595 1.6647 10.1067 1.67664Z"
      fill="#D45702"
    />
  </svg>,
  'HistoryClose',
)
