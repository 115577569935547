import { styled, Box, BoxProps } from '@mui/material'

export const StyledAgGridDefaultCellRendererRoot = styled(
  ({ ...props }: BoxProps) => <Box {...props} />,
)(({ theme }) => ({
  'position': 'relative',

  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'marginLeft': 'calc(-1 * var(--ag-cell-padding-x, 0px))',
  'marginRight': 'calc(-1 * var(--ag-cell-padding-x, 0px))',

  '.populate-forward-tip': {
    opacity: 0,
    pointerEvents: 'none',
  },

  '&:hover .populate-forward-tip': {
    opacity: 1,
    pointerEvents: 'initial',
  },
}))

export const StyledAgGridDefaultCellRendererValueContainer = styled(
  ({
    hasPopulateForwardTip,
    ...props
  }: BoxProps & { hasPopulateForwardTip?: boolean }) => <Box {...props} />,
)(({ theme, hasPopulateForwardTip }) => ({
  overflow: 'hidden',
  width: '100%',

  paddingLeft: 'var(--ag-cell-padding-x, 0px)',
  paddingRight: `calc(var(--ag-cell-padding-x, 0px) + ${
    hasPopulateForwardTip ? '5px' : '0'
  })`,
}))
