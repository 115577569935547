import {
  styled,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material'

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({}))

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
}))

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: 32,
  minWidth: 32,

  color: theme.palette.text.primary,
}))
