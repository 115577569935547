import { styled, ButtonBase, Box } from '@mui/material'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
`

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (p) => p !== 'active' && p !== 'size',
})<{
  active: boolean
  disabled: boolean
  size: number
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  padding: 0 8px;
  height: 28px;
  border-radius: 4px;
  background: ${({ active, disabled, theme }) => {
    if (disabled) {
      return theme.palette.grey[200]
    }
    return active ? '#FFE9DA' : 'transparent'
  }};
  color: ${({ active, disabled, theme }) => {
    if (disabled) {
      return theme.palette.grey[400]
    }
    return active ? theme.palette.primary.main : theme.palette.text.secondary
  }};
  transition: ${({ theme }) => theme.transitions.create('all')};

  &:hover {
    background: ${({ active, theme }) =>
      active ? '#FFE9DA' : theme.palette.grey[100]};
  }

  .MuiTypography-button {
    font-weight: normal;
  }

  ${({ size }) => {
    if (size === 5) {
      return `
        width: calc(20% - 8px);
        max-width: calc(20% - 8px);
      `
    }

    if (size === 4) {
      return `
        width: calc(25% - 8px);
        max-width: calc(25% - 8px);
      `
    }

    if (size === 3) {
      return `
        width: calc(33% - 8px);
        max-width: calc(33% - 8px);
      `
    }

    return ``
  }}
`
