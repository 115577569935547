import { Box, inputAdornmentClasses, outlinedInputClasses, styled } from '@mui/material'

export const StyledRoot = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1.5, 1.25)};

  width: 100%;
`

export const StyledInputContainer = styled(Box)`
  width: 100%;

  .${outlinedInputClasses.root} {
    padding-left: ${({ theme }) => theme.spacing(1)};
  }

  .${outlinedInputClasses.root} .${outlinedInputClasses.input} {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: ${({ theme }) => theme.spacing(1)};
    height: 24px;
  }

  .${inputAdornmentClasses.root} {
    margin-right: 0;
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`
