import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeColumnTimeAggregationPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const changeColumnTimeAggregation = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeColumnTimeAggregationPayload>,
) => {
  const state = castToListEditingState(uncheckedState)

  const list = new MutableListWrapper(state.list)

  list
    .findColumnByClientId(payload.columnId)
    ?.applyTimeAggregationChanges(
      payload.rollUpFunction,
      payload.weightsMetricId,
    )
}
