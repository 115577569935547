import React from 'react'

type SharedProps = {
  goBack: () => void
  goToReportingGroup: (id: string) => void
  groups: Array<{
    id: string
    label: string
  }>
}

const Editable: React.FC<
  SharedProps & {
    addReportingGroup: () => void
  }
> = () => <div>Editable</div>

const Readonly: React.FC<SharedProps> = () => <div>Readonly</div>

export const ReportingGroupsView = {
  Editable,
  Readonly,
} as const
