import {
  styled,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  listItemTextClasses,
  Box,
} from '@mui/material'

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({}))

export const StyledListItemText = styled(ListItemText)`
  margin: 0;

  .${listItemTextClasses.secondary} {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: 48,
  minWidth: 48,

  color: theme.palette.text.primary,
}))

export const StyledArrowIcon = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: 0,
}))
