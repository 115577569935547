import React, { forwardRef } from 'react'
import {
  StyledHandleDraggler,
  StyledHandleRoot,
  StyledHandleDragglerIcon,
} from './Handle.styled'
import { ResizeHandle } from 'react-resizable'

export type HandleProps = {
  handleAxis: ResizeHandle
}

export const Handle = forwardRef<HTMLElement, HandleProps>((props, ref) => {
  const { handleAxis, ...rest } = props

  return (
    <StyledHandleRoot ref={ref} {...rest}>
      <StyledHandleDraggler>
        <StyledHandleDragglerIcon>
          <svg
            width="24"
            height="6"
            viewBox="0 0 24 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="21" cy="3" r="3" fill="#C4C4C4" />
            <circle cx="12" cy="3" r="3" fill="#C4C4C4" />
            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
          </svg>
        </StyledHandleDragglerIcon>
      </StyledHandleDraggler>
    </StyledHandleRoot>
  )
})

export function renderHandleCallback(
  handleAxis: ResizeHandle,
  ref: React.ForwardedRef<HTMLElement>,
  enabled = true,
) {
  if (!enabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></> // can't return just null, should return a react element
  }

  return <Handle handleAxis={handleAxis} ref={ref} />
}
