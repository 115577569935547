import {
  CalendarDatePickerConfig,
  PeriodSelection,
} from '@fintastic/web/util/period-selector'
import { ColumnColor } from '@fintastic/web/util/metrics-and-lists'

export const addLinesPayloadType = 'list-add-lines' as const

export const addLinesTargetIdParts = ['listId', 'versionId'] as const

export type AddLinesTargetIdKeys = typeof addLinesTargetIdParts[number]

export type AddLinesTargetParams = {
  numberOfRows?: number
  periodSelection?: PeriodSelection
  calendarConfig?: CalendarDatePickerConfig
  columnColors?: ColumnColor[]
}

export type ListAddLinesTarget = Record<
  AddLinesTargetIdKeys,
  string | undefined
>
