import { BlankOrMaskedValue } from './types'
import { BLANK_VALUE, containsBlankValue } from './blanks'
import { containsMaskedValue, MASKED_VALUE } from './masked-value'
import { MathDivideByZeroError } from './math-errors'

/**
 * `a + b` operation for `BlankOrMaskedValue`
 */
export const sumWithBlanks = (
  a: BlankOrMaskedValue,
  b: BlankOrMaskedValue,
): BlankOrMaskedValue => {
  // both blanks = blanks
  if (containsBlankValue(a) && containsBlankValue(b)) {
    return BLANK_VALUE
  }

  // any masked = masked
  if (containsMaskedValue(a) || containsMaskedValue(b)) {
    return MASKED_VALUE
  }

  if (containsBlankValue(b)) {
    return a
  }

  if (containsBlankValue(a)) {
    return b
  }

  return a + b
}

/**
 * `a - b` operation for `BlankOrMaskedValue`
 */
export const subtractWithBlanks = (
  a: BlankOrMaskedValue,
  b: BlankOrMaskedValue,
): BlankOrMaskedValue => {
  if (containsBlankValue(a) && containsBlankValue(b)) {
    return BLANK_VALUE
  }

  if (containsMaskedValue(a) || containsMaskedValue(b)) {
    return MASKED_VALUE
  }

  if (containsBlankValue(b)) {
    return a
  }

  if (containsBlankValue(a)) {
    if (typeof b === 'number') {
      return b * -1
    }

    return b
  }

  return a - b
}

/**
 * `a * b` operation for `BlankOrMaskedValue`
 */
export const multiplyWithBlanks = (
  a: BlankOrMaskedValue,
  b: BlankOrMaskedValue,
): BlankOrMaskedValue => {
  // both blanks = blanks
  if (containsBlankValue(a) && containsBlankValue(b)) {
    return BLANK_VALUE
  }

  // any masked = masked
  if (containsMaskedValue(a) || containsMaskedValue(b)) {
    return MASKED_VALUE
  }

  if (containsBlankValue(a) || containsBlankValue(b)) {
    return BLANK_VALUE
  }

  return a * b
}

/**
 * `a / b` operation for `BlankOrMaskedValue`
 */
export const divideWithBlanks = (
  a: BlankOrMaskedValue,
  b: BlankOrMaskedValue,
): BlankOrMaskedValue => {
  // both blanks = blanks
  if (containsBlankValue(a) && containsBlankValue(b)) {
    return BLANK_VALUE
  }

  // any masked = masked
  if (containsMaskedValue(a) || containsMaskedValue(b)) {
    return MASKED_VALUE
  }

  if (containsBlankValue(a) || containsBlankValue(b)) {
    return BLANK_VALUE
  }

  if (b === 0) {
    throw new MathDivideByZeroError('Cannot divide by zero')
  }

  return a / b
}

export const fallbackBlankOrMaskedToZero = (
  value: BlankOrMaskedValue,
): number => {
  if (containsBlankValue(value) || containsMaskedValue(value)) {
    return 0
  }
  return value
}

/**
 * `b is % from a` for `BlankOrMaskedValue`
 */
export const percentageWithBlanks = (
  a: BlankOrMaskedValue,
  b: BlankOrMaskedValue,
): BlankOrMaskedValue => {
  if (containsBlankValue(a) && containsBlankValue(b)) {
    return BLANK_VALUE
  }

  if (containsMaskedValue(a) || containsMaskedValue(b)) {
    return MASKED_VALUE
  }

  if (containsBlankValue(b) || Math.round(b) === 0) {
    throw new MathDivideByZeroError('divide by zero in percentageWithBlanks')
  }

  if (containsBlankValue(a) || Math.round(a) === 0) {
    return Infinity
  }

  const diffSign = a > b ? 1 : -1
  const percentage = Math.abs(1 - a / b)

  return percentage * diffSign
}
