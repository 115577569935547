import React, { useMemo } from 'react'
import type { DiffMode, Version } from '@fintastic/web/util/versions'
import {
  VersionsAndDiffSelect,
  VersionSelectorItem,
} from '@fintastic/shared/ui/components'

export type PlanningVersionsAndDiffsSelectProps = {
  versions: Array<string>
  diffs: Array<[string, string, DiffMode]>
  onChangeVersions: (versionsIds: Array<string>) => void
  onChangeDiffs: (diffs: Array<[string, string, DiffMode]>) => void
  versionsData: Version[]
  showApplyButton?: boolean
  enableDiffsSelection?: boolean
}

export const PlanningVersionsAndDiffsSelect: React.FC<
  PlanningVersionsAndDiffsSelectProps
> = (props) => {
  const {
    versions,
    onChangeVersions,
    onChangeDiffs,
    versionsData,
    diffs,
    showApplyButton = false,
    enableDiffsSelection = true,
  } = props

  const transformedVersionsData = useMemo(
    () =>
      versionsData
        .sort((version1, version2) => {
          if (version1.is_live_actuals) {
            return -1
          }
          if (version2.is_live_actuals) {
            return 1
          }

          return new Date(version1.updated_at) > new Date(version2.updated_at)
            ? -1
            : 1
        })
        .map<VersionSelectorItem>((data) => ({
          id: data.uuid,
          name: data.name,
          isLocked: !!data.locked_at,
        })),
    [versionsData],
  )

  return (
    <VersionsAndDiffSelect
      versions={transformedVersionsData}
      selectedDiffs={diffs}
      selectedVersions={versions}
      selectVersions={onChangeVersions}
      selectDiffs={onChangeDiffs}
      noPaddings={true}
      showApplyButton={showApplyButton}
      enableDiffsSelection={enableDiffsSelection}
    />
  )
}
