import { useMemo } from 'react'
import { useBoardsListQuery } from './useBoardsListQuery'
import { BoardFolder } from '@fintastic/web/feature/boards'

export type UseBoardNameValidationParams = {
  boardName: string
  editingBoardId?: string
}

export type UseBoardNameValidationResult = {
  isValid: boolean
  error?: string
}

export function useBoardNameValidation(
  params: UseBoardNameValidationParams,
) {
  const { boardName, editingBoardId } = params

  const existingBoards = useBoardsListQuery()

  const boardNames = useMemo(() => {
    const namesMap: Record<string, BoardFolder['id']> = {}
    existingBoards.data?.forEach((folder) => {
      namesMap[folder.name.toLowerCase()] = folder.id
    })
    return namesMap
  }, [existingBoards.data])

  return useMemo(() => {
    const result: UseBoardNameValidationResult = {
      isValid: true,
    }

    if (boardName === '') {
      result.isValid = false
      result.error = 'Mandatory field'
      return result
    }

    if (
      boardNames[boardName.toLowerCase()] !== undefined &&
      boardNames[boardName.toLowerCase()] !== editingBoardId
    ) {
      result.isValid = false
      result.error = `Name "${boardName}" is already in use`
    }

    return result
  }, [boardName, boardNames, editingBoardId])
}
