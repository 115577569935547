import React, { useCallback } from 'react'
import { ModalWithParamsState } from '@fintastic/shared/util/modal'
import { RemoveWidgetDialogParams } from '../../../../types/board-design-types'
import { StandardConfirmationModal } from '@fintastic/shared/ui/components'

type RemoveWidgetDialogProps = {
  state: ModalWithParamsState<RemoveWidgetDialogParams>
  onConfirm: (id: string) => void
}

export const RemoveWidgetDialog: React.FC<RemoveWidgetDialogProps> = ({
  state,
  onConfirm,
}) => {
  const handleConfirm = useCallback(() => {
    state.close()
    onConfirm(state.params.id)
  }, [onConfirm, state])

  return (
    <StandardConfirmationModal
      open={state.isOpen}
      title={`Delete ${state.params.type} widget`}
      text={`Are you sure you want to delete ${state.params.name} widget?`}
      confirmText={'Delete widget'}
      onRequestClose={state.close}
      onConfirm={handleConfirm}
    />
  )
}
