import {
  MetricDataValue,
  SparseMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import { DimensionTuple } from '@fintastic/web/util/dimensions'

export const iterateOverSparse = (
  { indexes, dimensions, values }: SparseMetricData,
  callback: (dimensions: DimensionTuple[], value: MetricDataValue) => void,
): void => {
  for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
    const valueDims: DimensionTuple[] = []
    for (let dimIndex = 0; dimIndex < indexes.length; dimIndex++) {
      valueDims.push([indexes[dimIndex], dimensions[dimIndex][valueIndex]])
    }
    callback(valueDims, values[valueIndex])
  }
}
