import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ExpandIcon = createSvgIcon(
  <>
    <path d="M16.589 8.74467C16.4536 8.89092 16.2701 8.97308 16.0787 8.97308C15.8873 8.97308 15.7038 8.89092 15.5684 8.74467L11.9957 4.88305L8.42297 8.74467C8.28685 8.88677 8.10453 8.9654 7.91529 8.96363C7.72604 8.96185 7.54502 8.8798 7.4112 8.73516C7.27738 8.59052 7.20147 8.39486 7.19983 8.19031C7.19818 7.98576 7.27093 7.7887 7.40241 7.64157L11.4854 3.22841C11.6208 3.08216 11.8043 3 11.9957 3C12.1871 3 12.3706 3.08216 12.506 3.22841L16.589 7.64157C16.7243 7.78787 16.8003 7.98626 16.8003 8.19312C16.8003 8.39998 16.7243 8.59837 16.589 8.74467Z" />
    <path d="M7.41152 15.2558C7.54687 15.1095 7.73042 15.0273 7.9218 15.0273C8.11319 15.0273 8.29674 15.1095 8.43209 15.2558L12.0048 19.1174L15.5775 15.2558C15.7136 15.1136 15.896 15.035 16.0852 15.0368C16.2744 15.0386 16.4555 15.1206 16.5893 15.2653C16.7231 15.4099 16.799 15.6056 16.8007 15.8101C16.8023 16.0147 16.7296 16.2117 16.5981 16.3588L12.5151 20.772C12.3797 20.9183 12.1962 21.0004 12.0048 21.0004C11.8134 21.0004 11.6299 20.9183 11.4945 20.772L7.41152 16.3588C7.27621 16.2126 7.2002 16.0142 7.2002 15.8073C7.2002 15.6004 7.27621 15.402 7.41152 15.2558Z" />
    <path d="M4.75 10.9872C4.33579 10.9872 4 11.323 4 11.7372C4 12.1514 4.33579 12.4872 4.75 12.4872H19.2507C19.665 12.4872 20.0007 12.1514 20.0007 11.7372C20.0007 11.323 19.665 10.9872 19.2507 10.9872H4.75Z" />
  </>,
  'Expand',
)
