import { useQuery } from 'react-query'
import { getVersion } from '../../api/versions-management-api'
import { Maybe } from '@fintastic/shared/util/types'
import { versionsCacheKeys } from '../../cache'

export function useLoadVersion(id?: Maybe<string>) {
  return useQuery(
    versionsCacheKeys.versionData(id || ''),
    async () => {
      const response = await getVersion(id || '')
      return response.data.version
    },
    {
      enabled: id !== null && id !== undefined,
      retry: 1,
    },
  )
}
