import { createContext, useContext } from 'react'

export type PageLayoutContextValue = {
  centerContent?: boolean
  disablePaddingVertical?: boolean
  disablePaddingBottom?: boolean
  disablePaddingTop?: boolean
  disablePaddingHorizontal?: boolean
  disableScrollX?: boolean
  disableScrollY?: boolean
}

export const pageLayoutContext = createContext<PageLayoutContextValue>({})

export const usePageLayoutContext = () => useContext(pageLayoutContext)
