import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { useModalState } from '@fintastic/shared/util/modal'
import { LockVersionForm } from './LockVersionForm'
import {
  useLockVersion,
  useUnlockVersion,
} from '@fintastic/web/data-access/versions'
import { useAttachEventListenerToNativeDom } from '@fintastic/shared/util/ag-grid'
import { VersionLockUnlockIcon } from '@fintastic/shared/ui/components'
import { StyledLockVersionCell } from './LockVersionAction.styled'

type Props = {
  versionId: string
  versionName: string
  isLocked?: boolean
  allowUnlock?: boolean
  archived: boolean
  disabled?: boolean
  onAfterLock?: () => void
  onAfterUnlock?: () => void
}

export const LockVersionAction: React.FC<Props> = (props) => {
  const {
    versionId,
    versionName,
    isLocked,
    allowUnlock = true,
    archived,
    disabled = false,
    onAfterLock,
    onAfterUnlock,
  } = props
  const {
    isOpen: isModalOpened,
    open: openModal,
    close: closeModal,
  } = useModalState()
  const lockVersionMutation = useLockVersion(versionId)
  const unlockVersionMutation = useUnlockVersion(versionId)

  const disableAction =
    disabled ||
    lockVersionMutation.isLoading ||
    unlockVersionMutation.isLoading ||
    (!allowUnlock && !!isLocked) ||
    archived

  let tooltipText
  if (archived) {
    tooltipText = 'Archived'
  } else if (!isLocked) {
    tooltipText = 'Lock'
  } else if (allowUnlock) {
    tooltipText = 'Unlock'
  } else {
    tooltipText = 'Locked'
  }

  const buttonRef = useAttachEventListenerToNativeDom('click', openModal, true)

  return (
    <StyledLockVersionCell>
      <Modal
        open={isModalOpened}
        onRequestClose={closeModal}
        title={isLocked ? 'Unlock' : 'Lock'}
      >
        <LockVersionForm
          closeParentModal={closeModal}
          versionId={versionId}
          versionName={versionName}
          isLocked={isLocked}
          onAfterLock={onAfterLock}
          onAfterUnlock={onAfterUnlock}
        />
      </Modal>

      <Tooltip title={tooltipText}>
        <span>
          <IconButton ref={buttonRef} disabled={disableAction}>
            <VersionLockUnlockIcon
              isLocked={isLocked}
              disabled={disableAction}
            />
          </IconButton>
        </span>
      </Tooltip>
    </StyledLockVersionCell>
  )
}
