import { QueryClient, useMutation } from 'react-query'
import { useMemo } from 'react'
import {
  DisplayErrorCallback,
  DisplaySuccessCallback,
  UnlockVersionCallback,
} from './types'
import { StateApi } from '../editor-state'
import {
  createReport,
  invalidateReportsListCache,
  updateReport,
} from '@fintastic/web/data-access/generic-report'

export type SaveOperationApi = Pick<
  ReturnType<typeof useSaveOperation>,
  'error' | 'mutateAsync' | 'isLoading'
>

export type SaveOperationParams = {
  versionId: string
}

export type SaveOperationDependencies = {
  stateApi: StateApi
  unlockVersion: UnlockVersionCallback
  displayError: DisplayErrorCallback
  displaySuccess: DisplaySuccessCallback
  queryClient: QueryClient
}

export const useSaveOperation = (
  params: SaveOperationParams,
  deps: SaveOperationDependencies,
) =>
  useMutation({
    mutationFn: useMemo(() => makeOperationFn(params, deps), [deps, params]),
  })

export const makeOperationFn =
  (params: SaveOperationParams, deps: SaveOperationDependencies) =>
  async () => {
    const state = deps.stateApi.state

    if (!state.active) {
      throw new Error('no report is being editing right now')
    }

    try {
      if (state.new) {
        await createReport(params.versionId, state.reportDefinition)
      } else {
        if (state.dirty) {
          await updateReport(
            params.versionId,
            state.reportDefinition.id,
            state.reportDefinition,
          )
        }
      }
    } catch (e) {
      deps.displayError('Failed to save report configuration')
      return
    }

    try {
      await deps.unlockVersion()
    } catch (e) {
      // do nothing
    }

    deps.displaySuccess(
      state.new
        ? 'Report created successfully.'
        : 'Report configuration updated successfully.',
    )
    deps.stateApi.discardEditing(
      state.new ? state.reportDefinition.id : undefined,
    )
    invalidateReportsListCache(deps.queryClient, params.versionId)
  }
