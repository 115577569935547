import React, { CSSProperties } from 'react'
import { StyledIconContainer } from './VersionLockUnlockIcon.styled'
import { EditOffIcon } from '@fintastic/shared/ui/icons'

export type VersionIconProps = {
  sx?: CSSProperties
  size?: number
}

export const VersionLockedForEditIcon: React.FC<VersionIconProps> = ({
  sx,
  size = 20,
}) => (
  <StyledIconContainer size={size} sx={{ color: '#FF7933',...sx}}>
    <EditOffIcon />
  </StyledIconContainer>
)
