import React from 'react'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { FiltersIcon } from '@fintastic/shared/ui/icons'

export const NoResultsOverlay: React.FC<NoResultsOverlayProps> = ({
  listOriginallyEmpty,
}) =>
  listOriginallyEmpty ? (
    <>No rows to show</>
  ) : (
    <ErrorAlert
      icon={<FiltersIcon />}
      severity="info"
      title="No results match your filter"
      message="Adjust your filter and try again"
    />
  )

export type NoResultsOverlayProps = { listOriginallyEmpty: boolean }
