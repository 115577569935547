import React from 'react'
import { StyledTipRoot } from './Tip.styled'

export type TipProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export const Tip: React.FC<TipProps> = ({ onClick }) => (
  <StyledTipRoot
    disableRipple={true}
    onClickCapture={onClick}
    className="populate-forward-tip"
  >
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-7.34329e-05 6L6.19116 6L6.19116 0L-7.34329e-05 6Z"
        fill="#263646"
      />
    </svg>
  </StyledTipRoot>
)
