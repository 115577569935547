import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { PartialVersion } from '@fintastic/web/util/versions'
import { updateVersion } from '../../api/versions-management-api'
import { invalidateVersionManagementRelatedQueries } from '../../cache'

export function useUpdateVersion(versionId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.updateVersion(versionId),
    async (params: Omit<PartialVersion, 'uuid'>) => {
      const response = await updateVersion({ ...params, uuid: versionId })
      return response.data
    },
    {
      onSuccess: async () => {
        await invalidateVersionManagementRelatedQueries(queryClient, versionId)
      },
    },
  )
}
