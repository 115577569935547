import { useCallback, useMemo, useState } from 'react'
import { RowSelectedEvent } from 'ag-grid-community'
import { RowData } from './types'

export const useRowSelection = () => {
  const [selectedRows, setSelectedRows] = useState<RowData[]>([])

  const handleRowSelected = useCallback((event: RowSelectedEvent<RowData>) => {
    setSelectedRows(event.api.getSelectedRows())
  }, [])

  const reset = useCallback(() => {
    setSelectedRows([])
  }, [])

  return useMemo(
    () => ({
      selectedRows,
      handleRowSelected,
      reset,
    }),
    [handleRowSelected, selectedRows, reset],
  )
}
