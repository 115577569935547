import { mapCalculationErrorCode } from './error-utils'
import {
  CalculationErrorCode,
  CalculationErrorSeverity,
  EntityDeletionErrorPayload,
} from './types'

export class CalculationTimedOutError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CalculationTimedOutError'
    Object.setPrototypeOf(this, CalculationTimedOutError.prototype)
  }
}

export class CalculationError extends Error {
  public caclulationErrorCode: CalculationErrorCode
  public calculationErrorSeverity: CalculationErrorSeverity

  constructor(code: CalculationErrorCode) {
    const error = mapCalculationErrorCode(code)
    super(error.message)
    this.name = 'CalculationError'
    this.caclulationErrorCode = code
    this.calculationErrorSeverity = error.severity
    Object.setPrototypeOf(this, CalculationError.prototype)
  }
}

export class DeletionError extends Error {
  constructor(public reports: EntityDeletionErrorPayload[], message: string) {
    super(message)
    this.name = 'DeletionError'
    this.reports = reports
    Object.setPrototypeOf(this, DeletionError.prototype)
  }
}
