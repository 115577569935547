import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { sortBy } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'
import {
  CompactMetricOrList,
  CompactReport,
} from '@fintastic/web/util/metrics-and-lists'

export type SelectOption = { id: string; label: string }

export type WidgetSelectDataCallbacksProps = {
  onFetching?: (processName: string) => void
  onReady?: (processName: string) => void
}

export type WidgetSelectDataWrapperProps = WidgetSelectDataCallbacksProps & {
  disabled?: boolean
  versionId?: Maybe<string>
  isLoading?: boolean
  value: Maybe<string>
  onSelect: (listId: Maybe<string>, listName: Maybe<string>) => void
  onLoadingUpdate?: (loading: boolean) => void
}

export type WidgetSelectProps = {
  disabled?: boolean
  versionId?: Maybe<string>
  emptyText?: string // 'list' | 'metric' | 'report'
  loadingText?: string
  value: Maybe<string>
  entityList: CompactMetricOrList[] | CompactReport[]
  isLoading?: boolean
  onSelect: (listId: Maybe<string>, listName: Maybe<string>) => void
}

const renderOptions = (
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: Maybe<SelectOption>,
) => (
  <Box component="li" {...optionProps} key={option?.id}>
    {option?.label}
  </Box>
)

const renderInput = (
  params: AutocompleteRenderInputParams,
  loading: boolean,
  emptyText = 'Select value',
  loadingText = 'Loading...',
) => {
  if (loading) {
    return (
      <TextField
        {...params}
        label={loadingText}
        InputProps={{
          startAdornment: (
            <Box mt={0.5} ml={0.5}>
              <CircularProgress size={16} />
            </Box>
          ),
        }}
      />
    )
  }

  return <TextField {...params} label={emptyText} />
}

export const WidgetSelect: React.FC<WidgetSelectProps> = ({
  onSelect,
  emptyText,
  loadingText,
  value,
  disabled,
  isLoading,
  entityList,
}) => {
  const entityNames: SelectOption[] = useMemo(
    () => [
      ...sortBy(
        entityList?.map((f: CompactMetricOrList | CompactReport) => ({
          id: f.id,
          label: (f.label || f.id || '').replaceAll('_', ' '),
        })),
        ['label'],
      ),
    ],
    [entityList],
  )

  const currentValue = useMemo(() => {
    if (!value) {
      return null
    }
    const dataValue = entityNames.find((o) => o.id === value)
    if (entityNames && entityNames.length > 0 && !dataValue) {
      onSelect(null, null)
    }
    return dataValue || null
  }, [value, entityNames, onSelect])

  const handleSelect = useCallback(
    (_: React.SyntheticEvent, newInputValue: Maybe<SelectOption>) => {
      onSelect(
        newInputValue ? newInputValue.id : null,
        newInputValue ? newInputValue.label : null,
      )
    },
    [onSelect],
  )

  return (
    <Autocomplete<Maybe<SelectOption>, false, true | false, undefined>
      size={'small'}
      value={currentValue}
      options={entityNames}
      disabled={disabled || isLoading || entityNames.length === 0}
      disableClearable={true}
      onChange={handleSelect}
      renderInput={(params) =>
        renderInput(params, Boolean(isLoading), emptyText, loadingText)
      }
      isOptionEqualToValue={(fo, option) => fo?.id === option?.id}
      renderOption={renderOptions}
    />
  )
}
