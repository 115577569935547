import { styled, Box } from '@mui/material'

export const StyledRoot = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export const StyledTypeField = styled(Box)(({ theme }) => ({}))

export const StyledCurrencyField = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))
