import { IAggFuncParams } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import { last as lodashLast } from 'lodash'

// copied from the source code:
// https://github.com/ag-grid/ag-grid/blob/ca68754eef544c3ece8d3fb800f70d39e9be23e1/grid-enterprise-modules/row-grouping/src/rowGrouping/aggFuncService.ts#L131
export function last<TData = any, TValue = any>(
  params: IAggFuncParams<TData, TValue>,
): Maybe<TValue> {
  return params.values.length > 0 ? lodashLast(params.values) || null : null
}
