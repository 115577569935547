import { VersionMetric } from '../../../../utils/metrics'
import { HandleMetricUpdateCallbackParams } from '../../types'
import { idLooksLikeDimValueOther } from '@fintastic/web/util/dimensions'

export const getChangesThatAreNotApplicableForMetric = (
  data: VersionMetric[],
  changes: HandleMetricUpdateCallbackParams[],
): HandleMetricUpdateCallbackParams[] =>
  changes.filter((change) => {
    const indexes = data.find((d) => d.versionId === change.versionId)
      ?.metricData.indexes

    return (
      change.changes.every((c) =>
        Object.entries(c.dimensions).every(
          ([dimensionKey, dimensionValue]) =>
            indexes?.includes(dimensionKey) ||
            idLooksLikeDimValueOther(dimensionValue),
        ),
      ) === false
    )
  })
