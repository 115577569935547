import { IAggFuncParams } from 'ag-grid-community'
import lodashMean from 'lodash/mean'
import {
  containsBlankValue,
  containsMaskedValue,
  isRawBlankValue,
} from '@fintastic/web/util/blanks-and-masked'

export function mean<TData = any, TValue = any>(
  params: IAggFuncParams<TData, TValue>,
): number {
  return lodashMean(
    params.values.filter(
      (v) =>
        !containsBlankValue(v) &&
        !containsMaskedValue(v) &&
        !isRawBlankValue(v),
    ),
  )
}
