import { HistoryLogFilters } from '@fintastic/web/data-access/history'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import dayjs from 'dayjs'
import { iso8601 } from '@fintastic/shared/util/date'
import { isEqual, uniq } from 'lodash'
import { HistoryLogAction } from '@fintastic/web/util/history'

type SupportedFilters = Pick<
  HistoryLogFilters,
  'action' | 'entity_type' | 'min_date' | 'max_date'
>

export type LocalFiltersAndOrderingParamsState = ReturnType<
  typeof useLocalFiltersAndOrderingParamsState
>

export const useLocalFiltersAndOrderingParamsState = (
  currentParams: {
    filter: HistoryLogFilters
    desc: boolean
  },
  defaultParams: {
    filter: HistoryLogFilters
    desc: boolean
  },
) => {
  const [localParams, setLocalParams] = useState<{
    filter: SupportedFilters
    desc: boolean
  }>(currentParams)

  useEffect(() => {
    setLocalParams(currentParams)
  }, [currentParams])

  const changeOrdering = useCallback((desc: boolean) => {
    setLocalParams((p) => ({
      ...p,
      desc,
    }))
  }, [])

  const changeEntityType = useCallback(
    (entityType: Maybe<('list' | 'metric')[]>) => {
      const noneType = !entityType || entityType.length === 0

      setLocalParams((v) => ({
        ...v,
        filter: {
          ...v.filter,
          entity_type: noneType ? [] : entityType,
        },
      }))
    },
    [],
  )

  const changeActionType = useCallback(
    (actionType: Maybe<HistoryLogAction[]>) => {
      const noneType = !actionType || actionType.length === 0

      setLocalParams((v) => ({
        ...v,
        filter: {
          ...v.filter,
          action: noneType ? [] : actionType,
        },
      }))
    },
    [],
  )

  const changeDateFrom = useCallback((date: dayjs.Dayjs) => {
    const fromDate = iso8601.toUtc(
      iso8601.fromDate(date.startOf('day').toDate()),
    )
    setLocalParams((v) => ({
      ...v,
      filter: {
        ...v.filter,
        min_date: fromDate,
      },
    }))
  }, [])

  const changeDateTo = useCallback((date: dayjs.Dayjs) => {
    const toDate = iso8601.toUtc(iso8601.fromDate(date.endOf('day').toDate()))
    setLocalParams((v) => ({
      ...v,
      filter: {
        ...v.filter,
        max_date: toDate,
      },
    }))
  }, [])

  const resetToCurrent = useCallback(() => {
    setLocalParams(currentParams)
  }, [currentParams])

  const localDiffersFromCurrent = useMemo(
    () => !isEqual(currentParams, localParams),
    [currentParams, localParams],
  )

  const currentDiffersFromDefault = useMemo(
    () => !isEqual(defaultParams, currentParams),
    [currentParams, defaultParams],
  )

  const diffsFromDefaultsCounter = useMemo(() => {
    let counter = 0

    if (currentParams.desc !== defaultParams.desc) {
      counter += 1
    }

    uniq([
      ...Object.keys(currentParams.filter),
      ...Object.keys(defaultParams.filter),
    ]).forEach((filterKey) => {
      const key = filterKey as keyof HistoryLogFilters
      if (!isEqual(currentParams.filter[key], defaultParams.filter[key])) {
        counter += 1
      }
    })

    return counter
  }, [currentParams, defaultParams])

  return useMemo(
    () =>
      ({
        localParams,
        localDiffersFromCurrent,
        currentDiffersFromDefault,
        diffsFromDefaultsCounter,
        changeOrdering,
        changeEntityType,
        changeActionType,
        changeDateFrom,
        changeDateTo,
        resetToCurrent,
      } as const),
    [
      localParams,
      localDiffersFromCurrent,
      currentDiffersFromDefault,
      diffsFromDefaultsCounter,
      changeOrdering,
      changeEntityType,
      changeActionType,
      changeDateFrom,
      changeDateTo,
      resetToCurrent,
    ],
  )
}
