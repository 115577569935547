import {
  invalidateListColumnsCache,
  invalidateV2MetricCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import { invalidateAllVersionRelatedQueries } from '@fintastic/web/data-access/versions'
import { QueryClient } from 'react-query'

export const invalidateEverythingForVersion = async (
  versionId: string,
  queryClient: QueryClient,
) => {
  // don't trigger re-loading, because it will be triggered by the whole version invalidation
  await invalidateListColumnsCache(queryClient, { versionId: versionId }, false)
  await invalidateAllVersionRelatedQueries(queryClient, versionId)
  await invalidateV2MetricCache.invalidateVersion(queryClient, versionId)
}
