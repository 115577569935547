import React, { forwardRef, useImperativeHandle } from 'react'
import {
  ModalState,
  ModalWithParamsState,
  useModalState,
  useModalWithParamsState,
} from '@fintastic/shared/util/modal'
import {
  ChangeWidgetDialogParams,
  ChangeWidgetDialogState,
  emptyWidgetDialogState,
  RemoveWidgetDialogParams,
} from '@fintastic/web/feature/boards'
import { ChangeWidgetDialog } from './components/modals/ChangeWidgetDialog'
import { RemoveWidgetDialog } from './components/modals/RemoveWidgetDialog'
import { CancelDesignDialog } from './components/modals/CancelDesignDialog'

export type BoardDesignDialogsHandler = {
  changeDialog: ChangeWidgetDialogState
  removeDialog: ModalWithParamsState<RemoveWidgetDialogParams>
  cancelDesignDialog: ModalState
}

export type BoardDesignViewDialogsProps = {
  onAddOrChangeConfirm: (params: ChangeWidgetDialogParams) => void
  onRemoveConfirm: (id: string) => void
  onCancelDesignConfirm: () => void
}

export const BoardDesignViewDialogs = forwardRef<
  BoardDesignDialogsHandler,
  BoardDesignViewDialogsProps
>((props, ref) => {
  const { onAddOrChangeConfirm, onRemoveConfirm, onCancelDesignConfirm } = props

  const changeDialog: ChangeWidgetDialogState =
    useModalWithParamsState<ChangeWidgetDialogParams>({
      initialParams: {
        ...emptyWidgetDialogState,
      },
    })

  const removeDialog = useModalWithParamsState<RemoveWidgetDialogParams>({
    initialParams: {
      name: '',
      type: '',
      id: '',
    },
  })

  const cancelDesignDialog = useModalState()

  useImperativeHandle(ref, () => ({
    changeDialog: changeDialog,
    removeDialog: removeDialog,
    cancelDesignDialog: cancelDesignDialog,
  }))

  return (
    <>
      {changeDialog.isOpen && (
        <ChangeWidgetDialog
          state={changeDialog}
          onConfirm={onAddOrChangeConfirm}
        />
      )}

      <RemoveWidgetDialog state={removeDialog} onConfirm={onRemoveConfirm} />

      <CancelDesignDialog
        state={cancelDesignDialog}
        onConfirm={onCancelDesignConfirm}
      />
    </>
  )
})
