import { styled, Box, Typography } from '@mui/material'

export const StyledRoot = styled(Box)(({ theme }) => ({}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))

export const StyledList = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, -2, 0),
}))
