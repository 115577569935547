import React, { PropsWithChildren } from 'react'
import {
  StyledFormField,
  StyledFormFieldCont,
  StyledFormFieldLabel,
  StyledFormFieldLabelCont,
  StyledFormFieldRoot,
} from './FormField.styled'
import { SxProps } from '@mui/system'

export type FormFieldProps = PropsWithChildren & {
  label?: string
  fullWidth?: boolean
  placeholder?: string
  ['data-testid']?: string
  containerSx?: SxProps
}

export const FormField: React.FC<FormFieldProps> = (props) => {
  const { label, fullWidth, containerSx, children } = props

  return (
    <StyledFormFieldRoot sx={containerSx}>
      {!fullWidth && !!label && (
        <StyledFormFieldLabelCont>
          <StyledFormFieldLabel>{label}</StyledFormFieldLabel>
        </StyledFormFieldLabelCont>
      )}
      <StyledFormFieldCont fullWidth={fullWidth}>
        <StyledFormField>{children}</StyledFormField>
      </StyledFormFieldCont>
    </StyledFormFieldRoot>
  )
}
