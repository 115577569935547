import React from 'react'
import type { ToastWrapperProps } from 'react-hot-toast/src/core/types'
import { Box } from '@mui/material'

// basically copied from https://github.com/timolins/react-hot-toast/blob/main/src/components/toaster.tsx
// but then modified to use MUI
export const ToastWrapper: React.FC<
  ToastWrapperProps & {
    visible?: boolean
  }
> = ({ visible, id, className, style, onHeightUpdate, children }) => {
  const ref = React.useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        const updateHeight = () => {
          const height = el.getBoundingClientRect().height
          onHeightUpdate(id, height)
        }
        updateHeight()
        new MutationObserver(updateHeight).observe(el, {
          subtree: true,
          childList: true,
          characterData: true,
        })
      }
    },
    [id, onHeightUpdate],
  )

  return (
    <Box
      ref={ref}
      className={className}
      sx={{
        ...style,
        ...(visible
          ? {
              'zIndex': (theme) => theme.zIndex.snackbar,
              '& > *': {
                pointerEvents: 'auto',
              },
            }
          : {}),
      }}
    >
      {children}
    </Box>
  )
}
