import { Board } from '../types'
import { MutationKey, useMutation, useQueryClient } from 'react-query'
import { boardsListQueryKeyGetter } from './useBoardsListQuery'
import { unDeleteBoard } from '../api'
import { Maybe } from '@fintastic/shared/util/types'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'
import { boardQueryKeyGetter } from '@fintastic/web/feature/boards'

export const boardUnDeleteMutationKeyGetter = (
  boardId?: Maybe<Board['id']>,
) => {
  const key: MutationKey = `board/undelete/${boardId}`
  return key
}

export type UseBoardUnDeleteMutationParams = {
  boardId?: Maybe<Board['id']>
}

const defaultParams: UseBoardUnDeleteMutationParams = {}

export function useBoardUnDeleteMutation(
  params: UseBoardUnDeleteMutationParams = defaultParams,
) {
  const { boardId } = params
  const queryClient = useQueryClient()

  return useMutation(
    boardUnDeleteMutationKeyGetter(boardId),
    async (variables: { boardId?: Maybe<Board['id']> }) => {
      if (!boardId && !variables.boardId) {
        throw new Error('Board ID is not provided.')
      }
      await unDeleteBoard((boardId || variables.boardId) as string)
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(boardQueryKeyGetter(variables.boardId), {
          exact: true,
        })
        queryClient.invalidateQueries(boardsListQueryKeyGetter(), {
          exact: true,
        })
        queryClient.invalidateQueries(boardsFoldersListQueryKeyGetter(), {
          exact: true,
        })
      },
    },
  )
}
