import { createContext, useContext } from 'react'
import { ChartSettings } from '@fintastic/web/feature/charts'

const stub = () => {
  throw new Error('ChartSettingsEditContextValue is not initialised')
}

export type ChartSettingsEditContextValue = {
  initialSettingsChanged: boolean
  referenceSettingsChanged: boolean
  localSettings: ChartSettings
  handleSettingsChange: (state: ChartSettings) => void
  handleReset: () => void
  handleCancel: () => void
  handleApply: () => void
  extraPayload?: Record<string, unknown>
}

export const ChartSettingsEditContext =
  createContext<ChartSettingsEditContextValue>({
    initialSettingsChanged: false,
    referenceSettingsChanged: false,
    localSettings: {},
    handleSettingsChange: stub,
    handleReset: stub,
    handleApply: stub,
    handleCancel: stub,
    extraPayload: {},
  })

export function useChartSettingsEditContext() {
  return useContext(ChartSettingsEditContext)
}
