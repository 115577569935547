import { RowsMap } from './types'
import {
  NonTimeDimensionId,
  NonTimeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { MutableReference } from '@fintastic/shared/util/types'
import { createRowKey } from '@fintastic/web/util/metrics-and-lists'

type RowDimensions = Record<NonTimeDimensionId, NonTimeDimensionValueId>

/**
 * Creates a set of rows where defined only the row key and the combination of dimensions.
 * Value columns are not generated because we can simply treat the absence of valus as undefined as blank.
 */
export const makeEmptyTableRows = (params: {
  dimIds: NonTimeDimensionId[]
  dimValueIds: NonTimeDimensionValueId[][]
  modifyRowDimensions?: (rowDims: MutableReference<RowDimensions>) => void
}): RowsMap => {
  const rowsMap: RowsMap = {}

  const dimsCombinations: NonTimeDimensionValueId[][] = cartesianProduct(
    params.dimValueIds,
  )

  for (let i = 0; i < dimsCombinations.length; i++) {
    const rowDimensions = Object.fromEntries(
      params.dimIds.map((dimId, dimIndex) => [
        dimId,
        dimsCombinations[i][dimIndex],
      ]),
    )

    params.modifyRowDimensions?.(
      rowDimensions as MutableReference<RowDimensions>,
    )

    const key = createRowKey(rowDimensions)
    if (!(key in rowsMap)) {
      rowsMap[key] = rowDimensions
    }
  }

  return rowsMap
}

type ValuesSet<T = any> = T[]
type ValuesCombination<T = any> = T[]

/**
 * Turns [[1, 2], [a, b]]
 * into [[1, a], [1, b], [2, a], [2, b]]
 */
const cartesianProduct = <T = any>(
  sets: ValuesSet<T>[],
): ValuesCombination<T>[] => {
  const numberOfCombinations = sets.reduce(
    (res, array) => res * array.length,
    1,
  )

  const combinations: ValuesCombination<T>[] = Array(numberOfCombinations)
    .fill(0)
    .map(() => [])

  let repeatEachElement

  for (let i = 0; i < sets.length; i++) {
    const set = sets[i]

    repeatEachElement = repeatEachElement
      ? repeatEachElement / set.length
      : numberOfCombinations / set.length
    const timesOfEveryElementRepeated = repeatEachElement * set.length

    for (let j = 0; j < numberOfCombinations; j++) {
      const index = Math.floor(
        (j % timesOfEveryElementRepeated) / repeatEachElement,
      )
      combinations[j][i] = set[index]
    }
  }

  return combinations
}
