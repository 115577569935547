import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosError, AxiosResponse, default as axiosStatic } from 'axios'
import { fromAxiosErrorToFirstMatchedError } from '@fintastic/shared/util/errors'
import { DimensionId } from '@fintastic/web/util/dimensions'
import {
  BadRequestError,
  NetworkError,
  RuntimeError,
} from './save-dimension-errors'

type ReorderDimsPayload = {
  items: string[]
}

export const reorderDimensions = async (
  dimId: DimensionId,
  items: string[],
) => {
  try {
    return await axios.post<
      void,
      AxiosResponse<void, ReorderDimsPayload>,
      ReorderDimsPayload
    >(endpoints.reorderDimensions(dimId), { items })
  } catch (e) {
    return castReorderError(e as Error)
  }
}

const castReorderError = (
  e: AxiosError | Error,
): NetworkError | RuntimeError => {
  if (!axiosStatic.isAxiosError(e)) {
    return new RuntimeError(e)
  }

  return (
    fromAxiosErrorToFirstMatchedError(e, [BadRequestError]) ||
    new NetworkError()
  )
}
