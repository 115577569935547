/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-rest-params */

export type Nullish = null | undefined
export type Nullable<T> = T | Nullish

export const isNullish = <T>(f: Nullable<T>): f is Nullish =>
  f === undefined || f === null

/**
 * Pipes the value of an expression into a pipeline of functions.
 * Short-circuits when encounters first Nullish
 *
 * @example
 * pipeNullable(
 *    [1, 2, 3].find((v) => v > 1),
 *    (n) => n % 2 === 0 ? n : null,
 *    (n) => n * 3
 * )
 */
export function pipeNullable<A>(a: Nullable<A>): Nullable<A>
export function pipeNullable<A, B>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
): Nullable<B>
export function pipeNullable<A, B, C>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
): Nullable<C>
export function pipeNullable<A, B, C, D>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
): Nullable<D>
export function pipeNullable<A, B, C, D, E>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
): Nullable<E>
export function pipeNullable<A, B, C, D, E, F>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
  fef: (e: E) => Nullable<F>,
): Nullable<F>
export function pipeNullable<A, B, C, D, E, F, G>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
  fef: (e: E) => Nullable<F>,
  ffg: (f: F) => Nullable<G>,
): Nullable<G>
export function pipeNullable<A, B, C, D, E, F, G, H>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
  fef: (e: E) => Nullable<F>,
  ffg: (f: F) => Nullable<G>,
  fgh: (g: G) => Nullable<H>,
): Nullable<H>
export function pipeNullable<A, B, C, D, E, F, G, H, I>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
  fef: (e: E) => Nullable<F>,
  ffg: (f: F) => Nullable<G>,
  fgh: (g: G) => Nullable<H>,
  fhi: (h: H) => Nullable<I>,
): Nullable<I>
export function pipeNullable<A, B, C, D, E, F, G, H, I, J>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
  fef: (e: E) => Nullable<F>,
  ffg: (f: F) => Nullable<G>,
  fgh: (g: G) => Nullable<H>,
  fhi: (h: H) => Nullable<I>,
  fij: (i: I) => Nullable<J>,
): Nullable<J>
export function pipeNullable<A, B, C, D, E, F, G, H, I, J, K>(
  a: Nullable<A>,
  fab: (a: A) => Nullable<B>,
  fbc: (b: B) => Nullable<C>,
  fcd: (c: C) => Nullable<D>,
  fde: (d: D) => Nullable<E>,
  fef: (e: E) => Nullable<F>,
  ffg: (f: F) => Nullable<G>,
  fgh: (g: G) => Nullable<H>,
  fhi: (h: H) => Nullable<I>,
  fij: (i: I) => Nullable<J>,
  fjk: (j: J) => Nullable<K>,
): Nullable<K>
export function pipeNullable(): unknown {
  if (arguments.length === 1 || isNullish(arguments[0])) {
    return arguments[0]
  }

  // often this function is applied like bind, so there is only 1 function in the pipeline
  if (arguments.length === 2) {
    return arguments[1](arguments[0])
  }

  let result = arguments[0]

  for (let i = 1; i < arguments.length; i++) {
    result = arguments[i](result)
    if (isNullish(result)) {
      return result
    }
  }
  return result
}

/**
 * If function returns Nullish, then skips the item from the result array
 */
export const filterMapNullable = <A, B>(
  fa: A[],
  f: (el: A, i: number, fa: A[]) => Nullable<B>,
): B[] => {
  const result: B[] = []

  for (let i = 0; i < fa.length; i++) {
    const r = f(fa[i], i, fa)
    if (!isNullish(r)) {
      result.push(r)
    }
  }

  return result
}
