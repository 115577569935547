const ID_PATH_PARAMETER = ':id'

const encodeId = (id = ID_PATH_PARAMETER) => {
  if (id !== ID_PATH_PARAMETER) {
    return encodeURIComponent(id)
  }
  return id
}

export const settingsPanelNavigationPaths = {
  root: '/',
  metrics: '/metrics',
  metricSettings: (id = ID_PATH_PARAMETER) =>
    `${settingsPanelNavigationPaths.metrics}/${encodeId(id)}`,
  reportingGroups: '/reporting-groups',
  reportingGroupSettings: (id = ID_PATH_PARAMETER) =>
    `${settingsPanelNavigationPaths.reportingGroups}/${encodeId(id)}`,
  dimensions: '/dimensions',
  dimensionSettings: (id = ID_PATH_PARAMETER) =>
    `${settingsPanelNavigationPaths.dimensions}/${encodeId(id)}`,
} as const

export const settingsPanelNavigationDefaultPathHistory = [
  settingsPanelNavigationPaths.root,
]
