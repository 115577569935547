/**
 Keep here the hooks attached to the current duck.
 In perfect case there should be only one way for components to interact with a duck - it's thought the hooks.
 Our hooks it's like a public API for components, and they shouldn't use selectors or action creators directly.
 */

import { AppFeatureFlag, AppFeatureFlagKey, AppServerConfig } from '../../types'
import {
  selectAllWithStatus,
  selectFeaturesDict,
} from './index'
import { useSelector } from 'react-redux'

export function useConfig(): ReturnType<typeof selectAllWithStatus> {
  return useSelector(selectAllWithStatus)
}

export function useIsFeatureEnabled(feature: AppFeatureFlagKey): boolean {
  return useSelector(selectFeaturesDict)[feature]?.enabled ?? false
}

export function useFeatureConfig(
  feature: keyof AppServerConfig['features_dict'],
): AppFeatureFlag {
  return (
    useSelector(selectFeaturesDict)[feature] ?? { enabled: false, value: '' }
  )
}
