import React from 'react'
import { useWidgetSettingsDialogContext } from '../../../../BoardDesigner/hooks'
import {
  StyledIssueContent,
  StyledIssueContentIcon,
  StyledIssueContentMessage,
  StyledIssueContentText,
} from './WidgetSettingsDialogIssues.styled'
import { WarningIcon } from '@fintastic/shared/ui/icons'

export type WidgetSettingsDialogIssuesProps = {
  fullWidth?: boolean
}

export const WidgetSettingsDialogIssues: React.FC<
  WidgetSettingsDialogIssuesProps
> = ({ fullWidth = false }) => {
  const { errorsApi } = useWidgetSettingsDialogContext()

  if (!errorsApi.isAnyPendingError) {
    return null
  }

  return (
    <>
      {errorsApi.pendingErrors.map((err) => (
        <StyledIssueContent fullWidth={fullWidth} key={err.id}>
          <StyledIssueContentIcon>
            <WarningIcon />
          </StyledIssueContentIcon>
          <StyledIssueContentText>
            <StyledIssueContentMessage>{err.message}</StyledIssueContentMessage>
          </StyledIssueContentText>
        </StyledIssueContent>
      ))}
    </>
  )
}
