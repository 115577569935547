import { ExtendedColumn, getTypeAndParams } from './utils'
import { ColumnApi, GridApi, RowNode } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import {
  containsBlankValue,
  containsMaskedValue,
} from '@fintastic/web/util/blanks-and-masked'
import { ExportMode } from '../types'
import type {
  AgGridDateCellEditorProps,
  AgGridNumericCellEditorProps,
} from '@fintastic/shared/ui/ag-grid'
import { CellRemark } from './grid-comments'
import { CalculatedRowIdUtils } from '@fintastic/web/util/generic-report'

export type GetDataValuesParams = {
  api: GridApi
  columnApi: ColumnApi
  context: unknown
  fallbackGroupValueFormatter?: ((value: unknown) => Maybe<string>) | undefined
  rowsToDisplay: RowNode[]
  columns: ExtendedColumn[]
  mode: ExportMode
  comments: Maybe<CellRemark[][]>
}

export type CellData = {
  rawValue: unknown
  value: unknown
  type: string
  columnIndex: number
  formattingParams?: AgGridNumericCellEditorProps | AgGridDateCellEditorProps
  level: number
  isGroup: boolean
  isExpandableRow: boolean
  isCalculatedRow?: boolean
  isPinnedRow?: boolean
  isFooter?: boolean
  hexColor?: string
  flatColumns?: string[]
  remarks?: Maybe<CellRemark>
}

export function getDataValues({
  api,
  columnApi,
  context,
  fallbackGroupValueFormatter,
  rowsToDisplay,
  columns,
  mode = 'current',
  comments,
}: GetDataValuesParams): CellData[][] {
  const rawDataSet: CellData[][] = []
  const groupColumn = columns?.find((c) => c.getColDef().showRowGroup)

  if (comments) {
    if (comments.length !== rowsToDisplay.length) {
      throw new Error('Rows sizes are different!')
    }
    if (comments[0].length !== columns.length) {
      throw new Error('Columns sizes are different!')
    }
  }

  rowsToDisplay.forEach((row, rowIdx) => {
    const rowData: CellData[] = []
    const isExpandableRow = row.isExpandable()
    const level = row.uiLevel
    const isCalculatedRow = !!(
      row.id && CalculatedRowIdUtils.isCalculatedRowId(row.id)
    )

    const flatColumns: string[] = []
    if (mode === 'flat' && groupColumn) {
      // back traverse for group name...
      let currentLevel = row.parent
      while (currentLevel) {
        const groupValue = api.getValue(groupColumn, currentLevel)

        if (groupValue) {
          flatColumns.unshift(groupValue)
        }

        currentLevel = currentLevel.parent
      }
    }

    columns.forEach((col, colIdx) => {
      const rawValue = api.getValue(col, row)
      let value = rawValue
      let hasFinalValue = false
      if (rawValue && Object.hasOwn(rawValue, 'finalValue')) {
        hasFinalValue = true
        value = rawValue.finalValue
      }

      if (value === rawValue && fallbackGroupValueFormatter) {
        value = fallbackGroupValueFormatter(rawValue)
      }

      if (row.level === -1 || !row.key || !value) {
        const footerFormatter =
          col._colDef.cellRendererParams?.footerValueGetter
        if (typeof footerFormatter === 'function') {
          value = footerFormatter({
            node: row,
          })
        }
      }

      const formatter =
        col._userColDef?.valueFormatter || col._colDef.valueFormatter
      if (typeof formatter === 'function') {
        const fmtValue = formatter({
          context,
          columnApi,
          data: rawValue,
          api: api,
          value: rawValue,
          column: col,
          colDef: col._userColDef || col._colDef,
          node: row,
        })

        if (fmtValue) {
          value = fmtValue
        }
      } else {
        if (Array.isArray(value)) {
          value = value.join(', ')
        }
      }

      const color =
        col._userColDef?.headerComponentParams?.contentBeforeMenu?.props
          ?.currentColumnColumn?.hexColor

      let rawValueData = hasFinalValue ? rawValue.finalValue : rawValue
      if (containsBlankValue(rawValueData)) {
        rawValueData = undefined
      }

      if (containsMaskedValue(rawValueData)) {
        rawValueData = undefined
      }

      rowData.push({
        rawValue: rawValueData,
        value,
        ...getTypeAndParams(col, value),
        level,
        columnIndex: colIdx,
        isGroup: !!col._colDef.showRowGroup,
        isExpandableRow,
        hexColor: color,
        isCalculatedRow,
        isPinnedRow: row.isRowPinned(),
        isFooter: row.footer,
        flatColumns:
          flatColumns && flatColumns?.length > 0 ? flatColumns : undefined,
        remarks: comments ? comments[rowIdx][colIdx] : null,
      })
    })
    rawDataSet.push(rowData)
  })
  return rawDataSet
}
