import { useQuery } from 'react-query'
import { LabelTagListItem, LabelsContext } from '../types'
import { labelsQueryKey } from './query-keys'
import { getLabels } from '../api'
import { sortBy } from 'lodash'

export function useLabelsListQuery(
  context: LabelsContext,
  refetchOnMount = true,
) {
  const result = useQuery<LabelTagListItem[]>(
    labelsQueryKey.list(context),
    async () => {
      const response = await getLabels(context)
      return sortBy(response.data.result, 'id')
    },
    {
      keepPreviousData: true,
      refetchOnMount,
      retryOnMount: refetchOnMount,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  )

  return result
}
