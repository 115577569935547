import React from 'react'
import type { PopulateForwardButtonDefinition } from './types'
import {
  StyledMenuRoot,
  StyledMenuContainer,
  StyledMenuButton,
} from './Menu.styled'
import { Grid } from '@mui/material'
import type { PopoverProps } from '@mui/material/Popover'
import type { BaseGridRowData } from '@fintastic/shared/ui/grid-framework'

export type MenuProps<TData extends BaseGridRowData = BaseGridRowData> = {
  buttons: PopulateForwardButtonDefinition<TData>[]
  onButtonClick: (button: PopulateForwardButtonDefinition<TData>) => void
  anchorEl?: PopoverProps['anchorEl']
  open: boolean
  onClose: PopoverProps['onClose']
}

export const Menu = <TData extends BaseGridRowData = BaseGridRowData>({
  buttons,
  onButtonClick,
  anchorEl,
  open,
  onClose,
}: MenuProps<TData>): JSX.Element => (
  <StyledMenuRoot open={open} anchorEl={anchorEl} onClose={onClose}>
    <StyledMenuContainer>
      <Grid container>
        {buttons.map(({ key, label, onClick, ...rest }, index) => (
          <Grid gap={2} xs={12} item key={key}>
            <StyledMenuButton
              variant="outlined"
              color="primary"
              {...rest}
              onClick={() => onButtonClick(buttons[index])}
            >
              {label}
            </StyledMenuButton>
          </Grid>
        ))}
      </Grid>
    </StyledMenuContainer>
  </StyledMenuRoot>
)
