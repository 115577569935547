import { styled, ButtonBase, Box } from '@mui/material'

export const StyledClearCellButtonRoot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-2px',
  left: '100%',
  zIndex: theme.zIndex.tooltip,

  padding: '0 3px',

  lineHeight: 0,
}))

export const StyledClearCellButtonButton = styled(ButtonBase)(({ theme }) => ({
  'border': `1px solid ${theme.palette.divider}`,

  'width': '20px',
  'height': '20px',

  'color': theme.palette.grey[400],
  'background': theme.palette.common.white,
  'borderRadius': '4px',
  'boxShadow':
    '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',

  '&:hover': {
    color: theme.palette.common.black,
  },
}))
