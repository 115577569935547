import React, { MouseEventHandler, useCallback } from 'react'
import { FormulaButton, FormulaIcon } from '@fintastic/web/feature/formulas'
import { useFormulasTabContext } from './formulas-tab-context'

export type LocalFormulaButtonProps = {
  metricId: string
  formulaIcon?: FormulaIcon
  disabled?: boolean
}

export const ColumnFormulaButton: React.FC<LocalFormulaButtonProps> = ({
  metricId,
  formulaIcon,
  disabled,
}) => {
  const { isOpen, tab, column, openColumnFormula, closeFormula } =
    useFormulasTabContext()

  const isActive = isOpen && tab === 'column' && column === metricId
  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (disabled) {
        return
      }
      isActive ? closeFormula() : openColumnFormula(metricId)
    },
    [disabled, isActive, closeFormula, metricId, openColumnFormula],
  )

  return (
    <FormulaButton
      disabled={disabled}
      icon={formulaIcon}
      sx={{ ml: '4px' }}
      onClick={handleClick}
      isActive={isActive}
    />
  )
}
