import { styled } from '@mui/material/styles'
import { Popover } from '@mui/material'

export const StyledAutoDropdownWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row nowrap;
  overflow: hidden;
`

export const StyledAutoDropdownRoot = styled('div')`
  gap: 6px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden; // important! used on calculation!
  position: relative;
`

export const StyledAutoDropdownItem = styled('div')`
  z-index: 2;
`

export const StyledAutoDropdownMenuButtonRoot = styled('div')`
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  width: 32px;
  height: 32px;

  button {
    width: 32px;
    height: 32px;
  }

  svg {
    width: 16px;
    transform: rotate(90deg);
  }
`

export const StyledAutoDropdownMenuPopover = styled(Popover)`
  .MuiPopover-paper {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05))
      drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.05));
    box-shadow: none;
    border-radius: 4px;
    padding: 6px 12px;

    .__autodropdown_item {
      min-width: 100%;
      margin: 8px 0;
    }
  }
`
