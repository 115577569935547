import React, { useCallback } from 'react'
import {
  RootView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

export const RootViewConnector: React.FC = () => {
  const { goTo } = useReportSettingsPanelNavigationContext()
  const goToMetrics = useCallback(
    () => goTo(settingsPanelNavigationPaths.metrics),
    [goTo],
  )

  const goToDimensions = useCallback(
    () => goTo(settingsPanelNavigationPaths.dimensions),
    [goTo],
  )

  return (
    <RootView.Readonly
      reportTitle={'Dummy'} // @todo RESP connect
      goToMetrics={goToMetrics}
      goToDimensions={goToDimensions}
    />
  )
}
