import { useLoadVersionUserLockersList } from '../api/useLoadVersionUserLockersList'
import { useMemo } from 'react'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import {
  VersionUserLockParsed,
  doesUserLockPreventEdit,
  parseVersionUserLock,
} from '@fintastic/web/util/versions'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { isEqual } from 'lodash'

export function useVersionUserLocks(
  versionsIds: string[],
): VersionUserLockParsed[] {
  const queries = useLoadVersionUserLockersList(versionsIds)
  const userInfo = useAuthUserInfo()

  const dataSource = useMemo(
    () => Object.fromEntries(queries.map((q, i) => [versionsIds[i], q.data])),
    [queries, versionsIds],
  )
  const data = useReferenceMemo(dataSource, isEqual)

  return useMemo<VersionUserLockParsed[]>(
    () =>
      versionsIds.map((versionId) => {
        const originalLock = data[versionId]
        const email = userInfo?.email

        if (!originalLock || !email) {
          return { ...NO_LOCK_FALLBACK, versionId: versionId || '' }
        }

        const lock = parseVersionUserLock(versionId, originalLock, email)
        const editIsBlocked = doesUserLockPreventEdit(lock)

        return { ...lock, editIsBlocked }
      }),
    [data, userInfo?.email, versionsIds],
  )
}

const NO_LOCK_FALLBACK: Omit<VersionUserLockParsed, 'versionId'> = {
  lockOps: [],
  originalLock: undefined,
  lockReasons: null,
  editIsBlocked: false,
}
