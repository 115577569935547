import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode'
import { Nullable } from '@fintastic/shared/util/functional-programming'
import {
  BLANK_VALUE,
  containsBlankValue,
  containsMaskedValue,
  MASKED_VALUE,
} from '@fintastic/web/util/blanks-and-masked'

type Comparator<TData> = (
  valueA: Nullable<TData>,
  valueB: Nullable<TData>,
  nodeA: RowNode<TData>,
  nodeB: RowNode<TData>,
  isDescending: boolean,
) => number

type Ordering = -1 | 0 | 1

const doQuickCompare = (a: number, b: number) => (a > b ? 1 : a < b ? -1 : 0)

// Basic implementation is copied from AgGrid code, but added correct handling of blanks and masked Symbols
export const defaultComparator: Comparator<
  | number
  | string
  | boolean
  | typeof MASKED_VALUE
  | typeof BLANK_VALUE
  | {
      toNumber: () => number
    }
> = (a, b): Ordering => {
  const aIsBlank =
    a === null ||
    a === undefined ||
    containsBlankValue(a) ||
    containsMaskedValue(a)
  const bIsBlank =
    b === null ||
    b === undefined ||
    containsBlankValue(b) ||
    containsMaskedValue(b)

  if (aIsBlank && bIsBlank) {
    return 0
  }
  if (aIsBlank) {
    return -1
  }
  if (bIsBlank) {
    return 1
  }

  let valueA = a
  let valueB = b
  if (typeof valueA === 'object' && 'toNumber' in valueA) {
    valueA = valueA.toNumber()
  }
  if (typeof valueB === 'object' && 'toNumber' in valueB) {
    valueB = valueB.toNumber()
  }

  if (typeof valueA !== 'string') {
    return doQuickCompare(valueA as number, valueB as number)
  }
  try {
    return valueA.localeCompare(valueB as string) as Ordering
  } catch (e) {
    return doQuickCompare(valueA as unknown as number, valueB as number)
  }
}
