import { useMemo } from 'react'
import type { MetricDataWithVersionId } from '../../types'
import { extractQueryParams } from './extractQueryParams'
import { useLoadWeightMetrics } from './useLoadWeightMetrics'

// @todo add tests
export function useWeightedAverageMetrics(metrics: MetricDataWithVersionId[]): {
  isLoading: boolean
  isFetching: boolean
  weightMetrics: MetricDataWithVersionId[]
  hasCalculatedWeights: boolean
} {
  const metricsWithWeightedAverageRollUp = useMemo(
    () =>
      metrics.filter(
        (m) => m.metric?.metadata.value.category_agg === 'weighted_avg',
      ),
    [metrics],
  )
  const enabled = metricsWithWeightedAverageRollUp.length > 0
  const queriesParams = useMemo(
    () => (enabled ? extractQueryParams(metrics) : []),
    [enabled, metrics],
  )

  const {
    isLoading,
    isFetching,
    metrics: metricsData,
  } = useLoadWeightMetrics(queriesParams, enabled)

  const hasCalculatedWeights = useMemo(
    () => metricsData.some((m) => m.metric?.source === 'calculated'),
    [metricsData],
  )

  return useMemo(
    () => ({
      isLoading: enabled && isLoading,
      isFetching: enabled && isFetching,
      weightMetrics: metricsData,
      hasCalculatedWeights,
    }),
    [enabled, isFetching, isLoading, metricsData, hasCalculatedWeights],
  )
}
