import { last } from 'lodash'
import {
  CalendarDatePickerConfig,
  PeriodSelection,
  PeriodsRange,
} from '@fintastic/web/util/period-selector'
import { isRangeBasedSelection } from './selection-guards'

export const convertPeriodSelectionToRange = (
  periodSelection: PeriodSelection,
  calendarConfig: CalendarDatePickerConfig,
): PeriodsRange => {
  if (isRangeBasedSelection(periodSelection)) {
    return periodSelection.range
  }

  if (!periodSelection.periods.length) {
    throw new Error('cannot convert empty selection to range')
  }

  const dimensionValueIds =
    calendarConfig
      .find((c) => c.dimension_id === periodSelection.dimensionId)
      ?.values?.map((d) => d.dimension_value_id) ?? []

  const from = dimensionValueIds.find((dim) =>
    periodSelection.periods.includes(dim),
  )
  const to = dimensionValueIds.findLast((dim) =>
    periodSelection.periods.includes(dim),
  )

  return [
    from || periodSelection.periods[0],
    to || last(periodSelection.periods) || periodSelection.periods[0],
  ] as const
}
