export const endpoints = {
  importConfigs: (versionId: string) =>
    `/planning/api/v2/versions/${versionId}/import_configs/`,
  updateImportConfig: (
    versionId: string,
    type: 'lists' | 'metrics',
    resourceId: string,
  ) =>
    `/planning/api/v2/versions/${versionId}/import_configs/${type}/${resourceId}/`,
}
