import * as echarts from 'echarts'
import { fintasticTheme } from './fintastic.theme'
import { oceanTheme } from './ocean.theme'
import { vintageTheme } from './vintage.theme'
import { springTheme } from './spring.theme'

echarts.registerTheme('ocean', oceanTheme)
echarts.registerTheme('vintage', vintageTheme)
echarts.registerTheme('spring', springTheme)
echarts.registerTheme('fintastic', fintasticTheme)
