import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { extendVersionPeriod } from '../../api/versions-management-api'
import { invalidateVersionManagementRelatedQueries } from '../../cache'

export function useExtendVersion(versionId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.extendVersion(versionId),
    async (endDate: string) => {
      const response = await extendVersionPeriod(versionId, endDate)
      return response.data
    },
    {
      onSuccess: async () => {
        await invalidateVersionManagementRelatedQueries(queryClient, [
          versionId,
        ])
      },
    },
  )
}
