import { Maybe } from '@fintastic/shared/util/types'
import React, { useEffect, useMemo } from 'react'
import { SyntaxLiteral } from '../../../syntax/types'
import { DATA_ATTRIBUTES } from '../const'
import { scrollContainerToNode } from '@fintastic/shared/util/web-api'
import { MIN_ROW_HEIGHT } from './const'

// @todo add tests
export function useScrollToHighlightedItem(
  options: SyntaxLiteral[],
  highlighted: Maybe<string>,
  rootRef: React.MutableRefObject<Maybe<HTMLElement>>,
) {
  const highlightedItemIndex = useMemo(
    () => options.findIndex((o) => o.literal === highlighted),
    [highlighted, options],
  )

  useEffect(() => {
    if (highlightedItemIndex === -1 || rootRef.current === null) {
      return
    }

    const itemNode = rootRef.current.querySelector(
      `[${DATA_ATTRIBUTES.listItem}]:nth-child(${highlightedItemIndex + 1})`,
    )
    if (itemNode === null) {
      return
    }

    scrollContainerToNode(
      rootRef.current,
      itemNode as HTMLElement,
      Math.floor(MIN_ROW_HEIGHT / 2),
    )
  }, [highlighted, highlightedItemIndex, options, rootRef])
}
