import { MetricDisplaySettings } from '@fintastic/web/util/metrics-and-lists'
import { format } from 'numerable'

export type CurrencySignPositionOption = {
  value: MetricDisplaySettings['currency_sign_position']
  label: string
}

export const createCurrencySignPositionOptions = (
  currencySymbol: string,
): CurrencySignPositionOption[] => [
  {
    value: 'before',
    label: format(1000.48, '~C~0,0[.]00').replace('~C~', currencySymbol),
  },
  {
    value: 'after',
    label: format(1000.48, '0,0[.]00 ~C~').replace('~C~', currencySymbol),
  },
]
