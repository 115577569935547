import { styled, Box, Popper } from '@mui/material'

export const StyledValidationErrorsPopperRoot = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
}))

export const StyledValidationErrorsPopperContent = styled(Box)(({ theme }) => ({
  marginBottom: 4,
  background: theme.palette.common.white,
  borderRadius: 4,
  boxShadow:
    '0px 4px 10px rgba(0, 0, 0, 0.1), 0px -4px 10px rgba(0, 0, 0, 0.1)',
}))
