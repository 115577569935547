import { Box } from '@mui/material'
import React from 'react'
import { PeriodSelectionLiteButtonSkeleton } from '../components/PeriodSelectionLiteButton/PeriodSelectionLiteButtonSkeleton'
import { PeriodSelectionStatusPanelSkeleton } from '../components/PeriodSelectionStatusPanel/PeriodSelectionStatusPanelSkeleton'

export type PeriodSelectorContainerSkeletonProps = {
  liteButton?: boolean
}

export const PeriodSelectorContainerSkeleton: React.FC<
  PeriodSelectorContainerSkeletonProps
> = ({ liteButton }) => (
  <Box width={liteButton ? undefined : 240}>
    {liteButton ? (
      <PeriodSelectionLiteButtonSkeleton />
    ) : (
      <PeriodSelectionStatusPanelSkeleton />
    )}
  </Box>
)
