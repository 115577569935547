import React, { useEffect, useMemo, useRef } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export function useFormulaContainerHeightObserver(
  ref: React.RefObject<Maybe<HTMLElement>>,
  callback: () => void,
) {
  const rendersCountRef = useRef(0)
  rendersCountRef.current += 1
  const observerCallbackDataRef = useRef({
    callback,
  })
  observerCallbackDataRef.current.callback = callback
  const isSubscribed = useRef(false)

  // @todo re-made with useResizeObserver hook
  const observer = useMemo(
    () =>
      new ResizeObserver(() => {
        observerCallbackDataRef.current.callback()
      }),
    [],
  )

  useEffect(() => {
    if (ref.current) {
      if (!isSubscribed.current) {
        observer.observe(ref.current)
        isSubscribed.current = true
      }
    } else {
      observer.disconnect()
      isSubscribed.current = false
    }
  })

  useEffect(
    () => () => {
      if (rendersCountRef.current < 2) {
        return
      }
      if (isSubscribed.current) {
        if (ref.current) {
          observer.unobserve(ref.current)
        } else {
          observer.disconnect()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
