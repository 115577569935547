import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo, useMemo } from 'react'
import {
  getLegendFormatName,
  getLegendPositionName,
} from '@fintastic/web/feature/charts'

export const DialogSectionLegendSubtitle: React.FC = memo(() => {
  const { data } = useWidgetSettingsDialogContext()

  const title = useMemo(
    () =>
      getLegendPositionName(data.chartSettings?.legendPosition) +
      ', ' +
      getLegendFormatName(data.chartSettings?.legendFormat),
    [data.chartSettings?.legendFormat, data.chartSettings?.legendPosition],
  )

  return <span>{title}</span>
})
