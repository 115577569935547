import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Board, BoardConfig } from '../../types'
import { StyledBoardDesignViewRoot } from './BoardDesignView.styled'
import { TEXT_WIDGET_TYPE } from '../widgets'
import { Widget } from '@fintastic/shared/ui/widgets-framework'
import { useBoardUpdateMutation } from '../../hooks/useBoardUpdateMutation'
import { Maybe } from '@fintastic/shared/util/types'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { v4 as uuid } from 'uuid'
import { buildWidgetContextActions } from '../BoardDesigner/widgets/board-context-actions'
import { ChangeWidgetDialogParams } from '../../types/board-design-types'
import { BoardSaveCurtain } from '../BoardDesigner/components/BoardSaveCurtain'
import { BoardTitleMenu } from './components/BoardTitle/BoardTitleMenu'
import { BoardDesignName } from './components/BoardName/BoardDesignName'
import { useDesignRouterBlocker } from '../../hooks/useDesignRouteBlocker'
import { BoardDesignerAddWidgetMenu } from './components/AddWidget/BoardDesignerAddWidgetMenu'
import { BoardDesignSaveControls } from './components/SaveControls/BoardDesignSaveControls'
import { StyledBoardTitleBox } from './components/BoardTitle/BoardTitle.styled'
import { BoardArchived } from '../BoardArchived/BoardArchived'
import { useBoardsParams } from '../../hooks/boards-params/hooks'
import { BoardDesignViewWidgetsArea } from './BoardDesignViewWidgetsArea'
import {
  BoardDesignDialogsHandler,
  BoardDesignViewDialogs,
} from './BoardDesignViewDialogs'
import { BoardDebug } from '../BoardDebug'
import { useLocalBoardLayout } from '../../hooks/useLocalBoardLayout'
import type { AxiosError } from 'axios'
import {
  getNewWidgetDefaultHeight,
  newWidgetSettingsFromDialog,
} from './utils/new-widget-utils'

export type BoardViewProps = {
  board: Board
  defaultVersions: string[]
  setDisplayMode: () => void
}

export const BoardDesignView: React.FC<BoardViewProps> = ({
  board,
  setDisplayMode,
  defaultVersions,
}) => {
  const dialogHandleRef = useRef<Maybe<BoardDesignDialogsHandler>>(null)

  const updateBoardMutation = useBoardUpdateMutation({
    boardId: board.id,
    refetchItem: true,
    refetchList: true,
    updateItemCache: true,
    updateListCache: true,
  })

  const [boardConfig, setBoardConfig] = useState<BoardConfig>(() => {
    const config = structuredClone(board.config)

    if (!config.widgets) {
      config.widgets = []
    }

    if (!config.layout) {
      config.layout = []
    }

    return config
  })

  const [designChanged, setDesignChanged] = useState(false)
  const [boardName, setBoardName] = useState(board.name)
  const [nameChanged, setNameChanged] = useState(false)

  const handleNameChange = useCallback(
    (value: string) => {
      setBoardName(value)
      setNameChanged(value !== board.name)
    },
    [setBoardName, setNameChanged, board.name],
  )

  useDesignRouterBlocker(designChanged || nameChanged)

  const handleRemoveConfirm = useCallback(
    (id: string) => {
      const config: BoardConfig = {
        widgets: (boardConfig?.widgets || []).filter((w) => w.id !== id),
        layout: (boardConfig?.layout || []).filter((l) => l.i !== id),
      }

      setDesignChanged(true)
      setBoardConfig(config)
    },
    [boardConfig, setDesignChanged, setBoardConfig],
  )

  const handleCancelDesignConfirm = useCallback(() => {
    setDisplayMode()
  }, [setDisplayMode])

  const [designBusy, setDesignBusy] = useState(false)
  const [, setLocalLayoutOverride] = useLocalBoardLayout(board)

  const handleSaveDesign = useCallback(async () => {
    if (!boardConfig) {
      toast.error('Cannot save design - no config!')
      return
    }

    setDesignBusy(() => true)

    try {
      if (nameChanged) {
        await updateBoardMutation.mutateAsync({ name: boardName })
      }

      if (designChanged) {
        await updateBoardMutation.mutateAsync({ config: boardConfig })
        setLocalLayoutOverride(boardConfig.layout)
      }

      toast.success('Changes to Board were saved successfully.')
      setDisplayMode()
    } catch (e: unknown) {
      if ((e as AxiosError)?.response?.status === 409) {
        toast.error('A Board with this name already exists.')
      } else {
        toast.error('Error on saving Board')
      }
      console.log(e)
      setDesignBusy(() => false)
    }

    setTimeout(() => {
      setDesignBusy(() => false)
    }, 500)
  }, [
    boardConfig,
    nameChanged,
    designChanged,
    setDisplayMode,
    updateBoardMutation,
    boardName,
    setLocalLayoutOverride,
  ])

  const handleUpdateDesign = useCallback(
    (config: BoardConfig, manualChange = false) => {
      setBoardConfig({
        widgets: config.widgets ? [...config.widgets] : [],
        layout: config.layout ? [...config.layout] : [],
      })

      if (manualChange) {
        setDesignChanged(true)
      }
    },
    [setBoardConfig, setDesignChanged],
  )

  const handleAddOrChangeConfirm = useCallback(
    (params: ChangeWidgetDialogParams) => {
      if (!boardConfig?.widgets || !boardConfig?.layout) {
        toast.error('Invalid designer state!')
        return
      }

      const isNewWidget = !params.widgetId
      const widgetType = params.type || TEXT_WIDGET_TYPE

      const newWidgetSettings = newWidgetSettingsFromDialog(params)
      if (!newWidgetSettings) {
        toast.error('Invalid or unknown widget!')
        return
      }

      if (!isNewWidget) {
        const widgetIdx = (boardConfig.widgets || []).findIndex(
          (w) => w.id === params.widgetId,
        )
        if (widgetIdx === -1) {
          throw new Error('Widget not found!')
        }

        const updatedWidgets = (boardConfig.widgets || []).map((w) =>
          w.id === params.widgetId
            ? { ...w, settings: { ...w.settings, ...newWidgetSettings } }
            : w,
        )

        setBoardConfig((c) => ({
          widgets: updatedWidgets,
          layout: c.layout,
        }))

        setDesignChanged(true)

        return
      }

      const newWidget: Widget = {
        id: params.widgetId || uuid(),
        type: widgetType,
        settings: newWidgetSettings,
        configurable: false,
      }

      const layout = boardConfig?.layout || []

      const maxY =
        layout.length > 0
          ? layout.reduce((prev, cur) => Math.max(prev, cur.y + cur.w), 0)
          : 0

      const newLayout = {
        h: getNewWidgetDefaultHeight(widgetType),
        i: newWidget.id,
        w: 12,
        x: 0,
        y: maxY > 0 ? maxY + 1 : 0,
        moved: false,
        static: false,
      }

      setBoardConfig((c) => ({
        widgets: c.widgets ? [...c.widgets, newWidget] : [newWidget],
        layout: c.layout ? [...c.layout, newLayout] : [newLayout],
      }))

      setDesignChanged(true)

      // scroll to bottom (new widgets are on bottom)
      setTimeout(() => {
        const el = document.querySelector('[data-testid="page-layout"]')
        if (el) {
          el.scrollTop = el.scrollHeight
        }
      }, 100)
    },
    [boardConfig],
  )

  const getReferenceVersion = useCallback(() => {
    if (!boardConfig) {
      return ''
    }
    const firstWidgetWithVersion = (boardConfig.widgets || []).find(
      (w) => !!w.settings.versionId,
    )
    return firstWidgetWithVersion
      ? (firstWidgetWithVersion.settings.versionId as string)
      : ''
  }, [boardConfig])

  const boardsParams = useBoardsParams(defaultVersions)

  const widgetContextMenuBuilder = useMemo(
    () => (widget: Widget) =>
      buildWidgetContextActions(widget, {
        onChange: dialogHandleRef.current?.changeDialog.openWithParams,
        onDelete: dialogHandleRef.current?.removeDialog.openWithParams,
      }),
    [],
  )

  if (board.is_deleted) {
    return <BoardArchived />
  }

  return (
    <>
      <StyledBoardDesignViewRoot data-testid="board-view">
        <StyledBoardTitleBox data-testid="board-view-title-panel">
          <BoardDesignName
            boardName={boardName}
            boardId={board.id}
            onUpdateName={handleNameChange}
          />

          <BoardDesignerAddWidgetMenu
            changeDialog={dialogHandleRef.current?.changeDialog}
            getReferenceVersion={getReferenceVersion}
            directAddWidget={handleAddOrChangeConfirm}
          />

          <BoardDesignSaveControls
            onSave={handleSaveDesign}
            changed={designChanged || nameChanged}
            onCancel={setDisplayMode}
          />

          <BoardTitleMenu />
        </StyledBoardTitleBox>

        {designBusy && <BoardSaveCurtain show={true} />}

        <BoardDesignViewWidgetsArea
          boardConfig={boardConfig}
          boardsParams={boardsParams}
          widgetContextMenuBuilder={widgetContextMenuBuilder}
          handleUpdateDesign={handleUpdateDesign}
        />
      </StyledBoardDesignViewRoot>

      <BoardDebug board={board} isDesignMode={true} />

      <BoardDesignViewDialogs
        onCancelDesignConfirm={handleCancelDesignConfirm}
        onRemoveConfirm={handleRemoveConfirm}
        onAddOrChangeConfirm={handleAddOrChangeConfirm}
        ref={dialogHandleRef}
      />
    </>
  )
}
