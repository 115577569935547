import { styled, Box, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledEditorRoot = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  width: '100%',
  position: 'relative',
}))

export const StyledEditorHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  width: '100%',
}))

export const StyledEditorTitle = styled(Typography)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  color: theme.palette.text.secondary,
  minHeight: 32,
}))

export const StyledEditorVersionSelector = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
}))

export const StyledEditorHeaderLeft = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  marginRight: theme.spacing(2),
}))

export const StyledEditorHeaderRight = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  marginLeft: 'auto',
}))

export const StyledEditorMain = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export const StyledEditorFooter = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export const StyledEditorValidation = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  width: '100%',
}))
