import { useAuthAccessToken } from '@fintastic/web/feature/auth'
import React, { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { isEqual, sortBy } from 'lodash'
import { type WidgetLayout } from '@fintastic/shared/ui/widgets-framework'
import { useLocalStorage } from 'usehooks-ts'
import { Board, BoardConfig } from '../types'

// because, e.g.,
// 1. we have custom local layout for 3 widgets, local design used
// 2. we're adding one widget -> global design used because lists of layouts are differ
// 3. we're removing the widget -> return to previous local layout because lists are the same now
export const checkLocalLayoutValid = (
  referenceLayout?: Maybe<WidgetLayout[]>,
  localLayout?: Maybe<WidgetLayout[]>,
) => {
  if (!referenceLayout || !localLayout) {
    return false
  }

  if (!Array.isArray(referenceLayout) || !Array.isArray(localLayout)) {
    return false
  }

  const referenceLayoutIds = (referenceLayout || []).map((w) => w.i)
  const localLayoutIds = (localLayout || []).map((w) => w.i)
  return isEqual(sortBy(referenceLayoutIds), sortBy(localLayoutIds))
}

export const useLocalBoardLayout = (
  board: Board,
): readonly [
  BoardConfig['layout'],
  React.Dispatch<React.SetStateAction<BoardConfig['layout']> | undefined>,
] => {
  const { user } = useAuthAccessToken()

  const [cachedLayout, setCachedLayout] = useLocalStorage<
    BoardConfig['layout']
  >(`boardLayout_${board.id}_${user?.email}`, defaultLocalLayoutOverride)

  const validatedCachedValue = useMemo(
    () =>
      checkLocalLayoutValid(board.config.layout, cachedLayout)
        ? cachedLayout
        : defaultLocalLayoutOverride,
    [board.config.layout, cachedLayout],
  )

  return [validatedCachedValue, setCachedLayout] as const
}

const defaultLocalLayoutOverride: BoardConfig['layout'] = []
