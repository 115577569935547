import { ThemeComponents } from './types'
import { MuiButton } from './MuiButton'
import { MuiIconButton } from './MuiIconButton'
import { MuiSvgIcon } from './MuiSvgIcon'
import { MuiButtonBase } from './MuiButtonBase'
import { MuiTabs } from './MuiTabs'
import { MuiTab } from './MuiTab'
import { MuiSnackbar } from './MuiSnackbar'
import { MuiSnackbarContent } from './MuiSnackbarContent'
import { MuiMenu } from './MuiMenu'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiPopover } from './MuiPopover'
import { MuiSwitch } from './MuiSwitch'
import { MuiSelect } from './MuiSelect'
import { MuiInputBase } from './MuiInputBase'
import { MuiOutlinedInput } from './MuiOutlinedInput'
import { MuiCheckbox } from './MuiCheckbox'
import { MuiAlert } from './MuiAlert'
import { MuiAlertTitle } from './MuiAlertTitle'
import { MuiModal } from './MuiModal'
import { MuiDialog } from './MuiDialog'
import { MuiChip } from './MuiChip'

export const themeComponentsOverride: ThemeComponents = {
  MuiButtonBase,
  MuiButton,
  MuiIconButton,
  MuiSvgIcon,
  MuiTabs,
  MuiTab,
  MuiSnackbar,
  MuiSnackbarContent,
  MuiMenu,
  MuiMenuItem,
  MuiPopover,
  MuiSwitch,
  MuiSelect,
  MuiInputBase,
  MuiOutlinedInput,
  MuiCheckbox,
  MuiAlert,
  MuiAlertTitle,
  MuiModal,
  MuiDialog,
  MuiChip,
}
