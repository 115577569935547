import {
  StyledSaveBox,
  StyledSaveButton,
} from './BoardDesignSaveControls.styled'
import { Button } from '@mui/material'
import { memo } from 'react'

type BoardDesignSaveControlProps = {
  changed: boolean
  onSave: () => void
  onCancel: () => void
}

export const BoardDesignSaveControls: React.FC<BoardDesignSaveControlProps> =
  memo(({ onSave, onCancel, changed }) => (
    <StyledSaveBox>
      <StyledSaveButton
        id="design-save-button"
        variant="outlined"
        disabled={!changed}
        onClick={onSave}
      >
        Save
      </StyledSaveButton>
      <Button id="design-cancel-button" variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
    </StyledSaveBox>
  ))
