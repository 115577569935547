import { createKeys } from './utils'

export const chartNumberFormats = {
  taken: 'Taken from source',
  thousands: '1,000 → 1K',
  millions: '1,000,000 → 1M',
}

export type ChartNumberFormat = keyof typeof chartNumberFormats

export const chartNumberFormatExamples: Record<ChartNumberFormat, string> = {
  taken: 'Taken from source',
  thousands: '10K',
  millions: '10M',
}

export const chartNumberFormatKeys: ChartNumberFormat[] =
  createKeys<ChartNumberFormat>(chartNumberFormats)

export function isChartNumberFormat(
  value: unknown,
): value is ChartNumberFormat {
  return chartNumberFormats[value as ChartNumberFormat] !== undefined
}

export const chartDecimalPlaces = {
  taken: 'Taken from source',
  d0: '0 digits',
  d1: '1 digit',
  d2: '2 digits',
  d3: '3 digits',
  d4: '4 digits',
  d5: '5 digits',
  d6: '6 digits',
}

export type ChartDecimalPlaces = keyof typeof chartDecimalPlaces

export const chartDecimalPlacesKeys: ChartDecimalPlaces[] =
  createKeys<ChartDecimalPlaces>(chartDecimalPlaces)

export function isChartDecimalPlaces(
  value: unknown,
): value is ChartDecimalPlaces {
  return chartDecimalPlaces[value as ChartDecimalPlaces] !== undefined
}

export const defaultChartNumberFormat: ChartNumberFormat = 'taken'

export const defaultChartDecimalPlaces: ChartDecimalPlaces = 'taken'

export function getYAxisFormatName(numberFormat?: ChartNumberFormat) {
  return chartNumberFormatExamples[
    isChartNumberFormat(numberFormat) ? numberFormat : defaultChartNumberFormat
  ]
}

export function getYAxisDecimalPlacesName(decimalPlaces?: ChartDecimalPlaces) {
  return chartDecimalPlaces[
    isChartDecimalPlaces(decimalPlaces)
      ? decimalPlaces
      : defaultChartDecimalPlaces
  ]
}
