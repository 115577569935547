import React, { useEffect, useMemo, useRef } from 'react'
import { MemoryRouter, useLocation } from '@fintastic/shared/util/memory-router'
import {
  SectionedModalNavigationContext,
  SectionedModalRoutesContext,
  SectionedModalRoutesContextValue,
} from '../hooks'
import {
  SectionedModalNavigationPath,
  SectionedModalSectionDefinition,
} from '../types'
import {
  isFirstSection,
  makeInitialPath,
  prepareWrappedNavRoutes,
} from '../utils'

export type SectionedContextWrapperProps = {
  initialPath?: SectionedModalNavigationPath
  routes: SectionedModalSectionDefinition[]
  allowNavigation?: boolean
  allowLeaveSection?: (
    sectionPath: SectionedModalSectionDefinition['path'],
  ) => boolean
  onRequestClose: () => void
  title?: string
}

export const SectionedContextWrapper: React.FC<
  SectionedContextWrapperProps
> = ({
  initialPath,
  routes,
  onRequestClose,
  title,
  allowNavigation = true,
  allowLeaveSection,
}) => {
  const defaultInitialLocation = useRef(makeInitialPath(routes, initialPath)) // ! must be stable singleton
  const initialRoutePath = useRef(initialPath)

  const location = useLocation(defaultInitialLocation.current)

  const wrappedRoutes = useMemo(() => prepareWrappedNavRoutes(routes), [routes])

  const sectionedModalRoutesContextValue =
    useMemo<SectionedModalRoutesContextValue>(
      () => ({
        onRequestClose,
        title,
        routes,
        allowNavigation,
        allowLeaveSection,
        path: location.path ?? '',
        sectionCount: routes.length,
        isFirstSection: isFirstSection(location?.path, routes),
      }),
      [
        allowLeaveSection,
        allowNavigation,
        location.path,
        onRequestClose,
        routes,
        title,
      ],
    )

  useEffect(() => {
    if (initialRoutePath.current !== initialPath) {
      initialRoutePath.current = initialPath
      location.reset()
      location.goTo(initialPath || routes[0].path)
    }
  }, [initialPath, location, routes])

  return (
    <SectionedModalNavigationContext.Provider value={location}>
      <SectionedModalRoutesContext.Provider
        value={sectionedModalRoutesContextValue}
      >
        <MemoryRouter routes={wrappedRoutes} location={location} />
      </SectionedModalRoutesContext.Provider>
    </SectionedModalNavigationContext.Provider>
  )
}
