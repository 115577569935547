import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

export type CancelListEditingModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
}

export const CancelListEditingModal: React.FC<CancelListEditingModalProps> = ({
  isOpen,
  onConfirm,
  onRequestClose,
}) => (
  <ConfirmationPopup
    open={isOpen}
    onRequestClose={onRequestClose}
    onConfirm={onConfirm}
    title={'Cancel List editing?'}
    description="Unsaved data and settings will be lost."
  >
    <ConfirmationPopup.DeclineButton variant="contained" color="primary">
      Continue editing
    </ConfirmationPopup.DeclineButton>
    <ConfirmationPopup.ConfirmButton sx={{ ml: 1 }}>
      Yes, Cancel
    </ConfirmationPopup.ConfirmButton>
  </ConfirmationPopup>
)
