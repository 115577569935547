import React, { useCallback, useMemo } from 'react'
import { useMemoryRouterContext } from '@fintastic/shared/util/memory-router'
import {
  NavButton,
  NavButtonsContainer,
} from '@fintastic/shared/ui/view-layout'
import { SubsectionIcon } from './SubsectionIcon'
import {
  chartSettingsDialogGeneralRoute,
  chartSettingsDialogRoutes,
} from '@fintastic/web/feature/charts'

const iconSx = { width: 40, minWidth: 40 }

export const DialogSectionRoot: React.FC = () => {
  const { goTo } = useMemoryRouterContext()

  const handleGoToSection = useCallback(
    (sectionId: string) => {
      goTo(sectionId)
    },
    [goTo],
  )

  const actualRoutes = useMemo(
    () => [chartSettingsDialogGeneralRoute, ...chartSettingsDialogRoutes],
    [],
  )

  return (
    <NavButtonsContainer>
      {actualRoutes.map((sm) => (
        <NavButton
          key={sm.path}
          onClick={() => handleGoToSection(sm.path)}
          secondaryLabel={sm.subtitleComponent || sm.subtitle || ''}
          primaryLabel={sm.title || 'No title'}
          icon={sm.icon ? sm.icon : <SubsectionIcon />}
          iconSx={iconSx}
        />
      ))}
    </NavButtonsContainer>
  )
}
