import { RefCallback, useCallback, useRef } from 'react'

export function useAttachEventListenerToNativeDom<
  TEventName extends keyof HTMLElementEventMap,
>(
  eventName: TEventName,
  handler: (event: HTMLElementEventMap[TEventName]) => void,
  stopBubbling = false,
) {
  const handlerCallback = useCallback(
    (event: HTMLElementEventMap[TEventName]) => {
      if (stopBubbling) {
        event.stopPropagation()
      }
      handler(event)
    },
    [handler, stopBubbling],
  )

  const handlerRef = useRef(handlerCallback)
  handlerRef.current = handlerCallback

  const callbackRef: RefCallback<HTMLElement> = useCallback(
    (domNode) => {
      if (!domNode) {
        return
      }
      // need to clean up every time to avoid outdated event handlers
      domNode.removeEventListener(eventName, handlerRef.current)
      domNode.addEventListener(eventName, handlerRef.current)
    },
    [eventName],
  )

  return callbackRef
}
