import { CalculationErrorCode, CalculationErrorSeverity } from './types'

const DEFAULT_CALCULATION_ERROR_CODE: CalculationErrorCode = 300

const calculationErrors: Record<
  CalculationErrorCode,
  {
    severity: CalculationErrorSeverity
    message: string
  }
> = {
  '200': {
    severity: 'high',
    message: 'Unexpected argument',
  },
  '201': {
    severity: 'high',
    message: 'Object does not exist',
  },
  '202': {
    severity: 'low',
    message: 'Formula results in an empty list',
  },
  '203': {
    severity: 'high',
    message: 'Formula results in a circular dependancy',
  },
  '204': {
    severity: 'high',
    message:
      'Formula affects dependant calculations: show the first dependancy',
  },
  '300': {
    severity: 'high',
    message: 'Formula error',
  },
  '301': {
    severity: 'high',
    message: 'Metric size exceeds limit',
  },
  '302': {
    severity: 'low',
    message: 'Calculation results in "0" for one or more metrics/lists',
  },
}

const errorType = (errorCode: CalculationErrorCode): string => {
  if (errorCode < 100) {
    return 'Unexpected error'
  }

  // Such errors must be handled on UI
  if (errorCode < 200) {
    return 'Unexpected syntax error'
  }

  if (errorCode < 300) {
    return 'Calculation error'
  }

  if (errorCode < 400) {
    return 'General error'
  }

  return 'Unexpected general error'
}

export const mapCalculationErrorCode = (
  errorCode: CalculationErrorCode,
): {
  severity: CalculationErrorSeverity
  message: string
} => {
  const caclulationError =
    calculationErrors[errorCode] ||
    calculationErrors[DEFAULT_CALCULATION_ERROR_CODE]

  return {
    message: `${errorType(errorCode)} (${errorCode}) - ${
      caclulationError.message
    }`,
    severity: caclulationError.severity,
  }
}
