import { sparseToCompact } from './expand-metric-data'
import { MetricGridRow } from './types'
import { Maybe } from '@fintastic/shared/util/types'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { createFieldKey } from './field-key'

/*
 * Special fast parsing for lists.
 * 1. We assume that each column must have range dimension
 * 2. We assume that for list columns, we do not have blank rows
 * 3. We assume that list columns do not have any other dims except time dim
 * @author: Ben Gilat
 * @co-author: Ilya Pomazkin
 */
export function parseList(
  columns: Metric[],
  rowDimensionId: string,
  versionId: string,
  periodDimId?: string,
): Maybe<MetricGridRow[]> {
  let rows: Maybe<MetricGridRow[]> = null
  const allColumnsCalculated = columns.every((c) => c.source === 'calculated')

  for (let i = 0; i < columns.length; i++) {
    const metric = columns[i]

    if (metric.data === null) {
      continue
    }

    const compactMetric = sparseToCompact(
      {
        metricId: metric.id,
        metricData: metric.data,
        metricMetadata: metric.metadata,
        metricSource: metric.source,
        versionId,
      },
      allColumnsCalculated,
    )

    if (
      compactMetric.indexes.length === 0 ||
      compactMetric.dimensions.length === 0
    ) {
      continue
    }

    const rowDimIdx = compactMetric.indexes.indexOf(rowDimensionId)
    const rowDimVals = compactMetric.dimensions[rowDimIdx]

    if (rows === null) {
      rows = new Array(rowDimVals.length)
    }

    for (let rowIdx = 0; rowIdx < rows.length; rowIdx += 1) {
      if (rows[rowIdx] === undefined) {
        rows[rowIdx] = {} as MetricGridRow
      }
      const row = rows[rowIdx]
      const metricTimeDimIdx = periodDimId
        ? compactMetric.indexes.indexOf(periodDimId)
        : -1
      if (metricTimeDimIdx === -1) {
        row[createFieldKey(versionId, metric.id)] = compactMetric.values[rowIdx]
      } else {
        const numDates = compactMetric.dimensions[metricTimeDimIdx].length
        for (
          let dimValIdx = 0;
          dimValIdx < compactMetric.dimensions[metricTimeDimIdx].length;
          dimValIdx += 1
        ) {
          const periodDimValId =
            compactMetric.dimensions[metricTimeDimIdx][dimValIdx]
          row[createFieldKey(versionId, metric.id, periodDimValId)] =
            metricTimeDimIdx
              ? compactMetric.values[rowIdx * numDates + dimValIdx]
              : compactMetric.values[rowIdx + dimValIdx * rowDimVals.length]
        }
      }
    }

    for (let rowIdx = 0; rowIdx < rows.length; rowIdx += 1) {
      const row = rows[rowIdx]
      row[rowDimensionId] = rowDimVals[rowIdx]
      row['_rowId'] = rowDimVals[rowIdx]
    }
  }

  return rows
}
