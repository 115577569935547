import { VersionEntitiesContextValue } from '@fintastic/web/data-access/versions'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import { TimeDimensionWrapper } from '@fintastic/web/util/dimensions'
import { resolutionSupportsAggregatedEditing } from '@fintastic/web/util/period-selector'

export const isValueColumnAggregationCellEditingAllowed = (
  versionsEntities: VersionEntitiesContextValue,
  versionId: string,
  metricBaseTimeDimensionId: TimeDimensionId,
): boolean => {
  const currentVersionEntities = versionsEntities.entities[versionId]
  const baseTimeDimension = currentVersionEntities?.findDimensionById(
    metricBaseTimeDimensionId,
  )?.dimension

  if (
    !baseTimeDimension ||
    !(baseTimeDimension instanceof TimeDimensionWrapper)
  ) {
    throw new Error('Base time dimension is type of Time for metric column')
  }

  return resolutionSupportsAggregatedEditing(baseTimeDimension.resolution)
}
