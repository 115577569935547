import { alpha, Box, BoxProps, styled } from '@mui/material'
import React from 'react'

export const StyledIconContainer = styled(
  (
    props: BoxProps & { isLocked?: boolean; disabled?: boolean; size?: number },
  ) => {
    const { isLocked, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) =>
      propName !== 'isLocked' && propName !== 'disabled' && propName !== 'size',
  },
)`
  display: inline-flex;
  align-self: center;
  justify-self: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  svg {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    fill: ${({ theme, isLocked, disabled }) =>
      disabled
        ? alpha(theme.palette.text.secondary, 0.4)
        : isLocked
        ? theme.palette.primary.main
        : theme.palette.text.secondary}
  }
}
`
