import React, { forwardRef } from 'react'
import { WidgetMetricIcon } from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

export type MetricIconProps = {
  size?: SvgIconProps['fontSize']
}

export const MetricIcon = forwardRef<SVGSVGElement, MetricIconProps>(
  ({ size, ...props }, ref) => (
    <WidgetMetricIcon
      {...props}
      fontSize={size}
      ref={ref}
      sx={{
        color: '#2C45FE',
      }}
    />
  ),
)
