import {
  defaultDiffMode,
  DiffMode,
  diffModes,
} from '@fintastic/web/util/versions'

export const deeplinkDiffToNormalDiff = (
  deeplinkDiff: string[],
): Array<[string, string, DiffMode]> =>
  deeplinkDiff?.map((diff) => {
    const chunks = diff?.split(DEEPLINK_DIFF_DELIMETER)

    if (chunks.length === 2) {
      // fallback - no diff mode, default
      return [chunks[0], chunks[1], defaultDiffMode] as [
        string,
        string,
        DiffMode,
      ]
    }

    if (!diffModes.includes(chunks[2] as DiffMode)) {
      // fallback - no diff mode
      return [chunks[0], chunks[1], defaultDiffMode] as [
        string,
        string,
        DiffMode,
      ]
    }

    return chunks as [string, string, DiffMode]
  }) || []

export const normalDiffToDeeplinkDiff = (
  diffs: Array<[string, string, DiffMode]>,
): string[] => diffs.map((d) => d.join(DEEPLINK_DIFF_DELIMETER))

export const DEEPLINK_DIFF_DELIMETER = '_'
