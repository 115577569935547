import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectDimensionAccessForCurrentGroup,
  selectTableAccessListForCurrentGroup,
} from '../ducks'
import { DimensionMap, TableAccess } from '../types'

export const useTableAccessList = (): {
  tableAccessList: TableAccess[]
  defaultDimensionAccessConfig: DimensionMap
} => {
  const tableAccessList = useSelector(selectTableAccessListForCurrentGroup)

  const defaultDimensionAccessConfig = useSelector(
    selectDimensionAccessForCurrentGroup,
  )

  return useMemo(
    () => ({
      tableAccessList,
      defaultDimensionAccessConfig,
    }),
    [tableAccessList, defaultDimensionAccessConfig],
  )
}
