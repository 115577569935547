import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

export const StyledDetailsCellRoot = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  height: 100%;
`

export const StyledDetailsCellContent = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  height: 100%;
  margin: 4px 0;
`

export const StyledDetailsCellValue = styled(
  (props: { empty?: boolean } & BoxProps) => {
    const { empty, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'empty',
  },
)`
  display: inline-flex;
  flex-flow: row nowrap;
  height: auto;
  line-height: 1;
  padding: 4px 0;
  color: ${({ theme, empty }) =>
    empty ? theme.palette.text.disabled : theme.palette.text.primary};
`
