import React from 'react'
import {
  DimensionSettingsView,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

const dimension = {
  id: 'Dim.Country',
  label: 'Country',
}

// @todo RESP connect
export const DimensionSettingsViewConnector: React.FC = () => {
  const { goBack } = useReportSettingsPanelNavigationContext()

  return (
    <DimensionSettingsView.Readonly
      dimension={dimension}
      goBack={goBack}
      dimensionNameOverride={'Override name'}
      hasDimensionDependency={true}
      mappingList={null}
    />
  )
}
