import React, { useCallback } from 'react'
import {
  StyledCheckboxFieldCheckbox,
  StyledCheckboxFieldLabel,
  StyledCheckboxFieldRoot,
} from './CheckboxField.styled'

export type CheckboxFieldProps = {
  label: string
  checked: boolean
  onChange: (newChecked: boolean) => void
  ['data-testid']?: string
}

export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const { label, checked, onChange } = props

  const handleChange = useCallback(
    (event: React.SyntheticEvent, checked: boolean) => {
      onChange(checked)
    },
    [onChange],
  )

  return (
    <StyledCheckboxFieldRoot>
      <StyledCheckboxFieldLabel
        checked={checked}
        onChange={handleChange}
        label={label}
        control={
          <StyledCheckboxFieldCheckbox data-testid={props['data-testid']} />
        }
      />
    </StyledCheckboxFieldRoot>
  )
}
