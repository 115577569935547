import {
  MetricChartDimensions,
  MetricDimensionContent,
  useMetricDimensions,
} from '@fintastic/web/feature/charts'
import { useEffect, useMemo } from 'react'

export type UseCurrentMetricDimensionsParams = {
  metricId?: string
  versionId?: string
  storedDimensions?: MetricChartDimensions
  updateDimensions: (dimensions: MetricChartDimensions) => void
}

export const useCheckCurrentMetricDimensions = ({
  metricId,
  versionId,
  storedDimensions,
  updateDimensions,
}: UseCurrentMetricDimensionsParams) => {
  const { dimensions, loading, error } = useMetricDimensions(
    metricId || '',
    versionId || '',
  )

  useEffect(() => {
    if (loading) {
      return
    }
    if (!dimensions || dimensions.length === 0) {
      if (error && (storedDimensions || []).length > 0) {
        updateDimensions([])
      }
      return
    }
    let shouldUpdate = false
    // 1. if no dimensions yet (new widget) - put data from server-side
    if (!storedDimensions || (storedDimensions || []).length === 0) {
      shouldUpdate = true
    }
    // check all dims from local settings are in retrieved settings
    // they could be reordered - no problem
    // 2. lengths differ - replace with server-side data
    if (
      !shouldUpdate &&
      dimensions.length !== (storedDimensions || []).length
    ) {
      shouldUpdate = true
    }
    // 3. check all in server-side data are present in local
    if (
      !shouldUpdate &&
      !(storedDimensions || []).every((initialDim: MetricDimensionContent) =>
        dimensions.find((d) => d.id === initialDim.id),
      )
    ) {
      shouldUpdate = true
    }
    if (shouldUpdate) {
      updateDimensions(dimensions)
    }
  }, [
    metricId,
    versionId,
    storedDimensions,
    updateDimensions,
    dimensions,
    error,
    loading,
  ])

  return useMemo(
    () => ({
      loading,
      error,
      dimensions,
    }),
    [loading, dimensions, error],
  )
}
