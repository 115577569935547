import { Widget, WidgetLayout } from '../types'
import { MutableRefObject, useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export type UseWidgetLayoutStateParams = {
  widget: Widget
  layout: WidgetLayout
  measurableBlockRef: MutableRefObject<Maybe<HTMLDivElement>>
}

export function useWidgetLayoutState(params: UseWidgetLayoutStateParams) {
  const { widget, layout, measurableBlockRef } = params
  const { minHeightInPixels, minWidthInPixels } = widget

  const isCollapsedVert = useMemo(() => layout.h === 1, [layout.h])
  const [isEnoughSpace, setIsEnoughSpace] = useState(true)

  useEffect(() => {
    if (!measurableBlockRef.current) {
      return
    }

    if (!minHeightInPixels && !minWidthInPixels) {
      setIsEnoughSpace(true)
      return
    }

    const blockBcr = measurableBlockRef.current.getBoundingClientRect()

    setIsEnoughSpace(
      blockBcr.width >= (minWidthInPixels || 1) &&
        blockBcr.height >= (minHeightInPixels || 1),
    )
  }, [
    layout.h,
    layout.w,
    measurableBlockRef,
    minHeightInPixels,
    minWidthInPixels,
  ])

  return useMemo(
    () => ({
      isCollapsedVert,
      isEnoughSpace,
    }),
    [isCollapsedVert, isEnoughSpace],
  )
}
