import { ThemeComponents } from './types'

export const MuiSelect: ThemeComponents['MuiSelect'] = {
  styleOverrides: {
    select: ({ theme, ownerState, ...rest }) => ({
      'minHeight': 40 - 16,
      'padding': `${theme.spacing(1, 1.8, 1, 1.8)}`,
      'lineHeight': '24px',

      '&.MuiInputBase-inputSizeSmall': {
        minHeight: 32 - 8,
        padding: `${theme.spacing(0.5, 3, 0.5, 13 / 8)}`,
        lineHeight: '24px',
      },
    }),
    icon: ({ theme }) => ({
      color: theme.palette.text.secondary,
    }),
  },
}
