import { useMemo } from 'react'
import type { WeightedAverageAgGridExtendedContextObject } from '@fintastic/shared/ui/ag-grid'
import { weightsGetter } from './weightsGetter'

export function useWeightedAverageAgContext(): WeightedAverageAgGridExtendedContextObject {
  return useMemo(
    () => ({
      weightsGetter,
    }),
    [],
  )
}
