import { styled } from '@mui/material/styles'
import { Box, FormControlLabel, Typography } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'

export const StyledSectionRoot = styled(Box)`
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0 0 0 16px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
`

export const StyledDiffContainer = styled(FormGroup)`
  display: flex;
  flex-flow: column wrap;
`

export const StyledDiffItemRoot = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
`

export const StyledRecord = styled(FormControlLabel)`
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 0 -11px;
  flex-grow: 1;
  flex-shrink: 1;
`

export const StyledMultipartText = styled('div')`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 340px;

  b {
    font-weight: 500;
    padding: 0 0.4em;
  }

  span {
  }
`

export const StyledFormLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: bold !important;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex-flow: row nowrap;
  margin-bottom: ${({ theme }) => theme.spacing(1.7)};
  padding: 0;
`
