import { Version as FormulaEditorVersion } from '@fintastic/web/feature/formulas'
import { VersionMetric } from '../../../../utils/metrics'

export function metricVersionToFormulaVersion(
  version: VersionMetric,
): FormulaEditorVersion {
  return {
    formula: version.formula || '',
    title: version.versionLabel,
    id: version.versionId,
    locked: !version.versionEditable,
    connectedTable: {
      id: version.metricId,
    },
  }
}
