import { useQuery } from 'react-query'
import type { TableId, ColumnId } from '@fintastic/shared/util/types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { MetricOrListType } from '@fintastic/web/util/metrics-and-lists'
import { endpoints } from '../api/endpoints'

export type GlobalTablesAPIResponse = Array<{
  id: TableId
  label: string
  type: MetricOrListType
}>

export type GlobalColumnsAPIResponse = Array<{
  id: ColumnId
  label: string
  list_id: string
}>

export function useGlobalTables() {
  const tablesQuery = useQuery<GlobalTablesAPIResponse>(
    ['global/tables'],
    async () => {
      const { data } = await axios.get<{ result: GlobalTablesAPIResponse }>(
        endpoints.tables,
      )
      return data.result
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      retryOnMount: true,
    },
  )

  const columnsQuery = useQuery<GlobalColumnsAPIResponse>(
    ['global/columns'],
    async () => {
      const { data } = await axios.get<{ result: GlobalColumnsAPIResponse }>(
        endpoints.columns,
      )
      return data.result
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      retryOnMount: true,
    },
  )

  return {
    tablesQuery,
    columnsQuery,
  }
}
