import {
  createValueFormatter,
  isDataTypeNumeric,
  MetricDisplaySettingsFormatting,
  MetricMetadata,
} from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { ChartDisplaySettings } from '@fintastic/web/feature/charts'

type TooltipValueFormatterFunc = (value: any) => string

const defaultFormatter: TooltipValueFormatterFunc = (value: any) =>
  value.toString()

export const createChartValueFormatter = (
  metadata?: Maybe<MetricMetadata>,
  displaySettings?: ChartDisplaySettings,
): TooltipValueFormatterFunc => {
  if (!metadata || !metadata.display_config) {
    // metadata should be always available
    return defaultFormatter
  }

  let formatter: TooltipValueFormatterFunc | undefined

  const isPercentageColumn =
    metadata.value.type === 'percentage' ||
    metadata.value.type === 'percentage_integer'

  const isNumericColumn = isDataTypeNumeric(metadata.value.type)

  if (isNumericColumn) {
    const numericFormatter = chartNumericFormatter(metadata, displaySettings)

    formatter = (value) =>
      numericFormatter ? numericFormatter({ value }) : defaultFormatter(value)
  }

  if (isPercentageColumn) {
    const percentageFormatter = chartPercentageFormatter(
      metadata,
      displaySettings,
    )

    formatter = (value) =>
      percentageFormatter
        ? percentageFormatter({ value })
        : defaultFormatter(value)
  }

  return formatter || defaultFormatter
}

const getDecimalPlaceFromChartDisplaySettings = (
  displaySettings?: ChartDisplaySettings,
) => {
  if (!displaySettings) {
    return -1
  }

  switch (displaySettings.decimalPlaces) {
    case 'taken':
      return -1
    case 'd0':
      return 0
    case 'd1':
      return 1
    case 'd2':
      return 2
    case 'd3':
      return 3
    case 'd4':
      return 4
    case 'd5':
      return 5
    case 'd6':
      return 6
    default:
      return -1
  }
}

const getCompactFormatting = (
  displaySettings?: ChartDisplaySettings,
): MetricDisplaySettingsFormatting['compact_formatting'] => {
  if (displaySettings?.numberFormat === 'thousands') {
    return 'thousands'
  }

  if (displaySettings?.numberFormat === 'millions') {
    return 'millions'
  }

  return 'no_format'
}

export const chartPercentageFormatter = (
  metadata: MetricMetadata,
  displaySettings?: ChartDisplaySettings,
): TooltipValueFormatterFunc | undefined => {
  const dataType = metadata.value.type
  const currentDisplaySettings = structuredClone(metadata.display_config)

  // override local formatting
  if (getDecimalPlaceFromChartDisplaySettings(displaySettings) !== -1) {
    currentDisplaySettings.formatting.decimal_places =
      getDecimalPlaceFromChartDisplaySettings(displaySettings)
  }

  if (displaySettings?.numberFormat !== 'taken') {
    currentDisplaySettings.formatting.compact_formatting =
      getCompactFormatting(displaySettings)
  }

  return (
    createValueFormatter(dataType as never, currentDisplaySettings) || undefined
  )
}

export const chartNumericFormatter = (
  metadata: MetricMetadata,
  displaySettings?: ChartDisplaySettings,
): TooltipValueFormatterFunc | undefined => {
  const dataType = metadata.value.type
  const currentDisplaySettings = structuredClone(metadata.display_config)

  const currency = currencies.find(
    (c) => c.code === metadata.display_config.currency_name || '',
  )

  // override local formatting
  if (getDecimalPlaceFromChartDisplaySettings(displaySettings) !== -1) {
    currentDisplaySettings.formatting.decimal_places =
      getDecimalPlaceFromChartDisplaySettings(displaySettings)
  }

  if (displaySettings?.numberFormat !== 'taken') {
    currentDisplaySettings.formatting.compact_formatting =
      getCompactFormatting(displaySettings)
  }

  return (
    createValueFormatter(dataType as never, currentDisplaySettings, currency) ||
    undefined
  )
}
