import { AxiosResponse } from 'axios'
import { call, put } from 'redux-saga/effects'
import { AuthUserInfo } from '../../../types'
import { actions } from '../slice'
import { getAuthUserInfo } from '../../../api'

export function* loadUserInfoSaga() {
  yield put(actions.userInfoLoadStart())

  const userInfo: AxiosResponse<AuthUserInfo> = yield call(getAuthUserInfo)

  yield put(
    actions.userInfoLoadEnd({
      error: null,
      userInfo: userInfo.data,
    }),
  )
}
