import { CategoryDimensionId } from '@fintastic/web/util/dimensions'
import {
  DimensionColumnColor,
  ValueColumnColor,
  ValueColumnLocation,
} from './types'
import { HexColor, Maybe } from '@fintastic/shared/util/types'

export const createDimensionColumnColor = (
  dimensionId: CategoryDimensionId,
  versionId: Maybe<string>,
  color?: Maybe<HexColor>,
): DimensionColumnColor => ({
  hexColor: color || null,
  versionId,
  column: {
    type: 'dimension',
    dimensionId,
  },
})

export const createValueColumnColor = (
  location: ValueColumnLocation,
  color?: Maybe<HexColor>,
): ValueColumnColor => ({
  hexColor: color || null,
  versionId: location.versionId,
  column: {
    type: 'value',
    metricId: location.metricId,
    period: location.period,
  },
})
