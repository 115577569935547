import React, { useCallback } from 'react'
import { StyledVersionSelectorRoot } from './VersionSelector.styled'
import { Maybe } from '@fintastic/shared/util/types'
import { Version } from './types'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'

export type VersionSelectorProps = {
  onChange: (newValue: Maybe<string>) => void
  value: Maybe<string>
  versions: Version[]
  disabled?: boolean
}

export const VersionSelector: React.FC<VersionSelectorProps> = ({
  onChange,
  value,
  versions,
  disabled = false,
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value)
    },
    [onChange],
  )

  return (
    <StyledVersionSelectorRoot data-testid="formula-editor-version-selector">
      <Select
        value={value || undefined}
        onChange={handleChange}
        disabled={disabled}
        size="small"
        sx={{ maxWidth: '220px', minWidth: '100px' }}
      >
        {versions.map((version) => (
          <MenuItem key={version.id} value={version.id}>
            {version.title}
          </MenuItem>
        ))}
      </Select>
    </StyledVersionSelectorRoot>
  )
}
