import {
  alpha,
  Box,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  styled,
} from '@mui/material'
import React from 'react'

export const StyledList = styled(
  (props: { loading?: boolean } & ListProps) => {
    const { loading, ...listProps } = props
    return <List {...listProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'loading',
  },
)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 2;
  flex-shrink: 2;
  gap: 6px;

  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1)};

  transition: all 0.4s ease;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
`

export const StyledListItemRoot = styled(Box)``

export const StyledListItem = styled(
  (
    props: { highlighted?: boolean; notAvailable?: boolean } & ListItemProps,
  ) => {
    const { highlighted, notAvailable, ...listItemProps } = props
    return <ListItem {...listItemProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) =>
      propName !== 'highlighted' && propName !== 'notAvailable',
  },
)`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  border-radius: 8px;
  padding: 12px;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  cursor: default;

  background: ${({ highlighted }) =>
    highlighted ? alpha('#ff7933', 0.2) : 'transparent'};

  border: 1px solid
    ${({ highlighted }) => (highlighted ? '#ff7933' : 'transparent')};

  &:hover {
    background: ${alpha('#ff7933', 0.08)};
    border: 1px solid
      ${({ highlighted }) => (highlighted ? '#ff7933' : alpha('#ff7933', 0.5))};
    box-shadow: 0px -4px 10px 0px #0000000d;

    box-shadow: 0px 4px 10px 0px #0000000d;
  }
`
