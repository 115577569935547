import React, { useCallback, useMemo } from 'react'

import { ChartSettingsSection, ChartSettingsSubsectionIcon } from '../shared'
import { useMemoryRouterContext } from '@fintastic/shared/util/memory-router'
import {
  ChartSettingsSectionDefinition,
  ChartSettingsSectionPath,
  chartSettingsSections,
} from '../../routes/routes'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import {
  ChartTypeSelect,
  defaultChartType,
  getChartGridModeName,
  getChartThemeName,
  getLegendFormatName,
  getLegendPositionName,
  getXAxisAlignmentName,
  getXAxisFormatName,
  getYAxisDecimalPlacesName,
  getYAxisFormatName,
} from '@fintastic/web/feature/charts'
import {
  NavButton,
  NavButtonsContainer,
} from '@fintastic/shared/ui/view-layout'

const iconSx = { width: 40, minWidth: 40 }

export const MainSection: React.FC = () => {
  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()
  const { loading } = useChartSettingsContext()

  const { goTo } = useMemoryRouterContext()

  const handleGoToSection = useCallback(
    (sectionId: string) => {
      goTo(sectionId)
    },
    [goTo],
  )

  // all except the first one (initial, this section)
  const mainSectionSettingsModes = useMemo<ChartSettingsSectionDefinition[]>(
    () => chartSettingsSections.toSpliced(0, 1),
    [],
  )

  const getSecondaryLabel = useCallback(
    (path: ChartSettingsSectionPath) => {
      if (path === '/legend') {
        return (
          getLegendPositionName(localSettings.legendPosition) +
          ', ' +
          getLegendFormatName(localSettings.legendFormat)
        )
      }

      if (path === '/moreSettings') {
        return getChartThemeName(localSettings.theme)
      }

      if (path === '/xAxis') {
        return (
          getXAxisAlignmentName(localSettings.xAxisAlignment) +
          ', ' +
          getXAxisFormatName(localSettings.xAxisFormat)
        )
      }

      if (path === '/yAxis') {
        return (
          getYAxisFormatName(localSettings.numberFormat) +
          ', ' +
          getYAxisDecimalPlacesName(localSettings.decimalPlaces)
        )
      }

      if (path === '/grid') {
        return getChartGridModeName(localSettings.gridMode)
      }

      return ''
    },
    [
      localSettings.decimalPlaces,
      localSettings.gridMode,
      localSettings.legendFormat,
      localSettings.legendPosition,
      localSettings.numberFormat,
      localSettings.theme,
      localSettings.xAxisAlignment,
      localSettings.xAxisFormat,
    ],
  )

  return (
    <ChartSettingsSection allowBack={false} allowSavePanel={true}>
      <ChartTypeSelect
        disabled={loading}
        chartType={localSettings.type || defaultChartType}
        onChangeChartSettings={handleSettingsChange}
        containerSx={{ marginBottom: '10px' }}
      />

      <NavButtonsContainer>
        {mainSectionSettingsModes.map((sm) => (
          <NavButton
            key={sm.path}
            onClick={() => handleGoToSection(sm.path)}
            secondaryLabel={getSecondaryLabel(
              sm.path as ChartSettingsSectionPath,
            )}
            primaryLabel={sm.title}
            icon={sm.icon ? sm.icon : <ChartSettingsSubsectionIcon />}
            iconSx={iconSx}
          />
        ))}
      </NavButtonsContainer>
    </ChartSettingsSection>
  )
}
