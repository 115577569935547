import { useCallback } from 'react'
import { FormulaValidationCallback } from '@fintastic/web/feature/formulas'
import { useMetricsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useUpdateFormulaInEditingMetric() {
  const { changeFormula } = useMetricsActions()

  return useCallback<FormulaValidationCallback>(
    (versionId, newFormula, invalid) => {
      changeFormula({ formula: newFormula, invalid })
    },
    [changeFormula],
  )
}
