import { NavigationApi } from './types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { SyntaxLiteral } from '../../../syntax/types'
import { findNext, findPrev, getOptionIndex } from './navigation'

// @todo add tests
export function useNavigation(
  options: SyntaxLiteral[],
  select: (option: Readonly<SyntaxLiteral>) => void,
  requestClose: () => void,
): NavigationApi {
  const [selectedOptionId, setSelectedOptionId] = useState<Maybe<string>>(null)

  // using ref to avoid unnecessary re-renders
  const dependenciesRef = useRef({
    options,
    select,
    requestClose,
    selectedOptionId,
    setSelectedOptionId,
  })
  dependenciesRef.current = {
    options,
    select,
    requestClose,
    selectedOptionId,
    setSelectedOptionId,
  }

  const selectPrev = useCallback(() => {
    dependenciesRef.current.setSelectedOptionId((currentOption) =>
      findPrev(dependenciesRef.current.options, currentOption),
    )
  }, [])

  const selectNext = useCallback(() => {
    dependenciesRef.current.setSelectedOptionId((currentOption) =>
      findNext(dependenciesRef.current.options, currentOption),
    )
  }, [])

  const selectFirst = useCallback(() => {
    dependenciesRef.current.setSelectedOptionId(
      findNext(dependenciesRef.current.options, null),
    )
  }, [])

  const close = useCallback(() => {
    dependenciesRef.current.requestClose()
    selectFirst()
  }, [selectFirst])

  const applySelection = useCallback(
    (optionId = dependenciesRef.current.selectedOptionId) => {
      const optionIndex = getOptionIndex(
        dependenciesRef.current.options,
        optionId,
      )
      if (optionIndex === null) {
        return
      }
      dependenciesRef.current.select(
        dependenciesRef.current.options[optionIndex],
      )
      close()
    },
    [close],
  )

  useEffect(() => {
    if (getOptionIndex(options, selectedOptionId) === null) {
      selectNext()
    }
  }, [options, selectNext, selectedOptionId])

  return useMemo(
    () => ({
      selectedOptionId,
      selectPrev,
      selectNext,
      selectFirst,
      applySelection,
      close,
    }),
    [
      applySelection,
      selectFirst,
      selectNext,
      selectPrev,
      selectedOptionId,
      close,
    ],
  )
}
