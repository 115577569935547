import { InferReduxConnectable } from '@fintastic/shared/data-access/redux'
import * as slice from './slice'
import { namespace } from './state'
import sagas from './sagas'

// action creators and selectors should be accessible as named exports of redux/ducks/{duckName}
export * from './selectors'
const { actions, reducer } = slice
export const {
  setSelectedGroup,
  setTableAccess,
  setTableAccessBulk,
  setDimensionAccess,
  setTableOverride,
  setColumnMask,
  setColumnAccess,
  loadPermissionsData,
  saveChanges,
  loadPermissionGroupPolicyData,
} = actions

const connectable = {
  namespace,
  reducer,
  sagas,
}

export default connectable as InferReduxConnectable<typeof connectable>
