import { styled } from '@mui/material/styles'

export const StyledDiffModeRoot = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 16px;

  .MuiButtonBase-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal {
    font-size: 12px;
    height: 24px;
    min-width: 26px;
    font-weight: normal;
    padding: 8px 4px;

    &:nth-of-type(1) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.MuiButtonBase-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.Mui-selected {
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: rgba(218, 126, 21, 0.1);
    //background-color: ${({ theme }) => theme.palette.primary.light};
}
`
