import React, { useCallback } from 'react'
import {
  StyledDisableOverlay,
  StyledTopbarDiscussionsButton,
  StyledTopbarRoot,
  StyledTopbarSettings,
  StyledTopbarSettingsItem,
} from './Topbar.styled'
import { useLayoutStateIsDiscussionsOpened } from '../../ducks/layout/hooks'
import { SxProps } from '@mui/material'

export type TopbarSettingsItem = {
  content: React.ReactNode
  sx?: SxProps
  mostRight?: boolean
}

export type TopbarProps = {
  settingsItems?: TopbarSettingsItem[]
  disabled?: boolean
}

const defaultSettingsItems: TopbarSettingsItem[] = []

export const Topbar: React.FC<TopbarProps> = (props) => {
  const { settingsItems = defaultSettingsItems, disabled } = props

  const [isDiscussionsOpened, setIsDiscussionsOpened] =
    useLayoutStateIsDiscussionsOpened()

  const showDiscussions = false

  const handleToggleDiscussions = useCallback(() => {
    setIsDiscussionsOpened(!isDiscussionsOpened)
  }, [isDiscussionsOpened, setIsDiscussionsOpened])

  return (
    <StyledTopbarRoot data-testid="topbar-default">
      {disabled && <StyledDisableOverlay />}
      <StyledTopbarSettings>
        {settingsItems
          .filter((item) => !item.mostRight)
          .map((item, index) => (
            <StyledTopbarSettingsItem sx={item.sx} key={index}>
              {item.content}
            </StyledTopbarSettingsItem>
          ))}
        {showDiscussions && (
          <StyledTopbarSettingsItem>
            <StyledTopbarDiscussionsButton
              onClick={handleToggleDiscussions}
              size="large"
              edge="end"
              className={isDiscussionsOpened ? 'active' : ''}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_191_8536)">
                  <path
                    d="M18.4114 -9.91821e-05H6.10286C2.74286 -9.91821e-05 0 2.74276 0 6.10276V13.1828C0 16.2342 2.22857 18.8056 5.22857 19.2342L5.28 23.1256C5.28 23.417 5.43429 23.6913 5.72571 23.897L5.77714 23.9313C5.89714 23.9656 6 23.9999 6.13714 23.9999C6.27429 23.9999 6.51429 23.9656 6.66857 23.8285L12.5314 19.3028H18.3771C21.7371 19.3028 24.48 16.5599 24.48 13.1999V6.10276C24.48 2.74276 21.7371 -9.91821e-05 18.3771 -9.91821e-05H18.4114ZM22.7657 6.10276V13.1828C22.7657 15.5828 20.8114 17.537 18.4114 17.537H12.2571C12.12 17.537 11.88 17.5713 11.7257 17.7085L7.02857 21.3428L6.97714 18.4113C6.97714 17.9313 6.58286 17.537 6.10286 17.537C3.70286 17.537 1.74857 15.5828 1.74857 13.1828V6.10276C1.74857 3.70276 3.70286 1.74847 6.10286 1.74847H18.4114C20.8114 1.74847 22.7657 3.70276 22.7657 6.10276Z"
                    fill="#263646"
                  />
                  <path
                    d="M9.42858 11.0054C10.2333 11.0054 10.8857 10.353 10.8857 9.54828C10.8857 8.74353 10.2333 8.09114 9.42858 8.09114C8.62382 8.09114 7.97144 8.74353 7.97144 9.54828C7.97144 10.353 8.62382 11.0054 9.42858 11.0054Z"
                    fill="#263646"
                  />
                  <path
                    d="M15.7714 11.0054C16.5761 11.0054 17.2285 10.353 17.2285 9.54828C17.2285 8.74353 16.5761 8.09114 15.7714 8.09114C14.9666 8.09114 14.3142 8.74353 14.3142 9.54828C14.3142 10.353 14.9666 11.0054 15.7714 11.0054Z"
                    fill="#263646"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_191_8536">
                    <rect width="24.5143" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </StyledTopbarDiscussionsButton>
          </StyledTopbarSettingsItem>
        )}
        {settingsItems
          .filter((item) => !!item.mostRight)
          .map((item, index) => (
            <StyledTopbarSettingsItem sx={item.sx} key={index}>
              {item.content}
            </StyledTopbarSettingsItem>
          ))}
      </StyledTopbarSettings>
    </StyledTopbarRoot>
  )
}
