import { QueryClient } from 'react-query'
import { queryKeys } from './query-keys'

export const invalidateReportsListCache = async (
  qc: QueryClient,
  versionId: string,
) => {
  await qc.invalidateQueries({
    queryKey: queryKeys.reportsList(versionId),
    exact: true,
  })
}
