import React from 'react'
import { useModelExplorerApiImplementation } from './useModelExplorerApiImplementation'
import { ModelExplorerApiContext } from './model-explorer-api-context'
import { Popup } from './Popup'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

export const ModelExplorerProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const api = useModelExplorerApiImplementation()
  const enabled = useIsFeatureEnabled('enable_model_explorer')

  return (
    <ModelExplorerApiContext.Provider value={enabled ? api : null}>
      {children}
      {enabled && <Popup />}
    </ModelExplorerApiContext.Provider>
  )
}
