import React from 'react'
import { NoDataIcon } from '@fintastic/shared/ui/icons'
import { Typography } from '@mui/material'
import { StyledRoot, StyledIconContainer } from './NoResultsStatus.styled'

export const NoResultsStatus: React.FC = () => (
  <StyledRoot>
    <StyledIconContainer>
      <NoDataIcon fontSize="medium" htmlColor="#2C45FE" />
    </StyledIconContainer>

    <Typography variant="h6">No matching results</Typography>

    <Typography variant="body2" color="#757575">
      Try adjusting the filters to view results
    </Typography>
  </StyledRoot>
)
