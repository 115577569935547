import React from 'react'
import {
  StyledModalHeaderCloseButton,
  StyledModalHeaderRoot,
} from './ModalHeader.styled'
import { Typography } from '@mui/material'
import { ModalCloseButton } from '../ModalCloseButton'

export type ModalHeaderProps = {
  onRequestClose?: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  showCloseButton?: boolean
}

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { onRequestClose, showCloseButton = true, title, description } = props

  return (
    <StyledModalHeaderRoot>
      {title && (
        <Typography
          sx={{
            lineHeight: (theme) => `${24 / theme.typography.fontSize}rem`,
            mr: 3.5,
            fontWeight: (theme) => theme.typography.fontWeightRegular,
          }}
          variant="h6"
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body1" color="text.secondary" marginTop={1}>
          {description}
        </Typography>
      )}
      {showCloseButton && (
        <StyledModalHeaderCloseButton>
          <ModalCloseButton onClick={onRequestClose} />
        </StyledModalHeaderCloseButton>
      )}
    </StyledModalHeaderRoot>
  )
}
