import { useMemo } from 'react'
import {
  InlineSelectDefaultValue,
  InlineSelectOption,
  InlineSelectValueVariants,
} from '../types'

export function useButtonLabel<
  TValue extends InlineSelectValueVariants = InlineSelectDefaultValue,
>(options: InlineSelectOption<TValue>[], value: TValue, useExample = true) {
  const selectedOption = useMemo(
    () => options.find((o) => o.value === value),
    [options, value],
  )

  return useMemo(() => {
    if (!selectedOption) {
      return 'None'
    }
    return useExample
      ? selectedOption.example || selectedOption.label
      : selectedOption.label
  }, [selectedOption, useExample])
}
