import { useEffect } from 'react'
import { useVersionUserLocks } from './useVersionUserLocks'
import { hideVersionIsLockedForDataEditingToast } from '@fintastic/web/util/versions'

export const useAutoHideGlobalVersionLockWarning = (versionIds: string[]) => {
  const locks = useVersionUserLocks(versionIds)

  useEffect(() => {
    locks.forEach((lock) => hideVersionIsLockedForDataEditingToast(lock))
  }, [locks])
}
