import { styled } from '@mui/material/styles'
import { Box, Chip, Typography } from '@mui/material'

export const StyledMultipartDiffText = styled(Typography)`
  b {
    font-weight: 500;
  }
`

export const StyledChipsContainer = styled(Box)`
  width: 100%;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
`

export const StyledChip = styled(Chip)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: #fff;
  border-radius: 6px;
  text-align: left;
`
