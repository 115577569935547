import { slice } from 'lodash'
import {
  CalendarDatePickerConfig,
  PeriodSelection,
  PeriodsList,
  isEmptyPeriodSelection,
} from '@fintastic/web/util/period-selector'
import { isPeriodBasedSelection } from './selection-guards'

export const convertRangeSelectionToPeriods = (
  periodSelection: PeriodSelection,
  datePickerConfig: CalendarDatePickerConfig,
): PeriodsList => {
  if (isPeriodBasedSelection(periodSelection)) {
    return periodSelection.periods
  }

  if (isEmptyPeriodSelection(periodSelection)) {
    return []
  }

  const dimensionValueIds =
    datePickerConfig
      .find((c) => c.dimension_id === periodSelection.dimensionId)
      ?.values?.map((v) => v.dimension_value_id) ?? []

  const startIndex = dimensionValueIds.indexOf(periodSelection.range[0])
  const endIndex = dimensionValueIds.indexOf(periodSelection.range[1])

  if (startIndex === -1 || endIndex === -1) {
    return []
  }

  return slice(dimensionValueIds, startIndex, endIndex + 1)
}
