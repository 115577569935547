import { useMemo, useRef } from 'react'

export const useReferenceMemo = <TRef extends Record<any, any> | Array<any> | null>(
  object: TRef,
  compare: (a: TRef, b: TRef) => boolean,
): TRef => {
  const ref = useRef(object)

  return useMemo(() => {
    if (!compare(ref.current, object)) {
      ref.current = object
    }
    return ref.current
  }, [object, compare])
}
