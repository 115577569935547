import { useMutation, useQueryClient } from 'react-query'
import { mutationKeys } from './mutation-keys'
import { lockVersion } from '../../api/versions-management-api'
import { invalidateVersionManagementRelatedQueries } from '../../cache'

export function useLockVersion(versionId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    mutationKeys.lockVersion(versionId),
    async () => {
      const response = await lockVersion(versionId)
      return response.data
    },
    {
      onSuccess: async () => {
        await invalidateVersionManagementRelatedQueries(queryClient, [
          versionId,
        ])
      },
    },
  )
}
