import React from 'react'
import { Box } from '@mui/material'

export type FormulaEditorTitleHighlightProps = {
  children: React.ReactNode
}

export const FormulaEditorTitleHighlight: React.FC<
  FormulaEditorTitleHighlightProps
> = ({ children }) => (
  <Box component="span" sx={{ color: '#99491F', ml: '0.25em' }}>
    {children}
  </Box>
)
