import { LabelsContext, LabelTagBase, LabelTagListItem } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { labelsQueryKey } from './query-keys'
import { createLabel } from '../api'

export const useCreateLabelMutation = (context: LabelsContext) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse<LabelTagListItem>,
    AxiosError,
    LabelTagBase,
    {
      previousLabels: LabelTagListItem[]
    }
  >(
    labelsQueryKey.createLabel(context),
    (newLabel) => createLabel(context, newLabel),
    {
      onMutate: async (newLabel) => {
        await queryClient.cancelQueries(labelsQueryKey.createLabel(context))

        const previousLabels =
          queryClient.getQueryData<LabelTagListItem[]>(
            labelsQueryKey.list(context),
          ) || []

        queryClient.setQueryData<LabelTagListItem[]>(
          labelsQueryKey.list(context),
          (prev) => [
            ...(prev || []),
            {
              ...newLabel,
              is_removable: false,
              id: 99999999,
            },
          ],
        )

        return { previousLabels }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData<LabelTagListItem[]>(
          labelsQueryKey.list(context),
          ctx?.previousLabels || [],
        )

        if (err.response?.status === 409) {
          toast.error('Label with the same name already exists')
        } else {
          console.error(err)
          toast.error(
            `Failed to create a label. Error: ${err?.code || 'unknown'}`,
          )
        }
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: labelsQueryKey.list(context),
        })
      },
      onSuccess() {
        toast.success('New label is created!')
      },
    },
  )

  return result
}
