import { FC } from 'react'
import {
  AgGridTextCellEditor,
  AgGridNumericCellEditor,
  AgGridDateCellEditor,
  AgGridSelectboxCellEditor,
  AgGridCustomCellEditorsMap,
} from './'
import AgGridCheckboxCellEditor from './AgGridCheckboxCellEditor/AgGridCheckboxCellEditor'
import AgGridAsyncSelectboxCellEditor from './AgGridSelectboxCellEditor/AgGridAsyncSelectboxCellEditor'

export const agGridCellEditorsMap: AgGridCustomCellEditorsMap = {
  textCellEditor: AgGridTextCellEditor,
  numericCellEditor: AgGridNumericCellEditor,
  dateCellEditor: AgGridDateCellEditor,
  selectBoxCellEditor: AgGridSelectboxCellEditor,
  asyncSelectBoxCellEditor: AgGridAsyncSelectboxCellEditor as FC,
  checkboxCellEditor: AgGridCheckboxCellEditor,
}
