import React from 'react'
import {
  StyledAddNewBoardButtonRoot,
  StyledAddNewBoardButtonIcon,
  StyledAddNewBoardButtonText,
} from './AddNewBoardButton.styled'
import { AddIcon } from './AddIcon'

export type AddNewBoardButtonProps = {
  onClick: () => void
}

export const AddNewBoardButton: React.FC<AddNewBoardButtonProps> = (props) => {
  const { onClick } = props

  return (
    <StyledAddNewBoardButtonRoot onClick={onClick}>
      <StyledAddNewBoardButtonIcon>
        <AddIcon />
      </StyledAddNewBoardButtonIcon>
      <StyledAddNewBoardButtonText>Create New</StyledAddNewBoardButtonText>
    </StyledAddNewBoardButtonRoot>
  )
}
