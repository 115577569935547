import React, { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { StyledSelectedCellAggregationButton } from './SelectedCellAggregationButton.styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  SelectedCellAggregatedValue,
  SelectedCellAggregationType,
} from '@fintastic/web/util/selected-cell-aggregation'
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { formatNumeric } from '@fintastic/shared/util/formatters'
import { stringifyBlankOrMaskedValueIfNecessary } from '@fintastic/web/util/blanks-and-masked'

export type SelectedCellAggregationButtonProps = {
  aggregation: SelectedCellAggregationType
  aggregatedValues: SelectedCellAggregatedValue[]
  onChangeAggregation: (nextAggregation: SelectedCellAggregationType) => void
}

export const SelectedCellAggregationButton: React.FC<
  SelectedCellAggregationButtonProps
> = ({ aggregatedValues, aggregation, onChangeAggregation }) => {
  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLButtonElement>>(null)

  const handleShowPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleHidePopover = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const aggregationItem = useMemo(
    () => aggregatedValues.find((i) => i.type === aggregation),
    [aggregatedValues, aggregation],
  )

  if (!aggregationItem) {
    return null
  }

  if (aggregatedValues.length < 2) {
    return (
      <Box mr={4}>
        <StyledSelectedCellAggregationButton variant="text">
          <SelectedCellAggregationButtonValue {...aggregationItem} />
        </StyledSelectedCellAggregationButton>
      </Box>
    )
  }

  return (
    <Box mr={4}>
      <StyledSelectedCellAggregationButton
        onClick={handleShowPopover}
        endIcon={<ArrowDropDownIcon color="primary" />}
        variant="text"
      >
        <SelectedCellAggregationButtonValue {...aggregationItem} />
      </StyledSelectedCellAggregationButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleHidePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {aggregatedValues.map((item) => (
          <MenuItem
            key={item.type}
            onClick={() => {
              onChangeAggregation(item.type)
              handleHidePopover()
            }}
          >
            <ListItemIcon>
              {item.type === aggregation ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText>
              <SelectedCellAggregationButtonValue {...item} />
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const SelectedCellAggregationButtonValue: React.FC<
  SelectedCellAggregatedValue
> = ({ type, value, format, decimals }) => {
  const formattedValue = useMemo(() => {
    const nonSymbolValue = stringifyBlankOrMaskedValueIfNecessary(value)

    if (typeof nonSymbolValue === 'string') {
      return nonSymbolValue
    }

    return formatNumeric[format === 'percent' ? 'percentage' : 'number'](
      nonSymbolValue,
      {
        maxDecimals: decimals,
      },
    )
  }, [decimals, format, value])

  return (
    <span>
      {aggregationLabelsMap[type] ?? type}: {formattedValue}
    </span>
  )
}

const aggregationLabelsMap: Record<SelectedCellAggregationType, string> = {
  average: 'Average',
  count: 'Count',
  min: 'Min',
  max: 'Max',
  sum: 'Sum',
}
