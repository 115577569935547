import React, { useCallback } from 'react'
import { ColumnType } from '@fintastic/web/util/metrics-and-lists'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import type { SelectChangeEvent } from '@mui/material/Select'
import { ListItemText, ListItemIcon } from '@mui/material'
import { columnTypeToIconMap } from '../../../../../list-columns-icons'
import { StyledSelect, StyledMenuItem } from './TypeInput.styled'
import { listClasses } from '@mui/material'

export type TypeInputProps = {
  value: ColumnType
  onChange: (newValue: ColumnType) => void
  readonly: boolean
  disableCalculatedColumnType?: boolean
}

export const TypeInput: React.FC<TypeInputProps> = ({
  value,
  readonly,
  onChange,
  disableCalculatedColumnType,
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value as ColumnType)
    },
    [onChange],
  )

  return (
    <FormControl fullWidth disabled={readonly}>
      <InputLabel>Type</InputLabel>
      <StyledSelect
        value={value}
        label="Type"
        onChange={handleChange}
        MenuProps={{
          sx: {
            [`.${listClasses.root}`]: {
              py: 1,
            },
          },
        }}
      >
        <StyledMenuItem dense value="input">
          <ListItemIcon>
            <columnTypeToIconMap.input />
          </ListItemIcon>
          <ListItemText>User input</ListItemText>
        </StyledMenuItem>
        {!disableCalculatedColumnType && (
          <StyledMenuItem dense value="calculated">
            <ListItemIcon>
              <columnTypeToIconMap.calculated />
            </ListItemIcon>
            <ListItemText>Calculated</ListItemText>
          </StyledMenuItem>
        )}
        <StyledMenuItem dense value="dimension">
          <ListItemIcon>
            <columnTypeToIconMap.dimension />
          </ListItemIcon>
          <ListItemText>Dimension</ListItemText>
        </StyledMenuItem>
      </StyledSelect>
    </FormControl>
  )
}
