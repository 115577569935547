import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { EditMetricDataIntent, MetricDataFillStrategy } from '../../types'
import { DimensionId, DimensionValueId } from '@fintastic/web/util/dimensions'
import { createEditMetricDataIntent } from './constructors'
import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

export class MutableEditMetricDataIntentWrapper
  implements MutableADTWrapper<EditMetricDataIntent>
{
  _rawData: EditMetricDataIntent

  static fromConstructor(
    dimensions: DimensionId[],
    dataFillStrategy: Maybe<MetricDataFillStrategy> = null,
  ): MutableEditMetricDataIntentWrapper {
    return new MutableEditMetricDataIntentWrapper(
      createEditMetricDataIntent(dimensions, dataFillStrategy),
    )
  }

  constructor(rawIntent: EditMetricDataIntent) {
    this._rawData = rawIntent
  }

  unwrap(): EditMetricDataIntent {
    return this._rawData
  }

  addChange(dimensionValues: DimensionValueId[], value: MetricDataValue) {
    if (dimensionValues.length !== this._rawData.indexes.length) {
      throw new Error('Dimensions length mismatch')
    }
    this._rawData.indexes.forEach((_, index) => {
      this._rawData.dimensions[index].push(dimensionValues[index])
    })
    this._rawData.values.push(value)
  }
}
