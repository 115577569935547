import { ColumnState, PostSortRowsParams, RowNode } from 'ag-grid-community'
import type { GenericReportTreeRow } from '@fintastic/web/util/generic-report'
import { sortBy } from 'lodash'
import { isUnknownGroupLabel } from '../utils/unknown-group-label'
import {
  containsBlankValue,
  containsMaskedValue,
  isRawBlankValue,
} from '@fintastic/web/util/blanks-and-masked'

const positionAfterArray = (input: string | string[]): string[] =>
  typeof input === 'string' ? [input] : input

const getCalculatedRowPosition = (
  node: RowNode<GenericReportTreeRow>,
  nodes: Array<RowNode<GenericReportTreeRow>>,
): number => {
  const calculatedRow = node.data?.calculatedRow

  if (!calculatedRow) {
    return 0
  }

  const index =
    positionAfterArray(calculatedRow.positionAfter)
      .map((position) => nodes.findIndex((node) => node.key === position))
      .find((i) => i !== -1) ?? -1

  if (index === -1) {
    return nodes.length
  }

  return index + 1
}

const getValueFromPossibleSymbol = (value: unknown) => {
  if (isRawBlankValue(value) || containsBlankValue(value)) {
    return 0
  }

  if (containsMaskedValue(value)) {
    return 0
  }

  return value
}

// replacement of columnApi.getValue(sortColumn?.colId, row)
const getColumnData = (row: RowNode<GenericReportTreeRow>, colId: string) => {
  if (!row.aggData) {
    return undefined
  }
  const aggColumn = row.aggData[colId]
  if (aggColumn) {
    return aggColumn.finalValue
  }
  return null
}

const sortBySortColumn = (
  nodes: RowNode<GenericReportTreeRow>[],
  sortColumn: ColumnState,
) => {
  const orderSign = sortColumn.sort === 'asc' ? 1 : -1
  const sortColumnId = sortColumn.colId
  if (!sortColumnId) {
    return
  }

  nodes.sort((a, b) => {
    const c1 = getColumnData(a, sortColumnId)
    const c2 = getColumnData(b, sortColumnId)

    const v1 = getValueFromPossibleSymbol(c1)
    const v2 = getValueFromPossibleSymbol(c2)

    if (a.data?.calculatedRow && b.data?.calculatedRow) {
      return ((v1 || '') > (v2 || '') ? 1 : -1) * orderSign
    }

    if (a.data?.calculatedRow) {
      return 1
    }

    if (b.data?.calculatedRow) {
      return -1
    }
    // @todo: explicitly sort both-calculated?
    // @todo: check isUnknownGroupLabel?
    // @todo: check _others?

    return ((v1 || '') > (v2 || '') ? 1 : -1) * orderSign
  })
}

const sortByCalculatedRows = (nodes: RowNode<GenericReportTreeRow>[]) => {
  const calculatedRowNodes = nodes.filter((node) => node.data?.calculatedRow)

  // presort all
  nodes.sort((a, b) => {
    if (a.key === b.key) {
      return 0
    }
    if (isUnknownGroupLabel(a.key || '')) {
      return 1
    }

    if (isUnknownGroupLabel(b.key || '')) {
      return -1
    }

    return (a.key || '') > (b.key || '') ? 1 : -1
  })

  // post sort calculated
  sortBy(calculatedRowNodes, [
    (node) =>
      positionAfterArray(node.data?.calculatedRow?.positionAfter || []).join(
        '--',
      ),
    (node) => (node.data?.calculatedRow?.sortWeight || 0) * -1,
  ]).forEach((node) => {
    const currentNodeIndex = nodes.findIndex((i) => i.key === node.key)
    if (currentNodeIndex === -1) {
      return
    }

    const removedNode = nodes.splice(currentNodeIndex, 1)[0]
    nodes.splice(getCalculatedRowPosition(node, nodes), 0, removedNode)
  })
}

// Mutates the original array
export const sortReportRows = (
  { nodes }: PostSortRowsParams<GenericReportTreeRow>,
  sortColumn: ColumnState | undefined,
) => {
  if (nodes.length === 0) {
    return
  }

  if (sortColumn) {
    sortBySortColumn(nodes, sortColumn)
  } else {
    sortByCalculatedRows(nodes)
  }
}
