import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import type { Version } from '@fintastic/web/util/versions'

export type UseVersionDateRangeInputStateResult = {
  handleStartDateChange: React.Dispatch<
    React.SetStateAction<Maybe<dayjs.Dayjs>>
  >
  handleEndDateChange: React.Dispatch<React.SetStateAction<Maybe<dayjs.Dayjs>>>
  startDate: Maybe<dayjs.Dayjs>
  endDate: Maybe<dayjs.Dayjs>
  startDateSmallerThanEndDate: boolean
}

export function useVersionDateRangeInputState(
  version?: Version,
  emptyByDefault?: boolean,
): UseVersionDateRangeInputStateResult {
  const defaultStartDate = useMemo(
    () => (emptyByDefault ? null : dayjs().add(-12, 'months')),
    [emptyByDefault],
  )
  const defaultEndDate = useMemo(
    () => (emptyByDefault ? null : dayjs().add(12, 'months')),
    [emptyByDefault],
  )

  const [selectedStartDate, handleStartDateChange] = React.useState(
    version?.period_start
      ? dayjs(new Date(version.period_start))
      : defaultStartDate,
  )
  const [selectedEndDate, handleEndDateChange] = React.useState(
    version?.period_end ? dayjs(new Date(version.period_end)) : defaultEndDate,
  )

  useEffect(() => {
    handleStartDateChange(
      version?.period_start
        ? dayjs(new Date(version.period_start))
        : defaultStartDate,
    )
    handleEndDateChange(
      version?.period_end
        ? dayjs(new Date(version.period_end))
        : defaultEndDate,
    )
  }, [defaultEndDate, defaultStartDate, version])

  const startDateSmallerThanEndDate =
    selectedStartDate && selectedEndDate
      ? selectedStartDate.unix() < selectedEndDate.unix()
      : false

  return useMemo(
    () => ({
      handleStartDateChange,
      handleEndDateChange,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      startDateSmallerThanEndDate,
    }),
    [selectedEndDate, selectedStartDate, startDateSmallerThanEndDate],
  )
}
