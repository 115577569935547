import { RequestState } from './state'
import type { Selector } from 'reselect'
import { createSelector } from '@reduxjs/toolkit'

export function makeSelectors<
  R extends Record<string, any>,
  S extends RequestState = RequestState,
>(
  selectAll: Selector<R, S>,
): {
  selectStatus: Selector<R, S['status']>
  selectIsLoading: Selector<R, boolean>
  selectIsInitial: Selector<R, boolean>
  selectIsError: Selector<R, boolean>
  selectError: Selector<R, S['error']>
} {
  const selectStatus: Selector<R, S['status']> = (s) => selectAll(s).status

  const selectIsLoading = createSelector(
    selectStatus,
    (status) => status === 'loading',
  ) as unknown as Selector<R, boolean>

  const selectIsInitial = createSelector(
    selectStatus,
    (status) => status === 'initial',
  ) as unknown as Selector<R, boolean>

  const selectIsError = createSelector(
    selectStatus,
    (status) => status === 'error',
  ) as unknown as Selector<R, boolean>

  const selectError: Selector<R, S['error']> = (s) => selectAll(s).error

  return {
    selectStatus,
    selectIsLoading,
    selectIsInitial,
    selectIsError,
    selectError,
  }
}
