import React, { useEffect } from 'react'
import {
  WidgetSelect,
  WidgetSelectDataWrapperProps,
  WidgetSelectEmpty,
} from '@fintastic/web/feature/boards'
import { useLoadListOfLists } from '@fintastic/web/data-access/metrics-and-lists'

export const PENDING_LISTS_ID = 'listOfLists'

export const WidgetSelectList: React.FC<WidgetSelectDataWrapperProps> = (
  props,
) => {
  const { data: entityList, isLoading } = useLoadListOfLists(
    props.versionId || '',
    false,
  )

  useEffect(() => {
    if (isLoading) {
      props.onFetching?.(PENDING_LISTS_ID)
    } else {
      props.onReady?.(PENDING_LISTS_ID)
    }
  }, [isLoading, entityList, props])

  if (!isLoading && (entityList || []).length === 0) {
    return (
      <WidgetSelectEmpty
        emptyText={
          props.value
            ? 'Lists data in the version is not available'
            : 'No data to select'
        }
      />
    )
  }

  return (
    <WidgetSelect
      {...props}
      entityList={entityList || []}
      isLoading={isLoading}
      emptyText={'Select list'}
      loadingText={'Loading list of Lists...'}
    />
  )
}
