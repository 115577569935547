import React from 'react'
import { Box } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

export const DeleteConfirmationModal: React.FC<{
  isOpen: boolean
  close: () => void
  onConfirm: () => void
  deleting: boolean
}> = ({ isOpen, onConfirm, close, deleting }) => (
  <ConfirmationPopup
    open={isOpen}
    onRequestClose={close}
    onConfirm={onConfirm}
    onDecline={close}
    title={'Delete Report?'}
    autoClose={false}
  >
    <Box width="100%">
      <ErrorAlert
        title="Existing Report configuration will be lost"
        message="It won't be possible to retrieve the current Report configuration"
        severity="warning"
      />
      <Box mt={3} width="100%" display="flex" justifyContent="center">
        <ConfirmationPopup.DeclineButton
          variant="contained"
          color="primary"
          disabled={deleting}
        >
          Cancel
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton sx={{ ml: 1 }} disabled={deleting}>
          {deleting ? 'Deleting...' : 'Delete Report'}
        </ConfirmationPopup.ConfirmButton>
      </Box>
    </Box>
  </ConfirmationPopup>
)
