import { IconButton, styled } from '@mui/material'

export const StyledTitleRoot = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  user-select: none;
`

export const StyledLeftContent = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
`

export const StyledCenteredTitle = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 8px;
`

export const StyledListIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #047d34;

  svg {
    width: 24px;
    height: auto;
  }
`

export const StyledTitleButtons = styled('div')`
  display: inline-flex;
  flex-flow: row nowrap;
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const StyledDeleteButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>`
  height: 32px;
  width: 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  svg {
    opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  }
`

export const StyledValidLinesText = styled('span')`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-right: ${({ theme }) => theme.spacing(2)};
`

export const StyledRightContent = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 8px;

  .MuiSelect-select.MuiSelect-outlined.Mui-disabled.MuiInputBase-input.MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.text.primary};
    -webkit-text-fill-color: ${({ theme }) =>
      theme.palette.text.primary}; // o_O -webkit-text-fill-color
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    height: 32px;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.primary};

    fieldset {
      border-color: ${({ theme }) => theme.palette.divider};
    }
  }
`
