import { sortBy } from 'lodash'
import { UserData } from  '@fintastic/web/data-access/iam'
import { GroupsAPIResult } from '@fintastic/web/feature/settings'
import { ExtendedServerSideIdentity } from './BoardShareForm'
import { BoardAccessIdentityAccess } from '../../types'

// remap remote data - check user/group actually exists,
// get actual name for user,
// sort by type (users first) and id (as name),
// and left only existing and consistent records
export function prepareAccesses(
  accesses: BoardAccessIdentityAccess[],
  users: UserData[],
  groups: GroupsAPIResult,
): ExtendedServerSideIdentity[] {
  return [
    ...sortBy(
      (accesses || [])
        .filter((item) => item.identity_type === 'user')
        .map((identity) => {
          const user = users.find(
            (user) => user.username === identity.identity_id, // email
          )
          if (user) {
            return {
              identity_id: identity.identity_id,
              identity_type: identity.identity_type,
              identity_name: user.full_name || user.username || 'unknown user',
            }
          }
          return {
            identity_id: '',
            identity_type: identity.identity_type,
            identity_name: '',
          }
        }),
      'identity_id',
    ),
    ...sortBy(
      (accesses || [])
        .filter((item) => item.identity_type === 'group')
        .map((identity) => {
          const group = groups.find(
            (group) => group.id === identity.identity_id,
          )

          if (group) {
            return {
              identity_id: group.id,
              identity_type: identity.identity_type,
              identity_name: group.id,
            }
          }

          return {
            identity_id: '',
            identity_type: identity.identity_type,
            identity_name: '',
          }
        }),
      'identity_id',
    ),
  ].filter((item) => Boolean(item.identity_id))
}
