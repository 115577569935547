import React, { PropsWithChildren } from 'react'
import { BaseTimeDimensionContext } from '../context/BaseTimeDimensionContext'
import { BaseTimeDimensionDynamicFilteredContext } from '../types'

export const BaseTimeDimensionDynamicFilteredProvider: React.FC<
  PropsWithChildren<{ value: BaseTimeDimensionDynamicFilteredContext }>
> = ({ children, value }) => (
  <BaseTimeDimensionContext.Provider value={value}>
    {children}
  </BaseTimeDimensionContext.Provider>
)
