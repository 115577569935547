import { FormulaMutationsApi } from '../formula-mutations/types'
import { useCallback, useMemo, useRef } from 'react'
import { makeIncreaseIndentationMutation } from './formula-mutations/makeIncreaseIndentationMutation'
import { makeDecreaseIndentationMutation } from './formula-mutations/makeDecreaseIndentationMutation'

export type IndentationApi = ReturnType<typeof useIndentation>

export const useIndentation = (
  formula: string,
  caretPosition: number,
  formulaMutationsApi: FormulaMutationsApi,
) => {
  const { applyMutation } = formulaMutationsApi

  // for sake of rendering optimisation + always actual values in the native event handler
  const depsRefs = useRef({
    formula,
    applyMutation,
    caretPosition,
  })
  depsRefs.current = {
    formula,
    applyMutation,
    caretPosition,
  }

  const increaseIndentation = useCallback(() => {
    const { formula, applyMutation, caretPosition } = depsRefs.current

    const mutationDef = makeIncreaseIndentationMutation(formula, caretPosition)
    if (mutationDef) {
      applyMutation(mutationDef)
    }
  }, [])

  const decreaseIndentation = useCallback(() => {
    const { formula, applyMutation, caretPosition } = depsRefs.current

    const mutationDef = makeDecreaseIndentationMutation(formula, caretPosition)
    if (mutationDef) {
      applyMutation(mutationDef)
    }
  }, [])

  return useMemo(
    () =>
      ({
        increaseIndentation,
        decreaseIndentation,
      } as const),
    [decreaseIndentation, increaseIndentation],
  )
}
