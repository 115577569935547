import { PayloadAction } from '@reduxjs/toolkit'
import { State } from '../types'
import {
  castToMetricEditingState,
  checkMetricSourceOrThrowError,
} from './helpers'
import { ChangeMetricFormulaPayload } from '../../types'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

export const changeMetricFormula = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeMetricFormulaPayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  checkMetricSourceOrThrowError(state.metric, 'calculated')

  const { formula, invalid } = payload
  const metric = new MutableMetricWrapper(state.metric)

  if (metric.formula() === formula) {
    /**
     * @see EditableMetric
     */
    delete state.metric.formulaInvalid
    return
  }

  if (invalid) {
    state.metric.formulaInvalid = true
    return
  }
  metric.setFormula(formula)
  delete state.metric.formulaCalculationError
  delete state.metric.formulaInvalid
}
