import { ChangeWidgetDialogParams } from '@fintastic/web/feature/boards'
import { useTextFieldState } from '@fintastic/shared/ui/form-framework'
import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export type CommonDataWidgetSettings = {
  versionId?: Maybe<string>
  handleSelectVersion: (versionId: Maybe<string>) => void

  changed: boolean

  displayNameFieldState: ReturnType<typeof useTextFieldState>
  hasVersion: boolean
  showRefVersionProblem: boolean
  handleShowNoRefVersionMessage: () => void
}

export const useCommonDataWidgetSettings = (
  initialParams: Partial<ChangeWidgetDialogParams>,
): CommonDataWidgetSettings => {
  const initialDisplayLabel = initialParams.displayLabel || ''

  const initialVersionId = initialParams.versionId || null

  const displayNameFieldState = useTextFieldState(initialDisplayLabel)

  const [versionId, setVersionId] = useState<Maybe<string>>(initialVersionId)

  const [changed, setChanged] = useState(false)

  const hasVersion = Boolean(versionId)

  const [showRefVersionProblem, setShowRefVersionProblem] = useState(false)

  const handleSelectVersion = useCallback(
    (versionId: Maybe<string>) => {
      setVersionId(versionId)
      setChanged(() => true)
      setShowRefVersionProblem(() => false)
    },
    [setChanged, setVersionId],
  )

  const handleShowNoRefVersionMessage = useCallback(
    (notFound?: boolean) => {
      setTimeout(() => {
        // should happen in next render
        if (notFound) {
          setShowRefVersionProblem(true)
          setVersionId(null)
          setChanged(true)
        } else {
          setShowRefVersionProblem(false)
          setChanged(true)
        }
      }, 20)
    },
    [setChanged, setVersionId],
  )

  return useMemo<CommonDataWidgetSettings>(
    () => ({
      versionId,
      handleSelectVersion,
      changed,
      displayNameFieldState,
      hasVersion,
      showRefVersionProblem,
      handleShowNoRefVersionMessage,
    }),
    [
      versionId,
      handleSelectVersion,
      changed,
      displayNameFieldState,
      hasVersion,
      showRefVersionProblem,
      handleShowNoRefVersionMessage,
    ],
  )
}
