import React from 'react'

const View: React.FC<{
  reportTitle: string
  goToMetrics: () => void
  goToDimensions: () => void
}> = () => <div>RootView</div>

export const RootView = {
  Editable: View,
  Readonly: View,
} as const
