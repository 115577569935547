import {
  createValueFormatter,
  MetricDataValue,
  MetricMetadata,
} from '@fintastic/web/util/metrics-and-lists'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community'
import { applyValueFormatter } from '@fintastic/shared/ui/grid-framework'
import { formatNumeric } from '@fintastic/shared/util/formatters'
import {
  AgGridCellEditorProps,
  AgGridNumericCellEditorProps,
} from '@fintastic/shared/ui/ag-grid'

export type ChartCellEditorProps = {
  cellEditorParams?: AgGridNumericCellEditorProps | AgGridCellEditorProps
  cellStyle?: (params: any) => any
  valueFormatter: ValueFormatterFunc<MetricDataValue> | undefined
}

export const getChartNumericCellEditorProps = (
  metadata: MetricMetadata,
): ChartCellEditorProps => {
  const dataType = metadata.value.type

  const displaySettings = metadata.display_config

  const currency = currencies.find(
    (c) => c.code === metadata.display_config.currency_name || '',
  )

  const valueFormatter = displaySettings
    ? createValueFormatter(dataType as never, displaySettings, currency) ||
      undefined
    : (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          formatNumeric.number(value),
        )

  return {
    valueFormatter,
  }
}

export const getChartPercentageCellEditorProps = (
  metadata: MetricMetadata,
): ChartCellEditorProps => {
  const dataType = metadata.value.type

  const displaySettings = metadata.display_config

  const valueFormatter = displaySettings
    ? createValueFormatter(dataType as never, displaySettings) || undefined
    : (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          formatNumeric.percentage(value),
        )

  return {
    valueFormatter,
  }
}
