import { defaultAuditLogFilters, defaultAuditLogPagination } from '../defaults'

import {
  AuditLogFiltersParams,
  AuditLogPaginationParams,
  AuditLogParams,
  AuditLogRequestOrderingParams,
} from '../types'

export const AuditLogQueryKeyIndices = {
  keyName: 0,
  filters: 1,
  pagination: 2,
  ordering: 3,
}

export type AuditLogQueryKey = [
  string,
  AuditLogFiltersParams,
  AuditLogPaginationParams,
  AuditLogRequestOrderingParams,
]

export const cacheKeys = {
  auditLog: ({
    pagination,
    filter,
    desc,
  }: AuditLogParams): AuditLogQueryKey => [
    'auditLog',
    { ...defaultAuditLogFilters, ...filter },
    { ...defaultAuditLogPagination, ...pagination },
    { ordering: desc },
  ],
} as const
