import type { ICellRendererParams } from 'ag-grid-community'
import type { GenericReportTreeRow } from '@fintastic/web/util/generic-report'

export const CalculatedRowCellRenderer: React.FC<
  ICellRendererParams<GenericReportTreeRow>
> = (params) => (
  <b>
    <i>{params.valueFormatted || `${params.getValue?.()}`}</i>
  </b>
)
