import React from 'react'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'

type SharedProps = {
  goBack: () => void
  dimension: {
    id: DimensionId
    label: string
  }
  dimensionNameOverride: Maybe<string>
  hasDimensionDependency: boolean
  mappingList: Maybe<{
    id: string
    label: string
  }>
}

const Editable: React.FC<
  SharedProps & {
    availableMappingLists: Maybe<
      Array<{
        id: string
        label: string
      }>
    >
    changeDimensionNameOverride: (
      dimId: DimensionId,
      nameOverride: string,
    ) => void
    removeDimension: (dimId: DimensionId) => void
    changeMappingList: (dimId: DimensionId, listId: Maybe<string>) => void
  }
> = () => <div>Editable</div>

const Readonly: React.FC<SharedProps> = () => <div>Readonly</div>

export const DimensionSettingsView = {
  Editable,
  Readonly,
} as const
