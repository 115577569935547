import { RouteMatches } from '../matching/types'
import * as React from 'react'
import { RouteMatchContext } from '../context/route-match-context'

export function renderMatches(matches: RouteMatches): React.ReactNode[] | null {
  if (matches === null) {
    return null
  }

  return matches.map((m, i) => (
    <RouteMatchContext.Provider value={m} key={`${m.pathname}-${i}`}>
      {m.route.element}
    </RouteMatchContext.Provider>
  ))
}
