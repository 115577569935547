import { styled, Box } from '@mui/material'

const PREFIX = 'GenericReport'

export const classes = {
  bottomTableCloseIcon: `${PREFIX}-bottomTableCloseIcon`,
}

export const StyledGenericReportRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  minHeight: '100%',
  display: 'flex',
  flexFlow: 'nowrap column',
}))

export const StyledGenericReportBottomTableWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  paddingTop: 28,

  [`& .${classes.bottomTableCloseIcon}`]: {
    position: 'absolute',
    top: '0.3rem',
    right: '0.3rem',
    cursor: 'pointer',
  },
}))
