import { Backdrop, LinearProgress, styled } from '@mui/material'

export const StyledExportBackdrop = styled(Backdrop)`
  border-radius: 8px;
  background: transparent;
  color: #fff;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  overflow: hidden;
`

export const StyledExportProgress = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
