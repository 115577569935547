import { AxiosResponse } from 'axios'
import { call, put } from 'redux-saga/effects'
import { AppServerConfig } from '../../../types'
import { actions } from '../slice'
import { getAppServerConfig } from '../../../api'

export function* loadSaga() {
  yield put(actions.initStart())

  const config: AxiosResponse<AppServerConfig> = yield call(getAppServerConfig)

  yield put(
    actions.initFinished({
      error: null,
      config: config.data,
    }),
  )
}
