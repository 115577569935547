import { ThemeComponents } from './types'
import { styleKit } from '../styleKit'

export const MuiIconButton: ThemeComponents['MuiIconButton'] = {
  defaultProps: {
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      'padding': 0,
      'border': '1px solid transparent',
      'borderRadius': 4,
      'color': styleKit.colors.general.black2,
      'transition': theme.transitions.create(
        ['background-color', 'border-color'],
        {
          duration: theme.transitions.duration.shortest,
        },
      ),
      '&:disabled': {
        color: styleKit.colors.general.grey,
      },
      '&:hover, &.Mui-focusVisible': {
        background: styleKit.colors.general.notEditable,
        borderColor: styleKit.colors.general.dividers,
      },
      '.MuiTouchRipple-root': {
        display: 'none',
      },
    }),

    sizeSmall: {
      width: 24,
      height: 24,
    },
    sizeMedium: {
      width: 32,
      height: 32,
    },
    sizeLarge: {
      width: 40,
      height: 40,
    },

    colorPrimary: {
      color: styleKit.colors.orange.medium,
    },
    colorSecondary: {
      color: styleKit.colors.general.black2,
    },
  },
}
