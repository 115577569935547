import { PeriodResolution } from '@fintastic/web/util/period-selector'
import { PeriodSelectionPickerConfig } from '../types'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'

export const getMaximumAllowedPeriodsLength = (
  c: PeriodSelectionPickerConfig,
  periodsType: DimensionId,
  resolution: Maybe<PeriodResolution>,
): number => {
  if (!resolution) {
    return 0
  }

  return (
    c.periodTypes.find((pt) => pt.dimension_id === periodsType)
      ?.periodsAmountLimitPerAggregation[resolution] || 0
  )
}
