// copied from react-hot-toast/src/core/utils.ts
export const prefersReducedMotion = (() => {
  let shouldReduceMotion: boolean | undefined = undefined
  return () => {
    if (shouldReduceMotion === undefined && typeof window !== 'undefined') {
      const mediaQuery = matchMedia('(prefers-reduced-motion: reduce)')
      shouldReduceMotion = !mediaQuery || mediaQuery.matches
    }
    return shouldReduceMotion
  }
})()
