import React from 'react'
import { ActionButton } from '../../../../shared/ActionButton'
import AddIcon from '@mui/icons-material/Add'

export type AddColumnButtonProps = {
  onClick: () => void
}

export const AddColumnButton: React.FC<AddColumnButtonProps> = ({
  onClick,
}) => (
  <ActionButton label="Add new column" onClick={onClick} icon={<AddIcon />} />
)
