import { Maybe } from '@fintastic/shared/util/types'
import {
  GridCoordinatesColumns,
  GridCoordinatesRow,
  GridCoordinatesRows,
} from '@fintastic/shared/ui/app-layout-framework'
import { ColumnApi, RowNode } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'

export type HighlightCellsParams = {
  gridApi: Maybe<GridApi<unknown>>
  columnApi: Maybe<ColumnApi>
  rows: Maybe<GridCoordinatesRows>
  cols: Maybe<GridCoordinatesColumns>
  metricRow?: Maybe<GridCoordinatesRow>
}

const DEFAULT_FLASH_DELAY = 1000
const DEFAULT_FLASH_FADE_DELAY = 1000

export function highlightAGGridCells({
  gridApi,
  columnApi,
  rows,
  cols,
}: HighlightCellsParams): boolean {
  if (!gridApi || !columnApi) {
    return false
  }

  if (!rows && !cols) {
    // nothing to highlight
    return false
  }

  const colIdToHighlight = cols && cols.length > 0 ? cols[0] : ''

  const rowsToHighlight: RowNode[] = []

  if (rows && rows.length > 0) {
    gridApi.forEachNode((node: RowNode) => {
      rows.forEach((keys) => {
        let nodeMatches = true
        keys.forEach((rowKeySet) => {
          rowKeySet.forEach((value, key) => {
            if (node.data?.[key] !== value) {
              nodeMatches = false
            }
          })

          if (nodeMatches) {
            rowsToHighlight.push(node)
          }
        })
      })
    })
  }

  const columnToHighlight = colIdToHighlight
    ? columnApi.getColumn(colIdToHighlight)
    : null

  if (rowsToHighlight.length === 0 && !columnToHighlight) {
    // nothing FOUND to highlight
    return false
  }

  if (rowsToHighlight.length > 0) {
    // expand targets
    rowsToHighlight.forEach((node) => {
      let parent = node.parent
      while (parent && parent?.level >= 0) {
        if (parent?.isExpandable()) {
          gridApi.setRowNodeExpanded(parent, true)
        }
        parent = parent.parent
      }
    })

    // [sync]: scroll to the first one
    gridApi.ensureNodeVisible(rowsToHighlight[0])
    // [postponed]: scroll to the first one
    // because AGGrid not all the time guaranteed navigation, so let's do it twice
    setTimeout(() => {
      try {
        gridApi?.ensureNodeVisible(rowsToHighlight[0])
      } catch (e) {
        //
      }
    }, 20)
  }

  if (columnToHighlight) {
    // [postponed]: scroll to column
    // without that it fails with error
    setTimeout(() => {
      try {
        !!colIdToHighlight && gridApi?.ensureColumnVisible(colIdToHighlight)
      } catch (e) {
        // Sometimes bug:
        // Cannot read properties of undefined (reading 'addOrRemoveCssClass')
        //     at CellCtrl.animateCell (ag-grid-community.auto.esm.js:24737:1)
        //     at CellCtrl.flashCell
      }
    }, 40)
  }

  // https://www.ag-grid.com/react-data-grid/change-cell-renderers/
  setTimeout(() => {
    if (rowsToHighlight.length > 0) {
      console.log('flash', {
        rowNodes: rowsToHighlight,
        columns: cols || undefined,
        flashDelay: DEFAULT_FLASH_DELAY,
        fadeDelay: DEFAULT_FLASH_FADE_DELAY,
      })

      gridApi?.flashCells({
        rowNodes: rowsToHighlight,
        columns: cols || undefined,
        flashDelay: DEFAULT_FLASH_DELAY,
        fadeDelay: DEFAULT_FLASH_FADE_DELAY,
      })
    }
  }, 100)
  return true
}
