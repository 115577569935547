import {
  ChartThemeSelect,
  defaultChartTheme,
} from '@fintastic/web/feature/charts'
import React from 'react'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { ChartSettingsSection } from '../shared'

export const MoreSettingsSection: React.FC = () => {
  const { loading } = useChartSettingsContext()

  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <ChartSettingsSection>
      <ChartThemeSelect
        disabled={loading}
        chartTheme={localSettings.theme || defaultChartTheme}
        onChangeChartSettings={handleSettingsChange}
      />
    </ChartSettingsSection>
  )
}
