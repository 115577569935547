import React, {
  ForwardedRef,
  forwardRef,
  PropsWithoutRef,
  RefAttributes,
} from 'react'
import { SmartSelectOption } from '../../types'
import {
  StyledRoot,
  StyledText,
  StyledHighlightedText,
  StyledPlaceholder,
  StyledOptionText,
  StyledIcon,
  StyledTextMain,
  StyledFieldset,
} from './SmartSelectInputButton.styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { SxProps, Theme, FormControl, InputLabel } from '@mui/material'

export type SmartSelectInputButtonProps<T> = {
  placeholder?: React.ReactNode
  values: SmartSelectOption<T>[]
  onClick?: () => void
  isOpened?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
  label?: string
}

const _SmartSelectInputButton = <T,>(
  {
    placeholder,
    values,
    onClick,
    isOpened,
    disabled,
    sx,
    label,
  }: SmartSelectInputButtonProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) => (
  <FormControl fullWidth={true}>
    {label && (
      <InputLabel shrink={true} focused={!!isOpened}>
        {label}
      </InputLabel>
    )}
    <StyledRoot
      ref={ref}
      onClick={onClick}
      isOpened={!!isOpened}
      disabled={!!disabled}
      sx={sx}
    >
      <StyledText>
        {values.length === 0 ? (
          <StyledPlaceholder variant="body2">{placeholder}</StyledPlaceholder>
        ) : (
          <StyledOptionText variant="body2">
            <StyledTextMain>{values?.[0]?.label}</StyledTextMain>
            {values.length > 1 && (
              <StyledHighlightedText>
                &nbsp;{`(+${values.length - 1})`}
              </StyledHighlightedText>
            )}
          </StyledOptionText>
        )}
      </StyledText>
      <StyledIcon>
        <ArrowDropDownIcon />
      </StyledIcon>
      <StyledFieldset
        aria-hidden="true"
        isOpened={!!isOpened}
        hasLabel={!!label}
      >
        {label && (
          <legend>
            <span>{label}</span>
          </legend>
        )}
      </StyledFieldset>
    </StyledRoot>
  </FormControl>
)

export const SmartSelectInputButton = forwardRef(_SmartSelectInputButton) as <
  T,
>(
  props: PropsWithoutRef<SmartSelectInputButtonProps<T>> &
    RefAttributes<HTMLButtonElement>,
) => ReturnType<typeof _SmartSelectInputButton>
