import React from 'react'
import type { ICellRendererParams } from 'ag-grid-community'
import { Box, Typography } from '@mui/material'
import { ListIcon, MetricIcon } from '@fintastic/web/feature/metrics-and-lists'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'
import { WidgetReportIcon } from '@fintastic/shared/ui/icons'
import { MetricOrListType } from '@fintastic/web/util/metrics-and-lists'
import { capitalizeFirstLetter } from '@fintastic/shared/util/formatters'

const iconsMap: Record<MetricOrListType | 'report', () => React.ReactNode> = {
  metric: () => <MetricIcon size="small" />,
  list: () => <ListIcon size="small" />,
  report: () => (
    <WidgetReportIcon
      fontSize={'small'}
      sx={{
        color: '#D45702',
      }}
    />
  ),
}

export const TableTypeCellRenderer = ({ value }: ICellRendererParams<any>) => {
  const icon = iconsMap[value as MetricOrListType | 'report']()
  const text = capitalizeFirstLetter(value)

  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box
        component="span"
        sx={{
          ...sxMixins.alignContentCenter(),
          mr: 1,
        }}
      >
        {icon}
      </Box>
      {text}
    </Typography>
  )
}
