import { EditingBlockedTooltip } from './types'
import { Maybe } from '@fintastic/shared/util/types'

export const DEFAULT_TEXT = 'Editing is not allowed'

export function getTooltipText(tooltips: Maybe<EditingBlockedTooltip>[]) {
  const topPriorityText = (
    tooltips.filter((t) => t !== null) as EditingBlockedTooltip[]
  ).sort((a, b) => a.priority - b.priority)[0]?.text
  return topPriorityText !== undefined ? topPriorityText : DEFAULT_TEXT
}
