import React from 'react'

type WeightingMetricDescriptionProps = {
  affectedTypes?: string
}

export const WeightingMetricDescription: React.FC<
  WeightingMetricDescriptionProps
> = ({ affectedTypes = 'Metrics / Lists' }) => (
  <>
    <span style={{ marginTop: '1em', display: 'block' }}>
      The Metric you are attempting to delete is currently being used as a
      weighting Metric by one or more {affectedTypes}' columns.
    </span>
    <span style={{ marginTop: '0.8em', display: 'block' }}>
      Please remove the Metric from aggregation settings of the following{' '}
      {affectedTypes}' columns and try again:
    </span>
  </>
)
