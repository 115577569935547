import React, { useCallback } from 'react'
import {
  Avatar,
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { LabelItem, OnLabelCallbacks } from './types'
import { LabelsDropdownEditButton } from './LabelsDropdownEditButton'

export const LabelsDropdownItem: React.FC<
  { label: LabelItem } & OnLabelCallbacks
> = ({
  label,
  onLabelUnchecked,
  onLabelChecked,
  onLabelUpdated,
  onLabelDeleted,
}) => {
  const handleCheckboxChange = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      const toggledChecked = !label.checked
      if (toggledChecked) {
        onLabelChecked({ ...label, checked: true })
      } else {
        onLabelUnchecked({ ...label, checked: false })
      }
    },
    [label, onLabelChecked, onLabelUnchecked],
  )

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <LabelsDropdownEditButton
          onLabelUpdated={onLabelUpdated}
          onLabelDeleted={onLabelDeleted}
          label={label}
        />
      }
    >
      <ListItemButton onClick={handleCheckboxChange} dense>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Checkbox
            edge="start"
            tabIndex={-1}
            disableRipple
            checked={label.checked}
            sx={{ py: 0 }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  background: label.color,
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                }}
              >
                {' '}
              </Avatar>{' '}
              {label.title}
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
