import { MetricOrListEditingFlow } from './types'
import { EditableList, EditableMetric } from '../types'

export const namespace = 'metricOrListEditing' as const

export const initialState: MetricOrListEditingState = {
  flow: null,
  type: null,
  metric: null,
  list: null,
}

export type MetricOrListEditingState =
  | DefaultEditingState
  | MetricEditingState
  | ListEditingState

export type DefaultEditingState = {
  flow: null
  type: null
  metric: null
  list: null
}

export type MetricEditingState = {
  flow: MetricOrListEditingFlow
  type: 'metric'
  metric: EditableMetric
  list: null
}

export type ListEditingState = {
  flow: MetricOrListEditingFlow
  type: 'list'
  list: EditableList
  metric: null
}

export type RootState = {
  [namespace]: MetricOrListEditingState
}
