import React from 'react'
import { TabList } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { TopbarTabsRoot } from './TopbarTabs.styled'

export type TopbarTabsProps = {
  onChange: (event: React.SyntheticEvent<Element, Event>, value: string) => void
  readonly tabs: ReadonlyArray<{ readonly value: string; readonly label: string }>
}

export const TopbarTabs: React.FC<TopbarTabsProps> = (props) => (
  <TopbarTabsRoot>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} data-testid="layout-topbar-navigation">
      <TabList onChange={props.onChange} aria-label="Top bar navigation">
        {props.tabs.map((tabProps) => (
          <Tab key={tabProps.value} {...tabProps} />
        ))}
      </TabList>
    </Box>
  </TopbarTabsRoot>
)
