import { first } from 'lodash'
import {
  CalendarDatePickerConfig,
  PeriodResolution,
  PeriodsBasedSelection,
  PeriodsRange,
} from '../types'
import { closestDimensionByResolution } from './closest-dimension-by-resolution'
import { DEFAULT_PERIOD_RESOLUTION, RESOLUTIONS_ORDERED } from './const'
import { convertPeriodSelectionToRange } from './convert-to-range'

export function getDefaultPeriodSelection(
  calendarConfig: CalendarDatePickerConfig,
  forceDimensionId?: string,
): PeriodsBasedSelection {
  const dimensionId =
    forceDimensionId ??
    getDefaultDimensionIdFromCalendar(calendarConfig)?.dimension_id

  const aggregationDimensionId =
    getMostGranularAggregationDimensionIdFromCalendar(
      calendarConfig,
      DEFAULT_PERIOD_RESOLUTION,
    )

  if (!dimensionId) {
    throw new Error('Cannot build default period selection for no dimensionId')
  }

  if (!aggregationDimensionId) {
    throw new Error(
      'Cannot build default period selection for no aggregationDimensionId',
    )
  }

  if (calendarConfig?.length) {
    const todayDimensionValueId = calendarConfig.find(
      (i) => i.dimension_id === dimensionId,
    )?.today

    if (todayDimensionValueId) {
      return {
        aggregationDimensionId,
        dimensionId,
        periods: [todayDimensionValueId],
      }
    }
  }

  const firstYear = first(
    calendarConfig.find((d) => d.dimension_id === dimensionId)?.values ?? [],
  )?.dimension_value_id

  if (!firstYear) {
    throw new Error(
      'Cannot find from and to dimension values for the default periods',
    )
  }

  return {
    aggregationDimensionId,
    dimensionId,
    periods: [firstYear],
  }
}

const getDefaultDimensionIdFromCalendar = (
  calendarConfig: CalendarDatePickerConfig,
) => {
  const leastGranularDimension =
    getLeastGranularResolutionFromCalendar(calendarConfig)

  // Least granular dimnesion found in entries
  return calendarConfig.find((i) => i.resolution === leastGranularDimension)
}

export const getMostGranularAggregationDimensionIdFromCalendar = (
  calendarConfig: CalendarDatePickerConfig,
  minimalGranularity = DEFAULT_PERIOD_RESOLUTION,
) => {
  const mostGranularResolution = closestDimensionByResolution(
    minimalGranularity as PeriodResolution,
    calendarConfig,
  )
  return mostGranularResolution?.dimension_id
}

export function getDefaultPeriodSelectionAsRange(
  calendarConfig: CalendarDatePickerConfig,
  dimensionId: string,
): PeriodsRange {
  return convertPeriodSelectionToRange(
    getDefaultPeriodSelection(calendarConfig, dimensionId),
    calendarConfig,
  )
}

const getLeastGranularResolutionFromCalendar = (
  calendarConfig: CalendarDatePickerConfig,
) =>
  RESOLUTIONS_ORDERED.findLast((resolution) =>
    calendarConfig.find((i) => i.resolution === resolution),
  )
