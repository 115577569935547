import { useMemo } from 'react'
import { usePeriodSelectorContext } from './usePeriodSelectorContext'
import type { Maybe } from '@fintastic/shared/util/types'
import { usePeriodSelectorContextCalendarConfig } from './usePeriodSelectorContextCalendarConfig'
import { CalendarDatePickerConfigValueItem } from '../types'
import { isEqual } from 'lodash'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'

export const usePeriodSelectorOrderedPeriodList = (): Maybe<
  CalendarDatePickerConfigValueItem[]
> => {
  const calendarConfig = usePeriodSelectorContextCalendarConfig()
  const periodSelection = usePeriodSelectorContext()

  const source = useMemo<Maybe<CalendarDatePickerConfigValueItem[]>>(() => {
    if (!periodSelection.aggregationDimensionId) {
      return null
    }

    return (
      calendarConfig.find(
        (i) => i.dimension_id === periodSelection.aggregationDimensionId,
      )?.values ?? []
    )
  }, [calendarConfig, periodSelection.aggregationDimensionId])

  return useReferenceMemo<Maybe<CalendarDatePickerConfigValueItem[]>>(
    source,
    isEqual,
  )
}
