import React from 'react'
import {
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
} from './ActionButton.styled'

export type ActionButtonProps = {
  icon: React.ReactNode
  label: string
  onClick: () => void
  disabled?: boolean
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  onClick,
  disabled,
  label,
}) => (
  <StyledListItem disabled={disabled} onClick={onClick}>
    <StyledListItemIcon>{icon}</StyledListItemIcon>
    <StyledListItemText primary={label} />
  </StyledListItem>
)
