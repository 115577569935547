import { styled, Dialog, DialogProps } from '@mui/material'

export const StyledModalDialogRoot = styled(
  (props: DialogProps & { width?: number | string }) => {
    const { width, ...dialogProps } = props
    return <Dialog {...dialogProps} />
  },
  { shouldForwardProp: (propName) => propName !== 'width' },
)(({ theme, width }) => ({
  '.MuiDialog-paper': {
    width,
    maxWidth: width ? '100%' : undefined,
  },
}))
