import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { BoardCreateForm } from '../../forms/BoardCreateForm'

export type BoardCreateModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

export const BoardCreateModal: React.FC<BoardCreateModalProps> = (props) => {
  const { isOpen, onRequestClose } = props

  return (
    <Modal
      open={isOpen}
      onRequestClose={onRequestClose}
      title="Create New"
      description="Create new Board"
    >
      <BoardCreateForm closeParentModal={onRequestClose} />
    </Modal>
  )
}
