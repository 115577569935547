import { QueryClient } from 'react-query'
import { invalidateVersionDataCache } from './invalidateVersionDataCache'
import { invalidateVersionsListCache } from './invalidateVersionsListCache'

export async function invalidateVersionManagementRelatedQueries(
  queryClient: QueryClient,
  versionIds: string | string[],
) {
  await Promise.all([
    invalidateVersionsListCache(queryClient),
    invalidateVersionDataCache(queryClient, versionIds),
  ])
}
