import {
  StyledExportBackdrop,
  StyledExportProgress,
} from './ExportWithComments.styled'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { DataExportParams } from '../types'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useIsMountedRef } from '@fintastic/shared/util/hooks'
import { exportGrid } from '../utils/grid-export'

export type ExportDataProps = {
  exportParams: DataExportParams
  onClose: () => void
}

export const ExportData: React.FC<ExportDataProps> = ({
  exportParams,
  onClose,
}) => {
  const [shouldExport, setShouldExport] = useState(false)

  const processed = useRef(false)
  const prepared = useRef(false)
  const isMounted = useIsMountedRef()

  useLayoutEffect(() => {
    if (prepared.current) {
      return
    }
    prepared.current = true

    setTimeout(() => {
      if (isMounted.current) {
        setShouldExport(() => true)
      }
    }, 100)
  }, [prepared, isMounted])

  useLayoutEffect(() => {
    if (!shouldExport || processed.current) {
      return
    }
    processed.current = true
    try {
      exportGrid({ ...exportParams })
    } catch (e) {
      toast.error(e?.toString() || 'Unknown error')
    }
    setTimeout(() => {
      if (isMounted.current) {
        onClose()
      }
    }, 1200)
  }, [onClose, shouldExport, isMounted, exportParams, prepared])

  return (
    <StyledExportBackdrop open={true}>
      <StyledExportProgress />
    </StyledExportBackdrop>
  )
}
