import { styled, Box, Menu, Button } from '@mui/material'

export const StyledMenuRoot = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
  },
  '& .MuiList-root': {
    padding: 0,
  },
}))

export const StyledMenuContainer = styled(Box)(({ theme }) => ({
  padding: '16px 24px',
}))

export const StyledMenuButton = styled(Button)(({ theme }) => ({}))
