import React, { useMemo } from 'react'
import { PanelTopbar } from '@fintastic/shared/ui/panel-framework'
import {
  StyledCenteredTitle,
  StyledDeleteButton,
  StyledLeftContent,
  StyledListIcon,
  StyledRightContent,
  StyledTitleButtons,
  StyledTitleRoot,
} from './AddLineDrawerTitle.styled'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Button, Divider, Tooltip, Typography } from '@mui/material'
import { AddNewLineButton } from '@fintastic/shared/ui/grid-framework'
import { MAX_NEW_RECORDS_TO_EDIT } from '../../consts'
import { DeleteIcon, WidgetListIcon } from '@fintastic/shared/ui/icons'
import { LoadingButton } from '@fintastic/shared/ui/components'
import { VersionSelector } from '../../../../../formulas/src/lib/components/Editor/features/version-state/VersionSelector'

export type AddLinesDrawerTitleProps = {
  totalRecordsCount: number
  selectedRecordsCount: number
  validRecordsCount: number
  isBusy: boolean
  versionName: string
  versionId: string
  listName: string
  onSaveClick: () => void
  onDiscardClick: () => void
  onAddRows: (numberOfRows: number) => void
  onDelete: () => void
}

export const AddLineDrawerTitle: React.FC<AddLinesDrawerTitleProps> = ({
  versionName,
  versionId,
  totalRecordsCount,
  selectedRecordsCount,
  validRecordsCount,
  isBusy,
  listName,
  onDiscardClick,
  onSaveClick,
  onAddRows,
  onDelete,
}) => {
  const allowDelete =
    selectedRecordsCount > 0 &&
    selectedRecordsCount !== totalRecordsCount &&
    !isBusy

  const leftContent = useMemo(
    () => (
      <StyledLeftContent>
        <StyledTitleButtons>
          <AddNewLineButton
            onAddNewRows={onAddRows}
            disabled={totalRecordsCount >= MAX_NEW_RECORDS_TO_EDIT || isBusy}
          />
          <StyledDeleteButton disabled={!allowDelete} onClick={onDelete}>
            <DeleteIcon fontSize={'small'} />
          </StyledDeleteButton>
        </StyledTitleButtons>
      </StyledLeftContent>
    ),
    [onAddRows, totalRecordsCount, isBusy, allowDelete, onDelete, listName],
  )

  const centerContent = useMemo(
    () => (
      <StyledCenteredTitle>
        <StyledListIcon>
          <WidgetListIcon />
        </StyledListIcon>
        <Typography variant="body1" fontWeight="bold" sx={{ fontSize: '12px' }}>
          {titleFormatter(listName)}
        </Typography>
      </StyledCenteredTitle>
    ),
    [listName],
  )

  const rightContent = useMemo(
    () => (
      <StyledRightContent>
        <Tooltip
          title={versionName}
          arrow
          disableInteractive
          placement="top"
          disableHoverListener={versionName.length < 20}
        >
          <span>
            <VersionSelector
              onChange={() => void 0}
              value={versionId}
              versions={[
                {
                  id: versionId,
                  title: versionName,
                  formula: '',
                  connectedTable: null,
                },
              ]}
              disabled={true}
            />
          </span>
        </Tooltip>

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

        <Button variant="outlined" color="primary" onClick={onDiscardClick}>
          Discard
        </Button>

        <Tooltip
          title={'At least one record has to be valid for save'}
          arrow
          placement="top"
          disableInteractive
          disableHoverListener={validRecordsCount > 0}
        >
          <span>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={isBusy || validRecordsCount === 0}
              onClick={onSaveClick}
              loading={isBusy}
            >
              Save
            </LoadingButton>
          </span>
        </Tooltip>
      </StyledRightContent>
    ),
    [
      isBusy,
      onDiscardClick,
      onSaveClick,
      validRecordsCount,
      versionId,
      versionName,
    ],
  )

  return (
    <StyledTitleRoot data-testid="add-line-title">
      <PanelTopbar
        leftContent={leftContent}
        rightContent={rightContent}
        centerContent={centerContent}
      />
    </StyledTitleRoot>
  )
}
