import { useCallback, useMemo, useState } from 'react'

export type UsePendingProcesses = {
  addPendingProcess: (processName: string) => void
  finishAll: () => void
  finishPendingProcess: (processName: string) => void
  hasPendingProcess: (processName: string) => boolean
  pendingProcesses: string[]
  isAnyPendingProcess: boolean
}

export const usePendingProcesses = (): UsePendingProcesses => {
  const [pendingProcesses, setPendingProcess] = useState<string[]>([])

  const addPendingProcess = useCallback((processName: string) => {
    setPendingProcess((v) =>
      v.includes(processName) ? v : [...v, processName],
    )
  }, [])

  const finishPendingProcess = useCallback((processName: string) => {
    setPendingProcess((v) =>
      v.includes(processName) ? v.filter((p) => p !== processName) : v,
    )
  }, [])

  const hasPendingProcess = useCallback(
    (processName: string) => pendingProcesses.includes(processName),
    [pendingProcesses],
  )

  const finishAll = useCallback(() => {
    setPendingProcess((_) => [])
  }, [])

  return useMemo(
    () => ({
      addPendingProcess,
      finishPendingProcess,
      finishAll,
      pendingProcesses,
      hasPendingProcess,
      isAnyPendingProcess: pendingProcesses.length > 0,
    }),
    [
      addPendingProcess,
      finishAll,
      finishPendingProcess,
      hasPendingProcess,
      pendingProcesses,
    ],
  )
}
