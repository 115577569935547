import React from 'react'
import type { AgGridColumnHeaderProps } from './types'
import { Box } from '@mui/material'

export type AgGridDefaultColumnHeaderGroupProps = {
  contentAfterText?: React.ReactNode
}

// @todo add tests
export const AgGridDefaultColumnHeaderGroup: React.FC<
  AgGridColumnHeaderProps<AgGridDefaultColumnHeaderGroupProps>
> = ({ contentAfterText, ...restProps }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    }}
  >
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {restProps.displayName}
    </Box>
    {contentAfterText}
  </Box>
)
