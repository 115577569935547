import { useCallback, useMemo, useState } from 'react'

export function useAgGridDisableContextMenuWhileEditing() {
  const [allowContextMenu, setAllowContextMenu] = useState(true)

  const handleCellEditingStarted = useCallback(() => {
    setAllowContextMenu(false)
  }, [])

  const handleCellEditingStopped = useCallback(() => {
    setAllowContextMenu(true)
  }, [])

  return useMemo(
    () => ({
      suppressContextMenu: !allowContextMenu,
      onCellEditingStarted: handleCellEditingStarted,
      onCellEditingStopped: handleCellEditingStopped,
    }),
    [allowContextMenu, handleCellEditingStarted, handleCellEditingStopped],
  )
}
