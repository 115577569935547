/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { RootState, State } from './state'

import type { AppServerConfig } from '../../types'
import type { Selector } from 'reselect'
import { createSelector } from '@reduxjs/toolkit'
import { makeSelectors as makeRequestSelectors } from '@fintastic/shared/data-access/redux-extension-request'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s.config

export const {
  selectStatus,
  selectIsInitial,
  selectIsError,
  selectIsLoading,
  selectError,
} = makeRequestSelectors(selectAll)

export const selectAllWithStatus: Selector<
  RootState,
  State & {
    isInitial: ReturnType<typeof selectIsInitial>
    isError: ReturnType<typeof selectIsError>
    isLoading: ReturnType<typeof selectIsLoading>
  }
> = createSelector(
  selectAll,
  selectIsLoading,
  selectIsInitial,
  selectIsError,
  (all, isLoading, isInitial, isError) => ({
    ...all,
    isLoading,
    isInitial,
    isError,
  }),
)

export const selectFeaturesDict: Selector<
  RootState,
  AppServerConfig['features_dict']
> = (rootState) => selectAll(rootState)?.config?.features_dict || {}
