// @todo add tests
export function scrollContainerToNodeBottom(
  container: HTMLElement,
  node: HTMLElement,
  padding = 0,
) {
  const bottomDiff =
    node.getBoundingClientRect().bottom -
    container.getBoundingClientRect().bottom
  // eslint-disable-next-line no-param-reassign
  container.scrollTop += bottomDiff + padding
}
