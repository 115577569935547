import type { DeeplinkKey, DeeplinkValue } from '../types'
import { useMemo, useRef } from 'react'
import { getObjectFromUrlSearchParams } from '../useSyncDeeplinkValue/serialize-to-search-params'
import { useSearchParams } from 'react-router-dom'
import { isEqual } from 'lodash'

export function useReadDeeplinkValuesArrayBySuffix<T extends DeeplinkValue>(
  suffix: string,
): Partial<Record<DeeplinkKey, T>> {
  const [searchParams] = useSearchParams()
  const oldValueRef = useRef<Partial<Record<string, T>>>()

  return useMemo<Partial<Record<string, T>>>(() => {
    const nextValue: Partial<Record<DeeplinkKey, T>> = {}
    searchParams.forEach((value, key) => {
      if (key.endsWith(suffix)) {
        const parsed = getObjectFromUrlSearchParams<T>({
          key,
          searchParams,
        })

        if (parsed) {
          nextValue[key as DeeplinkKey] = parsed
        }
      }
    })

    if (isEqual(oldValueRef.current, nextValue)) {
      return oldValueRef.current as Partial<Record<string, T>>
    }

    oldValueRef.current = nextValue

    return nextValue
  }, [searchParams, suffix])
}
