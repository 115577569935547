import { getDataTypeGroup } from '../data-types'
import { Maybe, RollUpFunction } from '@fintastic/shared/util/types'
import { mean, sum } from 'lodash'
import { MetricDataValue, MetricDataValueType } from '../../types'

// @todo add tests
export function rollUpTimeColumns(
  columns: MetricDataValue[][],
  dataType: MetricDataValueType,
  rollUpFunction: RollUpFunction,
): MetricDataValue[] {
  const dataTypeGroup = getDataTypeGroup(dataType)

  if (
    dataType === 'string' ||
    (dataType === 'datetime' &&
      rollUpFunction !== 'first' &&
      rollUpFunction !== 'last')
  ) {
    throw new Error("can't rollup such data type")
  }

  if (rollUpFunction === 'first') {
    return columns[0]
  }

  if (rollUpFunction === 'last') {
    return columns[columns.length - 1]
  }

  const rowsAmount = columns[0].length

  return Array(rowsAmount)
    .fill(undefined)
    .map((_, rowIndex) => {
      const valuesToRollup = Array(columns.length)
        .fill(undefined)
        .map((_, columnIndex) => {
          const value = columns[columnIndex][rowIndex]
          if (dataTypeGroup !== 'numeric') {
            return value ? 1 : 0
          }
          return value as Extract<MetricDataValue, number | null | symbol>
        })
        .filter((v) => typeof v === 'number') as number[]

      let result: Maybe<number> = 0

      // @todo implement for weighted_avg
      if (rollUpFunction === 'weighted_avg') {
        result = null
      }

      if (rollUpFunction === 'sum') {
        result = sum(valuesToRollup)
      }

      if (rollUpFunction === 'mean') {
        result = mean(valuesToRollup)
      }

      return dataType === 'boolean' ? Boolean(result) : result
    })
}
