import { isArray, isEmpty, isEqual, isNumber } from 'lodash'
import {
  Filter,
  FilterOperator,
  FilterValue,
  FilterModelValues,
  FilterModel,
} from '../types'
import { FilterListAPIOperatorValue } from '../api-types'
import { mapFiltersToApiPayload } from './mapFiltersToApiPayload'

export const isFilterEmpty = (
  filters: Filter[],
  filterValues: FilterModelValues,
): boolean =>
  filters.every((filter) =>
    Object.values(filterValues?.[filter.id] ?? {}).every(
      (value) => isFilterValueEmpty(filter, value) || value?.valid === false,
    ),
  )

export const isFilterValueEmpty = (
  filter: Filter,
  { value, operator }: Partial<FilterValue> = {},
): boolean => {
  if (filter.type === 'dimension') {
    return !isArray(value) || filter.options.length === 0
  }

  if (isEmpty(value) && !operator) {
    return true
  }

  if (isArray(value)) {
    return false
  }

  if (isNumber(value)) {
    return false
  }

  if (operator === 'contains' || operator === 'equal') {
    return value === '' || value === undefined
  }

  return false
}

export const filterHasMeaningfulValue = ({
  value,
  operator,
}: Partial<FilterValue> = {}): boolean =>
  !isFilterValueNonAffective(value, operator ?? 'equal')

export const areFilterModelsEqual = (
  filterModelA: FilterModel,
  filterModelB: FilterModel,
): boolean => {
  const aAPIModel = mapFiltersToApiPayload(filterModelA)
  const bAPIModel = mapFiltersToApiPayload(filterModelB)

  return isEqual(aAPIModel, bAPIModel)
}

export const areFilterValuesEqual = (
  filter: Filter,
  filterValueA?: FilterValue<FilterListAPIOperatorValue>,
  filterValueB?: FilterValue<FilterListAPIOperatorValue>,
): boolean => {
  const AEmpty = isFilterValueEmpty(filter, filterValueA)
  const BEmpty = isFilterValueEmpty(filter, filterValueB)

  if (AEmpty && BEmpty) {
    return true
  }

  if (AEmpty || BEmpty) {
    return false
  }

  return isEqual(filterValueA, filterValueB)
}

const isFilterValueNonAffective = (
  primitive: FilterListAPIOperatorValue | undefined,
  operator: FilterOperator,
): boolean => {
  if (operator === 'blank' || operator === 'not_blank') {
    return false
  }

  if (primitive === '' || primitive === undefined || primitive === null) {
    return true
  }

  if (operator === 'range') {
    if (!isArray(primitive)) {
      return true
    }

    const invalidFirst = primitive[0] === undefined || primitive[0] === ''
    const invalidSecond = primitive[1] === undefined || primitive[1] === ''

    return invalidFirst && invalidSecond
  }

  return false
}
