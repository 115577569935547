import { ColDef } from 'ag-grid-community'

export const MAX_NEW_RECORDS_TO_EDIT = 100

export const validityColumnDefinition: ColDef = {
  colId: '_valid',
  pinned: true,
  suppressMenu: true,
  sortable: false,
  resizable: false,
  headerName: '',
  initialWidth: 32,
  minWidth: 32,
  maxWidth: 32,
  filter: false,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: false,
}
