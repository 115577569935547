import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import React from 'react'
import { ErrorAlert } from './ErrorAlert'

// @todo narrow icon design
export const AccessDeninedAlert: React.FC = () => (
  <ErrorAlert
    icon={<HighlightOffIcon fontSize="inherit" />}
    title="Access denied"
    message="You don't have access to this page. Please reach out administrator."
    severity="warning"
  />
)
