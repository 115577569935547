import React from 'react'
import { MetricDisplaySettingsFormatting } from '@fintastic/web/util/metrics-and-lists'
import { useFlagsContext } from '../../flags-context'
import { InlineSelectWithLabel } from '@fintastic/shared/ui/components'
import { decimalPlacesOptions } from './options/decimal-places-options'

type Value = MetricDisplaySettingsFormatting['decimal_places']

export type DecimalPlacesFieldProps = {
  value: Value
  onChange: (newValue: Value) => void
}

export const DecimalPlacesField: React.FC<DecimalPlacesFieldProps> = ({
  value,
  onChange,
}) => {
  const { readonly } = useFlagsContext()

  return (
    <InlineSelectWithLabel<Value>
      label="Decimal places"
      value={value}
      onChange={onChange}
      disabled={readonly}
      options={decimalPlacesOptions}
    />
  )
}
