import { SharedActionsContextValue } from '../../../shared/connection/actions/types'
import { createContext, useContext } from 'react'
import { Actions } from './types'

export type ActionsContextValue = SharedActionsContextValue<Actions>

const stub = () => {
  throw new Error('ActionsContext is not initialised')
}

export const ActionsContext = createContext<ActionsContextValue>({
  readonly: false,
  flow: 'creation',
  actions: {
    addDimension: stub,
    removeDimension: stub,
    changeValueDataType: stub,
    changeValueDataFormatting: stub,
    changeTimeAggregation: stub,
    changeCategoryAggregation: stub,
    changeCurrencySignPosition: stub,
    changeTimeDimension: stub,
  },
})

export const useActionsContext = () => useContext(ActionsContext)
