/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { PayloadAction } from '@reduxjs/toolkit'
import type { State } from './state'
import type { WritableDraft } from '@fintastic/shared/data-access/redux'
import { BoardFolder } from '../../types'

export type ExpandFolderPayload = BoardFolder['id']
export type ExpandFolder = PayloadAction<ExpandFolderPayload>

function expandFolder(state: WritableDraft<State>, action: ExpandFolder): void {
  state.expandedFolders[action.payload] = true
}

export type CollapseFolderPayload = BoardFolder['id']
export type CollapseFolder = PayloadAction<CollapseFolderPayload>

function collapseFolder(
  state: WritableDraft<State>,
  action: CollapseFolder,
): void {
  state.expandedFolders[action.payload] = false
}

export type ExpandAllFolderPayload = BoardFolder['id'][]
export type ExpandAllFolders = PayloadAction<ExpandAllFolderPayload>

function expandAllFolders(
  state: WritableDraft<State>,
  action: ExpandAllFolders,
): void {
  action.payload.forEach((id) => (state.expandedFolders[id] = true))
}

export type ExpandOnlyFolderPayload = BoardFolder['id']
export type ExpandOnlyFolder = PayloadAction<ExpandOnlyFolderPayload>

function expandOnlyFolder(
  state: WritableDraft<State>,
  action: ExpandOnlyFolder,
): void {
  Object.keys(state.expandedFolders).forEach((key) => {
    state.expandedFolders[key] = false
  })
  state.expandedFolders[action.payload] = true
}

export type CollapseAllFolderPayload = BoardFolder['id'][]
export type CollapseAllFolders = PayloadAction<CollapseAllFolderPayload>

function collapseAllFolders(
  state: WritableDraft<State>,
  action: CollapseAllFolders,
): void {
  action.payload.forEach((id) => (state.expandedFolders[id] = false))
}

export type SetDesignMode = PayloadAction<undefined>

function setBoardDesignMode(
  state: WritableDraft<State>,
  action: SetDesignMode,
): void {
  state.boardMode = 'design'
}

function setBoardDisplayMode(
  state: WritableDraft<State>,
  action: SetDesignMode,
): void {
  state.boardMode = 'display'
}

// export reducers below in default export to avoid names overlapping with action creators
export default {
  expandFolder,
  expandAllFolders,
  collapseAllFolders,
  collapseFolder,
  expandOnlyFolder,
  setBoardDesignMode,
  setBoardDisplayMode,
}
