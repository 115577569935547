import { useCallback } from 'react'
import {
  UserData,
  useUserListQuery,
  useUpdateUserMutation,
} from '@fintastic/web/data-access/iam'

export const useUserList = () => {
  const query = useUserListQuery()

  const updateUserMutation = useUpdateUserMutation()

  const onUserChange = useCallback(
    (user: UserData) => updateUserMutation.mutate(user),
    [updateUserMutation.mutate],
  )

  return {
    users: query.data,
    onUserChange,
    loading: query.isLoading,
    mutating: updateUserMutation.isLoading,
  }
}
