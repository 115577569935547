import { FocusedToken } from './types'
import { useEffect, useState } from 'react'
import { Token } from '../tokens/types'
import { deriveFocusedToken } from './deriveFocusedToken'
import { Maybe } from '@fintastic/shared/util/types'

export function useFocusedToken(tokens: Token[], caretPosition: number) {
  const [focusedToken, setFocusedToken] = useState<Maybe<FocusedToken>>(
    deriveFocusedToken(tokens, caretPosition),
  )

  useEffect(() => {
    setFocusedToken(deriveFocusedToken(tokens, caretPosition))
  }, [tokens, caretPosition])

  return focusedToken
}
