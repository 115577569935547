import React, { useCallback, useMemo } from 'react'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { GeneralSettings } from '../settings/general'
import { useDataContext } from '../connection/data/data-context'
import { Box, Divider } from '@mui/material'
import { ColumnsSettings } from '../settings/columns'
import { useMemoryRouterContext } from '@fintastic/shared/util/memory-router'
import { memoryRoutesPaths } from '../memory-routes-paths'
import { useActionsContext } from '../connection/actions/actions-context'
import {
  ColumnSettingsContext,
  ColumnsSettingsContextValue,
} from '../settings/columns/columns-settings-context'
import { TimeDimensionSelector } from '../settings/time-dimension-selector/TimeDimensionSelector'
import { generateNewColumnName } from '../settings/columns/column-creation/generateNewColumnName'
import { createClientOnlyColumnId } from '@fintastic/web/data-access/metrics-and-lists'
import { ActionButtonsContainer } from '../../shared/ActionButtonsContainer'
import { AddColumnButton } from '../settings/columns/column-creation/AddColumnButton'

export const List: React.FC = () => {
  const { list } = useDataContext()
  const { goTo } = useMemoryRouterContext()
  const { readonly, actions } = useActionsContext()

  const handleGoToColumn = useCallback(
    (columnId: string) => {
      goTo(memoryRoutesPaths.column(columnId))
    },
    [goTo],
  )

  const id = list?.id() || ''
  const columns = useMemo(() => list?.columns() || [], [list])
  const calculated = !!list?.isCalculated()

  const handleAddNewColumn = useCallback(() => {
    const defaultNewColumnName = generateNewColumnName(columns)
    const newColumnId = createClientOnlyColumnId(id)
    actions.addColumn(defaultNewColumnName, newColumnId)
    handleGoToColumn(newColumnId)
  }, [actions, columns, handleGoToColumn, id])

  const columnSettingsContextValue = useMemo<ColumnsSettingsContextValue>(
    () => ({
      listId: id,
      onGoToColumn: handleGoToColumn,
      columns: columns,
      calculated,
      readonly: list?.source() === 'calculated' || readonly,
      onReorderColumns: actions.reorderColumns,
      onChangeColumnVisibility: actions.changeColumnVisibility,
    }),
    [
      actions.changeColumnVisibility,
      actions.reorderColumns,
      calculated,
      columns,
      handleGoToColumn,
      id,
      list,
      readonly,
    ],
  )

  return (
    <ViewLayout
      title="List settings"
      enableBackButton={false}
      footer={
        list &&
        !calculated &&
        !readonly && (
          <>
            <Divider sx={{ mb: 1 }} />
            <ActionButtonsContainer>
              <AddColumnButton onClick={handleAddNewColumn} />
            </ActionButtonsContainer>
          </>
        )
      }
    >
      {list && (
        <>
          <GeneralSettings name={list.label()} />
          <Box pt={2}>
            <TimeDimensionSelector />
          </Box>
          {!list.columnsAreEmpty() && <Divider sx={{ mt: 3, mb: 2 }} />}
          <Box sx={{ pb: 1 }}>
            <ColumnSettingsContext.Provider value={columnSettingsContextValue}>
              <ColumnsSettings />
            </ColumnSettingsContext.Provider>
          </Box>
        </>
      )}
    </ViewLayout>
  )
}
