import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo, useMemo } from 'react'

export const DialogSectionDataSubtitle: React.FC = memo(() => {
  const { data } = useWidgetSettingsDialogContext()

  const title = useMemo(() => {
    if ((data.chartSettings?.dimensions || []).length === 0) {
      return 'Please select a metric with numeric columns'
    }
    return 'Dimensions: ' + (data.chartSettings?.dimensions || []).length
  }, [data.chartSettings?.dimensions])

  return <span>{title}</span>
})
