import { styled } from '@mui/material'

export const StyledLockVersionCell = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  & > span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
`
