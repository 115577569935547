import { createKeys } from './utils'

export const chartGridModes = {
  none: 'None',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  both: 'Both',
}

export type ChartGridMode = keyof typeof chartGridModes

export const chartGridModeKeys: ChartGridMode[] =
  createKeys<ChartGridMode>(chartGridModes)

export function isChartGridMode(value: unknown): value is ChartGridMode {
  return chartGridModes[value as ChartGridMode] !== undefined
}

export const defaultChartGridMode: ChartGridMode = 'horizontal'

export function getChartGridModeName(gridMode?: ChartGridMode) {
  return chartGridModes[
    isChartGridMode(gridMode) ? gridMode : defaultChartGridMode
  ]
}
