import { styled, Box, Typography } from '@mui/material'

export const StyledRoot = styled(Box)``

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: bold!important;
`

export const StyledMainContent = styled(Box)`
  margin-top: 4px;
`

export const StyledPostContent = styled(Box)``
