import { useLoadMetricForCharts } from '@fintastic/web/data-access/metrics-and-lists'
import { useMemo } from 'react'
import { toMaybe } from '@fintastic/shared/util/types'
import { useVersionEntitiesContextValue } from '@fintastic/web/data-access/versions'
import { MetricChartDimensions } from '../types' // @todo: replace with new API to get dimensions

// @todo: replace with new API to get dimensions
export const useMetricDimensions = (metricId: string, version: string) => {
  const metric = useLoadMetricForCharts(version, metricId, true)

  const entitiesContextValue = useVersionEntitiesContextValue([version])

  const loading = metric.isLoading || entitiesContextValue.isLoading
  // source: dimensionsLabelMapFromMetricDataList,
  // frontend/main-client/libs/web/feature/metrics-and-lists/src/lib/components/metric-grid/features/dimensions/dimensionsLabelMapFromMetricDataList.ts

  return useMemo(() => {
    const dimensions: MetricChartDimensions = []

    const metricDimensions = metric.data?.metadata.dimensions

    if (loading || !metricDimensions || metricDimensions.length === 0) {
      return {
        dimensions,
        loading,
        error: metric.error,
      }
    }

    const valueType = metric.data?.metadata?.value?.type
    if (
      valueType === 'datetime' ||
      valueType === 'string' ||
      valueType === 'boolean'
    ) {
      return {
        dimensions,
        loading,
        error: `Non-processable Metric value type ${valueType}`,
      }
    }

    const versionEntities = toMaybe(entitiesContextValue.entities?.[version])

    dimensions.push({
      id: 'Version',
      label: 'Version',
      type: 'Version',
    })

    metricDimensions.forEach((dimension) => {
      const dimId = dimension.id
      const localDimension = metricDimensions?.find((d) => d.id === dimId)

      const versionDimension = versionEntities
        ?.findDimensionById(dimId)
        ?.unwrap()

      const dimType =
        localDimension?.type || versionDimension?.type || 'Category'

      dimensions.push({
        id: dimId,
        type: dimType,
        label: versionDimension?.label || dimId,
        ...(dimType === 'Time'
          ? { time_dimension: true }
          : { aggregate: false }),
      })
    })

    return {
      dimensions,
      loading,
      error: metric.error,
    }
  }, [
    metric.data?.metadata.dimensions,
    metric.data?.metadata?.value?.type,
    metric.error,
    entitiesContextValue.entities,
    version,
    loading,
  ])
}
