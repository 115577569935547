import { QueryClient } from 'react-query'
import {
  DependencyGraphWrapper,
  VersionDependencyGraph,
} from '@fintastic/web/util/versions'
import { Maybe } from '@fintastic/shared/util/types'
import { loadManuallyVersionDependenciesGraph } from '../hooks/api/load-version-dependency-graph'

export async function getVersionDependenciesGraph(
  queryClient: QueryClient,
  versionId: string,
): Promise<Maybe<DependencyGraphWrapper>> {
  let data: Maybe<VersionDependencyGraph> = null

  try {
    data = await loadManuallyVersionDependenciesGraph(queryClient, versionId)
  } catch (e) {
    console.error("Can't load the version dependencies graph", e)
  }

  return data ? new DependencyGraphWrapper(data) : null
}
