import React, { useEffect, useMemo, useRef } from 'react'
import { useMemoryRouterContext } from '@fintastic/shared/util/memory-router'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { ColumnSettings } from '../settings/column'
import { useActionsContext } from '../connection/actions/actions-context'
import { useColumnByUrlParam } from './hooks/useColumnByUrlParam'
import { useDataContext } from '../connection/data/data-context'
import { memoryRoutesPaths } from '../memory-routes-paths'

export const Column: React.FC = () => {
  const { goTo } = useMemoryRouterContext()
  const column = useColumnByUrlParam()
  const {
    actions,
    flow,
    readonly,
    allowExistingColumnsDeletion,
    enableExistingColumnsDeletion,
  } = useActionsContext()
  const { list, dimensionsList } = useDataContext()

  const prevColumnRef = useRef(column)
  useEffect(() => {
    if (!column && prevColumnRef.current) {
      goTo(memoryRoutesPaths.list)
    }
    prevColumnRef.current = column
  }, [column, goTo])

  useEffect(() => {
    if (flow === null && !column) {
      goTo(memoryRoutesPaths.list)
    }
  }, [column, goTo, flow])

  const restColumnsNames = useMemo(
    () =>
      list
        ?.columns()
        .filter((m) => m.id() !== column?.id())
        .map((m) => m.label()) || [],
    [column, list],
  )

  const areThereAnotherInputColumns =
    (list?.amountOfNonCalculatedColumns() || 0) -
      (list?.isCalculated() ? 0 : 1) >
    0

  return (
    <ViewLayout title={'Column settings'}>
      {column && dimensionsList !== null && (
        <ColumnSettings
          listSource={list?.source() || 'input'}
          listHasBaseTimeDimension={list?.baseTimeDimension() !== null}
          column={column}
          readonly={!!readonly}
          editingFlow={flow}
          onGoToRoute={goTo}
          actions={actions}
          dimensionsList={dimensionsList}
          restExistingColumnNames={restColumnsNames}
          anotherInputColumnsExist={areThereAnotherInputColumns}
          allowExistingColumnsDeletion={allowExistingColumnsDeletion}
          enableExistingColumnsDeletion={enableExistingColumnsDeletion}
        />
      )}
    </ViewLayout>
  )
}
