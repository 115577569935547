import React from 'react'
import { VersionEntityType } from '@fintastic/web/util/versions'
import {
  WidgetMetricIcon,
  WidgetListIcon,
  DimensionIcon,
  ColumnIcon,
  WidgetReportIcon,
} from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

export type EntityIconProps = {
  type: VersionEntityType | 'report'
  iconProps?: SvgIconProps
}

export const EntityIcon: React.FC<EntityIconProps> = ({ type, iconProps }) => {
  if (type === 'metric') {
    return <WidgetMetricIcon {...iconProps} />
  }
  if (type === 'list') {
    return <WidgetListIcon {...iconProps} />
  }
  if (type === 'dimension') {
    return <DimensionIcon {...iconProps} />
  }
  if (type === 'report') {
    return <WidgetReportIcon {...iconProps} />
  }
  return <ColumnIcon {...iconProps} />
}
