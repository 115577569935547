import { styled, Box, ButtonBase, Typography } from '@mui/material'

export const StyledAddNewBoardButtonRoot = styled(ButtonBase)(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'flex-start',

  'paddingRight': theme.spacingFromPixels(14),
  'paddingLeft': theme.spacingFromPixels(24),

  'width': '100%',
  'minHeight': theme.spacingFromPixels(40),

  'textAlign': 'left',

  'transition': `background ${theme.transitions.duration.standard}ms`,

  '&:hover': {
    background: theme.palette.primary.light,
  },
}))

export const StyledAddNewBoardButtonIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacingFromPixels(16),

  fontSize: 0,

  svg: {
    width: theme.spacingFromPixels(24),
    height: 'auto',
  },
}))

export const StyledAddNewBoardButtonText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
}))
