import React, { useMemo } from 'react'
import {
  PageLayout,
  PageLayoutContentFullHeight,
} from '@fintastic/shared/ui/app-layout-framework'
import { UserData } from '@fintastic/web/data-access/iam'
import {
  isVersionEditable,
  Version as VersionType,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'
import { VersionTable } from '../components/version-management/types'
import { usePeriodSelectionComponent } from '@fintastic/web/feature/period-selector'
import { PeriodSelectorContext } from '@fintastic/web/util/period-selector'
import {
  CenteredCircularProgress,
  ErrorAlert,
} from '@fintastic/shared/ui/components'
import { LiveActuals } from '../components/version-management/LiveActuals/LiveActuals'
import { LiveActualsTopBar } from '../components/LiveActualsTopBar/LiveActualsTopBar'
import {
  useFullVersionRangePeriodSelection,
  useVersionBaseTimeDimension,
} from '@fintastic/web/data-access/versions'
import {
  BaseTimeDimensionStaticWidgetProvider,
  useBaseTimeDimensionStaticWidgetAdapter,
} from '@fintastic/web/data-access/base-time-dimension'
import { ListEditorProvider } from '@fintastic/web/feature/list-editor'

export const LiveActualsPageContainer: React.FC<{
  version: VersionType
  versionTables: VersionTable[]
  versionUserLock: VersionUserLockParsed
  users: UserData[]
  pageTitle: string
  isUpdated: boolean
}> = ({
  version,
  versionTables,
  versionUserLock,
  users,
  pageTitle,
  isUpdated,
}) => {
  const selectedVersions = useMemo(() => [version], [version])
  const defaultSelection = useFullVersionRangePeriodSelection(version)
  const baseVersionDimension = useVersionBaseTimeDimension(version.uuid)
  const baseDimensionContextValue =
    useBaseTimeDimensionStaticWidgetAdapter(baseVersionDimension)
  const periodSelection = usePeriodSelectionComponent({
    selectedVersions,
    persistingKey: 'live_actuals',
    defaultSelection,
    baseTimeDimensionId: baseVersionDimension,
    buttonDesign: 'full',
  })

  if (periodSelection.isError) {
    return (
      <ErrorAlert
        title="Something went wrong"
        message="Failed to load all required data. Please contact the administrator."
      />
    )
  }

  if (periodSelection.isLoading) {
    return (
      <PageLayout>
        <CenteredCircularProgress />
      </PageLayout>
    )
  }

  return (
    <BaseTimeDimensionStaticWidgetProvider value={baseDimensionContextValue}>
      <PeriodSelectorContext.Provider value={periodSelection.contextValue}>
        <PageLayout
          topbar={
            <LiveActualsTopBar
              periodSelector={periodSelection.periodSelectorComponent}
              pageTitle={pageTitle}
              isUpdated={isUpdated}
              versionId={version.uuid}
            />
          }
        >
          <PageLayoutContentFullHeight>
            <ListEditorProvider
              versionId={version.uuid}
              isVersionEditable={isVersionEditable(version)}
              isLiveActuals={true}
            >
              <LiveActuals
                version={version}
                versionTables={versionTables}
                editable={isVersionEditable(version)}
                versionUserLock={versionUserLock}
                users={users}
              />
            </ListEditorProvider>
          </PageLayoutContentFullHeight>
        </PageLayout>
      </PeriodSelectorContext.Provider>
    </BaseTimeDimensionStaticWidgetProvider>
  )
}
