import { createVersionUserLockerQueryHandler } from './version-user-locker-query-handler'
import { useQueriesWithMemo } from '@fintastic/shared/data-access/react-query'
import { VersionUserLock } from '@fintastic/web/util/versions'
import { useMemo } from 'react'
import { versionsCacheKeys } from '../../cache'

export function useLoadVersionUserLockersList(versionIds: string[]) {
  return useQueriesWithMemo<VersionUserLock[], Error>(
    useMemo(
      () =>
        versionIds.map(
          (versionId) =>
            ({
              queryKey: versionsCacheKeys.versionUserLock(versionId || ''),
              queryFn: createVersionUserLockerQueryHandler(versionId),
              refetchInterval: 60 * 1000,
            } as const),
        ),
      [versionIds],
    ),
  )
}
