import { AG_THEME_FINTASTIC_DEFAULT_OPTIONS } from '../const'
import { deepmerge } from '@mui/utils'
import { CSSObject } from '@mui/styled-engine'
import { agFintasicThemeVariantDefault } from './default'
import type {
  AgFintasticThemeVariantConfig,
  AgFintasticThemeVariantGridPropsGetter,
  AgFintasticThemeVariantStylesBuilder,
} from '../types'
import { AG_DATE_CELL_EDITOR_CLASS_NAME } from '@fintastic/shared/ui/ag-grid'

const stylesBuilder: AgFintasticThemeVariantStylesBuilder = (options) => {
  const { muiTheme } = {
    ...AG_THEME_FINTASTIC_DEFAULT_OPTIONS,
    ...options,
  }
  const t = muiTheme

  /**
   * to see all available variables, check it here:
   * ag-grid-community/src/styles/ag-theme-base/sass/ag-theme-base-default-params
   */
  const cssVariables: CSSObject = deepmerge(
    agFintasicThemeVariantDefault.stylesBuilder(options),
    {
      '--ag-root-font-size': '11px',
      '--ag-font-size': '11px',
      '--ag-line-height': '24px',
      '--ag-cell-padding-x': '9px',
    },
  )

  const cellEditors: CSSObject = {
    [`& .${AG_DATE_CELL_EDITOR_CLASS_NAME}`]: {
      '.MuiFormControl-root': {
        width: '100%',
      },
      '.MuiInputAdornment-root': {
        '.MuiButtonBase-root': {
          fontSize: '1rem',
        },
        '.MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      },
    },
  }

  return {
    ...cssVariables,
    ...cellEditors,
  }
}

const gridPropsGetter: AgFintasticThemeVariantGridPropsGetter = () => ({
  ...agFintasicThemeVariantDefault.gridPropsGetter(),
  rowHeight: 27,
  headerHeight: 27,
})

export const agFintasicThemeVariantCompact: AgFintasticThemeVariantConfig = {
  stylesBuilder,
  gridPropsGetter,
}
