import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ReorderColumnsPayload } from '../../types'
import { castToListEditingState } from './helpers'
import { MutableListWrapper } from '../../../abstract-data-types/list'

export const reorderColumns = (
  uncheckedState: State,
  { payload: { columnsIds } }: PayloadAction<ReorderColumnsPayload>,
) => {
  const state = castToListEditingState(uncheckedState)
  const list = new MutableListWrapper(state.list)

  list.reorderColumns(columnsIds)
}
