import React from 'react'

export const FolderExpandedIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 10.4062H19.2188V7.74375C19.2188 7.32891 18.8836 6.99375 18.4688 6.99375H11.0859L8.33672 4.36406C8.30174 4.33132 8.25573 4.31291 8.20781 4.3125H2.25C1.83516 4.3125 1.5 4.64766 1.5 5.0625V18.9375C1.5 19.3523 1.83516 19.6875 2.25 19.6875H18.6094C18.9141 19.6875 19.1906 19.5023 19.3055 19.2188L22.4461 11.4375C22.4813 11.3484 22.5 11.2523 22.5 11.1562C22.5 10.7414 22.1648 10.4062 21.75 10.4062ZM3.1875 6H7.60547L10.4086 8.68125H17.5312V10.4062H5.57812C5.27344 10.4062 4.99688 10.5914 4.88203 10.875L3.1875 15.075V6ZM18.0773 18H3.72656L6.14766 12H20.5008L18.0773 18Z"
      fill="#263646"
    />
  </svg>
)
