import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { FailedDataUpdateEvent } from '../../types'
import * as s from './selectors'
import { Maybe } from '@fintastic/shared/util/types'
export class FailedDataUpdateEventWrapper
  implements ImmutableADTWrapper<FailedDataUpdateEvent>
{
  _rawData: FailedDataUpdateEvent

  constructor(rawEvent: FailedDataUpdateEvent) {
    this._rawData = rawEvent
  }
  unwrap(): FailedDataUpdateEvent {
    return this._rawData
  }

  get successful(): false {
    return false
  }

  get userEmail(): string {
    return s.userEmail(this._rawData)
  }

  get versionId(): string {
    return s.versionId(this._rawData)
  }

  get errorText(): Maybe<string> {
    return this._rawData.error_string || this._rawData.error || null
  }
}
