/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { PayloadAction } from '@reduxjs/toolkit'
import type { State } from './state'
import type { WritableDraft } from '@fintastic/shared/data-access/redux'
import { SetDimensionAccess, SetTableAccess, SetTableAccessBulk } from './types'
import {
  ChangeType,
  ColumnAccessType,
  ColumnId,
  ColumnMaskConfig,
  DimensionMap,
  GroupId,
  TableAccess,
} from '../types'
import { PolicyTableResourceType } from '../api'

export type SetPolicies = PayloadAction<{
  groupId: string
  tableAccessList: TableAccess[]
  defaultDimensions: DimensionMap
  policyHash: string
  columnMasking: ColumnMaskConfig[]
}>

function resetPolicies(state: WritableDraft<State>): void {
  state.tableAccessList = {}
  state.defaultDimensionConfig = {}
  state.policyHash = {}
  state.columnMasking = {}
}

function setGroupPolicy(
  state: WritableDraft<State>,
  action: SetPolicies,
): void {
  state.tableAccessList[action.payload.groupId] = [
    ...action.payload.tableAccessList,
  ]
  state.defaultDimensionConfig[action.payload.groupId] = {
    ...action.payload.defaultDimensions,
  }
  state.policyHash[action.payload.groupId] = action.payload.policyHash
  state.columnMasking[action.payload.groupId] = [
    ...action.payload.columnMasking,
  ]
}

function updatePolicyHash(
  state: WritableDraft<State>,
  action: PayloadAction<{ groupId: GroupId; hash: string }>,
): void {
  const { groupId, hash } = action.payload
  state.policyHash[groupId] = hash
}

export type GroupListPaylaod = State['groups']
export type SetGroupListPaylaod = PayloadAction<GroupListPaylaod>

function setGroupList(
  state: WritableDraft<State>,
  action: SetGroupListPaylaod,
): void {
  state.groups = action.payload
}

export type SelectedGroupPayload = State['selectedGroup']
export type SetSelectedGroupPayload = PayloadAction<SelectedGroupPayload>

function setSelectedGroup(
  state: WritableDraft<State>,
  action: SetSelectedGroupPayload,
): void {
  state.selectedGroup = action.payload
}

const updateChangeState = (
  state: State,
  changeType: ChangeType,
  key: string,
  payload: any,
) => {
  state.changes[[state.selectedGroup, changeType, key].join('.')] = {
    groupId: state.selectedGroup,
    changeType,
    payload,
  }
}

const verifyTableOnGroup = (
  state: WritableDraft<State>,
  tableId: string,
  resourceType: PolicyTableResourceType,
) => {
  if (!state.tableAccessList[state.selectedGroup]) {
    state.tableAccessList[state.selectedGroup] = []
  }
  if (
    !state.tableAccessList[state.selectedGroup].find(
      (table) => table.id === tableId,
    )
  ) {
    state.tableAccessList[state.selectedGroup].push({
      id: tableId,
      allowed: false,
      dimensions: null,
      resourceType,
    })
  }
}

const setTableAccess = (
  state: WritableDraft<State>,
  action: SetTableAccess,
) => {
  verifyTableOnGroup(state, action.payload.id, action.payload.resourceType)

  state.tableAccessList[state.selectedGroup] = (
    state.tableAccessList[state.selectedGroup] || []
  ).map((table) =>
    table.id === action.payload.id
      ? { ...table, allowed: action.payload.allowed }
      : table,
  )

  updateChangeState(
    state,
    'table',
    action.payload.id,
    state.tableAccessList[state.selectedGroup].find(
      (t) => t.id === action.payload.id,
    ),
  )
}

const setTableAccessBulk = (
  state: WritableDraft<State>,
  action: SetTableAccessBulk,
) => {
  action.payload.tables.forEach(({ id, resourceType }) => {
    verifyTableOnGroup(state, id, resourceType)

    state.tableAccessList[state.selectedGroup] = (
      state.tableAccessList[state.selectedGroup] || []
    ).map((table) =>
      table.id === id ? { ...table, allowed: action.payload.allowed } : table,
    )

    updateChangeState(
      state,
      'table',
      id,
      state.tableAccessList[state.selectedGroup].find((t) => t.id === id),
    )
  })
}

const setDimensionAccess = (
  state: WritableDraft<State>,
  action: SetDimensionAccess,
) => {
  if (action.payload.table && action.payload.resourceType) {
    verifyTableOnGroup(state, action.payload.table, action.payload.resourceType)

    state.tableAccessList[state.selectedGroup] = state.tableAccessList[
      state.selectedGroup
    ].map((table) =>
      table.id === action.payload.table
        ? {
            ...table,
            dimensions: action.payload.dimensions,
          }
        : table,
    )

    updateChangeState(
      state,
      'table',
      action.payload.table,
      state.tableAccessList[state.selectedGroup].find(
        (t) => t.id === action.payload.table,
      ),
    )
  } else {
    state.defaultDimensionConfig = {
      ...state.defaultDimensionConfig,
      [state.selectedGroup]: action.payload.dimensions || {},
    }

    updateChangeState(
      state,
      'default_dimensions',
      '___default',
      state.defaultDimensionConfig[state.selectedGroup],
    )
  }
}

export type SetTableOverride = PayloadAction<{
  table: string
  override: boolean
  resourceType: PolicyTableResourceType
}>

const setTableOverride = (
  state: WritableDraft<State>,
  action: SetTableOverride,
) => {
  verifyTableOnGroup(state, action.payload.table, action.payload.resourceType)

  state.tableAccessList[state.selectedGroup] = state.tableAccessList[
    state.selectedGroup
  ].map((table) => {
    if (table.id !== action.payload.table) {
      return table
    }
    if (action.payload.override) {
      return {
        ...table,
        dimensions: state.defaultDimensionConfig[state.selectedGroup] || {},
      }
    } else {
      return { ...table, dimensions: null }
    }
  })

  updateChangeState(
    state,
    'table',
    action.payload.table,
    state.tableAccessList[state.selectedGroup].find(
      (t) => t.id === action.payload.table,
    ),
  )
}

export type SetColumnMaskPayload = PayloadAction<{
  id: ColumnId
  restrictions: DimensionMap
}>

const verifyColumnConfig = (
  state: WritableDraft<State>,
  columnId: ColumnId,
) => {
  if (!state.columnMasking[state.selectedGroup]) {
    state.columnMasking[state.selectedGroup] = []
  }
  if (
    !state.columnMasking[state.selectedGroup].find((c) => c.id === columnId)
  ) {
    state.columnMasking[state.selectedGroup].push({
      id: columnId,
      access: 'never',
    })
  }
}

function setColumnMask(
  state: WritableDraft<State>,
  action: SetColumnMaskPayload,
): void {
  verifyColumnConfig(state, action.payload.id)
  state.columnMasking[state.selectedGroup] = state.columnMasking[
    state.selectedGroup
  ].map((c) =>
    c.id === action.payload.id
      ? { ...c, dimensions: action.payload.restrictions }
      : c,
  )

  updateChangeState(
    state,
    'column_mask',
    action.payload.id,
    state.columnMasking[state.selectedGroup].find(
      (c) => c.id === action.payload.id,
    ),
  )
}

export type SetColumnAccessPayload = PayloadAction<{
  id: ColumnId
  access: ColumnAccessType
}>

function setColumnAccess(
  state: WritableDraft<State>,
  action: SetColumnAccessPayload,
): void {
  verifyColumnConfig(state, action.payload.id)

  state.columnMasking[state.selectedGroup] = state.columnMasking[
    state.selectedGroup
  ].map((c) =>
    c.id === action.payload.id
      ? {
          ...c,
          access: action.payload.access,
          dimensions:
            action.payload.access === 'never' ? undefined : c.dimensions,
        }
      : c,
  )

  updateChangeState(
    state,
    'column_mask',
    action.payload.id,
    state.columnMasking[state.selectedGroup].find(
      (c) => c.id === action.payload.id,
    ),
  )
}

function loadPermissionsData(state: WritableDraft<State>): void {
  state.loading = true
}

function loadPermissionGroupPolicyData(
  state: WritableDraft<State>,
  action: SetSelectedGroupPayload,
): void {
  state.loading = true
}

function loadPermissionsDone(state: WritableDraft<State>): void {
  state.loading = false
}

function saveChanges(state: WritableDraft<State>): void {
  state.saving = true
}

function saveChangesDone(state: WritableDraft<State>): void {
  state.saving = false
  state.changes = {}
}

// export reducers below in default export to avoid names overlapping with action creators
export default {
  setGroupPolicy,
  resetPolicies,
  setGroupList,
  setSelectedGroup,
  setTableAccess,
  setDimensionAccess,
  setTableOverride,
  setColumnMask,
  setColumnAccess,
  loadPermissionsData,
  loadPermissionsDone,
  saveChanges,
  saveChangesDone,
  updatePolicyHash,
  setTableAccessBulk,
  loadPermissionGroupPolicyData,
}
