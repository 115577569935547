import { Box, styled } from '@mui/material'

export const StyledRoot = styled(Box)`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
`

export const StyledList = styled(Box)`
  width: 100%;
  height: calc(100% - 48px);
`

export const StyledActionsBar = styled(Box)`
  width: 100%;
`
