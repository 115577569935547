import React from 'react'
import { createSvgIcon } from '@mui/material'

export const InputColumnIconIcon = createSvgIcon(
  <>
    <path d="M9.5 2.625C9.5 2.27982 9.77982 2 10.125 2C11.2853 2 12.3981 2.46094 13.2186 3.28141C13.473 3.53577 13.6928 3.81824 13.875 4.12151C14.0572 3.81824 14.277 3.53577 14.5314 3.28141C15.3519 2.46094 16.4647 2 17.625 2C17.9702 2 18.25 2.27982 18.25 2.625C18.25 2.97018 17.9702 3.25 17.625 3.25C16.7962 3.25 16.0013 3.57924 15.4153 4.16529C14.8292 4.75134 14.5 5.5462 14.5 6.375V13.25H16.375C16.7202 13.25 17 13.5298 17 13.875C17 14.2202 16.7202 14.5 16.375 14.5H14.5V17.625C14.5 18.4538 14.8292 19.2487 15.4153 19.8347C16.0013 20.4208 16.7962 20.75 17.625 20.75C17.9702 20.75 18.25 21.0298 18.25 21.375C18.25 21.7202 17.9702 22 17.625 22C16.4647 22 15.3519 21.5391 14.5314 20.7186C14.277 20.4642 14.0572 20.1818 13.875 19.8785C13.6928 20.1818 13.473 20.4642 13.2186 20.7186C12.3981 21.5391 11.2853 22 10.125 22C9.77982 22 9.5 21.7202 9.5 21.375C9.5 21.0298 9.77982 20.75 10.125 20.75C10.9538 20.75 11.7487 20.4208 12.3347 19.8347C12.9208 19.2487 13.25 18.4538 13.25 17.625V14.5H11.375C11.0298 14.5 10.75 14.2202 10.75 13.875C10.75 13.5298 11.0298 13.25 11.375 13.25H13.25V6.375C13.25 5.5462 12.9208 4.75134 12.3347 4.16529C11.7487 3.57924 10.9538 3.25 10.125 3.25C9.77982 3.25 9.5 2.97018 9.5 2.625Z" />
    <path d="M3.875 8.25C3.70924 8.25 3.55027 8.31585 3.43306 8.43306C3.31585 8.55027 3.25 8.70924 3.25 8.875C3.25 9.22018 2.97018 9.5 2.625 9.5C2.27982 9.5 2 9.22018 2 8.875C2 8.37772 2.19754 7.90081 2.54917 7.54917C2.90081 7.19754 3.37772 7 3.875 7C4.22018 7 4.5 7.27982 4.5 7.625C4.5 7.97018 4.22018 8.25 3.875 8.25Z" />
    <path d="M20.125 7C19.7798 7 19.5 7.27982 19.5 7.625C19.5 7.97018 19.7798 8.25 20.125 8.25C20.2908 8.25 20.4497 8.31585 20.5669 8.43306C20.6842 8.55027 20.75 8.70924 20.75 8.875C20.75 9.22018 21.0298 9.5 21.375 9.5C21.7202 9.5 22 9.22018 22 8.875C22 8.37772 21.8025 7.9008 21.4508 7.54917C21.0992 7.19754 20.6223 7 20.125 7Z" />
    <path d="M22 15.125C22 14.7798 21.7202 14.5 21.375 14.5C21.0298 14.5 20.75 14.7798 20.75 15.125C20.75 15.2908 20.6842 15.4497 20.5669 15.5669C20.4497 15.6842 20.2908 15.75 20.125 15.75C19.7798 15.75 19.5 16.0298 19.5 16.375C19.5 16.7202 19.7798 17 20.125 17C20.6223 17 21.0992 16.8025 21.4508 16.4508C21.8025 16.0992 22 15.6223 22 15.125Z" />
    <path d="M3.25 15.125C3.25 14.7798 2.97018 14.5 2.625 14.5C2.27982 14.5 2 14.7798 2 15.125C2 15.6223 2.19754 16.0992 2.54917 16.4508C2.90081 16.8025 3.37772 17 3.875 17C4.22018 17 4.5 16.7202 4.5 16.375C4.5 16.0298 4.22018 15.75 3.875 15.75C3.70924 15.75 3.55027 15.6842 3.43306 15.5669C3.31585 15.4497 3.25 15.2908 3.25 15.125Z" />
    <path d="M2.625 10.75C2.97018 10.75 3.25 11.0298 3.25 11.375V12.625C3.25 12.9702 2.97018 13.25 2.625 13.25C2.27982 13.25 2 12.9702 2 12.625V11.375C2 11.0298 2.27982 10.75 2.625 10.75Z" />
    <path d="M22 11.375C22 11.0298 21.7202 10.75 21.375 10.75C21.0298 10.75 20.75 11.0298 20.75 11.375V12.625C20.75 12.9702 21.0298 13.25 21.375 13.25C21.7202 13.25 22 12.9702 22 12.625V11.375Z" />
    <path d="M5.75 7.625C5.75 7.27982 6.02982 7 6.375 7H7.625C7.97018 7 8.25 7.27982 8.25 7.625C8.25 7.97018 7.97018 8.25 7.625 8.25H6.375C6.02982 8.25 5.75 7.97018 5.75 7.625Z" />
    <path d="M10.125 7C9.77982 7 9.5 7.27982 9.5 7.625C9.5 7.97018 9.77982 8.25 10.125 8.25H11.375C11.7202 8.25 12 7.97018 12 7.625C12 7.27982 11.7202 7 11.375 7H10.125Z" />
    <path d="M15.75 7.625C15.75 7.27982 16.0298 7 16.375 7H17.625C17.9702 7 18.25 7.27982 18.25 7.625C18.25 7.97018 17.9702 8.25 17.625 8.25H16.375C16.0298 8.25 15.75 7.97018 15.75 7.625Z" />
    <path d="M6.375 15.75C6.02982 15.75 5.75 16.0298 5.75 16.375C5.75 16.7202 6.02982 17 6.375 17H7.625C7.97018 17 8.25 16.7202 8.25 16.375C8.25 16.0298 7.97018 15.75 7.625 15.75H6.375Z" />
    <path d="M9.5 16.375C9.5 16.0298 9.77982 15.75 10.125 15.75H11.375C11.7202 15.75 12 16.0298 12 16.375C12 16.7202 11.7202 17 11.375 17H10.125C9.77982 17 9.5 16.7202 9.5 16.375Z" />
    <path d="M16.375 15.75C16.0298 15.75 15.75 16.0298 15.75 16.375C15.75 16.7202 16.0298 17 16.375 17H17.625C17.9702 17 18.25 16.7202 18.25 16.375C18.25 16.0298 17.9702 15.75 17.625 15.75H16.375Z" />
  </>,
  'InputColumnIcon',
)
