import { texts } from '@fintastic/web/data-access/versions'
import { UnsupportedGranularityAggregationError } from '@fintastic/web/util/period-selector'

export type MetricGridError =
  | VersionsAreNotSelectedError
  | MetricDoesNotExistError
  | UnsupportedGranularityAggregationError
  | RestrictedByPermissionsError
  | NetworkError
  | UnknownError

export class VersionsAreNotSelectedError extends Error {
  public ERROR_TEXT = texts.noVersionsSharedWithUser

  constructor() {
    super()
    Object.defineProperty(this, 'name', {
      value: 'VersionsAreNotSelectedError',
    })
  }
}

export class MetricDoesNotExistError extends Error {
  public ERROR_TEXT = 'The Metric does not exist in the selected version'

  constructor() {
    super()
    Object.defineProperty(this, 'name', {
      value: 'MetricDoesNotExistError',
    })
  }
}

export class RestrictedByPermissionsError extends Error {
  public ERROR_TEXT =
    'Your permissions settings do not allow you to view the data in this Metric'

  constructor() {
    super()
    Object.defineProperty(this, 'name', {
      value: 'RestrictedByPermissionsError',
    })
  }
}

export class NetworkError extends Error {
  public ERROR_TEXT = 'Something went wrong during the Metric loading'

  constructor() {
    super()
    Object.defineProperty(this, 'name', {
      value: 'NetworkError',
    })
  }
}

export class UnknownError extends Error {
  public originalError: Error
  public ERROR_TEXT = 'Something went wrong during the Metric processing'

  constructor(originalError: Error) {
    super()
    this.originalError = originalError
    Object.defineProperty(this, 'name', {
      value: 'UnknownError',
    })
  }
}
