import { useCallback, useMemo, useState } from 'react'

export function useFormulasState() {
  const [formulasOpened, setFormulasOpened] = useState(false)

  const openFormulas = useCallback(() => {
    setFormulasOpened(true)
  }, [])

  const closeFormulas = useCallback(() => {
    setFormulasOpened(false)
  }, [])

  const toggleFormulas = useCallback(() => {
    setFormulasOpened((current) => !current)
  }, [])

  return useMemo(() => ({
    formulasOpened,
    openFormulas,
    closeFormulas,
    toggleFormulas
  }), [closeFormulas, formulasOpened, openFormulas, toggleFormulas])
}
