import React, { useMemo } from 'react'
import {
  PanelToolbarIconButton,
  PanelToolbarIconButtonProps,
} from '@fintastic/shared/ui/panel-framework'
import { SvgIconProps } from '@mui/material'
import { AddIcon } from '@fintastic/shared/ui/icons'

const defaultIconProps: SvgIconProps = {
  fontSize: 'small',
}

export type AddRowButtonProps = Omit<
  PanelToolbarIconButtonProps,
  'children'
> & {
  iconProps?: SvgIconProps
}

export const AddRowButton = ({
  title = 'Add',
  iconProps = defaultIconProps,
  ...rest
}: AddRowButtonProps): JSX.Element | null => {
  const mergedIconProps = useMemo(
    () => ({
      ...defaultIconProps,
      ...iconProps,
    }),
    [iconProps],
  )

  return (
    <PanelToolbarIconButton data-testid="AddRowsButton" title={title} {...rest}>
      <AddIcon {...mergedIconProps} />
    </PanelToolbarIconButton>
  )
}
