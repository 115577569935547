import React from 'react'
import { StyledRoot } from './PanelLoadingOverlay.styled'
import { CircularProgress, Typography } from '@mui/material'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'

export type PanelLoadingOverlayProps = {
  text: string
}

export const PanelLoadingOverlay: React.FC<PanelLoadingOverlayProps> = ({
  text,
}) => (
  <FintasticThemeProvider applyLegacyTheme={false}>
    <StyledRoot>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={sxMixins.alignContentCenter()}
      >
        <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
        <b>{text}</b>
      </Typography>
    </StyledRoot>
  </FintasticThemeProvider>
)
