import React from 'react'
import { IToolPanelParams } from 'ag-grid-community'
import { useBlanksFilterPanelContext } from '../context'
import { Box } from '@mui/material'
import { ShowBlanksSwitchButton } from '@fintastic/shared/ui/grid-framework'

export type BlanksFilterToolPanelProps = IToolPanelParams

export const BlanksFilterToolPanel: React.FC<
  BlanksFilterToolPanelProps
> = () => {
  const { blanksVisible, toggleBlanks } = useBlanksFilterPanelContext()

  return (
    <Box p={2}>
      <ShowBlanksSwitchButton
        label="Show blanks and zeros"
        tooltipTitle="Enable to display lines with blank and zero values in the Report"
        isLoading={false}
        onChange={() => toggleBlanks(!blanksVisible)}
        checked={blanksVisible}
        disabled={false}
      />
    </Box>
  )
}
