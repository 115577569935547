import React from 'react'
import { StyledRoot } from './ActionButtonsContainer.styled'

export type ActionButtonsContainerProps = {
  children: React.ReactNode
}

export const ActionButtonsContainer: React.FC<ActionButtonsContainerProps> = ({
  children,
}) => <StyledRoot>{children}</StyledRoot>
