import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { debounce } from 'lodash'
import type { FocusedToken } from '../../focused-token/types'
import { usePopperTargetNode } from './usePopperTargetNode'
import { CaretMovementReason } from '../../caret/types'
import { SyntaxLiteral } from '../../syntax/types'
import { useSuggestions } from './suggestions/useSuggestions'
import { MIN_QUERY_LENGTH } from './suggestions/const'
import { useNavigation } from './navigation/useNavigation'
import { useKeyboard } from './keyboard/useKeyboard'
import { FormulaMutationsApi } from '../../formula-mutations/types'
import { applySuggestionToFormula } from './formula-mutations/applySuggestionToFormula'

export function useAutocomplete({
  enabled,
  formula,
  focusedToken,
  getTokensNodes,
  caretMovementReason,
  syntaxLiterals,
  formulaMutationsApi,
}: {
  enabled: boolean
  formula: string
  focusedToken: Maybe<FocusedToken>
  getTokensNodes: Maybe<() => HTMLElement[]>
  caretMovementReason: Maybe<CaretMovementReason>
  syntaxLiterals: SyntaxLiteral[]
  formulaMutationsApi: FormulaMutationsApi
}) {
  const [isShowAllowed, setIsShowAllowed] = useState(false)
  const close = useCallback(() => setIsShowAllowed(false), [])

  const popperTarget = usePopperTargetNode(focusedToken, getTokensNodes)
  const setIsShowAllowedDebounced = useMemo(
    () => debounce(setIsShowAllowed, 100),
    [],
  )
  const prevFocusedTokenRef = useRef(focusedToken)

  const suggestions = useSuggestions(
    syntaxLiterals,
    focusedToken?.textBeforeCaret || '',
  )

  const applySuggestion = useCallback(
    (literal: SyntaxLiteral) => {
      if (!focusedToken) {
        return
      }
      applySuggestionToFormula(
        formula,
        literal,
        focusedToken.textBeforeCaret,
        focusedToken.token.start,
        formulaMutationsApi,
      )
      setIsShowAllowedDebounced.cancel()
    },
    [setIsShowAllowedDebounced, focusedToken, formula, formulaMutationsApi],
  )

  const isShow = Boolean(
    enabled &&
      focusedToken &&
      focusedToken.textBeforeCaret.length >= MIN_QUERY_LENGTH &&
      isShowAllowed &&
      suggestions.length > 0 &&
      (suggestions.length === 1
        ? suggestions[0].literal !== focusedToken.textBeforeCaret
        : true) &&
      popperTarget,
  )

  const navigation = useNavigation(suggestions, applySuggestion, close)
  const { selectFirst: navigationSelectFirst } = navigation
  const keyboardInterceptors = useKeyboard(isShow, navigation)

  const hasFocusedToken = Boolean(focusedToken)
  useEffect(() => {
    if (!enabled || !hasFocusedToken) {
      setIsShowAllowedDebounced.cancel()
      setIsShowAllowed(false)
      return
    }

    const tokenIndexChanged =
      prevFocusedTokenRef.current?.token.start !== focusedToken?.token.start

    if (caretMovementReason === 'editing') {
      if (tokenIndexChanged) {
        setIsShowAllowedDebounced.cancel()
        setIsShowAllowed(false)
      } else {
        setIsShowAllowedDebounced(true)
        if (navigation.selectedOptionId === null) {
          navigationSelectFirst()
        }
      }
    } else if (caretMovementReason === 'navigation' && tokenIndexChanged) {
      setIsShowAllowedDebounced.cancel()
      setIsShowAllowed(false)
    }
  }, [
    caretMovementReason,
    enabled,
    focusedToken?.token.start,
    hasFocusedToken,
    navigation.selectedOptionId,
    navigationSelectFirst,
    setIsShowAllowedDebounced,
  ])

  useEffect(() => {
    navigationSelectFirst()
  }, [navigationSelectFirst, suggestions])

  useEffect(() => {
    prevFocusedTokenRef.current = focusedToken
  }, [focusedToken])

  // clean up debounce
  useEffect(
    () => () => {
      setIsShowAllowedDebounced.cancel()
    },
    [setIsShowAllowedDebounced],
  )

  return useMemo(
    () =>
      ({
        editingToken: focusedToken,
        isShow,
        popperTarget,
        suggestions,
        navigation,
        keyboardInterceptors,
      } as const),
    [
      focusedToken,
      isShow,
      popperTarget,
      suggestions,
      navigation,
      keyboardInterceptors,
    ],
  )
}
