import { styled, Box } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledWidgetDependenciesWarningRoot = styled(Box)`
  ${styledMixins.alignContentCenter('column')};

  position: relative;

  padding: ${({ theme }) => theme.spacing(2)};

  height: 100%;
  width: 100%;

  text-align: center;
`

export const StyledWidgetDependenciesWarningIcon = styled(Box)(({ theme }) => ({
  fontSize: 0,
}))

export const StyledWidgetDependenciesWarningText = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`
