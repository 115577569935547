import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { MetricEntityDefinition } from '../../types'

export class MetricEntityDefinitionWrapper
  implements ImmutableADTWrapper<MetricEntityDefinition>
{
  readonly _rawData: MetricEntityDefinition
  constructor(data: MetricEntityDefinition) {
    this._rawData = data
  }

  unwrap(): MetricEntityDefinition {
    return this._rawData
  }

  public type = 'metric' as const

  get id(): string {
    return this._rawData.id
  }

  get label(): string {
    return this._rawData.label
  }

  get isCalculated(): boolean {
    return this._rawData.source === 'calculated'
  }
}
