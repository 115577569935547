import { useMutation, useQueryClient } from 'react-query'
import { reorderDimensions } from '../api/reorder-dimensions-api'
import { invalidateDimensionsRelated } from '../utils/invalidate-dimensions-utils'

type Payload = {
  dimensionId: string
  items: string[]
}

// this is not independent update, so no automatic invalidation
export const useSaveDimensionOrderChanges = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ dimensionId, items }: Payload) => {
      const response = await reorderDimensions(dimensionId, items)

      if (response instanceof Error) {
        return {
          error: response,
        }
      }
      return { error: null }
    },
    {
      // can happen only if body of mutation throws a runtime error
      onError: () => invalidateDimensionsRelated(queryClient),
    },
  )
}
