import React from 'react'
import { ListItem, ListItemButton, ListItemText } from '@mui/material'

export const LabelsDropdownNoItems: React.FC = () => (
  <ListItem disablePadding>
    <ListItemButton disabled dense>
      <ListItemText primary={'No labels created yet'} />
    </ListItemButton>
  </ListItem>
)
