import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { Board } from '../../../types'
import { BoardArchiveForm } from '../../forms/BoardArchiveForm'
import { Maybe } from '@fintastic/shared/util/types'

export type BoardArchiveModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  boardId?: Maybe<Board['id']>
  boardName?: Maybe<Board['name']>
}

export const BoardArchiveModal: React.FC<BoardArchiveModalProps> = (props) => {
  const { isOpen, onRequestClose, boardId, boardName } = props

  return (
    <Modal
      open={isOpen}
      onRequestClose={onRequestClose}
      title="Archive Board"
      data-testid="board-archive-modal-root"
    >
      {boardId && (
        <BoardArchiveForm
          closeParentModal={onRequestClose}
          boardId={boardId}
          boardName={boardName || ''}
        />
      )}
    </Modal>
  )
}
