import { usePeriodSelectorOrderedPeriodList } from '@fintastic/web/util/period-selector'
import { isEqual, sortBy, uniq } from 'lodash'
import { GenericReportNormalised } from '@fintastic/web/util/generic-report'
import { useMemo } from 'react'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'

/**
 * Build list of columns for the report based on report data
 * As report does use dimension value ids yet, the list of columns renders
 * labels only, like 'Jan 2023', 'Q1 2022' etc
 * This list may include special columns provided by API, like 'Total 2022'
 *
 * As the source for ordering we use calendar (based on dimension value label)
 * And reports `ordered_time_labels` field.
 * This function merges `ordered_time_labels` from various reports and sorts them
 * according to calendar if possible.
 */
export const useReportOrderedPeriodList = ({
  reportsData,
}: {
  reportsData: GenericReportNormalised[]
}): string[] => {
  const orderedPeriodList = usePeriodSelectorOrderedPeriodList()

  const allPeriodsOriginal = useMemo<string[]>(() => {
    const allPeriods = uniq(
      reportsData.flatMap((i) => i.result.map((row) => row._timestamp)),
    )

    const longestOrderedTimeLabelsReport = sortBy(
      reportsData,
      (i) => (i.ordered_time_labels?.length ?? 0) * -1,
    )?.[0]

    const hasConflicts =
      longestOrderedTimeLabelsReport?.ordered_time_labels?.length &&
      reportsData.find((r) => {
        if (
          r.reportOrVersionId ===
          longestOrderedTimeLabelsReport.reportOrVersionId
        ) {
          return false
        }

        return (r.ordered_time_labels ?? []).find(
          (p) =>
            longestOrderedTimeLabelsReport.ordered_time_labels?.includes(p) ===
            false,
        )
      })

    // Base case - use longest ordered_time_labels directly from the report
    if (
      !hasConflicts &&
      longestOrderedTimeLabelsReport?.ordered_time_labels?.length &&
      allPeriods.length ===
        longestOrderedTimeLabelsReport?.ordered_time_labels?.length
    ) {
      return longestOrderedTimeLabelsReport.ordered_time_labels
    }

    // If longestOrderedTimeLabelsReport does not cover all possible periods, use raw unsorted periods as the fallback
    const defaultFallbackValue =
      hasConflicts ||
      allPeriods.length >
        (longestOrderedTimeLabelsReport.ordered_time_labels?.length ?? 0)
        ? allPeriods
        : longestOrderedTimeLabelsReport.ordered_time_labels ?? allPeriods

    // If calendar does not have ordered periods, fallback to default
    if (!orderedPeriodList?.length) {
      console.error('no orderedPeriodList found for report')
      if (hasConflicts) {
        console.error(
          'ordered_time_labels has conflicts and no time dimension found',
        )
      }

      return allPeriods.length > defaultFallbackValue.length
        ? allPeriods
        : defaultFallbackValue
    }

    if (reportsData.length === 1) {
      return defaultFallbackValue
    }

    const ids =
      orderedPeriodList.filter((o) => allPeriods.includes(o.label)) ?? []

    const allPeriodsSortedByTimeDim = ids
      .map((id) => id.label)
      .filter((i) => allPeriods.includes(i))

    const otherOrderedValues = (
      longestOrderedTimeLabelsReport.ordered_time_labels ?? []
    ).filter((i) => !allPeriodsSortedByTimeDim.includes(i))

    // Assume that we have all ordered period list and other periods in the longest report
    const mergedOrderedValues = [
      ...allPeriodsSortedByTimeDim,
      ...otherOrderedValues,
    ]

    if (defaultFallbackValue.length > mergedOrderedValues.length) {
      console.error('failed to build ordered period list!')
      return defaultFallbackValue
    }

    return mergedOrderedValues
  }, [orderedPeriodList, reportsData])

  return useReferenceMemo<string[]>(allPeriodsOriginal, isEqual)
}
