import {
  PeriodSelection,
  PeriodsBasedSelection,
  RangeBasedSelection,
} from '../types'
import { NullableFields } from '@fintastic/shared/util/types'

export const isRangeBasedSelection = (
  selection: PeriodSelection | NullableFields<PeriodSelection>,
): selection is RangeBasedSelection =>
  'range' in selection && Array.isArray(selection['range'])

export const isPeriodBasedSelection = (
  selection: PeriodSelection | NullableFields<PeriodSelection>,
): selection is PeriodsBasedSelection =>
  'periods' in selection && Array.isArray(selection['periods'])
