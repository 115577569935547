import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { ColorBucketIcon } from '@fintastic/shared/ui/icons'

export const ColorSelectorButton: React.FC<IconButtonProps> = React.forwardRef(
  (props, ref) => (
    <IconButton
      size="small"
      disableTouchRipple
      disableRipple
      data-testid="color-selector-button"
      {...props}
      ref={ref}
    >
      <ColorBucketIcon htmlColor="#263646" fontSize="small" />
    </IconButton>
  ),
)
