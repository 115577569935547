import {
  MetricFormulaEditorWrapper,
  VersionMetric,
} from '@fintastic/web/feature/metrics-and-lists'
import React from 'react'
import { StyledMetricChartFormula } from './MetricChartFormula.styled'

export type MetricChartFormulaProps = {
  isCollapsedVert: boolean
  hasFormulas: boolean
  formulasOpened: boolean
  versions: VersionMetric[]
  onRequestClose: () => void
}

export const MetricChartFormula: React.FC<MetricChartFormulaProps> = ({
  versions,
  onRequestClose,
  isCollapsedVert,
  hasFormulas,
  formulasOpened,
}) => {
  if (!formulasOpened || !hasFormulas || isCollapsedVert) {
    return null
  }
  return (
    <StyledMetricChartFormula>
      <MetricFormulaEditorWrapper
        versions={versions}
        onRequestClose={onRequestClose}
        showVersionsSelector={true}
        controlledFormulaError={null}
        isSettingsEditingActive={false}
      />
    </StyledMetricChartFormula>
  )
}
