import { styled, Box, Button, Typography } from '@mui/material'

export const StyledUserPanelRoot = styled(Box)(({ theme }) => ({}))

export const StyledUserPanelPopoverContent = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  fontSize: '12px',
  padding: '15px',
}))

export const StyledUserPanelEmail = styled(Typography)(({ theme }) => ({
  fontSize: 12,
}))

export const StyledUserPanelTenant = styled(Typography)(({ theme }) => ({}))

export const StyledUserPanelLogoutCont = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacingFromPixels(15),
  marginTop: theme.spacingFromPixels(10),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const StyledUserPanelLogout = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '0',
  fontWeight: 'normal',
  padding: '15px 30px',
}))
