import { QueryClient } from 'react-query'
import { reportQueryKeyBase } from '@fintastic/web/util/generic-report'

export const invalidateReportsCache = (
  queryClient: QueryClient,
  versionIds: string[],
) =>
  Promise.all(
    versionIds.map((versionId) =>
      queryClient.invalidateQueries({
        queryKey: reportQueryKeyBase(versionId),
      }),
    ),
  )
