import { useCallback } from 'react'
import { ChangeValueDataTypeAction } from '../types'
import {
  MetricOrListEditingFlow,
  useMetricsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import {
  useModalWithParamsState,
  useModalState,
} from '@fintastic/shared/util/modal'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Maybe } from '@fintastic/shared/util/types'
import {
  isDataTypeConversionNecessary,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { DataContextValue } from '../../data/data-context'

export function useChangeValueDataTypeFlow(
  { metric, dependencyGraph, dimensionsList }: DataContextValue,
  flow: Maybe<MetricOrListEditingFlow>,
) {
  const { changeValueDataType } = useMetricsActions()

  const confirmationPopupState = useModalWithParamsState<{
    dataType: Maybe<MetricConfigurableDataValueType>
    currency: Maybe<string>
  }>({
    initialParams: {
      dataType: null,
      currency: null,
    },
  })

  const dependenciesWarningPopupState = useModalState()

  const handleAction = useCallback<ChangeValueDataTypeAction>(
    (dataType, currency) => {
      if (!metric || flow === null || dimensionsList === null) {
        return
      }

      if (!isDataTypeConversionNecessary(metric.dataType(), dataType)) {
        changeValueDataType({
          dataType,
          currency,
          allDimensions: dimensionsList,
        })
        return
      }

      if (
        flow !== 'creation' &&
        dependencyGraph &&
        dependencyGraph.hasAtLeastOneSuccessor(metric.id())
      ) {
        dependenciesWarningPopupState.open()
        return
      }

      if (flow === 'creation') {
        changeValueDataType({
          dataType,
          currency,
          allDimensions: dimensionsList,
        })
        return
      }

      confirmationPopupState.openWithParams({
        dataType,
        currency,
      })
    },
    [
      changeValueDataType,
      confirmationPopupState,
      dependenciesWarningPopupState,
      dependencyGraph,
      dimensionsList,
      flow,
      metric,
    ],
  )

  const handleConfirm = useCallback(() => {
    const { dataType, currency } = confirmationPopupState.params

    if (dataType !== null && currency !== null && dimensionsList !== null) {
      changeValueDataType({
        dataType,
        currency,
        allDimensions: dimensionsList,
      })
    }
  }, [changeValueDataType, confirmationPopupState.params, dimensionsList])

  const popups = (
    <>
      <ConfirmationPopup
        title="Change Metric’s data type?"
        description="Metric’s data will be deleted."
        open={confirmationPopupState.isOpen}
        onRequestClose={confirmationPopupState.close}
        onConfirm={handleConfirm}
      >
        <ConfirmationPopup.DeclineButton
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
        >
          Keep existing
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton>
          Change data type
        </ConfirmationPopup.ConfirmButton>
      </ConfirmationPopup>
      <ConfirmationPopup
        title="Data type change is not possible due to dependencies."
        description="Please remove this Metric from related formula and try again."
        open={dependenciesWarningPopupState.isOpen}
        onRequestClose={dependenciesWarningPopupState.close}
      >
        <ConfirmationPopup.DeclineButton>Close</ConfirmationPopup.DeclineButton>
      </ConfirmationPopup>
    </>
  )

  return {
    action: handleAction,
    popups,
  }
}
