import { isFinite } from 'lodash'
import { DeeplinkValueElement } from '../types'
import { JOIN_SYMBOL } from './const'

const isNumberRegexp = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/

export const deeplinkValueElementParser = (
  input: string,
): DeeplinkValueElement => {
  if (input === 'null') {
    return null
  }

  if (input === 'true') {
    return true
  }

  if (input === 'false') {
    return false
  }

  const potentialNumber = isNumberRegexp.test(input) ? parseFloat(input) : false

  if (typeof potentialNumber === 'number' && isFinite(potentialNumber)) {
    return potentialNumber
  }

  return decodeURIComponent(input)
}

export const parseSimpleArray = (input: string): DeeplinkValueElement[] =>
  input
    .split(JOIN_SYMBOL)
    .filter((i) => i !== '')
    .map(deeplinkValueElementParser)

export const containsSimpleArray = (input: string): boolean =>
  input.startsWith(JOIN_SYMBOL) && input.length > 1
