import {
  EditListColumnsDataIntent,
  EditListColumnsDataCompressedIntent,
  ListColumnChange,
  ListColumnCompressedChange,
} from '../../types'
import { Maybe } from '@fintastic/shared/util/types'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { createEditListColumnsDataIntent } from './consturctors'
import { isEqual } from 'lodash'

/**
 * Extracts common dimensions from columns to top level to avoid redundant duplication.
 */
export const tryCompress = (
  intent: EditListColumnsDataIntent,
): Maybe<EditListColumnsDataCompressedIntent> => {
  if (intent.columns.length < 2) {
    return null // doesn't make sense to compress such cases
  }

  const commonDimensions = getCommonDimensions(
    intent.columns[0],
    intent.columns[1],
  )
  if (commonDimensions === null) {
    return null
  }

  const compressedIntent = createEditListColumnsDataIntent(
    intent.fill_strategy,
  ) as EditListColumnsDataCompressedIntent
  compressedIntent.indexes = [...commonDimensions]
  compressedIntent.dimensions = commonDimensions.map(
    (dimId) =>
      intent.columns[0].dimensions[intent.columns[0].indexes.indexOf(dimId)],
  )

  for (let i = 0; i < intent.columns.length; i++) {
    const column = intent.columns[i]
    const columnCommonDimIndexes: number[] = []

    for (
      let commonDimIndex = 0;
      commonDimIndex < commonDimensions.length;
      commonDimIndex++
    ) {
      const commonDim = commonDimensions[commonDimIndex]
      const dimIndexInColumn = column.indexes.indexOf(commonDim)
      if (dimIndexInColumn === -1) {
        return null
      }

      const dimValuesInColumn = column.dimensions[dimIndexInColumn]
      if (
        !isEqual(compressedIntent.dimensions[commonDimIndex], dimValuesInColumn)
      ) {
        return null
      }

      columnCommonDimIndexes.push(dimIndexInColumn)
    }

    // we suppose that columns in the intent doesn't repeat; they're unique by the column name
    compressedIntent.columns.push(
      createCompressedColumn(column, columnCommonDimIndexes),
    )
  }

  return compressedIntent
}

const getCommonDimensions = (
  firstColumn: ListColumnChange,
  secondColumn: ListColumnChange,
): Maybe<DimensionId[]> => {
  const intersection = firstColumn.indexes.filter((dimId) =>
    secondColumn.indexes.includes(dimId),
  )
  return intersection.length > 0 ? intersection : null
}

const createCompressedColumn = (
  column: ListColumnChange,
  columnCommonDimIndexes: number[],
): ListColumnCompressedChange => {
  const compressedColumn: ListColumnCompressedChange = {
    column_name: column.column_name,
    values: column.values,
  }

  const newIndexes = column.indexes.filter(
    (_, i) => !columnCommonDimIndexes.includes(i),
  )
  if (newIndexes.length > 0) {
    compressedColumn.indexes = newIndexes
  }

  const newDimensions = column.dimensions.filter(
    (_, i) => !columnCommonDimIndexes.includes(i),
  )
  if (newDimensions.length) {
    compressedColumn.dimensions = newDimensions
  }

  return compressedColumn
}
