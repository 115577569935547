import { Maybe } from '@fintastic/shared/util/types'
import { useQuery } from 'react-query'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { getListColumns } from '../api/lists-api'
import { FilterListAPIPayload } from '@fintastic/web/util/filters'

type Params =
  | {
      enabled: false
    }
  | {
      enabled: true
      versionId: string
      listId: string
      columnIds: string[]
      periodSelection: Maybe<PeriodSelection>
      page: number
      itemsPerPage: number
      filters: FilterListAPIPayload
    }

export const useLoadPaginatedListColumns = (params: Params) =>
  useQuery(
    params.enabled
      ? metricsAndListsCacheKeys.paginatedListColumns(
          params.versionId,
          params.listId,
          params.columnIds,
          params.periodSelection,
          params.page,
          params.itemsPerPage,
          params.filters,
        )
      : [],
    async () => {
      if (!params.enabled) {
        throw new Error('query is disabled')
      }

      const response = await getListColumns(
        params.versionId,
        params.listId,
        params.columnIds,
        params.periodSelection,
        true,
        undefined,
        params.filters,
        {
          limit: params.itemsPerPage,
          offset: (params.page - 1) * params.itemsPerPage,
        },
      )

      return response.data
    },
    {
      enabled: params.enabled,
      cacheTime: 1000 * 60 * 3,
      keepPreviousData: true,
    },
  )
