import { styled, Box, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledUserPanelRoot = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'flexStart',
}))

export const StyledUserPanelAvatarCont = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

export const StyledUserPanelText = styled(Box)(({ theme }) => ({}))

export const StyledUserPanelName = styled(Typography)(({ theme }) => ({}))

export const StyledUserPanelEmail = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
}))
