import { Box, Collapse, Typography, useTheme } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { FilterCollapseStatus } from './FilterCollapseStatus'
import { FiltersIcon } from '@fintastic/shared/ui/icons'

export const FilterGroup: React.FC<
  PropsWithChildren<{
    collapsed: boolean
    title: string
    onToggleCollapse: (collapsed: boolean) => void
    hasValue: boolean
  }>
> = ({ collapsed, title, children, hasValue, onToggleCollapse }) => {
  const theme = useTheme()
  return (
    <Box py={1.5} px={1}>
      <Box
        display="flex"
        gap={1}
        onClick={() => onToggleCollapse(!collapsed)}
        sx={{ cursor: 'pointer' }}
        alignItems="center"
      >
        <FilterCollapseStatus collapsed={collapsed} />
        <Typography variant="body1" lineHeight="24px" fontWeight={500} display="flex" alignItems="center">
          {title}
          {hasValue ? (
            <FiltersIcon
              fontSize="small"
              style={{color: theme.palette.text.secondary, marginLeft: '4px'}}
            />
          ) : (
            <span />
          )}
        </Typography>
      </Box>
      <Collapse in={collapsed} mountOnEnter>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  )
}

FilterGroup.displayName = 'FilterGroup'
