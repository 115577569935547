import React from 'react'
import {
  StyledPanelToolbarTitleIcon,
  StyledPanelTopbarTitleRoot,
} from './PanelTopbarTitle.styled'

export type PanelTopbarTitleProps = {
  children: React.ReactNode
  icon?: React.ReactNode
}

export const PanelTopbarTitle: React.FC<PanelTopbarTitleProps> = ({
  children,
  icon,
}) => (
  <StyledPanelTopbarTitleRoot variant="h4">
    {icon && <StyledPanelToolbarTitleIcon>{icon}</StyledPanelToolbarTitleIcon>}
    {children}
  </StyledPanelTopbarTitleRoot>
)
