import {
  Metric,
  MetricOrListSource,
} from '@fintastic/web/util/metrics-and-lists'
import { createMetricData } from '../metric-data'
import { createDisplaySettings } from '../display-settings'

export const createEmptyMetricObject = (
  name: string,
  source: MetricOrListSource,
): Metric => ({
  id: `Cube.${name}`,
  label: name,
  source,
  data: createMetricData(),
  metadata: {
    dimensions: [],
    value: {
      type: 'number',
      roll_up_function: 'sum',
      category_agg: 'sum',
      dimension_id: null,
      weights_metric_id: null,
      mask: '',
    },
    formula: source === 'input' ? null : '',
    description: '',
    display_config: createDisplaySettings(),
    time_dimension_id: null,
    base_time_dimension_id: null,
  },
})
