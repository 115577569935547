import React from 'react'
import { ColorSelection } from './types'
import { styled } from '@mui/material'

export const ColorSelectorMenuItem: React.FC<ColorSelection> = ({
  bg,
  label,
}) => (
  <>
    <ColorSelectorMenuItemCircleStyled hex={bg} /> {label}
  </>
)

const ColorSelectorMenuItemCircleStyled = styled('div')<{
  hex: string
}>(
  ({ hex }) => `
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    background: ${hex};
`,
)

ColorSelectorMenuItemCircleStyled.displayName =
  'ColorSelectorMenuItemCircleStyled'
