import type {
  AgFintasticThemeVariant,
  AgFintasticThemeVariantConfig,
} from '../types'
import { agFintasicThemeVariantDefault } from './default'
import { agFintasicThemeVariantCompact } from './compact'

export const agFintasticThemeVariants: Record<
  AgFintasticThemeVariant,
  AgFintasticThemeVariantConfig
> = {
  default: agFintasicThemeVariantDefault,
  compact: agFintasicThemeVariantCompact,
}
