import { FactoryOpts } from 'imask'

export const agGridNumericEditorDefaultNumberMask: FactoryOpts = {
  mask: 'num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      radix: '.',
      thousandsSeparator: ',',
      scale: 4,
    },
  },
}

export const agGridNumericEditorDefaultCurrencyMask: FactoryOpts = {
  mask: '$num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
      radix: '.',
      scale: 4,
    },
  },
}

export const agGridNumericEditorDefaultPercentMask: FactoryOpts = {
  mask: 'num%',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      radix: '.',
      scale: 4,
    },
  },
}
