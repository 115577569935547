import { Box, IconButton, IconButtonProps, styled } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'

export const FloatingPanelButton: React.FC<IconButtonProps> = (props) => (
  <Box>
    <FloatingPanelButtonStyled {...props}>
      <MenuIcon fontSize="small" />
    </FloatingPanelButtonStyled>
    <Box width="32px" />
  </Box>
)

const FloatingPanelButtonStyled = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.1s ease-in;
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -16px;
  background: white;

  .ag-header-cell:hover &, .ag-header-cell.ag-header-active & {
    opacity: 1;
  }
`
