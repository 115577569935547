import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { Maybe } from '@fintastic/shared/util/types'
import {
  DimensionId,
  VersionCategoryDimension,
} from '@fintastic/web/util/dimensions'
import { StyledAutocomplete } from './DimensionInput.styled'

export type DimensionInputProps = {
  dimensionsList: VersionCategoryDimension[]
  value: Maybe<string>
  onChange: (newValue: DimensionId) => void
  readonly: boolean
}

const sortByLabel = (
  a: VersionCategoryDimension,
  b: VersionCategoryDimension,
): number => {
  const labelA = (a?.label || '').toUpperCase()
  const labelB = (b?.label || '').toUpperCase()
  if (labelA < labelB) {
    return -1
  }
  if (labelA > labelB) {
    return 1
  }

  return 0
}

const initialDimension = {
  id: '',
  label: 'none',
}

export const DimensionInput: React.FC<DimensionInputProps> = ({
  value,
  readonly,
  dimensionsList,
  onChange,
}) => {
  const sortedDimensions = useMemo<VersionCategoryDimension[]>(
    () =>
      dimensionsList.filter((d) => d && d.id && d.label).sort(sortByLabel),
    [dimensionsList],
  )

  const val = useMemo(() => {
    const dimension = dimensionsList.find((d) => d.id === value)
    return dimension ?? initialDimension
  }, [value, dimensionsList])

  const handleChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      value:
        | Partial<VersionCategoryDimension>
        | Partial<VersionCategoryDimension>[]
        | null,
    ) => {
      if (!value || Array.isArray(value)) {
        // single select only
        return
      }
      const dimension = dimensionsList.find((d) => d.id === value.id)
      if (dimension) {
        onChange(dimension.id)
      }
    },
    [dimensionsList, onChange],
  )

  return (
    <FormControl fullWidth disabled={readonly}>
      <StyledAutocomplete
        id="autocomplete-dimension-select"
        size="small"
        value={val}
        disabled={readonly}
        autoSelect={true}
        disableClearable={true}
        options={sortedDimensions}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            label="Dimension"
            variant="outlined"
            onFocus={(e) => e.target?.select()}
            {...params}
          />
        )}
      />
    </FormControl>
  )
}
