import { SectionedModalSectionDefinition } from '@fintastic/shared/ui/modal-framework'
import React from 'react'
import { ModalSection } from '../ModalSection/ModalSection'
import { RouteObject } from '@fintastic/shared/util/memory-router'
import { Maybe } from '@fintastic/shared/util/types'

export const prepareWrappedNavRoutes = (
  routes: SectionedModalSectionDefinition[],
): RouteObject[] =>
  routes.map((r) => ({
    path: r.path,
    element: <ModalSection />,
  }))

export const makeInitialPath = (
  routes: SectionedModalSectionDefinition[],
  initialPath?: string,
) => {
  if (initialPath) {
    if (routes.length === 1) {
      return [initialPath] // single section
    }
    return [routes[0].path, initialPath] // root navigation + given section
  }

  return [routes[0].path] // first section
}

export const isFirstSection = (
  path: Maybe<string>,
  routes: SectionedModalSectionDefinition[],
) => {
  if (!path) {
    return false
  }
  return path === (routes || [])[0]?.path
}
