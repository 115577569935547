import React, { useEffect, useState } from 'react'
import { AgGridColumnHeaderProps } from './types'
import { AgGridColumnHeaderCustomizer } from './AgGridColumnHeaderCustomizer'
import { Box } from '@mui/material'

export type AgGridDefaultColumnHeaderProps = {
  contentAfterText?: React.ReactNode
  contentAfterFilter?: React.ReactNode
  contentAfterSorting?: React.ReactNode
  contentBeforeMenu?: React.ReactNode
  contentAfterMenu?: React.ReactNode
  ContentAfterMenuComponent?: React.FC<AgGridColumnHeaderProps>
  ContentAfterTextComponent?: React.FC<AgGridColumnHeaderProps>
}

// @todo add tests
export const AgGridDefaultColumnHeader = ({
  contentAfterText,
  contentAfterFilter,
  contentAfterSorting,
  contentBeforeMenu,
  contentAfterMenu,
  ContentAfterMenuComponent,
  ContentAfterTextComponent,
  ...props
}: AgGridColumnHeaderProps<AgGridDefaultColumnHeaderProps>): JSX.Element => {
  const { api } = props
  const [, setcount] = useState(0)

  /**
   * For some reason AgGrid doesn't re-render headers when filters has changed,
   * which leads to inconsisten UI.
   * So to fix this I added the following workaround:
   */
  useEffect(() => {
    api.addEventListener('filterChanged', () => {
      setcount((c) => c + 1)
    })
  }, [api])

  return (
    <AgGridColumnHeaderCustomizer {...props}>
      {({ filtersIndicator, menu, sorting, handleSortClick }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: menu ? 'calc(100% - 16px)' : '100%',
            }}
            onClick={(e) => {
              handleSortClick(undefined, e)
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {props.displayName}
            </Box>
            {contentAfterText}
            {ContentAfterTextComponent ? (
              <ContentAfterTextComponent {...props} />
            ) : null}
            {filtersIndicator}
            {contentAfterFilter}
            {sorting}
            {contentAfterSorting}
          </Box>
          {contentBeforeMenu}
          {menu && <Box ml="auto">{menu}</Box>}
          {contentAfterMenu}
          {ContentAfterMenuComponent ? (
            <ContentAfterMenuComponent {...props} />
          ) : null}
        </Box>
      )}
    </AgGridColumnHeaderCustomizer>
  )
}
