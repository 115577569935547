import { Box, styled } from '@mui/material'

export const StyledEntityType = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  flex-shrink: 1;

  .MuiButtonBase-root {
    background: #fff;
  }
`
