import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  ISetFilterParams,
  SideBarDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useMemo } from 'react'
import { DimensionItem } from '@fintastic/web/data-access/model-globals'
import { AllowDenyToggle } from '../../components/AllowDenyToggle'
import { UnrestrictedDimensionsResult } from '../../api'
import type { DimensionId } from '@fintastic/shared/util/types'

const defaultGridStyles = {
  height: '100%',
  width: '100%',
}

const defaultColDef = {
  filter: true,
  sortable: true,
  resizable: true,
  flex: 1,
}

type RestrictedDimensionsTableProps = {
  dimensions: DimensionItem[]
  unrestrictedDimensions: UnrestrictedDimensionsResult
  onChangeAccess: (change: {
    dimensionId: DimensionId
    allowed: boolean
  }) => void
}

export const RestrictedDimensionsTable: React.FC<
  RestrictedDimensionsTableProps
> = ({ dimensions, unrestrictedDimensions, onChangeAccess }) => {
  const dimensionLabelsMap = useMemo(
    () => Object.fromEntries(dimensions.map((d) => [d.id, d.label])),
    [dimensions],
  )

  const unrestrictedDimensionsMap = useMemo(
    () =>
      Object.fromEntries(
        unrestrictedDimensions.map((d) => [d.resource_id, true]),
      ),
    [unrestrictedDimensions],
  )

  const dimensionsTableColDefs = useMemo<ColDef<DimensionItem>[]>(
    () => [
      {
        field: 'id',
        headerName: 'Dimension',
        valueFormatter: (params) => params.data?.label ?? '',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: (params) =>
            dimensionLabelsMap[params.value ?? ''] ??
            params.value ??
            '(Unknown dimension)',
        } as ISetFilterParams,
      },
      {
        field: 'allowed',
        headerName: 'Access',
        filter: false,
        valueGetter: (params) =>
          unrestrictedDimensionsMap[params.data?.id || ''] || false,
        valueFormatter: (params) =>
          !params.value || params.value === 'false' ? 'Available' : 'Available',
        filterParams: {
          valueFormatter: (params: ValueFormatterParams<DimensionItem>) =>
            !params.value || params.value === 'false'
              ? 'Available'
              : 'Restricted',
        },
        cellRenderer: (params: ICellRendererParams<DimensionItem>) => (
          <AllowDenyToggle
            value={params.value}
            onChange={(newValue) => {
              onChangeAccess({
                dimensionId: params.data?.id || '',
                allowed: newValue === 'allow',
              })
            }}
          />
        ),
        width: 180,
      },
    ],
    [dimensionLabelsMap, unrestrictedDimensionsMap, onChangeAccess],
  )

  const rowData = useMemo<DimensionItem[]>(() => {
    const result = dimensions.map((dimensionValue) => ({
      ...dimensionValue,
      allowed: true,
    }))

    return result
  }, [dimensions])

  const getRowIdCallBack = useCallback(
    (params: GetRowIdParams<DimensionItem>) => params.data.id,
    [],
  )

  return (
    <AgGridThemeFintasticWrapper
      sx={defaultGridStyles}
      themeOptions={useMemo(() => ({}), [])}
    >
      {({ defaultGridProps }) => (
        <AgGridReact
          {...defaultGridProps}
          columnDefs={dimensionsTableColDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          getRowId={getRowIdCallBack}
          sideBar={sidebarDef}
        ></AgGridReact>
      )}
    </AgGridThemeFintasticWrapper>
  )
}

const sidebarDef: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: '',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
}
