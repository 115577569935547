import React from 'react'
import { StyledRoot, StyledButtonContainer } from './ActionsBar.styled'
import { Button } from '@mui/material'

export type ActionsBarProps = {
  canApply: boolean
  canReset: boolean
  onReset: () => void
  onApply: () => void
}

export const ActionsBar: React.FC<ActionsBarProps> = ({
  canApply,
  onApply,
  canReset,
  onReset,
}) => (
  <StyledRoot>
    <StyledButtonContainer>
      <Button
        disabled={!canReset}
        onClick={onReset}
        size="medium"
        variant="text"
        fullWidth
      >
        Restore defaults
      </Button>
    </StyledButtonContainer>
    <StyledButtonContainer>
      <Button
        disabled={!canApply}
        onClick={onApply}
        size="medium"
        variant="contained"
        color="primary"
        fullWidth
      >
        Apply
      </Button>
    </StyledButtonContainer>
  </StyledRoot>
)
