import { useMemo, useState } from 'react'
import {
  AddLinesDrawerPublicContext,
  AddLinesInternalContext,
  ListAddLinesTarget,
} from '../types'
import { useAddLinesData, useAddLinesTargetApi } from '../hooks'

export type AddLinesDrawerContextValues = {
  publicContext: AddLinesDrawerPublicContext
  stateContext: AddLinesInternalContext
}

export const useAddLinesDrawerContextValues =
  (): AddLinesDrawerContextValues => {
    const [target, setTarget] = useState<ListAddLinesTarget>({
      listId: '',
      versionId: '',
    })

    const addLinesData = useAddLinesData()

    const addLinesTargetApi = useAddLinesTargetApi(
      target,
      setTarget,
      addLinesData,
    )

    const publicContextValue = useMemo<AddLinesDrawerPublicContext>(() => {
      const value: AddLinesDrawerPublicContext = {
        target,
        updateTargetParams: addLinesTargetApi.updateTargetParams,
        addRows: addLinesTargetApi.addRows,
        setTarget: addLinesTargetApi.setTarget,
      }
      return value
    }, [target, addLinesTargetApi])

    const internalContextValue = useMemo<AddLinesInternalContext>(() => {
      const value: AddLinesInternalContext = {
        addLinesData,
        addLinesTargetApi,
      }

      return value
    }, [addLinesData, addLinesTargetApi])

    return useMemo<AddLinesDrawerContextValues>(
      () => ({
        publicContext: publicContextValue,
        stateContext: internalContextValue,
      }),
      [internalContextValue, publicContextValue],
    )
  }
