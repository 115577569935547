import { Button } from '@mui/material'
import React from 'react'
import {
  useChartSettingsContext,
  useChartSettingsEditContext,
} from '../../contexts'
import { StyledChartSaveButtons } from './SettingsSavePanel.styled'

export const SettingsSavePanel: React.FC = () => {
  const {
    initialSettingsChanged,
    referenceSettingsChanged,
    handleReset,
    handleCancel,
    handleApply,
  } = useChartSettingsEditContext()

  const { loading } = useChartSettingsContext()

  return (
    <StyledChartSaveButtons>
      <Button
        disabled={!referenceSettingsChanged || loading}
        color="primary"
        variant="text"
        onClick={handleReset}
      >
        Restore defaults
      </Button>

      <Button
        disabled={!initialSettingsChanged || loading}
        color="primary"
        variant="outlined"
        sx={{ ml: 1 }}
        onClick={handleCancel}
      >
        Cancel
      </Button>

      <Button
        disabled={!initialSettingsChanged || loading}
        sx={{ ml: 1 }}
        color="primary"
        variant="contained"
        onClick={handleApply}
      >
        Apply
      </Button>
    </StyledChartSaveButtons>
  )
}
