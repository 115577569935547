import React, { useCallback } from 'react'
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import type {
  BasicColumnDefinition,
  GenericReportTreeRowCellValue,
} from '@fintastic/web/util/generic-report'
import {
  useCurrentDiscussionObjectId,
  useLayoutStateIsDiscussionsOpened,
} from '@fintastic/shared/ui/app-layout-framework'
import { buildObjectId } from '../utils/build-object-id'
import { useSyncDeeplinkValue } from '@fintastic/web/util/deeplink'
import {
  useAllThreadsBulk,
  useBoardId,
} from '@fintastic/web/data-access/comments'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'

export const useAddNewComment = ({
  gridRef,
  dimensions,
  deeplinkWidgetId,
}: {
  gridRef: React.RefObject<AgGridReactType<GenericReportTreeRowCellValue>>
  dimensions: BasicColumnDefinition[]
  deeplinkWidgetId: string
}) => {
  const boardId = useBoardId()

  const [, setNewComment] = useSyncDeeplinkValue<boolean>({
    key: 'new_comment',
    defaultValue: false,
  })
  const [, setObjectId] = useCurrentDiscussionObjectId()
  const [, setCommentsPanelOpened] = useLayoutStateIsDiscussionsOpened()

  const [, setThreadId] = useSyncDeeplinkValue<number>({
    key: 'thread_id',
    defaultValue: 0,
  })

  const { data } = useAllThreadsBulk('board' + boardId)

  const periodSelection = usePeriodSelectorContext()

  const [monthOverMonthEnabled] = useSyncDeeplinkValue<boolean>({
    key: `w${deeplinkWidgetId}_mom`,
    defaultValue: false,
  })

  return useCallback(
    (newComment: boolean) => {
      const range = gridRef.current?.api.getCellRanges()?.[0]
      if (!range) {
        return
      }
      const selectedRow = range?.startRow?.rowIndex

      if (typeof selectedRow !== 'number') {
        return
      }

      const node = gridRef.current?.api.getDisplayedRowAtIndex(selectedRow)
      const column = range.startColumn

      if (!node) {
        return
      }

      const object_key = buildObjectId(
        column,
        node,
        dimensions,
        deeplinkWidgetId,
        periodSelection,
        monthOverMonthEnabled,
      )

      setNewComment(newComment)
      setCommentsPanelOpened(true)
      setObjectId(object_key)

      const activeThread = data?.find((i) => i.object_key === object_key)
      setThreadId(activeThread?.id || 0)
    },
    [
      data,
      deeplinkWidgetId,
      dimensions,
      gridRef,
      monthOverMonthEnabled,
      periodSelection,
      setCommentsPanelOpened,
      setNewComment,
      setObjectId,
      setThreadId,
    ],
  )
}
