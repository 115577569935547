import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  StyledClearCellButtonButton,
  StyledClearCellButtonRoot,
} from './ClearCellButton.styled'
import { CloseIcon } from '@fintastic/shared/ui/icons'
import { Maybe } from '@fintastic/shared/util/types'
import { isDomNodeVisible } from '@fintastic/shared/util/web-api'

export type ClearCellButtonProps = {
  onClick: () => void
}

export const ClearCellButton: React.FC<ClearCellButtonProps> = ({
  onClick,
}) => {
  const buttonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const [isShowOnLeft, setIsShowOnLeft] = useState(false)

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      onClick()
    },
    [onClick],
  )

  useEffect(() => {
    if (!buttonRef.current) {
      return
    }
    if (!isDomNodeVisible(buttonRef.current)) {
      setIsShowOnLeft(true)
    }
  }, [])

  return (
    <StyledClearCellButtonRoot
      sx={
        isShowOnLeft
          ? {
              left: 'auto',
              right: '100%',
            }
          : {}
      }
    >
      <StyledClearCellButtonButton ref={buttonRef} onClickCapture={handleClick}>
        <CloseIcon fontSize="small" />
      </StyledClearCellButtonButton>
    </StyledClearCellButtonRoot>
  )
}
