import { useDispatch } from 'react-redux'
import {
  ChangeListFormulaPayload,
  ChangeListFormulaStatePayload,
  ChangeListTimeDimensionPayload,
  StartListCreationPayload,
  StartListEditingPayload,
} from '../types'
import { actions } from '../slice'
import { useMemo } from 'react'

export function useListsActions() {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      startEditing: (p: StartListEditingPayload) => {
        dispatch(actions.startListEditing(p))
      },
      startCreation: (p: StartListCreationPayload) => {
        dispatch(actions.startListCreation(p))
      },
      changeFormula: (p: ChangeListFormulaPayload) => {
        dispatch(actions.changeListFormula(p))
      },
      changeFormulaState: (p: ChangeListFormulaStatePayload) => {
        dispatch(actions.changeListFormulaState(p))
      },
      changeListTimeDimension: (p: ChangeListTimeDimensionPayload) => {
        dispatch(actions.changeListTimeDimension(p))
      },
    }),
    [dispatch],
  )
}
