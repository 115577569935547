import { useMemo } from 'react'
import { getFintasticSyntaxApi } from '@fintastic/web/data-access/formulas'
import { SyntaxApi } from '../../../Editor/features/syntax/types'

// @todo add tests
export function useSyntaxApi(): SyntaxApi {
  const api = getFintasticSyntaxApi()

  return useMemo<SyntaxApi>(
    () => ({
      api: api,
    }),
    [api],
  )
}
