import React from 'react'
import { usePeriodSelectionComponent } from '@fintastic/web/feature/period-selector'
import {
  useBoardDefaultVersions,
  useBoardSelectedVersions,
} from '@fintastic/web/feature/boards'
import { PeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { WidgetRendererProps } from '@fintastic/shared/ui/widgets-framework'
import {
  BaseTimeDimensionStaticWidgetProvider,
  useBaseTimeDimensionStaticWidgetAdapter,
} from '@fintastic/web/data-access/base-time-dimension'
import { WidgetPeriodSelectionComponentProps } from './WidgetPeriodSelectionContainer'
import { StyledLoadingBox } from './WidgetPeriodSelection.styled'
import { CircularProgress } from '@mui/material'

export const WidgetPeriodSelectionContainerDisplayMode: React.FC<
  {
    Component: React.FC<WidgetPeriodSelectionComponentProps>
  } & WidgetRendererProps
> = ({ Component, ...props }) => {
  const baseTimeDimensionContextValue =
    useBaseTimeDimensionStaticWidgetAdapter()

  const boardDefaultVersions = useBoardDefaultVersions()

  const { versions: selectedVersions } =
    useBoardSelectedVersions(boardDefaultVersions)

  const periodSelection = usePeriodSelectionComponent({
    selectedVersions,
    persistingKey: `w_${props.widget.id}`,
    buttonDesign: 'compact',
    baseTimeDimensionId: baseTimeDimensionContextValue.baseTimeDimensionId,
  })

  // If loading - no context provided
  if (periodSelection.isLoading) {
    return (
      <StyledLoadingBox>
        <CircularProgress />
      </StyledLoadingBox>
    )
  }

  if (periodSelection.isError) {
    return (
      <ErrorAlert
        title="Loading error"
        message="Version entities or calendar could not be loaded."
      />
    )
  }

  // Otherwise add context
  return (
    <BaseTimeDimensionStaticWidgetProvider
      value={baseTimeDimensionContextValue}
    >
      <PeriodSelectorContext.Provider value={periodSelection.contextValue}>
        <Component {...props} periodSelection={periodSelection} />
      </PeriodSelectorContext.Provider>
    </BaseTimeDimensionStaticWidgetProvider>
  )
}
