import type { Maybe } from '@fintastic/shared/util/types'
import { prefixKey } from './prefixKey'

/**
 * Gets value from local storage by key
 * @deprecated use lib `usehooks-ts` instead
 */
export const getLocalStorageValue = (key: string): Maybe<string> => {
  const value = window.localStorage.getItem(prefixKey(key))

  if (value) {
    return JSON.parse(value)
  }

  return null
}

export * from './prefixKey'
