import { useCallback } from 'react'
import {
  VersionUserLockParsed,
  showVersionIsLockedForDataEditingToast,
} from '@fintastic/web/util/versions'
import { CellClickedEvent } from 'ag-grid-community'
import {
  MetricGridRow,
  MetricOrListSource,
} from '@fintastic/web/util/metrics-and-lists'
import type { Maybe } from '@fintastic/shared/util/types'

export const useHandleLockedCellClick = (
  source: Maybe<MetricOrListSource>,
  versionUserLock: VersionUserLockParsed,
) =>
  useCallback(
    (e: CellClickedEvent<MetricGridRow, any>) => {
      const isCellEditable = e.column.isCellEditable(e.node)
      if (isCellEditable) {
        return
      }

      if (source !== 'input') {
        return
      }

      if (!versionUserLock.editIsBlocked) {
        return
      }

      showVersionIsLockedForDataEditingToast(versionUserLock)
    },
    [source, versionUserLock],
  )
