import { useCallback } from 'react'
import { SaveFormulaApi } from './types'

export type UseUpdateFormulaParams = {
  formula: string
  saveFormulaApi: SaveFormulaApi
}

// @todo add tests
export function useUpdateFormulaCallback({
  formula,
  saveFormulaApi,
}: UseUpdateFormulaParams) {
  return useCallback(() => {
    if (saveFormulaApi.calculating) {
      return
    }
    saveFormulaApi.save(formula)
  }, [formula, saveFormulaApi])
}
