import { useCallback, useMemo } from 'react'
import { ChangeTimeAggregationAction } from '../types'
import { useMetricsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeTimeAggregationFlow() {
  const { changeTimeAggregation } = useMetricsActions()

  const handleAction = useCallback<ChangeTimeAggregationAction>(
    (aggregation) => {
      changeTimeAggregation(aggregation)
    },
    [changeTimeAggregation],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
