import CommentsIconUrl from '../../assets/comments.svg'
import AddCommentIconUrl from '../../assets/add-comment.svg'
import PivotIconUrl from '../../assets/pivot.svg'
import {
  fintasticAgIcons,
  resolveFintasticAgIconKey,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'

export const commentsIcon = `<span class="ag-icon" role="presentation"><img src=${CommentsIconUrl} height="16px"/></span>`

export const addCommentIcon = `<span class="ag-icon" role="presentation"><img src=${AddCommentIconUrl} height="16px"/></span>`

export const pivotIcon = `<span class="ag-icon active-sidebar-icon" role="presentation"><img src=${PivotIconUrl} height="20px"/></span>`

export const actionIcons = {
  grouping: pivotIcon,
  filter:
    '<span title="Filter" class="ag-icon ag-icon-filter" role="presentation"></span>',
  [resolveFintasticAgIconKey(fintasticAgIcons.rows.name)]:
    fintasticAgIcons.rows.makeIcon({
      title: 'Show/hide rows with blanks and zeros only',
    }),
}
