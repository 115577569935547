import {
  FailedDataUpdateEventWrapper,
  SuccessfulDataUpdateEventWrapper,
} from './abstract-data-types/pusher-events'

export const logEvent = (
  event: SuccessfulDataUpdateEventWrapper | FailedDataUpdateEventWrapper,
) => {
  console.log(
    `[${new Date().toLocaleTimeString()}] data update event received\n`,
    event,
  )
}
