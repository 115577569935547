import { useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  useLiveActualsVersion,
  useLoadVersionsList,
  useVersionsListMap,
} from '@fintastic/web/data-access/versions'
import { isVersionEditable } from '@fintastic/web/util/versions'

export type VersionsApi = ReturnType<typeof useVersionsApi>

export const useVersionsApi = (versionIds: string[]) => {
  const [versionId, setVersionId] = useState<Maybe<string>>(
    versionIds[0] || null,
  )

  const versionsListQuery = useLoadVersionsList({
    versionsIds: versionIds,
    withLiveActuals: true,
    showArchived: true,
  })

  const versionsMetadata = useVersionsListMap(
    useMemo(() => versionsListQuery.data || [], [versionsListQuery.data]),
  )
  const versionEditable = isVersionEditable(versionsMetadata[versionId || ''])
  const { liveActualsVersion } = useLiveActualsVersion()
  const isLiveActuals = versionId === liveActualsVersion?.uuid
  const versionLabel = useMemo(() => {
    if (isLiveActuals) {
      return 'Actuals'
    }
    return (
      versionsMetadata[versionId as string]?.name || '(unrecognized version)'
    )
  }, [isLiveActuals, versionsMetadata, versionId])

  useEffect(() => {
    if (versionIds.length === 0) {
      setVersionId(null)
      return
    }

    if (!versionId || !versionIds.includes(versionId)) {
      setVersionId(versionIds[0])
    }
  }, [versionIds, versionId])

  const isLoading = versionsListQuery.isLoading
  const isFetching = versionsListQuery.isFetching
  const error = versionsListQuery.error

  return useMemo(
    () =>
      ({
        versionId: versionId as string,
        versionLabel,
        versionEditable,
        versionsMetadata,
        switchToVersion: setVersionId,
        isLoading,
        isFetching,
        error,
        isLiveActuals,
      } as const),
    [
      versionId,
      versionLabel,
      versionEditable,
      versionsMetadata,
      isLoading,
      isFetching,
      error,
      isLiveActuals,
    ],
  )
}
