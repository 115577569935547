import { usePusher } from '@fintastic/shared/data-access/pusher-react'
import { useEffect, useRef } from 'react'
import Pusher from 'pusher-js'
import {
  isNullish,
  Nullable,
} from '@fintastic/shared/util/functional-programming'

// use private method is the only way to programmatically trigger re-connection
type PrivatePusherConnectionInterface = {
  retryIn: (delay: number) => void
}

const reconnect = (client: Pusher) => {
  ;(client.connection as unknown as PrivatePusherConnectionInterface).retryIn(0)
}

type Seconds = number
const TAB_NOT_IN_FOCUS_TIMIOUT: Seconds = 60

export const usePusherReconnectionFixture = () => {
  const client = usePusher()
  const tabLostFocusAt = useRef<Nullable<number>>()

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        tabLostFocusAt.current = new Date().getTime()
        return
      }

      if (isNullish(tabLostFocusAt.current)) {
        return
      }

      const diffInMilliSeconds = new Date().getTime() - tabLostFocusAt.current
      if (diffInMilliSeconds >= TAB_NOT_IN_FOCUS_TIMIOUT * 1000) {
        reconnect(client)
        tabLostFocusAt.current = null
      }
    })
  }, [client])
}
