import React from 'react'

export const FolderCollapsedIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 6.68125H12.5859L9.83672 4.05156C9.80174 4.01882 9.75573 4.00041 9.70781 4H3.75C3.33516 4 3 4.33516 3 4.75V18.625C3 19.0398 3.33516 19.375 3.75 19.375H21C21.4148 19.375 21.75 19.0398 21.75 18.625V7.43125C21.75 7.01641 21.4148 6.68125 21 6.68125ZM20.0625 17.6875H4.6875V5.6875H9.10547L11.9086 8.36875H20.0625V17.6875Z"
      fill="#263646"
    />
  </svg>
)
