import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { EntityId, ListEntityDefinition } from '../../types'
import { CommonListOrMetricWrapperImplementation } from './CommonListOrMetricWrapperImplementation'
import { ColumnEntityDefinitionWrapper } from './ColumnEntityDefinitionWrapper'

export class ListEntityDefinitionWrapper
  extends CommonListOrMetricWrapperImplementation
  implements ImmutableADTWrapper<ListEntityDefinition>
{
  readonly _rawData: ListEntityDefinition

  constructor(data: ListEntityDefinition) {
    super(data)
    this._rawData = data
  }

  unwrap(): ListEntityDefinition {
    return this._rawData
  }

  public type = 'list' as const

  public get columns(): ColumnEntityDefinitionWrapper[] {
    return this._rawData.columns.map(
      (c) => new ColumnEntityDefinitionWrapper(c),
    )
  }

  public get allEntitiesIds(): EntityId[] {
    return [this.id, ...this.columns.map((c) => c.id)]
  }
}
