import type { InferReduxConnectable } from '@fintastic/shared/data-access/redux'
import * as slice from './slice'
import { namespace } from './state'

export * from './selectors'

const { reducer } = slice

const connectable = {
  namespace,
  reducer,
}

export default connectable as InferReduxConnectable<typeof connectable>
