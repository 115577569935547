import { useVersionEntitiesContext } from '@fintastic/web/data-access/versions'
import { useMemo } from 'react'
import { useListEditorApi } from '../../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../../features/active/makeApi'

export const useVersionDimensions = () => {
  const api = useListEditorApi() as ActiveListEditorApi
  const entitiesContext = useVersionEntitiesContext()

  const { versionMetadata } = api
  const versionId = versionMetadata ? versionMetadata.uuid : ''

  return useMemo(
    () =>
      ({
        dimensions: !entitiesContext.entities[versionId]
          ? []
          : entitiesContext.entities[versionId]!.dimensions.map(
              (d) => d.dimension,
            ),
        isLoading: entitiesContext.isLoading,
      } as const),
    [entitiesContext.entities, entitiesContext.isLoading, versionId],
  )
}
