import React from 'react'
import {
  PageLayout,
  PageLayoutContentError,
  PageLayoutContentLoader,
} from '@fintastic/shared/ui/app-layout-framework'
import {
  useLiveActualsVersion,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import { LiveActualsLoadingContainer } from '../containers/LiveActualsLoadingContainer'
import { CommonVersionPageContainer } from '../containers/shared/CommonVersionPageContainer'

export const LiveActualsPage: React.FC = () => {
  const { liveActualsVersion, versionsListQuery } = useLiveActualsVersion()
  const entities = useLoadVersionEntities(liveActualsVersion?.uuid)

  if (versionsListQuery.isLoading || entities.isLoading) {
    return (
      <PageLayout>
        <PageLayoutContentLoader />
      </PageLayout>
    )
  }

  if (versionsListQuery.isError || !liveActualsVersion) {
    return (
      <PageLayout>
        <PageLayoutContentError
          title="Can't find the Live Actuals version"
          message="Something went wrong."
        />
      </PageLayout>
    )
  }

  if (entities.isError) {
    return (
      <PageLayout>
        <PageLayoutContentError
          title="Can't load the version"
          message="Something went wrong."
        />
      </PageLayout>
    )
  }

  return (
    <CommonVersionPageContainer versionId={liveActualsVersion.uuid}>
      <LiveActualsLoadingContainer liveActualsVersion={liveActualsVersion} />
    </CommonVersionPageContainer>
  )
}
