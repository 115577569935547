import React, { useMemo } from 'react'
import { BaseGridRow } from '../../../base-grid/types'
import {
  ToolbarRowsActionIconButton,
  ToolbarRowsActionIconButtonProps,
} from '../ToolbarRowsActionIconButton'
import { DeleteIcon } from '@fintastic/shared/ui/icons'
import { SvgIconProps } from '@mui/material'

const defaultIconProps: SvgIconProps = {
  fontSize: 'small',
}

export type DeleteRowsButtonProps<T extends BaseGridRow> = Omit<
  ToolbarRowsActionIconButtonProps<T>,
  'children'
> & {
  iconProps?: SvgIconProps
}

export const DeleteRowsButton = <T extends BaseGridRow>({
  title = 'Delete',
  iconProps = defaultIconProps,
  ...rest
}: DeleteRowsButtonProps<T>): JSX.Element | null => {
  const mergedIconProps = useMemo(
    () => ({
      ...defaultIconProps,
      ...iconProps,
    }),
    [iconProps],
  )

  return (
    <ToolbarRowsActionIconButton
      data-testid="DeleteRowsButton"
      title={title}
      {...rest}
    >
      <DeleteIcon {...mergedIconProps} />
    </ToolbarRowsActionIconButton>
  )
}
