import { Box, styled } from '@mui/material'

export const StyledRoot = styled(Box)(({ theme }) => ({}))

export const StyledColumnsHeader = styled(Box)`
  padding: ${({ theme }) => theme.spacing(0.5, 2)};

  text-align: right;
`

export const StyledColumns = styled(Box)(({ theme }) => ({}))

export const StyledFooter = styled(Box)(({ theme }) => ({}))
