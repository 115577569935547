import React, { MouseEvent, useCallback, useMemo, useRef } from 'react'
import { PopoverOrigin, Tooltip, styled } from '@mui/material'
import { ColorSelectorButton } from './ColorSelectorButton'
import {
  ContextMenu,
  ContextMenuItemButton,
  ContextMenuProps,
  useContextMenuState,
} from '@fintastic/shared/ui/context-menu-framework'
import { HexColor, Maybe, toMaybe } from '@fintastic/shared/util/types'
import { ColorSelectorMenuItem } from './ColorSelectorMenuItem'
import { ColorResetButton } from './ColorResetButton'
import {
  ColumnColor,
  columnColorEqualByTypeAndLocation,
} from '@fintastic/web/util/metrics-and-lists'
import { ColorSelection } from './types'
import produce from 'immer'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { SYSTEM_COLORS } from '@fintastic/shared/util/colors'

export const ColumnColorSelector: React.FC<{
  currentColumnColumn: ColumnColor
  handleUpdateColumnColors: (columnColors: ColumnColor[]) => void
  columnColors: ColumnColor[]
}> = ({ currentColumnColumn, columnColors, handleUpdateColumnColors }) => {
  const contextMenuState = useContextMenuState()
  const mainButtonRef = useRef<Maybe<HTMLButtonElement>>(null)

  const handleUpdateColor = useCallback(
    (color: Maybe<ColorSelection>) => {
      handleUpdateColumnColors(
        produce(columnColors, (draft) => {
          const columnColor = toMaybe(
            draft.find((c) =>
              columnColorEqualByTypeAndLocation(c, currentColumnColumn),
            ),
          )
          if (!columnColor) {
            draft.push({
              ...currentColumnColumn,
              hexColor: toMaybe(color?.hex) as Maybe<HexColor>,
            })
          } else {
            columnColor.hexColor = toMaybe(color?.hex) as Maybe<HexColor>
          }
        }),
      )
    },
    [columnColors, currentColumnColumn, handleUpdateColumnColors],
  )

  const handleOpenContextMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (mainButtonRef.current === null) {
        return
      }
      contextMenuState.handleClickOnAnchor({ target: mainButtonRef.current })
    },
    [contextMenuState],
  )

  const contextMenu = useMemo<ContextMenuProps['menuItems']>(
    () => [
      {
        id: 'reset',
        type: 'button',
        label: <ColorResetButton />,
        onClick: () => handleUpdateColor(null),
      },
      {
        id: 'divider',
        type: 'divider',
      },
      ...SYSTEM_COLORS.map<ContextMenuItemButton>((color) => ({
        type: 'button',
        id: color.hex,
        label: <ColorSelectorMenuItem {...color} />,
        onClick: () => handleUpdateColor(color),
        active: currentColumnColumn.hexColor === color.hex,
      })),
    ],
    [currentColumnColumn.hexColor, handleUpdateColor],
  )

  const hasColumnColoringAccess = useRoleLevelAccess(['power_user', 'modeler'])

  if (!hasColumnColoringAccess) {
    return null
  }

  return (
    <>
      <WrapperStyled open={contextMenuState.isOpen}>
        <Tooltip title="Apply background color">
          <ColorSelectorButton
            onClick={handleOpenContextMenu}
            ref={mainButtonRef}
          />
        </Tooltip>
      </WrapperStyled>
      <ContextMenu
        menuItems={contextMenu}
        open={contextMenuState.isOpen}
        anchorEl={contextMenuState.anchorElement}
        onClose={contextMenuState.handleClose}
        data-testid="color-selector-context-menu"
        sx={{ width: '240px' }}
        {...contextMenuOrigin}
      />
    </>
  )
}

const WrapperStyled = styled('div')<{
  open: boolean
}>(
  ({ open }) => `
  transition: opacity 0.2s;
  opacity: 0;

  .ag-header-active & {
    opacity: 1;
  }

  ${
    open &&
    `
      opacity: 1;
  `
  }
`,
)

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 32,
}

const contextMenuOrigin = {
  anchorOrigin,
  transformOrigin,
}
