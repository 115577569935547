import { UseLocationResult } from '@fintastic/shared/util/memory-router'
import { createContext, useContext } from 'react'

export type ChartSettingsNavigationContextValue = UseLocationResult

const stub = () => {
  throw new Error('ChartSettingsNavigationContext is not initialised')
}

export const ChartSettingsNavigationContext =
  createContext<ChartSettingsNavigationContextValue>({
    path: null,
    goTo: stub,
    goBack: stub,
    reset: stub,
  })

export function useChartSettingsNavigationContext() {
  return useContext(ChartSettingsNavigationContext)
}
