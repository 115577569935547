import { Maybe } from '@fintastic/shared/util/types'
import { ToolPanelColumnCompParams, ToolPanelDef } from 'ag-grid-community'

const defaultColumnsToolPanelDefinition: ToolPanelDef = {
  id: 'columns',
  labelDefault: '',
  labelKey: 'columns',
  iconKey: 'grouping',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: {},
}

const groupingToolPanelParams: Partial<ToolPanelColumnCompParams> = {
  suppressPivotMode: true,
  suppressValues: true,
  suppressPivots: true,
  suppressColumnExpandAll: true,
  suppressColumnMove: true,
  contractColumnSelection: true,
  suppressColumnFilter: true,
  suppressColumnSelectAll: true,
}

const columnToolPanelParams: Partial<ToolPanelColumnCompParams> = {
  ...groupingToolPanelParams,
  suppressRowGroups: true,
  suppressColumnMove: false,
}

export function buildColumnsToolPanelDefinition(
  enableColumnsSelection: boolean,
  enableGrouping: boolean,
): Maybe<ToolPanelDef> {
  if (!enableColumnsSelection && !enableGrouping) {
    return null
  }

  return {
    ...defaultColumnsToolPanelDefinition,
    toolPanelParams: enableGrouping
      ? groupingToolPanelParams
      : columnToolPanelParams,
  }
}
