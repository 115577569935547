import { styled } from '@mui/material'

export const StyledDataTypeRoot = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  margin: 0;
`

export const StyledDimensionsRoot = styled('div')`
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`
