import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { DesignerWidgetType } from '../../BoardDesigner/widgets/board-design-type-utils'
import { METRIC_CHART_WIDGET_TYPE } from '../MetricChartWidget'
import { ChartWidgetSettings } from '@fintastic/web/feature/charts'
import { WidgetSettingsStandardDialog } from './WidgetSettingsStandardDialog'

export type WidgetSettingsDialogProps = {
  widgetType: Maybe<DesignerWidgetType>
}

// simple dispatcher
export const WidgetSettingsDialog: React.FC<WidgetSettingsDialogProps> = ({
  widgetType,
}) => {
  if (widgetType === METRIC_CHART_WIDGET_TYPE) {
    // imported from @fintastic/web/feature/charts
    return <ChartWidgetSettings />
  }

  return <WidgetSettingsStandardDialog widgetType={widgetType} />
}
