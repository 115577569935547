import { ListColumnWrapper } from './ListColumnWrapper'
import { MutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  DimensionId,
  Maybe,
  RollUpFunction,
} from '@fintastic/shared/util/types'
import * as mutations from '../common-metric/mutations'
import { FormattingChanges } from '../common-metric/mutations'
import { MutableWrappedData } from './types'
import { createColumnId } from './constructors'
import { chunk } from 'lodash'
import * as selectors from '../common-metric/selectors'
import { MutableMetricDataWrapper } from '../metric-data'
import {
  isDataTypeConversionNecessary,
  MetricConfigurableDataValueType,
  rollUpTimeColumns,
} from '@fintastic/web/util/metrics-and-lists'
import { MutableMetricDisplaySettingsWrapper } from '../display-settings'
import { getDefaultDimValueIdForCategoryDim } from './utils'
import { VersionDimension } from '@fintastic/web/util/dimensions'

export class MutableListColumnWrapper
  extends ListColumnWrapper
  implements MutableADTWrapper<MutableWrappedData>
{
  readonly _rawData: MutableWrappedData

  constructor(data: MutableWrappedData) {
    super(data)
    this._rawData = data
  }

  unwrap(): MutableWrappedData {
    return this._rawData
  }

  // @todo add test
  data() {
    return new MutableMetricDataWrapper(selectors.data(this._rawData).unwrap())
  }

  // @todo add test
  displaySettings() {
    return new MutableMetricDisplaySettingsWrapper(
      selectors.displaySettings(this._rawData).unwrap(),
    )
  }

  applyCategoryAggregationChanges = (
    aggFunc: RollUpFunction,
    weightsMetricId?: string,
  ) => {
    mutations.applyCategoryAggregationChanges(
      this._rawData,
      aggFunc,
      weightsMetricId,
    )
  }

  applyTimeAggregationChanges = (
    aggFunc: RollUpFunction,
    weightsMetricId?: string,
  ) => {
    mutations.applyTimeAggregationChanges(
      this._rawData,
      aggFunc,
      weightsMetricId,
    )
  }

  applyFormattingChanges(changes: FormattingChanges) {
    mutations.applyFormattingChanges(this._rawData, changes)
  }

  rename(newLabel: string, listId: string) {
    this._rawData.label = newLabel
    if (this.isNewColumn()) {
      this._rawData.id = createColumnId(listId, newLabel)
    }
  }

  addTimeDimension(
    dimensionId: DimensionId,
    allDimensions: VersionDimension[],
  ) {
    /**
     * Column can have only 1 or 2 dimensions. It always has Range dim, and it may have Time dim
     */

    if (this.isCalculated()) {
      throw new Error(
        `Can't add dimension to calculated column "${this.label()}"`,
      )
    }

    if (this.hasTimeDimension()) {
      throw new Error(`Column "${this.label()}" already has time dimension`)
    }

    const dim: Maybe<VersionDimension> =
      allDimensions.find((d) => d.id === dimensionId) || null
    if (!dim) {
      throw new Error(
        `Can't resolve dimension "${dimensionId}" in allDimensions`,
      )
    }

    if (dim.type !== 'Time') {
      throw new Error(
        `Dimension type mismatch for din "${dimensionId}": it's not a Time dimension`,
      )
    }

    const data = this.data()

    const dimensions = [...data.dimensions(), dim.id]
    const timeDimValues = dim.ordered_values
    const dimensionValues = [...data.dimensionValues(), timeDimValues]
    const values = data
      .values()
      .flatMap((v) => Array(timeDimValues.length).fill(v))
    this._rawData.metadata.time_dimension_id = dim.id

    data.setDimensions(dimensions)
    data.setDimensionValues(dimensionValues)
    data.setValues(values)

    // @todo remove after migration from dims metadata
    mutations.restoreDimensionsMetadataFromData(this.unwrap(), allDimensions)
  }

  removeTimeDimension() {
    if (this.isCalculated()) {
      throw new Error(
        `Can't remove dimension from calculated column "${this.label()}"`,
      )
    }

    if (!this.hasTimeDimension()) {
      throw new Error(`Column "${this.label()}" doesn't have Time dimension`)
    }

    const data = this.data()

    const rows = chunk(data.values(), data.values().length / this.rowsAmount())
    const pivotedRows = rows[0].map((v, i) => [
      v,
      ...rows.slice(1).map((r) => r[i]),
    ])

    const values = rollUpTimeColumns(
      pivotedRows,
      this.dataType(),
      this.timeAggregation(),
    )
    const dimensions = [...data.dimensions()]
    dimensions.pop()
    const dimensionValues = [...data.dimensionValues()]
    dimensionValues.pop()
    this._rawData.metadata.time_dimension_id = null

    data.setDimensions(dimensions)
    data.setDimensionValues(dimensionValues)
    data.setValues(values)

    // @todo remove after migration from dims metadata
    mutations.restoreDimensionsMetadataFromData(this.unwrap(), [])
  }

  changeValueDimension(
    dimensionId: DimensionId,
    allDimensions: VersionDimension[],
  ) {
    if (!this.isNewColumn()) {
      throw new Error('Value dimension can be set only in new columns')
    }
    if (this.type() !== 'dimension') {
      throw new Error('Value dimension can be set only in dimension columns')
    }

    const dim: Maybe<VersionDimension> =
      allDimensions.find((d) => d.id === dimensionId) || null
    if (!dim) {
      throw new Error(
        `Can't resolve dimension "${dimensionId}" in allDimensions`,
      )
    }
    if (dim.type !== 'Category') {
      throw new Error(
        `Dimension type mismatch for din "${dimensionId}": it's not a Category dimension`,
      )
    }

    // @todo remove after migration from dims metadata
    this._rawData.metadata.dimensions.splice(
      this._rawData.metadata.dimensions.findIndex(
        (d) => d.id === this.valuesDimensionId(),
      ),
      1,
      {
        ...dim,
      },
    )

    this._rawData.metadata.value.dimension_id = dim.id
    this.data().regenerateValues(getDefaultDimValueIdForCategoryDim(dim))
  }

  changeDataType(
    dataType: MetricConfigurableDataValueType,
    currency: Maybe<string>,
    allDimensions: VersionDimension[],
  ) {
    if (this.type() === 'dimension') {
      throw new Error("Can't change data type of dimension column")
    }

    const dataConversionNecessary = isDataTypeConversionNecessary(
      this.dataType(),
      dataType,
    )

    if (dataConversionNecessary && !this.isNewColumn()) {
      throw new Error("Can't convert data for existing column")
    }

    mutations.applyDataValueTypeChanges(this._rawData, {
      dataType,
      currency,
    })

    if (dataConversionNecessary && !this.isCalculated()) {
      const data = this.data()

      if (this.hasTimeDimension() && !this.allowedToHaveTimeDimension()) {
        // removing of time dimension
        const dimensions = [...data.dimensions()]
        dimensions.pop()
        const dimensionValues = [...data.dimensionValues()]
        dimensionValues.pop()
        this._rawData.metadata.time_dimension_id = null

        data.setDimensions(dimensions)
        data.setDimensionValues(dimensionValues)
      }

      data.regenerateValues(null)
    }

    // @todo remove after migration from dims metadata
    mutations.restoreDimensionsMetadataFromData(this.unwrap(), allDimensions)

    mutations.resetAggregationFunctionsToNoopIfNeeded(this.unwrap())
  }

  changeVisibility(visible: boolean) {
    this.displaySettings().setVisibleAsColumn(visible)
  }

  changeFormula(formula: string) {
    if (!this.isCalculated()) {
      throw new Error('Only calculated columns can have formula')
    }
    mutations.setFormula(this.unwrap(), formula)
  }
}
