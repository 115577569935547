import { memoryRoutesPaths } from '../../../settings-panel/metric/memory-routes-paths'
import { useLocation } from '@fintastic/shared/util/memory-router'
import { useEffect } from 'react'

const defaultInitialLocation = [memoryRoutesPaths.metric]

export function useSettingsRouterLocation(isEditingActive: boolean) {
  const location = useLocation(defaultInitialLocation)
  const { reset } = location

  useEffect(() => {
    if (!isEditingActive) {
      reset()
    }
  }, [isEditingActive, reset])

  return location
}
