import React from 'react'
import { Filter, FilterValue, FilterModel } from '../types'
import type { Maybe } from '@fintastic/shared/util/types'
import { DEFAULT_FILTERS_VALUE_MODEL } from './default-filter-model'

const stub = (): never => {
  throw new Error('FilterContextValue method is called out of provider')
}

export type FilterContextValue = {
  versionId: string
  filters: Filter[]
  model: FilterModel
  appliedModel: FilterModel
  filterIsApplied: boolean
  dirty: boolean
  setFilterValue: (
    filterId: string,
    timeDimensionValueId: Maybe<string>,
    value?: FilterValue,
  ) => void
  apply: () => void
  reset: () => void
  resetFilter: (filterId: string, timeDimensionValueId: Maybe<string>) => void
}

export const FilterContextInitialValue: FilterContextValue = {
  filters: [],
  model: DEFAULT_FILTERS_VALUE_MODEL,
  appliedModel: DEFAULT_FILTERS_VALUE_MODEL,
  versionId: '',
  dirty: false,
  filterIsApplied: false,
  setFilterValue: stub,
  apply: stub,
  reset: stub,
  resetFilter: stub,
}

// Initial value is not supposed to be ever used
export const FilterContext = React.createContext<FilterContextValue>(
  FilterContextInitialValue,
)

FilterContext.displayName = 'FilterContext'
