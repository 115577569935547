import React, { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { GenericReportDetails } from '@fintastic/web/util/generic-report'
import { FormulaEditor, Version } from '@fintastic/web/feature/formulas'

export type GridReportDetailsFormulasEditorWrapperProps = {
  onRequestClose: () => void
  reportDetails: Maybe<GenericReportDetails>
  versionEditable?: boolean
}

// @todo add tests
export const GridReportDetailsFormulasEditorWrapper: React.FC<
  GridReportDetailsFormulasEditorWrapperProps
> = ({ onRequestClose, reportDetails, versionEditable = false }) => {
  const versions = useMemo<Version[]>(
    () => [
      {
        title: '',
        id: reportDetails?.id || 'versionId',
        formula: reportDetails?.formula || '',
        locked: !versionEditable,
        connectedTable: reportDetails?.object_id
          ? {
              id: reportDetails?.object_id,
              revision: reportDetails.revision,
            }
          : null,
      },
    ],
    [
      reportDetails?.formula,
      reportDetails?.id,
      reportDetails?.object_id,
      reportDetails?.revision,
      versionEditable,
    ],
  )

  if (!reportDetails?.id) {
    return null
  }

  return (
    <FormulaEditor
      title="Forecast formula"
      versions={versions}
      readonly={true}
      onRequestClose={onRequestClose}
      showVersionsSelector={false}
    />
  )
}
