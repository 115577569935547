import { useQueryClient } from 'react-query'
import { loadVersionEntities } from './version-entities-query-handler'
import { versionsCacheKeys } from '../../cache'
import { useCallback } from 'react'

export function useManuallyLoadVersionEntities() {
  const queryClient = useQueryClient()

  return useCallback(
    async (versionId: string) =>
      await queryClient.fetchQuery(
        versionsCacheKeys.versionEntities(versionId),
        () => loadVersionEntities(versionId),
        {
          staleTime: Infinity,
          retry: 1,
        },
      ),
    [queryClient],
  )
}
