/**
 * It's a set of values from figma designs.
 * If it's possible - use MUI Theme, this values used there.
 */
export const styleKit = {
  colors: {
    // Names of colors - as in design
    general: {
      black: '#000000',
      black2: '#263646',
      white: '#FFFFFF',
      bg: '#FDFCFB',
      dividers: '#E7E6E4',
      lightGrey: '#F2F2F2',
      grey: '#B6B6B6',
      darkGrey: '#757575',
      notEditable: '#F9F7F5',
      explanation: '#C9EFFF',
    },
    orange: {
      light: '#FFF6ED',
      light2: '#FFE9DA',
      medium: '#FF9568',
      dark: '#FE6422',
      dark2: '#D64E15',
    },
    formula: {
      functions: '#1360F5',
      dimensionValues: '#EDEFFF',
      dimensions: '#7C56B9',
    },
  },
  typography: {
    defaults: {
      fontSize: 14,
      fontWeightRegular: 400,
      fontWeightBold: 800,
      lineHeight: 19.6 / 14,
    },
    // Names of components - as in designs
    h1: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: 33 / 24,
    },
    h2: {
      fontSize: 14,
      fontWeight: 900,
      lineHeight: 19 / 14,
    },
    h3: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 19 / 14,
    },
    h4: {
      fontSize: 12,
      fontWeight: 900,
      lineHeight: 14 / 12,
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 25 / 18,
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 25 / 18,
    },
    paragraph1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 19.6 / 14,
    },
    paragraph2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 19.6 / 14,
    },
    paragraph3: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 19.6 / 14,
    },
    specialTitle1: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 18 / 12,
    },
    specialTitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 19.6 / 14,
    },
    button: {
      fontSize: 12,
      fontWeight: 900,
      lineHeight: 14 / 12,
    },
  },
  spacing: {
    base: 8,
  },
  shapes: {
    borderRadius: 8,
  },
  shadows: {
    toaster: '0px 1px 10px rgba(0, 0, 0, 0.25)',
  },
} as const

export type StyleKit = typeof styleKit
