import type {
  BasicColumnDefinition,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { Button } from '@mui/material'
import { CSS } from '@dnd-kit/utilities'
import { useCallback } from 'react'

const SortableItem: React.FC<{ dimension: BasicColumnDefinition }> = ({
  dimension: { name, label },
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: name })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <span
      className="ag-column-drop-cell ag-column-drop-vertical-cell"
      data-testid="dimensions-sorter-panel-item"
      data-test-value={name}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
    >
      <span
        className="ag-drag-handle ag-column-drop-cell-drag-handle ag-column-drop-vertical-cell-drag-handle"
        role="presentation"
      >
        <span
          className="ag-icon ag-icon-grip"
          unselectable="on"
          role="presentation"
        ></span>
      </span>
      <span className="ag-column-drop-cell-text ag-column-drop-vertical-cell-text">
        {label}
      </span>
    </span>
  )
}

export type DimensionsSorterPanelProps = {
  setLocalDimensions: React.Dispatch<React.SetStateAction<SortableDimension[]>>
  localDimensions: SortableDimension[]
  resetDimensions: () => void
}

export const DimensionsSorterPanel: React.FC<DimensionsSorterPanelProps> = ({
  setLocalDimensions,
  localDimensions,
  resetDimensions,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        setLocalDimensions((items) => {
          const oldIndex = items.findIndex((dim) => dim.id === active.id)
          const newIndex = items.findIndex((dim) => dim.id === over?.id)

          return arrayMove(items, oldIndex, newIndex)
        })
      }
    },
    [setLocalDimensions],
  )

  return (
    <div
      className="ag-column-drop-vertical-list"
      data-testid="dimensions-sorter-panel"
      style={{ overflow: 'visible' }}
    >
      <div
        className="ag-column-drop-title-bar ag-column-drop-vertical-title-bar"
        style={{ display: 'flex' }}
      >
        <span
          className="ag-icon ag-icon-group ag-column-drop-icon ag-column-drop-vertical-icon"
          unselectable="on"
          role="presentation"
        ></span>
        <span className="ag-column-drop-title ag-column-drop-vertical-title">
          Group by
        </span>
        <Button
          size="small"
          variant="outlined"
          sx={{ marginLeft: 'auto' }}
          onClick={resetDimensions}
        >
          Reset
        </Button>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={localDimensions}
          strategy={verticalListSortingStrategy}
        >
          {localDimensions.map((dimension) => (
            <SortableItem key={dimension.name} dimension={dimension} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  )
}
