import React from 'react'
import { useFlagsContext } from '../../flags-context'
import { CurrencySelect } from './CurrencySelect'
import { useDataContext } from '../../data-context'
import { Maybe } from '@fintastic/shared/util/types'

export type CurrencyFieldProps = {
  value: Maybe<string>
  onChange: (newValue: string) => void
}

export const CurrencyField: React.FC<CurrencyFieldProps> = ({
  value,
  onChange,
}) => {
  const { readonly } = useFlagsContext()
  const { currenciesList } = useDataContext()

  return (
    <CurrencySelect
      value={value}
      onChange={onChange}
      disabled={readonly}
      currencies={currenciesList}
    />
  )
}
