import { useEffect } from 'react'

export function useControlledEditingMode(
  modeEnabled: boolean,
  enterEditingMode: () => void,
) {
  useEffect(() => {
    if (modeEnabled) {
      enterEditingMode()
    }
  }, [enterEditingMode, modeEnabled])
}
