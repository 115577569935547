import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import {
  usePeriodSelectorContext,
  usePeriodSelectorContextCalendarConfig,
} from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import { getPeriodSelectorDataPeriodList } from '../period-selector-container/utils/get-period-selection-data-periods-list'
/**
 * Try to assume list of periods return from API for current request
 * For 1 year aggregated by month it should 12 month ids
 */
export const usePeriodSelectorPredictedDataPeriodIds =
  (): TimeDimensionId[] => {
    // We need to use full calendar config since it is an optimistic evaluation
    // If report API returns error with base_time_dimension_id, calendar changes due to cut
    // And result differs from the original
    // So for optimistic evaluation we simply use full calendar as we don't know what is available or not
    const calendarConfig = usePeriodSelectorContextCalendarConfig({
      limitToSelectedEntitiesOnly: false,
    })
    const periodSelection = usePeriodSelectorContext()

    return useMemo(
      () => getPeriodSelectorDataPeriodList(calendarConfig, periodSelection),
      [calendarConfig, periodSelection],
    )
  }
