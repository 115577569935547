import { Maybe } from '@fintastic/shared/util/types'
import { useCallback, useMemo, useState } from 'react'
import { useLoadMetric } from './useLoadMetric'
import { Metric } from '@fintastic/web/util/metrics-and-lists'

export function useManualLoaderForMetric({
  versionId,
  metricId,
}: {
  versionId?: Maybe<string>
  metricId?: Maybe<string>
}) {
  const [enabled, setEnabled] = useState(false)

  const query = useLoadMetric(versionId, metricId, undefined, enabled)

  const handler = useCallback(async () => {
    setEnabled(true)
    return (
      await query.refetch({
        cancelRefetch: false,
      })
    )?.data as Metric
  }, [query])

  const stopWatchingStatus = useCallback(() => {
    setEnabled(false)
  }, [])

  return useMemo(
    () => ({
      load: handler,
      query,
      stopWatchingStatus,
    }),
    [handler, query, stopWatchingStatus],
  )
}
