import { ColumnType } from '@fintastic/web/util/metrics-and-lists'
import SvgIcon from '@mui/material/SvgIcon'
import {
  DimensionIcon,
  FunctionFXIcon,
  InputColumnIconIcon,
} from '@fintastic/shared/ui/icons'

export const columnTypeToIconMap: Record<ColumnType, typeof SvgIcon> = {
  input: InputColumnIconIcon,
  calculated: FunctionFXIcon,
  dimension: DimensionIcon,
}
