import {
  DimensionValueId,
  DimensionValueLabel,
  VersionCategoryDimension,
  VersionTimeDimension,
} from '../types'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import * as selectors from './common-selectors'

export const resolveValueLabel = (
  d: VersionTimeDimension | VersionCategoryDimension,
  valueId: DimensionValueId,
): Maybe<DimensionValueLabel> => toMaybe(selectors.values(d)[valueId])
