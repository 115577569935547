import { AxiosError } from 'axios'
import { Maybe } from '@fintastic/shared/util/types'

export interface FromAxiosError<TError> {
  (error: AxiosError): Maybe<TError>
}

export interface WithFromAxiosError<TError> {
  fromAxiosError: FromAxiosError<TError>
}

export const makeFromAxiosErrorFunction =
  <TError>(
    errorConstructor: new (error?: AxiosError) => TError,
    predicate: (error: AxiosError) => boolean,
  ): FromAxiosError<TError> =>
  (error: AxiosError): Maybe<TError> =>
    predicate(error) ? new errorConstructor(error) : null

export const fromAxiosErrorToFirstMatchedError = <TError>(
  error: AxiosError,
  candidates: WithFromAxiosError<TError>[],
): Maybe<TError> => {
  for (let i = 0; i < candidates.length; i++) {
    const knownError = candidates[i].fromAxiosError(error)
    if (knownError !== null) {
      return knownError
    }
  }

  return null
}
