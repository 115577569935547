import React, { useCallback, useEffect, useRef, useState } from 'react'
import { EntityFormSharedProps, EntityType } from './types'
import { NewMetricForm } from './NewMetricForm'
import { NewListForm } from './NewListForm'
import { NewReportForm } from './NewReportForm'
import { TypeField } from './TypeField'
import { NameField } from './NameField'
import { Buttons } from './Buttons'

const typeToRendererMap: Record<EntityType, React.FC<EntityFormSharedProps>> = {
  metric: NewMetricForm,
  list: NewListForm,
  report: NewReportForm,
}

export const CreateEntityForm: React.FC<{
  versionId: string
  isLiveActuals: boolean
  requestModalClose: () => void
}> = ({ versionId, isLiveActuals, requestModalClose }) => {
  const [type, setType] = useState<EntityType>('metric')
  const [name, setName] = useState('')

  const nameFieldValueRef = useRef(name)
  const [nameFieldDirty, setNameFieldDirey] = useState(false)
  useEffect(() => {
    if (name !== nameFieldValueRef.current) {
      nameFieldValueRef.current = name
      setNameFieldDirey(true)
    }
  }, [name])

  const Renderer = typeToRendererMap[type]

  const [nameInputFocusCounter, setNameInputFocusCounter] = useState(0)
  const handleNameInputAutoFocus = useCallback(() => {
    setNameInputFocusCounter((c) => c + 1)
  }, [])

  return (
    <Renderer
      name={name}
      versionId={versionId}
      isLiveActuals={isLiveActuals}
      requestModalClose={requestModalClose}
      nameFieldSlot={(params) => (
        <NameField
          {...params}
          onChange={setName}
          value={name}
          autofocus={nameInputFocusCounter < 1}
          onAutoFocus={handleNameInputAutoFocus}
          dirty={nameFieldDirty}
        />
      )}
      typeFieldSlot={({ disabled }) => (
        <TypeField type={type} setType={setType} disabled={disabled} />
      )}
      buttonsSlot={(params) => (
        <Buttons {...params} onCancel={requestModalClose} />
      )}
    />
  )
}
