import { MutationCreator } from './types'
import { getPrefixAndPostfix } from './getPrefixAndPostfix'
import { getCaretPosition } from './getCaretPosition'

// @todo add tests
export const expandFunctionLiteral: MutationCreator = (
  formula,
  literal,
  template,
  templatePosition,
) => {
  const [prefix, postfix] = getPrefixAndPostfix(
    formula,
    template,
    templatePosition,
  )
  const middle = literal.literal

  return {
    newFormula: `${prefix}${middle}()${postfix}`,
    newCaretPosition: getCaretPosition(prefix, `${middle}(`),
  }
}
