/**
 Types for describe duck state and extend root state
 */

import {
  RequestState,
  requestInitialState,
} from '@fintastic/shared/data-access/redux-extension-request'

import type { AuthUserInfo } from '../../types'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'auth' as const

// extending root state to use it in selectors
export type RootState = {
  [namespace]: State
}

// duck state
export type State = RequestState & {
  userInfo?: AuthUserInfo
}

export const initialState: State = {
  ...requestInitialState,
}
