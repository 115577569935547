import { agGridNumericEditorDefaultCurrencyMask } from '@fintastic/shared/ui/ag-grid'
import {
  createValueFormatter,
  MetricNumericDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { ValueFormatterParams } from 'ag-grid-community'
import { formatNumeric } from '@fintastic/shared/util/formatters'
import { applyValueFormatter } from '@fintastic/shared/ui/grid-framework'
import { GetCellEditorProps } from './types'

export const getCurrencyCellEditorProps = (
  column: ListColumnWrapper,
): GetCellEditorProps => {
  const dataType = column.dataType()

  const displaySettings = column.displaySettings().unwrap()

  const currency = currencies.find(
    (c) => c.code === column.displaySettings().currency(),
  )

  const valueFormatter = displaySettings
    ? createValueFormatter(dataType as never, displaySettings, currency) ||
      undefined
    : (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          formatNumeric.currency(value || 0),
        )

  return {
    valueFormatter,

    cellEditorParams: {
      mask: displaySettings
        ? undefined
        : agGridNumericEditorDefaultCurrencyMask,
      dataType: dataType as MetricNumericDataValueType,
      displaySettings: displaySettings,
      currency: currency,
    },
  }
}
