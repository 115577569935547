import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { useMemo } from 'react'

export const useAvailableSettingsList = (): [
  enabled: boolean,
  available: AvailableSettings,
] => {
  const permissions = Boolean(useRoleLevelAccess(['power_user', 'admin']))
  const dimensions = Boolean(
    useRoleLevelAccess(['power_user', 'modeler', 'admin']),
  )

  const settingsEnabled = permissions || dimensions

  return [
    settingsEnabled,
    useMemo(
      () => ({
        permissions,
        dimensions,
      }),
      [permissions, dimensions],
    ),
  ]
}

export type AvailableSettings = {
  permissions: boolean
  dimensions: boolean
}
