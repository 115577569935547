import React from 'react'
import { LabelTagsProps } from './types'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import { LabelTagsContainer } from './LabelTagsContainer'

export const LabelTags: React.FC<LabelTagsProps> = (props) => (
  <ErrorBoundary>
    <LabelTagsContainer {...props} />
  </ErrorBoundary>
)
