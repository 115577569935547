import { axios } from '@fintastic/web/data-access/service-axios'
import { BoardFolder } from '../types'

const baseURL = `${axios.defaults.baseURL}/planning/api/v1/board_folders`

export type GetFoldersResponse = {
  result: BoardFolder[]
}

export function getFolders() {
  return axios.get<GetFoldersResponse>('/', {
    baseURL,
  })
}

export type EditableFolderParams = {
  name: string
}

export function createFolder(params: EditableFolderParams) {
  return axios.post<BoardFolder>('/', params, {
    baseURL,
  })
}

export function updateFolder(
  folderId: BoardFolder['id'],
  params: EditableFolderParams,
) {
  return axios.patch<BoardFolder>(`/${folderId}/`, params, {
    baseURL,
  })
}

export function deleteFolder(folderId: BoardFolder['id']) {
  return axios.delete(`/${folderId}/`, {
    baseURL,
  })
}
