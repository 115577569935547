import { createContext, useContext } from 'react'
import { ChartSettings } from '@fintastic/web/feature/charts'
import { Maybe } from '@fintastic/shared/util/types'

export type ChartSettingsContextValue = {
  widgetId: string
  loading?: boolean
  metricId: string
  versions: string[]
  initialChartSettings: ChartSettings
  referenceSettings?: ChartSettings
  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<ChartSettings>,
  ) => void
}

export const ChartSettingsContext = createContext<ChartSettingsContextValue>({
  widgetId: '',
  metricId: '',
  versions: [],
  initialChartSettings: {},
  loading: true,
})

export function useChartSettingsContext() {
  return useContext(ChartSettingsContext)
}
