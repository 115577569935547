import { Box, ButtonBase, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledVersionButton = styled(ButtonBase, {
  shouldForwardProp: (p) => p !== 'opened',
})<{ opened: boolean }>`
  ${styledMixins.alignContentCenter()};
  padding: 4px 4px 4px 8px;
  height: 32px;
  margin-right: 8px;

  white-space: nowrap;

  border-radius: ${({ theme }) => theme.shape.borderRadius / 2}px;
  transition: ${({ theme }) => theme.transitions.create('all')};

  background: ${({ theme, opened }) =>
    opened ? theme.palette.action.focus : 'transparent'};

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  svg {
    transition: ${({ theme }) => theme.transitions.create('all')};
    transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'none')};
  }
`

export const StyledVersionButtonText = styled('span')`
  ${styledMixins.alignContentCenter()};

  .MuiTypography-root {
    line-height: 1.143;
  }
`

export const StyledVersionButtonIcon = styled('span')`
  ${styledMixins.alignContentCenter()};

  margin-left: 4px;
`

export const StyledVersionContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-right: 8px;
`

export const StyledApplyButtonContainer = styled(Box)`
  padding: 0 16px 16px;
`
