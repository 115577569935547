import React from 'react'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'

type SharedProps = {
  goBack: () => void
  goToDimension: (id: string) => void
}

const Editable: React.FC<
  SharedProps & {
    dimensions: Array<{
      id: DimensionId
      label: string
      visible: boolean
      linked: boolean
      error: Maybe<string>
    }>
    availableDimensions: Array<{
      id: DimensionId
      label: string
    }>
    addDimension: (dimId: DimensionId) => void
    reorderDimensions: (dimIds: DimensionId[]) => void
    toggleDimensionVisibility: (dimId: DimensionId) => void
  }
> = () => <div>Editable</div>

const Readonly: React.FC<
  SharedProps & {
    dimensions: Array<{
      id: DimensionId
      label: string
      visible: boolean
      linked: boolean
    }>
  }
> = () => <div>Readonly</div>

export const DimensionsView = {
  Editable,
  Readonly,
} as const
