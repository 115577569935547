import { StyledRoot } from './CreatedAtHeaderRenderer.styled'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import React, { memo } from 'react'

export const CreatedAtHeaderRenderer: React.FC<{
  orderingDesc: boolean
}> = memo(({ orderingDesc }) => (
  <StyledRoot>
    Date & Time{' '}
    {orderingDesc ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
  </StyledRoot>
))
