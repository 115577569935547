import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { BoardShareForm } from '../../BoardShare'
import { useUserListQuery } from '@fintastic/web/data-access/iam'
import { Box, CircularProgress } from '@mui/material'
import { StyledBoardShareLoading } from './BoardShare.styled'
import { useGroups } from '../../../hooks/useLoadGroups'
import { useLoadAccessForBoard } from '../../../hooks/useLoadAccessForBoardsList'
import { FormLayout } from '@fintastic/shared/ui/form-framework'

export type BoardShareModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  boardId: string
  boardName?: Maybe<string>
}

export const BoardShareModal: React.FC<BoardShareModalProps> = (props) => {
  const { isOpen, onRequestClose, boardId, boardName } = props

  const {
    data: usersListData,
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess,
  } = useUserListQuery()

  const {
    data: groupListData,
    isLoading: isGroupsLoading,
    isSuccess: isGroupsSuccess,
  } = useGroups()

  const {
    data: boardAccessData,
    isLoading: isBoardAccessLoading,
    isSuccess: isBoardAccessSuccess,
  } = useLoadAccessForBoard(boardId)

  const isLoading = isUsersLoading || isGroupsLoading || isBoardAccessLoading
  const isError =
    !isLoading && (!isUsersSuccess || !isGroupsSuccess || !isBoardAccessSuccess)
  const hasDataReady =
    !isLoading &&
    !isError &&
    boardId &&
    !!groupListData &&
    !!usersListData &&
    !!boardAccessData

  return (
    <Modal
      open={isOpen}
      width={600}
      onRequestClose={onRequestClose}
      title="Share Board"
      description={isLoading ? 'Loading...' : boardName || 'board'}
      data-testid="board-share-modal-root"
    >
      {isLoading && (
        <StyledBoardShareLoading>
          <CircularProgress />
        </StyledBoardShareLoading>
      )}

      {isError && (
        <FormLayout onSubmit={onRequestClose} submitButtonText={'Close'}>
          <Box sx={{ mt: 4, mb: 4 }}>
            Some error with getting sharing data. Contact support, please.
          </Box>
        </FormLayout>
      )}

      {hasDataReady && (
        <BoardShareForm
          closeParentModal={onRequestClose}
          selectedBoard={boardId}
          boardName={boardName || 'Selected board'}
          users={usersListData || []}
          groups={groupListData || []}
          remoteAccess={boardAccessData || {}}
        />
      )}
    </Modal>
  )
}
