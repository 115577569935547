import React, { useCallback, useMemo } from 'react'
import { EntityFormSharedProps } from './types'
import { Box } from '@mui/material'
import { useLoadListOfReports } from '@fintastic/web/data-access/generic-report'
import { validateEntityName } from './utils/validation'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'

export const NewReportForm: React.FC<EntityFormSharedProps> = ({
  name,
  nameFieldSlot,
  typeFieldSlot,
  buttonsSlot,
  versionId,
  requestModalClose,
}) => {
  const existingReportsQuery = useLoadListOfReports(versionId)

  const existingNames = useMemo(
    () =>
      Object.fromEntries(
        (existingReportsQuery.data || []).map(({ name }) => [name, true]),
      ),
    [existingReportsQuery.data],
  )

  const nameValidation = useMemo(
    () => validateEntityName(name, (n) => !existingNames[n]),
    [existingNames, name],
  )

  const reportEditorApi = useReportEditorApi()

  const handleSubmit = useCallback(() => {
    if (!reportEditorApi || reportEditorApi.active) {
      return
    }
    reportEditorApi.startCreationOperationApi.mutateAsync(
      {
        name,
      },
      {
        onSuccess: () => {
          requestModalClose()
        },
      },
    )
  }, [reportEditorApi, name, requestModalClose])

  if (existingReportsQuery.isLoading) {
    return <CenteredCircularProgress fullScreen={false} />
  }

  const isLoading =
    reportEditorApi?.active === false &&
    reportEditorApi.startCreationOperationApi.isLoading

  return (
    <Box>
      <Box>
        {nameFieldSlot({
          isValid: nameValidation === null,
          validationMessage: nameValidation || '',
          disabled: isLoading,
        })}
      </Box>
      <Box mt={2}>
        {typeFieldSlot({
          disabled: isLoading,
        })}
      </Box>
      <Box mt={3}>
        {buttonsSlot({
          continueEnabled: nameValidation === null,
          isSubmitting: isLoading,
          onContinue: handleSubmit,
        })}
      </Box>
    </Box>
  )
}
