export class CellIsNotEditableError extends Error {
  constructor(e: string) {
    super(e)
    this.name = 'CellIsNotEditableError'
    this.stack = e
    Object.setPrototypeOf(this, CellIsNotEditableError.prototype)
  }
}

export class ColumnIsUnavailableError extends Error {
  constructor(e: string) {
    super(e)
    this.name = 'ColumnIsUnavailableError'
    this.stack = e
    Object.setPrototypeOf(this, ColumnIsUnavailableError.prototype)
  }
}

export class CellIsAggregatedError extends Error {
  constructor(e: string) {
    super(e)
    this.name = 'CellIsAggregatedError'
    this.stack = e
    Object.setPrototypeOf(this, CellIsAggregatedError.prototype)
  }
}
