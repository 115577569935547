import { HistoryLogEntry } from './types'
import dayjs from 'dayjs'
import { resolveHistoryLogActionLabel } from './const'

type Entry = HistoryLogEntry

const getTimestamp = (entry: Entry): string =>
  dayjs(entry.created_at).format('DD-MMM-YYYY h:mm A')

const getTimestampShortDate = (entry: Entry): string =>
  dayjs(entry.created_at).format('DD-MMM-YYYY')

const getTimestampShortTime = (entry: Entry): string =>
  dayjs(entry.created_at).format('h:mm A')

const getTimestampGmtTimeZone = (entry: Entry): string =>
  dayjs(entry.created_at).format('z')

const getActionLabel = (entry: Entry): string =>
  resolveHistoryLogActionLabel(entry.action)

export const historyLogEntryAdt = {
  getTimestamp,
  getTimestampShortDate,
  getTimestampShortTime,
  getTimestampGmtTimeZone,
  getActionLabel,
} as const
