import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

export function getCurrencyForDataType(
  dataType: MetricConfigurableDataValueType,
  currency: Maybe<string>,
  defaultCurrency: string,
): Maybe<string> {
  let newCurrency = currency

  if (dataType !== 'currency') {
    newCurrency = null
  } else if (currency === null) {
    newCurrency = defaultCurrency
  }

  return newCurrency
}
