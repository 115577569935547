/** @deprecated */
export const combination = (arr: any[], numOfCombos: number): any[] => {
  let i, j, combs, head, tailcombs

  // prevent a situation when the numOfCombos is negetive or greater than the arr length
  if (numOfCombos > arr.length) {
    return []
  }
  if (numOfCombos <= 0) {
    return []
  }

  if (numOfCombos === arr.length) {
    return [arr]
  }

  // There is N 1-sized subsets in a N-sized arr.
  if (numOfCombos === 1) {
    combs = []
    for (i = 0; i < arr.length; i++) {
      combs.push([arr[i]])
    }
    return combs
  }

  combs = []
  for (i = 0; i < arr.length - numOfCombos + 1; i++) {
    // array with the first element
    head = arr.slice(i, i + 1)
    // smaller combinations from the subsequent elements
    tailcombs = combination(arr.slice(i + 1), numOfCombos - 1)
    // For each (numOfCombos-1)-combination we join it with the current
    // and store it to the arr of numOfCombos-combinations.
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]))
    }
  }
  return combs
}
