import React, { CSSProperties } from 'react'
import { StyledIconContainer } from './VersionLockUnlockIcon.styled'
import { LockedVersionIcon } from './LockedVersionIcon'
import { UnlockedVersionIcon } from './UnlockedVersionIcon'
import { VersionLockedForEditIcon } from './VersionLockedForEditIcon'

export type VersionIconProps = {
  isLocked?: boolean
  editDisabled?: boolean
  disabled?: boolean
  sx?: CSSProperties
  size?: number
}

export const VersionLockUnlockIcon: React.FC<VersionIconProps> = ({
  isLocked,
  editDisabled,
  disabled,
  sx,
  size = 20,
}) => {
  if (editDisabled) {
    return <VersionLockedForEditIcon sx={sx} size={size} />
  }

  return (
    <StyledIconContainer
      isLocked={isLocked === true}
      disabled={disabled === true}
      size={size}
      sx={{ ...sx }}
    >
      {isLocked ? <LockedVersionIcon /> : <UnlockedVersionIcon />}
    </StyledIconContainer>
  )
}
