// all supported uoms
const ALL_UOMS = ['ea', 'usd', 'percentage'] as const

export type ValueUom = typeof ALL_UOMS[number]

export const getUOM = (source: ValueUom | string): ValueUom => {
  const lowercase = source?.toLocaleLowerCase() || ''

  if (ALL_UOMS.includes(lowercase as ValueUom)) {
    return lowercase as ValueUom
  }

  if (lowercase === 'num' || lowercase === 'number') {
    return 'ea'
  }

  return 'usd'
}
