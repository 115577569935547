import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import {
  ChartType,
  chartTypeKeys,
  chartTypes,
  defaultChartType,
  isChartType,
} from '@fintastic/web/feature/charts'
import { chartIcons } from '../../../../icons'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'
import { StyledTextWithIcon, StyledTextWithIconField } from '../common-styles'
import { SxProps } from '@mui/system'

export type ChartTypeSelectProps = {
  chartType: ChartType
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
  containerSx?: SxProps
}

export const ChartTypeSelect: React.FC<ChartTypeSelectProps> = memo(
  ({ chartType, onChangeChartSettings, disabled, containerSx }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartType(e.target.value)) {
          onChangeChartSettings({ type: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true} containerSx={containerSx}>
        <StyledTextWithIconField
          id="chart-type-select"
          fullWidth
          value={chartType}
          size="small"
          select
          label="Chart type"
          defaultValue={defaultChartType}
          onChange={handleSelect}
          disabled={disabled}
        >
          {chartTypeKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithIcon data-testid={`chart-type-item-${ct}`}>
                {chartIcons[ct]} {chartTypes[ct].name}
              </StyledTextWithIcon>
            </MenuItem>
          ))}
        </StyledTextWithIconField>
      </FormField>
    )
  },
)
