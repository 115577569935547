/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { RootState, State } from './state'
import type { Selector } from 'reselect'
import { namespace } from './state'
import { ColumnMaskConfig, DimensionMap, TableAccess } from '../types'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s[namespace]

export const selectTableAccessList: Selector<
  RootState,
  State['tableAccessList']
> = (s) => selectAll(s).tableAccessList ?? []

export const selectTableAccessListForCurrentGroup: Selector<
  RootState,
  TableAccess[]
> = (s) => selectTableAccessList(s)[selectSelectedGroup(s)] || []

export const selectDefaultDimensionConfig: Selector<
  RootState,
  State['defaultDimensionConfig']
> = (s) => selectAll(s).defaultDimensionConfig ?? {}

export const selectPolicyHash: Selector<RootState, State['policyHash']> = (s) =>
  selectAll(s).policyHash ?? ''

export const selectDimensionAccessForCurrentGroup: Selector<
  RootState,
  DimensionMap
> = (s) => selectAll(s).defaultDimensionConfig[selectSelectedGroup(s)]

export const selectSelectedGroup: Selector<
  RootState,
  State['selectedGroup']
> = (s) => selectAll(s).selectedGroup ?? ''

export const selectAllGroups: Selector<RootState, State['groups']> = (s) =>
  selectAll(s).groups ?? []

export const selectChanges: Selector<RootState, State['changes']> = (s) =>
  selectAll(s).changes ?? []

export const selectColumnMasking: Selector<
  RootState,
  State['columnMasking']
> = (s) => selectAll(s).columnMasking

export const selectColumnMaskConfigForCurrentGroup: Selector<
  RootState,
  ColumnMaskConfig[]
> = (s) => selectColumnMasking(s)[selectSelectedGroup(s)] || []

export const selectSaveState: Selector<RootState, boolean> = (s) =>
  selectAll(s).saving

export const selectLoadingState: Selector<RootState, boolean> = (s) =>
  selectAll(s).loading
