import {
  DeleteListIntent,
  DeleteMetricIntent,
  useCalculationRequest,
} from '@fintastic/web/data-access/calc'
import { useCallback, useMemo } from 'react'
import { useModalWithParamsState } from '@fintastic/shared/util/modal'
import { Maybe } from '@fintastic/shared/util/types'
import {
  useLoadVersionDependencyGraph,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import {
  DependencyGraphWrapper,
  VersionEntitiesWrapper,
} from '@fintastic/web/util/versions'
import { ConfirmationModal } from './ConfirmationModal'
import { DependenciesModal } from './DependenciesModal'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { entityTypeToDeleteAction, entityTypeToTypeLabel } from './mapping'
import { ConfirmationModalParams, DependenciesModalParams } from './types'
import { routeRequestEntityDeletion } from './utils'
import { filterMapNullable } from '@fintastic/shared/util/functional-programming'
import { idLooksLikeReport } from '@fintastic/web/util/generic-report'
import { MetricOrListType } from '@fintastic/web/util/metrics-and-lists'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'

type Callbacks = {
  onSuccess: () => void
}

export const useDeleteEntity = (versionId: string, callbacks: Callbacks) => {
  const versionDependencyGraph = useLoadVersionDependencyGraph(versionId)
  const entitiesQuery = useLoadVersionEntities(versionId)
  const dataIsLoading =
    versionDependencyGraph.isLoading || entitiesQuery.isLoading

  const reportEditorApi = useReportEditorApi()
  const deleteReport = reportEditorApi?.deleteOperationApi.mutateAsync

  const depsGraph = useMemo(
    () =>
      !versionDependencyGraph.data
        ? null
        : new DependencyGraphWrapper(versionDependencyGraph.data),
    [versionDependencyGraph.data],
  )
  const entities = useMemo(
    () =>
      !entitiesQuery.data
        ? null
        : new VersionEntitiesWrapper(versionId, entitiesQuery.data),
    [entitiesQuery.data, versionId],
  )

  const dependenciesModal = useModalWithParamsState<
    Maybe<DependenciesModalParams>
  >({
    initialParams: null,
  })
  const confirmationModal = useModalWithParamsState<
    Maybe<ConfirmationModalParams>
  >({
    initialParams: null,
  })

  const handleDeletionSucceed = useCallback(() => {
    toast.success(
      `${
        !confirmationModal.params?.entity.type
          ? ''
          : entityTypeToTypeLabel[confirmationModal.params?.entity.type] || ''
      }${
        confirmationModal.params?.entity
          ? ` "${confirmationModal.params?.entity.label}"`
          : ''
      } successfully deleted.`,
    )
    callbacks.onSuccess()
    confirmationModal.close()
  }, [callbacks, confirmationModal])

  const handleDeletionFailed = useCallback(() => {
    toast.error(
      `Failed to delete ${confirmationModal.params?.entity.type}${
        confirmationModal.params?.entity
          ? ` "${confirmationModal.params?.entity.label}"`
          : ''
      }. Try again or contact support.`,
    )
  }, [confirmationModal])

  const { calculating: deleting, query } = useCalculationRequest(
    versionId,
    handleDeletionSucceed,
    handleDeletionFailed,
  )

  const requestEntityDeletion = useCallback(
    (entityId: string, entityType?: MetricOrListType | 'report') => {
      if (entityType === 'report') {
        deleteReport?.({ reportId: entityId })
        return
      }

      if (deleting || !entities || !depsGraph) {
        return
      }

      const result = routeRequestEntityDeletion(entityId, {
        entities,
        graph: depsGraph,
        openDepsModal: dependenciesModal.openWithParams,
        openConfirmationModal: confirmationModal.openWithParams,
      })

      if (result instanceof Error) {
        console.error(result)
      }
    },
    [
      deleting,
      entities,
      depsGraph,
      dependenciesModal.openWithParams,
      confirmationModal.openWithParams,
      deleteReport,
    ],
  )

  const successors = useMemo(
    () =>
      filterMapNullable(dependenciesModal.params?.successorsIds || [], (id) => {
        if (idLooksLikeReport(id)) {
          return {
            id,
            label: id.slice('Report.'.length),
            type: 'report' as const,
          }
        }

        return entities?.findEntityById(id)
      }),
    [dependenciesModal.params?.successorsIds, entities],
  )

  const lists = useMemo(() => {
    if (!entities) {
      return []
    }
    return entities.lists
  }, [entities])

  const deleteEntity = useCallback(() => {
    if (!confirmationModal.params) {
      return
    }
    query.mutate({
      action: !confirmationModal.params.entity.type
        ? 'delete:metric'
        : entityTypeToDeleteAction[confirmationModal.params.entity.type],
      payload: {
        object_id: confirmationModal.params.entity.id,
      },
    } as DeleteListIntent | DeleteMetricIntent)
  }, [confirmationModal, query])

  const modals = useMemo(
    () => (
      <>
        <DependenciesModal
          isOpen={dependenciesModal.isOpen}
          onClose={dependenciesModal.close}
          successors={successors}
          weightsSuccessors={
            dependenciesModal.params?.weightMetricSuccessorsIds || []
          }
          entityType={dependenciesModal.params?.entity.type || 'metric'}
          lists={lists}
          lastColumnInList={!!dependenciesModal.params?.lastColumnInList}
        />
        <ConfirmationModal
          isOpen={confirmationModal.isOpen}
          onClose={confirmationModal.close}
          onConfirm={deleteEntity}
          deleting={deleting || query.isLoading}
          entityType={confirmationModal.params?.entity.type || 'metric'}
          lastColumnInList={!!confirmationModal.params?.lastColumnInList}
        />
      </>
    ),
    [
      dependenciesModal.isOpen,
      dependenciesModal.close,
      dependenciesModal.params?.weightMetricSuccessorsIds,
      dependenciesModal.params?.entity.type,
      dependenciesModal.params?.lastColumnInList,
      successors,
      lists,
      confirmationModal.isOpen,
      confirmationModal.close,
      confirmationModal.params?.entity.type,
      confirmationModal.params?.lastColumnInList,
      deleteEntity,
      deleting,
      query.isLoading,
    ],
  )

  return useMemo(
    () => ({
      modals,
      requestEntityDeletion,
      dataIsLoading,
      deletionIsInProgress:
        deleting ||
        query.isLoading ||
        !!reportEditorApi?.deleteOperationApi.isLoading,
    }),
    [
      modals,
      requestEntityDeletion,
      dataIsLoading,
      deleting,
      query.isLoading,
      reportEditorApi?.deleteOperationApi.isLoading,
    ],
  )
}
