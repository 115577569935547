import { styled, ButtonBase } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledContextMenuButtonRoot = styled(ButtonBase)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  'justifyContent': 'flex-start',

  'paddingTop': theme.spacingFromPixels(3),
  'paddingBottom': theme.spacingFromPixels(3),
  'paddingRight': theme.spacingFromPixels(8),
  'paddingLeft': theme.spacingFromPixels(24),

  'width': '100%',
  'minHeight': 40,

  'textAlign': 'left',
  'fontSize': '1rem',
  'fontWeight': 500,
  'fontFamily': theme.typography.fontFamily,

  'transition': `background ${theme.transitions.duration.standard}ms`,

  '&.Mui-focusVisible': {
    background: theme.palette.primary.light,
  },

  '&:hover': {
    background: theme.palette.primary.light,
  },
}))
