import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  EditableList,
  getListForEditMode,
  useCancelEditing,
  useCurrentEditingFlow,
  useEditingList,
  useListColumnsActions,
  useListsActions,
  useSaveList,
} from '@fintastic/web/data-access/metrics-and-lists'
import { makeApi as makeActiveApi } from '../../features/active/makeApi'
import { makeApi as makeInactiveApi } from '../../features/inactive/makeApi'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { ListEditorApiContext } from '../../context/list-editor-api-context'
import { ImmutableCalculationProgressEvent } from '@fintastic/web/data-access/calc'
import { useSubscribeToCalculationProgressEvent } from '@fintastic/web/data-access/service-pusher'
import { useOperationState } from '@fintastic/shared/util/operation'
import {
  useLoadVersion,
  useLockVersionForUser,
  useManuallyLoadVersionEntities,
  useReleaseVersionUserLocker,
} from '@fintastic/web/data-access/versions'
import { useModalState } from '@fintastic/shared/util/modal'
import { CancelListEditingModal } from './modals/CancelListEditingModal'
import { useHiddenColumnsLocalStorage } from '@fintastic/web/feature/lists'
import { toMaybe } from '@fintastic/shared/util/types'
import {
  ColumnsFormulaStateApi,
  ListFormulaStateApi,
} from '../../features/shared-types'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'

const displayError = toast.error
const displaySuccess = toast.success

export type ListEditorProviderProps = {
  versionId: string
  isVersionEditable: boolean
  isLiveActuals: boolean
  children: React.ReactNode
}

export const ListEditorProvider: React.FC<ListEditorProviderProps> = ({
  versionId,
  isVersionEditable,
  children,
  isLiveActuals,
}) => {
  const isUserAllowedToEditList = Boolean(
    useRoleLevelAccess(['power_user', 'modeler']),
  )

  const legacyFlow = useCurrentEditingFlow()
  const isActive = legacyFlow.type === 'list' && legacyFlow.flow !== null
  const isNewList = legacyFlow.flow === 'creation'
  const isValid = legacyFlow.isValid
  const list = useEditingList()
  const cancelEditing = useCancelEditing()
  const { startEditing, changeFormulaState, changeFormula } = useListsActions()
  const {
    changeFormulaState: changeColumnFormulaState,
    changeFormula: changeColumnFormula,
  } = useListColumnsActions()
  const [, setLocalHiddenColumns] = useHiddenColumnsLocalStorage(
    versionId || '',
    list?.id || '',
  )

  const listFormulaStateApi = useMemo<ListFormulaStateApi>(
    () => ({
      error: legacyFlow.formulaError,
      changeFormula: (formula, valid) =>
        changeFormula({ formula, invalid: !valid }),
      setValidationState: (payload) =>
        changeFormulaState({
          valid: payload.valid,
          error: !payload.valid ? payload.error : undefined,
        }),
    }),
    [changeFormula, changeFormulaState, legacyFlow.formulaError],
  )

  const columnsFormulaStateApi = useMemo<ColumnsFormulaStateApi>(
    () => ({
      errors: legacyFlow.columnsFormulaErrors,
      changeFormula: (metricId, formula, valid) =>
        changeColumnFormula({ metricId, formula, invalid: !valid }),
      setValidationState: (metricId, payload) =>
        changeColumnFormulaState({
          metricId,
          valid: payload.valid,
          error: !payload.valid ? payload.error : undefined,
        }),
    }),
    [
      changeColumnFormula,
      changeColumnFormulaState,
      legacyFlow.columnsFormulaErrors,
    ],
  )

  const createdEventSubscriptionApi = useCreatedEventListenersRegistry()

  const editOperationStateApi = useOperationState()
  const cancelOperationStateApi = useOperationState()
  const saveOperationStateApi = useOperationState()

  const cancelModalState = useModalState()

  const { mutateAsync: saveList } = useSaveList(versionId)
  const { mutateAsync: lockVersion } = useLockVersionForUser(versionId)
  const { mutateAsync: unlockVersion } = useReleaseVersionUserLocker(versionId)
  const loadVersionEntities = useManuallyLoadVersionEntities()
  const versionMetadataQuery = useLoadVersion(isActive ? versionId : null)

  const calcEventApi = useCalcEvent(
    versionId,
    useCallback(
      (_, stopWatching) => {
        saveOperationStateApi.finish(true)
        stopWatching()
        cancelEditing()
        createdEventSubscriptionApi.apply(list?.id || '')
        if (!isNewList) {
          setLocalHiddenColumns(
            list?.metrics
              .filter((m) => !m.metadata.display_config.is_visible)
              .map(({ id }) => id) || [],
          )
        }
        displaySuccess(isNewList ? 'List created' : 'List saved')
        unlockVersion()
      },
      [
        cancelEditing,
        createdEventSubscriptionApi,
        isNewList,
        list?.id,
        list?.metrics,
        saveOperationStateApi,
        setLocalHiddenColumns,
        unlockVersion,
      ],
    ),
    useCallback(
      (event, stopWatching) => {
        saveOperationStateApi.finish(false)
        stopWatching()
        displayError(
          isNewList ? 'Failed to create list' : 'Failed to save list',
        )
        if (!event.errorMessage) {
          return
        }
        if (event.errorCausedByEntity(list?.id || '')) {
          changeFormulaState({
            valid: false,
            error: event.errorMessage,
          })
        }

        if (event.errorCausedByListColumn(list?.id || '')) {
          changeColumnFormulaState({
            metricId: event.errorDetails?.object_id as string,
            valid: false,
            error: event.errorMessage,
          })
        }
      },
      [
        changeColumnFormulaState,
        changeFormulaState,
        isNewList,
        list?.id,
        saveOperationStateApi,
      ],
    ),
  )

  const api = useMemo(
    () =>
      isActive
        ? makeActiveApi(
            {
              isNew: isNewList,
              list: list as EditableList,
              isValid,
              listFormulaStateApi,
              columnsFormulaStateApi,
              cancelOperationStateApi,
              saveOperationStateApi,
            },
            {
              displayError,
              displaySuccess,
              unlockVersion,
              saveList,
              startWaitingForCalcEvent: calcEventApi.startWatching,
              stopWaitingForCalcEvent: calcEventApi.stopWatching,
              requestCancelConfirmation: cancelModalState.open,
              cancelEditing,
              subscribeToCreatedEvent: createdEventSubscriptionApi.subscribe,
              versionMetadata: toMaybe(versionMetadataQuery.data),
            },
            {
              isLiveActuals,
            },
          )
        : makeInactiveApi(
            {
              editOperationStateApi,
            },
            {
              displayError,
              lockVersion,
              unlockVersion,
              loadListMetadata: (listId) =>
                getListForEditMode(versionId, listId),
              loadVersionEntities: () => loadVersionEntities(versionId),
              startEditing: (list) => startEditing({ list }),
              isUserAllowedToEditList,
            },
            {
              isVersionEditable,
            },
          ),
    [
      isActive,
      isNewList,
      list,
      isValid,
      listFormulaStateApi,
      columnsFormulaStateApi,
      cancelOperationStateApi,
      saveOperationStateApi,
      unlockVersion,
      saveList,
      calcEventApi.startWatching,
      calcEventApi.stopWatching,
      cancelModalState.open,
      cancelEditing,
      createdEventSubscriptionApi.subscribe,
      versionMetadataQuery.data,
      isLiveActuals,
      editOperationStateApi,
      lockVersion,
      isUserAllowedToEditList,
      isVersionEditable,
      versionId,
      loadVersionEntities,
      startEditing,
    ],
  )

  return (
    <ListEditorApiContext.Provider value={api}>
      {children}
      {api.active && (
        <CancelListEditingModal
          isOpen={cancelModalState.isOpen}
          onRequestClose={cancelModalState.close}
          onConfirm={() => api.cancelEditing.run(true)}
        />
      )}
    </ListEditorApiContext.Provider>
  )
}

const useCalcEvent = (
  versionId: string,
  onSuccess: (
    event: ImmutableCalculationProgressEvent,
    stopWatching: () => void,
  ) => void,
  onError: (
    event: ImmutableCalculationProgressEvent,
    stopWatching: () => void,
  ) => void,
) => {
  const [watching, setWatching] = useState<boolean>(false)

  const startWatching = useCallback(() => {
    setWatching(true)
  }, [])

  const stopWatching = useCallback(() => {
    setWatching(false)
  }, [])

  useSubscribeToCalculationProgressEvent(
    [versionId],
    useCallback(
      async (event) => {
        if (event.successful()) {
          onSuccess(event, stopWatching)
        }
        if (event.failed()) {
          onError(event, stopWatching)
        }
      },
      [onSuccess, onError, stopWatching],
    ),
    watching,
  )

  return useMemo(
    () =>
      ({
        startWatching,
        stopWatching,
      } as const),
    [startWatching, stopWatching],
  )
}

const useCreatedEventListenersRegistry = () => {
  type Callback = (listId: string) => void

  const registry = useRef<Array<Callback>>([])

  const subscribe = useCallback((cb: Callback) => {
    if (!registry.current.includes(cb)) {
      registry.current.push(cb)
    }
    return () => {
      registry.current = registry.current.filter((item) => item !== cb)
    }
  }, [])

  const apply = useCallback((listId: string) => {
    registry.current.forEach((cb) => {
      cb(listId)
    })
  }, [])

  return useMemo(
    () =>
      ({
        subscribe,
        apply,
      } as const),
    [apply, subscribe],
  )
}
