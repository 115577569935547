import type { Maybe, Uuid } from '@fintastic/shared/util/types'
import { useQueries } from 'react-query'
import { useEffect, useMemo, useRef } from 'react'
import {
  getMetric,
  isOriginallyEmpty,
  metricsAndListsCacheKeys,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useLoadMultipleVersionsEntities } from '@fintastic/web/data-access/versions'
import { enrichMetricDimensionWithEntities } from '../utils/enrich-metric-dimensions-with-entities'
import isEqual from 'lodash/isEqual'
import { AxiosError } from 'axios'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { PeriodSelection } from '@fintastic/web/util/period-selector'

export type MetricInVersionLoadingResult = {
  error: Maybe<AxiosError | Error>
  version: string
  metric: Maybe<Metric>
  originallyEmpty: boolean
}

export function useMetricInVersions(
  versions: Uuid[],
  metric: Uuid,
  enabled = true,
  periodSelection: PeriodSelection,
) {
  const { data: entitiesData } = useLoadMultipleVersionsEntities(
    versions,
    enabled,
  )

  const entitiesDataRef = useRef(entitiesData)
  const entitiesUpdatedKeyRef = useRef(0)
  const entitiesUpdatedKey = useMemo(() => {
    if (
      !isEqual(
        entitiesData.map((d) => d.entities?.dimensions),
        entitiesDataRef.current.map((d) => d.entities?.dimensions),
      )
    ) {
      entitiesDataRef.current = entitiesData
      entitiesUpdatedKeyRef.current += 1
    }
    return entitiesUpdatedKeyRef.current
  }, [entitiesData])

  const queries = useQueries(
    versions.map((version) => ({
      queryKey: metricsAndListsCacheKeys.metric(
        version,
        metric,
        periodSelection,
      ),
      queryFn: async () => {
        const response = await getMetric(version, metric, periodSelection)
        return {
          ...response?.data,
          _originallyEmpty: isOriginallyEmpty(response.headers),
        }
      },
      retry: false,
      staleTime: Infinity,
      enabled,
    })),
  )

  const queryRef = useRef(queries)
  queryRef.current = queries

  const enabledRef = useRef(enabled)
  enabledRef.current = enabled

  const refetchPeriodSelectionKey = useMemo(
    () => JSON.stringify(periodSelection),
    [periodSelection],
  )

  useEffect(() => {
    if (!enabledRef.current) {
      return
    }
    queryRef.current.forEach((q) => {
      if (!q.isFetching) {
        q.remove()
      }
    })
  }, [refetchPeriodSelectionKey, versions.join(';')])

  const loadingCount = queries.filter((query) => query.isLoading).length
  const fetchingCount = queries.filter((query) => query.isFetching).length
  const memoKey = queries
    .map(
      (query, queryIndex) =>
        `${versions[queryIndex]}-${metric}-${JSON.stringify(periodSelection)}-${
          query.dataUpdatedAt
        }-${query.errorUpdatedAt}`,
    )
    .join(';')

  return useMemo(
    () =>
      ({
        isLoading: loadingCount !== 0 || !entitiesDataRef.current,
        isFetching: fetchingCount !== 0,
        metricsWithVersion:
          loadingCount === 0
            ? queries.map(
                (query, queryIndex) =>
                  ({
                    version: versions[queryIndex],
                    metric: query.data
                      ? // @todo remove dimensions metadata usage
                        enrichMetricDimensionWithEntities(
                          query.data,
                          entitiesDataRef.current.find(
                            (i) => i.versionId === versions[queryIndex],
                          )?.entities?.dimensions || null,
                        )
                      : null,
                    originallyEmpty: Boolean(query.data?._originallyEmpty),
                    error: query.error || null,
                  } as MetricInVersionLoadingResult),
              )
            : [],
      } as const),
    // useQueries returns a new array on each render
    // that's why we need to wrap it into useMemo and provide custom memo keys
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingCount, memoKey, entitiesUpdatedKey],
  )
}
