import { Box, BoxProps, Collapse } from '@mui/material'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import {
  CollapsePanelHeaderStyled,
  CollapsePanelStyled,
} from './CollapsePanel.styled'
import { CollapseIcon, ExpandIcon } from '@fintastic/shared/ui/icons'

export const CollapsePanel: React.FC<
  PropsWithChildren<
    {
      initialOpen?: boolean
      title?: string
      titleComponent?: React.ReactNode
    } & BoxProps
  >
> = ({ initialOpen, title, titleComponent, children, ...props }) => {
  const [open, setOpen] = useState(initialOpen ?? true)

  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  return (
    <CollapsePanelStyled {...props} data-testid="collapse-panel" open={open}>
      <CollapsePanelHeaderStyled onClick={handleToggleOpen} open={open}>
        {title && <span>{title}</span>}
        {titleComponent}
        {open ? <CollapseIcon /> : <ExpandIcon />}
      </CollapsePanelHeaderStyled>
      <Collapse in={open}>
        <Box>{children}</Box>
      </Collapse>
    </CollapsePanelStyled>
  )
}
