import { ValueFormatterParams } from 'ag-grid-community'
import { applyValueFormatter } from '@fintastic/shared/ui/grid-framework'
import { GetCellEditorProps } from './types'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import dayjs from 'dayjs'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'

export const getDateCellEditorProps = (
  column: ListColumnWrapper,
): GetCellEditorProps => {
  // @todo: params.dataFormat?
  const valueFormatter = (formatterParams: ValueFormatterParams) =>
    applyValueFormatter(formatterParams, ({ value }) =>
      value ? dayjs(value).format(DEFAULT_DAYJS_DATE_FORMAT) : '',
    )

  return {
    valueFormatter,
    cellEditorParams: {
      displayingFormat: dayjsFormatToDateFnsFormat(DEFAULT_DAYJS_DATE_FORMAT),
      storingFormat: dayjsFormatToDateFnsFormat(DEFAULT_DAYJS_DATE_FORMAT),
      dataType: column.dataType(),
    },
  }
}

/** @deprecated */
function dayjsFormatToDateFnsFormat(dayjsFormat: string): string {
  return dayjsFormat
    .replace('YYYY', 'yyyy')
    .replace('DDDD', 'ddd')
    .replace('DDD', 'ddd')
    .replace('DD', 'dd')
    .replace('D', 'd')
}
