import { useQuery } from 'react-query'
import { axios } from '@fintastic/web/data-access/service-axios'
import { DimensionItem } from '../types'
import { endpoints } from '../api/endpoints'
import { globalDimensionsKey } from '../api/keys'
import Qs from 'qs'
import { sortBy } from 'lodash'

type GlobalDimensionType = 'manual' | 'dynamic' | 'time'

export function useGlobalDimensions(
  hide_unrestricted = false,
  include_values = true,
  dim_type: GlobalDimensionType[] = [],
) {
  return useQuery<DimensionItem[]>(
    [
      ...globalDimensionsKey,
      encodeURIComponent(hide_unrestricted),
      encodeURIComponent(include_values),
      encodeURIComponent(sortBy(dim_type, (x) => x).join(',')),
    ],
    async () => {
      const { data } = await axios.get<{ result: DimensionItem[] }>(
        endpoints.dimensions,
        {
          params: {
            hide_unrestricted,
            include_values,
            dim_type,
          },
          paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
        },
      )
      return data.result
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      retryOnMount: true,
    },
  )
}
