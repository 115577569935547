import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppContainer } from './AppContainer'
import reportWebVitals from './reportWebVitals'
import { environment } from '@fintastic/web/data-access/environment'
import LogRocket from 'logrocket'
import { shouldUseTestAuth0Client } from '@fintastic/web/data-access/testing-auth0-client'
import { syntaxInjector } from '@fintastic/web/data-access/formulas'

syntaxInjector.inject()

if (
  environment.REACT_APP_LOGROCKET_APP_ID &&
  !window.Cypress &&
  !shouldUseTestAuth0Client()
) {
  LogRocket.init(environment.REACT_APP_LOGROCKET_APP_ID)
}

const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)
