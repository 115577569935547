import { NavigationApi } from '../navigation/types'
import React from 'react'

const WATCHING_KEYS = ['ArrowDown', 'ArrowUp', 'Enter', 'Tab', 'Escape']

export function interceptKeyUpEvent(
  enabled: boolean,
  navigationApi: NavigationApi,
  event: React.KeyboardEvent<HTMLElement>,
): boolean {
  if (!enabled) {
    return false
  }

  if (!WATCHING_KEYS.includes(event.key)) {
    return false
  }

  event.preventDefault()
  event.stopPropagation()

  if (event.key === 'ArrowDown') {
    navigationApi.selectNext()
  }
  if (event.key === 'ArrowUp') {
    navigationApi.selectPrev()
  }
  if (event.key === 'Escape') {
    navigationApi.close()
  }
  if (event.key === 'Enter' || event.key === 'Tab') {
    navigationApi.applySelection()
  }

  return true
}
