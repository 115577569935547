// https://docs.sheetjs.com/docs/getting-started/installation/frameworks
// https://www.npmjs.com/package/xlsx-js-style
import { isHeaderCell, isNumericProps } from './utils'
import { HeaderCell } from './header-utils'
import { CellData } from './data-values'
import _, { orderBy } from 'lodash'
import { getCurrencyFormat, getDateTimeFormat, getNumericFormat, getPercentageFormat } from './excel-utils'
import type { RowInfo } from 'xlsx-js-style'
import dayjs from 'dayjs'
import { ExtendedCell } from './grid-comments'
import type { AgGridNumericCellEditorProps } from '@fintastic/shared/ui/ag-grid'

export type XLSComment = {
  a: string // author
  t: string // text
  T?: boolean // threaded?
}

function suggestAndApplyFormatting(cellData: CellData, dataType: string) {
  if (
    typeof (cellData as any).formattingParams?.displaySettings?.formatting
      ?.decimal_places !== 'undefined'
  ) {
    // params already exists
    return
  }

  const val = ((cellData.value as string) || '').replace(/[^\d.-]/g, '')

  let scale = 0
  if (val.length > 0 && val.includes('.')) {
    scale = val.split('.')[1].length // 0.02%  or (0.02%)
    // we need to set cellData.formattingParams?.dataType
    // and cellData.formattingParams?.displaySettings?.formatting?.decimal_places
  }
  // eslint-disable-next-line no-param-reassign
  cellData.formattingParams = {
    currency: {
      symbol: '$',
    },
    dataType: dataType,
    displaySettings: {
      currency_sign_position: 'before',
      formatting: {
        decimal_places: scale || 2,
        // @todo: perhaps we should provide mask for integers, but it has to be aligned with Nadav
        // let's use default 2
      },
    },
  } as AgGridNumericCellEditorProps
}

export function prepareTempXLSArray(
  dataTable: Array<HeaderCell[] | CellData[]>,
) {
  return dataTable.map((row) => {
    const excelRow: RowInfo[] = []

    row.forEach((cellData) => {
      const bg = cellData.hexColor?.replace('#', '')
      const cell: Record<string, any> = {
        v: isHeaderCell(cellData) ? cellData.label : cellData.value,
      }
      let styles = {}
      let format = ''
      if (cell.v === null) {
        cell.v = undefined
      }

      if (isHeaderCell(cellData)) {
        styles = _.set(styles, 'font.bold', true)
        styles = _.set(styles, 'alignment.horizontal', 'left')
      } else {
        cell['v_fmt'] = cellData.value // for better auto-width
        if (cellData.value === null) {
          cell['v_fmt'] = undefined
        }

        if (cellData.isCalculatedRow) {
          styles = _.set(styles, 'font.bold', true)
          styles = _.set(styles, 'font.italic', true)
        }

        if (cellData.isFooter) {
          styles = _.set(styles, 'font.bold', true)
        }

        if (cellData.isPinnedRow) {
          styles = _.set(styles, 'font.bold', true)
        }

        // texts
        if (cellData.type === 'generic') {
          styles = _.set(styles, 'alignment.horizontal', 'left')
        }

        // boolean
        if (cellData.type === 'boolean') {
          cell['t'] = 'b'
        }

        // numbers
        if (cellData.type === 'numericColumn') {
          styles = _.set(styles, 'alignment.horizontal', 'right')
          cell['v'] = cellData.rawValue === null ? '' : cellData.rawValue
          cell['t'] = 'n'

          suggestAndApplyFormatting(cellData, 'number')

          if (cellData.value === '-') {
            // blank!
            cell['v_fmt'] = undefined
            cell['v'] = undefined
            cell['t'] = undefined
          }

          format = getNumericFormat(cellData)
        }

        // currency
        // some values formatted as currency not marked as currency
        if (
          cellData.type === 'numericColumn' &&
          ((isNumericProps(cellData.formattingParams) &&
            cellData.formattingParams?.dataType === 'currency') ||
            (cellData.value || '').toString().indexOf('$') !== -1)
        ) {
          suggestAndApplyFormatting(cellData, 'currency')

          format = getCurrencyFormat(cellData)
        }

        // percentage
        // some % values not have valid "formattingParams", so extra check on value "%"
        if (
          cellData.type === 'numericColumn' &&
          ((isNumericProps(cellData.formattingParams) &&
            cellData.formattingParams?.dataType === 'percentage') ||
            (cellData.value || '').toString().indexOf('%') !== -1)
        ) {
          suggestAndApplyFormatting(cellData, 'percentage')

          format = getPercentageFormat(cellData)
        }

        // date
        if (cellData.type === 'datetime') {
          cell['v'] =
            (cellData.rawValue === null ? '' : cellData.rawValue) || undefined
          cell['t'] = 'd'
          format = getDateTimeFormat(cellData)
        }

        // comments
        if (cellData.remarks && cellData.remarks.length > 0) {
          const finalComments: XLSComment[] = []
          const remarks = orderBy(cellData.remarks, ['resolved_at'], ['desc'])

          remarks.forEach((record: Partial<ExtendedCell>) => {
            const comments = orderBy(record.comments, ['created_at'], ['asc'])

            const labels = record.label_titles || []

            comments.forEach((comment) => {
              const author =
                (record.is_resolved ? '[resolved] ' : '') + comment.author_id

              const body = sanitizeXMLString(
                (labels.length ? labels.join(' ') + '\r\n' : '') +
                  dayjs(comment.created_at).format('DD MMM YYYY, h:mm A') +
                  '\r\n\r\n' +
                  comment.body,
              )

              const cmt: XLSComment = {
                a: author,
                t: body,
                T: true,
              }

              finalComments.push(cmt)
            })
          })

          cell.c = finalComments
          cell.c.hidden = true
        }
      }

      if (bg) {
        styles = _.set(styles, 'fill.fgColor.rgb', bg)
      }

      if (Object.keys(styles).length > 0) {
        cell['s'] = styles
      }
      if (format) {
        cell['z'] = format
      }
      excelRow.push(cell)
    })

    return excelRow
  })
}

function sanitizeXMLString(str: string) {
  if (!str) {
    return ''
  }

  return str
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('&', '&amp;')
    .replaceAll('€', '&euro;')
}
