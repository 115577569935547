import React, { useMemo } from 'react'
import {
  useWidgetContextImplementation,
  WidgetContext,
  WidgetInvisibleContainer,
} from '@fintastic/shared/ui/widgets-framework'
import {
  CenteredCircularProgress,
  ErrorAlert,
} from '@fintastic/shared/ui/components'
import {
  WidgetPeriodSelectionComponentProps,
  WidgetPeriodSelectionContainer,
} from '../containers/WidgetPeriodSelectionContainer'
import { MetricGridWidget, MetricGridWidgetProps } from './MetricGridWidget'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'

const MetricGridWidgetContainerRenderer: React.FC<
  WidgetPeriodSelectionComponentProps & MetricGridWidgetProps
> = ({ periodSelection, widget, ...props }) => {
  const content = useMemo(() => {
    if (periodSelection.isLoading) {
      return <CenteredCircularProgress />
    } else if (periodSelection.isError) {
      console.error(periodSelection.error)
      return (
        <ErrorAlert
          title="Something is wrong with the period selection"
          message="Select different periods in the calendar or contact the administrator."
          severity="info"
          icon={<EmptyFolderErrorIcon />}
        />
      )
    } else {
      return (
        <MetricGridWidget
          periodSelection={periodSelection}
          widget={widget}
          {...props}
        />
      )
    }
  }, [periodSelection, props, widget])

  return (
    <WidgetInvisibleContainer
      data-testid={`board-widget-${widget.id}`}
      data-widget-type={widget.type}
    >
      {content}
    </WidgetInvisibleContainer>
  )
}

export const MetricGridWidgetContainer: React.FC<MetricGridWidgetProps> = (
  props,
) => {
  const widgetContext = useWidgetContextImplementation(
    props,
    !!props.boardParams.isDesignMode,
  )

  return (
    <WidgetContext.Provider value={widgetContext}>
      <WidgetPeriodSelectionContainer
        {...props}
        Component={
          MetricGridWidgetContainerRenderer as React.FC<WidgetPeriodSelectionComponentProps>
        }
      />
    </WidgetContext.Provider>
  )
}
