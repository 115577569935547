import {
  MetricDataValue,
  UnfoldedMetricDataValues,
} from '@fintastic/web/util/metrics-and-lists'

export function extendLastUnfoldLevelWith(
  data: UnfoldedMetricDataValues,
  callback: (value: MetricDataValue) => MetricDataValue[],
): UnfoldedMetricDataValues {
  return data.map((node) => {
    if (Array.isArray(node)) {
      return extendLastUnfoldLevelWith(node, callback)
    }
    return callback(node)
  })
}
