import React from 'react'
import { StyledDisabled, StyledEnabled } from './Button.styled'
import { ArrowDropDown } from '@mui/icons-material'

export type ButtonProps = {
  disabled?: boolean
  onClick: () => void
  children: React.ReactNode
}

export const Button: React.FC<ButtonProps> = ({
  disabled = false,
  onClick,
  children,
}) =>
  disabled ? (
    <StyledDisabled variant="overline" color="text.secondary">
      {children}
    </StyledDisabled>
  ) : (
    <StyledEnabled
      size="small"
      endIcon={<ArrowDropDown fontSize="small" />}
      onClick={onClick}
    >
      {children}
    </StyledEnabled>
  )
