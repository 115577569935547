import { styled } from '@mui/material'

export const StyledInlineTooltip = styled('span')`
  display: inline;
  margin: 0 0.4rem;
  cursor: default;

  svg {
    vertical-align: text-top;
    display: inline;
    width: 16px;
    height: 16px;
    color: var(--color-icon-nav-inactive);
    transition: color 200ms ease-in-out;
  }

  &:hover {
    svg {
      color: var(--color-icon-nav-selected);
    }
  }
`
