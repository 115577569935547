import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

export const StyledDetailsItemChange = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 14px;
`

export const StyledDetailsItemValue = styled(
  (props: { empty?: boolean } & BoxProps) => {
    const { empty, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'empty',
  },
)`
  display: inline-flex;
  flex-flow: row nowrap;
  height: auto;
  line-height: 1;
  padding: 4px 0;
  margin: 0;

  color: ${({ theme, empty }) =>
    empty ? theme.palette.text.disabled : theme.palette.text.primary};
`

export const StyledTransitionIcon = styled('div')`
  display: inline-flex;
  padding: 0 ${({ theme }) => theme.spacing(1)};

  svg {
    width: 12px;
    height: 12px;
  }
`

export const StyledDetailsItemRawContent = styled('pre')`
  font-size: 12px;
`
