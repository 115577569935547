import { StateApi } from '../editor-state'
import { DeleteOperationApi } from '../lifecycle-operations/delete'
import { DiscardOperationApi } from '../lifecycle-operations/discard'
import { SaveOperationApi } from '../lifecycle-operations/save'
import { StartCreationOperationApi } from '../lifecycle-operations/start-creation'
import { StartEditingOperationApi } from '../lifecycle-operations/start-editing'
import { useMemo } from 'react'
import { pick } from 'lodash'

export type InactiveReportEditorApi = {
  active: false
  deleteOperationApi: DeleteOperationApi
  startCreationOperationApi: StartCreationOperationApi
  startEditingOperationApi: StartEditingOperationApi
  lastCreatedReportId?: string
}

export type ActiveReportEditorApi = {
  active: true
  deleteOperationApi: DeleteOperationApi
  discardOperationApi: DiscardOperationApi
  saveOperationApi: SaveOperationApi
  new: boolean
  reportDefinition: {
    id: string
    name: string
  }
}

export type ReportEditorApi = InactiveReportEditorApi | ActiveReportEditorApi

type Params = {}

type Dependencies = {
  stateApi: StateApi
  discardOperationApi: DiscardOperationApi
  saveOperationApi: SaveOperationApi
  startCreationOperationApi: StartCreationOperationApi
  startEditingOperationApi: StartEditingOperationApi
  deleteOperationApi: DeleteOperationApi
}

export const usePublicApiImplementation = (
  params: Params,
  {
    stateApi,
    deleteOperationApi,
    discardOperationApi,
    saveOperationApi,
    startCreationOperationApi,
    startEditingOperationApi,
  }: Dependencies,
) => {
  const maybeActiveState = stateApi.state.active ? stateApi.state : null
  const maybeInctiveState = !stateApi.state.active ? stateApi.state : null
  const partialReportDef = useMemo(
    () =>
      pick(
        maybeActiveState?.reportDefinition || {
          id: '',
          name: '',
        },
        ['id', 'name'],
      ),
    [
      maybeActiveState?.reportDefinition.id,
      maybeActiveState?.reportDefinition.name,
    ],
  )

  return useMemo<ReportEditorApi>(
    () =>
      stateApi.state.active
        ? {
            active: true,
            deleteOperationApi,
            discardOperationApi,
            saveOperationApi,
            new: stateApi.state.new,
            reportDefinition: partialReportDef,
          }
        : {
            active: false,
            deleteOperationApi,
            startCreationOperationApi,
            startEditingOperationApi,
            lastCreatedReportId: maybeInctiveState?.lastCreatedReportId,
          },
    [
      stateApi.state.active,
      maybeActiveState?.new,
      deleteOperationApi,
      discardOperationApi,
      saveOperationApi,
      startCreationOperationApi,
      startEditingOperationApi,
      partialReportDef,
      maybeInctiveState?.lastCreatedReportId,
    ],
  )
}
