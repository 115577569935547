import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ImportConfigItem } from '@fintastic/web/data-access/import-configs'
import { ImportActualsConfigContext } from '../context/ImportActualsConfigContext'
import {
  BaseGrid,
  BaseGridColumnDefinitionCellRenderer,
  BaseGridEditProps,
  BaseGridEventHandlers,
  BuildBaseGridColumnDefinitionParams,
} from '@fintastic/shared/ui/grid-framework'
import {
  AgGridDefaultCellRendererProps,
  EntityLabelCellRenderer,
  EntityLabelCellRendererParams,
  TableTypeCellRenderer,
  textCaseInsensitiveComparator,
} from '@fintastic/shared/ui/ag-grid'
import type { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import type { Maybe } from '@fintastic/shared/util/types'
import { PanelLoadingOverlay } from '@fintastic/shared/ui/panel-framework'
import { ColDef, ISetFilterParams, RowNode } from 'ag-grid-community'
import { Box, LinearProgress } from '@mui/material'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import { QuickSearch, useQuickSearch } from '@fintastic/shared/ui/components'
import { DateFilterCellRenderer } from './DateFilterCellRenderer'
import { LabelTagListItem } from '@fintastic/web/data-access/labels'
import { FilterLabelCellRenderer } from '@fintastic/web/feature/versions'
import { ReadonlyLabelsCellRenderer } from './ReadonlyLabelsCellRenderer'
import { StyledTableContainer } from './Table.styled'
import { ReplaceAllSwitchCellRenderer } from './ReplaceAllSwitchCellRenderer'

export const ImportEntitiesTable: React.FC<{
  initialData: ImportConfigItem[]
  labels: LabelTagListItem[]
}> = ({ initialData, labels }) => {
  const {
    versionId,
    dateFrom,
    dateTo,
    appliedConfigRef,
    loading,
    calculating,
    setSelectedItems,
  } = useContext(ImportActualsConfigContext)
  const apiRef = useRef<Maybe<GridApi<ImportConfigItem>>>(null)

  const [data, setData] = useState(initialData)

  const labelListDataRef = useRef(labels)
  labelListDataRef.current = labels

  const applySelectedRows = useCallback(() => {
    if (!apiRef.current) {
      return
    }

    const rowNodes: RowNode[] = []
    apiRef.current.forEachNodeAfterFilterAndSort((node) => {
      if (
        !appliedConfigRef.current?.find(
          (item) => node.data?.resource_id === item?.resource_id,
        )?.is_enabled
      ) {
        return
      }

      node.setSelected(true)
      rowNodes.push(node)
    })

    apiRef.current.redrawRows({ rowNodes })
    apiRef.current.sizeColumnsToFit()
  }, [appliedConfigRef])

  useEffect(() => {
    setData(initialData)
    appliedConfigRef.current = initialData
    applySelectedRows()
  }, [initialData, appliedConfigRef, applySelectedRows])

  appliedConfigRef.current = data

  const { quickFilterText, handleQuickFilterTextChange } = useQuickSearch(
    apiRef?.current,
  )

  const columns = useMemo<
    Array<BuildBaseGridColumnDefinitionParams<ImportConfigItem>>
  >(
    () => [
      {
        field: 'resource_id',
        title: 'Name',
        dataType: 'string' as const,
        defaultSort: 'asc',
        sort: 'asc',
        comparator: textCaseInsensitiveComparator,
        minWidth: 300,
        flex: 2,
        // Base grid types are broken, so we need this ugly conversion
        cellRenderer:
          EntityLabelCellRenderer as unknown as BaseGridColumnDefinitionCellRenderer,
        cellRendererParams: {
          versionId: versionId,
        } as EntityLabelCellRendererParams,
      },
      {
        field: 'type',
        title: 'Type',
        dataType: 'string' as const,
        cellRenderer: TableTypeCellRenderer,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'last_imported_at',
        title: 'Last import',
        dataType: 'datetime' as const,
      },
      {
        field: 'is_replacement',
        title: 'Replace all',
        dataType: 'dimension' as const,
        isEditable: () => false,
        cellRenderer:
          ReplaceAllSwitchCellRenderer as BaseGridColumnDefinitionCellRenderer<ImportConfigItem>,
        minWidth: 100,
        width: 100,
        valueSetter: (params) => {
          // eslint-disable-next-line no-param-reassign
          params.data.is_replacement = params.newValue
          return true
        },
        cellRendererParams: {
          fallbackForNonEditable: '-',
        } as AgGridDefaultCellRendererProps,
      },
      {
        field: 'from_earliest_available',
        title: 'From',
        dataType: 'dimension' as const,
        valueGetter: (params) => {
          if (params?.data?.from_earliest_available === undefined) {
            return 'from_source'
          }
          return params?.data?.from_earliest_available
            ? 'from_source'
            : 'user_input'
        },
        valueSetter: (params) => {
          // eslint-disable-next-line no-param-reassign
          params.data.from_earliest_available =
            params.newValue === 'from_source'
          return true
        },
        isEditable: (params) =>
          !!(
            params.data &&
            !params.data?.is_replacement &&
            params.data.type === 'list' &&
            !!params.data.date_column_name
          ),
        cellEditorParams: {
          options: [
            {
              value: 'user_input',
              label: dayjs(dateFrom, DEFAULT_DAYJS_DATE_FORMAT).format(
                'MMM YYYY',
              ),
            },
            {
              value: 'from_source',
              label: 'Earliest available',
            },
          ],
        },
        filterParams: {
          cellRenderer: DateFilterCellRenderer,
          userInputFieldValue: 'dateFrom',
          fromSourceLabel: 'Earliest available',
        },
        cellRendererParams: {
          fallbackForNonEditable: ({ data }) =>
            (data as ImportConfigItem).is_replacement
              ? '-'
              : dayjs(dateFrom, DEFAULT_DAYJS_DATE_FORMAT).format('MMM YYYY'),
        } as AgGridDefaultCellRendererProps,
      },
      {
        field: 'to_latest_available',
        title: 'To',
        dataType: 'dimension' as const,
        valueGetter: (params) => {
          if (params?.data?.to_latest_available === undefined) {
            return 'from_source'
          }
          return params?.data?.to_latest_available
            ? 'from_source'
            : 'user_input'
        },
        valueSetter: (params) => {
          // eslint-disable-next-line no-param-reassign
          params.data.to_latest_available = params.newValue === 'from_source'
          return true
        },
        isEditable: (params) =>
          !!(
            params.data &&
            !params.data?.is_replacement &&
            params.data.type === 'list' &&
            !!params.data.date_column_name
          ),
        cellEditorParams: {
          options: [
            {
              value: 'user_input',
              label: dayjs(dateTo, DEFAULT_DAYJS_DATE_FORMAT).format(
                'MMM YYYY',
              ),
            },
            {
              value: 'from_source',
              label: 'Latest available',
            },
          ],
        },
        filterParams: {
          cellRenderer: DateFilterCellRenderer,
          userInputFieldValue: 'dateTo',
          fromSourceLabel: 'Latest available',
        },
        cellRendererParams: {
          fallbackForNonEditable: ({ data }) =>
            (data as ImportConfigItem).is_replacement
              ? '-'
              : dayjs(dateTo, DEFAULT_DAYJS_DATE_FORMAT).format('MMM YYYY'),
        } as AgGridDefaultCellRendererProps,
      },
      {
        field: 'label_ids',
        title: 'Labels',
        cellRenderer:
          ReadonlyLabelsCellRenderer as BaseGridColumnDefinitionCellRenderer<ImportConfigItem>,
        cellRendererParams: {
          labels: labelListDataRef.current,
        },
        minWidth: 300,
        width: 300,
        valueGetter: (params) => params.data?.label_ids || [],
        valueFormatter: ({ value }) =>
          ((value as number[]) || [])
            .map(
              (valueId) =>
                (labelListDataRef.current || []).find(
                  ({ id }) => id === valueId,
                )?.title || valueId,
            )
            .join(', '),
        filterParams: {
          cellRenderer: FilterLabelCellRenderer,
          valueFormatter: ({ value }) =>
            (labelListDataRef.current || []).find(({ id }) => `${id}` === value)
              ?.title || value,
        } as ISetFilterParams,
      },
    ],
    [dateFrom, dateTo, versionId],
  )

  const eventHandlers = useMemo<BaseGridEventHandlers<ImportConfigItem>>(
    () => ({
      onFirstDataRendered: () => {
        applySelectedRows()
      },
      onRowSelected: (event) => {
        const isSelected = event.node.isSelected()
        const dataSelected = appliedConfigRef.current?.find(
          (item) => event.node.data?.resource_id === item?.resource_id,
        )?.is_enabled

        if (isSelected === dataSelected) {
          return
        }

        setData((prevData) =>
          prevData.map((i) => {
            if (i.resource_id !== event.node.data?.resource_id) {
              return i
            }

            return {
              ...i,
              is_enabled: !!isSelected,
            }
          }),
        )

        if (!isSelected) {
          event.api.redrawRows({ rowNodes: [event.node] })
        }
      },
    }),
    [appliedConfigRef, applySelectedRows],
  )

  const overlay = useMemo(() => {
    if (loading || calculating) {
      return (
        <PanelLoadingOverlay
          text={calculating ? 'Calculating...' : 'Loading...'}
        />
      )
    }

    return null
  }, [loading, calculating])

  const editProps = useMemo<BaseGridEditProps<ImportConfigItem>>(
    () => ({
      rowIdField: 'resource_id',
      onCellValueChanged: (event) => {
        setData((prevData) =>
          prevData.map((i) => {
            if (i.resource_id !== event.node.data?.resource_id) {
              return i
            }

            return {
              ...i,
              ...event.data,
            }
          }),
        )

        event.api.redrawRows({ rowNodes: [event.node] })
      },
      stopEditingWhenCellsLoseFocus: false,
    }),
    [],
  )

  useEffect(() => {
    setSelectedItems(data.filter((i) => i.is_enabled))
  }, [data, setSelectedItems])

  const agContext = useMemo(
    () => ({
      dateFrom,
      dateTo,
    }),
    [dateFrom, dateTo],
  )

  return (
    <Box p={1} display="flex" flexDirection="column" width="100%">
      {loading || calculating ? (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        />
      ) : (
        <div /> // Maintain DOM-elements number and order
      )}
      <Box mb={2} mt={1} sx={{ maxWidth: '311px' }}>
        <QuickSearch
          value={quickFilterText}
          disabled={loading || calculating}
          onChange={handleQuickFilterTextChange}
        />
      </Box>
      <StyledTableContainer>
        <BaseGrid<ImportConfigItem>
          rowData={data}
          columns={columns}
          defaultColDef={defaultColDef}
          apiRef={apiRef}
          overlay={overlay}
          eventHandlers={eventHandlers}
          checkboxSelection
          editProps={editProps}
          agContext={agContext}
        ></BaseGrid>
      </StyledTableContainer>
    </Box>
  )
}

const defaultColDef: ColDef<ImportConfigItem> = {
  filter: true,
  sortable: true,
  resizable: true,
}
