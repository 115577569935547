import { styled, Box, Typography, Divider } from '@mui/material'

export const StyledRoot = styled(Box)(({ theme }) => ({}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))

export const StyledFields = styled(Box)(({ theme }) => ({}))
