import { createContext, useContext } from 'react'

export type ContextValue = {
  onConfirm: () => void
  onDecline: () => void
}

const stub = () => {
  throw new Error('confirmation popup context is not initialised')
}

export const context = createContext<ContextValue>({
  onConfirm: stub,
  onDecline: stub,
})

export function useLocalContext() {
  return useContext(context)
}
