import {
  DimensionId,
  DimensionLabel,
  DimensionType,
  DimensionValuesMap,
  VersionCategoryDimension,
  VersionDimension,
  VersionTimeDimension,
} from '../types'

export const type = (d: VersionDimension): DimensionType => d.type

export const id = (d: VersionDimension): DimensionId => d.id

export const label = (d: VersionDimension): DimensionLabel => d.label

export const values = (
  d: VersionTimeDimension | VersionCategoryDimension,
): DimensionValuesMap => d.values
