// compare ignoring DiffMode part

import { DiffMode } from './diff-mode.types'

export const isDiffPairEqualsByVersions = (
  colA: [string, string, DiffMode],
  colB: [string, string, DiffMode],
) => {
  if (colA[0] === colB[0] && colA[1] === colB[1]) {
    return true
  }
  return colA[0] === colB[1] && colA[1] === colB[0]
}
