import { useMemo } from 'react'
import { PeriodResolution } from '../types'
import { usePeriodSelectorContext } from './usePeriodSelectorContext'
import { usePeriodSelectorContextCalendarConfig } from './usePeriodSelectorContextCalendarConfig'

export const usePeriodSelectorResolution = (): PeriodResolution => {
  const selection = usePeriodSelectorContext()
  const calendarConfig = usePeriodSelectorContextCalendarConfig({
    // We do not care if resolution is limited due to entities selected
    // We simply need to identify it's value
    limitToSelectedEntitiesOnly: false,
  })

  return useMemo<PeriodResolution>(() => {
    const resolution = calendarConfig.find(
      ({ dimension_id }) => dimension_id === selection.aggregationDimensionId,
    )?.resolution

    if (!resolution) {
      throw new Error(
        `Cannot determine resolution for the calendar. aggregationDimensionId: ${selection.aggregationDimensionId}`,
      )
    }

    return resolution
  }, [calendarConfig, selection.aggregationDimensionId])
}
