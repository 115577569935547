/**
 Redux store and sagas configuring
 */

import { combineReducers, Reducer } from 'redux'
import createSagaMiddleware, { Saga } from 'redux-saga'

import { all } from 'redux-saga/effects'
import { configureStore } from '@reduxjs/toolkit'
import ducks, { RootReducer } from './ducks'

const isDev = process.env.NODE_ENV === 'development'

// grab all reducers and sagas together
const allReducers: Record<string, Reducer> = {}
let allSagas: Saga[] = []

ducks.forEach((d) => {
  allReducers[d.namespace] = d.reducer
  if (d.sagas?.length) {
    allSagas = [...allSagas, ...d.sagas]
  }
})

// make root reducer
const rootReducer = combineReducers(allReducers) as RootReducer

/**
 * Creates store and runs saga middleware
 */
function createStore() {
  // add middlewares
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware]

  // create store
  const store = configureStore({
    reducer: rootReducer,
    middleware,
    // to use dev tools install this Chrome extension: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd/related?hl=en
    devTools: isDev,
  })

  // HMR support
  if (isDev && module.hot) {
    module.hot.accept('./ducks', () => {
      store.replaceReducer(combineReducers(allReducers) as RootReducer)
    })
  }

  // run sagas
  sagaMiddleware.run(function* runSagas() {
    yield all([...allSagas.map((s) => s())])
  })

  return store
}

export const store = createStore()

/**
 * It's a util types to use them outside of ducks for some custom selectors and dispatchers.
 * ATTENTION! Do not use them inside the ducks to avoid circular dependencies!
 */
export type AppRootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']
