import { Maybe } from '@fintastic/shared/util/types'
import { DesignerWidgetType } from '../components/BoardDesigner/widgets/board-design-type-utils'
import { ModalWithParamsState } from '@fintastic/shared/util/modal'
import { ChartSettings } from '@fintastic/web/feature/charts'

export type ChangeWidgetDialogParams = {
  type: Maybe<DesignerWidgetType>
  id?: string
  versionId?: string
  widgetId?: string
  displayLabel?: string
  chartSettings?: ChartSettings
}

export const emptyWidgetDialogState: ChangeWidgetDialogParams = {
  type: null,
  id: '',
  versionId: '',
  displayLabel: '',
}

export type ChangeWidgetDialogState =
  ModalWithParamsState<ChangeWidgetDialogParams> & {
    onConfirm?: (params: ChangeWidgetDialogParams) => void
  }

export type RemoveWidgetDialogParams = {
  name: string
  type: string
  id: string
}
