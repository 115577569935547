import { Box, Button, styled } from '@mui/material'

export const StyledBoardTitleBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: flex-start;
  margin: 0 0 24px auto;
`
export const StyledTitleContextMenuButton = styled(Button)`
  padding: 0;
  position: relative;

  width: 40px;
  min-width: 40px;
  margin-top: 4px;
  z-index: 2;

  &.Mui-disabled {
    opacity: 0.3;
  }

  &.design-menu {
    transform: translateX(20px);
  }
`
