import { useAuthAccessToken } from '@fintastic/web/feature/auth'
import { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useUserListQuery } from '../query'
import { UserRole } from '../../types'

// we don't have a sync between auth0 and our DB for the users,
// so there can ba a case when current user doesn't exist on the backend
// in that case we fall back to the null for the role
export function useUserRole(): Maybe<UserRole | undefined> {
  const {
    data: usersListData,
    isSuccess: isUsersSuccess,
    isLoading,
  } = useUserListQuery({
    alwaysShowFintasticUsers: true,
  })
  const { user } = useAuthAccessToken()

  // TODO better to refactor and get role directly from backend

  return useMemo<Maybe<UserRole | undefined>>(() => {
    if (isLoading) {
      return undefined
    }

    return isUsersSuccess && user
      ? usersListData?.find(({ username }) => username === user.email)?.role ||
          null
      : null
  }, [isLoading, isUsersSuccess, user, usersListData])
}
