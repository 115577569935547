import React, { useMemo } from 'react'
import { HistoryLogEntryChangeMetricValue } from '@fintastic/web/util/history'
import {
  DimensionId,
  DimensionLabel,
  DimensionValueLabel,
  VersionDimension,
} from '@fintastic/web/util/dimensions'
import { Box } from '@mui/material'
import {
  CardDimensionsSection,
  CardHeader,
  CardValueSection,
} from '../shared-card-elements'
import { WidgetMetricIcon } from '@fintastic/shared/ui/icons'
import { resolveMetricOrListLabelFromId } from '../../tmp-utilities'
import { OldToNewValueTransition } from '../old-and-new-values'
import { Maybe } from '@fintastic/shared/util/types'

export const ChangeMetricValueDetailsCard: React.FC<{
  entry: HistoryLogEntryChangeMetricValue
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entry, dimensions }) => {
  const dimensionsToDisplay = useMemo<
    Maybe<
      Array<{
        dimensionLabel: DimensionLabel
        dimensionValueLabel: DimensionValueLabel
      }>
    >
  >(() => {
    if (entry.dim_ids.length === 0) {
      return null
    }

    return entry.dim_ids.map((dimId, dimIndex) => {
      const dimValueId = entry.dim_value_ids[dimIndex]

      const dimension = dimensions[dimId]

      if (!dimension) {
        return {
          dimensionLabel: dimId,
          dimensionValueLabel: dimValueId,
        }
      }

      if (dimension.type !== 'Category' && dimension.type !== 'Time') {
        return {
          dimensionLabel: dimension.label,
          dimensionValueLabel: dimValueId,
        }
      }

      return {
        dimensionLabel: dimension.label,
        dimensionValueLabel: dimension.values[dimValueId] || dimValueId,
      }
    })
  }, [dimensions, entry.dim_ids, entry.dim_value_ids])

  return (
    <Box>
      <CardHeader
        icon={
          <WidgetMetricIcon
            sx={{
              color: '#2C45FE',
            }}
          />
        }
        title={resolveMetricOrListLabelFromId(entry.entity_id)}
      />
      <CardValueSection>
        <OldToNewValueTransition entry={entry} dimensions={dimensions} />
      </CardValueSection>
      {dimensionsToDisplay && (
        <CardDimensionsSection dimensions={dimensionsToDisplay} />
      )}
    </Box>
  )
}
