import React, { useCallback, useState } from 'react'
import {
  MetricSettingsView,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'
import { Maybe } from '@fintastic/shared/util/types'
import { cloneDeep } from 'lodash'

const allReportingGroups = [
  {
    id: '1',
    label: 'Reporting Group 1',
  },
  {
    id: '2',
    label: 'Reporting Group 2',
  },
  {
    id: '3',
    label: 'Reporting Group 3',
  },
]

const allDateColumns = [
  {
    id: 'Table.Headcount.Start_Date',
    label: 'Start Date',
  },
  {
    id: 'Table.Headcount.End_Date',
    label: 'End Date',
  },
  {
    id: 'Table.Different.Date',
    label: 'Other Date',
  },
]

// @todo RESP connect
export const MetricSettingsViewConnector: React.FC = () => {
  const { goBack } = useReportSettingsPanelNavigationContext()

  const [metric, setMetric] = useState<{
    id: string
    label: string
    isColumn: boolean
    reportingGroup: Maybe<{
      id: string
      label: string
    }>
    aggregation: unknown
    timeAggregation: unknown
    requiresDateColumn: boolean
    dateColumn: Maybe<{
      id: string
      label: string
    }>
  }>({
    id: 'Table.Headcount.Something',
    label: 'Something',
    isColumn: true,
    reportingGroup: {
      id: '1',
      label: 'Reporting Group 1',
    },
    aggregation: 'sum',
    timeAggregation: 'sum',
    requiresDateColumn: true,
    dateColumn: {
      id: 'Table.Headcount.Start_Date',
      label: 'Start Date',
    },
  })

  const changeDateColumn = useCallback(
    (metricId: string, dateColumnId: string) => {
      const column = allDateColumns.find((c) => c.id === dateColumnId)
      if (!column) {
        return
      }
      setMetric((metric) => ({
        ...metric,
        dateColumn: cloneDeep(column),
      }))
    },
    [],
  )

  const changeReportingGroup = useCallback(
    (metricId: string, groupId: string) => {
      const group = allReportingGroups.find((g) => g.id === groupId)
      if (!group) {
        return
      }
      setMetric((metric) => ({
        ...metric,
        reportingGroup: cloneDeep(group),
      }))
    },
    [],
  )

  const changeAggregation = useCallback(
    (metricId: string, aggregation: unknown) => {
      setMetric((metric) => ({
        ...metric,
        aggregation,
      }))
    },
    [],
  )

  const changeTimeAggregation = useCallback(
    (metricId: string, timeAggregation: unknown) => {
      setMetric((metric) => ({
        ...metric,
        timeAggregation,
      }))
    },
    [],
  )

  return (
    <MetricSettingsView.Editable
      goBack={goBack}
      metric={metric}
      availableReportingGroups={allReportingGroups}
      availableDateColumns={allDateColumns}
      removeMetric={console.log}
      changeDateColumn={changeDateColumn}
      changeReportingGroup={changeReportingGroup}
      changeAggregation={changeAggregation}
      changeTimeAggregation={changeTimeAggregation}
    />
  )
}
