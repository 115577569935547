import { ColDef, ValueSetterParams } from 'ag-grid-community'
import { VersionTimeDimension } from '@fintastic/web/util/dimensions'

export const makeDefaultSetter = (
  setCellValue: (rowId?: string, fieldName?: string, value?: unknown) => void,
  allowEmptyValue = true,
) => ({
  valueSetter: ({ newValue, data, colDef }: ValueSetterParams) => {
    if (!allowEmptyValue && !newValue) {
      return false
    }
    const field = colDef.field
    if (!field) {
      return false
    }
    // eslint-disable-next-line no-param-reassign
    data[field] = newValue
    setCellValue(data._rowId, field, newValue)
    return true
  },
})

export const resolveTimeDimensionValueLabel = (
  dimension: VersionTimeDimension,
  id: string,
): string => dimension.values[id] || id || 'Unknown'

export const makeBaseColDef = (): Partial<ColDef> => ({
  cellClass: () => ['readonly'],
  headerClass: () => ['readonly'],
  suppressMenu: true,
  valueGetter: (params) => params.data[params.colDef.field || ''],
  cellRenderer: 'defaultCellRenderer',
  headerComponent: 'defaultColumnHeader',
  resizable: true,
  editable: true,
})
