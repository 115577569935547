import type {
  DimensionLabelMap,
  DimensionValue,
  DimensionValueId,
} from '@fintastic/shared/util/types'

export type DimensionValuesLabels = Record<DimensionValueId, DimensionValue>

// @todo add tests
export function dimensionsLabelsMapToValuesLabels(
  labelsMap: DimensionLabelMap,
): DimensionValuesLabels {
  return Object.values(labelsMap).reduce(
    (acc, dim) => ({ ...acc, ...dim.values }),
    {},
  )
}
