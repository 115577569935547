import { Box, BoxProps, styled, Typography } from '@mui/material'

export const StyledRoot = styled(
  (props: BoxProps & { active?: boolean }) => {
    const { active, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'active',
  },
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing(0.5, 1)};

  cursor: ${({ active }) => (active ? 'pointer' : 'default')};

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.palette.action.hover : 'none'};
  }
`

export const StyledIcon = styled(Box)`
  margin-left: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.text.primary};

  width: 32px;
  min-width: 32px;
`

export const StyledTitleText = styled('div')`
  margin-right: auto;
  position: relative;

  &:hover {
    span.tooltiptext {
      visibility: visible;
      animation-name: showTooltip;
      animation-duration: 0.2s;
      animation-delay: 0.7s;
      animation-play-state: running;
      animation-fill-mode: forwards;
    }
  }

  @keyframes showTooltip {
    0% {
      opacity: 0;
      top: -42px;
      transform: scale(0.1, 0.1);
    }
    100% {
      opacity: 1;
      top: -46px;
      transform: scale(1, 1);
    }
  }
`
export const StyledTitleTooltip = styled('span')`
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1, 0.1);
  transform-origin: bottom center;
  background: rgba(86, 86, 86, 0.9);
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 7px 8px;
  border-radius: 9px;
  font-size: 11px;

  position: absolute;
  top: -42px;
  left: -32px;
  z-index: 1;
  max-width: 270px;
  line-height: 1.4;

  b {
    font-weight: bold;
    line-break: auto;
    white-space: wrap;

    i {
      font-weight: normal;
      font-style: normal;
      line-break: loose;
      white-space: nowrap;
    }
  }
`

export const StyledTitle = styled(Typography)`
  overflow: hidden;

  margin-right: auto;
  max-width: 210px;

  text-overflow: ellipsis;
`

export const StyledActions = styled(Box)`
  width: 32px;
  min-width: 32px;
`
