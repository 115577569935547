import { axios } from '@fintastic/web/data-access/service-axios'
import { AxiosResponse } from 'axios'

const endpoints = {
  setDescription: (versionId: string, entityType: string, entityId: string) =>
    `/planning/api/v2/versions/${versionId}/entities/${entityType}/${entityId}/description/`,
} as const

export function setDescription(
  versionId: string,
  entityType: string,
  entityId: string,
  description: string,
) {
  return axios.put<void, AxiosResponse<void>, DescriptionPutObject>(
    endpoints.setDescription(versionId, entityType, entityId),
    {
      description,
    },
  )
}

type DescriptionPutObject = {
  description: string
}
