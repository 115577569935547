import { ConstantTokenRecognitionMap } from '../tokens/types'
import { Maybe } from '@fintastic/shared/util/types'
import { useMemo } from 'react'

// @todo add tests
export function useConstantTokenRecognitionMap(
  constants: Maybe<string[]>,
): ConstantTokenRecognitionMap {
  return useMemo<ConstantTokenRecognitionMap>(
    () =>
      constants && constants.length
        ? Object.fromEntries(constants.map((c) => [c, true]))
        : {},
    [constants],
  )
}
