export const isNameExists = (newName: string, versionNames: string[]) =>
  versionNames.includes(newName)

export const findNewVersionName = (
  initialName: string,
  currentNames: string[],
) => {
  let resName = initialName
  while (isNameExists(resName, currentNames)) {
    resName = `${resName}_copy`
  }
  return resName
}
