// We assume structure is the same as buildObjectId() returns
export const parseObjectId = (objectKey: string): Partial<ParsedObjectId> => {
  const parsedKeyParts = objectKey
    .split('&')
    .map((i) => i.split('=').map(decodeURIComponent))

  return parsedKeyParts.reduce<Partial<ParsedObjectId>>((acc, [key, value]) => {
    if (typeof value !== 'string') {
      return acc
    }

    if (nonDimensionKeys.includes(key as keyof ParsedObjectId)) {
      return {
        ...acc,
        [key]: value,
      }
    }

    return {
      ...acc,
      dimensions: {
        ...(acc.dimensions || {}),
        [key]: value,
      },
    }
  }, {})
}

const nonDimensionKeys: Array<keyof ParsedObjectId> = [
  'version',
  'period',
  'deeplinkWidgetId',
  'mom',
]

export type ParsedObjectId = {
  version: string
  period: string
  deeplinkWidgetId: string
  mom: boolean
  dimensions: Record<string, string>
}
