import React, { useCallback, useEffect } from 'react'
import {
  StyledSidebarBarContainer,
  StyledSidebarRoot,
  StyledSidebarSubMenuContainer,
  StyledSidebarSubMenuContent,
} from './Sidebar.styled'
import { useLayoutStateIsSidebarOpened } from '../../ducks/layout/hooks'
import { Bar } from './Bar'
import { Submenu } from './Submenu'
import { triggerWindowResizeSmoothly } from '@fintastic/shared/util/web-api'
import { ClickAwayListener } from '@mui/material'

export const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useLayoutStateIsSidebarOpened()

  const handleCloseSidebar = useCallback(() => {
    if (!isOpen) {
      return
    }
    setIsOpen(false)
  }, [isOpen, setIsOpen])
  useEffect(() => triggerWindowResizeSmoothly(300, 10), [isOpen])

  return (
    <ClickAwayListener onClickAway={handleCloseSidebar}>
      <StyledSidebarRoot data-testid="sidebar-root">
        <StyledSidebarSubMenuContainer
          sx={{
            transform: isOpen ? 'translate(-1px, 0)' : 'translate(-101%, 0)',
          }}
        >
          <StyledSidebarSubMenuContent>
            <Submenu />
          </StyledSidebarSubMenuContent>
        </StyledSidebarSubMenuContainer>
        <StyledSidebarBarContainer>
          <Bar />
        </StyledSidebarBarContainer>
      </StyledSidebarRoot>
    </ClickAwayListener>
  )
}
