import {
  MetricDataValue,
  MetricDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { RollUpFunction } from '@fintastic/shared/util/types'
import { mean, sum } from 'lodash'

type Tree = MetricDataValue[] | Tree[]

export function rollUpTree(
  data: Tree,
  dataType: MetricDataValueType,
  rollUpFunction: RollUpFunction,
): MetricDataValue {
  if (
    dataType === 'string' ||
    dataType === 'datetime' ||
    rollUpFunction === 'first' ||
    rollUpFunction === 'last'
  ) {
    throw new Error("can't rollup such data type")
  }

  const result = rollUpTreeWith(
    data,
    (values: MetricDataValue[]): MetricDataValue => {
      if (rollUpFunction === 'sum') {
        return sum(values)
      }
      if (rollUpFunction === 'mean') {
        return mean(values)
      }
      // @todo implement later
      if (rollUpFunction === 'weighted_avg') {
        return null
      }
      throw new Error('unsupported rollup function')
    },
  )

  return dataType === 'boolean' ? Boolean(result) : result
}

function rollUpTreeWith(
  tree: Tree,
  callback: (values: MetricDataValue[]) => MetricDataValue,
): MetricDataValue {
  const result: MetricDataValue[] = []

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]

    if (Array.isArray(node)) {
      result.push(rollUpTreeWith(node, callback))
      continue
    }

    if (typeof node !== 'number') {
      result.push(node ? 1 : 0)
      continue
    }

    result.push(node)
  }

  return callback(result)
}
