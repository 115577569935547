import React from 'react'
import {
  DimensionLabel,
  DimensionValueLabel,
} from '@fintastic/web/util/dimensions'
import {
  StyledContent,
  StyledLabel,
  StyledRoot,
  StyledDimension,
  StyledDimensionChip,
} from './CardDimensionsSection.styled'
import { CardSectionLabel } from './CardSectionLabel'

export const CardDimensionsSection: React.FC<{
  dimensions: Array<{
    dimensionLabel: DimensionLabel
    dimensionValueLabel: DimensionValueLabel
  }>
}> = ({ dimensions }) => (
  <StyledRoot>
    <StyledLabel>
      <CardSectionLabel>Dimensions</CardSectionLabel>
    </StyledLabel>
    <StyledContent>
      {dimensions.map((dimension) => (
        <StyledDimension key={dimension.dimensionLabel}>
          {dimension.dimensionLabel}:{' '}
          <StyledDimensionChip>
            {dimension.dimensionValueLabel}
          </StyledDimensionChip>
        </StyledDimension>
      ))}
    </StyledContent>
  </StyledRoot>
)
