import {
  selectCurrentDiscussionObjectId,
  selectIsDiscussionsOpened,
  setCurrentDiscussionObjectId,
  setIsDiscussionsOpened,
} from './index'
import { useDispatch, useSelector } from 'react-redux'
import type {
  SetCurrentDiscussionObjectIdPayload,
  SetIsDiscussionsOpenedPayload,
  SetSidebarSubmenuPayload,
} from './reducers'
import { useCallback, useEffect } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { useSyncDeeplinkValue } from '@fintastic/web/util/deeplink'
import { useBoardId } from '@fintastic/web/data-access/comments'

export function useLayoutStateIsDiscussionsOpened(): [
  boolean,
  (payload: SetIsDiscussionsOpenedPayload) => void,
] {
  const dispatch = useDispatch()
  const isOpened = useSelector(selectIsDiscussionsOpened)
  const boardId = useBoardId()

  const [isUrlOpened, setUrlOpened] = useSyncDeeplinkValue<boolean>({
    key: 'comments',
    defaultValue: false,
  })

  const setHandler = useCallback(
    (payload: SetIsDiscussionsOpenedPayload) => {
      dispatch(setIsDiscussionsOpened(payload))
      setUrlOpened(payload === true ? true : false)
    },
    [dispatch, setUrlOpened],
  )

  useEffect(() => {
    if (!isOpened && isUrlOpened) {
      setHandler(true)
    }
  }, [isOpened, isUrlOpened, setHandler])

  return [(isOpened || isUrlOpened) && !!boardId, setHandler]
}

export function useCurrentDiscussionObjectId(): [
  Maybe<string>,
  (payload: SetCurrentDiscussionObjectIdPayload) => void,
] {
  const dispatch = useDispatch()
  const currentDiscussionObjectId = useSelector(selectCurrentDiscussionObjectId)

  const setHandler = useCallback(
    (payload: SetSidebarSubmenuPayload) => {
      dispatch(setCurrentDiscussionObjectId(payload))
    },
    [dispatch],
  )

  return [currentDiscussionObjectId, setHandler]
}
