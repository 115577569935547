import { styled } from '@mui/material'

export const StyledChartAndSettingsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  padding-right: 40px;
  overflow: hidden;
`
