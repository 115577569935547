import React, { useEffect } from 'react'
import { IToolPanelParams } from 'ag-grid-community'
import { LIST_SETTINGS_TOOL_PANEL_ID } from './tool-panel-definition'
import { useIsToolPanelOpened } from '../../shared/aggrid-tool-panel/useIsToolPanelOpened'
import { ListSettingsPanel } from '../ListSettingsPanel'
import { useDataContext } from '../connection/data/data-context'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { useActionsContext } from '../connection/actions/actions-context'

export type ListSettingsToolPanelProps = IToolPanelParams

export const ListSettingsToolPanel: React.FC<ListSettingsToolPanelProps> = ({
  api,
}) => {
  const isOpened = useIsToolPanelOpened(LIST_SETTINGS_TOOL_PANEL_ID, api)
  const { isLoaded, isLoading, load, list } = useDataContext()
  const { flow } = useActionsContext()

  useEffect(() => {
    if (isOpened && !isLoaded && !isLoading) {
      load()
    }
  }, [isLoaded, isLoading, isOpened, load])

  useEffect(() => {
    if (list && !list.isCalculated()) {
      api.openToolPanel(LIST_SETTINGS_TOOL_PANEL_ID)
    }
    if (flow === null) {
      api.closeToolPanel()
    }
  }, [api, flow, list])

  if (!isLoaded || isLoading) {
    return <CenteredCircularProgress fullScreen={false} />
  }

  return <ListSettingsPanel />
}
