import { useQuery, QueryKey } from 'react-query'
import { getBoard } from '../api'
import type { BoardsApiError } from '../api'
import type { Board } from '../types'
import { Maybe } from '@fintastic/shared/util/types'

export const boardQueryKeyGetter = (boardId?: Maybe<Board['id']>) => {
  const key: QueryKey = `board/item/${boardId}`
  return key
}

export type UseBoardQueryParams = {
  boardId?: Maybe<Board['id']>
}

export function useBoardQuery(params: UseBoardQueryParams) {
  const { boardId } = params

  return useQuery<Board, BoardsApiError>(
    boardQueryKeyGetter(boardId),
    async () => {
      const response = await getBoard(boardId as string)
      return response.data
    },
    {
      enabled: !!boardId,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      staleTime: Infinity,
      retry: 1,
    },
  )
}
