import type { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import type { Maybe } from '@fintastic/shared/util/types'
import type { ColDef } from 'ag-grid-community'
import { idLooksLikeDimensionValue } from '@fintastic/web/util/dimensions'
import { parseFormattedNumber } from '../utils/parse-formatted-number'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'

export type TargetCell<TData extends BaseGridRow = BaseGridRow> = {
  colDef: ColDef<TData>
  value?: Maybe<string | number> | undefined
}

export type CellDataType =
  | 'TEXT'
  | 'BOOLEAN'
  | 'DATE'
  | 'CURRENCY'
  | 'NUMERIC'
  | 'INTEGER'
  | 'PERCENT_NUMERIC'
  | 'PERCENT_INTEGER'
  | 'DIMENSION_ID'
  | 'UNKNOWN'

export const cellDataTypeIsNumeric = (dataType: CellDataType) =>
  dataType === 'NUMERIC' ||
  dataType === 'INTEGER' ||
  dataType === 'PERCENT_NUMERIC' ||
  dataType === 'PERCENT_INTEGER' ||
  dataType === 'CURRENCY'

export const cellDataTypeIsPureNumeric = (dataType: CellDataType) =>
  dataType === 'NUMERIC' || dataType === 'INTEGER' || dataType === 'CURRENCY'

export const cellDataTypeIsInteger = (dataType: CellDataType) =>
  dataType === 'INTEGER' || dataType === 'PERCENT_INTEGER'

export const cellDataTypeIsPercentage = (dataType: CellDataType) =>
  dataType === 'PERCENT_NUMERIC' || dataType === 'PERCENT_INTEGER'

export const cellDataTypeIsCurrency = (dataType: CellDataType) =>
  dataType === 'CURRENCY'

export const cellDataTypeAllowEmpty = (dataType: CellDataType) =>
  dataType === 'TEXT' ||
  dataType === 'BOOLEAN' ||
  dataType === 'NUMERIC' ||
  dataType === 'INTEGER' ||
  dataType === 'PERCENT_NUMERIC' ||
  dataType === 'PERCENT_INTEGER' ||
  dataType === 'DATE' ||
  dataType === 'CURRENCY' // no Dimensions!

export const getCellDataType = <TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
): CellDataType => {
  if (cellIsBoolean(target)) {
    return 'BOOLEAN'
  }

  if (cellIsDate(target)) {
    return 'DATE'
  }

  if (cellIsCurrency(target)) {
    return 'CURRENCY'
  }

  if (cellIsInteger(target)) {
    return 'INTEGER'
  }

  if (cellIsPercentage(target)) {
    return 'PERCENT_NUMERIC'
  }

  if (cellIsPercentageInteger(target)) {
    return 'PERCENT_INTEGER'
  }

  if (cellIsNumeric(target)) {
    // must be the last of numeric-like values
    return 'NUMERIC'
  }

  if (cellIsText(target)) {
    return 'TEXT'
  }

  if (
    target?.value &&
    idLooksLikeDimensionValue((target?.value || '').toString())
  ) {
    return 'DIMENSION_ID'
  }

  if (
    !target?.value &&
    target?.colDef?.cellEditorParams?.dataType === 'dimension'
  ) {
    return 'DIMENSION_ID'
  }

  return 'UNKNOWN'
}

function cellIsDate<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return target?.colDef.cellEditor === 'dateCellEditor'
}

function cellIsBoolean<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return target?.colDef.cellEditor === 'checkboxCellEditor'
}

function cellIsNumeric<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return target?.colDef.cellEditor === 'numericCellEditor'
}

function cellIsCurrency<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return (
    target?.colDef.cellEditor === 'numericCellEditor' &&
    target?.colDef.cellEditorParams?.dataType === 'currency'
  )
}

function cellIsInteger<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return (
    target?.colDef.cellEditor === 'numericCellEditor' &&
    target?.colDef.cellEditorParams?.dataType === 'integer'
  )
}

function cellIsPercentage<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return (
    target?.colDef.cellEditor === 'numericCellEditor' &&
    target?.colDef.cellEditorParams?.dataType === 'percentage'
  )
}

function cellIsPercentageInteger<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return (
    target?.colDef.cellEditor === 'numericCellEditor' &&
    target?.colDef.cellEditorParams?.dataType === 'percentage_integer'
  )
}

function cellIsText<TData extends BaseGridRow = BaseGridRow>(
  target?: Maybe<TargetCell<TData>>,
) {
  return target?.colDef.cellEditor === 'textCellEditor'
}

const booleanTrueValues = ['1']
const booleanFalseValues = ['0', '', '-']

export const isBooleanTrue = (value: string) =>
  booleanTrueValues.includes(value.toLowerCase())

export const isBooleanFalse = (value: string) =>
  booleanFalseValues.includes(value.toLowerCase())

export const isValidBoolean = (value: string) =>
  isBooleanTrue(value) || isBooleanFalse(value)

export const isValidNumber = (value: string) =>
  !Number.isNaN(parseFormattedNumber(value)) // float is wider than integer

// * - from the ticket
// https://fintastic.atlassian.net/browse/FIN-7923
export const supportedDateFormats = [
  DEFAULT_DAYJS_DATE_FORMAT, // *
  'MM-DD-YYYY',
  'DD-ABC-YYYY',
]

const monthAbbrs = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function normalizeAbbreviatedDate(date: string) {
  const idx = monthAbbrs.findIndex((m) =>
    date.toLowerCase().includes(m.toLowerCase()),
  )

  if (idx < 0) {
    return ''
  }

  const strWithMonthIndex = date
    .toLowerCase()
    .replace(
      monthAbbrs[idx].toLowerCase(),
      (idx + 1).toString().padStart(2, '0'),
    )

  // separator here always '-'
  const parts = strWithMonthIndex.split('-')
  const result = parts.map((p) => p.padStart(2, '0')).join('-')

  // 1-MON-XXXX -> 01-MON-XXXX
  // 10-MON-XX -> invalid
  return result.length === 10 || result.length === 11 ? result : ''
}

export const isValidDate = (date: string): boolean =>
  !!firstValidFormattedDate(date)

export const isAbbreviatedDate = (date: string): boolean =>
  monthAbbrs.some((m) =>
    date.toLowerCase().includes('-' + m.toLowerCase() + '-'),
  )

export const firstValidFormattedDate = (date: string): string | null => {
  let normDateStr = date.trim().replaceAll('/', '-').replaceAll('.', '-')

  if (isAbbreviatedDate(normDateStr)) {
    normDateStr = normalizeAbbreviatedDate(normDateStr)

    if (dayjs(normDateStr, 'DD-MM-YYYY').isValid()) {
      return dayjs(normDateStr, 'DD-MM-YYYY').format(DEFAULT_DAYJS_DATE_FORMAT)
    }

    if (dayjs(normDateStr, 'YYYY-MM-DD').isValid()) {
      return dayjs(normDateStr, 'YYYY-MM-DD').format(DEFAULT_DAYJS_DATE_FORMAT)
    }
    return null
  }

  // transform 1-1-2024 -> 01-01-2024
  const parts = normDateStr.split('-')
  normDateStr = parts.map((p) => p.padStart(2, '0')).join('-')

  if (!/(\d{2,4})-(\d{2,4})-(\d{2,4})/.test(normDateStr)) {
    return null
  }

  let mm = ''
  let dd = ''

  const regTest1 = /(\d{2})-(\d{2})-(\d{4})/.exec(normDateStr)
  if (regTest1 && regTest1.length > 2) {
    // mm-dd-yyyy
    mm = regTest1[1]
    dd = regTest1[2]
  } else {
    const regTest2 = /(\d{4})-(\d{2})-(\d{2})/.exec(normDateStr)
    if (regTest2 && regTest2.length > 3) {
      // yyyy-mm-dd
      mm = regTest2[2]
      dd = regTest2[3]
    }
  }
  if (!mm || !dd) {
    return null
  }

  if (parseInt(mm, 10) > 12 || parseInt(mm, 10) < 1) {
    return null
  }

  if (parseInt(dd, 10) > 31 || parseInt(dd, 10) < 1) {
    return null
  }

  const format = supportedDateFormats.find((format) =>
    dayjs(normDateStr, format).isValid(),
  )

  if (!format || !dayjs(normDateStr, format).isValid()) {
    return null
  }

  return dayjs(normDateStr, format).format(DEFAULT_DAYJS_DATE_FORMAT)
}

const suspiciousPair = [/\./g, /-/g, /\//g] // 12/12/2012 11-Jan-2008 1.01.2023

export const removeSigns = (value: string) =>
  (value || '')
    .replaceAll('-', '')
    .replaceAll('+', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('%', '')
    .replaceAll('$', '')

export const isStringLooksLikeDate = (value: string) => {
  if (!value || value === '' || value.includes(',')) {
    // 100.200.300,22 => number, not a date
    return false
  }

  const counts = suspiciousPair.map(
    (matcher) => (value.match(matcher) || []).length,
  )

  if (value.indexOf('.') !== -1) {
    // special case for 1.200.300
    const parts = removeSigns(value).split('.')
    if (!parts.some((part) => parseFloat(part).toString(10) !== part)) {
      return false
    }
  }

  return counts.some((v) => v === 2)
}
