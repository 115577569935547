import React, { useContext, useMemo } from 'react'
import { Modal } from '@fintastic/shared/ui/modal-framework'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  CollapsePanel,
  ErrorAlert,
  LoadingButton,
} from '@fintastic/shared/ui/components'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateRangeSelector } from '../components/DateRangeSelector'
import { ImportEntitiesTableConnector } from '../components/ImportEntitiesTableConnector'
import { Box, Button, Tooltip } from '@mui/material'
import { ImportActualsConfigContext } from '../context/ImportActualsConfigContext'
import { ImportModalTitle } from './ImportModalTitle'
import { useVersionUserLockQuery } from '@fintastic/web/data-access/versions'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'

export type ImportModalContainerProps = {
  isOpen: boolean
  isLoading: boolean
  isCalculating: boolean
  isError: boolean
  onAskForConfirmation: () => void
  onRequestClose: () => void
}

export const ImportModalContainer: React.FC<ImportModalContainerProps> = ({
  onAskForConfirmation,
  onRequestClose,
  isOpen,
  isLoading,
  isCalculating,
  isError,
}) => {
  const { dateFrom, dateTo, selectedItems, versionId } = useContext(
    ImportActualsConfigContext,
  )

  const { lock } = useVersionUserLockQuery(versionId)

  const importButton = useMemo(() => {
    if (!isOpen) {
      return null
    }

    if (lock.editIsBlocked) {
      return (
        <Tooltip title={versionIsLockedMessage(lock, 'Import')} arrow>
          <span>
            <LoadingButton
              disabled
              sx={{ ml: 1 }}
              variant="contained"
              color="primary"
            >
              Import
            </LoadingButton>
          </span>
        </Tooltip>
      )
    }

    return (
      <LoadingButton
        loading={isLoading}
        onClick={onAskForConfirmation}
        disabled={!dateFrom || !dateTo || !selectedItems.length}
        loadingText={
          isLoading ? (isCalculating ? 'Calculating...' : 'Loading...') : ''
        }
        sx={{ ml: 1 }}
        variant="contained"
        color="primary"
      >
        Import
      </LoadingButton>
    )
  }, [
    dateFrom,
    dateTo,
    isCalculating,
    isLoading,
    isOpen,
    lock,
    onAskForConfirmation,
    selectedItems.length,
  ])

  if (!isOpen) {
    return null
  }

  return (
    <Modal
      open={isOpen}
      onRequestClose={isLoading ? () => void 0 : onRequestClose}
      showCloseButton={!isLoading}
      title="Import Live Actuals"
      keepMounted={true}
      width={'80vw'}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangeSelector />
        <CollapsePanel
          titleComponent={
            <ImportModalTitle
              versionId={versionId}
              selectedItemsCount={selectedItems.length}
            />
          }
        >
          <Box
            p={0}
            minHeight="150px"
            maxHeight="45vh"
            height="650px"
            display="flex"
            width="100%"
          >
            <ImportEntitiesTableConnector />
          </Box>
        </CollapsePanel>
        {isError && (
          <Box mb={1} mt={1}>
            <ErrorAlert
              title="Import failed. No data was imported."
              message="Please try again or contact support if this issue persists."
              severity="warning"
            />
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <Button
            variant="outlined"
            onClick={onRequestClose}
            disabled={isCalculating}
          >
            Cancel
          </Button>
          {importButton}
        </Box>
      </LocalizationProvider>
    </Modal>
  )
}
