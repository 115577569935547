import { ThemeComponents } from './types'
import { styleKit } from '../styleKit'

export const MuiButton: ThemeComponents['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: {
      'paddingLeft': styleKit.spacing.base,
      'paddingRight': styleKit.spacing.base,
      'minWidth': 0,
      '.MuiTouchRipple-root': {
        display: 'none',
      },
    },

    sizeSmall: {
      paddingTop: 4,
      paddingBottom: 4,
      height: 24,
      fontSize: `${11 / styleKit.typography.defaults.fontSize}rem`,
    },
    sizeMedium: {
      paddingTop: 8,
      paddingBottom: 8,
      height: 32,
    },
    sizeLarge: {
      paddingTop: 10,
      paddingBottom: 10,
      height: 40,
    },

    contained: {
      'border': '1px solid rgba(0, 0, 0, 0.15)',

      '&:disabled': {
        borderColor: styleKit.colors.general.dividers,
        background: styleKit.colors.general.lightGrey,
        color: styleKit.colors.general.grey,
      },
    },
    containedPrimary: {
      'background': styleKit.colors.orange.dark,
      'color': styleKit.colors.general.white,
      'borderColor': styleKit.colors.orange.dark2,

      '&:hover, &.Mui-focusVisible': {
        background: styleKit.colors.orange.dark2,
      },
    },

    text: {
      '&:hover, &.Mui-focusVisible': {
        background: 'none',
      },
      '&:disabled': {
        color: styleKit.colors.general.grey,
      },
    },
    textSizeSmall: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    textSizeMedium: {
      paddingTop: 9,
      paddingBottom: 9,
    },
    textSizeLarge: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    textPrimary: {
      'color': styleKit.colors.orange.dark,
      '&:hover, &.Mui-focusVisible': {
        color: styleKit.colors.orange.dark2,
      },
    },

    outlined: {
      '&:disabled': {
        borderColor: styleKit.colors.general.dividers,
        background: styleKit.colors.general.lightGrey,
        color: styleKit.colors.general.grey,
      },
    },
    outlinedPrimary: {
      'background': styleKit.colors.general.white,
      'borderColor': styleKit.colors.general.dividers,
      'color': styleKit.colors.general.black2,

      '&:hover, &.Mui-focusVisible': {
        background: styleKit.colors.general.notEditable,
        borderColor: styleKit.colors.general.dividers,
      },
    },
  },
}
