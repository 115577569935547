import { Period, PeriodsRange } from '../types'
import { Maybe } from '@fintastic/shared/util/types'

const mapToIndexes = (
  range: PeriodsRange,
  allowedOrderedPeriods: Period[],
): Maybe<[number, number]> => {
  const indexes: [number, number] = [
    allowedOrderedPeriods.indexOf(range[0]),
    allowedOrderedPeriods.indexOf(range[1]),
  ]

  return indexes.includes(-1) ? null : indexes
}

export const rangeFromChangedFrom = (
  range: PeriodsRange,
  from: Period,
  allowedOrderedPeriods: Period[],
): Maybe<PeriodsRange> => {
  const newRange: PeriodsRange = [from, range[1]]
  if (newRange[0] === newRange[1]) {
    return newRange
  }

  const indexes = mapToIndexes(newRange, allowedOrderedPeriods)
  if (!indexes) {
    return null
  }

  const [fromIndex, toIndex] = indexes
  if (toIndex >= fromIndex) {
    return newRange
  }

  let adjustedToIndex = fromIndex + 1
  const maxIndex = allowedOrderedPeriods.length - 1
  if (adjustedToIndex > maxIndex) {
    adjustedToIndex = maxIndex
  }

  return [from, allowedOrderedPeriods[adjustedToIndex]]
}

export const rangeFromChangedTo = (
  range: PeriodsRange,
  to: Period,
  allowedOrderedPeriods: Period[],
): Maybe<PeriodsRange> => {
  const newRange: PeriodsRange = [range[0], to]
  if (newRange[0] === newRange[1]) {
    return newRange
  }

  const indexes = mapToIndexes(newRange, allowedOrderedPeriods)
  if (!indexes) {
    return null
  }

  const [fromIndex, toIndex] = indexes
  if (toIndex >= fromIndex) {
    return newRange
  }

  let adjustedFromIndex = toIndex - 1
  const minIndex = 0
  if (adjustedFromIndex < minIndex) {
    adjustedFromIndex = minIndex
  }

  return [allowedOrderedPeriods[adjustedFromIndex], to]
}
