import React, { useCallback } from 'react'
import { MetricChartSortableColumns } from './MetricChartSortableColumns'
import {
  MetricChartDimensions,
  MetricDimensionContent,
} from '../../../../../types'

export type MetricChartColumnsProps = {
  disabled?: boolean
  currentDimensions: MetricChartDimensions
  onColumnsUpdate: (
    dimensions: MetricChartDimensions,
    initial?: boolean,
  ) => void
}

export const MetricChartGrouping: React.FC<MetricChartColumnsProps> = ({
  disabled,
  currentDimensions,
  onColumnsUpdate,
}) => {
  const handleSorted = useCallback(
    (dimensions: MetricChartDimensions) => {
      onColumnsUpdate(dimensions)
    },
    [onColumnsUpdate],
  )

  const handleSetDimensionAggregateState = useCallback(
    (id: string, checked: boolean) => {
      const sortedDims = currentDimensions.map((d: MetricDimensionContent) =>
        d.id === id ? { ...d, aggregate: checked } : d,
      )

      onColumnsUpdate(sortedDims)
    },
    [onColumnsUpdate, currentDimensions],
  )

  return (
    <MetricChartSortableColumns
      disabled={disabled}
      dimensions={currentDimensions}
      onSetDimensionsOrder={handleSorted}
      onSetDimensionAggregateState={handleSetDimensionAggregateState}
    />
  )
}
