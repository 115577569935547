import React from 'react'
import {
  StyledRoot,
  StyledItem,
  StyledItemLabel,
  StyledItemValue,
  StyledProperties,
  StyledProperty,
  StyledPropertyItems,
  StyledPropertyTitle,
} from './PropertiesList.styled'
import { FintasticMuiSxProps } from '@fintastic/shared/ui/mui-theme'

export type PropertiesListProperty = {
  key: string | number
  label: React.ReactNode
  labelSx?: FintasticMuiSxProps
  items: PropertiesListItem[]
}

export type PropertiesListItem = {
  key: string | number
  label?: React.ReactNode
  labelSx?: FintasticMuiSxProps
  value: React.ReactNode
  valueSx?: FintasticMuiSxProps
}

export type PropertiesListProps = {
  properties: PropertiesListProperty[]
  variant?: 'default' | 'compact'
}

export const PropertiesList: React.FC<PropertiesListProps> = ({
  properties,
  variant = 'default',
}) => (
  <StyledRoot>
    <StyledProperties>
      {properties.map((property) => (
        <StyledProperty
          key={property.key}
          sx={{ padding: variant === 'compact' ? 1 : undefined }}
        >
          <StyledPropertyTitle
            sx={{
              ...property.labelSx,
              marginBottom: variant === 'compact' ? '0' : undefined,
            }}
          >
            {property.label}
          </StyledPropertyTitle>
          <StyledPropertyItems>
            {property.items.map((item) => (
              <StyledItem key={item.key}>
                {item.label && (
                  <StyledItemLabel sx={item.labelSx}>
                    {item.label}
                  </StyledItemLabel>
                )}
                <StyledItemValue
                  sx={{
                    ...item.valueSx,
                    fontSize: variant === 'compact' ? 12 : undefined,
                  }}
                >
                  {item.value}
                </StyledItemValue>
              </StyledItem>
            ))}
          </StyledPropertyItems>
        </StyledProperty>
      ))}
    </StyledProperties>
  </StyledRoot>
)
