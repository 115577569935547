import React, { useEffect } from 'react'
import { useListEditorApi } from '../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../features/active/makeApi'
import { NewListEditor } from './new-list'
import { ExistingListEditor } from './existing-list'
import { useLocation } from '@fintastic/shared/util/memory-router'
import { listMemoryRoutesPaths } from '@fintastic/web/feature/metrics-and-lists'
import { EditableListWithoutData } from '@fintastic/web/data-access/metrics-and-lists'
import {
  SettingsPanelContextsWrapper,
  useFormulasTabs,
  FormulasTabContextProvider,
} from '@fintastic/web/feature/lists'
import {
  useVersionEntitiesContextValue,
  VersionEntitiesContext,
} from '@fintastic/web/data-access/versions'
import { SetGridSizeCallback } from '@fintastic/shared/ui/grid-framework'

const defaultInitialLocation = [listMemoryRoutesPaths.list]

export const ListEditor: React.FC<{
  setGridSizeCallback: SetGridSizeCallback
}> = ({ setGridSizeCallback }) => {
  const api = useListEditorApi() as ActiveListEditorApi
  const { isNew, list, versionMetadata } = api

  const location = useLocation(defaultInitialLocation)
  const formulasTabsState = useFormulasTabs()
  const { openListFormula } = formulasTabsState

  const entitiesContextValue = useVersionEntitiesContextValue(
    !versionMetadata?.uuid ? [] : [versionMetadata.uuid],
  )

  useEffect(() => {
    if (list.source === 'calculated') {
      openListFormula()
    }
  }, [list.source, openListFormula])

  useEffect(() => {
    setGridSizeCallback({
      // fixed sizes used because it anyway works
      headerHeight: 40,
      rowHeight: 40,
      rows: 1,
    })
  }, [setGridSizeCallback])

  const content = isNew ? <NewListEditor /> : <ExistingListEditor />

  return (
    <VersionEntitiesContext.Provider value={entitiesContextValue}>
      <FormulasTabContextProvider value={formulasTabsState}>
        <SettingsPanelContextsWrapper
          list={list as unknown as EditableListWithoutData}
          editingAllowed={true}
          version={versionMetadata}
          location={location}
          allowExistingColumnsDeletion={false}
          requestEntityDeletion={console.log}
        >
          {content}
        </SettingsPanelContextsWrapper>
      </FormulasTabContextProvider>
    </VersionEntitiesContext.Provider>
  )
}
