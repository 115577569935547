import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectChanges } from '../ducks'
import { ChangeType, GroupId, TableAccess } from '../types'

export const usePermissionsFlattenedChanges = (): Array<{
  groupId: GroupId
  changeType: ChangeType
  payload: TableAccess
  changeKey: string
}> => {
  const changes = useSelector(selectChanges)

  const flattendChanges = useMemo(
    () =>
      Object.keys(changes).map((changeKey) => ({
        ...changes[changeKey],
        changeKey,
      })),
    [changes],
  )

  return flattendChanges
}
