import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { useMemo } from 'react'

export const useBoardDefaultVersions = () => {
  const query = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: false,
  })

  const defaultVersions = useMemo(() => {
    if (!query.data?.length) {
      return []
    }

    const liveActualsVersion = query.data.find((i) => i.is_live_actuals)
    return [liveActualsVersion?.uuid || query.data[0].uuid]
  }, [query.data])

  return { query, defaultVersions }
}
