import { useMemo } from 'react'
import type { MetricGridProps } from '../../MetricGrid'
import { MetricDataWithVersionId, MetricShape } from '../../types'
import { DEFAULT_METRIC_SOURCE } from '../metric-source/defaults'
import { validateMetricsShapeInDifferentVersions } from '../multiple-versions/validateMetricsShapeInDifferentVersions'
import { dimensionsLabelMapFromMetricDataList } from '../dimensions/dimensionsLabelMapFromMetricDataList'
import { extractVersionMetricsList } from '../multiple-versions/extractVersionMetricsList'
import { extractMetricShape } from '../multiple-versions/extractMetricShape'
import { Version, VersionEntitiesWrapper } from '@fintastic/web/util/versions'
import { hideMaskedValuesMemorized } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

// @todo add tests
export function useMetricGridDataProps(
  metricId: string,
  validVersions: MetricDataWithVersionId[],
  versionsData: Record<string, Version>,
  weightMetrics?: MetricDataWithVersionId[],
  entities?: Record<string, Maybe<VersionEntitiesWrapper>>,
): Pick<MetricGridProps, 'data' | 'dimensions' | 'weightedAverageData'> &
  MetricShape {
  const cachedVersionsData = useMemo(
    () => versionsData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.entries(versionsData)
        .map(([k, v]) => `${k}-${v.name}-${v.editable}`)
        .join(';'),
    ],
  )

  return useMemo<
    Pick<MetricGridProps, 'data' | 'dimensions' | 'weightedAverageData'> &
      MetricShape
  >(() => {
    if (validVersions.length === 0) {
      return {
        data: [],
        dimensions: {},
        source: DEFAULT_METRIC_SOURCE,
      }
    }

    if (!validateMetricsShapeInDifferentVersions(validVersions)) {
      throw new Error('Metrics types or roll-ups are different')
    }

    const processedVersions = validVersions.map((version) => {
      if (version.metric === null) {
        return version
      }

      version.metric.data.values = hideMaskedValuesMemorized(
        version.metric.data.values,
        version.metric.metadata.value.mask,
      )

      return version
    })

    return {
      // @todo remove dimensions metadata usage
      dimensions: dimensionsLabelMapFromMetricDataList(
        processedVersions,
        entities,
      ),
      data: extractVersionMetricsList(
        metricId,
        processedVersions,
        cachedVersionsData,
      ),
      weightedAverageData: weightMetrics
        ? extractVersionMetricsList(metricId, weightMetrics, cachedVersionsData)
        : undefined,
      ...extractMetricShape(processedVersions[0]),
    }
  }, [validVersions, entities, metricId, cachedVersionsData, weightMetrics])
}
