import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { historyLogEntryAdt } from '@fintastic/web/util/history'
import { SupportedEntry } from '../../../supported-entry-types'

export const CreatedAtCellRenderer: React.FC<
  ICellRendererParams<SupportedEntry>
> = ({ data }) => {
  if (!data) {
    return ''
  }

  const timestamp = historyLogEntryAdt.getTimestamp(data)
  const gmt = historyLogEntryAdt.getTimestampGmtTimeZone(data)

  return (
    <span>
      {timestamp}
      <span style={{ opacity: '0.3', marginLeft: '0.4em' }}>({gmt})</span>
    </span>
  )
}
