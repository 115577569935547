import { styled } from '@mui/material'

export const NoSharedBoardsMessageRootStyled = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
`
