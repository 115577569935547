import { Maybe } from '@fintastic/shared/util/types'
import { Version } from '../version-state/types'
import { useEffect, useMemo } from 'react'
import { VersionPermissionsApi } from './types'

export function useVersionPermissions(
  version: Maybe<Version>,
  discardChanges: () => void,
): VersionPermissionsApi {
  useEffect(() => {
    if (version?.locked) {
      discardChanges()
    }
  }, [discardChanges, version?.locked])

  const versionDefined = version !== null
  return useMemo(
    () => ({
      editingAllowed: !versionDefined ? false : !version.locked,
      editingBlockedTooltip: {
        priority: 1000,
        text: 'Version is locked',
      },
    }),
    [version?.locked, versionDefined],
  )
}
