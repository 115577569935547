import { AuditLogEntityType } from '../types'
import React, { ReactElement } from 'react'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import { WidgetListIcon, WidgetMetricIcon } from '@fintastic/shared/ui/icons'

type EntityTypeRecord = { name: string; icon: ReactElement }

export const auditLogEntityTypes: Record<AuditLogEntityType, EntityTypeRecord> =
  {
    metric: {
      name: 'Metric',
      icon: (
        <WidgetMetricIcon
          sx={{
            color: '#2C45FE',
          }}
        />
      ),
    },
    list: {
      name: 'List',
      icon: (
        <WidgetListIcon
          sx={{
            color: '#047D34',
          }}
        />
      ),
    },
  }

export function isEntityType(
  valueType: unknown,
): valueType is AuditLogEntityType {
  return auditLogEntityTypes[valueType as AuditLogEntityType] !== undefined
}

export function getEntityTypeName(entityType: unknown): string {
  if (isEntityType(entityType)) {
    return auditLogEntityTypes[entityType].name
  }

  return String(entityType)
}

export function getEntityTypeIcon(entityType: unknown): ReactElement {
  if (isEntityType(entityType)) {
    return auditLogEntityTypes[entityType].icon
  }

  return (
    <QuestionMarkOutlinedIcon sx={{ width: 16, height: 16, color: 'red' }} />
  )
}

export const entityTypesSelectOptions: Array<{
  label: string
  value: AuditLogEntityType
}> = Object.keys(auditLogEntityTypes).map((key) => ({
  value: key as AuditLogEntityType,
  label: getEntityTypeName(key),
}))
