import { Nominal, Maybe } from '@fintastic/shared/util/types'

export type Iso8601DateTime = Nominal<string, 'Iso8601DateTime'>
export type Iso8601UtcDateTime = Nominal<string, 'Iso8601UtcDateTime'>

export const toUtc = (s: Iso8601DateTime): Iso8601UtcDateTime =>
  // Date.toISOString always returns date in +00:00 timezone
  toDate(s).toISOString() as Iso8601UtcDateTime

export const toDate = (s: Iso8601DateTime | Iso8601UtcDateTime): Date =>
  new Date(s)

export const fromDate = (date: Date): Iso8601DateTime =>
  date.toISOString() as Iso8601DateTime

export const fromString = (s: string): Maybe<Iso8601DateTime> => {
  const date = new Date(s)
  if (Number.isNaN(date)) {
    return null
  }
  return date.toISOString() as Iso8601DateTime
}
