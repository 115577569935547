import { useCallback, useMemo, useState } from 'react'

type EventId = string

export const useAwaitingEventIds = () => {
  const [ids, setIds] = useState<EventId[]>([])

  const add = useCallback((id: EventId) => {
    setIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds
      }
      return [...prevIds, id]
    })
  }, [])

  const remove = useCallback((id: EventId) => {
    setIds((prevIds) => prevIds.filter((existingId) => existingId !== id))
  }, [])

  return useMemo(
    () =>
      ({
        ids,
        add,
        remove,
      } as const),
    [add, ids, remove],
  )
}
