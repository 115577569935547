import { VersionDateRangeForm } from '../VersionDateRangeForm'
import React, { useCallback, useMemo } from 'react'
import { useVersionDateRangeInputState } from '../useVersionDateRangeInputState'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import dayjs from 'dayjs'
import { useExtendVersion } from '@fintastic/web/data-access/versions'
import { Version } from '@fintastic/web/util/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { FormLayout } from '@fintastic/shared/ui/form-framework'

export type VersionDateRangeChangeFormProps = {
  version: Version
  closeParentModal: () => void
}
export const VersionDateRangeChangeForm: React.FC<
  VersionDateRangeChangeFormProps
> = (props) => {
  const { version, closeParentModal } = props
  const inputDateRangeState = useVersionDateRangeInputState(version)

  const minEndDate = useMemo(
    () => dayjs(version.period_end),
    [version.period_end],
  )
  const maxEndDate = useMemo(
    () => dayjs(version.period_start).add(20, 'years'),
    [version.period_start],
  )

  const extendVersionMutation = useExtendVersion(version.uuid)

  const handleDateRangeChange = useCallback(() => {
    if (!inputDateRangeState.startDate || !inputDateRangeState.endDate) {
      return
    }

    const newPeriodEnd = new Date(
      inputDateRangeState.endDate
        .endOf('month')
        .format(DEFAULT_DAYJS_DATE_FORMAT),
    )

    extendVersionMutation.mutate(
      dayjs(newPeriodEnd).format(DEFAULT_DAYJS_DATE_FORMAT),
      {
        onSuccess: () => {
          toast.success('The version has been successfully extended.')
        },
        onError: () => {
          toast.error(
            'An error occurred during the version extension. Please try again or contact support.',
          )
        },
      },
    )

    closeParentModal()
  }, [
    inputDateRangeState.startDate,
    inputDateRangeState.endDate,
    extendVersionMutation,
    closeParentModal,
  ])
  return (
    <FormLayout
      submitButtonText="Extend"
      onSubmit={handleDateRangeChange}
      testIdPrefix="extend"
    >
      <VersionDateRangeForm
        inputState={inputDateRangeState}
        fullWidth
        minEndDate={minEndDate}
        maxEndDate={maxEndDate}
      />
    </FormLayout>
  )
}
