import React, { useCallback, useMemo } from 'react'
import { Typography } from '@mui/material'
import { FormLayout } from '@fintastic/shared/ui/form-framework'
import {
  generateErrorMessage,
  generateSuccessMessage,
  useSelectedVersions,
} from '../utils'
import { isVersionArchived } from '@fintastic/web/util/versions'
import { useUnarchiveListOfVersions } from '@fintastic/web/data-access/versions'
import { Version } from '@fintastic/web/util/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'

export type VersionsRetrieveFormProps = {
  selectedVersions: Version[]
  closeParentModal: () => void
  onAfterSuccess?: () => void
}

export const VersionsRetrieveForm: React.FC<VersionsRetrieveFormProps> = (
  props,
) => {
  const { closeParentModal, selectedVersions, onAfterSuccess } = props
  const { singleVersionSelected, versionNamesTitle } =
    useSelectedVersions(selectedVersions)

  const unarchiveVersionsMutation = useUnarchiveListOfVersions(
    useMemo(
      () =>
        selectedVersions
          .filter((version) => isVersionArchived(version))
          .map((v) => v.uuid),
      [selectedVersions],
    ),
  )

  const handleSubmit = useCallback(() => {
    unarchiveVersionsMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(generateSuccessMessage(selectedVersions, 'Retrieved'))
        onAfterSuccess?.()
      },
      onError: () => {
        toast.error(generateErrorMessage(selectedVersions, 'Retrieve'))
      },
    })
    if (closeParentModal) {
      closeParentModal()
    }
  }, [
    unarchiveVersionsMutation,
    closeParentModal,
    selectedVersions,
    onAfterSuccess,
  ])

  return (
    <FormLayout
      submitButtonText="Retrieve"
      onSubmit={handleSubmit}
      testIdPrefix="retrieve"
    >
      <Typography sx={{ marginBottom: 3, whiteSpace: 'pre-line' }}>
        {versionNamesTitle}
      </Typography>
      <Typography>{`Are you sure you want to retrieve ${
        singleVersionSelected ? 'this' : selectedVersions.length
      } version${singleVersionSelected ? '' : 's'}?`}</Typography>
    </FormLayout>
  )
}
