import { Box, styled } from '@mui/material'

export const StyledWidgetResizeHandlerRoot = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: nwse-resize;

  &:after {
    content: '';

    position: absolute;
    bottom: 3px;
    right: 3px;

    width: 11px;
    height: 11px;
    border-radius: 0 0 100% 0;

    border: 2px solid ${({ theme }) => theme.palette.grey[500]};
    border-top: none;
    border-left: none;
    transition: all 0.2s ease-in-out;
  }

  &:hover:after {
    border-color: ${({ theme }) => theme.palette.primary.dark} !important;
  }
`
