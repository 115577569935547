import type { Maybe } from '@fintastic/shared/util/types'
import { useQuery } from 'react-query'
import { iamQueryKeys } from '../../utils/query-keys'
import { IAMResourceType } from '../../types'
import { getIAMResources } from '../../api/resources'

export const useIAMResourcesQuery = (resource_type: Maybe<IAMResourceType>) =>
  useQuery(
    iamQueryKeys.resources(resource_type),
    () => {
      if (!resource_type) {
        throw new Error(
          'Cannot load IAM resources with no resource_type defined',
        )
      }
      return getIAMResources(resource_type)
    },
    {
      refetchOnMount: false,
      enabled: !!resource_type,
    },
  )
