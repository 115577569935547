import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DuplicateIcon = createSvgIcon(
  <>
    <path d="M14.625 10.125C14.625 9.71079 14.2892 9.375 13.875 9.375C13.4608 9.375 13.125 9.71079 13.125 10.125V13.125H10.125C9.71079 13.125 9.375 13.4608 9.375 13.875C9.375 14.2892 9.71079 14.625 10.125 14.625H13.125V17.625C13.125 18.0392 13.4608 18.375 13.875 18.375C14.2892 18.375 14.625 18.0392 14.625 17.625V14.625H17.625C18.0392 14.625 18.375 14.2892 18.375 13.875C18.375 13.4608 18.0392 13.125 17.625 13.125H14.625V10.125Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 1.5L5.24778 1.5C4.25471 1.50294 3.30315 1.89874 2.60095 2.60095C1.89874 3.30316 1.50294 4.25471 1.5 5.24778L1.5 5.25V15.375L1.5 15.3771C1.50255 16.2709 1.85872 17.1273 2.4907 17.7593C3.12268 18.3913 3.97911 18.7475 4.87287 18.75L4.875 18.75H5.25V19.0781C5.25 20.968 6.78203 22.5 8.67188 22.5H19.0781C20.968 22.5 22.5 20.968 22.5 19.0781V8.67188C22.5 6.78203 20.968 5.25 19.0781 5.25H18.7423L18.7498 4.89062L18.75 4.87287C18.7475 3.97911 18.3913 3.12268 17.7593 2.4907C17.1273 1.85872 16.2709 1.50255 15.3771 1.5L5.25 1.5ZM17.9791 6.75C17.9776 6.75 17.9761 6.75 17.9746 6.75H8.67188C7.61045 6.75 6.75 7.61045 6.75 8.67188V19.0781C6.75 20.1395 7.61045 21 8.67188 21H19.0781C20.1395 21 21 20.1395 21 19.0781V8.67188C21 7.61045 20.1395 6.75 19.0781 6.75H17.9791ZM5.25 17.25V8.67188C5.25 6.78203 6.78203 5.25 8.67188 5.25H17.242L17.25 4.86902C17.2464 4.37454 17.0484 3.90117 16.6986 3.55136C16.3471 3.19987 15.8709 3.00167 15.3738 3H5.25119C4.65478 3.00203 4.08335 3.23986 3.66161 3.66161C3.23986 4.08336 3.00203 4.65478 3 5.25119V15.3738C3.00167 15.8709 3.19987 16.3471 3.55136 16.6986C3.90287 17.0501 4.37913 17.2483 4.87621 17.25H5.25Z"
    />
  </>,
  'Duplicate',
)
