import { SectionedModal } from '@fintastic/shared/ui/modal-framework'
import React, { useMemo } from 'react'
import {
  fallbackRoutes,
  useWidgetSettingsDialogContext,
  WidgetSettingsButtonPanel,
  WidgetSettingsDialogIssues,
} from '@fintastic/web/feature/boards'
import { genericReportRoutes } from './GenericReportWidgetSettings/routes/generic-report-routes'
import { listRoutes } from './ListWidgetSettings/routes/list-routes'
import { metricRoutes } from './MetricWidgetSettings/routes/metric-routes'
import { Maybe } from '@fintastic/shared/util/types'
import { DesignerWidgetType } from '../../BoardDesigner/widgets/board-design-type-utils'
import { LIST_GRID_WIDGET_TYPE } from '../ListGridWidget'
import { METRIC_GRID_WIDGET_TYPE } from '../MetricGridWidget'
import { GENERIC_REPORT_WIDGET_TYPE } from '../GenericReportWidget'

export type WidgetSettingsStandardDialogProps = {
  widgetType: Maybe<DesignerWidgetType>
}

export const WidgetSettingsStandardDialog: React.FC<
  WidgetSettingsStandardDialogProps
> = ({ widgetType }) => {
  const { title, onCancel } = useWidgetSettingsDialogContext()

  const routes = useMemo(() => {
    if (widgetType === LIST_GRID_WIDGET_TYPE) {
      return listRoutes
    }

    if (widgetType === METRIC_GRID_WIDGET_TYPE) {
      return metricRoutes
    }

    if (widgetType === GENERIC_REPORT_WIDGET_TYPE) {
      return genericReportRoutes
    }

    return fallbackRoutes
  }, [widgetType])

  // local dialogs
  return (
    <SectionedModal
      title={title}
      onCancel={onCancel}
      routes={routes}
      bottomPanel={<WidgetSettingsDialogIssues />}
      footer={<WidgetSettingsButtonPanel />}
    />
  )
}
