import React, { useMemo } from 'react'
import {
  useLoadVersionsList,
  useVersionUserLocks,
} from '@fintastic/web/data-access/versions'
import { StatusPanel } from './StatusPanel'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type VersionCalculationStatusProps = {
  versionIds: string[]
}

export const VersionCalculationStatus: React.FC<
  VersionCalculationStatusProps
> = ({ versionIds }) => {
  const { data: allVersionList } = useLoadVersionsList({
    versionsIds: versionIds,
    showArchived: true,
    showDeleted: false,
    withLiveActuals: true,
  })
  const versionsList = useMemo(
    () => (allVersionList || []).filter((v) => versionIds.includes(v.uuid)),
    [allVersionList, versionIds],
  )
  const versionUserLocks = useVersionUserLocks(versionIds)

  const lockedBecauseOfCalculation = useMemo(
    () =>
      Object.fromEntries(
        versionUserLocks
          .filter(
            (lock) => lock.lockReasons?.calc || lock.lockReasons?.import_la,
          )
          .map((lock) => [lock.versionId, true]),
      ),
    [versionUserLocks],
  )

  const calculatingVersionNames = useMemo(
    () =>
      versionsList
        .filter(({ uuid }) => lockedBecauseOfCalculation[uuid])
        .map(({ name }) => name),
    [versionsList, lockedBecauseOfCalculation],
  )

  return (
    <FintasticThemeProvider applyLegacyTheme={true}>
      <StatusPanel calculatingVersionNames={calculatingVersionNames} />
    </FintasticThemeProvider>
  )
}
