import { Box, LinearProgress } from '@mui/material'
import React, { useCallback } from 'react'
import { useGlobalDimensions } from '@fintastic/web/data-access/model-globals'
import { RestrictedDimensionsTable } from './RestrictedDimensionsTable'
import { useUnrestrictedDimensionsQuery } from '../../hooks/useUnrestrictedDimensionsQuery'
import { usePatchUnrestrictedDimensionMutation } from '../../hooks/usePatchUnrestrictedDimensionMutation'

export const RestrictedDimensionsContainer: React.FC = () => {
  const dimensionsQuery = useGlobalDimensions(false, false, [
    'manual',
    'dynamic',
  ])
  const unrestrictedDimensionsQuery = useUnrestrictedDimensionsQuery()
  const unrestrictedDimensionsPatchMutation =
    usePatchUnrestrictedDimensionMutation()

  const handleChangeDimensionsRestriction = useCallback(
    ({ dimensionId, allowed }: { dimensionId: string; allowed: boolean }) => {
      unrestrictedDimensionsPatchMutation.mutate({
        dimensionIds: [dimensionId],
        isRestricted: !allowed,
      })
    },
    [unrestrictedDimensionsPatchMutation.mutate],
  )

  const loading =
    unrestrictedDimensionsQuery.isLoading ||
    dimensionsQuery.isLoading ||
    unrestrictedDimensionsPatchMutation.isLoading

  return (
    <Box height="100%" position="relative">
      {loading ? (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        />
      ) : (
        <div /> // Maintain DOM-elements number and order
      )}
      <RestrictedDimensionsTable
        dimensions={dimensionsQuery.data ?? []}
        unrestrictedDimensions={unrestrictedDimensionsQuery.data ?? []}
        onChangeAccess={handleChangeDimensionsRestriction}
      />
    </Box>
  )
}
