import React, { FormEvent, useCallback } from 'react'
import {
  StyledFormLayoutCancelButton,
  StyledFormLayoutFooter,
  StyledFormLayoutLoader,
  StyledFormLayoutMain,
  StyledFormLayoutRoot,
  StyledFormLayoutSubmitButton,
  StyledFormLayoutSubmitContainer,
  StyledFormLayoutSubmitErrorMessage,
} from './FormLayout.styled'
import { Maybe } from '@fintastic/shared/util/types'
import { FormLoader } from '../FormLoader'
export type FormLayoutProps = {
  submitEnabled?: boolean
  showLoader?: boolean
  submitButtonText?: string
  submitErrorMessage?: Maybe<string>
  onSubmit: () => void
  onCancel?: () => void
  children: React.ReactNode
  testIdPrefix?: string
}
export const FormLayout: React.FC<FormLayoutProps> = (props) => {
  const {
    submitEnabled = true,
    showLoader,
    submitButtonText = 'Submit',
    submitErrorMessage,
    onSubmit,
    onCancel,

    children,
    testIdPrefix,
  } = props
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (submitEnabled) {
        onSubmit()
      }
    },
    [onSubmit, submitEnabled],
  )
  return (
    <StyledFormLayoutRoot
      onSubmit={handleSubmit}
      data-testid={testIdPrefix ? `${testIdPrefix}-form-root` : undefined}
    >
      {showLoader && (
        <StyledFormLayoutLoader
          data-testid={testIdPrefix ? `${testIdPrefix}-form-loader` : undefined}
        >
          <FormLoader />
        </StyledFormLayoutLoader>
      )}
      {!showLoader && (
        <>
          <StyledFormLayoutMain
            data-testid={
              testIdPrefix ? `${testIdPrefix}-form-content` : undefined
            }
          >
            {children}
          </StyledFormLayoutMain>
          <StyledFormLayoutFooter
            data-testid={
              testIdPrefix ? `${testIdPrefix}-form-footer` : undefined
            }
          >
            <StyledFormLayoutSubmitContainer>
              {onCancel && <StyledFormLayoutCancelButton
                variant="outlined"
                onClick={onCancel}
                data-testid={
                  testIdPrefix ? `${testIdPrefix}-form-cancel` : undefined
                }
              >
                Cancel
              </StyledFormLayoutCancelButton>}
              <StyledFormLayoutSubmitButton
                disabled={!submitEnabled}
                variant="contained"
                type="submit"
                data-testid={
                  testIdPrefix ? `${testIdPrefix}-form-submit` : undefined
                }
              >
                {submitButtonText}
              </StyledFormLayoutSubmitButton>
              {submitErrorMessage && (
                <StyledFormLayoutSubmitErrorMessage
                  data-testid={
                    testIdPrefix
                      ? `${testIdPrefix}-form-error-message`
                      : undefined
                  }
                  variant="body2"
                >
                  {submitErrorMessage}
                </StyledFormLayoutSubmitErrorMessage>
              )}
            </StyledFormLayoutSubmitContainer>
          </StyledFormLayoutFooter>
        </>
      )}
    </StyledFormLayoutRoot>
  )
}
