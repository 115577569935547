import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { VersionEntities, WrappedVersionEntityDefinition } from '../../types'
import {
  idLooksLikeColumn,
  idLooksLikeList,
  idLooksLikeMetric,
  ParsedColumnId,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DimensionId,
  idLooksLikeDimension,
  WrappedDimension,
} from '@fintastic/web/util/dimensions'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { DimensionEntityDefinitionWrapper } from './DimensionEntityDefinitionWrapper'
import { ListEntityDefinitionWrapper } from './ListEntityDefinitionWrapper'
import { ColumnEntityDefinitionWrapper } from './ColumnEntityDefinitionWrapper'
import { MetricEntityDefinitionWrapper } from './MetricEntityDefinitionWrapper'
import { pipeNullable } from '@fintastic/shared/util/functional-programming'

export class VersionEntitiesWrapper
  implements ImmutableADTWrapper<VersionEntities>
{
  _rawData: VersionEntities
  protected _versionId: string

  constructor(versionId: string, entities: VersionEntities) {
    this._rawData = entities
    this._versionId = versionId
  }

  public unwrap(): VersionEntities {
    return this._rawData
  }

  public get versionId() {
    return this._versionId
  }

  public get metrics(): MetricEntityDefinitionWrapper[] {
    return this._rawData.metrics.map(
      (m) => new MetricEntityDefinitionWrapper(m),
    )
  }

  public get lists(): ListEntityDefinitionWrapper[] {
    return this._rawData.lists.map((l) => new ListEntityDefinitionWrapper(l))
  }

  public get dimensions(): DimensionEntityDefinitionWrapper[] {
    return this._rawData.dimensions.map(
      (d) => new DimensionEntityDefinitionWrapper(d),
    )
  }

  public findDimension(
    p: (d: WrappedDimension) => boolean,
  ): Maybe<DimensionEntityDefinitionWrapper> {
    return toMaybe(this.dimensions.find((entityDef) => p(entityDef.dimension)))
  }

  public findDimensionById(
    id: DimensionId,
  ): Maybe<DimensionEntityDefinitionWrapper> {
    return this.findDimension((d) => d.id === id)
  }

  public findMetricById(id: string): Maybe<MetricEntityDefinitionWrapper> {
    return toMaybe(this.metrics.find((m) => m.id === id))
  }

  public findListById(id: string): Maybe<ListEntityDefinitionWrapper> {
    return toMaybe(this.lists.find((l) => l.id === id))
  }

  public findColumnById(
    columnId: string,
  ): Maybe<ColumnEntityDefinitionWrapper> {
    return (
      pipeNullable(
        ParsedColumnId.fromString(columnId),
        (id) => this.findListById(id.listId),
        (list) => list.columns.find((c) => c.id === columnId),
      ) || null
    )
  }

  public findEntityById(
    entityId: string,
  ): Maybe<WrappedVersionEntityDefinition> {
    if (idLooksLikeDimension(entityId)) {
      return this.findDimensionById(entityId)
    }

    if (idLooksLikeList(entityId)) {
      return this.findListById(entityId)
    }

    if (idLooksLikeColumn(entityId)) {
      return this.findColumnById(entityId)
    }

    if (idLooksLikeMetric(entityId)) {
      return this.findMetricById(entityId)
    }

    return null
  }
}
