import React, { useEffect } from 'react'
import {
  ReferenceVersionSelect,
  useCommonDataWidgetSettings,
  useSetWidgetSettingsVersionErrorsEffect,
  useWidgetSettingsDialogContext,
  WidgetSelectEmpty,
} from '@fintastic/web/feature/boards'
import { FormField, TextField } from '@fintastic/shared/ui/form-framework'
import { useMetricWidgetSettings } from '../hooks/useMetricWidgetSettings'
import { PENDING_METRICS_ID, WidgetSelectMetric } from './WidgetSelectMetric'

export const MetricWidgetForm: React.FC = () => {
  const { data, setPartialData, pendingApi } = useWidgetSettingsDialogContext()

  // common piece of data
  const {
    versionId,
    displayNameFieldState,
    handleSelectVersion,
    handleShowNoRefVersionMessage,
    showRefVersionProblem,
  } = useCommonDataWidgetSettings(data)

  // metric-specific pieces of data
  const { metricId, handleSelectMetric } = useMetricWidgetSettings(
    data,
    displayNameFieldState,
  )

  const isListOfMetricsLoading =
    pendingApi.hasPendingProcess(PENDING_METRICS_ID)

  const showNoReferenceVersionSelected =
    !versionId &&
    !showRefVersionProblem &&
    Boolean(metricId) &&
    !pendingApi.isAnyPendingProcess // version is not selected but it can be selected

  useEffect(() => {
    setPartialData({
      versionId: versionId || '',
      id: metricId || '',
      displayLabel: displayNameFieldState.trimmedValue || '',
    })
  }, [
    data,
    displayNameFieldState.trimmedValue,
    metricId,
    setPartialData,
    versionId,
  ])

  useEffect(() => {
    if (!versionId) {
      pendingApi.finishPendingProcess(PENDING_METRICS_ID) // reference version is not available anymore - stop any pending ops
    }
  }, [pendingApi, versionId])

  useSetWidgetSettingsVersionErrorsEffect(
    showRefVersionProblem,
    showNoReferenceVersionSelected,
  )

  return (
    <>
      <FormField fullWidth={true}>
        <ReferenceVersionSelect
          value={versionId || ''}
          onNoRefVersion={handleShowNoRefVersionMessage}
          onSelect={handleSelectVersion}
          onFetching={pendingApi.addPendingProcess}
          onReady={pendingApi.finishPendingProcess}
        />
      </FormField>

      <FormField fullWidth={true}>
        {versionId ? (
          <WidgetSelectMetric
            disabled={!versionId}
            isLoading={isListOfMetricsLoading}
            versionId={versionId}
            value={metricId || ''}
            onSelect={handleSelectMetric}
            onFetching={pendingApi.addPendingProcess}
            onReady={pendingApi.finishPendingProcess}
          />
        ) : (
          <WidgetSelectEmpty
            emptyText={'Please select the reference version first'}
          />
        )}
      </FormField>

      <TextField
        label="Display name"
        fullWidth
        placeholder="Display name"
        value={displayNameFieldState.value}
        onChange={displayNameFieldState.setValue}
        error={
          displayNameFieldState.touched
            ? !displayNameFieldState.trimmedValue
            : false
        }
        errorMessage={
          displayNameFieldState.touched && !displayNameFieldState.trimmedValue
            ? 'Mandatory field'
            : undefined
        }
      />
    </>
  )
}
