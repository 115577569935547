import { useQuery, QueryKey } from 'react-query'
import { getFolders } from '../api'
import type { BoardsApiError } from '../api'
import type { BoardFolder } from '../types'

export const boardsFoldersListQueryKeyGetter = () => {
  const key: QueryKey = 'boardsFolders/list'
  return key
}

export function useBoardsFoldersListQuery() {
  return useQuery<BoardFolder[], BoardsApiError>(
    boardsFoldersListQueryKeyGetter(),
    async () => {
      const response = await getFolders()
      return response.data.result
    },
    {
      refetchIntervalInBackground: false,
      refetchInterval: false,
      staleTime: Infinity,
      retry: 1,
    },
  )
}
