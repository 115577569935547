import React, { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import { Board } from '../../../types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export type BoardInfoProps = {
  board: Board
}

export const BoardInfo: React.FC<BoardInfoProps> = ({ board }) => {
  const [tick, setTick] = useState(0)

  useEffect(() => {
    const tid = setInterval(() => {
      setTick((tickValue) => tickValue + 1)
    }, 1000)

    return () => {
      clearInterval(tid)
    }
  }, [])

  const updated = useMemo(
    () => dayjs(board.updated_at).fromNow(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [board.updated_at, tick],
  )

  const renderableBoardFields = useMemo(
    () =>
      // turn the object's fields to the array of ready-to-render rows
      Object.entries(board)
        .filter(([key]) => key !== 'config')
        .map(([key, value]) => {
          const label = key
          let valueString = `${value}`
          let displayAsRawJson = false
          if (value === null) {
            valueString = 'null'
          }
          if (value === undefined) {
            valueString = 'undefined'
          }
          if (typeof value === 'object') {
            valueString = JSON.stringify(value, undefined, 2)
            displayAsRawJson = true
          }
          if (typeof value === 'boolean') {
            valueString = value ? 'true' : 'false'
          }
          return {
            id: key,
            label,
            value: valueString,
            displayAsRawJson,
          }
        }),
    [board],
  )

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderableBoardFields.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="right">
                <b>{row.label}</b>
              </TableCell>
              <TableCell>
                {row.displayAsRawJson ? (
                  <TextField
                    sx={{
                      '& textarea': {
                        fontSize: 9,
                        lineHeight: 1.2,
                      },
                    }}
                    fullWidth
                    multiline
                    disabled
                    value={row.value}
                  />
                ) : (
                  row.value
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" scope="row" align="right">
              <b>Updated:</b>
            </TableCell>
            <TableCell component="th" scope="row">
              {updated}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
