import React, { useCallback, useMemo } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useGlobalModel } from '../hooks/useGlobalModel'
import { useTableChangeHandlers } from '../hooks/useTableChangeHandlers'

export const BulkMetricAndListsToggle: React.FC = () => {
  const { tables, loading } = useGlobalModel()
  const { onToggleAccessBulk } = useTableChangeHandlers()

  const handleChange = useCallback(
    (event: unknown, newValue: string) => {
      onToggleAccessBulk({
        tables,
        allowed: newValue === 'allow-all',
      })
    },
    [onToggleAccessBulk, tables],
  )

  const style = useMemo(
    () => ({
      margin: '4px',
    }),
    [],
  )

  if (loading) {
    return null
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={null}
      onChange={handleChange}
      exclusive
      size="small"
      sx={style}
    >
      <ToggleButton value="allow-all">Allow All</ToggleButton>
      <ToggleButton value="deny-all">Deny All</ToggleButton>
    </ToggleButtonGroup>
  )
}
