import React, { useCallback } from 'react'
import {
  DimensionsView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

const dimensions = [
  {
    id: 'Dim.Country',
    label: 'Country',
    visible: true,
    linked: false,
  },
  {
    id: 'Dim.Department',
    label: 'Department',
    visible: false,
    linked: true,
  },
  {
    id: 'Dim.Region',
    label: 'Region',
    visible: true,
    linked: true,
  },
]

// @todo RESP connect
export const DimensionsViewConnector: React.FC = () => {
  const { goTo, goBack } = useReportSettingsPanelNavigationContext()
  const goToDimension = useCallback(
    (id: string) => goTo(settingsPanelNavigationPaths.dimensionSettings(id)),
    [goTo],
  )

  return (
    <DimensionsView.Readonly
      goBack={goBack}
      goToDimension={goToDimension}
      dimensions={dimensions}
    />
  )
}
