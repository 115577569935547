import React, { useCallback, useState } from 'react'
import {
  ReportingGroupSettingsView,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'
import { Maybe } from '@fintastic/shared/util/types'
import { cloneDeep } from 'lodash'

const weighedMetrics = [
  {
    id: 'Cube.Something_1',
    label: 'Something_1',
  },
  {
    id: 'Cube.Something_2',
    label: 'Something_2',
  },
]

// @todo RESP connect
export const ReportingGroupSettingsViewConnector: React.FC = () => {
  const { goBack } = useReportSettingsPanelNavigationContext()

  const [group, setGroup] = useState<{
    id: string
    label: string
    aggregation: unknown
    weighedMetric: Maybe<{
      id: string
      label: string
    }>
    dataType: unknown
    currency: unknown
  }>({
    id: '1',
    label: 'Reporting Group 1',
    aggregation: 'sum',
    weighedMetric: null,
    dataType: 'currency',
    currency: 'USD',
  })

  const changeWeighedMetric = useCallback(
    (groupId: string, metricId: string) => {
      const metric = weighedMetrics.find((m) => m.id === groupId)
      if (!metric) {
        return
      }
      setGroup((g) => ({ ...g, weighedMetric: cloneDeep(metric) }))
    },
    [],
  )

  return (
    <ReportingGroupSettingsView.Editable
      goBack={goBack}
      group={group}
      deleteReportingGroup={console.log}
      changeCurrency={(_, currency) => setGroup((g) => ({ ...g, currency }))}
      changeAggregation={(_, aggregation) =>
        setGroup((g) => ({ ...g, aggregation }))
      }
      changeDataType={(_, dataType) => setGroup((g) => ({ ...g, dataType }))}
      changeWeighedMetric={changeWeighedMetric}
      availableWeighedMetrics={weighedMetrics}
    />
  )
}
