import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import { UserPanel } from '../UserPanel'
import { WithRequired } from '@fintastic/shared/util/types'
import { ArrowBottomIcon } from '@fintastic/shared/ui/icons'
import {
  StyledChipIconCont,
  StyledUserSelectPopper,
  StyledUserSelectRoot,
} from './UserSelect.styled'
import { UserSelectOption } from './UserSelect'
import Chip from '@mui/material/Chip'
import { matchSorter } from 'match-sorter'
import { GroupPanel } from '../GroupPanel'
import { sortBy } from 'lodash'

import GroupIcon from '@mui/icons-material/Group'
import { Avatar } from '../Avatar'

export type UsersMultiSelectProps = {
  id?: string
  options: UserSelectOption[]
  values: string[]
  disabled?: boolean
  onChange: (selectedEmails: string[]) => void
  label?: string
}

export type UserMultiSelectOption = {
  name: string
  email: string
  isGroup?: boolean
  group?: string
  avatarSrc?: string
}

export const UsersMultiSelect: React.FC<UsersMultiSelectProps> = ({
  id,
  options,
  values,
  onChange,
  disabled,
  label,
}) => {
  const [inputValue, setInputValue] = useState<string>('')

  const selectedOptions = useMemo(
    () => options.filter((o) => values?.includes(o.email)),
    [options, values],
  )

  useEffect(() => {
    if (values.length === 0) {
      setInputValue('')
    }
  }, [values])

  const handleChange = useCallback<
    WithRequired<
      AutocompleteProps<UserMultiSelectOption, true, undefined, undefined>,
      'onChange'
    >['onChange']
  >(
    (event, values) => {
      onChange(values?.map((v) => v.email) || [])
    },
    [onChange],
  )

  const handleChangeInput = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setInputValue(value)
    },
    [],
  )

  const filterOptions = (
    options: UserMultiSelectOption[],
    { inputValue }: FilterOptionsState<UserMultiSelectOption>,
  ) => {
    const result = matchSorter(options, inputValue, {
      keys: ['name', 'email'],
    })
    // does not work with groupBy, breaks the sort order
    // requires additional group-based sorting!
    return sortBy(result, function (o) {
      return [o.isGroup, o.name]
    })
  }

  const getOptionLabel = useCallback(
    (option: UserSelectOption) => option.email,
    [],
  )

  const renderOptions = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: UserMultiSelectOption,
    ) => (
      <Box component="li" {...optionProps}>
        {option.isGroup ? (
          <GroupPanel name={option.name} />
        ) : (
          <UserPanel
            name={option.name}
            email={option.email}
          />
        )}
      </Box>
    ),
    [],
  )

  return (
    <StyledUserSelectRoot>
      <Autocomplete<UserMultiSelectOption, true, true, undefined>
        id={id}
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        disabled={disabled}
        size="small"
        fullWidth
        multiple
        groupBy={(option) => option.group || 'Unknown'}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        disableClearable
        popupIcon={<ArrowBottomIcon fontSize="small" />}
        PopperComponent={StyledUserSelectPopper}
        inputValue={inputValue}
        onInputChange={handleChangeInput}
        getOptionLabel={getOptionLabel}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              icon={
                option.isGroup ? (
                  <StyledChipIconCont>
                    <GroupIcon color="info" />
                  </StyledChipIconCont>
                ) : undefined
              }
              avatar={
                option.isGroup ? undefined : (
                  <Avatar
                    size="small"
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: '100%',
                      marginLeft: '5px',
                      fontSize: '11px',
                    }}
                    fixedColors={true}
                    text={option.name || option.email || ' '}
                  />
                )
              }
              label={option.name}
              {...getTagProps({ index })}
              color={option.isGroup ? 'info' : 'default'}
              variant={option.isGroup ? 'outlined' : undefined}
              key={option.email}
            />
          ))
        }
        renderOption={renderOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            label={label}
          />
        )}
      />
    </StyledUserSelectRoot>
  )
}
