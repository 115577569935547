import React from 'react'
import {
  StyledPanelTopbarRoot,
  StyledPanelTopbarCenter,
  StyledPanelTopbarLeft,
  StyledPanelTopbarRight,
} from './PanelTopbar.styled'
import { BoxProps } from '@mui/material'

export type PanelTopbarProps = {
  leftContent?: React.ReactNode
  centerContent?: React.ReactNode
  rightContent?: React.ReactNode
  enableBorderBottom?: boolean
  sx?: BoxProps['sx']
}

export const PanelTopbar: React.FC<PanelTopbarProps> = ({
  leftContent,
  centerContent,
  rightContent,
  enableBorderBottom = false,
  sx,
}) => (
  <StyledPanelTopbarRoot enableBorderBottom={enableBorderBottom} sx={sx}>
    {(leftContent || rightContent) && (
      <StyledPanelTopbarLeft sx={{ width: centerContent ? undefined : '50% ' }}>
        {leftContent}
      </StyledPanelTopbarLeft>
    )}
    {centerContent && (
      <StyledPanelTopbarCenter
        sx={{
          width: !leftContent && !rightContent ? '100%' : undefined,
        }}
      >
        {centerContent}
      </StyledPanelTopbarCenter>
    )}
    {(leftContent || rightContent) && (
      <StyledPanelTopbarRight
        sx={{ width: centerContent ? undefined : '50% ' }}
      >
        {rightContent}
      </StyledPanelTopbarRight>
    )}
  </StyledPanelTopbarRoot>
)
