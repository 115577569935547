import React from 'react'
import {
  ChartDecimalPlacesSelect,
  ChartNumberFormatSelect,
  defaultChartDecimalPlaces,
  defaultChartNumberFormat,
  useChartSettingsEditContext,
} from '@fintastic/web/feature/charts'

export const DialogSectionYAxis: React.FC = () => {
  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <>
      <ChartNumberFormatSelect
        numberFormat={localSettings.numberFormat || defaultChartNumberFormat}
        onChangeChartSettings={handleSettingsChange}
      />
      <ChartDecimalPlacesSelect
        decimalPlaces={localSettings.decimalPlaces || defaultChartDecimalPlaces}
        onChangeChartSettings={handleSettingsChange}
      />
    </>
  )
}
