import {
  alpha,
  backdropClasses,
  Drawer,
  drawerClasses,
  styled,
} from '@mui/material'

export const StyledRoot = styled(Drawer)`
  .${backdropClasses.root} {
    background: ${alpha('#000', 0.2)};
  }

  .${drawerClasses.paper} {
    box-shadow: 0px 8px 38px 7px #0000001f, 0px 20px 31px 3px #00000024,
      0px 10px 13px -6px #00000033;
  }
`

export const StyledWidthContainer = styled('div')`
  position: relative;

  width: ${(1600 / 1920) * 100}vw;
  height: 100%;
`

export const StyledLayoutRoot = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledHeader = styled('div')`
  width: 100%;
`

export const StyledMain = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 32px;

  background: #f8f4f1;
`

export const StyledFilters = styled('div')`
  width: 100%;
`

export const StyledTable = styled('div')`
  margin: ${({ theme }) => theme.spacing(2)} 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

export const StyledPagination = styled('div')`
  width: 100%;
`
