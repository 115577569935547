import { styled } from '@mui/material'

export const NoSharedBoardsCircleStyled = styled('div')`
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(44, 69, 254, 0.119);
  border-radius: 50%;

  svg {
    width: 32px;
    height: 32px;
    fill: #2C45FE;
    color: #2C45FE;
  }
`
NoSharedBoardsCircleStyled.displayName = 'NoSharedBoardsCircleStyled'
