import React from 'react'
import { StyledControlButtons } from './ControlButtons.styled'
import { Button } from '@mui/material'

export type ControlButtonsProps = {
  isChanged: boolean
  disabled?: boolean
  isDefaultChanged: boolean
  onCancel: () => void
  onClear: () => void
  onApply: () => void
}

export const ControlButtons: React.FC<ControlButtonsProps> = ({
  isChanged,
  isDefaultChanged,
  onCancel,
  onClear,
  onApply,
  disabled,
}) => (
  <StyledControlButtons>
    <Button
      color="primary"
      variant={'text'}
      onClick={onClear}
      disabled={disabled || !isDefaultChanged}
    >
      CLEAR
    </Button>

    <Button
      color="primary"
      variant={'outlined'}
      disabled={disabled || !isChanged}
      onClick={onCancel}
    >
      Cancel
    </Button>

    <Button
      color="primary"
      variant={'contained'}
      disabled={disabled || !isChanged}
      onClick={onApply}
    >
      Apply
    </Button>
  </StyledControlButtons>
)
