import { useCallback, useMemo } from 'react'
import { ChangeCategoryAggregationAction } from '../types'
import { useMetricsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeCategoryAggregationFlow() {
  const { changeCategoryAggregation } = useMetricsActions()

  const handleAction = useCallback<ChangeCategoryAggregationAction>(
    (aggregation) => {
      changeCategoryAggregation(aggregation)
    },
    [changeCategoryAggregation],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
