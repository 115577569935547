import { useCallback, useMemo, useState } from 'react'
import { MutableReference } from '@fintastic/shared/util/types'
import { cloneDeep } from 'lodash'

export type ReportEditorState =
  | InactiveReportEditorState
  | ActiveReportEditorState

export type InactiveReportEditorState = {
  active: false
  lastCreatedReportId?: string
}

export type ActiveReportEditorState = {
  active: true
  new: boolean
  dirty: boolean
  reportDefinition: {
    id: string
    name: string
  }
}

const initialState: ReportEditorState = {
  active: false,
}

export type StateApi = ReturnType<typeof useReportEditorStateApi>

export const useReportEditorStateApi = () => {
  const [state, setState] = useState<ReportEditorState>(initialState)

  const startCreation = useCallback((payload: { name: string }) => {
    setState({
      active: true,
      new: true,
      dirty: false,
      reportDefinition: {
        id: `Report.${payload.name}`,
        name: payload.name,
      },
    })
  }, [])

  const startEditing = useCallback(
    (payload: { reportDefinition: { id: string; name: string } }) => {
      setState({
        active: true,
        new: false,
        dirty: false,
        reportDefinition: cloneDeep(payload.reportDefinition),
      })
    },
    [],
  )

  const discardEditing = useCallback((lastCreatedReportId?: string) => {
    setState({
      active: false,
      lastCreatedReportId,
    })
  }, [])

  const mutateReportDefinition = useCallback(
    (
      mutatorCb: (
        reportDefinition: MutableReference<{ id: string; name: string }>,
      ) => void,
    ) => {
      setState((s) => {
        if (!s.active) {
          return s
        }

        const newState = cloneDeep(s)
        newState.dirty = true
        mutatorCb(
          s.reportDefinition as MutableReference<{ id: string; name: string }>,
        )
        return newState
      })
    },
    [],
  )

  return useMemo(
    () =>
      ({
        state,
        startCreation,
        startEditing,
        discardEditing,
        mutateReportDefinition,
      } as const),
    [
      mutateReportDefinition,
      startCreation,
      startEditing,
      discardEditing,
      state,
    ],
  )
}
