import { styled, Box, Popper } from '@mui/material'

export const StyledUserSelectRoot = styled(Box)(({ theme }) => ({
  // customizing styles here is easier than create styled component with generics support for Autocomplete
  '& .MuiAutocomplete-root': {
    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    '& .MuiInputBase-input': {
      height: '1.643em',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.divider,
    },
    '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FE6422',
    },
  },
}))

export const StyledUserSelectPopper = styled(Popper)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: 6,
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.05), 0px -4px 10px rgba(0, 0, 0, 0.05)',
  },

  '& .MuiAutocomplete-listbox': {
    padding: 0,
  },

  '& .MuiAutocomplete-option': {
    'paddingTop': 10,
    'paddingBottom': 10,
    '&.Mui-focused': {
      background: '#FFF6ED',
    },
    '&.Mui-focused.Mui-focusVisible': {},
    '&[aria-selected="true"]': {
      background: '#FFE9DA',
    },
    '&[aria-selected="true"].Mui-focused': {
      background: '#FFF6ED',
    },
  },
}))

export const StyledChipIconCont = styled(Box)`
  margin-right: 0;
  display: inline-flex;
  align-content: center;
  width: 22px;
  height: 22px;
  svg {
    margin-left: 2px;
    width: 100%;
    height: 100%;
  }
`