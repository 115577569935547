import { styled, Box, Typography } from '@mui/material'

export const StyledRoot = styled(Box)(({ theme }) => ({}))

export const StyledProperties = styled(Box)(({ theme }) => ({}))

export const StyledProperty = styled(Box)(({ theme }) => ({
  'padding': theme.spacing(2),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

export const StyledPropertyTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),

  fontSize: 10,
  textTransform: 'uppercase',
  fontWeight: 500,

  color: '#D64E15',
}))

export const StyledPropertyItems = styled(Box)(({ theme }) => ({}))

export const StyledItem = styled(Box)(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}))

export const StyledItemLabel = styled(Typography)(({ theme }) => ({
  marginBottom: 2,

  fontSize: 12,

  color: theme.palette.grey[400],
}))

export const StyledItemValue = styled(Typography)(({ theme }) => ({
  fontSize: 14,
}))
