import React, { useCallback, useMemo } from 'react'
import {
  FormulaCollapse,
  FormulaValidationCallback,
} from '@fintastic/web/feature/formulas'
import {
  FormulaEditorRouter,
  useFormulasTabContext,
} from '@fintastic/web/feature/lists'
import { useListEditorApi } from '../../../context/list-editor-api-context'
import { ActiveListEditorApi } from '../../../features/active/makeApi'
import { toMaybe } from '@fintastic/shared/util/types'
import { keyBy } from 'lodash'
import { ClientOnlyMapping } from '@fintastic/web/util/formulas'

export const FormulasEditor: React.FC = () => {
  const formulasTabState = useFormulasTabContext()
  const { versionMetadata, list, listFormula, columnsFormula, isLiveActuals } =
    useListEditorApi() as ActiveListEditorApi

  const calculatedColumns = useMemo(
    () =>
      keyBy(
        list.metrics.filter((m) => m.source === 'calculated'),
        'id',
      ),
    [list.metrics],
  )

  const listAsConnectedTable = useMemo(() => ({ id: list.id }), [list.id])

  const clientOnlyMapping = useMemo<ClientOnlyMapping>(
    () => ({
      listColumns: list.metrics
        .filter((m) => m.newColumn)
        .map((c) => ({
          id: c.id,
          label: c.label,
          source: c.source,
          listLabel: list.label,
        })),
    }),
    [list.label, list.metrics],
  )

  const handleListFormulaValidationFinished: FormulaValidationCallback =
    useCallback(
      (_, formula, invalid) => {
        listFormula.changeFormula(formula, !invalid)
      },
      [listFormula],
    )

  const handleColumnFormulaValidationFinished = useCallback(
    (columnId: string, formula: string, invalid?: boolean) => {
      columnsFormula.changeFormula(columnId, formula, !invalid)
    },
    [columnsFormula],
  )

  return (
    <FormulaCollapse isOpen={formulasTabState.isOpen}>
      <FormulaEditorRouter
        tab={formulasTabState.tab}
        column={formulasTabState.column}
        onRequestClose={formulasTabState.closeFormula}
        versionId={toMaybe(versionMetadata?.uuid)}
        versionEditable={true}
        listFormula={toMaybe(list.metadata.formula)}
        columns={calculatedColumns}
        listConnectedTable={listAsConnectedTable}
        formulaEditorTitle={isLiveActuals ? 'Actuals Formula' : undefined}
        controlledMode={true}
        readonly={false}
        onFormulaValidationFinished={handleListFormulaValidationFinished}
        onColumnFormulaValidationFinished={
          handleColumnFormulaValidationFinished
        }
        clientOnlyMapping={clientOnlyMapping || null}
        isSettingsEditingActive={true}
        controlledFormulaErrorForListItself={listFormula.error}
        controlledFormulaErrorForColumns={columnsFormula.errors}
      />
    </FormulaCollapse>
  )
}
