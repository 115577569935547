import { Board } from '../types'
import { MutationKey, useMutation, useQueryClient } from 'react-query'
import { boardsListQueryKeyGetter } from './useBoardsListQuery'
import { boardQueryKeyGetter } from './useBoardQuery'
import { deleteBoard } from '../api'
import { Maybe } from '@fintastic/shared/util/types'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'

export const boardDeleteMutationKeyGetter = (boardId?: Maybe<Board['id']>) => {
  const key: MutationKey = `board/delete/${boardId}`
  return key
}

export type UseBoardDeleteMutationParams = {
  boardId?: Maybe<Board['id']>
  permanently?: boolean
}

const defaultParams: UseBoardDeleteMutationParams = {}

export function useBoardDeleteMutation(
  params: UseBoardDeleteMutationParams = defaultParams,
) {
  const { boardId } = params
  const queryClient = useQueryClient()

  return useMutation(
    boardDeleteMutationKeyGetter(boardId),
    async (variables: { boardId?: Maybe<Board['id']>, permanently?: boolean }) => {
      if (!boardId && !variables.boardId) {
        throw new Error('Board ID is not provided.')
      }
      await deleteBoard((boardId || variables.boardId) as string, variables.permanently || false)
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(boardQueryKeyGetter(variables.boardId), {
          exact: true,
        })
        queryClient.invalidateQueries(boardsListQueryKeyGetter(), {
          exact: true,
        })
        queryClient.invalidateQueries(boardsFoldersListQueryKeyGetter(), {
          exact: true,
        })
        if (boardId || variables.boardId) {
          queryClient.removeQueries(
            boardQueryKeyGetter((boardId || variables.boardId) as string),
            { exact: true },
          )
        }
      },
    },
  )
}
