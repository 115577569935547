import React, { useCallback } from 'react'
import { ContextMenuItem } from '../../types'
import {
  StyledContextMenuPopover,
  StyledContextMenuContainer,
} from './ContextMenu.styled'
import { PopoverProps } from '@mui/material/Popover/Popover'
import { ContextMenuTree } from '../ContextMenuTree'

export type ContextMenuProps = Omit<PopoverProps, 'children'> & {
  menuItems: ContextMenuItem[]
  closeOnButtonClick?: boolean
  ['data-testid']?: string
}

export const ContextMenu: React.FC<ContextMenuProps> = (props) => {
  const { menuItems, closeOnButtonClick = true, ...popoverProps } = props

  const hideContextMenu = useCallback(() => {
    if (popoverProps.onClose && closeOnButtonClick) {
      popoverProps.onClose({}, 'escapeKeyDown')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOnButtonClick, popoverProps.onClose])

  return (
    <StyledContextMenuPopover {...popoverProps}>
      <StyledContextMenuContainer>
        <ContextMenuTree
          hideContextMenu={hideContextMenu}
          menuItems={menuItems}
        />
      </StyledContextMenuContainer>
    </StyledContextMenuPopover>
  )
}
