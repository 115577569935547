import {
  ChartColumnData,
  ChartDisplaySettings,
} from '@fintastic/web/feature/charts'
import { DataRow, HeaderRow } from './types'
import { ChartDefaultStyle } from './chart-default-style'
import { EChartsOption } from 'echarts'
import { createChartValueFormatter } from './chart-value-formatters'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricMetadata } from '@fintastic/web/util/metrics-and-lists'
import { processLegendSettings } from './process-chart-legend'
import { processGridSettings } from './process-chart-grid'
import { processXAxis } from './process-xaxis-grid'

export const extractChartData = (data?: ChartColumnData) => {
  if (!data || data.length <= 1) {
    return {
      headers: [],
      meaningRows: [],
    }
  }

  const headers = (data[0] as HeaderRow).toSpliced(0, 1)

  const meaningRows = (data as DataRow[]).toSpliced(0, 1)

  return { headers, meaningRows }
}

export const prepareOptions = (
  headers: string[],
  series: EChartsOption['series'],
  metadata?: Maybe<MetricMetadata>,
  displaySettings?: ChartDisplaySettings,
) => {
  const valueFormatter = createChartValueFormatter(metadata, displaySettings)

  const axisXLabel = (ChartDefaultStyle.xAxis as any).axisLabel || {}
  const axisYLabel = (ChartDefaultStyle.yAxis as any).axisLabel || {}

  const seriesWithTooltip: EChartsOption['series'] = Array.isArray(series || [])
    ? ((series || []) as Array<any>).map((s: any) => ({
        ...s,
        tooltip: { valueFormatter },
      }))
    : series

  const result = {
    ...ChartDefaultStyle,
    xAxis: {
      ...ChartDefaultStyle.xAxis,
      data: headers,
      axisLabel: {
        ...axisXLabel,
      },
    },
    yAxis: {
      ...ChartDefaultStyle.yAxis,
      type: 'value',
      axisLabel: {
        ...axisYLabel,
        formatter: valueFormatter,
      },
    },
    series: seriesWithTooltip,
  } as EChartsOption

  if (displaySettings) {
    // modify result settings according to display settings
    processLegendSettings(result, displaySettings)
    processXAxis(result, displaySettings)
    processGridSettings(result, displaySettings)
  }

  // extra tuning
  // +20 pixels for vertical x labels
  if (displaySettings?.xAxisAlignment === 'vertical') {
    if (typeof (result as any)?.grid?.bottom === 'number') {
      (result.grid as any).bottom += 20
    }
  }
  return result
}
