import React from 'react'
import { useTokenHelperContext } from './token-helper-context'
import {
  StyledRoot,
  StyledContent,
  StyledText,
  StyledButtonContainer,
} from './TokenHelperPopper.styled'
import { IconButton } from '@mui/material'
import { CloseIcon } from '@fintastic/shared/ui/icons'
import { useVirtualAnchorElement } from '../popper-utils/useVirtualAnchorElement'

export const TokenHelperPopper: React.FC = () => {
  const { token, helperContent, closeHelper } = useTokenHelperContext()
  const anchorEl = useVirtualAnchorElement(token?.node)

  return (
    <StyledRoot
      anchorEl={anchorEl}
      open={!!token?.node && !!token.node?.isConnected}
      placement="bottom-end"
      data-testid="formula-editor-token-helper-popper"
    >
      <StyledContent>
        <StyledText>{helperContent}</StyledText>
        <StyledButtonContainer>
          <IconButton onClick={closeHelper} color="textPrimary">
            <CloseIcon fontSize="small" />
          </IconButton>
        </StyledButtonContainer>
      </StyledContent>
    </StyledRoot>
  )
}
