import {
  ColumnsFormulaStateApi,
  DisplayErrorCallback,
  DisplaySuccessCallback,
  ListFormulaStateApi,
  RequestCancelConfirmationCallback,
  SaveListCallback,
  UnlockVersionCallback,
} from '../shared-types'
import {
  EditableList,
  isRestrictedColumnUsedInFormula,
  isSaveListErrorAboutNoColumnAccess,
  isSaveListErrorAboutNoopAggregation,
} from '@fintastic/web/data-access/metrics-and-lists'
import { OperationStateApi } from '@fintastic/shared/util/operation'
import { Maybe } from '@fintastic/shared/util/types'
import { Version } from '@fintastic/web/util/versions'

export type ActiveListEditorApi = ReturnType<typeof makeApi>

type State = {
  list: EditableList
  isNew: boolean
  isValid: boolean
  listFormulaStateApi: ListFormulaStateApi
  columnsFormulaStateApi: ColumnsFormulaStateApi
  cancelOperationStateApi: OperationStateApi
  saveOperationStateApi: OperationStateApi
}

type Dependencies = {
  displayError: DisplayErrorCallback
  displaySuccess: DisplaySuccessCallback
  unlockVersion: UnlockVersionCallback
  saveList: SaveListCallback
  startWaitingForCalcEvent: () => void
  stopWaitingForCalcEvent: () => void
  requestCancelConfirmation: RequestCancelConfirmationCallback
  cancelEditing: () => void
  subscribeToCreatedEvent: (cb: (listId: string) => void) => void
  versionMetadata: Maybe<Version>
}

type Context = {
  isLiveActuals: boolean
}

export const makeApi = (state: State, deps: Dependencies, context: Context) =>
  ({
    active: true,
    isLoadingDependencies: deps.versionMetadata === null,
    list: state.list,
    versionMetadata: deps.versionMetadata,
    isNew: state.isNew,
    isValid: state.isValid,
    isLiveActuals: context.isLiveActuals,
    subscribeToCreatedEvent: deps.subscribeToCreatedEvent,
    listFormula: state.listFormulaStateApi,
    columnsFormula: state.columnsFormulaStateApi,
    cancelEditing: {
      run: async (confirmed = false) => {
        if (!confirmed) {
          deps.requestCancelConfirmation()
          return
        }
        state.cancelOperationStateApi.start()
        try {
          await deps.unlockVersion()
          deps.cancelEditing()
          state.cancelOperationStateApi.finish(true)
        } catch (e) {
          state.cancelOperationStateApi.finish(false)
          deps.displayError("Can't exit the edit mode")
          console.error(e)
        }
      },
      running: state.cancelOperationStateApi.status === 'processing',
    },
    saveChanges: {
      run: async () => {
        state.saveOperationStateApi.start()
        deps.startWaitingForCalcEvent()
        try {
          await deps.saveList({ isNewList: state.isNew, list: state.list })
        } catch (e) {
          deps.stopWaitingForCalcEvent()
          state.saveOperationStateApi.finish(false)
          if (isSaveListErrorAboutNoopAggregation(e)) {
            deps.displayError(
              "The formula returns a data type that doesn't match the aggregation function. Please set the aggregation function to “None” and try again.",
            )
          } else if (isSaveListErrorAboutNoColumnAccess(e)) {
            deps.displayError(
              'You need permission to use one or more entities in the formula',
            )
          } else if (isRestrictedColumnUsedInFormula(e)) {
            deps.displayError(
              'You are not allowed to use restricted columns and lists containing restricted columns in formulas as well as edit settings of such Lists.',
            )
          } else {
            deps.displayError(
              state.isNew ? 'Failed to create list' : 'Failed to save list',
            )
          }
          console.error(e)
        }
      },
      running: state.saveOperationStateApi.status === 'processing',
    },
  } as const)
