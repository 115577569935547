import { ThemeComponents } from './types'
import {
  CheckboxCheckedIcon,
  CheckboxNotCheckedIcon,
} from '@fintastic/shared/ui/icons'
import { alpha } from '@mui/system'

export const MuiCheckbox: ThemeComponents['MuiCheckbox'] = {
  defaultProps: {
    icon: <CheckboxNotCheckedIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
  },
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      '&.Mui-checked.Mui-disabled': {
        color: alpha(theme.palette.primary.main, 0.38),
      },
    }),
  },
}
