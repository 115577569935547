import { VersionUserLockParsed } from './types'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { versionIsLockedMessage } from './version-user-locker'
import { Maybe } from '@fintastic/shared/util/types'

let editingIsBlockedToast: Maybe<string> = null
let editingIsBlockedVersionId: Maybe<string> = null

export const showVersionIsLockedForDataEditingToast = (
  lock: VersionUserLockParsed,
) => {
  if (editingIsBlockedToast) {
    return
  }

  editingIsBlockedToast = toast.custom(
    versionIsLockedMessage(lock, 'Data editing'),
    {
      duration: Infinity,
      position: 'top-center',
    },
  )

  editingIsBlockedVersionId = lock.versionId
}

export const hideVersionIsLockedForDataEditingToast = (
  lock: VersionUserLockParsed,
) => {
  if (
    lock.editIsBlocked ||
    !editingIsBlockedToast ||
    editingIsBlockedVersionId !== lock.versionId
  ) {
    return
  }
  toast.dismiss(editingIsBlockedToast)
  editingIsBlockedToast = null
  editingIsBlockedVersionId = null
}
