import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'
import { chunk } from 'lodash'

type RecursiveArray<T> = T[] | RecursiveArray<T>[]
export type UnfoldedMetricDataValues = RecursiveArray<MetricDataValue>

export function unfoldMetricDataValues(
  data: MetricDataValue[],
  dimensionsSizes: number[],
): UnfoldedMetricDataValues {
  const sizesExceptLast = dimensionsSizes.slice(0, dimensionsSizes.length - 1)
  if (sizesExceptLast.length === 0) {
    return data
  }

  return divideArrayBySizes(data, sizesExceptLast)
}

function divideArrayBySizes<T>(list: T[], sizes: number[]): RecursiveArray<T> {
  const size = Math.ceil(list.length / sizes[0])

  const chunks = chunk(list, size)
  if (sizes.length > 1) {
    const remainSizes = sizes.slice(1)
    return chunks.map((c) => divideArrayBySizes(c, remainSizes))
  }

  return chunks
}
