import { Box, BoxProps, styled } from '@mui/material'

export const StyledPanelTopbarRoot = styled(
  ({
    enableBorderBottom,
    ...rest
  }: { enableBorderBottom: boolean } & BoxProps) => <Box {...rest} />,
)(({ theme, enableBorderBottom }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '16px',
  paddingRight: '16px',
  width: '100%',
  height: '48px',
  borderBottom: `1px solid ${
    enableBorderBottom ? theme.palette.divider : 'transparent'
  }`,
}))

export const StyledPanelTopbarLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  width: '35%',
}))

export const StyledPanelTopbarCenter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '30%',
}))

export const StyledPanelTopbarRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),

  width: '35%',
}))
