import { styled } from '@mui/material'
import { ArrowsSyncIcon, SuccessIcon } from '@fintastic/shared/ui/icons'

export const StyledLiveActualsPageTitleRoot = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
`

export const StyledLiveActualsPageTitle = styled('div')`
  font-size: 18px;
`

export const StyledLiveActualsPageStatus = styled('div')`
  font-size: 12px;
`

export const StyledLiveActualsStatus = styled('div')`
  display: flex;
  line-height: 1;
  align-items: center;
  gap: 6px;
`

export const StyledSuccessIcon = styled(SuccessIcon)`
  color: ${({ theme }) => theme.palette.success.main};
  height: 16px;
  width: 16px;
`

export const StyledArrowsSyncIcon = styled(ArrowsSyncIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
  height: 16px;
  width: 16px;
`
