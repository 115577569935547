import { ThemeComponents } from './types'
import { styleKit } from '../styleKit'

export const MuiSnackbarContent: ThemeComponents['MuiSnackbarContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: '8px 16px',
      backgroundColor: styleKit.colors.general.black2,
      boxShadow: styleKit.shadows.toaster,
    }),
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0,
      fontSize: styleKit.typography.defaults.fontSize,
      fontWeight: 500,
    },
  },
}
