/**
 Sagas attached to current duck.
 @see https://redux-saga.js.org/docs/introduction/GettingStarted
 */

import type { Saga } from 'redux-saga'

// here can be any other generators besides watchers
const sagas: Saga[] = []

// export only array of sagas
export default sagas
