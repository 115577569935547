import { ColDef, Column, ColumnApi, GridApi, RowNode } from 'ag-grid-community'
import { CellData } from './data-values'
import { HeaderCell } from './header-utils'
import { ExportMode } from '../types'
import type {
  AgGridDateCellEditorProps,
  AgGridNumericCellEditorProps,
} from '@fintastic/shared/ui/ag-grid'

export type ExtendedColumn = Column & {
  _colDef: ColDef
  _userColDef: ColDef | null
}

export function getVisibleRows(api: GridApi, mode: ExportMode): RowNode[] {
  const rowsToDisplay: RowNode[] = []

  const isCurrentMode = mode === 'current' || mode === 'current-with-comments'

  if (isCurrentMode) {
    api.getModel().forEachNode((rowNode) => {
      if (rowNode.displayed) {
        rowsToDisplay.push(rowNode)
      }
    })
  } else {
    api.forEachNodeAfterFilterAndSort((rowNode) => {
      if (
        !rowNode.group &&
        !rowNode.isRowPinned() &&
        !(rowNode.id && rowNode.id.indexOf('calculated') !== -1)
      ) {
        rowsToDisplay.push(rowNode)
      }
    })
  }

  if (isCurrentMode) {
    const root = api.getModel().getRow(0)?.parent
    if (root && root.sibling && root.sibling.footer) {
      rowsToDisplay.push(root.sibling)
    }

    rowsToDisplay.sort((a, b) => (a.rowIndex || -1) - (b.rowIndex || -1))

    // add pinned rows
    const pinnedTopCount = api.getPinnedTopRowCount()
    if (pinnedTopCount > 0) {
      for (let i = 0; i < pinnedTopCount; i++) {
        const row = api.getPinnedTopRow(i)
        if (row) {
          rowsToDisplay.unshift(row)
        }
      }
    }
    const pinnedBottomCount = api.getPinnedBottomRowCount()
    if (pinnedBottomCount > 0) {
      for (let i = 0; i < pinnedBottomCount; i++) {
        const row = api.getPinnedBottomRow(i)
        if (row) {
          rowsToDisplay.push(row)
        }
      }
    }
  } else {
    rowsToDisplay.reverse()
  }

  return rowsToDisplay
}

export function getMeaningfulColumns(columnApi: ColumnApi): ExtendedColumn[] {
  const columns = columnApi.getAllDisplayedColumns()

  return columns
    .map((col: Column & Partial<ExtendedColumn>) => {
      // eslint-disable-next-line no-param-reassign
      col._colDef = col.getColDef()
      // eslint-disable-next-line no-param-reassign
      col._userColDef = col.getUserProvidedColDef()
      return col as ExtendedColumn
    })
    .filter((col) => !col._colDef.headerCheckboxSelection)
}

export function isDateProps(
  props: AgGridDateCellEditorProps | AgGridNumericCellEditorProps | undefined,
): props is AgGridDateCellEditorProps {
  return (props as AgGridDateCellEditorProps)?.displayingFormat !== undefined
}

export function isNumericProps(
  props: AgGridDateCellEditorProps | AgGridNumericCellEditorProps | undefined,
): props is AgGridNumericCellEditorProps {
  return (
    (props as AgGridNumericCellEditorProps)?.dataType === 'number' ||
    (props as AgGridNumericCellEditorProps)?.dataType === 'currency' ||
    (props as AgGridNumericCellEditorProps)?.dataType === 'integer' ||
    (props as AgGridNumericCellEditorProps)?.dataType === 'percentage' ||
    (props as AgGridNumericCellEditorProps)?.dataType === 'percentage_integer'
  )
}

function isExtendedColumn(col: ExtendedColumn | Column): col is ExtendedColumn {
  return (col as ExtendedColumn)._colDef !== undefined
}

export function isHeaderCell(cell: CellData | HeaderCell): cell is HeaderCell {
  return (cell as HeaderCell).label !== undefined
}

export function getTypeAndParams(
  col: ExtendedColumn | Column,
  value?: unknown,
): { type: string; formattingParams: AgGridNumericCellEditorProps } {
  const colDef = isExtendedColumn(col) ? col._colDef : col.getColDef()
  const userColDef = isExtendedColumn(col)
    ? col._userColDef
    : col.getUserProvidedColDef()

  const isDateTime = colDef.cellEditor === 'dateCellEditor'
  const cellFormattingParams = colDef.cellEditorParams
  let cellType = (userColDef?.type || colDef.type || 'generic').toString() // may be an array (?)
  if (isDateTime) {
    cellType = 'datetime'
  }
  if (typeof value === 'boolean') {
    cellType = 'boolean'
  }
  return {
    type: cellType,
    formattingParams: cellFormattingParams,
  }
}

export function getMaxVisibleGroupLevel(rowsToDisplay: RowNode[]) {
  return rowsToDisplay.reduce(
    (prev, current) => (current.uiLevel > prev ? current.uiLevel : prev),
    0,
  )
}
