import { MetricDataWithVersionId } from '../../types'
import { VersionMetric } from '../../../../utils/metrics'
import { DEFAULT_METRIC_SOURCE } from '../metric-source/defaults'
import { Version } from '@fintastic/web/util/versions'
import { isVersionEditable } from '@fintastic/web/util/versions'

// @todo add tests
export function extractVersionMetricsList(
  metricId: string,
  rawDataList: MetricDataWithVersionId[],
  versionsData: Record<string, Version>,
): VersionMetric[] {
  return rawDataList.map<VersionMetric>((versionMetric) => {
    if (!versionMetric.metric) {
      throw new Error('No versionMetric.metric provided')
    }

    return {
      versionId: versionMetric.version,
      versionLabel:
        versionsData[versionMetric.version]?.name || '(unrecognized version)',
      versionEditable: isVersionEditable(versionsData[versionMetric.version]),
      metricId,
      metricLabel: versionMetric.metric.label,
      metricData: versionMetric.metric.data || {
        indexes: [],
        dimensions: [],
        values: [],
      },
      metricSource: versionMetric.metric.source || DEFAULT_METRIC_SOURCE,
      formula: versionMetric.metric.metadata.formula,
      // @todo remove dimensions metadata usage
      metricMetadata: versionMetric.metric.metadata,
      displaySettings: versionMetric.metric.metadata.display_config,
    }
  })
}
