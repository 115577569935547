import { useMemo } from 'react'
import type { ICellRendererParams } from 'ag-grid-community'
import type { GenericReportTreeRow } from '@fintastic/web/util/generic-report'
import {
  useAllThreadsBulk,
  useBoardId,
} from '@fintastic/web/data-access/comments'
import { buildObjectId } from '../utils/build-object-id'
import { WithReportContext } from '../types'
import { useReadDeeplinkValue } from '@fintastic/web/util/deeplink'

export const useCellHasComment = (
  params: WithReportContext<ICellRendererParams<GenericReportTreeRow>>,
) => {
  const boardId = useBoardId()

  const { data: threads } = useAllThreadsBulk('board' + boardId, false)

  const monthOverMonthEnabled = useReadDeeplinkValue<boolean>(
    `w${params.context.deeplinkWidgetId}_mom`,
  )

  return useMemo<boolean>(() => {
    if (!params.column || !params.node || !params.context || !threads?.length) {
      return false
    }

    const objectId = buildObjectId(
      params.column,
      params.node,
      params.context.dimensions,
      params.context.deeplinkWidgetId,
      params.context.periodSelection,
      monthOverMonthEnabled,
    )

    return threads?.find(
      (thread) => thread.object_key === objectId && !thread.is_resolved,
    )
      ? true
      : false
  }, [
    params.column,
    params.context,
    params.node,
    threads,
    monthOverMonthEnabled,
  ])
}
