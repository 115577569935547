import { useMemoryRouteMatchContext } from '@fintastic/shared/util/memory-router'
import { useDataContext } from '../../connection/data/data-context'
import { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'

export function useColumnByUrlParam(): Maybe<ListColumnWrapper> {
  const match = useMemoryRouteMatchContext<'id'>()
  const columnId = decodeURIComponent(match?.params.id || '')

  const { list } = useDataContext()

  return useMemo(
    () => list?.findColumnByClientId(columnId) || null,
    [columnId, list],
  )
}
