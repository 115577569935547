import { Widget, WidgetChangeLayoutCallback, WidgetLayout } from '../types'
import { useCallback, useMemo, useRef } from 'react'

export type UseWidgetLayoutActionsParams = {
  widget: Widget
  layout: WidgetLayout
  changeLayout: WidgetChangeLayoutCallback
  gridLayoutRowHeightInPixels: number
}

export function useWidgetLayoutActions(params: UseWidgetLayoutActionsParams) {
  const { widget, layout, changeLayout, gridLayoutRowHeightInPixels } = params
  const { minHeightInPixels } = widget

  const prevHeightValueRef = useRef(layout.h)

  const minHeightInRows = useMemo(
    () =>
      minHeightInPixels ? minHeightInPixels / gridLayoutRowHeightInPixels : 3,
    [gridLayoutRowHeightInPixels, minHeightInPixels],
  )

  const collapseVert = useCallback(() => {
    prevHeightValueRef.current = layout.h

    changeLayout({
      ...layout,
      h: 1,
      maxH: layout.h,
    })
  }, [changeLayout, layout])

  const expandVert = useCallback(() => {
    const targetHeight = layout.maxH ? layout.maxH : prevHeightValueRef.current

    changeLayout({
      ...layout,
      maxH: undefined,
      h: Math.max(Math.ceil(minHeightInRows), targetHeight),
    })
  }, [changeLayout, layout, minHeightInRows])

  return useMemo(
    () => ({
      collapseVert,
      expandVert,
    }),
    [collapseVert, expandVert],
  )
}
