import { useMutation } from 'react-query'
import { useMemo } from 'react'
import { DisplayErrorCallback, LockVersionCallback } from './types'
import { StateApi } from '../editor-state'

export type StartCreationOperationApi = Pick<
  ReturnType<typeof useStartCreationOperation>,
  'error' | 'mutateAsync' | 'isLoading'
>

export type StartCreationOperationParams = {}

export type StartCreationOperationDependencies = {
  stateApi: StateApi
  lockVersion: LockVersionCallback
  displayError: DisplayErrorCallback
}

export const useStartCreationOperation = (
  params: StartCreationOperationParams,
  deps: StartCreationOperationDependencies,
) =>
  useMutation({
    mutationFn: useMemo(() => makeOperationFn(params, deps), [deps, params]),
  })

export const makeOperationFn =
  (
    params: StartCreationOperationParams,
    deps: StartCreationOperationDependencies,
  ) =>
  async (variables: { name: string }) => {
    if (deps.stateApi.state.active) {
      throw new Error('some report is already being editing')
    }

    try {
      await deps.lockVersion()
    } catch (error) {
      deps.displayError('Cannot lock the version for editing')
      throw error
    }

    deps.stateApi.startCreation(variables)
  }
