/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { RootState, State } from './state'
import type { Selector } from 'reselect'
import type { Maybe } from '@fintastic/shared/util/types'
import { AuditLogEntityContext } from '@fintastic/web/util/audit-log' // unified access to all state of current duck

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s.layout

export const selectIsSidebarOpened: Selector<RootState, boolean> = (s) =>
  selectAll(s).isSidebarOpened

export const selectIsDiscussionsOpened: Selector<RootState, boolean> = (s) =>
  selectAll(s).isDiscussionsOpened

export const selectIsHistoryOpened: Selector<RootState, boolean> = (s) =>
  selectAll(s).isHistoryOpened

export const selectSidebarSubmenu: Selector<
  RootState,
  State['sidebarSubmenu']
> = (s) => selectAll(s).sidebarSubmenu

export const selectCurrentDiscussionObjectId: Selector<
  RootState,
  Maybe<string>
> = (s) => selectAll(s).currentDiscussionObjectId

export const selectIsBottomDrawerOpened: Selector<RootState, boolean> = (s) =>
  selectAll(s).isBottomDrawerOpened

export const selectCurrentHistoryContext: Selector<
  RootState,
  Maybe<AuditLogEntityContext>
> = (s) => selectAll(s).currentHistoryContext
