import React from 'react'
import { Box, Switch } from '@mui/material'
import { StyledRoot } from './BreakdownByTime.styled'
import { Tooltip } from '@mui/material'
import { InfoIcon } from './InfoIcon'

export type BreakdownByTimeProps = {
  isApplied: boolean
  onChange: (newValue: boolean) => void
  readonly: boolean
}

export const BreakdownByTime: React.FC<BreakdownByTimeProps> = ({
  isApplied,
  readonly,
  onChange,
}) => (
  <StyledRoot
    label={
      <Box display="inline-flex" alignItems="center">
        Breakdown by Time{' '}
        <Tooltip
          title={
            'When activated, a time dimension is added to the column. To enable the toggle, choose a time dimension in the List settings.'
          }
          placement="bottom"
          arrow
        >
          <Box pl={0.5} display="flex">
            <InfoIcon width={16} height={16} />
          </Box>
        </Tooltip>
      </Box>
    }
    disabled={readonly}
    checked={isApplied}
    onChange={() => onChange(!isApplied)}
    control={<Switch />}
  />
)
