import { ThemeComponents } from './types'
import { FintasticStylesOverride } from '@fintastic/shared/ui/mui-theme'
import { alpha } from '@mui/system'

const makeContainedVariant =
  (color: 'primary' | 'secondary' | 'black'): FintasticStylesOverride =>
  ({ theme }) => ({
    'color': theme.palette[color].contrastText,
    'background': theme.palette[color].main,
    'borderColor': theme.palette[color].dark,

    '&:hover, &.Mui-focusVisible': {
      background: theme.palette[color].dark,
    },

    '&:disabled': {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.action.disabledBackground,
      background: theme.palette.action.disabledBackground,
    },
  })

const containedPrimary = makeContainedVariant('primary')
const containedSecondary = makeContainedVariant('secondary')
const containedBlack = makeContainedVariant('black')

const makeOutlinedVariant =
  (
    color: 'primary' | 'secondary' | 'black',
  ): FintasticStylesOverride<'MuiIconButton'> =>
  ({ theme, ownerState }) => ({
    'color': theme.palette[color].main,
    'background': 'transparent',
    'borderColor': alpha(theme.palette[color].main, 0.5),

    '&:hover': {
      color: theme.palette[color].dark,
      borderColor: theme.palette[color].main,
      background: alpha(
        theme.palette[color].main,
        theme.palette.action.hoverOpacity,
      ),
    },

    '&:disabled': {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.action.disabled,
    },
  })

const makeTextVariant =
  (
    color: 'primary' | 'secondary' | 'black',
  ): FintasticStylesOverride<'MuiIconButton'> =>
  ({ theme, ownerState }) => ({
    'color': theme.palette[color].main,
    'background': 'transparent',

    '&:hover': {
      color: theme.palette[color].dark,
      background: alpha(
        theme.palette[color].main,
        theme.palette.action.hoverOpacity,
      ),
    },

    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  })

export const MuiIconButton: ThemeComponents['MuiIconButton'] = {
  defaultProps: {
    disableFocusRipple: true,
    color: 'outlinedBlack',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      'padding': 0,
      'border': '1px solid transparent',
      'borderRadius': 8,
      'transition': theme.transitions.create(
        ['background-color', 'border-color'],
        {
          duration: theme.transitions.duration.shortest,
        },
      ),
      '&:disabled': {
        color: theme.palette.text.disabled,
      },
      '.MuiTouchRipple-root': {
        display: 'none',
      },
    }),

    sizeSmall: {
      width: 24,
      height: 24,
    },
    sizeMedium: {
      width: 32,
      height: 32,
    },
    sizeLarge: {
      width: 40,
      height: 40,
    },

    colorPrimary: containedPrimary,
    colorSecondary: containedSecondary,
    colorBlack: containedBlack,
    colorContainedPrimary: containedPrimary,
    colorContainedSecondary: containedSecondary,
    colorContainedBlack: containedBlack,
    colorOutlinedPrimary: makeOutlinedVariant('primary'),
    colorOutlinedSecondary: makeOutlinedVariant('secondary'),
    colorOutlinedBlack: makeOutlinedVariant('black'),
    colorTextPrimary: makeTextVariant('primary'),
    colorTextSecondary: makeTextVariant('secondary'),
    colorTextBlack: makeTextVariant('black'),
  },
}
