import { MutationCreator } from './types'
import { expandFunctionLiteral } from './expandFunctionLiteral'
import { expandObjectLiteral } from './expandObjectLiteral'

// @todo add tests
export const suggestionToMutation: MutationCreator = (
  formula,
  literal,
  template,
  templatePosition,
) => {
  if (literal.type === 'function') {
    return expandFunctionLiteral(formula, literal, template, templatePosition)
  }
  return expandObjectLiteral(formula, literal, template, templatePosition)
}
