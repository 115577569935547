import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { useCallback } from 'react'

export const useValidateVersionsIds = () => {
  const query = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: false,
  })

  return useCallback(
    (versions: string[]) => {
      if (!query.data?.length) {
        return versions
      }

      return versions.filter(
        (versionId) => !!query.data.find((i) => i.uuid === versionId),
      )
    },
    [query.data],
  )
}
