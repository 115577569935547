import type { GenericReportTreeRow } from '@fintastic/web/util/generic-report'
import type { ColDef, ColGroupDef, GridApi } from 'ag-grid-community'
import type { SetFilter } from 'ag-grid-enterprise'
import { compact } from 'lodash'
import { useCallback } from 'react'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

const FILTER_OUT = '_other'

export const useFilterOutOthers = (allowedColIDs?: string[]) => {
  const enabled = useIsFeatureEnabled('filter_out_others')

  return useCallback(
    (api: GridApi<unknown>) => {
      if (!api || !enabled) {
        return
      }

      const colDefs = api.getColumnDefs() as Array<ColDef<unknown>>
      const allFilters = compact(
        colDefs
          ?.flatMap((col) =>
            (col as ColGroupDef).children?.length
              ? ((col as ColGroupDef).children as ColDef[])
              : [col],
          )
          ?.filter((colDef) => colDef.filter === 'agSetColumnFilter')
          .map((colDef) => colDef.field),
      )
      let anyFilterChanged = false
      allFilters.forEach((filterName) => {
        if (allowedColIDs?.length) {
          if (!allowedColIDs.includes(filterName)) {
            return
          }
        }

        const instance = api.getFilterInstance(
          filterName,
        ) as SetFilter<GenericReportTreeRow>
        const newValues = compact(instance.getValues()).filter(
          (v) => v && v !== FILTER_OUT,
        )

        if (newValues.length) {
          instance.setModel({ values: newValues })
          anyFilterChanged = true
        }
      })

      if (anyFilterChanged) {
        api.onFilterChanged()
      }
    },
    [enabled, allowedColIDs],
  )
}
