import { GridApi } from 'ag-grid-community'
import { GenericReportTreeRow } from '@fintastic/web/util/generic-report'
import { useCallback } from 'react'

export const useRenderCalculatedRows = () =>
  useCallback((api: GridApi<GenericReportTreeRow>) => {
    const rowNodes = api
      .getRenderedNodes()
      .filter((node) => node.data?.calculatedRow)

    api.redrawRows({
      rowNodes,
    })
  }, [])
