import { useReleaseVersionUserLocker } from '@fintastic/web/data-access/versions'
import { useCallback } from 'react'
import { useCancelEditing } from '@fintastic/web/data-access/metrics-and-lists'

export function useStopEditingHandler(versionId: string) {
  const cancelEditing = useCancelEditing()

  const { mutate: releaseVersionUserLocker } =
    useReleaseVersionUserLocker(versionId)

  return useCallback(async () => {
    cancelEditing()
    releaseVersionUserLocker()
  }, [cancelEditing, releaseVersionUserLocker])
}
