import { useMutation, useQueryClient } from 'react-query'
import { iamQueryKeys } from '../../utils/query-keys'
import { PatchIAMResourcePayload, patchIAMResource } from '../../api/resources'
import { AxiosError, AxiosResponse } from 'axios'
import { IAMResource } from '../../types'

export const useIAMResourceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<void>,
    AxiosError,
    PatchIAMResourcePayload,
    {
      previousResources: IAMResource[]
    }
  >(
    iamQueryKeys.mutateResource(),
    (patch: PatchIAMResourcePayload) => patchIAMResource(patch),
    {
      onMutate(patch) {
        const previousResources =
          queryClient.getQueryData<IAMResource[]>(
            iamQueryKeys.resources(patch.resource_type),
          ) || []

        // Optimistic Update
        queryClient.setQueryData<IAMResource[]>(
          iamQueryKeys.resources(patch.resource_type),
          processPatchForResourcesArray(previousResources, patch),
        )

        return {
          previousResources,
        }
      },
      onError: (err, patch, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData<IAMResource[]>(
          iamQueryKeys.resources(patch.resource_type),
          ctx?.previousResources || [],
        )

        console.error(err)
      },
      onSettled(data, err, patch) {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: iamQueryKeys.resources(patch.resource_type),
          }),
        ])
      },
    },
  )
}

const processPatchForResourcesArray = (
  resources: IAMResource[],
  patch: PatchIAMResourcePayload,
): IAMResource[] =>
  patch.resource_ids.reduce<IAMResource[]>((acc, id) => {
    if (acc.find((i) => i.resource_id === id)) {
      return acc.map((mapped) => {
        if (mapped.resource_id === id) {
          return {
            resource_id: mapped.resource_id,
            resource_type: mapped.resource_type,
            restricted_for: patch.restricted_for,
          }
        }

        return mapped
      })
    }

    return [
      ...acc,
      {
        resource_id: id,
        resource_type: patch.resource_type,
        restricted_for: patch.restricted_for,
      },
    ]
  }, resources)
