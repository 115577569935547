import { LabelTagBase, LabelTagListItem, LabelsContext } from '../types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosResponse } from 'axios'

export function getLabels(context: LabelsContext) {
  return axios.get<{
    result: LabelTagListItem[]
  }>(endpoints.list(context))
}

export function createLabel(context: LabelsContext, label: LabelTagBase) {
  return axios.post<
    LabelTagListItem,
    AxiosResponse<LabelTagListItem>,
    LabelTagBase
  >(endpoints.createLabel(context), label)
}

export function updateLabel(
  context: LabelsContext,
  id: number,
  label: LabelTagBase,
) {
  return axios.put<
    LabelTagListItem,
    AxiosResponse<LabelTagListItem>,
    LabelTagBase
  >(endpoints.updateLabel(context, id), label)
}

export function deleteLabel(context: LabelsContext, id: number) {
  return axios.delete(endpoints.deleteLabel(context, id))
}
