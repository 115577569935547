import { styled, Menu, ButtonBase, darken } from '@mui/material'
import { menuItemClasses } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledButton = styled(ButtonBase)`
  ${styledMixins.alignContentCenter()};
  padding: 4px;

  background: #eff6fc;
  border-radius: 4px;
  transition: ${({ theme }) => theme.transitions.create('all')};

  &:hover {
    background: ${darken('#eff6fc', 0.02)};
  }
`

export const StyledButtonIcon = styled('span')`
  ${styledMixins.alignContentCenter()};
  margin-right: 4px;
`

export const StyledButtonText = styled('span')`
  ${styledMixins.alignContentCenter()};
`

export const StyledMenu = styled(Menu)`
  .${menuItemClasses.root} p {
    overflow: hidden;

    width: 100%;

    text-overflow: ellipsis;
  }
`
