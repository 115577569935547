import { ToolPanelDef } from 'ag-grid-community'
import { BlanksFilterToolPanel } from './BlanksFilterToolPanel'
import { resolveFintasticAgIconKey } from '@fintastic/shared/ui/ag-grid-theme-fintastic'

export const BLANKS_FILTER_TOOL_PANEL_ID = 'blanksFilter'

export const blanksFilterToolPanelDefinition: ToolPanelDef = {
  id: BLANKS_FILTER_TOOL_PANEL_ID,
  labelDefault: '',
  toolPanel: BlanksFilterToolPanel,
  labelKey: '',
  iconKey: resolveFintasticAgIconKey('rows'),
  maxWidth: 250,
  minWidth: 250,
  width: 250,
}
