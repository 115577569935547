import React, { useMemo } from 'react'
import { Topbar } from '@fintastic/shared/ui/app-layout-framework'
import { LiveActualsStatus } from '../LiveActualsStatus/LiveActualsStatus'
import { compact } from 'lodash'
import { VersionCalculationStatus } from '@fintastic/web/feature/versions'
import { useVersionUserLockQuery } from '@fintastic/web/data-access/versions'

type LiveActualsTopBarProps = {
  versionId: string
  isUpdated: boolean
  pageTitle: string
  periodSelector?: React.ReactNode
}

export const LiveActualsTopBar: React.FC<LiveActualsTopBarProps> = ({
  versionId,
  pageTitle,
  isUpdated,
  periodSelector,
}) => {
  const { lock } = useVersionUserLockQuery(versionId)

  const settingsItems = useMemo(
    () =>
      compact([
        {
          content: (
            <LiveActualsStatus
              pageTitle={pageTitle}
              isUpdated={isUpdated}
              lock={lock}
            />
          ),
          sx: {
            marginRight: 'auto',
            marginLeft: 0,
          },
        },
        periodSelector && {
          content: periodSelector,
        },
        {
          content: <VersionCalculationStatus versionIds={[versionId]} />,
          mostRight: true,
        },
      ]),
    [pageTitle, isUpdated, lock, periodSelector, versionId],
  )

  return <Topbar settingsItems={settingsItems} />
}
