import { Maybe } from '@fintastic/shared/util/types'

const NAME_REG_EXP = /^[a-zA-Z_][a-zA-Z0-9_]*$/

export const validateEntityName = (
  name: string,
  isUnique: (name: string) => boolean,
): Maybe<string> => {
  const trimmedName = name.trim()

  if (trimmedName.length === 0) {
    return 'Name is required'
  }

  if (!NAME_REG_EXP.test(trimmedName)) {
    return 'Invalid name'
  }

  if (!isUnique(trimmedName)) {
    return 'This name is already in use'
  }

  return null
}
