import React, { useCallback } from 'react'
import { Column } from '../types'
import { ColumnVisibilityToggleCallback } from './types'
import {
  StyledActions,
  StyledIcon,
  StyledRoot,
  StyledTitle,
  StyledTitleText,
  StyledTitleTooltip,
} from './ColumnsListItem.styled'
import { columnTypeToIconMap } from '@fintastic/web/feature/metrics-and-lists'
import { IconButton } from '@mui/material'
import { ViewOffIcon, ViewOnIcon } from '@fintastic/shared/ui/icons'

export type ColumnsListItemProps = {
  column: Column
  visible: boolean
  onToggleVisibility: ColumnVisibilityToggleCallback
  onNavigateToColumn?: () => void
}

export const ColumnsListItem: React.FC<ColumnsListItemProps> = ({
  column,
  onToggleVisibility,
  visible,
  onNavigateToColumn,
}) => {
  const handleToggle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onToggleVisibility(column.id, !visible)
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    [column.id, onToggleVisibility, visible],
  )

  const TypeIcon = columnTypeToIconMap[column.type]
  // custom tooltip because of https://github.com/mui/material-ui/issues/38379
  // bug
  return (
    <StyledRoot
      active={visible}
      onClick={visible ? onNavigateToColumn : undefined}
    >
      <StyledIcon>
        <TypeIcon />
      </StyledIcon>
      <StyledTitleText>
        <StyledTitle>{column.label}</StyledTitle>

        <StyledTitleTooltip className={'tooltiptext'}>
          {visible ? (
            <b>
              <i>Click to scroll to this column</i> {column.label}
            </b>
          ) : (
            column.label
          )}
        </StyledTitleTooltip>
      </StyledTitleText>
      <StyledActions>
        <IconButton size="medium" color="textBlack" onClick={handleToggle}>
          {visible ? (
            <ViewOnIcon fontSize="small" />
          ) : (
            <ViewOffIcon fontSize="small" />
          )}
        </IconButton>
      </StyledActions>
    </StyledRoot>
  )
}
