import { createKeys } from './utils'

export const chartTypes = {
  bars: { name: 'Bar chart' },
  lines: { name: 'Line chart' },
  // @todo: for the future
  // stackedBars: { name: 'Stacked bar chart' },
  // waterfall: { name: 'Waterfall chart' },
}

export type ChartType = keyof typeof chartTypes

export const chartTypeKeys: ChartType[] = createKeys<ChartType>(chartTypes)

export function isChartType(value: unknown): value is ChartType {
  return chartTypes[value as ChartType] !== undefined
}

export const defaultChartType: ChartType = 'bars'

export function getChartTypeName(type?: ChartType) {
  return chartTypes[isChartType(type) ? type : defaultChartType].name
}
