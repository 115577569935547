import React from 'react'
import { ModalCloseButton } from '@fintastic/shared/ui/legacy-modal-framework'
import {
  StyledDrawerNotificationClose,
  StyledDrawerNotificationMessage,
} from './DrawerNotification.styled'

export type DrawerNotificationSeverity =
  | 'info'
  | 'success'
  | 'warning'
  | 'error'

export type DrawerNotificationProps = {
  open: boolean
  severity: DrawerNotificationSeverity
  message: string
  onClose?: () => void
}

export const DrawerNotification: React.FC<DrawerNotificationProps> = ({
  open,
  severity,
  message,
  onClose,
}) => {
  if (!open) {
    return null
  }

  return (
    <StyledDrawerNotificationMessage
      severity={severity}
      withClose={Boolean(onClose)}
    >
      {message}

      {onClose && (
        <StyledDrawerNotificationClose>
          <ModalCloseButton onClick={onClose} />
        </StyledDrawerNotificationClose>
      )}
    </StyledDrawerNotificationMessage>
  )
}
