import React, { ChangeEvent, memo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { OnChangeChartSettings } from '../../../../internal-types'
import {
  chartDecimalPlaces,
  ChartDecimalPlaces,
  chartDecimalPlacesKeys,
  defaultChartDecimalPlaces,
  isChartDecimalPlaces,
} from '@fintastic/web/feature/charts'
import {
  StyledTextWithoutIcon,
  StyledTextWithoutIconField,
} from '../common-styles'

export type ChartDecimalPlacesSelectProps = {
  decimalPlaces: ChartDecimalPlaces
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartDecimalPlacesSelect: React.FC<ChartDecimalPlacesSelectProps> =
  memo(({ decimalPlaces, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartDecimalPlaces(e.target.value)) {
          onChangeChartSettings({ decimalPlaces: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextWithoutIconField
          id="chart-decimal-places-select"
          fullWidth
          value={decimalPlaces}
          size="small"
          select
          label="Decimal places"
          defaultValue={defaultChartDecimalPlaces}
          onChange={handleSelect}
          disabled={disabled}
        >
          {chartDecimalPlacesKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledTextWithoutIcon
                data-testid={`chart-dec-places-item-${ct}`}
              >
                {chartDecimalPlaces[ct]}
              </StyledTextWithoutIcon>
            </MenuItem>
          ))}
        </StyledTextWithoutIconField>
      </FormField>
    )
  })
