import { axios } from '@fintastic/web/data-access/service-axios'
import { PartialVersion, Version } from '@fintastic/web/util/versions'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'

const endpoints = {
  versionsList: '/planning/api/v1/scenario/',
  version: (id: string) => `${endpoints.versionsList}${id}`,
} as const

export type VersionsManagementApiErrorResponse = {
  message: string
}

export function getVersion(id: string) {
  return axios.get<{ version: Version }>(endpoints.version(id))
}

export function getVersionsList(
  showArchived = false,
  showDeleted = false,
  showLiveActuals = false,
) {
  return axios.get<Version[]>(endpoints.versionsList, {
    params: {
      show_deleted: encodeURIComponent(showDeleted),
      show_archived: encodeURIComponent(showArchived),
      show_live_actuals: encodeURIComponent(showLiveActuals),
    },
  })
}

export function updateVersion(version: PartialVersion) {
  return axios.put<Version>(endpoints.version(version.uuid), version)
}

export function extendVersionPeriod(id: string, endDate: string) {
  return axios.put(`${endpoints.version(id)}/period/`, {
    end: endDate,
  })
}

export function copyVersion(
  id: string,
  name: string,
  dateRange: {
    start: Date
    end: Date
  },
  duplicatePermissions = false,
  duplicateLabels = false,
) {
  return axios.post<Version>(`${endpoints.version(id)}/copy/`, undefined, {
    params: {
      name: encodeURIComponent(name),
      period_start: dayjs(dateRange.start)
        .startOf('month')
        .format(DEFAULT_DAYJS_DATE_FORMAT),
      period_end: dayjs(dateRange.end)
        .endOf('month')
        .format(DEFAULT_DAYJS_DATE_FORMAT),
      inherit_access: encodeURIComponent(duplicatePermissions),
      inherit_labels: encodeURIComponent(duplicateLabels),
    },
  })
}

export function lockVersion(id: string) {
  return axios.post<Version>(`${endpoints.version(id)}/lock/`)
}

export function unlockVersion(id: string) {
  return axios.post<Version>(`${endpoints.version(id)}/unlock/`)
}

export function deleteVersion(id: string) {
  return axios.delete<Version>(endpoints.version(id))
}

export function archiveVersion(id: string) {
  return axios.delete<Version>(`${endpoints.version(id)}/archive/`)
}

export function unarchiveVersion(id: string) {
  return axios.delete<Version>(`${endpoints.version(id)}/unarchive/`)
}
