import { EditListColumnsDataIntent, MetricDataFillStrategy } from '../../types'
import { Maybe } from '@fintastic/shared/util/types'

export const createEditListColumnsDataIntent = (
  fillStrategy: Maybe<MetricDataFillStrategy> = null,
): EditListColumnsDataIntent => ({
  indexes: [],
  dimensions: [],
  columns: [],
  fill_strategy: fillStrategy,
})
