import { useCallback, useMemo } from 'react'
import { ChangeColumnCurrencySignPositionAction } from '../types'
import { useListColumnsActions } from '@fintastic/web/data-access/metrics-and-lists'

export function useChangeColumnCurrencySignPositionFlow() {
  const { changeCurrencySignPosition } = useListColumnsActions()

  const handleAction = useCallback<ChangeColumnCurrencySignPositionAction>(
    (columnId, position) => {
      changeCurrencySignPosition({
        columnId,
        position,
      })
    },
    [changeCurrencySignPosition],
  )

  return useMemo(
    () => ({
      action: handleAction,
    }),
    [handleAction],
  )
}
