import { isFinite } from 'lodash'
import { DeeplinkValueElement } from '../types'

export const getObjectFieldName = (input: string) =>
  input.split('[')?.[1]?.split(']')?.[0]

export const isConvertableToArray = (
  input: Record<string, DeeplinkValueElement | DeeplinkValueElement[]>,
): boolean => Object.keys(input).every((key) => isFinite(parseInt(key, 10)))

export const convertMaybeArray = (
  input: Record<string, DeeplinkValueElement | DeeplinkValueElement[]>,
): Array<DeeplinkValueElement | DeeplinkValueElement[]> => Object.values(input)
