import { Maybe } from '@fintastic/shared/util/types'
import { FormulaMutationsApi } from '../../../formula-mutations/types'
import { suggestionToMutation } from './suggestionToMutation'
import { SyntaxLiteral } from '../../../syntax/types'

// @todo add tests
export function applySuggestionToFormula(
  formula: string,
  literal: SyntaxLiteral,
  template: Maybe<string>,
  templatePosition: Maybe<number>,
  formulaMutationApi: FormulaMutationsApi,
): boolean {
  if (template === null || templatePosition === null) {
    return false
  }

  const mutation = suggestionToMutation(
    formula,
    literal,
    template,
    templatePosition,
  )
  if (mutation === null) {
    return false
  }

  formulaMutationApi.applyMutation(mutation)
  return true
}
