import {
  ConfirmationPopup as ConfirmationPopupComponent,
  ConfirmationPopupProps,
} from './ConfirmationPopup'
import { ConfirmButton } from './ConfirmButton'
import { DeclineButton } from './DeclineButton'

type ConfirmationPopupWithAttachedComponents =
  typeof ConfirmationPopupComponent & {
    ConfirmButton: typeof ConfirmButton
    DeclineButton: typeof DeclineButton
  }
;(
  ConfirmationPopupComponent as ConfirmationPopupWithAttachedComponents
).ConfirmButton = ConfirmButton
;(
  ConfirmationPopupComponent as ConfirmationPopupWithAttachedComponents
).DeclineButton = DeclineButton

export const ConfirmationPopup =
  ConfirmationPopupComponent as ConfirmationPopupWithAttachedComponents
export type { ConfirmationPopupProps }
