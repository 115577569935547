import { styled, ButtonBase, Box, Typography } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled('div')`
  width: 100%;
`

export const StyledButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: ${({ theme }) => theme.spacing(0.375, 0.5)};

  background: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
`

export const StyledButtonText = styled(Typography)``

export const StyledButtonIcon = styled(Box)`
  ${styledMixins.alignContentCenter()};
  margin-left: ${({ theme }) => theme.spacing(1)};
`
