import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { FolderRenameForm } from '../../forms/FolderRenameForm'
import { BoardFolder } from '../../../types'
import { Maybe } from '@fintastic/shared/util/types'

export type FolderRenameModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  folderId?: Maybe<BoardFolder['id']>
}

export const FolderRenameModal: React.FC<FolderRenameModalProps> = (props) => {
  const { isOpen, onRequestClose, folderId } = props

  return (
    <Modal open={isOpen} onRequestClose={onRequestClose} title="Rename Folder">
      {folderId && (
        <FolderRenameForm
          closeParentModal={onRequestClose}
          folderId={folderId}
        />
      )}
    </Modal>
  )
}
