import { IdToken, User } from '@fintastic/shared/data-access/auth0-react'
import { Maybe } from '@fintastic/shared/util/types'
import Cookie from 'js-cookie'

export interface DecodedToken {
  claims: IdToken
  user: User
}

export interface CacheEntry {
  id_token: string
  access_token: string
  expires_in: number
  decodedToken: DecodedToken
  audience: string
  scope: string
  client_id: string
  refresh_token?: string
}

export type TestingAuth0LSData = {
  body: Partial<CacheEntry>
  expiresAt: string
}

export function shouldUseTestAuth0Client(): boolean {
  return (
    Cookie.get('_fintastic-auth0-test-env-data') !== undefined ||
    localStorage.getItem('auth0Cypress') !== null
  )
}

export const getTestingLSAuthData = (): Maybe<TestingAuth0LSData> => {
  const jsonString =
    Cookie.get('_fintastic-auth0-test-env-data') ||
    localStorage.getItem('auth0Cypress')

  if (jsonString === null || jsonString === undefined) {
    return null
  }

  return JSON.parse(jsonString) as TestingAuth0LSData
}

export const stub = () => {
  throw new Error('Not allowed in Testing mode')
}

export class TestingAuth0Client {
  checkSession() {
    return Promise.resolve()
  }

  getUser() {
    const data = getTestingLSAuthData()
    return data?.body.decodedToken?.user
  }

  async getTokenSilently() {
    const data = getTestingLSAuthData()
    return data?.body.access_token
  }

  async getIdTokenClaims() {
    const data = getTestingLSAuthData()
    return data?.body.decodedToken?.claims
  }

  buildAuthorizeUrl = stub
  buildLogoutUrl = () => 'http://not-working-in-cypress'
  loginWithPopup = stub
  loginWithRedirect = stub
  logout = stub
  getAccessTokenWithPopup = stub
  handleRedirectCallback = stub
}
