import { QueryClient } from 'react-query'
import {
  invalidateListColumnsCache,
  removeListColumnsCache,
} from '@fintastic/web/data-access/metrics-and-lists'

export const invalidateListFilterRelatedData = async (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
) => {
  await invalidateListColumnsCache(queryClient, {
    listId,
    versionId,
  })
  await removeListColumnsCache(queryClient, {
    listId,
    versionId,
  })
}
