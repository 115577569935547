import { axios } from '@fintastic/web/data-access/service-axios'
import type { MetricOrListType } from '@fintastic/web/util/metrics-and-lists'
import { DimensionValueId, GroupId, ColumnId } from '../types'
import { Maybe } from '@fintastic/shared/util/types'
import { AxiosResponse } from 'axios'

export type UnrestrictedColumnsResult = Array<{
  resource_id: ColumnId
  resource_type: PolicyColumnResourceType
}>
export type UnrestrictedDimensionsItem = {
  resource_id: string
  resource_type: 'dimension'
}
export type UnrestrictedDimensionsResult = Array<UnrestrictedDimensionsItem>

// @todo(mykola): Resolve duplicate with comments
export type GroupsAPIResult = {
  id: string
  created_at: string
  updated_at: string
}[]

export type PoliciesAPIDimensions = Record<string, Array<DimensionValueId>>

export type PolicyTableResourceType = MetricOrListType
export type PolicyColumnResourceType = 'metric'

export type Policy = {
  tables: Array<{
    resource_id: string
    resource_type: PolicyTableResourceType
    is_allowed: boolean
    allowed_dimensions?: Maybe<PoliciesAPIDimensions>
  }>
  columns: {
    resource_id: string
    resource_type: 'metric'
    allowed_dimensions: PoliciesAPIDimensions
  }[]
  allowed_dimensions: PoliciesAPIDimensions
  group_id: GroupId
  hash?: string
}

export type PoliciesAPIResult = Array<Policy>

export const putPolicy = (policy: Policy, hash: string) =>
  axios
    .put<Policy>(
      `planning/api/v1/iam/governance_policies/${policy.group_id}/`,
      policy,
      {
        params: { hash },
      },
    )
    .then((res) => res.data)

export const fetchPolicy = (groupId: string) =>
  axios
    .get<Policy>(`planning/api/v1/iam/governance_policies/${groupId}`)
    .then((res) => res.data)

export const fetchGroupList = () =>
  axios
    .get<{ result: GroupsAPIResult }>('planning/api/v1/iam/groups/')
    .then((res) => res.data.result)

export const fetchUnrestrictedColumns = () =>
  axios
    .get<{ result: UnrestrictedColumnsResult }>(
      'planning/api/v1/iam/unrestricted_columns/',
    )
    .then((res) => res.data.result)

export const fetchUnrestrictedDimensions = () =>
  axios
    .get<{ result: UnrestrictedDimensionsResult }>(
      'planning/api/v1/iam/unrestricted_dimensions/',
    )
    .then((res) => res.data.result)

export const putDimensionRestrictions = (
  dimensions: ColumnId[],
  is_restricted: boolean,
) =>
  axios.patch<void, AxiosResponse<void>, UnrestrictedDimensionsPatchPayload>(
    'planning/api/v1/iam/unrestricted_dimensions/',
    {
      dimensions,
      is_restricted,
    },
  )

type UnrestrictedDimensionsPatchPayload = {
  is_restricted: boolean
  dimensions: string[]
}
