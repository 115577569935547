import {
  INVALID_FORMULA_VALUE,
  type CalculatedRowOptions,
  type GenericReportTreeRow,
  type GenericReportTreeRowCellValue,
  GenericReportValue,
} from '@fintastic/web/util/generic-report'
import type { RowNode, ValueGetterParams } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import {
  BlankOrMaskedValue,
  BLANK_VALUE,
  subtractWithBlanks,
  stringifyBlankOrMaskedValue,
  MathDivideByZeroError,
  percentageWithBlanks,
} from '@fintastic/web/util/blanks-and-masked'
import { executeCalculatedRowFormula } from './calculate-row-formula'
import { DiffOperation } from '../types'
import { getCellValue } from './cell-value-getter'

const getNodeRows = (
  params: Pick<
    ValueGetterParams<GenericReportTreeRow>,
    'data' | 'column' | 'node'
  >,
  rowsScope: CalculatedRowOptions['rowsScope'],
) =>
  rowsScope.map(
    ([rowName, name]) =>
      [
        name,
        params?.node?.parent?.childrenAfterFilter?.find(
          (node) => node.key === rowName,
        ),
      ] as const,
  )

const getScopeValueFn =
  (colId: string, reportOrVersionId: string, period: string) =>
  ([name, node]: readonly [
    name: string,
    node?: RowNode<GenericReportTreeRow>,
  ]) => {
    if (!node?.aggData) {
      if (!node?.data) {
        return [name, getCellValue([])] as const
      }
      const relevantValues = (node.data?.values || []).filter(
        (v: GenericReportValue) =>
          v.reportOrVersionId === reportOrVersionId && v._timestamp === period,
      )

      return [name, getCellValue(relevantValues)] as const
    }

    const finalValue = node?.aggData[colId]?.finalValue as
      | BlankOrMaskedValue
      | undefined

    return [name, finalValue ?? BLANK_VALUE] as const
  }

const calculatedRowFinalValueGetter = (
  calculatedRow: GenericReportTreeRow['calculatedRow'],
  params: Pick<
    ValueGetterParams<GenericReportTreeRow>,
    'data' | 'column' | 'node'
  >,
  reportOrVersionId: string,
  period: string,
): BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE => {
  if (!calculatedRow) {
    return BLANK_VALUE
  }

  const { rowsScope, formula } = calculatedRow

  if (!formula) {
    return BLANK_VALUE
  }

  const nodeRows = getNodeRows(params, rowsScope)

  const scope = Object.fromEntries<BlankOrMaskedValue>(
    nodeRows.map(
      getScopeValueFn(params.column.getColId(), reportOrVersionId, period),
    ),
  )

  try {
    return executeCalculatedRowFormula(formula, scope)
  } catch (ex) {
    if (ex instanceof MathDivideByZeroError) {
      return INVALID_FORMULA_VALUE
    }
    throw ex
  }
}

export const calculatedRowValueGetter = (
  calculatedRow: GenericReportTreeRow['calculatedRow'],
  params: Pick<
    ValueGetterParams<GenericReportTreeRow>,
    'data' | 'column' | 'node'
  >,
  reportOrVersionId: string,
  period: string,
): Maybe<Partial<GenericReportTreeRowCellValue>> => {
  const finalValue = calculatedRowFinalValueGetter(
    calculatedRow,
    params,
    reportOrVersionId,
    period,
  )

  return {
    ...params.data,
    isCalculated: true,
    finalValue,
    toString: () => stringifyBlankOrMaskedValue(finalValue),
  }
}

export const calculatedRowDiffValueGetter = (
  calculatedRow: GenericReportTreeRow['calculatedRow'],
  params: ValueGetterParams<GenericReportTreeRow>,
  colIdA: string,
  colIdB: string,
  operation: DiffOperation,
  periodA: string,
  periodB: string,
  reportId: string,
): Maybe<Partial<GenericReportTreeRowCellValue>> => {
  if (!calculatedRow) {
    return null
  }

  const { rowsScope, formula } = calculatedRow

  if (!formula) {
    return null
  }

  const nodeRows = getNodeRows(params, rowsScope)

  if (!colIdA || !colIdB) {
    console.error(
      'Could not find column for calculated value diff aggregation!',
    )
    return {
      ...params.data,
      toString: () => 'Err!',
    }
  }

  const [a, b] = [
    [colIdA, periodA],
    [colIdB, periodB],
  ].map(
    ([colId, period]): BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE => {
      const scope = Object.fromEntries<BlankOrMaskedValue>(
        nodeRows.map(getScopeValueFn(colId, reportId, period)),
      )

      try {
        return executeCalculatedRowFormula(formula, scope)
      } catch (ex) {
        if (ex instanceof MathDivideByZeroError) {
          return INVALID_FORMULA_VALUE
        }
        throw ex
      }
    },
  )

  const isInvalidValue =
    a === INVALID_FORMULA_VALUE || b === INVALID_FORMULA_VALUE

  let finalValue: BlankOrMaskedValue | typeof INVALID_FORMULA_VALUE

  if (isInvalidValue) {
    finalValue = INVALID_FORMULA_VALUE
  } else {
    try {
      finalValue =
        operation === 'subtract'
          ? subtractWithBlanks(a, b)
          : percentageWithBlanks(a, b)
    } catch (ex) {
      if (ex instanceof MathDivideByZeroError) {
        finalValue = INVALID_FORMULA_VALUE
      } else {
        throw ex
      }
    }
  }

  return {
    ...params.data,
    values: params.data?.values.map((v) => ({
      ...v,
      uom: operation === 'percentage' ? 'percentage' : v.uom,
    })),
    isCalculated: true,
    finalValue,
    toString: () => stringifyBlankOrMaskedValue(finalValue),
  }
}
