import { CommentsThreadsBulkItem, ReplaceEvent } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import {
  threadEventsQueryKey,
  threadsBulkByPageQueryKey,
} from '../utils/query-key'
import { replaceEvent } from '../api/api'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'

export const useEditEvent = (threadId: number, pageKey: string) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse,
    AxiosError,
    ReplaceEvent,
    {
      previousThreadsBulk: CommentsThreadsBulkItem[]
    }
  >(
    threadEventsQueryKey(threadId),
    (eventReplacement) => replaceEvent(threadId, eventReplacement),
    {
      onMutate: async (eventReplacement) => {
        await queryClient.cancelQueries(threadEventsQueryKey(threadId))

        const previousThreadsBulk =
          queryClient.getQueryData<CommentsThreadsBulkItem[]>(
            threadsBulkByPageQueryKey(pageKey),
          ) || []

        queryClient.setQueryData<CommentsThreadsBulkItem[]>(
          threadsBulkByPageQueryKey(pageKey),
          (prev) =>
            (prev ?? [])?.map((thread) => {
              if (thread.id !== threadId) {
                return thread
              }

              return {
                ...thread,
                comments: thread.comments.map((comment) =>
                  comment.id === eventReplacement.id
                    ? {
                        ...comment,
                        body: eventReplacement.body,
                      }
                    : comment,
                ),
              }
            }),
        )

        return { previousThreadsBulk }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData(
          threadsBulkByPageQueryKey(pageKey),
          ctx?.previousThreadsBulk || [],
        )

        console.log(err)
        toast.error(`Failed to edit event. Error: ${err?.code || 'unknown'}`)
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: threadsBulkByPageQueryKey(pageKey),
        })
      },
    },
  )

  return result
}
