import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import { DimensionValueId, VersionCategoryDimension } from '../types'
import * as selectors from './common-selectors'
import * as helpers from './common-helpers'

export class CategoryDimensionWrapper
  implements ImmutableADTWrapper<VersionCategoryDimension>
{
  _rawData: VersionCategoryDimension

  private _valuesReference: DimensionValueId[] = []

  constructor(d: VersionCategoryDimension) {
    this._rawData = d
  }

  unwrap(): VersionCategoryDimension {
    return this._rawData
  }

  get id() {
    return selectors.id(this._rawData)
  }

  get label() {
    return selectors.label(this._rawData)
  }

  get type() {
    return selectors.type(this._rawData)
  }

  get orderedValuesIds(): DimensionValueId[] {
    if (this._valuesReference.length === 0) {
      this._valuesReference = Object.keys(this._rawData.values).sort()
    }
    return this._valuesReference
  }

  resolveValueLabel(valueId: DimensionValueId) {
    return helpers.resolveValueLabel(this._rawData, valueId)
  }
}
