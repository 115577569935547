import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import keyBy from 'lodash/keyBy'

export const applyPartialUpdateTransaction = <
  TData extends Record<string, unknown>,
>(
  gridApi: GridApi<TData>,
  partialRowsToUpdate: Partial<TData>[],
  rowIdField: string,
) => {
  const newRowsMap = keyBy(partialRowsToUpdate, rowIdField)
  const rowsToUpdate: TData[] = []

  gridApi.forEachNode(({ data }) => {
    if (!data) {
      return
    }
    const updatingRow = newRowsMap[data[rowIdField] as string]
    if (updatingRow === undefined) {
      return
    }
    rowsToUpdate.push({
      ...data,
      ...updatingRow,
    })
  })

  gridApi.applyTransaction({
    update: rowsToUpdate,
  })
}
