import { MutationKey, useMutation, useQueryClient } from 'react-query'
import { copyBoard, createFolder, EditableBoardParams } from '../api'
import { boardsListQueryKeyGetter } from './useBoardsListQuery'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'

export const boardCopyMutationKeyGetter = (): MutationKey => 'board/copy'

export function useBoardCopyMutation(boardId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    boardCopyMutationKeyGetter(),
    async (variables: EditableBoardParams & { newFolderName?: string }) => {
      const { newFolderName, ...createBoardParams } = variables

      if (newFolderName !== undefined) {
        const folderResponse = await createFolder({ name: newFolderName })
        createBoardParams.folder_id = folderResponse.data.id
      }

      const response = await copyBoard(boardId, createBoardParams)
      return response.data
    },
    {
      onSuccess: (_, variables) => {
        if (variables.newFolderName !== undefined) {
          void queryClient.invalidateQueries(
            boardsFoldersListQueryKeyGetter(),
            {
              exact: true,
            },
          )
        }
        void queryClient.invalidateQueries(boardsListQueryKeyGetter(), {
          exact: true,
        })
      },
    },
  )
}
