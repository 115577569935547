import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import {
  MetricData,
  MetricDataFormat,
  MetricDataValue,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DeepReadonly,
  Dimension,
  DimensionId,
  DimensionValueId,
  Maybe,
} from '@fintastic/shared/util/types'
import { VersionDimension } from '@fintastic/web/util/dimensions'

// @todo narrow the MetricData to CompactMetricData
export class MetricDataWrapper implements ImmutableADTWrapper<MetricData> {
  _rawData: DeepReadonly<MetricData>

  constructor(data: MetricData) {
    this._rawData = data
  }

  unwrap(): MetricData {
    return this._rawData
  }

  format(): MetricDataFormat {
    return this._rawData.format
  }

  dimensions(): DimensionId[] {
    return this._rawData.indexes
  }

  hasDimension(dimId: DimensionId): boolean {
    return this.dimensions().includes(dimId)
  }

  hasOneOfDimensions(dimId: DimensionId[]): boolean {
    return this.dimensions().some((did) => dimId.includes(did))
  }

  dimensionsAreEmpty(): boolean {
    return this.dimensions().length === 0
  }

  dimensionValues(): Array<DimensionValueId[]> {
    return this._rawData.dimensions
  }

  values(): MetricDataValue[] {
    return this._rawData.values
  }

  getRegeneratedValues(
    defaultValue: MetricDataValue = null,
  ): MetricDataValue[] {
    const totalValuesLength = this.dimensionValues().reduce(
      (length, dimensionValues) => length * dimensionValues.length,
      1,
    )
    return Array(totalValuesLength).fill(defaultValue)
  }

  getTimeDimensionId(allTimeDimensions: DimensionId[]): Maybe<DimensionId> {
    return this.dimensions().find((d) => allTimeDimensions.includes(d)) || null
  }

  // @todo remove it later, it's just for testing
  toDimensionsList(allDimensions: VersionDimension[]): Dimension[] {
    return this.dimensions().map<Dimension>((id, index) => {
      const dimension = allDimensions.find((d) => d.id === id)
      return {
        id,
        label: id,
        type: dimension?.type || 'Range',
        values: Object.fromEntries(
          this.dimensionValues()[index].map((v) => [
            v,
            dimension && 'values' in dimension ? dimension.values[v] : v,
          ]),
        ),
      }
    })
  }

  findDimension(predicate: (did: DimensionId) => boolean): Maybe<{
    id: DimensionId
    valueIds: DimensionValueId[]
  }> {
    const index = this.dimensions().findIndex(predicate)
    if (index === -1) {
      return null
    }

    return {
      id: this.dimensions()[index],
      valueIds: this.dimensionValues()[index],
    }
  }

  cloneDimensions(): ReturnType<typeof this.dimensions> {
    return [...this.dimensions()]
  }

  cloneDimensionValues(): ReturnType<typeof this.dimensionValues> {
    return [...this.dimensionValues().map((d) => [...d])]
  }
}
