import axiosLib from 'axios'

import { environment } from '@fintastic/web/data-access/environment'
import { axiosAuthRequestInterceptor } from '@fintastic/web/data-access/auth-axios-interceptor'

export const axios = axiosLib.create({
  baseURL: environment.REACT_APP_API_URL,
})

axios.interceptors.request.use(
  axiosAuthRequestInterceptor.onFulfilled,
  axiosAuthRequestInterceptor.onRejected,
)
