import { ThemeComponents } from './types'

export const MuiAlertTitle: ThemeComponents['MuiAlertTitle'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body1,
      marginTop: 0,
      marginBottom: theme.spacing(0.5),
    }),
  },
}
