import React from 'react'
import { HistoryLogEntryChangeColumnValue } from '@fintastic/web/util/history'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'
import { OldToNewValueTransition } from '../old-and-new-values'

export const ChangeColumnValueShortDetails: React.FC<{
  entry: HistoryLogEntryChangeColumnValue
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entry, dimensions }) => (
  <OldToNewValueTransition entry={entry} dimensions={dimensions} />
)
