import { Box, IconButton, IconButtonProps } from '@mui/material'
import React from 'react'
import { DeleteIcon } from './DeleteIcon'

export const DeleteChipButton: React.FC<IconButtonProps> = (
  iconButtonProps,
) => (
  <Box sx={{ paddingLeft: '6px' }}>
    <IconButton size="small" edge="end" {...iconButtonProps}>
      <DeleteIcon width={16} height={16} />
    </IconButton>
  </Box>
)
