import { useCallback, useMemo, useState } from 'react'

export function useMasterDetailsExpandState() {
  const [expandState, setExpandState] = useState<
    Record<string, boolean | undefined>
  >({})

  const markGridAsExpanded = useCallback((gridId: string) => {
    setExpandState((current) => ({ ...current, [gridId]: true }))
  }, [])

  const markGridAsCollapsed = useCallback((gridId: string) => {
    setExpandState((current) => ({ ...current, [gridId]: false }))
  }, [])

  const checkIsGridMarkedAsExpanded = useCallback(
    (gridId: string) => !!expandState[gridId],
    [expandState],
  )

  return useMemo(
    () => ({
      checkIsGridMarkedAsExpanded,
      markGridAsExpanded,
      markGridAsCollapsed,
    }),
    [checkIsGridMarkedAsExpanded, markGridAsCollapsed, markGridAsExpanded],
  )
}
