import React from "react"

/** @deprecated */
type IconProps = {
  width?: number | string
  height?: number | string
  color?: string
  className?: string
}

/** @deprecated */
export const LockIcon: React.FC<IconProps> = ({
  width = 24,
  height = 24,
  color = 'black',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 20 20"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6 9.3999H6.2C5.53726 9.3999 5 9.93716 5 10.5999V14.7999C5 15.4626 5.53726 15.9999 6.2 15.9999H14.6C15.2627 15.9999 15.8 15.4626 15.8 14.7999V10.5999C15.8 9.93716 15.2627 9.3999 14.6 9.3999Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.3999 9.4V7C7.3999 6.20435 7.71597 5.44129 8.27858 4.87868C8.84119 4.31607 9.60425 4 10.3999 4C11.1956 4 11.9586 4.31607 12.5212 4.87868C13.0838 5.44129 13.3999 6.20435 13.3999 7V9.4"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
