import { useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectAllGroups,
  selectSelectedGroup,
  loadPermissionGroupPolicyData,
} from '../ducks'

export const useGroupList = () => {
  const dispatch = useDispatch()

  const groups = useSelector(selectAllGroups)
  const currentGroup = useSelector(selectSelectedGroup)

  return useMemo(
    () => ({
      groups,
      currentGroup,
      onGroupChange: (nextGroupId: string) =>
        dispatch(loadPermissionGroupPolicyData(nextGroupId)),
    }),
    [currentGroup, dispatch, groups],
  )
}
