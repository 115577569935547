import { compact } from 'lodash'
import { PeriodSelection } from '../types'
import {
  isPeriodBasedSelection,
  isRangeBasedSelection,
} from './selection-guards'
import { Maybe } from '@fintastic/shared/util/types'

export const isEmptyPeriodSelection = (
  periodSelection: Maybe<PeriodSelection>,
): periodSelection is null => {
  if (
    !periodSelection?.aggregationDimensionId ||
    !periodSelection?.dimensionId
  ) {
    return true
  }

  if (
    !isRangeBasedSelection(periodSelection) &&
    !isPeriodBasedSelection(periodSelection)
  ) {
    return true
  }

  if (isRangeBasedSelection(periodSelection)) {
    return !periodSelection.range[0] || !periodSelection.range[1]
  }

  return compact(periodSelection.periods).length === 0
}
