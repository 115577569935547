import { CellValueChangedEvent } from 'ag-grid-community'
import { BaseGridOnCellValueChangedMetadata } from '../types'
import { Maybe } from '@fintastic/shared/util/types'

export const wrapCellValueChangedCallback =
  (
    callback?: CallableFunction,
    getMetadata?: (
      event: CellValueChangedEvent,
    ) => Maybe<BaseGridOnCellValueChangedMetadata>,
  ) =>
  (
    event: CellValueChangedEvent,
    metadata?: BaseGridOnCellValueChangedMetadata,
  ) => {
    if (!callback) {
      return
    }
    let newValue: unknown = event.newValue

    if (event.colDef.type === 'numericColumn' && typeof newValue === 'string') {
      newValue = Number(newValue)
    }

    if (newValue === event.oldValue) {
      return
    }

    callback({ ...event, newValue }, getMetadata?.(event))
  }
