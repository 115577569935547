import { Box, BoxProps, InputLabel, styled } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledFormFieldRoot = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacingFromPixels(24)};
`

export const StyledFormFieldLabelCont = styled(Box)`
  width: 100%;
`

export const StyledFormFieldLabel = styled(InputLabel)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'flex-start',

  minHeight: 40,

  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: 500,
  whiteSpace: 'pre-wrap',

  color: theme.palette.text.primary,
}))

export const StyledFormFieldCont = styled(
  (props: BoxProps & { fullWidth?: boolean }) => {
    const { fullWidth, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'fullWidth',
  },
)(({ theme, fullWidth }) => ({
  marginLeft: fullWidth ? 0 : theme.spacingFromPixels(24),
  width: fullWidth ? '100%' : 240,
  minWidth: fullWidth ? '100%' : 240,
}))

export const StyledFormField = styled(Box)`
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    font-weight: 500;
  }

  .MuiAutocomplete-root {
    .MuiTextField-root {
      .MuiAutocomplete-input {
        padding: 4px 10px;

        font-size: 1rem;
        font-weight: 500;
      }

      & .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
        //transform: translateY(-2px);

        button {
          background: none;
          border: none;

          &:hover {
            background: none;
            border: none;
            color: ${({ theme }) => theme.palette.primary.main};
          }
        }
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.divider};
  }

  .MuiOutlinedInput-root:not(.Mui-focused) {
    :hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.grey[400]};
    }
  }
`
