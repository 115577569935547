import { useWidgetSettingsDialogContext } from '@fintastic/web/feature/boards'
import React, { memo, useMemo } from 'react'
import { getChartGridModeName } from '@fintastic/web/feature/charts'

export const DialogSectionGridDisplaySubtitle: React.FC = memo(() => {
  const { data } = useWidgetSettingsDialogContext()

  const title = useMemo(
    () => getChartGridModeName(data.chartSettings?.gridMode),
    [data.chartSettings?.gridMode],
  )

  return <span>{title}</span>
})
