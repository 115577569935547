import React, { MouseEvent, useMemo } from 'react'
import { AgGridColumnHeaderProps } from '@fintastic/shared/ui/ag-grid'
import { Menu } from './features/menu/Menu'
import { Maybe } from '@fintastic/shared/util/types'
import { FilterIndicator } from './features/filters/FilterIndicator'
import { OrderType, useSortState } from './features/sort/useSortState'
import { Sort } from './features/sort/Sort'

export type AgGridColumnHeaderCustomizerRenderPropParam = {
  menu: Maybe<React.ReactNode>
  filtersIndicator: Maybe<React.ReactNode>
  sorting: Maybe<React.ReactNode>
  handleSortClick: (
    newOrder: Maybe<OrderType> | undefined,
    event: MouseEvent<HTMLElement>,
  ) => void
}
export type AgGridColumnHeaderCustomizerRenderProp = (
  params: AgGridColumnHeaderCustomizerRenderPropParam,
) => React.ReactNode

export type AgGridColumnHeaderCustomizerProps = AgGridColumnHeaderProps<{
  children: AgGridColumnHeaderCustomizerRenderProp
}>

// @todo add tests
export const AgGridColumnHeaderCustomizer: React.FC<
  AgGridColumnHeaderCustomizerProps
> = ({
  children,
  enableMenu,
  showColumnMenu,
  column,
  enableSorting,
  setSort,
  api,
  columnApi,
}) => {
  const isFilterActive = column.isFilterActive()
  const { order, handleSortButtonClick, sortIndex } = useSortState(
    !!enableSorting,
    column,
    setSort,
    api,
    columnApi,
  )

  const menu = useMemo(
    () => (enableMenu ? <Menu showColumnMenu={showColumnMenu} /> : null),
    [enableMenu, showColumnMenu],
  )

  const filtersIndicator = useMemo(
    () => (isFilterActive ? <FilterIndicator /> : null),
    [isFilterActive],
  )

  const sorting = useMemo(
    () => (enableSorting ? <Sort sortIndex={sortIndex} order={order} /> : null),
    [enableSorting, order, sortIndex],
  )

  return (
    <>
      {children({
        menu,
        filtersIndicator,
        sorting,
        handleSortClick: handleSortButtonClick,
      })}
    </>
  )
}
