export const layoutConfig = {
  topbar: {
    height: 64,
  },
  sidebar: {
    bar: {
      width: 80,
    },
    submenu: {
      minWidth: 240,
      maxWidth: 320,
    },
  },
  page: {
    paddingTop: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 24,
  },
  lateralPanel: {
    width: 420,
  },
  bottomdrawer: {
    minHeight: 48,
    height: 300,
    maxHeight: 700,
  },
} as const
