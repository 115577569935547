import { State } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { StartMetricEditingPayload } from '@fintastic/web/data-access/metrics-and-lists'
import cloneDeep from 'lodash/cloneDeep'

export const startMetricEditing = (
  state: State,
  { payload }: PayloadAction<StartMetricEditingPayload>,
) => {
  state.type = 'metric'
  state.flow = 'editing'
  state.list = null
  state.metric = cloneDeep(payload.metric)
}
