import React from 'react'
import { Toast } from 'react-hot-toast/headless'
import { StyledRoot } from './WarningToast.styled'
import { Typography } from '@mui/material'

export type WarningToastProps = {
  toast: Toast
  messageContent: React.ReactNode
}

export const WarningToast: React.FC<WarningToastProps> = ({
  toast,
  messageContent,
}) => (
  <StyledRoot
    severity="warning"
    icon={false}
    displayVariant={toast.className === 'neutral' ? 'neutral' : 'warning'}
  >
    <Typography variant="body1">{messageContent}</Typography>
  </StyledRoot>
)
