import React, { useCallback, useMemo, useState } from 'react'
import {
  CheckboxField,
  Divider,
  FormLayout,
  TextField,
  useTextFieldState,
} from '@fintastic/shared/ui/form-framework'
import { useBoardFolderNameValidation } from '../../../hooks/useBoardFolderNameValidation'
import { useBoardCreateMutation } from '../../../hooks/useBoardCreateMutation'
import { BoardConfig } from '../../../types'
import { useBoardAndFolderOpen } from '../../../hooks/useBoardAndFolderOpen'

export type BoardCreateFormProps = {
  closeParentModal?: () => void
}

export const BoardCreateForm: React.FC<BoardCreateFormProps> = (props) => {
  const { closeParentModal } = props

  const boardNameFieldState = useTextFieldState()
  const folderNameFieldState = useTextFieldState()
  const [isAddNewFolder, setIsAddNewFolder] = useState(false)

  const folderNameValidation = useBoardFolderNameValidation({
    folderName: folderNameFieldState.trimmedValue,
  })
  const isEveryFieldFilled =
    boardNameFieldState.value.length > 0 &&
    (isAddNewFolder ? folderNameFieldState.trimmedValue.length > 0 : true)
  const isSubmitEnabled =
    isEveryFieldFilled && (isAddNewFolder ? folderNameValidation.isValid : true)

  const boardCreateMutation = useBoardCreateMutation()
  const isSending = boardCreateMutation.isLoading
  const submitErrorMessage = useMemo(() => {
    if (!boardCreateMutation.error) {
      return null
    }
    console.error(boardCreateMutation.error)
    return "Can't create a new board"
  }, [boardCreateMutation.error])

  const openBoardAndFolder = useBoardAndFolderOpen()

  const handleSubmit = useCallback(() => {
    boardCreateMutation.mutate(
      {
        name: boardNameFieldState.value,
        config: {} as BoardConfig,
        newFolderName: isAddNewFolder
          ? folderNameFieldState.trimmedValue
          : undefined,
      },
      {
        onSuccess: (data) => {
          if (closeParentModal) {
            closeParentModal()
          }
          openBoardAndFolder(data.id, data.folder_id || undefined)
        },
      },
    )
  }, [
    boardCreateMutation,
    boardNameFieldState.value,
    closeParentModal,
    folderNameFieldState.trimmedValue,
    isAddNewFolder,
    openBoardAndFolder,
  ])

  return (
    <FormLayout
      submitEnabled={!isSending && isSubmitEnabled}
      showLoader={isSending}
      submitButtonText="Create New"
      submitErrorMessage={submitErrorMessage}
      onSubmit={handleSubmit}
    >
      <TextField
        label="Board"
        placeholder="Name"
        value={boardNameFieldState.value}
        onChange={boardNameFieldState.setValue}
      />
      <Divider />
      <CheckboxField
        label="Create in a new folder"
        checked={isAddNewFolder}
        onChange={setIsAddNewFolder}
      />
      {isAddNewFolder && (
        <TextField
          label="New folder name"
          placeholder="Folder name"
          value={folderNameFieldState.value}
          onChange={folderNameFieldState.setValue}
          error={!folderNameValidation.isValid}
          errorMessage={folderNameValidation.error}
        />
      )}
    </FormLayout>
  )
}
