import { CommentsThreadsBulkItem } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import {
  threadEventsQueryKey,
  threadsBulkByPageQueryKey,
} from '../utils/query-key'
import { deleteEvent } from '../api/api'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { compact } from 'lodash'

export const useDeleteEvent = (threadId: number, pageKey: string) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse,
    AxiosError,
    number,
    {
      previousThreadsBulk: CommentsThreadsBulkItem[]
    }
  >(
    threadEventsQueryKey(threadId),
    (event_id) => deleteEvent(threadId, event_id),
    {
      onMutate: async (event_id) => {
        await queryClient.cancelQueries(threadEventsQueryKey(threadId))

        const previousThreadsBulk =
          queryClient.getQueryData<CommentsThreadsBulkItem[]>(
            threadsBulkByPageQueryKey(pageKey),
          ) || []

        queryClient.setQueryData<CommentsThreadsBulkItem[]>(
          threadsBulkByPageQueryKey(pageKey),
          (prev) =>
            compact(
              prev?.map((thread) => {
                if (thread.id !== threadId) {
                  return thread
                }
                // If there was just a single comment: remove whole thread
                if (thread.comments.length === 1) {
                  return null
                }

                return {
                  ...thread,
                  comments: thread.comments.filter(
                    (comment) => comment.id !== event_id,
                  ),
                }
              }),
            ),
        )

        return { previousThreadsBulk }
      },
      onError: (err, v, ctx) => {
        // Reset optimistic update
        queryClient.setQueryData(
          threadsBulkByPageQueryKey(pageKey),
          ctx?.previousThreadsBulk || [],
        )

        console.log(err)
        toast.error(`Failed to delete event. Error: ${err?.code || 'unknown'}`)
      },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: threadsBulkByPageQueryKey(pageKey),
        })
      },
    },
  )

  return result
}
