import React from 'react'
import { PanelDragHandle } from '@fintastic/shared/ui/panel-framework'
import { FormulaButton } from '@fintastic/web/feature/formulas'
import { ModelExplorerVersionSelector } from '@fintastic/shared/ui/components'

export type MetricChartTopBarLeftContentProps = {
  draggable: boolean
  collapsible: boolean
  collapseButton?: React.ReactNode
  hasFormulas: boolean
  toggleFormulas: () => void
  formulasOpened: boolean
  metricId: string
  title?: string
  isDesignMode?: boolean
}

export const MetricChartTopBarLeftContent: React.FC<
  MetricChartTopBarLeftContentProps
> = ({
  draggable,
  collapsible,
  hasFormulas,
  collapseButton,
  toggleFormulas,
  formulasOpened,
  metricId,
  title,
  isDesignMode,
}) => {
  if (!draggable && !collapsible && !hasFormulas) {
    return null
  }

  return (
    <>
      {draggable && <PanelDragHandle />}

      {collapsible && collapseButton}

      {hasFormulas && (
        <FormulaButton
          icon={undefined}
          onClick={toggleFormulas}
          disabled={false}
          isActive={formulasOpened}
        />
      )}
      <ModelExplorerVersionSelector
        entityId={metricId}
        entityTitle={title}
        disabled={Boolean(isDesignMode)}
      />
    </>
  )
}
