import { styled, typographyClasses } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledIcon = styled('div')`
  ${styledMixins.alignContentCenter()};

  svg {
    width: 24px;
    height: auto;
  }
`

export const StyledLabel = styled('div')`
  padding: 4px 12px;
`

export const StyledList = styled('div')`
  padding-bottom: 3px;
`

export const StyledListItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;

  height: 24px;
`

export const StyledListIcon = styled('div')`
  ${styledMixins.alignContentCenter()};

  margin-right: 6px;

  width: 12px;
  min-width: 12px;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  svg {
    width: 12px;
    height: auto;

    animation: 1s infinite rotate linear;
  }
`

export const StyledListText = styled('div')`
  ${styledMixins.alignContentCenter()};

  .${typographyClasses.root} {
    overflow: hidden;

    max-width: ${320 - 8 * 2 - 20 - 4}px;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
