import { ImmutableADTWrapper } from '@fintastic/shared/util/abstract-data-types'
import * as s from './selectors'
import { SuccessfulDataUpdateEvent } from '../../types'

export class SuccessfulDataUpdateEventWrapper
  implements ImmutableADTWrapper<SuccessfulDataUpdateEvent>
{
  _rawData: SuccessfulDataUpdateEvent

  constructor(rawEvent: SuccessfulDataUpdateEvent) {
    this._rawData = rawEvent
  }

  unwrap(): SuccessfulDataUpdateEvent {
    return this._rawData
  }

  get successful(): true {
    return true
  }

  get userEmail(): string {
    return s.userEmail(this._rawData)
  }

  get versionId(): string {
    return s.versionId(this._rawData)
  }

  get updateData() {
    return s.update(this._rawData)
  }

  triggeredByUser(user: string): boolean {
    return this._rawData.user_email === user
  }
}
