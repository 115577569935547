import { CellData } from './data-values'
import type { ColInfo } from 'xlsx-js-style'
import { HeaderCell } from './header-utils'
import { isDateProps, isHeaderCell, isNumericProps } from './utils'

export function getColumnsWidth(dataTable: Array<HeaderCell[] | CellData[]>) {
  if (!dataTable || dataTable.length < 1) {
    return []
  }
  const wdths: ColInfo[] = []
  for (let colIdx = 0; colIdx < dataTable[0].length; colIdx++) {
    let maxWidth = 0
    for (let rowIdx = 0; rowIdx < dataTable.length; rowIdx++) {
      const cell = dataTable[rowIdx][colIdx]
      let cellText = (isHeaderCell(cell) ? cell.label : '' + cell.value) || ''
      if (cell.type === 'numericColumn' && cellText.length > 3) {
        cellText += ''.padEnd(Math.floor(cellText.length / 3), ' ') // because of 1000 separator
      }

      maxWidth = Math.max(maxWidth, cellText.length + 2) // because of "$ "
    }
    wdths.push({ wch: maxWidth })
  }
  return wdths
}

export function getDecimalFiller(
  cellData: CellData,
  char = '0',
  minLength = 1,
) {
  const decimalZeroes = isNumericProps(cellData.formattingParams)
    ? cellData.formattingParams?.displaySettings?.formatting?.decimal_places
    : undefined

  let placeholder

  if (typeof decimalZeroes === 'number') {
    placeholder = ''.padStart(decimalZeroes, char)
  } else {
    placeholder = ''.padStart(minLength, char) // default precision
  }
  return placeholder
}

export function getNumericFormat(cellData: CellData) {
  const pfiller = getDecimalFiller(cellData, '#')
  const filler = getDecimalFiller(cellData, '0')
  return `#,${pfiller}0.${filler};(#,${pfiller}0.${filler});0`
}

export function getDateTimeFormat(cellData: CellData) {
  return isDateProps(cellData.formattingParams)
    ? cellData.formattingParams.displayingFormat || 'yyyy-MM-dd'
    : 'yyyy-MM-dd'
}

export function getPercentageFormat(cellData: CellData) {
  const filler = getDecimalFiller(cellData, '0')
  return `0.${filler}%;(0.${filler}%);0%`
}

export function getCurrencyFormat(cellData: CellData) {
  const symbol = isNumericProps(cellData.formattingParams)
    ? cellData.formattingParams?.currency?.symbol || '$'
    : '$'

  const filler = getDecimalFiller(cellData, '0', 2)
  const pfiller = getDecimalFiller(cellData, '#', 2)

  let isBefore = true
  if (
    isNumericProps(cellData.formattingParams) &&
    cellData.formattingParams?.displaySettings?.currency_sign_position
  ) {
    isBefore =
      cellData.formattingParams?.displaySettings?.currency_sign_position ===
      'before'
  }

  if (isBefore) {
    return `${symbol} #,${pfiller}0.${filler};(${symbol} #,${pfiller}0.${filler});${symbol} 0`
  }
  return `#,${pfiller}0.${filler} ${symbol};(#,${pfiller}0.${filler} ${symbol});0 ${symbol}`
}
