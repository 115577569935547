import React, { useCallback } from 'react'
import { DimensionAccessGrid } from './DimensionAccessGrid'
import { useTableAccessList } from './hooks/useTableAccessList'
import Box from '@mui/system/Box/Box'
import { styled } from '@mui/material/styles'
import { DimensionMap } from './types'
import { useGlobalModel } from './hooks/useGlobalModel'
import { useTableChangeHandlers } from './hooks/useTableChangeHandlers'
import { useSaveChanges } from './hooks/useSaveChanges'
import { useSelector } from 'react-redux'
import { selectSaveState } from './ducks'
import { LinearProgress } from '@mui/material'

const GridBox = styled(Box)({
  height: '100%',
  position: 'relative',
})

export const DimensionAccessConfiguration: React.FC<{
  showRestrictionsOnly?: boolean
  autoSave?: boolean
}> = ({ autoSave }) => {
  const { dimensions: allDimensions, loading } = useGlobalModel()
  const { onDimensionAccessChange } = useTableChangeHandlers()
  const { defaultDimensionAccessConfig } = useTableAccessList()
  const saveChanges = useSaveChanges()
  const saving = useSelector(selectSaveState)

  const handleDefaultChange = useCallback(
    (newRestrictions: DimensionMap) => {
      onDimensionAccessChange(null, null, newRestrictions)
      if (autoSave) {
        saveChanges()
      }
    },
    [onDimensionAccessChange, saveChanges, autoSave],
  )

  return (
    <GridBox>
      {(saving || loading) && autoSave ? (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        />
      ) : (
        <div /> // Maintain DOM-elements number and order
      )}
      <DimensionAccessGrid
        dimensions={allDimensions}
        restrictions={defaultDimensionAccessConfig || []}
        onChange={handleDefaultChange}
      />
    </GridBox>
  )
}
