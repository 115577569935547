import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, FormControl } from '@mui/material'
import { AgGridCellEditorProps } from '../types'
import {
  AG_CELL_EDITOR_CLASS_NAME,
  AG_SELECT_BOX_CELL_EDITOR_CLASS_NAME,
} from '../classNames'
import { AutocompleteAsCellEditor } from '@fintastic/shared/ui/dropdowns'

export type AgGridSelectboxCellEditorOption = {
  label: string
  value: string
}

export type AgGridSelectboxCellEditorProps = {
  options: AgGridSelectboxCellEditorOption[]
  emptyText?: string
}

const isOptionEqualToValue = (
  option: AgGridSelectboxCellEditorOption,
  val: Partial<AgGridSelectboxCellEditorOption>,
) => option?.value === val?.value

export type AgGridSelectboxCellEditorAllProps = AgGridCellEditorProps &
  AgGridSelectboxCellEditorProps

// ATTENTION: do not change my styles here,
// change them in frontend/main-client/libs/shared/ui/ag-grid-theme-fintastic/src/lib/theme-variants/default.ts
export const AgGridSelectboxCellEditor = memo(
  forwardRef<unknown, AgGridSelectboxCellEditorAllProps>(
    ({ afterInput, ...props }, ref) => {
      const renderCount = useRef(0)
      renderCount.current += 1

      const { options, stopEditing, eGridCell } = props
      const [value, setValue] = useState<string>(props.value || '')
      const inputRef = React.useRef<HTMLInputElement>(null)
      const cellInnerWidth = eGridCell.clientWidth

      useEffect(() => {
        window.setTimeout(() => {
          inputRef.current?.focus()
        }, 0)
      }, [])

      useEffect(() => {
        if (renderCount.current > 1) {
          stopEditing(false)
        }
      }, [value, stopEditing])

      useImperativeHandle(ref, () => ({
        getValue() {
          return value
        },
        isCancelBeforeStart() {
          return false
        },
        isCancelAfterEnd() {
          return false
        },
      }))

      const selectedOption = useMemo<AgGridSelectboxCellEditorOption>(() => {
        const option = options.find((o) => isOptionEqualToValue(o, { value }))
        return (
          option || {
            value,
            label: props.emptyText || 'Not selected',
          }
        )
      }, [options, value, props.emptyText])

      return (
        <Box
          position="relative"
          className={
            AG_CELL_EDITOR_CLASS_NAME +
            ' ' +
            AG_SELECT_BOX_CELL_EDITOR_CLASS_NAME
          }
        >
          <FormControl fullWidth>
            <AutocompleteAsCellEditor
              value={selectedOption}
              options={options}
              onChange={setValue}
              inputRef={inputRef}
              minWidth={cellInnerWidth}
            />
          </FormControl>
          {afterInput}
        </Box>
      )
    },
  ),
)

export default AgGridSelectboxCellEditor
