import { QueriesResults } from 'react-query/types/react/useQueries'

export function getMemoKeyByBaseStateFlagsAndUpdatedDate<T extends any[]>(
  queriesResults: QueriesResults<T>,
): string {
  return queriesResults
    .reduce(
      (s, qr, i) =>
        (['isFetching', 'isLoading', 'isSuccess', 'isError'] as const).reduce(
          (s, flag) => `${s}${qr[flag]}=${qr[flag] ? '1' : '0'}`,
          s + `;${i}:`,
        ) + `${qr.dataUpdatedAt}`,
      '',
    )
    .toString()
}
