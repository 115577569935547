import {
  hideCompleteBlankRows,
  mergeRowsMaps,
  rowsMapToRowsList,
  VersionMetric,
} from '../../../../utils/metrics'
import { useEffect, useMemo, useRef } from 'react'
import { MetricGridRow } from '../../types'
import { allCalculated } from '../metric-source/utils'
import { useLoadMultipleVersionsEntities } from '@fintastic/web/data-access/versions'
import {
  createFieldKey,
  expandMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import { createWeightedAverageWeightFieldKey } from '../weighted-average/createWeightedAverageWeightFieldKey'
import { applyPartialUpdateTransaction } from '@fintastic/shared/util/ag-grid'
import { GridApi } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'

type AdditionalContext = {
  settingsEditingActive: boolean
  timeDimension?: string
  gridApi: Maybe<GridApi<MetricGridRow>>
  gridReady: boolean
}

export function useTableRows(
  data: VersionMetric[],
  weightedAverageData: VersionMetric[] | undefined,
  {
    settingsEditingActive,
    timeDimension,
    gridApi,
    gridReady,
  }: AdditionalContext,
) {
  const dataRef = useRef(data)
  dataRef.current = data
  const weightedAverageDataRef = useRef<VersionMetric[] | undefined>()
  const dataManualRef = useRef(data)

  const versionIds = useMemo(() => data.map((i) => i.versionId), [data])

  const entitiesQuery = useLoadMultipleVersionsEntities(versionIds, true)

  const reParseCounterRef = useRef(0)
  const reParseDataKey = useMemo(() => {
    if (isMetricDataEqual(dataManualRef.current, data)) {
      return reParseCounterRef.current
    }

    dataManualRef.current = data
    reParseCounterRef.current += 1
    return reParseCounterRef.current
  }, [data])

  useEffect(() => {
    if (!gridReady || !gridApi) {
      return
    }
    if (
      weightedAverageDataRef.current === undefined &&
      weightedAverageData === undefined
    ) {
      return
    }
    if (weightedAverageData === undefined) {
      weightedAverageDataRef.current = undefined
      return
    }
    if (
      weightedAverageDataRef.current !== undefined &&
      isMetricDataEqual(weightedAverageDataRef.current, weightedAverageData)
    ) {
      return
    }
    weightedAverageDataRef.current = weightedAverageData
    const rows =
      weightedAverageData.length > 0
        ? rowsMapToRowsList(
            expandMetricData(
              weightedAverageData,
              timeDimension,
              createWeightedAverageWeightFieldKey,
              entitiesQuery.data || [],
            ),
          )
        : []

    applyPartialUpdateTransaction(
      gridApi,
      !settingsEditingActive && allCalculated(weightedAverageData)
        ? hideCompleteBlankRows(rows)
        : rows,
      '_rowId',
    )
  }, [
    entitiesQuery.data,
    settingsEditingActive,
    timeDimension,
    weightedAverageData,
    gridReady,
    gridApi,
  ])

  return useMemo<MetricGridRow[]>(() => {
    if (!entitiesQuery.data?.length) {
      return []
    }

    const data = settingsEditingActive ? dataRef.current : dataManualRef.current
    const mainRowsMap =
      data.length > 0
        ? expandMetricData(
            data,
            timeDimension,
            createFieldKey,
            entitiesQuery.data || [],
          )
        : {}
    const weightRowsMap =
      weightedAverageData && weightedAverageData.length > 0
        ? expandMetricData(
            weightedAverageData,
            timeDimension,
            createWeightedAverageWeightFieldKey,
            entitiesQuery.data || [],
          )
        : null
    const rows = rowsMapToRowsList(
      weightRowsMap ? mergeRowsMaps(mainRowsMap, weightRowsMap) : mainRowsMap,
    )

    if (settingsEditingActive) {
      return rows
    }

    return allCalculated(dataRef.current) ? hideCompleteBlankRows(rows) : rows
    // we need to put a manual dependency key here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reParseDataKey, settingsEditingActive, timeDimension, entitiesQuery.data])
}

const isMetricDataEqual = (a: VersionMetric[], b: VersionMetric[]): boolean => {
  if (a === b) {
    return true
  }

  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < b.length; i += 1) {
    const el = b[i]
    if (
      // here we need to check only those fields that used for calculation
      a[i].metricData !== el.metricData ||
      a[i].metricId !== el.metricId ||
      a[i].versionId !== el.versionId
    ) {
      return false
    }
  }

  return true
}
