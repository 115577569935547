import { styled, Box, Popper } from '@mui/material'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledRoot = styled(Popper)(({ theme }) => ({
  paddingTop: 1,

  zIndex: theme.zIndex.tooltip,
}))

export const StyledContent = styled(Box)(({ theme }) => ({
  ...styledMixins.alignContentCenter(),
  justifyContent: 'space-between',
  padding: 4,

  maxWidth: 400,

  boxShadow:
    '0px 3px 3px -2px rgba(38, 54, 70, 0.16), 0px 3px 4px rgba(38, 54, 70, 0.1), 0px 1px 8px rgba(38, 54, 70, 0.08)',
  background: theme.palette.grey[100],
  borderRadius: 4,
}))

export const StyledText = styled(Box)(({ theme }) => ({
  padding: '0 12px',
}))

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  paddingLeft: 12,
}))
