import { GetContextMenuItemsParams } from 'ag-grid-community'
import {
  csvIcon,
  ExportFormat,
  exportGrid,
  xlsIcon,
} from '@fintastic/shared/util/ag-grid-export'
import { toast } from '@fintastic/shared/ui/toast-framework'

export const getContextMenuItemsByMaxRows = (
  params: GetContextMenuItemsParams,
  maxRows: number,
  fallbackGroupValueFormatter?: any,
  exportName?: string,
  sheetName?: string,
) => {
  const xlsExportDisabled = params.api.getDisplayedRowCount() > maxRows

  const exportFn = (format: ExportFormat) => {
    try {
      exportGrid({
        ...params,
        fallbackGroupValueFormatter,
        exportName,
        sheetName,
        format,
      })
    } catch (e) {
      toast.error(e?.toString() || 'Unknown error')
    }
  }
  return [
    'copy',
    'separator',
    {
      name: 'Download as MS Excel',
      icon: xlsIcon,
      disabled: xlsExportDisabled,
      action: () => exportFn('xls'),
    },
    {
      name: 'Download as CSV',
      icon: csvIcon,
      action: () => exportFn('csv'),
    },
  ]
}
