import { useQuery } from 'react-query'
import {
  UnrestrictedDimensionsResult,
  fetchUnrestrictedDimensions,
} from '../api'

export const useUnrestrictedDimensionsQuery = () =>
  useQuery<UnrestrictedDimensionsResult>(
    unrestrictedDimensionsQueryKey,
    fetchUnrestrictedDimensions,
  )

export const unrestrictedDimensionsQueryKey = [
  'settings',
  'unrestrictedDimensions',
]
