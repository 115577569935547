import React from 'react'
import { CircularProgress } from '@mui/material'
import { useImportConfigQuery } from '@fintastic/web/data-access/import-configs'

export type ImportModalTitleProps = {
  versionId?: string
  selectedItemsCount?: number
}

export const ImportModalTitle: React.FC<ImportModalTitleProps> = ({
  versionId,
  selectedItemsCount,
}) => {
  const query = useImportConfigQuery(versionId || '', { refetchOnMount: false })

  if (!versionId) {
    return null
  }

  if (query.error) {
    return <span>Error loading data</span>
  }

  if (!query.data) {
    return (
      <span>
        <CircularProgress size={16} sx={{ marginRight: 1 }} /> Please wait...
      </span>
    )
  }

  if (query.isLoading || query.isFetching) {
    return (
      <span>
        <CircularProgress size={16} sx={{ marginRight: 0.5 }} />
        Storing selection...
      </span>
    )
  }

  return query.data?.length ? (
    <span>
      <b>{selectedItemsCount}</b> of <b>{query.data.length}</b>
      Metrics/Lists with mapping selected
    </span>
  ) : (
    <span>
      <b>{selectedItemsCount}</b> Metrics/Lists with mapping selected
    </span>
  )
}
