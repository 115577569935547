import { useCallback } from 'react'
import { ChangeColumnValueDataTypeAction } from '../types'
import {
  MetricOrListEditingFlow,
  useListColumnsActions,
} from '@fintastic/web/data-access/metrics-and-lists'
import { DataContextValue } from '../../data/data-context'
import { Maybe } from '@fintastic/shared/util/types'
import {
  useModalState,
  useModalWithParamsState,
} from '@fintastic/shared/util/modal'
import {
  isDataTypeConversionNecessary,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'

export function useChangeColumnValueDataTypeFlow(
  { list, dependencyGraph, dimensionsList }: DataContextValue,
  flow: Maybe<MetricOrListEditingFlow>,
) {
  const { changeValueDataType } = useListColumnsActions()

  const confirmationPopupState = useModalWithParamsState<{
    dataType: Maybe<MetricConfigurableDataValueType>
    currency: Maybe<string>
    columnId: Maybe<string>
  }>({
    initialParams: {
      columnId: null,
      dataType: null,
      currency: null,
    },
  })

  const dependenciesWarningPopupState = useModalState()

  const handleAction = useCallback<ChangeColumnValueDataTypeAction>(
    (columnId, dataType, currency) => {
      if (!list || dimensionsList === null || flow === null) {
        return
      }

      const column = list.findColumnByClientId(columnId)
      if (!column) {
        return
      }

      if (!isDataTypeConversionNecessary(column.dataType(), dataType)) {
        changeValueDataType({
          columnId,
          dataType,
          currency,
          allDimensions: dimensionsList,
        })
        return
      }

      if (
        !column.isNewColumn() &&
        dependencyGraph &&
        dependencyGraph.hasAtLeastOneSuccessor([column.id(), list.id()])
      ) {
        dependenciesWarningPopupState.open()
        return
      }

      if (column.isNewColumn()) {
        changeValueDataType({
          dataType,
          currency,
          columnId,
          allDimensions: dimensionsList,
        })
        return
      }

      confirmationPopupState.openWithParams({
        dataType,
        currency,
        columnId,
      })
    },
    [
      changeValueDataType,
      confirmationPopupState,
      dependenciesWarningPopupState,
      dependencyGraph,
      dimensionsList,
      flow,
      list,
    ],
  )

  const handleConfirm = useCallback(() => {
    const { dataType, currency, columnId } = confirmationPopupState.params

    if (
      dataType !== null &&
      currency !== null &&
      columnId !== null &&
      dimensionsList !== null
    ) {
      changeValueDataType({
        dataType,
        currency,
        columnId,
        allDimensions: dimensionsList,
      })
    }
  }, [changeValueDataType, confirmationPopupState.params, dimensionsList])

  const popups = (
    <>
      <ConfirmationPopup
        title="Change Column’s data type?"
        open={confirmationPopupState.isOpen}
        onRequestClose={confirmationPopupState.close}
        onConfirm={handleConfirm}
      >
        <ConfirmationPopup.DeclineButton
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
        >
          Keep existing
        </ConfirmationPopup.DeclineButton>
        <ConfirmationPopup.ConfirmButton>
          Change data type
        </ConfirmationPopup.ConfirmButton>
      </ConfirmationPopup>
      <ConfirmationPopup
        title="Data type change is not possible due to dependencies."
        description="Please remove this Column from related formula and try again."
        open={dependenciesWarningPopupState.isOpen}
        onRequestClose={dependenciesWarningPopupState.close}
      >
        <ConfirmationPopup.DeclineButton>Close</ConfirmationPopup.DeclineButton>
      </ConfirmationPopup>
    </>
  )

  return {
    action: handleAction,
    popups,
  }
}
