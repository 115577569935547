import { Box, styled } from '@mui/material'

export const StyledBoardViewRoot = styled(Box)``

export const StyledBoardViewContent = styled(Box)`
  .board-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
  }
`
