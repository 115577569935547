import {
  ChartDisplaySettings,
  defaultChartXAxisAlignment,
  defaultChartXAxisFormat,
} from '@fintastic/web/feature/charts'

const compactXAxisFormatter = (text: string) =>
  (text || '').replaceAll(' ', '\n')

const standardXAxisFormatter = (text: string) => text

// @todo: find a way how to get correct option types from ECharts
export const processXAxis = (option: any, settings: ChartDisplaySettings) => {
  if (!option || !option.xAxis?.axisLabel) {
    return
  }

  const isSloping =
    settings?.xAxisAlignment === defaultChartXAxisAlignment ||
    !settings?.xAxisAlignment

  const xLabelsRotation = isSloping
    ? 30
    : settings?.xAxisAlignment === 'vertical'
    ? 90
    : 0

  const xAxisFormatter =
    settings?.xAxisFormat === defaultChartXAxisFormat || !settings?.xAxisFormat
      ? standardXAxisFormatter
      : compactXAxisFormatter

  if (!option.xAxis) {
    option.xAxis = {}
  }

  if (!option.xAxis.axisLabel) {
    option.xAxis.axisLabel = {}
  }

  option.xAxis.axisLabel = {
    ...option.xAxis.axisLabel,
    rotate: xLabelsRotation,
    formatter: xAxisFormatter,
    margin: isSloping ? 16 : 8,
  }
}
