import numeral from 'numeral'
import { repeat } from 'lodash'

export type NumericFormatter<
  P extends Record<string, unknown> | undefined = undefined,
> = (value: unknown, params?: P) => string

const number: NumericFormatter<{ short?: boolean; maxDecimals?: number }> = (
  v,
  { short, maxDecimals = 4 } = {},
) =>
  numeral(parseFloat(`${v}`).toFixed(maxDecimals)).format(
    short || maxDecimals === 0
      ? '(0,0)'
      : `(0,0[.][${repeat('0', maxDecimals)}])`,
  )

const currency: NumericFormatter<{ short?: boolean }> = (v, { short } = {}) => {
  const result = numeral(parseFloat(`${v}`).toFixed(4)).format(
    short ? '($0,0)' : '($0,0[.][0000])',
  )

  // @todo: Replace this fucker `numeral` with smth better
  // It gives 0$ for numbers like `-0.08999997936189175`
  if (result === '0$') {
    return '$0'
  }

  return result
}

const percentage: NumericFormatter<{ short?: boolean }> = (v, { short } = {}) =>
  numeral(v).format(short ? '(0,0[.][00]%)' : '(0,0[.][0000]%)')

export const formatNumeric = {
  number,
  currency,
  percentage,
} as const
