import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { UserPermissionsApi } from './types'
import { useEffect, useMemo } from 'react'

export function useUserPermissions(
  discardChanges: () => void,
): UserPermissionsApi {
  const editingAllowed = Boolean(useRoleLevelAccess(['power_user', 'modeler']))

  useEffect(() => {
    if (!editingAllowed) {
      discardChanges()
    }
  }, [discardChanges, editingAllowed])

  return useMemo(
    () => ({
      editingAllowed,
      editingBlockedTooltip: {
        priority: 1,
        text: 'You don’t have editing permissions',
      },
    }),
    [editingAllowed],
  )
}
