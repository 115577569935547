import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { ChartDebugDataTable } from '../../debug/ChartDebugDataTable/ChartDebugDataTable'
import { ChartDebugParams } from '../../debug/ChartDebugParams/ChartDebugParams'
import { Chart } from '../../../../chart/Chart'
import {
  ChartDebugDisplayMode,
  ChartRequestParams,
  ChartSettings,
  MergedMetricsData,
} from '../../../../../types'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { CalendarIcon, EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'
import {
  StyledChartContent,
  StyledChartError,
  StyledChartPanel,
} from './MetricChartPanels.styled'
import {
  doNotSupportRequestedGranularityMessage,
  usePeriodSelectorResolution,
} from '@fintastic/web/util/period-selector'

type MetricChartPanelsProps = {
  mergedMetricsData: Maybe<MergedMetricsData>
  displayMode: ChartDebugDisplayMode
  loading: boolean
  isDesignMode?: boolean
  chartSettings: ChartSettings
  request: ChartRequestParams
  hasResolutionError?: boolean
}

// @todo: get rid of panels after charts stabilized
export const MetricChartPanels: React.FC<MetricChartPanelsProps> = ({
  mergedMetricsData,
  displayMode,
  loading,
  isDesignMode,
  chartSettings,
  request,
  hasResolutionError,
}) => {
  const shouldShowNoData =
    !loading && (!mergedMetricsData || mergedMetricsData.data.length === 0)

  const periodResolution = usePeriodSelectorResolution()

  if (shouldShowNoData) {
    if (hasResolutionError) {
      const title = doNotSupportRequestedGranularityMessage(
        'metric_chart',
        periodResolution,
      )
      return (
        <StyledChartError>
          <ErrorAlert
            title={title}
            message={'Try selecting another time granularity'}
            severity={'info'}
            icon={<CalendarIcon />}
          />
        </StyledChartError>
      )
    }
    return (
      <StyledChartError>
        <ErrorAlert
          title={"Chart doesn't exist in the selected version"}
          message={'Try selecting another version'}
          severity={'info'}
          icon={<EmptyFolderErrorIcon />}
        />
      </StyledChartError>
    )
  }

  return (
    <StyledChartContent data-testid="chart-content">
      <StyledChartPanel>
        {displayMode === 'chart' && (
          <Chart
            isDesignMode={isDesignMode}
            loading={loading}
            displaySettings={chartSettings}
            mergedMetricsData={mergedMetricsData}
          />
        )}

        {displayMode === 'table' && (
          <ChartDebugDataTable mergedMetricsData={mergedMetricsData} />
        )}

        {displayMode === 'debug' && (
          <ChartDebugParams
            chartSettings={chartSettings}
            request={request}
            mergedMetricsData={mergedMetricsData}
          />
        )}
      </StyledChartPanel>
    </StyledChartContent>
  )
}
