import {
  EditFormulaIntent,
  EditFormulaIntentPayload,
  CalculateIntent,
} from './types'

export type CreateIntent<T extends CalculateIntent, P extends T['payload']> = (
  payload: P,
) => T

export const editFormulaIntent: CreateIntent<
  EditFormulaIntent,
  EditFormulaIntentPayload
> = (payload) => ({
  action: 'edit:formula',
  payload,
})
