import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { useCallback, useEffect, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export const useQuickSearch = (api: Maybe<GridApi>) => {
  const [quickFilterText, setQuickFilterText] = useState('')

  useEffect(() => api?.setQuickFilter(quickFilterText), [api, quickFilterText])

  const handleQuickFilterTextChange = useCallback(
    (newValue: string) => setQuickFilterText(newValue),
    [],
  )

  return {
    quickFilterText,
    handleQuickFilterTextChange,
  }
}
