import { useQuery } from 'react-query'
import { getLists } from '../api/lists-api'
import { metricsAndListsCacheKeys } from '../cache'

export function useLoadListOfLists(versionId: string, showHidden = false) {
  return useQuery(
    metricsAndListsCacheKeys.lists(versionId, showHidden),
    async () => {
      const response = await getLists(versionId, showHidden)
      return response.data.result
    },
  )
}
