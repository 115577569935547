import React from 'react'
import {
  MetricSettingsView,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'

// @todo RESP connect
export const MetricSettingsViewConnector: React.FC = () => {
  const { goBack } = useReportSettingsPanelNavigationContext()

  return (
    <MetricSettingsView.Readonly
      goBack={goBack}
      metric={{
        id: 'Table.Headcount.Something',
        label: 'Something',
        isColumn: true,
        reportingGroup: {
          id: '1',
          label: 'Reporting Group 1',
        },
        aggregation: 'sum',
        timeAggregation: 'sum',
        requiresDateColumn: true,
        dateColumn: {
          id: 'Table.Headcount.Start_Date',
          label: 'Start Date',
        },
      }}
    />
  )
}
