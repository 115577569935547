export const fintasticTheme = {
  color: [
    '#ff7933',
    '#ffcc66',
    '#66b3ff',
    '#89daa5',
    'rgba(254,76,76,0.74)',
    '#b3b3b3',
    'rgba(200,118,118,0.63)',
    'rgba(51,51,51,0.77)',
  ],
}
