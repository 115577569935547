import React from 'react'
import {
  LocalFiltersAndOrderingParamsState,
  ControlButtons,
  DateRange,
  EntityType,
  OrderByCreatedAt,
  ActionType,
} from '../filters-and-ordering'
import {
  StyledDateControls,
  StyledFiltersAndOrderingControls,
  StyledRoot,
} from './FiltersAndOrdering.styled'
import { iso8601 } from '@fintastic/shared/util/date'

export const FiltersAndOrdering: React.FC<{
  localParamsStateApi: LocalFiltersAndOrderingParamsState
  resetToDefaults: () => void
  apply: () => void
  isBusy: boolean
}> = ({ localParamsStateApi, resetToDefaults, apply, isBusy }) => {
  const {
    localParams,
    localDiffersFromCurrent,
    currentDiffersFromDefault,
    changeDateFrom,
    changeDateTo,
    changeOrdering,
    resetToCurrent,
    changeEntityType,
    changeActionType,
  } = localParamsStateApi

  return (
    <StyledRoot>
      <StyledFiltersAndOrderingControls>
        <ActionType
          disabled={isBusy}
          actionTypes={localParams.filter.action}
          onChangeActionType={changeActionType}
        />

        <StyledDateControls>
          <DateRange
            disabled={isBusy}
            fromDate={iso8601.toDate(localParams.filter.min_date!)}
            onChangeFromDate={changeDateFrom}
            toDate={iso8601.toDate(localParams.filter.max_date!)}
            onChangeToDate={changeDateTo}
          />
        </StyledDateControls>

        <EntityType
          disabled={isBusy}
          entityTypes={localParams.filter.entity_type}
          onChangeType={changeEntityType}
        />

        <OrderByCreatedAt
          disabled={isBusy}
          desc={localParams.desc}
          onChangeOrdering={changeOrdering}
        />
      </StyledFiltersAndOrderingControls>

      <ControlButtons
        disabled={isBusy}
        isChanged={localDiffersFromCurrent}
        isDefaultChanged={currentDiffersFromDefault}
        onCancel={resetToCurrent}
        onClear={resetToDefaults}
        onApply={apply}
      />
    </StyledRoot>
  )
}
