import { styled } from '@mui/material/styles'

export type EntityTypeChipVariant = 'list' | 'metric' | 'other'

const chipColors: Record<
  EntityTypeChipVariant,
  {
    background: string
    color: string
  }
> = {
  list: {
    color: '#147a03',
    background: '#faf7f5',
  },
  metric: {
    color: '#135EF5',
    background: '#faf7f5',
  },
  other: {
    color: 'rgba(112, 66, 194, 0.6)',
    background: '#faf7f5',
  },
}

export const EntityTypeChip = styled('span')<{
  variant: EntityTypeChipVariant
}>(
  ({ variant }) => `
    padding: 4px 8px;
    border-radius: 4px;
    background: ${chipColors[variant].background};
    color: ${chipColors[variant].color};
    display: inline-block;
    line-height: 1rem;
  `,
)
