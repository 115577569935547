import { ThemeComponents } from './types'
import { styleKit } from '../styleKit'

export const MuiMenuItem: ThemeComponents['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      'paddingTop': 10.5,
      'paddingBottom': 10.5,
      'paddingLeft': theme.spacing(3),
      'paddingRight': theme.spacing(3),

      '&:hover, &.Mui-selected:hover': {
        background: styleKit.colors.orange.light,
      },

      '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
        background: styleKit.colors.orange.light2,
      },

      '&.Mui-selected': {
        color: styleKit.colors.orange.dark2,
        background: 'transparent',
        fontWeight: 900,
      },
    }),
  },
}
