import React, { forwardRef } from 'react'
import { FunctionFXIcon, FunctionAXIcon } from '@fintastic/shared/ui/icons'
import { IconButton, IconButtonProps } from '@mui/material'

export type FormulaIcon = 'fx' | 'ax'

export type FormulaButtonProps = Omit<IconButtonProps, 'children' | 'color'> & {
  isActive?: boolean
  iconOnly?: boolean
  icon?: FormulaIcon
}

const iconsMap = {
  fx: FunctionFXIcon,
  ax: FunctionAXIcon,
}

// @todo add tests
export const FormulaButton = forwardRef<HTMLButtonElement, FormulaButtonProps>(
  ({ icon = 'fx', isActive, iconOnly, ...rest }, ref) => {
    const Icon = iconsMap[icon]
    return iconOnly ? (
      <Icon />
    ) : (
      <IconButton color={isActive ? 'primary' : 'default'} {...rest} ref={ref}>
        <Icon />
      </IconButton>
    )
  },
)
