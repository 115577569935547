import { VersionDependencyGraph } from '../types'
import cytoscape from 'cytoscape'

export const cytoscapeFromGraph = (
  graph: VersionDependencyGraph,
): cytoscape.Core =>
  cytoscape({
    headless: true,
    elements: graph.elements,
  })
