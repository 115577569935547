import { Box, styled } from '@mui/material'
import { layoutConfig } from '../../layout-config'

export const StyledLateralRoot = styled(Box)(({ theme }) => ({
  position: 'relative',

  height: '100%',

  transition: `padding-left ${theme.transitions.duration.standard}ms`,
}))

export const StyledLateralContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,

  width: layoutConfig.lateralPanel.width,

  background: theme.palette.common.white,
  border: '1px solid transparent',
  borderLeftColor: theme.palette.divider,
  transition: `transform ${theme.transitions.duration.standard}ms`,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))
