import {
  wrapEvent,
  SuccessfulDataUpdateEventWrapper,
  FailedDataUpdateEventWrapper,
  DataUpdateEvent,
} from '@fintastic/web/util/metric-data-editing'
import {
  PUSHER_EVENT_CELL_DATA_UPDATE,
  useSubscribeToTenantEvent,
} from '@fintastic/web/data-access/service-pusher'
import { useCallback } from 'react'

export const useSubscribeOnCellDataUpdateEvent = (
  versionIds: string[] | '*',
  cb: (
    params: SuccessfulDataUpdateEventWrapper | FailedDataUpdateEventWrapper,
  ) => void,
) => {
  useSubscribeToTenantEvent<DataUpdateEvent>(
    PUSHER_EVENT_CELL_DATA_UPDATE,
    useCallback(
      (event: DataUpdateEvent) => {
        const wrappedEvent = wrapEvent(event)
        if (versionIds === '*' || versionIds.includes(wrappedEvent.versionId)) {
          cb(wrappedEvent)
        }
      },
      [cb, versionIds],
    ),
    `${PUSHER_EVENT_CELL_DATA_UPDATE} ${versionIds}`,
  )
}
