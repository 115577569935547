import { styled, Box, FormControlLabel, Checkbox } from '@mui/material'

export const StyledCheckboxFieldRoot = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacingFromPixels(24),
}))

export const StyledCheckboxFieldLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    'width': '100%',

    'marginRight': 0,

    '.MuiFormControlLabel-label': {
      fontSize: '1rem',
    },
  }),
)

export const StyledCheckboxFieldCheckbox = styled(Checkbox)(({ theme }) => ({
  marginTop: -8,
  marginBottom: -8,
}))
