import { styled, Box } from '@mui/material'
import { layoutConfig } from '../../layout-config'

export const StyledSidebarRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  transition: `padding ${theme.transitions.duration.standard}ms`,
  zIndex: 5,
}))

export const StyledSidebarBarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',

  width: layoutConfig.sidebar.bar.width,
  height: '100%',

  background: theme.palette.common.white,
  borderRight: `1px solid ${theme.palette.divider}`,
}))

export const StyledSidebarSubMenuContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: layoutConfig.sidebar.bar.width,

  transition: `transform ${theme.transitions.duration.standard}ms`,
}))

export const StyledSidebarSubMenuContent = styled(Box)(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',

  minWidth: layoutConfig.sidebar.submenu.minWidth,
  maxWidth: layoutConfig.sidebar.submenu.maxWidth,
  height: '100%',

  background: theme.palette.common.white,
  border: '1px solid transparent',
  borderRightColor: theme.palette.divider,
}))
