import {
  getAllowedRollUps,
  Metric,
  MetricData,
  MetricDataValueType,
  MetricMetadata,
  MetricOrListSource,
} from '@fintastic/web/util/metrics-and-lists'
import { MetricDisplaySettingsWrapper } from '../display-settings'
import {
  DimensionWithoutLabel,
  Maybe,
  RollUpFunction,
} from '@fintastic/shared/util/types'
import { MetricDataWrapper } from '../metric-data'
import {
  DimensionId,
  DimensionType,
  TimeDimensionId,
  TimeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { product } from '@fintastic/shared/util/numeric-array'

export const id = (m: Pick<Metric, 'id'>): string => m.id

export const label = (m: Pick<Metric, 'label'>): string => m.label

export const source = (m: Pick<Metric, 'source'>): MetricOrListSource =>
  m.source

export const data = (m: Pick<Metric, 'data'>): MetricDataWrapper =>
  new MetricDataWrapper(m.data)

export const dataType = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): MetricDataValueType => m.metadata.value.type

/**
 * This field is filled only when dataType === "dimension".
 * Usually it happens only in list columns
 */
export const valuesDimensionId = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): Maybe<DimensionId> => m.metadata.value.dimension_id

/**
 * The unique value that we use to identify the masked cell
 */
export const maskedValueMask = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): string | number => m.metadata.value.mask

export const categoryAggregation = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): RollUpFunction => m.metadata.value.category_agg

export const timeAggregation = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): RollUpFunction => m.metadata.value.roll_up_function

export const weightsMetricId = (m: {
  metadata: Pick<MetricMetadata, 'value'>
}): Maybe<string> => m.metadata.value.weights_metric_id

export const description = (m: {
  metadata: Pick<MetricMetadata, 'description'>
}): string => m.metadata.description

/**
 * Filled only in calculated metrics
 */
export const formula = (m: {
  metadata: Pick<MetricMetadata, 'formula'>
}): Maybe<string> => m.metadata.formula

export const displaySettings = (m: {
  metadata: Pick<MetricMetadata, 'display_config'>
}): MetricDisplaySettingsWrapper =>
  new MetricDisplaySettingsWrapper(m.metadata.display_config)

export const dimensionsMetadata = (m: {
  metadata: Pick<MetricMetadata, 'dimensions'>
}): DimensionWithoutLabel[] => m.metadata.dimensions

export const getLocalRangeDimensions = (
  m: {
    metadata: Pick<MetricMetadata, 'dimensions'>
  },
  rowDimId?: string,
): DimensionWithoutLabel[] =>
  dimensionsMetadata(m).filter((d) => d.id !== rowDimId && d.type === 'Range')

/**
 * Checks if rollup function can be applied to metric with specific data type.
 * If not - returns the default allowed function
 */
export const getAllowedRollUp = (
  m: {
    metadata: Pick<MetricMetadata, 'value'>
  },
  rollUp: RollUpFunction,
  dimensionType: Exclude<DimensionType, 'Range'>,
): RollUpFunction => {
  const allowedRollUps = getAllowedRollUps(dataType(m), dimensionType)
  return allowedRollUps.includes(rollUp) ? rollUp : allowedRollUps[0]
}

export const timeDimension = (m: {
  metadata: Pick<MetricMetadata, 'time_dimension_id'>
}): Maybe<string> => m.metadata.time_dimension_id || null

export const baseTimeDimension = (m: {
  metadata: Pick<MetricMetadata, 'base_time_dimension_id'>
}): Maybe<TimeDimensionId> => m.metadata.base_time_dimension_id || null

export const periodsList = (m: {
  data: MetricData
  metadata: Pick<MetricMetadata, 'time_dimension_id'>
}): Maybe<TimeDimensionValueId[]> => {
  if (!timeDimension(m)) {
    return null
  }
  const dimension = data(m).findDimension((dimId) => dimId === timeDimension(m))
  if (!dimension) {
    return null
  }
  return [...dimension.valueIds]
}

export const size = ({
  metadata: { shape },
}: {
  metadata: Pick<MetricMetadata, 'shape'>
}): Maybe<number> => (shape ? product(shape) : null)
