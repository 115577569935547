import type {
  GenericReportDiff,
  GenericReportId,
} from '@fintastic/web/util/generic-report'

export const getDiffLabel = (
  reportNames: Record<GenericReportId, string> | undefined,
  diffSelection: GenericReportDiff,
): string => {
  const diff1 = reportNames ? reportNames[diffSelection[0]] : undefined
  const diff2 = reportNames ? reportNames[diffSelection[1]] : undefined
  if (!diff1 || !diff2) {
    return ''
  }

  return `${diff1} vs. ${diff2}`
}
