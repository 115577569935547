import React from 'react'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { useDataContext } from '../connection/data/data-context'
import { useActionsContext } from '../connection/actions/actions-context'
import { ColumnValueDataFormatSettings } from '../settings/column-value-data-format/ColumnValueDataFormatSettings'
import { useColumnByUrlParam } from './hooks/useColumnByUrlParam'

export const ColumnValueDataFormat: React.FC = () => {
  const column = useColumnByUrlParam()
  const { currenciesList, list } = useDataContext()
  const { actions, readonly, flow } = useActionsContext()

  return (
    <ViewLayout title={'Value data type'}>
      {column && currenciesList !== null && (
        <ColumnValueDataFormatSettings
          column={column}
          readonly={list?.source() === 'calculated' || !!readonly}
          currenciesList={currenciesList}
          onChangeDataType={actions.changeColumnValueDataType}
          onChangeFormatting={actions.changeColumnValueDataFormatting}
          onChangeCurrencySignPosition={
            actions.changeColumnCurrencySignPosition
          }
          editingFlow={flow}
        />
      )}
    </ViewLayout>
  )
}
