import {
  useBoardDefaultVersions,
  useBoardsParamsVersions,
} from '@fintastic/web/feature/boards'
import { useVersionEntitiesContextValue } from '@fintastic/web/data-access/versions'
import { useMemo } from 'react'
import { idLooksLikeReport } from '@fintastic/web/util/generic-report'

export type BoardVersionsSelectOptions = {
  value: string
  label: string
  isLocked: boolean
  disabled: boolean
  isCurrent: boolean
}

export const useBoardVersionsForEntity = (
  entityId: string,
  currentVersion?: string,
  disabledVersionsDueToReports?: string[],
) => {
  const { defaultVersions, query } = useBoardDefaultVersions()

  // versions - raw list of available version Ids
  // versions request is synchronous!
  const { versions } = useBoardsParamsVersions(defaultVersions)

  const { entities, isFetching, isLoading } =
    useVersionEntitiesContextValue(versions)

  const versionsLoading = query.isFetching || query.isLoading
  const entitiesLoading = isFetching || isLoading

  // options - list of options for dropdown, with name, locked, disabled status
  const versionOptions = useMemo<BoardVersionsSelectOptions[]>(() => {
    if (entitiesLoading || !versions.length || versionsLoading) {
      return []
    }

    return versions?.map((versionId) => {
      const dbVersion = (query?.data || []).find(
        (record) => record.uuid === versionId,
      )

      const isExists = idLooksLikeReport(entityId)
        ? !disabledVersionsDueToReports?.includes(versionId)
        : entities[versionId]?.findListById(entityId) ||
          entities[versionId]?.findMetricById(entityId)

      return {
        value: versionId,
        label: dbVersion?.name || 'Unknown version name',
        isLocked: !!dbVersion?.locked_at || false,
        disabled: !isExists,
        isCurrent: versionId === currentVersion,
      }
    })
  }, [
    entitiesLoading,
    versions,
    versionsLoading,
    query?.data,
    entityId,
    disabledVersionsDueToReports,
    entities,
    currentVersion,
  ])

  return useMemo(
    () => ({
      options: versionOptions,
      isLoading: versionsLoading || entitiesLoading,
    }),
    [entitiesLoading, versionOptions, versionsLoading],
  )
}
