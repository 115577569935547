import React from 'react'
import {
  StyledWidgetDependenciesWarningRoot,
  StyledWidgetDependenciesWarningIcon,
  StyledWidgetDependenciesWarningText,
} from './WidgetDependenciesWarning.styled'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Typography } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export const WidgetDependenciesWarning: React.FC<Props> = (props) => {
  const { children } = props

  return (
    <StyledWidgetDependenciesWarningRoot>
      <StyledWidgetDependenciesWarningIcon>
        <WarningAmberOutlinedIcon fontSize="medium" color="primary" />
      </StyledWidgetDependenciesWarningIcon>
      <StyledWidgetDependenciesWarningText>
        {typeof children === 'string' ? (
          <Typography variant="body2">{children}</Typography>
        ) : (
          children
        )}
      </StyledWidgetDependenciesWarningText>
    </StyledWidgetDependenciesWarningRoot>
  )
}
