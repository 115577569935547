// @todo add tests

import type { Selector } from 'reselect'
import {
  RootState,
  MetricOrListEditingState as State,
  namespace,
} from './state'
import { ColumnFormulaError, FlowState } from './types'
import type { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'
import { createSelector } from 'reselect'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricWrapper } from '../abstract-data-types/metric'

export const selectAll: Selector<RootState, State> = (s) => s[namespace]

const _selectMetric = createSelector(selectAll, (s) => s.metric)
export const selectMetric = createSelector(_selectMetric, (frozenMetric) =>
  structuredClone(frozenMetric),
)

const _selectList = createSelector(selectAll, (s) => s.list)
export const selectList = createSelector(_selectList, (frozenList) =>
  structuredClone(frozenList),
)

export const selectIsListValid = createSelector(selectList, (list) => {
  if (!list) {
    return false
  }

  if (list.source === 'input') {
    const calculatedColumnsWithInvalidFormula = list.metrics.filter(
      (m) => m.formulaInvalid,
    )
    if (calculatedColumnsWithInvalidFormula.length > 0) {
      return false
    }
  }

  if (list.source === 'calculated') {
    return !list.formulaInvalid
  }

  return list.metrics.length > 0
})

export const selectIsMetricValid = createSelector(selectMetric, (metric) => {
  if (!metric) {
    return false
  }

  if (metric.source === 'calculated') {
    return !metric.formulaInvalid
  }

  return !new MetricWrapper(metric).data().dimensionsAreEmpty()
})

export const selectMetricFormulaError = createSelector(
  selectMetric,
  (metric) => {
    if (!metric) {
      return false
    }

    if (metric.source === 'calculated') {
      return metric.formulaCalculationError || null
    }

    return null
  },
)

export const selectListFormulaError = createSelector(selectList, (list) => {
  if (!list) {
    return false
  }

  if (list.source === 'calculated') {
    return list.formulaCalculationError || null
  }

  return null
})

export const selectListColumnsFormulaErrors: Selector<
  RootState,
  Maybe<ColumnFormulaError[]>
> = createSelector(selectList, (list) => {
  if (!list || list.source === 'calculated') {
    return null
  }

  return list.metrics
    .filter((c) => c.formulaCalculationError !== undefined)
    .map((c) => ({
      id: c.id,
      error: c.formulaCalculationError as string,
    }))
})

export const selectCurrentFlow: Selector<RootState, FlowState> = createSelector(
  selectAll,
  selectIsListValid,
  selectIsMetricValid,
  selectListFormulaError,
  selectMetricFormulaError,
  selectListColumnsFormulaErrors,
  (
    state,
    isValidList,
    isValidMetric,
    listFormulaError,
    metricFormulaError,
    listColumnsFormulaErrors,
  ) => {
    const label = (
      state.type === 'list' ? state.list?.label : state.metric?.label
    ) as string
    const source = (
      state.type === 'list' ? state.list?.source : state.metric?.source
    ) as MetricOrListSource
    const isValid = state.type === 'list' ? isValidList : isValidMetric

    return {
      id: (state.metric ? state.metric.id : state.list?.id) || null,
      label,
      source,
      flow: state.flow,
      type: state.type,
      isValid,
      formulaError: metricFormulaError || listFormulaError || null,
      columnsFormulaErrors: listColumnsFormulaErrors,
    }
  },
)
