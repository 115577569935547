import React from 'react'
import { FormulaEditor, Version } from '@fintastic/web/feature/formulas'
import { ListGridSettingsPanelProps } from '../../components/list-grid/types'
import { Maybe } from '@fintastic/shared/util/types'

export type ListGridFormulaEditorWrapperProps = {
  versions: Version[]
  onRequestClose: () => void
  formulaEditorTitle?: string
  controlledMode?: boolean
  onValidationFinished?: ListGridSettingsPanelProps['onFormulaValidationFinished']
  readonly?: boolean
  controlledFormulaError: Maybe<string>
}

// @todo add tests
export const ListGridFormulaEditorWrapper: React.FC<
  ListGridFormulaEditorWrapperProps
> = ({
  versions,
  onRequestClose,
  formulaEditorTitle,
  controlledMode,
  onValidationFinished,
  readonly,
  controlledFormulaError,
}) => (
  <FormulaEditor
    title={formulaEditorTitle || 'Forecast formula'}
    versions={versions}
    showVersionsSelector={false}
    readonly={readonly}
    onRequestClose={onRequestClose}
    controlledEditingMode={controlledMode}
    onValidationFinished={onValidationFinished}
    controlledFormulaError={controlledFormulaError}
  />
)
