import React from 'react'
import { Box, Pagination as MuiPagination, Typography } from '@mui/material'
import { formatNumeric } from '@fintastic/shared/util/formatters'

export type PaginationProps = {
  offset: number
  totalEntries: number
  entriesPerPage: number
  goToPage: (page: number) => void
  isBusy: boolean
  boundaryCount?: number
  siblingCount?: number
}

export const Pagination: React.FC<PaginationProps> = ({
  offset,
  goToPage,
  entriesPerPage,
  totalEntries,
  isBusy,
  boundaryCount,
  siblingCount,
}) => {
  const totalPages = Math.ceil(totalEntries / entriesPerPage)
  const page = Math.ceil(offset / entriesPerPage)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      minHeight={32}
      width="100%"
    >
      <Typography variant="subtitle2" sx={{ mr: 'auto' }}>
        {totalEntries > 0 ? (
          <span>
            {`${offset + 1} - ${Math.min(
              totalEntries,
              offset + entriesPerPage,
            )} of ${formatNumeric.number(totalEntries)}`}
          </span>
        ) : (
          <span>&nbsp;</span>
        )}
      </Typography>

      {totalPages > 1 && (
        <MuiPagination
          page={page + 1}
          count={totalPages}
          onChange={(_, page) => goToPage(page - 1)}
          shape="rounded"
          color="primary"
          disabled={isBusy}
          siblingCount={siblingCount}
          boundaryCount={boundaryCount}
        />
      )}
    </Box>
  )
}
