import { Metric } from '@fintastic/web/util/metrics-and-lists'
import {
  Dimension,
  DimensionLabelMap,
  DimensionWithOptionalValues,
  Maybe,
} from '@fintastic/shared/util/types'
import keyBy from 'lodash/keyBy'

type MetricLike = {
  data: {
    indexes: Metric['data']['indexes']
  }
  metadata: {
    dimensions: Metric['metadata']['dimensions']
    value: {
      dimension_id: Metric['metadata']['value']['dimension_id']
    }
  }
}

export function extractDimensionsFromMetrics(
  metrics: MetricLike[],
  globalDimensions: DimensionWithOptionalValues[],
  rowDimension?: string,
): DimensionLabelMap {
  const dimsMap = keyBy(globalDimensions, 'id')
  const allUsedDimensionsIds = getAllUsedDimsIds(metrics, rowDimension)

  const result: DimensionLabelMap = {}

  // @todo remove dimensions metadata usage
  allUsedDimensionsIds.forEach((dimId) => {
    // if metric has its own time dimension, use it instead of using entities
    // we need it because in metric it's aligned with used time segmentation
    if (dimsMap[dimId]?.type === 'Time' || dimsMap[dimId]?.type === 'Range') {
      let timeDimFromMetric: Maybe<Dimension> = null
      for (let i = 0; i < metrics.length; i++) {
        const dim = metrics[i].metadata.dimensions.find((d) => d.id === dimId)
        if (dim) {
          timeDimFromMetric = {
            ...dim,
            label:
              globalDimensions.find((d) => d.id === dim.id)?.label || dim.id,
          }
          break
        }
      }
      result[dimId] = timeDimFromMetric || dimsMap[dimId]
      return
    } else if (dimsMap[dimId]?.values) {
      result[dimId] = dimsMap[dimId]
    }
  })

  return result
}

function getAllUsedDimsIds(
  metrics: MetricLike[],
  rowDimension?: string,
): string[] {
  const dimsSet = new Set<string>()

  metrics.forEach((m) => {
    m.data.indexes.forEach((dimId) => dimsSet.add(dimId))
    if (m.metadata.value.dimension_id) {
      dimsSet.add(m.metadata.value.dimension_id)
    }
  })

  if (rowDimension) {
    dimsSet.delete(rowDimension)
  }

  return [...dimsSet]
}
