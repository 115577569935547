import React from 'react'

export const DragHandle: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.96999 6.21196C8.22518 6.21196 9.24272 5.19442 9.24272 3.93923C9.24272 2.68404 8.22518 1.6665 6.96999 1.6665C5.7148 1.6665 4.69727 2.68404 4.69727 3.93923C4.69727 5.19442 5.7148 6.21196 6.96999 6.21196ZM13.0306 6.21196C14.2858 6.21196 15.3033 5.19442 15.3033 3.93923C15.3033 2.68404 14.2858 1.6665 13.0306 1.6665C11.7754 1.6665 10.7579 2.68404 10.7579 3.93923C10.7579 5.19442 11.7754 6.21196 13.0306 6.21196ZM9.24272 9.99984C9.24272 11.2551 8.22518 12.2726 6.96999 12.2726C5.7148 12.2726 4.69727 11.2551 4.69727 9.99984C4.69727 8.74461 5.7148 7.72711 6.96999 7.72711C8.22518 7.72711 9.24272 8.74461 9.24272 9.99984ZM6.96999 18.3332C8.22518 18.3332 9.24272 17.3157 9.24272 16.0604C9.24272 14.8052 8.22518 13.7877 6.96999 13.7877C5.7148 13.7877 4.69727 14.8052 4.69727 16.0604C4.69727 17.3157 5.7148 18.3332 6.96999 18.3332ZM15.3033 9.99984C15.3033 11.2551 14.2858 12.2726 13.0306 12.2726C11.7754 12.2726 10.7579 11.2551 10.7579 9.99984C10.7579 8.74461 11.7754 7.72711 13.0306 7.72711C14.2858 7.72711 15.3033 8.74461 15.3033 9.99984ZM13.0306 18.3332C14.2858 18.3332 15.3033 17.3157 15.3033 16.0604C15.3033 14.8052 14.2858 13.7877 13.0306 13.7877C11.7754 13.7877 10.7579 14.8052 10.7579 16.0604C10.7579 17.3157 11.7754 18.3332 13.0306 18.3332Z"
      fill="#D9D9D9"
      fillOpacity="0.54"
    />
  </svg>
)
