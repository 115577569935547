import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import type { ObjectTokenRecognitionMap } from '../tokens/types'
import type { Maybe } from '@fintastic/shared/util/types'
import type {
  FintasticSyntaxApi,
  ValidationError,
} from '@fintastic/web/data-access/formulas'
import { getErrorMessage } from './getErrorMessage'
import { RangesMap } from './types'
import { getRangeHash } from './getRangeHash'

// @todo add tests
export function useValidation(
  formula: string,
  objectRecognitionMap: Maybe<ObjectTokenRecognitionMap>,
  syntaxApi: Maybe<FintasticSyntaxApi>,
) {
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [isWaiting, setIsWaiting] = useState(true)

  const highlightRanges = useMemo<RangesMap>(() => {
    const ranges = errors
      .map((error) => {
        if (error.type === 'objectRecognition') {
          return [
            getRangeHash(error.column, error.column + error.object.length - 1),
            getErrorMessage(error),
          ]
        }
        if (error.type === 'tokenRecognition') {
          return [
            getRangeHash(error.column, error.column + 1),
            getErrorMessage(error),
          ]
        }
        if (error.type === 'parser') {
          return [getRangeHash(error.start, error.stop), getErrorMessage(error)]
        }
        return null
      })
      .filter((e) => !!e) as unknown as [string, string][]
    return Object.fromEntries(ranges)
  }, [errors])

  const paramsRef = useRef({
    formula,
    objectRecognitionMap,
    syntaxApi,
  })
  paramsRef.current = {
    formula,
    objectRecognitionMap,
    syntaxApi,
  }

  const rendersCount = useRef(0)
  rendersCount.current += 1

  const validate = useCallback(() => {
    const { formula, objectRecognitionMap, syntaxApi } = paramsRef.current

    if (!syntaxApi) {
      return
    }

    try {
      const result = syntaxApi.validateSyntax(
        formula,
        objectRecognitionMap || {},
      )
      setErrors(result !== true ? result : [])
      setIsWaiting(false)
    } catch (ex: unknown) {
      setErrors([])
      // @todo (mykola): uncomment when FIN-5314 is done
      // setErrors([
      //   {
      //     type: 'parser',
      //     message: (ex as Error).message || 'Unknown Error',
      //     line: 0,
      //     column: 0,
      //     start: 0,
      //     stop: 0,
      //     text: (ex as Error).message || 'Unknown Error',
      //     tokenIndex: 0,
      //   },
      // ])
      setIsWaiting(false)
      console.error(ex)
    }
  }, [])

  const debouncedValidate = useMemo(() => debounce(validate, 600), [validate])

  useEffect(() => {
    debouncedValidate()
  }, [debouncedValidate, formula, objectRecognitionMap, syntaxApi])

  useEffect(() => {
    if (rendersCount.current === 1) {
      return
    }
    setIsWaiting(true)
  }, [formula])

  return useMemo(
    () => ({
      isValid: errors.length === 0,
      isWaiting,
      errorMessage: errors.length > 0 ? getErrorMessage(errors[0]) : null,
      highlightRanges,
    }),
    [errors, highlightRanges, isWaiting],
  )
}
