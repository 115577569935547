import React, { useCallback } from 'react'
import { ModalDialog } from '@fintastic/shared/ui/components'
import { useSelector } from 'react-redux'
import { selectSaveState } from '../ducks'
import { Box, Button, List, ListItem } from '@mui/material'
import { ColumnConfig, TableAccess } from '../types'
import { useSaveChanges } from '../hooks/useSaveChanges'
import { LoadingButton } from '@mui/lab'
import { usePermissionsFlattenedChanges } from '../hooks/usePermissionsFlattenedChanges'
import { LockIcon } from './LockIcon'

type ChangeObject = {
  groupId: string
  changeKey: string
  changeType: string
  payload: any
}
const changeDisplay: Record<string, (change: ChangeObject) => JSX.Element> = {
  column_mask: (change) => (
    <span>
      A masking configuration for <b>{(change.payload as ColumnConfig).id}</b>{' '}
      has been changed
    </span>
  ),
  table: (change) => (
    <span>
      A table configuration for <b>{(change.payload as TableAccess).id}</b> has
      been changed
    </span>
  ),
  default_dimensions: (change) => (
    <span>The default dimension configuration has been changed</span>
  ),
}
export const ApplyChangesDialog: React.FC = () => {
  const saving = useSelector(selectSaveState)
  const [dialogOpen, openDialog] = React.useState(false)
  const saveChanges = useSaveChanges()
  const onSaveClick = useCallback(() => {
    saveChanges()
    openDialog(false)
  }, [saveChanges])

  const flattendChanges = usePermissionsFlattenedChanges()
  const isChanged = flattendChanges.length > 0

  const modal = (
    <ModalDialog open={!!dialogOpen} maxWidth="105rem">
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        Changes:
        <List>
          {flattendChanges.map((change) => (
            <ListItem key={change.groupId + change.changeKey}>
              <>
                <LockIcon />
                <b style={{ marginRight: '4px' }}>{change.groupId}</b>
                {(changeDisplay[change.changeType] || JSON.stringify)(change)}
              </>
            </ListItem>
          ))}
        </List>
      </Box>
      <Button
        data-testid="save-changes-button"
        onClick={onSaveClick}
        style={{ marginTop: '5px' }}
        variant="contained"
      >
        Save
      </Button>
      <Button
        style={{ marginLeft: '5px', marginTop: '5px' }}
        onClick={() => {
          openDialog(false)
        }}
        variant="outlined"
      >
        Close
      </Button>
    </ModalDialog>
  )

  return (
    <>
      <LoadingButton
        variant="contained"
        data-testid="open-apply-changes-button"
        onClick={() => openDialog(true)}
        loading={saving}
        disabled={!isChanged}
      >
        Apply Changes
      </LoadingButton>
      {modal}
    </>
  )
}
