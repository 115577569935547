import { Maybe } from '@fintastic/shared/util/types'
import { AddLinesStateValuesRecord } from '@fintastic/web/feature/list-add-lines'
import { ColumnIdParts, ColumnsIdStructure } from '../columns'
import { AddLinesDataColumn, AddLinesDataPayload } from '../api'

const getFieldValueOrEmpty = (
  record: AddLinesStateValuesRecord,
  column: ColumnIdParts,
) => {
  const result = record[column.field] ?? null

  if (column.isText === true) {
    return result === null ? '' : result.toString() // @todo: trim?
  }

  return result
}

export const convertStateToServerFormat = (
  structure: ColumnsIdStructure,
  stateData: AddLinesStateValuesRecord[],
): Maybe<AddLinesDataPayload> => {
  const validRows = stateData.filter((r) => r._valid)
  if (validRows.length === 0) {
    return null
  }
  const columns: AddLinesDataColumn[] = []

  structure.forEach((column) => {
    // regular column, check calculated
    if ((column as ColumnIdParts).isCalculated) {
      return
    }

    if (!Array.isArray(column)) {
      // create output column
      const dataColumn: AddLinesDataColumn = {
        column_name: column.columnName,
        values: validRows.map((record) => getFieldValueOrEmpty(record, column)),
      }
      columns.push(dataColumn)
      return
    }

    if (column.length === 0) {
      return
    }

    const column_name = column[0].columnName
    const time_dimension_id = column[0].listTimeBreakdownDimId
    const time_dimension_value_ids = column[0].timeBreakdownDims || []

    const subColumns = column.filter((col) => !col.isCalculated)
    if (subColumns.length === 0) {
      return
    }
    // create output column
    const dataTimeBreakColumn: AddLinesDataColumn = {
      column_name,
      time_dimension_id,
      time_dimension_value_ids,
      values: validRows.map((record) =>
        subColumns.map((subColumn) => getFieldValueOrEmpty(record, subColumn)),
      ),
    }
    columns.push(dataTimeBreakColumn)
  })

  return columns.length
    ? {
        columns,
      }
    : null
}
