import { DimensionId } from '@fintastic/web/util/dimensions'
import { EditMetricDataIntent, MetricDataFillStrategy } from '../../types'
import { Maybe } from '@fintastic/shared/util/types'

export const createEditMetricDataIntent = (
  dimensions: DimensionId[],
  fillStrategy: Maybe<MetricDataFillStrategy> = null,
): EditMetricDataIntent => ({
  format: 'sparse',
  indexes: dimensions,
  dimensions: Array(dimensions.length)
    .fill(null)
    .map(() => []),
  values: [],
  fill_strategy: fillStrategy,
})
