import { Report } from '@fintastic/web/util/generic-report'
import { useCommonBaseDimensionByDimensionIds } from '@fintastic/web/util/period-selector'
import { compact } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSetStaticWidgetBaseTimeDimension } from '@fintastic/web/data-access/base-time-dimension'
import { UnsupportedGranularityAggregationError } from '@fintastic/web/util/period-selector'

export const useSetBaseTimeDimensionEffect = (
  reports: Report[],
  errors: Array<{ error: Error; versionId: string }>,
): void => {
  const reportBaseTimeDimensionIds = useMemo(() => {
    const baseTimeIdsFromMetadata = compact(
      reports.map((i) => i?.metadata?.base_time_dimension_id),
    )

    const baseTimeIdsFromErrors = compact(
      errors.map(({ error }) => {
        if (error && error instanceof UnsupportedGranularityAggregationError) {
          const baseDimensionId = error.getBaseTimeDimensionId()
          if (baseDimensionId) {
            return baseDimensionId
          }
        }

        return null
      }),
    )

    return [...baseTimeIdsFromMetadata, ...baseTimeIdsFromErrors]
  }, [errors, reports])
  const commonBaseTimeDimension = useCommonBaseDimensionByDimensionIds(
    reportBaseTimeDimensionIds,
  )
  const setStaticWidgetBaseTimeDimension = useSetStaticWidgetBaseTimeDimension()

  useEffect(() => {
    if (commonBaseTimeDimension) {
      setStaticWidgetBaseTimeDimension(commonBaseTimeDimension)
    }
  }, [commonBaseTimeDimension, setStaticWidgetBaseTimeDimension])
}
