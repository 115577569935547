import { UseLocationResult } from '@fintastic/shared/util/memory-router'
import { createContext, useContext } from 'react'

export type SectionedModalNavigationContextValue = UseLocationResult
const stub = () => {
  throw new Error('SectionedModalNavigationContext is not initialised')
}

export const SectionedModalNavigationContext =
  createContext<SectionedModalNavigationContextValue>({
    path: null,
    goTo: stub,
    goBack: stub,
    reset: stub,
  })

export function useSectionedModalNavigationContext() {
  return useContext(SectionedModalNavigationContext)
}
