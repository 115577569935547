import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import {
  ChangeColumnVisibilityAction,
  ReorderColumnsAction,
} from '../../connection/actions/types'
import { createContext, useContext } from 'react'

export type ColumnsSettingsContextValue = {
  listId: string
  columns: ListColumnWrapper[]
  onGoToColumn: (columnId: string) => void
  onReorderColumns: ReorderColumnsAction
  onChangeColumnVisibility: ChangeColumnVisibilityAction
  readonly?: boolean
  calculated: boolean
}

const stub = () => {
  throw new Error('ColumnSettingsContext is not initialised')
}

export const ColumnSettingsContext = createContext<ColumnsSettingsContextValue>(
  {
    listId: '',
    columns: [],
    onGoToColumn: stub,
    onReorderColumns: stub,
    onChangeColumnVisibility: stub,
    readonly: false,
    calculated: false,
  },
)

export function useColumnsSettingsContext() {
  return useContext(ColumnSettingsContext)
}
