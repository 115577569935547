import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyledActionsBar, StyledList, StyledRoot } from './ColumnsPanel.styled'
import { ActionsBar } from './ActionsBar'
import { ColumnsList } from './ColumnsList'
import isEqual from 'lodash/isEqual'
import { useColumnsPanelContext } from '../context'
import { ColumnVisibilityToggleCallback } from './types'
import { ColDef, GridApi } from 'ag-grid-community'
import { ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { toast } from '@fintastic/shared/ui/toast-framework'

function flatChildren(columns?: ColDef[] | ColGroupDef[]) {
  const flatten: ColDef[] = []
  if (!columns) {
    return []
  }

  const flatLevel = (arr: ColDef[] | ColGroupDef[]) => {
    arr.forEach((col) => {
      if (!Array.isArray((col as ColGroupDef).children)) {
        flatten.push(col as ColDef)
      } else {
        flatLevel((col as ColGroupDef).children)
      }
    })
  }
  flatLevel(columns)

  return flatten
}

export const ColumnsPanel: React.FC<{
  closePanel: () => void
  api?: GridApi
}> = ({ closePanel, api }) => {
  const {
    columns,
    hiddenColumns: currentHiddenColumns,
    defaultHiddenColumns,
    onChangeVisibility,
  } = useColumnsPanelContext()

  const [newHiddenColumns, setNewHiddenColumns] = useState<string[]>([])

  useEffect(() => {
    setNewHiddenColumns(currentHiddenColumns)
  }, [currentHiddenColumns])

  const handleReset = useCallback(() => {
    setNewHiddenColumns(defaultHiddenColumns)
  }, [defaultHiddenColumns])

  const handleApply = useCallback(() => {
    onChangeVisibility(newHiddenColumns)
    closePanel()
  }, [newHiddenColumns, onChangeVisibility, closePanel])

  const handleToggleColumnVisibility =
    useCallback<ColumnVisibilityToggleCallback>((column, visible) => {
      if (visible) {
        setNewHiddenColumns((c) => c.filter((id) => id !== column))
      } else {
        setNewHiddenColumns((c) => [...c, column])
      }
    }, [])

  const handleShowAll = useCallback(() => {
    setNewHiddenColumns([])
  }, [])

  const handleHideAll = useCallback(() => {
    setNewHiddenColumns(columns.map((c) => c.id))
  }, [columns])

  const canApply = useMemo(
    () =>
      !isEqual(currentHiddenColumns, newHiddenColumns) &&
      newHiddenColumns.length < columns.length,
    [columns.length, currentHiddenColumns, newHiddenColumns],
  )

  const canReset = useMemo(
    () => !isEqual(defaultHiddenColumns, newHiddenColumns),
    [defaultHiddenColumns, newHiddenColumns],
  )

  const handleNavigate = useCallback(
    (columnId: string) => {
      if (!api || !columnId) {
        return
      }

      const existingColumns = flatChildren(api.getColumnDefs()) || []

      // direct column_id
      let result = existingColumns.find(
        (col) => col.colId === columnId || col.colId?.endsWith(columnId),
      )

      // entity_id:column_id
      if (!result) {
        result = existingColumns.find((col) =>
          col.colId?.endsWith(':' + columnId),
        )
      }

      // entity_id:column_id:dimension_id (first)
      if (!result) {
        result = existingColumns.find((col) =>
          col.colId?.includes(':' + columnId + ':'),
        )
      }

      if (!result?.colId) {
        if (canApply) {
          toast(
            'Column not found. If you change the visibility of a column, first click "Apply"',
          )
        }
        return
      }

      api.ensureColumnVisible(result.colId, 'start')
    },
    [api, canApply],
  )

  return (
    <StyledRoot>
      <StyledList>
        <ColumnsList
          columns={columns}
          hiddenColumns={newHiddenColumns}
          onToggleColumnVisibility={handleToggleColumnVisibility}
          onShowAll={handleShowAll}
          onHideAll={handleHideAll}
          onNavigateToColumn={handleNavigate}
        />
      </StyledList>
      <StyledActionsBar>
        <ActionsBar
          canApply={canApply}
          canReset={canReset}
          onReset={handleReset}
          onApply={handleApply}
        />
      </StyledActionsBar>
    </StyledRoot>
  )
}
