import { MenuItem, styled } from '@mui/material'
import IconButton from '@mui/material/IconButton'

export const StyledModelExplorerVersionSelectorMenuItem = styled(MenuItem)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  span {
    margin-left: 0.4em;
  }
`

export const ExplorerButtonWithDropDown = styled(IconButton)<{
  disabled?: boolean
}>`
  width: 48px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ disabled, theme }) =>
      disabled ? theme.palette.action.disabledBackground : '#f9f7f5'};
  }
`
