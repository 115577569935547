import { Box, Button, styled } from '@mui/material'

export const StyledDialogButtonContainer = styled(Box)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`

export const StyledDialogSubmitButton = styled(Button)``

export const StyledDialogCancelButton = styled(Button)`
  margin-right: 6px;
`
