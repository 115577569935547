import { useCallback, useMemo, useState } from 'react'
import {
  useLoadVersionDependencyGraph,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import { DependencyGraphWrapper, Version } from '@fintastic/web/util/versions'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { ListSettingsPanelDataProviderProps } from '@fintastic/web/feature/metrics-and-lists'
import { currencies } from '@fintastic/shared/data-access/currencies'

type Result = Omit<ListSettingsPanelDataProviderProps, 'list' | 'children'>

export function useSettingsPanelData(
  version: Maybe<Pick<Version, 'uuid' | 'period_start' | 'period_end'>>,
) {
  const [shouldLoadData, setShouldLoadData] = useState(false)

  const entitiesQuery = useLoadVersionEntities(version?.uuid, shouldLoadData)
  const dependenciesQuery = useLoadVersionDependencyGraph(
    version?.uuid,
    shouldLoadData,
  )
  const dependencyGraph = useMemo(
    () =>
      !dependenciesQuery.data
        ? null
        : new DependencyGraphWrapper(dependenciesQuery.data),
    [dependenciesQuery.data],
  )

  const handleLoadData = useCallback(() => {
    setShouldLoadData(true)
  }, [])

  return useMemo<Result>(
    () => ({
      isLoading: entitiesQuery.isLoading || dependenciesQuery.isLoading,
      isLoaded:
        entitiesQuery.data !== undefined &&
        dependenciesQuery.data !== undefined,
      load: handleLoadData,
      metricsList: toMaybe(entitiesQuery.data?.metrics),
      dimensionsList: toMaybe(entitiesQuery.data?.dimensions),
      currenciesList: currencies,
      dependencyGraph,
    }),
    [
      dependenciesQuery.data,
      dependenciesQuery.isLoading,
      dependencyGraph,
      entitiesQuery.data,
      entitiesQuery.isLoading,
      handleLoadData,
    ],
  )
}
