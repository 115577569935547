import { alpha, styled } from '@mui/material'

export const StyledRoot = styled('div', {
  shouldForwardProp: (p) => p !== 'noPaddings' && p !== 'alignment',
})<{ noPaddings: boolean; alignment: 'center' | 'left' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ alignment }) =>
    alignment === 'center' ? 'center' : 'flex-start'};
  padding: ${({ noPaddings }) => (noPaddings ? '0' : '6px 16px')};

  width: 100%;
`

export type ErrorAlertSeverity = 'info' | 'warning'

const iconColours: Record<
  ErrorAlertSeverity,
  {
    bg: string
    fg: string
  }
> = {
  info: {
    bg: alpha('#7296FE', 0.12),
    fg: '#05A8EF',
  },
  warning: {
    bg: alpha('#FE9463', 0.12),
    fg: '#D45702',
  },
}

export const StyledIcon = styled('div', {
  shouldForwardProp: (p) => p !== 'severity',
})<{ severity: ErrorAlertSeverity }>`
  margin-right: 12px;

  i {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    min-width: 42px;

    border-radius: 50%;

    background: ${({ severity }) => iconColours[severity].bg};
    color: ${({ severity }) => iconColours[severity].fg};
  }

  svg {
    width: 24px;
    height: auto;

    color: inherit;
  }
`

export const StyledText = styled('div')`
  max-width: 326px;
`

export const StyledTitle = styled('div')`
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledMessage = styled('div')`
  margin-top: 4px;

  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.palette.text.secondary};
`
