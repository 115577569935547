import { useMutation } from 'react-query'
import { useMemo } from 'react'
import { DisplayErrorCallback, LockVersionCallback } from './types'
import { StateApi } from '../editor-state'
import { getReport } from '@fintastic/web/data-access/generic-report'
import { Maybe } from '@fintastic/shared/util/types'

export type StartEditingOperationApi = Pick<
  ReturnType<typeof useStartEditingOperation>,
  'error' | 'mutateAsync' | 'isLoading'
>

export type StartEditingOperationParams = {
  versionId: string
}

export type StartEditingOperationDependencies = {
  stateApi: StateApi
  lockVersion: LockVersionCallback
  displayError: DisplayErrorCallback
}

export const useStartEditingOperation = (
  params: StartEditingOperationParams,
  deps: StartEditingOperationDependencies,
) =>
  useMutation({
    mutationFn: useMemo(() => makeOperationFn(params, deps), [deps, params]),
  })

export const makeOperationFn =
  (
    params: StartEditingOperationParams,
    deps: StartEditingOperationDependencies,
  ) =>
  async (variables: { reportId: string }) => {
    if (deps.stateApi.state.active) {
      throw new Error('some report is already being editing')
    }

    let reportDefinition: Maybe<{ id: string; name: string }> = null

    try {
      reportDefinition = (await getReport(params.versionId, variables.reportId))
        .data
    } catch (error) {
      console.error(error)
      deps.displayError('Cannot load the report')
      throw error
    }
    if (!reportDefinition) {
      return
    }

    try {
      await deps.lockVersion()
    } catch (error) {
      deps.displayError('Cannot lock the version for editing')
      throw error
    }

    deps.stateApi.startEditing({
      reportDefinition,
    })
  }
