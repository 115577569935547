import React, { useMemo } from 'react'
import { TableNameExpandableGroupRenderer } from '../CellRenderers/TableNameExpandableGroupRenderer'
import {
  BaseGridColumnDefinitionCellRenderer,
  BuildBaseGridColumnDefinitionParams,
} from '@fintastic/shared/ui/grid-framework'
import {
  DescriptionCellEditor,
  FilterLabelCellRenderer,
  LabelsCellRenderer,
  VersionTable,
} from '@fintastic/web/feature/versions'
import {
  type ICellRendererParams,
  ISetFilterParams,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community'
import {
  HeaderWithTooltip,
  TableTypeCellRenderer,
} from '@fintastic/shared/ui/ag-grid'
import { TableSourceColRenderer } from '../CellRenderers/TableSourceColRenderer'
import { TableActionsCellRenderer } from '../CellRenderers/TableActionsCellRenderer'
import {
  dateToLocalDateTimeString,
  LocalDate,
} from '@fintastic/shared/util/date'
import { LabelTagListItem } from '@fintastic/web/data-access/labels'
import { Maybe } from '@fintastic/shared/util/types'
import { ModelExplorerApi } from '@fintastic/web/feature/model-explorer'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'

export const useBaseGridColumns = (
  params: {
    versionId: string
    isLiveActuals: boolean
    editable: boolean
    hasEditingAccess: boolean
  },
  deps: {
    checkIsGridOpened: (gridId: string) => boolean
    checkIsFormulaOpened: (gridId: string) => boolean
    toggleFormulaMasterDetail: (gridId: string, node?: Maybe<RowNode>) => void
    labelListDataRef: React.RefObject<LabelTagListItem[] | undefined>
    modelExplorerApi: Maybe<ModelExplorerApi>
  },
) => {
  const { versionId, isLiveActuals, hasEditingAccess, editable } = params
  const {
    checkIsGridOpened,
    checkIsFormulaOpened,
    toggleFormulaMasterDetail,
    labelListDataRef,
    modelExplorerApi,
  } = deps

  const labelChangesAllowed = useRoleLevelAccess(['power_user', 'modeler'])

  return useMemo<BuildBaseGridColumnDefinitionParams<VersionTable>[]>(
    () => [
      {
        field: 'label',
        title: 'Name',
        cellRenderer:
          TableNameExpandableGroupRenderer as BaseGridColumnDefinitionCellRenderer<VersionTable>,
        cellRendererParams: {
          checkIsGridOpened,
        },
        width: 400,
        minWidth: 400,
      },
      {
        field: 'info.label_ids',
        title: 'Labels',
        cellRenderer: (params) => {
          if (params.data?.type === 'report') {
            // @todo add support for reports
            return null
          }
          if (params.data?.creationDummy) {
            return null
          }
          return (
            <LabelsCellRenderer
              {...(params as Parameters<typeof LabelsCellRenderer>[0])}
            />
          )
        },
        cellRendererParams: {
          versionId,
          isLiveActuals,
          changesAllowed: labelChangesAllowed,
        },
        minWidth: 400,
        width: 400,
        valueGetter: (params) =>
          params.data?.type === 'report'
            ? []
            : params.data?.info?.label_ids || [],
        valueFormatter: ({ value }) =>
          ((value as number[]) || [])
            .map(
              (valueId) =>
                (labelListDataRef.current || []).find(
                  ({ id }) => id === valueId,
                )?.title || valueId,
            )
            .join(', '),
        filterParams: {
          cellRenderer: FilterLabelCellRenderer,
          valueFormatter: ({ value }) =>
            (labelListDataRef.current || []).find(({ id }) => `${id}` === value)
              ?.title || value,
        } as ISetFilterParams,
      },
      {
        field: 'type',
        title: 'Entity',
        cellRenderer:
          TableTypeCellRenderer as BaseGridColumnDefinitionCellRenderer<VersionTable>,
      },
      {
        field: 'source',
        title: 'Type',
        cellRenderer:
          TableSourceColRenderer as BaseGridColumnDefinitionCellRenderer<VersionTable>,
        cellRendererParams: {
          toggleFormulaMasterDetail,
          checkIsFormulaOpened,
          icon: isLiveActuals ? 'ax' : 'fx',
        },
      },
      ...(hasEditingAccess && modelExplorerApi
        ? [
            {
              field: '_modelExplorer',
              title: 'Version Explorer',
              minWidth: 140,
              maxWidth: 140,
              resizable: false,
              cellRenderer: (params: ICellRendererParams<VersionTable>) => {
                if (params.data?.type === 'report') {
                  // @todo add support for reports
                  return null
                }
                if (params.data?.creationDummy) {
                  return null
                }
                return (
                  <TableActionsCellRenderer
                    {...(params as Parameters<
                      typeof TableActionsCellRenderer
                    >[0])}
                  />
                )
              },
              cellRendererParams: {
                versionId,
              },
            },
          ]
        : []),
      {
        field: 'info.description',
        disableFilter: true,
        title: 'Description',
        headerComponent: HeaderWithTooltip,
        headerComponentParams: {
          tooltip: 'Up to 160 characters entity description',
        },
        cellRenderer: (params) => {
          if (params.data?.type === 'report') {
            // @todo add support for reports
            return null
          }
          if (params.data?.creationDummy) {
            return null
          }

          return (
            <DescriptionCellEditor
              {...(params as Parameters<typeof DescriptionCellEditor>[0])}
            />
          )
        },
        cellRendererParams: {
          versionId,
          editable: editable,
          showHidden: isLiveActuals,
        },
        valueGetter: (params: ValueGetterParams<VersionTable>): string =>
          params?.data?.type === 'report'
            ? ''
            : params?.data?.info?.description || '',
        minWidth: 200,
      },
      {
        field: 'info',
        title: isLiveActuals ? 'Updated' : 'Last modified',
        minWidth: 230,
        valueGetter: (params: ValueGetterParams<VersionTable>): string =>
          params?.data?.type === 'report'
            ? ''
            : dateToLocalDateTimeString(params?.data?.info?.last_updated),
        filter: 'agSetColumnFilter',
        cellRenderer: (params) =>
          params?.data?.type === 'report' ? null : (
            <LocalDate
              date={params?.data?.info?.last_updated}
              withTooltip={true}
              withGMT={true}
            />
          ),
      },
    ],
    [
      checkIsGridOpened,
      versionId,
      toggleFormulaMasterDetail,
      checkIsFormulaOpened,
      isLiveActuals,
      hasEditingAccess,
      modelExplorerApi,
      editable,
      labelListDataRef,
    ],
  )
}
