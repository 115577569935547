import React, { useMemo } from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import {
  VersionEntityType,
  WrappedVersionEntityDefinition,
} from '@fintastic/web/util/versions'
import { EntityIcon } from './EntityIcon'
import { ParsedColumnId } from '@fintastic/web/util/metrics-and-lists'
import {
  Nullable,
  pipeNullable,
} from '@fintastic/shared/util/functional-programming'
import { StandardDescription } from './delete-descriptions/StandardDescription'
import { WeightingMetricDescription } from './delete-descriptions/WeightingMetricDescription'

type EntityInterface = {
  id: string
  label: string
  type: VersionEntityType | 'report'
}

export type DependenciesPopupProps = {
  isOpen: boolean
  onClose: () => void
  successors: Maybe<EntityInterface[]>
  weightsSuccessors: string[]
  lists: Nullable<EntityInterface[]>
  entityType: WrappedVersionEntityDefinition['type']
  lastColumnInList: boolean
}

const capitalise = (s: string) =>
  `${s.slice(0, 1).toLocaleUpperCase()}${s.slice(1)}`

const getEntitySecondaryText = (
  lists: Nullable<EntityInterface[]>,
  entity: EntityInterface,
): string =>
  pipeNullable(
    entity.type === 'column' ? ParsedColumnId.fromString(entity.id) : null,
    (id) => lists?.find((entity) => entity.id === id.listId),
    (listEntity) => `Column of ${listEntity.label}`,
  ) || capitalise(entity.type)

const MAX_VISIBLE_SUCCESSORS = 5

export const DependenciesModal: React.FC<DependenciesPopupProps> = ({
  isOpen,
  onClose,
  successors,
  entityType,
  lists,
  lastColumnInList,
  weightsSuccessors,
}) => {
  const visibleSuccessors = (successors || []).slice(0, MAX_VISIBLE_SUCCESSORS)

  const invisibleEntitiesAmount =
    (successors?.length || 0) - visibleSuccessors.length

  const affectedTypes = useMemo<string>(
    () =>
      (successors || [])
        .reduce((prev, current) => {
          // column is for lists, so parent entity type is List
          const curType = current.type === 'column' ? 'list' : current.type

          if (prev.indexOf(curType) === -1) {
            return [...prev, curType]
          }
          return prev
        }, [] as string[])
        .map((v) => v.charAt(0).toUpperCase() + v.slice(1) + 's')
        .join(' / '),
    [successors],
  )

  const descriptionComponent = useMemo(() => {
    if (weightsSuccessors && weightsSuccessors.length > 0) {
      return <WeightingMetricDescription affectedTypes={affectedTypes} />
    }
    return (
      <StandardDescription
        entityType={entityType}
        lastColumnInList={lastColumnInList}
        affectedTypes={affectedTypes}
      />
    )
  }, [entityType, lastColumnInList, weightsSuccessors])

  return (
    <ConfirmationPopup
      open={isOpen}
      onRequestClose={onClose}
      title="Deletion is not possible due to dependencies."
      description={descriptionComponent}
    >
      <Box width="100%">
        <List dense={true} sx={{ pt: 0, mb: 1 }}>
          {visibleSuccessors.map((entity) => (
            <ListItem key={entity.id} sx={{ pl: 0, pr: 0 }}>
              <ListItemIcon sx={{ minWidth: 32 }}>
                <EntityIcon type={entity.type} />
              </ListItemIcon>
              <ListItemText secondary={getEntitySecondaryText(lists, entity)}>
                {entity.label}
              </ListItemText>
            </ListItem>
          ))}
          {invisibleEntitiesAmount > 0 && (
            <ListItem sx={{ pl: 0, pr: 0 }}>
              <ListItemText>And {invisibleEntitiesAmount} more...</ListItemText>
            </ListItem>
          )}
        </List>
        <Box display="flex" justifyContent="center">
          <ConfirmationPopup.DeclineButton>
            Cancel
          </ConfirmationPopup.DeclineButton>
        </Box>
      </Box>
    </ConfirmationPopup>
  )
}
