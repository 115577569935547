import { ThemeComponents } from './types'

export const MuiAlert: ThemeComponents['MuiAlert'] = {
  defaultProps: {},
  styleOverrides: {
    icon: ({ theme }) => ({
      marginLeft: theme.spacing(1),
      marginRight: 0,
    }),
    root: ({ theme }) => ({
      padding: theme.spacing(1),
    }),
    message: ({ theme }) => ({
      padding: theme.spacing(0, 2),
      width: '100%',
      marginBottom: 2,
    }),
  },
}
