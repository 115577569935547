import { styled, Box, BoxProps } from '@mui/material'
import { layoutConfig } from '../../layout-config'

export const StyledPageLayoutContentFullHeightRoot = styled(
  (
    props: BoxProps & {
      disablePaddingVertical?: boolean
      disablePaddingBottom?: boolean
      disablePaddingTop?: boolean
      disablePaddingHorizontal?: boolean
    },
  ) => {
    const {
      disablePaddingHorizontal,
      disablePaddingVertical,
      disablePaddingBottom,
      disablePaddingTop,
      ...rest
    } = props

    return <Box {...rest} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'disablePaddingVertical',
  },
)(({ theme, disablePaddingVertical }) => ({
  position: 'relative',

  width: '100%',
  height: `calc(100vh - ${
    !disablePaddingVertical
      ? layoutConfig.page.paddingTop + layoutConfig.page.paddingBottom
      : 0
  }px - ${layoutConfig.topbar.height}px)`,
}))
