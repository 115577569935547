/**
 Keep here the hooks attached to the current duck.
 In perfect case there should be only one way for components to interact with a duck - it's thought the hooks.
 Our hooks it's like a public API for components, and they shouldn't use selectors or action creators directly.
 */

import {
  selectIsSidebarOpened,
  selectSidebarSubmenu,
  setIsSidebarOpened,
  setSidebarSubmenu,
} from './index'
import { useDispatch, useSelector } from 'react-redux'
import type {
  SetIsSidebarOpenedPayload,
  SetSidebarSubmenuPayload,
} from './reducers'
import { useCallback } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export function useLayoutStateIsSidebarOpened(): [
  boolean,
  (payload: SetIsSidebarOpenedPayload) => void,
] {
  const dispatch = useDispatch()
  const isOpened = useSelector(selectIsSidebarOpened)

  const setHandler = useCallback(
    (payload: SetIsSidebarOpenedPayload) => {
      dispatch(setIsSidebarOpened(payload))
    },
    [dispatch],
  )

  return [isOpened, setHandler]
}

export function useLayoutStateSidebarSubmenu(): [
  Maybe<string>,
  (payload: SetSidebarSubmenuPayload) => void,
] {
  const dispatch = useDispatch()
  const submenuKey = useSelector(selectSidebarSubmenu)

  const setHandler = useCallback(
    (payload: SetSidebarSubmenuPayload) => {
      dispatch(setSidebarSubmenu(payload))
    },
    [dispatch],
  )

  return [submenuKey, setHandler]
}
