import React from 'react'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { BoardNewForm } from '../../forms/BoardNewForm'

export type BoardNewDesignModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

export const BoardNewDesignModal: React.FC<BoardNewDesignModalProps> = (props) => {
  const { isOpen, onRequestClose } = props

  return (
    <Modal
      open={isOpen}
      fixAutofocus={true}
      onRequestClose={onRequestClose}
      title="Create Board"
    >
      <BoardNewForm closeParentModal={onRequestClose} />
    </Modal>
  )
}
