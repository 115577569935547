import { Maybe } from '@fintastic/shared/util/types'
import { DimensionId, DimensionValueId } from './types'

export const DIMENSION_ID_PREFIX = 'Dim'
export const DIM_VALUE_OTHER_POSTFIX = '_other'

const dimIdRegExp = new RegExp(`^${DIMENSION_ID_PREFIX}\\.[^.]+$`)
const dimValueIdRegExp = new RegExp(`^${DIMENSION_ID_PREFIX}\\.[^.]+\\..+$`)
const dimValueOtherIdRegExp = new RegExp(
  `^${DIMENSION_ID_PREFIX}\\.[^.]+\\.${DIM_VALUE_OTHER_POSTFIX}$`,
)

export const idLooksLikeDimValueOther = (id: string): boolean =>
  dimValueOtherIdRegExp.test(id)

export const idLooksLikeDimension = (id: string): id is DimensionId =>
  dimIdRegExp.test(id)

export const idLooksLikeDimensionValue = (id: string): id is DimensionValueId =>
  dimValueIdRegExp.test(id)

export class ParsedDimensionValueId {
  constructor(
    public readonly dimensionId: string,
    public readonly valueIdWithoutPrefix: string,
  ) {}

  public static fromString(id: string): Maybe<ParsedDimensionValueId> {
    if (!idLooksLikeDimensionValue(id)) {
      return null
    }

    const [, dimensionIdWithoutPrefix, ...valueIdParts] = id.split('.')
    if (
      valueIdParts.length === 0 ||
      (valueIdParts.length === 1 && valueIdParts[0] === '')
    ) {
      return null
    }

    return new ParsedDimensionValueId(
      `${DIMENSION_ID_PREFIX}.${dimensionIdWithoutPrefix}`,
      valueIdParts.join('.'),
    )
  }

  public toString() {
    return this.toWrappedString()
  }

  public toWrappedString(wrapper: (s: string) => string = (s) => s): string {
    return `${wrapper(this.dimensionId)}.${wrapper(this.valueIdWithoutPrefix)}`
  }
}
