import React from 'react'
import { createSvgIcon } from '@mui/material'

const paths = [
  'M1.21973 14.4249C1.21973 13.9574 1.31036 13.5758 1.49164 13.28C1.68246 12.9747 1.96868 12.7362 2.35032 12.5645C2.73195 12.3832 3.20899 12.2448 3.78144 12.1494C4.35389 12.0445 5.01698 11.9634 5.77071 11.9061V11.0188C5.77071 10.6277 5.63237 10.3319 5.35568 10.1315C5.08854 9.93118 4.6926 9.83101 4.16785 9.83101C3.97703 9.83101 3.75759 9.84055 3.50953 9.85963C3.27101 9.87871 3.01818 9.9121 2.75103 9.95981C2.48389 10.0075 2.21197 10.0743 1.93529 10.1602L1.67769 8.91508C2.08794 8.78151 2.52205 8.68133 2.98001 8.61455C3.43797 8.53822 3.86731 8.50006 4.26803 8.50006C4.96451 8.50006 5.53696 8.59547 5.98538 8.78628C6.44334 8.96756 6.78204 9.24424 7.00148 9.61634C7.23046 9.98843 7.34495 10.4512 7.34495 11.0045V14.4106C7.34495 14.6968 7.38788 14.8924 7.47375 14.9974C7.55962 15.0928 7.66934 15.1405 7.80291 15.1405C7.85061 15.1405 7.90786 15.1357 7.97465 15.1262C8.04143 15.1166 8.10822 15.0976 8.175 15.0689L8.31812 16.2138C8.16546 16.2997 7.9985 16.3665 7.81722 16.4142C7.63595 16.4714 7.44036 16.5001 7.23046 16.5001C6.86791 16.5001 6.57691 16.419 6.35747 16.2568C6.14757 16.085 6.00446 15.8274 5.92813 15.484C5.68961 15.6652 5.43201 15.837 5.15533 15.9992C4.88818 16.1518 4.59242 16.2711 4.26803 16.3569C3.95318 16.4524 3.60494 16.5001 3.2233 16.5001C2.918 16.5001 2.64131 16.4524 2.39325 16.3569C2.15473 16.2711 1.94483 16.1423 1.76355 15.9705C1.59182 15.7893 1.45825 15.5698 1.36284 15.3122C1.26743 15.0546 1.21973 14.7589 1.21973 14.4249ZM2.79397 14.3104C2.79397 14.6825 2.8846 14.9592 3.06588 15.1405C3.24716 15.3218 3.49045 15.4124 3.79576 15.4124C4.02474 15.4124 4.25849 15.379 4.49701 15.3122C4.73553 15.2359 4.96451 15.1357 5.18395 15.0117C5.41293 14.8781 5.60852 14.7207 5.77071 14.5394V12.7934C5.31275 12.8316 4.90249 12.8793 4.53994 12.9366C4.17739 12.9938 3.86254 13.0797 3.5954 13.1942C3.33779 13.2991 3.13744 13.4422 2.99432 13.6235C2.86075 13.8048 2.79397 14.0337 2.79397 14.3104Z',
  'M12.7719 7.01206L12.7579 7.06806C12.1979 7.85206 11.7592 8.71539 11.4419 9.65806C11.1245 10.5914 10.9659 11.5387 10.9659 12.5001C10.9659 13.4707 11.1245 14.4274 11.4419 15.3701C11.7592 16.3034 12.1979 17.1621 12.7579 17.9461L11.5819 18.7161C10.9379 17.8294 10.4339 16.8587 10.0699 15.8041C9.7152 14.7494 9.53787 13.6481 9.53787 12.5001C9.53787 11.3614 9.7152 10.2647 10.0699 9.21006C10.4339 8.14606 10.9379 7.17072 11.5819 6.28406L12.7719 7.01206Z',
  'M15.6964 12.9201L13.3724 9.81206H15.4164L16.7604 11.8281L18.2164 9.81206H20.1344L17.8384 12.9201L20.5404 16.5321H18.4964L16.7464 14.1241L14.9824 16.5321H12.9944L15.6964 12.9201Z',
  'M20.766 17.9881L20.78 17.9321C21.34 17.1481 21.7786 16.2894 22.096 15.3561C22.4133 14.4134 22.572 13.4614 22.572 12.5001C22.572 11.5294 22.4133 10.5774 22.096 9.64406C21.7786 8.71072 21.34 7.85206 20.78 7.06806L21.956 6.28406C22.6 7.17072 23.0993 8.14139 23.454 9.19606C23.818 10.2507 24 11.3521 24 12.5001C24 13.6387 23.818 14.7401 23.454 15.8041C23.0993 16.8587 22.6 17.8294 21.956 18.7161L20.766 17.9881Z',
]

export const FunctionAXIcon = createSvgIcon(
  <>
    {paths.map((path, i) => (
      <path d={path} key={i} />
    ))}
  </>,
  'FunctionAX',
)

export const functionAxIconSvgPathDef = {
  width: 24,
  height: 24,
  pathString: paths.join(''),
}
