import React, { useCallback } from 'react'
import { IToolPanelParams } from 'ag-grid-community'
import { ColumnsPanel } from './ColumnsPanel'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type ColumnsToolPanelProps = IToolPanelParams

export const AgGridColumnsToolPanel: React.FC<ColumnsToolPanelProps> = ({
  api,
}) => {
  const closePanel = useCallback(() => {
    api.closeToolPanel()
  }, [api])

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <ColumnsPanel closePanel={closePanel} api={api} />
    </FintasticThemeProvider>
  )
}
