import { Maybe } from '@fintastic/shared/util/types'
import { agGridNumericEditorDefaultNumberMask } from '@fintastic/shared/ui/ag-grid'
import {
  createValueFormatter,
  MetricNumericDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { isRawBlankValue } from '@fintastic/web/util/blanks-and-masked'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { ValueFormatterParams } from 'ag-grid-community'
import { formatNumeric } from '@fintastic/shared/util/formatters'
import { applyValueFormatter } from '@fintastic/shared/ui/grid-framework'
import { GetCellEditorProps } from './types'

export const getPercentageCellEditorProps = (
  column: ListColumnWrapper,
): GetCellEditorProps => {
  const dataType = column.dataType()

  const displaySettings = column.displaySettings().unwrap()

  const cellEditorProps = {
    mask: displaySettings ? undefined : agGridNumericEditorDefaultNumberMask,
    dataType: dataType as MetricNumericDataValueType,
    displaySettings: displaySettings,
    parseSourceValue: (value: Maybe<string>) => {
      if (isRawBlankValue(value)) {
        return value
      }
      const parsed = parseFloat(`${value}`)
      return Number.isNaN(parsed) ? '' : `${parsed * 100}`
    },
    parseEditedValue: (value: Maybe<string>) => {
      if (isRawBlankValue(value)) {
        return value
      }
      const parsed = parseFloat(`${value}`)
      return Number.isNaN(parsed) ? '' : parsed / 100
    },
  }

  const valueFormatter = displaySettings
    ? createValueFormatter(dataType as never, displaySettings) || undefined
    : (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          formatNumeric.percentage(value),
        )

  return {
    valueFormatter,
    cellEditorParams: cellEditorProps,
  }
}
