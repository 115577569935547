import React from 'react'
import {
  ChartLegendFormatSwitch,
  ChartLegendPositionSelect,
  defaultChartLegendFormat,
  defaultChartLegendPosition,
  useChartSettingsEditContext,
} from '@fintastic/web/feature/charts'

export const DialogSectionLegend: React.FC = () => {
  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <>
      <ChartLegendPositionSelect
        legendPosition={
          localSettings.legendPosition || defaultChartLegendPosition
        }
        onChangeChartSettings={handleSettingsChange}
      />

      <ChartLegendFormatSwitch
        legendFormat={localSettings.legendFormat || defaultChartLegendFormat}
        onChangeChartSettings={handleSettingsChange}
      />
    </>
  )
}
