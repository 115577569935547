import { Box, styled } from '@mui/material'
import React, {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { DimensionAccessGrid } from './DimensionAccessGrid'
import { useColumnRestriction } from './hooks/useColumnRestriction'
import { useGlobalModel } from './hooks/useGlobalModel'
import {
  ColumnAccessType,
  ColumnMaskConfig,
  DimensionMap,
  DimensionValueTuple,
} from './types'
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  IAggFuncParams,
  ICellRendererParams,
  IDetailCellRendererParams,
  ISetFilterParams,
  SideBarDef,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ColumnMaskToggle } from './components/ColumnMaskToggle'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import {
  cleanupListName,
  removePrefixFromName,
} from '@fintastic/web/util/metrics-and-lists'

const StyledToggle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

type ColumnAccessContextType = {
  dimensions: DimensionValueTuple[]
  columnAccessMap: { [p: string]: ColumnMaskConfig }
}

const ColumnAccessContext = React.createContext<ColumnAccessContextType>({
  dimensions: [],
  columnAccessMap: {},
})

export const ColumnAccessConfiguration = () => {
  const gridRef = useRef<AgGridReact>(null)

  const {
    columns: allColumns,
    dimensions: allDimensions,
    loading,
  } = useGlobalModel()
  const { columnMasking, onColumnAccessChange } = useColumnRestriction()

  const columnAccessMap = useMemo(
    () => Object.fromEntries(columnMasking.map((c) => [c.id, c])),
    [columnMasking],
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
    }),
    [],
  )

  const getRowId = useMemo<GetRowIdFunc>(
    () => (params: GetRowIdParams) => params.data.id,
    [],
  )

  const onColumnAccessChangeRef = useRef(onColumnAccessChange)
  onColumnAccessChangeRef.current = onColumnAccessChange
  const columnAccessMapRef = useRef(columnAccessMap)
  columnAccessMapRef.current = columnAccessMap

  const columnsColDefs = useMemo<ColDef<ColumnAccessRow>[]>(
    () => [
      {
        field: 'access',
        hide: true,
        filter: false,
      },
      {
        field: 'list_id',
        headerName: 'List Name',
        rowGroup: true,
        hide: true,
        filter: true,
        valueFormatter({ value }) {
          return cleanupListName(value)
        },
        filterParams: {
          valueFormatter: ({ value }) => cleanupListName(value),
        } as ISetFilterParams,
      },
      {
        field: 'name',
        headerName: 'Name',
        filter: false,
        valueFormatter({ value }) {
          return removePrefixFromName(cleanupListName(value))
        },
      },
      {
        field: 'allowed',
        headerName: 'Show',
        filter: false,
        aggFunc: (params: IAggFuncParams<ColumnAccessRow>) => {
          if (params.values.every((v) => v === params.values[0])) {
            return params.values[0] === true
              ? true
              : params.values[0] === false
              ? false
              : params.values[0]
          }
          return null
        },
        cellRenderer: (params: ICellRendererParams<ColumnAccessRow>) =>
          params.data?.access && params.data?.id ? (
            <StyledToggle>
              <ColumnMaskToggle
                value={params.data.access}
                onChange={(newValue) => {
                  if (!newValue) {
                    return
                  }
                  onColumnAccessChangeRef.current(
                    params.data?.id ?? '',
                    newValue as ColumnAccessType,
                  )

                  params.api.redrawRows({
                    rowNodes: [params.node],
                  })

                  params.node.setExpanded(newValue === 'conditional')
                }}
              />
            </StyledToggle>
          ) : null,
        onCellClicked: (params) => {
          params.node.setExpanded(
            columnAccessMapRef.current[params.data?.id ?? ''].access ===
              'conditional',
          )
        },
        width: 180,
      },
    ],
    [],
  )

  const detailCellRendererParams = useMemo<
    Partial<IDetailCellRendererParams<ColumnAccessRow, ColumnAccessRow>>
  >(
    () => ({
      refreshStrategy: 'nothing',
      detailGridOptions: {
        columnDefs: [
          { field: 'value', headerName: 'Value' },
          { field: 'access', hide: true },
        ],
        detailCellRendererParams: {
          flex: 1,
        },
      },
    }),
    [],
  )

  const rowData = useMemo<ColumnAccessRow[]>(
    () =>
      allColumns
        .filter((column) => column.restriction_audience !== null)
        .map((column) => ({
          id: column.id,
          name: column.name,
          list_id: column.list_id,
          access: columnAccessMap[column.id]?.access ?? 'never',
        })),
    [allColumns, columnAccessMap],
  )

  const contextValue = useMemo<ColumnAccessContextType>(
    () => ({
      dimensions: allDimensions,
      columnAccessMap,
    }),
    [allDimensions, columnAccessMap],
  )

  if (loading) {
    return <CenteredCircularProgress />
  }

  return (
    <ColumnAccessContext.Provider value={contextValue}>
      <AgGridThemeFintasticWrapper sx={defaultGridStyles}>
        {() => (
          <AgGridReact<ColumnAccessRow>
            ref={gridRef}
            rowData={rowData}
            masterDetail={true}
            columnDefs={columnsColDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            detailCellRenderer={DimensionAccessGridWrapper}
            detailCellRendererParams={detailCellRendererParams}
            sideBar={sidebarDef}
            groupDisplayType="groupRows"
            suppressAggFuncInHeader
          ></AgGridReact>
        )}
      </AgGridThemeFintasticWrapper>
    </ColumnAccessContext.Provider>
  )
}

const DimensionAccessGridWrapper = React.forwardRef<
  unknown,
  ICellRendererParams<ColumnAccessRow>
>(({ data }, ref) => {
  const contextData = useContext(ColumnAccessContext)
  const { onColumnMaskChange } = useColumnRestriction()

  const handleDimensionChange = useCallback(
    (restrictions: DimensionMap) => {
      onColumnMaskChange(data?.id ?? '', restrictions)
    },
    [data?.id, onColumnMaskChange],
  )

  useImperativeHandle(ref, () => ({
    refresh() {
      // We never need to remount master detail
      // in case of parent table row data update
      return true
    },
  }))

  return (
    <DimensionAccessGrid
      masking
      dimensions={useMemo(
        () =>
          contextData.dimensions.filter(
            (dim) => !data?.id.includes(dim.dimension),
          ),
        [contextData.dimensions, data?.id],
      )}
      restrictions={useMemo(
        () => contextData.columnAccessMap[data?.id || '']?.dimensions || {},
        [contextData.columnAccessMap, data?.id],
      )}
      onChange={handleDimensionChange}
    />
  )
})

const sidebarDef: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: '',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
}

type ColumnAccessRow = {
  id: string
  name: string
  list_id: string
  access: ColumnAccessType
}

const defaultGridStyles = {
  height: '100%',
  width: '100%',
}
