import { Maybe } from '@fintastic/shared/util/types'
import { MetricMetadata } from '@fintastic/web/util/metrics-and-lists'
import {
  ChartColumnData,
  ChartDecimalPlaces,
  ChartGridMode,
  ChartLegendFormat,
  ChartLegendPosition,
  ChartNumberFormat,
  ChartTheme,
  ChartType,
  ChartXAxisFormat,
  MetricChartDimensions,
} from '@fintastic/web/feature/charts'
import { ChartXAxisAlignment } from './chart-x-axis-types'

export type ChartDisplaySettings = {
  type?: ChartType
  theme?: ChartTheme
  legendPosition?: ChartLegendPosition
  legendFormat?: ChartLegendFormat
  xAxisAlignment?: ChartXAxisAlignment
  xAxisFormat?: ChartXAxisFormat
  gridMode?: ChartGridMode
  numberFormat?: ChartNumberFormat
  decimalPlaces?: ChartDecimalPlaces
}

export type ChartDataSettings = {
  dimensions?: MetricChartDimensions
}

export type ChartSettings = ChartDisplaySettings & ChartDataSettings

export type MergedMetricsData = {
  data: ChartColumnData
  metadata: Maybe<MetricMetadata>
}

export type WidgetWithChartSettings = {
  chartSettings?: ChartSettings
}

export function isWidgetWithChartSettings(
  value: Record<string, unknown>,
): value is WidgetWithChartSettings {
  return typeof value.chartSettings !== 'undefined'
}
