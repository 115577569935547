import React from 'react'
import {
  ChartXAxisAlignmentSelect,
  ChartXAxisFormatSwitch,
  defaultChartXAxisAlignment,
  defaultChartXAxisFormat,
  useChartSettingsEditContext,
} from '@fintastic/web/feature/charts'

export const DialogSectionXAxis: React.FC = () => {
  const { localSettings, handleSettingsChange } = useChartSettingsEditContext()

  return (
    <>
      <ChartXAxisAlignmentSelect
        xAxisAlignment={
          localSettings.xAxisAlignment || defaultChartXAxisAlignment
        }
        onChangeChartSettings={handleSettingsChange}
      />
      <ChartXAxisFormatSwitch
        xAxisFormat={localSettings.xAxisFormat || defaultChartXAxisFormat}
        onChangeChartSettings={handleSettingsChange}
      />
    </>
  )
}
