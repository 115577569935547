import React from 'react'
import { createSvgIcon } from '@mui/material'

export const CalendarIcon = createSvgIcon(
  <>
    <path
      d="M5.50134 5.5H18.5013C18.5013 5.5 19.5013 5.5 19.5013 6.5V17.5C19.5013 17.5 19.5013 18.5 18.5013 18.5H5.50134C5.50134 18.5 4.50134 18.5 4.50134 17.5V6.5C4.50134 6.5 4.50134 5.5 5.50134 5.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.50134 9.5H19.5013"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.50134 7V3.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5013 7V3.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Calendar',
)
