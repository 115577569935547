import { useMemo } from 'react'
import { useFeatureConfig } from '../ducks/config/hooks'
import { compact } from 'lodash'

export const useWhitelistedUsersList = (): string[] => {
  const whitelistedUsersList = useFeatureConfig('support_emails').value || ''
  return useMemo(
    () =>
      (whitelistedUsersList || '')?.length
        ? compact(
            whitelistedUsersList
              .split(',')
              .map((s) => (s || '').trim().toLocaleLowerCase()),
          )
        : [],
    [whitelistedUsersList],
  )
}
