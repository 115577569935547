import { useCallback, useMemo, useRef } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { Filter, FilterValue, FilterModel } from '../types'
import { filterUtils } from '../utils'
import { FilterContextValue } from './FilterContext'
import { DEFAULT_FILTERS_VALUE_MODEL } from './default-filter-model'
import { useFilterContextValueModels } from './useFilterContextValueModels'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'

export const useFilterContextValue = ({
  filters,
  versionId,
  localStorageKey,
  onBeforeFilterApply,
}: UseFilterContextValueParams): FilterContextValue => {
  const { model, appliedModel, setModel, setAppliedModel } =
    useFilterContextValueModels(localStorageKey, filters)
  const periodSelection = usePeriodSelectorContext()

  const setFilterValue = useCallback(
    (
      filterId: string,
      timeDimensionValueId: Maybe<string>,
      value?: FilterValue,
    ) => {
      const f = filters.find(({ id }) => id === filterId)

      if (!f) {
        return
      }

      setModel((prev) =>
        filterUtils.addValueToModel(prev, f, timeDimensionValueId, value, {
          filters,
          time_dimension_id: periodSelection.aggregationDimensionId,
        }),
      )
    },
    [filters, periodSelection.aggregationDimensionId, setModel],
  )

  const resetFilter = useCallback(
    (filterId: string, timeDimensionValueId: Maybe<string>) => {
      const f = filters.find(({ id }) => id === filterId)

      if (!f) {
        return
      }

      const currentAppliedValue = filterUtils.getValueFromTheModel(
        appliedModel,
        filterId,
        timeDimensionValueId,
      )
      setModel((prev) =>
        filterUtils.addValueToModel(
          prev,
          f,
          timeDimensionValueId,
          currentAppliedValue,
          {
            filters,
            time_dimension_id: periodSelection.aggregationDimensionId,
          },
        ),
      )
    },
    [appliedModel, filters, periodSelection.aggregationDimensionId, setModel],
  )

  const filterModelRef = useRef(model)
  filterModelRef.current = model

  const apply = useCallback(() => {
    onBeforeFilterApply(filterModelRef.current)
    setAppliedModel(filterModelRef.current)
  }, [onBeforeFilterApply, setAppliedModel])

  const reset = useCallback(() => {
    const emptyModel = filterUtils.cleanUpModel(DEFAULT_FILTERS_VALUE_MODEL, {
      time_dimension_id: periodSelection.aggregationDimensionId,
      filters,
    })
    onBeforeFilterApply(emptyModel)
    setAppliedModel(emptyModel)
    setModel(emptyModel)
  }, [
    filters,
    onBeforeFilterApply,
    periodSelection.aggregationDimensionId,
    setAppliedModel,
    setModel,
  ])

  return useMemo<FilterContextValue>(() => {
    const dirty = !filterUtils.areFilterModelsEqual(model, appliedModel)

    return {
      versionId,
      filters,
      model,
      appliedModel,
      setFilterValue,
      resetFilter,
      apply,
      reset,
      dirty,
      filterIsApplied: !filterUtils.isFilterEmpty(
        filters,
        appliedModel.filterModelValues,
      ),
    }
  }, [
    filters,
    model,
    appliedModel,
    versionId,
    setFilterValue,
    resetFilter,
    apply,
    reset,
  ])
}

export type UseFilterContextValueParams = {
  versionId: string
  localStorageKey: string
  filters: Filter[]
  onBeforeFilterApply: (nextModel: FilterModel) => void
}
