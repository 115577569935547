import React, { useCallback, useMemo } from 'react'
import { CommonPeriodSelectionPickerProps } from '../types'
import {
  Period,
  PeriodsList,
  isRangeBasedSelection,
  rangeFromChangedFrom,
  rangeFromChangedTo,
} from '@fintastic/web/util/period-selector'
import { useEditingState } from './editing-state'
import {
  StyledAggregationSection,
  StyledAggregationSectionError,
  StyledDatePickers,
  StyledFooter,
  StyledFooterLeft,
  StyledFooterRight,
  StyledPeriods,
  StyledPeriodSection,
  StyledRangeToggle,
  StyledRoot,
} from './PeriodSelectionPicker.styled'
import { Section } from './Section'
import { ResolutionPicker } from './ResolutionPicker'
import { Switch, FormControlLabel, Divider } from '@mui/material'
import { toMaybe } from '@fintastic/shared/util/types'
import { PeriodsPicker } from './PeriodsPicker'
import { RangePicker } from './RangePicker'
import { Button } from '@mui/material'
import { ValidationError } from './ValidationError/ValidationError'
import { RecentSelectionsPicker } from './RecentSelectionsPicker'
import { createPeriodLabelsResolver } from '../utils/createPeriodLabelsResolver'
import { getMaximumAllowedPeriodsLength } from '../utils/getMaximumAllowedPeriodsLength'
import { getSelectedPeriodsLength } from '../utils/getSelectedPeriodsLength'
import { DimensionId } from '@fintastic/web/util/dimensions'

export type PeriodSelectionPickerProps = CommonPeriodSelectionPickerProps & {
  onRequestReset?: () => void
  onCancel?: () => void
}

export const PeriodSelectionPicker: React.FC<PeriodSelectionPickerProps> = ({
  value,
  onChange,
  onCancel,
  config,
  onRequestReset,
}) => {
  const { dispatch, actions, state } = useEditingState(value)
  const selection = state.selection
  const isRangeMode = isRangeBasedSelection(selection)

  const resolvePeriodLabel = useMemo(
    () => createPeriodLabelsResolver(config),
    [config],
  )

  const periodConfig = useMemo(
    () =>
      toMaybe(
        config.periodTypes.find(
          (p) => p.dimension_id === selection.dimensionId,
        ),
      ),
    [config.periodTypes, selection.dimensionId],
  )

  const aggregationConfig = useMemo(
    () =>
      toMaybe(
        config.aggregationTypes.find(
          (p) => p.dimension_id === selection.aggregationDimensionId,
        ),
      ),
    [config.aggregationTypes, selection.aggregationDimensionId],
  )

  const handleChangePeriodType = useCallback(
    (p: DimensionId) => {
      if (isRangeMode) {
        dispatch(
          actions.changePeriodType({
            dimension_id: p,
            range: config.newPeriodsRangeGetter(p, selection),
          }),
        )
      } else {
        dispatch(
          actions.changePeriodType({
            dimension_id: p,
            periods: config.newPeriodsListGetter(p, selection),
          }),
        )
      }
    },
    [actions, config, dispatch, isRangeMode, selection],
  )

  const handleToggleRangeMode = useCallback(() => {
    if (isRangeMode) {
      dispatch(
        actions.turnOffRange({
          periods: config.newPeriodsListGetter(
            selection.dimensionId,
            selection,
          ),
        }),
      )
    } else {
      dispatch(
        actions.turnOnRange({
          range: config.newPeriodsRangeGetter(selection.dimensionId, selection),
        }),
      )
    }
  }, [actions, config, dispatch, isRangeMode, selection])

  const handleChangeAggregationType = useCallback(
    (a: DimensionId) => {
      dispatch(actions.changeAggregationType(a))
    },
    [actions, dispatch],
  )

  const handleAddPeriods = useCallback(
    (periods: PeriodsList) => {
      dispatch(actions.addPeriods(periods))
    },
    [actions, dispatch],
  )

  const handleRemovePeriods = useCallback(
    (periods: PeriodsList) => {
      dispatch(actions.removePeriods(periods))
    },
    [actions, dispatch],
  )

  const handleChangeRangeFrom = useCallback(
    (from: Period) => {
      if (!('range' in selection)) {
        return
      }
      const newRange = rangeFromChangedFrom(
        selection.range,
        from,
        (periodConfig?.periods || []).map(({ id }) => id),
      )
      if (!newRange) {
        return
      }
      dispatch(actions.changeRange({ range: newRange }))
    },
    [actions, dispatch, periodConfig?.periods, selection],
  )

  const handleChangeRangeTo = useCallback(
    (to: Period) => {
      if (!('range' in selection)) {
        return
      }
      const newRange = rangeFromChangedTo(
        selection.range,
        to,
        (periodConfig?.periods || []).map(({ id }) => id),
      )
      if (!newRange) {
        return
      }
      dispatch(actions.changeRange({ range: newRange }))
    },
    [actions, dispatch, periodConfig?.periods, selection],
  )

  const periodsOptions = useMemo<
    Array<{ label: string; value: Period; disabled?: boolean }>
  >(
    () =>
      periodConfig?.periods.map((p) => ({
        label: p.label,
        value: p.id,
        disabled: p.disabled,
      })) || [],
    [periodConfig?.periods],
  )

  const currentResolution = useMemo(
    () =>
      config.periodTypes.find(
        (i) => i.dimension_id === state.selection.aggregationDimensionId,
      )?.resolution ?? null,
    [config.periodTypes, state.selection.aggregationDimensionId],
  )

  const maximumPeriodsLength = getMaximumAllowedPeriodsLength(
    config,
    selection.dimensionId,
    currentResolution,
  )
  const currentPeriodsLength = useMemo(
    () => getSelectedPeriodsLength(config, selection),
    [config, selection],
  )

  const tooManyPeriods = maximumPeriodsLength
    ? currentPeriodsLength > maximumPeriodsLength
    : false

  const isValid = useMemo(() => {
    if (tooManyPeriods) {
      return false
    }

    if (aggregationConfig?.disabled) {
      return false
    }

    if (!currentResolution) {
      return false
    }

    if (isRangeMode) {
      return selection.range[0] && selection.range[1]
    }

    return selection.periods.length > 0
  }, [
    aggregationConfig?.disabled,
    isRangeMode,
    selection,
    tooManyPeriods,
    currentResolution,
  ])

  const handleApplyValue = useCallback(() => {
    onChange(state.selection)
  }, [onChange, state.selection])

  return (
    <StyledRoot>
      <StyledPeriodSection>
        <Section title="Period">
          <ResolutionPicker
            value={selection.dimensionId}
            onChange={handleChangePeriodType}
            options={config.periodTypes}
          />
          <StyledPeriods>
            <StyledDatePickers>
              {!isRangeMode && (
                <PeriodsPicker
                  options={periodsOptions}
                  values={selection.periods}
                  onAddPeriods={handleAddPeriods}
                  onRemovePeriods={handleRemovePeriods}
                  disabled={false}
                />
              )}
              {isRangeMode && (
                <RangePicker
                  options={periodsOptions}
                  value={selection.range}
                  onChangeFrom={handleChangeRangeFrom}
                  onChangeTo={handleChangeRangeTo}
                  disabled={false}
                />
              )}
            </StyledDatePickers>
            <StyledRangeToggle>
              <FormControlLabel
                control={
                  <Switch
                    checked={isRangeMode}
                    onClick={handleToggleRangeMode}
                    sx={{ ml: 0.5 }}
                  />
                }
                labelPlacement="start"
                label="Range"
                componentsProps={{
                  typography: {
                    variant: 'body2',
                  },
                }}
              />
            </StyledRangeToggle>
          </StyledPeriods>
        </Section>
      </StyledPeriodSection>
      <StyledAggregationSection>
        <Section title="Show by">
          <ResolutionPicker
            value={selection.aggregationDimensionId}
            onChange={handleChangeAggregationType}
            options={config.aggregationTypes}
          />
        </Section>
        {tooManyPeriods && (
          <StyledAggregationSectionError>
            <ValidationError>
              To show by {aggregationConfig?.label.toLocaleLowerCase()}, reduce
              period length to {maximumPeriodsLength}
            </ValidationError>
          </StyledAggregationSectionError>
        )}
      </StyledAggregationSection>
      <Divider />
      <StyledFooter>
        <StyledFooterLeft>
          {config.recentSelections.length > 0 && (
            <RecentSelectionsPicker
              recentSelections={config.recentSelections}
              onSelect={onChange}
              resolvePeriodLabel={resolvePeriodLabel}
            />
          )}
        </StyledFooterLeft>
        <StyledFooterRight>
          {onRequestReset && (
            <Button
              color="primary"
              variant="text"
              sx={{ ml: 1, textTransform: 'uppercase' }}
              onClick={onRequestReset}
            >
              Reset
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            sx={{ ml: 1 }}
            color="primary"
            variant="contained"
            onClick={handleApplyValue}
          >
            Apply
          </Button>
        </StyledFooterRight>
      </StyledFooter>
    </StyledRoot>
  )
}
