import { ColDef, ColumnApi, GridApi } from 'ag-grid-community'
import { HeaderCell } from './header-utils'

export type GetGroupHeaderNamesParams = {
  api: GridApi
  columnApi: ColumnApi
  maxGroupLevel: number
  sortedGroupNames?: Array<string>
}

export function getGroupHeaderRow({
  api,
  columnApi,
  maxGroupLevel,
  sortedGroupNames,
}: GetGroupHeaderNamesParams): HeaderCell[] {
  const columns = columnApi.getAllDisplayedColumns()
  // get first group, only one yet (?) allowed
  const groupColumn = columns?.find((c) => c.getColDef().showRowGroup)

  const groupHeaders: HeaderCell[] = []
  if (!groupColumn) {
    // no grouping, flat table - exit
    return groupHeaders
  }

  const defs = api.getColumnDefs()
  const groupDefs = defs
    ?.filter((c: ColDef) => c.rowGroup)
    .sort(
      (a: ColDef, b: ColDef) =>
        (a.rowGroupIndex ?? -1) - (b.rowGroupIndex ?? -1),
    )

  if (!groupDefs || (groupDefs && groupDefs.length === 0)) {
    if (sortedGroupNames) {
      sortedGroupNames.forEach((name) =>
        groupHeaders.push({
          label: name || '<empty>',
          calculated: false,
          isGroupingHeader: true,
          type: 'generic',
        }),
      )
    } else {
      // metric?
      const groupColumns = columnApi.getRowGroupColumns()

      groupColumns.forEach((column) => {
        groupHeaders.push({
          label: column.getColDef().headerName || '',
          calculated: false,
          isGroupingHeader: true,
          type: 'generic',
        })
      })

      if (groupHeaders.length === 0) {
        // fallback of fallback
        groupHeaders.push({
          label: '',
          calculated: false,
          isGroupingHeader: true,
          type: 'generic',
        })
      }
    }
  } else {
    groupDefs?.forEach((c) => {
      groupHeaders.push({
        label: c.headerName || '<empty>',
        calculated: false,
        isGroupingHeader: true,
        type: 'generic',
      })
    })
  }

  if (groupHeaders.length === 0) {
    throw new Error(
      'Cannot process headers: neither external not internal data available',
    )
  }
  return groupHeaders.slice(0, maxGroupLevel + 1)
}
