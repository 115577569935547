import { useMemo } from 'react'
import { useAuthUserInfo } from '../ducks/auth/hooks'
import { getTenantOverrideHeader } from '@fintastic/web/data-access/service-auth0'

export function useAuthTenantName() {
  const userInfo = useAuthUserInfo()
  const tenantOverrideHeader = getTenantOverrideHeader()
  const tenantOverride = useMemo(
    () => tenantOverrideHeader[Object.keys(tenantOverrideHeader)[0]],
    [tenantOverrideHeader],
  )

  return useMemo(
    () =>
      tenantOverride ||
      userInfo?.tenant_label ||
      userInfo?.tenant_id ||
      'Fintastic',
    [tenantOverride, userInfo?.tenant_id, userInfo?.tenant_label],
  )
}
