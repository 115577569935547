import React, { useMemo } from 'react'
import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotsComponentsProps,
} from '@mui/x-date-pickers'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type CustomisedMuiDatePickerProps<TDate> = DatePickerProps<TDate>

const defaultSlotProps: Partial<DatePickerSlotsComponentsProps<unknown>> = {
  openPickerButton: {
    color: 'textBlack',
  },
  switchViewButton: {
    color: 'textBlack',
  },
}

export const CustomisedMuiDatePicker = <TDate,>(
  props: CustomisedMuiDatePickerProps<TDate>,
): JSX.Element => {
  const { slotProps, ...forwardedProps } = props

  const mergedSlotProps = useMemo(
    () => ({
      ...slotProps,
      openPickerButton: {
        ...defaultSlotProps.openPickerButton,
        ...slotProps?.openPickerButton,
      },
      switchViewButton: {
        ...defaultSlotProps.switchViewButton,
        ...slotProps?.switchViewButton,
      },
    }),
    [slotProps],
  )

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <DatePicker {...forwardedProps} slotProps={mergedSlotProps} />
    </FintasticThemeProvider>
  )
}
