export function capitalizeFirstLetter(str?: string) {
  if (!str || str.length === 1) {
    return (str || '').toLocaleUpperCase()
  }

  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase()
}

export function replaceUnderscores(str?: string) {
  return (str || '').replaceAll('_', ' ')
}

export function normalizeTitle(str?: string) {
  return capitalizeFirstLetter(replaceUnderscores(str))
}
