import React from 'react'
import {
  FilterByDate,
  FilterDateRange,
  FilterValue,
} from '@fintastic/web/util/filters'
import { RangedDateField } from './RangedDateField'
import { NormalDateField } from './NormalDateField'
import { RangedFilterInput } from '../shared/RangedFilterInput'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export const DateFilterInput: React.FC<DateFilterInputProps> = ({
  filterValue,
  onChange,
  disableClear,
  filter,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <RangedFilterInput<string, FilterDateRange>
      filterValue={filterValue}
      onChange={onChange}
      RangeFieldComponent={RangedDateField}
      NormalFieldComponent={NormalDateField}
      disableClear={disableClear ?? false}
      filter={filter}
    />
  </LocalizationProvider>
)

export type DateFilterInputProps = {
  filterValue: Partial<FilterValue<string | FilterDateRange>>
  onChange: (nextValue: FilterValue<string | FilterDateRange>) => void
  disableClear?: boolean
  filter: FilterByDate
}
