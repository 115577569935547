import { ThemeComponents } from './types'

export const MuiChip: ThemeComponents['MuiChip'] = {
  styleOverrides: {
    root: {
      color: '#332E2A',
      borderRadius: 4,
      '&.MuiChip-filled.MuiChip-colorDefault.MuiChip-filledDefault': {
        backgroundColor: '#FAF8F7'
      },
      '& .MuiChip-deleteIcon': {
        width: 16,
        height: 16
      },
      '& .MuiChip-avatarSmall': {
        width: 16,
        height: 16
      },
      '& .MuiChip-label': {
        padding: '0 6px'
      }
    },
  },
}
