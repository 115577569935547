import {
  useLayoutStateIsSidebarOpened,
  useLayoutStateSidebarSubmenu,
} from '@fintastic/shared/ui/app-layout-framework'
import { useEffect, useRef } from 'react'
import { BOARDS_SIDEBAR_BAR_ITEM_KEY } from '../const'
import { useBoardPageParams } from './useBoardPageParams'
import { useBoardsListQuery } from './useBoardsListQuery'
import { useNavigate } from 'react-router-dom'
import { boardRoutes } from '../routes'
import { Maybe } from '@fintastic/shared/util/types'

export function useOpenDefaultBoard() {
  const [isSidebarSubmenuOpened] = useLayoutStateIsSidebarOpened()
  const [sidebarSubmenu] = useLayoutStateSidebarSubmenu()
  const boards = useBoardsListQuery()
  const navigate = useNavigate()

  const { id: boardId } = useBoardPageParams()
  const prevBoardRef = useRef<Maybe<string>>(boardId || null)

  useEffect(() => {
    if (
      sidebarSubmenu === BOARDS_SIDEBAR_BAR_ITEM_KEY &&
      isSidebarSubmenuOpened
    ) {
      const prevBoard = prevBoardRef.current || boards.data?.[0]?.id || null
      if (!boardId && prevBoard) {
        navigate(boardRoutes.board(prevBoard))
      }
    }
  }, [sidebarSubmenu, isSidebarSubmenuOpened, boards.data, boardId, navigate])

  useEffect(() => {
    if (boardId) {
      prevBoardRef.current = boardId
    }
  }, [boardId])
}
