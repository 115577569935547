import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { PeriodSelectionPickerConfig } from '../types'
import {
  StyledAggregationButton,
  StyledAggregationButtonIcon,
  StyledAggregationButtonText,
  StyledAggregationMenu,
  StyledMain,
  StyledMainButton,
  StyledMainButtonIcon,
  StyledRoot,
  StyledSecondary,
} from './PeriodSelectionStatusPanel.styled'
import { createPeriodLabelsResolver } from '../utils/createPeriodLabelsResolver'
import { Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { getMaximumAllowedPeriodsLength } from '../utils/getMaximumAllowedPeriodsLength'
import { getSelectedPeriodsLength } from '../utils/getSelectedPeriodsLength'
import { Maybe } from '@fintastic/shared/util/types'
import { ArrowDropDown } from '@mui/icons-material'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { CalendarIcon } from '@fintastic/shared/ui/icons'
import { capitalizeFirstLetter } from '@fintastic/shared/util/formatters'

export type PeriodSelectionStatusPanelProps = {
  config: PeriodSelectionPickerConfig
  value: PeriodSelection
  onChange: (v: PeriodSelection) => void
  onRequestPickerOpen: () => void
}

export const PeriodSelectionStatusPanel = forwardRef<
  HTMLDivElement,
  PeriodSelectionStatusPanelProps
>(({ config, onRequestPickerOpen, value, onChange }, ref) => {
  const aggButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const [aggMenuOpen, setAggMenuOpen] = useState(false)

  const resolvePeriodLabel = useMemo(
    () => createPeriodLabelsResolver(config),
    [config],
  )

  const currentPeriodsLength = useMemo(
    () => getSelectedPeriodsLength(config, value),
    [config, value],
  )

  const currentAggregation = useMemo(
    () =>
      config.aggregationTypes.find(
        (a) => value.aggregationDimensionId === a.dimension_id,
      ),
    [config.aggregationTypes, value.aggregationDimensionId],
  )

  const handleSelectNewAggregationType = useCallback(
    (aggregationDimensionId: DimensionId) => {
      setAggMenuOpen(false)
      onChange({
        ...value,
        aggregationDimensionId,
      })
    },
    [onChange, value],
  )

  return (
    <StyledRoot ref={ref}>
      <StyledMain>
        <StyledMainButton
          onClick={onRequestPickerOpen}
          data-testid="top-period-selector-period"
        >
          <CalendarIcon
            fontSize="medium"
            sx={{ marginRight: (theme) => theme.spacing(0.5) }}
          />
          {'range' in value ? (
            <Typography>
              {resolvePeriodLabel(value.range[0])}
              &nbsp;-&nbsp;
              {resolvePeriodLabel(value.range[1])}
            </Typography>
          ) : (
            <Typography>
              {resolvePeriodLabel(value.periods[0])}
              {value.periods.length > 1 && (
                <i>&nbsp;(+{value.periods.length - 1})</i>
              )}
            </Typography>
          )}
          <StyledMainButtonIcon>
            <ArrowDropDown fontSize="small" />
          </StyledMainButtonIcon>
        </StyledMainButton>
      </StyledMain>
      <StyledSecondary>
        <StyledAggregationButton
          ref={aggButtonRef}
          onClick={() => setAggMenuOpen(true)}
          opened={aggMenuOpen}
        >
          <StyledAggregationButtonText>
            <Typography>By: {currentAggregation?.label}</Typography>
          </StyledAggregationButtonText>
          <StyledAggregationButtonIcon className="icon">
            <ArrowDropDown fontSize="small" />
          </StyledAggregationButtonIcon>
        </StyledAggregationButton>
        <StyledAggregationMenu
          open={aggMenuOpen}
          onClose={() => setAggMenuOpen(false)}
          anchorEl={aggButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {config.aggregationTypes.map((a) => {
            const maximumPeriodsLength = getMaximumAllowedPeriodsLength(
              config,
              value.dimensionId,
              a.resolution,
            )
            const tooManyPeriods = maximumPeriodsLength
              ? currentPeriodsLength > maximumPeriodsLength
              : false

            return (
              <MenuItem
                key={a.dimension_id}
                onClick={() => handleSelectNewAggregationType(a.dimension_id)}
                dense={true}
                disabled={tooManyPeriods}
              >
                <Typography variant="body2">
                  {capitalizeFirstLetter(a.label)}
                </Typography>
              </MenuItem>
            )
          })}
        </StyledAggregationMenu>
      </StyledSecondary>
    </StyledRoot>
  )
})
