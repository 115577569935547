export const removeListPrefixFromListId = (name: string) =>
  (name || '').replace('Table.', '')

export const cleanupListName = (name: string) =>
  removeListPrefixFromListId((name || '')).replaceAll('_', ' ')

export const cleanupDimensionName = (name: string) =>
  (name || '').replace('Dim.', '').replaceAll('_', ' ')

export const removePrefixFromName = (cleanedUp: string) => {
  const [prefix, value] = cleanedUp.split('.')

  return value ? value : prefix
}
