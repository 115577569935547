import React, { useCallback, useMemo, useState } from 'react'
import {
  DimensionsView,
  settingsPanelNavigationPaths,
  useReportSettingsPanelNavigationContext,
} from '@fintastic/web/feature/report-editor'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { cloneDeep } from 'lodash'

type Dimension = {
  id: DimensionId
  label: string
  visible: boolean
  linked: boolean
  error: Maybe<string>
}

const allDimsList: Array<{ id: DimensionId; label: string }> = [
  {
    id: 'Dim.Country',
    label: 'Country',
  },
  {
    id: 'Dim.Department',
    label: 'Department',
  },
  {
    id: 'Dim.Region',
    label: 'Region',
  },
  {
    id: 'Dim.Team',
    label: 'Team',
  },
  {
    id: 'Dim.Product',
    label: 'Product',
  },
  {
    id: 'Dim.Color',
    label: 'Color',
  },
]

// @todo RESP connect
export const DimensionsViewConnector: React.FC = () => {
  const { goTo, goBack } = useReportSettingsPanelNavigationContext()
  const goToDimension = useCallback(
    (id: string) => goTo(settingsPanelNavigationPaths.dimensionSettings(id)),
    [goTo],
  )

  const [dimensions, setDimensions] = useState<Dimension[]>([
    {
      id: 'Dim.Country',
      label: 'Country',
      visible: true,
      linked: false,
      error: null,
    },
    {
      id: 'Dim.Department',
      label: 'Department',
      visible: false,
      linked: true,
      error: null,
    },
    {
      id: 'Dim.Region',
      label: 'Region',
      visible: true,
      linked: true,
      error: 'Some error text',
    },
  ])

  const availableDimensions = useMemo(
    () => allDimsList.filter((d) => !dimensions.find((d2) => d2.id === d.id)),
    [dimensions],
  )

  const addDimension = useCallback(
    (dimId: DimensionId) => {
      const dim = availableDimensions.find((d) => d.id === dimId)
      if (!dim) {
        return
      }

      setDimensions((s) => [
        ...s,
        {
          ...dim,
          visible: true,
          linked: false,
          error: null,
        },
      ])
    },
    [availableDimensions],
  )

  const toggleDimensionVisibility = useCallback((dimId: DimensionId) => {
    setDimensions((dims) => {
      const newDims = cloneDeep(dims)
      const dimIndex = newDims.findIndex((d) => d.id === dimId)
      newDims[dimIndex].visible = !newDims[dimIndex].visible
      return newDims
    })
  }, [])

  const reorderDimensions = useCallback((dimIds: DimensionId[]) => {
    setDimensions((dims) => {
      const newDims = cloneDeep(dims)
      newDims.sort((a, b) => dimIds.indexOf(a.id) - dimIds.indexOf(b.id))
      return newDims
    })
  }, [])

  return (
    <DimensionsView.Editable
      goBack={goBack}
      goToDimension={goToDimension}
      dimensions={dimensions}
      availableDimensions={availableDimensions}
      addDimension={addDimension}
      toggleDimensionVisibility={toggleDimensionVisibility}
      reorderDimensions={reorderDimensions}
    />
  )
}
