import React from 'react'
import { TimeResolutionOption } from './types'
import { StyledButton, StyledRoot } from './ResolutionPicker.styled'
import { Typography } from '@mui/material'
import { DimensionId } from '@fintastic/web/util/dimensions'

export type ResolutionPickerProps = {
  value: DimensionId
  onChange: (t: DimensionId) => void
  options: TimeResolutionOption[]
  ignoreDisabled?: boolean
}

export const ResolutionPicker: React.FC<ResolutionPickerProps> = ({
  value,
  onChange,
  options,
  ignoreDisabled
}) => (
  <StyledRoot>
    {options.map((o) => (
      <StyledButton
        active={o.dimension_id === value}
        key={o.dimension_id}
        onClick={() => onChange(o.dimension_id)}
        disabled={ignoreDisabled ? false : !!o.disabled}
        size={options.length}
      >
        <Typography
          variant="button"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {o.label}
        </Typography>
      </StyledButton>
    ))}
  </StyledRoot>
)
