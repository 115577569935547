import React from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { ImportConfigItem } from '@fintastic/web/data-access/import-configs'

export type ImportActualsConfigContextValue = {
  versionId: string
  minPossibleDate: string
  maxPossibleDate: string
  dateFrom: Maybe<string>
  dateTo: Maybe<string>
  updateDateRange: (dateFrom: string, dateTo: string) => void
  appliedConfigRef: React.MutableRefObject<ImportConfigItem[]>
  loading: boolean
  calculating: boolean
  selectedItems: ImportConfigItem[]
  setSelectedItems: (items: ImportConfigItem[]) => void
}

const stub = (): never => {
  throw new Error(
    'Cannot set value for ImportActualsConfigContext out of context',
  )
}

export const ImportActualsConfigContext =
  React.createContext<ImportActualsConfigContextValue>({
    versionId: '',
    minPossibleDate: '',
    maxPossibleDate: '',
    dateFrom: null,
    dateTo: null,
    updateDateRange: stub,
    appliedConfigRef: { current: [] },
    loading: false,
    calculating: false,
    selectedItems: [],
    setSelectedItems: stub,
  })

ImportActualsConfigContext.displayName = 'ImportActualsConfigContext'
