import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { TextField } from '@mui/material'
import {
  isNameValid,
  resolveNameValidationErrorMessage,
  validateName,
} from '../../../../../utils/metric-or-list-name-validation'
import { sanitizeName } from '@fintastic/shared/util/ag-grid'

export type NameInputProps = {
  value: string
  onChange: (newValue: string) => void
  existingNames: string[]
  readonly: boolean
  optional: boolean
}

export const NameInput: React.FC<NameInputProps> = ({
  existingNames,
  onChange,
  value,
  readonly,
}) => {
  const existingNamesMap = useMemo(
    () => Object.fromEntries(existingNames.map((name) => [name, true])),
    [existingNames],
  )
  const [validationError, setValidationError] = useState<Maybe<string>>(null)
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const handleChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (event) => {
      const sanitizedValue = sanitizeName(event.target.value)
      const validationResult = validateName(sanitizedValue, existingNamesMap)

      if (isNameValid(validationResult)) {
        setValidationError(null)
      } else {
        setValidationError(resolveNameValidationErrorMessage(validationResult))
      }

      setLocalValue(sanitizedValue)
    },
    [existingNamesMap],
  )

  const handleBlur = useCallback(() => {
    if (localValue === value) {
      return
    }
    if (validationError === null) {
      onChange(localValue)
    }
  }, [localValue, onChange, validationError, value])

  return (
    <TextField
      fullWidth
      size="small"
      disabled={readonly}
      label="Name"
      placeholder="Type name"
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!validationError}
      helperText={validationError}
    />
  )
}
