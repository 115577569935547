const requiredVariables = [
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_API_URL',
] as const

const optionalVariables = [
  'REACT_APP_AUTH0_AUDIENCE',
  'REACT_APP_AGGRID_LICENSE',
  'REACT_APP_GOJS_LICENSE',
  'REACT_APP_LOGROCKET_APP_ID',
  'REACT_APP_TENANT_LOGIN',
  'REACT_APP_TENANT_AUTH_SERVICE',
  'REACT_APP_PUSHER_APP_KEY',
  'REACT_APP_PUSHER_APP_SECRET',
  'REACT_APP_PUSHER_APP_ID',
  'REACT_APP_TENANT_OVERRIDE_LOCAL_DEV_KEY',
  'REACT_APP_NEW_APP_LAYOUT_DOMAINS',
  'REACT_APP_SENTRY_DSN',
  'REACT_APP_SENTRY_ENVIRONMENT',
] as const

const env = JSON.parse(JSON.stringify(process.env))

if (env['STORYBOOK']) {
  const fallbacks = {
    REACT_APP_AUTH0_DOMAIN: ['STORYBOOK_AUTH0_DOMAIN'],
    REACT_APP_API_URL: ['STORYBOOK_API_URL'],
    REACT_APP_AGGRID_LICENSE: ['STORYBOOK_AGGRID_LICENSE'],
  }
  Object.entries(fallbacks).forEach(([envVar, fallbackVars]) => {
    if (env[envVar] !== undefined) {
      return
    }
    for (const fallbackVar of fallbackVars) {
      if (env[fallbackVar] !== undefined) {
        env[envVar] = env[fallbackVar]
        return
      }
    }
  })
}

export const isProduction = env['NODE_ENV'] === 'production'

export const environment = Object.fromEntries(
  [...optionalVariables, ...requiredVariables].map((variableName) => {
    if (
      requiredVariables.includes(
        variableName as typeof requiredVariables[number],
      ) &&
      !env[`${variableName}`]
    ) {
      throw new Error(`${variableName} is required but not defined`)
    }

    return [variableName, env[`${variableName}`]]
  }),
) as Record<
  typeof requiredVariables[number] | typeof optionalVariables[number],
  string
>
