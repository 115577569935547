import React from 'react'
import { Avatar } from '../Avatar'
import {
  StyledUserPanelAvatarCont,
  StyledUserPanelEmail,
  StyledUserPanelName,
  StyledUserPanelRoot,
  StyledUserPanelText,
} from './UserPanel.styled'
import { SxProps } from '@mui/system'

export type UserPanelProps = {
  name?: string
  email?: string
  avatarSrc?: string
  disabled?: boolean
  sx?: SxProps
}

export const UserPanel: React.FC<UserPanelProps> = ({
  name,
  email,
  avatarSrc,
  disabled,
  sx,
}) => (
  <StyledUserPanelRoot sx={sx}>
    <StyledUserPanelAvatarCont>
      <Avatar
        size="small"
        src={avatarSrc}
        fixedColors={true}
        text={name || email || ' '}
        sx={{ bgcolor: disabled ? 'lightgray' : undefined }}
      />
    </StyledUserPanelAvatarCont>
    <StyledUserPanelText>
      {name !== undefined && <StyledUserPanelName sx={{ color: disabled ? 'gray' : undefined }}>{name}</StyledUserPanelName>}
      {email !== undefined && (
        <StyledUserPanelEmail variant="body2">{email}</StyledUserPanelEmail>
      )}
    </StyledUserPanelText>
  </StyledUserPanelRoot>
)
