import { useMemo } from 'react'
import { useDataContext } from '../../connection/data/data-context'
import { useActionsContext } from '../../connection/actions/actions-context'
import { AggregationSettingsProps } from '../../../shared/settings/aggregation-function/types'

export function useCategoryAggregation() {
  const { metric } = useDataContext()
  const {
    actions: { changeCategoryAggregation },
  } = useActionsContext()

  const rollUpFunction = metric?.categoryAggregation() || 'sum'
  const weightsMetricId = metric?.weightsMetricId() || undefined

  return useMemo<AggregationSettingsProps>(
    () => ({
      rollUpFunction,
      weightsMetricId:
        rollUpFunction === 'weighted_avg' ? weightsMetricId : undefined,
      onChange: changeCategoryAggregation,
    }),
    [changeCategoryAggregation, rollUpFunction, weightsMetricId],
  )
}
