import React from 'react'
import { Paper } from '@mui/material'
import {
  AddNewLineButtonNumberOfLinesPicker,
  AddNewLineButtonNumberOfLinesPickerProps,
} from './AddNewLineButtonNumberOfLinesPicker'

export const AddNewLineButtonDropdown: React.FC<
  AddNewLineButtonNumberOfLinesPickerProps
> = (props) => (
  <Paper elevation={1}>
    <AddNewLineButtonNumberOfLinesPicker {...props} />
  </Paper>
)
