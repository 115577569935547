import { useBoardsListQuery } from './useBoardsListQuery'
import { useBoardsFoldersListQuery } from './useBoardsFoldersListQuery'
import { useMemo } from 'react'
import {
  BoardFolder,
  BoardFoldersTree,
  BoardsFoldersTreeNode,
  BoardsFoldersTreeNodeBoard,
  BoardsFoldersTreeNodeFolder,
  BoardShortened,
} from '../types'
import { BOARD_FOLDER_ARCHIVE_ID } from '../const/board-folders'
import { Maybe } from '@fintastic/shared/util/types'

function createEmptyTree(): BoardFoldersTree {
  return {
    rootNodes: [],
  }
}

function createFolderToIndexMap() {
  const folderToNodeIndexMap: Record<BoardFolder['id'], number> = {}
  return folderToNodeIndexMap
}

function createFolderToDataMap(folders: BoardFolder[]) {
  const folderToDataMap: Record<BoardFolder['id'], BoardFolder> = {}
  folders.forEach((folder) => {
    folderToDataMap[folder.id] = folder
  })
  return folderToDataMap
}

function createArchiveFolder(): BoardsFoldersTreeNodeFolder {
  return {
    type: 'folder',
    children: [],
    data: {
      id: BOARD_FOLDER_ARCHIVE_ID,
      name: 'Archive',
    },
  }
}

function createBoardNode(board: BoardShortened): BoardsFoldersTreeNodeBoard {
  return {
    type: 'board',
    data: board,
  }
}

function createFolderNode(folder: BoardFolder): BoardsFoldersTreeNodeFolder {
  return {
    type: 'folder',
    data: folder,
    children: [],
  }
}

function addNodeToTree(tree: BoardFoldersTree, node: BoardsFoldersTreeNode) {
  tree.rootNodes.push(node)
  return tree.rootNodes.length - 1
}

function addNodeToFolder(
  folderNode: BoardsFoldersTreeNodeFolder,
  processingNode: BoardsFoldersTreeNode,
) {
  folderNode.children.push(processingNode)
  return folderNode.children.length - 1
}

function sortBoards(boards: BoardShortened[]): BoardShortened[] {
  return boards.sort((boardA, boardB) => {
    if (boardA.is_predefined === boardB.is_predefined) {
      // sort all predefined and all non-predefined boards by name ASC
      return boardA.name.localeCompare(boardB.name)
    }
    // show predefined boards first in any case
    return boardA.is_predefined && !boardB.is_predefined ? 1 : -1
  })
}

function sortTreeNodes(tree: BoardFoldersTree) {
  // put all boards first
  const newTree = createEmptyTree()
  newTree.rootNodes = tree.rootNodes.sort((nodeA, nodeB) => {
    if (nodeA.type === nodeB.type) {
      return 0
    }
    return nodeA.type === 'board' ? 1 : -1
  })
  return newTree
}

function buildFoldersTree(
  folders?: Maybe<BoardFolder[]>,
  boards?: Maybe<BoardShortened[]>,
): BoardFoldersTree {
  const tree = createEmptyTree()
  const archiveFolder = createArchiveFolder()

  if (!boards || !folders) {
    addNodeToTree(tree, archiveFolder)
    return tree
  }

  const folderToNodeIndexMap = createFolderToIndexMap()
  const folderToDataMap = createFolderToDataMap(folders)

  sortBoards(boards).forEach((board) => {
    const boardNode = createBoardNode(board)

    if (board.is_deleted) {
      addNodeToFolder(archiveFolder, boardNode)
      return
    }

    if (board.folder_id === null) {
      addNodeToTree(tree, boardNode)
      return
    }

    const folder = folderToDataMap[board.folder_id]
    if (!folder) {
      addNodeToTree(tree, boardNode)
      return
    }

    if (folderToNodeIndexMap[folder.id] === undefined) {
      folderToNodeIndexMap[folder.id] = addNodeToTree(
        tree,
        createFolderNode(folder),
      )
    }

    const folderNodeIndex = folderToNodeIndexMap[folder.id]
    addNodeToFolder(
      tree.rootNodes[folderNodeIndex] as BoardsFoldersTreeNodeFolder,
      boardNode,
    )
  })

  sortTreeNodes(tree)

  addNodeToTree(tree, archiveFolder)

  return tree
}

export function useBoardsFoldersTree() {
  const boards = useBoardsListQuery()
  const folders = useBoardsFoldersListQuery()

  const tree = useMemo(
    () => buildFoldersTree(folders.data, boards.data),
    [folders.data, boards.data],
  )

  return useMemo(
    () => ({
      tree,
      isLoading: boards.isLoading || folders.isLoading,
    }),
    [boards.isLoading, folders.isLoading, tree],
  )
}
