import { MetricOrListSource } from '@fintastic/web/util/metrics-and-lists'
import { WrappedData } from './types'

export const createEmptyListObject = (
  name: string,
  source: MetricOrListSource,
): WrappedData => ({
  id: `Table.${name}`,
  label: name,
  row_dim: `Dim.${name}_range`,
  info: null,
  source,
  metrics: [],
  metadata: {
    formula: source === 'input' ? null : '',
    base_time_dimension_id: null,
  },
})
