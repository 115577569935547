import {
  isNullish,
  Nullable,
} from '@fintastic/shared/util/functional-programming'

export type Maybe<T> = T | null

export type NullableFields<T> = { [K in keyof T]: Maybe<T[K]> }

export const toMaybe = <T>(f: Nullable<T>): Maybe<T> =>
  isNullish(f) ? null : (f as Maybe<T>)
