import {
  makeFromAxiosErrorFunction,
  WithUserFriendlyErrorText,
} from '@fintastic/shared/util/errors'

export class NetworkError
  extends Error
  implements WithUserFriendlyErrorText
{
  getUiMessage() {
    return 'Something went wrong during the saving of the changes'
  }

  constructor() {
    super('Something went wrong during the request')
    this.name = 'NetworkError'
    Object.setPrototypeOf(this, NetworkError.prototype)
  }
}

export class RuntimeError
  extends Error
  implements WithUserFriendlyErrorText
{
  getUiMessage() {
    return 'Something went wrong'
  }

  constructor(public cause: Error) {
    super(cause.message)
    this.name = 'RuntimeError'
    Object.setPrototypeOf(this, RuntimeError.prototype)
  }
}

export class BadRequestError
  extends Error
  implements WithUserFriendlyErrorText
{
  getUiMessage() {
    return 'Something went wrong (400 Bad request)'
  }

  constructor() {
    super('The validation on BE is failed')
    this.name = 'BadRequestError'
    Object.setPrototypeOf(this, BadRequestError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    this,
    (e) => e.response?.status === 400,
  )
}
