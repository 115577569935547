import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
} from '@mui/material'

export const StyledHeader = styled(
  (props: BoxProps & { size?: string }) => {
    const { size, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'size',
  },
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  width: 100%;
  height: ${({ size }) => (size === 'small' ? 48 : 64)}px;
  min-height: ${({ size }) => (size === 'small' ? 48 : 64)}px;
`

export const StyledHeaderButton = styled(
  (props: IconButtonProps & { size?: string }) => {
    const { size, ...iconButtonProps } = props
    return <IconButton {...iconButtonProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'size',
  },
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ theme, size }) => theme.spacing(size === 'small' ? 1 : 2)};
  margin-left: ${({ size }) => (size === 'small' ? 12 : 24)}px;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const StyledHeaderText = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`

export const StyledLabel = styled('span')`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
`
