import { PayloadAction } from '@reduxjs/toolkit'
import { ChangeMetricValueDataTypePayload } from '../../types'
import { State } from '../types'
import { castToMetricEditingState } from './helpers'
import { isDataTypeConversionNecessary } from '@fintastic/web/util/metrics-and-lists'
import { MutableMetricWrapper } from '../../../abstract-data-types/metric'

/* @todo add tests */
// @todo review it, maybe this logic should be extracted to ADT
export const changeMetricDataValueType = (
  uncheckedState: State,
  { payload }: PayloadAction<ChangeMetricValueDataTypePayload>,
) => {
  const state = castToMetricEditingState(uncheckedState)
  const metric = new MutableMetricWrapper(state.metric)

  const conversionNecessary = isDataTypeConversionNecessary(
    metric.dataType(),
    payload.dataType,
  )

  if (state.flow === 'editing' && conversionNecessary) {
    throw new Error('impossible to change data type')
  }

  metric.changeDataType(
    payload.dataType,
    payload.currency,
    payload.allDimensions,
  )
}
