import { SectionedModalSectionDefinition } from '@fintastic/shared/ui/modal-framework'
import {
  DialogSectionGeneral,
  DialogSectionGeneralSubtitle,
} from '../subsections/general'
import { DialogSectionGeneralIcon } from '../subsections/general/DialogSectionGeneralIcon'
import {
  DialogSectionData,
  DialogSectionDataIcon,
  DialogSectionDataSubtitle,
} from '../subsections/data'
import * as React from 'react'
import {
  DialogSectionYAxis,
  DialogSectionYAxisSubtitle,
} from '../subsections/YAxis'
import {
  ChartGridModeSectionIcon,
  ChartLegendSectionIcon,
  ChartXAxisSectionIcon,
  ChartYAxisSectionIcon,
} from '../../chart-container/components/settings-panel/subsections/shared'
import {
  DialogSectionXAxis,
  DialogSectionXAxisSubtitle,
} from '../subsections/XAxis'
import {
  DialogSectionLegend,
  DialogSectionLegendSubtitle,
} from '../subsections/legend'
import {
  DialogSectionGridDisplay,
  DialogSectionGridDisplaySubtitle,
} from '../subsections/grid-display'
import { DialogSectionRoot } from '../subsections/DialogSectionRoot'

export const chartSettingsDialogNavigationRoute: SectionedModalSectionDefinition =
  {
    path: '/root',
    view: <DialogSectionRoot />,
  }

export const chartSettingsDialogGeneralRoute: SectionedModalSectionDefinition =
  {
    path: '/general',
    title: 'General',
    contentClass: 'no-top-padding',
    subtitleComponent: <DialogSectionGeneralSubtitle />,
    view: <DialogSectionGeneral />,
    icon: <DialogSectionGeneralIcon />,
  }

export const chartSettingsDialogRoutes: SectionedModalSectionDefinition[] = [
  {
    path: '/data',
    title: 'Chart data',
    subtitleComponent: <DialogSectionDataSubtitle />,
    view: <DialogSectionData />,
    icon: <DialogSectionDataIcon />,
  },
  {
    path: '/yAxis',
    title: 'Vertical axis',
    view: <DialogSectionYAxis />,
    subtitleComponent: <DialogSectionYAxisSubtitle />,
    icon: <ChartYAxisSectionIcon />,
  },
  {
    path: '/xAxis',
    title: 'Horizontal axis',
    view: <DialogSectionXAxis />,
    subtitleComponent: <DialogSectionXAxisSubtitle />,
    icon: <ChartXAxisSectionIcon />,
  },
  {
    path: '/legend',
    title: 'Legend',
    view: <DialogSectionLegend />,
    subtitleComponent: <DialogSectionLegendSubtitle />,
    icon: <ChartLegendSectionIcon />,
  },
  {
    path: '/grid',
    title: 'Grid display',
    view: <DialogSectionGridDisplay />,
    icon: <ChartGridModeSectionIcon />,
    subtitleComponent: <DialogSectionGridDisplaySubtitle />,
  },
]

export const allRoutes = [
  chartSettingsDialogNavigationRoute,
  chartSettingsDialogGeneralRoute,
  ...chartSettingsDialogRoutes,
]
