import { EChartsOption } from 'echarts'
import {
  ChartDisplaySettings,
  defaultChartLegendFormat,
} from '@fintastic/web/feature/charts'
import { defaultChartTextStyle } from './chart-default-style'
import { Maybe } from '@fintastic/shared/util/types'
import { LegendComponentOption } from 'echarts/types/dist/echarts'

const standardFormatter = (text: string) => text

const compactFormatter = (text: string) => (text || '').replaceAll(',\n', ', ')

export const processLegendSettings = (
  option: Maybe<EChartsOption>,
  settings: ChartDisplaySettings,
) => {
  if (!option) {
    return
  }

  const legend: LegendComponentOption = {
    textStyle: {
      ...defaultChartTextStyle,
      overflow: 'truncate',
    },
    orient: 'horizontal',
    icon: 'pin',
    type: 'scroll',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
    height: 'auto',
    formatter:
      settings.legendFormat === defaultChartLegendFormat ||
      !settings.legendFormat
        ? compactFormatter
        : standardFormatter,
    tooltip: {
      show: settings.legendFormat === 'compact',
    },
  }

  option.grid = {
    ...option.grid,
    // https://echarts.apache.org/en/option.html#grid.top
    right: '10%',
    left: '10%',
    top: 60,
    bottom: 60,
  }

  if (settings?.legendPosition === 'left') {
    legend.orient = 'vertical'
    legend.top = 'center'
    legend.left = 10
    legend.align = 'auto'
    legend.width = 100
    legend.textStyle = {
      ...legend.textStyle,
      width: 100,
    }

    option.grid = {
      ...option.grid,
      left: 200,
      right: 20,
    }
  }

  if (settings?.legendPosition === 'right') {
    legend.orient = 'vertical'
    legend.top = 'center'
    legend.right = 0
    legend.width = 120
    // legend.align = 'right'
    legend.textStyle = {
      ...legend.textStyle,
      width: 120,
    }

    option.grid = {
      ...option.grid,
      right: 190,
    }
  }

  if (settings?.legendPosition === 'top') {
    legend.left = 'center'
    legend.height = settings.legendFormat === 'compact' ? 48 : 100
    legend.top = 12
    legend.textStyle = {
      ...legend.textStyle,
      height: legend.height,
    }

    option.grid = {
      ...option.grid,
      top: settings.legendFormat === 'compact' ? 48 : 100,
    }
  }

  // default
  if (settings?.legendPosition === 'bottom' || !settings?.legendPosition) {
    legend.bottom = settings.legendFormat === 'compact' ? 10 : 5
    legend.left = 'center'
    legend.height = settings.legendFormat === 'compact' ? 48 : 100
    legend.textStyle = {
      ...legend.textStyle,
      height: legend.height,
    }

    const bottomSpace =
      legend.height + (settings.xAxisAlignment !== 'horizontal' ? 60 : 20)

    // https://github.com/apache/echarts/issues/14724
    option.grid = {
      ...option.grid,
      bottom: bottomSpace,
    }
  }

  option.legend = legend
}
