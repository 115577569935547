import { Maybe } from '@fintastic/shared/util/types'
import { useMemo, useState } from 'react'
import { SelectedCellAggregationContextValue } from './SelectedCellAggregationContext'
import { SelectedCell, SelectedCellAggregationType } from '../types'
import { useLocalStorage } from 'usehooks-ts'

export type UseSelectedCellAggregationContextValueParams = {
  entityIdForPersistentSettings: string
}

export const useSelectedCellAggregationContextValue = ({
  entityIdForPersistentSettings,
}: UseSelectedCellAggregationContextValueParams): SelectedCellAggregationContextValue => {
  const [aggregation, setAggregation] = useLocalStorage<
    Maybe<SelectedCellAggregationType>
  >(
    makeLocalStoragePersistentAggregationKey(entityIdForPersistentSettings),
    null,
  )
  const [selectedCells, setSelectedCells] = useState<SelectedCell[]>(() => [])

  return useMemo<SelectedCellAggregationContextValue>(
    () => ({
      aggregation,
      setAggregation,
      selectedCells,
      setSelectedCells,
    }),
    [aggregation, selectedCells, setAggregation],
  )
}

const makeLocalStoragePersistentAggregationKey = (prefix: string) =>
  `selected-cell-aggregation_${prefix}_aggregation`
