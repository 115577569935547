import { axios } from '@fintastic/web/data-access/service-axios'
import { VersionUserLock } from '@fintastic/web/util/versions'

const endpoints = {
  versionLocking: (id: string) => `/planning/api/v2/versions/${id}/locking/`,
} as const

export function getVersionsUserLocker(versionId: string) {
  return axios.get<VersionUserLock>(endpoints.versionLocking(versionId))
}

export function lockVersionForUser(versionId: string) {
  return axios.post<void>(endpoints.versionLocking(versionId))
}

export function releaseVersionUserLocker(versionId: string) {
  return axios.delete<void>(endpoints.versionLocking(versionId))
}
